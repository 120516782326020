import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { globalFiltersSlice } from '../@interface/globalFilters/globalFiltersReducer';

export type UsersOverviewState = AsyncReduxState<{
    page: number;
}>;

const initialState: UsersOverviewState = {
    ...initialAsyncReduxState,
    page: 1,
};

export const usersOverviewSlice = createSlice({
    name: 'usersOverviewReducer',
    initialState,
    reducers: {
        setPage(state, action: PayloadAction<number>): UsersOverviewState {
            return {
                ...state,
                page: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(globalFiltersSlice.actions.resetFilters, (): UsersOverviewState => initialState);
        builder.addCase(globalFiltersSlice.actions.setDepartmentOptions, (): UsersOverviewState => initialState);
        builder.addCase(globalFiltersSlice.actions.setEmploymentTypeOptions, (): UsersOverviewState => initialState);
        builder.addCase(globalFiltersSlice.actions.setFiltersFromLegacyFilters, (): UsersOverviewState => initialState);
    },
});

export const {
    setPage,
} = usersOverviewSlice.actions;

export default usersOverviewSlice.reducer;
