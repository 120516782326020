import { FC, ReactElement, useMemo } from 'react';

import classnames from 'classnames';

import { getUserFullName } from '../../../../../helpers';
import { Button, Skeleton } from '../../../../components';
import { FullLoketUser } from '../../../../entities/LoketUser/LoketUser';
import { getPersonFullName } from '../../../../entities/Person/PersonHelpers';
import { UserProfile } from '../../../../entities/User/User';
import { formatDate } from '../../../../helpers/date';
import trans from '../../../../helpers/trans';
import { UserStatus } from '../../../../types/userStatus';
import { IconButton } from '../../../index';

import './UserDetailBasicCard.scss';

interface UserDetailBasicCardProps {
    canViewLoketData: boolean;
    canViewLogs: boolean;
    isLoketUserLoading: boolean;
    user: UserProfile;
    loketUser?: FullLoketUser;
    onDeactivateUserButtonClick: () => void;
    onHistoryButtonClick: () => void;
    onResendRegistrationMailButtonClick: () => void;
    onSendResetPasswordAndPinButtonClick: () => void;
    className?: string;
    innerWrapperClassName?: string;
    loketWrapperClassName?: string;
}

const UserDetailBasicCard: FC<UserDetailBasicCardProps> = ({
    canViewLoketData,
    canViewLogs,
    isLoketUserLoading,
    user,
    loketUser,
    onDeactivateUserButtonClick,
    onHistoryButtonClick,
    onResendRegistrationMailButtonClick,
    onSendResetPasswordAndPinButtonClick,
    className = '',
    innerWrapperClassName = '',
    loketWrapperClassName = '',
}): ReactElement => {
    const linkedLoketUser = useMemo((): string => {
        if (!loketUser) return '';

        const loketUserName = getUserFullName(loketUser.person);
        const payrollId = loketUser.employment?.payrollId;

        return `${loketUserName} ${payrollId ? ` - ${payrollId}` : ''}`;
    }, [loketUser]);

    const loketContractDates = useMemo((): string => {
        if (!loketUser?.employment) return '';

        const startDate = formatDate(loketUser.employment.start);

        if (loketUser.employment.end) {
            const endDate = formatDate(loketUser.employment.end);

            return `${startDate} - ${endDate}`;
        }

        return startDate;
    }, [loketUser]);

    const userStatusBadgeClassName = classnames('user-detail-basic-card__user-status-badge', {
        [`user-detail-basic-card__user-status-badge--is-${user.status}`]: user.status,
        'user-detail-basic-card__user-status-badge--is-invited': !user.isRegistrationComplete,
    });

    return (
        <div className={`user-detail-basic-card ${className}`}>
            <div className={`user-detail-basic-card__inner-wrapper ${innerWrapperClassName}`}>
                <div className="user-detail-basic-card__employee-number-and-history-button">
                    <div className="user-detail-basic-card__employee-number">
                        {`${trans(`entities.roles.name.${user.role.slug}`)} - ${user.employeeNumber}`}
                    </div>

                    <div className="user-detail-basic-card__experience">
                        {user.experience.name}
                    </div>

                    {canViewLogs && (
                        <IconButton
                            hideLabel
                            icon="history"
                            text={trans('common.history')}
                            onClick={onHistoryButtonClick}
                            className="user-detail-basic-card__history-button"
                            iconClassName="user-detail-basic-card__history-button-icon"
                        />
                    )}
                </div>

                <h1 className="user-detail-basic-card__user-full-name">
                    {getPersonFullName(user.person)}
                </h1>

                <div className="user-detail-basic-card__user-status">
                    {trans('common.status')}

                    <div className={userStatusBadgeClassName}>
                        {!user.isRegistrationComplete ? (
                            trans('common.invited')
                        ) : (
                            trans(`types.userStatus.${user.status}`)
                        )}
                    </div>
                </div>

                <div className="user-detail-basic-card__action-buttons">
                    <IconButton
                        icon="lock-closed"
                        text={user.isRegistrationComplete ? (
                            trans('compositions.userDetailCards.sendResetPasswordAndPin')
                        ) : (
                            trans('compositions.userDetailCards.resendRegistrationMail')
                        )}
                        onClick={user.isRegistrationComplete ? onSendResetPasswordAndPinButtonClick : onResendRegistrationMailButtonClick}
                        className="user-detail-basic-card__action-button"
                        iconClassName="user-detail-basic-card__action-button-icon"
                    />

                    {user.status === UserStatus.active && (
                        <Button
                            text={trans('compositions.userDetailCards.makeInactive')}
                            onClick={onDeactivateUserButtonClick}
                            className="user-detail-basic-card__action-button"
                        />
                    )}
                </div>
            </div>

            {canViewLoketData && (
                <div className={`user-detail-basic-card__loket-wrapper ${loketWrapperClassName}`}>
                    <h2 className="user-detail-basic-card__loket-title">
                        {trans('compositions.userDetailCards.loket.title')}
                    </h2>

                    {isLoketUserLoading && (
                        <div className="user-detail-basic-card__loket-skeleton-wrapper">
                            <Skeleton className="user-detail-basic-card__loket-skeleton" />
                            <Skeleton className="user-detail-basic-card__loket-skeleton" />
                        </div>
                    )}

                    {!isLoketUserLoading && linkedLoketUser && (
                        <div className="user-detail-basic-card__loket-description-wrapper">
                            <p className="user-detail-basic-card__loket-description">
                                {trans('compositions.userDetailCards.loket.linkedUser', { loketName: linkedLoketUser })}
                            </p>

                            <p className="user-detail-basic-card__loket-description">
                                {trans('compositions.userDetailCards.loket.contractDates', { contractDates: loketContractDates })}
                            </p>
                        </div>
                    )}

                    {!isLoketUserLoading && !linkedLoketUser && (
                        <p className="user-detail-basic-card__loket-description">
                            {trans('compositions.userDetailCards.loket.notLinked')}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

export default UserDetailBasicCard;
