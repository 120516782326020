import { getPersonFullName } from '../../../../entities/Person/PersonHelpers';
import { BasicUser } from '../../../../entities/User/User';
import { CompanyMedicType } from '../../../../types';
import { UsersSelectOption } from '../../../index';
import { MultiUserSelectOption } from '../MultiUsersSelect';

export const transformToMultiUserSelectOptions = (users: BasicUser[]): MultiUserSelectOption[] => users.map(user => (
    {
        value: getPersonFullName(user.person),
        label: (
            <UsersSelectOption
                key={user.id}
                isCompanyMedic={user.companyMedic === CompanyMedicType.yes}
                contractHours={[]}
                fullName={getPersonFullName(user.person)}
            />
        ),
        userId: user.id,
    }
));
