import React, { FC } from 'react';

import classnames from 'classnames';

import { SelectPayrollPeriod } from '../../../../@paco/compositions';
import { PayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { translate } from '../../../../helpers/translations/translator';
import CompletePeriodTopBarSkeleton from './subcomponents/CompletePeriodTopBarSkeleton';

import './CompletePeriodTopBar.scss';

interface CompletePeriodTopBarProps {
    isLoading?: boolean;
    activePeriod?: PayrollPeriod;
    payrollPeriods: PayrollPeriod[];
    onPeriodSelect: (period: PayrollPeriod) => void;
}

const CompletePeriodTopBar: FC<CompletePeriodTopBarProps> = ({
    isLoading,
    activePeriod,
    payrollPeriods,
    onPeriodSelect,
}) => {
    const badgeClassName = classnames('badge complete-period-top-bar__status', {
        'badge-light-blue': activePeriod && !activePeriod.isLocked,
        'badge-orange': activePeriod && activePeriod.isLocked,
    });

    const handlePeriodSelect = (period: PayrollPeriod[]): void => {
        onPeriodSelect(period[0]);
    };

    if (isLoading && !payrollPeriods.length) {
        return (
            <CompletePeriodTopBarSkeleton />
        );
    }

    return (
        <div className="complete-period-top-bar">
            {activePeriod && (
                <div className="complete-period-top-bar__title">
                    {`${translate('common.period')} ${activePeriod.periodNumber}`}
                    <div className={badgeClassName}>
                        {activePeriod.isLocked ? translate('common.locked') : translate('common.open')}
                    </div>
                </div>
            )}
            <SelectPayrollPeriod
                hideLabel
                hasPeriodTitle
                periods={payrollPeriods}
                activePeriod={activePeriod}
                onChange={handlePeriodSelect}
                className="complete-period-top-bar__select-payroll-period"
                colClassName="complete-period-top-bar__select-payroll-period-col"
            />
        </div>
    );
};

export default CompletePeriodTopBar;
