import React, { FC } from 'react';

import { Button } from 'reactstrap';

import { ConnectedHelpTooltip } from '../../../../@paco/connectors';
import trans from '../../../../@paco/helpers/trans';
import Card from '../../../../components/Card/Card';
import Icon from '../../../../components/style/Icon/Icon';
import { translate } from '../../../../helpers/translations/translator';
import { LoketData } from '../../../../models';

import './LoketDataCard.scss';

interface LoketDataCardProps {
    className?: string;
    loketData: LoketData | null;
    onNonCoupledEmployeesButtonClick: () => void;
}

const LoketDataCard: FC<LoketDataCardProps> = ({
    className = '',
    loketData,
    onNonCoupledEmployeesButtonClick,
}) => (
    <>
        <ConnectedHelpTooltip
            index={0}
            route="synchronizations"
            subTitle={trans('help.pz.loketCoupling.title')}
            text={trans('help.pz.loketCoupling.text')}
            title={trans('help.pz.title')}
            className="loket-data-card__loket-coupling-help-tooltip"
        />
        <Card
            className={`loket-data-card ${className}`}
            title={translate('pages.pz.coupling')}
        >
            {loketData ? (
                <>
                    <div className="loket-data-card__card-title">
                        {translate('pages.pz.loketCoupling')}
                    </div>
                    <p className="loket-data-card__card-paragraph">
                        {translate('pages.pz.loketIsCoupledWithEmployer', { name: loketData.loketEmployerName })}
                    </p>

                    <div className="loket-data-card__card-title">
                        {translate('pages.pz.employerId')}
                    </div>
                    <p className="loket-data-card__card-paragraph">
                        {loketData.employerId}
                    </p>

                    <div className="loket-data-card__divider" />

                    <div className="loket-data-card__card-title">
                        {translate('pages.pz.coupleUsers')}
                    </div>
                    <p className="loket-data-card__card-paragraph">
                        <strong>{loketData.linkedUserCount}</strong>
                        {' '}
                        {translate('common.of').toLowerCase()}
                        {' '}
                        <strong>{loketData.totalUserCount}</strong>
                        {' '}
                        {translate('pages.pz.numberOfEmployeesCoupled')}
                    </p>
                    {loketData.linkedUserCount < loketData.totalUserCount && (
                        <Button
                            onClick={onNonCoupledEmployeesButtonClick}
                            color="orange"
                            className="loket-data-card__button"
                        >
                            <span className="loket-data-card__button-text">{translate('pages.pz.lookAtNonCoupledUsers')}</span>
                            <Icon
                                className="loket-data-card__button-icon"
                                color="white"
                                kind="arrow_downward"
                            />
                        </Button>
                    )}
                </>
            ) : <div>{translate('pages.pz.noLoketDataFound')}</div>}
        </Card>
    </>
);

export default LoketDataCard;
