import { FC, ReactElement } from 'react';

import { LeaveOfAbsencesOverviewBlock } from '../../../../../entities/LeaveOfAbsenceOverviewBlock/LeaveOfAbsencesOverviewBlock';
import { LeaveOfAbsencesOverviewTableBlock } from '../index';

import './LeaveOfAbsencesOverviewTableBodyCell.scss';

interface LeaveOfAbsencesOverviewTableBodyCellProps {
    index: number;
    leaveOfAbsences: LeaveOfAbsencesOverviewBlock[];
    className?: string;
}

const LeaveOfAbsencesOverviewTableBodyCell: FC<LeaveOfAbsencesOverviewTableBodyCellProps> = ({
    index,
    leaveOfAbsences,
    className = '',
}): ReactElement => (
    <td
        data-day-index={index}
        className={`leave-of-absences-overview-table-body-cell ${className}`}
    >
        <div className="leave-of-absences-overview-table-body-cell__active-layer" />
        {leaveOfAbsences.map(leaveOfAbsence => (
            <LeaveOfAbsencesOverviewTableBlock
                key={leaveOfAbsence.id}
                leaveOfAbsence={leaveOfAbsence}
            />
        ))}
    </td>
);

export default LeaveOfAbsencesOverviewTableBodyCell;
