import { transformToPeriod } from '../Period/PeriodTransformers';
import { UserResource } from '../User/User';
import { ShiftsCalendarUnavailableToWorkTimeSlot, ShiftsCalendarUnavailableToWorkTimeSlotResource } from './ShiftsCalendarUnavailableToWorkTimeSlot';

export const transformToShiftsCalendarUnavailableToWorkTimeSlot = (
    shiftsCalendarUnavailableToWorkTimeSlotResource: ShiftsCalendarUnavailableToWorkTimeSlotResource,
    user: UserResource,
): ShiftsCalendarUnavailableToWorkTimeSlot => ({
    id: shiftsCalendarUnavailableToWorkTimeSlotResource.id,
    comment: shiftsCalendarUnavailableToWorkTimeSlotResource.attributes.comment,
    weekday: shiftsCalendarUnavailableToWorkTimeSlotResource.attributes.weekday,
    userId: user.id,
    period: transformToPeriod(
        new Date(shiftsCalendarUnavailableToWorkTimeSlotResource.attributes.start),
        new Date(shiftsCalendarUnavailableToWorkTimeSlotResource.attributes.end),
    ),
});
