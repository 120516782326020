import React, { FC } from 'react';

import { translate } from '../../../../../../helpers/translations/translator';


interface RegisteredUsersProps {
    length: number;
    className?: string;
}

const RegisteredUsers: FC<RegisteredUsersProps> = ({ length, className = '' }) => (
    <div className={`calendar-shift-registered-users ${className}`}>
        {`${length} `}
        {length === 1
            ? translate('common.signup').toLowerCase()
            : translate('common.signups').toLowerCase()}
    </div>
);

export default RegisteredUsers;
