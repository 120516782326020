import React, { FC, useState } from 'react';

import classnames from 'classnames';
import { Button, Modal, ModalHeader } from 'reactstrap';

import { LoadingSpinner } from '../../../../@paco/components';
import { ModalContent, PacoModal } from '../../../../@paco/compositions';
import ConnectedEditTemporaryConceptWorkerForm from '../../../../@paco/connectors/@forms/ConnectedEditTemporaryConceptWorkerForm/ConnectedEditTemporaryConceptWorkerForm';
import { Setting } from '../../../../@paco/entities/Setting/Setting';
import { TemporaryWorker } from '../../../../@paco/entities/TemporaryWorker/TemporaryWorker';
import trans from '../../../../@paco/helpers/trans';
import { BaseScheduleShiftViewModel } from '../../../../entities/BaseScheduleShift/BaseScheduleShift';
import { PreferToWorkTimeSlotViewModel } from '../../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { ShiftConceptViewModelOld } from '../../../../entities/ShiftConcept/ShiftConcept';
import { UnavailableToWorkTimeSlotViewModel } from '../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { translate } from '../../../../helpers/translations/translator';
import {
    AbsenceViewModel,
    AddShiftFormData,
    EditShiftFormData,
    EditShiftPlanningFormData,
    LeaveOfAbsenceViewModel,
    ShiftPlanningViewModel,
    ShiftViewModel,
} from '../../../../models';
import AddTempWorker from '../../forms/AddTempWorker';
import EditShift from '../../forms/EditShiftForm/EditShiftForm';
import { transformShiftToFormData } from '../../forms/EditShiftForm/helpers/transformShiftToFormData';
import EditShiftPlanningForm from '../../forms/EditShiftPlanningForm/EditShiftPlanningForm';
import ShiftPlanningsForm from '../../forms/ShiftPlanningsForm/ShiftPlanningsForm';

import './ShiftDetail.scss';

interface ShiftDetailProps {
    isLoading: boolean;
    isBaseScheduleShift?: boolean;
    isEmployeeLoading: boolean;
    canDeleteShift: boolean;
    canAddShiftPlannings: boolean;
    canEditShiftPlannings: boolean;
    canDeleteShiftPlannings: boolean;
    hasDarkBackground?: boolean;
    showComments?: boolean;
    canAddTemporaryWorkers?: boolean;
    title: string;
    saveButtonTitle?: string;
    shift: ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel;
    selectedDays?: Date[];
    absences?: AbsenceViewModel[];
    settings?: Setting[];
    leaveOfAbsences?: LeaveOfAbsenceViewModel[];
    unavailableToWorkTimeSlots?: UnavailableToWorkTimeSlotViewModel[];
    preferToWorkTimeSlots?: PreferToWorkTimeSlotViewModel[];
    onEmployeeSelect: (employeeId: string) => void;
    onShiftPlanningColorClick: (shiftPlanning: ShiftPlanningViewModel, color?: string) => void;
    onSubmitEditShiftPlanning: (formData: EditShiftPlanningFormData, shiftPlanning: ShiftPlanningViewModel) => void;
    onDeleteShiftPlanningClick: (shiftPlanning: ShiftPlanningViewModel) => void;
    onAddTemporaryWorkerClick?: (name: string) => void;
    onTemporaryConceptWorkerColorClick?: (temporaryConceptWorkerId: string, color?: string) => void;
    onDeleteTemporaryWorkerClick?: (temporaryConceptWorkerId: string) => void;
    onDeleteButtonClick: () => void;
    onSaveButtonClick: (data: EditShiftFormData) => void;
    onCancel: () => void;
    className?: string;
}

const ShiftDetail: FC<ShiftDetailProps> = ({
    isLoading,
    isBaseScheduleShift,
    isEmployeeLoading,
    canDeleteShift,
    canAddShiftPlannings,
    canEditShiftPlannings,
    canDeleteShiftPlannings,
    canAddTemporaryWorkers,
    shift,
    selectedDays,
    absences = [],
    leaveOfAbsences = [],
    unavailableToWorkTimeSlots = [],
    preferToWorkTimeSlots = [],
    settings = [],
    hasDarkBackground = false,
    showComments = false,
    title,
    saveButtonTitle,
    onEmployeeSelect,
    onShiftPlanningColorClick,
    onSubmitEditShiftPlanning,
    onDeleteShiftPlanningClick,
    onAddTemporaryWorkerClick,
    onTemporaryConceptWorkerColorClick,
    onDeleteTemporaryWorkerClick,
    onDeleteButtonClick,
    onSaveButtonClick,
    onCancel,
    className = '',
}) => {
    const [shiftFormData, setShiftFormData] = useState<AddShiftFormData>(transformShiftToFormData(shift));
    const [temporaryConceptWorkerState, setTemporaryConceptWorkerState] = useState<TemporaryWorker | undefined>(undefined);
    const [editShiftPlanningForm, setEditShiftPlanningForm] = useState<boolean>(false);
    const [editShiftPlanningData, setEditShiftPlanningData] = useState<ShiftPlanningViewModel | null>(null);
    const [addTemporaryWorkerForm, setAddTemporaryWorkerForm] = useState<boolean>(false);
    const [editTemporaryWorkerForm, setEditTemporaryWorkerForm] = useState<boolean>(false);

    const classNames = classnames('shift-detail', {
        'shift-detail--has-dark-background': hasDarkBackground,
    }, [className]);
    const canSubmit = true;

    const handleShiftInfoChange = (formData: AddShiftFormData) => {
        setShiftFormData(formData);
    };

    const handleEditShiftPlanningClick = (shiftPlanning: ShiftPlanningViewModel) => {
        setEditShiftPlanningData(shiftPlanning);
        setEditShiftPlanningForm(true);
    };

    const handleAddTemporaryWorkerClick = (): void => {
        setAddTemporaryWorkerForm(true);
    };

    const handleCancelAddTemporaryWorkerClick = (): void => {
        setAddTemporaryWorkerForm(false);
    };

    const handleSubmitTemporaryWorkerClick = (name: string): void => {
        if (onAddTemporaryWorkerClick) {
            onAddTemporaryWorkerClick(name);
            setAddTemporaryWorkerForm(false);
        }
    };

    const handleOnTemporaryConceptWorkerColorClick = (temporaryConceptWorkerId: string, color?: string): void => {
        if (onTemporaryConceptWorkerColorClick) {
            onTemporaryConceptWorkerColorClick(temporaryConceptWorkerId, color);
        }
    };

    const handleOnEditTemporaryWorker = (temporaryConceptWorker: TemporaryWorker): void => {
        setTemporaryConceptWorkerState(temporaryConceptWorker);
        setEditTemporaryWorkerForm(true);
    };

    const closeEditTemporaryWorkerForm = (): void => {
        setEditTemporaryWorkerForm(false);
        setTemporaryConceptWorkerState(undefined);
    };

    const handleOnDeleteTemporaryWorkerClick = (temporaryWorker: TemporaryWorker): void => {
        if (onDeleteTemporaryWorkerClick) {
            onDeleteTemporaryWorkerClick(temporaryWorker.id);
        }
    };

    const handleCancelEditShiftPlanningForm = () => setEditShiftPlanningForm(false);

    const handleSubmitEditShiftPlanningForm = (formData: EditShiftPlanningFormData) => {
        if (editShiftPlanningData) {
            onSubmitEditShiftPlanning(formData, editShiftPlanningData);
        }
        setEditShiftPlanningForm(false);
    };

    const handleSaveButtonClick = () => onSaveButtonClick(shiftFormData);

    return (
        <div className={classNames}>
            <div className="shift-detail__container">
                <div className="shift-detail__header">
                    <ModalHeader className="shift-detail__title">
                        {title}
                    </ModalHeader>
                    <Button
                        className="shift-detail__button-cancel"
                        type="button"
                        color="white"
                        onClick={onCancel}
                    >
                        {translate('common.cancel')}
                    </Button>
                    <Button
                        type="button"
                        color="orange"
                        disabled={!canSubmit}
                        onClick={handleSaveButtonClick}
                    >
                        {saveButtonTitle || translate('common.save')}
                    </Button>
                </div>
                <div className="shift-detail__forms">
                    <EditShift
                        selectedDays={selectedDays}
                        departmentSelectIsDisabled
                        title={translate('common.information')}
                        data={shift}
                        className="shift-detail__edit-shift-form"
                        onChange={handleShiftInfoChange}
                        canDeleteShift={canDeleteShift}
                        onDeleteButtonClick={onDeleteButtonClick}
                    />
                    <ShiftPlanningsForm
                        isBaseScheduleShift={isBaseScheduleShift}
                        isLoading={isEmployeeLoading}
                        showComments={showComments}
                        canAddShiftPlannings={canAddShiftPlannings}
                        canEditShiftPlannings={canEditShiftPlannings}
                        canDeleteShiftPlannings={canDeleteShiftPlannings}
                        canAddTemporaryWorkers={canAddTemporaryWorkers}
                        shift={shift}
                        absences={absences}
                        leaveOfAbsences={leaveOfAbsences}
                        unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                        preferToWorkTimeSlots={preferToWorkTimeSlots}
                        settings={settings}
                        onEmployeeSelect={onEmployeeSelect}
                        onShiftPlanningColorClick={onShiftPlanningColorClick}
                        onDeleteShiftPlanningClick={onDeleteShiftPlanningClick}
                        onEditShiftPlanningClick={handleEditShiftPlanningClick}
                        onAddTemporaryWorkerClick={handleAddTemporaryWorkerClick}
                        onTemporaryConceptWorkerColorClick={handleOnTemporaryConceptWorkerColorClick}
                        onEditTemporaryWorkerClick={handleOnEditTemporaryWorker}
                        onDeleteTemporaryWorkerClick={handleOnDeleteTemporaryWorkerClick}
                        className="shift-detail__shift-plannings-form"
                    />
                </div>
                {isLoading && <LoadingSpinner className="shift-detail__loading-spinner" />}
                <Modal
                    isOpen={editShiftPlanningForm}
                    size="lg"
                    className="concepts-calendar-overview__add-shift-form"
                >
                    { editShiftPlanningData && (
                        <EditShiftPlanningForm
                            showComment={showComments}
                            data={editShiftPlanningData}
                            onSubmit={handleSubmitEditShiftPlanningForm}
                            onCancel={handleCancelEditShiftPlanningForm}
                        />
                    )}
                </Modal>
                <Modal
                    isOpen={addTemporaryWorkerForm}
                    size="lg"
                    className="shift-detail__add-temp-worker"
                >
                    <AddTempWorker
                        onSubmit={handleSubmitTemporaryWorkerClick}
                        onCancel={handleCancelAddTemporaryWorkerClick}
                    />
                </Modal>
                {editTemporaryWorkerForm && temporaryConceptWorkerState && (
                    <PacoModal>
                        <ModalContent title={trans('containers.forms.editTemporaryWorkerForm.title')}>
                            <ConnectedEditTemporaryConceptWorkerForm
                                temporaryWorker={temporaryConceptWorkerState}
                                onClose={closeEditTemporaryWorkerForm}
                            />
                        </ModalContent>
                    </PacoModal>
                )}
            </div>
        </div>
    );
};

export default ShiftDetail;
