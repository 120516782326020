import { DepartmentResource } from '../../entities/Departments/Departments';
import { transformDepartmentResource } from '../../entities/Departments/DepartmentsTransformers';
import { getDummyUserPersonViewModel } from '../../entities/Person/PersonHelpers';
import {
    Person,
    Role,
    UserPersonViewModel,
    UserResource,
    UserViewModel,
    UserWithPersonViewModel,
} from '../../models';
import { EmploymentTypeResource } from '../../models/EmploymentType';
import transformEmploymentTypeResource from '../EmploymentTypeService/transformEmploymentTypeResource';

export default function transformUserResource(
    user: UserResource,
    departments: DepartmentResource[] = [],
    roles: Role[] = [],
    employmentType?: EmploymentTypeResource,
): UserViewModel {
    return {
        type: 'users',
        affix: user.affix,
        birthday: new Date(user.birthday),
        departments: departments.map(transformDepartmentResource),
        email: user.email,
        employeeNumber: user.employeeNumber,
        employmentType: employmentType ? transformEmploymentTypeResource(employmentType) : undefined,
        experience: user.experience,
        firstname: user.firstname,
        id: user.id,
        isEligibleForTimeForTime: user.isEligibleForTimeForTime,
        isLoketAuthorized: user.isLoketAuthorized,
        isPaidBasedOnTrackedTime: user.isPaidBasedOnTrackedTime,
        lastname: user.lastname,
        loketEmployeeId: user.loketEmployeeId,
        nationality: user.nationality,
        phone: user.phone,
        plannedHoursInWeek: user.plannedHoursInWeek || 0,
        plannedConceptHoursInWeek: user.plannedConceptHoursInWeek || 0,
        registrationComplete: user.registrationComplete,
        roles,
        shouldTrackTime: user.shouldTrackTime,
        status: user.status,
    };
}

export const transformToUserPerson = (person: Person): UserPersonViewModel => ({
    id: person.id,
    affix: person.affix,
    birthday: person.birthday,
    firstname: person.firstname,
    gender: person.gender,
    identificationExpirationDate: person.identificationExpirationDate,
    identificationType: person.identificationType,
    isCompanyMedic: person.isCompanyMedic,
    lastname: person.lastname,
    nationality: person.nationality,
    hasSundayAllowance: person.hasSundayAllowance,
    workDays: person.workDays,
});

export const transformUserResourceToUserWithPerson = (
    user: UserResource,
    person: Person,
    departments: DepartmentResource[] = [],
    roles: Role[] = [],
    employmentType?: EmploymentTypeResource,
): UserWithPersonViewModel => {
    if (!person) {
        console.error(`[transformUserResourceToUserWithPerson] Missing person resource in user with id ${user.id} (${user.firstname})`);
    }

    return {
        ...transformUserResource(user, departments, roles, employmentType),
        person: person ? transformToUserPerson(person) : getDummyUserPersonViewModel(`${user.id}-person`),
    };
};
