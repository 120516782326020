import { FC } from 'react';

import { formatDate } from '../../../../../helpers/date';

import './CalendarDayHeader.scss';

interface CalendarDayHeaderProps {
    day: Date;
    dayHeaderFormat?: 'EEEEEE' | 'EEEE';
    className?: string;
}

const CalendarDayHeader: FC<CalendarDayHeaderProps> = ({
    day,
    dayHeaderFormat = 'EEEEEE',
    className = '',
}) => {
    const weekday = formatDate(day, dayHeaderFormat);

    return (
        <div className={`calendar-day-header ${className}`}>
            {weekday}
        </div>
    );
};

export default CalendarDayHeader;
