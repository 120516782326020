import { FC } from 'react';

import { ShiftsWidgetShiftSkeleton } from '..';

interface DashboardShiftsWidgetProps {
    className?: string;
}

const ShiftsWidgetShiftsSkeletons: FC<DashboardShiftsWidgetProps> = ({ className = '' }) => (
    <ul className={`shifts-widget__shifts ${className}`}>
        <li className="shifts-widget__shifts-item">
            <ShiftsWidgetShiftSkeleton index={1} />
        </li>
        <li className="shifts-widget__shifts-item">
            <ShiftsWidgetShiftSkeleton index={2} />
        </li>
        <li className="shifts-widget__shifts-item">
            <ShiftsWidgetShiftSkeleton index={3} />
        </li>
        <li className="shifts-widget__shifts-item">
            <div className="shifts-widget__shifts-divider" />
        </li>
        <li className="shifts-widget__shifts-item">
            <ShiftsWidgetShiftSkeleton index={2} />
        </li>
        <li className="shifts-widget__shifts-item">
            <div className="shifts-widget__shifts-divider" />
        </li>
        <li className="shifts-widget__shifts-item">
            <ShiftsWidgetShiftSkeleton index={1} />
        </li>
        <li className="shifts-widget__shifts-item">
            <ShiftsWidgetShiftSkeleton index={3} />
        </li>
    </ul>
);

export default ShiftsWidgetShiftsSkeletons;
