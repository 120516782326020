import React, { FC, useEffect, useState } from 'react';

import { ShiftPlanningWithShift } from '../../../../../../@paco/entities/ShiftPlanning/ShiftPlanning';
import {
    Absence,
    LeaveOfAbsence,
    PreferToWorkTimeSlot,
    UnavailableToWorkTimeSlot,
    UserWithAvailability,
} from '../../../../../../models';
import { getOverlappingResourcesOnDate } from '../../../AvailabilityDays/helpers';
import Availability, { AvailabilityResourceTypes } from '../../../AvailabilityDays/subcomponents/Availability/Availability';

interface Props {
    loading: boolean;
    user: UserWithAvailability;
    day: Date;
    onAvailabilityClick: (resource: AvailabilityResourceTypes) => void;
}

type ResourceTypes = LeaveOfAbsence | Absence | PreferToWorkTimeSlot | UnavailableToWorkTimeSlot
| ShiftPlanningWithShift;

const UserAvailabilityOnDate: FC<Props> = ({
    loading,
    user,
    day,
    onAvailabilityClick,
}) => {
    const [resources, setResources] = useState<ResourceTypes[]>([]);

    useEffect(() => {
        if (!loading) {
            setResources(getOverlappingResourcesOnDate(
                user.leaveOfAbsences,
                user.absences,
                user.preferToWorkTimeSlots,
                user.unavailableToWorkTimeSlots,
                day,
                user.shiftPlannings,
            ));
        }
    }, [user, day, loading]);

    return (
        <>
            {
                resources.map(resource => (
                    <Availability
                        showDescription
                        key={resource.id}
                        resource={resource}
                        onClick={onAvailabilityClick}
                    />
                ))
            }
        </>
    );
};

export default UserAvailabilityOnDate;
