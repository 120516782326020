import { FC, ReactElement } from 'react';

import { UserSearch } from '../../containers';
import trans from '../../helpers/trans';
import { setPage, setSearch as setPzLogsSearch } from '../../redux/pzLogs/pzLogsReducer';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedUserSearchProps {
    className?: string;
    inputClassName?: string;
    iconWrapperClassName?: string;
}

const ConnectedUserSearch: FC<ConnectedUserSearchProps> = ({
    className = '',
    iconWrapperClassName = '',
    inputClassName = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { search } = useTypedSelector(state => state.pzLogsReducer);

    const handleSearchChange = (value: string) => {
        dispatch(setPage(1));
        dispatch(setPzLogsSearch(value));
    };

    return (
        <UserSearch
            label={trans('common.search')}
            search={search}
            onSearchChange={handleSearchChange}
            className={className}
            iconWrapperClassName={iconWrapperClassName}
            inputClassName={inputClassName}
        />
    );
};

export default ConnectedUserSearch;
