import { PayrollPeriod } from '../../../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { compareAsc } from '../../../../../helpers/date';
import { AbsenceHours } from '../../../../../models';
import transformAbsenceHourToWeekdayWithHours
    from '../../../../../services/WeekWithHours/transformAbsenceHourToWeekdayWithHours';

export const transformAbsenceHoursToWeekDayWithHours = (
    leaveOfAbsenceHours: AbsenceHours[] = [],
    pacoPayrollPeriods: PayrollPeriod[],
) => leaveOfAbsenceHours
    .map((hours) => transformAbsenceHourToWeekdayWithHours(hours, pacoPayrollPeriods))
    .sort((a, b) => compareAsc(a.start, b.start));
