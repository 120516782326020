import React, { FC, useMemo } from 'react';

import {
    Col,
    FormGroup,
    Label,
    ModalBody,
    ModalHeader,
    Row,
} from 'reactstrap';

import { LoadingSpinner } from '../../../../@paco/components';
import TemporaryWorkerCard from '../../../../@paco/compositions/TemporaryWorkerCard/TemporaryWorkerCard';
import { ConnectedUsersSelect, ConnectedWorkweekUsersSelect } from '../../../../@paco/connectors';
import { transformToPeriod } from '../../../../@paco/entities/Period/PeriodTransformers';
import { Setting } from '../../../../@paco/entities/Setting/Setting';
import { TemporaryWorker } from '../../../../@paco/entities/TemporaryWorker/TemporaryWorker';
import { LegacyBasicUser, WorkweekUser } from '../../../../@paco/entities/User/User';
import AddResourceButton from '../../../../components/AddResourceButton/AddResourceButton';
import LegacyShiftPlanningCard from '../../../../components/LegacyShiftPlanningCard/LegacyShiftPlanningCard';
import { BaseScheduleShiftViewModel } from '../../../../entities/BaseScheduleShift/BaseScheduleShift';
import { PreferToWorkTimeSlotViewModel } from '../../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { ShiftConceptViewModelOld } from '../../../../entities/ShiftConcept/ShiftConcept';
import { UnavailableToWorkTimeSlotViewModel } from '../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { translate } from '../../../../helpers/translations/translator';
import {
    AbsenceViewModel,
    LeaveOfAbsenceViewModel,
    ShiftPlanningViewModel,
    ShiftViewModel,
    UserWithPersonViewModel,
} from '../../../../models';

import './ShiftPlanningsForm.scss';

interface ShiftPlanningsFormProps {
    canAddShiftPlannings: boolean;
    canEditShiftPlannings: boolean;
    canDeleteShiftPlannings: boolean;
    canAddTemporaryWorkers?: boolean;
    isBaseScheduleShift?: boolean;
    onEmployeeSelect: (userId: string) => void;
    onShiftPlanningColorClick: (shiftPlanning: ShiftPlanningViewModel, color?: string) => void;
    onEditShiftPlanningClick: (shiftPlanning: ShiftPlanningViewModel) => void;
    onDeleteShiftPlanningClick: (shiftPlanning: ShiftPlanningViewModel) => void;
    onAddTemporaryWorkerClick: () => void;
    onTemporaryConceptWorkerColorClick: (temporaryConceptWorkerId: string, color?: string) => void;
    onEditTemporaryWorkerClick: (temporaryConceptWorker: TemporaryWorker) => void;
    onDeleteTemporaryWorkerClick: (temporaryConceptWorker: TemporaryWorker) => void;
    shift: ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel;
    absences: AbsenceViewModel[];
    leaveOfAbsences: LeaveOfAbsenceViewModel[];
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlotViewModel[];
    preferToWorkTimeSlots: PreferToWorkTimeSlotViewModel[];
    settings?: Setting[];
    showComments?: boolean;
    isLoading?: boolean;
    className?: string;
}

const ShiftPlanningsForm: FC<ShiftPlanningsFormProps> = ({
    canAddShiftPlannings,
    canEditShiftPlannings,
    canDeleteShiftPlannings,
    canAddTemporaryWorkers,
    isBaseScheduleShift,
    shift,
    absences,
    leaveOfAbsences,
    unavailableToWorkTimeSlots,
    preferToWorkTimeSlots,
    settings,
    onEmployeeSelect,
    onShiftPlanningColorClick,
    onEditShiftPlanningClick,
    onDeleteShiftPlanningClick,
    onAddTemporaryWorkerClick,
    onTemporaryConceptWorkerColorClick,
    onEditTemporaryWorkerClick,
    onDeleteTemporaryWorkerClick,
    showComments = false,
    isLoading = false,
    className = '',
}) => {
    const { shiftPlannings, peopleLimit } = shift;
    const temporaryWorkers = 'temporaryWorkers' in shift ? shift.temporaryWorkers : [];
    const shiftPeriod = transformToPeriod(new Date(shift.start), new Date(shift.end.date));

    const shiftPlanningUsers = useMemo(() => (shiftPlannings
        .map(shiftPlanning => shiftPlanning.user)
        .filter(user => Boolean(user)) as UserWithPersonViewModel[]
    ), [shiftPlannings]);

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const handleSelectEmployee = (value: LegacyBasicUser | WorkweekUser) => {
        onEmployeeSelect(value.id);
    };

    return (
        <form className={`shift-plannings-form ${className}`} onSubmit={handleFormSubmit}>
            <ModalHeader className="shift-plannings-form__header">
                <span>{translate('common.employees')}</span>
                <span>{` ${shiftPlannings.length + temporaryWorkers.length}/${peopleLimit}`}</span>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.employee')}</Label>

                            {isBaseScheduleShift ? (
                                <ConnectedUsersSelect
                                    controlShouldRenderValue={false}
                                    isDisabled={!canAddShiftPlannings || isLoading}
                                    departmentId={shift.department?.id}
                                    excludeUsers={shiftPlanningUsers.map(user => user.id)}
                                    onSelect={handleSelectEmployee}
                                />
                            ) : (
                                <ConnectedWorkweekUsersSelect
                                    controlShouldRenderValue={false}
                                    isDisabled={!canAddShiftPlannings || isLoading}
                                    departmentId={shift.department?.id}
                                    excludeUsers={shiftPlanningUsers.map(user => user.id)}
                                    period={shiftPeriod}
                                    onSelect={handleSelectEmployee}
                                />
                            )}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {shiftPlannings.map(shiftPlanning => (
                            <LegacyShiftPlanningCard
                                key={shiftPlanning.id}
                                shift={shift}
                                showComments={showComments}
                                absences={absences}
                                leaveOfAbsences={leaveOfAbsences}
                                unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                                preferToWorkTimeSlots={preferToWorkTimeSlots}
                                settings={settings}
                                shiftPlanning={shiftPlanning}
                                onColorSelect={canEditShiftPlannings ? onShiftPlanningColorClick : undefined}
                                onEditClick={canEditShiftPlannings ? onEditShiftPlanningClick : undefined}
                                onDeleteClick={canDeleteShiftPlannings ? onDeleteShiftPlanningClick : undefined}
                            />
                        ))}
                        {temporaryWorkers.map(temporaryConceptWorker => (
                            <TemporaryWorkerCard
                                key={temporaryConceptWorker.id}
                                shift={shift as ShiftConceptViewModelOld}
                                temporaryWorker={temporaryConceptWorker}
                                onColorSelect={onTemporaryConceptWorkerColorClick}
                                onEditClick={onEditTemporaryWorkerClick}
                                onDeleteClick={onDeleteTemporaryWorkerClick}
                            />
                        ))}
                        {canAddTemporaryWorkers && (
                            <AddResourceButton
                                text={translate('common.tempWorker')}
                                onClick={onAddTemporaryWorkerClick}
                            />
                        )}
                    </Col>
                </Row>
            </ModalBody>
            {isLoading && <LoadingSpinner isSmall className="shift-plannings-form__loader" />}
        </form>
    );
};

export default ShiftPlanningsForm;
