import { FC, ReactElement } from 'react';

import { Button } from '../../../../components';
import trans from '../../../../helpers/trans';
import { UserStatus } from '../../../../types/userStatus';

import './DeactivatedUserBar.scss';

interface DeactivatedUserBarProps {
    showButtons: boolean;
    userFullName: string;
    userStatus: UserStatus;
    onDeleteButtonClick?: () => void;
    onReactivateButtonClick?: () => void;
    className?: string;
}

const DeactivatedUserBar: FC<DeactivatedUserBarProps> = ({
    showButtons,
    userFullName,
    userStatus,
    onDeleteButtonClick,
    onReactivateButtonClick,
    className = '',
}): ReactElement => (
    <div className={`deactivated-user-bar ${className}`}>
        <div className="deactivated-user-bar__intro">
            {userStatus === UserStatus.inActive && (
                trans('containers.userDetail.userIsDeactivated', { fullName: userFullName })
            )}
            {userStatus === UserStatus.deleted && (
                trans('containers.userDetail.userIsDeleted', { fullName: userFullName })
            )}
        </div>
        {showButtons && (
            <div className="deactivated-user-bar__buttons">
                <Button
                    text={trans('common.delete')}
                    onClick={onDeleteButtonClick}
                    className="deactivated-user-bar__delete-button"
                />
                <Button
                    text={trans('containers.userDetail.reactivate')}
                    onClick={onReactivateButtonClick}
                    className="deactivated-user-bar__reactivate-button"
                />
            </div>
        )}
    </div>
);

export default DeactivatedUserBar;
