import React, { FC } from 'react';

import { IconButton } from '../../../../../../@paco/compositions';
import Icon from '../../../../../../components/style/Icon/Icon';
import { DocumentViewModel } from '../../../../../../models';
import { downloadDocument } from '../../../../../../services/UserDocument/downloadUserDocument';
import SharedDocumentItem from '../SharedDocumentItem/SharedDocumentItem';

import './SharedDocumentsRow.scss';

interface SharedDocumentProps {
    document: DocumentViewModel;
    editDocument: (document: DocumentViewModel) => void;
    deleteDocument: (documentId: string) => void;
}

const SharedDocumentsRow: FC<SharedDocumentProps> = ({
    document,
    editDocument,
    deleteDocument,
}) => (
    <tr key={document.id} className="shared-documents-row">
        <SharedDocumentItem {...document} />
        <td className="shared-documents-row__column">
            <button type="button" onClick={() => downloadDocument(document)} className="clear-button">
                <Icon color="light-blue" kind="download" />
            </button>
            <button type="button" onClick={() => editDocument(document)} className="clear-button">
                <Icon color="light-blue" kind="edit" />
            </button>
            <IconButton
                hideLabel
                onClick={() => deleteDocument(document.id)}
                icon="trash"
                text="delete"
                className="clear-button shared-documents-row-delete"
            />
        </td>
    </tr>
);

export default SharedDocumentsRow;
