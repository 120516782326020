import { ShiftPlanningWithShift } from '../../../@paco/entities/ShiftPlanning/ShiftPlanning';
import {
    Absence,
    BasicUser,
    LeaveOfAbsence,
    PreferToWorkTimeSlot,
    UnavailableToWorkTimeSlot,
    UserWithAvailability,
} from '../../../models';

export default function transformAvailabilityDataToUserWithAvailability(
    user: BasicUser,
    absences: Absence[],
    leaveOfAbsences: LeaveOfAbsence[],
    preferToWorkTimeSlots: PreferToWorkTimeSlot[],
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[],
    shiftPlannings: ShiftPlanningWithShift[],
): UserWithAvailability {
    return {
        ...user,
        absences: absences
            .filter(absence => absence.user.id === user.id),
        leaveOfAbsences: leaveOfAbsences
            .filter(leaveOfAbsence => leaveOfAbsence.user.id === user.id),
        preferToWorkTimeSlots: preferToWorkTimeSlots
            .filter(preferToWorkTimeSlot => preferToWorkTimeSlot.user.id === user.id),
        unavailableToWorkTimeSlots: unavailableToWorkTimeSlots
            .filter(unavailableToWorkTimeSlot => unavailableToWorkTimeSlot.user.id === user.id),
        shiftPlannings: shiftPlannings
            .filter(shiftPlanning => shiftPlanning.user.id === user.id),
    };
}
