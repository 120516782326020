import { differenceInMinutes } from 'date-fns';

import { compareAsc } from '../../../../helpers/date';
import { Absence } from '../../../entities/Absence/Absence';
import { doesAbsenceOverlapWithPeriod } from '../../../entities/Absence/AbsenceHelpers';
import { Department } from '../../../entities/Department/Department';
import { Period } from '../../../entities/Period/Period';
import { getTimeOnlyLabel } from '../../../entities/Period/PeriodHelpers';
import { getPersonFullName } from '../../../entities/Person/PersonHelpers';
import { ShiftIndex } from '../../../entities/Shift/Shift';
import { ShiftPlanning } from '../../../entities/ShiftPlanning/ShiftPlanning';
import { Track } from '../../../entities/Track/Track';
import { formatDate } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { CompanyMedicType } from '../../../types';
import { DateFormat } from '../../../types/dateFormatTypes';
import { DepartmentOption } from '../../../types/selectOptionTypes';
import { DashboardShiftsWidgetGroup } from '../ShiftsWidget';
import { DashboardShiftsWidgetShiftProps } from '../subcomponents/ShiftsWidgetShift/ShiftsWidgetShift';

export const getShiftTrackPeriodText = (trackPeriod?: Period, absence?: Absence): string | undefined => {
    if (!trackPeriod && absence) {
        return undefined;
    }

    if (!trackPeriod) {
        return trans('compositions.dashboardShiftsWidget.notYetClockedIn');
    }

    if (trackPeriod.hasEnd) {
        return getTimeOnlyLabel(trackPeriod.start, trackPeriod.end);
    }

    const startTime = formatDate(trackPeriod.start, DateFormat.hoursMinutes);

    return trans('compositions.dashboardShiftsWidget.clockedInAt', { time: startTime });
};


const transformShiftPlanningToWidgetShift = (
    shift: ShiftIndex,
    shiftPlanning: ShiftPlanning,
    track?: Track,
    absence?: Absence,
): DashboardShiftsWidgetShiftProps => ({
    id: shiftPlanning.id,
    isCompanyMedic: shiftPlanning.user.companyMedic === CompanyMedicType.yes,
    absence,
    comment: shiftPlanning.comments[shiftPlanning.comments.length - 1],
    department: shift.department,
    employmentType: shiftPlanning.user.employmentType || undefined,
    experience: shiftPlanning.user.experience || undefined,
    shiftPeriod: shiftPlanning.period,
    trackPeriod: track?.checkInOutPeriod,
    userId: shiftPlanning.user.id,
    userFullName: shiftPlanning.user.fullName,
});

const transformTrackToWidgetShift = (track: Track): DashboardShiftsWidgetShiftProps => ({
    id: track.id,
    isCompanyMedic: track.owner.person.companyMedic === CompanyMedicType.yes,
    employmentType: track.owner.employmentType,
    experience: track.owner.experience,
    trackPeriod: track.checkInOutPeriod,
    userId: track.owner.id,
    userFullName: getPersonFullName(track.owner.person),
});

export const transformShiftsToDashboardShiftsWidgetGroups = (
    shifts: ShiftIndex[],
    tracks: Track[] = [],
    absences: Absence[] = [],
    now: Date,
    shiftExpiryThresholdInMinutes = 30,
    futureShiftAppearanceThresholdInMinutes = 60,
): DashboardShiftsWidgetGroup[] => {
    const shiftGroups = shifts
        .filter(shift => {
            // Remove shifts that have no shiftPlannings
            const hasPlannedShiftPlannings = shift.shiftPlannings.some(shiftPlanning => shiftPlanning.planned && shiftPlanning.user.shouldTrackTime);

            if (!hasPlannedShiftPlannings) {
                return false;
            }

            // Remove shifts when they are too far in past
            const isExpired = differenceInMinutes(now, shift.period.end) >= shiftExpiryThresholdInMinutes;

            // Remove shifts that are too far in future
            const isNotRelevantYet = differenceInMinutes(shift.period.start, now) >= futureShiftAppearanceThresholdInMinutes;

            // Keep all shifts that have an active track (employee has not clocked out yet)
            const hasActiveTracks = tracks.some(track => track.shift?.id === shift.id && !track.checkInOutPeriod.hasEnd);

            return (!isExpired && !isNotRelevantYet) || hasActiveTracks;
        })
        .map(shift => {
            const entries: DashboardShiftsWidgetShiftProps[] = shift.shiftPlannings
                .filter(shiftPlanning => shiftPlanning.planned && shiftPlanning.user.shouldTrackTime)
                .map(shiftPlanning => {
                    const shiftTrack = tracks.find(track => track.shift?.id === shift.id && track.owner.id === shiftPlanning.user.id);
                    const userAbsence = absences.find(absence => absence.user.id === shiftPlanning.user.id
                        && doesAbsenceOverlapWithPeriod(absence, shiftPlanning.period));

                    return transformShiftPlanningToWidgetShift(
                        shift,
                        shiftPlanning,
                        shiftTrack,
                        userAbsence,
                    );
                });

            return {
                date: shift.period.start,
                entries,
                id: shift.id,
            };
        });

    const unplannedTrackGroups = tracks
        // A track is unplanned if it has no shift
        .filter(track => !track.shift)
        // Filter out all tracks that have been ended longer than the threshold time
        .filter(track => !track.checkInOutPeriod.hasEnd || differenceInMinutes(now, track.checkInOutPeriod.end) < shiftExpiryThresholdInMinutes)
        .map(track => ({
            date: track.checkInOutPeriod.start,
            entries: [transformTrackToWidgetShift(track)],
            id: track.owner.id,
        }));

    const sortedGroups = [...shiftGroups, ...unplannedTrackGroups]
        .sort((a, b) => compareAsc(a.date, b.date));

    // Group all unplanned tracks together if they are next to each other.
    const reducedGroups = sortedGroups.reduce((total: DashboardShiftsWidgetGroup[], group) => {
        const previousGroup = total[total.length - 1];

        const groupFirstEntry = group?.entries[0];
        const previousGroupFirstEntry = previousGroup?.entries[0];

        if (previousGroupFirstEntry && (!groupFirstEntry.shiftPeriod && !previousGroupFirstEntry.shiftPeriod)) {
            const entries = [...previousGroup.entries, ...group.entries];
            const combinedGroup: DashboardShiftsWidgetGroup = {
                ...group,
                entries,
            };

            return [
                ...total.slice(0, total.length - 1),
                combinedGroup,
            ];
        }

        return [
            ...total,
            group,
        ];
    }, []);

    return reducedGroups;
};

export const getSelectedDepartmentsLabel = (selectedDepartmentsLength: number): string => {
    if (selectedDepartmentsLength === 0) {
        return trans('compositions.dashboardShiftsWidget.allDepartmentsSelected');
    }

    if (selectedDepartmentsLength === 1) {
        return trans('common.departmentSelected', { amount: String(selectedDepartmentsLength) });
    }

    return trans('common.departmentsSelected', { amount: String(selectedDepartmentsLength) });
};

export const transformDepartmentOptionsToDepartment = (
    departments: Department[],
    departmentOption: DepartmentOption,
): Department | undefined => departments.find(department => department.id === departmentOption.value);
