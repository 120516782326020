import { FC, ReactElement } from 'react';

import { ResetFiltersButton } from '../../../compositions';
import { resetFilters } from '../../../redux/@interface/globalFilters/globalFiltersReducer';
import { useTypedDispatch } from '../../../redux/store';

interface ConnectedResetFiltersButtonProps {
    className?: string;
}

const ConnectedResetFiltersButton: FC<ConnectedResetFiltersButtonProps> = ({ className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();

    const handleResetFilters = ():void => {
        dispatch(resetFilters());
    };

    return (
        <ResetFiltersButton onClick={handleResetFilters} className={className} />
    );
};

export default ConnectedResetFiltersButton;
