import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';

export type CompletePeriodsState = AsyncReduxState<{
    isLockPayrollPeriodSuccessful: boolean;
    isLockPayrollPeriodWeekSuccessful: boolean;
    isUnlockPayrollPeriodSuccessful: boolean;
    isUnlockPayrollPeriodWeekSuccessful: boolean;
    lastActivePeriodId?: string;
    lastUpdatedPayrollPeriod?: PayrollPeriod;
    openTasksInitialized: boolean;
}>;

const initialState: CompletePeriodsState = {
    isLockPayrollPeriodSuccessful: false,
    isLockPayrollPeriodWeekSuccessful: false,
    isUnlockPayrollPeriodSuccessful: false,
    isUnlockPayrollPeriodWeekSuccessful: false,
    openTasksInitialized: false,
    ...initialAsyncReduxState,
};

export const completePeriodsSlice = createSlice({
    name: 'completePeriodsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CompletePeriodsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CompletePeriodsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsLockPayrollPeriodSuccessful(state, action: PayloadAction<boolean>): CompletePeriodsState {
            return {
                ...state,
                isLockPayrollPeriodSuccessful: action.payload,
            };
        },
        setIsLockPayrollPeriodWeekSuccessful(state, action: PayloadAction<boolean>): CompletePeriodsState {
            return {
                ...state,
                isLockPayrollPeriodWeekSuccessful: action.payload,
            };
        },
        setIsUnlockPayrollPeriodSuccessful(state, action: PayloadAction<boolean>): CompletePeriodsState {
            return {
                ...state,
                isUnlockPayrollPeriodSuccessful: action.payload,
            };
        },
        setIsUnlockPayrollPeriodWeekSuccessful(state, action: PayloadAction<boolean>): CompletePeriodsState {
            return {
                ...state,
                isUnlockPayrollPeriodWeekSuccessful: action.payload,
            };
        },
        setLastActivePeriodId(state, action: PayloadAction<string | undefined>): CompletePeriodsState {
            return {
                ...state,
                lastActivePeriodId: action.payload,
            };
        },
        setLastUpdatedPayrollPeriod(state, action: PayloadAction<PayrollPeriod | undefined>): CompletePeriodsState {
            return {
                ...state,
                lastUpdatedPayrollPeriod: action.payload,
            };
        },
        setOpenTasksInitialized(state, action: PayloadAction<boolean>): CompletePeriodsState {
            return {
                ...state,
                openTasksInitialized: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsLockPayrollPeriodSuccessful,
    setIsLockPayrollPeriodWeekSuccessful,
    setIsUnlockPayrollPeriodSuccessful,
    setIsUnlockPayrollPeriodWeekSuccessful,
    setError,
    setLastActivePeriodId,
    setLastUpdatedPayrollPeriod,
    setOpenTasksInitialized,
} = completePeriodsSlice.actions;

export default completePeriodsSlice.reducer;
