import React, { FC, useMemo } from 'react';

import Tooltip from 'react-tooltip-lite';

import { translate } from '../../../helpers/translations/translator';
import { getCaoTooltipText } from '../helpers';

import './CaoBadge.scss';

interface CaoBadgeProps {
    userHasShiftPlanningInLast11h?: boolean;
    userAgeIsBelow16AndShiftIsAfter19h?: boolean;
    userAgeIsBelow18AndShiftIsAfter23h?: boolean;
}


const CaoBadge: FC<CaoBadgeProps> = ({
    userHasShiftPlanningInLast11h = false,
    userAgeIsBelow16AndShiftIsAfter19h = false,
    userAgeIsBelow18AndShiftIsAfter23h = false,
}) => {
    const translation = useMemo(
        () => getCaoTooltipText(userHasShiftPlanningInLast11h, userAgeIsBelow16AndShiftIsAfter19h, userAgeIsBelow18AndShiftIsAfter23h),
        [userHasShiftPlanningInLast11h, userAgeIsBelow16AndShiftIsAfter19h, userAgeIsBelow18AndShiftIsAfter23h],
    );
    const tooltip = (
        <>
            <strong>{`${translate('common.lookOut')}! `}</strong>
            {' '}
            {translation}
        </>
    );

    return (
        // @ts-ignore
        <Tooltip content={tooltip} arrowSize={6} padding={7}>
            <span
                id="cao-tooltip"
                className="badge-open badge-open-orange cao-badge"
            >
                <div className="cao-badge__icon">
                    !
                </div>
            </span>
        </Tooltip>
    );
};

export default CaoBadge;
