import { isFetchCollectionResultSuccessful, isFetchResultSuccessful } from '../../entities/FetchResult';
import { getShiftApiCall, getSimilarShiftsApiCall } from '../../entities/Shift/ShiftService';
import { ShiftPlanning } from '../../entities/ShiftPlanning/ShiftPlanning';
import { getShiftPlanningsWithShiftApiCall } from '../../entities/ShiftPlanning/ShiftPlanningService';
import { addHours } from '../../helpers/date';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoadingRecentShiftPlannings,
    setIsLoadingShift,
    setIsLoadingSimilarShifts,
    setRecentShiftPlannings,
    setShift,
    setSimilarShifts,
} from './shiftDetailReducer';

export const getShiftDetail = (shiftId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoadingShift(true));
    dispatch(setError(''));

    try {
        const response = await getShiftApiCall(shiftId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShift(response.data));
    } catch (error) {
        console.error('[getShiftDetail]', error);
    } finally {
        dispatch(setIsLoadingShift(false));
    }
};

export const getSimilarShifts = (shiftId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoadingSimilarShifts(true));
    dispatch(setError(''));

    try {
        const response = await getSimilarShiftsApiCall(shiftId);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setSimilarShifts(response.data));
    } catch (error) {
        console.error('[getSimilarShifts]', error);
    } finally {
        dispatch(setIsLoadingSimilarShifts(false));
    }
};

export const getRecentShiftPlannings = (date: Date, shiftPlannings: ShiftPlanning[]) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoadingRecentShiftPlannings(true));
    dispatch(setError(''));

    try {
        const userUuids = shiftPlannings
            .filter(shiftPlanning => shiftPlanning.planned)
            .map((shiftPlanning) => shiftPlanning.user.id);

        const response = await getShiftPlanningsWithShiftApiCall({
            startDate: addHours(date, -24),
            endDate: date,
            userUuids,
        });

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const recentShiftPlanningsExcludingCurrentShift = response.data.filter(recentShiftPlanning => (
            !shiftPlannings.some(shiftPlanning => shiftPlanning.id === recentShiftPlanning.id)
        ));

        dispatch(setRecentShiftPlannings(recentShiftPlanningsExcludingCurrentShift));
    } catch (error) {
        console.error('[getRecentShiftPlannings]', error);
    } finally {
        dispatch(setIsLoadingRecentShiftPlannings(false));
    }
};
