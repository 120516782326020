import React from 'react';

import PropTypes from 'prop-types';
import {
    Button, ModalBody,
    ModalFooter, ModalHeader,
} from 'reactstrap';

import { translate } from '../../../helpers/translations/translator';

import './Forms.scss';

const DeleteSpecialDay = ({ onCancel, onSubmit }) => (
    <div>
        <ModalHeader>
            {
                `${translate('pages.specialDays.specialDay')} ${translate('common.delete').toLowerCase()} `
            }
        </ModalHeader>
        <ModalBody>
            <p className="registration-intro">
                {translate('common.areYouSure')}
            </p>
        </ModalBody>
        <ModalFooter>
            <Button type="button" color="link" id="modal-close" onClick={onCancel}>{translate('common.cancel')}</Button>
            <Button type="submit" color="orange" onClick={onSubmit}>{translate('common.delete')}</Button>
        </ModalFooter>
    </div>
);

DeleteSpecialDay.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default DeleteSpecialDay;
