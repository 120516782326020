import {
    ShiftExchangeResource,
    ShiftExchangeViewModel,
    ShiftPlanningResource,
} from '../../models';
import transformShiftPlanningResource from '../ShiftPlanningService/transformShiftPlanningResource';

export default function transformShiftExchangeResource(
    shiftExchange: ShiftExchangeResource,
    shiftPlanning1: ShiftPlanningResource,
    shiftPlanning2: ShiftPlanningResource,
): ShiftExchangeViewModel {
    return {
        type: 'shift-exchanges',
        id: shiftExchange.id,
        shiftPlanning1: transformShiftPlanningResource(shiftPlanning1, shiftPlanning1.user, shiftPlanning1.shift),
        shiftPlanning2: transformShiftPlanningResource(shiftPlanning2, shiftPlanning2.user, shiftPlanning2.shift),
        createdAt: new Date(shiftExchange.createdAt),
    };
}
