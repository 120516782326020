// eslint-disable-next-line import/no-cycle
import { Absence } from './Absence';
import { LeaveOfAbsence } from './LeaveOfAbsence';
import { PreferToWorkTimeSlot } from './PreferToWorkTimeSlot';
// eslint-disable-next-line import/no-cycle
import { ShiftPlanning } from './ShiftPlanning';
import { UnavailableToWorkTimeSlot } from './UnavailableToWorkTimeSlot';
import { BasicUser, User } from './User';
import { EmploymentType } from './UserWithEmployment';

export interface UserWithAbsenceData extends User {
    userId: string;
    employmentType: EmploymentType | null;
    shiftPlanning: ShiftPlanning | null;
    leaveOfAbsenceRequest: LeaveOfAbsence | null;
    leaveOfAbsence: LeaveOfAbsence | null;
    absence: Absence | null;
    preferToWorkTimeSlot: PreferToWorkTimeSlot | null;
    unavailableWorkTimeSlot: UnavailableToWorkTimeSlot | null;
}

export interface BasicUserWithAbsenceData extends BasicUser {
    userId: string;
    shiftPlanning: ShiftPlanning | null;
    leaveOfAbsenceRequest: LeaveOfAbsence | null;
    leaveOfAbsence: LeaveOfAbsence | null;
    absence: Absence | null;
    unavailableWorkTimeSlot: UnavailableToWorkTimeSlot | null;
    preferToWorkTimeSlot: PreferToWorkTimeSlot | null;
}

export function transformBasicUserToBasicUserWithAbsenceData(
    user: BasicUser,
    shiftPlanning: ShiftPlanning | null = null,
    leaveOfAbsenceRequest: LeaveOfAbsence | null = null,
    leaveOfAbsence: LeaveOfAbsence | null = null,
    absence: Absence | null = null,
    unavailableWorkTimeSlot: UnavailableToWorkTimeSlot | null = null,
    preferToWorkTimeSlot: PreferToWorkTimeSlot | null = null,
) : BasicUserWithAbsenceData {
    return {
        type: 'users',
        id: user.id,
        userId: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        affix: user.affix,
        plannedHoursInWeek: user.plannedHoursInWeek || 0,
        plannedConceptHoursInWeek: user.plannedConceptHoursInWeek || 0,
        contractHours: user.contractHours || 0,
        isEligibleForTimeForTime: user.isEligibleForTimeForTime,
        experience: user.experience,
        person: user.person,
        departments: [],
        mainDepartment: null,
        loketEmployeeId: user.loketEmployeeId,
        employmentType: user.employmentType,
        shiftPlanning,
        leaveOfAbsenceRequest,
        leaveOfAbsence,
        absence,
        preferToWorkTimeSlot,
        unavailableWorkTimeSlot,
    };
}

export function transformUserToUserWithAbsenceData(
    user: User,
    shiftPlanning: ShiftPlanning | null = null,
    employmentType: EmploymentType | null = null,
    leaveOfAbsenceRequest: LeaveOfAbsence | null = null,
    leaveOfAbsence: LeaveOfAbsence | null = null,
    absence: Absence | null = null,
    unavailableWorkTimeSlot: UnavailableToWorkTimeSlot | null = null,
    preferToWorkTimeSlot: PreferToWorkTimeSlot | null = null,
) : UserWithAbsenceData {
    return {
        type: 'users',
        id: user.id,
        userId: user.id,
        employeeNumber: user.employeeNumber,
        affix: user.affix,
        firstname: user.firstname,
        lastname: user.lastname,
        picture: user.picture,
        phone: user.phone,
        email: user.email,
        birthday: user.birthday,
        shouldTrackTime: user.shouldTrackTime,
        contractHours: user.contractHours,
        status: user.status,
        registrationComplete: user.registrationComplete,
        loketEmployeeId: user.loketEmployeeId,
        isLoketAuthorized: user.isLoketAuthorized,
        departments: user.departments,
        roles: user.roles,
        experience: user.experience,
        person: user.person,
        plannedHoursInWeek: user.plannedHoursInWeek,
        employmentType,
        shiftPlanning,
        leaveOfAbsenceRequest,
        leaveOfAbsence,
        absence,
        unavailableWorkTimeSlot,
        preferToWorkTimeSlot,
    };
}
