import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { ConfirmForm } from '../../../containers';
import { getPersonFullName } from '../../../entities/Person/PersonHelpers';
import { UserProfile } from '../../../entities/User/User';
import trans from '../../../helpers/trans';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { editUserStatus } from '../../../redux/user/userActions';
import { setIsSetUserStatusSuccessful } from '../../../redux/user/userReducer';
import { ToastType } from '../../../types';
import { UserStatus } from '../../../types/userStatus';

interface ConnectedReactivateUserFormProps {
    user: UserProfile;
    onClose: () => void;
    className?: string;
}

const ConnectedReactivateUserForm: FC<ConnectedReactivateUserFormProps> = ({ user, onClose, className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();
    const { isLoading, isSetUserStatusSuccessful } = useTypedSelector(state => state.pacoUserReducer);

    const fullName = useMemo(() => getPersonFullName(user.person), [user]);

    const handleSubmit = (): void => {
        dispatch(editUserStatus(user, UserStatus.active));
    };

    useEffect((): void => {
        if (isSetUserStatusSuccessful) {
            dispatch(setIsSetUserStatusSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.reactivateUserForm.reactivateUserSuccess', { fullName }),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isSetUserStatusSuccessful]);

    return (
        <ConfirmForm
            isLoading={isLoading}
            intro={trans('containers.forms.reactivateUserForm.intro', { fullName })}
            submitText={trans('common.reactivate')}
            onCancel={onClose}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedReactivateUserForm;
