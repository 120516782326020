import { ReactElement } from 'react';

import { useToggle } from 'react-use';

import trans from '../../helpers/trans';
import { IconButton } from '../index';
import { LegendColor } from './subcomponents';
import { LegendColorProps } from './subcomponents/LegendColor/LegendColor';

import './Legend.scss';

interface LegendProps<T> {
    colors: LegendColorProps<T>[];
    buttonClassName?: string;
    className?: string;
}

const Legend = <T, >({
    colors,
    buttonClassName = '',
    className = '',
}: LegendProps<T>): ReactElement => {
    const [isOpen, toggleIsOpen] = useToggle(false);

    return (
        <div className={`paco-legend ${className}`}>
            <IconButton
                icon="eye"
                text={isOpen ? trans('compositions.legend.hideLegend') : trans('compositions.legend.showLegend')}
                onClick={toggleIsOpen}
                className={`paco-legend__button ${buttonClassName}`}
            />
            {isOpen && (
                <div className="paco-legend__legend">
                    <div className="paco-legend__title">
                        {trans('common.legend')}
                    </div>
                    <IconButton
                        hideLabel
                        icon="cross"
                        text={trans('compositions.legend.hideLegend')}
                        onClick={toggleIsOpen}
                        className="paco-legend__close-button"
                    />
                    {colors.map(color => (
                        <LegendColor
                            key={color.title}
                            title={color.title}
                            type={color.type}
                            className="paco-legend__color"
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default Legend;
