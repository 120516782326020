import { FC, ReactElement } from 'react';

import { UserContractHours } from '../../compositions';
import { useTypedSelector } from '../../redux/store';

interface ConnectedContractHoursProps {
    date?: Date;
    plannedHoursInWeek?: number;
    userId: string;
    className?: string;
}

const ConnectedUserContractHours: FC<ConnectedContractHoursProps> = ({
    date,
    plannedHoursInWeek,
    userId,
    className = '',
}): ReactElement => {
    const { userContractHours } = useTypedSelector(state => state.contractHoursReducer);

    return (
        <UserContractHours
            contractHours={userContractHours[userId] || []}
            date={date}
            plannedHoursInWeek={plannedHoursInWeek}
            className={className}
        />
    );
};

export default ConnectedUserContractHours;
