import React, { FC, useMemo } from 'react';

import classnames from 'classnames';

import { ElementWithTooltip } from '../../../../../../@paco/compositions';
import { compareAsc, isBefore, startOfDay } from '../../../../../../helpers/date';
import { translate } from '../../../../../../helpers/translations/translator';
import { ShiftDraggable } from '../../../../../../models';
import doesShiftFallOnWorkDay from '../../../../../../services/ShiftService/doesShiftFallOnWorkDay';
import CalendarButtonAdd from '../../../CalendarButtonAdd/CalendarButtonAdd';
import { ConceptDayProps } from '../../models/Day';
import DayContent from '../DayContent/DayContent';
import DayHeader from '../DayHeader/DayHeader';
import ShiftsList from '../ShiftsList/ShiftsList';

import './Day.scss';


interface DayProps extends ConceptDayProps {
    day: Date;
    shifts: ShiftDraggable[];
    showHeader?: boolean;
    isOutOfDragRange?: boolean;
}

const Day: FC<DayProps> = ({
    isOutOfDragRange,
    day,
    showDayInMonth,
    dayHeaderFormat,
    canEditShifts,
    canEditShiftConcepts,
    canEditBaseScheduleShifts,
    showEmploymentTypeBadge,
    showEmployees,
    showAddButton,
    showAddConceptButton,
    highlightShiftId,
    className = '',
    dayClassName,
    dayHeaderClassName = '',
    dayContentClassName,
    shiftClassName,
    dayButtonsClassName,
    dayContentHeaderLabelClassName,
    dayContentHeaderDayClassName,
    shifts,
    absences,
    leaveOfAbsences,
    unavailableTimeSlots,
    specialDays,
    showHeader,
    isMobile,
    onAddButtonClick,
    onAddBaseScheduleButtonClick,
    onShiftClick,
    onDayDrop,
    onDayDragEnter,
    onShiftStartDrag,
}) => {
    const isPast = useMemo(() => isBefore(day, startOfDay(new Date())), [day]);
    const classNames = classnames('day concept-day', {
        'concept-day--is-out-of-range': isOutOfDragRange,
        'concept-day--is-past': isPast,
        'concept-day--is-mobile': isMobile,
    }, [dayClassName]);

    const handleElementDrop = () => {
        onDayDrop(isPast ? undefined : day);
    };

    const handleElementDragEnter = () => {
        onDayDragEnter(isPast ? undefined : day);
    };

    const handleElementDragOver = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    const handleAddButtonClick = () => {
        onAddButtonClick(day);
    };

    const handleAddConceptButtonClick = () => {
        onAddBaseScheduleButtonClick(day);
    };

    const filteredShifts = useMemo(
        () => shifts
            .filter((shift) => doesShiftFallOnWorkDay(shift, day))
            .sort((a, b) => compareAsc(a.start, b.start)),
        [shifts, day],
    );

    return (
        <div
            className={classNames}
            onDragOver={handleElementDragOver}
            onDragEnter={handleElementDragEnter}
            onDrop={handleElementDrop}
        >
            {(isMobile || showHeader) && (
                <DayHeader
                    className={dayHeaderClassName}
                    day={day}
                    dayHeaderFormat={dayHeaderFormat}
                />
            )}
            <DayContent
                day={day}
                showDayInMonth={showDayInMonth}
                className={dayContentClassName}
                headerLabelClassName={dayContentHeaderLabelClassName}
                headerDayClassName={dayContentHeaderDayClassName}
                specialDays={specialDays}
            >
                <ShiftsList
                    className={className}
                    shiftClassName={shiftClassName}
                    shifts={filteredShifts}
                    leaveOfAbsences={leaveOfAbsences}
                    absences={absences}
                    unavailableTimeSlots={unavailableTimeSlots}
                    onShiftClick={onShiftClick}
                    onShiftStartDrag={onShiftStartDrag}
                    canEditShifts={canEditShifts}
                    canEditShiftConcepts={canEditShiftConcepts}
                    canEditBaseScheduleShifts={canEditBaseScheduleShifts}
                    showEmploymentTypeBadge={showEmploymentTypeBadge}
                    showEmployees={showEmployees}
                    highlightShiftId={highlightShiftId}
                />
                <div className={`concept-day__buttons ${dayButtonsClassName}`}>
                    {showAddButton && (
                        <CalendarButtonAdd
                            className="concept-day__button-add"
                            onClick={handleAddButtonClick}
                        />
                    )}
                    {showAddConceptButton && (
                        <ElementWithTooltip tooltipText={translate('pages.shifts.addBaseSchedule')} tooltipIsActive>
                            <CalendarButtonAdd
                                className="concept-day__button-add"
                                onClick={handleAddConceptButtonClick}
                                kind="plus-shift-concept"
                            />
                        </ElementWithTooltip>
                    )}
                </div>
            </DayContent>
        </div>
    );
};

export default Day;
