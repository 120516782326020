export const getUniqueArrayList = <T>(array: T[], key: string) => [...new Map(array.map((item: any) => [item[key], item])).values()];

export const swapArrayElements = <T>(array: T[], a: number, b: number) => {
    const copy = [...array];
    const firstElement = array[a];
    copy[a] = array[b];
    copy[b] = firstElement;

    return copy;
};

export const addArrayLengths = <T>(...arrays: T[][]): number => arrays
    .reduce((accumulator, currentValue) => accumulator + currentValue.flat().length, 0);

type PaginatedArray<T> = T[][];

export const paginateArray = <T>(array: T[], pageSize: number): PaginatedArray<T> => array.reduce((
    pages: PaginatedArray<T>,
    item: T,
    index: number,
) => {
    const pageIndex = Math.floor(index / pageSize);
    const currentPage = pages[pageIndex] ? [...pages[pageIndex], item] : [item];

    return [
        ...pages.slice(0, pageIndex),
        currentPage,
        ...pages.slice(pageIndex + 1),
    ];
}, []);
