// Source: https://usehooks.com

import { useEffect, useState } from 'react';

interface useWindowSizeReturnValues {
    height: number;
    width: number;
}

const useWindowSize = (): useWindowSizeReturnValues => {
    const isClient = typeof window === 'object';

    function getSize(): { height: number; width: number } {
        return {
            width: isClient ? window.innerWidth : 0,
            height: isClient ? window.innerHeight : 0,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect((): any => {
        if (!isClient) {
            return false;
        }

        function handleResize(): void {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);

        return (): void => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export default useWindowSize;
