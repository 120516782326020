import { FC, useMemo } from 'react';

import trans from '../../helpers/trans';
import { SelectOption, TimeModeType } from '../../types';
import { getTimeModeSelectOptions } from '../CalendarControls/helpers';
import { BasicSelect } from '../index';

import './TimeModeSelect.scss';

interface TimeModeSelectProps {
    value: TimeModeType;
    options: TimeModeType[];
    onChange?: (timeMode: TimeModeType) => void;
    className?: string;
}

const TimeModeSelect: FC<TimeModeSelectProps> = ({
    value,
    options,
    onChange,
    className = '',
}) => {
    const selectOptions = useMemo(() => getTimeModeSelectOptions(options), [options]);

    const handleTimeModeSelectChange = (newOption: SelectOption): void => {
        const newTimeModeType = options.find(option => option === newOption.value);
        if (newTimeModeType && onChange) {
            onChange(newTimeModeType);
        }
    };

    return (
        <div className={`time-mode-select ${className}`}>
            <div className="time-mode-select__label">
                {trans('compositions.calendarNavigation.view')}
            </div>
            <BasicSelect
                options={selectOptions}
                value={value}
                onChange={handleTimeModeSelectChange}
                className="time-mode-select__select"
            />
        </div>
    );
};

export default TimeModeSelect;
