import { IdentificationType, SelectOption } from '../../types';
import trans from '../trans';

export const transformIdentificationTypeToSelectOption = (identificationType: IdentificationType): SelectOption<IdentificationType> => {
    if (identificationType) {
        return {
            value: identificationType,
            label: trans(`types.identification.${identificationType}`),
        } as SelectOption<IdentificationType>;
    }

    return {
        value: IdentificationType.unknown,
        label: trans('types.identification.unknown'),
    } as SelectOption<IdentificationType>;
};
