import { FC, FormEvent, useState } from 'react';

import { FormActionButtons } from '../../../compositions';
import { DistrictDivisionGroupTemplate } from '../../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import { swapArrayElements } from '../../../helpers/array';
import trans from '../../../helpers/trans';
import { TemplateGroupButton } from './subcomponents';

import './ManageDistrictDivisionTemplateGroupsOrderForm.scss';

interface ManageDistrictDivisionTemplateGroupsOrderFormProps {
    isLoading: boolean;
    districtDivisionGroupTemplates: DistrictDivisionGroupTemplate[];
    onCancel: () => void;
    onSubmit: (groups: DistrictDivisionGroupTemplate[]) => void;
    className?: string;
}

const ManageDistrictDivisionTemplateGroupsOrderForm: FC<ManageDistrictDivisionTemplateGroupsOrderFormProps> = ({
    isLoading,
    districtDivisionGroupTemplates,
    onCancel,
    onSubmit,
    className = '',
}) => {
    const [activeDragGroupId, setActiveDragGroupId] = useState<string>();
    const [groups, setGroups] = useState(districtDivisionGroupTemplates);

    const handleGroupDragEnter = (groupId: string): void => {
        const groupIndex = groups.findIndex(group => group.id === groupId);
        const dragGroupIndex = groups.findIndex(group => group.id === activeDragGroupId);

        if (groupIndex === dragGroupIndex) {
            return;
        }

        const newGroups = swapArrayElements(groups, groupIndex, dragGroupIndex);

        setGroups(newGroups);
    };

    const handleGroupDragStart = (groupId: string): void => {
        setActiveDragGroupId(groupId);
    };

    const handleGroupDragEnd = (): void => {
        setGroups([...groups].map((group, index) => ({ ...group, order: index })));
        setActiveDragGroupId(undefined);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        onSubmit(groups);
    };

    return (
        <form className={`manage-district-division-template-groups-order-form ${className}`} onSubmit={handleSubmit}>
            <div className="manage-district-division-template-groups-order-form__row">
                <div className="manage-district-division-template-groups-order-form__intro">
                    {trans('containers.forms.manageDistrictDivisionTemplateGroupsOrderForm.intro')}
                </div>
            </div>
            <div className="manage-district-division-template-groups-order-form__row">
                <ul className="manage-district-division-template-groups-order-form__group-draggables-list">
                    {groups.map(group => (
                        <TemplateGroupButton
                            isActive={group.id === activeDragGroupId}
                            isDisabled={isLoading}
                            userIsDragging={!!activeDragGroupId}
                            group={group}
                            key={group.id}
                            onDragEnd={handleGroupDragEnd}
                            onDragEnter={handleGroupDragEnter}
                            onDragStart={handleGroupDragStart}
                            className="manage-district-division-template-groups-order-form__group-draggable"
                        />
                    ))}
                </ul>
            </div>
            <FormActionButtons
                isLoading={isLoading}
                submitText={trans('common.save')}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default ManageDistrictDivisionTemplateGroupsOrderForm;
