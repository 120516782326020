import { FC, ReactElement } from 'react';

import FilterBlock from '../../../../components/Filters/subcomponents/FilterBlock/FilterBlock';
import { Checkbox } from '../../../components';
import trans from '../../../helpers/trans';

import './ShowEmploymentTypesFilterContainer.scss';

interface ShowEmploymentTypesFilterContainerProps {
    showEmploymentTypes: boolean;
    onChange: (show: boolean) => void;
    className?: string;
}

const ShowEmploymentTypesFilterContainer: FC<ShowEmploymentTypesFilterContainerProps> = ({
    showEmploymentTypes,
    onChange,
    className = '',
}): ReactElement => (
    <FilterBlock
        title={trans('common.employmentType')}
        className="show-employment-types-filter-container"
    >
        <Checkbox
            label={trans('containers.filters.ShowEmploymentTypesFilterContainer')}
            checked={showEmploymentTypes}
            onChange={onChange}
            className={`show-employment-types-filter-container__check ${className}`}
        />
    </FilterBlock>
);

export default ShowEmploymentTypesFilterContainer;
