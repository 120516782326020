import { FC, ReactElement } from 'react';

import { KnowledgeBaseSearch } from '../../containers';
import { fetchDropboxFolders, fetchDropboxSearchFiles } from '../../redux/dropbox/dropboxActions';
import { useTypedDispatch } from '../../redux/store';

const ConnectedKnowledgeBaseSearch: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const onSearchSubmit = (search: string): void => {
        if (!search.length) {
            dispatch(fetchDropboxFolders());

            return;
        }

        dispatch(fetchDropboxSearchFiles(search));
    };

    return (
        <KnowledgeBaseSearch onSubmit={onSearchSubmit} />
    );
};

export default ConnectedKnowledgeBaseSearch;
