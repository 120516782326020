import React, { FC } from 'react';

import { connect, useDispatch } from 'react-redux';

import { Button } from '../../@paco/components';
import { TIME_MODES } from '../../constants';
import { translate } from '../../helpers/translations/translator';
import { setStartAndEndDate } from '../../redux/weekNavigator/weekNavigatorActions';
import WeekNavigator from '../WeekNavigator/WeekNavigator';
import { calculateEndDate, calculateStartDate } from './helpers';
import SingleDateNavigator from './subcomponents/SingleDateNavigator';

import './DoubleDateNavigator.scss';


interface DoubleWeekNavigatorProps {
    startDate: Date,
    endDate: Date,
    onDateChange: (selectedDate: Date, start: Date, end: Date) => void,
    onTodayClick: (date: Date) => void;
}

const DoubleDateNavigator: FC<DoubleWeekNavigatorProps> = ({
    startDate,
    endDate,
    onDateChange,
    onTodayClick,
}) => {
    const dispatch = useDispatch();
    const onStartDateChange = (selectedDate: Date) => {
        const justifiedEndDate = calculateEndDate(selectedDate, endDate);
        dispatch(setStartAndEndDate(selectedDate, selectedDate, justifiedEndDate));
        onDateChange(selectedDate, selectedDate, justifiedEndDate);
    };

    const onEndDateChange = (selectedDate: Date) => {
        const justifiedStartDate = calculateStartDate(startDate, selectedDate);
        dispatch(setStartAndEndDate(selectedDate, justifiedStartDate, selectedDate));
        onDateChange(selectedDate, justifiedStartDate, selectedDate);
    };

    const handleTodayButtonClick = (): void => {
        onTodayClick(new Date());
    };


    return (
        <>
            <div className="double-date-navigator">
                <Button
                    text="Naar vandaag"
                    onClick={handleTodayButtonClick}
                    className="double-date-navigator__today-button"
                />
                <SingleDateNavigator
                    type="startDate"
                    startDate={startDate}
                    endDate={endDate}
                    title={translate('common.startDate')}
                    onChange={onStartDateChange}
                />
                <SingleDateNavigator
                    type="endDate"
                    startDate={startDate}
                    endDate={endDate}
                    title={translate('common.endDate')}
                    onChange={onEndDateChange}
                />
            </div>
            <div className="double-date-navigator-mobile">
                <WeekNavigator
                    mode={TIME_MODES.WEEK}
                    afterDateChange={onDateChange}
                />
            </div>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        startDate: state.weekNavigatorReducer.startDate,
        endDate: state.weekNavigatorReducer.endDate,
        selectedDate: state.weekNavigatorReducer.selectedDate,
    };
}

export default connect(mapStateToProps)(DoubleDateNavigator);
