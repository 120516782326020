import React, { FC, useMemo } from 'react';

import Tooltip from 'react-tooltip-lite';

import { transformToPeriod } from '../../../../../../@paco/entities/Period/PeriodTransformers';
import { ShiftPlanningWithShift } from '../../../../../../@paco/entities/ShiftPlanning/ShiftPlanning';
import { CompanyMedicType } from '../../../../../../@paco/types';
import { getPreferToWorkTimeSlotComment } from '../../../../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlotHelpers';
import { getUserFullName } from '../../../../../../helpers';
import {
    Absence,
    LeaveOfAbsence,
    PreferToWorkTimeSlot,
    UnavailableToWorkTimeSlot,
    User,
} from '../../../../../../models';
import AvailabilityButton from '../../../../compositions/AvailabilityButton/AvailabilityButton';
import { getResourceDescription, getResourceStyleClass } from '../../helpers';

import './Availability.scss';

export type AvailabilityResourceTypes = LeaveOfAbsence | Absence | PreferToWorkTimeSlot
| UnavailableToWorkTimeSlot | ShiftPlanningWithShift;

interface Props {
    resource: AvailabilityResourceTypes;
    onClick: (resource: AvailabilityResourceTypes) => void;
    showName?: boolean;
    showDescription?: boolean;
}

const Availability: FC<Props> = ({
    resource,
    onClick,
    showName = false,
    showDescription = false,
}) => {
    const start = 'period' in resource ? resource.period.start : resource.start;
    const end = 'period' in resource ? resource.period.end : resource.end;

    const user = resource.user as unknown as User;
    const name = useMemo(() => getUserFullName(user), [user]);
    const isCompanyMedic = user.person?.isCompanyMedic === CompanyMedicType.yes;
    const type = useMemo(() => getResourceDescription(resource), [resource]);
    const modifier = useMemo(() => getResourceStyleClass(resource), [resource]);
    const period = useMemo(() => transformToPeriod(new Date(start), new Date(end || start), !!end), [resource]);
    const color = resource.type === 'shiftPlannings' ? resource.color : '';
    const comment = (resource.type === 'shiftPlannings' && resource.comments?.length) ? (
        resource.comments[resource.comments.length - 1]
    ) : undefined;

    const onButtonClick = () => {
        onClick(resource);
    };

    if (resource.type === 'PreferToWorkTimeSlots') {
        return (
            // @ts-ignore
            <Tooltip
                arrowSize={6}
                content={getPreferToWorkTimeSlotComment(resource as PreferToWorkTimeSlot)}
                padding={7}
                className="availability__tooltip-container"
            >
                <AvailabilityButton
                    isCompanyMedic={isCompanyMedic}
                    showDescription={showDescription}
                    showName={showName}
                    color={color}
                    type={type}
                    id={resource.id}
                    name={name}
                    modifier={modifier}
                    period={period}
                    onButtonClick={onButtonClick}
                />
            </Tooltip>
        );
    }

    return (
        <AvailabilityButton
            isCompanyMedic={isCompanyMedic}
            showName={showName}
            showDescription={showDescription}
            comment={comment?.body}
            color={color}
            type={type}
            id={resource.id}
            name={name}
            modifier={modifier}
            period={period}
            onButtonClick={onButtonClick}
        />
    );
};

export default Availability;
