import { FC, ReactElement } from 'react';

import classnames from 'classnames';

import { ButtonProps } from '../../../components/@buttons/Button/Button';
import trans from '../../../helpers/trans';
import { IconButton } from '../..';

import './LockButton.scss';

interface LockButtonProps extends Omit<ButtonProps, 'ref'> {
    isLocked?: boolean;
    className?: string;
}

const LockButton: FC<LockButtonProps> = ({
    isLocked = false,
    text,
    className = '',
    ...buttonProps
}): ReactElement => {
    const action = trans(`compositions.lockButton.${isLocked ? 'unlock' : 'lock'}`).toLowerCase();

    const buttonClassName = classnames('lock-button', {
        'lock-button--is-locked': isLocked,
    }, className);

    return (
        <IconButton
            {...buttonProps}
            iconPos="right"
            icon={isLocked ? 'lock-open' : 'lock'}
            text={`${text} ${action}`}
            className={buttonClassName}
            iconClassName="lock-button__icon"
        />
    );
};

export default LockButton;
