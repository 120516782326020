import * as Sentry from '@sentry/browser';

import { AuthenticatedUser } from '../../@paco/entities/User/User';

export const anonymiseEmail = (email: string): string => email
    .replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2');

export const setSentrySessionUser = (user: AuthenticatedUser) => {
    // TODO: Enable when this is approved by Mike A.
    // const username = getUserFullName(user).substr(0, 3);
    // const email = user.email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2');

    Sentry.configureScope((scope) => {
        scope.setUser({
            // username,
            // email,
            id: user.id,
        });
    });
};

export const clearSentrySessionUser = () => {
    Sentry.configureScope((scope) => {
        scope.setUser(null);
    });
};
