import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { BasicUser } from '../../entities/User/User';

export type UsersState = AsyncReduxState<{
    basicUsers: BasicUser[];
}>;

const initialState: UsersState = {
    ...initialAsyncReduxState,
    basicUsers: [],
};

export const usersSlice = createSlice({
    name: 'usersReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): UsersState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): UsersState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): UsersState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBasicUsers(state, action: PayloadAction<BasicUser[]>): UsersState {
            return {
                ...state,
                basicUsers: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setBasicUsers,
} = usersSlice.actions;

export default usersSlice.reducer;
