import { EditShiftPlanningTimeFormData } from '../../../models';
import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { CommentResource } from '../Comment/Comment';
import { transformToComment } from '../Comment/CommentTransformers';
import { DepartmentResource } from '../Department/Department';
import { transformToDepartment } from '../Department/DepartmentTransformers';
import { EmploymentTypeResource } from '../EmploymentType/EmploymentType';
import { transformToEmploymentType } from '../EmploymentType/EmploymentTypeTransformers';
import { ExperienceResource } from '../Experience/Experience';
import { transformToExperience } from '../Experience/ExperienceTransformers';
import { FetchResult, FetchResultType } from '../FetchResult';
import { transformToShiftPlanningShiftConcept } from '../Shift/ShiftTransformers';
import { ShiftConceptResource } from '../ShiftConcept/ShiftConcept';
import { ShiftPlanningWithShiftConcept } from '../ShiftPlanning/ShiftPlanning';
import { ShiftIndexUserResource, UserResource } from '../User/User';
import { getDummyUserResource } from '../User/UserHelpers';
import { transformToShiftPlanningUser } from '../User/UserTransformers';
import { ShiftConceptPlanningResource } from './ShiftConceptPlanning';
import { transformEditShiftPlanningTimeFormDataToEditShiftConceptPlanningRequest, transformToShiftPlanningWithShiftConcept } from './ShiftConceptPlanningTransformers';

export const editShiftConceptPlanningTimeApiCall = async (
    shiftPlanningFormData: EditShiftPlanningTimeFormData,
    shiftConceptPlanningId: string,
): Promise<FetchResult<ShiftPlanningWithShiftConcept, string>> => {
    const apiData = transformEditShiftPlanningTimeFormDataToEditShiftConceptPlanningRequest(shiftPlanningFormData, shiftConceptPlanningId);

    try {
        const includes = [
            'user',
            'user.employmentType',
            'user.experience',
            'shiftConcept',
            'shiftConcept.department',
            'comments',
        ];

        const url = generateApiUrl({
            endpoint: `/shift/concept/plannings/${shiftConceptPlanningId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ShiftConceptPlanningResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const userResource = getIncluded(doc, doc.data, 'user') as ShiftIndexUserResource;
        const shiftResource = getIncluded(doc, doc.data, 'shiftConcept') as ShiftConceptResource;
        const departmentResource = getIncluded(doc, shiftResource, 'department') as DepartmentResource;
        const commentsResource = (getMultipleIncluded(doc, userResource, 'comments') || []) as CommentResource[];
        const employmentTypeResource = getIncluded(doc, userResource, 'employmentType') as EmploymentTypeResource;
        const experienceResource = getIncluded(doc, userResource, 'experience') as ExperienceResource;

        // TODO: Missing resources bug. BE should fix this.
        if (!employmentTypeResource) {
            console.error(`[editShiftConceptPlanningTimeApiCall] Missing employmentType resource in user with id ${userResource.id} (${userResource.attributes.firstname})`);
        }

        if (!experienceResource) {
            console.error(`[editShiftConceptPlanningTimeApiCall] Missing experience resource in user with id ${userResource.id} (${userResource.attributes.firstname})`);
        }

        const department = transformToDepartment(departmentResource);
        const shift = transformToShiftPlanningShiftConcept(shiftResource, department);
        const employmentType = transformToEmploymentType(employmentTypeResource);
        const experience = transformToExperience(experienceResource);

        const shiftPlanningUser = transformToShiftPlanningUser(userResource, employmentType, experience);
        const comments = commentsResource.map(commentResource => {
            const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

            // TODO: Missing resources bug. BE should fix this.
            if (!commentOwnerResource) {
                console.error(`[editShiftConceptPlanningTimeApiCall] Missing owner resource in comment with id ${commentResource.id} (${commentResource.attributes.body})`);
            }

            return transformToComment(
                commentResource,
                commentOwnerResource || getDummyUserResource(`${commentResource.id}-user`),
            );
        });

        const shiftPlanning = transformToShiftPlanningWithShiftConcept(
            doc.data,
            shiftPlanningUser,
            shift,
            comments,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: shiftPlanning,
        };
    } catch (error) {
        console.error('[editShiftConceptPlanningTimeApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteShiftConceptPlanningApiCall = async (shiftConceptPlanningId: string): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/shift/concept/plannings/${shiftConceptPlanningId}` });

        const response = await authorizedFetch(url, { method: 'DELETE' });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[deleteShiftConceptPlanningTimeApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
