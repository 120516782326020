import React, { FC, ReactElement, useState } from 'react';

import { Modal } from 'reactstrap';

import { LoadingSpinner } from '../../../@paco/components';
import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { Permission } from '../../../@paco/entities/Permission/Permission';
import { Setting } from '../../../@paco/entities/Setting/Setting';
import { AuthenticatedUser } from '../../../@paco/entities/User/User';
import Card from '../../../components/Card/Card';
import OpenTracksTable from '../../../components/OpenTracksTable/OpenTracksTable';
import { EditTrackFormData } from '../../../entities/Track/Track';
import { translate } from '../../../helpers/translations/translator';
import { TrackViewModel } from '../../../models';
import EditTrack from '../../../pages/Tracks/forms/EditTrack';

import './CompletePeriodOpenTracksContainer.scss';

interface CompletePeriodOpenTracksContainerProps {
    isLoading: boolean;
    currentUser?: AuthenticatedUser;
    payrollPeriods: PayrollPeriod[];
    permissions: Permission[];
    settings: Setting[];
    openTracks: TrackViewModel[];
    onSubmitTrack: (formData: EditTrackFormData) => void;
    className?: string;
}

const CompletePeriodOpenTracksContainer: FC<CompletePeriodOpenTracksContainerProps> = ({
    isLoading,
    currentUser,
    payrollPeriods,
    permissions,
    settings,
    openTracks,
    onSubmitTrack,
    className = '',
}): ReactElement => {
    const [trackToEdit, setTrackToEdit] = useState<TrackViewModel>();

    const handleCancelEditTrack = (): void => {
        setTrackToEdit(undefined);
    };

    const handleSubmit = (formData: EditTrackFormData): void => {
        onSubmitTrack(formData);
        handleCancelEditTrack();
    };

    return (
        <div className={`complete-period-detail-open-tracks-container ${className}`}>
            <Card
                title={`${translate('pages.pz.openTracks')} (${openTracks.length})`}
                className="complete-period-detail-open-tracks-container__card"
            />

            {isLoading && (
                <LoadingSpinner isSmall className="complete-period-detail-open-tracks-container__loader" />
            )}

            {!isLoading && openTracks.length !== 0 && !!currentUser && (
                <OpenTracksTable
                    currentUser={currentUser}
                    permissions={permissions}
                    tracks={openTracks}
                    onStopClick={setTrackToEdit}
                    className="complete-period-detail-open-tracks-container__table"
                />
            )}

            {!!trackToEdit && (
                <Modal size="lg" isOpen className="form-edit-track">
                    <EditTrack
                        canEdit
                        settings={settings}
                        payrollPeriods={payrollPeriods}
                        title={translate('pages.tracks.editTrackAndApprove')}
                        onSubmit={handleSubmit}
                        onCancel={handleCancelEditTrack}
                        track={trackToEdit}
                    />
                </Modal>
            )}
        </div>
    );
};

export default CompletePeriodOpenTracksContainer;
