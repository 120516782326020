import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { UsersRoutes } from '../../@paco/routes/helpers';
import Users from './Users';

import './Management.scss';

const LegacyUsersRouteGroup = (props) => {
    const propsWithDefaults = {
        currentUser: {
            departments: null,
        },
        ...props,
    };

    return (
        <Routes>
            <Route path={`${UsersRoutes.users()}`} element={<Users {...propsWithDefaults} />} />
            <Route path="/" element={<Navigate to={UsersRoutes.users()} />} />
        </Routes>
    );
};

LegacyUsersRouteGroup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    sorting: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    employmentTypes: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    experiences: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    exportCSVRequest: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        loading: state.managementReducer.loading,
        filter: state.filterReducer.filter,
        currentUser: state.authenticatedUserReducer.user,
        users: state.managementReducer.users,
        sorting: state.managementReducer.sorting,
        page: state.usersOverviewReducer.page,
        pagination: state.paginationReducer,
        employmentTypes: state.appReducer.employmentTypes,
        roles: state.appReducer.roles,
        experiences: state.experiencesReducer.experiences,
        permissions: state.authenticatedUserReducer.permissions,
        payrollPeriods: state.appReducer.payrollPeriods,
        exportCSVRequest: state.managementReducer.exportCSVRequest,
    };
}

export default connect(mapStateToProps)(LegacyUsersRouteGroup);
