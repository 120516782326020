import React from 'react';

import PropTypes from 'prop-types';

import Icon from '../../style/Icon/Icon';

const Controls = (props) => {
    const { month = null, year = null } = props;

    return (
        <div className="date-controls date-controls-month">
            <button className="date-controls__button" type="button" onClick={() => props.onIncrement(false)}>
                <Icon kind="chevron_left" />
            </button>
            <button type="button" className="date-controls__button-picker" onClick={props.toggleDayPicker && props.toggleDayPicker}>
                {`${month || ''} ${year}`}
            </button>
            <button className="date-controls__button" type="button" onClick={() => props.onIncrement(true)}>
                <Icon kind="chevron_right" />
            </button>
        </div>
    );
};

Controls.propTypes = {
    month: PropTypes.string,
    year: PropTypes.number.isRequired,
    onIncrement: PropTypes.func.isRequired,
    toggleDayPicker: PropTypes.func,
};

export default Controls;
