import { ReactElement } from 'react';

import { LeaveOfAbsencesOverviewUsers } from '../../containers';
import { useTypedSelector } from '../../redux/store';

const ConnectedLeaveOfAbsencesOverviewUsers = (): ReactElement => {
    const { isLoading: isUsersLoading, basicUsers: users } = useTypedSelector(state => state.pacoUsersReducer);
    const { isLoading: isLeaveOfAbsencesLoading } = useTypedSelector(state => state.pacoLeaveOfAbsencesReducer);

    return (
        <LeaveOfAbsencesOverviewUsers
            isLoading={isUsersLoading || isLeaveOfAbsencesLoading}
            users={users}
        />
    );
};

export default ConnectedLeaveOfAbsencesOverviewUsers;
