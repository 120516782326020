import { DepartmentViewModel } from '../../../entities/Departments/Departments';
import { FormOption } from '../../types';
import { DepartmentGroupResource } from '../DepartmentGroup/DepartmentGroup';
import {
    transformDepartmentGroupV3ResourceToDepartmentGroup,
    transformLegacyDepartmentGroupToDepartmentGroup,
    transformToDepartmentGroup,
} from '../DepartmentGroup/DepartmentGroupTransformers';
import {
    Department,
    DepartmentGroupDepartment,
    DepartmentResource,
    DepartmentV3Resource,
} from './Department';

export const transformToDepartment = (department: DepartmentResource, departmentGroup?: DepartmentGroupResource): Department => ({
    id: department.id,
    name: department.attributes.name,
    deletedAt: department.attributes.deletedAt ? new Date(department.attributes.deletedAt) : undefined,
    departmentGroup: departmentGroup ? transformToDepartmentGroup(departmentGroup) : undefined,
});

export const transformLegacyDepartmentToDepartment = (department: DepartmentViewModel): Department => ({
    id: department.id,
    name: department.name,
    deletedAt: department.deletedAt ? new Date(department.deletedAt) : undefined,
    departmentGroup: department.group ? transformLegacyDepartmentGroupToDepartmentGroup(department.group) : undefined,
});

export const transformV3ResourceToDepartment = (resource: DepartmentV3Resource): Department => ({
    id: resource.id,
    name: resource.name,
    deletedAt: resource.deletedAt ? new Date(resource.deletedAt) : undefined,
    departmentGroup: resource.group ? transformDepartmentGroupV3ResourceToDepartmentGroup(resource.group) : undefined,
});

export const transformDepartmentToFormOption = (department: Department): FormOption => ({
    label: department.name,
    value: department.id,
    group: department.departmentGroup
        ? { label: department.departmentGroup.name, value: department.departmentGroup.id }
        : undefined,
});

export const transformDepartmentToDepartmentGroupDepartment = (department: Department): DepartmentGroupDepartment => ({
    id: department.id,
    name: department.name,
    deletedAt: department.deletedAt,
});
