import { FC, ReactElement } from 'react';

import classnames from 'classnames';

import { IconButton } from '../../../index';

import './SidebarToggleButton.scss';

interface SidebarToggleButtonProps {
    isOpened?: boolean;
    onClick: () => void;
    className?: string;
}

const SidebarToggleButton: FC<SidebarToggleButtonProps> = ({ isOpened = false, onClick, className = '' }): ReactElement => {
    const sidebarToggleButtonClassname = classnames('sidebar-toggle-button', {
        'sidebar-toggle-button--is-opened': isOpened,
    }, className);

    const sidebarToggleButtonIconClassname = classnames('sidebar-toggle-button__icon', {
        'sidebar-toggle-button__icon--is-opened': isOpened,
    });

    return (
        <IconButton
            text="close"
            icon={isOpened ? 'chevron-left' : 'filter'}
            hideLabel
            onClick={onClick}
            className={sidebarToggleButtonClassname}
            iconClassName={sidebarToggleButtonIconClassname}
        />
    );
};

export default SidebarToggleButton;
