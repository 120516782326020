import { FC, ReactElement, useMemo } from 'react';

import classNames from 'classnames';

import { LoadingSpinner } from '../../components';
import { EventLog } from '../../entities/EventLog/EventLog';
import { groupLogsByDate } from './helpers';
import { GroupedEntityEvents } from './subcomponents';

import './EntityEventLogs.scss';

interface EntityEventLogsProps {
    isLoading: boolean;
    logs: EventLog[];
    className?: string;
}

const EntityEventLogs: FC<EntityEventLogsProps> = ({ isLoading, logs, className = '' }): ReactElement => {
    const logsClassName = classNames('entity-event-logs', {
        'entity-event-logs--is-loading': isLoading,
    }, className);

    const groupedLogs = useMemo(() => groupLogsByDate(logs), [logs]);

    if (isLoading) {
        return (
            <div className={logsClassName}>
                <LoadingSpinner className="entity-event-logs__loader" />
            </div>
        );
    }

    return (
        <div className={logsClassName}>
            <div className="entity-event-logs__list">
                {Object.keys(groupedLogs).map(date => (
                    <li
                        key={date}
                        className="entity-event-logs__list-item"
                    >
                        <GroupedEntityEvents title={date} logs={groupedLogs[date]} />
                    </li>
                ))}
            </div>
        </div>
    );
};

export default EntityEventLogs;
