import { getDay } from 'date-fns';

import {
    addDays,
    compareTimeStrings,
    convertDateToApiParamDateTime,
    formatDate,
} from '../../helpers/date';
import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import legacyParse from '../../helpers/legacyDateParser';
import { EditShiftFormData, ShiftType } from '../../models';
import compareShiftPlanningUsersAlphabetically from '../../services/ShiftPlanningService/compareShiftPlanningUsersAlphabetically';
import transformShiftPlanningResource from '../../services/ShiftPlanningService/transformShiftPlanningResource';
import { transformDepartmentResource } from '../Departments/DepartmentsTransformers';
import {
    AddBaseScheduleShiftRequest,
    BaseScheduleShiftFormData,
    BaseScheduleShiftResource,
    BaseScheduleShiftViewModel,
    CopyBaseScheduleShiftRequest,
    EditBaseScheduleShiftRequest,
} from './BaseScheduleShift';

const getShiftStartAndEndDate = (startTime: string, endTime: string, date: Date): [Date, Date] => {
    const endTimeIsLater = compareTimeStrings(startTime, endTime);
    const startDate = formatDate(date, 'yyyy-MM-dd');
    const endDate = formatDate(addDays(date, endTimeIsLater ? 1 : 0), 'yyyy-MM-dd');
    const start = legacyParse(`${startDate} ${startTime}:00`);
    const end = legacyParse(`${endDate} ${endTime}:00`);

    return [start, end];
};

const getWeekday = (startDate: Date): number => {
    const weekday = getDay(startDate) || 7;

    return weekday;
};

export const transformToBaseScheduleShift = (baseScheduleResource: BaseScheduleShiftResource): BaseScheduleShiftViewModel => {
    const start = new Date(baseScheduleResource.start);
    const weekday = baseScheduleResource.weekday - 1;

    return {
        type: ShiftType.baseSchedule,
        id: baseScheduleResource.id,
        start,
        end: new UpToButExcludingDate(baseScheduleResource.end).transformToUpToAndIncludingDate(),
        weekday,
        peopleLimit: baseScheduleResource.peopleLimit,
        department: baseScheduleResource.department ? transformDepartmentResource(baseScheduleResource.department) : undefined,
        shiftPlannings: (baseScheduleResource.baseScheduleShiftPlannings || [])
            .map((shiftPlanning) => transformShiftPlanningResource(
                shiftPlanning,
                shiftPlanning.user,
            )).sort(compareShiftPlanningUsersAlphabetically),
        baseScheduleId: baseScheduleResource.baseSchedule?.id,
    };
};

export const transformEditBaseScheduleShiftFormData = (formData: EditShiftFormData, baseScheduleShiftId: string): EditBaseScheduleShiftRequest => {
    const [start, end] = getShiftStartAndEndDate(formData.startTime, formData.endTime, formData.date);
    const weekday = getWeekday(start);

    return {
        type: 'baseScheduleShifts',
        id: baseScheduleShiftId,
        attributes: {
            start: convertDateToApiParamDateTime(start),
            end: convertDateToApiParamDateTime(end),
            peopleLimit: formData.peopleLimit,
            weekday,
        },
        relationships: {
            department: {
                data: {
                    type: 'departments',
                    id: formData.department,
                },
            },
        },
    };
};

export const transformBaseScheduleShiftToRequest = (baseScheduleShift: BaseScheduleShiftViewModel): CopyBaseScheduleShiftRequest => {
    const baseScheduleShiftWeekday = baseScheduleShift.weekday + 1;

    return {
        type: 'baseScheduleShifts',
        attributes: {
            start: convertDateToApiParamDateTime(baseScheduleShift.start),
            end: convertDateToApiParamDateTime(baseScheduleShift.end.transformToAPIDatetime()),
            peopleLimit: baseScheduleShift.peopleLimit || 0,
            weekday: baseScheduleShiftWeekday > 0 ? baseScheduleShiftWeekday : 1,
        },
        relationships: {
            baseSchedule: {
                data: {
                    type: 'baseSchedules',
                    id: baseScheduleShift.baseScheduleId || '',
                },
            },
            department: {
                data: {
                    type: 'departments',
                    id: baseScheduleShift.department?.id || '',
                },
            },
        },
    };
};

export const transformAddBaseScheduleShiftFormData = (formData: BaseScheduleShiftFormData, baseScheduleId: string): AddBaseScheduleShiftRequest => {
    const [start, end] = getShiftStartAndEndDate(formData.startTime, formData.endTime, formData.date);
    const weekday = getWeekday(start);

    return {
        type: 'baseScheduleShifts',
        attributes: {
            start: convertDateToApiParamDateTime(start),
            end: convertDateToApiParamDateTime(end),
            peopleLimit: formData.peopleLimit || 0,
            weekday,
        },
        relationships: {
            baseSchedule: {
                data: {
                    type: 'baseSchedules',
                    id: baseScheduleId,
                },
            },
            department: {
                data: {
                    type: 'departments',
                    id: formData.department || '',
                },
            },
        },
    };
};
