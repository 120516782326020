import React, { FC, useMemo } from 'react';

import { TemporaryWorker } from '../../../../../../@paco/entities/TemporaryWorker/TemporaryWorker';
import { ShiftDraggable } from '../../../../../../models';
import { EmploymentTypeSlug } from '../../../../../../models/EmploymentType';
import { getTemporaryWorkerDeviatedTime } from '../../../../../../services/TemporaryWorkerService/getTemporaryWorkerDeviatedTime';
import getTemporaryWorkerIcons from '../../helpers/getTemporaryWorkerIcons';
import ShiftEmployee from '../ShiftEmployee/ShiftEmployee';

interface ShiftTemporaryWorkerProps {
    showEmploymentTypeBadge?: boolean;
    temporaryWorker: TemporaryWorker;
    shift?: ShiftDraggable;
    className?: string;
    employmentTypeClassName?: string;
    iconClassName?: string;
}

const ShiftTemporaryWorker: FC<ShiftTemporaryWorkerProps> = ({
    showEmploymentTypeBadge = false,
    temporaryWorker,
    shift,
    className,
    employmentTypeClassName,
    iconClassName,
}) => {
    const deviatedTime = useMemo(
        () => getTemporaryWorkerDeviatedTime(temporaryWorker, shift),
        [shift, temporaryWorker],
    );

    const icons = useMemo(
        () => getTemporaryWorkerIcons(temporaryWorker.comments),
        [temporaryWorker],
    );

    return (
        <ShiftEmployee
            showEmploymentTypeBadge={showEmploymentTypeBadge}
            icons={icons}
            name={temporaryWorker.name}
            employmentTypeSlug={EmploymentTypeSlug.temporaryWorker}
            deviatedTime={deviatedTime}
            color={temporaryWorker.color || undefined}
            className={className}
            employmentTypeClassName={employmentTypeClassName}
            iconClassName={iconClassName}
        />
    );
};

export default ShiftTemporaryWorker;
