import { areIntervalsOverlapping, compareAsc } from '../../../helpers/date';
import transformTimeStringToNumber from '../../../helpers/date/transformTimeStringToNumber';
import {
    addDays,
    addHours,
    addSeconds,
    eachDayOfInterval,
    formatDate,
    getEndOfWorkDay,
    getStartOfWorkDay,
    isBefore,
} from '../../helpers/date';
import trans from '../../helpers/trans';
import { DateFormat } from '../../types/dateFormatTypes';
import { PeriodDay } from '../PeriodDay/PeriodDay';
import { Period } from './Period';

export const hasDateFourHoursOrLess = (date: Date): boolean => (date.getHours() < 4) || (date.getHours() === 4 && date.getMinutes() === 0);
const isEndDateOnTheSameDayAsStartDate = (startDate: Date, endDate: Date): boolean => (startDate.toDateString() === endDate.toDateString())
    || (startDate.toDateString() === addDays(endDate, -1).toDateString() && hasDateFourHoursOrLess(endDate));
const noEndDateLabel = (start: Date, dateFormat: DateFormat) => `${trans('common.from')} ${formatDate(start, dateFormat)}`;

export const getDateLabel = (
    start: Date,
    end: Date,
    hasEnd?: boolean,
    dateFormat = DateFormat.dateLabel,
): string => {
    if (hasEnd === false) {
        return noEndDateLabel(start, dateFormat);
    }

    if (isEndDateOnTheSameDayAsStartDate(start, end)) {
        return `${formatDate(start, dateFormat)}`;
    }

    return `${formatDate(start, dateFormat)} ${trans('common.until')} ${formatDate(end, dateFormat)}`;
};

export const getDateLabelShort = (start: Date, end: Date, hasEnd?: boolean): string => getDateLabel(start, end, hasEnd, DateFormat.dateLabelShort);

export const getTimeLabel = (start: Date, end: Date, hasEnd?: boolean): string => {
    if (hasEnd === false) {
        return noEndDateLabel(start, DateFormat.timeLabel);
    }

    if (isEndDateOnTheSameDayAsStartDate(start, end)) {
        return `${formatDate(start, DateFormat.timeLabel)}`;
    }

    return `${formatDate(start, DateFormat.timeLabel)} - ${formatDate(end, DateFormat.timeLabel)}`;
};

export const getTimeLabelShort = (start: Date, end: Date, hasEnd?: boolean): string => {
    if (hasEnd === false) {
        return noEndDateLabel(start, DateFormat.timeLabelShort);
    }

    if (isEndDateOnTheSameDayAsStartDate(start, end)) {
        return `${formatDate(start, DateFormat.timeLabelShort)}`;
    }

    return `${formatDate(start, DateFormat.timeLabelShort)} - ${formatDate(end, DateFormat.timeLabelShort)}`;
};

export const getTimeOnlyLabel = (start: Date, end: Date): string => `${formatDate(start, DateFormat.hoursMinutes)} - ${formatDate(end, DateFormat.hoursMinutes)}`;

const isTimeLabelBeforeFourHours = (timeLabel: string): boolean => {
    const startTimeString = `${timeLabel}:00`;

    return transformTimeStringToNumber(startTimeString) < 4;
};

const isTimeLabelBeforeOrEqualToFourHours = (timeLabel: string): boolean => {
    const startTimeString = `${timeLabel}:00`;

    return transformTimeStringToNumber(startTimeString) <= 4;
};

export const getDays = (start: Date, end: Date): PeriodDay[] => {
    const justifiedStartDate = addHours(start, -4);
    const justifiedEndDate = addHours(end, -4);
    const days = eachDayOfInterval({ start: justifiedStartDate, end: justifiedEndDate });
    const startTimeLabel = formatDate(start, 'HH:mm');
    const endTimeLabel = formatDate(end, 'HH:mm');

    if (isEndDateOnTheSameDayAsStartDate(start, end) && days.length > 1) {
        days.pop();
    }

    return days.reduce((periodDays: PeriodDay[], day) => {
        const valkDay = addHours(day, 4);
        let periodStartLabel = formatDate(valkDay, DateFormat.dateWithHoursMinutes);
        let periodEndLabel = formatDate(addDays(valkDay, 1), DateFormat.dateWithHoursMinutes);
        let label = trans('common.wholeDay');

        // First element
        if (day === days[0]) {
            periodStartLabel = `${formatDate(day, DateFormat.date)} ${startTimeLabel}`;
            if (isTimeLabelBeforeFourHours(startTimeLabel)) {
                periodStartLabel = `${formatDate(addDays(day, 1), DateFormat.date)} ${startTimeLabel}`;
            }
        }

        // Last element
        const lastDay = days[days.length - 1];
        if (day === lastDay) {
            periodEndLabel = `${formatDate(day, DateFormat.date)} ${endTimeLabel}`;
            if (isTimeLabelBeforeOrEqualToFourHours(endTimeLabel)) {
                periodEndLabel = `${formatDate(addDays(day, 1), DateFormat.date)} ${endTimeLabel}`;
            }
        }

        // Get hours from start/end labels
        if (periodStartLabel.slice(-5) !== periodEndLabel.slice(-5)) {
            label = `${periodStartLabel.slice(-5)} - ${periodEndLabel.slice(-5)}`;
        }

        return [
            ...periodDays,
            {
                start: periodStartLabel,
                end: periodEndLabel,
                label,
            },
        ];
    }, []);
};

export const arePeriodsDeviated = (period1: Period, period2: Period): boolean => (compareAsc(period1.start, period2.start) !== 0
        || compareAsc(period1.end, period2.end) !== 0);

// It is technically possible to have a period with a start date that is after the end date.
// This should of course not happen but it is possible. This function is to prevent areIntervalsOverlapping from throwing an error.
const sanitizePeriod = (period: Period): Period => {
    if (isBefore(period.end, period.start)) {
        return {
            start: period.end,
            end: period.start,
        };
    }

    return period;
};

export const doesDateOverlapWithPeriod = (date: Date, period: Period): boolean => {
    const sanitizedPeriod = sanitizePeriod(period);

    let snavie = false;

    try {
        snavie = areIntervalsOverlapping(
            {
                start: date,
                end: addSeconds(date, 1),
            },
            {
                start: sanitizedPeriod.start,
                end: sanitizedPeriod.end,
            },
        );
    } catch (error) {
        console.error('Error in doesDateOverlapWithPeriod', date, period, sanitizedPeriod);
    }

    return snavie;
};

export const doPeriodsOverlap = (period1: Period, period2: Period): boolean => {
    const sanitizedPeriod1 = sanitizePeriod(period1);
    const sanitizedPeriod2 = sanitizePeriod(period2);

    return (
        areIntervalsOverlapping(
            {
                start: sanitizedPeriod1.start,
                end: sanitizedPeriod1.end,
            },
            {
                start: sanitizedPeriod2.start,
                end: sanitizedPeriod2.end,
            },
        )
    );
};

export const doesPeriodOverlapWithWorkday = (day: Date, period: Period): boolean => {
    const sanitizedPeriod = sanitizePeriod(period);

    let snavie = false;

    try {
        snavie = areIntervalsOverlapping(
            {
                start: getStartOfWorkDay(day),
                end: getEndOfWorkDay(day),
            },
            {
                start: sanitizedPeriod.start,
                end: sanitizedPeriod.end,
            },
        );
    } catch (error) {
        console.error('Error in doesDateOverlapWithPeriod', day, period, sanitizedPeriod);
    }

    return snavie;
};

export const someOverlappingWorkdaysWithPeriod = (workdays: Date[], period: Period): boolean => workdays
    .some(workday => doesPeriodOverlapWithWorkday(workday, period));

export const getOverlappingWorkdaysWithPeriod = (workdays: Date[], period: Period): number => workdays
    .reduce((total, workday) => {
        const isOverlap = doesPeriodOverlapWithWorkday(workday, period);

        return total + (isOverlap ? 1 : 0);
    }, 0);

export const isSamePeriod = (period1: Period, period2: Period): boolean => (
    compareAsc(period1.start, period2.start) === 0 && compareAsc(period1.end, period2.end) === 0
);
