import { PublishShiftConceptsFormData } from '../../../../../entities/ShiftConcept/ShiftConcept';
import { compareAsc } from '../../../../../helpers/date';
import { translate } from '../../../../../helpers/translations/translator';


export interface ValidationErrors {
    date?: string;
    departments?: string;
    notifyUsers?: boolean;
    notificationTitle?: string;
    notificationBody?: string;
}

const validateFormData = (
    formData: PublishShiftConceptsFormData,
    notifyUsers = false,
    isSubmitted = true,
): ValidationErrors => {
    const errors: ValidationErrors = {};

    if (compareAsc(formData.endDate, formData.startDate) === -1) {
        errors.date = translate('pages.management.endDateNeedsToBeLaterThanStartDate');
    }

    if (!isSubmitted) {
        return errors;
    }

    if (!formData.departments.length) {
        errors.departments = translate('validation.isRequired', {
            item: translate('common.departments'),
        });
    }

    if (notifyUsers && !formData.notificationTitle) {
        errors.notificationTitle = translate('validation.isRequired', {
            item: translate('common.title'),
        });
    }

    if (notifyUsers && !formData.notificationBody) {
        errors.notificationBody = translate('validation.isRequired', {
            item: translate('common.message'),
        });
    }

    return errors;
};

export default validateFormData;
