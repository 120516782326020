import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { DropboxEntity, DropboxFolder } from '../../entities/DropboxEntity/DropboxEntity';

export enum DropboxResultType {
    search = 'search',
    folder = 'folder',
}

export type DropboxState = AsyncReduxState<{
    isDownloadFailed: boolean;
    isFoldersLoading: boolean;
    isSearchLoading: boolean;
    activeDirectoryId?: string;
    entities: DropboxEntity[];
    folders: DropboxFolder[];
    activeResultType: DropboxResultType;
    searchFiles: DropboxEntity[];
}>;

const initialState: DropboxState = {
    ...initialAsyncReduxState,
    isDownloadFailed: false,
    isFoldersLoading: false,
    isSearchLoading: false,
    entities: [],
    folders: [],
    activeResultType: DropboxResultType.folder,
    searchFiles: [],
};

export const DropboxSlice = createSlice({
    name: 'dropboxReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DropboxState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsDownloadFailed(state, action: PayloadAction<boolean>): DropboxState {
            return {
                ...state,
                isDownloadFailed: action.payload,
            };
        },
        setIsFoldersLoading(state, action: PayloadAction<boolean>): DropboxState {
            return {
                ...state,
                isFoldersLoading: action.payload,
            };
        },
        setIsSearchLoading(state, action: PayloadAction<boolean>): DropboxState {
            return {
                ...state,
                isSearchLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DropboxState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDropboxEntities(state, action: PayloadAction<DropboxEntity[]>): DropboxState {
            return {
                ...state,
                entities: action.payload,
            };
        },
        setDropboxFolders(state, action: PayloadAction<DropboxFolder[]>): DropboxState {
            return {
                ...state,
                folders: action.payload,
            };
        },
        setDropboxSearchFiles(state, action: PayloadAction<DropboxEntity[]>): DropboxState {
            return {
                ...state,
                searchFiles: action.payload,
            };
        },
        setActiveResultType(state, action: PayloadAction<DropboxResultType>): DropboxState {
            return {
                ...state,
                activeResultType: action.payload,
            };
        },
        setActiveDirectoryId(state, action: PayloadAction<string | undefined>): DropboxState {
            return {
                ...state,
                activeDirectoryId: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsDownloadFailed,
    setIsFoldersLoading,
    setIsSearchLoading,
    setActiveDirectoryId,
    setError,
    setDropboxEntities,
    setDropboxFolders,
    setDropboxSearchFiles,
    setActiveResultType,
} = DropboxSlice.actions;

export default DropboxSlice.reducer;
