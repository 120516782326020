import { CommentResource as LegacyCommentResource, TemporaryWorkerResource as LegacyTemporaryWorkerResource } from '../../../models';
import { formatDate } from '../../helpers/date';
import { ShiftPlanningColor } from '../../types';
import { DateFormat } from '../../types/dateFormatTypes';
import { Comment } from '../Comment/Comment';
import { Period } from '../Period/Period';
import { transformToPeriod } from '../Period/PeriodTransformers';
import {
    AddTemporaryConceptWorkerRequest,
    AddTemporaryWorkerRequest,
    EditColorTemporaryConceptWorkerRequest,
    EditColorTemporaryWorkerRequest,
    EditTemporaryConceptWorkerRequest,
    EditTemporaryWorkerFormData,
    EditTemporaryWorkerRequest,
    TemporaryWorker,
    TemporaryWorkerResource,
} from './TemporaryWorker';

export const transformToTemporaryWorker = (
    resource: TemporaryWorkerResource,
    fallbackPeriod: Period,
    comments: Comment[] = [],
): TemporaryWorker => {
    const { start, end } = resource.attributes;
    const period = (start && end) ? transformToPeriod(new Date(start), new Date(end)) : fallbackPeriod;

    return ({
        id: resource.id,
        type: resource.type,
        name: resource.attributes.name,
        color: resource.attributes.color,
        period,
        comments,
    });
};

export const transformLegacyTemporaryConceptWorker = (
    legacyTemporaryWorkerResource: LegacyTemporaryWorkerResource,
    legacyCommentResources: LegacyCommentResource[] = [],
): TemporaryWorkerResource => ({
    id: legacyTemporaryWorkerResource.id,
    type: legacyTemporaryWorkerResource.type,
    attributes: {
        start: legacyTemporaryWorkerResource.start,
        end: legacyTemporaryWorkerResource.end,
        name: legacyTemporaryWorkerResource.name,
        color: legacyTemporaryWorkerResource.color as ShiftPlanningColor,
    },
    relationships: {
        comments: {
            data: {
                ...legacyCommentResources.map(legacyCommentResource => ({
                    type: 'comments',
                    id: legacyCommentResource.id,
                })),
            },
        },
    },
});

export const transformAddTemporaryWorkerFormDataToRequest = (
    shiftId: string,
    name: string,
): AddTemporaryWorkerRequest => ({
    type: 'temporaryWorkers',
    attributes: {
        name,
    },
    relationships: {
        shift: {
            data: {
                type: 'shifts',
                id: shiftId,
            },
        },
    },
});

export const transformAddTemporaryConceptWorkerFormDataToRequest = (
    shiftConceptId: string,
    name: string,
): AddTemporaryConceptWorkerRequest => ({
    type: 'temporaryConceptWorkers',
    attributes: {
        name,
    },
    relationships: {
        shiftConcept: {
            data: {
                type: 'shiftConcepts',
                id: shiftConceptId,
            },
        },
    },
});

export const transformEditColorTemporaryWorkerFormDataToRequest = (color?: string): EditColorTemporaryWorkerRequest => ({
    type: 'temporaryWorkers',
    attributes: {
        color: color || null,
    },
});

export const transformEditColorTemporaryConceptWorkerFormDataToRequest = (
    temporaryConceptWorkerId: string,
    color?: string,
): EditColorTemporaryConceptWorkerRequest => ({
    id: temporaryConceptWorkerId,
    type: 'temporaryConceptWorkers',
    attributes: {
        color: color || null,
    },
});

export const transformEditTemporaryWorkerFormDataToRequest = (
    formData: EditTemporaryWorkerFormData,
): EditTemporaryWorkerRequest => {
    const start = formatDate(formData.period.start, DateFormat.apiDateTime);
    const end = formatDate(formData.period.end, DateFormat.apiDateTime);

    return {
        // This request does not accept the resource id in the request body
        type: 'temporaryWorkers',
        attributes: {
            start,
            end,
        },
    };
};

export const transformEditTemporaryConceptWorkerFormDataToRequest = (
    temporaryConceptWorkerId: string,
    formData: EditTemporaryWorkerFormData,
): EditTemporaryConceptWorkerRequest => {
    const customStart = formatDate(formData.period.start, DateFormat.apiDateTime);
    const customEnd = formatDate(formData.period.end, DateFormat.apiDateTime);

    return {
        id: temporaryConceptWorkerId,
        type: 'temporaryConceptWorkers',
        attributes: {
            customStart,
            customEnd,
        },
    };
};
