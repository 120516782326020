import { FC } from 'react';

import PlannedUserCard from '../../../components/PlannedUserCard/PlannedUserCard';
import { Absence } from '../../entities/Absence/Absence';
import { LeaveOfAbsence } from '../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { PreferToWorkTimeSlot } from '../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { Setting } from '../../entities/Setting/Setting';
import { ShiftIndex } from '../../entities/Shift/Shift';
import { ShiftPlanning } from '../../entities/ShiftPlanning/ShiftPlanning';
import { getShiftPlanningDeviatedTime } from '../../entities/ShiftPlanning/ShiftPlanningHelpers';
import { UnavailableToWorkTimeSlot } from '../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { WorkweekUser } from '../../entities/User/User';
import { CompanyMedicType } from '../../types';
import { ShiftPlanningAvailabilityLabels } from './subcomponents';

import './ShiftPlanningCard.scss';

interface ShiftPlanningCardProps {
    showComments?: boolean;
    absences: Absence[];
    leaveOfAbsences: LeaveOfAbsence[];
    settings: Setting[];
    shiftPlanning: ShiftPlanning;
    shift: ShiftIndex;
    preferToWorkTimeSlots: PreferToWorkTimeSlot[];
    recentShiftPlannings: ShiftPlanning[];
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[];
    user?: WorkweekUser;
    onAddButtonClick?: (shiftPlanning: ShiftPlanning) => void;
    onColorSelect?: (shiftPlanning: ShiftPlanning, color?: string) => void;
    onEditButtonClick?: (shiftPlanning: ShiftPlanning) => void;
    onDeleteButtonClick?: (shiftPlanning: ShiftPlanning) => void;
    className?: string;
}

const ShiftPlanningCard: FC<ShiftPlanningCardProps> = ({
    showComments = false,
    absences,
    leaveOfAbsences,
    shiftPlanning,
    shift,
    settings,
    preferToWorkTimeSlots,
    recentShiftPlannings,
    unavailableToWorkTimeSlots,
    user,
    onAddButtonClick,
    onColorSelect,
    onEditButtonClick,
    onDeleteButtonClick,
    className = '',
}) => {
    const { id } = shiftPlanning;
    const userId = shiftPlanning?.user?.id;
    const employmentType = shiftPlanning?.user?.employmentType;
    const isCompanyMedic = shiftPlanning.user.companyMedic === CompanyMedicType.yes;
    const time = getShiftPlanningDeviatedTime(shiftPlanning, shift);
    const comment = shiftPlanning.comments[shiftPlanning.comments.length - 1];
    const experience = shiftPlanning.user.experience?.name;

    const plannedHoursInWeek = user?.plannedHoursInWeek || 0;
    const plannedConceptHoursInWeek = user?.plannedConceptHoursInWeek || 0;
    const totalPlannedHoursInWeek = plannedHoursInWeek + plannedConceptHoursInWeek;

    const handleAddButtonClick = () => {
        if (onAddButtonClick) {
            onAddButtonClick(shiftPlanning);
        }
    };

    const handleDeleteButtonClick = () => {
        if (onDeleteButtonClick) {
            onDeleteButtonClick(shiftPlanning);
        }
    };

    const handleEditButtonClick = () => {
        if (onEditButtonClick) {
            onEditButtonClick(shiftPlanning);
        }
    };

    const handleColorSelect = (
        // TODO: These props come from PlannedUserCard. It is a legacy component and should be refactored
        serId: string,
        shiftPlanningId: string,
        userType: any,
        color: string | null,
    ) => {
        if (onColorSelect) {
            onColorSelect(shiftPlanning, color || undefined);
        }
    };

    return (
        // TODO: PlannedUserCard is legacy and should be refactored
        <PlannedUserCard
            isCompanyMedic={isCompanyMedic}
            id={id}
            color={shiftPlanning.color}
            userId={userId}
            userName={shiftPlanning.user.fullName}
            employmentType={employmentType}
            experience={experience}
            time={time}
            plannedHoursInWeek={totalPlannedHoursInWeek}
            comment={showComments ? comment : undefined}
            labels={(
                <ShiftPlanningAvailabilityLabels
                    absences={absences}
                    leaveOfAbsences={leaveOfAbsences}
                    unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                    preferToWorkTimeSlots={preferToWorkTimeSlots}
                    recentShiftPlannings={recentShiftPlannings}
                    settings={settings}
                    departmentId={shift.department.id}
                    shiftPlanning={shiftPlanning}
                    user={user}
                    className="shift-planning-card__availability-labels"
                />
            )}
            onAddButtonClick={onAddButtonClick ? handleAddButtonClick : undefined}
            onColorSelect={handleColorSelect}
            onEditButtonClick={onEditButtonClick ? handleEditButtonClick : undefined}
            onDeleteButtonClick={onDeleteButtonClick ? handleDeleteButtonClick : undefined}
            className={`shift-planning-card ${className}`}
        />
    );
};

export default ShiftPlanningCard;
