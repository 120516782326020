import { FC, PropsWithChildren, ReactElement } from 'react';

import { ValkLogo } from '../../components';

import './LoginLayout.scss';

const LoginLayout: FC<PropsWithChildren> = ({ children }): ReactElement => (
    <div className="login-layout">
        <div className="login-layout__branding">
            <ValkLogo />
        </div>

        <div className="login-layout__content">
            {children}
        </div>
    </div>
);

export default LoginLayout;
