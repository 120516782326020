import { FC, ReactElement, useMemo } from 'react';

import { EmploymentType } from '../../../entities/EmploymentType/EmploymentType';
import trans from '../../../helpers/trans';
import { SelectOption } from '../../../types';
import { SelectInput } from '../../index';

const generateEmploymentTypeOptions = (employmentTypes: EmploymentType[]): SelectOption[] => employmentTypes
    .map(employmentType => ({ label: trans(`common.${employmentType.slug}`), value: employmentType.id }))
    .sort((a, b) => a.label.localeCompare(b.label)) as SelectOption[];

interface EmploymentTypeSelectInputProps {
    disabled?: boolean;
    employmentTypes: EmploymentType[];
    employmentType: SelectOption;
    onChange: (option: SelectOption) => void;
}

const EmploymentTypeSelectInput: FC<EmploymentTypeSelectInputProps> = ({
    disabled,
    employmentTypes,
    employmentType,
    onChange,
}): ReactElement => {
    const employmentTypeOptions = useMemo(() => generateEmploymentTypeOptions(employmentTypes), [employmentTypes]);

    const onEmploymentTypeStateChange = (employmentTypeValue: SelectOption): void => {
        onChange(employmentTypeValue as SelectOption);
    };

    return (
        <SelectInput
            disabled={disabled}
            isSearchable={false}
            label={trans('common.employmentType')}
            name="employmentType"
            value={employmentType}
            options={employmentTypeOptions}
            onChange={onEmploymentTypeStateChange}
        />
    );
};

export default EmploymentTypeSelectInput;
