import { FC, ReactElement, useMemo } from 'react';

import { ContractHours } from '../../entities/ContractHours/ContractHours';
import { getContractHoursLabel } from '../../entities/ContractHours/ContractHoursHelpers';

interface UserContractHoursProps {
    contractHours: ContractHours[];
    date?: Date;
    plannedHoursInWeek?: number;
    className?: string;
}

const UserContractHours: FC<UserContractHoursProps> = ({
    contractHours,
    date,
    plannedHoursInWeek,
    className = '',
}): ReactElement => {
    const label = useMemo(() => getContractHoursLabel(contractHours, plannedHoursInWeek, date || undefined), [contractHours, plannedHoursInWeek, date]);

    return (
        <div className={`user-contract-hours ${className}`}>
            {`(${label})`}
        </div>
    );
};

export default UserContractHours;
