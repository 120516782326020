import {
    APP_ADD_TOAST,
    APP_CLEAR_TOASTS,
    APP_GET_GENERAL_DATA_SUCCESS,
    APP_GET_NOTIFICATIONS_SUCCESS,
    APP_GET_PAYROLL_PERIODS_REQUEST,
    APP_GET_PAYROLL_PERIODS_SUCCESS,
    APP_LOGOUT,
    APP_PUSH_HISTORY,
    APP_SET_ERROR,
    APP_SET_SWITCH_MODE,
    AUTH_UNSET_TOKENS,
} from '../actionTypes';

const initialState = {
    error: null,
    initialized: false,
    toasts: [],
    employmentTypes: [],
    roles: [],
    payrollPeriods: [],
    payrollPeriodsLoading: false,
    runningTracksLength: 0,
    reviewTracksLength: 0,
    absencesLength: 0,
    exchangeRequestsLength: 0,
    leaveOfAbsenceRequestsLength: 0,
    history: [],
    isConceptMode: false,
};

// eslint-disable-next-line default-param-last
const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case APP_SET_ERROR:
            return { ...state, error: action.error };
        case APP_ADD_TOAST:
            return {
                ...state,
                toasts: [
                    ...state.toasts,
                    {
                        createdOn: new Date().getTime(),
                        type: action.toastType,
                        text: action.toast,
                    },
                ],
            };
        case APP_CLEAR_TOASTS:
            return {
                ...state,
                toasts: [],
            };
        case APP_GET_GENERAL_DATA_SUCCESS:
            return {
                ...state,
                initialized: true,
                employmentTypes: action.employmentTypes || [],
                roles: action.roles || [],
                runningTracksLength: action.runningTracksLength || 0,
                reviewTracksLength: action.reviewTracksLength || 0,
                absencesLength: action.absencesLength || 0,
                exchangeRequestsLength: action.exchangeRequestsLength || 0,
                leaveOfAbsenceRequestsLength: action.leaveOfAbsenceRequestsLength || 0,
            };
        case APP_GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                runningTracksLength: action.runningTracksLength || 0,
                reviewTracksLength: action.reviewTracksLength || 0,
                absencesLength: action.absencesLength || 0,
                exchangeRequestsLength: action.exchangeRequestsLength || 0,
                leaveOfAbsenceRequestsLength: action.leaveOfAbsenceRequestsLength || 0,
            };
        case AUTH_UNSET_TOKENS:
            return {
                ...state,
                initialized: false,
                toasts: [],
                error: null,
            };
        case APP_GET_PAYROLL_PERIODS_REQUEST:
            return {
                ...state,
                payrollPeriodsLoading: true,
            };
        case APP_GET_PAYROLL_PERIODS_SUCCESS:
            return {
                ...state,
                payrollPeriods: action.payrollPeriods,
                payrollPeriodsLoading: false,
            };
        case APP_SET_SWITCH_MODE:
            return { ...state, isConceptMode: !state.isConceptMode };
        case APP_PUSH_HISTORY:
            return { ...state, history: [...state.history, action.location] };
        case APP_LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default appReducer;
