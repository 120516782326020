import { FC, ReactElement } from 'react';

import FixedCalendarHeader from '../../../components/FixedCalendarHeader/FixedCalendarHeader';
import { getEndOfWorkDay, isBefore } from '../../../helpers/date';
import { CalendarDay } from '../../compositions';
import { CalenderDayProps } from '../../compositions/CalendarDay/CalendarDay';
import { ConnectedHelpTooltip } from '../../connectors';
import trans from '../../helpers/trans';
import { TimeModeType } from '../../types';

import './CalendarDays.scss';

export interface CalendarItem {
    start: Date;
    end: Date;
    element: ReactElement;
}

interface CalenderDaysProps extends Omit<CalenderDayProps, 'day'> {
    isDragging?: boolean;
    items: CalendarItem[];
    days: Date[];
    timeMode?: TimeModeType;
}

const CalenderDays: FC<CalenderDaysProps> = ({
    isDark,
    isDragging,
    showAddItemButton,
    showAddSequenceButton,
    showDayInMonth,
    showHeader,

    addItemButtonTooltipText,
    addSequenceButtonTooltipText,
    dayHeaderFormat,
    days,
    items,
    specialDays,
    timeMode,

    onAddItemButtonClick,
    onDayDragEnter,
    onDayDrop,

    className = '',
}) => (
    <div className={`calendar-days ${className}`}>
        {days.map(day => {
            const isOutOfMonthRange = timeMode === TimeModeType.month
                ? day.getMonth() !== days[days.length - 1].getMonth()
                : false;

            const isOutOfDragRange = isDragging && isBefore(getEndOfWorkDay(day), new Date());


            return (
                <CalendarDay
                    key={day.getTime()}
                    isDark={isDark}
                    isOutOfRange={isOutOfMonthRange || isOutOfDragRange}
                    showAddItemButton={showAddItemButton}
                    showAddSequenceButton={showAddSequenceButton}
                    showDayInMonth={showDayInMonth}
                    showHeader={showHeader}
                    addItemButtonTooltipText={addItemButtonTooltipText}
                    addSequenceButtonTooltipText={addSequenceButtonTooltipText}
                    dayHeaderFormat={dayHeaderFormat}
                    day={day}
                    items={items}
                    specialDays={specialDays}
                    onAddItemButtonClick={onAddItemButtonClick}
                    onDayDragEnter={onDayDragEnter}
                    onDayDrop={onDayDrop}
                />
            );
        })}
        {(timeMode && showHeader) && (
            <FixedCalendarHeader
                mode={timeMode}
                days={days}
                dayClassName="calendar-days__fixed-calendar-header-day"
            />
        )}
        <ConnectedHelpTooltip
            index={2}
            videoSrc="/help/shift-icons.webm"
            route="calendar"
            subTitle={trans('help.shiftsCalendar.icons.title')}
            text={trans('help.shiftsCalendar.icons.text')}
            title={trans('help.shiftsCalendar.title')}
            showMobileInfoWarning
            className="calendar-days__icons-help-tooltip"
        />
        <ConnectedHelpTooltip
            index={3}
            route="calendar"
            subTitle={trans('help.shiftsCalendar.editShift.title')}
            text={trans('help.shiftsCalendar.editShift.text')}
            title={trans('help.shiftsCalendar.title')}
            className="calendar-days__edit-shift-help-tooltip"
        />
        <ConnectedHelpTooltip
            index={4}
            videoSrc="/help/drag-shift.webm"
            route="calendar"
            subTitle={trans('help.shiftsCalendar.dragShift.title')}
            text={trans('help.shiftsCalendar.dragShift.text')}
            title={trans('help.shiftsCalendar.title')}
            showMobileInfoWarning
            className="calendar-days__drag-shift-help-tooltip"
        />
    </div>
);

export default CalenderDays;
