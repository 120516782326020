import { FC } from 'react';

import trans from '../../helpers/trans';
import { Legend } from '../index';

interface LeaveOfAbsencesCalenderLegendProps {
    className?: string;
}

enum LeaveOfAbsenceCalenderLegendColorType {
    approved = 'approved-calender',
    open = 'open-calender',
    rejected = 'rejected-calender'
}

const colors = [
    {
        title: trans('compositions.leaveOfAbsencesLegend.approved'),
        type: LeaveOfAbsenceCalenderLegendColorType.approved,
    },
    {
        title: trans('compositions.leaveOfAbsencesLegend.open'),
        type: LeaveOfAbsenceCalenderLegendColorType.open,
    },
    {
        title: trans('compositions.leaveOfAbsencesLegend.rejected'),
        type: LeaveOfAbsenceCalenderLegendColorType.rejected,
    },
];

const LeaveOfAbsencesCalenderLegend: FC<LeaveOfAbsencesCalenderLegendProps> = ({ className = '' }) => (
    <Legend
        colors={colors}
        className={className}
    />
);

export default LeaveOfAbsencesCalenderLegend;
