import { compareAsc, formatDate } from '../../../helpers/date';
import { LeaveOfAbsenceStatus } from '../../types';
import { DateFormat } from '../../types/dateFormatTypes';
import { doesAbsenceOverlapWithPeriod } from '../Absence/AbsenceHelpers';
import { doPeriodsOverlap } from '../Period/PeriodHelpers';
import { ShiftIndex } from '../Shift/Shift';
import { ShiftsCalendarAbsence } from '../ShiftsCalendarAbsence/ShiftsCalendarAbsence';
import { ShiftsCalendarLeaveOfAbsence } from '../ShiftsCalendarLeaveOfAbsence/ShiftsCalendarLeaveOfAbsence';
import { ShiftPlanning } from './ShiftPlanning';

export const doesShiftPlanningHaveAbsence = (shiftPlanning: ShiftPlanning, absences: ShiftsCalendarAbsence[]): boolean => absences
    .filter(absence => absence.userId === shiftPlanning.user.id)
    .some(absence => doesAbsenceOverlapWithPeriod(absence, shiftPlanning.period));

export const doesShiftPlanningHaveOpenLeaveOfAbsence = (
    shiftPlanning: ShiftPlanning,
    leaveOfAbsences: ShiftsCalendarLeaveOfAbsence[],
): boolean => leaveOfAbsences
    .filter(leaveOfAbsence => leaveOfAbsence.userId === shiftPlanning.user.id)
    .filter(leaveOfAbsence => leaveOfAbsence.status === LeaveOfAbsenceStatus.open)
    .some(leaveOfAbsence => doPeriodsOverlap(leaveOfAbsence.period, shiftPlanning.period));

export const isShiftPlanningTimeDeviated = (shiftPlanning: ShiftPlanning, shift: ShiftIndex) => (compareAsc(shiftPlanning.period.start, shift.period.start) !== 0
        || compareAsc(shiftPlanning.period.end, shift.period.end) !== 0);

export const getShiftPlanningDeviatedTime = (shiftPlanning: ShiftPlanning, shift?: ShiftIndex): string | undefined => {
    if (!shift || !isShiftPlanningTimeDeviated(shiftPlanning, shift)) {
        return undefined;
    }

    return `${formatDate(shiftPlanning.period.start, DateFormat.hoursMinutes)}-${formatDate(shiftPlanning.period.end, DateFormat.hoursMinutes)}`;
};
