import { FullLoketUser } from '../../../../entities/LoketUser/LoketUser';
import { AddUserFormData } from '../../../../entities/User/User';
import { IdentificationType } from '../../../../types';
import { generateAddUserFormDataEmptyValues } from './generateAddUserFormDataEmptyValues';

export const transformFullLoketUserToAddUserFormData = (fullLoketUser: FullLoketUser): AddUserFormData => {
    const formData = generateAddUserFormDataEmptyValues();

    if (formData.employmentDates) {
        formData.employmentDates.startDate = fullLoketUser.employment?.start;
    }

    formData.address = fullLoketUser.address;
    formData.personalInfo.affix = fullLoketUser.person.affix;
    formData.personalInfo.birthday = fullLoketUser.birthday;
    formData.personalInfo.employeeNumber = fullLoketUser.employeeNumber;
    formData.personalInfo.email = fullLoketUser.emailAddress;
    formData.personalInfo.firstname = fullLoketUser.person.firstname;
    formData.personalInfo.gender = fullLoketUser.gender;
    formData.personalInfo.lastname = fullLoketUser.person.lastname;
    formData.personalInfo.phoneNumber = fullLoketUser.phoneNumber || fullLoketUser.mobilePhoneNumber;
    formData.person.identificationType = fullLoketUser.identificationType || IdentificationType.unknown;
    formData.person.nationality = fullLoketUser.nationality;

    return formData;
};
