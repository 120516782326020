import { take } from 'redux-saga/effects';

import { formatDate } from '../../helpers/date';
import {
    Absence,
    BasicUser,
    BasicUserWithAbsenceData,
    LeaveOfAbsence,
    LegacyEditShiftFormData,
    MappedAbsenceData,
    Shift,
    ShiftPlanning,
    ShiftWithAbsenceData,
    transformBasicUserToBasicUserWithAbsenceData,
    transformShiftToShiftWithAbsenceData,
    UnavailableToWorkTimeSlot,
} from '../../models';
import { TemporaryWorker } from '../../models/TemporaryWorker';
import { fetchAbsencesDataForShiftsAndUsers } from '../absences/absencesSaga';
import { ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_SUCCESS } from '../actionTypes';
import { convertShiftPlanningsToUsers, mapAbsenceDataToUsers } from '../users/usersHelpers';

export function mapAbsencesDataToRegisteredAndPlannedUsers(
    shifts: Shift[],
    leaveOfAbsences: LeaveOfAbsence[],
    absences: Absence[],
    unavailableTimeSlots: UnavailableToWorkTimeSlot[],
    now: Date | string,
): ShiftWithAbsenceData[] {
    return shifts.map((shift) => {
        const userIds = shift.shiftPlannings.map(planning => planning?.user?.id);
        const absenceData = mapAbsenceDataToUsers(userIds, shift.start, shift.end, now, leaveOfAbsences, absences);

        const { users, registeredUsers, plannedUsers } = convertShiftPlanningsToUsers(shift, absenceData, unavailableTimeSlots);
        const hasLeaveOfAbsence = !!users
            .find(user => user.leaveOfAbsence);
        const hasLeaveOfAbsenceRequest = !!users
            .find(user => user.leaveOfAbsenceRequest);
        const hasAbsence = !!users.find(user => user.absence);

        return transformShiftToShiftWithAbsenceData(
            shift,
            plannedUsers,
            registeredUsers,
            hasLeaveOfAbsence,
            hasLeaveOfAbsenceRequest,
            hasAbsence,
        );
    });
}

export function mapAbsencesDataToPreviouslyPlannedUsers(
    shifts: ShiftWithAbsenceData[],
    leaveOfAbsences: LeaveOfAbsence[],
    absences: Absence[],
    now: Date | string,
): ShiftWithAbsenceData[] {
    return shifts.map((shift) => {
        const userIds = (shift.previouslyPlannedUsers as BasicUser[]).map((user: any) => user.id);
        const absenceData = mapAbsenceDataToUsers(userIds, shift.start, shift.end, now, leaveOfAbsences, absences);
        const previouslyPlannedUsers = (shift.previouslyPlannedUsers as BasicUser[])
            .map((user: BasicUser) => {
                const data = absenceData.find(d => d.userId === user.id) as MappedAbsenceData;
                return transformBasicUserToBasicUserWithAbsenceData(
                    user,
                    data.shiftPlanning,
                    data.leaveOfAbsenceRequest,
                    data.leaveOfAbsence,
                    data.absence,
                );
            });

        return {
            ...shift,
            previouslyPlannedUsers,
        };
    });
}

export function mapAbsencesDataToShifts(
    shifts: Shift[] = [],
    leaveOfAbsences: LeaveOfAbsence[] = [],
    absences: Absence[] = [],
    unavailableTimeSlots: UnavailableToWorkTimeSlot[] = [],
    now: Date = new Date(),
): ShiftWithAbsenceData[] {
    let mappedShifts = mapAbsencesDataToRegisteredAndPlannedUsers(shifts, leaveOfAbsences, absences, unavailableTimeSlots, now);

    if (mappedShifts.find(shift => shift.previouslyPlannedUsers)) {
        mappedShifts = mapAbsencesDataToPreviouslyPlannedUsers(mappedShifts, leaveOfAbsences, absences, now);
    }

    return mappedShifts;
}

export function* fetchAbsencesData(state: any) {
    const { startDate, endDate } = state.weekNavigatorReducer;
    const {
        shiftsAndUsersLoading,
        startDate: absencesStartDate,
        endDate: absencesEndDate,
    } = state.absencesReducer;
    // Make sure we only get new data when date changes, so there won't be any
    // delays when user changes category filters
    if (startDate !== absencesStartDate || endDate !== absencesEndDate) {
        yield fetchAbsencesDataForShiftsAndUsers();
    } else if (shiftsAndUsersLoading) {
        // If already loading then we simply wait for the saga to finish (the saga
        // was already called by another action)
        yield take(ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_SUCCESS);
    }
}

export function filterShiftsByAbsences(shifts: ShiftWithAbsenceData[], filters: any) {
    return shifts.filter((shift => (
        (filters.onlyShowShiftsWithOpenLeaveOfAbsence && shift.hasLeaveOfAbsenceRequest)
        || (filters.onlyShowShiftsWithAbsence && shift.hasAbsence))));
}

export function getShiftWithPlannedUser(
    shift: ShiftWithAbsenceData,
    plannedUser: BasicUserWithAbsenceData,
    shiftPlanning: ShiftPlanning,
): ShiftWithAbsenceData {
    const plannedUsers = [
        ...shift.plannedUsers,
        plannedUser,
    ];

    const previouslyPlannedUsers = shift.previouslyPlannedUsers
        .filter((user) => user.id !== plannedUser.id);
    const registeredUsers = shift.registeredUsers
        .filter((user) => user.id !== plannedUser.id);
    const shiftPlannings = [
        ...shift.shiftPlannings.filter((planning) => planning.user.id !== plannedUser.id),
        shiftPlanning,
    ];

    return {
        ...shift,
        plannedUsers,
        previouslyPlannedUsers,
        registeredUsers,
        shiftPlannings,
    };
}

export function getShiftsWithUpdatedShift(
    shifts: ShiftWithAbsenceData[],
    newShift: ShiftWithAbsenceData,
): ShiftWithAbsenceData[] {
    const index = shifts.findIndex(shift => shift.id === newShift.id);
    const filteredShifts = [...shifts];
    filteredShifts.splice(index, 1, newShift);

    return filteredShifts;
}

export function getShiftWithoutPlannedUser(
    shift: ShiftWithAbsenceData,
    userId: string,
): ShiftWithAbsenceData {
    const removedUser = shift.plannedUsers
        .find((user) => user.id === userId) as BasicUserWithAbsenceData;
    const plannedUsers = shift.plannedUsers.filter((user) => user.id !== userId);
    const registeredUsers = shift.registeredUsers.filter((user) => user.id !== userId);
    const shiftPlannings = shift.shiftPlannings
        .filter((planning) => planning.user.id !== userId);
    const { previouslyPlannedUsers } = shift;

    if (removedUser && !!removedUser.absence) {
        previouslyPlannedUsers.push(removedUser);
    }

    return {
        ...shift,
        plannedUsers,
        registeredUsers,
        previouslyPlannedUsers,
        shiftPlannings,
    };
}

export function getShiftWithShiftPlanning(
    shift: ShiftWithAbsenceData,
    newShiftPlanning: ShiftPlanning,
): ShiftWithAbsenceData {
    const shiftPlannings = shift.shiftPlannings
        .filter(shiftPlanning => shiftPlanning.id !== newShiftPlanning.id);
    shiftPlannings.push(newShiftPlanning);

    return {
        ...shift,
        shiftPlannings,
    };
}

export function getShiftWithTempWorker(
    shift: ShiftWithAbsenceData,
    newTempWorker: TemporaryWorker,
): ShiftWithAbsenceData {
    const temporaryWorkers = shift.temporaryWorkers
        .filter(tempWorker => tempWorker.id !== newTempWorker.id);
    temporaryWorkers.push(newTempWorker);

    return {
        ...shift,
        temporaryWorkers,
    };
}

export function getShiftWithoutTempWorker(shift: ShiftWithAbsenceData, workerId: string)
    : ShiftWithAbsenceData {
    const temporaryWorkers = shift.temporaryWorkers.filter(worker => worker.id !== workerId);

    return {
        ...shift,
        temporaryWorkers,
    };
}

export function isDataDifferentThanShift(shift: Shift, data: LegacyEditShiftFormData) {
    const { end, start, peopleLimit } = shift;

    return (
        formatDate(start, 'yyyy-MM-dd') !== data.startDate
        || formatDate(start, 'HH:mm') !== data.startTime
        || formatDate(end, 'yyyy-MM-dd') !== data.endDate
        || formatDate(end, 'HH:mm') !== data.endTime
        || peopleLimit !== data.peopleLimit
    );
}
