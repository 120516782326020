import { Dispatch } from 'redux';

import { Department } from '../../../../@paco/entities/Department/Department';
import { getOpenLeaveOfAbsencesInPeriod } from '../../../../helpers/api-ts/leaveOfAbsence';
import { PayrollPeriodViewModel } from '../../../../models';
import { deductOneSecondFromLOARequests } from '../../../absences-ts/absencesHelpers';
import { setError, setIsLoading, setOpenLeaveOfAbsences } from './openLeaveOfAbsencesReducer';

export const fetchOpenLeaveOfAbsences = (payrollPeriod: PayrollPeriodViewModel, departments: Department[]) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const { start, end } = payrollPeriod;

    try {
        const endDate = end.transformToUpToButExcludingDate();

        const response = await getOpenLeaveOfAbsencesInPeriod(start, endDate.date);

        // TODO: Use proper transformer to LeaveOfAbsenceViewModel in next refactor
        const leaveOfAbsences = deductOneSecondFromLOARequests(response);

        const departmentIds = departments.map(department => department.id);
        const matchedMainDepartmentLeaveOfAbsences = leaveOfAbsences
            .filter(leaveOfAbsence => leaveOfAbsence.user.mainDepartment && departmentIds
                .includes(leaveOfAbsence.user.mainDepartment?.id));

        dispatch(setOpenLeaveOfAbsences(matchedMainDepartmentLeaveOfAbsences));
    } catch (error) {
        console.error('[fetchOpenLeaveOfAbsences]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
