import React, { FC, useMemo } from 'react';

import { CompanyMedicType } from '../../../../../../@paco/types';
import { UnavailableToWorkTimeSlotViewModel } from '../../../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { getUserFullName } from '../../../../../../helpers';
import {
    AbsenceViewModel,
    LeaveOfAbsenceViewModel,
    ShiftDraggable,
    ShiftPlanningViewModel,
} from '../../../../../../models';
import getShiftPlanningDeviatedTime from '../../../../../../services/ShiftPlanningService/getShiftPlanningDeviatedTime';
import getShiftEmployeeIcons from '../../helpers/getShiftEmployeeIcons';
import ShiftEmployee from '../ShiftEmployee/ShiftEmployee';


interface ShiftEmployeeProps {
    employmentTypeClassName?: string;
    shiftPlanning: ShiftPlanningViewModel;
    shift?: ShiftDraggable;
    leaveOfAbsences?: LeaveOfAbsenceViewModel[];
    absences?: AbsenceViewModel[];
    unavailableTimeSlots?: UnavailableToWorkTimeSlotViewModel[];
    showEmploymentTypeBadge: boolean;
    className?: string;
    iconClassName?: string;
}

const ShiftPlanning: FC<ShiftEmployeeProps> = ({
    employmentTypeClassName,
    shiftPlanning,
    shift,
    leaveOfAbsences = [],
    absences = [],
    unavailableTimeSlots = [],
    showEmploymentTypeBadge,
    className,
    iconClassName,
}) => {
    const { color } = shiftPlanning;
    const name = useMemo(() => getUserFullName(shiftPlanning.user), [shiftPlanning]);
    const deviatedTime = useMemo(
        () => getShiftPlanningDeviatedTime(shiftPlanning, shift),
        [shift, shiftPlanning],
    );
    const isCompanyMedic = shiftPlanning.user?.person.isCompanyMedic === CompanyMedicType.yes;
    const icons = useMemo(
        () => getShiftEmployeeIcons(
            shiftPlanning,
            leaveOfAbsences,
            absences,
            unavailableTimeSlots,
            new Date(),
        ),
        [shiftPlanning, leaveOfAbsences, absences],
    );

    return (
        <ShiftEmployee
            isCompanyMedic={isCompanyMedic}
            employmentTypeClassName={employmentTypeClassName}
            name={name}
            color={color || undefined}
            deviatedTime={deviatedTime}
            icons={icons}
            showEmploymentTypeBadge={showEmploymentTypeBadge}
            employmentTypeSlug={shiftPlanning?.user?.employmentType?.slug}
            className={className}
            iconClassName={iconClassName}
        />
    );
};

export default ShiftPlanning;
