import { EditBaseScheduleShiftTimeFormData } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { deleteBaseScheduleShiftApiCall, editBaseScheduleShiftTimeApiCall } from '../../entities/BaseScheduleShift/BaseScheduleShiftService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import {
    setBaseScheduleShift,
    setDeletedBaseScheduleShiftId,
    setError,
    setIsDeleteBaseScheduleShiftSuccessful,
    setIsEditBaseScheduleShiftSuccessful,
    setIsLoading,
} from './baseScheduleShiftReducer';

export const editBaseScheduleShiftTime = (editShiftConceptTimeFormData: EditBaseScheduleShiftTimeFormData, baseScheduleShiftId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsEditBaseScheduleShiftSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await editBaseScheduleShiftTimeApiCall(editShiftConceptTimeFormData, baseScheduleShiftId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setBaseScheduleShift(response.data));
        dispatch(setIsEditBaseScheduleShiftSuccessful(true));
    } catch (error) {
        console.error('[editBaseScheduleShiftTime]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteBaseScheduleShift = (baseScheduleShiftId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsDeleteBaseScheduleShiftSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await deleteBaseScheduleShiftApiCall(baseScheduleShiftId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDeletedBaseScheduleShiftId(baseScheduleShiftId));
        dispatch(setIsDeleteBaseScheduleShiftSuccessful(true));
    } catch (error) {
        console.error('[deleteBaseScheduleShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
