import { HelpToolTip } from '../../../redux/@interface/help/helpReducer';

export const getIncrementedTooltips = (increment: -1 | 1, tooltips: HelpToolTip[]): HelpToolTip[] => {
    const activeIndex = tooltips.findIndex(tooltip => tooltip.isActive) + increment;
    const justifiedActiveIndex = Math.max(Math.min(activeIndex, tooltips.length - 1), 0);

    return tooltips.map((tooltip, index) => ({
        ...tooltip,
        isActive: justifiedActiveIndex === index,
    }));
};

export const getTooltipsWithNewActiveIndex = (newActiveIndex: number, tooltips: HelpToolTip[]): HelpToolTip[] => tooltips
    .map((tooltip, index) => ({
        ...tooltip,
        isActive: newActiveIndex === index,
    }));
