import { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Reducers } from '../../../../redux/reducers';
import { ExportLeaveOfAbsencesForm } from '../../../containers';
import { LoketLeaveSyncFormData } from '../../../entities/LoketLeaveSync/LoketLeaveSync';
import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import trans from '../../../helpers/trans';
import { getLoketLeaveOfAbsenceHours, syncLeaveOfAbsencesToLoket } from '../../../redux/@forms/exportLeaveOfAbsencesForm/exportLeaveOfAbsencesFormActions';
import {
    reset,
    setIsLoading,
    setIsLoketLeaveSyncSuccessful,
    setIsSuccessful,
} from '../../../redux/@forms/exportLeaveOfAbsencesForm/exportLeaveOfAbsencesFormReducer';
import { getPacoPayrollPeriods } from '../../../redux/payrollPeriods/payrollPeriodsActions';
import { useTypedDispatch } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedExportLeaveOfAbsencesFormProps {
    activePeriod: PayrollPeriod;
    onClose: () => void;
}

const ConnectedExportLeaveOfAbsencesForm: FC<ConnectedExportLeaveOfAbsencesFormProps> = ({
    activePeriod,
    onClose,
}) => {
    const dispatch = useTypedDispatch();
    const { payrollPeriods, isLoading: isPayrollPeriodsLoading } = useSelector((state: Reducers) => state.pacoPayrollPeriodsReducer);
    const {
        isLoading,
        isLoketLeaveSyncSuccessful,
        isSuccessful,
        leaveOfAbsenceHours,
        loketLeaveSyncErrors,
        error,
    } = useSelector((state: Reducers) => state.exportLeaveOfAbsencesFormReducer);

    const hasUnknownError = !!error;

    const handleSubmit = (formData: LoketLeaveSyncFormData): void => {
        dispatch(syncLeaveOfAbsencesToLoket(formData));
    };

    const handleClose = (): void => {
        onClose();
        dispatch(reset());
    };

    useEffect((): void => {
        if (!payrollPeriods.length) {
            dispatch(getPacoPayrollPeriods());
        }
    }, []);

    useEffect((): void => {
        if (!isLoketLeaveSyncSuccessful) {
            return;
        }

        if (loketLeaveSyncErrors.length) {
            dispatch(getLoketLeaveOfAbsenceHours(loketLeaveSyncErrors.map(loketLeaveSyncError => loketLeaveSyncError.leaveId)));
        } else {
            dispatch(setIsLoading(false));
            dispatch(setIsLoketLeaveSyncSuccessful(false));
            dispatch(setIsSuccessful(true));
        }
    }, [isLoketLeaveSyncSuccessful]);

    useEffect((): void => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.exportLOAForm.exportSuccess'),
                type: ToastType.pass,
            }));
        }

        if (isSuccessful && !loketLeaveSyncErrors.length && !hasUnknownError) {
            onClose();
            dispatch(reset());
        }
    }, [isSuccessful]);

    return (
        <ExportLeaveOfAbsencesForm
            hasUnknownError={hasUnknownError}
            isLoading={isLoading}
            isPayrollPeriodsLoading={isPayrollPeriodsLoading}
            activePeriod={activePeriod}
            errors={loketLeaveSyncErrors}
            leaveOfAbsenceHours={leaveOfAbsenceHours}
            payrollPeriods={payrollPeriods}
            onSubmit={handleSubmit}
            onCancel={handleClose}
        />
    );
};

export default ConnectedExportLeaveOfAbsencesForm;
