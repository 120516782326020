import React, { FC } from 'react';

import { DayProps } from '../../models';
import DayContent from '../DayContent/DayContent';


interface Props extends DayProps {
    day: Date;
    outOfRange: boolean;
}

const Day: FC<Props> = ({
    day,
    outOfRange,
    leaveOfAbsences,
    absences,
    preferToWorkTimeSlots,
    unavailableToWorkTimeSlots,
    onResourceClick,
}) => (
    <div className={`day ${outOfRange ? 'day--out-of-range' : ''}`}>
        <DayContent
            day={day}
            leaveOfAbsences={leaveOfAbsences}
            absences={absences}
            preferToWorkTimeSlots={preferToWorkTimeSlots}
            unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
            onResourceClick={onResourceClick}
        />
    </div>
);

export default Day;
