import { FC, useRef } from 'react';

import { Setting } from '../../../../entities/Setting/Setting';
import useBlockItemOffset from '../../hooks/useBlockItemOffset';
import { PrintableDistrictDivisionDistrictBlock } from '../../models';
import PrintableDistrictDivisionDistrict from '../PrintableDistrictDivisionDistrict/PrintableDistrictDivisionDistrict';

import './PrintableDistrictDivisionDistrictBlockItem.scss';

interface PrintableDistrictDivisionDistrictBlockItemProps {
    isSizer?: boolean;
    block: PrintableDistrictDivisionDistrictBlock;
    settings: Setting[];
    className?: string;
}

const PrintableDistrictDivisionDistrictBlockItem: FC<PrintableDistrictDivisionDistrictBlockItemProps> = ({
    isSizer = false,
    block,
    settings,
    className = '',
}) => {
    const blockItemRef = useRef<HTMLDivElement>(null);

    useBlockItemOffset(blockItemRef, block.id, isSizer);

    return (
        <div ref={blockItemRef} className={`printable-district-division-district-block-item ${className}`}>
            {block.groupTitle && (
                <h2 className="printable-district-division-district-block-item__group-title">
                    {block.groupTitle}
                </h2>
            )}
            <PrintableDistrictDivisionDistrict
                districtDivisionDistrict={block.districtDivisionDistrict}
                settings={settings}
                className="printable-district-division-district-block-item__district-division-district"
            />
        </div>
    );
};

export default PrintableDistrictDivisionDistrictBlockItem;
