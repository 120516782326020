import { Employment } from '../Employment/Employment';
import { Period } from '../Period/Period';
import { doesDateOverlapWithPeriod } from '../Period/PeriodHelpers';

export const getDivergentPostEmploymentHoursBasedOnPeriod = (
    employment: Employment,
    period: Period,
): number | undefined => {
    if (!employment.divergentPostEmploymentHour || !employment.end) {
        return undefined;
    }

    if (!doesDateOverlapWithPeriod(employment.end, period)) {
        return undefined;
    }

    return employment.divergentPostEmploymentHour.contractHours;
};
