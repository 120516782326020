import { useMemo } from 'react';

import { useTypedSelector } from '../../redux/store';
import { SettingKey } from '../../types';
import { getSettingEnabled } from './SettingHelpers';

export const useSettingEnabled = (settingKey: SettingKey, departmentId?: string): boolean => {
    const { settings } = useTypedSelector(state => state.pacoSettingsReducer);

    return useMemo(() => getSettingEnabled(settingKey, settings, departmentId), [settings, departmentId]);
};
