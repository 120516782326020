import {
    LOGS_SAGA_CLEAR_RESOURCE_LOGS,
    LOGS_SAGA_GET_DELETED_LOGS,
    LOGS_SAGA_GET_RESOURCE_LOGS,
} from '../actionTypes';

export const getDeletedLogs = (entity) => ({ type: LOGS_SAGA_GET_DELETED_LOGS, entity });

export const getResourceLogs = (id, resourceType) => ({
    type: LOGS_SAGA_GET_RESOURCE_LOGS, id, resourceType,
});

export const clearResourceLogs = () => ({ type: LOGS_SAGA_CLEAR_RESOURCE_LOGS });
