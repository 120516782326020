import { Department } from '../../../../entities/Department/Department';
import { getDepartmentLabel } from '../../../../entities/Department/DepartmentHelpers';
import { DepartmentOption } from '../../../../types/selectOptionTypes';
import { getGroupDepartmentOption } from './getDepartmentOptionsByGroupId';

interface GroupSize {
    [groupId: string]: number;
}

const getDepartmentGroupSizes = (departments: Department[]): GroupSize => departments
    .reduce((total: GroupSize, department) => {
        if (!department.departmentGroup) {
            return total;
        }

        return {
            ...total,
            [department.departmentGroup.id]: (total[department.departmentGroup.id] || 0) + 1,
        };
    }, {});

export const transformDepartmentToDepartmentOption = (department: Department, hasFullGroup?: boolean, isGroup?: boolean): DepartmentOption => ({
    label: getDepartmentLabel(department),
    value: department.id,
    isDeleted: !!department.deletedAt,
    hasFullGroup: hasFullGroup || false,
    isGroup: isGroup || false,
});

export const getTrimmedDepartmentOptions = (selectedDepartments: Department[], allDepartments: Department[]): DepartmentOption[] => {
    const groupSizes = getDepartmentGroupSizes(allDepartments);
    const selectedGroupSizes = getDepartmentGroupSizes(selectedDepartments);

    const fullGroups = Object.keys(selectedGroupSizes).reduce((total: string[], groupId) => [
        ...total,
        ...(groupSizes[groupId] <= selectedGroupSizes[groupId] ? [groupId] : []),
    ], []);

    const activeDepartments = selectedDepartments.filter(department => !fullGroups.includes(department.departmentGroup?.id || ''));
    const hiddenDepartments = selectedDepartments.filter(department => fullGroups.includes(department.departmentGroup?.id || ''));

    const activeDepartmentOptions = activeDepartments.map(department => transformDepartmentToDepartmentOption(department));
    const hiddenDepartmentOptions = hiddenDepartments.map(department => transformDepartmentToDepartmentOption(department, true));
    const departmentGroupOptions = fullGroups.map((groupId: string): DepartmentOption => {
        const match = allDepartments.find(department => groupId === department.departmentGroup?.id);

        return getGroupDepartmentOption(match?.departmentGroup?.name || '', groupId);
    });

    return [
        ...departmentGroupOptions,
        ...hiddenDepartmentOptions,
        ...activeDepartmentOptions,
    ];
};
