import { RefObject } from 'react';

export const scrollToTop = (): void => window.scrollTo(0, 0);

export const scrollIntoView = <T extends HTMLElement>(ref?: RefObject<T>): void => {
    if (ref?.current) {
        ref.current.scrollIntoView({
            behavior: 'smooth',
        });
    }
};
