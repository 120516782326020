import React, { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { translate } from '../../../../helpers/translations/translator';
import { setFilter } from '../../../../redux/filter/filterActions';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import { getLoketLogs } from '../../../../redux/logs-ts/logsActions';
import { getLoketInfo } from '../../../../redux/pz/pzActions';
import { Reducers } from '../../../../redux/reducers';
import Synchronizations from '../../containers/Synchronizations/Synchronizations';


const ConnectedSynchronizations: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { filter } = useSelector((state: Reducers) => state.filterReducer);
    const { loading: isLoketDataLoading, loketData } = useSelector((state: Reducers) => state.pzReducer);
    const { loading: isLoketLogsLoading, loketLogs } = useSelector((state: Reducers) => state.logsReducer);
    const isLoading = isLoketDataLoading || isLoketLogsLoading;

    useEffect(() => {
        dispatch(getLoketInfo());
        dispatch(getLoketLogs());
    }, []);

    const onNonCoupledEmployeesButtonClick = () => {
        const link = `/${translate('nav.userManagement.link')}`;
        const management: AppFilter['management'] = {
            ...filter.management,
            showUncoupledLoketUsers: true,
        };

        dispatch(setFilter({
            ...filter,
            management,
        }));

        navigate(link);
    };

    return (
        <Synchronizations
            loketData={loketData}
            logs={loketLogs}
            loading={isLoading}
            onNonCoupledEmployeesButtonClick={onNonCoupledEmployeesButtonClick}
        />
    );
};

export default ConnectedSynchronizations;
