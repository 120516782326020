import React from 'react';

import { components, MultiValueProps } from 'react-select';

import { Option } from '../../../../../helpers';

const MultiValueContainer = (props: MultiValueProps<Option>) => (
    <components.MultiValueContainer {...props} />
);

export default MultiValueContainer;
