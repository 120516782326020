import { convertDateToApiParamDateTime } from '../../helpers/date';
import { AcceptTrackRequest, EditTrackFormData, EditTrackRequest } from './Track';

export const transformToEditTrackRequest = (formData: EditTrackFormData): EditTrackRequest => ({
    data: {
        type: 'tracks',
        id: formData.id,
        attributes: {
            ...formData.accepted && { accepted: formData.accepted },
            checkIn: convertDateToApiParamDateTime(formData.checkIn),
            checkOut: convertDateToApiParamDateTime(formData.checkOut),
            billableStart: convertDateToApiParamDateTime(formData.checkIn),
            billableEnd: convertDateToApiParamDateTime(formData.checkOut),
        },
        ...(formData.departmentId && {
            relationships: {
                department: {
                    data: {
                        id: formData.departmentId,
                        type: 'departments',
                    },
                },
            },
        }),
    },
});

export const transformToAcceptTrackRequest = (trackId: string): AcceptTrackRequest => ({
    data: {
        type: 'tracks',
        id: trackId,
        attributes: {
            accepted: true,
        },
        relationships: {}, // Needed for BE
    },
});
