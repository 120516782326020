import { FC, useEffect, useMemo } from 'react';

import { ExportShiftsForm } from '../../../containers';
import { ExportShiftFormData } from '../../../entities/Shift/Shift';
import { exportsShifts } from '../../../redux/shift/shiftActions';
import { setIsSuccessful } from '../../../redux/shift/shiftReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedExportShiftsFormProps {
    onClose: () => void;
}

const ConnectedExportShiftsForm: FC<ConnectedExportShiftsFormProps> = ({
    onClose,
}) => {
    const dispatch = useTypedDispatch();
    const { selectedDays } = useTypedSelector(state => state.calendarReducer);
    const { isLoading, isSuccessful } = useTypedSelector(state => state.shiftReducer);
    const { departmentOptions, userSearch } = useTypedSelector(state => state.globalFiltersReducer);
    const selectedDepartments = useMemo(() => departmentOptions.filter(option => option.isChecked), [departmentOptions]);

    const startDate = selectedDays[0];
    const endDate = selectedDays[selectedDays.length - 1];

    const handleShiftExport = (shiftExportFormData: ExportShiftFormData) => {
        dispatch(exportsShifts(shiftExportFormData, selectedDepartments, userSearch));
    };

    useEffect(() => {
        dispatch(setIsSuccessful(false));
        if (isSuccessful) onClose();
    }, [isSuccessful]);

    return (
        <ExportShiftsForm
            isLoading={isLoading}
            startDate={startDate}
            endDate={endDate}
            onSubmit={handleShiftExport}
            onCancel={onClose}
        />
    );
};

export default ConnectedExportShiftsForm;
