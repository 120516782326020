import { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-use';

import { logout } from '../../../redux/app/appActions';
import { Reducers } from '../../../redux/reducers';
import { Navigation } from '../../containers';
import useActiveNavigationRouteKey from '../../helpers/hooks/useActiveNavigationRoute';
import { setHelpActiveState, setTooltips } from '../../redux/@interface/help/helpReducer';
import { useTypedSelector } from '../../redux/store';
import { getTooltipsWithNewActiveIndex } from '../ConnectedHelpTooltip/helpers';

const ConnectedNavigation: FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const activeRoute = useActiveNavigationRouteKey();

    const { notifications } = useTypedSelector(state => state.notificationsReducer);
    const { isActive: helpIsActive, activeRoute: helpActiveRoute, tooltips } = useTypedSelector(state => state.helpReducer);
    const { permissions, user: currentUser } = useTypedSelector(state => state.authenticatedUserReducer);
    const settings = useSelector((state: Reducers) => state.pacoSettingsReducer.settings);

    const handleLogoutClick = (): void => {
        dispatch(logout());
    };

    const handleHelpButtonClick = () => {
        dispatch(setHelpActiveState(!helpIsActive));
    };

    const handleStopHelpButtonClick = () => {
        const newTooltips = getTooltipsWithNewActiveIndex(-1, tooltips);
        dispatch(setHelpActiveState(false));
        dispatch(setTooltips(newTooltips));
    };

    return (
        <Navigation
            showHelpButton={activeRoute === helpActiveRoute}
            showHelpModal={helpIsActive}
            currentUser={currentUser}
            pathname={location.pathname}
            permissions={permissions}
            notifications={notifications}
            settings={settings}
            onLogoutClick={handleLogoutClick}
            onHelpButtonClick={handleHelpButtonClick}
            onStopHelpButtonClick={handleStopHelpButtonClick}
        />
    );
};

export default ConnectedNavigation;
