import React from 'react';

import PropTypes from 'prop-types';

import { TIME_MODES } from '../../../constants';
import { addWeeks, formatDate, startOfMonth } from '../../../helpers/date';
import Icon from '../../style/Icon/Icon';

export function getStartDate(startDate, mode) {
    if (mode === TIME_MODES.MONTH) {
        return startOfMonth(addWeeks(startDate, 1));
    }

    return startDate;
}

const Controls = (props) => {
    const {
        startDate,
        endDate,
        selectedDate,
        day,
        mode = null,
        isDisabled = false,
    } = props;

    return (
        <div className="date-controls">
            <button
                disabled={isDisabled}
                className="date-controls__button"
                type="button"
                onClick={() => props.onIncrement(false, day)}
            >
                <Icon kind="chevron_left" />
            </button>
            { day ? (
                <button
                    disabled={isDisabled}
                    type="button"
                    onClick={props.toggleDayPicker}
                    className="date-controls__button-picker"
                    id="date-controls-toggle"
                >
                    {formatDate(selectedDate, 'd MMM yyyy')}
                </button>
            ) : (
                <button
                    disabled={isDisabled}
                    type="button"
                    onClick={props.toggleDayPicker}
                    className="date-controls__button-picker"
                    id="date-controls-toggle"
                >
                    {formatDate(getStartDate(startDate, mode), 'd MMM')}
                    {' '}
                    -
                    {formatDate(endDate, 'd MMM')}
                    {' '}
                    {formatDate(endDate, 'yyyy')}
                </button>
            )}
            <button
                disabled={isDisabled}
                className="date-controls__button"
                type="button"
                onClick={() => props.onIncrement(true, day)}
            >
                <Icon kind="chevron_right" />
            </button>
        </div>
    );
};

Controls.propTypes = {
    day: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    mode: PropTypes.string,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    selectedDate: PropTypes.object.isRequired,
    onIncrement: PropTypes.func.isRequired,
    toggleDayPicker: PropTypes.func.isRequired,
};

export default Controls;
