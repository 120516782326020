import { Department } from '../../../../entities/Department/Department';
import trans from '../../../../helpers/trans';
import { DepartmentOption } from '../../../../types/selectOptionTypes';

export const getGroupDepartmentOption = (groupName: string, groupId: string): DepartmentOption => {
    const label = `${trans('common.group')} - ${groupName}`;

    return { label, value: groupId, isGroup: true } as DepartmentOption;
};

export const getDepartmentOptionsByGroupId = (departments: Department[], groupId: string): DepartmentOption[] => {
    const filteredDepartments = departments.filter(department => department.departmentGroup?.id === groupId);
    const groupDepartmentOption = getGroupDepartmentOption(filteredDepartments[0]?.departmentGroup?.name || '', groupId);

    const departmentsOptions: DepartmentOption[] = filteredDepartments.map(department => ({
        isDeleted: !!department.deletedAt,
        hasFullGroup: true,
        label: department.departmentGroup ? `${department.departmentGroup.name} - ${department.name}` : department.name,
        value: department.id,
    }));

    return [...departmentsOptions, groupDepartmentOption];
};
