import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, Icon, TableCell } from '../../../components';
import trans from '../../../helpers/trans';
import { HorizontalAlignment } from '../../../types/alignmentTypes';
import { SortDirection, SortField } from '../../../types/tableTypes';

import './SortLabel.scss';

export interface SortLabelInfo {
    label: string;
    field?: SortField;
    align?: HorizontalAlignment;
    colSpan?: number;
}

interface SortLabelProps extends SortLabelInfo {
    isActive?: boolean;
    isDisabled?: boolean;
    activeDirection: SortDirection;
    onSort: (field?: SortField, direction?: SortDirection) => void;
    className?: string;
    contentClassName?: string;
}

const SortLabel: FC<SortLabelProps> = ({
    isActive,
    isDisabled,
    label,
    field,
    align,
    colSpan,
    activeDirection,
    onSort,
    className = '',
    contentClassName = '',
}): ReactElement => {
    const isAscending = activeDirection === SortDirection.asc;

    const descriptiveLabel = trans('compositions.sortLabel.descriptiveLabel', {
        label,
        sortDirection: trans(`common.sort.${activeDirection.toLowerCase()}`),
    });

    const handleClick = (): void => {
        const newDirection: SortDirection = isAscending ? SortDirection.desc : SortDirection.asc;

        onSort(field, newDirection);
    };

    const sortLabelClassNames = classNames('sort-label', {
        'sort-label--is-active': isActive,
        'sort-label--is-disabled': isDisabled,
        [`sort-label--align-${align}`]: align,
    }, className);

    return (
        <TableCell
            align={align}
            colSpan={colSpan}
            className={sortLabelClassNames}
            contentClassName={contentClassName}
        >
            <Button
                text={isActive ? descriptiveLabel : label}
                onClick={field ? handleClick : undefined}
                className="sort-label__button"
            >
                {label}
                <Icon
                    name={`arrow-${isAscending ? 'down' : 'up'}`}
                    className="sort-label__icon"
                />
            </Button>
        </TableCell>
    );
};

export default SortLabel;
