import React, { FC, useMemo } from 'react';

import { Col, Row } from 'reactstrap';

import { translate } from '../../helpers/translations/translator';
import { Absence, LeaveOfAbsence, WeekWithHours } from '../../models';
import WeekRows from '../WeekHoursInputs/subcomponents/WeekRows/WeekRows';

import './LegacyHoursWarning.scss';


interface LegacyHoursWarningProps {
    weekWithHours: WeekWithHours[];
    type: Absence['type'] | LeaveOfAbsence['type'];
}

const LegacyHoursWarning: FC<LegacyHoursWarningProps> = ({ weekWithHours = [], type }) => {
    const legacyHours = useMemo(() => weekWithHours
        .filter(week => week.weekNumber === null), [weekWithHours]);

    if (!legacyHours.length) {
        return null;
    }

    return (
        <>
            <Row className="legacy-hours-warning__warning">
                <Col>
                    {translate('pages.absences.hoursWithoutWeekNumberWarning')}
                </Col>
            </Row>
            <WeekRows
                weeks={legacyHours}
                type={type}
                disabled
            />
            <Row className="legacy-hours-warning__title">
                <Col>
                    {type === 'leaveOfAbsences' ? (
                        translate('pages.absences.leaveOfAbsenceHours')
                    ) : translate('pages.absences.absenceHours')}
                </Col>
            </Row>
        </>
    );
};

export default LegacyHoursWarning;
