import { transformEventLogCategoryToFormOption } from '../../entities/EventLog/EventLogTransformers';
import { getEventLogCategoriesApiCall } from '../../entities/EventLogCategory/EventLogCategoryService';
import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import {
    setCategories,
    setCategoryOptions,
    setError,
    setIsLoading,
} from './pzLogCategoriesReducer';

export const fetchPzLogCategories = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getEventLogCategoriesApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const categoryOptions = response.data.map(transformEventLogCategoryToFormOption);
        dispatch(setCategories(response.data));
        dispatch(setCategoryOptions(categoryOptions));
    } catch (error) {
        console.error('[fetchPzLogCategories]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
