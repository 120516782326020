import { transformToPeriod } from '../Period/PeriodTransformers';
import { UserResource } from '../User/User';
import { ShiftsCalendarLeaveOfAbsence, ShiftsCalendarLeaveOfAbsenceResource } from './ShiftsCalendarLeaveOfAbsence';

export const transformToShiftsCalendarLeaveOfAbsence = (
    shiftsCalendarLeaveOfAbsenceResource: ShiftsCalendarLeaveOfAbsenceResource,
    userResource: UserResource,
): ShiftsCalendarLeaveOfAbsence => ({
    id: shiftsCalendarLeaveOfAbsenceResource.id,
    status: shiftsCalendarLeaveOfAbsenceResource.attributes.status,
    reason: shiftsCalendarLeaveOfAbsenceResource.attributes.reason,
    declineReason: shiftsCalendarLeaveOfAbsenceResource.attributes.declineReason,
    period: transformToPeriod(
        new Date(shiftsCalendarLeaveOfAbsenceResource.attributes.start),
        new Date(shiftsCalendarLeaveOfAbsenceResource.attributes.end),
    ),
    userId: userResource.id,
});
