import { FC, ReactElement, useEffect } from 'react';

import { AddTemporaryWorkerForm } from '../../../containers';
import { AddTemporaryWorkerFormData } from '../../../entities/TemporaryWorker/TemporaryWorker';
import trans from '../../../helpers/trans';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { addTemporaryWorker } from '../../../redux/temporaryWorker/temporaryWorkerActions';
import { setIsSuccessful } from '../../../redux/temporaryWorker/temporaryWorkerReducer';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedAddTemporaryWorkerFormProps {
    shiftId: string;
    onClose: () => void;
    className?: string;
}

const ConnectedAddTemporaryWorkerForm: FC<ConnectedAddTemporaryWorkerFormProps> = ({
    shiftId,
    onClose,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isLoading, isSuccessful } = useTypedSelector(state => state.temporaryWorkerReducer);

    const handleSubmit = (formData: AddTemporaryWorkerFormData): void => {
        dispatch(addTemporaryWorker(formData, shiftId));
    };

    useEffect((): void => {
        if (isSuccessful) {
            onClose();

            dispatch(setToast({
                text: trans('containers.forms.addTemporaryWorkerForm.addTemporaryWorkerSuccess'),
                type: ToastType.pass,
            }));
            dispatch(setIsSuccessful(false));
        }
    }, [isSuccessful]);

    return (
        <AddTemporaryWorkerForm
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onCancel={onClose}
            className={className}
        />
    );
};

export default ConnectedAddTemporaryWorkerForm;
