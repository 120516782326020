import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TOAST_TYPE_FAIL } from '../../constants';
import Toast from './Toast';

import './Toasts.scss';

const MAX_TOASTS = 3;

class Toasts extends Component {
    state = {
        toasts: [],
    };

    static getDerivedStateFromProps(props) {
        let toasts = props.toasts.map(toast => ({
            ...toast,
            autoFadeOut: toast.type !== TOAST_TYPE_FAIL,
        }));

        if (toasts.length <= MAX_TOASTS) {
            return {
                toasts,
            };
        }

        let failedToasts = 0;
        toasts = toasts
            .slice(0)
            .reverse()
            .map((toast) => {
                const autoFadeOut = failedToasts >= MAX_TOASTS;
                if (toast.type === TOAST_TYPE_FAIL) {
                    failedToasts += 1;
                }
                return {
                    ...toast,
                    autoFadeOut: toast.autoFadeOut || autoFadeOut,
                };
            })
            .reverse();

        return {
            toasts,
        };
    }

    render() {
        const { toasts } = this.state;
        return (
            <div className="toasts">
                {toasts.map((toast) => {
                    if (!toast || !toast.text) {
                        return null;
                    }

                    return (
                        <Toast
                            toast={toast}
                            key={toast.createdOn}
                        />
                    );
                })}
            </div>
        );
    }
}

Toasts.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    toasts: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        toasts: state.appReducer.toasts,
    };
}

export default connect(mapStateToProps)(Toasts);
