// TODO: Convert to components
// @ts-nocheck
import briefcase from '../../../../images/briefcase.svg';
import cloud from '../../../../images/cloud.svg';
import flag from '../../../../images/flag.svg';
import group from '../../../../images/group.svg';
import palm from '../../../../images/palm.svg';
import party from '../../../../images/party.svg';
import person from '../../../../images/person.svg';
import personBlack from '../../../../images/person-black.svg';
import sun from '../../../../images/sun.svg';

const getImageSource = (kind: string): string | undefined => {
    switch (kind) {
        case 'crowded':
            return cloud;
        case 'warm-weather':
            return palm;
        case 'party':
            return party;
        case 'holiday':
            return sun;
        case 'christmas':
            return group;
        case 'national-holiday':
            return flag;
        case 'business':
            return briefcase;
        case 'person':
            return person;
        case 'person-black':
            return personBlack;
        default:
            return undefined;
    }
};

export default getImageSource;
