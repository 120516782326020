import {
    ChangeEvent,
    FC,
    ReactElement,
    useRef,
} from 'react';

import { Input, InputLabel } from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';
import ErrorLabel from '../../../components/ErrorLabel/ErrorLabel';

import './NumberInput.scss';

interface NumberInputProps extends Omit<HTMLInputProps, 'onChange'> {
    label: string;
    hideLabel?: boolean;
    requiredTextIsAlwaysVisible?: boolean;
    requiredText?: string;
    requiredClassName?: string;
    onChange: (value: number) => void;
}

const NumberInput: FC<NumberInputProps> = ({
    label,
    hideLabel,
    requiredTextIsAlwaysVisible = false,
    tabIndex,
    required,
    requiredText = '',
    disabled,
    error = '',
    onChange,
    className = '',
    requiredClassName = '',
    ...inputProps
}): ReactElement => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => onChange(Number(event.currentTarget.value));
    const handleFocus = (event: ChangeEvent<HTMLInputElement>): void => event.currentTarget.select();

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={`number-input ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    text={label}
                    requiredTextIsAlwaysVisible={requiredTextIsAlwaysVisible}
                    required={required}
                    requiredText={requiredText}
                    className="number-input__label"
                    requiredClassName={requiredClassName}
                />
            )}
            <Input
                {...inputProps}
                ref={inputRef}
                type="number"
                tabIndex={tabIndex}
                disabled={disabled}
                error={error}
                onChange={handleChange}
                onFocus={handleFocus}
                className="number-input__field"
            />
            {error && <ErrorLabel text={error} className="number-input__error-label" />}
        </label>
    );
};

export default NumberInput;
