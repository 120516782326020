import { EditShiftPlanningTimeFormData } from '../../../models';
import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { Comment } from '../Comment/Comment';
import { ShiftPlanningShiftConcept } from '../ShiftConcept/ShiftConcept';
import { ShiftPlanningWithShiftConcept } from '../ShiftPlanning/ShiftPlanning';
import { transformToShiftPlanning } from '../ShiftPlanning/ShiftPlanningTransformers';
import { ShiftPlanningUser } from '../User/User';
import { EditShiftConceptPlanningRequest, ShiftConceptPlanningResource } from './ShiftConceptPlanning';

export const transformToShiftPlanningWithShiftConcept = (
    resource: ShiftConceptPlanningResource,
    user: ShiftPlanningUser,
    shift: ShiftPlanningShiftConcept,
    comments: Comment[] = [],
): ShiftPlanningWithShiftConcept => ({
    ...transformToShiftPlanning(resource, user, comments),
    shift,
});

export const transformEditShiftPlanningTimeFormDataToEditShiftConceptPlanningRequest = (
    data: EditShiftPlanningTimeFormData,
    shiftConceptPlanningId: string,
): EditShiftConceptPlanningRequest => ({
    type: 'shiftConceptPlannings',
    id: shiftConceptPlanningId,
    attributes: {
        start: formatDate(data.period.start, DateFormat.apiDateTime),
        end: formatDate(data.period.end, DateFormat.apiDateTime),
    },
});
