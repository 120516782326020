import { unique } from 'underscore';

import { formatDate } from '../../../helpers/date';
import { TrackViewModel } from '../../../models/Track';

export default function getTracksByDate(
    tracks: TrackViewModel[],
): { [date: string]: TrackViewModel[] } {
    const format = 'yyyy-MM-dd';
    const dates = unique(tracks.map((track) => formatDate(track.checkIn, format)));
    return dates.reduce((total: { [date: string]: TrackViewModel[] }, date: string) => ({
        ...total,
        [date]: tracks.filter(track => formatDate(track.checkIn, format) === date),
    }), {});
}
