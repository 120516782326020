import { FC, ReactElement } from 'react';

import { InputLabel } from '../../../components';
import { CheckboxList } from '../../../compositions';
import trans from '../../../helpers/trans';
import { FormOption } from '../../../types';

interface EmploymentTypesFilterContainerProps {
    employmentTypeOptions: FormOption[];
    onChange: (selectedEmploymentTypeOptions: FormOption[]) => void;
    className?: string;
}

const EmploymentTypesFilterContainer: FC<EmploymentTypesFilterContainerProps> = ({
    employmentTypeOptions,
    onChange,
    className = '',
}): ReactElement => {
    const selectedEmploymentTypeOptions = employmentTypeOptions.filter(employmentTypeOption => employmentTypeOption.isChecked);

    const handleOptionsChange = (checkedOptionValues: string[]): void => {
        const changedOptions = employmentTypeOptions.map(employmentTypeOption => ({
            ...employmentTypeOption,
            isChecked: checkedOptionValues.some(checkedOptionValue => checkedOptionValue === employmentTypeOption.value),
        }));

        onChange(changedOptions);
    };

    return (
        <div className={`employment-types-filter-container ${className}`}>
            <InputLabel text={trans('common.employmentType')} />
            <CheckboxList
                hideLabel
                label={trans('common.employmentType')}
                name={trans('common.employmentType')}
                options={employmentTypeOptions}
                value={selectedEmploymentTypeOptions.map(option => option.value)}
                onChange={handleOptionsChange}
            />
        </div>
    );
};

export default EmploymentTypesFilterContainer;
