import { PzLeaveType } from '../@paco/types/leaveType';
import { PayrollPeriodResource, PayrollPeriodViewModel } from './PayrollPeriod';
import { Resource } from './Resource';

export enum LeaveType {
    normal = 'normal',
    special = 'special',
    tvt = 'time-for-time',
    unpaid = 'unpaid',
}

export interface LeaveOfAbsenceHoursResource extends Resource {
    type: 'absenceHours';
    hours: number;
    weekNumber: number;
    leaveType: string;
    payrollPeriod?: PayrollPeriodResource;
}

export interface LeaveOfAbsenceHoursViewModel {
    hours: number;
    weekNumber: number;
    leaveType: LeaveType | PzLeaveType;
    payrollPeriod?: PayrollPeriodResource;
}

export interface LeaveOfAbsenceHours extends Resource {
    type: 'leaveOfAbsenceHours';
    hours: number;
    weekNumber: number;
    leaveType: LeaveType | PzLeaveType;
    payrollPeriod: PayrollPeriodViewModel;
    loketIds?: string[];
}
