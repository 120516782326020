import React, { FC } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';


interface FilterProps {
    filter: AppFilter;
    onChange: (filter: AppFilter) => void;
}

const ShowInactiveUsersFilter: FC<FilterProps> = ({ filter, onChange }) => {
    const onCheckboxChange = (showInactiveUsers: boolean) => {
        const management = {
            ...filter.management,
            showInactiveUsers,
        };

        onChange({
            ...filter,
            management,
        });
    };

    return (
        <FilterBlock title={translate('pages.management.inactiveUsers')}>
            <FormGroup check className="p-0">
                <Label check>
                    <CustomInput
                        id="inactive-users-filter-checkbox"
                        type="checkbox"
                        label={translate('pages.management.showInactiveUsers')}
                        checked={filter.management.showInactiveUsers}
                        onChange={() => onCheckboxChange(!filter.management.showInactiveUsers)}
                    />
                </Label>
            </FormGroup>
        </FilterBlock>
    );
};

export default ShowInactiveUsersFilter;
