import React, { FC, ReactElement } from 'react';

import { NavLink } from 'react-router-dom';

import { transformToPeriod } from '../../../../../../@paco/entities/Period/PeriodTransformers';
import { Track } from '../../../../../../@paco/entities/Track/Track';
import UserColor from '../../../../../../components/UserColor/UserColor';
import { translate } from '../../../../../../helpers/translations/translator';
import { Shift, ShiftPlanning } from '../../../../../../models';
import UserShiftTrackTime from '../UserShiftTrackTime/UserShiftTrackTime';

import './UserShift.scss';

const getUserShiftPlanning = (
    userId: string,
    shiftPlannings: ShiftPlanning[],
): ShiftPlanning | undefined => shiftPlannings
    .find((shiftPlanning) => shiftPlanning.user.id === userId);

interface UserShiftProps {
    shift: Shift;
    track?: Track;
    userId: string;
}

const UserShift: FC<UserShiftProps> = ({ shift, track, userId }): ReactElement => {
    const userPlanning = getUserShiftPlanning(userId, shift.shiftPlannings);
    const isUserPlanned = userPlanning && userPlanning.planned;
    const color = userPlanning && userPlanning.color;
    const pathCalendar = `/${translate('nav.shifts.link')}/${translate('nav.shifts.schedule.link')}/${shift.id}`;
    const departmentGroupName = shift.department?.group?.name;
    const shiftPeriod = transformToPeriod(new Date(userPlanning?.start || shift.start), new Date(userPlanning?.end || shift.end));

    return (
        <NavLink
            className={`calendar-shift calendar-user-shift ${isUserPlanned ? 'calendar-user-shift--planned' : 'calendar-user-shift--unplanned'}`}
            to={{ pathname: pathCalendar }}
        >
            <div className="calendar-shift-inner">
                <UserShiftTrackTime
                    shiftPeriod={shiftPeriod}
                    trackPeriod={isUserPlanned ? track?.billablePeriod : undefined}
                    className="calendar-user-shift__shift-track-time"
                />
                <div className="calendar-shift-department">
                    { color && <UserColor className="calendar-shift__user-color" size="tiny" color={color} /> }
                    { departmentGroupName && (
                        <span className="calendar-shift-department-group">
                            {`${departmentGroupName} -`}
                        </span>
                    )}
                    { shift.department.name }
                </div>
            </div>
        </NavLink>
    );
};

export default UserShift;
