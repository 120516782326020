import { FC } from 'react';

import packageJson from '../../../../../../package.json';
import { Skeleton } from '../../../../components';
import trans from '../../../../helpers/trans';

interface ChangeLogWidgetSkeletonProps {
    className?: string;
}

const ChangeLogWidgetSkeleton: FC<ChangeLogWidgetSkeletonProps> = ({ className = '' }) => (
    <div className={`change-log-widget change-log-widget--is-loading ${className}`}>
        <h2 className="change-log-widget__title">
            {trans('compositions.dashboardChangeLogWidget.title')}
        </h2>
        <div className="change-log-widget__container">
            <h3 className="change-log-widget__sub-title">
                {trans('compositions.dashboardChangeLogWidget.currentAppVersion')}
            </h3>
            {`v${packageJson.version}`}

            <div className="change-log-widget__list">
                <h3 className="change-log-widget__sub-title">
                    {trans('compositions.dashboardChangeLogWidget.recentUpdates')}
                </h3>
                <Skeleton className="change-log-widget__list-item change-log-widget__list-item-skeleton" />
                <Skeleton className="change-log-widget__list-item change-log-widget__list-item-skeleton" />
                <Skeleton className="change-log-widget__list-item change-log-widget__list-item-skeleton" />
                <Skeleton className="change-log-widget__list-item change-log-widget__list-item-skeleton" />
            </div>
        </div>
    </div>
);

export default ChangeLogWidgetSkeleton;
