import { DepartmentViewModel } from '../../../../../entities/Departments/Departments';
import { ShiftConceptViewModelOld } from '../../../../../entities/ShiftConcept/ShiftConcept';
import { getShiftConceptsApiCall } from '../../../../../entities/ShiftConcept/ShiftConceptService';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../../../../helpers/date';

export default function getShiftConceptsForDepartmentsOnDate(
    departments: DepartmentViewModel[],
    date: string | Date,
): Promise<ShiftConceptViewModelOld[]> {
    const departmentIds = departments.map((department) => department.id);

    return getShiftConceptsApiCall(
        getStartOfWorkDay(date),
        getEndOfWorkDay(date),
        {},
        departmentIds,
        [
            'department',
            'department.group',
            'shiftConceptPlannings',
            'shiftConceptPlannings.user',
            'shiftConceptPlannings.user.person',
        ],
    );
}
