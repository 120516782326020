import { DepartmentResource } from '../../entities/Departments/Departments';
import {
    DepartmentAccessResource,
    DocumentTypeResource,
    FileResource,
    Role,
    RoleAccessResource,
    UserDocumentViewModel,
    UserResource,
} from '../../models';

export default function transformFileResourceToUserDocument(
    file: FileResource,
    userId: string,
    userDepartments: DepartmentResource[],
    userRoles: Role[],
    documentType: DocumentTypeResource,
    viewers: UserResource[] = [],
    departmentAccess: DepartmentAccessResource[] = [],
    roleAccess: RoleAccessResource[] = [],
): UserDocumentViewModel {
    const viewerIds = viewers.map(viewer => viewer.id);
    const userHasDepartmentAccess = departmentAccess
        // eslint-disable-next-line max-len
        .some(department => userDepartments.some(userDepartment => userDepartment.id === department.id));
    const userHasRoleAccess = roleAccess
        .some(role => userRoles.some(userRole => userRole.id === role.id));

    return {
        id: file.id,
        isLoketFile: false,
        isDataUrl: true,
        displayName: file.displayName,
        userCanView: userHasDepartmentAccess || userHasRoleAccess || viewerIds.includes(userId),
        createdAt: new Date(file.createdAt),
        documentType,
        fileName: file.fileName,
        downloadUrl: `data:${file.mimeType};base64,${file.content}`,
        viewers: viewerIds,
    };
}
