import { FC, useMemo, useState } from 'react';

import classnames from 'classnames';

import {
    Button,
    Icon,
    LoadingSpinner,
    TableCell,
    TableRow,
} from '../../../components';
import { IconButton, SortValkTable } from '../../../compositions';
import { SortLabelInfo } from '../../../compositions/@tables/SortLabel/SortLabel';
import { DropboxEntity, DropboxEntityType } from '../../../entities/DropboxEntity/DropboxEntity';
import { sortDropboxEntities } from '../../../entities/DropboxEntity/DropboxEntityHelpers';
import { generateDownloadURI } from '../../../helpers/browser';
import { formatDate } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { DateFormat } from '../../../types/dateFormatTypes';
import { SortConfig, SortDirection, SortField } from '../../../types/tableTypes';

import './KnowledgeBaseTable.scss';

interface KnowledgeBaseTableProps {
    isLoading: boolean;
    dropboxEntities: DropboxEntity[];
    onFolderClick: (id: string) => void;
    onViewFileClick: (id: string) => void;
    className?: string;
}

const KnowledgeBaseTable: FC<KnowledgeBaseTableProps> = ({
    isLoading,
    dropboxEntities,
    onFolderClick,
    onViewFileClick,
    className = '',
}) => {
    const [sortConfig, setSortConfig] = useState<SortConfig>({ field: SortField.name, direction: SortDirection.desc });

    const sortedDropboxFiles = useMemo(
        () => sortDropboxEntities([...dropboxEntities], sortConfig.field, sortConfig.direction),
        [dropboxEntities, sortConfig.field, sortConfig.direction],
    );

    const wrapperClassName = classnames('knowledge-base-table', {
        'knowledge-base-table--is-loading': isLoading,
    }, className);

    const sortCells: SortLabelInfo[] = [
        { label: trans('common.name'), field: SortField.name },
        { label: trans('common.size'), field: SortField.size },
        { label: trans('common.dateAdded'), field: SortField.dateAdded },
        { label: trans('common.action') },
    ];

    const handleDownloadButtonClick = (dropboxFile: DropboxEntity): void => {
        generateDownloadURI(dropboxFile.link, dropboxFile.name);
    };

    const handleViewButtonClick = (dropboxEntity: DropboxEntity): void => {
        onViewFileClick(dropboxEntity.id);
    };

    const handleRowButtonClick = (dropboxEntity: DropboxEntity): void => {
        if (dropboxEntity.type === DropboxEntityType.file) {
            handleViewButtonClick(dropboxEntity);

            return;
        }

        onFolderClick(dropboxEntity.id);
    };

    const handleTableSort = (field: SortField = SortField.name, direction = SortDirection.desc): void => {
        setSortConfig({ field, direction });
    };

    return (
        <div className={wrapperClassName}>
            <SortValkTable<DropboxEntity>
                rows={sortedDropboxFiles}
                sortCells={sortCells}
                sortConfig={sortConfig}
                cellClassName="knowledge-base-table__cell"
                cellContentClassName="knowledge-base-table__cell-content"
                className="knowledge-base-table__valk-table"
                headClassName="knowledge-base-table__table-head"
                headerRowClassName="knowledge-base-table__table-head-row"
                tableClassName="knowledge-base-table__table"
                onSort={handleTableSort}
            >

                {(dropboxFileRows: DropboxEntity[]): JSX.Element[] => dropboxFileRows.map(dropboxEntity => (
                    <TableRow key={dropboxEntity.id} className="knowledge-base-table__row">
                        <TableCell
                            className="knowledge-base-table__cell"
                            contentClassName="knowledge-base-table__cell-content"
                        >
                            <Icon
                                name={dropboxEntity.type}
                                className="knowledge-base-table__file-icon"
                            />
                            {dropboxEntity.name}
                        </TableCell>
                        <TableCell
                            className="knowledge-base-table__cell"
                            contentClassName="knowledge-base-table__cell-content"
                        >
                            {dropboxEntity.readableSize}
                        </TableCell>
                        <TableCell
                            className="knowledge-base-table__cell"
                            contentClassName="knowledge-base-table__cell-content"
                        >
                            {dropboxEntity.modifiedAt ? formatDate(dropboxEntity.modifiedAt, DateFormat.date) : undefined}
                        </TableCell>
                        {dropboxEntity.type === DropboxEntityType.file && (
                            <TableCell
                                className="knowledge-base-table__buttons-cell"
                            >
                                <IconButton
                                    hideLabel
                                    icon="download"
                                    text={trans('common.download')}
                                    className="knowledge-base-table__button"
                                    iconClassName="knowledge-base-table__button-icon"
                                    onClick={() => handleDownloadButtonClick(dropboxEntity)}
                                />
                                <IconButton
                                    hideLabel
                                    icon="eye"
                                    text={trans('common.open')}
                                    className="knowledge-base-table__button"
                                    iconClassName="knowledge-base-table__button-icon"
                                    onClick={() => handleViewButtonClick(dropboxEntity)}
                                />
                            </TableCell>
                        )}
                        <Button
                            hideLabel
                            text={trans('common.open')}
                            onClick={() => handleRowButtonClick(dropboxEntity)}
                            className="knowledge-base-table__row-button"
                        />
                    </TableRow>
                ))}
            </SortValkTable>
            {isLoading && <LoadingSpinner isSmall className="knowledge-base-table__loading-spinner" />}
        </div>
    );
};

export default KnowledgeBaseTable;
