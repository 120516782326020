import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BaseScheduleShiftViewModel } from '../../../entities/BaseScheduleShift/BaseScheduleShift';
import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';

export type BaseScheduleShiftState = AsyncReduxState<{
    isLoadingPlanning: boolean,
    baseScheduleShift?: BaseScheduleShiftViewModel;
}>;

const initialState: BaseScheduleShiftState = {
    ...initialAsyncReduxState,
    isLoadingPlanning: false,
};

export const BaseScheduleShiftSlice = createSlice({
    name: 'baseScheduleShiftReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): BaseScheduleShiftState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setIsLoadingPlanning(state, action: PayloadAction<boolean>): BaseScheduleShiftState {
            return ({
                ...state,
                isLoadingPlanning: action.payload,
            });
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): BaseScheduleShiftState {
            return ({
                ...state,
                isSuccessful: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): BaseScheduleShiftState {
            return ({
                ...state,
                error: action.payload,
            });
        },
        setBaseScheduleShift(state, action: PayloadAction<BaseScheduleShiftViewModel | undefined>): BaseScheduleShiftState {
            return ({
                ...state,
                baseScheduleShift: action.payload,
            });
        },
    },
});

export const {
    setBaseScheduleShift,
    setError,
    setIsLoading,
    setIsLoadingPlanning,
    setIsSuccessful,
} = BaseScheduleShiftSlice.actions;

export default BaseScheduleShiftSlice.reducer;
