import { DepartmentGroupResource } from '../../../../entities/Departments/Departments';
import { translate } from '../../../../helpers/translations/translator';
import { DepartmentOption } from '../../SelectDepartments/models/DepartmentOption';

export default function transformDepartmentGroupToSelectOption(
    group: DepartmentGroupResource,
): DepartmentOption {
    return {
        isGroup: true,
        label: `${translate('common.group')} - ${group.name}`,
        value: group.id,
    };
}
