import axios from 'axios';

const getExperiences = () => axios.get(
    `${process.env.REACT_APP_API_URL}/experiences/`,
);

const getEmploymentTypes = () => axios.get(
    `${process.env.REACT_APP_API_URL}/employment-types/`,
);

const getRoles = () => axios.get(
    `${process.env.REACT_APP_API_URL}/roles/`,
);

const getGroups = () => axios.get(
    `${process.env.REACT_APP_API_URL}/groups/`,
);

export {
    getExperiences,
    getEmploymentTypes,
    getRoles,
    getGroups,
};
