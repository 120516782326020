import { LegacyAddShiftFormData, Shift } from '../../models';
import {
    SHIFTS_SAGA_CALENDAR_COPY_SHIFT,
    SHIFTS_SAGA_CREATE_SHIFT,
    SHIFTS_SAGA_GET_USERS_WITH_AVAILABILITY,
    SHIFTS_SAGA_GET_USERS_WITH_LOKET_BALANCES,
    SHIFTS_SAGA_PLAN_AVAILABILITY_USER_TO_SHIFT,
} from '../actionTypes';
import {
    AddShiftAction,
    CopyShiftAction,
    GetUsersWithAvailabilityAction,
    GetUsersWithLoketBalancesAction,
    PlanAvailabilityUserToShiftAction,
} from './shiftsModels';

export const addShift = (formData: LegacyAddShiftFormData): AddShiftAction => ({
    type: SHIFTS_SAGA_CREATE_SHIFT, formData,
});

export const copyShift = (
    shift: Shift,
    newDate: string,
    copyEmployees: boolean,
): CopyShiftAction => ({
    type: SHIFTS_SAGA_CALENDAR_COPY_SHIFT, shift, newDate, copyEmployees,
});

export const getUsersWithAvailability = (
    canViewAbsences = true,
    canViewLeaveOfAbsences = true,
): GetUsersWithAvailabilityAction => ({
    type: SHIFTS_SAGA_GET_USERS_WITH_AVAILABILITY,
    canViewAbsences,
    canViewLeaveOfAbsences,
});

export const planAvailabilityUserToShift = (
    shiftId: string,
    userId: string,
): PlanAvailabilityUserToShiftAction => ({
    type: SHIFTS_SAGA_PLAN_AVAILABILITY_USER_TO_SHIFT,
    shiftId,
    userId,
});

export const getUsersWithLoketBalances = (): GetUsersWithLoketBalancesAction => ({
    type: SHIFTS_SAGA_GET_USERS_WITH_LOKET_BALANCES,
});
