import { FC, ReactElement } from 'react';

import { FilterSection } from '../../../components';
import { ConnectedPzLogCategoriesFilter, ConnectedResetFiltersButton } from '../../../connectors';

interface PzLogsFilterContainerProps {
    className?: string;
}

const PzLogsFilterContainer: FC<PzLogsFilterContainerProps> = ({ className = '' }): ReactElement => (
    <div className={`pz-logs-filter-container ${className}`}>
        <FilterSection>
            <ConnectedResetFiltersButton />
        </FilterSection>
        <FilterSection>
            <ConnectedPzLogCategoriesFilter />
        </FilterSection>
    </div>
);

export default PzLogsFilterContainer;
