import { ReactElement } from 'react';

import classnames from 'classnames';

import './LegendColor.scss';

export interface LegendColorProps<T> {
    title: string;
    type: T;
    className?: string;
}

const LegendColor = <T, >({
    title,
    type,
    className,
}: LegendColorProps<T>): ReactElement => {
    const classNames = classnames('legend-color', {
        [`legend-color--is-${type}`]: type,
    }, className);

    return (
        <div className={classNames}>
            <div className="legend-color__color-block" />
            {title}
        </div>
    );
};

export default LegendColor;
