import React, { FC, useEffect, useState } from 'react';

import { CustomInput, Label } from 'reactstrap';

import { CheckboxWithTextCallback, CheckboxWithTextParams } from '../../models';

import './CheckboxWithText.scss';

interface Props extends CheckboxWithTextParams {
    onChange: CheckboxWithTextCallback;
}

const CheckboxWithText: FC<Props> = ({
    id,
    checked,
    label,
    onChange,
    noValue,
    ...props
}) => {
    const [value, setValue] = useState<string | number>('');

    const onCheckboxChange = () => {
        onChange(id, !checked, value);
    };

    const onNumberInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
        onChange(id, checked, e.currentTarget.value);
    };

    const onNumberInputBlur = () => {
        onChange(id, checked, value);
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <div
            key={`checkbox-with-text-${id}`}
            className={`checkbox-with-text checkbox-with-text--${checked ? 'checked' : 'unchecked'} form-control-group`}
        >
            <Label
                check
                className="checkbox-with-text__input-checkbox"
            >
                <CustomInput
                    type="checkbox"
                    id={`checkbox-${id}`}
                    checked={checked}
                    onChange={onCheckboxChange}
                >
                    {label}
                </CustomInput>
            </Label>
            { !noValue && (
                <CustomInput
                    disabled={!checked}
                    // @ts-ignore
                    type="number"
                    className="checkbox-with-text__input-number"
                    id={`checkbox-text-${id}`}
                    value={value}
                    onChange={onNumberInputChange}
                    onBlur={onNumberInputBlur}
                >
                    {value}
                </CustomInput>
            )}
        </div>
    );
};

export default CheckboxWithText;
