import React, { FC } from 'react';

import classnames from 'classnames';

import Icon from '../../../style/Icon/Icon';

import './ExchangeRequestButton.scss';


interface ExchangeRequestButtonProps {
    type: 'accept' | 'decline';
    onClick?: () => void;
    className?: string;
}

const ExchangeRequestButton: FC<ExchangeRequestButtonProps> = ({
    type,
    onClick,
    className,
}) => {
    const classNames = classnames('exc-request-button', {
        'exc-request-button--is-disabled': !onClick,
        [`exc-request-button--type-${type}`]: type,
    }, [className]);
    const icon = type === 'accept' ? 'check' : 'close';

    return (
        <button
            className={classNames}
            type="button"
            disabled={!onClick}
            onClick={onClick}
        >
            <Icon className="exc-request-button__icon" kind={icon} fontSize={24} />
        </button>
    );
};

export default ExchangeRequestButton;
