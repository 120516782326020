import React, { FC } from 'react';

import { ConnectedHelpTooltip } from '../../../../@paco/connectors';
import trans from '../../../../@paco/helpers/trans';
import { TimeModeType } from '../../../../@paco/types';
import NewFilters from '../../../../components/Filters/Filters';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import SidebarPage from '../../../../components/SidebarPage/SidebarPage';
import { TIME_MODES } from '../../../../constants';
import { translate } from '../../../../helpers/translations/translator';
import { EmploymentType, PayrollPeriodViewModel, Role } from '../../../../models';
import { UserWithHourBalances } from '../../../../models/UserWithHourBalances';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import CalendarToolbar from '../../components/CalendarToolbar/CalendarToolbar';
import UsersWithHourBalancesTable from '../../components/UsersWithHourBalancesTable/UsersWithHourBalancesTable';

interface UsersWithHourBalancesOverviewProps {
    loading: boolean;
    timeMode: TimeModeType;
    users: UserWithHourBalances[];
    payrollPeriods: PayrollPeriodViewModel[];
    employmentTypes: EmploymentType[];
    onSetFilterChange: (filter: AppFilter) => void;
    onSearchBarChange: (search: string) => void;
    onAfterDateChange: () => void;
    onSetStartAndEndDate: (selectedDate: Date, newStartDate: Date, newEndDate: Date) => void;
    onPaginationChange: () => void;
    roles: Role[];
    filter: any;
    pagination: any;
}

const UsersWithHourBalancesOverview: FC<UsersWithHourBalancesOverviewProps> = ({
    loading,
    timeMode,
    users,
    payrollPeriods,
    employmentTypes,
    roles,
    filter,
    pagination,
    onSetFilterChange,
    onSearchBarChange,
    onAfterDateChange,
    onSetStartAndEndDate,
    onPaginationChange,
}) => (
    <SidebarPage
        sidebarContent={(
            <NewFilters
                roles={roles}
                employmentTypes={employmentTypes}
                filter={filter}
                filterBlocks={[
                    'user-types',
                    'contract-types',
                    'main-department',
                ]}
                onChange={onSetFilterChange}
            />
        )}
    >
        <div className="calendar">
            <ConnectedHelpTooltip
                index={0}
                route="hours"
                subTitle={trans('help.hours.filters.title')}
                text={trans('help.hours.filters.text')}
                title={trans('help.hours.title')}
                showMobileInfoWarning
                className="calendar__filters-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={1}
                route="hours"
                subTitle={trans('help.hours.overview.title')}
                text={trans('help.hours.overview.text')}
                title={trans('help.hours.title')}
                className="calendar__hours-overview-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={2}
                route="hours"
                subTitle={trans('help.hours.viewOptions.title')}
                text={trans('help.hours.viewOptions.text')}
                title={trans('help.hours.title')}
                showMobileInfoWarning
                className="calendar__view-options-help-tooltip"
            />
            <div className="calendar-overview-top">
                <CalendarToolbar
                    options={[
                        {
                            label: translate('common.week'),
                            value: TIME_MODES.WEEK,
                        },
                        {
                            label: translate('common.period'),
                            value: TIME_MODES.PERIOD,
                        },
                    ]}
                    payrollPeriods={payrollPeriods}
                    timeMode={timeMode}
                    setStartAndEndDate={onSetStartAndEndDate}
                    afterDateChange={onAfterDateChange}
                />
                <div className="calendar-overview-top-right">
                    <ConnectedHelpTooltip
                        index={3}
                        route="hours"
                        subTitle={trans('help.hours.search.title')}
                        text={trans('help.hours.search.text')}
                        title={trans('help.hours.title')}
                        className="calendar__search-help-tooltip"
                    />
                    <SearchBar
                        placeholder={translate('common.searchEmployee')}
                        startVal={filter.employeeSearch}
                        onChange={onSearchBarChange}
                    />
                </div>
            </div>
            <div>
                <ItemsList
                    loading={loading}
                    itemsLength={users.length}
                    pagination={pagination}
                    emptyMessage={translate('pages.management.emptyList')}
                    onPagination={onPaginationChange}
                >
                    <UsersWithHourBalancesTable loading={loading} users={users} />
                </ItemsList>
            </div>
        </div>
    </SidebarPage>
);

export default UsersWithHourBalancesOverview;
