import { formatDate } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import { AbsenceViewModel } from '../../models';

const getAbsencesTooltipText = (absence: AbsenceViewModel): string => {
    const format = 'EEEE dd-MM';
    const start = formatDate(absence.start, format);
    const end = absence.end && formatDate(absence.end.date, format);
    return end ? translate('pages.absences.calledInSickFromTo', { start, end })
        : translate('pages.absences.calledInSickFrom', { start });
};

export default getAbsencesTooltipText;
