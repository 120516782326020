import { EditShiftPlanningTimeFormData } from '../../../models';
import { authorizedFetch } from '../../helpers/authorizedFetch';
import { formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { DateFormat } from '../../types/dateFormatTypes';
import { BaseScheduleShiftPlanningResource } from '../BaseScheduleShiftPlanning/BaseScheduleShiftPlanning';
import { CommentResource } from '../Comment/Comment';
import { transformToComment } from '../Comment/CommentTransformers';
import { DepartmentResource } from '../Department/Department';
import { transformToDepartment } from '../Department/DepartmentTransformers';
import { DepartmentGroupResource } from '../DepartmentGroup/DepartmentGroup';
import { EmploymentTypeResource } from '../EmploymentType/EmploymentType';
import { getDummyEmploymentTypeResource } from '../EmploymentType/EmploymentTypeHelpers';
import { transformToEmploymentType } from '../EmploymentType/EmploymentTypeTransformers';
import { ExperienceResource } from '../Experience/Experience';
import { getDummyExperienceResource } from '../Experience/ExperienceHelpers';
import { transformToExperience } from '../Experience/ExperienceTransformers';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { ShiftResource } from '../Shift/Shift';
import { transformToShiftPlanningShift } from '../Shift/ShiftTransformers';
import { ShiftConceptPlanningResource } from '../ShiftConceptPlanning/ShiftConceptPlanning';
import { ShiftIndexUserResource, UserResource } from '../User/User';
import { getDummyUserResource } from '../User/UserHelpers';
import { transformToShiftPlanningUser } from '../User/UserTransformers';
import {
    EditShiftPlanningColorFormData,
    EditShiftPlanningPlannedFormData,
    ShiftPlanning,
    ShiftPlanningResource,
    ShiftPlanningWithShift,
} from './ShiftPlanning';
import {
    transformEditShiftPlanningFormDataToEditShiftPlanningRequest,
    transformToAddShiftPlanningRequest,
    transformToShiftPlanning,
    transformToShiftPlanningWithShift,
} from './ShiftPlanningTransformers';

export const getShiftPlanningResourceIncludesAndTransformToShiftPlanning = (
    doc: JapiDocument,
    shiftPlanningResource: ShiftPlanningResource | ShiftConceptPlanningResource | BaseScheduleShiftPlanningResource,
): ShiftPlanning | null => {
    const userResource = getIncluded(doc, shiftPlanningResource, 'user') as ShiftIndexUserResource;
    const commentsResource = (getMultipleIncluded(doc, shiftPlanningResource, 'comments') || []) as CommentResource[];
    const employmentTypeResource = getIncluded(doc, userResource, 'employmentType') as EmploymentTypeResource;
    const experienceResource = getIncluded(doc, userResource, 'experience') as ExperienceResource;
    const comments = commentsResource.map(commentResource => {
        const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

        if (!commentOwnerResource) {
            console.error(`[getShiftPlanningResourceIncludesAndTransformToShiftPlanning] Missing owner resource in comment with id ${commentResource?.id} (${commentResource?.attributes?.body})`);
        }

        return transformToComment(
            commentResource,
            commentOwnerResource || getDummyUserResource(`${commentResource.id}-user`),
        );
    });

    if (!userResource) {
        console.error('[getShiftPlanningResourceIncludesAndTransformToShiftPlanning] Missing user resource');
        return null;
    }

    if (!employmentTypeResource) {
        console.error(`[getShiftPlanningResourceIncludesAndTransformToShiftPlanning] Missing employmentType resource in user with id ${userResource?.id} (${userResource?.attributes?.firstname})`);
    }

    if (!experienceResource) {
        console.error(`[getShiftPlanningResourceIncludesAndTransformToShiftPlanning] Missing experience resource in user with id ${userResource?.id} (${userResource?.attributes?.firstname})`);
    }

    const employmentType = transformToEmploymentType(employmentTypeResource || getDummyEmploymentTypeResource(`${userResource.id}-employment-type`));
    const experience = transformToExperience(experienceResource || getDummyExperienceResource(`${userResource.id}-experience`));
    const shiftPlanningUser = transformToShiftPlanningUser(userResource, employmentType, experience);

    return transformToShiftPlanning(shiftPlanningResource, shiftPlanningUser, comments);
};

export const editShiftPlanningApiCall = async (
    shiftPlanningFormData: EditShiftPlanningColorFormData | EditShiftPlanningPlannedFormData | EditShiftPlanningTimeFormData,
    shiftPlanningId: string,
): Promise<FetchResult<ShiftPlanningWithShift, string>> => {
    const apiData = transformEditShiftPlanningFormDataToEditShiftPlanningRequest(shiftPlanningFormData);

    try {
        const includes = [
            'user',
            'user.employmentType',
            'user.experience',
            'shift',
            'shift.department',
            'comments',
            'comments.owner',
        ];

        const url = generateApiUrl({
            endpoint: `/shift/plannings/${shiftPlanningId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ShiftPlanningResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: doc.errors[0]?.detail || trans('errors.unknownError'),
            };
        }

        const userResource = getIncluded(doc, doc.data, 'user') as ShiftIndexUserResource;
        const shiftResource = getIncluded(doc, doc.data, 'shift') as ShiftResource;
        const commentsResource = (getMultipleIncluded(doc, doc.data, 'comments') || []) as CommentResource[];
        const departmentResource = getIncluded(doc, shiftResource, 'department') as DepartmentResource;
        const employmentTypeResource = getIncluded(doc, userResource, 'employmentType') as EmploymentTypeResource;
        const experienceResource = getIncluded(doc, userResource, 'experience') as ExperienceResource;

        // TODO: Missing resources bug. BE should fix this.
        if (!employmentTypeResource) {
            console.error(`[editShiftPlanningTimeApiCall] Missing employmentType resource in user with id ${userResource.id} (${userResource.attributes.firstname})`);
        }

        if (!experienceResource) {
            console.error(`[editShiftPlanningTimeApiCall] Missing experience resource in user with id ${userResource.id} (${userResource.attributes.firstname})`);
        }

        const department = transformToDepartment(departmentResource);
        const shift = transformToShiftPlanningShift(shiftResource, department);
        const employmentType = transformToEmploymentType(employmentTypeResource || getDummyEmploymentTypeResource(`${userResource.id}-employment-type`));
        const experience = transformToExperience(experienceResource || getDummyExperienceResource(`${userResource.id}-experience`));

        const shiftPlanningUser = transformToShiftPlanningUser(userResource, employmentType, experience);
        const comments = commentsResource.map(commentResource => {
            const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

            // TODO: Missing resources bug. BE should fix this.
            if (!commentOwnerResource) {
                console.error(`[editShiftPlanningTimeApiCall] Missing owner resource in comment with id ${commentResource.id} (${commentResource.attributes.body})`);
            }

            return transformToComment(
                commentResource,
                commentOwnerResource || getDummyUserResource(`${commentResource.id}-user`),
            );
        });

        const shiftPlanning = transformToShiftPlanningWithShift(
            doc.data,
            shiftPlanningUser,
            shift,
            comments,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: shiftPlanning,
        };
    } catch (error) {
        console.error('[editShiftPlanningTimeApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

interface GetShiftPlanningsWithShiftApiCallParams {
    startDate: Date;
    endDate: Date;
    userUuids: string[];
}

export const getShiftPlanningsWithShiftApiCall = async (params: GetShiftPlanningsWithShiftApiCallParams): Promise<FetchCollectionResult<ShiftPlanningWithShift[], string>> => {
    try {
        const includes = [
            'user',
            'user.employmentType',
            'user.experience',
            'shift',
            'shift.department',
            'shift.department.group',
            'comments',
        ];
        const { startDate, endDate, userUuids } = params;

        const url = generateApiUrl({
            endpoint: '/shift/plannings',
            queryParams: {
                include: includes.join(','),
                ...(userUuids.length) && { userUuids: JSON.stringify(userUuids) },
                from: formatDate(startDate, DateFormat.apiDateTime),
                to: formatDate(endDate, DateFormat.apiDateTime),
            },
        });

        const response = await authorizedFetch(url, { method: 'GET' });

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<ShiftPlanningResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shiftPlannings = doc.data.map(shiftPlanningResource => {
            const userResource = getIncluded(doc, shiftPlanningResource, 'user') as ShiftIndexUserResource;
            const shiftResource = getIncluded(doc, shiftPlanningResource, 'shift') as ShiftResource;
            const departmentResource = getIncluded(doc, shiftResource, 'department') as DepartmentResource;
            const departmentGroupResource = getIncluded(doc, departmentResource, 'group') as DepartmentGroupResource;
            const commentsResource = (getMultipleIncluded(doc, userResource, 'comments') || []) as CommentResource[];
            const employmentTypeResource = getIncluded(doc, userResource, 'employmentType') as EmploymentTypeResource;
            const experienceResource = getIncluded(doc, userResource, 'experience') as ExperienceResource;

            // TODO: Missing resources bug. BE should fix this.
            if (!employmentTypeResource) {
                console.error(`[getShiftPlanningsWithShiftApiCall] Missing employmentType resource in user with id ${userResource.id} (${userResource.attributes.firstname})`);
            }

            if (!experienceResource) {
                console.error(`[getShiftPlanningsWithShiftApiCall] Missing experience resource in user with id ${userResource.id} (${userResource.attributes.firstname})`);
            }

            const department = transformToDepartment(departmentResource, departmentGroupResource);
            const shift = transformToShiftPlanningShift(shiftResource, department);
            const employmentType = transformToEmploymentType(employmentTypeResource || getDummyEmploymentTypeResource(`${userResource.id}-employment-type`));
            const experience = transformToExperience(experienceResource || getDummyExperienceResource(`${userResource.id}-experience`));

            const shiftPlanningUser = transformToShiftPlanningUser(userResource, employmentType, experience);
            const comments = commentsResource.map(commentResource => {
                const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

                // TODO: Missing resources bug. BE should fix.
                if (!commentOwnerResource) {
                    console.error(`[getShiftPlanningsWithShiftApiCall] Missing owner resource in comment with id ${commentResource.id} (${commentResource.attributes.body})`);
                }

                return transformToComment(
                    commentResource,
                    commentOwnerResource || getDummyUserResource(`${commentResource.id}-user`),
                );
            });

            return transformToShiftPlanningWithShift(
                shiftPlanningResource,
                shiftPlanningUser,
                shift,
                comments,
            );
        });

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: shiftPlannings,
        };
    } catch (error) {
        console.error('[getShiftPlanningsWithShiftApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteShiftPlanningCall = async (shiftPlanningId: string): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/shift/plannings/${shiftPlanningId}` });

        const response = await authorizedFetch(url, { method: 'DELETE' });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[deleteShiftPlanningTimeApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postShiftPlanningCall = async (shiftId: string, userId: string): Promise<FetchResult<ShiftPlanning, string>> => {
    try {
        const includes = [
            'user',
            'user.employmentType',
            'user.experience',
        ];

        const url = generateApiUrl({
            endpoint: '/shift/plannings',
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: transformToAddShiftPlanningRequest(shiftId, userId) }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ShiftPlanningResource>(doc) || !doc.data) {
            const error = doc.errors.length ? doc.errors[0] : undefined;

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: error?.detail || trans('errors.unknownError'),
            };
        }

        const shiftPlanningResource = doc.data;

        const data: ShiftPlanning | null = getShiftPlanningResourceIncludesAndTransformToShiftPlanning(doc, shiftPlanningResource);

        if (!data) {
            return {
                status: 500,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[postShiftPlanningCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
