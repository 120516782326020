import { FC, PropsWithChildren, useMemo } from 'react';

import classnames from 'classnames';

import Icon from '../../../../../components/style/Icon/Icon';
import {
    formatDate,
    isBefore,
    isSameDay,
    startOfDay,
} from '../../../../../helpers/date';
import { translate } from '../../../../../helpers/translations/translator';
import { SpecialDayViewModel } from '../../../../../models';
import { ElementWithTooltip } from '../../..';
import CalendarActionButton from '../CalendarActionButton/CalendarActionButton';

import './CalendarDayContent.scss';

interface DayContentProps {
    isDark?: boolean;
    showAddItemButton?: boolean;
    showAddSequenceButton?: boolean;
    showDayInMonth?: boolean;

    addItemButtonTooltipText?: string;
    addSequenceButtonTooltipText?: string;
    day: Date;
    // TODO: Replace legacy model
    specialDays: SpecialDayViewModel[];

    onAddItemButtonClick?: (day: Date) => void;
    onAddSequenceButtonClick?: (day: Date) => void;

    className?: string;
}

const CalendarDayContent: FC<PropsWithChildren<DayContentProps>> = ({
    isDark,
    showAddItemButton,
    showAddSequenceButton,
    showDayInMonth,

    addItemButtonTooltipText,
    addSequenceButtonTooltipText,
    children,
    day,
    specialDays,

    onAddItemButtonClick,
    onAddSequenceButtonClick,

    className,
}) => {
    const isToday = useMemo(() => isSameDay(day, new Date()), [day]);
    const isPast = useMemo(() => isBefore(day, startOfDay(new Date())), [day]);
    const date = useMemo(() => formatDate(new Date(day), 'd'), [day]);

    const classNames = useMemo(() => classnames('calendar-day-content', {
        'calendar-day-content--show-day-in-month': showDayInMonth,
        'calendar-day-content--is-dark': isDark,
        'calendar-day-content--is-past': isPast,
        'calendar-day-content--is-today': isToday,
    }, className), [
        className,
        showDayInMonth,
        isToday,
        isPast,
    ]);

    const handleAddItemButtonClick = () => {
        if (onAddItemButtonClick) {
            onAddItemButtonClick(day);
        }
    };

    const handleAddSequenceButtonClick = () => {
        if (onAddSequenceButtonClick) {
            onAddSequenceButtonClick(day);
        }
    };

    return (
        <div className={classNames}>
            {showDayInMonth && (
                <div className="calendar-day-content__header">
                    {isToday && (
                        <div className="calendar-day-content__header-label">
                            {translate('common.today')}
                        </div>
                    )}
                    {specialDays.map(specialDay => (
                        <ElementWithTooltip
                            key={specialDay.id}
                            tooltipIsActive
                            tooltipText={specialDay.description}
                        >
                            <Icon
                                kind={specialDay.type}
                                fontSize={16}
                                className="calendar-day-content__special-day-icon"
                            />
                        </ElementWithTooltip>
                    ))}
                    <div className="calendar-day-content__header-day">
                        {date}
                    </div>
                </div>
            )}
            <div className="calendar-day-content__children">
                {children}
            </div>
            {(showAddItemButton || showAddSequenceButton) && (
                <div className="calendar-day-content__actions">
                    {showAddItemButton && (
                        <ElementWithTooltip
                            tooltipText={addItemButtonTooltipText}
                            tooltipIsActive={!!addItemButtonTooltipText}
                        >
                            <CalendarActionButton
                                className="calendar-day-content__button-add"
                                onClick={handleAddItemButtonClick}
                            />
                        </ElementWithTooltip>
                    )}
                    {showAddSequenceButton && (
                        <ElementWithTooltip
                            tooltipText={addSequenceButtonTooltipText}
                            tooltipIsActive={!!addSequenceButtonTooltipText}
                        >
                            <CalendarActionButton
                                className="calendar-day-content__button-add"
                                onClick={handleAddSequenceButtonClick}
                                iconName="plus-shift-concept"
                            />
                        </ElementWithTooltip>
                    )}
                </div>
            )}
        </div>
    );
};

export default CalendarDayContent;
