import { FC, useMemo } from 'react';

import { Absence } from '../../entities/Absence/Absence';
import { Department } from '../../entities/Department/Department';
import { ShiftIndex } from '../../entities/Shift/Shift';
import { Track } from '../../entities/Track/Track';
import { transformShiftsToDashboardShiftsWidgetGroups } from './helpers';
import {
    ShiftsWidgetEmptyMessage,
    ShiftsWidgetHeader,
    ShiftsWidgetShift,
    ShiftsWidgetShiftsSkeletons,
} from './subcomponents';
import { DashboardShiftsWidgetShiftProps } from './subcomponents/ShiftsWidgetShift/ShiftsWidgetShift';

import './ShiftsWidget.scss';

export interface DashboardShiftsWidgetGroup {
    id: string;
    entries: DashboardShiftsWidgetShiftProps[];
    date: Date;
}

interface ShiftsWidgetProps {
    isLoading: boolean;
    isTracksLoading: boolean;
    isDepartmentsLoading: boolean;
    absences: Absence[];
    shifts: ShiftIndex[];
    tracks: Track[];
    departmentOptions: Department[];
    selectedDepartments: Department[];
    onDepartmentChange: (departments: Department[]) => void;
    className?: string;
}

const ShiftsWidget: FC<ShiftsWidgetProps> = ({
    isLoading,
    isTracksLoading,
    isDepartmentsLoading,
    absences,
    shifts,
    tracks,
    selectedDepartments,
    departmentOptions,
    onDepartmentChange,
    className = '',
}) => {
    const groups = useMemo(() => transformShiftsToDashboardShiftsWidgetGroups(
        shifts,
        tracks,
        absences,
        new Date(),
    ), [
        shifts,
        tracks,
        absences,
    ]);

    return (
        <div className={`shifts-widget ${className}`}>
            <ShiftsWidgetHeader
                isDepartmentsLoading={isDepartmentsLoading}
                departmentOptions={departmentOptions}
                selectedDepartments={selectedDepartments}
                onDepartmentChange={onDepartmentChange}
            />

            {isLoading && <ShiftsWidgetShiftsSkeletons className="shifts-widget__shifts" />}

            {!isLoading && (
                <ul className="shifts-widget__shifts">
                    {!groups.length && (
                        <ShiftsWidgetEmptyMessage />
                    )}
                    {groups.map((group, index) => (
                        [
                            ...group.entries.map(entry => (
                                <li
                                    key={entry.id}
                                    className="shifts-widget__shifts-item"
                                >
                                    <ShiftsWidgetShift
                                        {...entry}
                                        showEmploymentType
                                        isTracksLoading={isTracksLoading}
                                    />
                                </li>
                            )),
                            ...[index < groups.length - 1 ? (
                                <li key={group.id} className="shifts-widget__shifts-item shifts-widget__shifts-item-divider">
                                    <div className="shifts-widget__shifts-divider" />
                                </li>
                            ) : []],
                        ]
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ShiftsWidget;
