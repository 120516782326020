import { FC, useMemo } from 'react';

import classNames from 'classnames';

import { Button } from '../../components';
import { CalendarTypeRadioList, CustomDateControls } from '../../compositions';
// Needs direct import because of className specificity
import CalendarControls from '../../compositions/CalendarControls/CalendarControls';
import { CalendarTypeFormOption } from '../../compositions/CalendarNavRadioList/CalendarTypeRadioList';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';
import { isInputTypeSupported } from '../../helpers/browser';
import useCheckPermission from '../../helpers/hooks/useCheckPermission';
import trans from '../../helpers/trans';
import { TimeModeType } from '../../types';

import './CalendarNavigation.scss';

interface CalendarNavigationProps {
    showConceptModeButtons?: boolean;
    showMobileControls?: boolean;
    activePayrollPeriod?: PayrollPeriod;
    selectedCalendarType: CalendarTypeFormOption;
    selectedDays: Date[];
    selectedMobileDay: Date;
    timeMode: TimeModeType;
    timeModeOptions: TimeModeType[];
    onCalendarTypeChange?: () => void;
    onCustomDatesChange: (startDate: Date, endDate: Date) => void;
    onDateChange: (date: Date) => void;
    onIncrementClick: (goForward: boolean, isMobile?: boolean) => void;
    onTimeModeChange: (timeMode: TimeModeType) => void;
    className?: string;
}

const CalendarNavigation: FC<CalendarNavigationProps> = ({
    showConceptModeButtons = false,
    showMobileControls = false,
    activePayrollPeriod,
    selectedDays,
    selectedMobileDay,
    selectedCalendarType,
    timeMode,
    timeModeOptions,
    onCalendarTypeChange,
    onCustomDatesChange,
    onDateChange,
    onIncrementClick,
    onTimeModeChange,
    className = '',
}) => {
    const supportedInputTypes: (TimeModeType.date | TimeModeType.week | TimeModeType.month)[] = [TimeModeType.date, TimeModeType.week, TimeModeType.month];
    const canViewShiftConcepts = useCheckPermission('view-all-shift-concepts');

    const inputType = useMemo(() => (
        isInputTypeSupported(timeMode) ? supportedInputTypes.find(type => type === timeMode) : undefined
    ), [timeMode]);

    const handleMobileIncrementClick = (goForward: boolean) => {
        onIncrementClick(goForward, true);
    };

    const handleTodayButtonClick = (): void => {
        onDateChange(new Date());
    };

    const calendarClassName = classNames('calendar-navigation', {
        'calendar-navigation--show-mobile-controls': showMobileControls,
    }, className);

    return (
        <div className={calendarClassName}>
            <div className="calendar-navigation__nav-radio-list-wrapper">
                {(showConceptModeButtons && canViewShiftConcepts && onCalendarTypeChange) && (
                    <CalendarTypeRadioList
                        selectedCalendarType={selectedCalendarType}
                        onChange={onCalendarTypeChange}
                        className="calendar-navigation__nav-radio-list"
                    />
                )}

                <Button
                    text={trans('compositions.calendarNavigation.toToday')}
                    onClick={handleTodayButtonClick}
                    className="calendar-navigation__mobile-today-button"
                />
            </div>
            {timeMode !== TimeModeType.custom ? (
                <CalendarControls
                    showTimeModeSelect
                    activePayrollPeriod={activePayrollPeriod}
                    inputType={inputType}
                    selectedDays={selectedDays}
                    timeModeOptions={timeModeOptions}
                    timeMode={timeMode}
                    onDateChange={onDateChange}
                    onIncrementClick={onIncrementClick}
                    onTimeModeChange={onTimeModeChange}
                    onTodayButtonClick={handleTodayButtonClick}
                    className="calendar-navigation__controls"
                />
            ) : (
                <CustomDateControls
                    selectedDays={selectedDays}
                    timeModeOptions={timeModeOptions}
                    timeMode={timeMode}
                    onDatesChange={onCustomDatesChange}
                    onTimeModeChange={onTimeModeChange}
                    onTodayButtonClick={handleTodayButtonClick}
                    className="calendar-navigation__controls"
                />
            )}
            <CalendarControls
                isMobile
                selectedDays={[selectedMobileDay]}
                onDateChange={onDateChange}
                onTodayButtonClick={handleTodayButtonClick}
                onIncrementClick={handleMobileIncrementClick}
                className="calendar-navigation__controls-mobile"
            />
        </div>
    );
};

export default CalendarNavigation;
