import { FC, ReactElement, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { addCommentToTemporaryConceptWorker, editTemporaryConceptWorker } from '../../../../redux/@toolkit/shiftConcept/shiftConceptActions';
import { setIsEditTemporaryWorkerSuccessful, ShiftConceptState } from '../../../../redux/@toolkit/shiftConcept/shiftConceptReducer';
import { Reducers } from '../../../../redux/reducers';
import EditTemporaryWorkerForm from '../../../containers/@forms/EditTemporaryWorkerForm/EditTemporaryWorkerForm';
import { EditTemporaryWorkerFormData, TemporaryWorker } from '../../../entities/TemporaryWorker/TemporaryWorker';
import trans from '../../../helpers/trans';
import { useTypedDispatch } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedEditTemporaryConceptWorkerFormProps {
    temporaryWorker: TemporaryWorker;
    onClose: () => void;
}

const ConnectedEditTemporaryConceptWorkerForm: FC<ConnectedEditTemporaryConceptWorkerFormProps> = ({
    temporaryWorker,
    onClose,
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { isTemporaryWorkerLoading, isEditTemporaryWorkerSuccessful, shiftConcept } = useSelector<Reducers, ShiftConceptState>(state => state.shiftConceptReducer);

    const handleEditTemporaryWorker = async (formData: EditTemporaryWorkerFormData): Promise<void> => {
        if (shiftConcept) {
            await dispatch(editTemporaryConceptWorker(shiftConcept, temporaryWorker.id, formData));

            if (formData.comment) {
                dispatch(addCommentToTemporaryConceptWorker(shiftConcept, temporaryWorker.id, formData.comment));
            }
        }
    };

    useEffect(() => {
        if (isEditTemporaryWorkerSuccessful) {
            dispatch(setIsEditTemporaryWorkerSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.editTemporaryWorkerForm.editTemporaryWorkerSuccess'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isEditTemporaryWorkerSuccessful]);

    return (
        <EditTemporaryWorkerForm
            isLoading={isTemporaryWorkerLoading}
            temporaryWorker={temporaryWorker}
            onSubmit={handleEditTemporaryWorker}
            onCancel={onClose}
        />
    );
};

export default ConnectedEditTemporaryConceptWorkerForm;
