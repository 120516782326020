import { FC, MouseEvent, ReactElement } from 'react';

import { LoadingSpinner } from '../../components';
import { DropboxFolder } from '../../entities/DropboxEntity/DropboxEntity';
import trans from '../../helpers/trans';
import { DropboxResultType } from '../../redux/dropbox/dropboxReducer';
import { KnowledgeBaseSidebarItem } from './subcomponents';

import './KnowledgeBaseSidebar.scss';

interface KnowledgeBaseSidebarProps {
    isLoading: boolean;
    activeDirectoryId?: string;
    dropboxFolders: DropboxFolder[];
    resultType: DropboxResultType;
    onFolderClick: (newActiveDirectoryId: string) => void;
}

const KnowledgeBaseSidebar: FC<KnowledgeBaseSidebarProps> = ({
    isLoading,
    activeDirectoryId,
    dropboxFolders,
    resultType,
    onFolderClick,
}): ReactElement => {
    const handleFolderClick = (event: MouseEvent<HTMLButtonElement>, newActiveDirectoryId: string): void => {
        onFolderClick(newActiveDirectoryId);
    };

    return (
        <div className="knowledge-base-sidebar">
            {(isLoading && resultType === DropboxResultType.folder) && (
                <LoadingSpinner isSmall className="knowledge-base-sidebar__loading-spinner" />
            )}

            {!isLoading && resultType === DropboxResultType.folder && dropboxFolders.length === 0 && (
                <div className="knowledge-base-sidebar__empty-text">{trans('containers.knowledgeBaseSidebar.noFoldersFound')}</div>
            )}

            {resultType === DropboxResultType.folder && dropboxFolders.map(dropboxFolder => (
                <KnowledgeBaseSidebarItem
                    key={dropboxFolder.path}
                    dropboxFolder={dropboxFolder}
                    activeDirectoryId={activeDirectoryId}
                    onFolderClick={handleFolderClick}
                />
            ))}
        </div>
    );
};
export default KnowledgeBaseSidebar;
