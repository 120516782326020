import { FC, ReactElement } from 'react';

import { DistrictDivisionTemplatesFilterContainer } from '../../../containers';

interface ConnectedDistrictDivisionTemplatesFilterProps {
    className?: string;
}

const ConnectedDistrictDivisionTemplatesFilter: FC<ConnectedDistrictDivisionTemplatesFilterProps> = ({
    className = '',
}): ReactElement => (
    <DistrictDivisionTemplatesFilterContainer className={className} />
);

export default ConnectedDistrictDivisionTemplatesFilter;
