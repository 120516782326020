import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';
import { completePeriodsSlice } from '../completePeriods/completePeriodsReducer';

export type PayrollPeriodsState = AsyncReduxState<{
    payrollPeriods: PayrollPeriod[];
}>;

const initialState: PayrollPeriodsState = {
    ...initialAsyncReduxState,
    payrollPeriods: [],
};

export const payrollPeriodsSlice = createSlice({
    name: 'payrollPeriodsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): PayrollPeriodsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): PayrollPeriodsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setPayrollPeriods(state, action: PayloadAction<PayrollPeriod[]>): PayrollPeriodsState {
            return {
                ...state,
                payrollPeriods: action.payload,
            };
        },
    },
    extraReducers: builder => {
        builder.addCase(completePeriodsSlice.actions.setLastUpdatedPayrollPeriod, (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                };
            }

            const index = state.payrollPeriods.findIndex(period => period.id === action.payload?.id);
            const updatedPayrollPeriods = [...state.payrollPeriods];
            updatedPayrollPeriods.splice(index, 1, action.payload);

            return {
                ...state,
                payrollPeriods: updatedPayrollPeriods,
            };
        });
    },
});

export const {
    setIsLoading,
    setError,
    setPayrollPeriods,
} = payrollPeriodsSlice.actions;

export default payrollPeriodsSlice.reducer;
