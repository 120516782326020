import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { fetchDepartments } from '../../redux/@toolkit/departments/departmentsActions';
import Groups from './Groups';
import Periods from './Periods';
import SettingsPage from './SettingsPage';
import { SettingsRoutes } from './SettingsRoutes';

class LegacySettingsRouteGroup extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchDepartments());
    }

    render() {
        return (
            <Routes>
                <Route path={SettingsRoutes.manager()} element={<SettingsPage page="manager" {...this.props} />} />
                <Route path={SettingsRoutes.timer()} element={<SettingsPage page="timer" {...this.props} />} />
                <Route path={SettingsRoutes.employee()} element={<SettingsPage page="employee" {...this.props} />} />
                <Route path={SettingsRoutes.periods()} element={<Periods {...this.props} />} />
                <Route path={SettingsRoutes.groups()} element={<Groups {...this.props} />} />
            </Routes>
        );
    }
}

LegacySettingsRouteGroup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    managementLoading: PropTypes.bool.isRequired,
    settings: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
    deletableDepartments: PropTypes.array.isRequired,
    isEditGroupsSuccessful: PropTypes.bool.isRequired,
    isLoadingDepartmentGroups: PropTypes.bool.isRequired,
    groups: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    userRoutes: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        loading: state.settingsReducer.loading,
        managementLoading: state.managementReducer.loading,
        filter: state.filterReducer.filter,
        settings: state.settingsReducer.settings,
        departments: state.authenticatedUserReducer.departments,
        deletableDepartments: state.departmentsReducer.departments,
        loadingDeletableDepartments: state.departmentsReducer.isLoading,
        isLoadingDepartmentGroups: state.departmentGroupsReducer.isLoading,
        isEditGroupsSuccessful: state.departmentGroupsReducer.isEditGroupsSuccessful,
        groups: state.departmentGroupsReducer.departmentGroups,
        permissions: state.authenticatedUserReducer.permissions,
        userRoutes: state.authenticatedUserReducer.permittedUserRoutes,
    };
}

export default connect(mapStateToProps)(LegacySettingsRouteGroup);
