import { transformToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import { UnavailableToWorkTimeSlot } from '../../@paco/entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { getDummyUserResource } from '../../@paco/entities/User/UserHelpers';
import { transformToUser } from '../../@paco/entities/User/UserTransformers';
import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import transformUserResource from '../../services/UserService/transformUserResource';
import { transformLegacyUserToUser, transformUserViewModelToUser } from '../User/UserTransformers';
import { UnavailableToWorkTimeSlotResource, UnavailableToWorkTimeSlotViewModel } from './UnavailableToWorkTimeSlot';

export const transformUnavailableToWorkTimeSlot = (
    resource: UnavailableToWorkTimeSlotResource,
): UnavailableToWorkTimeSlotViewModel => ({
    type: 'UnavailableToWorkTimeSlots',
    id: resource.id,
    comment: resource.comment,
    start: new Date(resource.start),
    end: new UpToButExcludingDate(resource.end).transformToUpToAndIncludingDate(),
    weekday: resource.weekday,
    user: resource.user ? transformUserResource(resource.user) : undefined,
});

export const transformLegacyUnavailableToWorkTimeSlotToUnavailableToWorkTimeSlot = (slot: UnavailableToWorkTimeSlotResource): UnavailableToWorkTimeSlot => ({
    id: slot.id,
    comment: slot.comment,
    period: transformToPeriod(new Date(slot.start), new Date(slot.end)),
    user: slot.user ? transformLegacyUserToUser(slot.user) : transformToUser(getDummyUserResource(`${slot.id}-id`)),
    weekday: slot.weekday,
});

export const transformUnavailableToWorkTimeSlotViewModelToUnavailableToWorkTimeSlot = (slot: UnavailableToWorkTimeSlotViewModel): UnavailableToWorkTimeSlot => ({
    id: slot.id,
    comment: slot.comment,
    period: transformToPeriod(slot.start, slot.end.date),
    user: slot.user ? transformUserViewModelToUser(slot.user) : transformToUser(getDummyUserResource(`${slot.id}-id`)),
    weekday: slot.weekday,
});
