import React, { Component } from 'react';

import PropTypes from 'prop-types';
import {
    Button,
    Col,
    FormGroup,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { ConnectedWorkweekUsersSelect } from '../../../@paco/connectors';
import { transformToPeriod } from '../../../@paco/entities/Period/PeriodTransformers';
import Checkbox from '../../../components/CheckboxGroup/Checkbox';
import PlannedUserCard from '../../../components/PlannedUserCardLegacy/PlannedUserCard';
import UserProfileInfo from '../../../components/UserProfileInfo/UserProfileInfo';
import { capitalizeFirstLetter, getObjProperty, getUserFullName } from '../../../helpers';
import { formatDate } from '../../../helpers/date';
import { translate } from '../../../helpers/translations/translator';

import './Forms.scss';

class EditShiftForm extends Component {
    state = {
        disableReplacement: false,
        replacementUser: null,
    };

    setDisableReplacement = () => {
        const { disableReplacement } = this.state;
        this.setState({
            disableReplacement: !disableReplacement,
        });
    };

    handleSelectEmployee = (employee) => {
        this.setState({ replacementUser: employee.id });
    };

    onSubmit = () => {
        const { disableReplacement, replacementUser } = this.state;
        const shiftId = getObjProperty(this.props, 'shift.id');
        const delUserId = getObjProperty(this.props, 'absence.user.id');
        const addUserId = !disableReplacement && replacementUser;
        this.props.onSubmit(addUserId, delUserId, shiftId);
    };

    render() {
        const {
            dispatch,
            onCancel,
            shift = {},
            absence = {},
        } = this.props;
        const { disableReplacement } = this.state;
        const name = getUserFullName(absence.user);
        const userId = getObjProperty(absence, 'user.id');
        const experience = getObjProperty(absence, 'user.experience.name');
        const department = getObjProperty(shift, 'department.name');
        const group = getObjProperty(shift, 'department.group.name');
        const startDate = capitalizeFirstLetter(formatDate(shift.start, 'EEEE dd MMMM yyyy'));
        const startTime = formatDate(shift.start, 'H:mm');
        const endTime = formatDate(shift.end, 'H:mm');
        const period = shift ? transformToPeriod(new Date(shift.start), new Date(shift.end)) : undefined;
        const plannedUsers = getObjProperty(shift, 'plannedUsers') || [];

        return (
            <div>
                <ModalHeader>
                    { translate('pages.absences.solveShift') }
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <div className="form-edit-shift-top">
                                <UserProfileInfo
                                    experience={experience}
                                    date={new Date(shift.start)}
                                    department={department}
                                    group={group}
                                    name={name}
                                    userId={userId}
                                />
                                <div className="form-edit-shift-right">
                                    <div className="form-edit-shift-date">
                                        {startDate}
                                    </div>
                                    <div className="form-edit-shift-time">
                                        {`${startTime} - ${endTime}`}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="form-edit-shift-select-users">
                                {!!period && (
                                    <ConnectedWorkweekUsersSelect
                                        excludeUsersWithShiftOnDate
                                        isDisabled={disableReplacement}
                                        departmentId={shift.department?.id}
                                        period={period}
                                        placeholder={translate('pages.absences.addReplacementUser')}
                                        excludeUsers={plannedUsers.map(user => user.id)}
                                        onSelect={this.handleSelectEmployee}
                                    />
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="form-edit-shift-disable-replacement">
                                <Checkbox
                                    onChange={this.setDisableReplacement}
                                    id="disable-replacement"
                                    value="disableReplacement"
                                    checked={disableReplacement}
                                    label={translate('pages.absences.doNotPlanReplacement')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="form-edit-shift-planned-users">
                        <Col>
                            <div className="form-edit-shift-planned-users">
                                {`${translate('pages.absences.plannedUsers')} (${plannedUsers.length})`}
                            </div>
                            {
                                plannedUsers.map(user => (
                                    <PlannedUserCard
                                        dispatch={dispatch}
                                        key={user.id}
                                        userId={user.id}
                                        type={user.type}
                                        employmentType={user.employmentType}
                                        experience={getObjProperty(user, 'experience.name')}
                                        remarks={getObjProperty(user, 'remarks')}
                                        name={getUserFullName(user)}
                                    />
                                ))
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" id="modal-close" onClick={onCancel}>
                        {translate('common.cancel')}
                    </Button>
                    <Button type="submit" color="orange" onClick={this.onSubmit}>
                        {translate('common.save')}
                    </Button>
                </ModalFooter>
            </div>
        );
    }
}

EditShiftForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    shift: PropTypes.object,
    absence: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default EditShiftForm;
