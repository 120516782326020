import axios from 'axios';

import { PAGE_SIZES } from '../../constants';

export const getRemovedLogs = (pagination) => axios.get(
    `${process.env.REACT_APP_API_URL}/logs`,
    {
        params: {
            'page[number]': pagination.number,
            'page[size]': PAGE_SIZES.LOGS,
            action: 'remove',
        },
    },
);

export const getResourceLogs = (id, resourceType) => {
    const includes = ['logs'];
    return axios.get(
        `${process.env.REACT_APP_API_URL}/${resourceType}/${id}`,
        {
            params: {
                include: includes.join(','),
            },
        },
    );
};
