import axios from 'axios';

import { formatDate, startOfDayInHours } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { addMonths } from '../date';

const includes = ['tracks', 'category', 'tracks.owner', 'tracks.department', 'tracks.department.group', 'tracks.shift.department'];

export const getFeedbackList = (departmentIds, employeeSearch, date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1, startOfDayInHours);
    const endOfNextMonth = addMonths(firstDayOfMonth, 1);

    return axios.get(
        `${process.env.REACT_APP_API_URL}/feedback/`,
        {
            params: {
                include: includes.join(','),
                departments: departmentIds,
                search: employeeSearch,
                startDate: formatDate(firstDayOfMonth, DateFormat.apiDateTime),
                endDate: formatDate(endOfNextMonth, DateFormat.apiDateTime),
                rating: 0,
                withOpenCheckOut: false,
            },
        },
    );
};

export const getFeedbackScores = (departmentIds, employeeSearch, yearMonth) => axios.get(
    `${process.env.REACT_APP_API_URL}/feedback/statistics/${yearMonth}`,
    {
        params: {
            departments: departmentIds,
            search: employeeSearch,
        },
    },
);
