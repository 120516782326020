import { FC, ReactElement } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ConnectedConceptsCalendar from '../../../pages/Schedule/connectors/ConnectedConceptsCalendar/ConnectedConceptsCalendar';
import { switchMode } from '../../../redux/app/appActions';
import { Reducers } from '../../../redux/reducers';
import { Page, SidebarPageWrapper } from '../../components';
import { Sidebar } from '../../compositions';
import { ConnectedShiftsCalendar, ConnectedShiftsCalendarFilter } from '../../connectors';

import './ShiftsCalendarPage.scss';

interface ShiftsCalendarProps {
    className?: string;
}

const ShiftsCalendarPage: FC<ShiftsCalendarProps> = ({ className = '' }): ReactElement => {
    const dispatch = useDispatch();

    const { isConceptMode } = useSelector((state: Reducers) => state.appReducer);

    const handleNavigationChange = () => {
        dispatch(switchMode());
    };

    if (isConceptMode) {
        return (
            <ConnectedConceptsCalendar
                onNavigationChange={handleNavigationChange}
            />
        );
    }

    return (
        <SidebarPageWrapper className={className}>
            <Sidebar hasShiftsLegend>
                <ConnectedShiftsCalendarFilter />
            </Sidebar>
            <Page className="shifts-calendar-page">
                <ConnectedShiftsCalendar
                    onNavigationChange={handleNavigationChange}
                />
            </Page>
        </SidebarPageWrapper>
    );
};

export default ShiftsCalendarPage;
