export const getParentElementWidth = (
    ref: React.MutableRefObject<HTMLElement | null>,
): number => {
    const element = ref.current as unknown as HTMLElement;
    if (element && element.parentElement) {
        return element.parentElement.getBoundingClientRect().width;
    }
    return 0;
};

export const getParentElementHeight = (
    ref: React.MutableRefObject<HTMLElement | null>,
): number => {
    const element = ref.current as unknown as HTMLElement;
    if (element && element.parentElement) {
        return element.parentElement.getBoundingClientRect().height;
    }
    return 0;
};

export const getParentElement = (
    ref: React.MutableRefObject<HTMLElement | null>,
): HTMLElement | null => {
    const element = ref.current as unknown as HTMLElement;
    if (!element) {
        return null;
    }

    return element.parentElement || null;
};

export const getParentSecondRowElement = (
    ref: React.MutableRefObject<HTMLElement | null>,
): HTMLElement | null => {
    const element = ref.current as unknown as HTMLElement;
    if (!element || !element.parentElement) {
        return null;
    }

    return element.parentElement.getElementsByClassName('day')[7] as unknown as HTMLElement || null;
};

export const didElementScrollPastElement = (
    scrollY: number,
    element: HTMLElement | null,
    withNavBarHeight = false,
): boolean => {
    if (!element) {
        return false;
    }

    return element.getBoundingClientRect().top < (withNavBarHeight ? 56 : 0);
};
