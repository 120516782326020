import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import {
    AbsenceNotifications,
    AbsenceNotificationsResource,
    ExchangeNotifications,
    ExchangeNotificationsResource,
    FeedbackNotifications,
    FeedbackNotificationsResource,
    LeaveOfAbsenceNotifications,
    LeaveOfAbsenceNotificationsResource,
    PayrollPeriodNotifications,
    PayrollPeriodNotificationsResource,
    ShiftNotifications,
    ShiftNotificationsResource,
    TrackNotifications,
    TrackNotificationsResource,
} from './Notifications';
import {
    transformAbsenceNotifications,
    transformExchangeNotifications,
    transformFeedbackNotifications,
    transformLeaveOfAbsenceNotifications,
    transformPayrollPeriodNotifications,
    transformShiftNotifications,
    transformTrackNotifications,
} from './NotificationsTransformers';

export const getAbsenceNotificationsIndexCall = async (): Promise<FetchResult<AbsenceNotifications, string>> => {
    try {
        const url = generateApiUrl({ endpoint: '/notifications/absences' });
        const response = await authorizedFetch(url);
        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<AbsenceNotificationsResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const absenceNotifications = transformAbsenceNotifications(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: absenceNotifications,
        };
    } catch (error) {
        console.error('[getAbsenceNotificationsIndexCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getExchangeNotificationsIndexCall = async (): Promise<FetchResult<ExchangeNotifications, string>> => {
    try {
        const url = generateApiUrl({ endpoint: '/notifications/exchanges' });
        const response = await authorizedFetch(url);
        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ExchangeNotificationsResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const exchangeNotifications = transformExchangeNotifications(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: exchangeNotifications,
        };
    } catch (error) {
        console.error('[getExchangeNotificationsIndexCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getLeaveOfAbsenceNotificationsIndexCall = async (): Promise<FetchResult<LeaveOfAbsenceNotifications, string>> => {
    try {
        const url = generateApiUrl({ endpoint: '/notifications/leave-of-absences' });
        const response = await authorizedFetch(url);
        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<LeaveOfAbsenceNotificationsResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const leaveOfAbsenceNotifications = transformLeaveOfAbsenceNotifications(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: leaveOfAbsenceNotifications,
        };
    } catch (error) {
        console.error('[getLeaveOfAbsenceNotificationsIndexCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getPayrollPeriodNotificationsIndexCall = async (): Promise<FetchResult<PayrollPeriodNotifications, string>> => {
    try {
        const url = generateApiUrl({ endpoint: '/notifications/payroll-periods' });
        const response = await authorizedFetch(url);
        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<PayrollPeriodNotificationsResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const payrollPeriodNotifications = transformPayrollPeriodNotifications(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: payrollPeriodNotifications,
        };
    } catch (error) {
        console.error('[getPayrollPeriodNotificationsIndexCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getFeedbackNotificationsIndexCall = async (): Promise<FetchResult<FeedbackNotifications, string>> => {
    try {
        const url = generateApiUrl({ endpoint: '/notifications/feedback' });
        const response = await authorizedFetch(url);
        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<FeedbackNotificationsResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const feedbackNotifications = transformFeedbackNotifications(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: feedbackNotifications,
        };
    } catch (error) {
        console.error('[getFeedbackNotificationsIndexCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getTrackNotificationsIndexCall = async (): Promise<FetchResult<TrackNotifications, string>> => {
    try {
        const url = generateApiUrl({ endpoint: '/notifications/tracks' });
        const response = await authorizedFetch(url);
        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<TrackNotificationsResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const trackNotifications = transformTrackNotifications(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: trackNotifications,
        };
    } catch (error) {
        console.error('[getTrackNotificationsIndexCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getShiftNotificationsIndexCall = async (): Promise<FetchResult<ShiftNotifications, string>> => {
    try {
        const url = generateApiUrl({ endpoint: '/notifications/shifts' });
        const response = await authorizedFetch(url);
        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ShiftNotificationsResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shiftNotifications = transformShiftNotifications(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: shiftNotifications,
        };
    } catch (error) {
        console.error('[getShiftNotificationsIndexCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
