import React, { FC, useEffect, useState } from 'react';

import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import {
    addHours,
    getEndOfWorkDay,
    getStartOfWorkDay,
    startOfDayInHours,
} from '../../../@paco/helpers/date';
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker';
import {
    addMonths,
    compareAsc,
    formatDate,
    setYear as dateHelperSetYear,
} from '../../../helpers/date';
import { translate } from '../../../helpers/translations/translator';
import { PayrollPeriodFormData, PayrollPeriodViewModel } from '../../../models';

interface Props {
    data: PayrollPeriodViewModel | null;
    activeYear: number;
    onCancel: () => void;
    onSubmit: (data: PayrollPeriodFormData, id?: string) => void;
}

const dateFormat = 'yyyy-MM-dd';

const AddOrEditPeriodForm: FC<Props> = ({
    data, activeYear, onCancel, onSubmit,
}) => {
    const [year, setYear] = useState(new Date().getUTCFullYear());
    const [number, setNumber] = useState(1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const dateError = compareAsc(startDate, endDate) === 1;
    const canSubmit = !dateError && year && number && startDate.length && endDate.length;
    const title = `${translate('pages.settings.period')} 
        ${(data ? translate('common.edit') : translate('common.add')).toLowerCase()}`;

    useEffect(() => {
        if (data) {
            setYear(data.year);
            setNumber(data.periodNumber);
            setStartDate(formatDate(addHours(data.start, -startOfDayInHours), dateFormat));
            setEndDate(formatDate(addHours(data.end.date, -startOfDayInHours), dateFormat));
        } else {
            const date = dateHelperSetYear(new Date(), activeYear);
            setYear(activeYear);
            setNumber(1);
            setStartDate(formatDate(date, dateFormat));
            setEndDate(formatDate(addMonths(date, 1), dateFormat));
        }
    }, [data]);

    const onFormSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        onSubmit(
            {
                year,
                periodNumber: number,
                start: getStartOfWorkDay(new Date(startDate)),
                end: getEndOfWorkDay(new Date(endDate)),
            },
            data ? data.id : undefined,
        );
    };

    const onChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
        setYear(parseInt(e.target.value, 10));
    };

    const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNumber(parseInt(e.target.value, 10));
    };

    const onChangeStartDate = (date: string) => {
        setStartDate(formatDate(date, dateFormat));
    };

    const onChangeEndDate = (date: string) => {
        setEndDate(formatDate(date, dateFormat));
    };

    return (
        <form onSubmit={onFormSubmit}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <p>
                            {translate('pages.settings.addPeriodWarning')}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.year')}</Label>
                            <Input
                                type="number"
                                id="year"
                                name="year"
                                value={year}
                                onChange={onChangeYear}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.number')}</Label>
                            <Input
                                type="number"
                                id="number"
                                name="number"
                                value={number}
                                onChange={onChangeNumber}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.startDate')}</Label>
                            <FormDatePicker
                                showYearDropdown
                                selected={startDate}
                                onChange={onChangeStartDate}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.endDate')}</Label>
                            <FormDatePicker
                                showYearDropdown
                                selected={endDate}
                                onChange={onChangeEndDate}
                                invalid={dateError}
                                feedback={translate('pages.management.endDateNeedsToBeLaterThanStartDate')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="link"
                    id="modal-close"
                    onClick={onCancel}
                >
                    {translate('common.cancel')}
                </Button>
                <Button
                    type="submit"
                    color="orange"
                    disabled={!canSubmit}
                >
                    {translate('common.save')}
                </Button>
            </ModalFooter>
        </form>
    );
};

export default AddOrEditPeriodForm;
