import { formatDate } from '../../helpers/date';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import trans from '../../helpers/trans';
import { MealType } from '../../types';
import { DateFormat } from '../../types/dateFormatTypes';
import { Department } from '../Department/Department';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { TrackShift } from '../Shift/Shift';
import { TrackOwner } from '../User/User';
import {
    ExportTracksFormData,
    Track,
    TrackExportData,
    TrackResource,
} from './Track';

export const transformToTrack = (
    resource: TrackResource,
    owner: TrackOwner,
    shift?: TrackShift,
    department?: Department,
): Track => {
    const mealKey = getEnumKeyByEnumValue(MealType, resource.attributes.meal || MealType.none);
    const billableStart = new Date(resource.attributes.billableStart);
    const billableEnd = resource.attributes.billableEnd ? new Date(resource.attributes.billableEnd) : new Date();

    const checkIn = new Date(resource.attributes.checkIn);
    const checkOut = resource.attributes.checkOut ? new Date(resource.attributes.checkOut) : new Date();

    if (!mealKey) {
        console.error(`[transformToTrack]: Unknown key found named "${resource.attributes.meal}".`);
    }

    return {
        accepted: resource.attributes.accepted,
        isOffline: resource.attributes.isOffline,
        billablePeriod: transformToPeriod(billableStart, billableEnd, !!resource.attributes.billableEnd),
        checkInOutPeriod: transformToPeriod(checkIn, checkOut, !!resource.attributes.checkOut),
        department: department || shift?.department,
        deviation: resource.attributes.deviation,
        deviationRemark: resource.attributes.deviationRemark || undefined,
        id: resource.id,
        meal: mealKey ? MealType[mealKey] : MealType.none,
        owner,
        pause: resource.attributes.pause || undefined,
        shift,
        totalBillableTime: resource.attributes.totalBillableTime,
        unplannedRemark: resource.attributes.unplannedRemark || undefined,
    };
};

const getTrackExportDataFileName = (name: string, extension: string, formData: ExportTracksFormData): string => {
    const startDate = formatDate(formData.startDate, DateFormat.date);
    const endDate = formatDate(formData.endDate, DateFormat.date);

    return `${name}-${startDate}-${trans('common.until')}-${endDate}.${extension}`;
};

export const transformTrackExportResponseToTrackExportData = (
    uri: string,
    name: string,
    extension: string,
    formData: ExportTracksFormData,
): TrackExportData => {
    const fileName = getTrackExportDataFileName(name, extension, formData);

    return { uri, fileName };
};
