import { Dispatch } from 'redux';

import { postCommentApiCall } from '../../../@paco/entities/Comment/CommentService';
import { isFetchResultSuccessful } from '../../../@paco/entities/FetchResult';
import {
    deleteTemporaryConceptWorkerApiCall,
    patchColorTemporaryConceptWorkerApiCall,
    patchTemporaryConceptWorkerApiCall,
    postTemporaryConceptWorkerApiCall,
} from '../../../@paco/entities/TemporaryWorker/TemporaryConceptWorkerService';
import { EditTemporaryWorkerFormData } from '../../../@paco/entities/TemporaryWorker/TemporaryWorker';
import { postCommentApiCall as legacyPostCommentApiCall } from '../../../entities/Comment/CommentService';
import { transformAddCommentRequest } from '../../../entities/Comment/CommentTransformers';
import { getPreferToWorkTimeSlots } from '../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlotService';
import { ShiftConceptViewModelOld } from '../../../entities/ShiftConcept/ShiftConcept';
import { deleteShiftConceptApiCall, editShiftConceptApiCall, getShiftConceptApiCall } from '../../../entities/ShiftConcept/ShiftConceptService';
import { transformEditShiftFormData } from '../../../entities/ShiftConcept/ShiftConceptTransformers';
import {
    deleteShiftConceptPlanningApiCall,
    patchShiftConceptPlanningApiCall,
    patchShiftConceptPlanningColorApiCall,
    postShiftConceptPlanningApiCall,
} from '../../../entities/ShiftConceptPlanning/ShiftConceptPlanningService';
import {
    transformAddShiftConceptRequest,
    transformEditShiftConceptPlanningColorRequest,
    transformEditShiftConceptPlanningFormData,
} from '../../../entities/ShiftConceptPlanning/ShiftConceptPlanningTransformers';
import { getUnavailableWorkTimeSlots } from '../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotService';
import { getAbsencesInDateRange } from '../../../helpers/api-ts/absences';
import { getLeaveOfAbsences } from '../../../helpers/api-ts/leaveOfAbsence';
import { getEmployeesInWorkWeek } from '../../../helpers/api-ts/users';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../../helpers/date';
import { EditShiftFormData, EditShiftPlanningFormData, ShiftPlanningViewModel } from '../../../models';
import compareShiftPlanningUsersAlphabetically from '../../../services/ShiftPlanningService/compareShiftPlanningUsersAlphabetically';
import { setLastModifiedShiftConceptId } from '../shiftConcepts/shiftConceptsReducer';
import {
    setAvailabilityData,
    setEmployees,
    setError,
    setIsDeleteSuccessful,
    setIsEditShiftPlanningSuccessful,
    setIsEditSuccessful,
    setIsEditTemporaryWorkerSuccessful,
    setIsEmployeesLoading,
    setIsLoading,
    setIsShiftPlanningLoading,
    setIsTemporaryWorkerLoading,
    setShiftConcept,
} from './shiftConceptReducer';

export const fetchShiftConcept = (id: string) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setShiftConcept(undefined));
    dispatch(setError(''));

    try {
        const response = await getShiftConceptApiCall(id);
        dispatch(setShiftConcept(response));
        dispatch(setIsLoading(false));
    } catch (error) {
        dispatch(setError('fetchShiftConcept'));
        console.error('[fetchShiftConcept]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const planUserToShiftConcept = (
    shiftConcept: ShiftConceptViewModelOld,
    userId: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsShiftPlanningLoading(true));
    dispatch(setError(''));

    try {
        const request = transformAddShiftConceptRequest(shiftConcept.id, userId);
        const response = await postShiftConceptPlanningApiCall(request);
        const updatedShiftConcept: ShiftConceptViewModelOld = {
            ...shiftConcept,
            shiftPlannings: [
                ...shiftConcept.shiftPlannings,
                response,
            ].sort(compareShiftPlanningUsersAlphabetically),
        };
        dispatch(setShiftConcept(updatedShiftConcept));
        dispatch(setIsShiftPlanningLoading(false));
    } catch (error) {
        console.error('[planUserToShiftConcept]', error);
    } finally {
        dispatch(setIsShiftPlanningLoading(false));
    }
};

export const addTemporaryConceptWorkerToShiftConcept = (
    shiftConcept: ShiftConceptViewModelOld,
    name: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsShiftPlanningLoading(true));
    dispatch(setError(''));

    try {
        const response = await postTemporaryConceptWorkerApiCall(shiftConcept.id, name);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const updatedShiftConcept = {
            ...shiftConcept,
            temporaryWorkers: [
                ...shiftConcept.temporaryWorkers,
                response.data,
            ].sort((
                temporaryWorker,
                temporaryWorkerToCompare,
            ) => temporaryWorker.name.localeCompare(temporaryWorkerToCompare.name)),
        };

        dispatch(setShiftConcept(updatedShiftConcept));
    } catch (error) {
        console.error('[addTemporaryConceptWorkerToShiftConcept]', error);
    } finally {
        dispatch(setIsShiftPlanningLoading(false));
    }
};

export const setTemporaryConceptWorkerColor = (
    shiftConcept: ShiftConceptViewModelOld,
    temporaryConceptWorkerId: string,
    color?: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsShiftPlanningLoading(true));
    dispatch(setError(''));

    try {
        const response = await patchColorTemporaryConceptWorkerApiCall(temporaryConceptWorkerId, color);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const updatedTemporaryWorkers = shiftConcept.temporaryWorkers.map(temporaryWorker => {
            if (temporaryWorker.id === temporaryConceptWorkerId) {
                return {
                    ...temporaryWorker,
                    color: response.data.color,
                };
            }

            return temporaryWorker;
        });

        const updatedShiftConcept = {
            ...shiftConcept,
            temporaryWorkers: updatedTemporaryWorkers,
        };

        dispatch(setShiftConcept(updatedShiftConcept));
    } catch (error) {
        console.error('[setTemporaryConceptWorkerColor]', error);
    } finally {
        dispatch(setIsShiftPlanningLoading(false));
    }
};

export const addCommentToTemporaryConceptWorker = (
    shiftConcept: ShiftConceptViewModelOld,
    temporaryConceptWorkerId: string,
    comment: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsTemporaryWorkerLoading(true));
    dispatch(setError(''));

    try {
        const response = await postCommentApiCall({
            comment,
            relationshipId: temporaryConceptWorkerId,
            relationshipKey: 'temporaryConceptWorker',
            relationshipType: 'temporaryConceptWorkers',
        });

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const updatedTemporaryWorkers = shiftConcept.temporaryWorkers.map(temporaryWorker => {
            if (temporaryWorker.id === temporaryConceptWorkerId) {
                return {
                    ...temporaryWorker,
                    comments: [
                        ...temporaryWorker.comments,
                        response.data,
                    ],
                };
            }

            return temporaryWorker;
        });

        const updatedShiftConcept = {
            ...shiftConcept,
            temporaryWorkers: updatedTemporaryWorkers,
        };

        dispatch(setShiftConcept(updatedShiftConcept));
    } catch (error) {
        console.error('[addCommentToTemporaryConceptWorker]', error);
    } finally {
        dispatch(setIsTemporaryWorkerLoading(false));
    }
};

export const editTemporaryConceptWorker = (
    shiftConcept: ShiftConceptViewModelOld,
    temporaryConceptWorkerId: string,
    formData: EditTemporaryWorkerFormData,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsTemporaryWorkerLoading(true));
    dispatch(setIsEditTemporaryWorkerSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await patchTemporaryConceptWorkerApiCall(temporaryConceptWorkerId, formData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const updatedTemporaryWorkers = shiftConcept.temporaryWorkers.map(temporaryWorker => {
            if (temporaryWorker.id === temporaryConceptWorkerId) {
                return {
                    ...temporaryWorker,
                    period: response.data.period,
                };
            }

            return temporaryWorker;
        });

        const updatedShiftConcept = {
            ...shiftConcept,
            temporaryWorkers: updatedTemporaryWorkers,
        };

        dispatch(setShiftConcept(updatedShiftConcept));
        dispatch(setIsEditTemporaryWorkerSuccessful(true));
    } catch (error) {
        console.error('[editTemporaryConceptWorker]', error);
    } finally {
        dispatch(setIsTemporaryWorkerLoading(false));
    }
};

export const removeTemporaryConceptWorkerFromShiftConcept = (
    shiftConcept: ShiftConceptViewModelOld,
    temporaryConceptWorkerId: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsShiftPlanningLoading(true));
    dispatch(setError(''));

    try {
        const response = await deleteTemporaryConceptWorkerApiCall(temporaryConceptWorkerId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const updatedShiftConcept = {
            ...shiftConcept,
            temporaryWorkers: shiftConcept.temporaryWorkers
                .filter(temporaryWorker => temporaryWorker.id !== temporaryConceptWorkerId),
        };

        dispatch(setShiftConcept(updatedShiftConcept));
    } catch (error) {
        console.error('[removeTemporaryConceptWorkerFromShiftConcept]', error);
    } finally {
        dispatch(setIsShiftPlanningLoading(false));
    }
};

export const setShiftConceptPlanningColor = (
    shiftConcept: ShiftConceptViewModelOld,
    shiftPlanning: ShiftPlanningViewModel,
    color?: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsShiftPlanningLoading(true));
    dispatch(setError(''));

    try {
        const request = transformEditShiftConceptPlanningColorRequest(shiftPlanning.id, color);
        const response = await patchShiftConceptPlanningColorApiCall(shiftPlanning.id, request);

        const updatedShiftConcept: ShiftConceptViewModelOld = {
            ...shiftConcept,
            shiftPlannings: [
                ...shiftConcept.shiftPlannings
                    .filter(planning => planning.id !== shiftPlanning.id),
                response,
            ].sort(compareShiftPlanningUsersAlphabetically),
        };
        dispatch(setShiftConcept(updatedShiftConcept));
    } catch (error) {
        console.error('[setShiftConceptPlanningColor]', error);
    } finally {
        dispatch(setIsShiftPlanningLoading(false));
    }
};

export const editShiftConceptPlanning = (
    shiftConcept: ShiftConceptViewModelOld,
    shiftPlanningId: string,
    formData: EditShiftPlanningFormData,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsShiftPlanningLoading(true));
    dispatch(setError(''));

    try {
        const commentRequest = transformAddCommentRequest(
            formData.comment,
            'shiftConceptPlanning',
            'shiftConceptPlannings',
            shiftPlanningId,
        );
        const formRequest = transformEditShiftConceptPlanningFormData(
            formData,
            shiftPlanningId,
            shiftConcept.start,
        );

        await legacyPostCommentApiCall(commentRequest);
        const shiftPlanningResponse = await patchShiftConceptPlanningApiCall(formRequest, shiftPlanningId);

        const updatedShiftConcept: ShiftConceptViewModelOld = {
            ...shiftConcept,
            shiftPlannings: [
                ...shiftConcept.shiftPlannings
                    .filter(planning => planning.id !== shiftPlanningId),
                shiftPlanningResponse,
            ].sort(compareShiftPlanningUsersAlphabetically),
        };
        dispatch(setShiftConcept(updatedShiftConcept));
    } catch (error) {
        console.error('[editShiftConceptPlanning]', error);
    } finally {
        dispatch(setIsShiftPlanningLoading(false));
    }
};

export const deleteShiftConceptPlanning = (
    shiftConcept: ShiftConceptViewModelOld,
    shiftPlanningId: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsShiftPlanningLoading(true));
    dispatch(setIsEditShiftPlanningSuccessful(false));
    dispatch(setError(''));

    try {
        await deleteShiftConceptPlanningApiCall(shiftPlanningId);
        const updatedShiftConcept: ShiftConceptViewModelOld = {
            ...shiftConcept,
            shiftPlannings: shiftConcept.shiftPlannings
                .filter((shiftPlanning) => shiftPlanning.id !== shiftPlanningId),
        };
        dispatch(setShiftConcept(updatedShiftConcept));
        dispatch(setIsEditShiftPlanningSuccessful(true));
    } catch (error) {
        console.error('[deleteShiftConceptPlanning]', error);
    } finally {
        dispatch(setIsShiftPlanningLoading(false));
    }
};

export const deleteShiftConcept = (shiftConceptId: string) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsDeleteSuccessful(false));
    dispatch(setError(''));

    try {
        await deleteShiftConceptApiCall(shiftConceptId);
        dispatch(setLastModifiedShiftConceptId(shiftConceptId));
        dispatch(setIsDeleteSuccessful(true));
    } catch (error) {
        console.error('[deleteShiftConcept]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editShiftConcept = (
    shiftConceptId: string,
    formData: EditShiftFormData,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsEditSuccessful(false));
    dispatch(setError(''));

    try {
        const request = transformEditShiftFormData(formData, shiftConceptId);
        await editShiftConceptApiCall(request, shiftConceptId);
        dispatch(setLastModifiedShiftConceptId(shiftConceptId));
        dispatch(setIsEditSuccessful(true));
    } catch (error) {
        console.error('[editShiftConcept]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchEmployees = (department: string, date: Date) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsEmployeesLoading(true));

    try {
        const response = await getEmployeesInWorkWeek(
            date,
            [department],
            [
                'person',
                'roles',
                'employmentType',
            ],
        );
        dispatch(setEmployees(response));
        dispatch(setIsEmployeesLoading(false));
    } catch (error) {
        console.error('[fetchEmployees]', error);
    }
};

export const fetchAvailabilityData = (department: string, startDate: Date, endDate: Date) => async (dispatch: Dispatch): Promise<void> => {
    try {
        const [
            absences,
            leaveOfAbsences,
            unavailableToWorkTimeSlots,
            preferToWorkTimeSlots,
        ] = await Promise.all([
            getAbsencesInDateRange(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                { departments: [department] },
                undefined,
                ['user'],
            ),
            getLeaveOfAbsences({
                startDate: getStartOfWorkDay(startDate),
                endDate: getEndOfWorkDay(endDate),
                filter: { departments: [department] },
                status: [1, 2],
                includes: ['user'],
            }),
            getUnavailableWorkTimeSlots(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                [],
                ['user'],
            ),
            getPreferToWorkTimeSlots(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                [],
                ['user'],
            ),
        ]);

        dispatch(setAvailabilityData({
            absences,
            leaveOfAbsences,
            unavailableToWorkTimeSlots,
            preferToWorkTimeSlots,
        }));
    } catch (error) {
        console.error('[fetchAvailabilityData]', error);
    }
};
