import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { baseScheduleShiftSlice } from '../../../@paco/redux/baseScheduleShift/baseScheduleShiftReducer';
import { BaseScheduleViewModel } from '../../../entities/BaseSchedule/BaseSchedule';
import { updateLegacyBaseScheduleShiftWithBaseScheduleShift } from '../../../entities/BaseScheduleShift/BaseScheduleShiftHelpers';
import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';

export type BaseScheduleState = AsyncReduxState<{
    baseSchedule?: BaseScheduleViewModel;
    lastModifiedBaseScheduleShiftId?: string;
}>;

const initialState: BaseScheduleState = {
    ...initialAsyncReduxState,
};

export const BaseScheduleSlice = createSlice({
    name: 'baseScheduleReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): BaseScheduleState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): BaseScheduleState {
            return ({
                ...state,
                isSuccessful: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): BaseScheduleState {
            return ({
                ...state,
                error: action.payload,
            });
        },
        setBaseSchedule(state, action: PayloadAction<BaseScheduleViewModel | undefined>): BaseScheduleState {
            return ({
                ...state,
                baseSchedule: action.payload,
            });
        },
        setLastModifiedBaseScheduleShiftId(state, action: PayloadAction<string | undefined>): BaseScheduleState {
            return ({
                ...state,
                lastModifiedBaseScheduleShiftId: action.payload,
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(baseScheduleShiftSlice.actions.setBaseScheduleShift, (state, action): BaseScheduleState => {
            const { baseSchedule } = state;
            const baseScheduleShifts = state.baseSchedule?.shifts || [];
            const shift = baseScheduleShifts.find(legacyShift => legacyShift.id === action.payload.id);

            if (!baseSchedule || !baseScheduleShifts.length || !shift) {
                return state;
            }

            const updatedShift = updateLegacyBaseScheduleShiftWithBaseScheduleShift(shift, action.payload);
            const updatedBaseScheduleShifts = [
                ...baseScheduleShifts.filter(legacyShift => legacyShift.id !== action.payload.id),
                updatedShift,
            ];
            const updatedBaseSchedule: BaseScheduleViewModel = {
                ...baseSchedule,
                shifts: updatedBaseScheduleShifts,
            };

            return {
                ...state,
                baseSchedule: updatedBaseSchedule,
            };
        });
    },
});

export const {
    setBaseSchedule,
    setError,
    setIsLoading,
    setIsSuccessful,
    setLastModifiedBaseScheduleShiftId,
} = BaseScheduleSlice.actions;

export default BaseScheduleSlice.reducer;
