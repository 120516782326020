import { DepartmentResource } from '../../entities/Departments/Departments';
import { transformDepartmentResource } from '../../entities/Departments/DepartmentsTransformers';
import {
    DocumentResource,
    DocumentTypeResource,
    DocumentViewModel,
    Role,
    UserResource,
} from '../../models';

export default function transformDocumentResource(
    document: DocumentResource,
    documentType: DocumentTypeResource,
    roleAccess: Role[],
    departmentAccess: DepartmentResource[],
    owner: UserResource,
): DocumentViewModel {
    return {
        id: document.id,
        displayName: document.displayName,
        documentType,
        userTypes: roleAccess,
        departments: departmentAccess.map(transformDepartmentResource),
        owner,
        fileName: document.fileName,
        downloadUrl: `data:${document.mimeType};base64,${document.content}`,
        isDataUrl: true,
        createdAt: new Date(document.createdAt),
    };
}
