import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../../redux/@config/AsyncReduxState';
import { FullLoketUser, LoketUser } from '../../../entities/LoketUser/LoketUser';

export type AddUserFormReducerState = AsyncReduxState<{
    isGetUnsyncedEmployeesSuccessful: boolean;
    isGetFullLoketUserSuccessful: boolean;
    fullLoketUser?: FullLoketUser;
    unsyncedEmployees: LoketUser[];
}>

const initialState: AddUserFormReducerState = {
    ...initialAsyncReduxState,
    isGetUnsyncedEmployeesSuccessful: false,
    isGetFullLoketUserSuccessful: false,
    unsyncedEmployees: [],
};

const addUserFormSlice = createSlice({
    name: 'addUserFormReducer',
    initialState,
    reducers: {
        reset: (): AddUserFormReducerState => ({
            ...initialState,
        }),
        setError: (state, action: PayloadAction<string>): AddUserFormReducerState => ({
            ...state,
            error: action.payload,
        }),
        setIsLoading: (state, action: PayloadAction<boolean>): AddUserFormReducerState => ({
            ...state,
            isLoading: action.payload,
        }),
        setIsGetUnsyncedEmployeesSuccessful: (state, action: PayloadAction<boolean>): AddUserFormReducerState => ({
            ...state,
            isGetUnsyncedEmployeesSuccessful: action.payload,
        }),
        setIsGetFullLoketUserSuccessful: (state, action: PayloadAction<boolean>): AddUserFormReducerState => ({
            ...state,
            isGetFullLoketUserSuccessful: action.payload,
        }),
        setFullLoketUser: (state, action: PayloadAction<FullLoketUser>) => ({
            ...state,
            fullLoketUser: action.payload,
        }),
        setUnsyncedEmployees: (state, action: PayloadAction<LoketUser[]>) => ({
            ...state,
            unsyncedEmployees: action.payload,
        }),
    },
});

export const {
    reset,
    setError,
    setIsLoading,
    setIsGetUnsyncedEmployeesSuccessful,
    setIsGetFullLoketUserSuccessful,
    setFullLoketUser,
    setUnsyncedEmployees,
} = addUserFormSlice.actions;

export default addUserFormSlice.reducer;
