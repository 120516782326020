import axios from 'axios';

import {
    BaseScheduleIndexViewModel,
    BaseScheduleRequest,
    BaseScheduleResource,
    BaseScheduleViewModel,
} from './BaseSchedule';
import { transformToBaseSchedule, transformToBaseScheduleIndex } from './BaseScheduleTransformers';

export const getBaseSchedulesApiCall = async (departmentsFilter: string[]): Promise<BaseScheduleIndexViewModel[]> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedules`);

    const response = await axios.get<BaseScheduleResource[]>(String(url), {
        params: {
            ...(departmentsFilter.length > 0 && { departments: departmentsFilter }),
        },
    });

    return response.data.map((resource) => transformToBaseScheduleIndex(resource));
};

export const getBaseSchedulesLazyLoadApiCall = async (departmentsFilter: string[]): Promise<BaseScheduleIndexViewModel[]> => {
    const includes = [
        'baseScheduleShifts.department',
        'baseScheduleShifts.department.group',
        'owner',
    ];

    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedules`);

    const response = await axios.get<BaseScheduleResource[]>(String(url), {
        params: {
            include: includes.join(','),
            ...(departmentsFilter.length > 0 && { departments: departmentsFilter }),
        },
    });

    return response.data.map((resource) => transformToBaseScheduleIndex(resource, true));
};

export const getBaseScheduleApiCall = async (id: string): Promise<BaseScheduleViewModel> => {
    const includes = [
        'baseScheduleShifts.department',
        'baseScheduleShifts.department.group',
        'baseScheduleShifts.baseScheduleShiftPlannings',
        'baseScheduleShifts.baseScheduleShiftPlannings.user',
        'baseScheduleShifts.baseScheduleShiftPlannings.user.person',
    ];

    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedules/${id}`);

    url.searchParams.append('include', includes.join(','));

    const response = await axios.get<BaseScheduleResource>(String(url));

    return transformToBaseSchedule(response.data);
};

export const postBaseScheduleApiCall = async (baseScheduleRequest: BaseScheduleRequest): Promise<BaseScheduleViewModel> => {
    const includes = [
        'baseScheduleShifts.department',
        'baseScheduleShifts.department.group',
        'baseScheduleShifts.baseScheduleShiftPlannings',
        'baseScheduleShifts.baseScheduleShiftPlannings.user',
        'baseScheduleShifts.baseScheduleShiftPlannings.user.person',
    ];

    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedules`);

    url.searchParams.append('include', includes.join(','));

    const response = await axios.post<BaseScheduleResource>(String(url), {
        data: baseScheduleRequest,
    });

    return transformToBaseSchedule(response.data);
};

export const patchBaseScheduleApiCall = async (baseScheduleRequest: BaseScheduleRequest): Promise<BaseScheduleViewModel> => {
    const includes = [
        'baseScheduleShifts.department',
        'baseScheduleShifts.department.group',
        'baseScheduleShifts.baseScheduleShiftPlannings',
        'baseScheduleShifts.baseScheduleShiftPlannings.user',
        'baseScheduleShifts.baseScheduleShiftPlannings.user.person',
    ];

    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedules/${baseScheduleRequest.id}`);

    url.searchParams.append('include', includes.join(','));

    const response = await axios.patch<BaseScheduleResource>(String(url), {
        data: baseScheduleRequest,
    });

    return transformToBaseSchedule(response.data);
};

export const deleteBaseScheduleApiCall = async (id: string): Promise<void> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedules/${id}`);

    await axios.delete(String(url));
};
