import { FC } from 'react';

import classNames from 'classnames';

import { ElementWithTooltip } from '../../../compositions';
import { LeaveOfAbsence } from '../../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { getLeaveOfAbsenceTooltipText } from '../../../entities/LeaveOfAbsence/LeaveOfAbsenceHelper';
import trans from '../../../helpers/trans';
import { LeaveOfAbsenceStatus } from '../../../types';

import './LeaveOfAbsenceBadge.scss';

interface LeaveOfAbsenceBadgeProps {
    leaveOfAbsence: LeaveOfAbsence;
    className?: string;
}

const LeaveOfAbsenceBadge: FC<LeaveOfAbsenceBadgeProps> = ({ leaveOfAbsence, className = '' }) => {
    const isRequest = leaveOfAbsence.status === LeaveOfAbsenceStatus.open;

    const badgeClassName = classNames('leave-of-absence-badge', {
        'leave-of-absence-badge--is-request': isRequest,
    }, className);

    return (
        <ElementWithTooltip
            tooltipIsActive
            tooltipText={getLeaveOfAbsenceTooltipText(leaveOfAbsence, isRequest)}
        >
            <div className={badgeClassName}>
                {isRequest ? trans('entities.leaveOfAbsences.leaveOfAbsenceRequest') : trans('entities.leaveOfAbsences.leaveOfAbsence')}
            </div>
        </ElementWithTooltip>
    );
};

export default LeaveOfAbsenceBadge;
