import {
    addDays,
    addHours,
    endOfWeek,
    setHours,
    setMinutes,
    setSeconds,
    startOfDay,
    startOfWeek,
} from '../../@paco/helpers/date';
import { LocalStorageItemKey } from '../../@paco/redux/@interface/calendar/calendarReducer';
import { TimeModeType } from '../../@paco/types';
import { eachDayOfInterval } from '../../helpers/date';
import {
    WEEK_NAVIGATOR_SET_ACTIVE_PAYROLL_PERIOD,
    WEEK_NAVIGATOR_SET_DATES,
    WEEK_NAVIGATOR_SET_SELECTED_DATE,
    WEEK_NAVIGATOR_SET_TIME_MODE,
} from '../actionTypes';

const defaultStartDay = setSeconds(setMinutes(setHours(addHours(new Date(), -4), 4), 0), 0);
const defaultStartOfWeek = startOfWeek(defaultStartDay, { weekStartsOn: 1 });

const initialState = {
    mode: TimeModeType.week,
    startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    selectedDateAtTimeSwitch: null,
    endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
    selectedDate: startOfDay(defaultStartDay),
    selectedDays: eachDayOfInterval(defaultStartOfWeek, addDays(defaultStartOfWeek, 6)),
};

const weekNavigatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case WEEK_NAVIGATOR_SET_DATES:
            localStorage.setItem('startDate', action.startDate);
            localStorage.setItem('endDate', action.endDate);
            return {
                ...state,
                startDate: action.startDate,
                endDate: action.endDate,
                selectedDays: eachDayOfInterval(action.startDate, action.endDate),
            };
        case WEEK_NAVIGATOR_SET_SELECTED_DATE:
            localStorage.setItem('selectedDate', action.selectedDate);
            return {
                ...state,
                selectedDate: action.selectedDate,
                selectedDateAtTimeSwitch: null,
            };
        case WEEK_NAVIGATOR_SET_TIME_MODE:
            localStorage.setItem(LocalStorageItemKey.timeMode, JSON.stringify(action.mode));
            return {
                ...state,
                mode: action.mode,
                selectedDateAtTimeSwitch: action.prevSelectedDate,
            };
        case WEEK_NAVIGATOR_SET_ACTIVE_PAYROLL_PERIOD:
            return {
                ...state,
                activePayrollPeriod: action.payrollPeriod,
            };
        default:
            return state;
    }
};

export default weekNavigatorReducer;
