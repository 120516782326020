import { FC } from 'react';

import { Skeleton } from '../../../../../@paco/components';

import './CompletePeriodTopBarSkeleton.scss';

interface CompletePeriodTopBarSkeletonProps {
    className?: string;
}

const CompletePeriodTopBarSkeleton: FC<CompletePeriodTopBarSkeletonProps> = ({ className = '' }) => (
    <div className={`complete-period-top-bar-skeleton ${className}`}>
        <div className="complete-period-top-bar-skeleton__period-skeleton-wrapper">
            <Skeleton className="complete-period-top-bar-skeleton__period-skeleton" />
            <Skeleton className="complete-period-top-bar-skeleton__badge-skeleton" />
        </div>
        <div className="complete-period-top-bar-skeleton__select-payroll-period-skeleton-wrapper">
            <Skeleton className="complete-period-top-bar-skeleton__year-skeleton" />
            <Skeleton className="complete-period-top-bar-skeleton__payroll-period-skeleton" />
        </div>
    </div>
);

export default CompletePeriodTopBarSkeleton;
