import { FC } from 'react';

import trans from '../../../../helpers/trans';

import './WidgetLabel.scss';

export interface WidgetLabelProps {
    hasPZLabel?: boolean;
    label: string;
    className?: string;
}

const WidgetLabel: FC<WidgetLabelProps> = ({
    hasPZLabel,
    label,
    className = '',
}) => (
    <div className={`widget-label ${className}`}>
        <div className="widget-label__text">{label}</div>
        {hasPZLabel && <div className="widget-label__pz-label">{trans('common.HR')}</div>}
    </div>
);

export default WidgetLabel;
