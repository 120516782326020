import React, { FC, useMemo } from 'react';

import './LegacySkeleton.scss';


interface SkeletonProps {
    itemWidthRem: number;
    itemWidthVariation?: number;
    itemCount?: number;
    className?: string;
    itemClassName?: string;
}

interface SkeletonItem {
    id: number;
    width: number;
}

const LegacySkeleton:FC<SkeletonProps> = ({
    itemCount = 1,
    itemWidthRem,
    itemWidthVariation = 0,
    className = '',
    itemClassName = '',
}) => {
    const items: SkeletonItem[] = useMemo(
        () => new Array(itemCount)
            .fill(null)
            .map((item, index) => ({
                id: index,
                width: itemWidthRem + (itemWidthRem * (Math.random() * (itemWidthVariation))),
            })),
        [itemCount, itemWidthRem, itemWidthVariation],
    );

    return (
        <div className={`skeleton ${className}`}>
            {
                items.map((item, index) => (
                    <div
                        className={`skeleton__item ${itemClassName}`}
                        key={item.id}
                        style={{
                            width: `${item.width}rem`,
                            animationDelay: `${index * 0.25}s`,
                        }}
                    />
                ))
            }
        </div>
    );
};

export default LegacySkeleton;
