import { PermissionGroup as LegacyPermissionGroup } from '../../../models';
import { transformLegacyPermissionResourceToPermission } from '../Permission/PermissionTransformers';
import { PermissionGroup, PermissionGroupV3Resource } from './PermissionGroup';

export const transformToPermissionGroup = (resource: PermissionGroupV3Resource): PermissionGroup => ({
    id: resource.id,
    name: resource.name,
    slug: resource.slug,
    permissions: [],
});

export const transformLegacyResourceToPermissionGroup = (resource: LegacyPermissionGroup): PermissionGroup => ({
    id: resource.id,
    name: resource.name,
    slug: resource.slug,
    permissions: resource.permissions.map(transformLegacyPermissionResourceToPermission),
});
