import { FC, PropsWithChildren, ReactElement } from 'react';

import './FilterSection.scss';

interface FilterSectionProps {
    className?: string;
}

const FilterSection: FC<PropsWithChildren<FilterSectionProps>> = ({ className = '', children }): ReactElement => (
    <div className={`filter-section ${className}`}>
        {children}
    </div>
);

export default FilterSection;
