import trans from '../helpers/trans';

export const ScheduleRoutes = {
    calendar: () => trans('routes.shifts.calendar.link'),
    conceptShiftsCalendar: () => `${trans('routes.shifts.calendar.link')}?concept=true`,
    shift: (id: string) => `${trans('routes.shifts.calendar.link')}/${id}`,
    shiftConcepts: (id: string) => `${trans('routes.shifts.shiftConcepts.link')}/${id}`,
    baseSchedule: (id: string) => `${trans('routes.shifts.baseSchedules.link')}/${id}`,
    baseSchedules: () => `${trans('routes.shifts.baseSchedules.link')}`,
    baseScheduleShift: (id: string) => `${trans('routes.shifts.baseScheduleShifts.link')}/${id}`,
    specialDays: () => `${trans('routes.shifts.specialDays.link')}`,
    availability: () => `${trans('routes.shifts.availability.link')}`,
    listView: () => `${trans('routes.shifts.listView.link')}`,
    conceptsListView: () => `${trans('routes.shifts.conceptsListView.link')}`,
    hours: () => `${trans('routes.shifts.hours.link')}`,
    districtDivision: () => `${trans('routes.shifts.districtDivision.link')}`,
};

export const UsersRoutes = {
    user: (id: string) => `${trans('routes.users.users.link')}/${id}`,
    users: (page?: string) => `${trans('routes.users.users.link')}${page ? `?page=${page}` : ''}`,
};

export const UserDetailRoutes = {
    personalInfo: () => `${trans('routes.user.personalInfo.link')}`,
    absences: () => `${trans('routes.absences.link')}`,
    couplings: () => `${trans('routes.user.couplings.link')}`,
    documents: () => `${trans('routes.pz.documents.link')}`,
    calendar: () => `${trans('routes.user.calendar.link')}`,
    configurablePermissions: () => `${trans('routes.user.configurablePermissions.link')}`,
    unavailableToWorkTimeSlots: () => `${trans('routes.user.unavailableToWorkTimeSlots.link')}`,
    notes: () => `${trans('routes.user.notes.link')}`,
    feedback: () => `${trans('routes.pz.satisfaction.link')}`,
};
