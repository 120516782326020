import {
    FC,
    FormEvent,
    useMemo,
    useState,
} from 'react';

import {
    DateInput,
    ExportLeaveOfAbsencesFormRadioList,
    FormActionButtons,
    SelectPayrollPeriod,
} from '../../../compositions';
import { SelectedPeriodFormOption } from '../../../compositions/ExportLeaveOfAbsencesFormRadioList/ExportLeaveOfAbsencesFormRadioList';
import { LoketLeaveOfAbsenceHour } from '../../../entities/LeaveOfAbsenceHour/LeaveOfAbsenceHour';
import { LoketLeaveSyncError, LoketLeaveSyncFormData } from '../../../entities/LoketLeaveSync/LoketLeaveSync';
import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import { addSeconds, getStartOfDay } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { ExportLeaveOfAbsenceSyncError, transformLoketSyncErrorsAndLeaveOfAbsenceHoursToExportLeaveOfAbsenceSyncError } from './helpers';
import ExportLeaveOfAbsencesFormError from './subcomponents/ExportLeaveOfAbsencesFormError';

import './ExportLeaveOfAbsencesForm.scss';

interface ExportLeaveOfAbsencesFormProps {
    hasUnknownError?: boolean;
    isLoading?: boolean;
    isPayrollPeriodsLoading?: boolean;
    activePeriod: PayrollPeriod;
    errors: LoketLeaveSyncError[];
    leaveOfAbsenceHours: LoketLeaveOfAbsenceHour[];
    payrollPeriods: PayrollPeriod[];
    onCancel: () => void;
    onSubmit: (formData: LoketLeaveSyncFormData) => void;
}

const ExportLeaveOfAbsencesForm: FC<ExportLeaveOfAbsencesFormProps> = ({
    isLoading,
    isPayrollPeriodsLoading,
    hasUnknownError,
    activePeriod,
    errors,
    leaveOfAbsenceHours,
    payrollPeriods,
    onCancel,
    onSubmit,
}) => {
    const [selectedPeriodOption, setSelectedPeriodOption] = useState<SelectedPeriodFormOption>(SelectedPeriodFormOption.period);
    const [activePayrollPeriod, setActivePayrollPeriod] = useState<PayrollPeriod>(activePeriod);
    const [startDate, setStartDate] = useState<Date>(new Date());

    const exportLeaveOfAbsenceSyncErrors: ExportLeaveOfAbsenceSyncError[] = useMemo(
        () => transformLoketSyncErrorsAndLeaveOfAbsenceHoursToExportLeaveOfAbsenceSyncError(errors, leaveOfAbsenceHours),
        [errors, leaveOfAbsenceHours],
    );

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (selectedPeriodOption === SelectedPeriodFormOption.period) {
            // PayrollPeriods go from 04:00 to 04:00. But Loket api goes from 00:00 to 23:59.
            const justifiedStart = getStartOfDay(activePayrollPeriod.period.start);
            const justifiedEnd = addSeconds(getStartOfDay(activePayrollPeriod.period.end), -1);

            const formData = {
                id: activePayrollPeriod.id,
                start: justifiedStart,
                end: justifiedEnd,
            };
            onSubmit(formData);

            return;
        }

        const formData = {
            start: startDate,
        };
        onSubmit(formData);
    };

    const handleNavigationChange = (value: string): void => {
        setSelectedPeriodOption(value === SelectedPeriodFormOption.period
            ? SelectedPeriodFormOption.period
            : SelectedPeriodFormOption.startDate);
    };

    const handlePeriodChange = (payrollPeriod: PayrollPeriod[]): void => {
        setActivePayrollPeriod(payrollPeriod[0]);
    };

    const handleDateChange = (value: Date): void => {
        setStartDate(value);
    };

    return (
        <form onSubmit={handleSubmit} className="export-leave-of-absences-form">
            <div className="export-leave-of-absences-form__row">
                <div className="export-leave-of-absences-form__col">
                    <ExportLeaveOfAbsencesFormRadioList
                        selectedPeriodType={selectedPeriodOption}
                        onChange={handleNavigationChange}
                        className="export-leave-of-absences-form__radio-list"
                    />
                </div>
            </div>

            <div className="export-leave-of-absences-form__row">
                <div className="export-leave-of-absences-form__col">
                    <p>
                        {trans('containers.forms.exportLOAForm.selectDateRangeForLoket', {
                            dateType: selectedPeriodOption === SelectedPeriodFormOption.period
                                ? trans('types.timeMode.period').toLowerCase()
                                : trans('common.startDate').toLowerCase(),
                        })}
                        <strong>{` ${trans('common.lookOut')}! `}</strong>
                        {selectedPeriodOption === SelectedPeriodFormOption.period ? (
                            trans('containers.forms.exportLOAForm.datesWarning')
                        ) : (
                            trans('containers.forms.exportLOAForm.dateWarning')
                        )}
                    </p>
                </div>
            </div>

            <div className="export-leave-of-absences-form__row">
                {selectedPeriodOption === SelectedPeriodFormOption.period ? (
                    <SelectPayrollPeriod
                        isLoading={isPayrollPeriodsLoading}
                        activePeriod={activePayrollPeriod}
                        periods={payrollPeriods}
                        onChange={handlePeriodChange}
                        colClassName="export-leave-of-absences-form__col"
                    />
                ) : (
                    <DateInput
                        label={trans('common.date')}
                        type="date"
                        value={startDate}
                        onChange={handleDateChange}
                        className="export-leave-of-absences-form__col export-leave-of-absences-form__date-input"
                    />
                )}
            </div>

            {hasUnknownError && (
                <div className="export-leave-of-absences-form__row export-leave-of-absences-form__unknown-error">
                    {trans('errors.unknownError')}
                </div>
            )}

            {exportLeaveOfAbsenceSyncErrors.length > 0 && (
                <div className="export-leave-of-absences-form__error-wrapper">
                    <p className="export-leave-of-absences-form__row export-leave-of-absences-form__sync-errors-intro">
                        {trans('containers.forms.exportLOAForm.exportErrorMessage')}
                    </p>
                    <ul className="export-leave-of-absences-form__row export-leave-of-absences-form__sync-errors-list">
                        {exportLeaveOfAbsenceSyncErrors.map(exportLeaveOfAbsenceSyncError => (
                            <li
                                key={exportLeaveOfAbsenceSyncError.leaveOfAbsenceHourId}
                                className="export-leave-of-absences-form__sync-errors-list-item"
                            >
                                <ExportLeaveOfAbsencesFormError
                                    exportLeaveOfAbsenceSyncError={exportLeaveOfAbsenceSyncError}
                                    className="export-leave-of-absences-form__sync-error"
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <FormActionButtons
                isLoading={isLoading}
                hideSubmit={errors.length > 0}
                cancelText={errors.length > 0 ? trans('common.close') : trans('compositions.modalContent.cancel')}
                submitText={trans('common.sync')}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default ExportLeaveOfAbsencesForm;
