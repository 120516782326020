import coupledPermissions from '../../@paco/helpers/permissions/coupledPermissions';
import transformPermissionsToConfigurablePermissions
    from '../../helpers/permissions/transformPermissionsToConfigurablePermissions';
import { UserWithEmployment } from '../../models/UserWithEmployment';
import { deductOneSecondFromLOARequests } from '../absences-ts/absencesHelpers';

// TODO: Write proper "api response to user transformer" in helpers/api-ts/user.ts
export function transformUserLeaveOfAbsencesAndPermissions(userData: any): any {
    const { leaveOfAbsences } = userData;
    // eslint-disable-next-line no-param-reassign
    userData.leaveOfAbsences = deductOneSecondFromLOARequests(leaveOfAbsences);
    if (userData.roles[0]) {
        // eslint-disable-next-line no-param-reassign
        userData.roles[0].configurablePermissions = transformPermissionsToConfigurablePermissions(
            userData.roles[0].configurablePermissions,
            coupledPermissions,
        );
    }

    return userData;
}

export function getUsersWithUpdatedUser(newUser: UserWithEmployment, users: UserWithEmployment[])
    : UserWithEmployment[] {
    return users.map((user: UserWithEmployment) => {
        if (user.id === newUser.id) {
            return newUser;
        }

        return user;
    });
}
