import { FC, ReactElement, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { LoadingSpinner } from '../../../../../@paco/components';
import { DepartmentsList } from '../../../../../@paco/compositions';
import { ConnectedHelpTooltip } from '../../../../../@paco/connectors';
import { transformLegacyDepartmentToDepartment } from '../../../../../@paco/entities/Department/DepartmentTransformers';
import { getUniqueArrayList } from '../../../../../@paco/helpers/array';
import trans from '../../../../../@paco/helpers/trans';
import { ScheduleRoutes } from '../../../../../@paco/routes/helpers';
import { PrivateRouteGroupRoutes } from '../../../../../@paco/routes/routes';
import LegacySkeleton from '../../../../../components/LegacySkeleton/LegacySkeleton';
import TableActionButtons from '../../../../../components/Table/subcomponents/TableActionButtons/TableActionButtons';
import { TableAttribute } from '../../../../../components/Table/subcomponents/TableHead';
import TableContainer from '../../../../../components/Table/TableContainer';
import { BaseScheduleIndexViewModel, BaseScheduleViewModel } from '../../../../../entities/BaseSchedule/BaseSchedule';
import { translate } from '../../../../../helpers/translations/translator';

import './BaseSchedulesTable.scss';

interface BaseScheduleTableProps {
    isLoading: boolean;
    isLazyLoading: boolean;
    canEditBaseSchedules: boolean;
    canDeleteBaseSchedules: boolean;
    canViewBaseScheduleShifts: boolean;
    baseSchedules: BaseScheduleIndexViewModel[];
    onDeleteBaseScheduleButtonClick: (baseSchedule: BaseScheduleViewModel) => void;
    onEditBaseScheduleButtonClick: (baseSchedule: BaseScheduleViewModel) => void;
    className?: string;
}

const BaseSchedulesTable: FC<BaseScheduleTableProps> = ({
    isLoading,
    isLazyLoading,
    canEditBaseSchedules,
    canDeleteBaseSchedules,
    canViewBaseScheduleShifts,
    baseSchedules,
    onDeleteBaseScheduleButtonClick,
    onEditBaseScheduleButtonClick,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const handleRowClick = (baseScheduleId: string) => navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.baseSchedule(baseScheduleId)}`);

    const tableAttributes: TableAttribute<BaseScheduleIndexViewModel>[] = useMemo(() => ([
        {
            label: translate('common.name'),
            key: 'name',
            className: 'base-schedules-table__row--name',
        },
        {
            label: translate('common.department(s)'),
            customAction: (baseSchedule) => {
                const departments = useMemo(() => (
                    getUniqueArrayList(baseSchedule.departments, 'id').map(transformLegacyDepartmentToDepartment)
                ), [baseSchedule]);

                return (
                    isLazyLoading
                        ? (
                            <LegacySkeleton
                                key={baseSchedule.id}
                                itemWidthRem={2}
                                itemWidthVariation={2}
                                itemCount={1}
                            />
                        )
                        : (
                            <DepartmentsList
                                departments={departments}
                            />
                        )
                );
            },
            className: 'base-schedules-table__row--departments',
        },
        {
            label: translate('common.createdBy'),
            customAction: (baseSchedule) => (
                isLazyLoading || !baseSchedule.createdBy
                    ? (
                        <LegacySkeleton
                            key={baseSchedule.id}
                            itemWidthRem={2}
                            itemWidthVariation={2}
                            itemCount={1}
                        />
                    )
                    : (
                        baseSchedule.createdBy
                    )
            ),
            className: 'base-schedules-table__row--created-by',
        },
        {
            customAction: (baseSchedule) => {
                const handleOnEditButtonClick = () => onEditBaseScheduleButtonClick(baseSchedule);
                const handleOnDeleteButtonClick = () => onDeleteBaseScheduleButtonClick(baseSchedule);

                return (
                    <TableActionButtons
                        onEditButtonClick={canEditBaseSchedules ? handleOnEditButtonClick : undefined}
                        onDeleteButtonClick={canDeleteBaseSchedules ? handleOnDeleteButtonClick : undefined}
                    />
                );
            },
            className: 'base-schedules-table__row--buttons',
        },
    ]), [
        baseSchedules,
        isLazyLoading,
        canEditBaseSchedules,
        canDeleteBaseSchedules,
    ]);

    return (
        <div className={`base-schedules-table ${className}`}>
            <ConnectedHelpTooltip
                index={0}
                route="base-schedules"
                subTitle={trans('help.baseSchedules.addSchedule.title')}
                text={trans('help.baseSchedules.addSchedule.text')}
                title={trans('help.baseSchedules.title')}
                showMobileInfoWarning
                className="calendar__add-base-schedules-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={1}
                route="base-schedules"
                subTitle={trans('help.baseSchedules.filters.title')}
                text={trans('help.baseSchedules.filters.text')}
                title={trans('help.baseSchedules.title')}
                showMobileInfoWarning
                className="calendar__base-schedules-filters-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={2}
                route="base-schedules"
                subTitle={trans('help.baseSchedules.overview.title')}
                text={trans('help.baseSchedules.overview.text')}
                title={trans('help.baseSchedules.title')}
                className="calendar__base-schedules-overview-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={3}
                route="base-schedules"
                subTitle={trans('help.baseSchedules.editBaseSchedule.title')}
                text={trans('help.baseSchedules.editBaseSchedule.text')}
                title={trans('help.baseSchedules.title')}
                showMobileInfoWarning
                className="calendar__edit-base-schedule-help-tooltip"
            />
            {isLoading && (
                <LoadingSpinner />
            )}

            {baseSchedules.length > 0 && (
                <TableContainer<BaseScheduleIndexViewModel>
                    attributes={tableAttributes}
                    data={baseSchedules}
                    onRowClick={canViewBaseScheduleShifts ? handleRowClick : undefined}
                    tableRowClassName="base-schedules-table__table-row"
                />
            )}
        </div>
    );
};

export default BaseSchedulesTable;
