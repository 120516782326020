import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { UserWithEmployment } from '../../entities/User/User';
import { DivergentPostEmploymentHourSlice } from '../divergentPostEmploymentHour/divergentPostEmploymentHourReducer';
import { DivergentPreEmploymentHourSlice } from '../divergentPreEmploymentHour/divergentPreEmploymentHourReducer';
import { getUserWithDivergentPostEmploymentHour, getUserWithDivergentPreEmploymentHour } from './usersWithDivergentEmploymentHoursHelpers';

export type UsersWithDivergentEmploymentHoursState = AsyncReduxState<{
    usersWithDivergentEmploymentHours: UserWithEmployment[],
    usersWithIncompleteDivergentEmploymentHours: UserWithEmployment[],
}>;

const initialState: UsersWithDivergentEmploymentHoursState = {
    ...initialAsyncReduxState,
    usersWithDivergentEmploymentHours: [],
    usersWithIncompleteDivergentEmploymentHours: [],
};

export const UsersWithDivergentEmploymentHoursSlice = createSlice({
    name: 'usersWithDivergentEmploymentHoursReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): UsersWithDivergentEmploymentHoursState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): UsersWithDivergentEmploymentHoursState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): UsersWithDivergentEmploymentHoursState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setUsersWithIncompleteDivergentEmploymentHours(state, action: PayloadAction<UserWithEmployment[]>): UsersWithDivergentEmploymentHoursState {
            return {
                ...state,
                usersWithIncompleteDivergentEmploymentHours: action.payload,
            };
        },
        setUsersWithDivergentEmploymentHours(state, action: PayloadAction<UserWithEmployment[]>): UsersWithDivergentEmploymentHoursState {
            return {
                ...state,
                usersWithDivergentEmploymentHours: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(DivergentPostEmploymentHourSlice.actions.setDivergentPostEmploymentHour, (state, action) => {
            const userIndex = state.usersWithDivergentEmploymentHours.findIndex(user => user.employment?.divergentPostEmploymentHour?.id === action.payload?.id);

            if (!action.payload || userIndex === -1) {
                return state;
            }

            const updatedUser = getUserWithDivergentPostEmploymentHour(state.usersWithDivergentEmploymentHours[userIndex], action.payload);
            const updatedUsers = [...state.usersWithDivergentEmploymentHours];
            updatedUsers.splice(userIndex, 1, updatedUser);

            const updatedState: UsersWithDivergentEmploymentHoursState = {
                ...state,
                usersWithDivergentEmploymentHours: updatedUsers,
            };

            return updatedState;
        });

        builder.addCase(DivergentPreEmploymentHourSlice.actions.setDivergentPreEmploymentHour, (state, action) => {
            const userIndex = state.usersWithDivergentEmploymentHours.findIndex(user => user.employment?.divergentPreEmploymentHour?.id === action.payload?.id);

            if (!action.payload || userIndex === -1) {
                return state;
            }

            const updatedUser = getUserWithDivergentPreEmploymentHour(state.usersWithDivergentEmploymentHours[userIndex], action.payload);
            const updatedUsers = [...state.usersWithDivergentEmploymentHours];
            updatedUsers.splice(userIndex, 1, updatedUser);

            const updatedState: UsersWithDivergentEmploymentHoursState = {
                ...state,
                usersWithDivergentEmploymentHours: updatedUsers,
            };

            return updatedState;
        });
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setUsersWithDivergentEmploymentHours,
    setUsersWithIncompleteDivergentEmploymentHours,
} = UsersWithDivergentEmploymentHoursSlice.actions;

export default UsersWithDivergentEmploymentHoursSlice.reducer;
