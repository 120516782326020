import { FC, ReactElement } from 'react';

import classnames from 'classnames';

import { LoadingSpinner } from '../../../components';
import { ValkTable } from '../../../compositions';
import { EventLog } from '../../../entities/EventLog/EventLog';
import { EventLogCategory } from '../../../entities/EventLogCategory/EventLogCategory';
import trans from '../../../helpers/trans';
import { PzLogRow } from './subcomponents';

import './PzLogsTable.scss';

interface PzLogsTableProps {
    isLoading?: boolean;
    categories: EventLogCategory[];
    logs: EventLog[];
    onViewLogClick: (log: EventLog) => void;
    className?: string;
}

const tableHeader = [
    trans('common.category'),
    trans('containers.tables.pzLogsTable.log'),
    trans('common.date'),
    '',
];

const IncompleteUsersTable: FC<PzLogsTableProps> = ({
    isLoading,
    categories,
    logs,
    onViewLogClick,
    className = '',
}): ReactElement => {
    const wrapperClassName = classnames('pz-logs-table', {
        'pz-logs-table--is-empty': !logs.length,
    }, className);

    if (isLoading && !logs.length) {
        return (
            <div className={wrapperClassName}>
                <LoadingSpinner isSmall className="pz-logs-table__loader" />
            </div>
        );
    }

    return (
        <div className={wrapperClassName}>
            <ValkTable<EventLog>
                hasRowClick
                rows={logs}
                tableHeaders={tableHeader}
                tableClassName="pz-logs-table__table"
                className="pz-logs-table__valk-table"
                cellClassName="pz-logs-table__table-cell"
            >
                {(pzLogs): JSX.Element[] => pzLogs.map(log => {
                    const logCategory = categories.find(category => category.slug === log.categorySlug);

                    return (
                        <PzLogRow
                            key={log.id}
                            category={logCategory}
                            log={log}
                            onViewLogClick={onViewLogClick}
                            cellClassName="pz-logs-table__table-cell"
                        />
                    );
                })}
            </ValkTable>
            {isLoading && <LoadingSpinner isSmall className="pz-logs-table__loader" />}
        </div>
    );
};

export default IncompleteUsersTable;
