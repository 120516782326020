import {
    USERS_GET_EMPLOYEES_FAILURE,
    USERS_GET_EMPLOYEES_REQUEST,
    USERS_GET_EMPLOYEES_SUCCESS,
    USERS_MAPPED_ABSENCE_DATA_TO_EMPLOYEES,
} from '../actionTypes';

const initialState = {
    users: [],
    employees: [],
    start: null,
    end: null,
    department: null,
    errors: [],
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case USERS_GET_EMPLOYEES_REQUEST:
            return { ...state, employees: [] };
        case USERS_GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.employees,
                start: action.start,
                department: action.department,
                end: action.end,
            };
        case USERS_GET_EMPLOYEES_FAILURE:
            return { ...state, errors: [action.error, ...state.errors] };
        case USERS_MAPPED_ABSENCE_DATA_TO_EMPLOYEES:
            return { ...state, employees: action.employees };
        default:
            return state;
    }
};

export default usersReducer;
