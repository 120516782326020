import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { FormActionButtons, TextInput } from '../../../compositions';
import { AddTemporaryWorkerFormData } from '../../../entities/TemporaryWorker/TemporaryWorker';
import trans from '../../../helpers/trans';

import './AddTemporaryWorkerForm.scss';

interface AddTemporaryWorkerFormProps {
    isLoading: boolean;
    onCancel: () => void;
    onSubmit: (formData: AddTemporaryWorkerFormData) => void;
    className?: string;
}

const AddTemporaryWorkerForm: FC<AddTemporaryWorkerFormProps> = ({
    isLoading,
    onCancel,
    onSubmit,
    className = '',
}): ReactElement => {
    const formRef = useRef<HTMLFormElement>(null);
    const [name, setName] = useState<string>('');

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        onSubmit({ name });
    };

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={`add-temporary-worker-form ${className}`}
        >
            <div className="add-shift-form__row">
                <div className="add-shift-form__col">
                    <TextInput
                        label={trans('common.name')}
                        value={name}
                        onChange={setName}
                    />
                </div>
            </div>

            <FormActionButtons
                isLoading={isLoading}
                disabled={!name}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default AddTemporaryWorkerForm;
