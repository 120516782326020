import { EditShiftPlanningTimeFormData } from '../../../models';
import { postCommentApiCall } from '../../entities/Comment/CommentService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { EditShiftPlanningFormData } from '../../entities/ShiftPlanning/ShiftPlanning';
import { deleteShiftPlanningCall, editShiftPlanningApiCall, postShiftPlanningCall } from '../../entities/ShiftPlanning/ShiftPlanningService';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsAddSuccessful,
    setIsDeleteSuccessful,
    setIsEditSuccessful,
    setIsLoading,
    setIsPlannedSuccessful,
    setLastAddedShiftPlanning,
    setLastDeletedShiftPlanningId,
    setShiftPlanning,
} from './shiftPlanningReducer';

export const editShiftPlanningTime = (editShiftPlanningFormData: EditShiftPlanningTimeFormData, shiftPlanningId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsEditSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await editShiftPlanningApiCall(editShiftPlanningFormData, shiftPlanningId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftPlanning(response.data));
        dispatch(setIsEditSuccessful(true));
    } catch (error) {
        console.error('[editShiftPlanningTime]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editShiftPlanning = (editShiftPlanningFormData: EditShiftPlanningFormData, shiftPlanningId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsEditSuccessful(false));
    dispatch(setError(''));

    try {
        if (editShiftPlanningFormData.comment) {
            await postCommentApiCall({
                comment: editShiftPlanningFormData.comment,
                relationshipId: shiftPlanningId,
                relationshipKey: 'shiftPlanning',
                relationshipType: 'shiftPlannings',
            });
        }

        const response = await editShiftPlanningApiCall(editShiftPlanningFormData, shiftPlanningId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftPlanning(response.data));
        dispatch(setIsEditSuccessful(true));
    } catch (error) {
        console.error('[editShiftPlanningTime]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editShiftPlanningColor = (
    shiftPlanningId: string,
    color?: string,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsEditSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await editShiftPlanningApiCall({ color: color || null }, shiftPlanningId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftPlanning(response.data));
        dispatch(setIsEditSuccessful(true));
    } catch (error) {
        console.error('[editShiftPlanningColor]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const planUnplannedShiftPlanning = (shiftPlanningId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsPlannedSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await editShiftPlanningApiCall({ planned: true }, shiftPlanningId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftPlanning(response.data));
        dispatch(setIsPlannedSuccessful(true));
    } catch (error) {
        console.error('[planUnplannedShiftPlanning]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteShiftPlanning = (shiftPlanningId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsDeleteSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await deleteShiftPlanningCall(shiftPlanningId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLastDeletedShiftPlanningId(shiftPlanningId));
        dispatch(setIsDeleteSuccessful(true));
    } catch (error) {
        console.error('[deleteShiftPlanning]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addShiftPlanning = (shiftId: string, userId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsAddSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await postShiftPlanningCall(shiftId, userId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLastAddedShiftPlanning(response.data));
        dispatch(setIsAddSuccessful(true));
    } catch (error) {
        console.error('[addShiftPlanning]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
