import { FC } from 'react';

import { Setting } from '../../../../entities/Setting/Setting';
import { PrintableDistrictDivisionBlock } from '../../models';
import { PrintableDistrictDivisionCommentBlockItem, PrintableDistrictDivisionDistrictBlockItem, PrintableDistrictDivisionTitleBlockItem } from '../index';

interface PrintableDistrictDivisionColumnProps {
    isSizer?: boolean;
    blocks: PrintableDistrictDivisionBlock[];
    settings: Setting[];
    className?: string;
}

const PrintableDistrictDivisionColumn: FC<PrintableDistrictDivisionColumnProps> = ({
    isSizer,
    blocks,
    settings,
    className = '',
}) => (
    <div className={`printable-district-division-column ${className}`}>
        {
            blocks.map(block => {
                if (block.type === 'printable-district-division-title-block') {
                    return (
                        <PrintableDistrictDivisionTitleBlockItem
                            key={block.id}
                            block={block}
                        />
                    );
                }

                if (block.type === 'printable-district-division-district-block') {
                    return (
                        <PrintableDistrictDivisionDistrictBlockItem
                            key={block.id}
                            isSizer={isSizer}
                            block={block}
                            settings={settings}
                        />
                    );
                }

                if (block.type === 'printable-district-division-comment-block') {
                    return (
                        <PrintableDistrictDivisionCommentBlockItem
                            key={block.id}
                            isSizer={isSizer}
                            block={block}
                        />
                    );
                }

                return null;
            })
        }
    </div>
);

export default PrintableDistrictDivisionColumn;
