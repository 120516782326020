import { FC, useMemo } from 'react';

import { DistrictDivision } from '../../entities/DistrictDivision/DistrictDivision';
import { Setting } from '../../entities/Setting/Setting';
import PrintableBlockOffsetsProvider from './contexts/PrintableBlockOffsetsContext';
import { getPrintableDistrictDivisionPages } from './helpers';
import { PrintableDistrictDivisionPages } from './subcomponents';

import './PrintableDistrictDivision.scss';

interface PrintableDistrictDivisionProps {
    districtDivision: DistrictDivision;
    settings: Setting[];
    className?: string;
}

const PrintableDistrictDivision: FC<PrintableDistrictDivisionProps> = ({
    districtDivision,
    settings,
    className = '',
}) => {
    const page = useMemo(() => getPrintableDistrictDivisionPages(districtDivision), [districtDivision]);

    return (
        <PrintableBlockOffsetsProvider>
            <div className={`printable-district-division ${className}`} data-printable="true">
                <PrintableDistrictDivisionPages
                    page={page}
                    settings={settings}
                />
            </div>
        </PrintableBlockOffsetsProvider>
    );
};

export default PrintableDistrictDivision;
