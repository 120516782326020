import { Permission as LegacyPermissionResource } from '../../../models/Permission';
import { transformToPermissionGroup } from '../PermissionGroup/PermissionGroupTransformers';
import {
    EditPermissionRequest,
    Permission,
    PermissionResource,
    PermissionV3Resource,
} from './Permission';

export const transformLegacyPermissionResourceToPermission = (resource: LegacyPermissionResource): Permission => ({
    id: resource.id,
    name: resource.name,
    slug: resource.slug,
    description: resource.description,
});

export const transformToPermission = (resource: PermissionResource): Permission => ({
    id: resource.id,
    name: resource.attributes.name,
    slug: resource.attributes.slug,
    description: resource.attributes.description,
});

export const transformV3ResourceToPermission = (resource: PermissionV3Resource): Permission => ({
    id: resource.id,
    name: resource.name,
    slug: resource.slug,
    description: resource.description || undefined,
    permissionGroup: resource.permissionGroup ? transformToPermissionGroup(resource.permissionGroup) : undefined,
});

export const transformPermissionIdsToEditPermissionRequest = (
    permissionIds: string[],
    userId: string,
): EditPermissionRequest => ({
    type: 'users',
    id: userId,
    relationships: {
        permissions: {
            data: permissionIds.map(id => ({
                type: 'permissions',
                id,
            })),
        },
    },
});
