import React, { FC, useEffect, useMemo } from 'react';

import { useMediaQueries } from '@react-hook/media-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CalendarTypeFormOption } from '../../../../@paco/compositions/CalendarNavRadioList/CalendarTypeRadioList';
import useCheckPermission from '../../../../@paco/helpers/hooks/useCheckPermission';
import { getCheckedFormOptionsValue } from '../../../../@paco/helpers/types/formOption';
import { setDeletedShiftConceptId, setIsDeleteShiftConceptSuccessful } from '../../../../@paco/redux/shiftConcept/shiftConceptReducer';
import { useTypedSelector } from '../../../../@paco/redux/store';
import { ScheduleRoutes } from '../../../../@paco/routes/helpers';
import { PrivateRouteGroupRoutes } from '../../../../@paco/routes/routes';
import { BREAK_TABLET_LANDSCAPE } from '../../../../constants';
import { PublishBaseScheduleFormData } from '../../../../entities/ShiftConcept/ShiftConcept';
import { AddShiftFormData, ShiftType } from '../../../../models';
import { fetchBaseSchedules } from '../../../../redux/@toolkit/baseSchedules/baseSchedulesActions';
import { fetchDepartments } from '../../../../redux/@toolkit/departments/departmentsActions';
import {
    addShiftConcept,
    copyShiftConcepts,
    deleteShiftConcepts,
    fetchAvailabilityData,
    fetchShiftConcepts,
    fetchShifts,
    publishBaseSchedule,
} from '../../../../redux/@toolkit/shiftConcepts/shiftConceptsActions';
import {
    setIsPublishShiftConceptsSuccessful,
    setIsSuccessful,
    setLastModifiedShiftConceptId,
    setShiftConcepts,
} from '../../../../redux/@toolkit/shiftConcepts/shiftConceptsReducer';
import { fetchSpecialDays } from '../../../../redux/@toolkit/specialDays/specialDaysActions';
import { getPayrollPeriods } from '../../../../redux/app/appActions';
import { setEmployeeSearch, setFilter } from '../../../../redux/filter/filterActions';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import { Reducers } from '../../../../redux/reducers';
import { TypedDispatch } from '../../../../redux/store';
import { setStartAndEndDate } from '../../../../redux/weekNavigator/weekNavigatorActions';
import ConceptsCalendarOverview from '../../containers/ConceptsCalendarOverview/ConceptsCalendarOverview';

interface ConnectedConceptsCalendarProps {
    onNavigationChange: () => void;
}

const ConnectedConceptsCalendar: FC<ConnectedConceptsCalendarProps> = ({ onNavigationChange }) => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();

    const { user } = useTypedSelector(state => state.authenticatedUserReducer);
    const {
        payrollPeriods,
        roles,
        isConceptMode,
    } = useSelector((state: Reducers) => state.appReducer);
    const { isLoading: isDepartmentsLoading, departments } = useSelector((state: Reducers) => state.departmentsReducer);
    const { isLoading: isShiftConceptLoading, isDeleteShiftConceptSuccessful, deletedShiftConceptId } = useSelector((state: Reducers) => state.pacoShiftConceptReducer);

    const {
        mode: timeMode,
        startDate,
        endDate,
        selectedDays,
        selectedDate,
    } = useSelector((state: Reducers) => state.weekNavigatorReducer);
    const {
        isLoading: isShiftConceptsLoading,
        isSuccessful,
        isPublishShiftConceptsSuccessful,
        isShiftsLoading,
        shifts,
        shiftConcepts,
        leaveOfAbsences,
        absences,
        unavailableWorkTimeSlots,
        lastModifiedShiftConceptId,
    } = useSelector((state: Reducers) => state.shiftConceptsReducer);
    const { specialDays } = useSelector((state: Reducers) => state.specialDaysReducer);
    const { baseSchedules } = useSelector((state: Reducers) => state.baseSchedulesReducer);
    const filter = useSelector((state: Reducers) => state.filterReducer.filter);
    const { departmentOptions } = useTypedSelector(state => state.globalFiltersReducer);
    const departmentIds = useMemo(() => getCheckedFormOptionsValue(departmentOptions), [departmentOptions]);

    const isLoading = isShiftsLoading
        || isDepartmentsLoading
        || isShiftConceptLoading
        || isShiftConceptsLoading;
    const currentUserId = user?.id;
    const { employmentTypeBadge, shifts: shiftFilter } = filter;
    const { showPublishedShifts } = shiftFilter;

    const canAddShiftConcepts = useCheckPermission('add-new-shift-concepts');
    const canEditShifts = useCheckPermission('edit-all-shifts');
    const canDeleteShiftConcepts = useCheckPermission('delete-all-shift-concepts');
    const canViewShiftConcepts = useCheckPermission('view-all-shift-concepts');
    const canInsertBaseSchedules = useCheckPermission('publish-base-schedules');
    const canEditShiftConcepts = useCheckPermission('edit-all-shift-concepts');
    const canPublishBaseSchedules = useCheckPermission('publish-base-schedules');
    const canPublishShiftConcepts = useCheckPermission('publish-shift-concepts');

    const { matchesAll: isMobile } = useMediaQueries({ screen: 'screen', width: `(max-width: ${BREAK_TABLET_LANDSCAPE}px)` });

    useEffect((): void => {
        dispatch(fetchShiftConcepts(
            startDate,
            endDate,
            filter,
            departmentIds,
        ));
        dispatch(fetchSpecialDays(startDate, endDate));
        if (showPublishedShifts) {
            dispatch(fetchShifts(
                startDate,
                endDate,
                filter,
                departmentIds,
            ));
        }
    }, [startDate, endDate, filter, departmentIds]);

    useEffect((): void => {
        if (shiftConcepts) {
            dispatch(fetchAvailabilityData(startDate, endDate));
        }
    }, [shiftConcepts]);

    useEffect((): void => {
        if (!payrollPeriods.length) {
            dispatch(getPayrollPeriods());
        }

        if (!departments.length) {
            dispatch(fetchDepartments());
        }
    }, []);

    useEffect((): void => {
        if (isSuccessful) {
            dispatch(fetchShiftConcepts(
                startDate,
                endDate,
                filter,
                departmentIds,
            ));
            dispatch(setLastModifiedShiftConceptId(undefined));
            dispatch(setIsSuccessful(false));
        }
    }, [isSuccessful]);

    useEffect((): void => {
        if (isPublishShiftConceptsSuccessful) {
            dispatch(fetchShifts(
                startDate,
                endDate,
                filter,
                departmentIds,
            ));
            dispatch(fetchShiftConcepts(
                startDate,
                endDate,
                filter,
                departmentIds,
            ));
            dispatch(setIsPublishShiftConceptsSuccessful(false));
        }
    }, [isPublishShiftConceptsSuccessful]);

    useEffect((): void => {
        if (isDeleteShiftConceptSuccessful) {
            dispatch(setShiftConcepts(shiftConcepts.filter(shiftConcept => shiftConcept.id !== deletedShiftConceptId)));
            dispatch(setDeletedShiftConceptId(undefined));
            dispatch(setIsDeleteShiftConceptSuccessful(false));
        }
    }, [isDeleteShiftConceptSuccessful]);

    useEffect(() => {
        dispatch(fetchBaseSchedules(getCheckedFormOptionsValue(departmentOptions)));
    }, [departmentOptions]);

    const handleSetFilter = (value: AppFilter) => {
        dispatch(setLastModifiedShiftConceptId(undefined));
        dispatch(setFilter(value));
    };

    const handleSetStartAndEndDate = (date: Date, newStartDate: Date, newEndDate: Date) => {
        dispatch(setStartAndEndDate(date, newStartDate, newEndDate));
    };

    const handleSubmitDeleteShifts = async (shiftConceptIds: string[]) => {
        dispatch(deleteShiftConcepts(shiftConceptIds));
    };

    const handleSubmitCopyShifts = async (shiftConceptIds: string[], copyEmployees: boolean, dateOffset: number) => {
        dispatch(copyShiftConcepts(shiftConceptIds, copyEmployees, dateOffset));
    };

    const handleSubmitAddShift = async (formData: AddShiftFormData) => {
        if (currentUserId) {
            dispatch(addShiftConcept(formData, currentUserId, shiftConcepts));
        }
    };

    const handleShiftClick = (shiftId: string, shiftType: ShiftType) => {
        const path = shiftType === ShiftType.concept ? ScheduleRoutes.shiftConcepts(shiftId) : ScheduleRoutes.shift(shiftId);
        navigate(`${PrivateRouteGroupRoutes.shifts()}/${path}`);
    };

    const handleSubmitPublishBaseSchedules = (formData: PublishBaseScheduleFormData) => {
        dispatch(publishBaseSchedule(formData));
    };

    const handleSearchBarChange = (search: string) => {
        dispatch(setEmployeeSearch(search));
    };

    return (
        <ConceptsCalendarOverview
            isLoading={isLoading}
            canAddShifts={canAddShiftConcepts}
            canEditShifts={canEditShifts}
            canDeleteShiftConcepts={canDeleteShiftConcepts}
            canViewShiftConcepts={canViewShiftConcepts}
            canInsertBaseSchedules={canInsertBaseSchedules}
            canEditShiftConcepts={canEditShiftConcepts}
            canPublishBaseSchedules={canPublishBaseSchedules}
            canPublishShiftConcepts={canPublishShiftConcepts}
            isMobile={isMobile}
            highlightShiftId={lastModifiedShiftConceptId || undefined}
            selectedCalendarType={isConceptMode ? CalendarTypeFormOption.concepts : CalendarTypeFormOption.shifts}
            filter={filter}
            departments={departments}
            payrollPeriods={payrollPeriods}
            baseSchedules={baseSchedules}
            shifts={[...(showPublishedShifts ? shifts : []), ...shiftConcepts]}
            specialDays={specialDays}
            leaveOfAbsences={leaveOfAbsences}
            absences={absences}
            unavailableTimeSlots={unavailableWorkTimeSlots}
            roles={roles}
            timeMode={timeMode}
            showEmploymentTypeBadge={employmentTypeBadge}
            showEmployees={shiftFilter.showEmployees}
            selectedDays={isMobile ? [selectedDate] : selectedDays}
            onSetFilter={handleSetFilter}
            onSetStartAndEndDate={handleSetStartAndEndDate}
            onSearchBarChange={handleSearchBarChange}
            onSubmitPublishBaseSchedules={handleSubmitPublishBaseSchedules}
            onShiftClick={handleShiftClick}
            onNavigationChange={onNavigationChange}
            onSubmitDeleteShifts={handleSubmitDeleteShifts}
            onSubmitCopyShifts={handleSubmitCopyShifts}
            onSubmitAddShift={handleSubmitAddShift}
        />
    );
};

export default ConnectedConceptsCalendar;
