import axios from 'axios';

import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { convertDateToApiParamDate } from '../../helpers/date';
import { Filter } from '../../models';
import { PublishBaseScheduleResultResource, PublishBaseScheduleResultViewModel } from '../BaseSchedule/BaseSchedule';
import { transformToPublishBaseScheduleResult } from '../BaseSchedule/BaseScheduleTransformers';
import {
    AddShiftConceptRequest,
    EditShiftConceptRequest,
    ShiftConceptCopyResultResource,
    ShiftConceptResource,
    ShiftConceptViewModelOld,
} from './ShiftConcept';
import { transformToShiftConcept } from './ShiftConceptTransformers';

let getShiftConceptsApiCallAbortController = new AbortController();

export const getShiftConceptsApiCall = async (
    startDate: Date,
    endDate: Date,
    filter: Filter,
    departments: string[],
    includes: string[] = [],
): Promise<ShiftConceptViewModelOld[]> => {
    getShiftConceptsApiCallAbortController.abort();
    getShiftConceptsApiCallAbortController = new AbortController();

    const { employeeSearch: search } = filter || {};
    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concepts`);

    if (search) url.searchParams.append('search', search);

    const response = await axios.get<ShiftConceptResource[]>(String(url), {
        params: {
            include: includes.join(','),
            startDate: formatDate(startDate, DateFormat.apiDateTime),
            endDate: formatDate(endDate, DateFormat.apiDateTime),
            departments: (departments && departments.length > 0) ? JSON.stringify(departments) : undefined,
        },
        signal: getShiftConceptsApiCallAbortController.signal,
    });

    return response.data.map(transformToShiftConcept);
};

export const getShiftConceptApiCall = async (id: string): Promise<ShiftConceptViewModelOld> => {
    const includes = [
        'shiftConceptPlannings',
        'temporaryConceptWorkers',
        'temporaryConceptWorkers.comments',
        'temporaryConceptWorkers.comments.owner',
        'temporaryConceptWorkers.comments.owner.person',
        'shiftConceptPlannings.user',
        'shiftConceptPlannings.user.person',
        'shiftConceptPlannings.user.employmentType',
        'shiftConceptPlannings.comments',
        'shiftConceptPlannings.comments.owner',
        'shiftConceptPlannings.comments.owner.person',
        'shiftConceptPlannings.comments.owner.roles',
        'department',
        'department.group',
    ];

    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concepts/${id}`);

    url.searchParams.append('include', includes.join(','));

    const response = await axios.get<ShiftConceptResource>(String(url));

    return transformToShiftConcept(response.data);
};

export const editShiftConceptApiCall = async (body: EditShiftConceptRequest, shiftConceptId: string): Promise<void> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concepts/${shiftConceptId}`);

    const includes = [
        'department',
    ];

    url.searchParams.append('include', includes.join(','));

    await axios.patch<ShiftConceptResource>(String(url), { data: body });
};

export const postShiftConceptApiCall = async (body: AddShiftConceptRequest): Promise<ShiftConceptViewModelOld> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concepts`);

    const includes = [
        'department',
        'department.group',
    ];

    url.searchParams.append('include', includes.join(','));

    const response = await axios.post<ShiftConceptResource>(String(url), { data: body });

    return transformToShiftConcept(response.data);
};

export const deleteShiftConceptApiCall = async (id: string): Promise<void> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concepts/${id}`);

    await axios.delete(String(url));
};

export const bulkCopyShiftConceptsApiCall = async (ids: string[], withUsers: boolean, dateOffset: number): Promise<boolean> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concepts/copy`);

    await axios.post<ShiftConceptCopyResultResource>(String(url), {}, {
        params: {
            shiftConceptUuids: ids,
            withUsers: withUsers ? 1 : 0,
            dateOffset,
        },
    });

    return true;
};

export const bulkDeleteShiftConceptsApiCall = async (ids: string[]): Promise<boolean> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concepts/bulk-delete`);

    await axios.delete(String(url), {
        params: {
            shiftConceptUuids: ids,
        },
    });

    return true;
};

export const publishBaseScheduleApiCall = async (
    startDate: Date,
    endDate: Date,
    baseScheduleId: string,
): Promise<PublishBaseScheduleResultViewModel> => {
    const start = convertDateToApiParamDate(startDate);
    const end = convertDateToApiParamDate(endDate);
    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedules/${baseScheduleId}/${start}/${end}`);
    const response = await axios.put<PublishBaseScheduleResultResource>(String(url));

    return transformToPublishBaseScheduleResult(response.data);
};
