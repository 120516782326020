import Balloon from '../../images/balloon.svg';
import Bread from '../../images/bread.svg';
import HappySmiley from '../../images/happy.svg';
import Hot from '../../images/hot_meal.svg';
import NeutralSmiley from '../../images/neutral.svg';
import None from '../../images/no_meal.svg';
import Remark from '../../images/remark.svg';
import SadSmiley from '../../images/sad.svg';
import Tie from '../../images/tie.svg';

const SATISFIED = 'satisfied';
const UNSATISFIED = 'unsatisfied';
const NEUTRAL = 'neutral';
const GEWELDIG = 'geweldig';
const NONE = 'none';
const HOT = 'hot';
const BREAD = 'bread';

export function getIconByMeal(meal) {
    if (!meal) {
        return null;
    }

    switch (meal.toLowerCase()) {
        case NONE:
            return None;
        case HOT:
            return Hot;
        case BREAD:
            return Bread;
        default:
            return None;
    }
}

export function getIconBySatisfaction(satisfaction) {
    if (!satisfaction) {
        return null;
    }

    switch (satisfaction.toLowerCase()) {
        case SATISFIED:
        case GEWELDIG:
            return HappySmiley;
        case UNSATISFIED:
            return SadSmiley;
        case NEUTRAL:
            return NeutralSmiley;
        default:
            return null;
    }
}

export function getIconById(id) {
    switch (id) {
        case 'remark':
            return Remark;
        case 'balloon':
            return Balloon;
        case 'tie':
            return Tie;
        default:
            return null;
    }
}
