import React, { FC, ReactNode } from 'react';

import Tooltip from 'react-tooltip-lite';
import { Button } from 'reactstrap';

import './ButtonWithTooltip.scss';

interface Props {
    onClick?: () => void;
    tooltip: boolean;
    tooltipText?: string;
    disabled?: boolean;
    className?: string;
    color?: string;
    children?: ReactNode;
    tooltipClassName?: string;
}

const ButtonWithTooltip: FC<Props> = ({
    onClick,
    tooltip,
    tooltipText,
    disabled = false,
    className = '',
    color = 'orange',
    tooltipClassName = '',
    children,
}) => {
    const onButtonClick = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };

    const renderButton = () => (
        <Button
            className={`
                    button-with-tooltip
                    ${disabled ? 'button-with-tooltip--disabled' : ''}
                    ${className}`}
            disabled={disabled}
            type="submit"
            color={color}
            onClick={onButtonClick}
        >
            {children}
        </Button>
    );

    return tooltip ? (
        // TODO: Fix children typing
        // @ts-ignore
        <Tooltip
            className={`button-with-tooltip-container ${tooltipClassName}`}
            content={tooltipText}
            arrowSize={6}
            padding={7}
        >
            {renderButton()}
        </Tooltip>
    ) : (
        renderButton()
    );
};

export default ButtonWithTooltip;
