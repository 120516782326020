import { USERS_SAGA_GET_EMPLOYEES } from '../actionTypes';

const getEmployees = (
    start?: string,
    department?: string,
    end?: string,
    includeDepartments?: boolean,
) => ({
    type: USERS_SAGA_GET_EMPLOYEES,
    start,
    department,
    end,
    includeDepartments,
});

export {
    getEmployees,
};
