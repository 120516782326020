import { Setting } from '../../../@paco/entities/Setting/Setting';
import { getSetting } from '../../../@paco/entities/Setting/SettingHelpers';
import { getShiftPlanningsWithinRange } from '../../../entities/ShiftPlanning/ShiftPlanningsService';
import { addHours } from '../../../helpers/date';

export const doesUserHaveShiftPlanningsInLast11h = async (
    settings: Setting[],
    userId: string,
    date: Date,
): Promise<boolean> => {
    const timeBetweenShiftsSetting = getSetting('ENABLE_CAO_MESSAGE_TIME_BETWEEN_SHIFTS', settings);

    if (!timeBetweenShiftsSetting || !timeBetweenShiftsSetting.isEnabled) {
        return false;
    }

    const shiftPlannings = await getShiftPlanningsWithinRange({
        filter: {
            userIds: [userId],
        },
        startDate: addHours(date, -11),
        endDate: date,
        planned: true,
    });

    return !!shiftPlannings.length;
};
