import { FC, ReactElement } from 'react';

import { Button } from '../../../../../components';
import { SubmitButton } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';

import './ActionButtons.scss';

interface ActionButtonsProps {
    isLoading?: boolean;
    showResendRegistrationButton?: boolean;
    submitButtonIsDisabled?: boolean;
    onCancelButtonClick: () => void;
    onResendRegistrationButtonClick: () => void;
}

const ActionButtons: FC<ActionButtonsProps> = ({
    isLoading,
    showResendRegistrationButton,
    submitButtonIsDisabled,
    onCancelButtonClick,
    onResendRegistrationButtonClick,
}): ReactElement => (
    <div className="action-buttons">
        <Button
            text={trans('compositions.modalContent.cancel')}
            onClick={onCancelButtonClick}
            className="action-buttons__cancel"
        />
        <SubmitButton
            isLoading={isLoading}
            disabled={submitButtonIsDisabled}
            type="submit"
            text={trans('compositions.modalContent.save')}
            className="action-buttons__confirm"
        />
        {showResendRegistrationButton && (
            <SubmitButton
                isLoading={isLoading}
                type="button"
                text={trans('containers.forms.editUserForm.resendRegistrationMail')}
                className="action-buttons__confirm"
                onClick={onResendRegistrationButtonClick}
            />
        )}
    </div>
);

export default ActionButtons;
