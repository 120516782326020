import { FC } from 'react';

import classNames from 'classnames';

import { NavRouteWithCounter, SubNavRouteWithCounter } from '../../Navigation';
import { MobileToggleButton } from '../index';
import NavigationLink from '../NavigationLink/NavigationLink';

import './MobileNavSection.scss';

interface MobileNavSectionProps {
    isActive: boolean;
    route: NavRouteWithCounter;
    subRoutes: SubNavRouteWithCounter[];
    onLinkClick: () => void;
    onToggle: (route: NavRouteWithCounter) => void;
    className?: string;
    navLinkClassName?: string;
    activeNavLinkClassName?: string;
}

const MobileNavSection: FC<MobileNavSectionProps> = ({
    isActive,
    route,
    subRoutes,
    onLinkClick,
    onToggle,
    className = '',
    navLinkClassName = '',
    activeNavLinkClassName = '',
}) => {
    const mobileRouteSectionClassName = classNames('mobile-nav-section', {
        'mobile-nav-section--is-active': isActive,
    }, className);

    const handleToggleButtonClick = (): void => {
        onToggle(route);
    };

    return (
        <div className={mobileRouteSectionClassName}>
            {subRoutes.length > 1 ? (
                <>
                    <MobileToggleButton
                        isActive={isActive}
                        counter={route.counter}
                        text={route.label}
                        onClick={handleToggleButtonClick}
                    />
                    <div className="mobile-nav-section__sub-routes">
                        {subRoutes.map(childRoute => (
                            <NavigationLink
                                counter={childRoute.counter}
                                key={childRoute.key}
                                to={`${route.path}/${childRoute.path}`}
                                label={childRoute.label}
                                onClick={onLinkClick}
                                className={`mobile-nav-section__nav-link ${navLinkClassName}`}
                                activeClassName={activeNavLinkClassName}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <NavigationLink
                    counter={subRoutes[0].counter}
                    key={subRoutes[0].key}
                    to={`${route.path}/${subRoutes[0].path}`}
                    label={route.label}
                    onClick={onLinkClick}
                    className="mobile-nav-section__stand-alone-nav-link"
                    labelClassName="mobile-nav-section__stand-alone-nav-link-label"
                    activeClassName={activeNavLinkClassName}
                />
            )}
        </div>
    );
};

export default MobileNavSection;
