import { FC, ReactElement } from 'react';

import Page from '../../components/Page/Page';
import { ConnectedShiftDetail } from '../../connectors';

const ShiftDetailPage: FC = (): ReactElement => (
    <Page>
        <ConnectedShiftDetail />
    </Page>
);

export default ShiftDetailPage;
