import { LeaveType, PzLeaveType } from '../../types/leaveType';
import { LeaveOfAbsence } from '../LeaveOfAbsence/LeaveOfAbsence';
import { PayrollPeriod } from '../PayrollPeriod/PayrollPeriod';
import { LeaveOfAbsenceHour, LeaveOfAbsenceHourResource, LoketLeaveOfAbsenceHour } from './LeaveOfAbsenceHour';

export const transformToLeaveOfAbsenceHour = (resource: LeaveOfAbsenceHourResource, payrollPeriod: PayrollPeriod): LeaveOfAbsenceHour => ({
    id: resource.id,
    hours: resource.attributes.hours,
    weekNumber: resource.attributes.weekNumber,
    leaveType: resource.attributes.leaveType as LeaveType,
    payrollPeriod,
});

export const transformToLoketLeaveOfAbsenceHour = (
    resource: LeaveOfAbsenceHourResource,
    leaveOfAbsence: LeaveOfAbsence,
): LoketLeaveOfAbsenceHour => ({
    id: resource.id,
    hours: resource.attributes.hours,
    weekNumber: resource.attributes.weekNumber,
    leaveOfAbsence,
    leaveType: resource.attributes.leaveType as LeaveType | PzLeaveType,
});
