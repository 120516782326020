import { authorizedFetch } from '../../helpers/authorizedFetch';
import { formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { DateFormat } from '../../types/dateFormatTypes';
import { DistrictDivisionResource } from '../DistrictDivision/DistrictDivision';
import { DistrictDivisionDistrictTemplateResource } from '../DistrictDivisionDistrictTemplate/DistrictDivisionDistrictTemplate';
import { DistrictDivisionGroupTemplateResource } from '../DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import { transformToDistrictDivisionGroupTemplate } from '../DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplateTransformers';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import {
    AddDistrictDivisionTemplateFormData,
    DistrictDivisionTemplate,
    DistrictDivisionTemplateResource,
    EditDistrictDivisionTemplateFormData,
} from './DistrictDivisionTemplate';
import {
    transformAddDistrictDivisionTemplateFormDataToAddApiParams,
    transformEditDistrictDivisionTemplateFormDataToEditApiParams,
    transformToDistrictDivisionTemplate,
} from './DistrictDivisionTemplateTransformers';

export const postDistrictDivisionTemplateApiCall = async (addDistrictDivisionTemplateFormData: AddDistrictDivisionTemplateFormData): Promise<FetchResult<DistrictDivisionTemplate, string>> => {
    const apiData = transformAddDistrictDivisionTemplateFormDataToAddApiParams(addDistrictDivisionTemplateFormData);

    try {
        const url = generateApiUrl({ endpoint: '/district-division-templates' });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DistrictDivisionTemplateResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const districtDivisionDistrictTemplate = transformToDistrictDivisionTemplate(doc.data, []);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: districtDivisionDistrictTemplate,
        };
    } catch (error) {
        console.error('[postDistrictDivisionTemplateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getDistrictDivisionTemplatesApiCall = async (): Promise<FetchCollectionResult<DistrictDivisionTemplate[], string>> => {
    try {
        const includes = [
            'districtDivisionGroupTemplates',
            'districtDivisionGroupTemplates.districtDivisionDistrictTemplates',
        ];

        const url = generateApiUrl({
            endpoint: '/district-division-templates',
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, { method: 'GET' });

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<DistrictDivisionTemplateResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const districtDivisionTemplates = doc.data.map(districtDivisionTemplateResource => {
            const districtDivisionGroupTemplatesResource = getMultipleIncluded(doc, districtDivisionTemplateResource, 'districtDivisionGroupTemplates') as DistrictDivisionGroupTemplateResource[];

            const districtDivisionDistrictGroupTemplates = districtDivisionGroupTemplatesResource.map(districtDivisionGroupTemplateResource => {
                const districtDivisionDistrictTemplatesResource = getMultipleIncluded(
                    doc,
                    districtDivisionGroupTemplateResource,
                    'districtDivisionDistrictTemplates',
                ) as DistrictDivisionDistrictTemplateResource[];

                return transformToDistrictDivisionGroupTemplate(districtDivisionGroupTemplateResource, districtDivisionDistrictTemplatesResource);
            });

            return transformToDistrictDivisionTemplate(districtDivisionTemplateResource, districtDivisionDistrictGroupTemplates);
        });

        return {
            amountOfPages: 1,
            status: 200,
            type: FetchResultType.Success,
            data: districtDivisionTemplates,
        };
    } catch (error) {
        console.error('[getDistrictDivisionTemplatesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getDistrictDivisionTemplateApiCall = async (districtDivisionTemplateId: string): Promise<FetchResult<DistrictDivisionTemplate, string>> => {
    try {
        const includes = [
            'districtDivisionGroupTemplates',
            'districtDivisionGroupTemplates.districtDivisionDistrictTemplates',
        ];

        const url = generateApiUrl({
            endpoint: `/district-division-templates/${districtDivisionTemplateId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, { method: 'GET' });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DistrictDivisionTemplateResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const districtDivisionGroupTemplatesResource = getMultipleIncluded(doc, doc.data, 'districtDivisionGroupTemplates') as DistrictDivisionGroupTemplateResource[];

        const districtDivisionDistrictGroupTemplates = districtDivisionGroupTemplatesResource
            .map(districtDivisionGroupTemplateResource => {
                const districtDivisionDistrictTemplatesResource = getMultipleIncluded(
                    doc,
                    districtDivisionGroupTemplateResource,
                    'districtDivisionDistrictTemplates',
                ) as DistrictDivisionDistrictTemplateResource[];

                return transformToDistrictDivisionGroupTemplate(districtDivisionGroupTemplateResource, districtDivisionDistrictTemplatesResource);
            })
            .sort((a, b) => a.order - b.order);

        const districtDivisionDistrictTemplate = transformToDistrictDivisionTemplate(doc.data, districtDivisionDistrictGroupTemplates);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: districtDivisionDistrictTemplate,
        };
    } catch (error) {
        console.error('[getDistrictDivisionTemplateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchDistrictDivisionTemplateApiCall = async (editDistrictDivisionTemplateFormData: EditDistrictDivisionTemplateFormData): Promise<FetchResult<DistrictDivisionTemplate, string>> => {
    const apiData = transformEditDistrictDivisionTemplateFormDataToEditApiParams(editDistrictDivisionTemplateFormData);

    try {
        const includes = [
            'districtDivisionGroupTemplates',
            'districtDivisionGroupTemplates.districtDivisionDistrictTemplates',
        ];

        const url = generateApiUrl({
            endpoint: `/district-division-templates/${apiData.id}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DistrictDivisionTemplateResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const districtDivisionGroupTemplatesResource = getMultipleIncluded(doc, doc.data, 'districtDivisionGroupTemplates') as DistrictDivisionGroupTemplateResource[];
        const districtDivisionDistrictGroupTemplates = districtDivisionGroupTemplatesResource.map(districtDivisionGroupTemplateResource => {
            const districtDivisionDistrictTemplatesResource = getMultipleIncluded(
                doc,
                districtDivisionGroupTemplateResource,
                'districtDivisionDistrictTemplates',
            ) as DistrictDivisionDistrictTemplateResource[];

            return transformToDistrictDivisionGroupTemplate(districtDivisionGroupTemplateResource, districtDivisionDistrictTemplatesResource);
        });

        const districtDivisionDistrictTemplate = transformToDistrictDivisionTemplate(doc.data, districtDivisionDistrictGroupTemplates);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: districtDivisionDistrictTemplate,
        };
    } catch (error) {
        console.error('[patchDistrictDivisionTemplateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteDistrictDivisionTemplateApiCall = async (districtDivisionTemplateId: string): Promise<FetchResult<undefined, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/district-division-templates/${districtDivisionTemplateId}` });

        const response = await authorizedFetch(url, { method: 'DELETE' });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteDistrictDivisionTemplateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const publishDistrictDivisionTemplateApiCall = async (districtDivisionTemplateId: string, date: Date): Promise<FetchResult<undefined, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: `/district-division-templates/publish/${districtDivisionTemplateId}?date=${formatDate(date, DateFormat.apiDate)}`,
            queryParams: {
                include:
                    [
                        'districtDivisionGroups',
                        'districtDivisionGroups.districtDivisionDistricts',
                    ].join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'POST',
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DistrictDivisionResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[publishDistrictDivisionTemplateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
