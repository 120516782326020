import React, { FC } from 'react';

import { translate } from '../../../../helpers/translations/translator';
import Icon from '../../../style/Icon/Icon';

import './CollapseButton.scss';

interface Props {
    collapsed: boolean;
    className?: string;
    onClick: () => void;
}

const CollapseButton: FC<Props> = ({
    collapsed,
    className = '',
    onClick,
}) => (
    <button
        type="button"
        onClick={onClick}
        className={`button-collapse ${collapsed ? 'button-collapse--collapsed' : ''} ${className}`}
    >
        {translate(!collapsed ? 'common.showMore' : 'common.showLess')}
        <Icon color="black" kind="keyboard_arrow_down" />
    </button>
);

export default CollapseButton;
