import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';

import { getLoketLogs } from '../../helpers/api-ts/logs';
import {
    LOGS_GET_LOKET_LOGS_FAILURE,
    LOGS_GET_LOKET_LOGS_REQUEST,
    LOGS_GET_LOKET_LOGS_SUCCESS,
    LOGS_SAGA_GET_LOKET_LOGS,
} from '../actionTypes';


export function* fetchLoketLogs() {
    yield put({ type: LOGS_GET_LOKET_LOGS_REQUEST });

    try {
        // @ts-ignore
        const response = yield call(() => getLoketLogs());
        yield put({ type: LOGS_GET_LOKET_LOGS_SUCCESS, loketLogs: response.data });
    } catch (error) {
        yield put({ type: LOGS_GET_LOKET_LOGS_FAILURE });
    }
}

export default function* logsWatcher() {
    yield takeLatest(LOGS_SAGA_GET_LOKET_LOGS, fetchLoketLogs);
}
