import { getAbsencesApiCall } from '../../entities/Absence/AbsenceService';
import { getChangeLogApiCall } from '../../entities/ChangeLog/ChangeLogService';
import { getDashboardSettingsApiCall } from '../../entities/DashboardSetting/DashboardSettingService';
import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { Period } from '../../entities/Period/Period';
import { getShiftsIndexApiCall } from '../../entities/Shift/ShiftService';
import { getTracksApiCall } from '../../entities/Track/TrackService';
import { TypedDispatch } from '../store';
import {
    setAbsences,
    setChangeLog,
    setDashboardSettings,
    setError,
    setIsAbsencesLoading,
    setIsChangeLogLoading,
    setIsLoading,
    setIsShiftsLoading,
    setIsTracksLoading,
    setShifts,
    setTracks,
} from './dashboardReducer';

export const fetchDashboardSettings = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getDashboardSettingsApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDashboardSettings(response.data));
    } catch (error) {
        console.error('[fetchDashboardSettings]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchDashboardTracks = (
    startDate: Date,
    endDate: Date,
    departments: string[] = [],
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsTracksLoading(true));
    dispatch(setError(''));

    try {
        const response = await getTracksApiCall(
            { start: startDate, end: endDate },
            { departments },
        );
        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setTracks(response.data));
    } catch (error) {
        console.error('[fetchDashboardTracks]', error);
    } finally {
        dispatch(setIsTracksLoading(false));
    }
};

export const fetchDashboardShifts = (
    startDate: Date,
    endDate: Date,
    departments: string[] = [],
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsShiftsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getShiftsIndexApiCall(
            startDate,
            endDate,
            departments,
            '',
        );

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShifts(response.data));
    } catch (error) {
        console.error('[fetchDashboardShifts]', error);
    }

    dispatch(setIsShiftsLoading(false));
};

export const fetchDashboardAbsences = (
    period: Period,
    departments: string[] = [],
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsAbsencesLoading(true));
    dispatch(setError(''));

    try {
        const response = await getAbsencesApiCall({ departments, period });

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setAbsences(response.data));
    } catch (error) {
        console.error('[fetchDashboardAbsences]', error);
    } finally {
        dispatch(setIsAbsencesLoading(false));
    }
};

export const fetchChangeLog = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsChangeLogLoading(true));
    dispatch(setError(''));

    try {
        const response = await getChangeLogApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setChangeLog(response.data));
    } catch (error) {
        console.error('[fetchChangeLog]', error);
    } finally {
        dispatch(setIsChangeLogLoading(false));
    }
};
