import React, { FC, ReactElement } from 'react';

import ElementWithTooltip from '../../../../../../@paco/compositions/ElementWithTooltip/ElementWithTooltip';
import Icon from '../../../../../../components/style/Icon/Icon';
import { SpecialDayViewModel } from '../../../../../../models';

import './ListViewTableHeadCell.scss';

interface ListViewTableHeadCellProps {
    name: string;
    specialDays?: SpecialDayViewModel[];
    className?: string;
}

const ListViewTableHeadCell: FC<ListViewTableHeadCellProps> = ({
    name,
    specialDays = [],
    className = '',
}): ReactElement => (
    <th className={`list-view-table-head-cell ${className}`}>
        <div className="list-view-table-head-cell__wrapper">
            <span className="list-view-table-head-cell__name">{name}</span>
            {specialDays.map((specialDay) => (
                <ElementWithTooltip
                    key={specialDay.id}
                    tooltipIsActive
                    tooltipText={specialDay.description}
                    className="list-view-table-head-cell__tooltip"
                >
                    <Icon
                        kind={specialDay.type}
                        fontSize={16}
                        className="list-view-table-head-cell__special-day-icon"
                    />
                </ElementWithTooltip>
            ))}
        </div>
    </th>
);

export default ListViewTableHeadCell;
