import React, { FC } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';


interface FilterProps {
    filter: AppFilter;
    onChange: (filter: AppFilter) => void;
}

const EmployeeStatusFilter: FC<FilterProps> = ({ filter, onChange }) => {
    const onCheckbox1Change = (onlyShowShiftsWithOpenLeaveOfAbsence: boolean) => {
        const shifts = {
            ...filter.shifts,
            onlyShowShiftsWithOpenLeaveOfAbsence,
        };

        onChange({
            ...filter,
            shifts,
        });
    };

    const onCheckbox2Change = (onlyShowShiftsWithAbsence: boolean) => {
        const shifts = {
            ...filter.shifts,
            onlyShowShiftsWithAbsence,
        };

        onChange({
            ...filter,
            shifts,
        });
    };

    return (
        <FilterBlock title={translate('common.employees')}>
            <FormGroup check className="p-0">
                <Label check>
                    <CustomInput
                        id="leave-of-absence-checkbox"
                        type="checkbox"
                        label={translate('pages.calendar.onlyShowShiftsWithOpenLeaveOfAbsence')}
                        checked={filter.shifts.onlyShowShiftsWithOpenLeaveOfAbsence}
                        onChange={() => onCheckbox1Change(!filter.shifts.onlyShowShiftsWithOpenLeaveOfAbsence)}
                    />
                </Label>
            </FormGroup>
            <FormGroup check className="p-0">
                <Label check>
                    <CustomInput
                        id="absence-checkbox"
                        type="checkbox"
                        label={translate('pages.calendar.onlyShowShiftsWithAbsence')}
                        checked={filter.shifts.onlyShowShiftsWithAbsence}
                        onChange={() => onCheckbox2Change(!filter.shifts.onlyShowShiftsWithAbsence)}
                    />
                </Label>
            </FormGroup>
        </FilterBlock>
    );
};

export default EmployeeStatusFilter;
