import { getLoketApiUrl } from '../../../helpers';
import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { LoketLeaveSyncError, LoketLeaveSyncErrorResource, LoketLeaveSyncFormData } from './LoketLeaveSync';
import { isLoketLeaveSyncErrorCollection } from './LoketLeaveSyncHelpers';
import {
    transformExportLeaveOfAbsencesFormDataToApiParams,
    transformToLoketLeaveSyncError,
} from './LoketLeaveSyncTransformers';

export const putLoketLeaveSyncApiCall = async (
    exportLeaveOfAbsencesFormData: LoketLeaveSyncFormData,
): Promise<FetchCollectionResult<LoketLeaveSyncError[], string>> => {
    const apiData = transformExportLeaveOfAbsencesFormDataToApiParams(exportLeaveOfAbsencesFormData);

    try {
        const lastParam = apiData.endDate ? `&to=${apiData.endDate}` : '';
        const url = `${getLoketApiUrl()}/leave/sync?from=${apiData.startDate}${lastParam}`;

        const response = await authorizedFetch(url, { method: 'PUT' });
        const doc: LoketLeaveSyncErrorResource[] = await response.json();

        if (!isLoketLeaveSyncErrorCollection(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = doc
            .filter(error => error.status !== 200)
            .map(transformToLoketLeaveSyncError);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[putLoketLeaveSyncApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
