import { addDays, max } from '../../helpers/date';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { User } from '../User/User';
import { Absence, AbsenceResource } from './Absence';

export const transformToAbsence = (
    resource: AbsenceResource,
    user: User,
): Absence => {
    const { start, end } = resource.attributes;
    const startDate = new Date(start);
    const justifiedEndDate = !end ? addDays(max([startDate, new Date()]), 3) : new Date(end);

    return {
        id: resource.id,
        user,
        period: transformToPeriod(
            startDate,
            justifiedEndDate,
            !!end,
        ),
    };
};
