import { WeekWithHours } from '../../../../../models';

export const isAllWeeksLocked = (weeksWithHours: WeekWithHours[], startDate: string | null) => {
    if (!startDate || !weeksWithHours?.length) return false;

    const { payrollPeriod } = weeksWithHours[0];
    const startDateObj = new Date(startDate);

    return !!(
        payrollPeriod?.locked
        && startDateObj >= payrollPeriod.start
        && startDateObj <= payrollPeriod.end.date
    );
};
