import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Absence } from '../../../models';
import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';

export type AbsencesState = AsyncReduxState<{
    absences: Absence[];
}>;

const initialState: AbsencesState = {
    ...initialAsyncReduxState,
    absences: [],
};

export const AbsencesSlice = createSlice({
    name: 'AbsencesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AbsencesState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): AbsencesState {
            return ({
                ...state,
                isSuccessful: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): AbsencesState {
            return ({
                ...state,
                error: action.payload,
            });
        },
        setAbsences(state, action: PayloadAction<Absence[]>): AbsencesState {
            return ({
                ...state,
                absences: action.payload,
            });
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setAbsences,
    setError,
} = AbsencesSlice.actions;

export default AbsencesSlice.reducer;
