import {
    all,
    call, put, select,
} from 'redux-saga/effects';

import { TOAST_TYPE_FAIL, TOAST_TYPE_PASS } from '../../constants';
import { getObjProperty } from '../../helpers';
import { getLoketLeaveBalance } from '../../helpers/api-ts/leaveBalance';
import { patchApproveShiftExchange, patchDeclineShiftExchange } from '../../helpers/api-ts/shiftExchange';
import { translate } from '../../helpers/translations/translator';
import getLoketEmployeeIdsFromAbsences from '../../services/AbsenceLegacyService/getLoketEmployeeIdsFromAbsences';
import mapLoketLeaveBalancesToLeaveOfAbsences
    from '../../services/LeaveOfAbsenceLegacyService/mapLoketLeaveBalancesToLeaveOfAbsences';
import {
    ABSENCES_APPROVE_SHIFT_EXCHANGE_FAILURE,
    ABSENCES_APPROVE_SHIFT_EXCHANGE_REQUEST,
    ABSENCES_APPROVE_SHIFT_EXCHANGE_SUCCESS,
    ABSENCES_DECLINE_SHIFT_EXCHANGE_FAILURE,
    ABSENCES_DECLINE_SHIFT_EXCHANGE_REQUEST,
    ABSENCES_DECLINE_SHIFT_EXCHANGE_SUCCESS,
    ABSENCES_GET_LOA_LEAVE_BALANCES_FAILURE,
    ABSENCES_GET_LOA_LEAVE_BALANCES_REQUEST,
    ABSENCES_GET_LOA_LEAVE_BALANCES_SUCCESS,
    ABSENCES_SAGA_GET_EXCHANGE_REQUESTS,
    APP_SAGA_ADD_TOAST,
} from '../actionTypes';
import { ApproveShiftExchangeAction } from './absencesModels';

export function* getAndApplyLeaveOfAbsenceLoketLeaveBalances() {
    yield put({ type: ABSENCES_GET_LOA_LEAVE_BALANCES_REQUEST });

    try {
        // @ts-ignore
        const state = yield select();
        const { openLeaveOfAbsencesRequests } = state.absencesReducer;
        const loketEmployeeIds = getLoketEmployeeIdsFromAbsences(openLeaveOfAbsencesRequests);
        // @ts-ignore
        const employeeLeaveBalances = yield all(loketEmployeeIds
            .map(loketEmployeeId => getLoketLeaveBalance(loketEmployeeId)));
        const leaveOfAbsences = mapLoketLeaveBalancesToLeaveOfAbsences(openLeaveOfAbsencesRequests, employeeLeaveBalances);

        yield put({
            type: ABSENCES_GET_LOA_LEAVE_BALANCES_SUCCESS,
            leaveOfAbsencesRequests: leaveOfAbsences,
        });
    } catch (error) {
        yield put({ type: ABSENCES_GET_LOA_LEAVE_BALANCES_FAILURE });
    }
}

export function* approveShiftExchange(action: ApproveShiftExchangeAction) {
    yield put({ type: ABSENCES_APPROVE_SHIFT_EXCHANGE_REQUEST });

    try {
        yield call(() => patchApproveShiftExchange(action.shiftExchangeId));
        yield put({ type: ABSENCES_APPROVE_SHIFT_EXCHANGE_SUCCESS });
        yield put({ type: ABSENCES_SAGA_GET_EXCHANGE_REQUESTS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.absences.approveExchangeSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: APP_SAGA_ADD_TOAST, toast: getObjProperty(error, 'meta.message'), toastType: TOAST_TYPE_FAIL });
        yield put({ type: ABSENCES_APPROVE_SHIFT_EXCHANGE_FAILURE });
    }
}

export function* declineShiftExchange(action: ApproveShiftExchangeAction) {
    yield put({ type: ABSENCES_DECLINE_SHIFT_EXCHANGE_REQUEST });

    try {
        yield call(() => patchDeclineShiftExchange(action.shiftExchangeId));
        yield put({ type: ABSENCES_DECLINE_SHIFT_EXCHANGE_SUCCESS });
        yield put({ type: ABSENCES_SAGA_GET_EXCHANGE_REQUESTS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.absences.declineExchangeSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: APP_SAGA_ADD_TOAST, toast: getObjProperty(error, 'meta.message'), toastType: TOAST_TYPE_FAIL });
        yield put({ type: ABSENCES_DECLINE_SHIFT_EXCHANGE_FAILURE });
    }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export default function* absencesWatcher() {}
