import React from 'react';

import PropTypes from 'prop-types';

import { getYear } from '../../helpers/date';
import Controls from './Controls/Controls';

function onIncrement(increment, year, onYearChange) {
    const newYear = increment ? year + 1 : year - 1;
    onYearChange(newYear);
}

const YearNavigator = props => {
    const { year, onYearChange } = props;

    return (
        <Controls
            year={year || getYear(new Date())}
            onIncrement={(increment) => onIncrement(increment, year, onYearChange)}
        />
    );
};

YearNavigator.propTypes = {
    year: PropTypes.number.isRequired,
    onYearChange: PropTypes.func.isRequired,
};

export default YearNavigator;
