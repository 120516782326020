import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';

import { Icon, PacoButtonLoadingSpinner } from '../../../../components';
import trans from '../../../../helpers/trans';
import { Textarea } from '../../../index';

import './DistrictDivisionComment.scss';

interface DistrictDivisionCommentProps {
    isLoading: boolean;
    isSuccessful: boolean;
    comment?: string;
    onBlur: () => void;
    onChange: (comment: string) => void;
    className?: string;
}

const DistrictDivisionComment: FC<DistrictDivisionCommentProps> = ({
    isLoading,
    isSuccessful,
    comment,
    onBlur,
    onChange,
    className = '',
}) => {
    const [inputHasChanged, setInputHasChanged] = useState(false);

    const wrapperClassName = classNames('district-division-comment', {
        'district-division-comment--is-loading': isLoading,
        'district-division-comment--is-successful': !inputHasChanged && isSuccessful,
    }, className);

    const handleChange = (value: string): void => {
        setInputHasChanged(true);
        onChange(value);
    };

    useEffect((): void => {
        setInputHasChanged(false);
    }, [isSuccessful]);

    return (
        <div className={wrapperClassName}>
            <div className="district-division-comment__title">
                {trans('common.generalComments')}
            </div>
            <div className="district-division-comment__textarea-wrapper">
                <Textarea
                    hideLabel
                    label={trans('common.comment')}
                    maxLength={254}
                    rows={4}
                    value={comment}
                    onChange={handleChange}
                    onBlur={onBlur}
                    className="district-division-comment__textarea"
                />
                <PacoButtonLoadingSpinner className="district-division-comment__loading-spinner" />
                <Icon name="check" className="district-division-comment__check-icon" />
            </div>
        </div>
    );
};

export default DistrictDivisionComment;
