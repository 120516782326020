import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import { UserResource } from '../User/User';
import { AddCommentFormData, Comment, CommentResource } from './Comment';
import { transformCommentFormDataToRequest, transformToComment } from './CommentTransformers';

let postCommentApiCallAbortController = new AbortController();

export const postCommentApiCall = async (
    addCommentFormData: AddCommentFormData,
    useAbortController = false,
): Promise<FetchResult<Comment, string>> => {
    if (useAbortController) {
        postCommentApiCallAbortController.abort();
        postCommentApiCallAbortController = new AbortController();
    }

    const commentRequest = transformCommentFormDataToRequest(addCommentFormData);

    try {
        const url = generateApiUrl({
            endpoint: '/comments',
            queryParams: {
                include: 'owner',
            },
        });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: commentRequest }),
            ...(useAbortController && { signal: postCommentApiCallAbortController.signal }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<CommentResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const userResource = getIncluded(doc, doc.data, 'owner') as UserResource;
        const comment = transformToComment(doc.data, userResource);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: comment,
        };
    } catch (error) {
        console.error('[postCommentApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
