/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconFilter: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 14 14">
        <path d="M0,2.33v1.56h14V2.33H0z M2.33,7.78h9.33V6.22H2.33V7.78z M8.56,11.67H5.44v-1.56h3.11V11.67z" />
    </svg>
);

export default IconFilter;
/* eslint-enable max-len */
