import { Dispatch } from 'redux';

import { fetchDepartmentsApiCall } from '../../../entities/Departments/DepartmentsService';
import { setDepartments, setError, setIsLoading } from './departmentsReducer';

export const fetchDepartments = () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await fetchDepartmentsApiCall();

        dispatch(setDepartments(response));
    } catch (error) {
        console.error('[fetchDepartments]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
