import { FC, ReactElement } from 'react';

import { TableCell, TableRow } from '../../../../components';
import { EventLogDataItem } from '../../../../entities/EventLog/EventLog';
import trans from '../../../../helpers/trans';
import { ValkTable } from '../../../index';

import './PzLogDataTable.scss';

interface PzLogDataTableProps {
    dataItems: EventLogDataItem[];
    className?: string;
}

const PzLogDataTable: FC<PzLogDataTableProps> = ({ dataItems, className = '' }): ReactElement => (
    <ValkTable<EventLogDataItem>
        hasRowClick
        rows={dataItems}
        tableHeaders={[trans('compositions.pzLogDetail.label'), trans('compositions.pzLogDetail.value')]}
        tableClassName="pz-log-data-table__table"
        cellClassName="pz-log-data-table__table-cell"
        className={className}
    >
        {(pzLogs): JSX.Element[] => pzLogs.map(log => (
            <TableRow
                key={log.label}
                className={`pz-log-data-table__row ${className}`}
            >
                <TableCell>
                    {log.label}
                </TableCell>
                <TableCell className="pz-log-data-table__value-cell">
                    {log.value}
                </TableCell>
            </TableRow>
        ))}
    </ValkTable>
);

export default PzLogDataTable;
