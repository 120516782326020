import React, { FC, useState } from 'react';

import { Modal } from 'reactstrap';

import ButtonAuthenticate from '../../../../components/ButtonAuthenticate/ButtonAuthenticate';
import DeleteResourceForm from '../../../../components/forms/DeleteResourceForm/DeleteResourceForm';
import { translate } from '../../../../helpers/translations/translator';
import { CommentCategoryViewModel, CommentFormData, CommentViewModel } from '../../../../models';
import AddOrEditNote from '../../forms/AddOrEditNote/AddOrEditNote';
import NotesTable from './subcomponents/NotesTable/NotesTable';

import './Notes.scss';


export interface NotesProps {
    userId: string;
    commentCategories: CommentCategoryViewModel[];
    comments: CommentViewModel[];
    onAddNote: (data: CommentFormData) => void;
    onEditNote: (data: CommentFormData) => void;
    onDeleteNote: (id: string) => void;
}

const Notes: FC<NotesProps> = ({
    userId,
    comments,
    commentCategories,
    onAddNote,
    onEditNote,
    onDeleteNote,
}) => {
    const [addEditNoteForm, setAddEditNoteForm] = useState(false);
    const [editNoteData, setEditNoteData] = useState<CommentViewModel | null>(null);
    const [deleteNoteForm, setDeleteNoteForm] = useState(false);
    const [deleteNoteId, setDeleteNoteId] = useState<string | null>(null);

    const onAddNoticeClick = () => {
        setAddEditNoteForm(true);
    };

    const onEditNoteClick = (data: CommentViewModel) => {
        setEditNoteData(data);
        setAddEditNoteForm(true);
    };

    const onDeleteNoteClick = (id: string) => {
        setDeleteNoteForm(true);
        setDeleteNoteId(id);
    };

    const onCancelNotice = () => {
        setAddEditNoteForm(false);
        setDeleteNoteForm(false);
        setEditNoteData(null);
    };

    const onSubmitNotice = (formData: CommentFormData) => {
        if (formData.id) {
            onEditNote(formData);
        } else {
            onAddNote(formData);
        }
        setAddEditNoteForm(false);
    };

    const onSubmitDeleteNote = () => {
        setDeleteNoteForm(false);
        onDeleteNote(deleteNoteId || '');
    };

    return (
        <>
            <div className="notes">
                <div className="notes__top">
                    <ButtonAuthenticate
                        text={translate('common.addEntity', { entity: translate('common.notice') })}
                        onClick={onAddNoticeClick}
                        responsive={false}
                    />
                </div>
                <NotesTable
                    notes={comments}
                    onEditNoteClick={onEditNoteClick}
                    onDeleteNoteClick={onDeleteNoteClick}
                />
            </div>

            <Modal size="lg" isOpen={addEditNoteForm} className="notes__form-add-edit-note">
                <AddOrEditNote
                    data={editNoteData}
                    userId={userId}
                    commentCategories={commentCategories}
                    onCancel={onCancelNotice}
                    onSubmit={onSubmitNotice}
                />
            </Modal>

            <Modal size="md" isOpen={deleteNoteForm} className="notes__form-delete-note">
                <DeleteResourceForm
                    title={translate('common.deleteResource', { resource: translate('common.notice') })}
                    intro={translate('common.deleteResourceIntro', { resource: translate('common.notice').toLowerCase() })}
                    onSubmit={onSubmitDeleteNote}
                    onCancel={onCancelNotice}
                />
            </Modal>
        </>
    );
};

export default Notes;
