import { FC, ReactElement, useMemo } from 'react';

import PlannedUserCard from '../../../components/PlannedUserCard/PlannedUserCard';
import { ShiftConceptViewModelOld } from '../../../entities/ShiftConcept/ShiftConcept';
import { TemporaryWorkerViewModel, UserViewModel } from '../../../models';
import { transformToPeriod } from '../../entities/Period/PeriodTransformers';
import { ShiftIndex } from '../../entities/Shift/Shift';
import { TemporaryWorker } from '../../entities/TemporaryWorker/TemporaryWorker';
import { getTemporaryWorkerDeviatedTime } from './helpers/getTemporaryWorkerDeviatedTime';

interface TemporaryWorkerCardProps {
    temporaryWorker: TemporaryWorker;
    shift: ShiftIndex | ShiftConceptViewModelOld;
    onColorSelect?: (temporaryWorkerId: string, color?: string) => void;
    onEditClick?: (temporaryWorker: TemporaryWorker) => void;
    onDeleteClick?: (temporaryWorker: TemporaryWorker) => void;
}

const TemporaryWorkerCard: FC<TemporaryWorkerCardProps> = ({
    temporaryWorker,
    shift,
    onColorSelect,
    onEditClick,
    onDeleteClick,
}): ReactElement => {
    const start = 'start' in shift ? shift.start : shift.period.start;
    const end = 'end' in shift ? shift.end.date : shift.period.end;
    const time = useMemo(() => getTemporaryWorkerDeviatedTime(temporaryWorker, transformToPeriod(start, end)), [temporaryWorker, shift]);
    const comment = temporaryWorker.comments.length ? temporaryWorker.comments[temporaryWorker.comments.length - 1] : undefined;

    const handleDeleteClick = (): void => {
        if (onDeleteClick) {
            onDeleteClick(temporaryWorker);
        }
    };

    const handleEditClick = (): void => {
        if (onEditClick) {
            onEditClick(temporaryWorker);
        }
    };

    const handleColorSelect = (
        currentUserId: string,
        temporaryWorkerId: string,
        userType: UserViewModel['type'] | TemporaryWorkerViewModel['type'],
        newColor: string | null,
    ): void => {
        if (onColorSelect) {
            onColorSelect(temporaryWorkerId, newColor || undefined);
        }
    };

    return (
        <PlannedUserCard
            isTemporaryWorker
            id={temporaryWorker.id}
            color={temporaryWorker.color}
            time={time}
            userName={temporaryWorker.name}
            comment={comment}
            userId={temporaryWorker.id}
            onColorSelect={onColorSelect ? handleColorSelect : undefined}
            onEditButtonClick={onEditClick ? handleEditClick : undefined}
            onDeleteButtonClick={onDeleteClick ? handleDeleteClick : undefined}
        />
    );
};

export default TemporaryWorkerCard;
