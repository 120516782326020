import { FC, PropsWithChildren, ReactElement } from 'react';

import { Button } from '../../components';
import trans from '../../helpers/trans';
import { IconButton } from '../index';

import './ModalContent.scss';

interface ModalContentProps {
    showCloseButton?: boolean;
    showHistoryButton?: boolean;
    title: string;
    subText?: string;
    onCloseButtonClick?: () => void;
    onHistoryButtonClick?: () => void;
}

const ModalContent: FC<PropsWithChildren<ModalContentProps>> = ({
    showCloseButton = false,
    showHistoryButton = false,
    title,
    subText,
    onCloseButtonClick,
    onHistoryButtonClick,
    children,
}): ReactElement => (
    <div className="modal-content">
        <header className="modal-content__header">
            <h2 className="modal-content__title">{title}</h2>

            {subText && (
                <p className="modal-content__sub-text">{subText}</p>
            )}

            {showCloseButton && (
                <Button
                    text={trans('common.close')}
                    onClick={onCloseButtonClick}
                    className="modal-content__close-button"
                />
            )}

            {showHistoryButton && (
                <IconButton
                    hideLabel
                    icon="history"
                    text={trans('common.close')}
                    onClick={onHistoryButtonClick}
                    className="modal-content__history-button"
                    iconClassName="modal-content__history-button-icon"
                />
            )}
        </header>

        <div className="modal-content__content-wrapper">
            {children}
        </div>
    </div>
);

export default ModalContent;
