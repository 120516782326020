import { FC, ReactElement } from 'react';

import { GroupBase } from 'react-select';

import { DepartmentOption } from '../../../../../types/selectOptionTypes';
import { AddDepartmentGroupButton } from '../index';

import './GroupLabel.scss';

export interface GroupLabelData extends GroupBase<DepartmentOption> {
    value?: string;
}

interface GroupLabelProps {
    groupLabel: GroupLabelData;
    title: string;
    onGroupButtonClick: (groupId: string) => void;
}

const GroupLabel: FC<GroupLabelProps> = ({
    groupLabel,
    onGroupButtonClick,
    title,
}): ReactElement => {
    const handleGroupButtonClick = (): void => {
        if (!groupLabel.value) {
            return;
        }

        onGroupButtonClick(groupLabel.value);
    };

    return (
        <div className="group-label">
            <span className="group-label__label">{`${groupLabel.label}`}</span>
            {groupLabel.value && (
                <AddDepartmentGroupButton
                    title={title}
                    onClick={handleGroupButtonClick}
                />
            )}
        </div>
    );
};

export default GroupLabel;
