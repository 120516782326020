import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiV3Url } from '../../helpers/url';
import { JapiDocument } from '../../japi/types/Document';
import { EventLogEntityType } from '../../types/eventLogTypes';
import { Pagination, PaginationV3RequestApiParams } from '../../types/paginations';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { isPaginationV3Resource } from '../Pagination/PaginationHelpers';
import { transformToPagination } from '../Pagination/PaginationTransformers';
import { EventLog } from './EventLog';
import { transformToEventLog } from './EventLogTransformers';

interface GetEventLogsApiCallParams {
    pagination: PaginationV3RequestApiParams;
    categories: string[];
    search?: string;
}

let getEventLogsApiCallAbortController = new AbortController();

export const getEventLogsApiCall = async ({
    pagination,
    categories,
    search = '',
}: GetEventLogsApiCallParams): Promise<FetchCollectionResult<EventLog[], string>> => {
    getEventLogsApiCallAbortController.abort();
    getEventLogsApiCallAbortController = new AbortController();

    try {
        const url = generateApiV3Url({
            endpoint: '/event-logs',
            queryParams: {
                ...(pagination && { start: pagination.start }),
                ...(pagination && { items: pagination.items }),
                ...(!!categories.length && { category: JSON.stringify(categories) }),
                ...(search?.length && { search }),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'GET',
            signal: getEventLogsApiCallAbortController.signal,
        });

        const doc: JapiDocument = await response.json();

        if (!doc || !Array.isArray(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const eventLogs = doc.map(transformToEventLog);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: eventLogs,
        };
    } catch (error) {
        console.error('[getEventLogsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

let getEventLogsPaginationApiCallAbortController = new AbortController();

export const getEventLogsPaginationApiCall = async ({
    pagination,
    categories,
    search = '',
}: GetEventLogsApiCallParams): Promise<FetchResult<Pagination, string>> => {
    getEventLogsPaginationApiCallAbortController.abort();
    getEventLogsPaginationApiCallAbortController = new AbortController();

    try {
        const url = generateApiV3Url({
            endpoint: '/event-logs/meta',
            queryParams: {
                ...(pagination && { start: pagination.start }),
                ...(pagination && { items: pagination.items }),
                ...(!!categories.length && { category: JSON.stringify(categories) }),
                ...(search?.length && { search }),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'GET',
            signal: getEventLogsApiCallAbortController.signal,
        });

        const doc: JapiDocument = await response.json();

        if (!doc || !isPaginationV3Resource(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = transformToPagination(doc);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[getEventLogsPaginationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};


const getEventLogEntityEndPoint = (entityId: string, entityType?: EventLogEntityType): string => {
    const base = '/event-logs';

    switch (entityType) {
        case EventLogEntityType.absence:
            return `${base}/absence/${entityId}`;
        case EventLogEntityType.leaveOfAbsence:
            return `${base}/leave-of-absence/${entityId}`;
        case EventLogEntityType.user:
            return `${base}/user/${entityId}`;
        default:
            return `${base}/by-entity/${entityId}`;
    }
};

export const getEventLogsByEntityApiCall = async (entityId: string, entityType?: EventLogEntityType): Promise<FetchCollectionResult<EventLog[], string>> => {
    try {
        const url = generateApiV3Url({
            endpoint: getEventLogEntityEndPoint(entityId, entityType),
        });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!doc || !Array.isArray(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const eventLogs = doc.map(transformToEventLog);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: eventLogs,
        };
    } catch (error) {
        console.error('[getEventLogsByEntityApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
