import { FC, useMemo } from 'react';

import { Route, Routes } from 'react-router-dom';

import Toasts from '../../../components/Toasts/Toasts';
import ErrorPage from '../../../pages/ErrorPage';
import LogsConnector from '../../../pages/Logs/LogsConnector';
import LoketSSO from '../../../pages/Management/LoketSSO';
import LegacyTracksRouteGroup from '../../../pages/Tracks/LegacyTracksRouteGroup';
import { LoadingSpinner } from '../../components';
import { ConnectedToasts } from '../../connectors';
import { Layout } from '../../containers';
import { DashboardPage } from '../../pages';
import { NavRoute, PrivateRouteGroupRoutes } from '../../routes/routes';
import {
    AbsencesRouteGroup,
    PzRouteGroup,
    ScheduleRouteGroup,
    SettingsRouteGroup,
    UsersRouteGroup,
} from './subcomponents';

interface PrivateRouteGroupProps {
    permittedUserRoutes: NavRoute[];
}

const PrivateRouteGroup: FC<PrivateRouteGroupProps> = ({ permittedUserRoutes }) => {
    const permittedUserRouteKeys = useMemo(() => permittedUserRoutes.map(route => route.key), [permittedUserRoutes]);

    return (
        <>
            <Layout>
                <Routes>
                    {permittedUserRouteKeys.includes('shifts') && (
                        <Route
                            path={`${PrivateRouteGroupRoutes.shifts()}/*`}
                            element={<ScheduleRouteGroup permittedUserRoutes={permittedUserRoutes} />}
                        />
                    )}
                    {permittedUserRouteKeys.includes('tracks') && <Route path={`${PrivateRouteGroupRoutes.tracks()}/*`} element={<LegacyTracksRouteGroup />} />}
                    {permittedUserRouteKeys.includes('users') && <Route path={`${PrivateRouteGroupRoutes.management()}/*`} element={<UsersRouteGroup />} />}
                    {permittedUserRouteKeys.includes('settings') && (
                        <Route
                            path={`${PrivateRouteGroupRoutes.settings()}/*`}
                            element={<SettingsRouteGroup permittedUserRoutes={permittedUserRoutes} />}
                        />
                    )}
                    {permittedUserRouteKeys.includes('absences') && (
                        <Route
                            path={`${PrivateRouteGroupRoutes.absences()}/*`}
                            element={<AbsencesRouteGroup permittedUserRoutes={permittedUserRoutes} />}
                        />
                    )}
                    {permittedUserRouteKeys.includes('pz') && (
                        <Route
                            path={`${PrivateRouteGroupRoutes.pz()}/*`}
                            element={<PzRouteGroup permittedUserRoutes={permittedUserRoutes} />}
                        />
                    )}
                    <Route path={PrivateRouteGroupRoutes.logs()} element={<LogsConnector />} />
                    <Route path={PrivateRouteGroupRoutes.loketSSO()} element={<LoketSSO />} />
                    <Route path={PrivateRouteGroupRoutes.dashboard()} element={<DashboardPage />} />
                    <Route path="/*" element={permittedUserRouteKeys.length ? <ErrorPage errorCode={404} /> : <LoadingSpinner />} />
                </Routes>
            </Layout>
            <Toasts />
            <ConnectedToasts />
        </>
    );
};

export default PrivateRouteGroup;
