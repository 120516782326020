import { generateEventPath } from '../../../../../helpers';

export default function didUserClickShiftOrModalOrDeleteButton(
    e: MouseEvent,
    deleteButtonId?: string,
): boolean {
    const paths = generateEventPath(e.target);
    const classNames: string[] = paths.map(path => (path?.className || ''));

    if (paths.some(path => path.id === deleteButtonId)) {
        return true;
    }

    if (classNames.some(className => className?.indexOf && className.indexOf('modal') !== -1)) {
        return true;
    }

    return classNames.some(className => className?.indexOf && className.indexOf('concept-shift') !== -1);
}
