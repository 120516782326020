import { FC, ReactElement } from 'react';

import { useSelector } from 'react-redux';

import { Reducers } from '../../../../redux/reducers';
import { ShowMainDepartmentFilterContainer } from '../../../containers';
import { setOnlyShowMainDepartments } from '../../../redux/@interface/globalFilters/globalFiltersReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

const ConnectedShowMainDepartmentFilter: FC = (): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const legacyShowMainDepartment = useSelector((state: Reducers) => state.filterReducer.filter.onlyShowMainDepartment);
    const onlyShowMainDepartment = useTypedSelector(state => state.globalFiltersReducer.onlyShowMainDepartment);
    const departmentOptions = useTypedSelector(state => state.globalFiltersReducer.departmentOptions);

    const handleOnChange = (isChecked: boolean): void => {
        dispatch(setOnlyShowMainDepartments(isChecked));
    };

    return departmentOptions.length > 0 ? (
        <ShowMainDepartmentFilterContainer
            onlyShowMainDepartment={onlyShowMainDepartment || legacyShowMainDepartment}
            onChange={handleOnChange}
        />
    ) : null;
};

export default ConnectedShowMainDepartmentFilter;
