import { translate } from '../../../../helpers/translations/translator';

export default function getEditTrackError(
    dateError: boolean,
    payrollPeriodError: boolean,
): string | null {
    if (dateError) {
        return translate('pages.tracks.endDateAndEndTimeNeedsToBeLaterThanStartDate');
    }

    if (payrollPeriodError) {
        return translate('common.datesOverlapWithLockedPayrollPeriod');
    }

    return null;
}
