import axios from 'axios';

import {
    AddShiftPlanningFormData,
    CopyShiftPlanningData,
    Filter,
    ShiftPlanning,
} from '../../models';
import { convertDateToApiParamDateTime } from '../date';

interface AddShiftPlanningApiRequestParams {
    type: 'shiftPlannings';
    attributes: {
        start?: string;
        end?: string;
        planned?: boolean;
        color?: string | null;
    };
    relationships: {
        shift: {
            data: {
                type: 'shifts',
                id: string;
            },
        },
        user: {
            data: {
                type: 'users',
                id: string;
            },
        },
    }
}

interface GetShiftPlanningParams {
    filter: Filter,
    startDate: Date,
    endDate: Date,
    planned?: boolean,
    includes?: string[],
}

function transformCopyShiftPlanningDataToAddApiParams(
    data: CopyShiftPlanningData,
): AddShiftPlanningApiRequestParams {
    const {
        start,
        end,
        planned,
        color,
        shiftId,
        userId,
    } = data;

    return {
        type: 'shiftPlannings',
        attributes: {
            ...(start && { start }),
            ...(end && { end }),
            ...(planned && { planned }),
            ...((color || color === null) && { color }),
        },
        relationships: {
            shift: {
                data: {
                    type: 'shifts',
                    id: shiftId,
                },
            },
            user: {
                data: {
                    type: 'users',
                    id: userId,
                },
            },
        },
    };
}

function transformAddShiftPlanningFormDataToAddApiParams(
    formData: AddShiftPlanningFormData,
): AddShiftPlanningApiRequestParams {
    const { shiftId, userId } = formData;

    return {
        type: 'shiftPlannings',
        attributes: {
            planned: true,
        },
        relationships: {
            shift: {
                data: {
                    type: 'shifts',
                    id: shiftId,
                },
            },
            user: {
                data: {
                    type: 'users',
                    id: userId,
                },
            },
        },
    };
}

export const postShiftPlanning = async (
    formData: AddShiftPlanningFormData,
): Promise<ShiftPlanning> => {
    const body = transformAddShiftPlanningFormDataToAddApiParams(formData);
    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shift/plannings`,
        { data: body },
        {
            params: {
                include: [
                    'user',
                    'user.experience',
                    'user.employmentType',
                    'user.person',
                ].join(','),
            },
        },
    );

    // TODO: write transformer
    return response.data;
};

export const copyShiftPlanning = (data: CopyShiftPlanningData) => {
    const body = transformCopyShiftPlanningDataToAddApiParams(data);
    return axios.post(
        `${process.env.REACT_APP_API_URL}/shift/plannings`,
        { data: body },
    );
};

export const getShiftPlannings = async (params: GetShiftPlanningParams): Promise<ShiftPlanning[]> => {
    const {
        planned,
        startDate,
        endDate,
        filter,
        includes = [],
    } = params;
    const { userIds } = filter;

    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shift/plannings`,
        {
            params: {
                ...(includes.length && { include: includes.join(',') }),
                from: convertDateToApiParamDateTime(startDate),
                to: convertDateToApiParamDateTime(endDate),
                userUuids: (userIds && userIds.length > 0) ? JSON.stringify(userIds) : undefined,
                planned: planned ? 1 : 0,
            },
        },
    );

    // TODO: write transformers
    return response.data;
};
