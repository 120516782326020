import { BaseScheduleShiftViewModel } from '../../../../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../../../../entities/ShiftConcept/ShiftConcept';
import { ShiftType, ShiftViewModel } from '../../../../../models';

const getShiftsByIdAndShiftType = (
    shiftIds: string[],
    shifts: (ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel)[],
    shiftType: ShiftType,
) => shiftIds
    .filter((shiftId) => shifts
        .some(shift => shift.id === shiftId && shift.type === shiftType));

export default getShiftsByIdAndShiftType;
