import React, { FC, useMemo } from 'react';

import { Setting } from '../../@paco/entities/Setting/Setting';
import { CompanyMedicType } from '../../@paco/types';
import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { PreferToWorkTimeSlotViewModel } from '../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import { UnavailableToWorkTimeSlotViewModel } from '../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { getUserFullName } from '../../helpers';
import {
    AbsenceViewModel,
    LeaveOfAbsenceViewModel,
    ShiftPlanningViewModel,
    ShiftViewModel,
    TemporaryWorkerViewModel,
    UserViewModel,
} from '../../models';
import getShiftPlanningDeviatedTime from '../../services/ShiftPlanningService/getShiftPlanningDeviatedTime';
import EmployeeAvailabilityLabels from '../EmployeeAvailabilityLabels/EmployeeAvailabilityLabels';
import PlannedUserCard from '../PlannedUserCard/PlannedUserCard';


interface ShiftPlanningCardProps {
    shiftPlanning: ShiftPlanningViewModel;
    shift: ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel;
    absences: AbsenceViewModel[];
    leaveOfAbsences: LeaveOfAbsenceViewModel[];
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlotViewModel[];
    preferToWorkTimeSlots: PreferToWorkTimeSlotViewModel[];
    settings?: Setting[];
    onColorSelect?: (shiftPlanning: ShiftPlanningViewModel, color?: string) => void;
    onEditClick?: (shiftPlanning: ShiftPlanningViewModel) => void;
    onDeleteClick?: (shiftPlanning: ShiftPlanningViewModel) => void;
    showComments?: boolean;
}

const LegacyShiftPlanningCard: FC<ShiftPlanningCardProps> = ({
    shiftPlanning,
    shift,
    absences,
    leaveOfAbsences,
    unavailableToWorkTimeSlots,
    preferToWorkTimeSlots,
    settings,
    onColorSelect,
    onEditClick,
    onDeleteClick,
    showComments = false,
}) => {
    const { id, color } = shiftPlanning;
    const userId = shiftPlanning?.user?.id;
    const employmentType = shiftPlanning?.user?.employmentType;
    const name = useMemo(() => getUserFullName(shiftPlanning?.user), [shiftPlanning]);
    const isCompanyMedic = shiftPlanning.user?.person.isCompanyMedic === CompanyMedicType.yes;
    const time = useMemo(() => getShiftPlanningDeviatedTime(shiftPlanning, shift), [shiftPlanning, shift]);
    const comment = shiftPlanning.comments.length ? shiftPlanning.comments[shiftPlanning.comments.length - 1] : undefined;
    const experience = shiftPlanning?.user?.experience?.name;

    const plannedHoursInWeek = shiftPlanning?.user?.plannedHoursInWeek || 0;
    const plannedConceptHoursInWeek = shiftPlanning?.user?.plannedConceptHoursInWeek || 0;
    const totalPlannedHoursInWeek = plannedHoursInWeek + plannedConceptHoursInWeek;

    const EmployeeAvailabilityLabelsComp = useMemo(() => (userId ? (
        <EmployeeAvailabilityLabels
            userId={userId}
            shift={shiftPlanning}
            absences={absences}
            leaveOfAbsences={leaveOfAbsences}
            unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
            preferToWorkTimeSlots={preferToWorkTimeSlots}
            settings={settings}
        />
    ) : undefined), [
        userId,
        shiftPlanning,
        absences,
        leaveOfAbsences,
        unavailableToWorkTimeSlots,
        preferToWorkTimeSlots,
        settings,
    ]);

    const handleEditClick = () => {
        if (onEditClick) {
            onEditClick(shiftPlanning);
        }
    };

    const handleDeleteClick = () => {
        if (onDeleteClick) {
            onDeleteClick(shiftPlanning);
        }
    };

    const handleColorSelect = (
        currentUserId: string,
        shiftPlanningId: string,
        userType: UserViewModel['type'] | TemporaryWorkerViewModel['type'],
        newColor: string | null,
    ) => {
        if (onColorSelect) {
            onColorSelect(shiftPlanning, newColor || undefined);
        }
    };

    return (
        <PlannedUserCard
            isCompanyMedic={isCompanyMedic}
            id={id}
            color={color}
            userId={userId}
            userName={name}
            employmentType={employmentType}
            experience={experience}
            time={time}
            plannedHoursInWeek={totalPlannedHoursInWeek}
            comment={showComments ? comment : undefined}
            labels={EmployeeAvailabilityLabelsComp}
            onColorSelect={handleColorSelect}
            onEditButtonClick={onEditClick ? handleEditClick : undefined}
            onDeleteButtonClick={onDeleteClick ? handleDeleteClick : undefined}
        />
    );
};

export default LegacyShiftPlanningCard;
