
// App
export const SET_LOADING = 'SET_LOADING';
export const APP_SET_ERROR = 'APP_SET_ERROR';
export const APP_SAGA_INIT = 'APP_SAGA_INIT';
export const APP_SAGA_SET_LOADING = 'APP_SAGA_SET_LOADING';
export const APP_SAGA_SET_ERROR = 'APP_SAGA_SET_ERROR';
export const APP_SAGA_ROUTE_CHANGE = 'APP_SAGA_ROUTE_CHANGE';
export const APP_ROUTE_CHANGE = 'APP_ROUTE_CHANGE';
export const APP_SAGA_ADD_TOAST = 'APP_SAGA_ADD_TOAST';
export const APP_ADD_TOAST = 'APP_SET_TOAST';
export const APP_CLEAR_TOASTS = 'APP_CLEAR_TOASTS';
export const APP_SAGA_GET_GENERAL_DATA = 'APP_SAGA_GET_GENERAL_DATA';
export const APP_GET_GENERAL_DATA = 'APP_GET_GENERAL_DATA';
export const APP_GET_GENERAL_DATA_SUCCESS = 'APP_GET_GENERAL_DATA_SUCCESS';
export const APP_GET_GENERAL_DATA_FAILURE = 'APP_GET_GENERAL_DATA_FAILURE';
export const APP_SET_SWITCH_MODE = 'APP_SET_SWITCH_MODE';
export const APP_SAGA_SWITCH_MODE = 'APP_SAGA_SWITCH_MODE';
export const APP_SET_FEEDBACK_SETTING = 'APP_SET_FEEDBACK_SETTING';
export const APP_SAGA_GET_HIGH_PRIO_NOTIFICATIONS_INTERVAL = 'APP_SAGA_GET_HIGH_PRIO_NOTIFICATIONS_INTERVAL';
export const APP_SAGA_GET_MED_PRIO_NOTIFICATIONS_INTERVAL = 'APP_SAGA_GET_MED_PRIO_NOTIFICATIONS_INTERVAL';
export const APP_SAGA_GET_LOW_PRIO_NOTIFICATIONS_INTERVAL = 'APP_SAGA_GET_LOW_PRIO_NOTIFICATIONS_INTERVAL';
export const APP_SAGA_SET_NOTIFICATIONS_INTERVAL = 'APP_SAGA_SET_NOTIFICATIONS_INTERVAL';
export const APP_GET_NOTIFICATIONS_REQUEST = 'APP_GET_NOTIFICATIONS_REQUEST';
export const APP_GET_NOTIFICATIONS_SUCCESS = 'APP_GET_NOTIFICATIONS_SUCCESS';
export const APP_GET_NOTIFICATIONS_FAILURE = 'APP_GET_NOTIFICATIONS_FAILURE';
export const APP_SAGA_GET_PAYROLL_PERIODS = 'APP_SAGA_GET_PAYROLL_PERIODS';
export const APP_GET_PAYROLL_PERIODS_REQUEST = 'APP_GET_PAYROLL_PERIODS_REQUEST';
export const APP_GET_PAYROLL_PERIODS_SUCCESS = 'APP_GET_PAYROLL_PERIODS_SUCCESS';
export const APP_GET_PAYROLL_PERIODS_FAILURE = 'APP_GET_PAYROLL_PERIODS_FAILURE';
export const APP_SAGA_PUSH_HISTORY = 'APP_SAGA_PUSH_HISTORY';
export const APP_PUSH_HISTORY = 'APP_PUSH_HISTORY';
export const APP_SAGA_SIDEBAR_RESIZE = 'APP_SAGA_SIDEBAR_RESIZE';
export const APP_SIDEBAR_RESIZE = 'APP_SIDEBAR_RESIZE';
export const APP_SAGA_NOTIFY_EMPLOYEES = 'APP_SAGA_NOTIFY_EMPLOYEES';
export const APP_NOTIFY_EMPLOYEES_REQUEST = 'APP_NOTIFY_EMPLOYEES_REQUEST';
export const APP_NOTIFY_EMPLOYEES_SUCCESS = 'APP_NOTIFY_EMPLOYEES_SUCCESS';
export const APP_NOTIFY_EMPLOYEES_FAILURE = 'APP_NOTIFY_EMPLOYEES_FAILURE';

export const APP_SAGA_LOGOUT = 'APP_SAGA_LOGOUT';
export const APP_LOGOUT = 'APP_LOGOUT';

// Filter
export const FILTER_SET_FILTER = 'FILTER_SET_FILTER';
export const FILTER_SET_FILTER_FROM_PACO_STORE = 'FILTER_SET_FILTER_FROM_PACO_STORE';
export const FILTER_SAGA_SET_SHIFTS = 'FILTER_SAGA_SET_SHIFTS';
export const FILTER_SAGA_SET_TRACKS = 'FILTER_SAGA_SET_TRACKS';
export const FILTER_SAGA_SET_FILTER = 'FILTER_SAGA_SET_FILTER';
export const FILTER_RESET_FILTER = 'FILTER_RESET_FILTER';
export const FILTER_SAGA_SET_AVAILABILITY_FILTER = 'FILTER_SAGA_SET_AVAILABILITY_FILTER';
export const FILTER_SAGA_SET_EMPLOYEE_CONTRACT_TYPE = 'FILTER_SAGA_SET_EMPLOYEE_CONTRACT_TYPE';
export const FILTER_SET_EMPLOYEE_CONTRACT_TYPE = 'FILTER_SET_EMPLOYEE_CONTRACT_TYPE';
export const FILTER_SAGA_SET_USER_TYPE = 'FILTER_SAGA_SET_USER_TYPE';
export const FILTER_SET_USER_TYPE = 'FILTER_SET_USER_TYPE';
export const FILTER_SAGA_SET_EMPLOYEE_SEARCH = 'FILTER_SAGA_SET_EMPLOYEE_SEARCH';
export const FILTER_SAGA_SET_CALENDAR_VIEW_PROPS = 'FILTER_SAGA_SET_CALENDAR_VIEW_PROPS';
export const FILTER_SET_CALENDAR_VIEW_PROPS = 'FILTER_SET_CALENDAR_VIEW_PROPS';
export const FILTER_SAGA_SET_DOCUMENT_CATEGORIES = 'FILTER_SAGA_SET_DOCUMENT_CATEGORIES';
export const FILTER_SET_DOCUMENT_CATEGORIES = 'FILTER_SET_DOCUMENT_CATEGORIES';
export const FILTER_SAGA_SET_EMPLOYMENT_TYPE_BADGE = 'FILTER_SAGA_SET_EMPLOYMENT_TYPE_BADGE';
export const FILTER_SET_EMPLOYMENT_TYPE_BADGE = 'FILTER_SET_EMPLOYMENT_TYPE_BADGE';
export const FILTER_SAGA_SET_DOCUMENT_SEARCH = 'FILTER_SAGA_SET_DOCUMENT_SEARCH';
// User
export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';
export const CURRENT_USER_FAILURE = 'CURRENT_USER_FAILURE';
export const CURRENT_USER_SAGA_GET_USER = 'CURRENT_USER_SAGA_GET_USER';
export const CURRENT_USER_UNSET = 'CURRENT_USER_UNSET';

// Users
export const USERS_GET_EMPLOYEES_REQUEST = 'USERS_GET_EMPLOYEES_REQUEST';
export const USERS_GET_EMPLOYEES_SUCCESS = 'USERS_GET_EMPLOYEES_SUCCESS';
export const USERS_GET_EMPLOYEES_FAILURE = 'USERS_GET_EMPLOYEES_FAILURE';
export const USERS_SAGA_GET_EMPLOYEES = 'USERS_SAGA_GET_EMPLOYEES';
export const USERS_MAPPED_ABSENCE_DATA_TO_EMPLOYEES = 'USERS_MAPPED_ABSENCE_DATA_TO_EMPLOYEES';

// Authentication
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_AUTHENTICATE_REQUEST = 'AUTH_AUTHENTICATE_REQUEST';
export const AUTH_AUTHENTICATE_SUCCESS = 'AUTH_AUTHENTICATE_SUCCESS';
export const AUTH_AUTHENTICATE_FAILURE = 'AUTH_AUTHENTICATE_FAILURE';
export const AUTH_SET_API_TOKEN = 'AUTH_SET_API_TOKEN';
export const AUTH_SET_REFRESH_TOKEN = 'AUTH_SET_REFRESH_TOKEN';
export const AUTH_UNSET_TOKENS = 'AUTH_UNSET_TOKENS';
export const AUTH_SAGA_AUTHENTICATE_USER = 'AUTH_SAGA_AUTHENTICATE_USER';
export const AUTH_SAGA_UPDATE_API_TOKEN = 'AUTH_SAGA_UPDATE_API_TOKEN';
export const AUTH_SAGA_UPDATE_REFRESH_TOKEN = 'AUTH_SAGA_UPDATE_REFRESH_TOKEN';
export const AUTH_SAGA_UNSET_TOKENS = 'AUTH_SAGA_UNSET_TOKENS';
export const AUTH_SAGA_FORGOT_PASSWORD = 'AUTH_SAGA_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_REQUEST = 'AUTH_FORGOT_PASSWORD_REQUEST';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_FAILURE = 'AUTH_FORGOT_PASSWORD_FAILURE';
export const AUTH_SAGA_SUBMIT_PASSWORD = 'AUTH_SAGA_SUBMIT_PASSWORD';
export const AUTH_SUBMIT_PASSWORD_REQUEST = 'AUTH_SUBMIT_PASSWORD_REQUEST';
export const AUTH_SUBMIT_PASSWORD_SUCCESS = 'AUTH_SUBMIT_PASSWORD_SUCCESS';
export const AUTH_SUBMIT_PASSWORD_FAILURE = 'AUTH_SUBMIT_PASSWORD_FAILURE';
export const AUTH_SAGA_SET_API_TOKEN_AND_GET_USER = 'AUTH_SAGA_SET_API_TOKEN_AND_GET_USER';
export const AUTH_SET_API_TOKEN_AND_GET_USER_REQUEST = 'AUTH_SET_API_TOKEN_AND_GET_USER_REQUEST';
export const AUTH_SET_API_TOKEN_AND_GET_USER_FAILURE = 'AUTH_SET_API_TOKEN_AND_GET_USER_FAILURE';

// Shifts
export const SHIFTS_FAILURE = 'SHIFTS_FAILURE';
export const SHIFTS_SAGA_GET_SHIFTS = 'SHIFTS_SAGA_GET_SHIFTS';
export const SHIFTS_GET_SHIFTS_REQUEST = 'SHIFTS_GET_SHIFTS_REQUEST';
export const SHIFTS_GET_SHIFTS_FAILURE = 'SHIFTS_GET_SHIFTS_FAILURE';
export const SHIFTS_GET_SHIFTS_SUCCESS = 'SHIFTS_GET_SHIFTS_SUCCESS';
export const SHIFTS_GET_SHIFTS_SUCCESS_BUT_WAIT_FOR_FILTER = 'SHIFTS_GET_SHIFTS_SUCCESS_BUT_WAIT_FOR_FILTER';
export const SHIFTS_SAGA_GET_SHIFT = 'SHIFTS_SAGA_GET_SHIFTS_REQUEST';
export const SHIFTS_GET_SHIFT_REQUEST = 'SHIFTS_GET_SHIFT_REQUEST';
export const SHIFTS_GET_SHIFT_FAILURE = 'SHIFTS_GET_SHIFT_FAILURE';
export const SHIFTS_GET_SHIFT_SUCCESS = 'SHIFTS_GET_SHIFT_SUCCESS';
export const SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_REQUEST = 'SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_REQUEST';
export const SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_SUCCESS = 'SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_SUCCESS';
export const SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_FAILURE = 'SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_FAILURE';
export const SHIFTS_GET_SHIFT_SNAPSHOT_REQUEST = 'SHIFTS_GET_SHIFT_SNAPSHOT_REQUEST';
export const SHIFTS_GET_SHIFT_SNAPSHOT_SUCCESS = 'SHIFTS_GET_SHIFT_SNAPSHOT_SUCCESS';
export const SHIFTS_GET_SHIFT_SNAPSHOT_FAILURE = 'SHIFTS_GET_SHIFT_SNAPSHOT_FAILURE';
export const SHIFTS_SAGA_EDIT_SHIFTS_WITH_SNAPSHOT = 'SHIFTS_SAGA_EDIT_SHIFTS_WITH_SNAPSHOT';
export const SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_REQUEST = 'SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_REQUEST';
export const SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_SUCCESS = 'SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_SUCCESS';
export const SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_FAILURE = 'SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_FAILURE';
export const SHIFTS_SAGA_CREATE_SHIFT = 'SHIFTS_SAGA_CREATE_SHIFT';
export const SHIFTS_SAGA_EDIT_SHIFT = 'SHIFTS_SAGA_EDIT_SHIFT';
export const SHIFTS_SAGA_CANCEL_EDIT_SHIFT = 'SHIFTS_SAGA_CANCEL_EDIT_SHIFT';
export const SHIFTS_CANCEL_EDIT_SHIFT = 'SHIFTS_CANCEL_EDIT_SHIFT';
export const SHIFTS_ADD_SHIFT_SUCCESS = 'SHIFTS_ADD_SHIFT_SUCCESS';
export const SHIFTS_ADD_SHIFT_REQUEST = 'SHIFTS_ADD_SHIFT_REQUEST';
export const SHIFTS_EDIT_SHIFT_SUCCESS = 'SHIFTS_EDIT_SHIFT_SUCCESS';
export const SHIFTS_EDIT_SHIFT_REQUEST = 'SHIFTS_EDIT_SHIFT_REQUEST';
export const SHIFTS_EDIT_SHIFT_FAILURE = 'SHIFTS_EDIT_SHIFT_FAILURE';
export const SHIFTS_SAGA_PLAN_USER_TO_SHIFT = 'SHIFTS_SAGA_PLAN_USER_TO_SHIFT';
export const SHIFTS_PLAN_USER_TO_SHIFT_REQUEST = 'SHIFTS_PLAN_USER_TO_SHIFT_REQUEST';
export const SHIFTS_PLAN_USER_TO_SHIFT_SUCCESS = 'SHIFTS_PLAN_USER_TO_SHIFT_SUCCESS';
export const SHIFTS_SAGA_DELETE_USER_FROM_SHIFT = 'SHIFTS_SAGA_DELETE_USER_FROM_SHIFT';
export const SHIFTS_DELETE_USER_FROM_SHIFT_REQUEST = 'SHIFTS_DELETE_USER_FROM_SHIFT_REQUEST';
export const SHIFTS_DELETE_USER_FROM_SHIFT_SUCCESS = 'SHIFTS_DELETE_USER_FROM_SHIFT_SUCCESS';
export const SHIFTS_SAGA_EDIT_SHIFT_PLANNING = 'SHIFTS_SAGA_EDIT_SHIFT_PLANNING';
export const SHIFTS_EDIT_SHIFT_PLANNING_REQUEST = 'SHIFTS_EDIT_SHIFT_PLANNING_REQUEST';
export const SHIFTS_EDIT_SHIFT_PLANNING_SUCCESS = 'SHIFTS_EDIT_SHIFT_PLANNING_SUCCESS';
export const SHIFTS_EDIT_SHIFT_PLANNING_FAILURE = 'SHIFTS_EDIT_SHIFT_PLANNING_FAILURE';
export const SHIFTS_DELETE_SHIFT_REQUEST = 'SHIFTS_DELETE_SHIFT_REQUEST';
export const SHIFTS_DELETE_SHIFT_SUCCESS = 'SHIFTS_DELETE_SHIFT_SUCCESS';
export const SHIFTS_DELETE_SHIFT_FAILURE = 'SHIFTS_DELETE_SHIFT_FAILURE';
export const SHIFTS_SET_LOADING = 'SHIFTS_SET_LOADING';
export const SHIFTS_SAGA_DELETE_SHIFT = 'SHIFTS_SAGA_DELETE_SHIFT';
export const SHIFTS_DELETE_SHIFT = 'SHIFTS_DELETE_SHIFT';
export const SHIFTS_PLAN_USER_SET_LOADING = 'SHIFTS_PLAN_USER_SET_LOADING';
export const SHIFTS_MAPPED_ABSENCE_DATA_TO_SHIFT_PLANNINGS = 'SHIFTS_MAPPED_ABSENCE_DATA_TO_SHIFT_PLANNINGS';
export const SHIFTS_FILTER_SHIFT_BY_ABSENCE_FILTERS = 'SHIFTS_FILTER_SHIFT_BY_ABSENCE_FILTERS';
export const SHIFTS_SAGA_OPEN_SHIFT = 'SHIFTS_SAGA_OPEN_SHIFT';
export const SHIFTS_OPEN_SHIFT = 'SHIFTS_OPEN_SHIFT';
export const SHIFTS_SAGA_ADD_TEMP_WORKER = 'SHIFTS_SAGA_ADD_TEMP_WORKER';
export const SHIFTS_ADD_TEMP_WORKER_REQUEST = 'SHIFTS_ADD_TEMP_WORKER_REQUEST';
export const SHIFTS_ADD_TEMP_WORKER_SUCCESS = 'SHIFTS_ADD_TEMP_WORKER_SUCCESS';
export const SHIFTS_ADD_TEMP_WORKER_FAILURE = 'SHIFTS_ADD_TEMP_WORKER_FAILURE';
export const SHIFTS_SAGA_EDIT_TEMP_WORKER = 'SHIFTS_SAGA_EDIT_TEMP_WORKER';
export const SHIFTS_EDIT_TEMP_WORKER_REQUEST = 'SHIFTS_EDIT_TEMP_WORKER_REQUEST';
export const SHIFTS_EDIT_TEMP_WORKER_SUCCESS = 'SHIFTS_EDIT_TEMP_WORKER_SUCCESS';
export const SHIFTS_EDIT_TEMP_WORKER_FAILURE = 'SHIFTS_EDIT_TEMP_WORKER_FAILURE';
export const SHIFTS_SAGA_DELETE_TEMP_WORKER = 'SHIFTS_SAGA_DELETE_TEMP_WORKER';
export const SHIFTS_DELETE_TEMP_WORKER_REQUEST = 'SHIFTS_DELETE_TEMP_WORKER_REQUEST';
export const SHIFTS_DELETE_TEMP_WORKER_SUCCESS = 'SHIFTS_DELETE_TEMP_WORKER_SUCCESS';
export const SHIFTS_DELETE_TEMP_WORKER_FAILURE = 'SHIFTS_DELETE_TEMP_WORKER_FAILURE';
export const SHIFTS_SAGA_GET_SPECIAL_DAYS = 'SHIFTS_SAGA_GET_SPECIAL_DAYS';
export const SHIFTS_GET_SPECIAL_DAYS_REQUEST = 'SHIFTS_GET_SPECIAL_DAYS_REQUEST';
export const SHIFTS_GET_SPECIAL_DAYS_SUCCESS = 'SHIFTS_GET_SPECIAL_DAYS_SUCCESS';
export const SHIFTS_GET_SPECIAL_DAYS_FAILURE = 'SHIFTS_GET_SPECIAL_DAYS_FAILURE';
export const SHIFTS_SAGA_GET_SPECIAL_DAY_TYPES = 'SHIFTS_SAGA_GET_SPECIAL_DAY_TYPES';
export const SHIFTS_GET_SPECIAL_DAY_TYPES_REQUEST = 'SHIFTS_GET_SPECIAL_DAY_TYPES_REQUEST';
export const SHIFTS_GET_SPECIAL_DAY_TYPES_SUCCESS = 'SHIFTS_GET_SPECIAL_DAY_TYPES_SUCCESS';
export const SHIFTS_GET_SPECIAL_DAY_TYPES_FAILURE = 'SHIFTS_GET_SPECIAL_DAY_TYPES_FAILURE';
export const SHIFTS_SAGA_ADD_SPECIAL_DAY = 'SHIFTS_SAGA_ADD_SPECIAL_DAY';
export const SHIFTS_ADD_SPECIAL_DAY_REQUEST = 'SHIFTS_ADD_SPECIAL_DAY_REQUEST';
export const SHIFTS_ADD_SPECIAL_DAY_SUCCESS = 'SHIFTS_ADD_SPECIAL_DAY_SUCCESS';
export const SHIFTS_ADD_SPECIAL_DAY_FAILURE = 'SHIFTS_ADD_SPECIAL_DAY_FAILURE';
export const SHIFTS_SAGA_EDIT_SPECIAL_DAY = 'SHIFTS_SAGA_EDIT_SPECIAL_DAY';
export const SHIFTS_EDIT_SPECIAL_DAY_REQUEST = 'SHIFTS_EDIT_SPECIAL_DAY_REQUEST';
export const SHIFTS_EDIT_SPECIAL_DAY_SUCCESS = 'SHIFTS_EDIT_SPECIAL_DAY_SUCCESS';
export const SHIFTS_EDIT_SPECIAL_DAY_FAILURE = 'SHIFTS_EDIT_SPECIAL_DAY_FAILURE';
export const SHIFTS_SAGA_DELETE_SPECIAL_DAY = 'SHIFTS_SAGA_DELETE_SPECIAL_DAY';
export const SHIFTS_DELETE_SPECIAL_DAY_REQUEST = 'SHIFTS_DELETE_SPECIAL_DAY_REQUEST';
export const SHIFTS_DELETE_SPECIAL_DAY_SUCCESS = 'SHIFTS_DELETE_SPECIAL_DAY_SUCCESS';
export const SHIFTS_DELETE_SPECIAL_DAY_FAILURE = 'SHIFTS_DELETE_SPECIAL_DAY_FAILURE';
export const SHIFTS_SAGA_GET_PLANNING_PREFERENCES = 'SHIFTS_SAGA_GET_PLANNING_PREFERENCES';
export const SHIFTS_GET_PLANNING_PREFERENCES_REQUEST = 'SHIFTS_GET_PLANNING_PREFERENCES_REQUEST';
export const SHIFTS_GET_PLANNING_PREFERENCES_SUCCESS = 'SHIFTS_GET_PLANNING_PREFERENCES_SUCCESS';
export const SHIFTS_GET_PLANNING_PREFERENCES_FAILURE = 'SHIFTS_GET_PLANNING_PREFERENCES_FAILURE';
export const SHIFTS_GET_USERS_PLANNED_ON_DATE_REQUEST = 'SHIFTS_GET_USERS_PLANNED_ON_DATE_REQUEST';
export const SHIFTS_GET_USERS_PLANNED_ON_DATE_SUCCESS = 'SHIFTS_GET_USERS_PLANNED_ON_DATE_SUCCESS';
export const SHIFTS_GET_USERS_PLANNED_ON_DATE_FAILURE = 'SHIFTS_GET_USERS_PLANNED_ON_DATE_FAILURE';
export const SHIFTS_SAGA_SET_EMPLOYEE_COLOR = 'SHIFTS_SAGA_SET_EMPLOYEE_COLOR';
export const SHIFTS_SET_EMPLOYEE_COLOR_REQUEST = 'SHIFTS_SET_EMPLOYEE_COLOR_REQUEST';
export const SHIFTS_SET_EMPLOYEE_COLOR_SUCCESS = 'SHIFTS_SET_EMPLOYEE_COLOR_SUCCESS';
export const SHIFTS_SET_EMPLOYEE_COLOR_FAILURE = 'SHIFTS_SET_EMPLOYEE_COLOR_FAILURE';
export const SHIFTS_SAGA_GET_AVAILABILITY = 'SHIFTS_SAGA_GET_AVAILABILITY';
export const SHIFTS_GET_AVAILABILITY_REQUEST = 'SHIFTS_GET_AVAILABILITY_REQUEST';
export const SHIFTS_GET_AVAILABILITY_SUCCESS = 'SHIFTS_GET_AVAILABILITY_SUCCESS';
export const SHIFTS_GET_AVAILABILITY_FAILURE = 'SHIFTS_GET_AVAILABILITY_FAILURE';
export const SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_REQUEST = 'SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_REQUEST';
export const SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_SUCCESS = 'SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_SUCCESS';
export const SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_FAILURE = 'SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_FAILURE';
export const SHIFTS_SAGA_UPDATE_SHIFTS_WITH_SHIFT = 'SHIFTS_SAGA_UPDATE_SHIFTS_WITH_SHIFT';
export const SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_REQUEST = 'SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_REQUEST';
export const SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_SUCCESS = 'SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_SUCCESS';
export const SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_FAILURE = 'SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_FAILURE';
export const SHIFTS_SAGA_CALENDAR_COPY_SHIFT = 'SHIFTS_SAGA_CALENDAR_COPY_SHIFT';
export const SHIFTS_CALENDAR_COPY_SHIFT_REQUEST = 'SHIFTS_CALENDAR_COPY_SHIFT_REQUEST';
export const SHIFTS_CALENDAR_COPY_SHIFT_SUCCESS = 'SHIFTS_CALENDAR_COPY_SHIFT_SUCCESS';
export const SHIFTS_SAGA_GET_USERS_WITH_AVAILABILITY = 'SHIFTS_SAGA_GET_USERS_WITH_AVAILABILITY';
export const SHIFTS_GET_USERS_WITH_AVAILABILITY_REQUEST = 'SHIFTS_GET_USERS_WITH_AVAILABILITY_REQUEST';
export const SHIFTS_GET_USERS_WITH_AVAILABILITY_SUCCESS = 'SHIFTS_GET_USERS_WITH_AVAILABILITY_SUCCESS';
export const SHIFTS_GET_USERS_WITH_AVAILABILITY_FAILURE = 'SHIFTS_GET_USERS_WITH_AVAILABILITY_FAILURE';
export const SHIFTS_SAGA_PLAN_AVAILABILITY_USER_TO_SHIFT = 'SHIFTS_SAGA_PLAN_AVAILABILITY_USER_TO_SHIFT';
export const SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_REQUEST = 'SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_REQUEST';
export const SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_FAILURE = 'SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_FAILURE';
export const SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_SUCCESS = 'SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_SUCCESS';
export const SHIFTS_SAGA_GET_USERS_WITH_LOKET_BALANCES = 'SHIFTS_SAGA_GET_USERS_WITH_LOKET_BALANCES';
export const SHIFTS_GET_USERS_WITH_LOKET_BALANCES_REQUEST = 'SHIFTS_GET_USERS_WITH_LOKET_BALANCES_REQUEST';
export const SHIFTS_GET_USERS_WITH_LOKET_BALANCES_FAILURE = 'SHIFTS_GET_USERS_WITH_LOKET_BALANCES_FAILURE';
export const SHIFTS_GET_USERS_WITH_LOKET_BALANCES_SUCCESS = 'SHIFTS_GET_USERS_WITH_LOKET_BALANCES_SUCCESS';
export const SHIFTS_UPDATE_USERS_WITH_AVAILABILITY = 'SHIFTS_UPDATE_USERS_WITH_AVAILABILITY';

// Tracks
export const TRACKS_SAGA_GET_TRACKS = 'TRACKS_SAGA_GET_TRACKS';
export const TRACKS_REQUEST = 'TRACKS_REQUEST';
export const TRACKS_FAILURE = 'TRACKS_FAILURE';
export const TRACKS_SUCCESS = 'TRACKS_SUCCESS';
export const TRACKS_SAGA_EXPORT_CSV = 'TRACKS_SAGA_EXPORT_CSV';
export const TRACKS_EXPORT_CSV_REQUEST = 'TRACKS_EXPORT_CSV_REQUEST';
export const TRACKS_EXPORT_CSV_SUCCESS = 'TRACKS_EXPORT_CSV_SUCCESS';
export const TRACKS_EXPORT_CSV_FAILURE = 'TRACKS_EXPORT_CSV_FAILURE';
export const TRACKS_SAGA_GET_USER_TRACKS = 'TRACKS_SAGA_GET_USER_TRACKS';
export const TRACKS_GET_USER_TRACKS_REQUEST = 'TRACKS_GET_USER_TRACKS_REQUEST';
export const TRACKS_GET_USER_TRACKS_SUCCESS = 'TRACKS_GET_USER_TRACKS_SUCCESS';
export const TRACKS_GET_USER_TRACKS_FAILURE = 'TRACKS_GET_USER_TRACKS_FAILURE';
export const TRACKS_SAGA_SYNC_TVT = 'TRACKS_SAGA_SYNC_TVT';
export const TRACKS_SYNC_TVT_REQUEST = 'TRACKS_SYNC_TVT_REQUEST';
export const TRACKS_SYNC_TVT_SUCCESS = 'TRACKS_SYNC_TVT_SUCCESS';
export const TRACKS_SYNC_TVT_FAILURE = 'TRACKS_SYNC_TVT_FAILURE';

// Track
export const TRACK_SAGA_STOP_TRACK = 'TRACK_STOP_TRACK';
export const TRACK_SAGA_APPROVE_TRACK = 'TRACK_SAGA_APPROVE_TRACK';
export const TRACK_SAGA_EDIT_TRACK = 'TRACK_SAGA_EDIT_TRACK';
export const TRACK_SAGA_ADD_TRACK = 'TRACK_SAGA_ADD_TRACK';
export const TRACK_SAGA_DELETE_TRACK = 'TRACK_SAGA_DELETE_TRACK';
export const TRACK_ADD_TRACK = 'TRACK_ADD_TRACK';
export const TRACK_UPDATE_SUCCESS = 'TRACK_UPDATE_SUCCESS ';
export const TRACK_UPDATE_FAILURE = 'TRACK_UPDATE_FAILURE';
export const TRACK_REMOVE = 'TRACK_REMOVE';
export const TRACK_REMOVE_REQUEST = 'TRACK_REMOVE_REQUEST';
export const TRACK_REMOVE_SUCCESS = 'TRACK_REMOVE_SUCCESS';

// Week Navigator
export const WEEK_NAVIGATOR_SET_DATES = 'WEEK_NAVIGATOR_SET_DATES';
export const WEEK_NAVIGATOR_SET_SELECTED_DATE = 'WEEK_NAVIGATOR_SET_SELECTED_DATE';
export const WEEK_NAVIGATOR_SET_TIME_MODE = 'WEEK_NAVIGATOR_SET_TIME_MODE';
export const WEEK_NAVIGATOR_SET_ACTIVE_PAYROLL_PERIOD = 'WEEK_NAVIGATOR_SET_ACTIVE_PAYROLL_PERIOD';
export const WEEK_NAVIGATOR_SAGA_SET_DATES = 'WEEK_NAVIGATOR_SAGA_SET_DATES';
export const WEEK_NAVIGATOR_SAGA_SET_SELECTED_DATE = 'WEEK_NAVIGATOR_SAGA_SET_SELECTED_DATE';
export const WEEK_NAVIGATOR_SAGA_SET_TIME_MODE = 'WEEK_NAVIGATOR_SAGA_SET_TIME_MODE';

// Pagination
export const PAGINATION_SAGA_SET_NUMBER = 'PAGINATION_SAGA_SET_NUMBER';
export const PAGINATION_SAGA_SET_PAGES = 'PAGINATION_SAGA_SET_PAGES';
export const PAGINATION_SAGA_SET_SIZE = 'PAGINATION_SAGA_SET_SIZE';
export const PAGINATION_SET_NUMBER = 'PAGINATION_SET_NUMBER';
export const PAGINATION_SET_PAGES = 'PAGINATION_SET_PAGES';
export const PAGINATION_SET_SIZE = 'PAGINATION_SET_SIZE';
export const PAGINATION_SET_PAGINATION = 'PAGINATION_SET_PAGINATION';

// Feedback
export const FEEDBACK_SAGA_GET_DATA = 'FEEDBACK_SAGA_GET_DATA';
export const FEEDBACK_GET_DATA = 'FEEDBACK_GET_DATA';
export const FEEDBACK_REQUEST = 'FEEDBACK_REQUEST';
export const FEEDBACK_FAILURE = 'FEEDBACK_FAILURE';
export const FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS';

// Management
export const MANAGEMENT_SAGA_SET_GROUPS = 'MANAGEMENT_SAGA_SET_GROUPS';
export const MANAGEMENT_SAGA_GET_DOCUMENT_TYPES = 'MANAGEMENT_GET_DOCUMENT_TYPES';
export const MANAGEMENT_SAGA_GET_USER = 'MANAGEMENT_SAGA_GET_USER';
export const MANAGEMENT_SAGA_DEACTIVATE_USER = 'MANAGEMENT_SAGA_DEACTIVATE_USER';
export const MANAGEMENT_SAGA_REACTIVATE_USER = 'MANAGEMENT_SAGA_REACTIVATE_USER';
export const MANAGEMENT_SAGA_DELETE_USER = 'MANAGEMENT_SAGA_DELETE_USER';
export const MANAGEMENT_GET_USER_REQUEST = 'MANAGEMENT_GET_USER_REQUEST';
export const MANAGEMENT_SAGA_GET_USERS = 'MANAGEMENT_SAGA_GET_USERS';
export const MANAGEMENT_SAGA_GET_USERS_QUICKLY = 'MANAGEMENT_SAGA_GET_USERS_QUICKLY';
export const MANAGEMENT_DEACTIVATE_USER_SUCCESS = 'MANAGEMENT_DEACTIVATE_USER';
export const MANAGEMENT_REACTIVATE_USER_SUCCESS = 'MANAGEMENT_REACTIVATE_USER';
export const MANAGEMENT_DELETE_USER_SUCCESS = 'MANAGEMENT_DELETE_USER';
export const MANAGEMENT_SET_USER = 'MANAGEMENT_SET_USER';
export const MANAGEMENT_SET_GROUPS = 'MANAGEMENT_SET_GROUPS';
export const MANAGEMENT_SET_USERS = 'MANAGEMENT_SET_USERS';
export const MANAGEMENT_SAGA_EDIT_DEPARTMENT = 'MANAGEMENT_SAGA_EDIT_DEPARTMENT';
export const MANAGEMENT_SAGA_ADD_DEPARTMENT = 'MANAGEMENT_SAGA_ADD_DEPARTMENT';
export const MANAGEMENT_EDIT_DEPARTMENT_SUCCESS = 'MANAGEMENT_EDIT_DEPARTMENT_SUCCESS';
export const MANAGEMENT_ADD_DEPARTMENT_SUCCESS = 'MANAGEMENT_ADD_DEPARTMENT_SUCCESS';
export const MANAGEMENT_SAGA_EDIT_GROUPS = 'MANAGEMENT_SAGA_EDIT_GROUPS';
export const MANAGEMENT_EDIT_GROUPS_SUCCESS = 'MANAGEMENT_SAGA_EDIT_GROUPS_SUCCESS';
export const MANAGEMENT_SAGA_DELETE_DEPARTMENT = 'MANAGEMENT_SAGA_DELETE_DEPARTMENT';
export const MANAGEMENT_DELETE_DEPARTMENT_SUCCESS = 'MANAGEMENT_DELETE_DEPARTMENT_SUCCESS';
export const MANAGEMENT_SAGA_ADD_DOCUMENT = 'MANAGEMENT_SAGA_ADD_DOCUMENT';
export const MANAGEMENT_ADD_DOCUMENT_REQUEST = 'MANAGEMENT_ADD_DOCUMENT_REQUEST';
export const MANAGEMENT_ADD_DOCUMENT_SUCCESS = 'MANAGEMENT_ADD_DOCUMENT_SUCCESS';
export const MANAGEMENT_ADD_DOCUMENT_FAILURE = 'MANAGEMENT_ADD_DOCUMENT_FAILURE';
export const MANAGEMENT_SAGA_EDIT_DOCUMENT = 'MANAGEMENT_SAGA_EDIT_DOCUMENT';
export const MANAGEMENT_EDIT_DOCUMENT_REQUEST = 'MANAGEMENT_EDIT_DOCUMENT_REQUEST';
export const MANAGEMENT_EDIT_DOCUMENT_SUCCESS = 'MANAGEMENT_EDIT_DOCUMENT_SUCCESS';
export const MANAGEMENT_EDIT_DOCUMENT_FAILURE = 'MANAGEMENT_EDIT_DOCUMENT_FAILURE';
export const MANAGEMENT_SAGA_DELETE_DOCUMENT = 'MANAGEMENT_SAGA_DELETE_DOCUMENT';
export const MANAGEMENT_DELETE_DOCUMENT_REQUEST = 'MANAGEMENT_DELETE_DOCUMENT_REQUEST';
export const MANAGEMENT_DELETE_DOCUMENT_SUCCESS = 'MANAGEMENT_DELETE_DOCUMENT_SUCCESS';
export const MANAGEMENT_DELETE_DOCUMENT_FAILURE = 'MANAGEMENT_DELETE_DOCUMENT_FAILURE';
export const MANAGEMENT_GET_DOCUMENT_TYPES_REQUEST = 'MANAGEMENT_GET_DOCUMENT_TYPES_REQUEST';
export const MANAGEMENT_GET_DOCUMENT_TYPES_SUCCESS = 'MANAGEMENT_GET_DOCUMENT_TYPES_SUCCESS';
export const MANAGEMENT_GET_DOCUMENT_TYPES_FAILURE = 'MANAGEMENT_GET_DOCUMENT_TYPES_FAILURE';
export const MANAGEMENT_SAGA_RESET_PASSWORD_AND_PINCODE = 'MANAGEMENT_SAGA_RESET_PASSWORD_AND_PINCODE';
export const MANAGEMENT_RESET_PASSWORD_AND_PINCODE_REQUEST = 'MANAGEMENT_RESET_PASSWORD_AND_PINCODE_REQUEST';
export const MANAGEMENT_RESET_PASSWORD_AND_PINCODE_SUCCESS = 'MANAGEMENT_RESET_PASSWORD_AND_PINCODE_SUCCESS';
export const MANAGEMENT_RESET_PASSWORD_AND_PINCODE_FAILURE = 'MANAGEMENT_RESET_PASSWORD_AND_PINCODE_FAILURE';
export const MANAGEMENT_EDIT_USER_REQUEST = 'MANAGEMENT_EDIT_USER_REQUEST';
export const MANAGEMENT_EDIT_USER_SUCCESS = 'MANAGEMENT_EDIT_USER_SUCCESS';
export const MANAGEMENT_EDIT_USER_FAILURE = 'MANAGEMENT_EDIT_USER_FAILURE';
export const MANAGEMENT_SAGA_RESEND_REGISTRATION_MAIL = 'MANAGEMENT_SAGA_RESEND_REGISTRATION_MAIL';
export const MANAGEMENT_RESEND_REGISTRATION_MAIL_REQUEST = 'MANAGEMENT_RESEND_REGISTRATION_MAIL_REQUEST';
export const MANAGEMENT_RESEND_REGISTRATION_MAIL_SUCCESS = 'MANAGEMENT_RESEND_REGISTRATION_MAIL_SUCCESS';
export const MANAGEMENT_RESEND_REGISTRATION_MAIL_FAILURE = 'MANAGEMENT_RESEND_REGISTRATION_MAIL_FAILURE';
export const MANAGEMENT_SAGA_SET_SORTING = 'MANAGEMENT_SAGA_SET_SORTING';
export const MANAGEMENT_SET_SORTING = 'MANAGEMENT_SET_SORTING';
export const MANAGEMENT_SAGA_AUTHENTICATE_LOKET = 'MANAGEMENT_SAGA_AUTHENTICATE_LOKET';
export const MANAGEMENT_AUTHENTICATE_LOKET_REQUEST = 'MANAGEMENT_AUTHENTICATE_LOKET_REQUEST';
export const MANAGEMENT_AUTHENTICATE_LOKET_SUCCESS = 'MANAGEMENT_AUTHENTICATE_LOKET_SUCCESS';
export const MANAGEMENT_AUTHENTICATE_LOKET_FAILURE = 'MANAGEMENT_AUTHENTICATE_LOKET_FAILURE';
export const MANAGEMENT_SAGA_LOGIN_LOKET = 'MANAGEMENT_SAGA_LOGIN_LOKET';
export const MANAGEMENT_LOGIN_LOKET_REQUEST = 'MANAGEMENT_LOGIN_LOKET_REQUEST';
export const MANAGEMENT_LOGIN_LOKET_SUCCESS = 'MANAGEMENT_LOGIN_LOKET_SUCCESS';
export const MANAGEMENT_LOGIN_LOKET_FAILURE = 'MANAGEMENT_LOGIN_LOKET_FAILURE';
export const MANAGEMENT_SAGA_LINK_USER_TO_LOKET = 'MANAGEMENT_SAGA_LINK_USER_TO_LOKET';
export const MANAGEMENT_LINK_USER_TO_LOKET_REQUEST = 'MANAGEMENT_LINK_USER_TO_LOKET_REQUEST';
export const MANAGEMENT_LINK_USER_TO_LOKET_SUCCESS = 'MANAGEMENT_LINK_USER_TO_LOKET_SUCCESS';
export const MANAGEMENT_LINK_USER_TO_LOKET_FAILURE = 'MANAGEMENT_LINK_USER_TO_LOKET_FAILURE';
export const MANAGEMENT_SAGA_LINK_USERS_TO_LOKET = 'MANAGEMENT_SAGA_LINK_USERS_TO_LOKET';
export const MANAGEMENT_LINK_USERS_TO_LOKET_REQUEST = 'MANAGEMENT_LINK_USERS_TO_LOKET_REQUEST';
export const MANAGEMENT_LINK_USERS_TO_LOKET_SUCCESS = 'MANAGEMENT_LINK_USERS_TO_LOKET_SUCCESS';
export const MANAGEMENT_LINK_USERS_TO_LOKET_FAILURE = 'MANAGEMENT_LINK_USERS_TO_LOKET_FAILURE';
export const MANAGEMENT_SAGA_EXPORT_USERS_CSV = 'MANAGEMENT_SAGA_EXPORT_USERS_CSV';
export const MANAGEMENT_EXPORT_USERS_CSV_REQUEST = 'MANAGEMENT_EXPORT_USERS_CSV_REQUEST';
export const MANAGEMENT_EXPORT_USERS_CSV_SUCCESS = 'MANAGEMENT_EXPORT_USERS_CSV_SUCCESS';
export const MANAGEMENT_EXPORT_USERS_CSV_FAILURE = 'MANAGEMENT_EXPORT_USERS_CSV_FAILURE';
export const MANAGEMENT_SAGA_GET_USER_SHIFTS = 'MANAGEMENT_SAGA_GET_USER_SHIFTS';
export const MANAGEMENT_GET_USER_SHIFTS_REQUEST = 'MANAGEMENT_GET_USER_SHIFTS_REQUEST';
export const MANAGEMENT_GET_USER_SHIFTS_SUCCESS = 'MANAGEMENT_GET_USER_SHIFTS_SUCCESS';
export const MANAGEMENT_GET_USER_SHIFTS_FAILURE = 'MANAGEMENT_GET_USER_SHIFTS_FAILURE';
export const MANAGEMENT_SAGA_GET_PERMISSION_GROUPS = 'MANAGEMENT_SAGA_GET_PERMISSION_GROUPS';
export const MANAGEMENT_GET_PERMISSION_GROUPS_REQUEST = 'MANAGEMENT_GET_PERMISSION_GROUPS_REQUEST';
export const MANAGEMENT_GET_PERMISSION_GROUPS_SUCCESS = 'MANAGEMENT_GET_PERMISSION_GROUPS_SUCCESS';
export const MANAGEMENT_GET_PERMISSION_GROUPS_FAILURE = 'MANAGEMENT_GET_PERMISSION_GROUPS_FAILURE';
export const MANAGEMENT_SAGA_SET_USER_PLANNING_PREFERENCE = 'MANAGEMENT_SAGA_SET_USER_PLANNING_PREFERENCE';
export const MANAGEMENT_SET_USER_PLANNING_PREFERENCE_REQUEST = 'MANAGEMENT_SET_USER_PLANNING_PREFERENCE_REQUEST';
export const MANAGEMENT_SET_USER_PLANNING_PREFERENCE_SUCCESS = 'MANAGEMENT_SET_USER_PLANNING_PREFERENCE_SUCCESS';
export const MANAGEMENT_SET_USER_PLANNING_PREFERENCE_FAILURE = 'MANAGEMENT_SET_USER_PLANNING_PREFERENCE_FAILURE';
export const MANAGEMENT_SAGA_DEL_USER_PLANNING_PREFERENCE = 'MANAGEMENT_SAGA_DEL_USER_PLANNING_PREFERENCE';
export const MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_REQUEST = 'MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_REQUEST';
export const MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_SUCCESS = 'MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_SUCCESS';
export const MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_FAILURE = 'MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_FAILURE';
export const MANAGEMENT_SAGA_ADD_USER_NOTE = 'MANAGEMENT_SAGA_ADD_USER_NOTE';
export const MANAGEMENT_ADD_USER_NOTE_REQUEST = 'MANAGEMENT_ADD_USER_NOTE_REQUEST';
export const MANAGEMENT_ADD_USER_NOTE_SUCCESS = 'MANAGEMENT_ADD_USER_NOTE_SUCCESS';
export const MANAGEMENT_ADD_USER_NOTE_FAILURE = 'MANAGEMENT_ADD_USER_NOTE_FAILURE';
export const MANAGEMENT_SAGA_EDIT_USER_NOTE = 'MANAGEMENT_SAGA_EDIT_USER_NOTE';
export const MANAGEMENT_EDIT_USER_NOTE_REQUEST = 'MANAGEMENT_EDIT_USER_NOTE_REQUEST';
export const MANAGEMENT_EDIT_USER_NOTE_SUCCESS = 'MANAGEMENT_EDIT_USER_NOTE_SUCCESS';
export const MANAGEMENT_EDIT_USER_NOTE_FAILURE = 'MANAGEMENT_EDIT_USER_NOTE_FAILURE';
export const MANAGEMENT_SAGA_DELETE_USER_NOTE = 'MANAGEMENT_SAGA_DELETE_USER_NOTE';
export const MANAGEMENT_DELETE_USER_NOTE_REQUEST = 'MANAGEMENT_DELETE_USER_NOTE_REQUEST';
export const MANAGEMENT_DELETE_USER_NOTE_SUCCESS = 'MANAGEMENT_DELETE_USER_NOTE_SUCCESS';
export const MANAGEMENT_DELETE_USER_NOTE_FAILURE = 'MANAGEMENT_DELETE_USER_NOTE_FAILURE';
export const MANAGEMENT_SAGA_GET_COMMENT_CATEGORIES = 'MANAGEMENT_SAGA_GET_COMMENT_CATEGORIES';
export const MANAGEMENT_GET_COMMENT_CATEGORIES_REQUEST = 'MANAGEMENT_GET_COMMENT_CATEGORIES_REQUEST';
export const MANAGEMENT_GET_COMMENT_CATEGORIES_SUCCESS = 'MANAGEMENT_GET_COMMENT_CATEGORIES_SUCCESS';
export const MANAGEMENT_GET_COMMENT_CATEGORIES_FAILURE = 'MANAGEMENT_GET_COMMENT_CATEGORIES_FAILURE';

export const MANAGEMENT_GET_USERS_REQUEST = 'MANAGEMENT_GET_USERS_REQUEST';
export const MANAGEMENT_REQUEST = 'MANAGEMENT_REQUEST';
export const MANAGEMENT_FAILURE = 'MANAGEMENT_FAILURE';
export const MANAGEMENT_SUCCESS = 'MANAGEMENT_SUCCESS';

// Settings
export const SETTINGS_SAGA_SET_PERIOD = 'SETTINGS_SAGA_SET_PERIOD';
export const SETTINGS_SET_PERIOD_REQUEST = 'SETTINGS_SET_PERIOD_REQUEST';
export const SETTINGS_SET_PERIOD_SUCCESS = 'SETTINGS_SET_PERIOD_SUCCESS';
export const SETTINGS_SET_PERIOD_FAILURE = 'SETTINGS_SET_PERIOD_FAILURE';
export const SETTINGS_SAGA_DELETE_PERIOD = 'SETTINGS_SAGA_DELETE_PERIOD';
export const SETTINGS_DELETE_PERIOD_REQUEST = 'SETTINGS_DELETE_PERIOD_REQUEST';
export const SETTINGS_DELETE_PERIOD_SUCCESS = 'SETTINGS_DELETE_PERIOD_SUCCESS';
export const SETTINGS_DELETE_PERIOD_FAILURE = 'SETTINGS_DELETE_PERIOD_FAILURE';
export const SETTINGS_SAGA_GET_SETTINGS = 'SETTINGS_SAGA_GET_SETTINGS';
export const SETTINGS_GET_SETTINGS_REQUEST = 'SETTINGS_GET_SETTINGS_REQUEST';
export const SETTINGS_GET_SETTINGS_SUCCESS = 'SETTINGS_GET_SETTINGS_SUCCESS';
export const SETTINGS_GET_SETTINGS_FAILURE = 'SETTINGS_GET_SETTINGS_FAILURE';
export const SETTINGS_SAGA_SET_SETTINGS = 'SETTINGS_SAGA_SET_SETTINGS';
export const SETTINGS_SET_SETTINGS_REQUEST = 'SETTINGS_SET_SETTINGS_REQUEST';
export const SETTINGS_SET_SETTINGS_SUCCESS = 'SETTINGS_SET_SETTINGS_SUCCESS';
export const SETTINGS_SET_SETTINGS_FAILURE = 'SETTINGS_SET_SETTINGS_FAILURE';

// Absences
export const ABSENCES_SAGA_GET_EXCHANGE_REQUESTS = 'ABSENCES_SAGA_GET_ABSENCE_REQUESTS';
export const ABSENCES_GET_EXCHANGE_REQUESTS_REQUEST = 'ABSENCES_GET_ABSENCE_REQUESTS_REQUEST';
export const ABSENCES_GET_EXCHANGE_REQUESTS_SUCCESS = 'ABSENCES_GET_ABSENCE_REQUESTS_SUCCESS';
export const ABSENCES_GET_EXCHANGE_REQUESTS_FAILURE = 'ABSENCES_GET_ABSENCE_REQUESTS_FAILURE';
export const ABSENCES_SAGA_APPROVE_EXCHANGE_REQUEST = 'ABSENCES_SAGA_APPROVE_EXCHANGE_REQUEST';
export const ABSENCES_APPROVE_EXCHANGE_REQUEST_REQUEST = 'ABSENCES_APPROVE_EXCHANGE_REQUEST_REQUEST';
export const ABSENCES_APPROVE_EXCHANGE_REQUEST_SUCCESS = 'ABSENCES_APPROVE_EXCHANGE_REQUEST_SUCCESS';
export const ABSENCES_APPROVE_EXCHANGE_REQUEST_FAILURE = 'ABSENCES_APPROVE_EXCHANGE_REQUEST_FAILURE';
export const ABSENCES_SAGA_DECLINE_EXCHANGE_REQUEST = 'ABSENCES_SAGA_DECLINE_EXCHANGE_REQUEST';
export const ABSENCES_DECLINE_EXCHANGE_REQUEST_REQUEST = 'ABSENCES_DECLINE_EXCHANGE_REQUEST_REQUEST';
export const ABSENCES_DECLINE_EXCHANGE_REQUEST_SUCCESS = 'ABSENCES_DECLINE_EXCHANGE_REQUEST_SUCCESS';
export const ABSENCES_DECLINE_EXCHANGE_REQUEST_FAILURE = 'ABSENCES_DECLINE_EXCHANGE_REQUEST_FAILURE';

export const ABSENCES_SAGA_APPROVE_SHIFT_EXCHANGE = 'ABSENCES_SAGA_APPROVE_SHIFT_EXCHANGE';
export const ABSENCES_APPROVE_SHIFT_EXCHANGE_REQUEST = 'ABSENCES_APPROVE_SHIFT_EXCHANGE_REQUEST';
export const ABSENCES_APPROVE_SHIFT_EXCHANGE_SUCCESS = 'ABSENCES_APPROVE_SHIFT_EXCHANGE_SUCCESS';
export const ABSENCES_APPROVE_SHIFT_EXCHANGE_FAILURE = 'ABSENCES_APPROVE_SHIFT_EXCHANGE_FAILURE';
export const ABSENCES_SAGA_DECLINE_SHIFT_EXCHANGE = 'ABSENCES_SAGA_DECLINE_SHIFT_EXCHANGE';
export const ABSENCES_DECLINE_SHIFT_EXCHANGE_REQUEST = 'ABSENCES_DECLINE_SHIFT_EXCHANGE_REQUEST';
export const ABSENCES_DECLINE_SHIFT_EXCHANGE_SUCCESS = 'ABSENCES_DECLINE_SHIFT_EXCHANGE_SUCCESS';
export const ABSENCES_DECLINE_SHIFT_EXCHANGE_FAILURE = 'ABSENCES_DECLINE_SHIFT_EXCHANGE_FAILURE';

export const ABSENCES_SAGA_DECLINE_LEAVE_OF_ABSENCE = 'ABSENCES_SAGA_DECLINE_LEAVE_OF_ABSENCE';
export const ABSENCES_SAGA_ACCEPT_LEAVE_OF_ABSENCE = 'ABSENCES_SAGA_ACCEPT_LEAVE_OF_ABSENCE';
export const ABSENCES_SAGA_EDIT_LEAVE_OF_ABSENCE = 'ABSENCES_SAGA_EDIT_LEAVE_OF_ABSENCE';
export const ABSENCES_HANDLE_LEAVE_OF_ABSENCE_REQUEST = 'ABSENCES_HANDLE_LEAVE_OF_ABSENCE_REQUEST';
export const ABSENCES_HANDLE_LEAVE_OF_ABSENCE_SUCCESS = 'ABSENCES_HANDLE_LEAVE_OF_ABSENCE_SUCCESS';
export const ABSENCES_HANDLE_LEAVE_OF_ABSENCE_FAILURE = 'ABSENCES_HANDLE_LEAVE_OF_ABSENCE_FAILURE';
export const ABSENCES_SAGA_GET_ABSENCES = 'ABSENCES_SAGA_GET_ABSENCES';
export const ABSENCES_GET_ABSENCES_REQUEST = 'ABSENCES_GET_ABSENCES_REQUEST';
export const ABSENCES_GET_ABSENCES_SUCCESS = 'ABSENCES_GET_ABSENCES_SUCCESS';
export const ABSENCES_GET_ABSENCES_FAILURE = 'ABSENCES_GET_ABSENCES_FAILURE';
export const ABSENCES_SAGA_GET_ABSENCES_IN_DATE_RANGE = 'ABSENCES_SAGA_GET_ABSENCES_IN_DATE_RANGE';
export const ABSENCES_GET_ABSENCES_IN_DATE_RANGE_REQUEST = 'ABSENCES_GET_ABSENCES_IN_DATE_RANGE_REQUEST';
export const ABSENCES_GET_ABSENCES_IN_DATE_RANGE_SUCCESS = 'ABSENCES_GET_ABSENCES_IN_DATE_RANGE_SUCCESS';
export const ABSENCES_GET_ABSENCES_IN_DATE_RANGE_FAILURE = 'ABSENCES_GET_ABSENCES_IN_DATE_RANGE_FAILURE';
export const ABSENCES_SAGA_GET_ALL_ABSENCES_IN_DATE_RANGE = 'ABSENCES_SAGA_GET_ALL_ABSENCES_IN_DATE_RANGE';
export const ABSENCES_SAGA_GET_ALL_LEAVE_OF_ABSENCES_IN_DATE_RANGE = 'ABSENCES_SAGA_GET_ALL_LEAVE_OF_ABSENCES_IN_DATE_RANGE';
export const ABSENCES_SAGA_RESOLVE_ABSENCE = 'ABSENCES_SAGA_RESOLVE_ABSENCE';
export const ABSENCES_SAGA_EDIT_ABSENCE = 'ABSENCES_SAGA_EDIT_ABSENCE';
export const ABSENCES_EDIT_ABSENCE_REQUEST = 'ABSENCES_EDIT_ABSENCE_REQUEST';
export const ABSENCES_EDIT_ABSENCE_FAILURE = 'ABSENCES_EDIT_ABSENCE_FAILURE';
export const ABSENCES_EDIT_ABSENCE_SUCCESS = 'ABSENCES_EDIT_ABSENCE_SUCCESS';
export const ABSENCES_SAGA_DELETE_ABSENCE = 'ABSENCES_SAGA_DELETE_ABSENCE';
export const ABSENCES_DELETE_ABSENCE_REQUEST = 'ABSENCES_DELETE_ABSENCE_REQUEST';
export const ABSENCES_DELETE_ABSENCE_FAILURE = 'ABSENCES_DELETE_ABSENCE_FAILURE';
export const ABSENCES_DELETE_ABSENCE_SUCCESS = 'ABSENCES_DELETE_ABSENCE_SUCCESS';
export const ABSENCES_SAGA_ADD_ABSENCE = 'ABSENCES_SAGA_ADD_ABSENCE';
export const ABSENCES_ADD_ABSENCE_REQUEST = 'ABSENCES_ADD_ABSENCE_REQUEST';
export const ABSENCES_ADD_ABSENCE_SUCCESS = 'ABSENCES_ADD_ABSENCE_SUCCESS';
export const ABSENCES_ADD_ABSENCE_FAILURE = 'ABSENCES_ADD_ABSENCE_FAILURE';
export const ABSENCES_SAGA_REPLACE_USER_IN_SHIFT = 'ABSENCES_SAGA_REPLACE_USER_IN_SHIFT';
export const ABSENCES_REPLACE_USER_IN_SHIFT_REQUEST = 'ABSENCES_REPLACE_USER_IN_SHIFT_REQUEST';
export const ABSENCES_REPLACE_USER_IN_SHIFT_SUCCESS = 'ABSENCES_REPLACE_USER_IN_SHIFT_SUCCESS';
export const ABSENCES_REPLACE_USER_IN_SHIFT_FAILURE = 'ABSENCES_REPLACE_USER_IN_SHIFT_FAILURE';
export const ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE_REQUESTS = 'ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE_REQUESTS';
export const ABSENCES_GET_LEAVE_OF_ABSENCE_REQUESTS_REQUEST = 'ABSENCES_GET_LEAVE_OF_ABSENCE_REQUESTS_REQUEST';
export const ABSENCES_GET_OPEN_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS = 'ABSENCES_GET_OPEN_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS';
export const ABSENCES_GET_CALENDAR_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS = 'ABSENCES_GET_CALENDAR_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS';
export const ABSENCES_GET_LEAVE_OF_ABSENCE_REQUESTS_FAILURE = 'ABSENCES_GET_LEAVE_OF_ABSENCE_REQUESTS_FAILURE';
export const ABSENCES_GET_LOA_LEAVE_BALANCES_REQUEST = 'ABSENCES_GET_LOA_LEAVE_BALANCES_REQUEST';
export const ABSENCES_GET_LOA_LEAVE_BALANCES_SUCCESS = 'ABSENCES_GET_LOA_LEAVE_BALANCES_SUCCESS';
export const ABSENCES_GET_LOA_LEAVE_BALANCES_FAILURE = 'ABSENCES_GET_LOA_LEAVE_BALANCES_FAILURE';
export const ABSENCES_SAGA_ADD_LEAVE_OF_ABSENCE_REQUEST = 'ABSENCES_SAGA_ADD_LEAVE_OF_ABSENCE_REQUEST';
export const ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_REQUEST = 'ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_REQUEST';
export const ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_SUCCESS = 'ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_SUCCESS';
export const ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_FAILURE = 'ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_FAILURE';
export const ABSENCES_SAGA_EXPORT_CSV = 'ABSENCES_SAGA_EXPORT_CSV';
export const ABSENCES_EXPORT_CSV_REQUEST = 'ABSENCES_EXPORT_CSV_REQUEST';
export const ABSENCES_EXPORT_CSV_SUCCESS = 'ABSENCES_EXPORT_CSV_SUCCESS';
export const ABSENCES_EXPORT_CSV_FAILURE = 'ABSENCES_EXPORT_CSV_FAILURE';
export const ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_REQUEST = 'ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_REQUEST';
export const ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_SUCCESS = 'ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_SUCCESS';
export const ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_FAILURE = 'ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_FAILURE';
export const ABSENCES_SAGA_SYNC_LEAVE_OF_ABSENCES_TO_LOKET = 'ABSENCES_SAGA_SYNC_LEAVE_OF_ABSENCES_TO_LOKET';
export const ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_REQUEST = 'ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_REQUEST';
export const ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_SUCCESS = 'ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_SUCCESS';
export const ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_FAILURE = 'ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_FAILURE';

// Logs
export const LOGS_SAGA_GET_DELETED_LOGS = 'LOGS_SAGA_GET_DELETED_LOGS';
export const LOGS_GET_DELETED_LOGS_REQUEST = 'LOGS_GET_DELETED_LOGS_REQUEST';
export const LOGS_GET_DELETED_LOGS_SUCCESS = 'LOGS_GET_DELETED_LOGS_SUCCESS';
export const LOGS_GET_DELETED_LOGS_FAILURE = 'LOGS_GET_DELETED_LOGS_FAILURE';
export const LOGS_SAGA_GET_RESOURCE_LOGS = 'LOGS_SAGA_GET_RESOURCE_LOGS';
export const LOGS_GET_RESOURCE_LOGS_REQUEST = 'LOGS_GET_RESOURCE_LOGS_REQUEST';
export const LOGS_GET_RESOURCE_LOGS_SUCCESS = 'LOGS_GET_RESOURCE_LOGS_SUCCESS';
export const LOGS_GET_RESOURCE_LOGS_FAILURE = 'LOGS_GET_RESOURCE_LOGS_FAILURE';
export const LOGS_SAGA_GET_LOKET_LOGS = 'LOGS_SAGA_GET_LOKET_LOGS';
export const LOGS_GET_LOKET_LOGS_REQUEST = 'LOGS_GET_LOKET_LOGS_REQUEST';
export const LOGS_GET_LOKET_LOGS_SUCCESS = 'LOGS_GET_LOKET_LOGS_SUCCESS';
export const LOGS_GET_LOKET_LOGS_FAILURE = 'LOGS_GET_LOKET_LOGS_FAILURE';
export const LOGS_SAGA_CLEAR_RESOURCE_LOGS = 'LOGS_SAGA_CLEAR_RESOURCE_LOGS';
export const LOGS_CLEAR_RESOURCE_LOGS = 'LOGS_CLEAR_RESOURCE_LOGS';

// PZ
export const PZ_SAGA_GET_DOCUMENT_TYPES = 'PZ_SAGA_GET_DOCUMENT_TYPES';
export const PZ_GET_DOCUMENT_TYPES_REQUEST = 'PZ_GET_DOCUMENT_TYPES_REQUEST';
export const PZ_GET_DOCUMENT_TYPES_SUCCESS = 'PZ_GET_DOCUMENT_TYPES_SUCCESS';
export const PZ_GET_DOCUMENT_TYPES_FAILURE = 'PZ_GET_DOCUMENT_TYPES_FAILURE';
export const PZ_SAGA_GET_DOCUMENTS = 'PZ_SAGA_GET_DOCUMENTS';
export const PZ_GET_DOCUMENTS_REQUEST = 'PZ_GET_DOCUMENTS_REQUEST';
export const PZ_GET_DOCUMENTS_SUCCESS = 'PZ_GET_DOCUMENTS_SUCCESS';
export const PZ_GET_DOCUMENTS_FAILURE = 'PZ_GET_DOCUMENTS_FAILURE';
export const PZ_SAGA_ADD_DOCUMENT = 'PZ_SAGA_ADD_DOCUMENT';
export const PZ_ADD_DOCUMENT_REQUEST = 'PZ_ADD_DOCUMENT_REQUEST';
export const PZ_ADD_DOCUMENT_SUCCESS = 'PZ_ADD_DOCUMENT_SUCCESS';
export const PZ_ADD_DOCUMENT_FAILURE = 'PZ_ADD_DOCUMENT_FAILURE';
export const PZ_SAGA_EDIT_DOCUMENT = 'PZ_SAGA_EDIT_DOCUMENT';
export const PZ_EDIT_DOCUMENT_REQUEST = 'PZ_EDIT_DOCUMENT_REQUEST';
export const PZ_EDIT_DOCUMENT_SUCCESS = 'PZ_EDIT_DOCUMENT_SUCCESS';
export const PZ_EDIT_DOCUMENT_FAILURE = 'PZ_EDIT_DOCUMENT_FAILURE';
export const PZ_SAGA_DELETE_DOCUMENT = 'PZ_SAGA_DELETE_DOCUMENT';
export const PZ_DELETE_DOCUMENT_REQUEST = 'PZ_DELETE_DOCUMENT_REQUEST';
export const PZ_DELETE_DOCUMENT_SUCCESS = 'PZ_DELETE_DOCUMENT_SUCCESS';
export const PZ_DELETE_DOCUMENT_FAILURE = 'PZ_DELETE_DOCUMENT_FAILURE';
export const PZ_SAGA_GET_LOKET_INFO = 'PZ_SAGA_GET_LOKET_INFO';
export const PZ_GET_LOKET_INFO_REQUEST = 'PZ_GET_LOKET_INFO_REQUEST';
export const PZ_GET_LOKET_INFO_SUCCESS = 'PZ_GET_LOKET_INFO_SUCCESS';
export const PZ_GET_LOKET_INFO_FAILURE = 'PZ_GET_LOKET_INFO_FAILURE';
