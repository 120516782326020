import React, { FC } from 'react';

import Tooltip from 'react-tooltip-lite';
import { Button } from 'reactstrap';

import { Button as PacoButton } from '../../../../@paco/components';
import { CalendarTypeRadioList } from '../../../../@paco/compositions';
import { CalendarTypeFormOption } from '../../../../@paco/compositions/CalendarNavRadioList/CalendarTypeRadioList';
import { ConnectedHelpTooltip } from '../../../../@paco/connectors';
import trans from '../../../../@paco/helpers/trans';
import { TimeModeType } from '../../../../@paco/types';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import Icon from '../../../../components/style/Icon/Icon';
import { translate } from '../../../../helpers/translations/translator';
import { PayrollPeriodViewModel } from '../../../../models';
import CalendarToolbar from '../CalendarToolbar/CalendarToolbar';

import './CalendarAndConceptsToolbar.scss';

interface CalendarAndConceptsToolbarProps {
    canViewShiftConcepts: boolean;
    onCalendarTypeChange: () => void;
    setStartAndEndDate: (selectedDate: Date, startDate: Date, endDate: Date) => void;
    afterDateChange?: () => void;
    onNotifyButtonClick?: () => void;
    onExportButtonClick?: () => void;
    onPublishButtonClick?: () => void;
    onDeleteButtonClick?: () => void;
    onSearchBarChange: (employeeSearch: string) => void;
    selectedCalendarType: CalendarTypeFormOption;
    deleteButtonId?: string;
    deleteButtonDisabled?: boolean;
    exportCSVRequest?: boolean;
    employeeSearch: string;
    timeMode: TimeModeType;
    payrollPeriods: PayrollPeriodViewModel[];
    className?: string;
}

const CalendarAndConceptsToolbar: FC<CalendarAndConceptsToolbarProps> = ({
    canViewShiftConcepts,
    onCalendarTypeChange,
    setStartAndEndDate,
    afterDateChange,
    onNotifyButtonClick,
    onExportButtonClick,
    onPublishButtonClick,
    onDeleteButtonClick,
    onSearchBarChange,
    selectedCalendarType,
    exportCSVRequest = false,
    deleteButtonId,
    deleteButtonDisabled = false,
    employeeSearch,
    timeMode,
    payrollPeriods,
    className = '',
}) => {
    const onDeleteButtonDragOver = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    const handleTodayButtonClick = (): void => {
        setStartAndEndDate(new Date(), new Date(), new Date());
    };

    return (
        <div className={`calendar-concepts-toolbar ${className}`}>
            <div className="calendar-concepts-toolbar__wrapper">
                <div className="calendar-concepts-toolbar__nav-buttons-wrapper">
                    {canViewShiftConcepts && (
                        <CalendarTypeRadioList
                            className="calendar-concepts-toolbar__nav-buttons"
                            onChange={onCalendarTypeChange}
                            selectedCalendarType={selectedCalendarType}
                        />
                    )}
                    <PacoButton
                        text={translate('common.toToday')}
                        onClick={handleTodayButtonClick}
                        className="calendar-concepts-toolbar__mobile-today-button"
                    />
                </div>
                <CalendarToolbar
                    className="calendar-concepts-toolbar__calendar-toolbar"
                    timeMode={timeMode}
                    payrollPeriods={payrollPeriods}
                    setStartAndEndDate={setStartAndEndDate}
                    afterDateChange={afterDateChange}
                />
                {onNotifyButtonClick && (
                    <button type="button" className="calendar-concepts-toolbar__notify-button" onClick={onNotifyButtonClick}>
                        <Icon color="white" kind="notifications" />
                        <span>{translate('pages.shifts.notifyEmployees')}</span>
                    </button>
                )}
                <div className="calendar-concepts-toolbar__buttons-wrapper">
                    {!!deleteButtonId && (
                        <div className="calendar-concepts-toolbar__delete-button-tooltip-wrapper">
                            {/* @ts-ignore */}
                            <Tooltip
                                forceDirection
                                direction="up"
                                className="calendar-concepts-toolbar__delete-button-tooltip"
                                content={translate('pages.shifts.deleteSelectedShifts')}
                                isOpen={!deleteButtonDisabled}
                                distance={10}
                                arrowSize={6}
                                padding={7}
                            >
                                <button
                                    type="button"
                                    id={deleteButtonId}
                                    disabled={deleteButtonDisabled}
                                    className="calendar-concepts-toolbar__delete-button"
                                    onDragOver={onDeleteButtonDragOver}
                                    onClick={onDeleteButtonClick}
                                >
                                    <Icon kind="trash" className="calendar-concepts-toolbar__delete-button-icon" />
                                </button>
                            </Tooltip>
                            <ConnectedHelpTooltip
                                index={4}
                                videoSrc="/help/delete-concept-shift.webm"
                                route="calendar"
                                subTitle={trans('help.conceptsCalendar.removeConceptShift.title')}
                                text={trans('help.conceptsCalendar.removeConceptShift.text')}
                                title={trans('help.conceptsCalendar.title')}
                                showMobileInfoWarning
                                className="calendar-concepts-toolbar__delete-button-help-tooltip"
                            />
                        </div>
                    )}
                    {onExportButtonClick && (
                        <Button
                            color="orange"
                            disabled={exportCSVRequest}
                            onClick={onExportButtonClick}
                            className="button-export calendar-concepts-toolbar__export-button"
                        >
                            <Icon color="white" kind="arrow_downward" />
                            <span>{translate('common.export')}</span>
                        </Button>
                    )}
                    {onPublishButtonClick && (
                        <div className="calendar-concepts-toolbar__publish-button-wrapper">
                            <Button
                                color="orange"
                                onClick={onPublishButtonClick}
                                className="button-export calendar-concepts-toolbar__publish-button"
                            >
                                <Icon color="white" kind="file_upload" />
                                <span>{translate('pages.shifts.publish')}</span>
                            </Button>
                            <ConnectedHelpTooltip
                                index={5}
                                route="calendar"
                                subTitle={trans('help.conceptsCalendar.publishConceptShifts.title')}
                                text={trans('help.conceptsCalendar.publishConceptShifts.text')}
                                title={trans('help.conceptsCalendar.title')}
                                className="calendar-concepts-toolbar__publish-button-help-tooltip"
                            />
                        </div>
                    )}
                    <SearchBar
                        className="calendar-concepts-toolbar__search-bar"
                        placeholder={translate('common.searchEmployee')}
                        startVal={employeeSearch}
                        onChange={onSearchBarChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default CalendarAndConceptsToolbar;
