import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import { addDays, compareAsc, isSameDay } from '../../helpers/date';
import UpToAndIncludingDate from '../../helpers/date/UpToAndIncludingDate';
import {
    DragState,
    ShiftDraggable,
    ShiftPlanningViewModel,
    ShiftViewModel,
} from '../../models';
import doesShiftFallOnWorkDay from '../ShiftService/doesShiftFallOnWorkDay';
import transformShiftToShiftDraggable from './transformShiftToShiftDraggable';

function transformSelectedDaysToMappedShiftIds(
    shifts: (ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel)[],
    selectedDays: Date[],
): string[][] {
    return selectedDays
        .map((day) => shifts
            .filter((shift) => doesShiftFallOnWorkDay(shift, day))
            .map((shift) => shift.id));
}

function getShiftDayIndex(mappedShiftIds: string[][], shiftId: string): number {
    return mappedShiftIds.findIndex(shiftIds => shiftIds.find((id) => shiftId === id));
}

function transposeShiftPlanningDates(
    shiftPlanning: ShiftPlanningViewModel,
    daysToAdd: number,
): ShiftPlanningViewModel {
    return {
        ...shiftPlanning,
        start: addDays(shiftPlanning.start, daysToAdd),
        end: new UpToAndIncludingDate(addDays(shiftPlanning.end.date, daysToAdd)),
    };
}

function transposeShiftDates(
    shift: ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel,
    daysToAdd: number,
): ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel {
    const shiftPlannings = shift.shiftPlannings
        .map((shiftPlanning) => transposeShiftPlanningDates(shiftPlanning, daysToAdd));

    return {
        ...shift,
        shiftPlannings,
        start: addDays(shift.start, daysToAdd),
        end: new UpToAndIncludingDate(addDays(shift.end.date, daysToAdd)),
    };
}

export default function transformShiftsToTransposedShiftDraggable(
    draggingShiftId: string | null,
    shifts: (ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel)[],
    targetDate: Date | null,
    selectedDays: Date[],
    today: Date,
): [ShiftDraggable[], number] {
    if (!shifts.length || !targetDate || !draggingShiftId) {
        return [[], 0];
    }

    const mappedShiftIds = transformSelectedDaysToMappedShiftIds(shifts, selectedDays);

    const sortedShiftsByDate = shifts.sort((a, b) => compareAsc(a.start, b.start));
    const originShift = shifts[0];
    const earliestShift = sortedShiftsByDate[0];
    const latestShift = sortedShiftsByDate[sortedShiftsByDate.length - 1];

    const originDayIndex = getShiftDayIndex(mappedShiftIds, originShift.id);
    const firstShiftDayIndex = getShiftDayIndex(mappedShiftIds, earliestShift.id);
    const lastShiftIndex = getShiftDayIndex(mappedShiftIds, latestShift.id);
    const lastSelectedShiftIndex = getShiftDayIndex(mappedShiftIds, draggingShiftId);
    const todayDateIndex = selectedDays.findIndex(date => isSameDay(date, today));
    const targetDateIndex = selectedDays.findIndex(date => date === targetDate);
    const justifiedTargetDateIndex = Math.max(todayDateIndex, targetDateIndex);

    const offsetRelativeToFirst = firstShiftDayIndex - lastSelectedShiftIndex;
    const rowsToTranspose = Math.floor(justifiedTargetDateIndex / 7) - Math.floor(firstShiftDayIndex / 7);
    const daysToTranspose = targetDateIndex - originDayIndex;

    const maxDaysToTransposeLeft = -firstShiftDayIndex % 7;
    const maxDaysToTransposeRight = 6 - (lastShiftIndex % 7);

    const justifiedDaysToTranspose = Math.max(Math.min(daysToTranspose + offsetRelativeToFirst - (rowsToTranspose * 7), maxDaysToTransposeRight), maxDaysToTransposeLeft) + (rowsToTranspose * 7);

    if (justifiedDaysToTranspose === 0) {
        return [[], 0];
    }

    const transposedShifts = shifts.map(
        (shift: ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel) => transposeShiftDates(shift, justifiedDaysToTranspose),
    );

    return [transposedShifts
        .filter(shift => compareAsc(shift.start, today) === 1)
        .map(shift => transformShiftToShiftDraggable(shift, DragState.preview)), justifiedDaysToTranspose];
}
