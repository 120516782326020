import { PayrollPeriod } from '../PayrollPeriod/PayrollPeriod';
import { Period } from '../Period/Period';
import { doPeriodsOverlap } from '../Period/PeriodHelpers';
import { PayrollPeriodWeek, PayrollPeriodWeekResource } from './PayrollPeriodWeek';

export const isPayrollPeriodWeekResource = (resource: any): resource is PayrollPeriodWeekResource => (
    typeof resource === 'object'
    && resource !== null
    && 'id' in resource
    && 'isLocked' in resource
    && 'start' in resource
    && 'end' in resource
    && 'payrollPeriodUuid' in resource
);

export const getOverlappingPayrollPeriodWeeks = (period: Period, payrollPeriods: PayrollPeriod[]): PayrollPeriodWeek[] => {
    const overlappingPayrollPeriods = payrollPeriods.filter(payrollPeriod => doPeriodsOverlap(period, payrollPeriod.period));

    const weeks = overlappingPayrollPeriods.flatMap(payrollPeriod => payrollPeriod.weeks);

    return weeks.filter(week => doPeriodsOverlap(period, week.period));
};
