import React, { FC } from 'react';

import { LoadingSpinner } from '../../../../../../@paco/components';
import { ShiftConceptViewModelOld } from '../../../../../../entities/ShiftConcept/ShiftConcept';
import { translate } from '../../../../../../helpers/translations/translator';
import ShiftConceptButton from '../ShiftConceptButton/ShiftConceptButton';

import './ShiftConceptButtonsList.scss';

interface ShiftConceptButtonsListProps {
    loading: boolean;
    shiftConcepts: ShiftConceptViewModelOld[];
    onShiftConceptClick: (activeShiftConcept: ShiftConceptViewModelOld) => void;
    activeShiftConcept: ShiftConceptViewModelOld | null;
}

const ShiftConceptButtonsList: FC<ShiftConceptButtonsListProps> = ({
    loading,
    shiftConcepts,
    onShiftConceptClick,
    activeShiftConcept,
}) => {
    if (loading) {
        return (
            <div className="shift-concept-buttons-list shift-buttons-list--loading">
                <LoadingSpinner
                    isSmall
                    className="shift-concept-buttons-list__loading-spinner"
                />
            </div>
        );
    }

    if (!shiftConcepts.length) {
        return (
            <div className="shift-concept-buttons-list">
                {translate('pages.shifts.noShiftsFound')}
            </div>
        );
    }

    return (
        <div className="shift-concept-buttons-list">
            {shiftConcepts.map((shiftConcept) => (
                <ShiftConceptButton
                    key={shiftConcept.id}
                    shiftConcept={shiftConcept}
                    onClick={onShiftConceptClick}
                    active={activeShiftConcept === shiftConcept}
                />
            ))}
        </div>
    );
};

export default ShiftConceptButtonsList;
