import * as Sentry from '@sentry/browser';

import { capitalizeFirstLetter } from '../../../../helpers';
import {
    formatDate,
    setMonth,
    setWeek,
    setYear,
    startOfMonth,
    startOfYear,
} from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { SelectOption, TimeModeType } from '../../../types';
import { DateFormat } from '../../../types/dateFormatTypes';

const inputDateFormats: Record<TimeModeType, DateFormat> = {
    [TimeModeType.date]: DateFormat.inputDate,
    [TimeModeType.week]: DateFormat.inputWeek,
    [TimeModeType.month]: DateFormat.inputMonth,
    [TimeModeType.year]: DateFormat.inputDate,
    [TimeModeType.period]: DateFormat.inputDate,
    [TimeModeType.custom]: DateFormat.inputDate,
};

export const getCalendarNavigationDateInputLabel = (selectedDays: Date[], timeMode?: TimeModeType, dateFormat?: DateFormat): string => {
    if (!selectedDays.length) {
        return trans('compositions.calendarNavigation.selectDate');
    }

    if (selectedDays.length === 1) {
        return capitalizeFirstLetter(formatDate(selectedDays[0], dateFormat || DateFormat.datePickerLabel));
    }

    const endDate = selectedDays[selectedDays.length - 1];
    const startDate = timeMode === TimeModeType.month ? startOfMonth(endDate) : selectedDays[0];
    const year = formatDate(selectedDays[0], DateFormat.year);
    const formattedStartDate = formatDate(startDate, dateFormat || DateFormat.datePickerDateLabel);
    const formattedEndDate = formatDate(endDate, dateFormat || DateFormat.datePickerDateLabel);

    return `${formattedStartDate} - ${formattedEndDate} ${year}`;
};

export const getTimeModeSelectOptions = (timeModes: TimeModeType[]): SelectOption[] => timeModes
    .map((option) => ({
        label: trans(`types.timeMode.${option}`),
        value: option,
    }));

export const transformWeekStringToDate = (weekDateString: string, now: Date): Date => {
    const [yearString, weekString] = weekDateString.split('-W');

    if (!yearString || !weekString) {
        console.error('[transformWeekStringToDate]: Invalid string. Returning now date');
        return now;
    }

    const year = parseInt(yearString, 10);
    const week = parseInt(weekString, 10);
    const firstDayOfYear = startOfYear(setYear(now, year));
    return setWeek(firstDayOfYear, week, { weekStartsOn: 1 });
};

export const transformMonthStringToDate = (monthDateString: string, now: Date): Date => {
    const [yearString, monthString] = monthDateString.split('-');

    if (!yearString || !monthString) {
        console.error('[transformWeekStringToDate]: Invalid string. Returning now date');
        return now;
    }

    const year = parseInt(yearString, 10);
    const month = parseInt(monthString, 10);
    const firstDayOfYear = startOfYear(setYear(now, year));
    return setMonth(firstDayOfYear, month - 1);
};

export const transformStringToDate = (value: string, now: Date): Date => {
    // Date format: 2022-01-01, 2022-12-31
    if (/\d{4}-\d{2}-\d{2}/gm.test(value)) {
        return new Date(value);
    }

    // Week format: 2022-W0, 2022-W11
    if (/\d{4}-[A-Z]\d{0,9}/gm.test(value)) {
        return transformWeekStringToDate(value, now);
    }

    // Month format: 2022-01, 2022-12
    if (/\d{4}-\d{2}/gm.test(value)) {
        return transformMonthStringToDate(value, now);
    }

    const error = '[transformStringToDate]: Invalid string. Returning now date';
    Sentry.captureMessage(error);
    console.error(error);

    return now;
};

export const transformDatesToInputValue = (selectedDays: Date[], timeMode: TimeModeType | undefined): string | undefined => {
    if (!selectedDays.length) {
        return undefined;
    }

    const date = timeMode === TimeModeType.month ? startOfMonth(selectedDays[selectedDays.length - 1]) : selectedDays[0];
    const format = timeMode ? inputDateFormats[timeMode] : DateFormat.inputDate;

    return formatDate(date, format);
};
