import { ShiftConceptPlanningResource } from '../../../../entities/ShiftConceptPlanning/ShiftConceptPlanning';
import {
    Absence,
    BasicUser,
    LeaveOfAbsenceResource,
    PreferToWorkTimeSlot,
    ShiftPlanning,
    UnavailableToWorkTimeSlot,
    UserWithConceptAvailability,
} from '../../../../models';

export default function transformListViewDataToUserWithConceptAvailability(
    user: BasicUser,
    absences: Absence[],
    leaveOfAbsences: LeaveOfAbsenceResource[],
    preferToWorkTimeSlots: PreferToWorkTimeSlot[],
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[],
    shiftConceptPlannings: ShiftConceptPlanningResource[],
    shiftPlannings?: ShiftPlanning[],
): UserWithConceptAvailability {
    return {
        ...user,
        absences: absences
            .filter(absence => absence.user && absence.user.id === user.id),
        leaveOfAbsences: leaveOfAbsences
            .filter(leaveOfAbsence => leaveOfAbsence.user && leaveOfAbsence.user.id === user.id),
        preferToWorkTimeSlots: preferToWorkTimeSlots
            .filter(preferToWorkTimeSlot => preferToWorkTimeSlot.user && preferToWorkTimeSlot.user.id === user.id),
        unavailableToWorkTimeSlots: unavailableToWorkTimeSlots
            .filter(unavailableToWorkTimeSlot => unavailableToWorkTimeSlot.user && unavailableToWorkTimeSlot.user.id === user.id),
        shiftConceptPlannings: shiftConceptPlannings
            .filter(shiftConceptPlanning => shiftConceptPlanning.user && shiftConceptPlanning.user.id === user.id),
        shiftPlannings: shiftPlannings && shiftPlannings
            .filter(shiftPlanning => shiftPlanning.user && shiftPlanning.user.id === user.id),
    };
}
