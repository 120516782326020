import React, { FC, useEffect, useState } from 'react';

import DayPicker from 'react-day-picker';

import { generateEventPath } from '../../../helpers';
import { eachDayOfInterval, formatDate } from '../../../helpers/date';
import { translate } from '../../../helpers/translations/translator';
import Icon from '../../style/Icon/Icon';

import './SingleDateNavigator.scss';

interface DayNavigatorProps {
    type: 'startDate' | 'endDate'
    startDate: Date,
    endDate: Date,
    title: string;
    className?: string;
    onChange: (date: Date) => void,
}

const SingleDateNavigator: FC<DayNavigatorProps> = ({
    startDate,
    endDate,
    type,
    title,
    onChange,
    className = '',
}) => {
    const [active, setActive] = useState(false);
    const selectedDate = type === 'startDate' ? startDate : endDate;
    const selectedDays = eachDayOfInterval(startDate, endDate);
    const navigatorDate = formatDate(selectedDate, 'EEEEEE d MMM yyyy');

    const months = [
        translate('common.months.jan'),
        translate('common.months.feb'),
        translate('common.months.mar'),
        translate('common.months.apr'),
        translate('common.months.may'),
        translate('common.months.jun'),
        translate('common.months.jul'),
        translate('common.months.aug'),
        translate('common.months.sep'),
        translate('common.months.oct'),
        translate('common.months.nov'),
        translate('common.months.dec'),
    ];

    const weekDays = [
        translate('common.weekDays.sun'),
        translate('common.weekDays.mon'),
        translate('common.weekDays.tue'),
        translate('common.weekDays.wed'),
        translate('common.weekDays.thu'),
        translate('common.weekDays.fri'),
        translate('common.weekDays.sat'),
    ];

    const weekDaysShort = weekDays.map((d => d.substr(0, 2)));

    const toggleDayPicker = () => {
        setActive(!active);
    };

    const onWindowClick = (e: MouseEvent) => {
        const elements = generateEventPath(e.target);
        const outside = !elements.find((element: HTMLElement) => element.id === title);
        if (outside) {
            setActive(false);
        }
    };

    const onDayClick = (date: Date) => {
        setActive(false);
        onChange(date);
    };

    useEffect(() => {
        window.addEventListener('click', onWindowClick);
        return () => {
            window.removeEventListener('click', onWindowClick);
        };
    });

    return (
        <div id={title} className={`date-controls single-date-navigator ${className}`}>
            <button type="button" onClick={toggleDayPicker} className="date-controls__button-picker single-date-navigator__button-picker">
                <span className="single-date-navigator__title">
                    {title}
                </span>
                <span className="single-date-navigator__date">
                    {navigatorDate}
                </span>
                <Icon className="single-date-navigator__arrow-down" color="primary" kind="keyboard_arrow_down" />
            </button>
            { active && (
                <div className="single-date-navigator__daypicker">
                    <DayPicker
                        locale="nl"
                        firstDayOfWeek={1}
                        weekdaysShort={weekDaysShort}
                        weekdaysLong={weekDays}
                        months={months}
                        month={selectedDate}
                        selectedDays={selectedDays}
                        showWeekNumbers
                        showOutsideDays
                        onDayClick={onDayClick}
                    />
                </div>
            )}
        </div>
    );
};

export default SingleDateNavigator;
