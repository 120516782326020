import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { CommentResource } from '../Comment/Comment';
import { transformToComment } from '../Comment/CommentTransformers';
import { FetchResult, FetchResultType } from '../FetchResult';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { ShiftResource } from '../Shift/Shift';
import { ShiftConceptResource } from '../ShiftConcept/ShiftConcept';
import { UserResource } from '../User/User';
import { EditTemporaryWorkerFormData, TemporaryWorker, TemporaryWorkerResource } from './TemporaryWorker';
import {
    transformAddTemporaryWorkerFormDataToRequest,
    transformEditColorTemporaryWorkerFormDataToRequest,
    transformEditTemporaryWorkerFormDataToRequest,
    transformToTemporaryWorker,
} from './TemporaryWorkerTransformers';

export const postTemporaryWorkerApiCall = async (shiftId: string, name: string): Promise<FetchResult<TemporaryWorker, string>> => {
    const request = transformAddTemporaryWorkerFormDataToRequest(shiftId, name);

    try {
        const url = generateApiUrl({
            endpoint: '/temporary-workers',
            queryParams: {
                include: 'shift',
            },
        });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: request }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<TemporaryWorkerResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const temporaryWorkerResource = doc.data;
        const commentsResource = (getMultipleIncluded(doc, temporaryWorkerResource, 'comments') || []) as CommentResource[];

        const comments = commentsResource.map(commentResource => {
            const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

            return transformToComment(commentResource, commentOwnerResource);
        });

        const shiftResource = getIncluded(doc, temporaryWorkerResource, 'shift') as ShiftResource;
        const shiftPeriod = transformToPeriod(new Date(shiftResource.attributes.start), new Date(shiftResource.attributes.end));

        const temporaryWorker = transformToTemporaryWorker(temporaryWorkerResource, shiftPeriod, comments);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: temporaryWorker,
        };
    } catch (error) {
        console.error('[postTemporaryConceptWorkerApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchTemporaryWorkerApiCall = async (
    temporaryWorkerId: string,
    formData: EditTemporaryWorkerFormData,
): Promise<FetchResult<TemporaryWorker, string>> => {
    const request = transformEditTemporaryWorkerFormDataToRequest(formData);

    try {
        const includes = ['comments', 'comments.owner'];
        const url = generateApiUrl({
            endpoint: `/temporary-workers/${temporaryWorkerId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: request }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<TemporaryWorkerResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const temporaryWorkerResource = doc.data;
        const commentsResource = (getMultipleIncluded(doc, temporaryWorkerResource, 'comments') || []) as CommentResource[];

        const comments = commentsResource.map(commentResource => {
            const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

            return transformToComment(commentResource, commentOwnerResource);
        });

        const temporaryWorker = transformToTemporaryWorker(temporaryWorkerResource, formData.period, comments);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: temporaryWorker,
        };
    } catch (error) {
        console.error('[patchTemporaryWorkerApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchTemporaryWorkerColorApiCall = async (
    temporaryWorkerId: string,
    color?: string,
): Promise<FetchResult<TemporaryWorker, string>> => {
    const temporaryConceptWorkerRequest = transformEditColorTemporaryWorkerFormDataToRequest(color);

    try {
        const includes = ['shift', 'comments', 'comments.owner'];
        const url = generateApiUrl({
            endpoint: `/temporary-workers/${temporaryWorkerId}`,
            queryParams: {
                include: includes.join(),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: temporaryConceptWorkerRequest }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<TemporaryWorkerResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const temporaryWorkerResource = doc.data;
        const shiftResource = getIncluded(doc, doc.data, 'shift') as ShiftConceptResource;
        const shiftPeriod = transformToPeriod(new Date(shiftResource.attributes.start), new Date(shiftResource.attributes.end));

        const commentsResource = (getMultipleIncluded(doc, temporaryWorkerResource, 'comments') || []) as CommentResource[];

        const comments = commentsResource.map(commentResource => {
            const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

            return transformToComment(commentResource, commentOwnerResource);
        });

        const temporaryConceptWorker = transformToTemporaryWorker(doc.data, shiftPeriod, comments);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: temporaryConceptWorker,
        };
    } catch (error) {
        console.error('[patchColorTemporaryWorkerApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteTemporaryWorkerColorApiCall = async (temporaryWorkerId: string): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/temporary-workers/${temporaryWorkerId}` });

        const response = await authorizedFetch(url, { method: 'DELETE' });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[deleteTemporaryWorkerColorApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
