import { formatDate } from '../../../helpers/date';
import { TrackViewModel } from '../../../models';

export const getShiftStartAndEndTimeOfTrack = (track: TrackViewModel): string => {
    if (!track.shift) {
        return '';
    }

    const customShiftPlanning = track.shift.shiftPlannings.find(shiftPlanning => shiftPlanning.customStartTime && shiftPlanning.user.id === track.owner.id);
    const startDate = customShiftPlanning ? formatDate(customShiftPlanning.start, 'HH:mm') : formatDate(track.shift.start, 'HH:mm');
    const endDate = customShiftPlanning ? formatDate(customShiftPlanning.end, 'HH:mm') : formatDate(track.shift.end, 'HH:mm');

    return `${startDate} - ${endDate}`;
};
