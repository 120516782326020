import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LeaveOfAbsence } from '../../../../models';
import { AsyncReduxState, initialAsyncReduxState } from '../../../@config/AsyncReduxState';

export type OpenLeaveOfAbsencesState = AsyncReduxState<{
    openLeaveOfAbsences: LeaveOfAbsence[];
}>;

const initialState: OpenLeaveOfAbsencesState = {
    ...initialAsyncReduxState,
    openLeaveOfAbsences: [],
};

export const OpenLeaveOfAbsencesSlice = createSlice({
    name: 'openLeaveOfAbsencesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OpenLeaveOfAbsencesState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): OpenLeaveOfAbsencesState {
            return ({
                ...state,
                isSuccessful: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): OpenLeaveOfAbsencesState {
            return ({
                ...state,
                error: action.payload,
            });
        },
        setOpenLeaveOfAbsences(state, action: PayloadAction<LeaveOfAbsence[]>): OpenLeaveOfAbsencesState {
            return ({
                ...state,
                openLeaveOfAbsences: action.payload,
            });
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setOpenLeaveOfAbsences,
    setError,
} = OpenLeaveOfAbsencesSlice.actions;

export default OpenLeaveOfAbsencesSlice.reducer;
