import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { ShiftConceptResource } from '../ShiftConcept/ShiftConcept';
import { EditTemporaryWorkerFormData, TemporaryWorker, TemporaryWorkerResource } from './TemporaryWorker';
import { getTemporaryWorkerResourceIncludesAndTransformToTemporaryWorker } from './TemporaryWorkerHelpers';
import {
    transformAddTemporaryConceptWorkerFormDataToRequest,
    transformEditColorTemporaryConceptWorkerFormDataToRequest,
    transformEditTemporaryConceptWorkerFormDataToRequest,
    transformToTemporaryWorker,
} from './TemporaryWorkerTransformers';

export const postTemporaryConceptWorkerApiCall = async (shiftConceptId: string, name: string): Promise<FetchResult<TemporaryWorker, string>> => {
    const request = transformAddTemporaryConceptWorkerFormDataToRequest(shiftConceptId, name);

    try {
        const url = generateApiUrl({
            endpoint: '/temporary-concept-workers',
            queryParams: {
                include: 'shiftConcept',
            },
        });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: request }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<TemporaryWorkerResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shiftConceptResource = getIncluded(doc, doc.data, 'shiftConcept') as ShiftConceptResource;
        const shiftPeriod = transformToPeriod(new Date(shiftConceptResource.attributes.start), new Date(shiftConceptResource.attributes.end));
        const temporaryConceptWorker = transformToTemporaryWorker(doc.data, shiftPeriod);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: temporaryConceptWorker,
        };
    } catch (error) {
        console.error('[postTemporaryConceptWorkerApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchColorTemporaryConceptWorkerApiCall = async (
    temporaryConceptWorkerId: string,
    color?: string,
): Promise<FetchResult<TemporaryWorker, string>> => {
    const temporaryConceptWorkerRequest = transformEditColorTemporaryConceptWorkerFormDataToRequest(temporaryConceptWorkerId, color);

    try {
        const url = generateApiUrl({
            endpoint: `/temporary-concept-workers/${temporaryConceptWorkerId}`,
            queryParams: {
                include: 'shiftConcept',
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: temporaryConceptWorkerRequest }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<TemporaryWorkerResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shiftConceptResource = getIncluded(doc, doc.data, 'shiftConcept') as ShiftConceptResource;
        const shiftPeriod = transformToPeriod(new Date(shiftConceptResource.attributes.start), new Date(shiftConceptResource.attributes.end));
        const temporaryConceptWorker = transformToTemporaryWorker(doc.data, shiftPeriod);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: temporaryConceptWorker,
        };
    } catch (error) {
        console.error('[patchColorTemporaryConceptWorkerApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchTemporaryConceptWorkerApiCall = async (
    temporaryWorkerId: string,
    formData: EditTemporaryWorkerFormData,
): Promise<FetchResult<TemporaryWorker, string>> => {
    const request = transformEditTemporaryConceptWorkerFormDataToRequest(temporaryWorkerId, formData);

    try {
        const includes = ['shiftConcept', 'comments', 'comments.owner'];

        const url = generateApiUrl({
            endpoint: `/temporary-concept-workers/${temporaryWorkerId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: request }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<TemporaryWorkerResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shift = getIncluded(doc, doc.data, 'shiftConcept') as ShiftConceptResource;
        const temporaryWorker = getTemporaryWorkerResourceIncludesAndTransformToTemporaryWorker(doc, doc.data, shift);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: temporaryWorker,
        };
    } catch (error) {
        console.error('[patchTemporaryWorkerApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteTemporaryConceptWorkerApiCall = async (
    temporaryConceptWorkerId: string,
): Promise<FetchResult<undefined, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: `/temporary-concept-workers/${temporaryConceptWorkerId}`,
        });

        const response = await authorizedFetch(url, {
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteTemporaryConceptWorkerApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
