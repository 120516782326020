import { DepartmentGroupResource as LegacyDepartmentGroupResource } from '../../../entities/Departments/Departments';
import { Department } from '../Department/Department';
import { transformDepartmentToDepartmentGroupDepartment } from '../Department/DepartmentTransformers';
import {
    DepartmentGroup,
    DepartmentGroupIndex,
    DepartmentGroupResource,
    DepartmentGroupV3Resource,
    PatchDepartmentGroupRequest,
    PostDepartmentGroupRequest,
} from './DepartmentGroup';

export const transformToDepartmentGroup = (resource: DepartmentGroupResource): DepartmentGroup => ({
    id: resource.id,
    name: resource.attributes.name,
});

export const transformDepartmentGroupV3ResourceToDepartmentGroup = (resource: DepartmentGroupV3Resource): DepartmentGroup => ({
    id: resource.id.toString(),
    name: resource.name,
});

export const transformDepartmentGroupToDepartmentGroupIndex = (departmentGroup: DepartmentGroup, departments: Department[]): DepartmentGroupIndex => ({
    id: departmentGroup.id,
    name: departmentGroup.name,
    departments: departments.map(transformDepartmentToDepartmentGroupDepartment),
});

export const transformLegacyDepartmentGroupToDepartmentGroup = (resource: LegacyDepartmentGroupResource): DepartmentGroup => ({
    id: resource.id,
    name: resource.name,
});

export const transformDepartmentGroupToPostDepartmentGroupRequest = (departmentGroup: DepartmentGroup): PostDepartmentGroupRequest => ({
    type: 'groups',
    attributes: {
        name: departmentGroup.name,
    },
});

export const transformDepartmentGroupToPatchDepartmentGroupRequest = (departmentGroup: DepartmentGroup): PatchDepartmentGroupRequest => ({
    type: 'groups',
    id: departmentGroup.id,
    attributes: {
        name: departmentGroup.name,
    },
});
