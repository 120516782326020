import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import Select, { OnChangeValue, SelectInstance } from 'react-select';

import { Absence } from '../../../entities/Absence/Absence';
import { LeaveOfAbsence } from '../../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { Period } from '../../../entities/Period/Period';
import { PreferToWorkTimeSlot } from '../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { UnavailableToWorkTimeSlot } from '../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { WorkweekUser } from '../../../entities/User/User';
import trans from '../../../helpers/trans';
import { SelectOption } from '../../../types';
import { transformToWorkweekUserSelectOptions } from './helpers';

export interface WorkweekUserSelectOption extends SelectOption<string, ReactElement> {
    userId: string;
}

interface WorkweekUsersSelectProps {
    controlShouldRenderValue?: boolean;
    isDisabled?: boolean;
    absences: Absence[];
    leaveOfAbsences: LeaveOfAbsence[];
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[];
    period: Period;
    placeholder?: string;
    preferToWorkTimeSlots: PreferToWorkTimeSlot[];
    users: WorkweekUser[];
    onSelect: (user: WorkweekUser) => void;
    className?: string;
}

const WorkweekUsersSelect: FC<WorkweekUsersSelectProps> = ({
    controlShouldRenderValue = true,
    isDisabled,
    absences,
    leaveOfAbsences,
    period,
    placeholder,
    preferToWorkTimeSlots,
    unavailableToWorkTimeSlots,
    users,
    onSelect,
    className = '',
}): ReactElement => {
    const selectRef = useRef<SelectInstance<WorkweekUserSelectOption>|null>(null);

    const [userSelectedOption, setUserSelectedOption] = useState(false);

    const options: WorkweekUserSelectOption[] = useMemo(() => (
        transformToWorkweekUserSelectOptions(
            absences,
            leaveOfAbsences,
            period,
            preferToWorkTimeSlots,
            unavailableToWorkTimeSlots,
            users,
        )
    ), [
        absences,
        leaveOfAbsences,
        period,
        preferToWorkTimeSlots,
        unavailableToWorkTimeSlots,
        users,
    ]);

    const handleSelectChange = (option: OnChangeValue<WorkweekUserSelectOption, false>): void => {
        const selectedUser = users.find(user => user.id === option?.userId);

        if (selectedUser) {
            onSelect(selectedUser);
            setUserSelectedOption(true);
        }
    };

    useEffect((): void => {
        if (!isDisabled && userSelectedOption) {
            selectRef.current?.focus();
        }
    }, [isDisabled, userSelectedOption]);

    return (
        <Select
            controlShouldRenderValue={controlShouldRenderValue}
            isDisabled={isDisabled || !users.length}
            maxMenuHeight={200}
            noOptionsMessage={() => trans('compositions.usersSelect.noEmployeesFound')}
            options={options}
            placeholder={placeholder || trans('compositions.usersSelect.searchEmployee')}
            onChange={handleSelectChange}
            className={`workweek-users-select ${className}`}
            ref={selectRef}
        />
    );
};

export default WorkweekUsersSelect;
