import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { DistrictDivisionTemplate } from '../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import { districtDivisionGroupTemplateSlice } from '../districtDivisionGroupTemplate/districtDivisionGroupTemplateReducer';

export type DistrictDivisionTemplateState = AsyncReduxState<{
    districtDivisionTemplate?: DistrictDivisionTemplate;
    isUpdating: boolean;
}>;

const initialState: DistrictDivisionTemplateState = {
    ...initialAsyncReduxState,
    isUpdating: false,
};

export const districtDivisionTemplateSlice = createSlice({
    name: 'districtDivisionTemplateReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DistrictDivisionTemplateState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsUpdating(state, action: PayloadAction<boolean>): DistrictDivisionTemplateState {
            return {
                ...state,
                isUpdating: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DistrictDivisionTemplateState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): DistrictDivisionTemplateState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setDistrictDivisionTemplate(state, action: PayloadAction<DistrictDivisionTemplate>): DistrictDivisionTemplateState {
            return {
                ...state,
                districtDivisionTemplate: action.payload,
            };
        },
        setDistrictDivisionTemplateToExistingDivisionTemplates(state, action: PayloadAction<DistrictDivisionTemplate>): DistrictDivisionTemplateState {
            return {
                ...state,
                districtDivisionTemplate: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(districtDivisionGroupTemplateSlice.actions.setDistrictDivisionGroupTemplate, (state, action) => {
            if (!state.districtDivisionTemplate) {
                return state;
            }

            const districtDivisionTemplate = {
                ...state.districtDivisionTemplate,
                districtDivisionGroupTemplates: [
                    ...state.districtDivisionTemplate.districtDivisionGroupTemplates,
                    action.payload,
                ],
            };

            return {
                ...state,
                districtDivisionTemplate,
            };
        });
    },
});

export const {
    setIsLoading,
    setIsUpdating,
    setError,
    setIsSuccessful,
    setDistrictDivisionTemplate,
    setDistrictDivisionTemplateToExistingDivisionTemplates,
} = districtDivisionTemplateSlice.actions;

export default districtDivisionTemplateSlice.reducer;
