// Jest crashes on this line, so we disable the import of localized-countries here
// eslint-disable-next-line @typescript-eslint/no-var-requires,import/no-unresolved
const countries = process.env.NODE_ENV === 'test' ? undefined : require('localized-countries')(require('localized-countries/data/nl'));

// Generate countries object for country lookup by Maps ISO 3166-1-alpha-2 code
export const getCountriesObject = () => (countries ? countries.object() : {});

// Generate countries for a react-select component
export const generateCountriesList = () => {
    const data = getCountriesObject();
    return Object.keys(data).map(key => ({
        value: key,
        label: data[key],
    }));
};
