import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { ConnectedHelpTooltip } from '../../@paco/connectors';
import trans from '../../@paco/helpers/trans';
import AbsenceOverlappingShift from '../../components/AbsenceOverlappingShift/AbsenceOverlappingShift';
import ButtonAuthenticate from '../../components/ButtonAuthenticate/ButtonAuthenticate';
import NewFilters from '../../components/Filters/Filters';
import ItemsList from '../../components/ItemsList/ItemsList';
import SearchBar from '../../components/SearchBar/SearchBar';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import Icon from '../../components/style/Icon/Icon';
import TableContainer from '../../components/Table/TableContainer';
import UserPageLink from '../../components/UserPageLink/UserPageLink';
import { ABSENCES_LIST } from '../../constants';
import { getObjProperty, getPermissionToAdd, getUserFullName } from '../../helpers';
import { formatDate, getDifferenceInTime } from '../../helpers/date';
import { getPermissionToEdit } from '../../helpers/permissions/getPermissionToAction';
import { translate } from '../../helpers/translations/translator';
import {
    addAbsence,
    deleteAbsence,
    editAbsence,
    getAbsences,
    replaceUserInShift,
    resolveAbsence,
} from '../../redux/absences-ts/absencesActions';
import { setEmployeeSearch, setFilter } from '../../redux/filter/filterActions';
import { getEmployees } from '../../redux/users/usersActions';
import AddAbsenceForm from './forms/AddAbsenceForm';
import EditAbsenceForm from './forms/EditAbsenceForm/EditAbsenceForm';
import EditShiftForm from './forms/EditShiftForm';
import ResolveAbsenceForm from './forms/ResolveAbsenceForm';

import './Absences.scss';


class AbsencesList extends Component {
    state = {
        resolveAbsenceForm: false,
        editShiftForm: false,
        addAbsenceForm: false,
        editAbsenceForm: false,
        activeAbsence: null,
        activeShift: null,
    };

    componentDidMount() {
        this.getAbsences();
    }

    componentDidUpdate(prevProps) {
        const activeDepartmentOptions = this.props.departmentOptions.filter(option => option.isChecked);
        const prevActiveDepartmentOptions = prevProps.departmentOptions.filter(option => option.isChecked);

        if (activeDepartmentOptions.length !== prevActiveDepartmentOptions.length) {
            this.getAbsences();
        }
    }

    getAbsences = () => {
        this.props.dispatch(getAbsences());
    };

    dispatchSetFilters = (filter) => {
        this.props.dispatch(setFilter(filter, this.getAbsences));
    };

    dispatchResolveAbsence = (
        absence,
        startDate,
        endDate,
        periodsWithHours,
        weeksWithHours,
        waitingDayHours,
    ) => {
        this.props.dispatch(resolveAbsence(
            absence,
            startDate,
            endDate,
            periodsWithHours,
            weeksWithHours,
            waitingDayHours,
            ABSENCES_LIST,
        ));

        this.setState({ resolveAbsenceForm: false });
    };

    dispatchPlanUserToShift = (addUserId, delUserId, shiftId) => {
        this.props.dispatch(replaceUserInShift(addUserId, delUserId, shiftId));
        this.setState({ editShiftForm: false });
    };

    dispatchAddAbsence = (
        userId,
        dateTime,
        periodsWithHours,
        weeksWithHours,
        waitingDayHours,
    ) => {
        this.props.dispatch(addAbsence(
            userId,
            dateTime,
            periodsWithHours,
            weeksWithHours,
            waitingDayHours,
        ));
        this.setState({ addAbsenceForm: false });
    };

    dispatchEditAbsence = (
        absence,
        startDate,
        endDate,
        periodsWithHours,
        weeksWithHours,
        waitingDayHours,
    ) => {
        this.props.dispatch(editAbsence(
            absence,
            startDate,
            endDate,
            periodsWithHours,
            weeksWithHours,
            waitingDayHours,
            ABSENCES_LIST,
        ));
        this.setState({ editAbsenceForm: false });
    };

    dispatchDeleteAbsence = (absenceId) => {
        this.props.dispatch(deleteAbsence(absenceId, ABSENCES_LIST));
        this.setState({ editAbsenceForm: false });
    };

    onSearchBarChange = (value) => {
        this.props.dispatch(setEmployeeSearch(value));
        this.getAbsences();
    };

    addEditAbsenceForm = (absence) => {
        this.setState({
            editAbsenceForm: true,
            activeAbsence: absence,
        });
    };

    addResolveAbsenceForm = (absence) => {
        this.setState({
            resolveAbsenceForm: true,
            activeAbsence: absence,
        });
    };

    addEditShiftForm = (shift, absence) => {
        this.props.dispatch(getEmployees(absence.start));
        this.setState({
            editShiftForm: true,
            activeShift: shift,
            activeAbsence: absence,
        });
    };

    addAbsenceForm = () => {
        this.setState({
            addAbsenceForm: true,
        });
    };

    renderResolveButton = (absence) => {
        const { currentUser, permissions } = this.props;

        if (!getPermissionToEdit(currentUser.role, absence.user.roles, permissions, 'absences')) {
            return null;
        }

        return (
            <button className="absences-table-button" type="button" onClick={() => this.addResolveAbsenceForm(absence)}>
                {translate('pages.absences.resolveAbsence')}
            </button>
        );
    };

    renderEditButton(absence) {
        const { currentUser, permissions } = this.props;

        if (!getPermissionToEdit(currentUser.role, absence.user.roles, permissions, 'absences')) {
            return null;
        }

        return (
            <button type="button" onClick={() => this.addEditAbsenceForm(absence)} className="clear-button">
                <Icon color="light-blue" kind="edit" />
            </button>
        );
    }

    renderAbsences() {
        const {
            loading,
            filter,
            absences,
            employees,
            pagination,
            permissions,
            currentUser,
            pacoPayrollPeriods,
            payrollPeriods,
            dispatch,
        } = this.props;
        const {
            resolveAbsenceForm,
            activeAbsence,
            editShiftForm,
            activeShift,
            addAbsenceForm,
            editAbsenceForm,
        } = this.state;
        const { employeeSearch } = filter;

        return (
            <>
                <div className="absences-search">
                    <SearchBar placeholder={translate('common.searchEmployee')} startVal={employeeSearch} onChange={this.onSearchBarChange} />
                </div>
                <ItemsList
                    loading={loading}
                    itemsLength={absences.length}
                    pagination={pagination}
                    emptyMessage={translate('pages.absences.emptyAbsencesList')}
                    onPagination={this.getAbsences}
                >
                    <div className="absences-table">
                        <TableContainer
                            data={absences}
                            attributes={[
                                {
                                    customAction: absence => <UserPageLink isPlain name={getUserFullName(absence.user)} id={getObjProperty(absence, 'user.id')} />,
                                    label: translate('common.name'),
                                },
                                {
                                    customAction: absence => formatDate(absence.start, 'd MMMM yyyy HH:mm'),
                                    label: translate('pages.absences.absenceDate'),
                                },
                                {
                                    customAction: absence => this.renderOverlappingShifts(absence),
                                    label: translate('pages.absences.upcommingShifts'),
                                    className: 'absences-overlapping-shifts-col',
                                },
                                {
                                    customAction: absence => this.renderResolveButton(absence),
                                },
                                {
                                    customAction: absence => this.renderEditButton(absence),
                                    className: 'td-edit',
                                }]}
                        />
                    </div>
                </ItemsList>
                <Modal size="lg" isOpen={resolveAbsenceForm} className="form-resolve-absence">
                    <ResolveAbsenceForm
                        absence={activeAbsence}
                        currentUserRole={currentUser.role}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        permissions={permissions}
                        onSubmit={this.dispatchResolveAbsence}
                        onCancel={() => this.setState({ resolveAbsenceForm: false })}
                    />
                </Modal>

                <Modal size="lg" isOpen={editShiftForm} className="form-edit-shift">
                    <EditShiftForm
                        excludeUsersWithShiftOnDate
                        dispatch={dispatch}
                        shift={activeShift}
                        absence={activeAbsence}
                        onSubmit={this.dispatchPlanUserToShift}
                        onCancel={() => this.setState({ editShiftForm: false })}
                    />
                </Modal>
                <Modal size="lg" isOpen={addAbsenceForm} className="form-add-absence">
                    <AddAbsenceForm
                        currentUserRole={currentUser.role}
                        employees={employees}
                        permissions={permissions}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        onSubmit={this.dispatchAddAbsence}
                        onCancel={() => this.setState({ addAbsenceForm: false })}
                    />
                </Modal>
                <Modal size="lg" isOpen={editAbsenceForm} className="form-edit-absence">
                    <EditAbsenceForm
                        data={activeAbsence}
                        permissions={permissions}
                        currentUserRole={currentUser.role}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        onSubmit={this.dispatchEditAbsence}
                        onDelete={this.dispatchDeleteAbsence}
                        onCancel={() => this.setState({ editAbsenceForm: false })}
                    />
                </Modal>
            </>
        );
    }

    renderOverlappingShifts(absence) {
        return absence.overlappingShifts
            .sort((a, b) => (getDifferenceInTime(a.start, b.start).indexOf('-') === 0 ? 1 : -1))
            .map((shift, index) => (
                <AbsenceOverlappingShift
                    key={`${index + 1}`}
                    onClick={this.addEditShiftForm}
                    shift={shift}
                    absence={absence}
                />
            ));
    }

    renderAddNewAbsenceButton(forMobile = false) {
        const { currentUser, permissions } = this.props;
        if (!getPermissionToAdd(currentUser?.role, permissions, 'absences')) {
            return null;
        }

        return (
            <ButtonAuthenticate
                onClick={this.addAbsenceForm}
                text={translate('pages.absences.newAbsence')}
                mobile={forMobile}
            />
        );
    }

    render() {
        const {
            departments,
            filter,
            employmentTypes,
            roles,
        } = this.props;

        const button = this.renderAddNewAbsenceButton();

        return (
            <>
                <SidebarPage
                    sidebarContent={(
                        <NewFilters
                            departments={departments}
                            filter={filter}
                            roles={roles}
                            employmentTypes={employmentTypes}
                            button={button}
                            filterBlocks={[
                                'user-types',
                                'contract-types',
                            ]}
                            onChange={this.dispatchSetFilters}
                        />
                    )}
                >
                    <div className="absences-list">
                        {this.renderAbsences()}
                        <ConnectedHelpTooltip
                            index={0}
                            route="absences-list"
                            subTitle={trans('help.absences.addAbsence.title')}
                            text={trans('help.absences.addAbsence.text')}
                            title={trans('help.absences.title')}
                            showMobileInfoWarning
                            className="absences__add-absence-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={1}
                            route="absences-list"
                            subTitle={trans('help.absences.absenceFilters.title')}
                            text={trans('help.absences.absenceFilters.text')}
                            title={trans('help.absences.title')}
                            showMobileInfoWarning
                            className="absences__filters-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={2}
                            route="absences-list"
                            subTitle={trans('help.absences.absenceInfo.title')}
                            text={trans('help.absences.absenceInfo.text')}
                            title={trans('help.absences.title')}
                            className="absences__absence-info-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={3}
                            route="absences-list"
                            subTitle={trans('help.absences.editAbsence.title')}
                            text={trans('help.absences.editAbsence.text')}
                            title={trans('help.absences.title')}
                            showMobileInfoWarning
                            className="absences__edit-absence-help-tooltip"
                        />
                    </div>
                </SidebarPage>

                {this.renderAddNewAbsenceButton(true)}
            </>
        );
    }
}

AbsencesList.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    departments: PropTypes.array.isRequired,
    pacoPayrollPeriods: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    employmentTypes: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    absences: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    employees: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
};

export default connect()(AbsencesList);
