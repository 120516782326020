import React, { FC, useMemo } from 'react';

import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

import { LockPeriodForm } from '../../../../@paco/containers';
import { PayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { PayrollPeriodWeek } from '../../../../@paco/entities/PayrollPeriodWeek/PayrollPeriodWeek';
import ButtonWithTooltip from '../../../../components/ButtonWithTooltip/ButtonWithTooltip';
import Icon from '../../../../components/style/Icon/Icon';
import { translate } from '../../../../helpers/translations/translator';

import './CompletePeriodStep.scss';

interface CompletePeriodStepProps {
    step: 1 | 2 | 3 | 4;
    isLocked?: boolean;
    isLoading?: boolean;
    canManageLoketData?: boolean;
    payrollPeriod: PayrollPeriod;
    openTasks?: number;
    onTracksExportButtonClick?: () => void;
    onTvTSyncButtonClick?: () => void;
    onLeaveOfAbsencesSyncButtonClick?: () => void;
    onLockPeriodButtonClick?: () => void;
    onLockPeriodWeekButtonClick?: (periodWeek: PayrollPeriodWeek) => void;
}

const CompletePeriodStep: FC<CompletePeriodStepProps> = ({
    isLocked,
    isLoading,
    canManageLoketData = false,
    step,
    payrollPeriod,
    openTasks,
    onTracksExportButtonClick,
    onTvTSyncButtonClick,
    onLeaveOfAbsencesSyncButtonClick,
    onLockPeriodButtonClick,
    onLockPeriodWeekButtonClick,
}) => {
    const link = `/${translate('nav.pz.link')}/${translate('nav.pz.completePeriods.link')}`;

    const handleLockPeriodButtonClick = (): void => {
        if (onLockPeriodButtonClick) {
            onLockPeriodButtonClick();
        }
    };

    const handleLockPeriodWeekButtonClick = (periodWeek: PayrollPeriodWeek): void => {
        if (onLockPeriodWeekButtonClick) {
            onLockPeriodWeekButtonClick(periodWeek);
        }
    };

    const classNames = useMemo(() => classnames('complete-period-step', {
        'complete-period-step--is-locked': isLocked,
    }), [isLocked]);

    return (
        <div className={classNames}>
            {step === 1 && (
                <>
                    <div className="complete-period-step__title">
                        {translate('pages.pz.completePeriodStep1.title')}
                    </div>
                    <div className="complete-period-step__text">
                        {translate('pages.pz.completePeriodStep1.intro')}
                    </div>
                    <NavLink
                        to={{ pathname: `${link}/${payrollPeriod.id}` }}
                        className="complete-period-step__link"
                    >
                        <>
                            {translate('pages.pz.checkOpenTasks', { number: isLoading ? '?' : openTasks })}
                            <Icon kind="keyboard_arrow_right" />
                        </>
                    </NavLink>
                </>
            ) }

            {step === 2 && (
                <>
                    <div className="complete-period-step__title">
                        {translate('pages.pz.completePeriodStep2.title')}
                    </div>
                    <div className="complete-period-step__text">
                        {translate('pages.pz.completePeriodStep2.intro')}
                    </div>
                    <div className="complete-period-step__buttons-container">
                        <button
                            className="complete-period-step__button"
                            type="button"
                            onClick={onTracksExportButtonClick}
                        >
                            <Icon color="orange" kind="download" />
                            <div className="complete-period-step__button-text">
                                {translate('pages.pz.completePeriodStep2.exportButton')}
                            </div>
                        </button>
                    </div>
                </>
            )}

            {step === 3 && (
                <LockPeriodForm
                    isLoading={!!isLoading}
                    payrollPeriod={payrollPeriod}
                    onLockPeriodButtonClick={handleLockPeriodButtonClick}
                    onLockPeriodWeekButtonClick={handleLockPeriodWeekButtonClick}
                />
            )}

            {step === 4 && (
                <>
                    <div className="complete-period-step__title">
                        {translate('pages.pz.completePeriodStep4.title')}
                    </div>
                    <div className="complete-period-step__text">
                        {translate('pages.pz.completePeriodStep4.intro')}
                    </div>
                    <div className="complete-period-step__buttons-container">
                        <ButtonWithTooltip
                            tooltip={!canManageLoketData}
                            tooltipText={translate('common.canNotManageLoketDataWarning')}
                            disabled={!canManageLoketData}
                            className="complete-period-step__button"
                            tooltipClassName="complete-period-step__tooltip"
                            onClick={onLeaveOfAbsencesSyncButtonClick}
                        >
                            <Icon color="orange" kind="sync" />
                            <div className="complete-period-step__button-text">
                                {translate('pages.pz.completePeriodStep4.syncLeaveOfAbsence')}
                            </div>
                        </ButtonWithTooltip>
                        <ButtonWithTooltip
                            tooltip={!canManageLoketData}
                            tooltipText={translate('common.canNotManageLoketDataWarning')}
                            disabled={!canManageLoketData}
                            className="complete-period-step__button"
                            tooltipClassName="complete-period-step__tooltip"
                            onClick={onTvTSyncButtonClick}
                        >
                            <Icon color="orange" kind="sync" />
                            <div className="complete-period-step__button-text">
                                {translate('pages.tracks.syncTvt')}
                            </div>
                        </ButtonWithTooltip>
                        <button
                            className="complete-period-step__button"
                            type="button"
                            onClick={onTracksExportButtonClick}
                        >
                            <Icon color="orange" kind="download" />
                            <div className="complete-period-step__button-text">
                                {translate('pages.pz.completePeriodStep4.loketExport')}
                            </div>
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default CompletePeriodStep;
