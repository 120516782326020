import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Button, Modal } from 'reactstrap';

import { ConnectedHelpTooltip } from '../../../../@paco/connectors';
import trans from '../../../../@paco/helpers/trans';
import ButtonAuthenticate from '../../../../components/ButtonAuthenticate/ButtonAuthenticate';
import NewFilters from '../../../../components/Filters/Filters';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import SidebarPage from '../../../../components/SidebarPage/SidebarPage';
import Icon from '../../../../components/style/Icon/Icon';
import { DepartmentViewModel } from '../../../../entities/Departments/Departments';
import { translate } from '../../../../helpers/translations/translator';
import {
    DocumentFormData,
    DocumentTypeViewModel,
    DocumentViewModel,
    Role,
} from '../../../../models';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import DeleteDocument from '../../../Management/forms/DeleteDocument';
import NotifyEmployees from '../../../Schedule/forms/NotifyEmployees';
import SharedDocumentsTable from '../../components/SharedDocumentsTable/SharedDocumentsTable';
import AddOrEditSharedDocument from '../../forms/AddOrEditSharedDocument/AddOrEditSharedDocument';

import './SharedDocumentsOverview.scss';

interface SharedDocumentsProps {
    loading: boolean;
    documents: DocumentViewModel[];
    roles: Role[];
    departments: DepartmentViewModel[];
    documentTypes: DocumentTypeViewModel[];
    lastAddedDocument: DocumentFormData | null;
    onSetFilterChange: (filter: AppFilter) => void;
    onSetPagination: () => void;
    onSearchBarChange: (search: string) => void;
    onAddDocument: (formData: DocumentFormData) => void;
    onDeleteDocument: (documentId: string) => void;
    onNotifyUsers: (data: any) => void;
    filter: any;
    pagination: any;
    canNotifyEmployees: boolean;
    canAddFiles: boolean;
}

const SharedDocumentsOverview: FC<SharedDocumentsProps> = ({
    loading,
    documents,
    roles,
    departments,
    documentTypes,
    lastAddedDocument,
    onSetFilterChange,
    onSetPagination,
    onSearchBarChange,
    onAddDocument,
    onDeleteDocument,
    onNotifyUsers,
    filter,
    pagination,
    canNotifyEmployees,
    canAddFiles,
}) => {
    const [notifyForm, setNotifyForm] = useState(false);
    const [document, setDocument] = useState<DocumentViewModel | null>(null);
    const [documentToDeleted, setDocumentToDeleted] = useState('');
    const [addDocumentForm, setAddDocumentForm] = useState(false);
    const [deleteDocumentForm, setDeleteDocumentForm] = useState(false);

    const notifyFormSubject = useMemo(() => {
        if (lastAddedDocument && lastAddedDocument.willNotifyUsersAfterSuccess) {
            const translation = lastAddedDocument.id ? 'pages.pz.editFileNotification' : 'pages.pz.addFileNotification';
            return translate(translation, { name: lastAddedDocument.name });
        }

        return translate('pages.pz.newFileNotification');
    }, [lastAddedDocument]);


    useEffect(() => {
        if (lastAddedDocument && lastAddedDocument.willNotifyUsersAfterSuccess) {
            setNotifyForm(true);
        }
    }, [lastAddedDocument]);


    const onAddDocumentClick = () => {
        setDocument(null);
        setAddDocumentForm(true);
    };
    const onAddDocumentSubmit = (formData: DocumentFormData) => {
        setAddDocumentForm(false);
        onAddDocument(formData);
    };

    const onEditDocumentClick = (selectedDocument: DocumentViewModel) => {
        setAddDocumentForm(true);
        setDocument(selectedDocument);
    };

    const onNotifyUserSubmit = (data: any) => {
        onNotifyUsers(data);
        setNotifyForm(false);
    };

    const onDeleteDocumentClick = (documentId: string) => {
        setDeleteDocumentForm(true);
        setDocumentToDeleted(documentId);
    };

    const onDeleteDocumentSubmit = () => {
        setDeleteDocumentForm(false);
        onDeleteDocument(documentToDeleted);
    };

    const onCancel = () => {
        setAddDocumentForm(false);
        setDeleteDocumentForm(false);
    };

    return (
        <SidebarPage
            sidebarContent={(
                <NewFilters
                    documentTypes={documentTypes}
                    filter={filter}
                    roles={roles}
                    filterBlocks={[
                        'document-types',
                        'user-types',
                        'main-department',
                    ]}
                    onChange={onSetFilterChange}
                />
            )}
        >
            <div className="shared-documents">
                <div className="shared-documents-overview">
                    <div className="shared-documents__button-wrapper">
                        <ConnectedHelpTooltip
                            index={0}
                            route="documents"
                            subTitle={trans('help.pz.documents.title')}
                            text={trans('help.pz.documents.text')}
                            title={trans('help.pz.title')}
                            className="shared-documents__documents-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={1}
                            route="documents"
                            subTitle={trans('help.pz.documentFilters.title')}
                            text={trans('help.pz.documentFilters.text')}
                            title={trans('help.pz.title')}
                            showMobileInfoWarning
                            className="shared-documents__filters-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={2}
                            route="documents"
                            subTitle={trans('help.pz.addDocument.title')}
                            text={trans('help.pz.addDocument.text')}
                            title={trans('help.pz.title')}
                            className="shared-documents__add-document-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={3}
                            route="documents"
                            subTitle={trans('help.pz.notifyEmployees.title')}
                            text={trans('help.pz.notifyEmployees.text')}
                            title={trans('help.pz.title')}
                            className="shared-documents__notify-employees-help-tooltip"
                        />
                        {canAddFiles && (
                            <ButtonAuthenticate
                                text={translate('pages.management.newDocument')}
                                responsive={false}
                                onClick={onAddDocumentClick}
                                className="shared-documents__button"
                            />
                        )}
                        {canNotifyEmployees && (
                            <Button
                                type="button"
                                color="orange"
                                onClick={() => { setNotifyForm(true); }}
                                className="shared-documents__button shared-documents__button--notify"
                            >
                                <Icon kind="notifications" />
                                <span>{translate('pages.shifts.notifyEmployees')}</span>
                            </Button>
                        )}
                    </div>

                    <SearchBar
                        placeholder={translate('common.searchDocument')}
                        startVal={filter.documentSearch}
                        onChange={onSearchBarChange}
                        className="shared-documents__search-bar"
                    />
                </div>
                <div>
                    <ItemsList
                        loading={loading}
                        itemsLength={documents.length}
                        pagination={pagination}
                        emptyMessage={translate('pages.pz.emptyDocumentList')}
                        onPagination={onSetPagination}
                    >
                        <SharedDocumentsTable
                            documents={documents}
                            editDocument={onEditDocumentClick}
                            deleteDocument={onDeleteDocumentClick}
                        />
                    </ItemsList>
                </div>
            </div>
            <Modal size="lg" isOpen={addDocumentForm}>
                <AddOrEditSharedDocument
                    document={document}
                    documentTypes={documentTypes}
                    roles={roles}
                    departments={departments}
                    onSubmit={onAddDocumentSubmit}
                    onCancel={onCancel}
                />
            </Modal>
            <Modal size="lg" isOpen={deleteDocumentForm} className="form-delete-document">
                <DeleteDocument
                    onSubmit={onDeleteDocumentSubmit}
                    onCancel={onCancel}
                />
            </Modal>
            <Modal size="lg" isOpen={notifyForm} className="form-notify">
                <NotifyEmployees
                    title={translate('pages.pz.notifyFormTitle')}
                    subjectEmployeesDefault={notifyFormSubject}
                    subjectDepartmentsDefault={notifyFormSubject}
                    bodyDefault={translate('pages.pz.notifyFormBody')}
                    generateNewSubjectAfterDepartmentChange={false}
                    departments={departments}
                    onSubmit={onNotifyUserSubmit}
                    onCancel={() => setNotifyForm(false)}
                />
            </Modal>
        </SidebarPage>
    );
};

export default SharedDocumentsOverview;
