import React, { FC } from 'react';

import { ConnectedHelpTooltip } from '../../@paco/connectors';
import { Permission } from '../../@paco/entities/Permission/Permission';
import { transformLegacyRoleToRole } from '../../@paco/entities/Role/RoleTransformers';
import { AuthenticatedUser } from '../../@paco/entities/User/User';
import checkPermission from '../../@paco/helpers/permissions/checkPermission';
import trans from '../../@paco/helpers/trans';
import { getUserFullName } from '../../helpers';
import { convertDateToTime, formatDate } from '../../helpers/date';
import { getPermissionToEdit } from '../../helpers/permissions/getPermissionToAction';
import { translate } from '../../helpers/translations/translator';
import useTimeTicker from '../../helpers/useTimeTicker';
import { TrackViewModel } from '../../models';
import { getShiftStartAndEndTimeOfTrack } from '../../pages/Tracks/helpers/getShiftStartAndEndTimeOfTrack';
import TableContainer from '../Table/TableContainer';
import UserPageLink from '../UserPageLink/UserPageLink';

interface OpenTracksTableProps {
    currentUser: AuthenticatedUser;
    tracks: TrackViewModel[];
    permissions: Permission[];
    onStopClick: (track: TrackViewModel) => void;
    className?: string;
}

const OpenTracksTable: FC<OpenTracksTableProps> = ({
    currentUser,
    tracks,
    permissions,
    onStopClick,
    className = '',
}) => {
    const renderStopButton = (track: TrackViewModel) => {
        const { roles: trackOwnerLegacyRoles = [] } = track.owner;
        const trackOwnerRoles = trackOwnerLegacyRoles.map(transformLegacyRoleToRole);
        const isAllowedToApproveOwnTrack = currentUser.id === track.owner.id && checkPermission(permissions, 'approve-own-tracks');
        const canEditTrack = getPermissionToEdit(currentUser.role, trackOwnerRoles, permissions, 'tracks') || isAllowedToApproveOwnTrack;

        return (
            <>
                <button type="button" className={`button-track-stop ${!canEditTrack ? 'button-track-stop--disabled' : ''}`} onClick={() => onStopClick(track)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path className="svg-button" fill="#FF1C25" d="M6 6h12v12H6z" />
                    </svg>
                    {translate('pages.tracks.stopTrack')}
                </button>
                <ConnectedHelpTooltip
                    index={3}
                    route="open-tracks"
                    subTitle={trans('help.tracks.stopTrack.title')}
                    text={trans('help.tracks.stopTrack.text')}
                    title={trans('help.tracks.title')}
                    showMobileInfoWarning
                    className="tracks__stop-track-help-tooltip"
                />
            </>
        );
    };

    const renderUserPageLink = (track: TrackViewModel) => {
        if (!track.owner) {
            return null;
        }

        return (
            <UserPageLink
                isPlain
                id={track.owner.id}
                className="tracks-user-link"
                name={getUserFullName(track.owner)}
            />
        );
    };

    return (
        <div className={className}>
            <TableContainer
                data={tracks}
                attributes={[
                    {
                        customAction: (track: TrackViewModel) => renderUserPageLink(track),
                        label: translate('common.name'),
                    },
                    {
                        customAction: (track: TrackViewModel) => formatDate(track.checkIn, 'd MMMM yyyy'),
                        label: translate('common.date'),
                    },
                    {
                        customAction: (track: TrackViewModel) => getShiftStartAndEndTimeOfTrack(track),
                        label: translate('common.shift'),
                    },
                    {
                        customAction: (track: TrackViewModel) => `${convertDateToTime(track.checkIn)}`,
                        label: translate('pages.tracks.startTime'),
                    },
                    {
                        customAction: (track: TrackViewModel) => (useTimeTicker({ from: track.checkIn })),
                        label: translate('pages.tracks.runningTime'),
                        className: 'td-timer',
                    },
                    {
                        customAction: (track: TrackViewModel) => renderStopButton(track),
                        label: '',
                        align: 'right',
                    },
                ]}
            />
            <ConnectedHelpTooltip
                index={2}
                route="open-tracks"
                subTitle={trans('help.tracks.trackData.title')}
                text={trans('help.tracks.trackData.text')}
                title={trans('help.tracks.title')}
                className="tracks__track-data-help-tooltip"
            />
        </div>
    );
};

export default OpenTracksTable;
