import { FC, ReactElement } from 'react';

import nationalities from '../../../helpers/nationalities';
import trans from '../../../helpers/trans';
import { SelectOption } from '../../../types';
import { SelectInput } from '../../index';

interface NationalitySelectInputProps {
    nationality: SelectOption;
    onChange: (option: SelectOption) => void;
}

const NationalitySelectInput: FC<NationalitySelectInputProps> = ({
    nationality,
    onChange,
}: NationalitySelectInputProps): ReactElement => (
    <SelectInput
        isSearchable
        label={trans('common.nationality')}
        name="nationality"
        noOptionsMessage={trans('compositions.nationalitySelectInput.noNationalityFound')}
        value={nationality}
        options={nationalities}
        onChange={onChange}
    />
);

export default NationalitySelectInput;
