import { FC, ReactElement } from 'react';

import { ConnectedInactiveUser } from '../../connectors';
import { LoginLayout } from '../../containers';

interface InactiveUserPageProps {
    className?: string;
}

const InactiveUserPage: FC<InactiveUserPageProps> = (): ReactElement => (
    <LoginLayout>
        <ConnectedInactiveUser />
    </LoginLayout>
);

export default InactiveUserPage;
