import React from 'react';

import PropTypes from 'prop-types';
import {
    Button, Col, CustomInput, FormGroup, Label, ModalBody,
    ModalFooter, ModalHeader, Row,
} from 'reactstrap';

import { translate } from '../../../helpers/translations/translator';

import './Forms.scss';

class AddTempWorker extends React.Component {
    state = {
        name: '',
    };

    render() {
        const { onCancel, onSubmit = null } = this.props;
        const { name } = this.state;

        return (
            <form onSubmit={(e) => { e.preventDefault(); onSubmit?.(name); }}>
                <ModalHeader>{translate('pages.shifts.addTempWorker')}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.name')}</Label>
                                <CustomInput
                                    onChange={e => this.setState({ name: e.target.value })}
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder={translate('pages.shifts.nameOfTempWorker')}
                                    autoComplete="off"
                                    value={name}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        color="link"
                        id="modal-close"
                        onClick={onCancel}
                    >
                        {translate('common.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        color="orange"
                        disabled={!this.state.name}
                    >
                        {translate('common.save')}
                    </Button>
                </ModalFooter>
            </form>
        );
    }
}

AddTempWorker.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
};

export default AddTempWorker;
