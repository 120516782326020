import { USER_ROLE_EMPLOYEE, USER_ROLE_JUNIOR_MANAGER, USER_ROLE_MANAGER } from '../../../../constants';
import { checkPermission } from '../../../../helpers';
import { Permission, User } from '../../../../models';


// TODO: Create a generic function for filtering users for an add feature with permissions
export default function getUsersForAddTrackForm(
    permissions: Permission[],
    users: User[],
    currentUserId: string,
): User[] {
    const canAddManagerTracks = checkPermission(permissions, 'add-new-manager-tracks');
    const canAddJuniorManagerTracks = checkPermission(permissions, 'add-new-junior-manager-tracks');
    const canAddEmployeeTracks = checkPermission(permissions, 'add-new-employee-tracks');
    const canAddNewTracks = checkPermission(permissions, 'add-new-tracks');
    const canApproveOwnTracks = checkPermission(permissions, 'approve-own-tracks');

    return users.filter(user => {
        if (!canApproveOwnTracks && user.id === currentUserId) {
            return false;
        }

        if (canAddNewTracks) {
            return true;
        }

        if (canAddManagerTracks
            && user.roles.find(role => role.slug === USER_ROLE_MANAGER)) {
            return true;
        }

        if (canAddJuniorManagerTracks
            && user.roles.find(role => role.slug === USER_ROLE_JUNIOR_MANAGER)) {
            return true;
        }

        if (canAddEmployeeTracks && user.roles.find(role => role.slug === USER_ROLE_EMPLOYEE)) {
            return true;
        }

        return false;
    });
}
