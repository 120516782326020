import React from 'react';

import PropTypes from 'prop-types';

import { hardRefresh } from '../helpers';
import { translate } from '../helpers/translations/translator';
import SVG403 from '../images/403.svg';
import SVG404 from '../images/404.svg';
import SVG500 from '../images/500.svg';

const ErrorPage = ({
    errorCode,
    fullHeight = null,
}) => {
    const errors = {
        403: {
            image: SVG403,
            title: `${translate('pages.error.oops')}, `,
            body: translate('pages.error.403'),
            goBackToHome: true,
        },
        404: {
            image: SVG404,
            title: `${translate('pages.error.oops')}, `,
            body: translate('pages.error.404'),
            goBackToHome: true,
        },
        410: {
            image: SVG500,
            title: `${translate('pages.error.oops')}, `,
            body: translate('pages.error.410'),
            goBackToHome: true,
        },
        500: {
            image: SVG500,
            title: `${translate('pages.error.oops')}, `,
            body: translate('pages.error.500'),
        },
        503: {
            image: SVG500,
            title: `${translate('pages.error.oops')}, `,
            body: translate('pages.error.503'),
        },
    };

    const error = errors[errorCode];

    return (
        <div
            className={`
                full-height-page
                ${fullHeight ? 'full-height-page--no-nav' : ''}
                bg-primary
                d-flex
                justify-content-center
                align-items-center
            `}
        >
            <div className="text-white text-center px-3">
                <img className="mb-5 w-25" src={error.image} alt={translate('pages.error.pageNotFound')} />

                <h5 className="text-white mb-4">
                    {error.title}
                </h5>

                <p className="mb-5">
                    {error.body}
                </p>

                <button
                    className="btn btn-orange"
                    type="button"
                    onClick={() => { hardRefresh(error.goBackToHome); }}
                >
                    {error.goBackToHome ? translate('common.goBackToHome') : translate('common.refresh')}
                </button>
            </div>
        </div>
    );
};

ErrorPage.propTypes = {
    errorCode: PropTypes.number.isRequired,
    fullHeight: PropTypes.bool,
};

export default ErrorPage;
