import React, { FC, ReactElement } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { useTypedSelector } from '../../../@paco/redux/store';
import { CompletePeriodAbsencesContainer } from '../../../containers';
import { Absence, PayrollPeriodWithHours, WeekWithHours } from '../../../models';
import { editAbsence } from '../../../redux/@toolkit/absences/absencesActions';
import { Reducers } from '../../../redux/reducers';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../../services/PayrollPeriodService/transformPayrollPeriodLegacy';

interface ConnectedCompletePeriodAbsencesProps {
    activePeriod?: PayrollPeriod;
    className?: string;
}

const ConnectedCompletePeriodAbsences: FC<ConnectedCompletePeriodAbsencesProps> = ({ activePeriod, className }): ReactElement => {
    const dispatch = useDispatch();

    const isLoading = useSelector<Reducers, boolean>(state => state.absencesReducerToolkit.isLoading);
    const absences = useSelector<Reducers, Absence[]>(state => state.absencesReducerToolkit.absences);
    const { permissions, role: currentUserRole } = useTypedSelector(state => state.authenticatedUserReducer);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);

    const handleSubmitAbsence = (
        absence: Absence,
        startDate: string,
        endDate: string | null,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        waitingDayHours: number,
    ): void => {
        const legacyPayrollPeriod = activePeriod ? transformPayrollPeriodToLegacyPayrollPeriod(activePeriod) : undefined;

        if (legacyPayrollPeriod) {
            // @ts-ignore
            dispatch(editAbsence(
                absence,
                startDate,
                endDate,
                payrollPeriodsWithHours,
                weeksWithHours,
                waitingDayHours,
                legacyPayrollPeriod,
            ));
        }
    };

    return (
        <CompletePeriodAbsencesContainer
            isLoading={isLoading}
            isPeriodLocked={activePeriod?.isLocked}
            permissions={permissions}
            currentUserRole={currentUserRole}
            absences={absences}
            payrollPeriods={payrollPeriods}
            onSubmitAbsence={handleSubmitAbsence}
            className={className}
        />
    );
};

export default ConnectedCompletePeriodAbsences;
