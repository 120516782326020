import React, {
    FC,
    useEffect,
    useRef,
    useState,
} from 'react';

import classnames from 'classnames';
import { useWindowScroll, useWindowSize } from 'react-use';

import {
    didElementScrollPastElement,
    getParentElement,
    getParentElementHeight,
    getParentElementWidth,
    getParentSecondRowElement,
} from './helpers';

import './FixedTableHeader.scss';


interface FixedTableHeaderProps {
    className?: string;
    children: React.ReactNode;
}

const FixedTableHeader: FC<FixedTableHeaderProps> = ({
    className = '',
    children,
}) => {
    const elementRef = useRef(null);
    const { y: scrollY } = useWindowScroll();
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const [parentElement, setParentElement] = useState<HTMLElement | null>(null);
    const [parentWidth, setParentWidth] = useState(0);
    const [parentHeight, setParentHeight] = useState(0);
    const [parentSecondRowElement, setParentSecondRowElement] = useState<HTMLElement | null>(null);
    const [visible, setVisible] = useState(false);
    const [pastSecondRow, setPastSecondRow] = useState(false);

    const classNames = classnames('fixed-table-header', {
        'fixed-table-header--visible': visible,
        'fixed-table-header--past-second-row': pastSecondRow,
    }, [className]);

    useEffect(() => {
        setParentElement(getParentElement(elementRef));
        setParentWidth(getParentElementWidth(elementRef));
        setParentSecondRowElement(getParentSecondRowElement(elementRef));
    }, [windowWidth, children]);

    useEffect(() => {
        setParentHeight(getParentElementHeight(elementRef));
    }, [windowWidth, children]);

    useEffect(() => {
        const parentIsTallerThanWindow = parentHeight > windowHeight;
        const scrolledPastParent = didElementScrollPastElement(scrollY, parentElement, true);
        const scrolledPastSecondRow = didElementScrollPastElement(scrollY, parentSecondRowElement);
        if (scrolledPastParent && (scrolledPastParent !== visible)) {
            setParentWidth(getParentElementWidth(elementRef));
        }
        setVisible(parentIsTallerThanWindow && scrolledPastParent);
        setPastSecondRow(scrolledPastSecondRow);
    }, [scrollY]);

    return (
        <div
            style={{ ...(parentWidth && { width: `${parentWidth}px` }) }}
            ref={elementRef}
            className={classNames}
        >
            {children}
        </div>
    );
};

export default FixedTableHeader;
