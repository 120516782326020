import { LEAVE_OF_ABSENCE_REQUESTS } from '../../constants';
import {
    ABSENCES_ADD_ABSENCE_FAILURE,
    ABSENCES_ADD_ABSENCE_REQUEST,
    ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_FAILURE,
    ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_REQUEST,
    ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_SUCCESS,
    ABSENCES_APPROVE_EXCHANGE_REQUEST_FAILURE,
    ABSENCES_APPROVE_EXCHANGE_REQUEST_REQUEST,
    ABSENCES_APPROVE_SHIFT_EXCHANGE_FAILURE,
    ABSENCES_APPROVE_SHIFT_EXCHANGE_REQUEST,
    ABSENCES_APPROVE_SHIFT_EXCHANGE_SUCCESS,
    ABSENCES_DECLINE_SHIFT_EXCHANGE_FAILURE,
    ABSENCES_DECLINE_SHIFT_EXCHANGE_REQUEST,
    ABSENCES_DECLINE_SHIFT_EXCHANGE_SUCCESS,
    ABSENCES_EDIT_ABSENCE_FAILURE,
    ABSENCES_EDIT_ABSENCE_REQUEST,
    ABSENCES_EDIT_ABSENCE_SUCCESS,
    ABSENCES_EXPORT_CSV_FAILURE,
    ABSENCES_EXPORT_CSV_REQUEST,
    ABSENCES_EXPORT_CSV_SUCCESS,
    ABSENCES_GET_ABSENCES_IN_DATE_RANGE_REQUEST,
    ABSENCES_GET_ABSENCES_IN_DATE_RANGE_SUCCESS,
    ABSENCES_GET_ABSENCES_REQUEST,
    ABSENCES_GET_ABSENCES_SUCCESS,
    ABSENCES_GET_CALENDAR_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS,
    ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_REQUEST,
    ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_SUCCESS,
    ABSENCES_GET_EXCHANGE_REQUESTS_REQUEST,
    ABSENCES_GET_EXCHANGE_REQUESTS_SUCCESS,
    ABSENCES_GET_LEAVE_OF_ABSENCE_REQUESTS_REQUEST,
    ABSENCES_GET_LOA_LEAVE_BALANCES_SUCCESS,
    ABSENCES_GET_OPEN_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS,
    ABSENCES_HANDLE_LEAVE_OF_ABSENCE_FAILURE,
    ABSENCES_HANDLE_LEAVE_OF_ABSENCE_REQUEST,
    ABSENCES_HANDLE_LEAVE_OF_ABSENCE_SUCCESS,
    ABSENCES_REPLACE_USER_IN_SHIFT_FAILURE,
    ABSENCES_REPLACE_USER_IN_SHIFT_REQUEST,
    ABSENCES_REPLACE_USER_IN_SHIFT_SUCCESS,
    ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_FAILURE,
    ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_REQUEST,
    ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_SUCCESS,
    APP_ROUTE_CHANGE,
    APP_SAGA_SIDEBAR_RESIZE,
    FILTER_SET_CALENDAR_VIEW_PROPS,
    FILTER_SET_FILTER,
    WEEK_NAVIGATOR_SET_DATES,
} from '../actionTypes';

const initialState = {
    startDate: null,
    endDate: null,
    loading: false,
    shiftsAndUsersLoading: false,
    leaveOfAbsencesStatus: null,
    syncLoketRequest: false,
    exportCsvRequest: false,
    leaveOfAbsencesPage: LEAVE_OF_ABSENCE_REQUESTS,
    leaveOfAbsencesRequests: [], // used for shiftPlannings and users
    openLeaveOfAbsencesRequests: [], // used in LeaveOfAbsenceRequests page
    calendarLeaveOfAbsencesRequests: [], // used in LeaveOfAbsencesCalendar page
    lastChangedLeaveOfAbsenceId: '',
    exchangeRequests: [],
    shiftExchanges: [],
    absences: [],
    absencesInDateRange: [],
};

const absencesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ABSENCES_GET_EXCHANGE_REQUESTS_REQUEST:
        case ABSENCES_APPROVE_EXCHANGE_REQUEST_REQUEST:
        case ABSENCES_GET_ABSENCES_REQUEST:
        case ABSENCES_GET_ABSENCES_IN_DATE_RANGE_REQUEST:
        case ABSENCES_EDIT_ABSENCE_REQUEST:
        case ABSENCES_ADD_ABSENCE_REQUEST:
        case ABSENCES_REPLACE_USER_IN_SHIFT_REQUEST:
        case ABSENCES_HANDLE_LEAVE_OF_ABSENCE_REQUEST:
        case ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_REQUEST:
        case ABSENCES_GET_LEAVE_OF_ABSENCE_REQUESTS_REQUEST:
        case ABSENCES_APPROVE_SHIFT_EXCHANGE_REQUEST:
        case ABSENCES_DECLINE_SHIFT_EXCHANGE_REQUEST:
            return {
                ...state,
                loading: true,
                ...(action.status && { leaveOfAbsencesStatus: action.status }),
                ...(action.page && { leaveOfAbsencesPage: action.page }),
            };
        case ABSENCES_EXPORT_CSV_REQUEST:
            return {
                ...state,
                exportCsvRequest: true,
            };
        case ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_REQUEST:
            return {
                ...state,
                shiftsAndUsersLoading: true,
                startDate: action.startDate,
                endDate: action.endDate,
            };
        case ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_SUCCESS:
            return {
                ...state,
                shiftsAndUsersLoading: false,
                leaveOfAbsencesRequests: action.leaveOfAbsencesRequests,
                absencesInDateRange: action.absences,
            };
        case ABSENCES_GET_OPEN_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                openLeaveOfAbsencesRequests: action.leaveOfAbsencesRequests,
            };
        case ABSENCES_GET_LOA_LEAVE_BALANCES_SUCCESS:
            return {
                ...state,
                openLeaveOfAbsencesRequests: action.leaveOfAbsencesRequests,
            };
        case ABSENCES_GET_CALENDAR_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                calendarLeaveOfAbsencesRequests: action.leaveOfAbsencesRequests,
            };
        case ABSENCES_REPLACE_USER_IN_SHIFT_SUCCESS:
        case ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_SUCCESS:
            return { ...state, loading: false };
        case ABSENCES_GET_EXCHANGE_REQUESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                exchangeRequests: action.exchangeRequests,
                shiftExchanges: action.shiftExchanges,
            };
        case ABSENCES_GET_ABSENCES_SUCCESS:
            return { ...state, loading: false, absences: action.absences };
        case ABSENCES_GET_ABSENCES_IN_DATE_RANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                absencesInDateRange: action.absences,
            };
        case ABSENCES_EXPORT_CSV_SUCCESS:
        case ABSENCES_EXPORT_CSV_FAILURE:
            return {
                ...state,
                exportCsvRequest: false,
            };
        case ABSENCES_ADD_ABSENCE_FAILURE:
        case ABSENCES_EDIT_ABSENCE_FAILURE:
        case ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_FAILURE:
        case ABSENCES_REPLACE_USER_IN_SHIFT_FAILURE:
        case ABSENCES_APPROVE_EXCHANGE_REQUEST_FAILURE:
        case ABSENCES_HANDLE_LEAVE_OF_ABSENCE_FAILURE:
        case ABSENCES_HANDLE_LEAVE_OF_ABSENCE_SUCCESS:
            return { ...state, loading: false, lastChangedLeaveOfAbsenceId: action.leaveOfAbsenceId || '' };
        case FILTER_SET_FILTER:
        case FILTER_SET_CALENDAR_VIEW_PROPS:
        case WEEK_NAVIGATOR_SET_DATES:
            return { ...state, lastChangedLeaveOfAbsenceId: '' };
        case ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_REQUEST:
            return { ...state, syncLoketRequest: true };
        case ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_SUCCESS:
        case ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_FAILURE:
            return { ...state, syncLoketRequest: false };
        case APP_SAGA_SIDEBAR_RESIZE:
            return { ...state, lastChangedLeaveOfAbsenceId: '' };
        case ABSENCES_EDIT_ABSENCE_SUCCESS:
        case ABSENCES_APPROVE_SHIFT_EXCHANGE_SUCCESS:
        case ABSENCES_DECLINE_SHIFT_EXCHANGE_SUCCESS:
            return { ...state, loading: false };
        case ABSENCES_APPROVE_SHIFT_EXCHANGE_FAILURE:
        case ABSENCES_DECLINE_SHIFT_EXCHANGE_FAILURE:
            return { ...state, loading: false };
        case APP_ROUTE_CHANGE:
            return {
                ...state, startData: null, endDate: null, lastChangedLeaveOfAbsenceId: '',
            };
        default:
            return state;
    }
};

export default absencesReducer;
