import React, { FC } from 'react';

import { Table } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { DocumentViewModel } from '../../../../models';
import SharedDocumentsRow from './subcomponents/SharedDocumentsRow/SharedDocumentsRow';


interface SharedDocumentsTableProps {
    documents: DocumentViewModel[];
    editDocument: (document: DocumentViewModel) => void;
    deleteDocument: (documentId: string) => void;
}

const SharedDocumentsTable: FC<SharedDocumentsTableProps> = ({
    documents,
    editDocument,
    deleteDocument,
}) => (
    <div className="table-container-responsive">
        <div className="table-container">
            <Table>
                <thead>
                    <tr>
                        <th>
                            {translate('common.name')}
                        </th>
                        <th>
                            {translate('common.category')}
                        </th>
                        <th>
                            {translate('common.userType')}
                        </th>
                        <th>
                            {translate('common.department')}
                        </th>
                        <th>
                            {translate('common.date')}
                        </th>
                        <th>
                            {translate('pages.pz.addedBy')}
                        </th>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {documents.map((document: DocumentViewModel) => (
                        <SharedDocumentsRow
                            key={document.id}
                            document={document}
                            editDocument={editDocument}
                            deleteDocument={deleteDocument}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    </div>
);

export default SharedDocumentsTable;
