import { FC } from 'react';

import { IconButton } from '../../../../compositions';
import trans from '../../../../helpers/trans';

import './MobileAddButton.scss';

interface MobileAddButtonProps {
    label?: string;
    onClick: () => void;
    className?: string;
}

const MobileAddButton: FC<MobileAddButtonProps> = ({ label, onClick, className = '' }) => (
    <IconButton
        hideLabel
        text={label || trans('common.add')}
        icon="plus"
        onClick={onClick}
        className={`mobile-add-button ${className}`}
    />
);

export default MobileAddButton;
