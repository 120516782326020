import { FC, FormEvent, ReactElement } from 'react';

import { FormActionButtons } from '../../../compositions';
import trans from '../../../helpers/trans';

import './DeleteShiftPlanningForm.scss';

interface DeleteShiftPlanningFormProps {
    isLoading: boolean;
    showDeleteWithin24hWarning: boolean;
    fullName: string;
    onCancel: () => void;
    onSubmit: () => void;
    className?: string;
}

const DeleteShiftPlanningForm: FC<DeleteShiftPlanningFormProps> = ({
    isLoading,
    showDeleteWithin24hWarning,
    fullName,
    onCancel,
    onSubmit,
    className = '',
}): ReactElement => {
    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        onSubmit();
    };

    return (
        <form onSubmit={handleSubmit} className={`delete-shift-planning-form ${className}`}>
            <div className="delete-shift-planning-form__row">
                {showDeleteWithin24hWarning && (
                    <p className="delete-shift-planning-form__intro">
                        <strong>{`${trans('containers.forms.deleteShiftPlanningForm.attention')}: `}</strong>

                        {trans('containers.forms.deleteShiftPlanningForm.24hWarning', { fullName })}
                    </p>
                )}

                <p className="delete-shift-planning-form__intro">
                    {trans('containers.forms.deleteShiftPlanningForm.intro', { fullName })}
                </p>
            </div>

            <FormActionButtons
                isLoading={isLoading}
                submitText={trans('common.delete')}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default DeleteShiftPlanningForm;
