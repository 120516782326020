import { Dispatch } from 'redux';

import { getSpecialDays } from '../../../helpers/api-ts/specialDay';
import { addDays } from '../../../helpers/date';
import { setSpecialDays } from './specialDaysReducer';

export const fetchSpecialDays = (
    startDate: Date,
    endDate: Date,
) => async (dispatch: Dispatch): Promise<void> => {
    try {
        const response = await getSpecialDays(addDays(startDate, -1), endDate);
        dispatch(setSpecialDays(response));
    } catch (error) {
        console.error('[fetchSpecialDays]', error);
    }
};
