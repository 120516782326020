import { FC } from 'react';

import { ElementWithTooltip } from '../../../compositions';
import { Absence } from '../../../entities/Absence/Absence';
import { getAbsenceTooltipText } from '../../../entities/Absence/AbsenceHelpers';
import trans from '../../../helpers/trans';
import { Icon } from '../../index';

import './AbsenceBadge.scss';

interface AbsenceBadgeProps {
    isCompact?: boolean;
    absence: Absence;
    className?: string;
}

const AbsenceBadge: FC<AbsenceBadgeProps> = ({ isCompact = false, absence, className = '' }) => (
    <ElementWithTooltip
        tooltipIsActive
        tooltipText={getAbsenceTooltipText(absence)}
    >
        <div className={`absence-badge ${className}`}>
            {isCompact ? (
                <Icon className="absence-badge__icon" name="medical-bag" />
            ) : (
                <div className="absence-badge__pill">
                    {trans('entities.absences.calledInSick')}
                </div>
            )}
        </div>
    </ElementWithTooltip>
);

export default AbsenceBadge;
