import React from 'react';

import { Icon } from '../../../@paco/components';
import { ElementWithTooltip } from '../../../@paco/compositions';
import { Setting } from '../../../@paco/entities/Setting/Setting';
import { getAbnormalTrackDurationThresholdSettingValue } from '../../../@paco/entities/Setting/SettingHelpers';
import trans from '../../../@paco/helpers/trans';
import InfoCircle from '../../../components/InfoCircle/InfoCircle';
import { TRACK_TYPE_FINISHED, TRACK_TYPE_REVIEW } from '../../../constants';
import {
    getIconById,
    getIconByMeal,
    getIconBySatisfaction,
    getObjProperty,
    getUserFullName,
} from '../../../helpers';
import transformTimeStringToNumber from '../../../helpers/date/transformTimeStringToNumber';
import { translate } from '../../../helpers/translations/translator';
import { TrackViewModel } from '../../../models';
import { transformBillableTimeToHourMinutes } from '../../../services/TrackService/getTotalBillableTimeFromTracks';
import ExclamationMarkBadge from '../compositions/ExclamationMarkBadge';

export function getTrackSatisfactionTooltip(track: any, canViewSatisfactionFeedback = false) {
    if (!track.feedback) {
        return null;
    }

    const remark = getObjProperty(track, 'feedback.remark');
    if (remark && canViewSatisfactionFeedback) {
        return remark;
    }

    return translate(`pages.tracks.${getObjProperty(track, 'feedback.rating') && getObjProperty(track, 'feedback.rating').toLowerCase()}`);
}

export function getTrackAssessorTooltip(track: any) {
    if (!track.assessor) {
        return null;
    }

    return `${translate('pages.tracks.approvedBy')} ${track.assessor}`;
}

export function getEditTrackFormTitle(editTrackType: string): string {
    if (editTrackType === TRACK_TYPE_REVIEW) {
        return translate('pages.tracks.editTrackAndApprove');
    }

    if (editTrackType === TRACK_TYPE_FINISHED) {
        return translate('pages.tracks.editTrack');
    }

    return translate('pages.tracks.stopTrack');
}

export function renderInfoCircles(track: TrackViewModel, canViewSatisfaction: boolean, canViewSatisfactionFeedback: boolean) {
    const feedbackRemark = canViewSatisfactionFeedback && getObjProperty(track, 'feedback.rating.remark');

    return (
        <>
            <InfoCircle
                text={track.pause}
                tooltip={`${track.pause} ${translate('pages.tracks.minutesBreakTime')}`}
            />
            <InfoCircle
                icon={getIconByMeal(track.meal)}
                tooltip={track.meal && translate(`pages.tracks.${track.meal.toLowerCase()}`)}
                iconClass={track.meal}
            />
            {track.assessor && (
                <InfoCircle
                    icon={getIconById('tie')}
                    tooltip={getTrackAssessorTooltip(track)}
                />
            )}
            {(track.feedback && canViewSatisfaction) && (
                <InfoCircle
                    icon={getIconBySatisfaction(getObjProperty(track, 'feedback.rating'))}
                    alt={translate('common.satisfaction')}
                    tooltip={feedbackRemark || getTrackSatisfactionTooltip(track)}
                    iconClass="smiley"
                />
            )}
            {(track.deviationRemark || track.unplannedRemark) && (
                <InfoCircle
                    icon={getIconById('remark')}
                    alt={translate('common.comment')}
                    tooltip={`${getUserFullName(track.owner)}: ${track.deviationRemark || track.unplannedRemark}`}
                    iconClass="balloon"
                />
            )}
            {track.managerComment && (
                <InfoCircle
                    icon={getIconById('remark')}
                    alt={translate('common.comment')}
                    tooltip={`${getUserFullName(track.managerComment.owner)}: ${track.managerComment.body}`}
                    iconClass="balloon"
                />
            )}
        </>
    );
}

export const renderWorkedTotalHours = (track: TrackViewModel, settings: Setting[], canViewOfflineTrackIcon: boolean) => {
    const abnormalTrackDurationTreshold = getAbnormalTrackDurationThresholdSettingValue(settings, track.department?.id);
    const totalBillableTime = transformTimeStringToNumber(track.totalBillableTime);
    const isAbnormal = totalBillableTime > abnormalTrackDurationTreshold;

    return (
        <span className="tracks__worked-total-hours">
            <div className="tracks__worked-total-hours__text">
                {`${transformBillableTimeToHourMinutes(track.totalBillableTime)} ${translate('common.hour').toLowerCase()} `}
            </div>
            {isAbnormal && <ExclamationMarkBadge className="tracks__worked-total-hours__icon" />}
            {canViewOfflineTrackIcon && track.isOffline && (
                <ElementWithTooltip
                    tooltipIsActive
                    tooltipText={trans('entities.tracks.offlineTrack')}
                    className="tracks__worked-total-hours__tooltip"
                >
                    <Icon name="offline" className="tracks__worked-total-hours__icon" />
                </ElementWithTooltip>
            )}
        </span>
    );
};
