import axios from 'axios';

import { TrackResource } from '../../models';
import { AcceptTrackRequest, EditTrackRequest } from './Track';

export const patchTrackApiCall = async (request: EditTrackRequest): Promise<boolean> => {
    await axios.patch<TrackResource>(`${process.env.REACT_APP_API_URL}/tracks/${request.data.id}`, request);

    return true;
};

export const patchAcceptTrackApiCall = async (request: AcceptTrackRequest): Promise<boolean> => {
    await axios.patch<TrackResource>(`${process.env.REACT_APP_API_URL}/tracks/${request.data.id}`, request);

    return true;
};

