import { FC } from 'react';

import trans from '../../helpers/trans';
import { ShiftsLegendColorTypes } from '../../types/shiftsLegendColorTypes';
import { Legend } from '../index';

import './ShiftsLegend.scss';

interface ShiftsLegendProps {
    legendItems?: ShiftsLegendColorTypes[],
    className?: string;
}

const ShiftsLegend: FC<ShiftsLegendProps> = ({
    legendItems = [
        ShiftsLegendColorTypes.plannedFull,
        ShiftsLegendColorTypes.plannedOpen,
        ShiftsLegendColorTypes.conceptFull,
        ShiftsLegendColorTypes.conceptOpen,
        ShiftsLegendColorTypes.selected,
        ShiftsLegendColorTypes.companyMedic,
        ShiftsLegendColorTypes.pastShift,
    ],
    className = '',
}) => {
    const colors = legendItems.map(item => ({
        title: trans(`compositions.shiftsLegend.${item}`),
        type: item,
    }));

    return (
        <Legend
            colors={colors}
            buttonClassName="shifts-legend__button"
            className={className}
        />
    );
};

export default ShiftsLegend;
