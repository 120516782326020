import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import classnames from 'classnames';
import {
    Button,
    Col,
    CustomInput,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { getUserFullName } from '../../../../helpers';
import { translate } from '../../../../helpers/translations/translator';
import {
    EditShiftPlanningFormData,
    ShiftPlanningViewModel,
} from '../../../../models';
import generateEmptyEditShiftPlanningFormDataValues from './helpers/generateEmptyEditShiftPlanningFormDataValues';
import transformShiftPlanningToEditShiftPlanningFormData
    from './helpers/transformSiftPlanningToEditShiftPlanningFormData';
import validateFormData from './helpers/validateFormData';

import './EditShiftPlanningForm.scss';


interface EditShiftPlanningFormProps {
    data: ShiftPlanningViewModel;
    className?: string;
    isTemporaryWorker?: boolean;
    showComment?: boolean;
    onCancel?: () => void;
    onSubmit?: (data: EditShiftPlanningFormData) => void;
}

const EditShiftPlanningForm: FC<EditShiftPlanningFormProps> = ({
    data,
    className,
    isTemporaryWorker = false,
    showComment = false,
    onCancel,
    onSubmit,
}) => {
    const [formData, setFormData] = useState<EditShiftPlanningFormData>(generateEmptyEditShiftPlanningFormDataValues());
    const [isSubmitted, setIsSubmitted] = useState(false);
    const validationErrors = useMemo(() => validateFormData(formData, isSubmitted), [formData, isSubmitted]);

    const name = useMemo(() => getUserFullName(data), [data]);
    const title = useMemo(
        () => (isTemporaryWorker
        ? `${translate('common.tempWorker')} ${name} ${translate('common.edit').toLowerCase()}`
        : `${translate('common.shift')} ${name} ${translate('common.edit').toLowerCase()}`),
        [name, isTemporaryWorker],
    );
    const classNames = useMemo(() => classnames('edit-shift-planning-form', {
        'edit-shift-planning-form--show-comment': showComment,
    }, [className]), [showComment, className]);
    const canSubmit = formData.startTime && formData.endTime;

    const handleChangeStartTime = (e: React.FormEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            startTime: e.currentTarget.value,
        });
    };

    const handleChangeEndTime = (e: React.FormEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            endTime: e.currentTarget.value,
        });
    };

    const handleChangeComment = (e: React.FormEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            comment: e.currentTarget.value,
        });
    };

    const handleSubmitButtonClick = () => {
        setIsSubmitted(true);

        if (!Object.keys(validateFormData(formData)).length && onSubmit) {
            onSubmit(formData);
        }
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    useEffect(() => {
        setFormData(transformShiftPlanningToEditShiftPlanningFormData(data));
    }, [data]);

    return (
        <form className={classNames} onSubmit={handleFormSubmit}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.tracks.startTime')}</Label>
                            <CustomInput
                                type="time"
                                className="form-control"
                                id="startTime"
                                name="startTime"
                                invalid={!!validationErrors.startTime}
                                value={formData.startTime}
                                onChange={handleChangeStartTime}
                            />
                            <FormFeedback>
                                { validationErrors.startTime }
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.tracks.endTime')}</Label>
                            <CustomInput
                                type="time"
                                className="form-control"
                                id="endTime"
                                name="endTime"
                                value={formData.endTime}
                                invalid={!!validationErrors.startTime}
                                onChange={handleChangeEndTime}
                            />
                            <FormFeedback>
                                { validationErrors.startTime }
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="edit-shift-planning-form__row-comment">
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.note')}</Label>
                            <Input
                                type="textarea"
                                rows={4}
                                className="form-control"
                                id="comment"
                                name="comment"
                                value={formData.comment}
                                placeholder={translate('pages.shifts.shiftPlanningWriteRemark')}
                                onChange={handleChangeComment}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="link"
                    id="modal-close"
                    onClick={onCancel}
                >
                    {translate('common.cancel')}
                </Button>
                <Button
                    type="submit"
                    color="orange"
                    disabled={!canSubmit}
                    onClick={handleSubmitButtonClick}
                >
                    {translate('common.save')}
                </Button>
            </ModalFooter>
        </form>
    );
};

export default EditShiftPlanningForm;
