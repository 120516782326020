import { ShiftPlanningViewModel } from '../../models';

const compareShiftPlanningUsersAlphabetically = (a: ShiftPlanningViewModel, b: ShiftPlanningViewModel): number => {
    const firstnameSort = (a.user?.firstname || '').localeCompare(b.user?.firstname || '');
    if (firstnameSort !== 0) {
        return firstnameSort;
    }

    return (a.user?.lastname || '').localeCompare((b.user?.lastname || ''));
};

export default compareShiftPlanningUsersAlphabetically;
