import { FC, useMemo } from 'react';

import Card from '../../../components/Card/Card';
import { hasUncheckedDivergentEmploymentHours } from '../../entities/Employment/EmploymentHelpers';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';
import { UserWithEmployment } from '../../entities/User/User';
import trans from '../../helpers/trans';
import { IncompleteUsersTable } from '../index';

import './CompletePeriodIncompleteUsersContainer.scss';

interface CompletePeriodIncompleteUsersContainerProps {
    isLoading: boolean;
    activePeriod?: PayrollPeriod;
    usersWithDivergentEmploymentHours: UserWithEmployment[];
    onEditUserClick: (user: UserWithEmployment) => void;
    onCheckUserClick: (user: UserWithEmployment, isChecked: boolean) => void;
    className?: string;
}

const CompletePeriodIncompleteUsersContainer: FC<CompletePeriodIncompleteUsersContainerProps> = ({
    isLoading,
    activePeriod,
    usersWithDivergentEmploymentHours,
    onEditUserClick,
    onCheckUserClick,
    className = '',
}) => {
    const usersWithIncompleteDivergentEmploymentHours = useMemo(
        () => usersWithDivergentEmploymentHours.filter(user => (
            activePeriod ? hasUncheckedDivergentEmploymentHours(user.employment, activePeriod) : false)),
        [activePeriod, usersWithDivergentEmploymentHours],
    );

    return (
        <div className={`complete-period-incomplete-users-container ${className}`}>
            <Card
                title={`${trans('containers.completePeriodIncompleteUsersContainer.usersWithIncompleteEmployment')} (${usersWithIncompleteDivergentEmploymentHours.length})`}
                className="complete-period-detail-incomplete-leave-of-absences-container__card"
            />

            {((isLoading || !!usersWithIncompleteDivergentEmploymentHours.length) && activePeriod) && (
                <IncompleteUsersTable
                    isLoading={isLoading}
                    activePeriod={activePeriod}
                    usersWithDivergentEmploymentHours={usersWithDivergentEmploymentHours}
                    onEditUserClick={onEditUserClick}
                    onCheckUserClick={onCheckUserClick}
                    className="complete-period-incomplete-users-container__table"
                />
            )}
        </div>
    );
};

export default CompletePeriodIncompleteUsersContainer;
