import { Dispatch } from 'redux';

import { TOAST_TYPE_PASS } from '../../../../constants';
import { EditTrackFormData } from '../../../../entities/Track/Track';
import { patchAcceptTrackApiCall, patchTrackApiCall } from '../../../../entities/Track/TrackService';
import { transformToAcceptTrackRequest, transformToEditTrackRequest } from '../../../../entities/Track/TrackTransformers';
import { getIncludes } from '../../../../helpers/api/tracksApi';
import { getTracksToReviewInDateRange } from '../../../../helpers/api-ts/track';
import { translate } from '../../../../helpers/translations/translator';
import { PayrollPeriodViewModel, TrackType } from '../../../../models';
import { setAppToast } from '../../../app/appActions';
import { setIsSuccessful } from '../openTracks/openTracksReducer';
import { setError, setIsLoading, setTracksToReview } from './tracksToReviewReducer';

export const fetchTracksToReview = (payrollPeriod: PayrollPeriodViewModel) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const { start, end } = payrollPeriod;

    try {
        const response = await getTracksToReviewInDateRange(
            start,
            end.date,
            getIncludes(TrackType.review),
        );

        dispatch(setTracksToReview(response.data));
    } catch (error) {
        console.error('[fetchTracksToReview]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editTrackToReview = (formData: EditTrackFormData, payrollPeriod: PayrollPeriodViewModel) => async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(setIsSuccessful(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const request = transformToEditTrackRequest(formData);

        await patchTrackApiCall(request);
        await dispatch(fetchTracksToReview(payrollPeriod));

        dispatch(setAppToast(translate(`pages.tracks.${formData.accepted ? 'editAndApproveTrackSuccess' : 'editTrackSuccess'}`), TOAST_TYPE_PASS));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editTrackToReview]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const acceptTrack = (trackId: string, payrollPeriod: PayrollPeriodViewModel) => async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(setIsSuccessful(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const request = transformToAcceptTrackRequest(trackId);

        await patchAcceptTrackApiCall(request);
        await dispatch(fetchTracksToReview(payrollPeriod));

        dispatch(setIsSuccessful(true));
        dispatch(setAppToast(translate('pages.tracks.approveTrackSuccess'), TOAST_TYPE_PASS));
    } catch (error) {
        console.error('[acceptTrack]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
