import { DepartmentGroupResource, DepartmentViewModel } from '../../../../entities/Departments/Departments';
import getDepartmentGroupSizeStatus from '../../SelectDepartment/helpers/getDepartmentGroupSizes';

export default function getFullDepartmentGroups(
    departments: DepartmentViewModel[],
    mainDepartment: DepartmentViewModel | null,
    allDepartments: DepartmentViewModel[],
): DepartmentGroupResource[] {
    if (!departments) {
        return [];
    }

    const groupSizes = getDepartmentGroupSizeStatus(departments, allDepartments, mainDepartment);

    return Object.values(groupSizes)
        .filter(value => value.isFull)
        .map(value => value.group);
}
