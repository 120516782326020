import { FC, ReactElement } from 'react';

import { Page, SidebarPageWrapper } from '../../components';
import { Sidebar } from '../../compositions';
import { ConnectedDistrictDivisionTemplates, ConnectedDistrictDivisionTemplatesFilter } from '../../connectors';

const DistrictDivisionTemplatesPage: FC = (): ReactElement => (
    <SidebarPageWrapper>
        <Sidebar>
            <ConnectedDistrictDivisionTemplatesFilter />
        </Sidebar>
        <Page>
            <ConnectedDistrictDivisionTemplates />
        </Page>
    </SidebarPageWrapper>
);

export default DistrictDivisionTemplatesPage;
