import { authorizedFetch } from '../../helpers/authorizedFetch';
import { formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { generateApiUrl, generateApiV3Url } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../../japi/types/Document';
import { DateFormat } from '../../types/dateFormatTypes';
import { TrackExportType } from '../../types/trackExportType';
import { TrackType } from '../../types/trackTypes';
import { DepartmentResource } from '../Department/Department';
import { transformToDepartment } from '../Department/DepartmentTransformers';
import { DepartmentGroupResource } from '../DepartmentGroup/DepartmentGroup';
import { EmploymentTypeResource, EmploymentTypeSlug } from '../EmploymentType/EmploymentType';
import { getDummyEmploymentTypeResource } from '../EmploymentType/EmploymentTypeHelpers';
import { transformToEmploymentType } from '../EmploymentType/EmploymentTypeTransformers';
import { ExperienceResource } from '../Experience/Experience';
import { getDummyExperienceResource } from '../Experience/ExperienceHelpers';
import { transformToExperience } from '../Experience/ExperienceTransformers';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { Period } from '../Period/Period';
import { PersonResource } from '../Person/Person';
import { transformToPerson } from '../Person/PersonTransformers';
import { ShiftResource } from '../Shift/Shift';
import { transformToTrackShift } from '../Shift/ShiftTransformers';
import { UserResource } from '../User/User';
import { transformToTrackOwner } from '../User/UserTransformers';
import {
    ExportTracksFormData,
    Track,
    TrackExportData,
    TrackResource,
} from './Track';
import { transformToTrack, transformTrackExportResponseToTrackExportData } from './TrackTransformers';

interface TracksFilters {
    departments?: string[];
    employeeSearch?: string;
    employmentTypeSlugs?: EmploymentTypeSlug[];
    trackType?: TrackType;
    userIds?: string[];
}

export const getTracksApiCall = async (
    period: Period,
    filters: Pick<TracksFilters, 'departments' | 'userIds'>,
): Promise<FetchCollectionResult<Track[], string>> => {
    try {
        const { departments, userIds } = filters;

        const includes = [
            'department',
            'department.group',
            'owner.departments',
            'owner.employmentType',
            'owner.experience',
            'owner.person',
            'owner.roles',
            'shift.department',
            'shift.department.group',
        ];

        const url = generateApiUrl({
            endpoint: '/tracks',
            queryParams: {
                ...((departments && departments.length) && { 'departments[]': departments.join('departments[]=') }),
                ...((userIds && userIds.length) && { userUuids: JSON.stringify(userIds) }),
                startDate: formatDate(period.start, DateFormat.apiDateTime),
                endDate: formatDate(period.end, DateFormat.apiDateTime),
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<TrackResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const tracks = doc.data.map(track => {
            const ownerResource = getIncluded(doc, track, 'owner') as UserResource;
            const ownerEmploymentTypeResource = getIncluded(doc, ownerResource, 'employmentType') as EmploymentTypeResource;
            const ownerExperienceResource = getIncluded(doc, ownerResource, 'experience') as ExperienceResource;
            const ownerPersonResource = getIncluded(doc, ownerResource, 'person') as PersonResource;

            // TODO: Missing resources bug. BE should fix this in VALK-2240.
            if (!ownerEmploymentTypeResource) {
                console.error(`[getDashboardTracksApiCall] Missing employmentType resource in user with id ${ownerResource.id} (${ownerResource.attributes.firstname})`);
            }

            if (!ownerExperienceResource) {
                console.error(`[getDashboardTracksApiCall] Missing experience resource in user with id ${ownerResource.id} (${ownerResource.attributes.firstname})`);
            }

            const person = transformToPerson(ownerPersonResource);
            const employmentType = transformToEmploymentType(ownerEmploymentTypeResource || getDummyEmploymentTypeResource(`${ownerResource.id}-employment-type`));
            const experience = transformToExperience(ownerExperienceResource || getDummyExperienceResource(`${ownerResource.id}-experience`));

            const owner = transformToTrackOwner(
                ownerResource,
                person,
                employmentType,
                experience,
            );

            const trackDepartmentResource = getIncluded(doc, track, 'department') as DepartmentResource;
            const trackDepartmentGroupResource = getIncluded(doc, trackDepartmentResource, 'group') as DepartmentGroupResource;
            const trackDepartment = trackDepartmentResource ? transformToDepartment(trackDepartmentResource, trackDepartmentGroupResource) : undefined;
            const shiftResource = getIncluded(doc, track, 'shift') as ShiftResource;
            const shiftDepartmentResource = shiftResource ? getIncluded(doc, shiftResource, 'department') as DepartmentResource : undefined;
            const shiftDepartmentGroupResource = shiftDepartmentResource ? getIncluded(doc, shiftDepartmentResource, 'group') as DepartmentGroupResource : undefined;

            const shiftDepartment = shiftDepartmentResource ? transformToDepartment(shiftDepartmentResource, shiftDepartmentGroupResource) : undefined;
            const shift = (shiftResource && shiftDepartment) ? transformToTrackShift(shiftResource, shiftDepartment) : undefined;

            return transformToTrack(
                track,
                owner,
                shift,
                trackDepartment,
            );
        });

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: tracks,
        };
    } catch (error) {
        console.error('[getDashboardTracksApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getUserExportCsvApiCall = async (formData: ExportTracksFormData): Promise<FetchResult<TrackExportData, string>> => {
    try {
        const {
            payrollPeriodIds,
            departmentIds,
            employmentTypeIds,
            search,
            userIds,
        } = formData;

        const url = generateApiUrl({
            endpoint: '/users/export/csv',
            queryParams: {
                ...((payrollPeriodIds || []) && { payrollPeriods: JSON.stringify(payrollPeriodIds) }),
                ...((employmentTypeIds || []).length && { employmentTypes: JSON.stringify(employmentTypeIds) }),
                ...((departmentIds || []).length && { departments: JSON.stringify(departmentIds) }),
                ...((userIds || []).length && { users: JSON.stringify(userIds) }),
                ...(search && { search }),
                'page[number]': 1,
            },
        });

        const response = await authorizedFetch(url);
        const blob = await response.blob();

        if (response.status !== 200 || !blob) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = transformTrackExportResponseToTrackExportData(
            window.URL.createObjectURL(blob),
            trans('entities.tracks.fileNames.users'),
            'csv',
            formData,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[getUserExportCsvApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getUserExportLoketCsvApiCall = async (formData: ExportTracksFormData): Promise<FetchResult<TrackExportData, string>> => {
    try {
        const {
            payrollPeriodIds,
            departmentIds,
            search,
        } = formData;

        const url = generateApiUrl({
            endpoint: '/users/export/loket-csv',
            queryParams: {
                ...(payrollPeriodIds?.length && { payrollPeriod: payrollPeriodIds[0] }),
                ...((departmentIds || []).length && { departments: JSON.stringify(departmentIds) }),
                ...(search && { search }),
                'page[number]': 1,
            },
        });

        const response = await authorizedFetch(url);
        const blob = await response.blob();

        if (response.status !== 200 || !blob) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = transformTrackExportResponseToTrackExportData(
            window.URL.createObjectURL(blob),
            trans('entities.tracks.fileNames.usersLoket'),
            'csv',
            formData,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[getUserExportLoketCsvApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getTracksCsvApiCall = async (formData: ExportTracksFormData): Promise<FetchResult<TrackExportData, string>> => {
    try {
        const {
            payrollPeriodIds,
            departmentIds,
            employmentTypeIds,
            endDate,
            search,
            startDate,
        } = formData;

        const url = generateApiUrl({
            endpoint: '/tracks/csv',
            queryParams: {
                ...(payrollPeriodIds?.length && { payrollPeriod: payrollPeriodIds[0] }),
                ...((employmentTypeIds || []).length && { employmentTypes: JSON.stringify(employmentTypeIds) }),
                ...((departmentIds || []).length && { departments: JSON.stringify(departmentIds) }),
                ...(search && { search }),
                approved: 1,
                startDate: formatDate(startDate, DateFormat.apiDateTime),
                endDate: formatDate(endDate, DateFormat.apiDateTime),
                'page[number]': 1,
            },
        });

        const response = await authorizedFetch(url);
        const blob = await response.blob();

        if (response.status !== 200 || !blob) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = transformTrackExportResponseToTrackExportData(
            window.URL.createObjectURL(blob),
            trans('entities.tracks.fileNames.tracks'),
            'csv',
            formData,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[getTracksCsvApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getExportWorkCostsApiCall = async (formData: ExportTracksFormData): Promise<FetchResult<TrackExportData, string>> => {
    try {
        const { endDate, startDate } = formData;

        const url = generateApiV3Url({
            endpoint: '/export/work-costs',
            queryParams: {
                start: formatDate(startDate, DateFormat.apiDateTime),
                end: formatDate(endDate, DateFormat.apiDateTime),
            },
        });

        const response = await authorizedFetch(url);
        const blob = await response.blob();

        if (response.status !== 200 || !blob) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = transformTrackExportResponseToTrackExportData(
            window.URL.createObjectURL(blob),
            trans('entities.tracks.fileNames.workRelatedCostsScheme'),
            'xlsx',
            formData,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[getExportWorkCostsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getExportTracksApiCall = async (formData: ExportTracksFormData): Promise<FetchResult<TrackExportData, string>> => {
    if (formData.exportType === TrackExportType.hourOverview) {
        return getUserExportCsvApiCall(formData);
    }

    if (formData.exportType === TrackExportType.loket) {
        return getUserExportLoketCsvApiCall(formData);
    }

    if (formData.exportType === TrackExportType.workRelatedCostsScheme) {
        return getExportWorkCostsApiCall(formData);
    }

    return getTracksCsvApiCall(formData);
};
