import {
    LOGS_CLEAR_RESOURCE_LOGS,
    LOGS_GET_DELETED_LOGS_FAILURE,
    LOGS_GET_DELETED_LOGS_REQUEST,
    LOGS_GET_DELETED_LOGS_SUCCESS,
    LOGS_GET_LOKET_LOGS_FAILURE,
    LOGS_GET_LOKET_LOGS_REQUEST,
    LOGS_GET_LOKET_LOGS_SUCCESS,
    LOGS_GET_RESOURCE_LOGS_REQUEST,
    LOGS_GET_RESOURCE_LOGS_SUCCESS,
} from '../actionTypes';

const initialState = {
    deletedLogs: [],
    logsResourceId: undefined,
    logs: null,
    loketLogs: [],
    loading: false,
};

const logsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGS_GET_DELETED_LOGS_REQUEST:
        case LOGS_GET_LOKET_LOGS_REQUEST:
            return { ...state, loading: true };
        case LOGS_GET_RESOURCE_LOGS_REQUEST:
            return {
                ...state,
                loading: true,
                logs: [],
                logsResourceId: action.id,
            };
        case LOGS_GET_DELETED_LOGS_SUCCESS:
            return { ...state, deletedLogs: action.logs, loading: false };
        case LOGS_GET_DELETED_LOGS_FAILURE:
        case LOGS_GET_LOKET_LOGS_FAILURE:
            return { ...state, loading: false };
        case LOGS_GET_LOKET_LOGS_SUCCESS:
            return { ...state, loketLogs: action.loketLogs, loading: false };
        case LOGS_GET_RESOURCE_LOGS_SUCCESS:
            return { ...state, logs: action.logs, loading: false };
        case LOGS_CLEAR_RESOURCE_LOGS:
            return { ...state, logs: null };
        default:
            return state;
    }
};

export default logsReducer;
