import React, { FC, ReactElement, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { useTypedDispatch, useTypedSelector } from '../../../@paco/redux/store';
import { PzLeaveType } from '../../../@paco/types/leaveType';
import { CompletePeriodOpenLeaveOfAbsencesContainer } from '../../../containers';
import {
    LeaveOfAbsence,
    LeaveOfAbsenceEditType,
    LeaveOfAbsenceFormData,
    LeaveType,
    PayrollPeriodViewModel,
    PayrollPeriodWithHours,
    WeekWithHours,
} from '../../../models';
import { editLeaveOfAbsence } from '../../../redux/@toolkit/leaveOfAbsences/leaveOfAbsenceActions';
import { fetchOpenLeaveOfAbsences } from '../../../redux/@toolkit/leaveOfAbsences/openLeaveOfAbsences/openLeaveOfAbsencesActions';
import { Reducers } from '../../../redux/reducers';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../../services/PayrollPeriodService/transformPayrollPeriodLegacy';

interface ConnectedCompletePeriodOpenLeaveOfAbsencesProps {
    activePeriod?: PayrollPeriod;
    className?: string;
}

const ConnectedCompletePeriodOpenLeaveOfAbsences: FC<ConnectedCompletePeriodOpenLeaveOfAbsencesProps> = ({ activePeriod, className }): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoadingOpenLeaveOfAbsence = useSelector<Reducers, boolean>(state => state.openLeaveOfAbsencesReducer.isLoading);
    const isLoadingLeaveOfAbsence = useSelector<Reducers, boolean>(state => state.leaveOfAbsenceReducer.isLoading);
    const isSuccessful = useSelector<Reducers, boolean>(state => state.leaveOfAbsenceReducer.isSuccessful);
    const leaveOfAbsences = useSelector<Reducers, LeaveOfAbsence[]>(state => state.openLeaveOfAbsencesReducer.openLeaveOfAbsences);
    const { departments, permissions, user } = useTypedSelector(state => state.authenticatedUserReducer);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);
    const legacyPayrollPeriods = useSelector<Reducers, PayrollPeriodViewModel[]>(state => state.appReducer.payrollPeriods);

    const handleSubmitLeaveOfAbsence = (
        type: LeaveOfAbsenceEditType,
        data: LeaveOfAbsenceFormData,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        leaveOfAbsence: LeaveOfAbsence,
        leaveType?: LeaveType | PzLeaveType,
        declineComment?: string,
    ): void => {
        if (activePeriod) {
            dispatch(editLeaveOfAbsence(
                type,
                data,
                payrollPeriodsWithHours,
                weeksWithHours,
                leaveOfAbsence,
                leaveType,
                declineComment,
            ));
        }
    };

    useEffect((): void => {
        if (activePeriod && isSuccessful) {
            const legacyPayrollPeriod = transformPayrollPeriodToLegacyPayrollPeriod(activePeriod);
            dispatch(fetchOpenLeaveOfAbsences(legacyPayrollPeriod, departments));
        }
    }, [isSuccessful]);

    return (
        <CompletePeriodOpenLeaveOfAbsencesContainer
            isLoading={isLoadingOpenLeaveOfAbsence || isLoadingLeaveOfAbsence || !user}
            leaveOfAbsences={leaveOfAbsences}
            currentUser={user}
            pacoPayrollPeriods={payrollPeriods}
            permissions={permissions}
            payrollPeriods={legacyPayrollPeriods}
            onSubmitLeaveOfAbsence={handleSubmitLeaveOfAbsence}
            className={className}
        />
    );
};

export default ConnectedCompletePeriodOpenLeaveOfAbsences;
