import {
    FC,
    MouseEvent,
    ReactElement,
    useMemo,
} from 'react';

import classnames from 'classnames';

import { DepartmentBadge } from '../../../../components';
import { Department } from '../../../../entities/Department/Department';
import { DepartmentGroupIndex } from '../../../../entities/DepartmentGroup/DepartmentGroup';
import { IconButton } from '../../..';

import './DepartmentGroupButtons.scss';

interface DepartmentGroupButtonsProps {
    group: DepartmentGroupIndex,
    mainDepartment?: Department,
    className?: string,
}

const DepartmentGroupButtons: FC<DepartmentGroupButtonsProps> = ({
    group,
    mainDepartment,
    className = '',
}): ReactElement => {
    const hasMainDepartment = group.departments.some(
        department => department.id === (mainDepartment && mainDepartment.id),
    );
    const buttonClassNames = classnames('department-group-buttons__group-button', {
        'department-group-buttons__group-button--has-main-department': hasMainDepartment,
    });

    const sortedDepartments = useMemo(() => group.departments.sort(
        (a, b) => a.name.localeCompare(b.name),
    ), [group]);

    const onGroupButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
        // This will prevent the click event from bubbling up to the table row. Preventing it from routing when
        // user clicks on this component inside the table.
        e.stopPropagation();

        // Unlike other browsers, on safari clicking a button won't focus it.
        e.currentTarget.focus();
    };

    return (
        <span className={`department-group-buttons ${className}`}>
            <IconButton
                icon="chevron-down"
                iconPos="right"
                text={`${group.name} (${group.departments.length})`}
                onClick={onGroupButtonClick}
                className={buttonClassNames}
                iconClassName="department-group-buttons__group-button-icon"
            />
            {!!group.departments.length && sortedDepartments.map(department => (
                <DepartmentBadge
                    isGrouped
                    isDeleted={!!department.deletedAt}
                    isMainDepartment={department.id === (mainDepartment && mainDepartment.id)}
                    key={department.id}
                    name={department.name}
                    className="department-group-buttons__department-badge"
                />
            ))}
        </span>
    );
};

export default DepartmentGroupButtons;
