import React, { FC } from 'react';

import { formatDate } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import { Log } from '../../../../models/Log';
import UserPageLink from '../../../UserPageLink/UserPageLink';
import { getTranslationForPropName } from '../../helpers';
import LogBlockItem from '../LogBlockItem/LogBlockItem';

import '../LogBlock/LogBlock.scss';

interface LogBlockProps {
    log: Log;
}

const LoketLogBlock: FC<LogBlockProps> = ({ log }) => {
    const { actor, loggedAt } = log;
    const actorFullName = actor?.fullName || translate('common.unknown');
    const actorId = actor?.uuid;

    const renderBlockItems = () => Object.keys(log.changes).map((key) => {
        const change = log.changes[key];
        const propName = getTranslationForPropName(key);

        return (
            <LogBlockItem
                action={log.action}
                key={`${log.id}-${key}`}
                propName={propName}
                oldValue={change.oldValue || ''}
                newValue={change.newValue || ''}
            />
        );
    });

    return (
        <div className="log-block log-block--is-loket">
            <div className="log-block__circle" />
            <div className="log-block__mutations">
                <div className="log-block__title">
                    <span className="log-block__bold">
                        {translate(`logs.${log.name}`)}
                    </span>
                </div>
                <table>
                    <tbody>
                        { renderBlockItems() }
                    </tbody>
                </table>
            </div>
            <div className="log-block__actor">
                {`${formatDate(loggedAt, 'HH:mm')} ${translate('common.by').toLowerCase()} `}
                <UserPageLink className="log-block__actor-link" name={actorFullName} id={actorId} />
            </div>
        </div>
    );
};

export default LoketLogBlock;
