import { getOverlappingPayrollPeriodOnWorkday } from '../../helpers';
import { PayrollPeriodViewModel, PayrollPeriodWithHours, WeekWithHours } from '../../models';

function transformPayrollPeriodToPayrollPeriodWithHours(
    payrollPeriod: PayrollPeriodViewModel,
    hours: number | null,
): PayrollPeriodWithHours {
    return {
        ...payrollPeriod,
        hours,
    };
}

export default function transformWeeksWithHoursToPayrollPeriods(
    weeks: WeekWithHours[],
    payrollPeriods: PayrollPeriodViewModel[],
): PayrollPeriodWithHours[] {
    const periodsWithHours = weeks
        .map(week => {
            const match = payrollPeriods
                .find(payrollPeriod => getOverlappingPayrollPeriodOnWorkday(payrollPeriod, week.start));

            return match ? transformPayrollPeriodToPayrollPeriodWithHours(match, week.hours) : undefined;
        }).filter(payrollPeriod => !!payrollPeriod) as unknown as PayrollPeriodWithHours[];

    return periodsWithHours
        .reduce((total: PayrollPeriodWithHours[], payrollPeriod) => {
            const indexedPeriod = total.find(period => payrollPeriod.id === period.id);

            if (indexedPeriod) {
                return [
                    ...total.filter((period) => period.id !== indexedPeriod.id),
                    {
                        ...indexedPeriod,
                        hours: (indexedPeriod.hours || 0) + (payrollPeriod.hours || 0),
                    },
                ];
            }

            return [
                ...total,
                payrollPeriod,
            ];
        }, []);
}
