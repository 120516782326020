import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Controls from './Controls/Controls';

const months = {
    nl: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
};

class MonthNavigator extends Component {
    state = {
        dayPickerIsOpen: false,
    };

    onIncrement = (increment) => {
        const { date } = this.props;
        const newDate = date.setMonth(date.getMonth() + (increment ? 1 : -1));

        this.props.onMonthChange(new Date(newDate));
    };

    toggleDayPicker = () => {
        const { dayPickerIsOpen } = this.state;
        this.setState({
            dayPickerIsOpen: !dayPickerIsOpen,
        });
    };

    render() {
        // TODO add daypicker like in WeekNavigator
        // const { dayPickerIsOpen } = this.state;
        const { date } = this.props;

        return (
            <Controls
                month={months.nl[date.getMonth()]}
                year={date.getUTCFullYear()}
                onIncrement={this.onIncrement}
                toggleDayPicker={this.toggleDayPicker}
            />
        );
    }
}

MonthNavigator.propTypes = {
    date: PropTypes.object.isRequired,
    onMonthChange: PropTypes.func.isRequired,
};

export default MonthNavigator;
