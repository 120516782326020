import {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import { LoadingSpinner, Toggle } from '../../components';
import { CheckboxGroupList, EmptyState } from '../../compositions';
import { CheckboxChange } from '../../compositions/GroupedCheckboxes/CheckboxGroup';
import { Permission } from '../../entities/Permission/Permission';
import { PermissionGroup } from '../../entities/PermissionGroup/PermissionGroup';
import coupledPermissions from '../../helpers/permissions/coupledPermissions';
import trans from '../../helpers/trans';
import { getConfigurablePermissionsChildrenIds, transformPermissionGroupToFormOptions, transformPermissionsToConfigurablePermissions } from './helpers';

import './PermissionsList.scss';

interface PermissionsListProps {
    isLoading: boolean;
    userPermissions: Permission[];
    configurableUserPermissions: Permission[];
    permissionGroups: PermissionGroup[];
    className?: string;
    onPermissionsChange: (permissionIds: string[]) => void;
}

const PermissionsList: FC<PermissionsListProps> = ({
    isLoading,
    userPermissions,
    configurableUserPermissions,
    permissionGroups,
    onPermissionsChange,
    className = '',
}): ReactElement => {
    const [isActive, setIsActive] = useState<boolean>(false);

    const configurablePermissions = transformPermissionsToConfigurablePermissions(configurableUserPermissions, coupledPermissions);

    const permissionOptionsGroups = useMemo(() => permissionGroups.map(assignablePermissionGroup => transformPermissionGroupToFormOptions(
        assignablePermissionGroup,
        userPermissions,
        configurablePermissions,
    )), [userPermissions, configurablePermissions]);

    const filteredAssignablePermissionsGroups = permissionOptionsGroups.map(permissionOptionsGroup => {
        const options = permissionOptionsGroup.options.filter(permissionOption => !permissionOption.isDisabled);

        return { ...permissionOptionsGroup, options };
    }).filter(permissionGroup => permissionGroup.options.length);

    const activePermissionOptionGroups = isActive ? permissionOptionsGroups : filteredAssignablePermissionsGroups;

    const handleGroupChange = (changes: CheckboxChange[], groupId: string): void => {
        if (!changes.length) {
            return;
        }

        const newConfigurablePermissionIds = changes.filter(change => change.checked).map(change => change.value) as string[];

        const existingPermissionsToSubmit = permissionOptionsGroups
            .flatMap(permissionOptionGroup => permissionOptionGroup.options
                .filter(permissionOption => !permissionOption.isDisabled && permissionOption.isChecked && permissionOption.group?.value !== groupId))
            .map(permission => permission.value);

        const childrenIds = getConfigurablePermissionsChildrenIds(configurablePermissions, [...existingPermissionsToSubmit, ...newConfigurablePermissionIds]);

        const allPermissionIds = [
            ...childrenIds,
            ...newConfigurablePermissionIds,
            ...existingPermissionsToSubmit,
        ];

        onPermissionsChange([...new Set(allPermissionIds)]);
    };

    const handleToggle = (newActive: boolean): void => {
        setIsActive(newActive);
    };

    return (
        <div className={`permissions-list ${className}`}>
            <div className="permissions-list__toggle">
                <Toggle
                    isActive={isActive}
                    text={trans('containers.permissionsList.showUnMutablePermissions')}
                    onChange={handleToggle}
                />
            </div>
            {isLoading && <LoadingSpinner />}

            {activePermissionOptionGroups.length === 0 && (
                <EmptyState
                    intro={trans('containers.permissionsList.noPermissionsFound')}
                    title={trans('containers.permissionsList.additionalRights')}
                    className="permissions-list__empty-state"
                />
            )}

            {activePermissionOptionGroups.map(permissionOptionsGroup => {
                const handleOnGroupChange = (changes: CheckboxChange[]) => handleGroupChange(changes, permissionOptionsGroup.value);

                return (
                    <CheckboxGroupList
                        isExpandable
                        hideLabel
                        key={permissionOptionsGroup.value}
                        label={permissionOptionsGroup.label}
                        name={trans('common.permissions')}
                        groupOption={permissionOptionsGroup}
                        options={permissionOptionsGroup.options}
                        selectedOptions={permissionOptionsGroup.options.filter(option => option.isChecked)}
                        disabledOptions={permissionOptionsGroup.options.filter(option => option.isDisabled)}
                        onChange={handleOnGroupChange}
                        className="permissions-list__checkbox-group"
                        groupLabelClassName="permissions-list__group-label"
                        checkboxLabelClassName="permissions-list__checkbox-label"
                    />
                );
            })}
        </div>
    );
};

export default PermissionsList;
