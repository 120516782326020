import { FC } from 'react';

import { NavRouteWithCounter } from '../../Navigation';
import NavigationLink from '../NavigationLink/NavigationLink';

import './MainNavigation.scss';

interface MainNavigationProps {
    routes: NavRouteWithCounter[];
    className?: string;
}

const MainNavigation: FC<MainNavigationProps> = ({ routes, className = '' }) => (
    <div className={`main-navigation ${className}`}>
        {routes.map(route => (
            <NavigationLink
                key={route.key}
                counter={route.counter}
                to={route.path}
                label={route.label}
                className="main-navigation__nav-link"
                activeClassName="main-navigation__nav-link--is-active"
            />
        ))}
    </div>
);

export default MainNavigation;
