/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';

import classnames from 'classnames';
import { useToggle } from 'react-use';

import { IconButton } from '../../../../@paco/compositions';
import { Log } from '../../../../models';
import { getLogDateBlockDateString } from '../../helpers';
import LogBlock from '../LogBlock/LogBlock';
import LoketLogBlock from '../LoketLogBlock/LoketLogBlock';

import './LogDateBlock.scss';


interface LogDateBlockProps {
    isLoket?: boolean;
    logs: Log[];
}

const LogDateBlock: FC<LogDateBlockProps> = ({ isLoket, logs }) => {
    const [isActive, toggleIsActive] = useToggle(true);

    const buttonClassName = classnames('log-date-block__date-button', {
        'log-date-block__date-button--is-active': isActive,
    });

    const dateString = logs[0] && getLogDateBlockDateString(logs[0].loggedAt, new Date());
    return (
        <>
            <IconButton
                icon="chevron-down"
                iconPos="right"
                text={dateString}
                onClick={toggleIsActive}
                className={buttonClassName}
                iconClassName="log-date-block__date-button-icon"
            />
            <div className="log-date-block__logs">
                {logs.map(log => (
                    isLoket
                        ? (
                            <LoketLogBlock
                                key={`${log.id}`}
                                log={log}
                            />
                        ) : (
                            <LogBlock
                                key={`${log.id}`}
                                log={log}
                            />
                        )
                ))}

            </div>
        </>
    );
};

export default LogDateBlock;
