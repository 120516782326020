import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { DepartmentGroup } from '../../entities/DepartmentGroup/DepartmentGroup';

export type DepartmentGroupsState = AsyncReduxState<{
    isEditGroupsSuccessful: boolean;
    departmentGroups: DepartmentGroup[];
}>;

const initialState: DepartmentGroupsState = {
    ...initialAsyncReduxState,
    isEditGroupsSuccessful: false,
    departmentGroups: [],
};

export const DepartmentGroupsSlice = createSlice({
    name: 'departmentGroupsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DepartmentGroupsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setDepartmentGroups(state, action: PayloadAction<DepartmentGroup[]>): DepartmentGroupsState {
            return {
                ...state,
                departmentGroups: action.payload,
            };
        },
        setIsEditGroupsSuccessful(state, action: PayloadAction<boolean>): DepartmentGroupsState {
            return {
                ...state,
                isEditGroupsSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DepartmentGroupsState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsEditGroupsSuccessful,
    setIsLoading,
    setDepartmentGroups,
    setError,
} = DepartmentGroupsSlice.actions;

export default DepartmentGroupsSlice.reducer;
