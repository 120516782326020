import { DistrictDivisionGroupTemplate } from '../../../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import trans from '../../../../helpers/trans';

export const getDistrictDivisionDistrictTemplateLabel = (districtDivisionGroupTemplates: DistrictDivisionGroupTemplate[]): string => {
    const districtDivisionDistrictTemplateCountArray = districtDivisionGroupTemplates.map(districtDivisionGroupTemplate => districtDivisionGroupTemplate.districtDivisionDistrictTemplates.length);

    const districtDivisionDistrictTemplatesCount = districtDivisionDistrictTemplateCountArray.reduce((oldValue, newValue) => oldValue + newValue, 0);

    return districtDivisionDistrictTemplatesCount === 1
        ? trans('common.districtDivision')
        : trans('common.districtDivisionsCount', { amount: districtDivisionDistrictTemplatesCount.toString() });
};
