import { PersonFormData } from '../../../../entities/Person/Person';
import { AddUserFormData, EmploymentDatesFormData, PersonalInfoFormData } from '../../../../entities/User/User';
import {
    FormError,
    FormErrors,
    FormValidation,
    validateContractHoursInput,
    validateDate,
    validateDateCompareAsc,
    validateDivergentEmploymentHoursInput,
    validateEmailRegex,
    validateForm,
    validateRequiredString,
    validateStringMinimalLength,
} from '../../../../services/validationService';

export type AddPersonalInfoFormErrors = FormErrors<PersonalInfoFormData>;
export type AddUserFormErrors = FormErrors<AddUserFormData> & FormErrors<PersonFormData>;
export type EmploymentDatesFormErrors = FormErrors<EmploymentDatesFormData>;

export const validatePersonalInfoFormData = (formData: PersonalInfoFormData): FormValidation<AddPersonalInfoFormErrors> => {
    const errors: AddPersonalInfoFormErrors = {
        firstname: validateRequiredString('firstname', formData.firstname),
        lastname: validateRequiredString('lastname', formData.lastname),
        email: validateEmailRegex('email', formData.email),
        phoneNumber: validateStringMinimalLength('phoneNumber', formData.phoneNumber, 10),
        birthday: validateDate(formData.birthday),
    };

    return validateForm<AddPersonalInfoFormErrors>(errors);
};

const validateDivergentEmploymentHours = (attribute: 'divergentPreEmploymentHours' | 'divergentPostEmploymentHours', hours?: string): FormError<string> => {
    const requiredValidation = validateRequiredString(attribute, hours);
    const contentValidation = hours ? validateDivergentEmploymentHoursInput(hours) : undefined;

    return requiredValidation || contentValidation;
};

export const validateEmploymentDatesInputFormData = (formData: EmploymentDatesFormData = {}): FormValidation<EmploymentDatesFormErrors> => {
    const {
        divergentPreEmploymentHours,
        divergentPostEmploymentHours,
        endDate,
        startDate,
    } = formData;

    const divergentPreEmploymentHoursValidation = startDate ? validateDivergentEmploymentHours('divergentPreEmploymentHours', divergentPreEmploymentHours) : undefined;
    const divergentPostEmploymentHoursValidation = endDate ? validateDivergentEmploymentHours('divergentPostEmploymentHours', divergentPostEmploymentHours) : undefined;
    const startDateValidation = startDate ? validateDate(startDate) : undefined;
    const endDateValidation = (startDate && endDate) ? validateDate(endDate) || validateDateCompareAsc(endDate, startDate) : undefined;

    const errors: EmploymentDatesFormErrors = {
        divergentPostEmploymentHours: divergentPostEmploymentHoursValidation,
        divergentPreEmploymentHours: divergentPreEmploymentHoursValidation,
        startDate: startDateValidation,
        endDate: endDateValidation,
    };

    return validateForm<EmploymentDatesFormErrors>(errors);
};

export const validateAddUserFormData = (formData: AddUserFormData): FormValidation<AddUserFormErrors> => {
    const errors: AddUserFormErrors = {
        mainDepartment: validateRequiredString('mainDepartment', formData.mainDepartment),
        contractHours: formData.contractHours ? validateContractHoursInput(formData.contractHours) : undefined,
        identificationExpirationDate: formData.person.identificationExpirationDate ? validateDate(formData.person.identificationExpirationDate) : undefined,
    };

    return validateForm<AddUserFormErrors>(errors);
};
