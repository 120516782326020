import { CoupledPermissions } from '../../@paco/entities/Permission/Permission';
import { Permission } from '../../models';

export function getPermissionChildren(
    permission: Permission,
    coupledPermissions: CoupledPermissions[],
): string[] {
    const match = coupledPermissions.find((coupledPermission) => coupledPermission.parents
        .find(coupledPermissionParent => coupledPermissionParent === permission.slug));

    return match ? match.children : [];
}
