import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { authenticateLoket } from '../../redux/management/managementActions';

import './User.scss';

const LoketSSO: FC = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    useEffectOnce(() => {
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        if (code && state) {
            dispatch(authenticateLoket(state, code));
        }
    });

    return (
        <div className="loket-sso-page">
            <Navigate to="/" />
        </div>
    );
};

export default LoketSSO;
