import React, { FC, useEffect, useState } from 'react';

import { ShiftPlanningWithShift } from '../../../../../../@paco/entities/ShiftPlanning/ShiftPlanning';
import { formatDate, isSameDay } from '../../../../../../helpers/date';
import { translate } from '../../../../../../helpers/translations/translator';
import {
    Absence,
    LeaveOfAbsence,
    PreferToWorkTimeSlot,
    UnavailableToWorkTimeSlot,
} from '../../../../../../models';
import { getOverlappingResourcesOnDate } from '../../helpers';
import Availability from '../Availability/Availability';

import './DayContent.scss';

type ResourceTypes = LeaveOfAbsence | Absence | PreferToWorkTimeSlot | UnavailableToWorkTimeSlot
| ShiftPlanningWithShift;

interface Props {
    day: Date;
    leaveOfAbsences: LeaveOfAbsence[];
    absences: Absence[];
    preferToWorkTimeSlots: PreferToWorkTimeSlot[];
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[];
    onResourceClick: (resource: ResourceTypes) => void;
}

const DayContent: FC<Props> = ({
    leaveOfAbsences,
    absences,
    preferToWorkTimeSlots,
    unavailableToWorkTimeSlots,
    day,
    onResourceClick,
}) => {
    const sameDay = isSameDay(new Date(day), new Date());
    const [resources, setResources] = useState<ResourceTypes[]>([]);

    useEffect(() => {
        setResources(getOverlappingResourcesOnDate(
            leaveOfAbsences,
            absences,
            preferToWorkTimeSlots,
            unavailableToWorkTimeSlots,
            day,
        ));
    }, [leaveOfAbsences, preferToWorkTimeSlots, unavailableToWorkTimeSlots, day]);

    return (
        <div className={`day-content ${sameDay && 'day-content--today'}`}>
            <div className={`day-content-header ${sameDay && 'day-content-header--today'} availability-day-content-header`}>
                <div className="day-content-header-label">
                    { sameDay && translate('common.today') }
                </div>
                <div className={`day-content-header-day ${sameDay && 'day-content-header-day--today'}`}>
                    {formatDate(new Date(day), 'd')}
                </div>
            </div>
            <div className="availability-day-content-resources">
                {
                    resources.map(resource => (
                        <Availability
                            showName
                            key={resource.id}
                            resource={resource}
                            onClick={onResourceClick}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default DayContent;
