import { FC, ReactElement } from 'react';

import { LeaveOfAbsencesOverviewTable } from '../../../../containers';
import { LeaveOfAbsencesOverviewUsersList } from '../../../../containers/LeaveOfAbsencesOverviewUsers/subcomponents';
import { LeaveOfAbsencesOverviewBodyRow } from '../../../../entities/LeaveOfAbsenceOverviewBlock/LeaveOfAbsencesOverviewBlock';
import { transformToPeriod } from '../../../../entities/Period/PeriodTransformers';

import './PrintableLeaveOfAbsencePage.scss';

export interface PrintableLeaveOfAbsencePageProps {
    calendarDays: Date[];
    leaveOfAbsencesOverviewBodyRows: LeaveOfAbsencesOverviewBodyRow[];
    className?: string;
}

const PrintableLeaveOfAbsencePage: FC<PrintableLeaveOfAbsencePageProps> = ({
    calendarDays,
    leaveOfAbsencesOverviewBodyRows,
    className,
}): ReactElement => (
    <div className={`printable-leave-of-absence-page ${className}`}>
        <LeaveOfAbsencesOverviewUsersList
            isPrintable
            users={leaveOfAbsencesOverviewBodyRows.map(row => row.user)}
            className="printable-leave-of-absence-page__users-list"
        />
        <LeaveOfAbsencesOverviewTable
            isLoading={false}
            period={transformToPeriod(calendarDays[0], calendarDays[calendarDays.length - 1])}
            rows={leaveOfAbsencesOverviewBodyRows}
        />
    </div>
);

export default PrintableLeaveOfAbsencePage;
