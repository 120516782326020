import { FC } from 'react';

import { useSelector } from 'react-redux';

import { Reducers } from '../../../redux/reducers';
import { ToastContainer } from '../../containers';
import { Toast } from '../../types/toastTypes';

const ConnectedToasts: FC = () => {
    const toasts = useSelector<Reducers, Toast[]>(state => state.toastsReducer.toasts);

    return (
        <ToastContainer toasts={toasts} />
    );
};

export default ConnectedToasts;
