import { FC } from 'react';

import { Icon } from '../../../../components';
import { WidgetLabel } from '../index';

import './WidgetButton.scss';

export interface WidgetButtonProps {
    hasPZLabel?: boolean;
    index: number;
    label: string;
    onClick?: (index: number) => void;
    className?: string;
}

const WidgetButton: FC<WidgetButtonProps> = ({
    hasPZLabel,
    index,
    label,
    onClick,
    className = '',
}) => {
    const handleButtonClick = () => {
        if (onClick) {
            onClick(index);
        }
    };

    return (
        <button
            type="button"
            className={`widget-button ${className}`}
            onClick={handleButtonClick}
        >
            <WidgetLabel
                hasPZLabel={hasPZLabel}
                label={label}
                className="widget-button__label"
            />
            <div className="widget-button__divider" />
            <div className="widget-button__icon-wrapper">
                <Icon
                    name="plus-circle"
                    className="widget-button__icon"
                />
            </div>
        </button>
    );
};

export default WidgetButton;
