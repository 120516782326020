import { isFetchCollectionResultSuccessful, isFetchResultSuccessful } from '../../../entities/FetchResult';
import { fetchLoketEmployeeApiCall, fetchUnsyncedEmployeesApiCall } from '../../../entities/LoketUser/LoketUserService';
import trans from '../../../helpers/trans';
import { TypedDispatch } from '../../store';
import {
    setError,
    setFullLoketUser,
    setIsGetFullLoketUserSuccessful,
    setIsGetUnsyncedEmployeesSuccessful,
    setIsLoading,
    setUnsyncedEmployees,
} from './addUserFormReducer';

export const fetchUnsyncedEmployees = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsGetUnsyncedEmployeesSuccessful(false));

    try {
        const response = await fetchUnsyncedEmployeesApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setUnsyncedEmployees(response.data));
        dispatch(setIsGetUnsyncedEmployeesSuccessful(true));
    } catch (error) {
        console.error('[fetchUnsyncedEmployees]', error);

        dispatch(setError(trans('errors.unknownError')));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchFullLoketUser = (userId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsGetFullLoketUserSuccessful(false));

    try {
        const response = await fetchLoketEmployeeApiCall(userId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setFullLoketUser(response.data));
        dispatch(setIsGetFullLoketUserSuccessful(true));
    } catch (error) {
        console.error('[fetchFullLoketUser]', error);

        dispatch(setError(trans('errors.unknownError')));
        dispatch(setIsLoading(false));
    }
};
