import { FC, ReactElement, useEffect } from 'react';

import { EditShiftTimeForm } from '../../../containers';
import { Period } from '../../../entities/Period/Period';
import trans from '../../../helpers/trans';
import { deleteShiftConcept, editShiftConceptTime } from '../../../redux/shiftConcept/shiftConceptActions';
import { setIsEditShiftConceptSuccessful } from '../../../redux/shiftConcept/shiftConceptReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedEditShiftConceptTimeFormProps {
    period: Period;
    shiftConceptId: string;
    onClose: () => void;
    className?: string;
}

const ConnectedEditShiftConceptTimeForm: FC<ConnectedEditShiftConceptTimeFormProps> = ({
    period,
    shiftConceptId,
    onClose,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isEditShiftConceptSuccessful, isDeleteShiftConceptSuccessful } = useTypedSelector(state => state.pacoShiftConceptReducer);

    const handleDeleteButtonClick = (): void => {
        dispatch(deleteShiftConcept(shiftConceptId));
    };

    const handleSubmit = (value: Period): void => {
        dispatch(editShiftConceptTime({ period: value }, shiftConceptId));
    };

    useEffect((): void => {
        if (isEditShiftConceptSuccessful) {
            onClose();

            dispatch(setIsEditShiftConceptSuccessful(false));
            dispatch(setToast({
                text: trans('containers.forms.editShiftTimeForm.editShiftTimeSuccess'),
                type: ToastType.pass,
            }));
        }
    }, [isEditShiftConceptSuccessful]);

    useEffect((): void => {
        if (isDeleteShiftConceptSuccessful) {
            onClose();

            dispatch(setToast({
                text: trans('containers.forms.editShiftTimeForm.deleteShiftSuccess'),
                type: ToastType.pass,
            }));
        }
    }, [isDeleteShiftConceptSuccessful]);

    return (
        <EditShiftTimeForm
            period={period}
            onSubmit={handleSubmit}
            onDeleteButtonClick={handleDeleteButtonClick}
            onCancel={onClose}
            className={className}
        />
    );
};

export default ConnectedEditShiftConceptTimeForm;
