import { ChangeEvent, FC } from 'react';

import { Input, InputLabel } from '../../../components';
import trans from '../../../helpers/trans';

import './WaitingDayHoursInput.scss';

interface WaitingDayHoursInputProps {
    isDisabled: boolean;
    name?: string;
    value?: number;
    onChange: (value?: number) => void;
    className?: string;
}

const WaitingDayHoursInput: FC<WaitingDayHoursInputProps> = ({
    isDisabled,
    name,
    value,
    onChange,
    className = '',
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value: newValue } = event.currentTarget;

        onChange(newValue === '' ? undefined : +newValue);
    };

    return (
        <div className={`waiting-day-hours-input ${className}`}>
            <label
                aria-label={trans('compositions.waitingDayHoursInput.label')}
                htmlFor={name}
                className="waiting-day-hours-input__label"
            >
                <InputLabel
                    text={trans('compositions.waitingDayHoursInput.label')}
                    tooltip={trans('compositions.waitingDayHoursInput.tooltip')}
                    requiredClassName="waiting-day-hours-input__input-label-required-label"
                    tooltipClassName="waiting-day-hours-input__input-label-tooltip"
                />
            </label>

            <Input
                disabled={isDisabled}
                id={name}
                type="number"
                value={value === undefined ? '' : value}
                onChange={handleChange}
                className="waiting-day-hours-input__input"
            />
        </div>
    );
};

export default WaitingDayHoursInput;
