import React, { FC, ReactElement, ReactNode } from 'react';

import Pagination from '@createnl/pagination';
import { useDispatch } from 'react-redux';

import { translate } from '../../helpers/translations/translator';
import { setPaginationNumber } from '../../redux/pagination/paginationActions';

import './Pagination.scss';

interface ConnectedPaginationBlockProps {
    number: number;
    pages: number;
    onPagination?: (page: number) => void;
    className: string;
    children?: ReactNode;
}

const ConnectedPaginationBlock: FC<ConnectedPaginationBlockProps> = ({
    number,
    pages,
    onPagination,
    className,
    children,
}) : ReactElement => {
    const dispatch = useDispatch();

    const changeCurrentPage = (newPageNumber: number) => {
        dispatch(setPaginationNumber(newPageNumber));
        if (onPagination) onPagination(newPageNumber);
    };

    return (
        <div className={className}>
            {children}
            <Pagination
                id="pagination"
                buttonPrevText={translate('common.previous')}
                buttonNextText={translate('common.next')}
                centerNumbers
                onChange={changeCurrentPage}
                amountOfPages={pages}
                currentPage={number}
            />
        </div>
    );
};

export default ConnectedPaginationBlock;
