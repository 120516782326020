import React, { FC } from 'react';

import classNames from 'classnames';

import UserColor from '../../../../../../components/UserColor/UserColor';
import { getFirstLetterOfEmploymentType } from '../../../../../../helpers';
import { EmploymentTypeSlug } from '../../../../../../models/EmploymentType';
import ShiftEmployeeIcon, { ShiftEmployeeIconProps } from '../ShiftEmployeeIcon/ShiftEmployeeIcon';

interface ShiftEmployeeProps {
    isCompanyMedic?: boolean;
    showEmploymentTypeBadge?: boolean;
    deviatedTime?: string;
    color?: string;
    name: string;
    employmentTypeSlug?: EmploymentTypeSlug;
    icons?: ShiftEmployeeIconProps[];
    className?: string;
    employmentTypeClassName?: string;
    iconClassName?: string;
}

const ShiftEmployee: FC<ShiftEmployeeProps> = ({
    isCompanyMedic,
    color,
    name,
    showEmploymentTypeBadge,
    employmentTypeSlug,
    deviatedTime,
    icons = [],
    className = '',
    employmentTypeClassName,
    iconClassName,
}) => {
    const nameClassName = classNames('calendar-shift-employee-name', {
        'calendar-shift-employee-name--is-company-medic': isCompanyMedic,
    }, className);

    return (
        <li className={`calendar-shift-employee ${className}`}>
            {(showEmploymentTypeBadge && employmentTypeSlug) && (
                <div className={`calendar-shift__employment-type ${employmentTypeClassName}`}>
                    {getFirstLetterOfEmploymentType(employmentTypeSlug)}
                </div>
            )}
            {color && <UserColor className="calendar-shift__user-color" size="tiny" color={color} /> }
            <span className={nameClassName}>
                {name}
            </span>
            {deviatedTime && (
                <span className="calendar-shift-employee-time">
                    {deviatedTime}
                </span>
            )}
            {icons.map((icon => (
                <ShiftEmployeeIcon
                    key={icon.iconName}
                    iconName={icon.iconName}
                    tooltipText={icon.tooltipText}
                    className={`calendar-shift-employee-icon ${iconClassName}`}
                />
            )))}
        </li>
    );
};

export default ShiftEmployee;
