import { isArray } from 'underscore';

import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { PublishBatchResult, PublishBatchResultResource } from './PublishBatch';
import { transformToPublishBatchResult } from './PublishBatchTransformers';

export const shiftConceptsPublishBatchApiCall = async (shiftConceptIds: string[]): Promise<FetchCollectionResult<PublishBatchResult[], string>> => {
    try {
        const url = generateApiUrl({
            endpoint: '/shift/concepts/publish-batch',
        });

        const response = await authorizedFetch(url, {
            body: JSON.stringify(shiftConceptIds),
            method: 'POST',
        });

        const resource = await response.json() as PublishBatchResultResource[][];

        if (!isArray(resource)) {
            return {
                status: 500,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const publishBatchResults = resource
            .flat()
            .map(transformToPublishBatchResult);

        return {
            amountOfPages: 1,
            status: 200,
            type: FetchResultType.Success,
            data: publishBatchResults,
        };
    } catch (error) {
        console.error('[shiftConceptsPublishBatchApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
