import {
    CSSProperties,
    FC,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Icon } from '../../../../components';
import trans from '../../../../helpers/trans';
import { IconButton } from '../../../index';

import './HelpTooltipContent.scss';

interface HelpTooltipContentProps {
    showMobileInfoWarning?: boolean,
    showNextButton?: boolean;
    showPrevButton?: boolean;
    index: number;
    subTitle: string;
    text: string;
    title: string;
    total: number;
    videoSrc?: string;
    onStopButtonClick: () => void;
    onPrevButtonClick: () => void;
    onNextButtonClick: () => void;
    className?: string;
}

const HelpTooltipContent: FC<HelpTooltipContentProps> = ({
    showMobileInfoWarning = false,
    showNextButton,
    showPrevButton,
    index,
    subTitle,
    text,
    title,
    total,
    videoSrc,
    onStopButtonClick,
    onPrevButtonClick,
    onNextButtonClick,
    className,
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [pxOffset, setPxOffset] = useState<number>();
    const bodyWidth = document.body.clientWidth;
    const phoneOnlyPx = 480;

    const getPxOffset = (): number => {
        if (wrapperRef.current) {
            const { left, right } = wrapperRef.current.getBoundingClientRect();
            const pagePadding = bodyWidth > phoneOnlyPx ? 20 : 0;

            if (left < 0) {
                return Math.abs(left) + pagePadding;
            }

            if (right > bodyWidth) {
                return (bodyWidth - right - pagePadding);
            }
        }

        return 0;
    };

    useEffect(() => {
        setPxOffset(getPxOffset());
    }, [wrapperRef, bodyWidth]);

    const cssVariables = {
        '--px-offset': `${pxOffset || 0}`,
        '--content-opacity': pxOffset === undefined ? 0 : 1,
    } as CSSProperties;

    return (
        <div ref={wrapperRef} className={`help-tooltip-content ${className}`} style={cssVariables}>
            <div className="help-tooltip-content__title-wrapper">
                <div className="help-tooltip-content__title-step-wrapper">
                    <h4 className="help-tooltip-content__title-step">{title}</h4>
                    <h4 className="help-tooltip-content__title-step">{`${index + 1} / ${total}`}</h4>
                </div>
                <div className="help-tooltip-content__stop-button-wrapper">
                    <IconButton
                        hideLabel
                        text={trans('common.close')}
                        icon="close-circle"
                        onClick={onStopButtonClick}
                        className="help-tooltip-content__stop-button"
                        iconClassName="help-tooltip-content__stop-button-icon"
                    />
                </div>
            </div>
            <h5 className="help-tooltip-content__sub-title">{subTitle}</h5>
            {showMobileInfoWarning && (
                <div className="help-tooltip-content__mobile-info-warning-wrapper">
                    <Icon
                        name="info-circle"
                        className="help-tooltip-content__mobile-info-warning-icon"
                    />
                    <p className="help-tooltip-content__mobile-info-warning-text">{trans('help.showMobileInfoWarning')}</p>
                </div>
            )}
            <p className="help-tooltip-content__text">{text}</p>
            {videoSrc && <video className="help-tooltip-content__video" autoPlay loop src={videoSrc} />}
            <div className="help-tooltip-content__buttons-wrapper">
                <div className="help-tooltip-content__prev-button-wrapper">
                    {showPrevButton && (
                        <IconButton
                            text={trans('common.previous')}
                            icon="chevron-left"
                            onClick={onPrevButtonClick}
                            className="help-tooltip-content__prev-button"
                            iconClassName="help-tooltip-content__prev-button-icon"
                        />
                    )}
                </div>
                <div className="help-tooltip-content__next-button-wrapper">
                    {showNextButton && (
                        <IconButton
                            text={trans('common.next')}
                            icon="chevron-right"
                            iconPos="right"
                            onClick={onNextButtonClick}
                            className="help-tooltip-content__next-button"
                            iconClassName="help-tooltip-content__next-button-icon"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default HelpTooltipContent;
