import React, { FC, useMemo } from 'react';

import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

import { capitalizeFirstLetter, getUserFullName } from '../../helpers';
import { formatDate } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import { ExchangeViewModel } from '../../models';
import ButtonHistory from '../ButtonHistory/ButtonHistory';
import Icon from '../style/Icon/Icon';
import UserProfileInfo from '../UserProfileInfo/UserProfileInfo';
import ExchangeRequestCardReply from './subcomponents/ExchangeRequestCardReply/ExchangeRequestCardReply';

import './ExchangeRequestCard.scss';


interface ExchangeRequestCardProps {
    request: ExchangeViewModel;
    onApproveClick?: (exchangeId: string, userId: string) => void;
    onDeclineClick?: (exchangeId: string) => void;
    onCalendarClick: () => void;
    onShowLogsClick?: () => void;
    className?: string;
}

const ExchangeRequestCard: FC<ExchangeRequestCardProps> = ({
    request,
    onApproveClick,
    onDeclineClick,
    onCalendarClick,
    onShowLogsClick,
    className = '',
}) => {
    const name = useMemo(() => getUserFullName(request.owner), [request]);
    const userId = request.owner?.id;
    const userShiftPlanning = useMemo(
        () => request.shift?.shiftPlannings.find(shiftPlanning => shiftPlanning.user?.id === userId),
        [userId, request],
    );
    const department = request.shift?.department?.name;
    const group = request.shift?.department?.group?.name;
    const experience = request.owner?.experience?.name;
    const start = userShiftPlanning?.start || request.shift?.start;
    const end = userShiftPlanning?.end || request.shift?.end;
    const shiftId = request.shift?.id;
    const startDate = useMemo(() => (start ? capitalizeFirstLetter(formatDate(start, 'EEEE dd MMMM yyyy')) : ''), [start]);
    const startTime = useMemo(() => (start ? formatDate(start, 'H:mm') : ''), [start]);
    const endTime = useMemo(() => (end ? formatDate(end.transformToUpToButExcludingDate().date, 'H:mm') : ''), [end]);
    const pathSchedule = `/${translate('nav.shifts.link')}`;
    const pathCalendar = `${pathSchedule}/${translate('nav.shifts.schedule.link')}`;
    const createdAtDate = formatDate(request.createdAt, 'dd-MM-yyyy');
    const classNames = classnames(
        'card request-card exc-request-card',
        {
            'exc-request-card--has-button-history': onShowLogsClick,
        },
        [className],
    );

    return (
        <div className={classNames}>
            <div className="request-card-top exc-request-card__top">
                <div className="request-card-left">
                    <div className="request-card-top__title">
                        {translate('pages.absences.takeOverShift')}
                    </div>
                    <UserProfileInfo
                        group={group}
                        userId={userId}
                        date={start}
                        department={department}
                        experience={experience}
                        name={name}
                    />
                </div>
                <div className="request-card-right exc-request-card__right">
                    <div className="request-card__requested-at">
                        {translate('pages.absences.requestedOn')}
                        {' '}
                        {createdAtDate}
                        { onShowLogsClick && <ButtonHistory className="request-card__button-history" onClick={onShowLogsClick} /> }
                    </div>
                    <div className="exc-request-card__date">
                        {startDate}
                    </div>
                    <div className="exc-request-card__time">
                        {`${startTime} - ${endTime}`}
                    </div>
                </div>
            </div>
            {(request.replacementUsers.length && onApproveClick && onDeclineClick) ? (
                <>
                    <div className="exc-request-card__title">
                        {translate('common.replies')}
                    </div>
                    <div className="exc-request-card__replies">
                        {request.replacementUsers.map(user => (
                            <ExchangeRequestCardReply
                                key={user.id}
                                user={user}
                                onApproveClick={() => onApproveClick(request.id, user.id)}
                                onDeclineClick={() => onDeclineClick(request.id)}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <div className="exc-request-card__no-replies">
                    {translate('common.noReplies')}
                </div>
            )}
            <NavLink
                to={{
                    pathname: pathCalendar,
                    search: `?focusShift=${shiftId}`,
                }}
                className="request-card-button-calendar"
                onClick={onCalendarClick}
            >
                { translate('pages.absences.seeInCalendar') }
                {' '}
                <Icon kind="keyboard_arrow_right" />
            </NavLink>
        </div>
    );
};

export default ExchangeRequestCard;
