import React from 'react';

import { TYPE_ABSENCE, TYPE_LEAVE_OF_ABSENCE, TYPE_LEAVE_OF_ABSENCE_REQUEST } from '../../../constants';
import { generateAbsenceTooltipText } from '../../../helpers';
import { translate } from '../../../helpers/translations/translator';
import {
    Absence,
    AbsenceViewModel,
    LeaveOfAbsence,
    LeaveOfAbsenceViewModel,
} from '../../../models';

// TODO: Only use view models instead of legacy models
export const getUserStatusBadge = (
    absence?: Absence | AbsenceViewModel,
    leaveOfAbsenceRequest?: LeaveOfAbsence | LeaveOfAbsenceViewModel,
    leaveOfAbsence?: LeaveOfAbsence | LeaveOfAbsenceViewModel,
    modifier?: string,
): JSX.Element | null => {
    if (absence) {
        return (
            <span
                data-testid="badge-absence"
                className={`badge-open badge-open-danger ${modifier ? `badge-open--${modifier}` : ''}`}
            >
                {translate('pages.absences.calledInSick')}
            </span>
        );
    }

    if (leaveOfAbsenceRequest) {
        return (
            <span
                data-testid="badge-leave-of-absence-request"
                className={`badge-open badge-open-blue ${modifier ? `badge-open--${modifier}` : ''}`}
            >
                {translate('pages.absences.leaveRequested')}
            </span>
        );
    }

    if (leaveOfAbsence) {
        return (
            <span
                data-testid="badge-leave-of-absence"
                className={`badge-open badge-open-primary ${modifier ? `badge-open--${modifier}` : ''}`}
            >
                {translate('pages.absences.leaveOfAbsence')}
            </span>
        );
    }

    return null;
};

export const generateTooltipText = (
    absence?: Absence | AbsenceViewModel,
    leaveOfAbsenceRequest?: LeaveOfAbsence | LeaveOfAbsenceViewModel,
    leaveOfAbsence?: LeaveOfAbsence | LeaveOfAbsenceViewModel,
): string | null => {
    if (absence) {
        return generateAbsenceTooltipText(TYPE_ABSENCE, { absence });
    } if (leaveOfAbsenceRequest) {
        return generateAbsenceTooltipText(TYPE_LEAVE_OF_ABSENCE_REQUEST, { leaveOfAbsenceRequest });
    } if (leaveOfAbsence) {
        return generateAbsenceTooltipText(TYPE_LEAVE_OF_ABSENCE, { leaveOfAbsence });
    }

    return null;
};

export const getCaoTooltipText = (
    userHasShiftPlanningInLast11h?: boolean,
    userAgeIsBelow16AndShiftIsAfter19h?: boolean,
    userAgeIsBelow18AndShiftIsAfter23h?: boolean,
): string => {
    if (userHasShiftPlanningInLast11h) {
        return translate('pages.shifts.userHasShiftPlanningInLast11h');
    }

    if (userAgeIsBelow16AndShiftIsAfter19h) {
        return translate('pages.shifts.userAgeIsBelow16AndShiftIsAfter19h');
    }

    if (userAgeIsBelow18AndShiftIsAfter23h) {
        return translate('pages.shifts.userAgeIsBelow18AndShiftIsAfter23h');
    }
    return '';
};
