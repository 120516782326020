import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { DivergentPostEmploymentHour } from '../DivergentPostEmploymentHour/DivergentPostEmploymentHour';
import { transformV3ResourceToDivergentPostEmploymentHour } from '../DivergentPostEmploymentHour/DivergentPostEmploymentHourTransformers';
import { DivergentPreEmploymentHour } from '../DivergentPreEmploymentHour/DivergentPreEmploymentHour';
import { transformV3ResourceToDivergentPreEmploymentHour } from '../DivergentPreEmploymentHour/DivergentPreEmploymentHourTransformers';
import { EmploymentDatesFormData } from '../User/User';
import {
    Employment,
    EmploymentRequest,
    EmploymentResource,
    EmploymentV3Resource,
} from './Employment';

export const transformToEmployment = (
    resource: EmploymentResource,
    divergentPostEmploymentHour?: DivergentPostEmploymentHour,
    divergentPreEmploymentHour?: DivergentPreEmploymentHour,
): Employment => ({
    id: resource.id,
    start: new Date(resource.attributes.start),
    end: resource.attributes.end ? new Date(resource.attributes.end) : undefined,
    divergentPostEmploymentHour,
    divergentPreEmploymentHour,
});

export const transformV3ResourceToEmployment = (resource: EmploymentV3Resource): Employment => ({
    id: resource.id,
    start: new Date(resource.start),
    end: resource.end ? new Date(resource.end) : undefined,
    divergentPostEmploymentHour: resource.divergentPostEmploymentHour ? transformV3ResourceToDivergentPostEmploymentHour(resource.divergentPostEmploymentHour) : undefined,
    divergentPreEmploymentHour: resource.divergentPreEmploymentHour ? transformV3ResourceToDivergentPreEmploymentHour(resource.divergentPreEmploymentHour) : undefined,
});

export const transformEmploymentAndFormDataToRequest = (employmentId: string, formData: EmploymentDatesFormData): EmploymentRequest => ({
    id: employmentId,
    type: 'employments',
    attributes: {
        end: formData.endDate ? formatDate(formData.endDate, DateFormat.apiDateTime) : null,
        start: formData.startDate ? formatDate(formData.startDate, DateFormat.apiDateTime) : null,
    },
});
