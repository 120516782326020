import {
    FC,
    ReactNode,
    ReactPortal,
    useEffect,
    useState,
} from 'react';

import { createPortal } from 'react-dom';

import { appRootId } from '../../../index';

interface RootPortalProps {
    children?: ReactNode;
}

const RootPortal: FC<RootPortalProps> = ({ children }): ReactPortal | null => {
    const [rootElement] = useState<Element | null>(document.getElementById(appRootId));

    useEffect(() => {
        if (!rootElement) {
            console.error('[RootPortal]: rootElement not found. Unable to use createPortal.');
        }
    }, [rootElement]);

    return rootElement && createPortal(
        children,
        rootElement,
    );
};

export default RootPortal;
