import { FC, ReactElement } from 'react';

import { Checkbox, InputLabel } from '../../../components';
import trans from '../../../helpers/trans';

import './ShowMainDepartmentFilterContainer.scss';

interface ShowMainDepartmentFilterContainerProps {
    onlyShowMainDepartment: boolean;
    onChange: (isChecked: boolean) => void;
    className?: string;
}

const ShowMainDepartmentFilterContainer: FC<ShowMainDepartmentFilterContainerProps> = ({
    onlyShowMainDepartment,
    onChange,
    className = '',
}): ReactElement => {
    const handleOptionChange = (isChecked: boolean) => {
        onChange(isChecked);
    };

    return (
        <div className={`show-main-department-filter-container ${className}`}>
            <InputLabel text={trans('common.mainDepartment')} />
            <Checkbox
                checked={onlyShowMainDepartment}
                label={trans('common.onlyShowMainDepartment')}
                onChange={handleOptionChange}
                className="show-main-department-filter-container__checkbox"
            />
        </div>
    );
};

export default ShowMainDepartmentFilterContainer;
