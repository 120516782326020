import React, { FC, useMemo } from 'react';

import { Col, Row } from 'reactstrap';

import { ConnectedHelpTooltip } from '../../@paco/connectors';
import { PayrollPeriod } from '../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { Permission } from '../../@paco/entities/Permission/Permission';
import { Role } from '../../@paco/entities/Role/Role';
import { Setting } from '../../@paco/entities/Setting/Setting';
import { getAbnormalTrackDurationThresholdSettingValue } from '../../@paco/entities/Setting/SettingHelpers';
import trans from '../../@paco/helpers/trans';
import { formatDate } from '../../helpers/date';
import transformTimeStringToNumber from '../../helpers/date/transformTimeStringToNumber';
import { TrackViewModel } from '../../models';
import TrackCard from '../TrackCard/TrackCard';
import getTracksByDate from './helpers/getTracksByDate';

import './TrackCardsContainer.scss';

interface TrackCardsContainerProps {
    isTimerEnableFeedback: boolean;
    currentUserRole: Role;
    tracks: TrackViewModel[];
    permissions: Permission[];
    settings: Setting[];
    payrollPeriods?: PayrollPeriod[];
    currentUserId?: string;
    onReviewTrackClick: (track: TrackViewModel) => void;
    onApproveTrackClick: (trackId: string) => void;
    className?: string;
}

const TrackCardsContainer: FC<TrackCardsContainerProps> = ({
    isTimerEnableFeedback,
    currentUserRole,
    tracks,
    permissions,
    settings,
    payrollPeriods = [],
    currentUserId,
    onReviewTrackClick,
    onApproveTrackClick,
    className = '',
}) => {
    const tracksByDate = useMemo(() => getTracksByDate(tracks), [tracks]);
    const keys = useMemo(() => Object.keys(tracksByDate), [tracksByDate]);

    return (
        <div className={`track-cards__container ${className}`}>
            <ConnectedHelpTooltip
                index={3}
                route="review-tracks"
                subTitle={trans('help.tracks.trackInfo.title')}
                text={trans('help.tracks.trackInfo.text')}
                title={trans('help.tracks.title')}
                className="tracks__track-info-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={4}
                videoSrc="/help/review-tracks-icons.webm"
                route="review-tracks"
                subTitle={trans('help.tracks.trackIcons.title')}
                text={trans('help.tracks.trackIcons.text')}
                title={trans('help.tracks.title')}
                className="tracks__review-track-icons-help-tooltip"
            />
            {keys.map(key => (
                <div key={key} className="track-cards__block">
                    <div className="track-cards__title">{formatDate(key, 'd MMMM yyyy')}</div>
                    <Row className="track-cards__row">
                        {tracksByDate[key].map(track => {
                            const abnormalTrackDurationThreshold = getAbnormalTrackDurationThresholdSettingValue(settings, track?.department?.id);
                            const totalBillableTime = transformTimeStringToNumber(track.totalBillableTime);
                            const showAbnormalTrackDurationWarning = totalBillableTime > abnormalTrackDurationThreshold;

                            return (
                                <Col key={track.id} xl={6} md={12} sm={12} className="track-cards__col">
                                    <TrackCard
                                        enableFeedback={isTimerEnableFeedback}
                                        currentUserId={currentUserId}
                                        currentUserRole={currentUserRole}
                                        payrollPeriods={payrollPeriods}
                                        permissions={permissions}
                                        track={track}
                                        onApproveClick={onApproveTrackClick}
                                        onReviewTrackClick={onReviewTrackClick}
                                        showAbnormalTrackDurationWarning={showAbnormalTrackDurationWarning}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            ))}
        </div>
    );
};

export default TrackCardsContainer;
