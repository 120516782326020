import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { noop } from 'react-use/lib/misc/util';

import { Button } from '../../../../../components';
import {
    DateInput,
    ModalContent,
    NumberInput,
    SelectInput,
    TimeInput,
} from '../../../../../compositions';
import { transformDateAndTimesToPeriod } from '../../../../../entities/Period/PeriodTransformers';
import { EditShiftFormData, ShiftIndex } from '../../../../../entities/Shift/Shift';
import { formatDate } from '../../../../../helpers/date';
import trans from '../../../../../helpers/trans';
import { DateFormat } from '../../../../../types/dateFormatTypes';

import './ShiftDetailMainForm.scss';

interface ShiftDetailMainFormProps {
    canDeleteShift: boolean;
    isDateInputDisabled?: boolean;
    showHistoryButton?: boolean;
    shift: ShiftIndex;
    onDeleteButtonClick: () => void;
    onFormDataChange: (date: EditShiftFormData) => void;
    onHistoryButtonClick?: () => void;
    className?: string;
}

const ShiftDetailMainForm: FC<ShiftDetailMainFormProps> = ({
    canDeleteShift,
    isDateInputDisabled = false,
    showHistoryButton = false,
    shift,
    onDeleteButtonClick,
    onFormDataChange,
    onHistoryButtonClick,
    className = '',
}): ReactElement => {
    const { department, period } = shift;

    const [date, setDate] = useState(period.start);
    const [start, setStart] = useState(formatDate(period.start, DateFormat.inputTime));
    const [end, setEnd] = useState(formatDate(period.end, DateFormat.inputTime));
    const [peopleLimit, setPeopleLimit] = useState(shift.peopleLimit);

    useEffect(() => {
        onFormDataChange({
            period: transformDateAndTimesToPeriod(date, start, end),
            peopleLimit,
        });
    }, [
        date,
        start,
        end,
        peopleLimit,
    ]);

    return (
        <div className={`shift-detail-main-form ${className}`}>
            <ModalContent
                showHistoryButton={showHistoryButton}
                title={trans('containers.forms.shiftDetailForm.info')}
                onHistoryButtonClick={onHistoryButtonClick}
            >
                <div className="shift-detail-main-form__body">
                    <div className="shift-detail-main-form__inputs">
                        <SelectInput
                            disabled
                            label={trans('common.department')}
                            name="department"
                            options={[{ value: department.id, label: department.name }]}
                            value={{ value: department.id, label: department.name }}
                            onChange={noop}
                        />

                        <DateInput
                            disabled={isDateInputDisabled}
                            label={trans('common.date')}
                            value={date}
                            onChange={setDate}
                        />

                        <TimeInput
                            label={trans('common.startTime')}
                            value={start}
                            onChange={setStart}
                        />

                        <TimeInput
                            label={trans('common.endTime')}
                            value={end}
                            onChange={setEnd}
                        />

                        <NumberInput
                            label={trans('containers.forms.shiftDetailForm.peopleLimit')}
                            value={peopleLimit}
                            onChange={setPeopleLimit}
                        />
                    </div>

                    {canDeleteShift && (
                        <Button
                            text={trans('containers.forms.shiftDetailForm.deleteShift')}
                            onClick={onDeleteButtonClick}
                            className="shift-detail-main-form__delete-button"
                        />
                    )}
                </div>
            </ModalContent>
        </div>
    );
};

export default ShiftDetailMainForm;
