import { FC, ReactElement } from 'react';

import { translate } from '../../../../helpers/translations/translator';
import { LockButton } from '../../../compositions';
import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import { PayrollPeriodWeek } from '../../../entities/PayrollPeriodWeek/PayrollPeriodWeek';
import { getDateLabel } from '../../../entities/Period/PeriodHelpers';
import { addSeconds, getPeriodWithoutStartOfDayCorrection } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { DateFormat } from '../../../types/dateFormatTypes';

import './LockPeriodForm.scss';

interface LockPeriodFormProps {
    isLoading: boolean;
    payrollPeriod: PayrollPeriod;
    onLockPeriodButtonClick: () => void;
    onLockPeriodWeekButtonClick: (periodWeek: PayrollPeriodWeek) => void;
    className?: string;
}

const LockPeriodForm: FC<LockPeriodFormProps> = ({
    isLoading,
    payrollPeriod,
    onLockPeriodButtonClick,
    onLockPeriodWeekButtonClick,
    className = '',
}): ReactElement => (
    <div className={`lock-period-form ${className}`}>
        <h3 className="lock-period-form__title">
            {trans('containers.forms.lockPeriodForm.lockPeriod')}
        </h3>

        <p className="lock-period-form__intro">
            {trans('containers.forms.lockPeriodForm.lockPeriodIntro')}
        </p>

        <LockButton
            disabled={isLoading}
            isLocked={payrollPeriod.isLocked}
            text={translate('common.period')}
            onClick={onLockPeriodButtonClick}
            className="lock-period-form__lock-period-button"
        />

        <h3 className="lock-period-form__title">
            {trans('containers.forms.lockPeriodForm.lockByWeek')}
        </h3>

        <p className="lock-period-form__intro">
            {trans('containers.forms.lockPeriodForm.lockByWeekIntro')}
        </p>

        <ul className="lock-period-form__weeks-list">
            {payrollPeriod.weeks.map((week, index) => {
                const justifiedPeriod = getPeriodWithoutStartOfDayCorrection(week.period);
                const handleLockWeekPeriodClick = () => onLockPeriodWeekButtonClick(week);

                return (
                    <li
                        key={week.id}
                        className="lock-period-form__weeks-list-item"
                    >
                        <LockButton
                            disabled={isLoading}
                            isLocked={week.isLocked}
                            text={`${trans('common.week')} ${index + 1}`}
                            onClick={handleLockWeekPeriodClick}
                            className="lock-period-form__lock-week-button"
                        />

                        {getDateLabel(
                            justifiedPeriod.start,
                            addSeconds(justifiedPeriod.end, -1),
                            true,
                            DateFormat.dateMonthShort,
                        )}
                    </li>
                );
            })}
        </ul>
    </div>
);

export default LockPeriodForm;
