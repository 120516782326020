import {
    FC,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useWindowScroll } from 'react-use';

import { CalendarControls, IconButton } from '../../../../compositions';
import { ConnectedHelpTooltip } from '../../../../connectors';
import { didElementScrollPastElement } from '../../../../helpers/browser';
import trans from '../../../../helpers/trans';
import { TimeModeType } from '../../../../types';
import { DateFormat } from '../../../../types/dateFormatTypes';

import './DistrictDivisionHeader.scss';

interface DistrictContainerHeaderProps {
    backButtonIsDisabled: boolean;
    showBackButton: boolean;
    showClearDistrictDivisionButton: boolean;
    showExportButton: boolean;
    selectedDate: Date;
    onBackButtonClick: () => void;
    onClearDistrictDivisionButtonClick: () => void;
    onDateChange: (date: Date) => void;
    onExportButtonClick: () => void;
    onIncrementClick: (goForward: boolean, isMobile?: boolean) => void;
    className?: string;
}

const DistrictContainerHeader: FC<DistrictContainerHeaderProps> = ({
    backButtonIsDisabled,
    showBackButton,
    showClearDistrictDivisionButton,
    showExportButton,
    selectedDate,
    onBackButtonClick,
    onClearDistrictDivisionButtonClick,
    onDateChange,
    onExportButtonClick,
    onIncrementClick,
    className = '',
}) => {
    const showButtons = showClearDistrictDivisionButton || showExportButton || showBackButton;
    const headerRef = useRef<HTMLDivElement>(null);
    const [elementScrolledPastElement, setElementScrolledPastElement] = useState<boolean>(false);
    const { y: scrollY } = useWindowScroll();

    useEffect((): void => {
        const parentElement = headerRef.current?.parentElement || null;
        const scrolledPastParent = didElementScrollPastElement(scrollY, parentElement, true);
        setElementScrolledPastElement(scrolledPastParent);
    }, [headerRef, scrollY]);

    const headerClassNames = classNames('district-division-header', {
        'district-division-header--scrolled-past-element': elementScrolledPastElement,
    }, className);

    const handleTodayButtonClick = (): void => {
        onDateChange(new Date());
    };

    return (
        <div className={headerClassNames} ref={headerRef}>
            <div className="district-division-header__calendar-controls">
                <CalendarControls
                    showTimeModeSelect
                    dateFormat={DateFormat.datePickerLabel}
                    inputType={TimeModeType.date}
                    selectedDays={[selectedDate]}
                    onTodayButtonClick={handleTodayButtonClick}
                    onDateChange={onDateChange}
                    onIncrementClick={onIncrementClick}
                    dateNavigationClassName="district-division-header__date-navigation"
                    todayButtonClassName="district-division-header__today-button"
                />
            </div>

            {showButtons && (
                <div className="district-division-header__buttons">
                    {showBackButton && (
                        <IconButton
                            disabled={backButtonIsDisabled}
                            icon="reset"
                            text={trans('common.back')}
                            onClick={onBackButtonClick}
                            className="district-division-header__back-button"
                            iconClassName="district-division-header__back-button-icon"
                        />
                    )}
                    {showClearDistrictDivisionButton && (
                        <IconButton
                            icon="trash"
                            text={trans('common.delete')}
                            onClick={onClearDistrictDivisionButtonClick}
                            className="district-division-header__reset-button"
                            iconClassName="district-division-header__reset-button-icon"
                        />

                    )}
                    {showExportButton && (
                        <>
                            <IconButton
                                icon="export"
                                text={trans('common.export')}
                                onClick={onExportButtonClick}
                                className="district-division-header__export-button"
                                iconClassName="district-division-header__export-button-icon"
                            />
                            <ConnectedHelpTooltip
                                index={4}
                                route="district-division"
                                subTitle={trans('help.districtDivision.exportDistrictDivision.title')}
                                text={trans('help.districtDivision.exportDistrictDivision.text')}
                                title={trans('help.districtDivision.title')}
                                showMobileInfoWarning
                                className="district-division-header__export-button-help-tooltip"
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default DistrictContainerHeader;
