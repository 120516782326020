import { hasUncheckedDivergentEmploymentHours } from '../../entities/Employment/EmploymentHelpers';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';
import { doesDateOverlapWithPayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriodHelpers';
import { getUsersWithEmploymentApiCall } from '../../entities/User/UserService';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setUsersWithDivergentEmploymentHours,
    setUsersWithIncompleteDivergentEmploymentHours,
} from './usersWithDivergentEmploymentHoursReducer';

export const fetchUsersWithDivergentEmploymentHours = (period: PayrollPeriod) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getUsersWithEmploymentApiCall({ showInactiveUsers: false });

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const usersWithDivergentEmploymentHours = response.data
            .filter(user => (
                (user.employment.start && doesDateOverlapWithPayrollPeriod(user.employment.start, period))
                || (user.employment?.end && doesDateOverlapWithPayrollPeriod(user.employment.end, period))));

        dispatch(setUsersWithDivergentEmploymentHours(usersWithDivergentEmploymentHours));
    } catch (error) {
        console.error('[fetchUsersWithDivergentEmploymentHours]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchUsersWithIncompleteDivergentEmploymentHours = (period: PayrollPeriod) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getUsersWithEmploymentApiCall({ showInactiveUsers: false });

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const usersWithDivergentEmploymentHours = response.data
            .filter(user => (
                (user.employment.start && doesDateOverlapWithPayrollPeriod(user.employment.start, period))
                || (user.employment?.end && doesDateOverlapWithPayrollPeriod(user.employment.end, period))));

        const usersWithIncompleteDivergentEmploymentHours = usersWithDivergentEmploymentHours
            .filter(user => hasUncheckedDivergentEmploymentHours(user.employment, period));

        dispatch(setUsersWithIncompleteDivergentEmploymentHours(usersWithIncompleteDivergentEmploymentHours));
    } catch (error) {
        console.error('[fetchUsersWithIncompleteDivergentEmploymentHours]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
