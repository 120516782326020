import React, { FC } from 'react';

import classNames from 'classnames';
import Tooltip from 'react-tooltip-lite';

import { ConnectedUserContractHours } from '../../../../../../@paco/connectors';
import { CompanyMedicType } from '../../../../../../@paco/types';
import UserPageLink from '../../../../../../components/UserPageLink/UserPageLink';
import { getFirstLetterOfEmploymentType, getUserFullName } from '../../../../../../helpers';
import { formatDate } from '../../../../../../helpers/date';
import { translate } from '../../../../../../helpers/translations/translator';
import { UserWithAvailability } from '../../../../../../models';
import { AvailabilityResourceTypes } from '../../../AvailabilityDays/subcomponents/Availability/Availability';
import CalendarButtonAdd from '../../../CalendarButtonAdd/CalendarButtonAdd';
import UserAvailabilityOnDate from '../UserAvailabilityOnDate/UserAvailabilityOnDate';

import './ListViewUser.scss';

interface Props {
    loading: boolean;
    user: UserWithAvailability;
    range: Date[];
    canAddPlannings: boolean;
    isFilterEmploymentTypeBadgeActive: boolean;
    onAvailabilityClick: (resource: AvailabilityResourceTypes) => void;
    onAddButtonClick: (date: Date, user: UserWithAvailability) => void;
}

const ListViewUser: FC<Props> = ({
    loading,
    user,
    range,
    canAddPlannings,
    isFilterEmploymentTypeBadgeActive,
    onAvailabilityClick,
    onAddButtonClick,
}) => {
    const rowClassName = classNames('list-view-user', {
        'list-view-user--is-company-medic': user.person.isCompanyMedic === CompanyMedicType.yes,
    });

    return (
        <tr key={user.id} className={rowClassName}>
            <td className="list-view-user__name-td">
                {(isFilterEmploymentTypeBadgeActive && user.employmentType)
                    && (
                        // @ts-ignore
                        <Tooltip className="list-view-user__tooltip" content={user.employmentType ? translate(`common.${user.employmentType.slug}`) : ''} arrowSize={6} padding={7}>
                            <span className="list-view-user__employment-type badge-level">
                                {getFirstLetterOfEmploymentType(user?.employmentType.slug)}
                            </span>
                        </Tooltip>
                    )}
                <span>
                    <UserPageLink name={getUserFullName(user)} id={user.id} className="list-view-user__name" />

                    <ConnectedUserContractHours
                        date={range[0]}
                        plannedHoursInWeek={user.plannedHoursInWeek}
                        userId={user.id}
                        className="list-view-user__hours"
                    />
                </span>
            </td>
            {range.map(day => (
                <td className="list-view-user__td" key={formatDate(day)}>
                    <div className="list-view-user__buttons">
                        <UserAvailabilityOnDate
                            loading={loading}
                            day={day}
                            user={user}
                            onAvailabilityClick={onAvailabilityClick}
                        />
                        {canAddPlannings && (
                            <CalendarButtonAdd
                                onClick={() => {
                                    onAddButtonClick(day, user);
                                }}
                                className="list-view-user__add-button"
                            />
                        )}
                    </div>
                </td>
            ))}
        </tr>
    );
};
export default ListViewUser;
