import React, { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useTypedSelector } from '../../../../@paco/redux/store';
import { getRangeFromDate } from '../../../../helpers/date';
import {
    approveExchangeRequest,
    approveShiftExchange,
    declineExchangeRequest,
    declineShiftExchange,
    getExchangeRequests,
} from '../../../../redux/absences-ts/absencesActions';
import { setEmployeeSearch, setFilter } from '../../../../redux/filter/filterActions';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import { Reducers } from '../../../../redux/reducers';
import { setStartAndEndDate } from '../../../../redux/weekNavigator/weekNavigatorActions';
import ExchangeRequestsContainer from '../../containers/ExchangeRequestsContainer/ExchangeRequestsContainer';
import sortRequestsByDate from './helpers/sortRequestsByDate';

const ConnectedExchangeRequests = () => {
    const dispatch = useDispatch();

    const {
        loading,
        exchangeRequests,
        shiftExchanges,
    } = useSelector((state: Reducers) => state.absencesReducer);
    const filter = useSelector((state: Reducers) => state.filterReducer.filter);
    const { departmentOptions } = useTypedSelector(state => state.globalFiltersReducer);
    const { permissions } = useTypedSelector(state => state.authenticatedUserReducer);
    const { mode } = useSelector((state: Reducers) => state.weekNavigatorReducer);

    const exchanges = useMemo(() => sortRequestsByDate([...exchangeRequests, ...shiftExchanges]), [exchangeRequests, shiftExchanges]);

    const getExchanges = () => {
        dispatch(getExchangeRequests());
    };

    useEffect(() => {
        getExchanges();
    }, [departmentOptions]);

    const onApproveExchange = async (exchangeId: string, userId: string) => {
        dispatch(approveExchangeRequest(exchangeId, userId));
    };

    const onDeclineExchange = (exchangeId: string) => {
        dispatch(declineExchangeRequest(exchangeId));
    };

    const onApproveShiftExchange = (shiftExchangeId: string) => {
        dispatch(approveShiftExchange(shiftExchangeId));
    };

    const onDeclineShiftExchange = (shiftExchangeId: string) => {
        dispatch(declineShiftExchange(shiftExchangeId));
    };

    const onSearchBarChange = async (search: string) => {
        await dispatch(setEmployeeSearch(search));
        getExchanges();
    };

    const onExchangeNavButtonClick = (date: Date) => {
        const days = getRangeFromDate(date, mode);
        dispatch(setStartAndEndDate(days[0], days[0], days[days.length - 1]));
    };

    const onFilterChange = (newFilters: AppFilter) => {
        dispatch(setFilter(newFilters, getExchanges));
    };

    return (
        <ExchangeRequestsContainer
            exchanges={exchanges}
            filter={filter}
            permissions={permissions}
            loading={loading}
            onFilterChange={onFilterChange}
            onApproveExchange={onApproveExchange}
            onDeclineExchange={onDeclineExchange}
            onApproveShiftExchange={onApproveShiftExchange}
            onDeclineShiftExchange={onDeclineShiftExchange}
            onSearchBarChange={onSearchBarChange}
            onExchangeNavButtonClick={onExchangeNavButtonClick}
        />
    );
};

export default ConnectedExchangeRequests;
