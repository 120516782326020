import { FC, ReactElement } from 'react';

import { PermissionsList } from '../../containers';
import { PermissionGroup } from '../../entities/PermissionGroup/PermissionGroup';
import { UserProfile } from '../../entities/User/User';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { patchUserPermissions } from '../../redux/user/userActions';

interface ConnectedPermissionsListProps {
    user: UserProfile;
    permissionGroups: PermissionGroup[];
}

const ConnectedPermissionsList: FC<ConnectedPermissionsListProps> = ({
    user,
    permissionGroups,
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { isPermissionsLoading } = useTypedSelector(state => state.pacoUserReducer);

    const onPermissionsChange = (permissionIds: string[]): void => {
        dispatch(patchUserPermissions(permissionIds, user));
    };

    return (
        <PermissionsList
            isLoading={isPermissionsLoading}
            userPermissions={user.permissions}
            configurableUserPermissions={user.configurablePermissions}
            permissionGroups={permissionGroups}
            onPermissionsChange={onPermissionsChange}
        />
    );
};

export default ConnectedPermissionsList;
