import { ShiftPlanning } from '../../../../@paco/entities/ShiftPlanning/ShiftPlanning';
import { formatDate } from '../../../../@paco/helpers/date';
import { DateFormat } from '../../../../@paco/types/dateFormatTypes';
import { ShiftConceptPlanningResource } from '../../../../entities/ShiftConceptPlanning/ShiftConceptPlanning';
import { UserWithConceptAvailability } from '../../../../models';

export const updateUsersWithConceptAvailabilityWithShiftPlanning = (
    users: UserWithConceptAvailability[],
    newShiftPlanning: ShiftPlanning,
): UserWithConceptAvailability[] => {
    const userToUpdate = users.find(user => user.id === newShiftPlanning.user.id);

    if (!userToUpdate) {
        return users;
    }

    const shiftPlanningIndex = userToUpdate.shiftConceptPlannings
        .findIndex(shiftConceptPlanning => shiftConceptPlanning.id === newShiftPlanning.id);

    if (shiftPlanningIndex === -1) {
        return users;
    }

    const shiftPlanning = userToUpdate.shiftConceptPlannings[shiftPlanningIndex];
    const updatedShiftPlanning: ShiftConceptPlanningResource = {
        ...shiftPlanning,
        start: formatDate(newShiftPlanning.period.start, DateFormat.inputDateTime),
        end: formatDate(newShiftPlanning.period.end, DateFormat.inputDateTime),
        customStartTime: newShiftPlanning.customStartTime,
    };

    const updatedShiftPlannings = [...userToUpdate.shiftConceptPlannings];
    updatedShiftPlannings.splice(shiftPlanningIndex, 1, updatedShiftPlanning);

    const updatedUser: UserWithConceptAvailability = {
        ...userToUpdate,
        shiftConceptPlannings: updatedShiftPlannings,
    };
    const updatedUserIndex = users.findIndex(user => user.id === updatedUser.id);
    const updatedUsers = [...users];
    updatedUsers.splice(updatedUserIndex, 1, updatedUser);

    return updatedUsers;
};
