import { FC } from 'react';

import classNames from 'classnames';

import { capitalizeFirstLetter } from '../../../../../helpers';
import { formatDate } from '../../../../helpers/date';
import { DateFormat } from '../../../../types/dateFormatTypes';
import { PrintableDistrictDivisionTitleBlock } from '../../models';

import './PrintableDistrictDivisionTitleBlockItem.scss';

interface PrintableDistrictDivisionDistrictBlockItemProps {
    block: PrintableDistrictDivisionTitleBlock;
    className?: string;
}

const PrintableDistrictDivisionDistrictBlockItem: FC<PrintableDistrictDivisionDistrictBlockItemProps> = ({ block, className = '' }) => {
    const titleClassName = classNames('printable-district-division-title-block-item', {
        'printable-district-division-title-block-item--is-hidden': block.isHidden,
    }, className);
    const date = capitalizeFirstLetter(formatDate(block.date, DateFormat.print));

    return (
        <div className={titleClassName}>
            {date}
        </div>
    );
};

export default PrintableDistrictDivisionDistrictBlockItem;
