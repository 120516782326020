import { FC } from 'react';

import classnames from 'classnames';

import { LoadingSpinner } from '../../../components';
import { ValkTable } from '../../../compositions';
import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import { UserWithEmployment } from '../../../entities/User/User';
import trans from '../../../helpers/trans';
import { IncompleteUserRow } from './subcomponents';

import './IncompleteUsersTable.scss';

interface IncompleteUsersTableProps {
    isLoading: boolean;
    activePeriod: PayrollPeriod;
    usersWithDivergentEmploymentHours: UserWithEmployment[];
    onEditUserClick: (user: UserWithEmployment) => void;
    onCheckUserClick: (user: UserWithEmployment, isChecked: boolean) => void;
    className?: string;
}

const tableHeader = [
    trans('common.name'),
    trans('containers.tables.incompleteUsersTable.employmentStartDate'),
    `${trans('common.contractHours')} ${trans('common.start').toLowerCase()}`,
    trans('containers.tables.incompleteUsersTable.employmentEndDate'),
    `${trans('common.contractHours')} ${trans('common.end').toLowerCase()}`,
];

const IncompleteUsersTable: FC<IncompleteUsersTableProps> = ({
    isLoading,
    activePeriod,
    usersWithDivergentEmploymentHours,
    onEditUserClick,
    onCheckUserClick,
    className = '',
}) => {
    const wrapperClassName = classnames('incomplete-users-table', {
        'incomplete-users-table--is-empty': !usersWithDivergentEmploymentHours.length,
    }, className);

    if (isLoading && !usersWithDivergentEmploymentHours.length) {
        return (
            <div className={wrapperClassName}>
                <LoadingSpinner isSmall className="incomplete-users-table__loader" />
            </div>
        );
    }

    return (
        <div className={wrapperClassName}>
            <ValkTable<UserWithEmployment>
                rows={usersWithDivergentEmploymentHours}
                tableHeaders={tableHeader}
                tableClassName="incomplete-users-table__table"
                cellClassName="incomplete-users-table__table-cell"
            >
                {(users): JSX.Element[] => users.map(user => (
                    <IncompleteUserRow
                        key={user.id}
                        activePeriod={activePeriod}
                        user={user}
                        onEditUserClick={onEditUserClick}
                        onCheckUserClick={onCheckUserClick}
                    />
                ))}
            </ValkTable>
            {isLoading && <LoadingSpinner isSmall className="incomplete-users-table__loader" />}
        </div>
    );
};

export default IncompleteUsersTable;
