import axios from 'axios';

import { formatDate, getEndOfWorkDay, getStartOfWorkDay } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import {
    ApiResponse,
    DataWithPagination,
    Filter,
    PaginationRequestApiParams,
    TrackResource,
    TrackType,
    TrackViewModel,
} from '../../models';
import transformTrackResource from '../../services/TrackService/transformTrackResource';

export function getApproved(trackType: TrackType): string {
    if (trackType === TrackType.review) {
        return '?approved=0';
    }
    if (trackType === TrackType.finished) {
        return '?approved=1';
    }
    return '';
}

interface TracksFilter extends Filter {
    openTracksInPeriod?: boolean;
}

export async function getOpenTracksInDateRange(
    startDate: Date,
    endDate: Date,
    includes: string[] = [],
): Promise<DataWithPagination<TrackViewModel[]>> {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tracks`,
        {
            params: {
                include: includes.join(','),
                startDate: formatDate(startDate, DateFormat.apiDateTime),
                endDate: formatDate(endDate, DateFormat.apiDateTime),
                onlyShowWithOverlappingDepartments: false,
                withOpenCheckOut: true,
            },
        },
    ) as unknown as ApiResponse<TrackResource[]>;

    const data = response.data.map(resource => transformTrackResource(
        resource,
        resource.owner,
        resource.department,
        resource.feedback,
        resource.shift,
    ));

    return {
        data,
        pagination: response.meta.pagination,
    };
}

export async function getTracksToReviewInDateRange(
    startDate: Date,
    endDate: Date,
    includes: string[] = [],
): Promise<DataWithPagination<TrackViewModel[]>> {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tracks?approved=0`,
        {
            params: {
                include: includes.join(','),
                startDate: formatDate(startDate, DateFormat.apiDateTime),
                endDate: formatDate(endDate, DateFormat.apiDateTime),
                onlyShowWithOverlappingDepartments: true,
                withOpenCheckOut: false,
            },
        },
    ) as unknown as ApiResponse<TrackResource[]>;

    const data = response.data.map(resource => transformTrackResource(
        resource,
        resource.owner,
        resource.department,
        resource.feedback,
        resource.shift,
    ));

    return {
        data,
        pagination: response.meta.pagination,
    };
}

export async function getTracksInDateRangeLegacy(
    startDate: Date,
    endDate: Date,
    trackType: TrackType,
    filter: TracksFilter = {},
    pagination?: PaginationRequestApiParams,
    includes: string[] = [],
    onlyShowWithOverlappingDepartments = false,
): Promise<DataWithPagination<TrackViewModel[]>> {
    const {
        userIds,
        departments,
        employeeSearch,
        employeeContractTypes,
        openTracksInPeriod,
    } = filter || {};

    const finishedCheckout = trackType === TrackType.finished;
    const withOpenCheckOut = trackType === TrackType.open;

    const formattedStartDate = (!withOpenCheckOut || openTracksInPeriod) ? formatDate(getStartOfWorkDay(startDate), DateFormat.apiDateTime) : undefined;
    const formattedEndDate = (!withOpenCheckOut || openTracksInPeriod) ? formatDate(getEndOfWorkDay(endDate), DateFormat.apiDateTime) : undefined;

    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tracks${getApproved(trackType)}`,
        {
            params: {
                include: includes.join(','),
                ...(departments && { departments }),
                ...(employeeSearch && { search: employeeSearch }),
                onlyShowWithOverlappingDepartments,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                withOpenCheckOut: !finishedCheckout ? withOpenCheckOut : undefined,
                userUuids: (userIds && userIds.length > 0) ? JSON.stringify(userIds) : undefined,
                employmentTypes: (employeeContractTypes && employeeContractTypes.length > 0) ? JSON.stringify(employeeContractTypes) : undefined,
                ...(pagination && { 'page[number]': pagination.number || 1 }),
                ...(pagination && { 'page[size]': pagination.size }),
            },
        },
    ) as unknown as ApiResponse<TrackResource[]>;

    const data = response.data.map(resource => transformTrackResource(
        resource,
        resource.owner,
        resource.department,
        resource.feedback,
        resource.shift,
        resource.comments,
    ));

    return {
        data,
        pagination: response.meta.pagination,
    };
}

export async function getTracksWithFeedbackForUser(
    userIds: string[] = [],
    includes: string[] = [],
    pagination?: PaginationRequestApiParams,
) {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tracks/`,
        {
            params: {
                include: (includes || []).join(','),
                ...(pagination && { 'page[number]': pagination.number || 1 }),
                ...(pagination && { 'page[size]': pagination.size }),
                userUuids: userIds.length > 0 ? JSON.stringify(userIds) : undefined,
            },
        },
    ) as unknown as ApiResponse<TrackResource[]>;

    const onlyTracksWithFeedback = response.data.filter((track) => track.feedback);

    return onlyTracksWithFeedback.map((resource) => transformTrackResource(
        resource,
        resource.owner,
        resource.department,
        resource.feedback,
        resource.shift,
    ));
}
