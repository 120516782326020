import axios from 'axios';

import { CopyTemporaryWorkerData } from '../../models';

interface AddTemporaryWorkerApiRequestParams {
    type: 'temporaryWorkers';
    attributes: {
        start: string;
        end: string;
        name: string;
        color: null | string;
    };
    relationships: {
        shift: {
            data: {
                type: 'shifts',
                id: string;
            },
        },
    }
}

function transformCopyTemporaryWorkerDataToAddApiParams(
    data: CopyTemporaryWorkerData,
): AddTemporaryWorkerApiRequestParams {
    const {
        start, end, name, color,
    } = data;

    return {
        type: 'temporaryWorkers',
        attributes: {
            start,
            end,
            name,
            color,
        },
        relationships: {
            shift: {
                data: {
                    type: 'shifts',
                    id: data.shiftId,
                },
            },
        },
    };
}

export const copyTempWorker = (data: CopyTemporaryWorkerData) => {
    const body = transformCopyTemporaryWorkerDataToAddApiParams(data);
    return axios.post(`${process.env.REACT_APP_API_URL}/temporary-workers`, { data: body });
};
