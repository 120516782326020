import { getUserFullName } from '../../helpers';
import { transformToBaseScheduleShift } from '../BaseScheduleShift/BaseScheduleShiftTransformers';
import { transformDepartmentResource } from '../Departments/DepartmentsTransformers';
import {
    BaseScheduleFormData,
    BaseScheduleIndexViewModel,
    BaseScheduleRequest,
    BaseScheduleResource,
    BaseScheduleViewModel,
    PublishBaseScheduleResultResource,
    PublishBaseScheduleResultViewModel,
} from './BaseSchedule';

export const transformToBaseScheduleIndex = (baseScheduleResource: BaseScheduleResource, lazyLoaded = false): BaseScheduleIndexViewModel => ({
    id: baseScheduleResource.id,
    name: baseScheduleResource.name,
    createdBy: getUserFullName(baseScheduleResource.owner),
    shifts: (baseScheduleResource.baseScheduleShifts || [])
        .map((shift) => transformToBaseScheduleShift(shift)),
    departments: (lazyLoaded && baseScheduleResource.baseScheduleShifts)
        ? baseScheduleResource.baseScheduleShifts.map(({ department }) => (transformDepartmentResource(department)))
        : [],
});

export const transformToBaseSchedule = (baseScheduleResource: BaseScheduleResource): BaseScheduleViewModel => ({
    id: baseScheduleResource.id,
    name: baseScheduleResource.name,
    shifts: baseScheduleResource.baseScheduleShifts?.map(transformToBaseScheduleShift) || [],
});

export const transformToBaseScheduleFormDataToRequest = (baseScheduleFormData: BaseScheduleFormData): BaseScheduleRequest => ({
    type: 'baseSchedules',
    id: baseScheduleFormData?.id,
    attributes: {
        name: baseScheduleFormData.name,
    },
});

export const transformToPublishBaseScheduleResult = (publishBaseScheduleResult: PublishBaseScheduleResultResource): PublishBaseScheduleResultViewModel => ({
    id: publishBaseScheduleResult.id,
    type: 'publishBaseScheduleResult',
    attributes: {
        baseScheduleStatuses: publishBaseScheduleResult.baseScheduleStatuses,
        publishedCount: publishBaseScheduleResult.publishedCount,
    },
});
