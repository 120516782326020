export interface DropboxResource {
    type: 'file' | 'folder';
    link: string;
    name: string;
    path: string;
}

export interface DropboxFileResource extends DropboxResource {
    type: 'file';
    modified: string;
    size: number;
    readableSize: string;
}

export interface DropboxFolderResource extends DropboxResource {
    type: 'folder';
    children: DropboxFolderResource[];
}

export enum DropboxEntityType {
    file = 'file',
    folder = 'folder',
}

export interface DropboxEntity {
    type: DropboxEntityType,
    id: string;
    modifiedAt?: Date;
    link: string;
    name: string;
    path: string;
    size?: number;
    readableSize?: string;
}

export interface DropboxFolder {
    id: string;
    type: 'folder';
    name: string;
    path: string;
    levelOfDeepness: number;
    children: DropboxFolder[];
}
