import { convertDateToApiParamDateTime } from '../../helpers/date';
import { DepartmentResource, DepartmentSoftDeleteRequest, DepartmentViewModel } from './Departments';

export const transformDepartmentResource = (
    department: DepartmentResource,
): DepartmentViewModel => ({
    type: department.type,
    id: department.id,
    name: department.name,
    deletedAt: department.deletedAt ? new Date(department.deletedAt) : undefined,
    ...(department.group && { group: department.group }),
});

export const transformToDepartmentSoftDeleteRequest = (department: DepartmentViewModel): DepartmentSoftDeleteRequest => ({
    type: 'departments',
    id: department.id,
    attributes: {
        deletedAt: convertDateToApiParamDateTime(new Date()),
    },
});

