import React from 'react';

import PropTypes from 'prop-types';

import './SettingsCardSection.scss';

const SettingsCardSection = ({
    children,
    title = null,
    text = null,
    disabled = false,
}) => (
    <div className={`settings-card-section ${disabled ? 'settings-card-section--disabled' : ''}`}>
        { (title || text) && (
            <div className="settings-card-section-left">
                <div className="settings-card-section-title">{title}</div>
                <div className="settings-card-section-text">{text}</div>
            </div>
        )}
        <div className="settings-card-section-right">
            {children}
        </div>
    </div>
);

SettingsCardSection.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

export default SettingsCardSection;
