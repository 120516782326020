import { GroupedSelectOption } from '../../components/@inputs/Select/Select';
import { formatDate } from '../../helpers/date';
import { SelectOption } from '../../types';
import { DateFormat } from '../../types/dateFormatTypes';
import { Comment } from '../Comment/Comment';
import { DistrictDivisionGroup } from '../DistrictDivisionGroup/DistrictDivisionGroup';
import { DistrictDivision, DistrictDivisionResource } from './DistrictDivision';

export const transformToDistrictDivision = (
    resource: DistrictDivisionResource,
    districtDivisionGroups: DistrictDivisionGroup[],
    comments: Comment[],
): DistrictDivision => ({
    id: resource.id,
    createdAt: resource.attributes.createdAt ? new Date(resource.attributes.createdAt) : new Date(resource.attributes.date),
    date: new Date(resource.attributes.date),
    name: resource.attributes.name,
    comments,
    districtDivisionGroups,
});

const transformDistrictDivisionTemplateToSelectOption = (districtDivision: DistrictDivision): SelectOption => ({
    label: `${districtDivision.name} - ${formatDate(districtDivision.createdAt, DateFormat.dateTime)}`,
    value: districtDivision.id,
});

export const transformDistrictDivisionsToGroupedSelectOptions = (districtDivisions: DistrictDivision[]): GroupedSelectOption[] => [
    {
        label: '',
        options: districtDivisions.map(transformDistrictDivisionTemplateToSelectOption),
    },
];
