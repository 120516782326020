import axios, { AxiosResponse } from 'axios';

import { AbsenceHoursResource } from '../../models';
import { DeepPartial } from '../../redux/models';

interface AbsenceHourApiParams {
    type: 'absenceHours';
    id?: string;
    attributes: {
        hours: number | null;
        weekNumber: number;
        waitingDayHours: number;
    }
    relationships: {
        absence: {
            data: {
                type: 'absences',
                id: string;
            }
        }
        payrollPeriod: {
            data: {
                type: 'payrollPeriods',
                id: string;
            }
        }
    }
}

interface ClearAbsenceHoursApiParams extends DeepPartial<AbsenceHourApiParams> {
    type: 'absenceHours';
    id: string;
    attributes: {
        hours: 0;
        waitingDayHours: 0;
    }
}

export interface PatchAbsenceHourData {
    id: string,
    absenceId: string,
    payrollPeriodId: string,
    hours: number | null,
    weekNumber: number,
    waitingDayHours: number,
}

export function generateAbsenceHoursBody(
    absenceId: string,
    payrollPeriodId: string,
    hours: number | null,
    weekNumber: number,
    waitingDayHours: number,
    absenceHourId?: string,
): AbsenceHourApiParams {
    return {
        type: 'absenceHours',
        ...(absenceHourId && { id: absenceHourId }),
        attributes: {
            hours,
            weekNumber,
            waitingDayHours,
        },
        relationships: {
            absence: {
                data: {
                    type: 'absences',
                    id: absenceId,
                },
            },
            payrollPeriod: {
                data: {
                    type: 'payrollPeriods',
                    id: payrollPeriodId,
                },
            },
        },
    };
}

export async function postAbsenceHour(
    absenceId: string,
    payrollPeriodId: string,
    hours: number | null,
    weekNumber: number,
    waitingDayHours: number,
): Promise<AbsenceHoursResource> {
    const body = generateAbsenceHoursBody(
        absenceId,
        payrollPeriodId,
        hours,
        weekNumber,
        waitingDayHours,
    );

    const response: AxiosResponse<AbsenceHoursResource> = await axios.post(
        `${process.env.REACT_APP_API_URL}/absence-hours/`,
        { data: body },
    );

    return response.data;
}

export async function patchAbsenceHours(
    data: PatchAbsenceHourData[],
): Promise<AbsenceHoursResource[]> {
    if (!data.length) {
        return [];
    }

    const body = data.map((absenceHour) => generateAbsenceHoursBody(
        absenceHour.absenceId,
        absenceHour.payrollPeriodId,
        absenceHour.hours,
        absenceHour.weekNumber,
        absenceHour.waitingDayHours,
        absenceHour.id,
    ));

    const response: AxiosResponse<AbsenceHoursResource[]> = await axios.patch(
        `${process.env.REACT_APP_API_URL}/absence-hours`,
        { data: body },
    );

    return response.data;
}

export async function clearAbsenceHours(absenceHourIds: string[]): Promise<AbsenceHoursResource[]> {
    if (!absenceHourIds.length) {
        return [];
    }

    const body: ClearAbsenceHoursApiParams[] = absenceHourIds.map((id) => ({
        id,
        type: 'absenceHours',
        attributes: {
            hours: 0,
            waitingDayHours: 0,
        },
    }));

    const response: AxiosResponse<AbsenceHoursResource[]> = await axios.patch(
        `${process.env.REACT_APP_API_URL}/absence-hours/`,
        { data: body },
    );

    return response.data;
}
