import { ReactElement, ReactNode } from 'react';

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableRow,
} from '../../../components';
import { SortConfig, SortDirection, SortField } from '../../../types/tableTypes';
import { SortLabel } from '../../index';
import { SortLabelInfo } from '../SortLabel/SortLabel';

import './SortValkTable.scss';

interface SortValkTableProps<Interface> {
    hasRowClick?: boolean;
    caption?: string;
    sortCells: SortLabelInfo[];
    rows?: Interface[];
    sortConfig: SortConfig;
    hasStickyColumn?: boolean;
    onSort: (field?: SortField, direction?: SortDirection) => void;
    headerRowClassName?: string;
    cellClassName?: string;
    cellContentClassName?: string;
    className?: string;
    tableClassName?: string;
    headClassName?: string;
    children?: (sortedRows: Interface[]) => ReactNode;
}

const SortValkTable = <T, >({
    hasRowClick,
    caption,
    sortConfig,
    rows = [],
    sortCells,
    headerRowClassName = '',
    cellClassName = '',
    cellContentClassName = '',
    className = '',
    tableClassName = '',
    headClassName = '',
    onSort,
    children,
}: SortValkTableProps<T>): ReactElement => {
    const { field, direction } = sortConfig;

    return (
        <div className={`sort-valk-table ${className}`}>
            <Table className={`sort-valk-table__table ${tableClassName}`}>
                {caption && <TableCaption title={caption} />}

                <TableHead className={`sort-valk-table__head ${headClassName}`}>
                    <TableRow className={`sort-valk-table__header-row ${headerRowClassName}`}>
                        {sortCells.map(cell => (
                            <SortLabel
                                {...cell}
                                key={cell.label}
                                isActive={(cell.field === field)}
                                isDisabled={!cell.field}
                                activeDirection={direction}
                                onSort={onSort}
                                className={cellClassName}
                                contentClassName={cellContentClassName}
                            />
                        ))}
                        {hasRowClick && <TableCell className="sort-valk-table__hidden-cell" />}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {children && children(rows)}
                </TableBody>
            </Table>
        </div>
    );
};

export default SortValkTable;
