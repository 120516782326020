import { DocumentFormData } from '../../../../../models';


export default function generateDocumentFormEmptyValues(): DocumentFormData {
    return {
        id: '',
        documentType: undefined,
        name: '',
        ext: '',
        base64: '',
        file: undefined,
        departments: [],
        roles: [],
        willNotifyUsersAfterSuccess: false,
    };
}
