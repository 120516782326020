import axios from 'axios';

import {
    ApiResponse,
    FileResource,
    User,
    UserDocumentViewModel,
} from '../../models';
import transformFileResourceToUserDocument from '../../services/UserDocument/transformFileResourceToUserDocument';

export async function getDocumentsForUser(user: User): Promise<UserDocumentViewModel[]> {
    const include = ['viewers', 'documentType', 'departmentAccess', 'roleAccess'];

    const roleSlugs = user.roles.map(role => role.slug);
    const departmentIds = user.departments.map(department => department.id);

    const response = await axios
        .get(
            `${process.env.REACT_APP_API_URL}/files`,
            {
                params: {
                    linkedUser: user.id,
                    fileRoles: (roleSlugs && roleSlugs.length > 0) ? JSON.stringify(roleSlugs) : undefined,
                    departments: JSON.stringify(departmentIds),
                    include: include.join(','),
                },
            },
        ) as unknown as ApiResponse<FileResource[]>;

    return response
        .data.map((file) => transformFileResourceToUserDocument(
            file,
            user.id,
            user.departments,
            user.roles,
            file.documentType,
            file.viewers,
            file.departmentAccess,
            file.roleAccess,
        ));
}
