import { PublishBaseScheduleFormData } from '../../../../../entities/ShiftConcept/ShiftConcept';
import { compareAsc } from '../../../../../helpers/date';
import { translate } from '../../../../../helpers/translations/translator';


export interface ValidationErrors {
    date?: string;
    baseSchedule?: string;
}

const validateFormData = (
    formData: PublishBaseScheduleFormData,
    isSubmitted = true,
): ValidationErrors => {
    const errors: ValidationErrors = {};

    if (compareAsc(formData.endDate, formData.startDate) === -1) {
        errors.date = translate('pages.management.endDateNeedsToBeLaterThanStartDate');
    }

    if (!isSubmitted) {
        return errors;
    }

    if (!formData.baseScheduleId) {
        errors.baseSchedule = translate('validation.isRequired', { item: translate('common.baseSchedule') });
    }

    return errors;
};

export default validateFormData;
