import trans from '../../@paco/helpers/trans';
import { Role } from '../../models';

export const getTranslationForRole = (slug: string) => trans(`entities.roles.name.${slug.toLowerCase()}`);

export const translateRoles = (roles: Role[]) => roles.map(
    role => ({
        label: getTranslationForRole(role.slug),
        value: role.id,
    }),
);
