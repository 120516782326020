import { LoketLeaveSyncErrorResource } from './LoketLeaveSync';

export const isLoketLeaveSyncError = (resource: LoketLeaveSyncErrorResource): resource is LoketLeaveSyncErrorResource => (
    typeof resource === 'object'
        && resource !== null
        && 'leaveId' in resource
        && 'message' in resource
);

export const isLoketLeaveSyncErrorCollection = (collection: LoketLeaveSyncErrorResource[]): boolean => Array.isArray(collection) && collection.every(isLoketLeaveSyncError);
