import { FC } from 'react';

import { useDispatch } from 'react-redux';

import { notifyUsers } from '../../../../redux/app/appActions';
import { LegacyNotifyEmployeesForm } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

interface ConnectedLegacyNotifyEmployeesFormProps {
    onClose: () => void;
}

const ConnectedLegacyNotifyEmployeesForm: FC<ConnectedLegacyNotifyEmployeesFormProps> = ({ onClose }) => {
    const dispatch = useDispatch();
    const { departments } = useTypedSelector(state => state.authenticatedUserReducer);

    const handleSubmitNotifyEmployees = (formData: any): void => {
        dispatch(notifyUsers(formData));
        onClose();
    };

    return (
        <LegacyNotifyEmployeesForm
            departments={departments}
            onSubmit={handleSubmitNotifyEmployees}
            onCancel={onClose}
        />
    );
};

export default ConnectedLegacyNotifyEmployeesForm;
