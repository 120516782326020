import { FC } from 'react';

import { Icon } from '../../../components';
import { IconSet } from '../../../components/Icon/Icon';
import { ElementWithTooltip } from '../../index';

import './ShiftEmployeeIcon.scss';

export interface ShiftEmployeeIconType {
    iconName: keyof IconSet;
    tooltipText?: string;
}

export interface ShiftEmployeeIconProps extends ShiftEmployeeIconType {
    className?: string;
}

const ShiftEmployeeIcon: FC<ShiftEmployeeIconProps> = ({
    iconName,
    tooltipText,
    className = '',
}) => (
    <ElementWithTooltip
        tooltipText={tooltipText}
        tooltipIsActive={!!tooltipText}
    >
        <Icon
            name={iconName}
            className={`paco-shift-employee-icon ${className}`}
        />
    </ElementWithTooltip>
);

export default ShiftEmployeeIcon;
