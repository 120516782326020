import { FC, ReactElement, useEffect } from 'react';

import { KnowledgeBaseSidebar } from '../../containers';
import { changeActiveDirectoryId, fetchDropboxFolders } from '../../redux/dropbox/dropboxActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

const ConnectedKnowledgeBaseSidebar: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const {
        activeDirectoryId,
        isLoading,
        isFoldersLoading,
        isSearchLoading,
        folders,
        activeResultType,
    } = useTypedSelector(state => state.dropboxReducer);

    useEffect((): void => {
        dispatch(fetchDropboxFolders());
    }, []);

    const handleFolderClick = (newActiveDirectoryId: string): void => {
        dispatch(changeActiveDirectoryId(newActiveDirectoryId));
    };

    return (
        <KnowledgeBaseSidebar
            isLoading={isLoading || isFoldersLoading || isSearchLoading}
            dropboxFolders={folders}
            activeDirectoryId={activeDirectoryId}
            resultType={activeResultType}
            onFolderClick={handleFolderClick}
        />
    );
};

export default ConnectedKnowledgeBaseSidebar;
