import React, { FC } from 'react';

import { LoadingSpinner } from '../../../../@paco/components';
import { areDatesInSameMonth, formatDate } from '../../../../helpers/date';
import { DayProps } from './models';
import Day from './subcomponents/Day/Day';

import './Days.scss';

interface Props extends DayProps {
    selectedDays: Date[];
    loading?: boolean;
}

const Days: FC<Props> = ({
    mode,
    mobile = false,
    selectedDays,
    leaveOfAbsences,
    absences,
    preferToWorkTimeSlots,
    unavailableToWorkTimeSlots,
    onResourceClick,
    loading = false,
}) => (
    <>
        {
            selectedDays.map(day => {
                const key = formatDate(day);
                const outOfRange = mode === 'month' && !areDatesInSameMonth(day, selectedDays[selectedDays.length - 1]);

                return (
                    <Day
                        key={key}
                        day={day}
                        mode={mode}
                        mobile={mobile}
                        leaveOfAbsences={leaveOfAbsences}
                        absences={absences}
                        preferToWorkTimeSlots={preferToWorkTimeSlots}
                        unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                        onResourceClick={onResourceClick}
                        outOfRange={outOfRange}
                    />
                );
            })
        }
        {loading && <LoadingSpinner className="availability-days__loading-spinner" />}
    </>
);

export default Days;
