import { FC } from 'react';

import { ErrorLabel, InputLabel, Select } from '../../../components';
import { GroupedSelectOption, SelectOption } from '../../../components/@inputs/Select/Select';

import './SelectInput.scss';

interface SelectInputProps {
    isLoading?: boolean;
    hideLabel?: boolean;
    isSearchable?: boolean;
    loadingMessage?: string;
    noOptionsMessage?: string;
    label: string;
    name: string;
    value?: SelectOption;
    options: SelectOption[] | GroupedSelectOption[];
    placeholder?: string;
    required?: boolean;
    requiredText?: string;
    requiredTextIsAlwaysVisible?: boolean;
    tooltip?: string;
    disabled?: boolean;
    error?: string;
    onChange: (option: SelectOption) => void;
    className?: string;
    selectClassName?: string;
    requiredClassName?: string;
}

const SelectInput: FC<SelectInputProps> = ({
    isLoading = false,
    hideLabel = false,
    isSearchable = false,
    loadingMessage,
    noOptionsMessage,
    label,
    name,
    value,
    options,
    placeholder,
    requiredTextIsAlwaysVisible = false,
    required = false,
    tooltip,
    disabled = false,
    requiredText = '',
    error = '',
    onChange,
    className = '',
    selectClassName = '',
    requiredClassName = '',
}) => {
    const handleChange = (selected: SelectOption): void => {
        onChange(selected);
    };

    return (
        <div className={`select-input ${className}`}>
            <label
                aria-label={label}
                htmlFor={name}
                className={`select-input__label ${className}`}
            >
                {!hideLabel && (
                    <InputLabel
                        text={label}
                        requiredTextIsAlwaysVisible={requiredTextIsAlwaysVisible}
                        required={required}
                        requiredText={requiredText}
                        tooltip={tooltip}
                        requiredClassName={requiredClassName}
                    />
                )}
            </label>
            <Select
                isSearchable={isSearchable}
                isLoading={isLoading}
                id={name}
                loadingMessage={loadingMessage}
                noOptionsMessage={noOptionsMessage}
                name={name}
                value={value}
                options={options}
                placeholder={placeholder}
                disabled={disabled}
                error={error}
                onChange={handleChange}
                className={selectClassName}
            />
            {error && <ErrorLabel text={error} className="select-input__error-label" />}
        </div>
    );
};

export default SelectInput;
