export enum EventLogEntityType {
    absence = 'absence',
    leaveOfAbsence = 'leaveOfAbsence',
    user = 'user',
}

export enum EventLogActionType {
    created = 'created',
    updated = 'updated',
}
