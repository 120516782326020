import { FC, useEffect, useState } from 'react';

import classnames from 'classnames';

import { MultiSelect, PacoButtonLoadingSpinner } from '../../components';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';
import {
    filterPeriodsByYear,
    getPayrollPeriodFromDate,
    getYearsFromPeriods,
} from '../../entities/PayrollPeriod/PayrollPeriodHelpers';
import trans from '../../helpers/trans';
import { SelectOption } from '../../types';
import { BasicSelect } from '../index';
import { generateNumberSelectOptions, generateYearSelectOptions } from './helpers';

import './SelectPayrollPeriod.scss';

interface SelectPayrollPeriodProps {
    isLoading?: boolean;
    isMultiple?: boolean;
    hideLabel?: boolean;
    hasPeriodTitle?: boolean;
    activePeriod?: PayrollPeriod;
    periods: PayrollPeriod[];
    onChange: (period: PayrollPeriod[]) => void;
    className?: string;
    colClassName?: string;
}

const SelectPayrollPeriod: FC<SelectPayrollPeriodProps> = ({
    isLoading,
    isMultiple,
    hideLabel = false,
    hasPeriodTitle = false,
    activePeriod,
    periods,
    onChange,
    className = '',
    colClassName = '',
}) => {
    const wrapperClassNames = classnames('select-payroll-period', {
        'select-payroll-period--has-period-title': hasPeriodTitle,
        'select-payroll-period--hide-label': hideLabel,
        'select-payroll-period--is-multiple': isMultiple,
    }, className);

    const [years, setYears] = useState<SelectOption[]>([]);
    const [numbers, setNumbers] = useState<SelectOption[]>([]);
    const [selectedYear, setSelectedYear] = useState<number>(0);
    const [selectedPayrollPeriods, setSelectedPayrollPeriods] = useState<SelectOption[]>([]);

    useEffect((): void => {
        if (!periods.length) {
            return;
        }

        const period = activePeriod || getPayrollPeriodFromDate(new Date(), periods) as PayrollPeriod;
        const newNumbers = generateNumberSelectOptions(
            filterPeriodsByYear(periods, period.year),
            hasPeriodTitle,
        );
        const newSelectedPayrollPeriod = newNumbers.find(option => option.value === activePeriod?.id);

        setYears(generateYearSelectOptions(getYearsFromPeriods(periods)));
        setNumbers(newNumbers);
        setSelectedYear(period.year);
        setSelectedPayrollPeriods(newSelectedPayrollPeriod ? [newSelectedPayrollPeriod] : []);
    }, [periods]);

    const handleYearChange = (option: SelectOption): void => {
        const newYear = parseInt(option.value, 10);
        const periodsFromYear = filterPeriodsByYear(periods, newYear);
        const newNumbers = generateNumberSelectOptions(periodsFromYear, hasPeriodTitle);

        if (!isMultiple && newNumbers.length) {
            setSelectedPayrollPeriods([newNumbers[0]]);
        }

        setSelectedYear(newYear);
        setNumbers(newNumbers);
    };

    const handleSelectChange = (option: SelectOption): void => {
        const matchingPeriod = periods.find(period => period.id === option.value);

        if (matchingPeriod) {
            onChange([matchingPeriod]);
        }

        setSelectedPayrollPeriods([option]);
    };

    const handleMultiSelectChange = (options: SelectOption[]): void => {
        const matchingPeriods = periods.filter(period => options.some(option => option.value === period.id));

        onChange(matchingPeriods);
        setSelectedPayrollPeriods(options);
    };

    if (isLoading) {
        return (
            <div className={`${wrapperClassNames} select-payroll-period--is-loading`}>
                <div className={colClassName}>
                    <PacoButtonLoadingSpinner />
                </div>
            </div>
        );
    }

    if (periods.length === 0) {
        return (
            <div className={`${wrapperClassNames} select-payroll-period--has-no-periods`}>
                <div className={colClassName}>
                    {trans('compositions.selectPayrollPeriod.noPayrollPeriodsWarning')}
                </div>
            </div>
        );
    }

    return (
        <div className={wrapperClassNames}>
            <div className={`select-payroll-period__col col-lg-3 ${colClassName}`}>
                {!hideLabel && (
                    <label className="select-payroll-period__label">
                        {trans('types.timeMode.year')}
                    </label>
                )}
                <BasicSelect
                    options={years}
                    value={selectedYear.toString()}
                    onChange={handleYearChange}
                    className="select-payroll-period__select-year"
                    selectClassName="select-payroll-period__select"
                />
            </div>

            <div className={`select-payroll-period__col ${colClassName}`}>
                {!hideLabel && (
                    <label className="select-payroll-period__label">
                        {trans('types.timeMode.period')}
                    </label>
                )}

                {isMultiple ? (
                    <MultiSelect
                        isLoading={false}
                        options={numbers}
                        value={selectedPayrollPeriods}
                        onChange={handleMultiSelectChange}
                        className="select-payroll-period__select-period"
                    />
                ) : (
                    <BasicSelect
                        options={numbers}
                        value={selectedPayrollPeriods[0]?.value}
                        onChange={handleSelectChange}
                        className="select-payroll-period__select-period"
                        selectClassName="select-payroll-period__select"
                    />
                )}
            </div>
        </div>
    );
};

export default SelectPayrollPeriod;
