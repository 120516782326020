import { AddDistrictDivisionTemplateFormData } from '../../../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../services/validationService';

export type AddDistrictDivisionTemplateFormErrors = FormErrors<AddDistrictDivisionTemplateFormData>;

export const validateAddDistrictDivisionTemplateFormData = (formData: AddDistrictDivisionTemplateFormData): FormValidation<AddDistrictDivisionTemplateFormErrors> => {
    const errors: AddDistrictDivisionTemplateFormErrors = {
        name: validateRequiredString('name', formData.name),
    };

    return validateForm<AddDistrictDivisionTemplateFormErrors>(errors);
};
