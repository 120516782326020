import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import Select from 'react-select';
import {
    Button,
    Col,
    CustomInput,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import SelectDepartments from '../../../../components/forms/SelectDepartments/SelectDepartments';
import { DepartmentViewModel } from '../../../../entities/Departments/Departments';
import { isObjEmpty, Option } from '../../../../helpers';
import { getBase64 } from '../../../../helpers/file/getBase64';
import getFileNameExtension from '../../../../helpers/file/getFileNameExtension';
import { validateDocumentFileType } from '../../../../helpers/file/validateDocumentFileType';
import { translateDocumentTypes } from '../../../../helpers/translations/translateDocumentTypes';
import { translateRoles } from '../../../../helpers/translations/translateRoles';
import { translate } from '../../../../helpers/translations/translator';
import {
    DocumentFormData,
    DocumentTypeViewModel,
    DocumentViewModel,
    Role,
} from '../../../../models';
import { BasicSelectOption } from '../../../Schedule/components/CalendarToolbar/BasicSelect/BasicSelect';
import generateDocumentFormEmptyValues from './helpers/generateDocumentFormEmptyValues';
import transformDocumentToFormData from './helpers/transformDocumentToFormData';
import { transformRoleIdToBasicSelectOption } from './helpers/transformRoleIdToBasicSelectOption';
import validateFormData from './helpers/validateFormData';

import './AddOrEditSharedDocument.scss';

interface AddOrEditSharedDocumentProps {
    document: DocumentViewModel | null;
    roles: Role[];
    departments: DepartmentViewModel[];
    documentTypes: DocumentTypeViewModel[];
    onSubmit: (formData: DocumentFormData) => void;
    onCancel: () => void;
}

const AddOrEditSharedDocument: FC<AddOrEditSharedDocumentProps> = ({
    document,
    documentTypes,
    roles,
    departments,
    onSubmit,
    onCancel,
}) => {
    const [values, setValues] = useState<DocumentFormData>(generateDocumentFormEmptyValues());

    const [submitted, setSubmitted] = useState(false);
    const [fileSizeTooLarge, setFileSizeTooLarge] = useState(false);
    const [fileTypeNotAllowed, setFileTypeNotAllowed] = useState(false);
    const validationErrors = validateFormData(values, submitted);

    useEffect(() => {
        if (document) {
            setValues(transformDocumentToFormData(document));
        }
    }, [document]);

    const documentTypeOptions : BasicSelectOption[] = useMemo(() => translateDocumentTypes(documentTypes), [documentTypes]);
    const roleOptions: BasicSelectOption[] = useMemo(() => translateRoles(roles), [roles]);
    const currentRoles: BasicSelectOption[] = useMemo(
        () => transformRoleIdToBasicSelectOption(
            roles,
            values.roles,
        ),
        [values.roles],
    );

    const onDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            name: event.target.value,
        });
    };

    const onDocumentTypeChange = (value: any) => {
        setValues({
            ...values,
            documentType: value.value,
        });
    };

    const onRolesChange = (value: any) => {
        const selectedRoles = ((value || []) as Option[])
            .map(role => String(role.value));

        setValues({
            ...values,
            roles: selectedRoles,
        });
    };

    const onDepartmentsChange = (selectedDepartments: DepartmentViewModel[]) => {
        setValues({
            ...values,
            departments: selectedDepartments,
        });
    };

    const onNotifyEmployeesCheckboxChange = () => {
        setValues({
            ...values,
            willNotifyUsersAfterSuccess: !values.willNotifyUsersAfterSuccess,
        });
    };

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentFile = event.target.files && event.target.files[0];
        if (!currentFile) {
            return;
        }

        if (!validateDocumentFileType(currentFile.type)) {
            setFileTypeNotAllowed(true);
            return;
        }

        if (currentFile.size > 5000000) {
            setFileSizeTooLarge(true);
            return;
        }

        setFileTypeNotAllowed(false);
        setFileSizeTooLarge(false);

        const getBase64Promise = getBase64(currentFile);

        getBase64Promise.then((base64String: any) => setValues({
            ...values,
            base64: base64String.split(',')[1],
            file: currentFile,
            ext: getFileNameExtension(currentFile.name) || undefined,
        }));
    };
    const onFormSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitted(true);
        if (isObjEmpty(validateFormData(values, true))) {
            onSubmit(values);
        }
    };

    const onFormCancel = () => {
        onCancel();
    };

    return (
        <form onSubmit={onFormSubmit} className="form-add-document">
            <ModalHeader>{document ? translate('pages.pz.editDocument') : translate('pages.pz.addDocument')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.management.fileName')}</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                autoComplete="off"
                                placeholder={translate('pages.pz.enterAName')}
                                value={values.name}
                                onChange={onDisplayNameChange}
                                invalid={!!validationErrors.name}
                            />
                            <FormFeedback>
                                { validationErrors.name }
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col>
                        {!document && (
                            <FormGroup>
                                <div className="form-add-document-button-file">
                                    <span>{translate('pages.management.file')}</span>
                                    <Label className="btn btn-orange" for="file">
                                        {translate('pages.management.chooseFile')}
                                    </Label>
                                    <Input
                                        className="form-add-document-input-file"
                                        type="file"
                                        name="file"
                                        id="file"
                                        placeholder={translate('pages.pz.chooseDocumentType')}
                                        onChange={onFileChange}
                                        invalid={!!validationErrors.file}
                                    />
                                    <FormFeedback>
                                        { validationErrors.file }
                                    </FormFeedback>
                                </div>
                                {fileTypeNotAllowed
                                && (
                                    <FormText className="form-add-document-text-red">
                                        {translate('pages.management.fileTypeNotAllowed')}
                                    </FormText>
                                )}
                                {fileSizeTooLarge
                                && (
                                    <FormText className="form-add-document-text-red">
                                        {translate('pages.management.fileSizeTooLarge')}
                                    </FormText>
                                )}
                                {(!fileTypeNotAllowed && !fileSizeTooLarge && !values.file)
                                && (
                                    <FormText className="form-add-document-text-grey">
                                        {translate('pages.management.fileDescription')}
                                    </FormText>
                                )}
                                {(!fileTypeNotAllowed && !fileSizeTooLarge && values.file)
                                && (
                                    <FormText className="form-add-document-text-black">
                                        {values.file.name}
                                    </FormText>
                                )}
                            </FormGroup>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.management.fileType')}</Label>
                            <Select
                                id="select-document-type"
                                options={documentTypeOptions}
                                placeholder={translate('pages.pz.chooseDocumentType')}
                                className={`basic-multi-select ${validationErrors.documentType ? 'basic-multi-select--is-invalid' : ''}`}
                                classNamePrefix="select"
                                value={documentTypeOptions.find(
                                    typeOption => typeOption.value === (values.documentType),
                                )}
                                onChange={onDocumentTypeChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col />
                </Row>
                <Row>
                    <Col>
                        { validationErrors.documentType
                    && (
                        <FormFeedback className="add-or-edit-shared-document__error-text">
                            {validationErrors.documentType}
                        </FormFeedback>
                    ) }
                    </Col>
                </Row>
            </ModalBody>
            <hr />
            <ModalBody className="add-or-edit-shared-document__body">
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.pz.makeVisibleFor')}</Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.roles')}</Label>
                            <Select
                                isMulti
                                id="select-document-userType"
                                options={roleOptions}
                                placeholder={translate('pages.pz.chooseUserType')}
                                className={`basic-multi-select ${validationErrors.departmentsOrRoles ? 'basic-multi-select--is-invalid' : ''}`}
                                classNamePrefix="select"
                                value={currentRoles}
                                onChange={onRolesChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <SelectDepartments
                        isMulti
                        required={false}
                        currentDepartments={values.departments || []}
                        allDepartments={departments}
                        mainDepartment={null}
                        title={translate('pages.pz.chooseDepartment(s)')}
                        onChange={onDepartmentsChange}
                        invalid={!!validationErrors.departmentsOrRoles}
                    />
                </Row>
                <Row>
                    <Col>
                        { validationErrors.departmentsOrRoles
                        && (
                            <FormFeedback className="add-or-edit-shared-document__error-text">
                                {validationErrors.departmentsOrRoles}
                            </FormFeedback>
                        ) }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <CustomInput
                                    checked={values.willNotifyUsersAfterSuccess}
                                    label={translate('pages.shifts.notifyEmployees')}
                                    type="checkbox"
                                    id="notify-employees-checkbox"
                                    onChange={onNotifyEmployeesCheckboxChange}
                                />
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="link"
                    id="modal-close"
                    onClick={onFormCancel}
                >
                    {translate('common.cancel')}
                </Button>
                <Button type="submit" color="orange" disabled={false}>{document ? translate('common.edit') : translate('common.add')}</Button>
            </ModalFooter>
        </form>
    );
};

export default AddOrEditSharedDocument;
