import React, { FC } from 'react';

import { translate } from '../../../../helpers/translations/translator';
import BasicSelect, { BasicSelectOption } from '../../../Schedule/components/CalendarToolbar/BasicSelect/BasicSelect';

import './PeriodYearSelect.scss';

interface Props {
    options: BasicSelectOption[];
    selected: number;
    onSelectChange?: (value: number) => void;
}

export const PeriodYearSelect:FC<Props> = ({
    options,
    selected,
    ...props
}) => {
    const onSelectChange = (value: string) => {
        if (props.onSelectChange) {
            props.onSelectChange(parseInt(value, 10));
        }
    };

    return (
        <div className="period-year-select__select">
            <div className="period-year-select__title">
                {translate('common.year')}
            </div>
            <BasicSelect
                value={selected}
                options={options}
                onChange={onSelectChange}
                className="period-year-select__dropdown"
            />
        </div>
    );
};
