import React, { FC, useMemo } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { translateDocumentTypes } from '../../../../helpers/translations/translateDocumentTypes';
import { translate } from '../../../../helpers/translations/translator';
import { DocumentTypeViewModel } from '../../../../models';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';


export interface FilterDocumentTypesProps {
    onChange: (filter: AppFilter) => void;
    filter: AppFilter;
    documentTypes: DocumentTypeViewModel[];
}

const FilterDocumentTypes: FC<FilterDocumentTypesProps> = ({
    onChange,
    filter,
    documentTypes,
}) => {
    const documentTypeOptions = useMemo(() => translateDocumentTypes(documentTypes), [documentTypes]);

    const getChecked = (id: string): boolean => !!filter.pz.documentTypes.find((a: string) => a === id);

    const setFilter = (id: string, checked: boolean) => {
        const values = !checked ? (
            filter.pz.documentTypes.filter((role) => role !== id)
        ) : [
            ...filter.pz.documentTypes,
            id,
        ];

        const pz = {
            documentTypes: values,
        };

        onChange({
            ...filter,
            pz,
        });
    };

    return (
        <FilterBlock title={translate('common.category')}>
            {documentTypeOptions.map(document => (
                <FormGroup check className="p-0" key={document.value}>
                    <Label check>
                        <CustomInput
                            id={`${document}-checkbox-${document.value}`}
                            type="checkbox"
                            label={document.label}
                            checked={getChecked(document.value)}
                            onChange={() => setFilter(document.value, !getChecked(document.value))}
                        />
                    </Label>
                </FormGroup>
            ))}
        </FilterBlock>
    );
};


export default FilterDocumentTypes;
