import {
    addDays,
    compareTimeStrings,
    formatDate,
    getEndOfWorkDay,
    getStartOfWorkDay,
} from '../../../../helpers/date';
import legacyParse from '../../../../helpers/legacyDateParser';

interface Props {
    justifiedStartDate: string;
    justifiedEndDate: string;
}

const dateFormat = 'yyyy-MM-dd';
const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

export const generateJustifiedStartAndEndDate = (
    date: string,
    startTime: string,
    endTime: string,
    startDate: string,
    endDate: string,
    multipleDays: boolean,
): Props => {
    if (multipleDays) {
        return {
            justifiedStartDate: formatDate(getStartOfWorkDay(startDate), dateTimeFormat),
            justifiedEndDate: formatDate(getEndOfWorkDay(endDate), dateTimeFormat),
        };
    }

    const justifiedEndDate = compareTimeStrings(startTime, endTime)
        ? addDays(legacyParse(date), 1) : date;

    return {
        justifiedStartDate: `${formatDate(date, dateFormat)} ${startTime}:00`,
        justifiedEndDate: `${formatDate(justifiedEndDate, dateFormat)} ${endTime}:00`,
    };
};
