import { FC } from 'react';

import { CalendarControls, IconButton } from '../../compositions';
import trans from '../../helpers/trans';
import { TimeModeType } from '../../types';

import './LeaveOfAbsencesOverviewTopBar.scss';

interface LeaveOfAbsencesOverviewTopBarProps {
    isLoading: boolean;
    selectedDays: Date[];
    onDateChange: (date: Date) => void;
    onIncrementClick: (goForward: boolean) => void;
    onExportClick: () => void;
    className?: string;
}

const LeaveOfAbsencesOverviewTopBar: FC<LeaveOfAbsencesOverviewTopBarProps> = ({
    isLoading,
    selectedDays,
    onDateChange,
    onIncrementClick,
    onExportClick,
    className = '',
}) => {
    const handleTodayButtonClick = (): void => {
        onDateChange(new Date());
    };

    return (
        <div className={`leave-of-absences-overview-top-bar ${className}`}>
            <CalendarControls
                inputType={TimeModeType.week}
                selectedDays={selectedDays}
                timeMode={TimeModeType.week}
                onDateChange={onDateChange}
                onIncrementClick={onIncrementClick}
                onTodayButtonClick={handleTodayButtonClick}
                className="leave-of-absences-overview-top-bar__calendar-controls"
            />
            <IconButton
                icon="download"
                disabled={!selectedDays || isLoading}
                text={trans('common.export')}
                onClick={onExportClick}
                className="leave-of-absences-overview-top-bar__export-button"
                iconClassName="leave-of-absences-overview-top-bar__export-button-icon"
            />
        </div>
    );
};

export default LeaveOfAbsencesOverviewTopBar;
