import { FC, ReactElement } from 'react';

import { TableCell, TableCellSkeleton, TableRow } from '../../../../../@paco/components';
import { SkeletonTable } from '../../../../../@paco/compositions';
import { translate } from '../../../../../helpers/translations/translator';

import './GroupsDepartmentsTableSkeleton.scss';

interface GroupsDepartmentsTableSkeletonProps {
    className?: string;
}

const GroupsDepartmentsTableSkeleton: FC<GroupsDepartmentsTableSkeletonProps> = ({
    className = '',
}): ReactElement => {
    const tableHeader = [
        translate('common.department'),
        translate('common.group'),
    ];

    return (
        <SkeletonTable
            tableHeaders={tableHeader}
            className={`groups-departments-table-skeleton ${className}`}
        >
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="medium" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="small" />
                </TableCell>
                <TableCell className="groups-departments-table-skeleton__buttons-cell">
                    <TableCellSkeleton size="medium" />
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="small" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="large" />
                </TableCell>
                <TableCell className="groups-departments-table-skeleton__buttons-cell">
                    <TableCellSkeleton size="medium" />
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="large" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="small" />
                </TableCell>
                <TableCell className="groups-departments-table-skeleton__buttons-cell">
                    <TableCellSkeleton size="medium" />
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="small" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="medium" />
                </TableCell>
                <TableCell className="groups-departments-table-skeleton__buttons-cell">
                    <TableCellSkeleton size="medium" />
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="medium" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="small" />
                </TableCell>
                <TableCell className="groups-departments-table-skeleton__buttons-cell">
                    <TableCellSkeleton size="medium" />
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="small" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="large" />
                </TableCell>
                <TableCell className="groups-departments-table-skeleton__buttons-cell">
                    <TableCellSkeleton size="medium" />
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="large" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="small" />
                </TableCell>
                <TableCell className="groups-departments-table-skeleton__buttons-cell">
                    <TableCellSkeleton size="medium" />
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="small" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="medium" />
                </TableCell>
                <TableCell className="groups-departments-table-skeleton__buttons-cell">
                    <TableCellSkeleton size="medium" />
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
        </SkeletonTable>
    );
};

export default GroupsDepartmentsTableSkeleton;
