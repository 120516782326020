import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import { getCheckedFormOptionsValue } from '../../@paco/helpers/types/formOption';
import { getFeedbackList, getFeedbackScores } from '../../helpers/api/feedbackApi';
import {
    FEEDBACK_FAILURE,
    FEEDBACK_REQUEST,
    FEEDBACK_SAGA_GET_DATA,
    FEEDBACK_SUCCESS,
} from '../actionTypes';

function* getFeedback(action) {
    yield put({ type: FEEDBACK_REQUEST });

    try {
        const state = yield select();
        const { departmentOptions } = state.globalFiltersReducer;
        const { employeeSearch } = state.filterReducer.filter;
        const departmentIds = getCheckedFormOptionsValue(departmentOptions);

        const yearMonth = `${action.date.getUTCFullYear()}-${action.date.getMonth() + 1}`;
        const feedback = yield call(() => getFeedbackList(departmentIds, employeeSearch, action.date));
        const scores = yield call(() => getFeedbackScores(departmentIds, employeeSearch, yearMonth));

        yield put({
            type: FEEDBACK_SUCCESS,
            feedbackList: feedback.data,
            scores: scores.data,
            loading: false,
        });
    } catch (error) {
        yield put({ type: FEEDBACK_FAILURE, error });
    }
}

export default function* usersWatcher() {
    yield takeLatest(FEEDBACK_SAGA_GET_DATA, getFeedback);
}
