import React, { FC, useMemo } from 'react';

import Tooltip from 'react-tooltip-lite';

import { transformToPeriod } from '../../../../@paco/entities/Period/PeriodTransformers';
import { getPreferToWorkTimeSlotComment } from '../../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlotHelpers';
import { getUserFullName } from '../../../../helpers';
import { ShiftPlanningType, UserViewModel } from '../../../../models';
import AvailabilityButton from '../../compositions/AvailabilityButton/AvailabilityButton';
import { ResourceTypes } from '../ConceptsListViewTable/ConceptsListViewTable';
import { getResourceDescription, getStyleClasses } from '../ConceptsListViewTable/subcomponents/UserConceptAvailabilityOnDate/helpers/transformResources';

import './ConceptAvailability.scss';

interface ConceptAvailabilityProps {
    showName?: boolean;
    showDescription?: boolean;
    resource: ResourceTypes;
    onClick: (resource: ResourceTypes) => void;
}

const ConceptAvailability: FC<ConceptAvailabilityProps> = ({
    showName = false,
    showDescription = false,
    resource,
    onClick,
}) => {
    const { id } = resource;
    const user = resource.user as unknown as UserViewModel;
    const name = useMemo(() => getUserFullName(user), [user]);
    const period = useMemo(() => transformToPeriod(new Date(resource.start), new Date(resource.end || resource.start), !!resource.end), [resource]);

    const status = resource.type === 'leaveOfAbsences' ? resource.status : '';
    const shiftPlanning = resource.type === ShiftPlanningType.shift ? resource : undefined;
    const shiftConceptPlanning = resource.type === 'shiftConceptPlannings' ? resource : undefined;
    const type = useMemo(() => getResourceDescription(resource.type, status, shiftPlanning, shiftConceptPlanning), [resource]);

    const modifier = useMemo(() => getStyleClasses(resource.type, status), [resource]);
    const color = resource.type === 'shiftConceptPlannings' || resource.type === 'shiftPlannings' ? resource.color : '';

    const onButtonClick = () => {
        onClick(resource);
    };

    if (resource.type === 'PreferToWorkTimeSlots') {
        return (
            // @ts-ignore
            <Tooltip
                arrowSize={6}
                content={getPreferToWorkTimeSlotComment(resource)}
                padding={7}
                className="concept-calendar-availability__tooltip-container"
            >
                <AvailabilityButton
                    showName={showName}
                    showDescription={showDescription}
                    color={color}
                    type={type}
                    id={id}
                    name={name}
                    modifier={modifier}
                    period={period}
                    onButtonClick={onButtonClick}
                />
            </Tooltip>
        );
    }

    return (
        <AvailabilityButton
            showName={showName}
            showDescription={showDescription}
            color={color}
            type={type}
            id={id}
            name={name}
            modifier={modifier}
            period={period}
            onButtonClick={onButtonClick}
        />
    );
};

export default ConceptAvailability;
