import { areIntervalsOverlapping } from '../../helpers/date';
import trans from '../../helpers/trans';
import { Period } from '../Period/Period';
import { getDateLabelShort } from '../Period/PeriodHelpers';
import { ShiftsCalendarAbsence } from '../ShiftsCalendarAbsence/ShiftsCalendarAbsence';
import { Absence } from './Absence';

const doesAbsenceOverlapWithPeriod = (
    absence: Absence | ShiftsCalendarAbsence,
    period: Period,
): boolean => areIntervalsOverlapping(
    {
        start: period.start,
        end: period.end,
    },
    {
        start: absence.period.start,
        end: absence.period.end,
    },
);

const getAbsenceTooltipText = (absence: Absence | ShiftsCalendarAbsence): string => {
    const date = getDateLabelShort(absence.period.start, absence.period.end, absence.period.hasEnd).toLowerCase();
    return trans('entities.absences.calledInSickOn', { date });
};

export {
    doesAbsenceOverlapWithPeriod,
    getAbsenceTooltipText,
};
