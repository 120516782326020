import { FC } from 'react';

import classNames from 'classnames';

import './TableCellSkeleton.scss';

interface TableCellSkeletonProps {
    size?: 'small' | 'medium' | 'large';
    className?: string;
}

const TableCellSkeleton: FC<TableCellSkeletonProps> = ({ size = 'medium', className }) => {
    const skeletonClassNames = classNames('table-cell-paco-skeleton', {
        [`table-cell-paco-skeleton--${size}`]: size,
    }, className);

    return (
        <div className={skeletonClassNames} />
    );
};

export default TableCellSkeleton;
