import { GetAbsenceCallApiCallProps, getAbsencesApiCall } from '../../entities/Absence/AbsenceService';
import { isFetchCollectionResultSuccessful, isFetchResultSuccessful } from '../../entities/FetchResult';
import { getLeaveOfAbsencesInPeriodApiCall } from '../../entities/LeaveOfAbsence/LeaveOfAbsenceService';
import { Period } from '../../entities/Period/Period';
import { getPreferToWorkTimeSlotsApiCall } from '../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlotService';
import { getShiftsIndexApiCall } from '../../entities/Shift/ShiftService';
import { getUnavailableToWorkTimeSlotsApiCall } from '../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotService';
import { getWorkweekUsersApiCall, GetWorkweekUsersApiCallProps } from '../../entities/User/UserService';
import { TypedDispatch } from '../store';
import {
    setAbsences,
    setError,
    setIsLoading,
    setIsLoadingUnavailableUsers,
    setLeaveOfAbsences,
    setPreferToWorkTimeSlots,
    setUnavailableToWorkTimeSlots,
    setUnavailableUsers,
    setWorkweekUsers,
} from './workweekUsersReducer';

export const getWorkweekUsers = (filter: GetWorkweekUsersApiCallProps) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getWorkweekUsersApiCall(filter);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setWorkweekUsers(response.data));
    } catch (error) {
        console.error('[getWorkweekUsers]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const getWorkweekUsersAbsences = (filter: GetAbsenceCallApiCallProps) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setError(''));

    try {
        const response = await getAbsencesApiCall(filter);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setAbsences(response.data));
    } catch (error) {
        console.error('[getWorkweekUsersAbsences]', error);
    }
};

export const getWorkweekUsersLeaveOfAbsences = (period: Period) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setError(''));

    try {
        const response = await getLeaveOfAbsencesInPeriodApiCall(period);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLeaveOfAbsences(response.data));
    } catch (error) {
        console.error('[getWorkweekUsersLeaveOfAbsences]', error);
    }
};

export const getWorkweekUsersUnavailableToWorkTimeSlots = (period: Period) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setError(''));

    try {
        const response = await getUnavailableToWorkTimeSlotsApiCall(period);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setUnavailableToWorkTimeSlots(response.data));
    } catch (error) {
        console.error('[getWorkweekUsersUnavailableToWorkTimeSlots]', error);
    }
};

export const getWorkweekUsersPreferToWorkTimeSlots = (period: Period) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setError(''));

    try {
        const response = await getPreferToWorkTimeSlotsApiCall(period);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setPreferToWorkTimeSlots(response.data));
    } catch (error) {
        console.error('[getWorkweekUsersPreferToWorkTimeSlots]', error);
    }
};

export const getUnavailableUsersByPeriod = (period: Period) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoadingUnavailableUsers(true));
    dispatch(setError(''));

    try {
        const response = await getShiftsIndexApiCall(period.start, period.end, [], '');

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const unavailableUserIds = response.data
            .flatMap(shift => shift.shiftPlannings)
            .filter(shiftPlanning => shiftPlanning.planned)
            .map(shiftPlanning => shiftPlanning.user.id);

        dispatch(setUnavailableUsers(unavailableUserIds));
    } catch (error) {
        console.error('[getUnavailableUsersByPeriod]', error);
    } finally {
        dispatch(setIsLoadingUnavailableUsers(false));
    }
};

export const clearUnavailableUsers = () => (dispatch: TypedDispatch): void => {
    dispatch(setUnavailableUsers([]));
};
