import React, { FC } from 'react';

import { TrackViewModel } from '../../../../models';
import SatisfactionTable from './subcomponents/SatisfactionTable/SatisfactionTable';


export interface SatisfactionProps {
    tracks: TrackViewModel[];
}

const Satisfaction: FC<SatisfactionProps> = ({
    tracks,
}) => (
    <div className="satisfaction">
        <SatisfactionTable
            tracks={tracks}
        />
    </div>
);

export default Satisfaction;
