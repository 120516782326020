/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconChevronRight: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 14 14">
        <path d="M7.68,6.12l-4.5-4.86L4.34,0l6.48,7l-6.48,7l-1.16-1.26L8.48,7L7.68,6.12z" />
    </svg>
);

export default IconChevronRight;
/* eslint-enable max-len */
