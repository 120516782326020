import React, { FC, useMemo } from 'react';

import CommentsModal, { Comment } from '../../../../components/CommentsModal/CommentsModal';
import { translate } from '../../../../helpers/translations/translator';
import { Absence, LeaveOfAbsence } from '../../../../models';
import getCommentsFromAbsence from './helpers/getCommentsFromAbsence';
import getReviewedByUserNameFromAbsence from './helpers/getReviewedByUserNameFromAbsence';

interface AbsenceCommentsModalProps {
    isOpen: boolean;
    onClose: () => void;
    absence: Absence | LeaveOfAbsence;
}

const AbsenceCommentsModal: FC<AbsenceCommentsModalProps> = ({ absence, onClose, isOpen }) => {
    const reviewedByUser = useMemo(() => (absence
        ? getReviewedByUserNameFromAbsence(absence) : undefined
    ), [absence]);

    const comments: Comment[] = useMemo(() => (absence
        ? getCommentsFromAbsence(absence) : []
    ), [absence]);

    return (
        <CommentsModal
            isOpen={isOpen}
            onClose={onClose}
            reviewedByUser={reviewedByUser}
            title={translate('common.notes')}
            comments={comments}
        />
    );
};

export default AbsenceCommentsModal;
