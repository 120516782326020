import { FC, useEffect } from 'react';

import classnames from 'classnames';
import { useToggle } from 'react-use';

import { Icon } from '../../../../components';
import { ChangeLogEntry } from '../../../../entities/ChangeLog/ChangeLog';
import trans from '../../../../helpers/trans';
import { IconButton } from '../../..';

import './ChangeLogWidgetItem.scss';

interface ChangeLogItemProps {
    isOpen: boolean;
    changeLogEntry: ChangeLogEntry;
    onButtonClick: (id: string) => void;
    className?: string;
}

const ChangeLogItem: FC<ChangeLogItemProps> = ({
    isOpen,
    changeLogEntry,
    onButtonClick,
    className = '',
}) => {
    const [isExtended, toggleIsExtended] = useToggle(false);

    const changes = isExtended ? changeLogEntry.changes : changeLogEntry.changes.slice(0, 3);

    const itemClassName = classnames('change-log-item', {
        'change-log-item--is-open': isOpen,
    }, className);

    const handleButtonClick = () => {
        onButtonClick(changeLogEntry.id);
    };

    useEffect(() => {
        toggleIsExtended(false);
    }, [isOpen]);

    return (
        <li
            className={itemClassName}
        >
            <button
                type="button"
                className="change-log-item__toggle-button"
                onClick={handleButtonClick}
            >
                {changeLogEntry.date}
                <Icon
                    name={isOpen ? 'chevron-up' : 'chevron-down'}
                    className="change-log-item__toggle-button-icon"
                />
            </button>
            {changeLogEntry.intro && <div className="change-log-item__intro">{changeLogEntry.intro}</div>}
            <ul className="change-log-item__list">
                {changes.map(change => (
                    <li
                        key={change}
                        className="change-log-item__list-item"
                    >
                        {change}
                    </li>
                ))}
            </ul>
            {(changeLogEntry.changes.length > 3 && !isExtended) && (
                <IconButton
                    icon="chevron-right"
                    iconPos="right"
                    text={trans('compositions.dashboardChangeLogWidget.extend')}
                    onClick={toggleIsExtended}
                    className="change-log-item__extend-button"
                />
            )}
        </li>
    );
};

export default ChangeLogItem;
