import { Dispatch } from 'redux';

import { PzLeaveType } from '../../../@paco/types/leaveType';
import { TOAST_TYPE_PASS } from '../../../constants';
import { patchLeaveOfAbsence, postLeaveOfAbsenceAccept, postLeaveOfAbsenceDecline } from '../../../helpers/api/absencesApi';
import { postComment } from '../../../helpers/api/commentsApi';
import { translate } from '../../../helpers/translations/translator';
import {
    LeaveOfAbsence,
    LeaveOfAbsenceEditType,
    LeaveOfAbsenceFormData,
    LeaveType,
    PayrollPeriodWithHours,
    WeekWithHours,
} from '../../../models';
import { updateLeaveOfAbsenceHours } from '../../absences-ts/absencesHelpers';
import { setAppToast } from '../../app/appActions';
import { setError, setIsLoading, setIsSuccessful } from './leaveOfAbsenceReducer';

export const editLeaveOfAbsence = (
    editType: LeaveOfAbsenceEditType,
    data: LeaveOfAbsenceFormData,
    payrollPeriodsWithHours: PayrollPeriodWithHours[],
    weeksWithHours: WeekWithHours[],
    leaveOfAbsence: LeaveOfAbsence,
    leaveType?: LeaveType | PzLeaveType,
    declineComment?: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsSuccessful(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    let toast = translate('pages.absences.editLOASuccess');

    try {
        if (editType === 'accept') {
            toast = translate('pages.absences.acceptLOARequest');
            await Promise.all([
                patchLeaveOfAbsence(leaveOfAbsence.id, data),
                postLeaveOfAbsenceAccept(leaveOfAbsence.id),
            ]);
        } else if (editType === 'deny') {
            toast = translate('pages.absences.refuseLOARequest');
            await postComment(leaveOfAbsence.id, declineComment, 'leaveOfAbsence', 'leaveOfAbsences');
            await postLeaveOfAbsenceDecline(leaveOfAbsence.id);
        } else {
            await patchLeaveOfAbsence(leaveOfAbsence.id, data);
        }

        await updateLeaveOfAbsenceHours(
            leaveOfAbsence.id,
            leaveOfAbsence.leaveOfAbsenceHours || [],
            weeksWithHours,
            leaveType || LeaveType.normal,
        );

        dispatch(setAppToast(toast, TOAST_TYPE_PASS));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editLeaveOfAbsence]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
