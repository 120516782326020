import { translate } from '../../../../../helpers/translations/translator';
import { DocumentFormData } from '../../../../../models';


export interface ValidationErrors {
    file?: string;
    documentType?: string;
    name?: string;
    departmentsOrRoles?: string;
}


export default function validateFormData(
    formData: DocumentFormData,
    submitted: boolean,
): ValidationErrors {
    const errors: ValidationErrors = {};

    if (!submitted) {
        return {};
    }

    if (!formData.file && !formData.downloadUrl) {
        errors.file = translate('pages.pz.isRequired', {
            item: translate('pages.management.file'),
        });
    }

    if (!formData.documentType) {
        errors.documentType = translate('pages.pz.isRequired', {
            item: translate('pages.pz.documentType'),
        });
    }

    if (!formData.name) {
        errors.name = translate('pages.pz.isRequired', {
            item: translate('common.fileName'),
        });
    }

    if (!formData.departments?.length && !formData.roles?.length) {
        errors.departmentsOrRoles = translate('pages.pz.isRequired', {
            item: translate('pages.pz.departmentOrRole'),
        });
    }

    return errors;
}
