import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../../redux/@config/AsyncReduxState';
import { AbsencesSlice } from '../../../../redux/@toolkit/absences/absencesReducer';
import { IncompleteLeaveOfAbsencesSlice } from '../../../../redux/@toolkit/leaveOfAbsences/incompleteLeaveOfAbsences/incompleteLeaveOfAbsencesReducer';
import {
    AbsenceNotifications,
    ExchangeNotifications,
    FeedbackNotifications,
    IncompleteUsersNotifications,
    LeaveOfAbsenceNotifications,
    Notifications,
    PayrollPeriodNotifications,
    ShiftNotifications,
    TrackNotifications,
} from '../../../entities/Notifications/Notifications';
import { Reducers } from '../../reducers';
import { UsersWithDivergentEmploymentHoursSlice } from '../../usersWithDivergentEmploymentHours/usersWithDivergentEmploymentHoursReducer';

export type NotificationsState = AsyncReduxState<{
    isAbsencesInitialized: boolean;
    isOpenAbsenceHoursInitialized: boolean;
    isExchangesInitialized: boolean;
    isLeaveOfAbsencesInitialized: boolean;
    isOpenLeaveOfAbsenceHoursInitialized: boolean;
    isPayrollPeriodsInitialized: boolean;
    isFeedbackInitialized: boolean;
    isTracksInitialized: boolean;
    isShiftsInitialized: boolean;
    isIncompleteUsersInitialized: boolean;
    notifications: Notifications;
}>;

const initialState: NotificationsState = {
    ...initialAsyncReduxState,
    isAbsencesInitialized: false,
    isOpenAbsenceHoursInitialized: false,
    isExchangesInitialized: false,
    isLeaveOfAbsencesInitialized: false,
    isOpenLeaveOfAbsenceHoursInitialized: false,
    isPayrollPeriodsInitialized: false,
    isFeedbackInitialized: false,
    isTracksInitialized: false,
    isShiftsInitialized: false,
    isIncompleteUsersInitialized: false,
    notifications: {
        openAbsences: 0,
        absencesWithConflicts: 0,
        currentPayrollPeriodNumber: 1,
        openExchanges: 0,
        openLeaveOfAbsences: 0,
        openLeaveOfAbsencesInPeriod: 0,
        openAbsenceHours: 0,
        openLeaveOfAbsenceHours: 0,
        openShiftsForFourWeeks: 0,
        openShiftsForOneWeek: 0,
        satisfiedRating: 0,
        runningTracks: 0,
        runningTracksInPeriod: 0,
        openTracks: 0,
        openTracksInPeriod: 0,
        incompleteUsers: 0,
        registeredShiftPlanningsForFourWeeks: 0,
    },
};

export const notificationsSlice = createSlice({
    name: 'notificationsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): NotificationsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): NotificationsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        clearNotifications(): NotificationsState {
            return initialState;
        },
        setNotifications(state, action: PayloadAction<Notifications>): NotificationsState {
            return {
                ...state,
                notifications: action.payload,
            };
        },
        setAbsenceNotifications(state, action: PayloadAction<AbsenceNotifications>): NotificationsState {
            return {
                ...state,
                isAbsencesInitialized: true,
                notifications: { ...state.notifications, ...action.payload },
            };
        },
        setExchangeNotifications(state, action: PayloadAction<ExchangeNotifications>): NotificationsState {
            return {
                ...state,
                isExchangesInitialized: true,
                notifications: { ...state.notifications, ...action.payload },
            };
        },
        setLeaveOfAbsenceNotifications(state, action: PayloadAction<LeaveOfAbsenceNotifications>): NotificationsState {
            return {
                ...state,
                isLeaveOfAbsencesInitialized: true,
                notifications: { ...state.notifications, ...action.payload },
            };
        },
        setOpenLeaveOfAbsenceHours(state): NotificationsState {
            return {
                ...state,
                isOpenLeaveOfAbsenceHoursInitialized: true,
            };
        },
        setOpenAbsenceHours(state): NotificationsState {
            return {
                ...state,
                isOpenAbsenceHoursInitialized: true,
            };
        },
        setPayrollPeriodNotifications(state, action: PayloadAction<PayrollPeriodNotifications>): NotificationsState {
            return {
                ...state,
                isPayrollPeriodsInitialized: true,
                notifications: { ...state.notifications, ...action.payload },
            };
        },
        setFeedbackNotifications(state, action: PayloadAction<FeedbackNotifications>): NotificationsState {
            return {
                ...state,
                isFeedbackInitialized: true,
                notifications: { ...state.notifications, ...action.payload },
            };
        },
        setTrackNotifications(state, action: PayloadAction<TrackNotifications>): NotificationsState {
            return {
                ...state,
                isTracksInitialized: true,
                notifications: { ...state.notifications, ...action.payload },
            };
        },
        setShiftNotifications(state, action: PayloadAction<ShiftNotifications>): NotificationsState {
            return {
                ...state,
                isShiftsInitialized: true,
                notifications: { ...state.notifications, ...action.payload },
            };
        },
        setIncompleteUsersNotifications(state, action: PayloadAction<IncompleteUsersNotifications>): NotificationsState {
            return {
                ...state,
                isIncompleteUsersInitialized: true,
                notifications: { ...state.notifications, ...action.payload },
            };
        },
        setIncompleteUsers(state): NotificationsState {
            return {
                ...state,
                isIncompleteUsersInitialized: true,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(AbsencesSlice.actions.setAbsences, (state, action): NotificationsState => {
            const absencesCount = action.payload.length;

            return {
                ...state,
                isOpenAbsenceHoursInitialized: true,
                notifications: { ...state.notifications, openAbsenceHours: absencesCount },
            };
        });
        builder.addCase(IncompleteLeaveOfAbsencesSlice.actions.setIncompleteLeaveOfAbsences, (state, action): NotificationsState => {
            const leaveOfAbsencesCount = action.payload.length;

            return {
                ...state,
                isOpenLeaveOfAbsenceHoursInitialized: true,
                notifications: { ...state.notifications, openLeaveOfAbsenceHours: leaveOfAbsencesCount },
            };
        });

        builder.addCase(UsersWithDivergentEmploymentHoursSlice.actions.setUsersWithIncompleteDivergentEmploymentHours, (state, action): NotificationsState => {
            const incompleteUsersCount = action.payload.length;

            return {
                ...state,
                isIncompleteUsersInitialized: true,
                notifications: { ...state.notifications, incompleteUsers: incompleteUsersCount },
            };
        });
    },
});

export const getNotificationsInitializedState = (state: Reducers) => state.notificationsReducer.isAbsencesInitialized
    && state.notificationsReducer.isExchangesInitialized
    && state.notificationsReducer.isFeedbackInitialized
    && state.notificationsReducer.isLeaveOfAbsencesInitialized
    && state.notificationsReducer.isPayrollPeriodsInitialized
    && state.notificationsReducer.isShiftsInitialized
    && state.notificationsReducer.isTracksInitialized;

export const {
    setIsLoading,
    setError,
    clearNotifications,
    setAbsenceNotifications,
    setExchangeNotifications,
    setIncompleteUsers,
    setIncompleteUsersNotifications,
    setLeaveOfAbsenceNotifications,
    setPayrollPeriodNotifications,
    setFeedbackNotifications,
    setTrackNotifications,
    setShiftNotifications,
    setOpenLeaveOfAbsenceHours,
    setOpenAbsenceHours,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
