import { getUniqueArrayList } from '../../helpers/array';
import { PublishBatchResult, PublishBatchResultType } from './PublishBatch';

export const getUniquePublishBatchPlanningResults = (results: PublishBatchResult[]): PublishBatchResult[] => {
    const planningResults = results.filter(status => status.type === PublishBatchResultType.planning);

    // Reverse the array to get the first result if duplicate id (the first contains the original conflict message)
    planningResults.reverse();

    const uniqueResults = getUniqueArrayList<PublishBatchResult>(planningResults, 'id');

    uniqueResults.reverse();

    return uniqueResults;
};
