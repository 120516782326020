import { doesPeriodOverlapWithWorkday } from '../../../../../../../@paco/entities/Period/PeriodHelpers';
import { Track } from '../../../../../../../@paco/entities/Track/Track';
import { compareAsc, compareDesc, formatDate } from '../../../../../../../helpers/date';
import { transformEndDateOrStringToFormDate, transformStartDateOrStringToFormDate } from '../../../../../../../helpers/date/transformStartDateOrStringToFormDate';
import legacyParse from '../../../../../../../helpers/legacyDateParser';
import { Absence, LeaveOfAbsence, Shift } from '../../../../../../../models';
import doesShiftFallOnWorkDay from '../../../../../../../services/ShiftService/doesShiftFallOnWorkDay';

export function filterResourceByDays(absences: (Absence | LeaveOfAbsence | Shift)[], day: Date) {
    // TODO: Fix this logic with just Date objects
    const format = 'yyyy-MM-dd';
    const dayStart = formatDate(new Date(day), format);
    const dayEnd = formatDate(new Date(day), format);

    return absences.filter((absence) => {
        const absenceStart = formatDate(transformStartDateOrStringToFormDate(new Date(absence.start)), format);
        const absenceEnd = absence.end ? formatDate(transformEndDateOrStringToFormDate(new Date(absence.end)), format) : '';

        if (!absence.end) {
            return dayStart === absenceStart;
        }

        return compareAsc(legacyParse(dayStart), legacyParse(absenceStart)) !== -1 && compareDesc(legacyParse(dayEnd), legacyParse(absenceEnd)) !== -1;
    });
}

export function filterShiftsByDays(shifts: Shift[], day: Date): Shift[] {
    return shifts
        .filter((shift) => doesShiftFallOnWorkDay(shift, day))
        .sort((a, b) => compareAsc(legacyParse(a.start), legacyParse(b.start)));
}

export function getHeader(day: Date) {
    return formatDate(new Date(day), 'EEEEEE');
}

export const filterTracksByDays = (tracks: Track[], day: Date): Track[] => tracks
    .filter(track => doesPeriodOverlapWithWorkday(day, track.billablePeriod))
    .sort((a, b) => compareAsc(legacyParse(a.billablePeriod.start), legacyParse(b.billablePeriod.start)));
