import {
    APP_SAGA_ROUTE_CHANGE,
    PZ_ADD_DOCUMENT_FAILURE,
    PZ_ADD_DOCUMENT_REQUEST,
    PZ_ADD_DOCUMENT_SUCCESS,
    PZ_DELETE_DOCUMENT_REQUEST,
    PZ_DELETE_DOCUMENT_SUCCESS,
    PZ_EDIT_DOCUMENT_FAILURE,
    PZ_EDIT_DOCUMENT_REQUEST,
    PZ_EDIT_DOCUMENT_SUCCESS,
    PZ_GET_DOCUMENT_TYPES_SUCCESS,
    PZ_GET_DOCUMENTS_REQUEST,
    PZ_GET_DOCUMENTS_SUCCESS,
    PZ_GET_LOKET_INFO_FAILURE,
    PZ_GET_LOKET_INFO_REQUEST,
    PZ_GET_LOKET_INFO_SUCCESS,
} from '../actionTypes';
import { PzReducer } from './pzModels';

const initialState: PzReducer = {
    loading: false,
    documentTypes: [],
    documents: [],
    lastAddedDocument: null,
    loketData: null,
};

const pzReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PZ_GET_DOCUMENTS_REQUEST:
        case PZ_DELETE_DOCUMENT_REQUEST:
        case PZ_EDIT_DOCUMENT_REQUEST:
        case PZ_GET_LOKET_INFO_REQUEST:
            return { ...state, loading: true };
        case PZ_ADD_DOCUMENT_REQUEST:
            return { ...state, loading: true };
        case PZ_DELETE_DOCUMENT_SUCCESS:
            return { ...state, loading: false };
        case PZ_GET_DOCUMENT_TYPES_SUCCESS:
            return {
                ...state,
                documentTypes: action.documentTypes,
            };
        case PZ_GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                documents: action.documents,
            };
        case PZ_GET_LOKET_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                loketData: action.loketData,
            };
        case PZ_GET_LOKET_INFO_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case PZ_ADD_DOCUMENT_FAILURE:
        case PZ_EDIT_DOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
                lastAddedDocument: null,
            };
        case PZ_ADD_DOCUMENT_SUCCESS:
        case PZ_EDIT_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                lastAddedDocument: action.lastAddedDocument,
            };
        case APP_SAGA_ROUTE_CHANGE:
            return {
                ...state,
                lastAddedDocument: null,
            };
        default:
            return state;
    }
};

export default pzReducer;
