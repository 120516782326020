import { Permission } from '../../../entities/Permission/Permission';
import { DashboardSettingType } from '../../../types';
import { dashboardSmallWidgets } from '../../../types/dashboardTypes';

export const filterWidgetByPermissions = (widgetType: DashboardSettingType, permissions: Permission[]): boolean => {
    const widget = dashboardSmallWidgets[widgetType];

    if (!widget) {
        return false;
    }

    const permissionSlugs = permissions.map(permission => permission.slug);
    const hasMissingPermission = widget.permissions.some(permission => !permissionSlugs.includes(permission));

    return !hasMissingPermission;
};
