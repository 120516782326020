import { transformToPeriod } from '../Period/PeriodTransformers';
import { LegacyBasicUser } from '../User/User';
import { LeaveOfAbsence, LeaveOfAbsenceResource, LeaveOfAbsenceV3Resource } from './LeaveOfAbsence';

export const transformToLeaveOfAbsence = (
    resource: LeaveOfAbsenceResource,
    user: LegacyBasicUser,
): LeaveOfAbsence => ({
    id: resource.id,
    status: resource.attributes.status,
    reason: resource.attributes.reason,
    declineReason: resource.attributes.declineReason,
    period: transformToPeriod(new Date(resource.attributes.start), new Date(resource.attributes.end)),
    user,
});

export const transformV3ResourceToLeaveOfAbsence = (
    resource: LeaveOfAbsenceV3Resource,
    user: LegacyBasicUser,
): LeaveOfAbsence => ({
    id: resource.uuid,
    status: resource.status,
    reason: resource.reason,
    declineReason: resource.declineReason,
    period: transformToPeriod(new Date(resource.startTime), new Date(resource.endTime)),
    user,
});
