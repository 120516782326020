import {
    Absence,
    AbsenceHours,
    LeaveOfAbsenceHours,
    PayrollPeriodViewModel,
} from '../../models';
import getWeeksFromStartAndEndDate from '../WeekWithHours/getWeeksFromStartAndEndDate';

export function doesAbsenceHoursHaveNullHours(
    // eslint-disable-next-line default-param-last
    absenceHours: (AbsenceHours | LeaveOfAbsenceHours)[] = [],
    payrollPeriod: PayrollPeriodViewModel,
): boolean {
    if (absenceHours.length === 0) {
        return true;
    }

    return absenceHours
        .some((absenceHour: AbsenceHours | LeaveOfAbsenceHours) => absenceHour.hours === null
            && absenceHour.payrollPeriod.id === payrollPeriod.id);
}

// This function is for the following scenario: an absence is created on monday and has one absenceHour. The next
// monday the absence should have two absenceHours. But the user does not update the absenceHours in the absence form.

export function doesAbsenceHaveMissingWeeks(
    absence: Absence,
    absenceHours: AbsenceHours[] = [],
    endDate: Date,
): boolean {
    const potentialWeeks = getWeeksFromStartAndEndDate(
        new Date(absence.start),
        absence.end ? new Date(absence.end) : endDate,
    );

    return potentialWeeks.some(week => !absenceHours.some(absenceHour => absenceHour.weekNumber === week.weekNumber));
}

export default function getIncompleteAbsences(
    absences: Absence[],
    payrollPeriod: PayrollPeriodViewModel,
): Absence[] {
    return absences.filter(absence => (
        doesAbsenceHoursHaveNullHours(absence.absenceHours, payrollPeriod)
            || doesAbsenceHaveMissingWeeks(absence, absence.absenceHours, payrollPeriod.end.date)
    ));
}
