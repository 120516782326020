import { addHours, startOfDayInHours } from '../../@paco/helpers/date';
import { PayrollPeriodViewModel } from '../../models';
import { getOverlappingPayrollPeriodOnDate } from './getOverlappingPeriodOnDate';

export default function getNextPayrollPeriodByDate(
    date: Date | string,
    // eslint-disable-next-line default-param-last
    payrollPeriods: PayrollPeriodViewModel[] = [],
    previous?: boolean,
): PayrollPeriodViewModel {
    const currentIndex = payrollPeriods
        .findIndex(period => getOverlappingPayrollPeriodOnDate(period, addHours(new Date(date), startOfDayInHours)));

    if (currentIndex <= 0 && previous) {
        return payrollPeriods[0];
    }

    if ((currentIndex === -1 || (currentIndex >= (payrollPeriods.length - 1))) && !previous) {
        return payrollPeriods[payrollPeriods.length - 1];
    }

    return payrollPeriods[currentIndex + (!previous ? 1 : -1)];
}
