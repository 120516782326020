import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { Department } from '../Department/Department';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { ShiftPlanning } from '../ShiftPlanning/ShiftPlanning';
import {
    BaseScheduleShift,
    BaseScheduleShiftResource,
    EditBaseScheduleShiftTimeFormData,
    EditBaseScheduleShiftTimeRequest,
} from './BaseScheduleShift';

export const transformToBaseScheduleShift = (
    baseScheduleShift: BaseScheduleShiftResource,
    department: Department,
    shiftPlannings: ShiftPlanning[] = [],
): BaseScheduleShift => ({
    id: baseScheduleShift.id,
    peopleLimit: baseScheduleShift.attributes.peopleLimit,
    department,
    period: transformToPeriod(
        new Date(baseScheduleShift.attributes.start),
        new Date(baseScheduleShift.attributes.end),
    ),
    shiftPlannings,
    weekday: baseScheduleShift.attributes.weekday,
});

export const transformEditBaseScheduleShiftTimeFormDataToEditBaseScheduleShiftRequest = (
    data: EditBaseScheduleShiftTimeFormData,
    shiftId: string,
): EditBaseScheduleShiftTimeRequest => ({
    type: 'baseScheduleShifts',
    id: shiftId,
    attributes: {
        start: formatDate(data.period.start, DateFormat.apiDateTime),
        end: formatDate(data.period.end, DateFormat.apiDateTime),
    },
});
