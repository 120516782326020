import { getHours, getUserAge } from '../../../helpers/date';
import { differenceInHours } from '../../helpers/date';
import { SettingKey } from '../../types';
import { ShiftPlanning } from '../ShiftPlanning/ShiftPlanning';
import { Setting } from './Setting';

export const getSetting = (settingKey: SettingKey, settings: Setting[], departmentId?: string): Setting | undefined => {
    if (!settings.length) {
        return undefined;
    }

    const generalSetting = settings
        .find(setting => setting.key === settingKey && setting.isActive && setting.departmentId === undefined);
    const departmentSetting = settings
        .find(setting => setting.key === settingKey && setting.isActive && setting.departmentId === departmentId);

    const setting = departmentSetting || generalSetting;

    if (!setting) {
        console.error(`[getSetting]: ${settingKey} setting not found`);
    }

    return departmentSetting || generalSetting;
};

export const getSettingEnabled = (settingKey: SettingKey, settings: Setting[], departmentId?: string): boolean => (
    !!getSetting(settingKey, settings, departmentId)?.isEnabled
);

export const getAbnormalTrackDurationThresholdSettingValue = (settings: Setting[], departmentId?: string): number => {
    const setting = getSetting('ABNORMAL_TRACK_DURATION_THRESHOLD', settings, departmentId);
    const defaultValue = 10;

    if (!setting) {
        return defaultValue;
    }

    const { value } = setting;

    if (Number.isNaN(+value)) {
        console.error('[getAbnormalTrackDurationThresholdSettingValue]: ABNORMAL_TRACK_DURATION_THRESHOLD setting value not a number', setting);

        return defaultValue;
    }

    return +value;
};

const isUnderAgeSettingEnabled = (settings: Setting[], departmentId: string): boolean => {
    const underAgeSetting = getSetting('ENABLE_CAO_MESSAGE_UNDERAGE_EMPLOYEE', settings, departmentId);

    return !(!underAgeSetting || !underAgeSetting.isEnabled);
};

export const isUserAgeBelow16AndShiftIsAfter19h = (
    settings: Setting[],
    departmentId: string,
    userBirthDay: Date,
    shiftPlanningEnd: Date,
    now: Date,
): boolean => {
    if (!isUnderAgeSettingEnabled(settings, departmentId)) {
        return false;
    }

    const userAge = getUserAge(now, userBirthDay);
    const hours = getHours(shiftPlanningEnd);
    const shiftEndsAfter19 = hours >= 19 || hours <= 3;

    return userAge < 16 && shiftEndsAfter19;
};

export const isUserAgeBelow18AndShiftIsAfter23h = (
    settings: Setting[],
    departmentId: string,
    userBirthDay: Date,
    shiftPlanningEnd: Date,
    now: Date,
): boolean => {
    if (!isUnderAgeSettingEnabled(settings, departmentId)) {
        return false;
    }

    const userAge = getUserAge(now, userBirthDay);
    const hours = getHours(shiftPlanningEnd);
    const shiftEndsAfter23 = hours >= 23 || hours <= 3;

    return userAge < 18 && shiftEndsAfter23;
};

export const getUserShiftPlanningInLast11Hours = (
    shiftPlanning: ShiftPlanning,
    recentShiftPlannings: ShiftPlanning[],
    settings: Setting[],
    departmentId?: string,
): boolean => {
    if (!getSettingEnabled('ENABLE_CAO_MESSAGE_TIME_BETWEEN_SHIFTS', settings, departmentId)) {
        return false;
    }

    const { period, user } = shiftPlanning;
    const userShiftPlannings = recentShiftPlannings.filter(recentShiftPlanning => recentShiftPlanning.user.id === user.id);

    return userShiftPlannings.some(userShiftPlanning => (
        differenceInHours(period.start, userShiftPlanning.period.end) < 11
    ));
};
