import { ReactElement, ReactNode } from 'react';

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableRow,
} from '../../../components';

import './ValkTable.scss';

interface ValkTableProps<T> {
    caption?: string;
    tableHeaders?: string[];
    rows?: T[];
    hasRowClick?: boolean;
    headerRowClassName?: string;
    cellClassName?: string;
    cellContentClassName?: string;
    className?: string;
    tableClassName?: string;
    headClassName?: string;
    children?: (rows: T[]) => ReactNode;
}

const ValkTable = <T, >({
    caption,
    tableHeaders,
    rows = [],
    hasRowClick,
    headerRowClassName = '',
    cellClassName = '',
    cellContentClassName = '',
    className = '',
    tableClassName = '',
    headClassName = '',
    children,
    // eslint-disable-next-line
}: ValkTableProps<T>): ReactElement => {
    return (
        <div className={`valk-table ${className}`}>
            <Table className={`valk-table__table ${tableClassName}`}>
                {caption && <TableCaption title={caption} />}

                {tableHeaders && (
                    <TableHead className={`valk-table__head ${headClassName}`}>
                        <TableRow className={`valk-table__header-row ${headerRowClassName}`}>
                            {tableHeaders.map(header => (
                                <TableCell
                                    key={header}
                                    className={cellClassName}
                                    contentClassName={cellContentClassName}
                                >
                                    {header}
                                </TableCell>
                            ))}
                            {hasRowClick && (
                                <TableCell
                                    className="valk-table__hidden-cell"
                                    contentClassName="valk-table__hidden-cell-content"
                                />
                            )}
                        </TableRow>
                    </TableHead>
                )}

                <TableBody>
                    {children && children(rows)}
                </TableBody>
            </Table>
        </div>
    );
};

export default ValkTable;
