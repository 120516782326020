import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { PreferToWorkTimeSlotViewModel } from '../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import { areIntervalsOverlapping } from '../../helpers/date';
import { ShiftPlanningViewModel, ShiftViewModel } from '../../models';

const doesPreferToWorkTimeSlotOverlapWithShiftPlanning = (
    preferToWorkTimeSlot: PreferToWorkTimeSlotViewModel,
    shiftPlanning: ShiftPlanningViewModel
    | ShiftViewModel
    | ShiftConceptViewModelOld
    | BaseScheduleShiftViewModel,
): boolean => areIntervalsOverlapping(
    {
        start: shiftPlanning.start,
        end: shiftPlanning.end.date,
    },
    {
        start: preferToWorkTimeSlot.start,
        end: preferToWorkTimeSlot.end.date,
    },
);

export default doesPreferToWorkTimeSlotOverlapWithShiftPlanning;
