import { transformToBasicPerson } from '../../@paco/entities/Person/PersonTransformers';
import { User } from '../../@paco/entities/User/User';
import { getEnumKeyByEnumValue } from '../../@paco/helpers/enum';
import { UserStatus } from '../../@paco/types/userStatus';
import { UserResource, UserViewModel } from '../../models';

export const transformUserViewModelToUser = (user: UserViewModel): User => {
    const status = getEnumKeyByEnumValue(UserStatus, user.status);

    return {
        id: user.id,
        email: user.email,
        employeeNumber: user.employeeNumber,
        shouldTrackTime: user.shouldTrackTime,
        isPaidBasedOnTrackedTime: user.isPaidBasedOnTrackedTime,
        isEligibleForTimeForTime: user.isEligibleForTimeForTime,
        isLoketAuthorized: user.isLoketAuthorized,
        isRegistrationComplete: user.registrationComplete,
        phone: user.phone,
        status: status ? UserStatus[status] : UserStatus.active,
        loketEmployeeId: user.loketEmployeeId || undefined,
        person: transformToBasicPerson(user.firstname, user.lastname, user.affix),
    };
};

export const transformLegacyUserToUser = (user: UserResource): User => {
    const status = getEnumKeyByEnumValue(UserStatus, user.status);

    return {
        id: user.id,
        email: user.email,
        employeeNumber: user.employeeNumber,
        shouldTrackTime: user.shouldTrackTime,
        isPaidBasedOnTrackedTime: user.isPaidBasedOnTrackedTime,
        isEligibleForTimeForTime: user.isEligibleForTimeForTime,
        isLoketAuthorized: user.isLoketAuthorized,
        isRegistrationComplete: user.registrationComplete,
        phone: user.phone,
        status: status ? UserStatus[status] : UserStatus.active,
        loketEmployeeId: user.loketEmployeeId || undefined,
        person: transformToBasicPerson(user.firstname, user.lastname, user.affix),
    };
};
