import { FC, useMemo } from 'react';

import { useToggle } from 'react-use';

import { getUserFullName } from '../../../helpers';
import { LoadingSpinner } from '../../components';
import DashboardWelcomeCard from '../../components/DashboardWelcomeCard/DashboardWelcomeCard';
import {
    EmptyState,
    IconButton,
    ModalContent,
    PacoModal,
} from '../../compositions';
import { ShiftsWidgetSkeletons } from '../../compositions/ShiftsWidget/subcomponents';
import { ConnectedEditDashboardForm, ConnectedHelpTooltip, ConnectedShiftsWidget } from '../../connectors';
import ConnectedChangeLogWidget from '../../connectors/ConnectedChangeLogWidget/ConnectedChangeLogWidget';
import { Notifications } from '../../entities/Notifications/Notifications';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';
import { Permission } from '../../entities/Permission/Permission';
import { AuthenticatedUser } from '../../entities/User/User';
import trans from '../../helpers/trans';
import { DashboardSettingType } from '../../types';
import { DashboardSmallWidgets } from '..';
import { filterWidgetByPermissions } from './helpers/filterWidgetByPermissions';

import './Dashboard.scss';

interface DashboardProps {
    shiftsIsLoading: boolean;
    notificationsIsLoading: boolean;
    userIsLoading: boolean;
    notifications: Notifications;
    payrollPeriods: PayrollPeriod[];
    permissions: Permission[];
    user?: AuthenticatedUser;
    userWidgetTypes: DashboardSettingType[];
    handleWidgetClick: () => void;
}

const Dashboard: FC<DashboardProps> = ({
    shiftsIsLoading,
    notificationsIsLoading,
    userIsLoading,
    notifications,
    payrollPeriods,
    permissions,
    user,
    userWidgetTypes,
    handleWidgetClick,
}) => {
    const [editWidgetModalIsOpen, toggleEditWidgetModalIsOpen] = useToggle(false);
    const userFullName = useMemo(() => getUserFullName(user), [user]);
    const filteredWidgetTypes = useMemo(() => (
        userWidgetTypes.filter(widgetType => filterWidgetByPermissions(widgetType, permissions))
    ), [userWidgetTypes, permissions]);

    return (
        <section className="dashboard">
            <div className="dashboard__title-wrapper">
                <h1 className="dashboard__title">{trans('routes.dashboard.label')}</h1>
                {!!(permissions.length && !userIsLoading) && (
                    <div className="dashboard__edit-button-wrapper">
                        <IconButton
                            icon="edit"
                            text={trans('containers.dashboard.editWidgets')}
                            onClick={toggleEditWidgetModalIsOpen}
                            className="dashboard__edit-button"
                        />
                        <ConnectedHelpTooltip
                            index={0}
                            videoSrc="/help/profile-icons.webm"
                            route="dashboard"
                            subTitle={trans('help.dashboard.profileIcons.title')}
                            text={trans('help.dashboard.profileIcons.text')}
                            title={trans('help.dashboard.title')}
                            showMobileInfoWarning
                            className="dashboard__profile-icons-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={1}
                            route="dashboard"
                            subTitle={trans('help.dashboard.editWidgets.title')}
                            text={trans('help.dashboard.editWidgets.text')}
                            title={trans('help.dashboard.title')}
                            className="dashboard__edit-help-tooltip"
                        />
                    </div>
                )}
            </div>

            {(!filteredWidgetTypes.length && !userIsLoading) ? (
                <div className="dashboard__cards">
                    <DashboardWelcomeCard userFullName={userFullName} className="dashboard__welcome-card" />
                    <EmptyState
                        showButton
                        buttonText={trans('compositions.dashboardEmptyState.addWidget')}
                        intro={trans('compositions.dashboardEmptyState.intro')}
                        title={trans('compositions.dashboardEmptyState.title')}
                        onAddButtonClick={toggleEditWidgetModalIsOpen}
                        className="dashboard__empty-state"
                    />
                </div>
            ) : (
                <DashboardSmallWidgets
                    notificationsIsLoading={notificationsIsLoading}
                    userIsLoading={userIsLoading}
                    notifications={notifications}
                    payrollPeriods={payrollPeriods}
                    permissions={permissions}
                    userFullName={userFullName}
                    widgets={filteredWidgetTypes}
                    handleWidgetClick={handleWidgetClick}
                    className="dashboard__small-widgets"
                />
            )}

            <div className="dashboard__big-widgets">
                <ConnectedChangeLogWidget className="dashboard__app-updates-widget" />
                {!userIsLoading ? (
                    <ConnectedShiftsWidget className="dashboard__shifts-widget" />
                ) : (
                    <ShiftsWidgetSkeletons className="dashboard__shifts-widget" />
                )}
            </div>

            {editWidgetModalIsOpen && (
                <PacoModal>
                    <ModalContent title={trans('containers.dashboard.editWidgets')}>
                        <ConnectedEditDashboardForm onClose={toggleEditWidgetModalIsOpen} />
                    </ModalContent>
                </PacoModal>
            )}

            {(userIsLoading && shiftsIsLoading) && (
                <LoadingSpinner />
            )}
        </section>
    );
};

export default Dashboard;
