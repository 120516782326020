import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { Experience } from '../../entities/Experience/Experience';

export type ExperiencesState = AsyncReduxState<{
    experiences: Experience[];
}>;

const initialState: ExperiencesState = {
    ...initialAsyncReduxState,
    experiences: [],
};

export const ExperiencesSlice = createSlice({
    name: 'experiencesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ExperiencesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ExperiencesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setExperiences(state, action: PayloadAction<Experience[]>): ExperiencesState {
            return {
                ...state,
                experiences: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setExperiences,
} = ExperiencesSlice.actions;

export default ExperiencesSlice.reducer;
