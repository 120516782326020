import React, { FC, ReactElement, useState } from 'react';

import classnames from 'classnames';

import { EditTimeButton } from '../../../../@paco/compositions';
import ElementWithTooltip from '../../../../@paco/compositions/ElementWithTooltip/ElementWithTooltip';
import { ConnectedEditShiftConceptPlanningTimeForm, ConnectedEditShiftPlanningTimeForm } from '../../../../@paco/connectors';
import { Period } from '../../../../@paco/entities/Period/Period';
import Icon from '../../../../components/style/Icon/Icon';
import UserColor from '../../../../components/UserColor/UserColor';

import './AvailabilityButton.scss';

interface AvailabilityButtonProps {
    isCompanyMedic?: boolean;
    showName: boolean;
    showDescription: boolean;
    comment?: string;
    id: string;
    modifier: string;
    period: Period;
    color?: string | null;
    name: string;
    type: string;
    onButtonClick: () => void;
}

const AvailabilityButton: FC<AvailabilityButtonProps> = ({
    isCompanyMedic,
    showName,
    showDescription,
    comment,
    id,
    modifier,
    period,
    color,
    name,
    type,
    onButtonClick,
}): ReactElement => {
    const [showEditTimeForm, setShowEditTimeForm] = useState(false);

    const buttonClassName = classnames('availability-button', {
        [`availability-button--is-${modifier}`]: modifier,
        'availability-button--is-company-medic': isCompanyMedic,
    });

    const handleTimeButtonClick = (): void => {
        setShowEditTimeForm(true);
    };

    const handleSubmitEditShiftTimeForm = (): void => {
        setShowEditTimeForm(false);
    };

    return (
        <div id={id} className={buttonClassName}>
            <div className="availability-button__time-and-color">
                <div className="availability-button__time-wrapper">
                    {showEditTimeForm ? (
                        <>
                            {modifier === 'shift-planning' && (
                                <ConnectedEditShiftPlanningTimeForm
                                    period={period}
                                    shiftId={id}
                                    onClose={handleSubmitEditShiftTimeForm}
                                    className="availability-button__edit-time-form"
                                />
                            )}
                            {modifier === 'shift-concept-planning' && (
                                <ConnectedEditShiftConceptPlanningTimeForm
                                    period={period}
                                    shiftId={id}
                                    onClose={handleSubmitEditShiftTimeForm}
                                    className="availability-button__edit-time-form"
                                />
                            )}
                        </>
                    ) : (
                        <EditTimeButton
                            isDisabled={!['shift-planning', 'shift-concept-planning'].includes(modifier)}
                            period={period}
                            onClick={handleTimeButtonClick}
                            className="availability-button__time-button"
                        />
                    )}
                </div>
                {color && <UserColor className="availability-button__color" size="tiny" color={color} />}
            </div>
            { showName && (
                <div className="availability-button__name">
                    {name}
                </div>
            )}
            { showDescription && (
                <div className="availability-button__description">
                    <span>{type}</span>
                    {comment && (
                        <ElementWithTooltip tooltipIsActive tooltipText={comment}>
                            <Icon color="white" fontSize={16} kind="comment" />
                        </ElementWithTooltip>
                    )}
                </div>
            )}
            <button
                type="button"
                aria-label="availability button"
                onClick={onButtonClick}
                className="availability-button__button"
            />
        </div>
    );
};

export default AvailabilityButton;
