export const getTableItemAttribute = (item: any, attribute: string): string => {
    const splitAttributes = attribute.split('.');

    let result = item[splitAttributes[0]];

    splitAttributes.forEach(splitAttribute => {
        if (result[splitAttribute]) {
            result = result[splitAttribute];
        }
    });

    return (typeof result === 'string') ? result : attribute;
};
