import React, { FC } from 'react';

import ConnectedSharedDocumentsOverview
    from './connectors/ConnectedSharedDocumentsOverview/ConnectedSharedDocumentsOverview';


const CompletePeriods: FC = () => (
    <ConnectedSharedDocumentsOverview />
);

export default CompletePeriods;
