import { FC, useRef } from 'react';

import trans from '../../../../helpers/trans';
import useBlockItemOffset from '../../hooks/useBlockItemOffset';
import { PrintableDistrictDivisionCommentBlock } from '../../models';

import './PrintableDistrictDivisionCommentBlockItem.scss';

interface PrintableDistrictDivisionCommentBlockItemProps {
    block: PrintableDistrictDivisionCommentBlock;
    isSizer?: boolean;
    className?: string;
}

const PrintableDistrictDivisionCommentBlockItem: FC<PrintableDistrictDivisionCommentBlockItemProps> = ({ isSizer = false, block, className = '' }) => {
    const blockItemRef = useRef<HTMLDivElement>(null);

    useBlockItemOffset(blockItemRef, block.id, isSizer);

    return (
        <div ref={blockItemRef} className={`printable-district-division-comment-block-item ${className}`}>
            <div className="printable-district-division-comment-block-item__wrapper">
                <h2 className="printable-district-division-comment-block-item__title">
                    {trans('common.generalComments')}
                </h2>
                <p className="printable-district-division-comment-block-item__body">
                    {block.body}
                </p>
            </div>
        </div>
    );
};

export default PrintableDistrictDivisionCommentBlockItem;
