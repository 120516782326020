import { Dispatch } from 'redux';

import { BaseScheduleShiftViewModel } from '../../../entities/BaseScheduleShift/BaseScheduleShift';
import {
    deleteBaseScheduleShiftPlanningApiCall,
    patchBaseScheduleShiftPlanningApiCall,
    patchBaseScheduleShiftPlanningColorApiCall,
    postBaseScheduleShiftPlanningApiCall,
} from '../../../entities/BaseScheduleShiftPlanning/BaseScheduleShiftPlanningService';
import {
    transformAddBaseScheduleShiftPlanningRequest,
    transformBaseScheduleShiftPlanningToRequest,
    transformEditBaseScheduleShiftPlanningColorRequest,
    transformEditBaseScheduleShiftPlanningFormData,
} from '../../../entities/BaseScheduleShiftPlanning/BaseScheduleShiftPlanningTransformers';
import { getEmployeesOfDepartment } from '../../../helpers/api-ts/users';
import { EditShiftPlanningFormData, ShiftPlanningViewModel } from '../../../models';
import compareShiftPlanningUsersAlphabetically from '../../../services/ShiftPlanningService/compareShiftPlanningUsersAlphabetically';
import { setBaseScheduleShift } from '../baseScheduleShift/baseScheduleShiftReducer';
import {
    setEmployeeOptions,
    setError,
    setIsLoading,
    setIsSuccessful,
} from './baseScheduleShiftPlanningReducer';

export const planUserToBaseScheduleShift = (
    baseScheduleShift: BaseScheduleShiftViewModel,
    userId: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const request = transformAddBaseScheduleShiftPlanningRequest(baseScheduleShift.id, userId);

        const response = await postBaseScheduleShiftPlanningApiCall(request);

        const updatedBaseScheduleShift: BaseScheduleShiftViewModel = {
            ...baseScheduleShift,
            shiftPlannings: [
                ...baseScheduleShift.shiftPlannings,
                response,
            ].sort(compareShiftPlanningUsersAlphabetically),
        };

        dispatch(setBaseScheduleShift(updatedBaseScheduleShift));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.error('[planUserToBaseScheduleShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const setBaseScheduleShiftPlanningColor = (
    baseScheduleShift: BaseScheduleShiftViewModel,
    shiftPlanning: ShiftPlanningViewModel,
    color?: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const request = transformEditBaseScheduleShiftPlanningColorRequest(shiftPlanning.id, color);
        const response = await patchBaseScheduleShiftPlanningColorApiCall(shiftPlanning.id, request);

        const updatedBaseScheduleShift: BaseScheduleShiftViewModel = {
            ...baseScheduleShift,
            shiftPlannings: [
                ...baseScheduleShift.shiftPlannings
                    .filter(planning => planning.id !== shiftPlanning.id),
                response,
            ].sort(compareShiftPlanningUsersAlphabetically),
        };

        dispatch(setBaseScheduleShift(updatedBaseScheduleShift));
    } catch (error) {
        console.error('[setBaseScheduleShiftPlanningColor]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const copyBaseScheduleShiftPlanning = (baseScheduleShiftPlanning: ShiftPlanningViewModel, baseScheduleShiftId: string) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const request = transformBaseScheduleShiftPlanningToRequest(baseScheduleShiftPlanning, baseScheduleShiftId);

        await postBaseScheduleShiftPlanningApiCall(request);

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[createBaseScheduleShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editBaseScheduleShiftPlanning = (
    baseScheduleShift: BaseScheduleShiftViewModel,
    shiftPlanningId: string,
    formData: EditShiftPlanningFormData,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const request = transformEditBaseScheduleShiftPlanningFormData(formData, shiftPlanningId, baseScheduleShift.start);
        const response = await patchBaseScheduleShiftPlanningApiCall(request, shiftPlanningId);

        const updatedBaseScheduleShift: BaseScheduleShiftViewModel = {
            ...baseScheduleShift,
            shiftPlannings: [
                ...baseScheduleShift.shiftPlannings
                    .filter(planning => planning.id !== shiftPlanningId),
                response,
            ].sort(compareShiftPlanningUsersAlphabetically),
        };

        dispatch(setBaseScheduleShift(updatedBaseScheduleShift));
    } catch (error) {
        console.error('[editBaseScheduleShiftPlanning]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteBaseScheduleShiftPlanning = (
    baseScheduleShift: BaseScheduleShiftViewModel,
    shiftPlanningId: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        await deleteBaseScheduleShiftPlanningApiCall(shiftPlanningId);

        const updatedBaseScheduleShift: BaseScheduleShiftViewModel = {
            ...baseScheduleShift,
            shiftPlannings: baseScheduleShift.shiftPlannings
                .filter((shiftPlanning) => shiftPlanning.id !== shiftPlanningId),
        };

        dispatch(setBaseScheduleShift(updatedBaseScheduleShift));
    } catch (error) {
        console.error('[deleteBaseScheduleShiftPlanning]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchEmployeeOptions = (department: string) => async (dispatch: Dispatch): Promise<void> => {
    try {
        const response = await getEmployeesOfDepartment(
            department,
            [
                'person',
                'roles',
            ],
        );
        dispatch(setEmployeeOptions(response));
    } catch (error) {
        console.error('[fetchEmployeeOptions]', error);
    }
};
