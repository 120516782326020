import { FC, ReactElement, useMemo } from 'react';

import { LeaveOfAbsencesOverviewBodyRow } from '../../entities/LeaveOfAbsenceOverviewBlock/LeaveOfAbsencesOverviewBlock';
import { LegacyBasicUser } from '../../entities/User/User';
import { paginateArray } from '../../helpers/array';
import { getRandomUuid } from '../../helpers/crypto';
import trans from '../../helpers/trans';
import { PrintableLeaveOfAbsencePage } from './subcomponents';

import './PrintableLeaveOfAbsenceOverview.scss';

interface PrintableLeaveOfAbsenceOverviewProps {
    calendarDays: Date[];
    leaveOfAbsencesOverviewBodyRows: LeaveOfAbsencesOverviewBodyRow[];
    users: LegacyBasicUser[];
    className?: string;
}

const usersPageLimit = 25;

const PrintableLeaveOfAbsenceOverview: FC<PrintableLeaveOfAbsenceOverviewProps> = ({
    calendarDays,
    leaveOfAbsencesOverviewBodyRows,
    users,
    className = '',
}): ReactElement => {
    const paginatedUsersArrays = useMemo(() => paginateArray<LeaveOfAbsencesOverviewBodyRow>(leaveOfAbsencesOverviewBodyRows, usersPageLimit), [leaveOfAbsencesOverviewBodyRows]);
    const paginatedUsersArraysIds = useMemo(() => new Array(paginatedUsersArrays.length).fill(null).map(() => getRandomUuid()), [paginatedUsersArrays]);

    return (
        <div className={`printable-leave-of-absence-overview ${className}`} data-printable="true">
            <div className="printable-leave-of-absence-overview__header printable-leave-of-absence-overview__users-amount-section">
                <h1 className="printable-leave-of-absence-overview__title">
                    {trans('common.employees')}
                </h1>
                <p className="printable-leave-of-absence-overview__users-amount">
                    {`${users.length} ${trans('common.employees')}`}
                </p>
            </div>
            {paginatedUsersArrays.map((rows, index) => (
                <div
                    key={paginatedUsersArraysIds[index]}
                    className="printable-leave-of-absence-overview__table"
                >
                    <PrintableLeaveOfAbsencePage
                        calendarDays={calendarDays}
                        leaveOfAbsencesOverviewBodyRows={rows}
                    />
                </div>
            ))}
        </div>
    );
};

export default PrintableLeaveOfAbsenceOverview;
