import { FC, ReactElement } from 'react';

import { FullLoketUser } from '../../entities/LoketUser/LoketUser';
import { UserProfile } from '../../entities/User/User';
import { UserDetailBasicCard, UserDetailInfoCard } from './subcomponents';

import './UserDetailCards.scss';

interface UserDetailCardsProps {
    canViewLogs: boolean;
    canViewLoketData: boolean;
    isLoketUserLoading: boolean;
    user: UserProfile;
    loketUser?: FullLoketUser;
    onDeactivateUserButtonClick: () => void;
    onHistoryButtonClick: () => void;
    onResendRegistrationMailButtonClick: () => void;
    onSendResetPasswordAndPinButtonClick: () => void;
    className?: string;
}

const UserDetailCards: FC<UserDetailCardsProps> = ({
    canViewLogs,
    canViewLoketData,
    isLoketUserLoading,
    user,
    loketUser,
    onDeactivateUserButtonClick,
    onHistoryButtonClick,
    onResendRegistrationMailButtonClick,
    onSendResetPasswordAndPinButtonClick,
    className = '',
}): ReactElement => (
    <div className={`user-detail-cards ${className}`}>
        <UserDetailBasicCard
            canViewLogs={canViewLogs}
            canViewLoketData={canViewLoketData}
            isLoketUserLoading={isLoketUserLoading}
            user={user}
            loketUser={loketUser}
            onDeactivateUserButtonClick={onDeactivateUserButtonClick}
            onResendRegistrationMailButtonClick={onResendRegistrationMailButtonClick}
            onSendResetPasswordAndPinButtonClick={onSendResetPasswordAndPinButtonClick}
            onHistoryButtonClick={onHistoryButtonClick}
            className="user-detail-cards__basic-info-card"
            innerWrapperClassName="user-detail-cards__basic-info-card__inner-wrapper"
            loketWrapperClassName="user-detail-cards__basic-info-card__loket-wrapper"
        />

        <UserDetailInfoCard
            user={user}
            className="user-detail-cards__detail-info-card"
        />
    </div>
);

export default UserDetailCards;
