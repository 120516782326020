import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { BaseScheduleShift } from '../../entities/BaseScheduleShift/BaseScheduleShift';

export type BaseScheduleShiftState = AsyncReduxState<{
    deletedBaseScheduleShiftId?: string;
    baseScheduleShift?: BaseScheduleShift;
    isDeleteBaseScheduleShiftSuccessful: boolean;
    isEditBaseScheduleShiftSuccessful: boolean;
}>;

const initialState: BaseScheduleShiftState = {
    ...initialAsyncReduxState,
    isDeleteBaseScheduleShiftSuccessful: false,
    isEditBaseScheduleShiftSuccessful: false,
};

export const baseScheduleShiftSlice = createSlice({
    name: 'pacoBaseScheduleShiftReducer',
    initialState,
    reducers: {
        setDeletedBaseScheduleShiftId(state, action: PayloadAction<string | undefined>): BaseScheduleShiftState {
            return {
                ...state,
                deletedBaseScheduleShiftId: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): BaseScheduleShiftState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): BaseScheduleShiftState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setIsDeleteBaseScheduleShiftSuccessful(state, action: PayloadAction<boolean>): BaseScheduleShiftState {
            return {
                ...state,
                isDeleteBaseScheduleShiftSuccessful: action.payload,
            };
        },
        setIsEditBaseScheduleShiftSuccessful(state, action: PayloadAction<boolean>): BaseScheduleShiftState {
            return {
                ...state,
                isEditBaseScheduleShiftSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): BaseScheduleShiftState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBaseScheduleShift(state, action: PayloadAction<BaseScheduleShift>): BaseScheduleShiftState {
            return {
                ...state,
                baseScheduleShift: action.payload,
            };
        },
    },
});

export const {
    setDeletedBaseScheduleShiftId,
    setIsLoading,
    setIsSuccessful,
    setIsDeleteBaseScheduleShiftSuccessful,
    setIsEditBaseScheduleShiftSuccessful,
    setError,
    setBaseScheduleShift,
} = baseScheduleShiftSlice.actions;

export default baseScheduleShiftSlice.reducer;
