import { ChangeEvent, FC } from 'react';

import { ErrorLabel, Input, InputLabel } from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';
import { isTimeStringValid } from '../../../helpers/date';

import './TimeInput.scss';

export interface DateInputProps extends Omit<HTMLInputProps, 'onChange' | 'value' | 'type'> {
    label: string;
    value: string;
    hideLabel?: boolean;
    error?: string;
    onChange: (value: string) => void;
}

const TimeInput: FC<DateInputProps> = ({
    label,
    value,
    hideLabel,
    required = false,
    error = '',
    onChange,
    className = '',
    ...inputProps
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (isTimeStringValid(event.currentTarget.value)) {
            onChange(event.currentTarget.value);
        }
    };

    return (
        <label className={`time-input ${className}`}>
            {!hideLabel && <InputLabel text={label} required={required} />}

            <Input
                {...inputProps}
                type="time"
                value={value}
                required={required}
                error={error}
                onChange={handleChange}
            />

            {error && <ErrorLabel text={error} className="time-input__error-label" />}
        </label>
    );
};

export default TimeInput;
