import React, {
    ChangeEvent,
    FC,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Button,
    Col,
    CustomInput,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { InputLabel } from '../../../@paco/components';
import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { doesPeriodOverlapWithLockedPayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformToPeriod } from '../../../@paco/entities/Period/PeriodTransformers';
import { Setting } from '../../../@paco/entities/Setting/Setting';
import { getAbnormalTrackDurationThresholdSettingValue } from '../../../@paco/entities/Setting/SettingHelpers';
import ButtonHistory from '../../../components/ButtonHistory/ButtonHistory';
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker';
import TrackDurationTooLongWarning from '../../../components/TrackDurationTooLongWarning/TrackDurationTooLongWarning';
import UserPageLink from '../../../components/UserPageLink/UserPageLink';
import { BREAK_TIMES, MEALS } from '../../../constants';
import { EditTrackFormData } from '../../../entities/Track/Track';
import { getUserFullName } from '../../../helpers';
import {
    compareAsc,
    formatDate,
    isValid,
    setHours,
    setMinutes,
} from '../../../helpers/date';
import transformTimeStringToNumber from '../../../helpers/date/transformTimeStringToNumber';
import { translate } from '../../../helpers/translations/translator';
import { MealType, TrackViewModel } from '../../../models';
import { getShiftStartAndEndTimeOfTrack } from '../helpers/getShiftStartAndEndTimeOfTrack';
import getEditTrackError from './helpers/getEditTrackError';

import './EditTrack.scss';

interface EditTrackProps {
    canEdit: boolean;
    settings: Setting[];
    payrollPeriods: PayrollPeriod[];
    track: TrackViewModel;
    title: string;
    onSubmit: (formData: EditTrackFormData) => void;
    onCancel: () => void;
    onShowLogsClick?: () => void;
}

const EditTrack: FC<EditTrackProps> = ({
    canEdit,
    payrollPeriods,
    settings,
    track,
    title,
    onSubmit,
    onCancel,
    onShowLogsClick,
}) => {
    const formRef = useRef<HTMLFormElement>(null);

    const [checkIn, setCheckIn] = useState<Date>(isValid(track.billableStart) ? track.billableStart : track.checkIn);
    const [checkOut, setCheckOut] = useState<Date>(track.billableEnd.transformToUpToButExcludingDate().date || new Date());

    const [checkInDate, setCheckInDate] = useState<Date>(checkIn);
    const [checkOutDate, setCheckOutDate] = useState<Date>(checkOut);
    const [checkInTime, setCheckInTime] = useState<string>(formatDate(checkIn, 'HH:mm'));
    const [checkOutTime, setCheckOutTime] = useState<string>(formatDate(checkOut, 'HH:mm'));
    const [pause, setPause] = useState<number>(track.pause || 0);
    const [meal, setMeal] = useState<MealType>(track.meal || MealType.NONE);
    const [departmentId, setDepartmentId] = useState<string>(track.department?.id || '');
    const [hasDateError, setHasDateError] = useState<boolean>(false);
    const [hasPayrollPeriodError, setHasPayrollPeriodError] = useState<boolean>(false);
    const [showAbnormalTrackDurationWarning, setShowAbnormalTrackDurationWarning] = useState<boolean>(false);

    const pauseTimeOptions = BREAK_TIMES.map(time => (
        <option key={time} value={time}>{time}</option>
    ));

    const mealOptions = MEALS.map(mealOption => (
        <option
            key={mealOption.value}
            value={mealOption.value}
        >
            {mealOption.label}
        </option>
    ));

    useEffect((): void => {
        const newCheckIn = setMinutes(setHours(checkInDate, Number(checkInTime.split(':')[0])), Number(checkInTime.split(':')[1]));
        const newCheckOut = setMinutes(setHours(checkOutDate, Number(checkOutTime.split(':')[0])), Number(checkOutTime.split(':')[1]));
        const dateError = compareAsc(newCheckIn, newCheckOut) === 1;

        const payrollPeriodError = doesPeriodOverlapWithLockedPayrollPeriod(
            transformToPeriod(newCheckIn, newCheckOut),
            payrollPeriods,
        );

        setCheckIn(newCheckIn);
        setCheckOut(newCheckOut);
        setHasDateError(dateError);
        setHasPayrollPeriodError(payrollPeriodError);
    }, [
        checkInDate,
        checkInTime,
        checkOutDate,
        checkOutTime,
    ]);

    useEffect(() => {
        const totalBillableTime = track?.totalBillableTime ? transformTimeStringToNumber(track.totalBillableTime) : 0;
        const abnormalTrackDurationThreshold = getAbnormalTrackDurationThresholdSettingValue(settings, track?.department?.id);
        setShowAbnormalTrackDurationWarning(totalBillableTime > abnormalTrackDurationThreshold);
    }, [track, settings]);

    const handleSubmit = (accepted?: boolean): void => {
        const formData: EditTrackFormData = {
            id: track.id,
            checkIn,
            checkOut,
            pause,
            meal,
            accepted,
            departmentId,
        };

        onSubmit(formData);
    };

    const handleEdit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        handleSubmit();
    };

    const handleAccept = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        handleSubmit(true);
    };

    const handleDepartmentChange = (e: ChangeEvent<HTMLInputElement>): void => setDepartmentId(e.currentTarget.value);
    const handlePauseChange = (e: ChangeEvent<HTMLInputElement>): void => setPause(Number(e.currentTarget.value));
    const handleCheckInDateChange = (dateString: string): void => setCheckInDate(new Date(dateString));
    const handleCheckOutDateChange = (dateString: string): void => setCheckOutDate(new Date(dateString));
    const handleCheckInTimeChange = (e: ChangeEvent<HTMLInputElement>): void => setCheckInTime(e.currentTarget.value);
    const handleCheckOutTimeChange = (e: ChangeEvent<HTMLInputElement>): void => setCheckOutTime(e.currentTarget.value);

    return (
        <form
            ref={formRef}
            onSubmit={!track.accepted ? handleAccept : handleEdit}
            className="form-edit-track"
        >
            <ModalHeader className="form-edit-track__modal-header">
                <span>{title}</span>
                {onShowLogsClick && <ButtonHistory className="form-edit-track__button-history" onClick={onShowLogsClick} />}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.employee')}</Label>
                            <p className="form-fake-field">
                                <UserPageLink
                                    isPlain
                                    name={getUserFullName(track.owner)}
                                    id={track.owner.id}
                                />
                            </p>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <InputLabel
                                required
                                text={translate('common.department')}
                            />
                            <CustomInput
                                disabled={track.accepted && departmentId}
                                type="select"
                                id="department"
                                onChange={handleDepartmentChange}
                                value={departmentId}
                            >
                                {!departmentId && <option value="null" key="null">{translate('common.notSelected')}</option>}
                                {track.owner.departments.map(departmentOption => (
                                    <option
                                        key={departmentOption.id}
                                        value={departmentOption.id}
                                    >
                                        {departmentOption.name}
                                    </option>
                                ))}
                            </CustomInput>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.shift')}</Label>
                            {track.shift ? (
                                <p className="form-fake-field">
                                    {getShiftStartAndEndTimeOfTrack(track)}
                                </p>
                            ) : (
                                <p className="form-fake-field">
                                    {translate('pages.tracks.unplanned')}
                                </p>
                            )}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <InputLabel
                                required
                                text={translate('pages.tracks.startDate')}
                            />
                            <FormDatePicker
                                selected={checkInDate}
                                invalid={hasDateError || hasPayrollPeriodError}
                                onChange={handleCheckInDateChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup className="mb-0">
                            <InputLabel
                                required
                                text={translate('pages.tracks.startTime')}
                            />
                            <CustomInput
                                className="form-control required"
                                type="time"
                                id="start-time"
                                onChange={handleCheckInTimeChange}
                                value={checkInTime}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <InputLabel
                                required
                                text={translate('pages.tracks.endDate')}
                            />
                            <FormDatePicker
                                selected={checkOutDate || checkInDate}
                                onChange={handleCheckOutDateChange}
                                invalid={hasDateError || hasPayrollPeriodError}
                                feedback={getEditTrackError(hasDateError, hasPayrollPeriodError)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup className="mb-0">
                            <InputLabel
                                required
                                text={translate('pages.tracks.endTime')}
                            />
                            <CustomInput
                                className="form-control required"
                                type="time"
                                id="start-time"
                                onChange={handleCheckOutTimeChange}
                                value={checkOutTime}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup className="mb-0">
                            <InputLabel
                                required
                                text={translate('pages.tracks.pauseTime')}
                            />
                            <CustomInput
                                type="select"
                                id="pause"
                                value={pause}
                                onChange={handlePauseChange}
                            >
                                {pauseTimeOptions}
                            </CustomInput>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup className="mb-0">
                            <InputLabel text={translate('common.meal')} />
                            <CustomInput
                                type="select"
                                value={meal}
                                onChange={setMeal}
                                id="meal"
                            >
                                {mealOptions}
                            </CustomInput>
                        </FormGroup>
                    </Col>
                </Row>
                {showAbnormalTrackDurationWarning && (
                    <Row>
                        <Col>
                            <TrackDurationTooLongWarning className="form-edit-track__warning" />
                        </Col>
                    </Row>
                )}
            </ModalBody>
            <ModalFooter className="form-edit-track__modal-footer">
                <Button type="button" color="link" id="modal-close" className="form-edit-track__button-cancel" onClick={onCancel}>
                    {translate('common.cancel')}
                </Button>
                {canEdit && (
                    <Button
                        type="button"
                        color="orange"
                        disabled={!departmentId || hasDateError}
                        className="form-edit-track__button-submit"
                    >
                        {translate('common.edit')}
                    </Button>
                )}
                {!track.accepted && (
                    <Button
                        type="submit"
                        color="green"
                        disabled={!departmentId || hasDateError || hasPayrollPeriodError}
                        className="form-edit-track__button-submit"
                    >
                        {title}
                    </Button>
                )}
            </ModalFooter>
        </form>
    );
};

export default EditTrack;
