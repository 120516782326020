import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Button,
    Col,
    CustomInput,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { CommentCategoryViewModel, CommentFormData, CommentViewModel } from '../../../../models';
import transformCommentCategoriesToSelectOptions
    from '../../../../services/CommentCategoryService/transformCommentCategoriesToSelectOptions';


interface AddOrEditNoteProps {
    data: CommentViewModel | null;
    commentCategories: CommentCategoryViewModel[];
    userId: string;
    onCancel: () => void;
    onSubmit: (data: CommentFormData) => void;
}

const AddOrEditNote: FC<AddOrEditNoteProps> = ({
    data,
    commentCategories = [],
    onCancel,
    onSubmit,
    userId,
}) => {
    const [category, setCategory] = useState<string | null>(commentCategories[0] ? commentCategories[0].id : null);
    const [body, setBody] = useState('');

    const title = useMemo(() => translate(`common.${data ? 'edit' : 'add'}Entity`, { entity: translate('common.notice') }), [data]);

    const commentCategoryOptions = useMemo(() => transformCommentCategoriesToSelectOptions(commentCategories), [commentCategories]);

    const canSubmit = !!category && !!body;

    useEffect(() => {
        if (data) {
            setCategory(data.category ? data.category.id : null);
            setBody(data.body);
        }
    }, [data]);

    const onFormSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit({
            id: data ? data.id : undefined,
            body,
            categoryId: category || '',
            userId,
        });
    };

    const onChangeCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCategory(e.target.value);
    };

    const onChangeBody = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBody(e.target.value);
    };

    return (
        <form onSubmit={onFormSubmit}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.category')}</Label>
                            <CustomInput
                                type="select"
                                id="category"
                                name="category"
                                value={category || ''}
                                onChange={onChangeCategory}
                            >
                                {commentCategoryOptions}
                            </CustomInput>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.notice')}</Label>
                            <Input
                                type="textarea"
                                rows={5}
                                className="form-control form-note-comment"
                                id="body"
                                name="comment"
                                placeholder={translate('pages.management.noticeFormBodyDescription')}
                                value={body}
                                onChange={onChangeBody}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="link"
                    id="modal-close"
                    onClick={onCancel}
                >
                    {translate('common.cancel')}
                </Button>
                <Button
                    type="submit"
                    color="orange"
                    disabled={!canSubmit}
                >
                    {translate('common.save')}
                </Button>
            </ModalFooter>
        </form>
    );
};

export default AddOrEditNote;
