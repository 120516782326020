import React, { FC, useState } from 'react';

import {
    Button,
    CustomInput,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';

import './CopyShiftsForm.scss';


interface CopyShiftsFormProps {
    shiftsLength: number;
    onCancel: () => void;
    onSubmit: (copyEmployees: boolean) => void;
}

const CopyShiftsForm: FC<CopyShiftsFormProps> = ({
    onCancel,
    onSubmit,
    shiftsLength,
}) => {
    const [copyEmployees, setCopyEmployees] = useState(false);

    const handleCopyShiftEmployeeClick = () => {
        setCopyEmployees(!copyEmployees);
    };

    const handleSubmit = () => {
        onSubmit(copyEmployees);
    };

    return (
        <div>
            <ModalHeader>
                {shiftsLength > 1 ? translate('pages.shifts.copyShifts') : translate('pages.shifts.copyShift')}
            </ModalHeader>
            <ModalBody>
                <p className="copy-shift-form__intro">
                    {`${translate('pages.shifts.copyShiftIntroPart1')} `}
                    {shiftsLength > 1 && <strong>{shiftsLength}</strong>}
                    {` ${shiftsLength > 1 ? translate('pages.shifts.copyShiftsIntroPart2') : translate('pages.shifts.copyShiftIntroPart2')}`}
                </p>
                <FormGroup
                    check
                    className="copy-shift-form__form-checkbox"
                >
                    <Label check>
                        <CustomInput
                            checked={copyEmployees}
                            label={translate('pages.shifts.copyShiftEmployees')}
                            type="checkbox"
                            id="copy-employees"
                            onChange={handleCopyShiftEmployeeClick}
                        />
                    </Label>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="link"
                    id="modal-close"
                    onClick={onCancel}
                >
                    {translate('common.cancel')}
                </Button>
                <Button type="submit" color="orange" onClick={handleSubmit}>{translate('common.toCopy')}</Button>
            </ModalFooter>
        </div>
    );
};

export default CopyShiftsForm;
