import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { DistrictDivisionDistrictTemplateResource } from '../DistrictDivisionDistrictTemplate/DistrictDivisionDistrictTemplate';
import { FetchResult, FetchResultType } from '../FetchResult';
import {
    AddDistrictDivisionGroupTemplateFormData,
    DistrictDivisionGroupTemplate,
    DistrictDivisionGroupTemplateResource,
    EditDistrictDivisionGroupTemplateFormData,
} from './DistrictDivisionGroupTemplate';
import {
    transformAddDistrictDivisionGroupTemplateFormDataToRequest,
    transformEditDistrictDivisionGroupTemplateFormDataToEditApiParams,
    transformToDistrictDivisionGroupTemplate,
} from './DistrictDivisionGroupTemplateTransformers';

export const postDistrictDivisionGroupTemplateApiCall = async (
    addDistrictDivisionGroupTemplateFormData: AddDistrictDivisionGroupTemplateFormData,
): Promise<FetchResult<DistrictDivisionGroupTemplate, string>> => {
    const apiData = transformAddDistrictDivisionGroupTemplateFormDataToRequest(addDistrictDivisionGroupTemplateFormData);

    try {
        const includes = [
            'districtDivisionDistrictTemplates',
        ];

        const url = generateApiUrl({
            endpoint: '/district-division-group-templates',
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DistrictDivisionGroupTemplateResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const districtDivisionDistrictTemplatesResource = getMultipleIncluded(doc, doc.data, 'districtDivisionDistrictTemplates') as DistrictDivisionDistrictTemplateResource[];

        const districtDivisionGroupTemplates = transformToDistrictDivisionGroupTemplate(doc.data, districtDivisionDistrictTemplatesResource);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: districtDivisionGroupTemplates,
        };
    } catch (error) {
        console.error('[postDistrictDivisionGroupTemplateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchDistrictDivisionGroupTemplateApiCall = async (
    editDistrictDivisionGroupTemplateFormData: EditDistrictDivisionGroupTemplateFormData,
): Promise<FetchResult<DistrictDivisionGroupTemplate, string>> => {
    const apiData = transformEditDistrictDivisionGroupTemplateFormDataToEditApiParams(editDistrictDivisionGroupTemplateFormData);

    try {
        const includes = [
            'districtDivisionDistrictTemplates',
        ];

        const url = generateApiUrl({
            endpoint: `/district-division-group-templates/${apiData.id}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DistrictDivisionGroupTemplateResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const districtDivisionDistrictTemplatesResource = getMultipleIncluded(doc, doc.data, 'districtDivisionDistrictTemplates') as DistrictDivisionDistrictTemplateResource[];

        const districtDivisionGroupTemplates = transformToDistrictDivisionGroupTemplate(doc.data, districtDivisionDistrictTemplatesResource);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: districtDivisionGroupTemplates,
        };
    } catch (error) {
        console.error('[patchDistrictDivisionGroupTemplateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteDistrictDivisionGroupTemplateApiCall = async (districtDivisionGroupTemplateId: string): Promise<FetchResult<undefined, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/district-division-group-templates/${districtDivisionGroupTemplateId}` });

        const response = await authorizedFetch(url, { method: 'DELETE' });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteDistrictDivisionGroupTemplateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
