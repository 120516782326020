import React, { FC } from 'react';

import {
    getIconById,
    getIconByMeal,
    getIconBySatisfaction,
    getUserFullName,
} from '../../../../helpers';
import { translate } from '../../../../helpers/translations/translator';
import { TrackViewModel } from '../../../../models/Track';
import { getTrackAssessorTooltip, getTrackSatisfactionTooltip } from '../../../../pages/Tracks/helpers';
import InfoCircle from '../../../InfoCircle/InfoCircle';

interface InfoCirclesProps {
    track: TrackViewModel;
    canViewSatisfaction: boolean;
    canViewSatisfactionFeedback: boolean;
}

const InfoCircles: FC<InfoCirclesProps> = ({ track, canViewSatisfaction, canViewSatisfactionFeedback }) => {
    const rating = track.feedback && track.feedback.rating;
    const { assessor } = track;
    const { meal } = track;

    return (
        <div className="info-circle-list">
            <InfoCircle
                text={track.pause}
                tooltip={`${track.pause} ${translate('pages.tracks.minutesBreakTime')}`}
            />
            <InfoCircle
                icon={getIconByMeal(track.meal)}
                tooltip={meal && translate(`pages.tracks.${meal.toString().toLowerCase()}`)}
            />
            {canViewSatisfaction && (
                <InfoCircle
                    icon={getIconBySatisfaction(rating)}
                    alt={translate('common.satisfaction')}
                    tooltip={getTrackSatisfactionTooltip(track, canViewSatisfactionFeedback)}
                    iconClass="smiley"
                />
            )}
            {assessor && (
                <InfoCircle
                    icon={getIconById('tie')}
                    tooltip={getTrackAssessorTooltip(track)}
                />
            )}
            {track.managerComment && (
                <InfoCircle
                    icon={getIconById('remark')}
                    alt={translate('common.comment')}
                    tooltip={`${getUserFullName(track.managerComment.owner)}: ${track.managerComment.body}`}
                    iconClass="balloon"
                />
            )}
        </div>
    );
};

export default InfoCircles;
