import React, {
    ChangeEvent,
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Button,
    Col,
    CustomInput,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { BaseScheduleFormData, BaseScheduleViewModel } from '../../../../entities/BaseSchedule/BaseSchedule';
import { scrollIntoView } from '../../../../helpers/tools/scroll';
import { translate } from '../../../../helpers/translations/translator';
import { BaseScheduleFormErrors, validateFormData } from './validation/baseScheduleFormValidation';


interface BaseScheduleFormProps {
    title: string;
    baseSchedule?: BaseScheduleViewModel;
    onSubmit: (formData: BaseScheduleFormData) => void;
    onCancel: () => void;
    className?: string;
}

const BaseScheduleForm: FC<BaseScheduleFormProps> = ({
    title,
    baseSchedule,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [name, setName] = useState<string>('');
    const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
    const [formErrors, setFormErrors] = useState<BaseScheduleFormErrors>();

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            id: baseSchedule?.id,
            name,
        };

        const [errors, hasErrors] = validateFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);

    useEffect(() => {
        if (baseSchedule) {
            setName(baseSchedule.name);
        }
    }, []);

    useEffect(() => {
        const formData = {
            id: baseSchedule?.id,
            name,
        };

        const [, hasErrors] = validateFormData(formData);

        setSubmitIsDisabled(hasErrors);
    }, [name]);

    return (
        <div className={`base-schedule-form ${className}`}>
            <form ref={formRef} onSubmit={handleSubmit}>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('forms.baseScheduleForm.name.label')}</Label>
                                <CustomInput
                                    className="form-control"
                                    type="text"
                                    id="name"
                                    name="name"
                                    autoComplete="off"
                                    placeholder={translate('forms.baseScheduleForm.name.placeholder')}
                                    onChange={handleNameChange}
                                    value={name}
                                    invalid={!!formErrors?.name}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" id="modal-close" onClick={onCancel}>{translate('common.cancel')}</Button>
                    <Button type="submit" color="orange" disabled={submitIsDisabled}>{translate('common.save')}</Button>
                </ModalFooter>
            </form>
        </div>
    );
};

export default BaseScheduleForm;
