import axios from 'axios';

import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { PreferToWorkTimeSlotResource, PreferToWorkTimeSlotViewModel } from './PreferToWorkTimeSlot';
import { transformPreferToWorkTimeSlot } from './PreferToWorkTimeSlotTransformers';

export const getPreferToWorkTimeSlots = async (
    startDate: Date,
    endDate: Date,
    userUuids: string[] = [],
    includes: string[] = [],
): Promise<PreferToWorkTimeSlotViewModel[]> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/prefer-to-work-time-slots`);

    const response = await axios.get<PreferToWorkTimeSlotResource[]>(String(url), {
        params: {
            orderByStart: JSON.stringify({ start: 'DESC' }),
            include: includes.join(','),
            userUuids: userUuids.length > 0 ? JSON.stringify(userUuids) : undefined,
            startDate: formatDate(startDate, DateFormat.apiDateTime),
            endDate: formatDate(endDate, DateFormat.apiDateTime),
        },
    });

    return response.data.map(preferToWorkTimeSlot => transformPreferToWorkTimeSlot(preferToWorkTimeSlot));
};
