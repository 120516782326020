import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../components';
import DistrictDivisionGroupTemplateTableSkeleton from '../../@tables/DistrictDivisionGroupTemplateTable/subcomponents/DistrictDivisionGroupTemplateTableSkeleton';

import './DistrictDivisionTemplateDetailContainerSkeleton.scss';

interface DistrictDivisionTemplateDetailContainerSkeletonProps {
    className?: string;
}

const DistrictDivisionTemplateDetailContainerSkeleton: FC<DistrictDivisionTemplateDetailContainerSkeletonProps> = ({
    className = '',
}): ReactElement => (
    <div className={`district-division-template-detail-container-skeleton ${className}`}>
        <div className="district-division-template-detail-container-skeleton__top-bar">
            <Skeleton className="district-division-template-detail-container-skeleton__title" />
            <Skeleton className="district-division-template-detail-container-skeleton__plus-button" />
        </div>

        <div className="district-division-template-detail-container-skeleton__item">
            <div className="district-division-template-detail-container-skeleton__item-header">
                <Skeleton className="district-division-template-detail-container-skeleton__groupname" />
                <div className="district-division-template-detail-container-skeleton__action-buttons">
                    <Skeleton className="district-division-template-detail-container-skeleton__icon-button" />
                    <Skeleton className="district-division-template-detail-container-skeleton__icon-button" />
                </div>
            </div>

            <Card className="district-division-group-template-table">
                <DistrictDivisionGroupTemplateTableSkeleton />
            </Card>
        </div>

        <div className="district-division-template-detail-container-skeleton__item">
            <div className="district-division-template-detail-container-skeleton__item-header">
                <Skeleton className="district-division-template-detail-container-skeleton__groupname" />
                <div className="district-division-template-detail-container-skeleton__action-buttons">
                    <Skeleton className="district-division-template-detail-container-skeleton__icon-button" />
                    <Skeleton className="district-division-template-detail-container-skeleton__icon-button" />
                </div>
            </div>

            <Card className="district-division-group-template-table">
                <DistrictDivisionGroupTemplateTableSkeleton />
            </Card>
        </div>

        <div className="district-division-template-detail-container-skeleton__item">
            <div className="district-division-template-detail-container-skeleton__item-header">
                <Skeleton className="district-division-template-detail-container-skeleton__groupname" />
                <div className="district-division-template-detail-container-skeleton__action-buttons">
                    <Skeleton className="district-division-template-detail-container-skeleton__icon-button" />
                    <Skeleton className="district-division-template-detail-container-skeleton__icon-button" />
                </div>
            </div>

            <Card className="district-division-group-template-table">
                <DistrictDivisionGroupTemplateTableSkeleton />
            </Card>
        </div>

        <div className="district-division-template-detail-container-skeleton__item">
            <div className="district-division-template-detail-container-skeleton__item-header">
                <Skeleton className="district-division-template-detail-container-skeleton__groupname" />
                <div className="district-division-template-detail-container-skeleton__action-buttons">
                    <Skeleton className="district-division-template-detail-container-skeleton__icon-button" />
                    <Skeleton className="district-division-template-detail-container-skeleton__icon-button" />
                </div>
            </div>

            <Card className="district-division-group-template-table">
                <DistrictDivisionGroupTemplateTableSkeleton />
            </Card>
        </div>
    </div>
);

export default DistrictDivisionTemplateDetailContainerSkeleton;
