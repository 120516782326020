import React, {
    FC,
    ReactNode,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

import { ShiftsLegend, SidebarToggleButton } from '../../@paco/compositions';
import { ShiftsLegendColorTypes } from '../../@paco/types/shiftsLegendColorTypes';
import { translate } from '../../helpers/translations/translator';
import { setAppSidebarResize } from '../../redux/app/appActions';
import Icon from '../style/Icon/Icon';

import './SidebarPage.scss';

interface SidebarPageProps {
    disableMobile?: boolean;
    hideToggleButton?: boolean;
    showLegend?: boolean;
    stickySidebar?: boolean;
    sidebarContent: ReactNode;
    mobileButtonText?: string;
    children?: ReactNode;
}

const SidebarPage: FC<SidebarPageProps> = ({
    disableMobile = false,
    hideToggleButton = false,
    stickySidebar,
    sidebarContent,
    mobileButtonText = null,
    showLegend,
    children,
}) => {
    const dispatch = useDispatch();

    const [isActiveOnMobile, setIsActiveOnMobile] = useState(false);
    const [sidebarIsHidden, setSidebarIsHidden] = useState(false);
    const [calendarIsHidden, setCalendarIsHidden] = useState(false);
    const showCalendarTimeout = useRef<NodeJS.Timeout | null>(null);

    const closeMobileSidebar = (): void => {
        setIsActiveOnMobile(false);
    };

    const onButtonToggleClick = (): void => {
        setIsActiveOnMobile(!isActiveOnMobile);
    };

    const showCalendar = (): void => {
        setCalendarIsHidden(false);
    };

    const onButtonHideClick = (): void => {
        localStorage.setItem('hiddenSidebar', String(!sidebarIsHidden));
        dispatch(setAppSidebarResize());
        setSidebarIsHidden(!sidebarIsHidden);
        setCalendarIsHidden(true);

        if (showCalendarTimeout.current) clearTimeout(showCalendarTimeout.current);
        showCalendarTimeout.current = setTimeout(showCalendar, 300);
    };

    const addEventListeners = (): void => {
        window.addEventListener('closeMobileSidebar', closeMobileSidebar);
    };

    const removeEventListeners = (): void => {
        window.removeEventListener('closeMobileSidebar', closeMobileSidebar);
    };

    useEffect((): () => void => {
        addEventListeners();
        setSidebarIsHidden(localStorage.getItem('hiddenSidebar') === 'true');

        return (): void => removeEventListeners();
    });

    const sidebarClassnames = classnames('sidebar', {
        'sidebar--sidebar-hidden': sidebarIsHidden,
        'sidebar--calendar-hidden': calendarIsHidden,
    });

    const sideContainerClassnames = classnames('sidebar-side-container', {
        'sidebar-side-container--active': isActiveOnMobile,
        'sidebar-side-container--sticky': !!stickySidebar,
        'sidebar-side-container--has-legend': showLegend,
    });

    const shiftLegendItems = useMemo(() => [
        ShiftsLegendColorTypes.plannedFull,
        ShiftsLegendColorTypes.plannedOpen,
        ShiftsLegendColorTypes.conceptFull,
        ShiftsLegendColorTypes.conceptOpen,
        ShiftsLegendColorTypes.selected,
        ShiftsLegendColorTypes.companyMedic,
    ], []);

    return (
        <div className={sidebarClassnames}>
            <div className="sidebar-page-container">
                {children}
                {(showLegend && !sidebarIsHidden) && (
                    <ShiftsLegend
                        legendItems={shiftLegendItems}
                        className="sidebar__shifts-legend"
                    />
                )}
            </div>
            <div className="sidebar-side-container-container">
                <div className={sideContainerClassnames}>
                    <div className="sidebar-page-sidebar">
                        <div className="sidebar__inner">
                            {stickySidebar ? (
                                <div className="sidebar__sticky-content">
                                    {sidebarContent}
                                </div>
                            ) : sidebarContent}
                        </div>

                        {!hideToggleButton && (
                            <SidebarToggleButton
                                isOpened={!sidebarIsHidden}
                                onClick={onButtonHideClick}
                                className="sidebar__toggle-button"
                            />
                        )}
                    </div>
                </div>
            </div>
            <Button
                block
                outline
                className={`sidebar-button-toggle ${disableMobile ? 'sidebar-button-toggle--hide-mobile' : ''}`}
                onClick={onButtonToggleClick}
                color="primary"
            >
                {!isActiveOnMobile
                    ? <Icon kind="filter_list" fontSize={24} />
                    : <Icon kind="close" fontSize={24} className="sidebar__button-toggle-close-icon" />}
                <span>
                    {!isActiveOnMobile
                        ? (mobileButtonText || translate('common.toFilter'))
                        : translate('common.close')}
                </span>
            </Button>
        </div>
    );
};

export default SidebarPage;
