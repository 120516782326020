export enum SortDirection {
    asc = 'ascending',
    desc = 'descending',
}

export enum SortField {
    dateAdded = 'dateAdded',
    department = 'department',
    id = 'id',
    group = 'group',
    name = 'name',
    size = 'size',
}

export interface SortConfig {
    field: SortField;
    direction: SortDirection;
}
