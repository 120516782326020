import axios from 'axios';

const postComment = (id, comment, relationship, type) => {
    const body = {
        type: 'comments',
        attributes: {
            body: comment,
        },
        relationships: {
            [relationship]: {
                data: {
                    type,
                    id,
                },
            },
        },
    };
    return axios.post(
        `${process.env.REACT_APP_API_URL}/comments/`,
        { data: body },
    );
};

export {
    postComment,
};
