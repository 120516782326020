import { ShiftConceptViewModelOld } from '../../../../../entities/ShiftConcept/ShiftConcept';
import { ShiftPlanningViewModel, UserWithPersonViewModel } from '../../../../../models';

// Dit is een bugfix: users worden door meerdere resources geinclude, daarom is er een race condition en kan het zijn dat de user include
// niet via shiftConceptPlannings binnen komt, waardoor de gewerkte uren er niet bij zitten. Daarom worden de users/workweek apart opgehaald om vervolgens
// gemapt te worden aan de users van shiftConceptPlannings.

export const mapEmployeesToShiftConcept = (shiftConcept: ShiftConceptViewModelOld, users: UserWithPersonViewModel[]): ShiftConceptViewModelOld => {
    const shiftPlannings: ShiftPlanningViewModel[] = shiftConcept.shiftPlannings.map(shiftPlanning => {
        const matchedUser = users.find(user => user.id === shiftPlanning.user?.id);

        return {
            ...shiftPlanning,
            user: matchedUser || shiftPlanning.user,
        };
    });

    return {
        ...shiftConcept,
        shiftPlannings,
    };
};
