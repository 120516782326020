import { FC, ReactElement } from 'react';

import { ShowEmploymentTypesFilterContainer } from '../../../containers';
import { setShowEmploymentTypes } from '../../../redux/@interface/globalFilters/globalFiltersReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

const ConnectedShowEmploymentTypesFilter: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const showEmploymentTypes = useTypedSelector(state => state.globalFiltersReducer.showEmploymentTypes);

    const handleOnChange = (show: boolean): void => {
        dispatch(setShowEmploymentTypes(show));
    };

    return (
        <ShowEmploymentTypesFilterContainer
            showEmploymentTypes={showEmploymentTypes}
            onChange={handleOnChange}
        />
    );
};

export default ConnectedShowEmploymentTypesFilter;
