import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { noop } from 'underscore';

import { LoadingSpinner } from '../../../@paco/components';
import ConnectedCompletePeriodIncompleteUsers from '../../../@paco/connectors/ConnectedCompletePeriodIncompleteUsers/ConnectedCompletePeriodIncompleteUsers';
import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { transformLegacyPayrollPeriodToPayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriodTransformers';
import { setLastActivePeriodId, setOpenTasksInitialized } from '../../../@paco/redux/completePeriods/completePeriodsReducer';
import { getPacoPayrollPeriods } from '../../../@paco/redux/payrollPeriods/payrollPeriodsActions';
import { useTypedSelector } from '../../../@paco/redux/store';
import { fetchUsersWithDivergentEmploymentHours } from '../../../@paco/redux/usersWithDivergentEmploymentHours/usersWithDivergentEmploymentHoursActions';
import { setUsersWithDivergentEmploymentHours } from '../../../@paco/redux/usersWithDivergentEmploymentHours/usersWithDivergentEmploymentHoursReducer';
import {
    ConnectedCompletePeriodAbsences,
    ConnectedCompletePeriodIncompleteLeaveOfAbsences,
    ConnectedCompletePeriodOpenLeaveOfAbsences,
    ConnectedCompletePeriodOpenTracks,
    ConnectedCompletePeriodTracksToReview,
} from '../../../connectors';
import { CompletePeriodHeaderContainer } from '../../../containers';
import { fetchOpenTracks } from '../../../redux/@toolkit/@tracks/openTracks/openTracksActions';
import { fetchTracksToReview } from '../../../redux/@toolkit/@tracks/tracksToReview/tracksToReviewActions';
import { fetchIncompleteAbsences } from '../../../redux/@toolkit/absences/absencesActions';
import { fetchIncompleteLeaveOfAbsences } from '../../../redux/@toolkit/leaveOfAbsences/incompleteLeaveOfAbsences/incompleteLeaveOfAbsencesActions';
import { fetchOpenLeaveOfAbsences } from '../../../redux/@toolkit/leaveOfAbsences/openLeaveOfAbsences/openLeaveOfAbsencesActions';
import { useTypedDispatch } from '../../../redux/store';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../../services/PayrollPeriodService/transformPayrollPeriodLegacy';

import './CompletePeriodDetail.scss';

const CompletePeriodDetail: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const { id: payrollPeriodId } = useParams<{ id: string }>();

    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);
    const [activePeriod, setActivePeriod] = useState<PayrollPeriod | undefined>(payrollPeriods.find(period => period.id === payrollPeriodId));

    const { user } = useTypedSelector(state => state.authenticatedUserReducer);
    const { openTasksInitialized } = useTypedSelector(state => state.completePeriodsReducer);
    const { departments = [] } = user || {};

    useEffectOnce((): void => {
        if (!payrollPeriods.length) {
            dispatch(getPacoPayrollPeriods());
        }
    });

    useEffect((): void => {
        if (!activePeriod) {
            setActivePeriod(payrollPeriods.find(payrollPeriod => payrollPeriod.id === payrollPeriodId));
        }
    }, [payrollPeriods]);

    useEffect((): () => void => {
        if (!activePeriod || openTasksInitialized) {
            return noop;
        }
        const legacyPayrollPeriod = transformPayrollPeriodToLegacyPayrollPeriod(activePeriod);

        dispatch(setUsersWithDivergentEmploymentHours([]));

        dispatch(fetchIncompleteLeaveOfAbsences(legacyPayrollPeriod));
        dispatch(fetchOpenLeaveOfAbsences(legacyPayrollPeriod, departments));
        dispatch(fetchTracksToReview(legacyPayrollPeriod));
        dispatch(fetchIncompleteAbsences(legacyPayrollPeriod));
        dispatch(fetchOpenTracks(legacyPayrollPeriod));
        dispatch(fetchUsersWithDivergentEmploymentHours(transformLegacyPayrollPeriodToPayrollPeriod(legacyPayrollPeriod)));
        dispatch(setOpenTasksInitialized(true));

        return () => {
            dispatch(setOpenTasksInitialized(false));
        };
    }, [activePeriod]);

    const onPeriodSelect = (payrollPeriod: PayrollPeriod): void => {
        setActivePeriod(payrollPeriod);
        dispatch(setOpenTasksInitialized(false));
        dispatch(setLastActivePeriodId(payrollPeriod.id));
    };

    if (!activePeriod) {
        return <LoadingSpinner />;
    }

    return (
        <div className="complete-period-detail-page">
            <CompletePeriodHeaderContainer
                payrollPeriods={payrollPeriods}
                activePeriod={activePeriod}
                onPeriodSelect={onPeriodSelect}
                className="complete-period-detail-page__block"
            />
            <ConnectedCompletePeriodOpenTracks
                activePeriod={activePeriod}
                className="complete-period-detail-page__block"
            />
            <ConnectedCompletePeriodTracksToReview
                activePeriod={activePeriod}
                className="complete-period-detail-page__block"
            />
            <ConnectedCompletePeriodAbsences
                activePeriod={activePeriod}
                className="complete-period-detail-page__block"
            />
            <ConnectedCompletePeriodOpenLeaveOfAbsences
                activePeriod={activePeriod}
                className="complete-period-detail-page__block"
            />
            <ConnectedCompletePeriodIncompleteLeaveOfAbsences
                activePeriod={activePeriod}
                className="complete-period-detail-page__block"
            />
            <ConnectedCompletePeriodIncompleteUsers
                activePeriod={activePeriod}
                className="complete-period-detail-page__block"
            />
        </div>
    );
};

export default CompletePeriodDetail;
