import { ExportShiftFormData } from '../../../../entities/Shift/Shift';
import {
    FormErrors,
    FormValidation,
    validateDateString,
    validateForm,
} from '../../../../services/validationService';

export type ExportShiftsFormErrors = FormErrors<ExportShiftFormData>;

export const validateExportsShiftFormData = (formData: ExportShiftFormData): FormValidation<ExportShiftsFormErrors> => {
    const errors: ExportShiftsFormErrors = {
        startDate: validateDateString('startDate', formData.endDate),
        endDate: validateDateString('endDate', formData.endDate),
    };

    return validateForm<ExportShiftsFormErrors>(errors);
};
