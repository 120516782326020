import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { LoadingSpinner } from '../../../../@paco/components';
import { ConnectedHelpTooltip } from '../../../../@paco/connectors';
import { transformLegacySettingToSetting } from '../../../../@paco/entities/Setting/SettingTransformers';
import trans from '../../../../@paco/helpers/trans';
import { checkPermission } from '../../../../helpers';
import { translate } from '../../../../helpers/translations/translator';
import { transformSettingToLegacySetting } from '../../../../services/SettingsService/transformSettingToLegacySetting';
import SettingsCard from '../SettingsCard/SettingsCard';
import SettingsCardSection from '../SettingsCard/SettingsCardSection';
import SettingsShiftTime from '../SettingsShiftTime/SettingsShiftTime';
import SettingsBreaks from './SettingsBreaks';
import SettingsInputTime from './SettingsInputTime';
import SettingsMeals from './SettingsMeals';
import SettingsRadios from './SettingsRadios';

class SettingsSection extends Component {
    state = {
        modified: false,
        settings: [],
    };

    componentDidMount() {
        const { settings } = this.state;
        this.setState({
            settings,
        });
    }

    reset = () => {
        const { department = undefined } = this.props;
        const departmentId = department?.id;
        const settings = this.props.settings
            .filter(setting => setting.departmentId === departmentId)
            .map(setting => ({ ...setting, delete: true }));

        this.props.onSubmit(settings);
    };

    submit = () => {
        const { settings } = this.state;
        this.props.onSubmit(settings);
    };

    trimSettings = (settings, newSettings) => settings.reduce((total, setting) => {
        const match = newSettings.find(newSetting => newSetting.id === setting.id
            && newSetting.departmentId === setting.departmentId);
        return [
            ...total,
            ...(!match ? [setting] : []),
        ];
    }, []);

    onSettingChange = (value) => {
        const { department } = this.props;
        const departmentId = department?.id;
        let { settings } = this.state;
        let newSettings = (Array.isArray(value) ? value : [value]);
        if (departmentId) {
            newSettings = newSettings.map(setting => ({
                ...setting,
                ...((
                    departmentId && !setting.departmentId
                ) && { new: true }),
                departmentId: departmentId || undefined,
            }));
        }

        settings = this.trimSettings(settings, newSettings);

        this.setState({
            settings: [...newSettings, ...settings],
            modified: true,
        });
    };

    onPacoSettingChange = (newSettings) => {
        this.onSettingChange(newSettings.map(transformSettingToLegacySetting));
    };

    findSettingByKey = (key, state) => {
        const { department } = this.props;
        const departmentId = department?.id;
        const settings = state ? this.state.settings : this.props.settings;

        // first try to find the setting specific for department, else get the general setting
        const match = settings.find(setting => setting.key === key
            && setting.departmentId === departmentId)
            || settings.find(setting => setting.key === key && !setting.departmentId);

        return match;
    };

    getBreakSetting = () => {
        const { settings, department } = this.props;
        const departmentId = department?.id;

        // first try to find the setting specific for department, else get the general setting
        const generalSettings = settings.find(setting => setting.key === 'TIMER_PAUSE_DURATION_OPTION' && setting.departmentId === undefined);
        const departmentSettings = settings.find(setting => setting.key === 'TIMER_PAUSE_DURATION_OPTION' && setting.departmentId === departmentId);

        return departmentSettings || generalSettings;
    };

    renderTimerSettings() {
        const { department } = this.props;
        const departmentId = department?.id;

        const enableFeedback = this.findSettingByKey('TIMER_ENABLE_FEEDBACK');
        const automaticApproval = this.findSettingByKey('TIMER_AUTOMATIC_APPROVAL');
        const automaticApprovalState = this.findSettingByKey('TIMER_AUTOMATIC_APPROVAL', true);
        const automaticCorrectBeforeShiftStartMargin = transformLegacySettingToSetting(this.findSettingByKey('TIMER_AUTOMATIC_CORRECT_BEFORE_SHIFT_START_MARGIN'));
        const automaticCorrectAfterShiftStartMargin = transformLegacySettingToSetting(this.findSettingByKey('TIMER_AUTOMATIC_CORRECT_AFTER_SHIFT_START_MARGIN'));
        const automaticCorrectBeforeShiftEndMargin = transformLegacySettingToSetting(this.findSettingByKey('TIMER_AUTOMATIC_CORRECT_BEFORE_SHIFT_END_MARGIN'));
        const automaticCorrectAfterShiftEndMargin = transformLegacySettingToSetting(this.findSettingByKey('TIMER_AUTOMATIC_CORRECT_AFTER_SHIFT_END_MARGIN'));
        const automaticApprovalBeforeShiftStartMargin = transformLegacySettingToSetting(this.findSettingByKey('TIMER_AUTOMATIC_APPROVAL_BEFORE_SHIFT_START_MARGIN'));
        const automaticApprovedAfterShiftStartMargin = transformLegacySettingToSetting(this.findSettingByKey('TIMER_AUTOMATIC_APPROVAL_AFTER_SHIFT_START_MARGIN'));
        const automaticApprovalBeforeShiftEndMargin = transformLegacySettingToSetting(this.findSettingByKey('TIMER_AUTOMATIC_APPROVAL_BEFORE_SHIFT_END_MARGIN'));
        const automaticApprovalAfterShiftEndMargin = transformLegacySettingToSetting(this.findSettingByKey('TIMER_AUTOMATIC_APPROVAL_AFTER_SHIFT_END_MARGIN'));
        const mealNoneEnabled = this.findSettingByKey('MEAL_NONE_ENABLED');
        const mealNonePrice = this.findSettingByKey('MEAL_NONE_PRICE');
        const mealBreadEnabled = this.findSettingByKey('MEAL_BREAD_ENABLED');
        const mealBreadPrice = this.findSettingByKey('MEAL_BREAD_PRICE');
        const mealHotEnabled = this.findSettingByKey('MEAL_HOT_ENABLED');
        const mealHotPrice = this.findSettingByKey('MEAL_HOT_PRICE');

        const breakSetting = this.getBreakSetting();
        const disableModifiedEnableFeedback = parseInt((automaticApprovalState || automaticApproval).value, 10) === 1;

        return (
            <>
                {!departmentId
                    && (
                        <SettingsCard
                            title={translate('pages.settings.feedback')}
                        >
                            <SettingsCardSection
                                title={translate('pages.settings.collectFeedback')}
                                text={translate('pages.settings.collectFeedbackIntro')}
                            >
                                <SettingsRadios
                                    title={translate('pages.settings.doYouWantToUseThisTool')}
                                    setting={enableFeedback}
                                    onChange={this.onSettingChange}
                                />
                            </SettingsCardSection>
                        </SettingsCard>
                    )}
                <ConnectedHelpTooltip
                    index={1}
                    route="timer"
                    subTitle={trans('help.settings.setByDepartment.title')}
                    text={trans('help.settings.setByDepartment.text')}
                    title={trans('help.settings.title')}
                    showMobileInfoWarning
                    className="settings__set-by-department-help-tooltip"
                />
                <SettingsCard
                    title={translate('common.tracks')}
                >
                    <SettingsCardSection
                        title={translate('pages.settings.automaticApproval')}
                        text={translate('pages.settings.automaticApprovalIntro')}
                    >
                        <SettingsRadios
                            title={translate('pages.settings.doYouWantToAutomaticallyApproveTracks')}
                            setting={automaticApproval}
                            onChange={this.onSettingChange}
                        />
                    </SettingsCardSection>
                </SettingsCard>
                <SettingsCard
                    title={translate('pages.settings.breaks')}
                >
                    <SettingsCardSection
                        title={translate('pages.settings.breakTimes')}
                        text={translate('pages.settings.breakTimesIntro')}
                    >
                        <SettingsBreaks
                            type="TIMER_PAUSE_DURATION_OPTION"
                            title={translate('pages.settings.breakOptions')}
                            buttonText={translate('pages.settings.addNewBreakTime')}
                            onChange={this.onSettingChange}
                            option={breakSetting}
                            departmentId={departmentId}
                        />
                    </SettingsCardSection>
                </SettingsCard>
                <SettingsCard
                    title={translate('pages.settings.timeMargins')}
                >
                    <SettingsCardSection
                        title={translate('pages.settings.automaticCorrectionStartTime')}
                        text={translate('pages.settings.automaticCorrectionStartTimeIntro')}
                    >
                        <SettingsShiftTime
                            settings={[
                                automaticCorrectBeforeShiftStartMargin,
                                automaticCorrectAfterShiftStartMargin,
                            ]}
                            onChange={this.onPacoSettingChange}
                        />
                    </SettingsCardSection>
                    <SettingsCardSection
                        title={translate('pages.settings.automaticCorrectionEndTimeMargins')}
                        text={translate('pages.settings.automaticCorrectionEndTimeMarginsIntro')}
                    >
                        <SettingsShiftTime
                            hasEnd
                            settings={[
                                automaticCorrectBeforeShiftEndMargin,
                                automaticCorrectAfterShiftEndMargin,
                            ]}
                            onChange={this.onSettingChange}
                        />
                    </SettingsCardSection>
                    <SettingsCardSection
                        disabled={disableModifiedEnableFeedback}
                        title={translate('pages.settings.automaticApprovalStartTime')}
                        text={translate('pages.settings.automaticApprovalStartTimeIntro')}
                    >
                        <SettingsShiftTime
                            settings={[
                                automaticApprovalBeforeShiftStartMargin,
                                automaticApprovedAfterShiftStartMargin,
                            ]}
                            onChange={this.onSettingChange}
                        />
                    </SettingsCardSection>
                    <SettingsCardSection
                        disabled={disableModifiedEnableFeedback}
                        title={translate('pages.settings.automaticApprovalEndTimeMargin')}
                        text={translate('pages.settings.automaticApprovalEndTimeMarginIntro')}
                    >
                        <SettingsShiftTime
                            hasEnd
                            settings={[
                                automaticApprovalBeforeShiftEndMargin,
                                automaticApprovalAfterShiftEndMargin,
                            ]}
                            onChange={this.onSettingChange}
                        />
                    </SettingsCardSection>
                </SettingsCard>
                <SettingsCard
                    title={translate('common.meals')}
                >
                    <SettingsCardSection
                        title={translate('common.meals')}
                        text={translate('pages.settings.chooseMealsIntro')}
                    >
                        <SettingsMeals
                            departmentId={departmentId}
                            onChange={this.onSettingChange}
                            mealNoneEnabled={mealNoneEnabled}
                            mealBreadEnabled={mealBreadEnabled}
                            mealHotEnabled={mealHotEnabled}
                            mealNonePrice={mealNonePrice}
                            mealBreadPrice={mealBreadPrice}
                            mealHotPrice={mealHotPrice}
                        />
                    </SettingsCardSection>
                </SettingsCard>
            </>
        );
    }

    renderEmployeeAppSettings() {
        const showEndTimes = this.findSettingByKey('EMPLOYEE_APP_SHOW_END_TIMES');

        return (
            <>
                <ConnectedHelpTooltip
                    index={1}
                    route="employee"
                    subTitle={trans('help.settings.setByDepartment.title')}
                    text={trans('help.settings.setByDepartment.text')}
                    title={trans('help.settings.title')}
                    showMobileInfoWarning
                    className="settings__set-by-department-help-tooltip"
                />
                <SettingsCard
                    title={translate('common.tracks')}
                >
                    <SettingsCardSection
                        title={translate('pages.settings.showEndTimes')}
                        text={translate('pages.settings.showEndTimesIntro')}
                    >
                        <SettingsRadios
                            title={translate('pages.settings.doYouWantToShowEndTimes')}
                            setting={showEndTimes}
                            onChange={this.onSettingChange}
                        />
                    </SettingsCardSection>
                </SettingsCard>
            </>
        );
    }

    renderManagerAppSettings() {
        const abnormalTrackDurationTreshold = this.findSettingByKey('ABNORMAL_TRACK_DURATION_THRESHOLD');
        const abnormalLoaHourDailyAverage = this.findSettingByKey('ABNORMAL_LEAVE_OF_ABSENCE_HOUR_DAILY_AVERAGE_THRESHOLD');
        const abnormalAbsenceHourDailyAverageTreshold = this.findSettingByKey('ABNORMAL_ABSENCE_HOUR_DAILY_AVERAGE_THRESHOLD');
        const costPerHourTempWorker = this.findSettingByKey('COST_PER_HOUR_TEMP_WORKER');
        const costPerHourEmploymentFallback = this.findSettingByKey('COST_PER_HOUR_EMPLOYMENT_FALLBACK');
        const isRemoveEmployeeFromShiftEnabled = this.findSettingByKey('ENABLE_CAO_MESSAGE_REMOVE_EMPLOYEE_FROM_SHIFT');
        const isUnderageEmployeeEnabled = this.findSettingByKey('ENABLE_CAO_MESSAGE_UNDERAGE_EMPLOYEE');
        const isTimeBetweenShiftsEnabled = this.findSettingByKey('ENABLE_CAO_MESSAGE_TIME_BETWEEN_SHIFTS');
        const isTimerOptionsEnabled = this.findSettingByKey('ENABLE_CAO_MESSAGE_TIMER_OPTIONS');

        return (
            <>
                <ConnectedHelpTooltip
                    index={1}
                    route="manager"
                    subTitle={trans('help.settings.setByDepartment.title')}
                    text={trans('help.settings.setByDepartment.text')}
                    title={trans('help.settings.title')}
                    showMobileInfoWarning
                    className="settings__set-by-department-help-tooltip"
                />
                <SettingsCard
                    title={translate('pages.settings.abnormalTimes')}
                >
                    <SettingsCardSection
                        title={translate('pages.settings.abnormalTrackDurationTreshold')}
                        text={translate('pages.settings.abnormalTrackDurationTresholdIntro')}
                    >
                        <SettingsInputTime
                            onChange={this.onSettingChange}
                            option={abnormalTrackDurationTreshold}
                            label={translate('pages.settings.hoursAmount')}
                        />
                    </SettingsCardSection>
                    <ConnectedHelpTooltip
                        index={2}
                        route="manager"
                        subTitle={trans('help.settings.abnormalTimes.title')}
                        text={trans('help.settings.abnormalTimes.text')}
                        title={trans('help.settings.title')}
                        className="settings__abnormal-times-help-tooltip"
                    />
                    <SettingsCardSection
                        title={translate('pages.settings.abnormalLoaHourDailyAverage')}
                        text={translate('pages.settings.abnormalLoaHourDailyAverageIntro')}
                    >
                        <SettingsInputTime
                            onChange={this.onSettingChange}
                            option={abnormalLoaHourDailyAverage}
                            label={translate('pages.settings.hoursAmount')}
                        />
                    </SettingsCardSection>
                    <SettingsCardSection
                        title={translate('pages.settings.abnormalAbsenceHourDailyAverageTreshold')}
                        text={translate('pages.settings.abnormalAbsenceHourDailyAverageTresholdIntro')}
                    >
                        <SettingsInputTime
                            onChange={this.onSettingChange}
                            option={abnormalAbsenceHourDailyAverageTreshold}
                            label={translate('pages.settings.hoursAmount')}
                        />
                    </SettingsCardSection>
                    <SettingsCardSection
                        title={translate('pages.settings.costPerHourTempWorkers')}
                        text={translate('pages.settings.costPerHourTempWorkersIntro')}
                    >
                        <SettingsInputTime
                            onChange={this.onSettingChange}
                            option={costPerHourTempWorker}
                            label={translate('pages.settings.centsAmount')}
                        />
                    </SettingsCardSection>
                    <SettingsCardSection
                        title={translate('pages.settings.costPerHourEmploymentFallback')}
                        text={translate('pages.settings.costPerHourEmploymentFallbackIntro')}
                    >
                        <SettingsInputTime
                            onChange={this.onSettingChange}
                            option={costPerHourEmploymentFallback}
                            label={translate('pages.settings.centsAmount')}
                        />
                    </SettingsCardSection>
                </SettingsCard>
                <SettingsCard
                    title={translate('pages.settings.caoNotifications')}
                >
                    <ConnectedHelpTooltip
                        index={3}
                        route="manager"
                        subTitle={trans('help.settings.caoNotifications.title')}
                        text={trans('help.settings.caoNotifications.text')}
                        title={trans('help.settings.title')}
                        className="settings__cao-notifications-help-tooltip"
                    />
                    <SettingsCardSection
                        title={translate('pages.settings.deleteEmployeeFromShift')}
                        text={translate('pages.settings.deleteEmployeeFromShiftIntro')}
                    >
                        <SettingsRadios
                            title={translate('pages.settings.doYouWantToShowTheseNotifications')}
                            setting={isRemoveEmployeeFromShiftEnabled}
                            onChange={this.onSettingChange}
                        />
                    </SettingsCardSection>
                    <SettingsCardSection
                        title={translate('pages.settings.underAgedEmployees')}
                        text={translate('pages.settings.underAgedEmployeesIntro')}
                    >
                        <SettingsRadios
                            title={translate('pages.settings.doYouWantToShowTheseNotifications')}
                            setting={isUnderageEmployeeEnabled}
                            onChange={this.onSettingChange}
                        />
                    </SettingsCardSection>
                    <SettingsCardSection
                        title={translate('pages.settings.timeBetweenShifts')}
                        text={translate('pages.settings.timeBetweenShiftsIntro')}
                    >
                        <SettingsRadios
                            title={translate('pages.settings.doYouWantToShowTheseNotifications')}
                            setting={isTimeBetweenShiftsEnabled}
                            onChange={this.onSettingChange}
                        />
                    </SettingsCardSection>
                    <SettingsCardSection
                        title={translate('pages.settings.timerOptions')}
                        text={translate('pages.settings.timerOptionsIntro')}
                    >
                        <SettingsRadios
                            title={translate('pages.settings.doYouWantToShowThisOption')}
                            setting={isTimerOptionsEnabled}
                            onChange={this.onSettingChange}
                        />
                    </SettingsCardSection>
                </SettingsCard>
            </>
        );
    }

    renderSettings() {
        const { page } = this.props;

        if (page === 'employee') {
            return this.renderEmployeeAppSettings();
        }

        if (page === 'timer') {
            return this.renderTimerSettings();
        }

        return this.renderManagerAppSettings();
    }

    render() {
        const { modified } = this.state;
        const {
            settings,
            department,
            loading,
            page,
            permissions,
        } = this.props;
        const departmentId = department?.id;
        const hasDepartmentSettings = departmentId && settings.filter(
            setting => setting.departmentId === departmentId,
        ).length !== 0;
        const title = `${translate(`pages.settings.${page}Settings`)} - ${departmentId ? department.name : translate('pages.settings.general')}`;
        const canEdit = checkPermission(permissions, 'edit-all-settings', 'settings-section') && checkPermission(permissions, 'add-new-settings', 'settings-section');
        const canDelete = checkPermission(permissions, 'delete-all-settings', 'settings-section');

        return (
            <>
                <div className="settings-top-bar">
                    <div className="settings-title">{title}</div>
                    {canEdit && (
                        <div className="settings-top-bar-buttons">
                            <Button onClick={this.submit} disabled={loading || !modified} color="orange">{translate('common.save')}</Button>
                            {(hasDepartmentSettings && canDelete) && (
                                <Button onClick={this.reset} disabled={loading} color="orange">{translate('common.reset')}</Button>
                            )}
                        </div>
                    )}
                </div>
                {this.renderSettings()}
                {loading && (
                    <LoadingSpinner
                        isLoading={loading}
                    />
                )}
            </>
        );
    }
}

SettingsSection.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    settings: PropTypes.array.isRequired,
    department: PropTypes.object,
    page: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default SettingsSection;
