import React, { FC, useEffect, useState } from 'react';

import {
    Col,
    CustomInput,
    FormFeedback,
    FormGroup,
    Label,
    Row,
} from 'reactstrap';

import FormDatePicker from '../../../../components/FormDatePicker/FormDatePicker';
import { RadioButtonsList } from '../../../../components/RadioButtonsList/RadioButtonsList';
import {
    addDays,
    addHours,
    compareAsc,
    formatDate,
    isSameDay,
    isWithin24Hours,
} from '../../../../helpers/date';
import { transformEndDateOrStringToFormDate, transformStartDateOrStringToFormDate } from '../../../../helpers/date/transformStartDateOrStringToFormDate';
import legacyParse from '../../../../helpers/legacyDateParser';
import { translate } from '../../../../helpers/translations/translator';
import { generateJustifiedStartAndEndDate } from './index';

import './SetStartEndDateTimeForm.scss';

interface Props {
    startDate: string | null;
    endDate: string | null;
    hasError?: boolean;
    errorText?: string;
    onChange: (startDate: string, endDate: string) => void;
}

const OUTPUT_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
const DATE_FORMAT = 'yyyy-MM-dd';
const DATE_END_FORMAT = "yyyy-MM-dd'T'23:59:59";
const START_DATE = formatDate(new Date(), DATE_FORMAT);
const END_DATE = formatDate(new Date(), DATE_END_FORMAT);

export const SetStartEndDateTimeForm: FC<Props> = ({
    onChange,
    hasError = false,
    errorText,
    ...props
}) => {
    const [initialized, setInitialized] = useState(false);
    const [interacted, setInteracted] = useState(false);
    const [date, setDate] = useState(START_DATE);
    const [startTime, setStartTime] = useState('00:00');
    const [endTime, setEndTime] = useState('00:00');
    const [startDate, setStartDate] = useState(START_DATE);
    const [endDate, setEndDate] = useState(END_DATE);
    const [multipleDays, setMultipleDays] = useState(false);
    const [dateError, setDateError] = useState(false);

    useEffect(() => {
        const { justifiedStartDate, justifiedEndDate } = generateJustifiedStartAndEndDate(
            date,
            startTime,
            endTime,
            startDate,
            endDate,
            multipleDays,
        );
        setDateError(compareAsc(legacyParse(justifiedStartDate), legacyParse(justifiedEndDate)) !== -1);

        if (interacted) {
            onChange(
                formatDate(justifiedStartDate, OUTPUT_FORMAT),
                formatDate(justifiedEndDate, OUTPUT_FORMAT),
            );
        }
    }, [interacted, date, startTime, endTime, startDate, endDate, multipleDays]);

    useEffect(() => {
        if (!initialized && props.startDate && props.endDate) {
            setDate(formatDate(props.startDate, DATE_FORMAT));
            setMultipleDays(!isWithin24Hours(props.startDate, props.endDate));
            setStartTime(formatDate(props.startDate, 'HH:mm'));
            setEndTime(formatDate(props.endDate, 'HH:mm'));
            setStartDate(props.startDate);
            setEndDate(formatDate(addHours(props.endDate, -4), DATE_FORMAT));
            setInitialized(true);
        }
    }, [props.startDate, props.endDate]);

    const toggleMultipleDays = () => {
        if (!multipleDays && (isSameDay(legacyParse(startDate), legacyParse(endDate))
            || compareAsc(legacyParse(startDate), legacyParse(endDate)) === 1)) {
            setEndDate(formatDate(addDays(legacyParse(startDate), 1), DATE_FORMAT));
        }

        setMultipleDays(!multipleDays);
        setInteracted(true);
    };

    const onChangeDate = (value: Date) => {
        setDate(formatDate(value, DATE_FORMAT));
        setInteracted(true);
    };

    const onChangeStartTime = (e: React.FormEvent<HTMLInputElement>) => {
        setStartTime(e.currentTarget.value);
        setInteracted(true);
    };

    const onChangeEndTime = (e: React.FormEvent<HTMLInputElement>) => {
        setEndTime(e.currentTarget.value);
        setInteracted(true);
    };

    const onChangeStartDate = (value: Date) => {
        setStartDate(formatDate(value, DATE_FORMAT));
        setInteracted(true);
    };

    const onChangeEndDate = (value: Date) => {
        setEndDate(formatDate(value, DATE_END_FORMAT));
        setInteracted(true);
    };

    return (
        <>
            <Row>
                <Col>
                    <RadioButtonsList
                        className="set-start-end-date-time-form__multiple-days-radio-list"
                        checked={multipleDays ? 1 : 0}
                        buttons={[
                            { label: translate('pages.absences.singleDay') },
                            { label: translate('pages.absences.multipleDays') },
                        ]}
                        onRadioChange={toggleMultipleDays}
                    />
                </Col>
            </Row>
            { !multipleDays ? (
                <div>
                    <Row>
                        <Col>
                            <FormGroup>
                                <FormGroup>
                                    <Label>{translate('common.date')}</Label>
                                    <FormDatePicker
                                        invalid={hasError}
                                        selected={date}
                                        onChange={onChangeDate}
                                    />
                                </FormGroup>
                            </FormGroup>
                        </Col>
                        <Col />
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <FormGroup>
                                    <Label>{translate('pages.tracks.startTime')}</Label>
                                    <CustomInput
                                        // @ts-ignore
                                        type="time"
                                        className="form-control"
                                        id="start-time"
                                        name="startTime"
                                        value={startTime}
                                        invalid={hasError}
                                        onChange={onChangeStartTime}
                                    />
                                </FormGroup>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <FormGroup>
                                    <Label>{translate('pages.tracks.endTime')}</Label>
                                    <CustomInput
                                        // @ts-ignore
                                        type="time"
                                        className="form-control"
                                        id="end-time"
                                        name="endTime"
                                        value={endTime}
                                        invalid={hasError}
                                        onChange={onChangeEndTime}
                                    />
                                </FormGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div>
                    <Row>
                        <Col>
                            <FormGroup>
                                <FormGroup>
                                    <Label>{translate('common.startDate')}</Label>
                                    <FormDatePicker
                                        selected={formatDate(transformStartDateOrStringToFormDate(new Date(startDate)), DATE_FORMAT)}
                                        invalid={hasError}
                                        onChange={onChangeStartDate}
                                    />
                                </FormGroup>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <FormGroup>
                                    <Label>{translate('common.endDate')}</Label>
                                    <FormDatePicker
                                        selected={formatDate(transformEndDateOrStringToFormDate(new Date(endDate)), DATE_FORMAT)}
                                        onChange={onChangeEndDate}
                                        invalid={dateError || hasError}
                                        feedback={dateError ? translate('pages.management.endDateNeedsToBeLaterThanStartDate') : ''}
                                    />
                                </FormGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            )}
            { (hasError && errorText) && (
                <Row>
                    <Col>
                        <FormFeedback className="set-start-end-date-time-form__error-text">
                            {errorText}
                        </FormFeedback>
                    </Col>
                </Row>
            )}
        </>
    );
};
