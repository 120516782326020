import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { ShiftIndex } from '../../entities/Shift/Shift';
import { ShiftsCalendarAbsence } from '../../entities/ShiftsCalendarAbsence/ShiftsCalendarAbsence';
import { ShiftsCalendarLeaveOfAbsence } from '../../entities/ShiftsCalendarLeaveOfAbsence/ShiftsCalendarLeaveOfAbsence';
import { ShiftsCalendarUnavailableToWorkTimeSlot } from '../../entities/ShiftsCalendarUnavailableToWorkTimeSlot/ShiftsCalendarUnavailableToWorkTimeSlot';
import { shiftSlice } from '../shift/shiftReducer';

export type ShiftsCalendarState = AsyncReduxState<{
    shifts: ShiftIndex[];
    shiftsCalendarAbsences: ShiftsCalendarAbsence[];
    shiftsCalendarLeaveOfAbsences: ShiftsCalendarLeaveOfAbsence[];
    shiftsCalendarUnavailableToWorkTimeSlots: ShiftsCalendarUnavailableToWorkTimeSlot[];
}>;

const initialState: ShiftsCalendarState = {
    ...initialAsyncReduxState,
    shifts: [],
    shiftsCalendarAbsences: [],
    shiftsCalendarLeaveOfAbsences: [],
    shiftsCalendarUnavailableToWorkTimeSlots: [],
};

export const shiftsSlice = createSlice({
    name: 'shiftsCalendarReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ShiftsCalendarState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ShiftsCalendarState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setShifts(state, action: PayloadAction<ShiftIndex[]>): ShiftsCalendarState {
            return {
                ...state,
                shifts: action.payload,
            };
        },
        removeShift(state, action: PayloadAction<string>): ShiftsCalendarState {
            return {
                ...state,
                shifts: state.shifts.filter(shift => shift.id !== action.payload),
            };
        },
        setShiftsCalendarAbsences(state, action: PayloadAction<ShiftsCalendarAbsence[]>): ShiftsCalendarState {
            return {
                ...state,
                shiftsCalendarAbsences: action.payload,
            };
        },
        setShiftsCalendarLeaveOfAbsences(state, action: PayloadAction<ShiftsCalendarLeaveOfAbsence[]>): ShiftsCalendarState {
            return {
                ...state,
                shiftsCalendarLeaveOfAbsences: action.payload,
            };
        },
        setShiftsCalendarUnavailableToWorkTimeSlots(state, action: PayloadAction<ShiftsCalendarUnavailableToWorkTimeSlot[]>): ShiftsCalendarState {
            return {
                ...state,
                shiftsCalendarUnavailableToWorkTimeSlots: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(shiftSlice.actions.setShift, (state, action) => {
            const shifts = [
                ...state.shifts.filter(shift => shift.id !== action.payload.id),
                action.payload,
            ];

            return {
                ...state,
                shifts,
            };
        });
    },
});

export const {
    setIsLoading,
    setError,
    setShifts,
    removeShift,
    setShiftsCalendarAbsences,
    setShiftsCalendarLeaveOfAbsences,
    setShiftsCalendarUnavailableToWorkTimeSlots,
} = shiftsSlice.actions;

export default shiftsSlice.reducer;
