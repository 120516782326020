import { AnyAction, Dispatch } from 'redux';

import { TOAST_TYPE_PASS } from '../../../../constants';
import { EditTrackFormData } from '../../../../entities/Track/Track';
import { patchTrackApiCall } from '../../../../entities/Track/TrackService';
import { transformToEditTrackRequest } from '../../../../entities/Track/TrackTransformers';
import { getIncludes } from '../../../../helpers/api/tracksApi';
import { getOpenTracksInDateRange } from '../../../../helpers/api-ts/track';
import { translate } from '../../../../helpers/translations/translator';
import { PayrollPeriodViewModel, TrackType } from '../../../../models';
import { setAppToast } from '../../../app/appActions';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setOpenTracks,
} from './openTracksReducer';

export const fetchOpenTracks = (payrollPeriod: PayrollPeriodViewModel) => async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const { start, end } = payrollPeriod;

    try {
        const response = await getOpenTracksInDateRange(
            start,
            end.date,
            getIncludes(TrackType.open),
        );

        dispatch(setOpenTracks(response.data));
    } catch (error) {
        console.error('[fetchOpenTracks]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editOpenTrack = (formData: EditTrackFormData, payrollPeriod: PayrollPeriodViewModel) => async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(setIsSuccessful(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const request = transformToEditTrackRequest(formData);

        await patchTrackApiCall(request);
        await dispatch(fetchOpenTracks(payrollPeriod));

        dispatch(setAppToast(translate('pages.tracks.editTrackSuccess'), TOAST_TYPE_PASS));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editOpenTrack]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
