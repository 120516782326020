import React, { FC, useState } from 'react';

import InfoCircle from '../../../../../../components/InfoCircle/InfoCircle';
import ItemsList from '../../../../../../components/ItemsList/ItemsList';
import TableContainer from '../../../../../../components/Table/TableContainer';
import TableFilter from '../../../../../../components/Table/TableFilter';
import { getIconBySatisfaction } from '../../../../../../helpers';
import { formatDate } from '../../../../../../helpers/date';
import { translate } from '../../../../../../helpers/translations/translator';
import { TrackViewModel } from '../../../../../../models';
import { RatingType } from '../../../../../../models/Feedback';

import './SatisfactionTable.scss';

const ALL = 'all';

export interface NotesTableProps {
    tracks: TrackViewModel[];
}

const SatisfactionTable: FC<NotesTableProps> = ({ tracks = [] }) => {
    const [yearState, setYearState] = useState<string | null>(null);
    const [ratingState, setRatingState] = useState<string | null>(null);

    const renderYearFilterButton = () => {
        // @ts-ignore
        const years = [...new Set(
            tracks.reduce((total: any, track) => {
                const yearStart = formatDate(track.billableStart, 'yyyy');
                const yearEnd = track.billableEnd && formatDate(track.billableEnd.date, 'yyyy');
                return [...total, yearStart, yearEnd];
            }, [])
                .filter((track: any) => track),
        )];

        const options = years.map(year => ({
            value: year,
            label: year,
        }));

        return (
            <TableFilter
                placeholder={translate('common.year')}
                id="year-filter"
                options={options}
                onChange={(value: string) => setYearState(value)}
            />
        );
    };

    const renderRatingButton = () => {
        // @ts-ignore
        const ratings = [...new Set(
            tracks
                .map((track) => track.feedback?.rating)
                .filter((rating) => rating),
        )];

        const options = ratings.map(rating => ({
            value: rating,
            label: translate(`pages.tracks.${(rating || 'NEUTRAL').toLowerCase()}`),
        }));

        return (
            <TableFilter
                placeholder={translate('common.satisfaction')}
                id="rating-filter"
                options={options}
                onChange={(value: string) => setRatingState(value)}
            />
        );
    };

    const tableAttributes = [
        {
            label: renderYearFilterButton(),
            customAction: (item: TrackViewModel) => formatDate(item.checkIn, 'dd-MM-yyyy'),
            className: 'satisfaction-table__td-year',
        },
        {
            label: renderRatingButton(),
            customAction: (item: TrackViewModel) => (
                <>
                    <InfoCircle
                        icon={getIconBySatisfaction(item.feedback?.rating)}
                        alt={translate('common.satisfaction')}
                        iconClass="smiley"
                    />
                    <p className="satisfaction-table__td-satisfaction-text">{(item.feedback?.rating ? translate(`pages.tracks.${item.feedback.rating.toLowerCase()}`) : '')}</p>
                </>
            ),
            className: 'satisfaction-table__td-satisfaction',
        },
        {
            label: translate('common.department'),
            customAction: (item: TrackViewModel) => item.department?.name,
            className: 'satisfaction-table__td-department',
        },
        {
            label: translate('common.time'),
            customAction: (item: TrackViewModel) => `${formatDate(item.checkIn, 'HH:mm')} - ${formatDate(item.checkOut.date, 'HH:mm')}`,
            className: 'satisfaction-table__td-time',
        },
        {
            label: translate('common.category'),
            customAction: (item: TrackViewModel) => (item.feedback?.category?.name ? translate(`pages.feedback.${item.feedback.category.name.toLowerCase()}`) : ''),
            className: 'satisfaction-table__td-category',
        },
        {
            label: translate('common.comment'),
            customAction: (item: TrackViewModel) => item.feedback?.remark,
            className: 'satisfaction-table__td-comment',
        },

    ];

    if (yearState) {
        // eslint-disable-next-line no-param-reassign
        tracks = tracks.filter(track => yearState === ALL || formatDate(track.billableStart, 'yyyy') === yearState || formatDate(track.billableEnd.date, 'yyyy') === yearState);
    }

    if (ratingState) {
        // eslint-disable-next-line no-param-reassign
        tracks = tracks.filter(track => ratingState === RatingType.ALL || track.feedback?.rating === ratingState);
    }

    return (
        <ItemsList
            itemsLength={tracks.length}
            emptyMessage={translate('pages.management.emptySatisfaction')}
        >
            <TableContainer
                className="satisfaction-table"
                attributes={tableAttributes}
                data={tracks}
            />
        </ItemsList>
    );
};

export default SatisfactionTable;
