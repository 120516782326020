import React, { FC, useMemo, useState } from 'react';

import classnames from 'classnames';

import { Button } from '../../../../../../@paco/components';
import { EditTimeButton } from '../../../../../../@paco/compositions';
import { ConnectedEditBaseScheduleShiftTimeForm, ConnectedEditShiftConceptTimeForm } from '../../../../../../@paco/connectors';
import { transformToPeriod } from '../../../../../../@paco/entities/Period/PeriodTransformers';
import trans from '../../../../../../@paco/helpers/trans';
import { UnavailableToWorkTimeSlotViewModel } from '../../../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import {
    AbsenceViewModel,
    LeaveOfAbsenceViewModel,
    ShiftDraggable,
    ShiftType,
} from '../../../../../../models';
import RegisteredUsers from '../RegisteredUsers/RegisteredUsers';
import ShiftPlanning from '../ShiftPlanning/ShiftPlanning';
import ShiftPreviouslyPlannedUser from '../ShiftPreviouslyPlannedUser/ShiftPreviouslyPlannedUser';
import ShiftTemporaryWorker from '../ShiftTemporaryWorker/ShiftTemporaryWorker';

// TODO: Remove legacy components and merge Shift.scss into ConceptShift.scss
import '../../../Shifts/Shift.scss';
import './ConceptShift.scss';


interface ConceptShiftProps {
    shift: ShiftDraggable;
    leaveOfAbsences?: LeaveOfAbsenceViewModel[];
    absences?: AbsenceViewModel[];
    unavailableTimeSlots?: UnavailableToWorkTimeSlotViewModel[];
    canEditShifts: boolean;
    canEditShiftConcepts: boolean;
    canEditBaseScheduleShifts: boolean;
    showEmploymentTypeBadge: boolean;
    showEmployees: boolean;
    isHighlighted: boolean;
    onShiftClick?: (shift: ShiftDraggable) => void;
    onShiftStartDrag?: (shift: ShiftDraggable) => void;
    className?: string;
}

const ConceptShift: FC<ConceptShiftProps> = ({
    shift,
    leaveOfAbsences = [],
    absences = [],
    unavailableTimeSlots = [],
    canEditShifts,
    canEditShiftConcepts,
    canEditBaseScheduleShifts,
    showEmploymentTypeBadge,
    showEmployees,
    isHighlighted,
    onShiftClick,
    onShiftStartDrag,
    className = '',
}) => {
    const [showEditTimeForm, setShowEditTimeForm] = useState(false);

    const isShift = shift.type === ShiftType.shift;
    const isConcept = shift.type === ShiftType.concept;
    const isBaseSchedule = shift.type === ShiftType.baseSchedule;
    const isDisabled = isShift || (isConcept && !canEditShiftConcepts) || (isBaseSchedule && !canEditBaseScheduleShifts);

    const classNames = useMemo(() => classnames('calendar-shift concept-shift', {
        [`${className}--is-concept`]: isConcept,
        'concept-shift--is-base-schedule': isBaseSchedule,
        [`calendar-shift--${shift.status}`]: shift.status,
        [`concept-shift--is-${shift.status}-status`]: shift.status,
        [`concept-shift--is-${shift.dragState}`]: shift.dragState,
        'concept-shift--is-highlighted': isHighlighted,
        'concept-shift--is-concept': isConcept,
        'concept-shift--hide-employees': !showEmployees,
    }, [className]), [
        shift,
        className,
        showEmployees,
        canEditShifts,
        canEditShiftConcepts,
    ]);

    const shiftPlanningClassName = 'concept-shift__shift-planning';
    const employmentTypeClassName = 'concept-shift__employment-type';

    const { shiftPlannings, temporaryWorkers, previouslyPlannedUsers } = shift;

    const period = useMemo(() => transformToPeriod(shift.start, shift.end.transformToUpToButExcludingDate().date), [shift]);
    const plannedShiftPlannings = useMemo(() => shiftPlannings
        .filter(shiftPlanning => shiftPlanning.planned), [shift]);
    const unplannedShiftPlannings = useMemo(() => shiftPlannings
        .filter(shiftPlanning => !shiftPlanning.planned), [shiftPlannings]);

    const handleShiftClick = (): void => {
        if (!isDisabled && onShiftClick) {
            onShiftClick(shift);
        }
    };

    const handleDragStart = (): void => {
        if (!isDisabled && onShiftStartDrag) {
            onShiftStartDrag(shift);
        }
    };

    const handleEditShiftConceptTimeFormClose = (): void => {
        setShowEditTimeForm(false);
    };

    const handleTimeButtonClick = (): void => {
        setShowEditTimeForm(true);
    };

    return (
        <div
            id={`concept-shift-${shift.id}${shift.dragState === 'preview' ? '-preview' : ''}`}
            draggable={!isDisabled && !!onShiftStartDrag}
            onDragStart={handleDragStart}
            className={classNames}
        >
            <div className="concept-shift__inner">
                <div className="concept-shift__time-and-people-count calendar-shift-top">
                    {showEditTimeForm && isConcept && (
                        <ConnectedEditShiftConceptTimeForm
                            period={period}
                            shiftConceptId={shift.id}
                            onClose={handleEditShiftConceptTimeFormClose}
                            className="paco-calendar-shift__edit-time-form"
                        />
                    )}
                    {showEditTimeForm && isBaseSchedule && (
                        <ConnectedEditBaseScheduleShiftTimeForm
                            period={period}
                            baseScheduleShiftId={shift.id}
                            onClose={handleEditShiftConceptTimeFormClose}
                            className="paco-calendar-shift__edit-time-form"
                        />
                    )}
                    {!showEditTimeForm && (
                        <EditTimeButton
                            isDisabled={isDisabled}
                            period={period}
                            onClick={handleTimeButtonClick}
                            className="paco-calendar-shift__edit-time-button"
                        />
                    )}
                    {`${plannedShiftPlannings.length + temporaryWorkers.length}/${shift.peopleLimit}`}
                </div>
                {shift.department && (
                    <div className="concept-shift__department">
                        {shift.department.group && (
                            <span className="concept-shift__department-group">
                                {`${shift.department.group.name} - `}
                            </span>
                        )}
                        {shift.department.name}
                    </div>
                )}
                { !!(plannedShiftPlannings.length || temporaryWorkers.length) && (
                    <ul className="calendar-shift-employees concept-shift__employees-list">
                        {plannedShiftPlannings
                            .map((shiftPlanning) => (
                                <ShiftPlanning
                                    key={shiftPlanning.id}
                                    className={shiftPlanningClassName}
                                    employmentTypeClassName={employmentTypeClassName}
                                    showEmploymentTypeBadge={showEmploymentTypeBadge}
                                    shift={shift}
                                    shiftPlanning={shiftPlanning}
                                    absences={absences}
                                    leaveOfAbsences={leaveOfAbsences}
                                    unavailableTimeSlots={unavailableTimeSlots}
                                    iconClassName="paco-calendar-shift__employee-icon"
                                />
                            ))}
                        {temporaryWorkers
                            .map((temporaryWorker) => (
                                <ShiftTemporaryWorker
                                    key={temporaryWorker.id}
                                    className={shiftPlanningClassName}
                                    employmentTypeClassName={employmentTypeClassName}
                                    showEmploymentTypeBadge={showEmploymentTypeBadge}
                                    shift={shift}
                                    temporaryWorker={temporaryWorker}
                                    iconClassName="paco-calendar-shift__employee-icon"
                                />
                            ))}
                    </ul>
                )}
                { !!previouslyPlannedUsers.length && (
                    <ul className="calendar-shift-employees calendar-shift-employees--previously-planned concept-shift__employees-list">
                        {previouslyPlannedUsers
                            .map((user) => (
                                <ShiftPreviouslyPlannedUser
                                    key={user.id}
                                    className={shiftPlanningClassName}
                                    employmentTypeClassName={employmentTypeClassName}
                                    employmentTypeSlug={user.employmentType?.slug}
                                    showEmploymentTypeBadge={showEmploymentTypeBadge}
                                    user={user}
                                />
                            ))}
                    </ul>
                )}
            </div>
            { !!unplannedShiftPlannings.length && (
                <RegisteredUsers
                    className="concept-shift__registered-users"
                    length={unplannedShiftPlannings.length}
                />
            )}
            <Button
                hideLabel
                disabled={isDisabled}
                text={trans('common.shift')}
                onClick={handleShiftClick}
                className="paco-calendar-shift__button"
            />
        </div>
    );
};

export default ConceptShift;
