import {
    TRACK_ADD_TRACK,
    TRACK_REMOVE,
    TRACK_REMOVE_REQUEST,
    TRACK_REMOVE_SUCCESS,
    TRACK_UPDATE_FAILURE,
    TRACK_UPDATE_SUCCESS,
    TRACKS_EXPORT_CSV_FAILURE,
    TRACKS_EXPORT_CSV_REQUEST,
    TRACKS_EXPORT_CSV_SUCCESS,
    TRACKS_FAILURE,
    TRACKS_GET_USER_TRACKS_REQUEST,
    TRACKS_GET_USER_TRACKS_SUCCESS,
    TRACKS_REQUEST,
    TRACKS_SUCCESS,
    TRACKS_SYNC_TVT_FAILURE,
    TRACKS_SYNC_TVT_REQUEST,
    TRACKS_SYNC_TVT_SUCCESS,
} from '../actionTypes';

const initialState = {
    tracks: [],
    userTracksOnDate: [],
    trackType: null,
    loading: false,
    exportCSVRequest: false,
    syncLoketRequest: false,
    errors: [],
};

const tracksReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRACKS_REQUEST:
            return { ...state, loading: action.loading || true, trackType: action.trackType };
        case TRACK_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                tracks: state.toJS().tracks.map((track) => {
                    if (track.id === action.response.data.id) {
                        return action.track;
                    }
                    return track;
                }),
            };
        case TRACK_REMOVE_REQUEST:
            return {
                ...state, loading: true,
            };
        case TRACK_REMOVE:
            return {
                ...state,
                tracks: state.tracks.filter((track) => {
                    if (track.id === action.response.data.id) {
                        return false;
                    }
                    return true;
                }),
            };
        case TRACK_REMOVE_SUCCESS:
            return {
                ...state, loading: false,
            };
        case TRACK_ADD_TRACK:
        case TRACK_UPDATE_FAILURE:
            return { ...state, loading: true };
        case TRACKS_SUCCESS:
            return {
                ...state, loading: false, tracks: action.tracks,
            };
        case TRACKS_FAILURE:
            return {
                ...state, loading: false, errors: [action.error, ...state.errors],
            };
        case TRACKS_EXPORT_CSV_REQUEST:
            return { ...state, exportCSVRequest: true };
        case TRACKS_EXPORT_CSV_SUCCESS:
        case TRACKS_EXPORT_CSV_FAILURE:
            return { ...state, exportCSVRequest: false };
        case TRACKS_GET_USER_TRACKS_REQUEST:
            return { ...state, userTracksOnDate: [] };
        case TRACKS_GET_USER_TRACKS_SUCCESS:
            return { ...state, userTracksOnDate: action.tracks };
        case TRACKS_SYNC_TVT_REQUEST:
            return { ...state, syncLoketRequest: true };
        case TRACKS_SYNC_TVT_SUCCESS:
        case TRACKS_SYNC_TVT_FAILURE:
            return { ...state, syncLoketRequest: false };
        default:
            return state;
    }
};

export default tracksReducer;
