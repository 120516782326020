import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Checkbox, ErrorLabel, InputLabel } from '../../../components';
import { FormOption } from '../../../types';

import './CheckboxList.scss';

interface CheckboxListProps extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
    maySelectOther?: boolean;
    hideLabel?: boolean;
    label: string;
    value: string[];
    options: FormOption[];
    error?: string;
    onChange: (values: string[]) => void;
    className?: string;
}

const CheckboxList: FC<CheckboxListProps> = ({
    hideLabel = false,
    label,
    options,
    value,
    required = false,
    error = '',
    onChange,
    className = '',
    ...checkboxProps
}): ReactElement => {
    const handleSelectCheckbox = (option: FormOption, isChecked: boolean): void => {
        const newCheckedOptions = isChecked
            ? [...value, option.value]
            : [...value].filter(selected => selected !== option.value);

        onChange(newCheckedOptions);
    };

    const checkboxListClassNames = classNames('checkbox-list__options-wrapper', {
        'checkbox-list__options-wrapper--has-error': !!error,
    });

    return (
        <div className={`checkbox-list ${className}`}>
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="checkbox-list__label"
                />
            )}

            <div className={checkboxListClassNames}>
                {options.map(option => {
                    const handleSelect = (isChecked: boolean): void => handleSelectCheckbox(option, isChecked);

                    return (
                        <Checkbox
                            {...checkboxProps}
                            key={option.value}
                            label={option.label}
                            checked={value.includes(option.value)}
                            onChange={handleSelect}
                            className="checkbox-list__option"
                        />
                    );
                })}
            </div>

            {error && (
                <ErrorLabel
                    text={error}
                    className="checkbox-list__error-label"
                />
            )}
        </div>
    );
};

export default CheckboxList;
