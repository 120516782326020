import { FC, ReactElement } from 'react';

import { InputLabel } from '../../../components';
import MultiSelect from '../../../components/@inputs/MultiSelect/MultiSelect';
import trans from '../../../helpers/trans';
import { SelectOption, WorkdayType } from '../../../types';

import './WorkdaysSelectInput.scss';

const generateWorkdayOptions = (): SelectOption[] => {
    const workdayArray = Object.values(WorkdayType);

    return workdayArray.map(workday => ({ label: trans(`types.workdays.${workday}`), value: workday })) as SelectOption<WorkdayType>[];
};

const workdayOptions = generateWorkdayOptions();

interface WorkdaySelectInputProps {
    label?: string;
    selectedWorkdays: SelectOption<WorkdayType>[];
    onChange: (option: SelectOption<WorkdayType>[]) => void;
}

const WorkdaysSelectInput: FC<WorkdaySelectInputProps> = ({
    label = trans('compositions.workdaysSelectInput.standardDays'),
    selectedWorkdays,
    onChange,
}): ReactElement => {
    const onWorkdayStateChange = (workdayValues: SelectOption[]): void => {
        onChange(workdayValues as SelectOption<WorkdayType>[]);
    };

    return (
        <label
            aria-label={label}
            className="workdays-select-input"
        >
            <InputLabel text={label} />
            <MultiSelect
                isLoading={false}
                closeMenuOnSelect={false}
                noOptionsMessage={trans('compositions.workdaysSelectInput.noWorkdaysFound')}
                value={selectedWorkdays}
                options={workdayOptions}
                onChange={onWorkdayStateChange}
            />
        </label>
    );
};

export default WorkdaysSelectInput;
