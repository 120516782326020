import { FC, useEffect, useState } from 'react';

import { useToggle } from 'react-use';

import { ModalContent, PacoModal } from '../../compositions';
import CompletePeriodIncompleteUsersContainer from '../../containers/CompletePeriodIncompleteUsersContainer/CompletePeriodIncompleteUsersContainer';
import { DivergentPostEmploymentHourCheckedFormData } from '../../entities/DivergentPostEmploymentHour/DivergentPostEmploymentHour';
import { DivergentPreEmploymentHourCheckedFormData } from '../../entities/DivergentPreEmploymentHour/DivergentPreEmploymentHour';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';
import { UserWithEmployment } from '../../entities/User/User';
import trans from '../../helpers/trans';
import { setDivergentPostEmploymentHoursCheck } from '../../redux/divergentPostEmploymentHour/divergentPostEmploymentHourActions';
import { setDivergentPreEmploymentHoursCheck } from '../../redux/divergentPreEmploymentHour/divergentPreEmploymentHourActions';
import { fetchEmploymentTypes } from '../../redux/employmentTypes/employmentTypesActions';
import { fetchExperiences } from '../../redux/experiences/experiencesActions';
import { fetchRoles } from '../../redux/roles/rolesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { getFullUser } from '../../redux/user/userActions';
import { fetchUsersWithDivergentEmploymentHours } from '../../redux/usersWithDivergentEmploymentHours/usersWithDivergentEmploymentHoursActions';
import { ConnectedEditUserForm } from '../index';

interface ConnectedCompletePeriodIncompleteUsersProps {
    activePeriod?: PayrollPeriod,
    className?: string;
}

const ConnectedCompletePeriodIncompleteUsers: FC<ConnectedCompletePeriodIncompleteUsersProps> = ({ activePeriod, className = '' }) => {
    const dispatch = useTypedDispatch();

    const { isLoading: isCompletePeriodsLoading, usersWithDivergentEmploymentHours } = useTypedSelector(state => state.usersWithDivergentEmploymentHoursReducer);
    const { isLoading: isUserLoading, isFullUserLoading, fullUser } = useTypedSelector(state => state.pacoUserReducer);
    const { isLoading: isDivergentPostEmploymentHoursLoading } = useTypedSelector(state => state.divergentPostEmploymentHourReducer);
    const { isLoading: isDivergentPreEmploymentHoursLoading } = useTypedSelector(state => state.divergentPreEmploymentHourReducer);
    const { user: authenticatedUser } = useTypedSelector(state => state.authenticatedUserReducer);
    const { employmentTypes } = useTypedSelector(state => state.employmentTypesReducer);
    const { experiences } = useTypedSelector(state => state.experiencesReducer);
    const { roles } = useTypedSelector(state => state.rolesReducer);

    const isLoading = [
        isCompletePeriodsLoading,
        isUserLoading,
        isFullUserLoading,
        isDivergentPostEmploymentHoursLoading,
        isDivergentPreEmploymentHoursLoading,
    ].some(Boolean);

    const [activeUserId, setActiveUserId] = useState<string>();
    const [editUserFormIsOpen, toggleEditUserFormIsOpen] = useToggle(false);

    useEffect((): void => {
        if (!experiences.length) {
            dispatch(fetchExperiences());
        }

        if (!roles.length) {
            dispatch(fetchRoles());
        }

        if (!employmentTypes.length) {
            dispatch(fetchEmploymentTypes());
        }
    }, []);

    useEffect((): void => {
        if (fullUser && fullUser.id === activeUserId) {
            toggleEditUserFormIsOpen(true);
        }
    }, [fullUser]);

    const handleEditUserClick = (user: UserWithEmployment): void => {
        setActiveUserId(user.id);
        dispatch(getFullUser(user.id));
    };

    const handleAfterEditUserSuccess = (): void => {
        if (activePeriod) {
            dispatch(fetchUsersWithDivergentEmploymentHours(activePeriod));
        }
    };

    const handleCheckUserClick = (user: UserWithEmployment, isChecked: boolean): void => {
        if (user.employment && user.employment.divergentPostEmploymentHour) {
            const divergentPostEmploymentHourFormData: DivergentPostEmploymentHourCheckedFormData = {
                isChecked,
                divergentPostEmploymentHoursId: user.employment.divergentPostEmploymentHour.id,
            };

            dispatch(setDivergentPostEmploymentHoursCheck(divergentPostEmploymentHourFormData));
        }

        if (user.employment && user.employment.divergentPreEmploymentHour) {
            const divergentPreEmploymentHourFormData: DivergentPreEmploymentHourCheckedFormData = {
                isChecked,
                divergentPreEmploymentHoursId: user.employment.divergentPreEmploymentHour.id,
            };

            dispatch(setDivergentPreEmploymentHoursCheck(divergentPreEmploymentHourFormData));
        }
    };

    return (
        <>
            <CompletePeriodIncompleteUsersContainer
                isLoading={isLoading}
                activePeriod={activePeriod}
                usersWithDivergentEmploymentHours={usersWithDivergentEmploymentHours}
                onEditUserClick={handleEditUserClick}
                onCheckUserClick={handleCheckUserClick}
                className={className}
            />
            {(editUserFormIsOpen && fullUser) && (
                <PacoModal>
                    <ModalContent title={trans('common.editUser')}>
                        <ConnectedEditUserForm
                            experiences={experiences}
                            roles={roles.filter(role => role.assignable)}
                            employmentTypes={employmentTypes}
                            fullUser={fullUser}
                            currentUserId={authenticatedUser?.id}
                            afterEditUserSuccess={handleAfterEditUserSuccess}
                            onClose={() => toggleEditUserFormIsOpen()}
                        />
                    </ModalContent>
                </PacoModal>
            )}
        </>
    );
};

export default ConnectedCompletePeriodIncompleteUsers;
