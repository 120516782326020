import {
    SHIFTS_SAGA_ADD_SPECIAL_DAY,
    SHIFTS_SAGA_DELETE_SPECIAL_DAY,
    SHIFTS_SAGA_EDIT_SPECIAL_DAY,
    SHIFTS_SAGA_GET_AVAILABILITY,
    SHIFTS_SAGA_GET_SPECIAL_DAY_TYPES,
    SHIFTS_SAGA_GET_SPECIAL_DAYS,
} from '../actionTypes';

export const getSpecialDays = (year) => ({ type: SHIFTS_SAGA_GET_SPECIAL_DAYS, year });
export const getSpecialDayTypes = () => ({ type: SHIFTS_SAGA_GET_SPECIAL_DAY_TYPES });
export const addSpecialDay = (data, year) => ({ type: SHIFTS_SAGA_ADD_SPECIAL_DAY, data, year });
export const editSpecialDay = (data, year) => ({ type: SHIFTS_SAGA_EDIT_SPECIAL_DAY, data, year });
export const deleteSpecialDay = (id, year) => ({ type: SHIFTS_SAGA_DELETE_SPECIAL_DAY, id, year });
export const getAvailability = () => ({ type: SHIFTS_SAGA_GET_AVAILABILITY });

