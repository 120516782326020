import { FC, useEffect } from 'react';

import ErrorPage from '../../../../pages/ErrorPage';
import { ManageDistrictDivisionTemplateGroupsOrderForm } from '../../../containers';
import { DistrictDivisionGroupTemplate } from '../../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import trans from '../../../helpers/trans';
import { editDistrictDivisionTemplateGroupsOrder } from '../../../redux/districtDivisionTemplate/districtDivisionTemplateActions';
import { setIsSuccessful } from '../../../redux/districtDivisionTemplate/districtDivisionTemplateReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedAddDistrictDivisionGroupTemplateFormProps {
    onClose: () => void;
}

const ConnectedManageDistrictDivisionTemplateGroupsOrderForm: FC<ConnectedAddDistrictDivisionGroupTemplateFormProps> = ({
    onClose,
}) => {
    const dispatch = useTypedDispatch();
    const { isUpdating, isSuccessful, districtDivisionTemplate } = useTypedSelector(state => state.districtDivisionTemplateReducer);

    const handleSubmit = (districtDivisionGroupTemplates: DistrictDivisionGroupTemplate[]): void => {
        if (districtDivisionTemplate) {
            dispatch(editDistrictDivisionTemplateGroupsOrder(districtDivisionGroupTemplates, districtDivisionTemplate));
        }
    };

    useEffect((): void => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.manageDistrictDivisionTemplateGroupsOrderForm.isSuccessful'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isSuccessful]);

    if (!districtDivisionTemplate) {
        return (
            <ErrorPage errorCode={404} />
        );
    }

    return (
        <ManageDistrictDivisionTemplateGroupsOrderForm
            isLoading={isUpdating}
            districtDivisionGroupTemplates={districtDivisionTemplate.districtDivisionGroupTemplates}
            onCancel={onClose}
            onSubmit={handleSubmit}
        />
    );
};

export default ConnectedManageDistrictDivisionTemplateGroupsOrderForm;
