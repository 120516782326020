import React, { FC } from 'react';

import { ConnectedHelpTooltip } from '../../../../@paco/connectors';
import trans from '../../../../@paco/helpers/trans';
import { TimeModeType } from '../../../../@paco/types';
import FixedCalendarHeader from '../../../../components/FixedCalendarHeader/FixedCalendarHeader';
import { getEndOfWorkDay, isBefore } from '../../../../helpers/date';
import { ShiftDraggable } from '../../../../models';
import { ConceptDayProps } from './models/Day';
import Day from './subcomponents/Day/Day';

import './ConceptDays.scss';

interface ConceptDays extends ConceptDayProps {
    isDragging?: boolean;
    selectedDays: Date[];
    shifts: ShiftDraggable[];
    timeMode?: TimeModeType;
}

const ConceptDays: FC<ConceptDays> = ({
    selectedDays,
    shifts,
    timeMode,
    isMobile,
    isDragging,
    ...props
}) => (
    <div
        id="concept-days"
        className="concept-days"
    >
        {selectedDays.map((day) => {
            const isOutOfDragRange = isDragging && isBefore(getEndOfWorkDay(day), new Date());

            return (
                <Day
                    isMobile={isMobile}
                    showHeader={isMobile}
                    key={day.getTime()}
                    day={day}
                    shifts={shifts}
                    isOutOfDragRange={isOutOfDragRange}
                    {...props}
                />
            );
        })}
        {(timeMode && isMobile) && (
            <FixedCalendarHeader mode={timeMode} days={selectedDays} />
        )}
        <ConnectedHelpTooltip
            index={3}
            route="calendar"
            subTitle={trans('help.shiftsCalendar.editShift.title')}
            text={trans('help.shiftsCalendar.editShift.text')}
            title={trans('help.shiftsCalendar.title')}
            className="calendar-days__edit-shift-help-tooltip"
        />
    </div>
);

export default ConceptDays;
