import { FC, ReactElement, useEffect } from 'react';

import { KnowledgeBaseContainer } from '../../containers';
import trans from '../../helpers/trans';
import { downloadDropboxFile, fetchDropboxEntities } from '../../redux/dropbox/dropboxActions';
import {
    DropboxResultType,
    setActiveDirectoryId,
    setActiveResultType,
    setIsDownloadFailed,
} from '../../redux/dropbox/dropboxReducer';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { setToast } from '../../redux/toasts/toastsReducer';
import { ToastType } from '../../types';

const ConnectedKnowledgeBase: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const {
        activeDirectoryId,
        entities,
        folders,
        activeResultType,
        searchFiles,
        isLoading,
        isDownloadFailed,
        isFoldersLoading,
        isSearchLoading,
    } = useTypedSelector(state => state.dropboxReducer);

    const handleBreadcrumbClick = (id?: string): void => {
        dispatch(setActiveResultType(DropboxResultType.folder));
        dispatch(setActiveDirectoryId(id));
    };

    const handleDropboxFolderClick = (dropboxFolderId: string): void => {
        dispatch(setActiveResultType(DropboxResultType.folder));
        dispatch(setActiveDirectoryId(dropboxFolderId));
    };

    const handleViewDropboxFileClick = (dropboxFileId: string): void => {
        dispatch(downloadDropboxFile(dropboxFileId));
    };

    useEffect((): void => {
        dispatch(fetchDropboxEntities(activeDirectoryId));
    }, [activeDirectoryId]);

    useEffect((): void => {
        if (isDownloadFailed) {
            dispatch(setToast({
                text: trans('errors.unknownError'),
                type: ToastType.fail,
            }));
            setIsDownloadFailed(false);
        }
    }, [isDownloadFailed]);

    return (
        <KnowledgeBaseContainer
            isLoading={isLoading || isFoldersLoading || isSearchLoading}
            activeDirectoryPath={activeDirectoryId}
            dropboxEntities={activeResultType === DropboxResultType.search ? searchFiles : entities}
            dropboxFolders={folders}
            resultType={activeResultType}
            onBreadcrumbClick={handleBreadcrumbClick}
            onFolderClick={handleDropboxFolderClick}
            onViewFileClick={handleViewDropboxFileClick}
        />
    );
};

export default ConnectedKnowledgeBase;
