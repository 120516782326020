import { FC, ReactElement } from 'react';

import { TableCell, TableCellSkeleton, TableRow } from '../../../../components';
import { SkeletonTable } from '../../../../compositions';

import './DistrictDivisionTemplatesTableSkeleton.scss';

interface DistrictDivisionTemplatesTableSkeletonProps {
    tableHeader: string[];
    className?: string;
}

const DistrictDivisionTemplatesTableSkeleton: FC<DistrictDivisionTemplatesTableSkeletonProps> = ({
    tableHeader,
    className = '',
}): ReactElement => (
    <SkeletonTable
        tableHeaders={tableHeader}
        className={`district-division-templates-table-skeleton ${className}`}
    >
        <TableRow>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell className="district-division-templates-table-skeleton__cell">
                <TableCellSkeleton size="medium" />
                <TableCellSkeleton size="medium" />
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell>
                <TableCellSkeleton size="large" />
            </TableCell>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell className="district-division-templates-table-skeleton__cell">
                <TableCellSkeleton size="medium" />
                <TableCellSkeleton size="medium" />
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell className="district-division-templates-table-skeleton__cell">
                <TableCellSkeleton size="medium" />
                <TableCellSkeleton size="medium" />
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell>
                <TableCellSkeleton size="medium" />
            </TableCell>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell className="district-division-templates-table-skeleton__cell">
                <TableCellSkeleton size="medium" />
                <TableCellSkeleton size="medium" />
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell>
                <TableCellSkeleton size="small" />
            </TableCell>
            <TableCell className="district-division-templates-table-skeleton__cell">
                <TableCellSkeleton size="medium" />
                <TableCellSkeleton size="medium" />
            </TableCell>
        </TableRow>
    </SkeletonTable>
);

export default DistrictDivisionTemplatesTableSkeleton;
