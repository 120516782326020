import { LEAVE_OF_ABSENCES_TYPE_APPROVED, LEAVE_OF_ABSENCES_TYPE_OPEN } from '../../constants';
import { getNotAvailablePreferences } from '../../helpers';
import {
    addDays,
    areIntervalsOverlapping,
    formatDate,
    max,
} from '../../helpers/date';
import {
    Absence,
    BasicUserWithAbsenceData,
    LeaveOfAbsence,
    MappedAbsenceData,
    PreferToWorkTimeSlot,
    Shift,
    transformBasicUserToBasicUserWithAbsenceData,
    transformUserToUserWithAbsenceData,
    UnavailableToWorkTimeSlot,
    UserWithAbsenceData,
} from '../../models';

function getJustifiedAbsenceDate(date: string | Date): string {
    const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
    if (formatDate(date, 'HH:mm') === '00:00') {
        return formatDate(date, 'yyyy-MM-dd 04:00:00');
    }

    return formatDate(date, dateTimeFormat);
}

function compareAbsenceAndShiftDate(
    absenceStart: string | Date,
    absenceEnd: string | Date,
    shiftStart: string,
    shiftEnd: string,
): boolean {
    if (!absenceStart || !absenceEnd || !shiftStart || !shiftEnd) {
        return false;
    }

    const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
    const loaStart = formatDate(getJustifiedAbsenceDate(absenceStart), dateTimeFormat);
    const loaEnd = formatDate(getJustifiedAbsenceDate(absenceEnd), dateTimeFormat);
    const planningStart = formatDate(shiftStart, dateTimeFormat);
    const planningEnd = formatDate(shiftEnd, dateTimeFormat);
    return areIntervalsOverlapping(
        {
            start: loaStart,
            end: loaEnd,
        },
        {
            start: planningStart,
            end: planningEnd,
        },
    );
}

function generateAbsenceEndDate(start: string, now: Date | string): Date {
    return addDays(max([start, now]), 3);
}

export function convertShiftPlanningsToUsers(
    shift: Shift,
    absenceData: MappedAbsenceData[],
    unavailableTimeSlotData: UnavailableToWorkTimeSlot[],
): {
        users: BasicUserWithAbsenceData[],
        registeredUsers: BasicUserWithAbsenceData[],
        plannedUsers: BasicUserWithAbsenceData[]
    } {
    const { shiftPlannings } = shift;
    const mappedShiftPlannings = shiftPlannings
        .map(shiftPlanning => ({
            ...shiftPlanning.user,
            shiftPlanning,
        }));

    const users: BasicUserWithAbsenceData[] = mappedShiftPlannings
        .map(user => {
            const absences = (absenceData || [])
                .find(absence => absence.userId === user.id) as MappedAbsenceData;
            const unavailableTimeSlots = getNotAvailablePreferences(
                (unavailableTimeSlotData || []).filter(timeSlot => timeSlot.user.id === user.id),
                shift.start,
                shift.end,
            );

            return transformBasicUserToBasicUserWithAbsenceData(
                user,
                user.shiftPlanning,
                absences.leaveOfAbsenceRequest,
                absences.leaveOfAbsence,
                absences.absence,
                unavailableTimeSlots[0],
            );
        });

    const registeredUsers = users.filter(user => !user?.shiftPlanning?.planned);
    const plannedUsers = users.filter(user => user?.shiftPlanning?.planned);
    return { users, registeredUsers, plannedUsers };
}

export function mapAbsenceDataToUsers(
    users: string[],
    start: string,
    end: string,
    now: Date | string,
    leaveOfAbsences: LeaveOfAbsence[] = [],
    absences: Absence[] = [],
): MappedAbsenceData[] {
    return users.map((user: string) => {
        // See if a shift of user are overlapping with leaveOfAbsencesRequests and absences
        const loaMatches = leaveOfAbsences
            .filter(loa => loa?.user?.id === user)
            .filter(loa => compareAbsenceAndShiftDate(loa.start, loa.end, start, end));

        const loaRequestMatch = loaMatches
            .find(loa => loa.status === LEAVE_OF_ABSENCES_TYPE_OPEN) || null;

        const loaApprovedMatch = loaMatches
            .find(loa => loa.status === LEAVE_OF_ABSENCES_TYPE_APPROVED) || null;

        const absenceMatch = absences
            .filter(absence => absence?.user?.id === user)
            .find((absence: Absence) => compareAbsenceAndShiftDate(
                absence.start,
                absence.end || generateAbsenceEndDate(absence.start, now),
                start,
                end,
            )) || null;

        return {
            id: user,
            userId: user,
            shiftPlanning: null,
            leaveOfAbsenceRequest: loaRequestMatch,
            leaveOfAbsence: loaApprovedMatch,
            absence: absenceMatch,
        };
    });
}

export function mapAbsencesDataToEmployees(
    employees: any[],
    leaveOfAbsences: LeaveOfAbsence[],
    absences: Absence[],
    start: string,
    end: string,
    now = new Date(),
): UserWithAbsenceData[] {
    const userIds = employees.map(employee => employee.id);
    const absenceData = mapAbsenceDataToUsers(userIds, start, end, now, leaveOfAbsences, absences);

    const mappedEmployees = employees.map((user) => {
        const data = absenceData.find(a => a.userId === user.id) as MappedAbsenceData;

        return transformUserToUserWithAbsenceData(
            user,
            user.shiftPlanning,
            user.employmentType,
            data.leaveOfAbsenceRequest,
            data.leaveOfAbsence,
            data.absence,
        );
    });

    return [...mappedEmployees];
}

export function mapPlanningPrefDataToEmployees(
    employees: UserWithAbsenceData[],
    preferToWorkTimeSlots: PreferToWorkTimeSlot[],
    unavailableWorkTimeSlots: UnavailableToWorkTimeSlot[],
): UserWithAbsenceData[] {
    const mappedEmployees = employees.map((user) => {
        const preferToWorkTimeSlotMatches = preferToWorkTimeSlots
            .filter(a => a.user.id === user.userId);
        const unavailableWorkTimeSlotMatches = unavailableWorkTimeSlots
            .filter(a => a.user.id === user.userId);

        return {
            ...user,
            preferToWorkTimeSlots: preferToWorkTimeSlotMatches,
            unavailableWorkTimeSlots: unavailableWorkTimeSlotMatches,
        };
    });

    return [...mappedEmployees];
}
