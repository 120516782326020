import React, { FC, ReactElement } from 'react';

import Icon from '../../../style/Icon/Icon';

import './TableActionButtons.scss';

interface TableActionButtonsProps {
    onEditButtonClick?: () => void;
    onDeleteButtonClick?: () => void;
    className?: string;
}

const TableActionButtons: FC<TableActionButtonsProps> = ({
    onEditButtonClick,
    onDeleteButtonClick,
    className = '',
}): ReactElement => {
    const handleEditButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (onEditButtonClick) onEditButtonClick();
    };
    const handleDeleteButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (onDeleteButtonClick) onDeleteButtonClick();
    };

    return (
        <div className={`table-action-buttons ${className}`}>
            {onEditButtonClick && (
                <button type="button" onClick={handleEditButtonClick} className="table-action-buttons__button">
                    <Icon color="light-blue" kind="edit" />
                </button>
            )}
            {onDeleteButtonClick && (
                <button type="button" onClick={handleDeleteButtonClick} className="table-action-buttons__button">
                    <Icon color="light-blue" kind="trash" />
                </button>
            )}
        </div>
    );
};

export default TableActionButtons;
