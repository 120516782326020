import React from 'react';

import PropTypes from 'prop-types';

import { compareAsc, compareDesc, formatDate } from '../../../../helpers/date';
import { transformEndDateOrStringToFormDate, transformStartDateOrStringToFormDate } from '../../../../helpers/date/transformStartDateOrStringToFormDate';
import AbsencesList from '../Absence/AbsencesList';
import DayContent from './DayContent';
import DayHeader from './DayHeader';

function filterAbsences(absences, day) {
    const dayStart = formatDate(new Date(day), 'yyyy-MM-dd');
    const dayEnd = formatDate(new Date(day), 'yyyy-MM-dd');

    return absences.filter((absence) => {
        const absenceStart = formatDate(transformStartDateOrStringToFormDate(absence.start), 'yyyy-MM-dd');
        const absenceEnd = formatDate(transformEndDateOrStringToFormDate(absence.end), 'yyyy-MM-dd');
        return compareAsc(dayStart, absenceStart) !== -1 && compareDesc(dayEnd, absenceEnd) !== -1;
    });
}

function getHeader(day) {
    return formatDate(new Date(day), 'EEEEEE');
}

const Day = (props) => {
    const {
        day, absences, showHeader, index, outOfRange, showDate, filter,
    } = props;
    const filteredAbsences = filterAbsences(absences, day);
    const header = getHeader(day);

    return (
        <div className={`day ${outOfRange ? 'day--out-of-range' : ''}`} style={{ zIndex: (40 - index) }}>
            { showHeader && <DayHeader showHeader={showDate} day={header} /> }
            <DayContent day={day} showHeader={showDate}>
                <AbsencesList
                    {...props}
                    filter={[...filter, 'name']}
                    dayIndex={index}
                    absences={filteredAbsences}
                />
            </DayContent>
        </div>
    );
};

Day.propTypes = {
    day: PropTypes.any.isRequired,
    last: PropTypes.bool.isRequired,
    outOfRange: PropTypes.bool.isRequired,
    showDate: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    absences: PropTypes.array.isRequired,
    showHeader: PropTypes.bool.isRequired,
    focusAbsenceId: PropTypes.string.isRequired,
    onAbsenceClick: PropTypes.func.isRequired,
    onAbsenceMouseEnter: PropTypes.func.isRequired,
    onAbsenceMouseLeave: PropTypes.func.isRequired,
    filter: PropTypes.array.isRequired,
};

export default Day;
