import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import { Modal } from 'reactstrap';

import { ConnectedHelpTooltip } from '../../@paco/connectors';
import trans from '../../@paco/helpers/trans';
import ButtonAuthenticate from '../../components/ButtonAuthenticate/ButtonAuthenticate';
import YearNavigator from '../../components/DateNavigator/YearNavigator';
import FilterBlock from '../../components/Filters/subcomponents/FilterBlock/FilterBlock';
import ItemsList from '../../components/ItemsList/ItemsList';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import Icon from '../../components/style/Icon/Icon';
import TableContainer from '../../components/Table/TableContainer';
import { capitalizeFirstLetter, checkPermission } from '../../helpers';
import { formatDate, getYear } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import {
    addSpecialDay,
    deleteSpecialDay,
    editSpecialDay,
    getSpecialDays,
    getSpecialDayTypes,
} from '../../redux/shifts/shiftsActions';
import AddSpecialDayForm from './forms/AddSpecialDay';
import DeleteSpecialDayForm from './forms/DeleteSpecialDay';

import './SpecialDays.scss';

const SpecialDays = (props) => {
    const {
        dispatch,
        loading,
        specialDays,
        specialDayTypes,
        permissions,
    } = props;
    const [addSpecialDayForm, setAddSpecialDayForm] = useState(false);
    const [deleteSpecialDayForm, setDeleteSpecialDayForm] = useState(false);
    const [specialDayFormData, setSpecialDayFormData] = useState(null);
    const [year, setYear] = useState(getYear(new Date()));

    const canAddSpecialDays = checkPermission(permissions, 'add-new-special-days', 'special-days');
    const canEditSpecialDays = checkPermission(permissions, 'edit-all-special-days', 'special-days');
    const canDeleteSpecialDays = checkPermission(permissions, 'delete-all-special-days', 'special-days');

    const getData = () => {
        dispatch(getSpecialDays(year));
    };

    useEffect(() => {
        dispatch(getSpecialDayTypes());
    }, []);

    useEffect(() => {
        getData();
    }, [year]);


    const dispatchAddSpecialDay = (data) => {
        if (specialDayFormData) {
            dispatch(editSpecialDay(data, year));
        } else {
            dispatch(addSpecialDay(data, year));
        }

        setAddSpecialDayForm(false);
        setSpecialDayFormData(null);
    };

    const dispatchDeleteSpecialDay = () => {
        dispatch(deleteSpecialDay(specialDayFormData.id, year));

        setDeleteSpecialDayForm(false);
        setSpecialDayFormData(null);
    };

    const onAddSpecialDayClick = () => {
        setAddSpecialDayForm(true);
        setSpecialDayFormData(null);
    };

    const renderIcon = (specialDay) => (
        <span className="special-days-icon-tooltip">
            <Tooltip content={translate(`pages.specialDays.${specialDay.type}`)} arrowSize={6} padding={7}>
                <Icon
                    kind={specialDay.type}
                    fontSize={20}
                    isImage
                />
            </Tooltip>
        </span>
    );

    const renderEditDeleteButtons = (specialDay) => (
        <>
            {canEditSpecialDays && (
                <button type="button" onClick={() => { setAddSpecialDayForm(true); setSpecialDayFormData(specialDay); }} className="clear-button">
                    <Icon color="light-blue" kind="edit" />
                </button>
            )}
            {canDeleteSpecialDays && (
                <button type="button" onClick={() => { setDeleteSpecialDayForm(true); setSpecialDayFormData(specialDay); }} className="clear-button">
                    <Icon color="light-blue" kind="trash" />
                </button>
            )}
        </>
    );

    const renderTable = () => {
        const attributes = [
            {
                label: translate('common.category'),
                customAction: specialDay => renderIcon(specialDay),
                className: 'special-days-td-category',
            },
            {
                label: translate('common.date'),
                customAction: specialDay => capitalizeFirstLetter(formatDate(specialDay.date, 'EEEEEE dd MMM')),
                className: 'special-days-td-date',
            },
            {
                label: translate('common.description'),
                key: 'description',
                className: 'special-days-td-description',
            },
            {
                label: translate('pages.specialDays.holidayAllowance'),
                customAction: specialDay => translate(`common.${specialDay.holidayAllowance ? 'yes' : 'no'}`),
                className: 'special-days-td-holiday-allowance',
            },
            {
                customAction: specialDay => renderEditDeleteButtons(specialDay),
                className: 'td-edit-delete special-days-td-edit-delete',
            },
        ];

        return (
            <ItemsList
                loading={loading}
                itemsLength={specialDays.length}
                emptyMessage={translate('pages.specialDays.emptyList')}
            >
                <TableContainer
                    className="special-days-table"
                    attributes={attributes}
                    data={specialDays}
                />
            </ItemsList>
        );
    };

    return (
        <>
            <SidebarPage
                sidebarContent={canAddSpecialDays && (
                    <FilterBlock>
                        <ButtonAuthenticate
                            text={translate('pages.specialDays.specialDay')}
                            onClick={onAddSpecialDayClick}
                        />
                    </FilterBlock>
                )}
            >
                <ConnectedHelpTooltip
                    index={0}
                    route="special-days"
                    subTitle={trans('help.specialDays.overview.title')}
                    text={trans('help.specialDays.overview.text')}
                    title={trans('help.specialDays.title')}
                    className="special-days__overview-help-tooltip"
                />
                <div className="special-days-list">
                    <div className="special-days-navigator">
                        <YearNavigator
                            year={year}
                            onYearChange={setYear}
                        />
                        <ConnectedHelpTooltip
                            index={2}
                            route="special-days"
                            subTitle={trans('help.specialDays.yearNavigation.title')}
                            text={trans('help.specialDays.yearNavigation.text')}
                            title={trans('help.specialDays.title')}
                            className="special-days__year-navigation-help-tooltip"
                        />
                    </div>
                    {renderTable()}
                </div>
                <ConnectedHelpTooltip
                    index={1}
                    route="special-days"
                    subTitle={trans('help.specialDays.addSpecialDay.title')}
                    text={trans('help.specialDays.addSpecialDay.text')}
                    title={trans('help.specialDays.title')}
                    showMobileInfoWarning
                    className="special-days__add-special-day-help-tooltip"
                />
            </SidebarPage>
            {canAddSpecialDays && (
                <ButtonAuthenticate
                    mobile
                    onClick={onAddSpecialDayClick}
                />
            )}
            <Modal size="lg" isOpen={addSpecialDayForm} className="form-add-special-day">
                <AddSpecialDayForm
                    permissions={permissions}
                    specialDay={specialDayFormData}
                    specialDayTypes={specialDayTypes}
                    onSubmit={dispatchAddSpecialDay}
                    onCancel={() => setAddSpecialDayForm(false)}
                />
            </Modal>
            <Modal size="lg" isOpen={deleteSpecialDayForm} className="form-delete-special-day">
                <DeleteSpecialDayForm
                    onSubmit={dispatchDeleteSpecialDay}
                    onCancel={() => setDeleteSpecialDayForm(false)}
                />
            </Modal>
        </>
    );
};

SpecialDays.propTypes = {
    dispatch: PropTypes.func.isRequired,
    specialDays: PropTypes.array.isRequired,
    specialDayTypes: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default SpecialDays;
