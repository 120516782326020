import { EmploymentType as LegacyEmploymentTypeResource } from '../../../models/User';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import trans from '../../helpers/trans';
import { FormOption } from '../../types';
import {
    EmploymentType,
    EmploymentTypeResource,
    EmploymentTypeSlug,
    EmploymentTypeV3Resource,
} from './EmploymentType';

export const transformToEmploymentType = (resource: EmploymentTypeResource): EmploymentType => ({
    id: resource.id,
    name: resource.attributes.name,
    slug: resource.attributes.slug,
});

export const transformV3ResourceToEmploymentType = (resource: EmploymentTypeV3Resource): EmploymentType => {
    const slug = getEnumKeyByEnumValue(EmploymentTypeSlug, resource.slug);

    return {
        id: resource.id,
        name: resource.name,
        slug: slug ? EmploymentTypeSlug[slug] : EmploymentTypeSlug.fullTime,
    };
};

export const transformEmploymentTypeToFormOption = (employmentType: EmploymentType): FormOption => ({
    label: trans(`common.${employmentType.slug}`),
    value: employmentType.id,
});

export const transformLegacyEmploymentTypeResourceToEmploymentType = (resource: LegacyEmploymentTypeResource): EmploymentType => ({
    id: resource.id,
    name: resource.name,
    slug: resource.slug as EmploymentTypeSlug,
});
