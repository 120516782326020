import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import { addDays, areIntervalsOverlapping, max } from '../../helpers/date';
import { AbsenceViewModel, ShiftPlanningViewModel, ShiftViewModel } from '../../models';

const doesAbsenceOverlapWithShiftPlanning = (
    absence: AbsenceViewModel,
    shiftPlanning: ShiftPlanningViewModel
    | ShiftViewModel
    | ShiftConceptViewModelOld
    | BaseScheduleShiftViewModel,
    now: Date,
): boolean => {
    const justifiedEndDate = !absence.end ? addDays(max([absence.start, now]), 3) : absence.end.date;

    return areIntervalsOverlapping(
        {
            start: shiftPlanning.start,
            end: shiftPlanning.end.date,
        },
        {
            start: absence.start,
            end: justifiedEndDate,
        },
    );
};

export default doesAbsenceOverlapWithShiftPlanning;
