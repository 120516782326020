import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { TemporaryWorker } from '../../entities/TemporaryWorker/TemporaryWorker';

export type TemporaryWorkerState = AsyncReduxState<{
    isDeleteSuccessful?: boolean;
    lastAddedTemporaryWorker?: TemporaryWorker;
    lastEditedTemporaryWorker?: TemporaryWorker;
    lastDeletedTemporaryWorkerId?: string;
}>;

const initialState: TemporaryWorkerState = {
    isDeleteSuccessful: false,
    ...initialAsyncReduxState,
};

export const TemporaryWorkerSlice = createSlice({
    name: 'temporaryWorkerReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): TemporaryWorkerState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsDeleteSuccessful(state, action: PayloadAction<boolean>): TemporaryWorkerState {
            return {
                ...state,
                isDeleteSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): TemporaryWorkerState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setLastAddedTemporaryWorker(state, action: PayloadAction<TemporaryWorker>): TemporaryWorkerState {
            return {
                ...state,
                lastAddedTemporaryWorker: action.payload,
            };
        },
        setLastDeletedTemporaryWorkerId(state, action: PayloadAction<string>): TemporaryWorkerState {
            return {
                ...state,
                lastDeletedTemporaryWorkerId: action.payload,
            };
        },
        setLastEditedTemporaryWorker(state, action: PayloadAction<TemporaryWorker>): TemporaryWorkerState {
            return {
                ...state,
                lastEditedTemporaryWorker: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): TemporaryWorkerState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsDeleteSuccessful,
    setError,
    setLastAddedTemporaryWorker,
    setLastDeletedTemporaryWorkerId,
    setLastEditedTemporaryWorker,
    setIsSuccessful,
} = TemporaryWorkerSlice.actions;

export default TemporaryWorkerSlice.reducer;
