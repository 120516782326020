import React, { FC } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';


interface FilterProps {
    filter: AppFilter;
    onChange: (filter: AppFilter) => void;
}

const ExchangesFilter: FC<FilterProps> = ({ filter, onChange }) => {
    const onCheckboxChange = (onlyWithReplies: boolean) => {
        const absences = {
            ...filter.absences,
            onlyWithReplies,
        };

        onChange({
            ...filter,
            absences,
        });
    };

    return (
        <FilterBlock title={translate('pages.absences.takeOverShift')}>
            <FormGroup check className="p-0">
                <Label check>
                    <CustomInput
                        id="only-with-replies-filter-checkbox"
                        type="checkbox"
                        label={translate('pages.absences.onlyWithReplies')}
                        checked={filter.absences.onlyWithReplies}
                        onChange={() => onCheckboxChange(!filter.absences.onlyWithReplies)}
                    />
                </Label>
            </FormGroup>
        </FilterBlock>
    );
};

export default ExchangesFilter;
