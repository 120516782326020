import {
    FC,
    useContext,
    useEffect,
    useMemo,
} from 'react';

import { Setting } from '../../../../entities/Setting/Setting';
import { PrintableBlockOffsetsContext } from '../../contexts/PrintableBlockOffsetsContext';
import { transformPrintableDistrictDivisionColumnsToPrintableDistrictDivisionPages } from '../../helpers';
import { PrintableDistrictDivisionPageInterface } from '../../models';
import { PrintableDistrictDivisionPage } from '../index';

interface PrintableDistrictDivisionPagesProps {
    page: PrintableDistrictDivisionPageInterface;
    settings: Setting[];
    className?: string;
}

const PrintableDistrictDivisionPages: FC<PrintableDistrictDivisionPagesProps> = ({ page: source, settings, className = '' }) => {
    const { offsets, reset } = useContext(PrintableBlockOffsetsContext);

    const pages = useMemo(() => transformPrintableDistrictDivisionColumnsToPrintableDistrictDivisionPages(source.columns, offsets), [offsets, source.id]);

    useEffect(() => () => {
        if (reset) {
            reset();
        }
    }, [source.id]);

    return (
        <div className={`printable-district-division-pages ${className}`}>
            <PrintableDistrictDivisionPage
                isSizer
                page={source}
                settings={settings}
            />

            {pages.map(page => (
                <PrintableDistrictDivisionPage
                    key={page.id}
                    page={page}
                    settings={settings}
                />
            ))}
        </div>
    );
};

export default PrintableDistrictDivisionPages;
