import { getAbsencesInDateRange } from '../../../helpers/api-ts/absences';
import { addWeeks } from '../../../helpers/date';
import { AbsenceViewModel } from '../../../models';

export default async function getAbsencesForEmployeeInLast4Weeks(
    userId: string,
    date: Date | string,
    excludedAbsenceId?: string,
): Promise<AbsenceViewModel[]> {
    const startDate = addWeeks(date, -4);
    const absences = await getAbsencesInDateRange(startDate, date, { userIds: [userId] });

    return excludedAbsenceId
        ? absences.filter(absence => absence.id !== excludedAbsenceId) : absences;
}
