import {
    all,
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import { TOAST_TYPE_PASS } from '../../constants';
import { getObjProperty } from '../../helpers';
import {
    delSpecialDay,
    getShift,
    getShiftsByDate,
    getShiftSnapshot,
    getSimilarShifts,
    getSpecialDays,
    getSpecialDayTypes,
    getUnavailableWorkTimeSlots,
    patchShift,
    patchSpecialDay,
    postSpecialDay,
} from '../../helpers/api/shiftsApi';
import { translate } from '../../helpers/translations/translator';
import {
    APP_SAGA_ADD_TOAST,
    SHIFTS_ADD_SPECIAL_DAY_FAILURE,
    SHIFTS_ADD_SPECIAL_DAY_REQUEST,
    SHIFTS_ADD_SPECIAL_DAY_SUCCESS,
    SHIFTS_DELETE_SPECIAL_DAY_FAILURE,
    SHIFTS_DELETE_SPECIAL_DAY_REQUEST,
    SHIFTS_DELETE_SPECIAL_DAY_SUCCESS,
    SHIFTS_EDIT_SHIFT_FAILURE,
    SHIFTS_EDIT_SHIFT_REQUEST,
    SHIFTS_EDIT_SHIFT_SUCCESS,
    SHIFTS_EDIT_SPECIAL_DAY_FAILURE,
    SHIFTS_EDIT_SPECIAL_DAY_REQUEST,
    SHIFTS_EDIT_SPECIAL_DAY_SUCCESS,
    SHIFTS_FILTER_SHIFT_BY_ABSENCE_FILTERS,
    SHIFTS_GET_SHIFT_FAILURE,
    SHIFTS_GET_SHIFT_REQUEST,
    SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_FAILURE,
    SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_REQUEST,
    SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_SUCCESS,
    SHIFTS_GET_SHIFT_SNAPSHOT_FAILURE,
    SHIFTS_GET_SHIFT_SNAPSHOT_REQUEST,
    SHIFTS_GET_SHIFT_SNAPSHOT_SUCCESS,
    SHIFTS_GET_SHIFT_SUCCESS,
    SHIFTS_GET_SHIFTS_FAILURE,
    SHIFTS_GET_SHIFTS_REQUEST,
    SHIFTS_GET_SHIFTS_SUCCESS,
    SHIFTS_GET_SHIFTS_SUCCESS_BUT_WAIT_FOR_FILTER,
    SHIFTS_GET_SPECIAL_DAY_TYPES_FAILURE,
    SHIFTS_GET_SPECIAL_DAY_TYPES_REQUEST,
    SHIFTS_GET_SPECIAL_DAY_TYPES_SUCCESS,
    SHIFTS_GET_SPECIAL_DAYS_FAILURE,
    SHIFTS_GET_SPECIAL_DAYS_REQUEST,
    SHIFTS_GET_SPECIAL_DAYS_SUCCESS,
    SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_FAILURE,
    SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_REQUEST,
    SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_SUCCESS,
    SHIFTS_GET_USERS_PLANNED_ON_DATE_FAILURE,
    SHIFTS_GET_USERS_PLANNED_ON_DATE_REQUEST,
    SHIFTS_GET_USERS_PLANNED_ON_DATE_SUCCESS,
    SHIFTS_MAPPED_ABSENCE_DATA_TO_SHIFT_PLANNINGS,
    SHIFTS_SAGA_ADD_SPECIAL_DAY,
    SHIFTS_SAGA_DELETE_SPECIAL_DAY,
    SHIFTS_SAGA_EDIT_SHIFT,
    SHIFTS_SAGA_EDIT_SPECIAL_DAY,
    SHIFTS_SAGA_GET_SHIFT,
    SHIFTS_SAGA_GET_SHIFTS,
    SHIFTS_SAGA_GET_SPECIAL_DAY_TYPES,
    SHIFTS_SAGA_GET_SPECIAL_DAYS,
    SHIFTS_SAGA_UPDATE_SHIFTS_WITH_SHIFT,
    SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_FAILURE,
    SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_REQUEST,
    SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_SUCCESS,
} from '../actionTypes';
import {
    fetchAbsencesData,
    filterShiftsByAbsences,
    getShiftsWithUpdatedShift,
    isDataDifferentThanShift,
    mapAbsencesDataToShifts,
} from './shiftsHelpers';

function* fetchAbsenceDataAndMapToShifts(shifts, skipApiCalls = false) {
    // This saga can receive a single (detail page) or multiple (calendar page) shifts.
    const isArray = Array.isArray(shifts);
    const array = isArray ? shifts : [shifts];
    let state = yield select();
    const { startDate, endDate } = state.weekNavigatorReducer;

    if (!skipApiCalls) {
        yield all([
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            fetchUnavailableWorkTimeSlots({ startDate, endDate }),
            fetchAbsencesData(state),
        ]);
    }

    state = yield select();
    const { leaveOfAbsencesRequests, absencesInDateRange } = state.absencesReducer;
    const { unavailableToWorkTimeSlots } = state.shiftsReducer;
    const mappedShifts = mapAbsencesDataToShifts(array, leaveOfAbsencesRequests, absencesInDateRange, unavailableToWorkTimeSlots);

    yield put({
        type: SHIFTS_MAPPED_ABSENCE_DATA_TO_SHIFT_PLANNINGS,
        ...(isArray && { shifts: mappedShifts }),
        ...(!isArray && { shift: mappedShifts[0] }),
    });
}

function* filterShiftByAbsenceFilters() {
    const state = yield select();
    const { shifts } = state.shiftsReducer;
    const {
        onlyShowShiftsWithOpenLeaveOfAbsence, onlyShowShiftsWithAbsence,
    } = state.filterReducer.filter.shifts;
    if (!onlyShowShiftsWithOpenLeaveOfAbsence && !onlyShowShiftsWithAbsence) {
        return null;
    }

    const filteredShifts = filterShiftsByAbsences(shifts, state.filterReducer.filter.shifts);
    return yield put({ type: SHIFTS_FILTER_SHIFT_BY_ABSENCE_FILTERS, shifts: filteredShifts });
}

export function* fetchShifts() {
    yield put({ type: SHIFTS_GET_SHIFTS_REQUEST });

    try {
        const state = yield select();

        const { startDate, endDate } = state.weekNavigatorReducer;
        const { filter } = state.filterReducer;
        const {
            onlyShowShiftsWithOpenLeaveOfAbsence, onlyShowShiftsWithAbsence,
        } = filter.shifts;

        const response = yield call(() => getShiftsByDate(startDate, endDate, filter));
        const shifts = mapAbsencesDataToShifts(response.data);

        // If absence filters then we have to wait for the absence data to be mapped first
        const waitForFilter = onlyShowShiftsWithOpenLeaveOfAbsence || onlyShowShiftsWithAbsence;

        yield put({
            type: waitForFilter
                ? SHIFTS_GET_SHIFTS_SUCCESS_BUT_WAIT_FOR_FILTER : SHIFTS_GET_SHIFTS_SUCCESS,
            shifts,
        });

        yield fetchAbsenceDataAndMapToShifts(response.data);
        yield filterShiftByAbsenceFilters();
    } catch (error) {
        yield put({ type: SHIFTS_GET_SHIFTS_FAILURE, error });
    }
}

function* fetchShiftSnapshot(action) {
    yield put({ type: SHIFTS_GET_SHIFT_SNAPSHOT_REQUEST });
    try {
        const response = yield call(getShiftSnapshot, action.shiftId);
        const snapshot = getObjProperty(response, 'data');
        yield put({ type: SHIFTS_GET_SHIFT_SNAPSHOT_SUCCESS, snapshot });
    } catch (error) {
        yield put({ type: SHIFTS_GET_SHIFT_SNAPSHOT_FAILURE });
    }
}

function* fetchSimilarShifts(action) {
    yield put({ type: SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_REQUEST });
    try {
        const response = yield call(getSimilarShifts, action.shiftId);
        const similarShifts = getObjProperty(response, 'data');
        yield put({ type: SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_SUCCESS, similarShifts });
    } catch (error) {
        yield put({ type: SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_FAILURE });
    }
}

function* fetchUsersPlannedOnShiftDate(startDate) {
    yield put({ type: SHIFTS_GET_USERS_PLANNED_ON_DATE_REQUEST });
    try {
        const response = yield call(() => getShiftsByDate(startDate, startDate));
        const users = response.data.reduce((total, shift) => [
            ...total, ...shift.shiftPlannings
                .filter(shiftPlanning => shiftPlanning.planned)
                .map(shiftPlanning => shiftPlanning.user.id)], []);
        yield put({ type: SHIFTS_GET_USERS_PLANNED_ON_DATE_SUCCESS, users });
    } catch (error) {
        yield put({ type: SHIFTS_GET_USERS_PLANNED_ON_DATE_FAILURE });
    }
}

function* fetchShift(action) {
    const state = yield select();
    let shiftId = getObjProperty(action, 'shiftId');
    if (!shiftId) {
        shiftId = getObjProperty(state, 'shiftsReducer.shift.id');
    }
    yield put({ type: SHIFTS_GET_SHIFT_REQUEST });
    try {
        const response = yield call(getShift, shiftId);
        const shift = getObjProperty(response, 'data');
        yield put({ type: SHIFTS_GET_SHIFT_SUCCESS, shift });
        yield all([
            call(() => fetchSimilarShifts({ shiftId })),
            call(() => fetchShiftSnapshot({ shiftId })),
            fetchAbsenceDataAndMapToShifts(shift),
            fetchUsersPlannedOnShiftDate(shift.start),
        ]);
    } catch (error) {
        yield put({ type: SHIFTS_GET_SHIFT_FAILURE });
        throw new Error(error);
    }
}

export function* editShift(action) {
    yield put({ type: SHIFTS_EDIT_SHIFT_REQUEST });
    try {
        const state = yield select();
        let { shift } = state.shiftsReducer;

        if (isDataDifferentThanShift(shift, action.data)) {
            yield call(() => patchShift(action.data));
            const response = yield call(() => getShift(action.data.id));
            shift = response.data;
        }

        yield put({ type: SHIFTS_EDIT_SHIFT_SUCCESS, shift });

        if (action.toast) {
            yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.shifts.editShiftSuccess'), toastType: TOAST_TYPE_PASS });
        }
        action.navigate(`/${translate('nav.shifts.link')}/${translate('nav.shifts.schedule.link')}`);
    } catch (error) {
        yield put({ type: SHIFTS_EDIT_SHIFT_FAILURE });
    }
}

export function* updateShiftsWithShift(action) {
    yield put({ type: SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_REQUEST });
    try {
        const state = yield select();
        const { shifts } = state.shiftsReducer;
        const { shift } = action;
        const updatedShifts = getShiftsWithUpdatedShift(shifts, shift);
        yield fetchAbsenceDataAndMapToShifts(updatedShifts, true);
        yield put({ type: SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_SUCCESS });
    } catch (error) {
        yield put({ type: SHIFTS_UPDATE_SHIFTS_WITH_SHIFT_FAILURE });
    }
}

export function* fetchSpecialDays({ year, startDate, endDate }) {
    yield put({ type: SHIFTS_GET_SPECIAL_DAYS_REQUEST });
    try {
        const response = yield call(getSpecialDays, year, startDate, endDate);
        yield put({ type: SHIFTS_GET_SPECIAL_DAYS_SUCCESS, specialDays: response.data });
    } catch (error) {
        yield put({ type: SHIFTS_GET_SPECIAL_DAYS_FAILURE });
    }
}

export function* fetchSpecialDayTypes() {
    yield put({ type: SHIFTS_GET_SPECIAL_DAY_TYPES_REQUEST });
    try {
        const response = yield call(getSpecialDayTypes);
        yield put({ type: SHIFTS_GET_SPECIAL_DAY_TYPES_SUCCESS, specialDayTypes: response.data });
    } catch (error) {
        yield put({ type: SHIFTS_GET_SPECIAL_DAY_TYPES_FAILURE });
    }
}

export function* addSpecialDay(action) {
    yield put({ type: SHIFTS_ADD_SPECIAL_DAY_REQUEST });
    try {
        yield call(postSpecialDay, action.data);
        yield fetchSpecialDays({ year: action.year });
        yield put({ type: SHIFTS_ADD_SPECIAL_DAY_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.specialDays.addSpecialDaySuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: SHIFTS_ADD_SPECIAL_DAY_FAILURE });
    }
}

export function* editSpecialDay(action) {
    yield put({ type: SHIFTS_EDIT_SPECIAL_DAY_REQUEST });
    try {
        yield call(patchSpecialDay, action.data);
        yield fetchSpecialDays({ year: action.year });
        yield put({ type: SHIFTS_EDIT_SPECIAL_DAY_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.specialDays.editSpecialDaySuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: SHIFTS_EDIT_SPECIAL_DAY_FAILURE });
    }
}

export function* deleteSpecialDay(action) {
    yield put({ type: SHIFTS_DELETE_SPECIAL_DAY_REQUEST });
    try {
        yield call(delSpecialDay, action.id);
        yield fetchSpecialDays({ year: action.year });
        yield put({ type: SHIFTS_DELETE_SPECIAL_DAY_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.specialDays.deleteSpecialDaySuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: SHIFTS_DELETE_SPECIAL_DAY_FAILURE });
    }
}

export function* fetchUnavailableWorkTimeSlots(action) {
    yield put({ type: SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_REQUEST });
    const { startDate, endDate } = action;

    try {
        const response = yield call(() => getUnavailableWorkTimeSlots(startDate, endDate));
        yield put({
            type: SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_SUCCESS,
            unavailableToWorkTimeSlots: response.data,
        });
    } catch (error) {
        yield put({ type: SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_FAILURE });
    }
}

export default function* shiftsWatcher() {
    yield takeLatest(SHIFTS_SAGA_GET_SHIFTS, fetchShifts);
    yield takeLatest(SHIFTS_SAGA_GET_SHIFT, fetchShift);
    yield takeLatest(SHIFTS_SAGA_EDIT_SHIFT, editShift);
    yield takeLatest(SHIFTS_SAGA_GET_SPECIAL_DAYS, fetchSpecialDays);
    yield takeLatest(SHIFTS_SAGA_GET_SPECIAL_DAY_TYPES, fetchSpecialDayTypes);
    yield takeLatest(SHIFTS_SAGA_ADD_SPECIAL_DAY, addSpecialDay);
    yield takeLatest(SHIFTS_SAGA_EDIT_SPECIAL_DAY, editSpecialDay);
    yield takeLatest(SHIFTS_SAGA_DELETE_SPECIAL_DAY, deleteSpecialDay);
    yield takeLatest(SHIFTS_SAGA_UPDATE_SHIFTS_WITH_SHIFT, updateShiftsWithShift);
}
