import React, { FC, ReactElement } from 'react';

import { ConnectedHelpTooltip } from '../../../../@paco/connectors';
import ConnectedCalendarNavigation from '../../../../@paco/connectors/ConnectedCalendarNavigation/ConnectedCalendarNavigation';
import trans from '../../../../@paco/helpers/trans';
import { TimeModeType } from '../../../../@paco/types';
import CalendarLegend from '../../../../components/CalendarLegend/CalendarLegend';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { translate } from '../../../../helpers/translations/translator';
import { Pagination, SpecialDayViewModel, UserWithAvailability } from '../../../../models';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import { AvailabilityResourceTypes } from '../../components/AvailabilityDays/subcomponents/Availability/Availability';
import ListViewTable from '../../components/ListViewTable/ListViewTable';

import './ListView.scss';

interface ListViewProps {
    canAddPlannings: boolean;
    canViewShiftConcepts: boolean;
    firstRowSticky: boolean;
    firstColumnSticky: boolean;
    loading: boolean;
    days: Date[];
    filter: AppFilter;
    users: UserWithAvailability[];
    pagination: Pagination;
    specialDays: SpecialDayViewModel[];
    startDate: Date;
    endDate: Date;
    onSearchBarChange: (search: string) => void;
    getUsersData: () => void;
    onAddButtonClick: (date: Date, user: UserWithAvailability) => void;
    onCalendarTypeChange: () => void;
    onResourceClick: (resource: AvailabilityResourceTypes) => void;
}

const ListView: FC<ListViewProps> = ({
    canAddPlannings,
    canViewShiftConcepts,
    firstRowSticky,
    firstColumnSticky,
    loading,
    days,
    filter,
    users,
    pagination,
    specialDays,
    startDate,
    endDate,
    onSearchBarChange,
    getUsersData,
    onAddButtonClick,
    onCalendarTypeChange,
    onResourceClick,
}): ReactElement => (
    <div className="list-view">
        <ConnectedHelpTooltip
            index={0}
            route="list-view"
            subTitle={trans('help.listView.filters.title')}
            text={trans('help.listView.filters.text')}
            title={trans('help.listView.title')}
            showMobileInfoWarning
            className="list-view__filters-help-tooltip"
        />
        <div className="list-view__calendar-overview-top">
            <ConnectedCalendarNavigation
                showConceptModeButtons={canViewShiftConcepts}
                timeModes={[TimeModeType.week, TimeModeType.custom]}
                onCalendarTypeChange={onCalendarTypeChange}
                className="list-view__navigation"
            />

            <div className="list-view__search-bar">
                <ConnectedHelpTooltip
                    index={4}
                    route="list-view"
                    subTitle={trans('help.listView.search.title')}
                    text={trans('help.listView.search.text')}
                    title={trans('help.listView.title')}
                    className="list-view__search-help-tooltip"
                />
                <SearchBar
                    placeholder={translate('common.searchEmployee')}
                    startVal={filter.employeeSearch}
                    onChange={onSearchBarChange}
                />
                <ConnectedHelpTooltip
                    index={1}
                    route="list-view"
                    subTitle={trans('help.listView.legend.title')}
                    text={trans('help.listView.legend.text')}
                    title={trans('help.listView.title')}
                    className="list-view__legend-help-tooltip"
                />
            </div>
        </div>
        <CalendarLegend
            plannedShift
            registeredShift
            absence
            leaveOfAbsence
            available
            notAvailable
            className="list-view__calendar-legend"
        />
        <div>
            <ItemsList
                loading={loading}
                itemsLength={users.length}
                pagination={pagination}
                emptyMessage={translate('pages.management.emptyList')}
                onPagination={getUsersData}
            >
                <ListViewTable
                    loading={loading}
                    specialDays={specialDays}
                    startDate={startDate}
                    endDate={endDate}
                    days={days}
                    users={users}
                    isFilterEmploymentTypeBadgeActive={filter.employmentTypeBadge}
                    canAddPlannings={canAddPlannings}
                    firstRowSticky={firstRowSticky}
                    firstColumnSticky={firstColumnSticky}
                    onAvailabilityClick={onResourceClick}
                    onAddButtonClick={onAddButtonClick}
                />
            </ItemsList>
        </div>
    </div>
);

export default ListView;
