import trans from '../../@paco/helpers/trans';

export const SettingsRoutes = {
    manager: () => `${trans('routes.settings.manager.link')}`,
    employee: () => `${trans('routes.settings.employee.link')}`,
    periods: () => `${trans('routes.settings.periods.link')}`,
    groups: () => `${trans('routes.settings.departments.link')}`,
    timer: () => `${trans('routes.settings.timer.link')}`,
    districtDivisionTemplates: () => `${trans('routes.settings.districtDivisionTemplates.link')}`,
    districtDivisionTemplateDetail: (id : string) => `${trans('routes.settings.districtDivisionTemplates.link')}/${id}`,
};
