import { EditDistrictDivisionGroupTemplateFormData } from '../../../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import trans from '../../../../helpers/trans';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../services/validationService';

export type EditDistrictDivisionGroupTemplateFormErrors = FormErrors<EditDistrictDivisionGroupTemplateFormData>;

export const validateEditDistrictDivisionGroupTemplateFormData = (formData: EditDistrictDivisionGroupTemplateFormData): FormValidation<EditDistrictDivisionGroupTemplateFormErrors> => {
    const errors: EditDistrictDivisionGroupTemplateFormErrors = {
        id: validateRequiredString('id', formData.id),
        name: validateRequiredString('name', formData.name),
        districtDivisionTemplateId: validateRequiredString('districtDivisionTemplateId', formData.districtDivisionTemplateId),
        districtDivisionDistrictTemplates: formData.districtDivisionDistrictTemplates.some(district => district.maxAmountOfEmployees < 0)
            ? trans('validation.errors.numberBelowComparison', {
                attribute: trans('validation.attributes.employeeLimit'),
                comparison: 0,
            })
            : undefined,
    };

    return validateForm<EditDistrictDivisionGroupTemplateFormErrors>(errors);
};
