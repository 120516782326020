import { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import LegacyUsersRouteGroup from '../../../../../pages/Management/LegacyUsersRouteGroup';
import { UserDetailPage } from '../../../../pages';
import { UsersRoutes } from '../../../../routes/helpers';

const UsersRouteGroup: FC = () => (
    <Routes>
        <Route path={`${UsersRoutes.user(':userId')}`} element={<UserDetailPage />} />

        <Route path="/*" element={<LegacyUsersRouteGroup />} />
    </Routes>
);

export default UsersRouteGroup;
