import { FC, useMemo } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import LegacySettingsRouteGroup from '../../../../../pages/Settings/LegacySettingsRouteGroup';
import { SettingsRoutes } from '../../../../../pages/Settings/SettingsRoutes';
import { getDefaultSubRoutePathByNavigationRouteKey } from '../../../../helpers/routes';
import { DistrictDivisionTemplateDetailPage, DistrictDivisionTemplatesPage } from '../../../../pages';
import { NavRoute } from '../../../../routes/routes';

interface SettingsRouteGroupProps {
    permittedUserRoutes: NavRoute[];
}

const SettingsRouteGroup: FC<SettingsRouteGroupProps> = ({ permittedUserRoutes }) => {
    const defaultRoute = useMemo(() => getDefaultSubRoutePathByNavigationRouteKey('settings', permittedUserRoutes), [permittedUserRoutes]);

    return (
        <Routes>
            <Route path={SettingsRoutes.districtDivisionTemplates()} element={<DistrictDivisionTemplatesPage />} />
            <Route path={SettingsRoutes.districtDivisionTemplateDetail(':id')} element={<DistrictDivisionTemplateDetailPage />} />

            <Route path="/*" element={<LegacySettingsRouteGroup />} />
            <Route path="/" element={<Navigate to={defaultRoute} />} />
        </Routes>
    );
};

export default SettingsRouteGroup;
