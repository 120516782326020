import { LeaveOfAbsence } from '../../../../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { addSeconds, compareAsc, getEndOfWorkDay } from '../../../../../helpers/date';
import { LeaveOfAbsenceWithCalendarPeriod } from './LeaveOfAbsenceWithCalendarPeriod';

export const transformLeaveOfAbsenceWithCalendarPeriod = (leaveOfAbsence: LeaveOfAbsence, calendarStartDay: Date): LeaveOfAbsenceWithCalendarPeriod => {
    const calendarStartOfWorkday = addSeconds(getEndOfWorkDay(addSeconds(calendarStartDay, -1)), 1);
    const justifiedStart = compareAsc(leaveOfAbsence.period.start, calendarStartDay) === -1 ? calendarStartOfWorkday : leaveOfAbsence.period.start;

    return {
        ...leaveOfAbsence,
        calendarPeriod: {
            ...leaveOfAbsence.period,
            start: compareAsc(leaveOfAbsence.period.end, justifiedStart) !== -1 ? justifiedStart : leaveOfAbsence.period.start,
        },
    };
};
