import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LeaveOfAbsence } from '../../../../models';
import { AsyncReduxState, initialAsyncReduxState } from '../../../@config/AsyncReduxState';

export type IncompleteLeaveOfAbsencesState = AsyncReduxState<{
    incompleteLeaveOfAbsences: LeaveOfAbsence[];
}>;

const initialState: IncompleteLeaveOfAbsencesState = {
    ...initialAsyncReduxState,
    incompleteLeaveOfAbsences: [],
};

export const IncompleteLeaveOfAbsencesSlice = createSlice({
    name: 'incompleteLeaveOfAbsencesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): IncompleteLeaveOfAbsencesState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): IncompleteLeaveOfAbsencesState {
            return ({
                ...state,
                isSuccessful: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): IncompleteLeaveOfAbsencesState {
            return ({
                ...state,
                error: action.payload,
            });
        },
        setIncompleteLeaveOfAbsences(state, action: PayloadAction<LeaveOfAbsence[]>): IncompleteLeaveOfAbsencesState {
            return ({
                ...state,
                incompleteLeaveOfAbsences: action.payload,
            });
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setIncompleteLeaveOfAbsences,
    setError,
} = IncompleteLeaveOfAbsencesSlice.actions;

export default IncompleteLeaveOfAbsencesSlice.reducer;
