import { FC, ReactElement } from 'react';

import classnames from 'classnames';

import './UserDetailListItem.scss';

interface UserDetailListItemProps {
    title: string;
    value?: ReactElement | string;
    className?: string;
}

const UserDetailListItem: FC<UserDetailListItemProps> = ({ title, value, className = '' }): ReactElement => {
    const listItemValueClassNames = classnames('user-detail-list-item__value', {
        'user-detail-list-item__value--is-string': typeof value === 'string',
    }, className);

    return (
        <li className={`user-detail-list-item ${className}`}>
            <div className="user-detail-list-item__title">
                {title}
            </div>
            <div className={listItemValueClassNames}>
                {value}
            </div>
        </li>
    );
};

export default UserDetailListItem;
