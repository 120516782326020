import { FC, ReactElement, useMemo } from 'react';

import CaoBadge from '../../../../../components/UserBadge/CaoBadge/CaoBadge';
import {
    AbsenceBadge,
    LeaveOfAbsenceBadge,
    PreferToWorkTimeSlotBadge,
    UnavailableToWorkTimeSlotBadge,
} from '../../../../components';
import { Absence } from '../../../../entities/Absence/Absence';
import { LeaveOfAbsence } from '../../../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { doPeriodsOverlap } from '../../../../entities/Period/PeriodHelpers';
import { PreferToWorkTimeSlot } from '../../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { doesPreferToWorkTimeSlotOverlapWithPeriod } from '../../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlotHelpers';
import { Setting } from '../../../../entities/Setting/Setting';
import { getUserShiftPlanningInLast11Hours, isUserAgeBelow16AndShiftIsAfter19h, isUserAgeBelow18AndShiftIsAfter23h } from '../../../../entities/Setting/SettingHelpers';
import { ShiftPlanning } from '../../../../entities/ShiftPlanning/ShiftPlanning';
import { UnavailableToWorkTimeSlot } from '../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { doesUnavailableToWorkTimeSlotOverlapWithPeriod } from '../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotHelpers';
import { WorkweekUser } from '../../../../entities/User/User';

import './ShiftPlanningAvailabilityLabels.scss';

interface ShiftPlanningAvailabilityLabelsProps {
    absences: Absence[];
    departmentId: string;
    leaveOfAbsences: LeaveOfAbsence[];
    preferToWorkTimeSlots: PreferToWorkTimeSlot[];
    settings: Setting[];
    shiftPlanning: ShiftPlanning;
    recentShiftPlannings: ShiftPlanning[];
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[];
    user?: WorkweekUser;
    className?: string;
}

const ShiftPlanningAvailabilityLabels: FC<ShiftPlanningAvailabilityLabelsProps> = ({
    absences,
    departmentId,
    leaveOfAbsences,
    preferToWorkTimeSlots,
    recentShiftPlannings,
    settings,
    shiftPlanning,
    unavailableToWorkTimeSlots,
    user,
    className = '',
}): ReactElement => {
    const userAbsence = useMemo(() => absences.find(absence => (
        absence.user.id === user?.id && doPeriodsOverlap(absence.period, shiftPlanning.period)
    )), [absences, user]);

    const userLeaveOfAbsence = useMemo(() => leaveOfAbsences.find(leaveOfAbsence => (
        leaveOfAbsence.user.id === user?.id && doPeriodsOverlap(leaveOfAbsence.period, shiftPlanning.period)
    )), [leaveOfAbsences, user]);

    const userUnavailableToWorkTimeSlot = useMemo(() => unavailableToWorkTimeSlots.find(unavailableToWorkTimeSlot => (
        unavailableToWorkTimeSlot.user.id === user?.id && doesUnavailableToWorkTimeSlotOverlapWithPeriod(unavailableToWorkTimeSlot, shiftPlanning.period)
    )), [unavailableToWorkTimeSlots, user]);

    const userPreferredToWorkTimeSlot = useMemo(() => preferToWorkTimeSlots.find(preferToWorkTimeSlot => (
        preferToWorkTimeSlot.user.id === user?.id && doesPreferToWorkTimeSlotOverlapWithPeriod(preferToWorkTimeSlot, shiftPlanning.period)
    )), [preferToWorkTimeSlots, user]);

    const userAgeIsBelow16AndShiftIsAfter19h = useMemo(() => (
        user ? isUserAgeBelow16AndShiftIsAfter19h(
            settings,
            departmentId,
            user.birthday,
            shiftPlanning.period.end,
            new Date(),
        ) : false
    ), [settings, user]);

    const userAgeIsBelow18AndShiftIsAfter23h = useMemo(() => (
        user ? isUserAgeBelow18AndShiftIsAfter23h(
            settings,
            departmentId,
            user.birthday,
            shiftPlanning.period.end,
            new Date(),
        ) : false
    ), [settings, user]);

    const userHasShiftPlanningInLast11h = useMemo(() => (
        getUserShiftPlanningInLast11Hours(
            shiftPlanning,
            recentShiftPlannings,
            settings,
            departmentId,
        )
    ), [
        shiftPlanning,
        recentShiftPlannings,
        user,
        settings,
    ]);

    return (
        <div className={`shift-planning-availability-labels ${className}`}>
            {userAgeIsBelow16AndShiftIsAfter19h && <CaoBadge userAgeIsBelow16AndShiftIsAfter19h />}
            {userAgeIsBelow18AndShiftIsAfter23h && <CaoBadge userAgeIsBelow18AndShiftIsAfter23h />}
            {userHasShiftPlanningInLast11h && <CaoBadge userHasShiftPlanningInLast11h />}

            {userAbsence && (
                <AbsenceBadge absence={userAbsence} className="users-select-option__absence-badge" />
            )}

            {(!userAbsence && userLeaveOfAbsence) && (
                <LeaveOfAbsenceBadge leaveOfAbsence={userLeaveOfAbsence} />
            )}

            {userUnavailableToWorkTimeSlot && (
                <UnavailableToWorkTimeSlotBadge unavailableToWorkTimeSlot={userUnavailableToWorkTimeSlot} />
            )}

            {userPreferredToWorkTimeSlot && (
                <PreferToWorkTimeSlotBadge preferToWorkTimeSlot={userPreferredToWorkTimeSlot} />
            )}
        </div>
    );
};

export default ShiftPlanningAvailabilityLabels;
