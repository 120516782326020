import { PayrollPeriod } from '../../@paco/entities/PayrollPeriod/PayrollPeriod';
import UpToAndIncludingDate from '../../helpers/date/UpToAndIncludingDate';
import { PayrollPeriodViewModel } from '../../models';

export const transformPayrollPeriodToLegacyPayrollPeriod = (payrollPeriod: PayrollPeriod): PayrollPeriodViewModel => ({
    id: payrollPeriod.id,
    type: 'payrollPeriods',
    start: payrollPeriod.period.start,
    end: new UpToAndIncludingDate(payrollPeriod.period.end),
    periodNumber: payrollPeriod.periodNumber,
    year: payrollPeriod.year,
    locked: payrollPeriod.isLocked,
});
