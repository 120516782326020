import React, { FC } from 'react';

import { DepartmentBadge } from '../../../../../../@paco/components';
import { convertDateToTime } from '../../../../../../helpers/date';
import { ShiftViewModel } from '../../../../../../models';
import getShiftPlannedUserAndWorkersCount from '../../../../helpers/getShiftPlannedUserAndWorkersCount';

import './ShiftButton.scss';

interface Props {
    shift: ShiftViewModel;
    onClick: (shiftId: string) => void;
    active: boolean;
}

const ShiftButton: FC<Props> = ({ shift, onClick, active }) => {
    const time = `${convertDateToTime(shift.start)} - ${convertDateToTime(shift.end.transformToUpToButExcludingDate().date)}`;
    const employeesCount = getShiftPlannedUserAndWorkersCount(shift);

    return (
        <button
            type="button"
            className={`shift-button ${active ? 'shift-button--active' : ''}`}
            onClick={() => onClick(shift.id)}
        >
            <div className="shift-button__department-time-container">
                { shift.department && (
                    <DepartmentBadge
                        name={shift.department.name}
                        className="shift-button__department"
                    />
                )}
                <div className="shift-button__time">
                    {time}
                </div>
            </div>
            <div className="shift-button__planned-employees">
                {`${employeesCount}/${shift.peopleLimit}`}
            </div>
        </button>
    );
};

export default ShiftButton;
