import { FC, useMemo, useState } from 'react';

import { swapArrayElements } from '../../helpers/array';
import { DashboardSmallWidget } from '../../types/dashboardTypes';
import { WidgetButton, WidgetDraggable } from './subcomponents';

import './WidgetsList.scss';

interface WidgetsListProps {
    isDraggable?: boolean;
    title: string;
    widgets: DashboardSmallWidget[];
    onWidgetsChange?: (widgets: DashboardSmallWidget[]) => void;
    className?: string;
}

const WidgetsList: FC<WidgetsListProps> = ({
    isDraggable,
    title,
    widgets,
    onWidgetsChange,
    className = '',
}) => {
    const [dragStartWidgetButton, setDragStartWidgetButton] = useState<number | undefined>();
    const [dragEnterWidgetButton, setDragEnterWidgetButton] = useState<number | undefined>();

    const widgetsState = useMemo(() => {
        if (dragStartWidgetButton !== undefined && dragEnterWidgetButton !== undefined) {
            return swapArrayElements<DashboardSmallWidget>(widgets, dragStartWidgetButton, dragEnterWidgetButton);
        }

        return widgets;
    }, [widgets, dragStartWidgetButton, dragEnterWidgetButton]);

    const handleWidgetButtonDrop = () => {
        if (onWidgetsChange) {
            onWidgetsChange(widgetsState);
            setDragStartWidgetButton(undefined);
            setDragEnterWidgetButton(undefined);
        }
    };

    const handleDeleteWidget = (deleteIndex: number) => {
        if (onWidgetsChange) {
            onWidgetsChange(widgets.filter((widget, index) => index !== deleteIndex));
        }
    };

    const handleWidgetButtonDragEnter = (index: number) => {
        setDragEnterWidgetButton(index);
    };

    const handleWidgetButtonDragStart = (index: number) => {
        setDragStartWidgetButton(index);
        setDragEnterWidgetButton(undefined);
    };

    return (
        <section className={`widgets-list ${className}`}>
            <h2 className="widgets-list__title">{title}</h2>
            <ul className="widgets-list__list">
                {widgetsState.map((widget, index) => (
                    <li
                        key={widget.type}
                        className="widgets-list__list-item"
                    >
                        {isDraggable ? (
                            <WidgetDraggable
                                key={widget.type}
                                hasPZLabel={widget.hasPZLabel}
                                isActive={dragStartWidgetButton === index}
                                index={index}
                                label={widget.label}
                                onDeleteButtonClick={handleDeleteWidget}
                                onDrop={handleWidgetButtonDrop}
                                onDragEnter={handleWidgetButtonDragEnter}
                                onDragStart={handleWidgetButtonDragStart}
                            />
                        ) : (
                            <WidgetButton
                                key={widget.type}
                                hasPZLabel={widget.hasPZLabel}
                                index={index}
                                label={widget.label}
                                onClick={handleDeleteWidget}
                            />
                        )}
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default WidgetsList;
