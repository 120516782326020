import { SelectOption } from '../components/@inputs/Select/Select';

const nationalities: SelectOption[] = [
    {
        value: 'AF',
        label: 'Afghanistan',
    },
    {
        value: 'AX',
        label: 'Åland',
    },
    {
        value: 'AL',
        label: 'Albanië',
    },
    {
        value: 'DZ',
        label: 'Algerije',
    },
    {
        value: 'AS',
        label: 'Amerikaans-Samoa',
    },
    {
        value: 'VI',
        label: 'Amerikaanse Maagdeneilanden',
    },
    {
        value: 'AD',
        label: 'Andorra',
    },
    {
        value: 'AO',
        label: 'Angola',
    },
    {
        value: 'AI',
        label: 'Anguilla',
    },
    {
        value: 'AQ',
        label: 'Antarctica',
    },
    {
        value: 'AG',
        label: 'Antigua en Barbuda',
    },
    {
        value: 'AR',
        label: 'Argentinië',
    },
    {
        value: 'AM',
        label: 'Armenië',
    },
    {
        value: 'AW',
        label: 'Aruba',
    },
    {
        value: 'AU',
        label: 'Australië',
    },
    {
        value: 'AZ',
        label: 'Azerbeidzjan',
    },
    {
        value: 'BS',
        label: 'Bahama’s',
    },
    {
        value: 'BH',
        label: 'Bahrein',
    },
    {
        value: 'BD',
        label: 'Bangladesh',
    },
    {
        value: 'BB',
        label: 'Barbados',
    },
    {
        value: 'BY',
        label: 'Belarus',
    },
    {
        value: 'BE',
        label: 'België',
    },
    {
        value: 'BZ',
        label: 'Belize',
    },
    {
        value: 'BJ',
        label: 'Benin',
    },
    {
        value: 'BM',
        label: 'Bermuda',
    },
    {
        value: 'BT',
        label: 'Bhutan',
    },
    {
        value: 'BO',
        label: 'Bolivia',
    },
    {
        value: 'BA',
        label: 'Bosnië en Herzegovina',
    },
    {
        value: 'BW',
        label: 'Botswana',
    },
    {
        value: 'BV',
        label: 'Bouveteiland',
    },
    {
        value: 'BR',
        label: 'Brazilië',
    },
    {
        value: 'IO',
        label: 'Brits Indische Oceaanterritorium',
    },
    {
        value: 'VG',
        label: 'Britse Maagdeneilanden',
    },
    {
        value: 'BN',
        label: 'Brunei',
    },
    {
        value: 'BG',
        label: 'Bulgarije',
    },
    {
        value: 'BF',
        label: 'Burkina Faso',
    },
    {
        value: 'BI',
        label: 'Burundi',
    },
    {
        value: 'KH',
        label: 'Cambodja',
    },
    {
        value: 'CA',
        label: 'Canada',
    },
    {
        value: 'BQ',
        label: 'Caribisch Nederland',
    },
    {
        value: 'CF',
        label: 'Centraal-Afrikaanse Republiek',
    },
    {
        value: 'CL',
        label: 'Chili',
    },
    {
        value: 'CN',
        label: 'China',
    },
    {
        value: 'CX',
        label: 'Christmaseiland',
    },
    {
        value: 'CC',
        label: 'Cocoseilanden',
    },
    {
        value: 'CO',
        label: 'Colombia',
    },
    {
        value: 'KM',
        label: 'Comoren',
    },
    {
        value: 'CG',
        label: 'Congo-Brazzaville',
    },
    {
        value: 'CD',
        label: 'Congo-Kinshasa',
    },
    {
        value: 'CK',
        label: 'Cookeilanden',
    },
    {
        value: 'CR',
        label: 'Costa Rica',
    },
    {
        value: 'CU',
        label: 'Cuba',
    },
    {
        value: 'CW',
        label: 'Curaçao',
    },
    {
        value: 'CY',
        label: 'Cyprus',
    },
    {
        value: 'DK',
        label: 'Denemarken',
    },
    {
        value: 'DJ',
        label: 'Djibouti',
    },
    {
        value: 'DM',
        label: 'Dominica',
    },
    {
        value: 'DO',
        label: 'Dominicaanse Republiek',
    },
    {
        value: 'DE',
        label: 'Duitsland',
    },
    {
        value: 'EC',
        label: 'Ecuador',
    },
    {
        value: 'EG',
        label: 'Egypte',
    },
    {
        value: 'SV',
        label: 'El Salvador',
    },
    {
        value: 'GQ',
        label: 'Equatoriaal-Guinea',
    },
    {
        value: 'ER',
        label: 'Eritrea',
    },
    {
        value: 'EE',
        label: 'Estland',
    },
    {
        value: 'SZ',
        label: 'eSwatini',
    },
    {
        value: 'ET',
        label: 'Ethiopië',
    },
    {
        value: 'FO',
        label: 'Faeröer',
    },
    {
        value: 'FK',
        label: 'Falklandeilanden',
    },
    {
        value: 'FJ',
        label: 'Fiji',
    },
    {
        value: 'PH',
        label: 'Filipijnen',
    },
    {
        value: 'FI',
        label: 'Finland',
    },
    {
        value: 'FR',
        label: 'Frankrijk',
    },
    {
        value: 'GF',
        label: 'Frans-Guyana',
    },
    {
        value: 'PF',
        label: 'Frans-Polynesië',
    },
    {
        value: 'TF',
        label: 'Franse Gebieden in de zuidelijke Indische Oceaan',
    },
    {
        value: 'GA',
        label: 'Gabon',
    },
    {
        value: 'GM',
        label: 'Gambia',
    },
    {
        value: 'GE',
        label: 'Georgië',
    },
    {
        value: 'GH',
        label: 'Ghana',
    },
    {
        value: 'GI',
        label: 'Gibraltar',
    },
    {
        value: 'GD',
        label: 'Grenada',
    },
    {
        value: 'GR',
        label: 'Griekenland',
    },
    {
        value: 'GL',
        label: 'Groenland',
    },
    {
        value: 'GP',
        label: 'Guadeloupe',
    },
    {
        value: 'GU',
        label: 'Guam',
    },
    {
        value: 'GT',
        label: 'Guatemala',
    },
    {
        value: 'GG',
        label: 'Guernsey',
    },
    {
        value: 'GN',
        label: 'Guinee',
    },
    {
        value: 'GW',
        label: 'Guinee-Bissau',
    },
    {
        value: 'GY',
        label: 'Guyana',
    },
    {
        value: 'HT',
        label: 'Haïti',
    },
    {
        value: 'HM',
        label: 'Heard en McDonaldeilanden',
    },
    {
        value: 'HN',
        label: 'Honduras',
    },
    {
        value: 'HU',
        label: 'Hongarije',
    },
    {
        value: 'HK',
        label: 'Hongkong SAR van China',
    },
    {
        value: 'IE',
        label: 'Ierland',
    },
    {
        value: 'IS',
        label: 'IJsland',
    },
    {
        value: 'IN',
        label: 'India',
    },
    {
        value: 'ID',
        label: 'Indonesië',
    },
    {
        value: 'IQ',
        label: 'Irak',
    },
    {
        value: 'IR',
        label: 'Iran',
    },
    {
        value: 'IM',
        label: 'Isle of Man',
    },
    {
        value: 'IL',
        label: 'Israël',
    },
    {
        value: 'IT',
        label: 'Italië',
    },
    {
        value: 'CI',
        label: 'Ivoorkust',
    },
    {
        value: 'JM',
        label: 'Jamaica',
    },
    {
        value: 'JP',
        label: 'Japan',
    },
    {
        value: 'YE',
        label: 'Jemen',
    },
    {
        value: 'JE',
        label: 'Jersey',
    },
    {
        value: 'JO',
        label: 'Jordanië',
    },
    {
        value: 'KY',
        label: 'Kaaimaneilanden',
    },
    {
        value: 'CV',
        label: 'Kaapverdië',
    },
    {
        value: 'CM',
        label: 'Kameroen',
    },
    {
        value: 'KZ',
        label: 'Kazachstan',
    },
    {
        value: 'KE',
        label: 'Kenia',
    },
    {
        value: 'KG',
        label: 'Kirgizië',
    },
    {
        value: 'KI',
        label: 'Kiribati',
    },
    {
        value: 'UM',
        label: 'Kleine afgelegen eilanden van de Verenigde Staten',
    },
    {
        value: 'KW',
        label: 'Koeweit',
    },
    {
        value: 'HR',
        label: 'Kroatië',
    },
    {
        value: 'LA',
        label: 'Laos',
    },
    {
        value: 'LS',
        label: 'Lesotho',
    },
    {
        value: 'LV',
        label: 'Letland',
    },
    {
        value: 'LB',
        label: 'Libanon',
    },
    {
        value: 'LR',
        label: 'Liberia',
    },
    {
        value: 'LY',
        label: 'Libië',
    },
    {
        value: 'LI',
        label: 'Liechtenstein',
    },
    {
        value: 'LT',
        label: 'Litouwen',
    },
    {
        value: 'LU',
        label: 'Luxemburg',
    },
    {
        value: 'MO',
        label: 'Macau SAR van China',
    },
    {
        value: 'MG',
        label: 'Madagaskar',
    },
    {
        value: 'MW',
        label: 'Malawi',
    },
    {
        value: 'MV',
        label: 'Maldiven',
    },
    {
        value: 'MY',
        label: 'Maleisië',
    },
    {
        value: 'ML',
        label: 'Mali',
    },
    {
        value: 'MT',
        label: 'Malta',
    },
    {
        value: 'MA',
        label: 'Marokko',
    },
    {
        value: 'MH',
        label: 'Marshalleilanden',
    },
    {
        value: 'MQ',
        label: 'Martinique',
    },
    {
        value: 'MR',
        label: 'Mauritanië',
    },
    {
        value: 'MU',
        label: 'Mauritius',
    },
    {
        value: 'YT',
        label: 'Mayotte',
    },
    {
        value: 'MX',
        label: 'Mexico',
    },
    {
        value: 'FM',
        label: 'Micronesia',
    },
    {
        value: 'MD',
        label: 'Moldavië',
    },
    {
        value: 'MC',
        label: 'Monaco',
    },
    {
        value: 'MN',
        label: 'Mongolië',
    },
    {
        value: 'ME',
        label: 'Montenegro',
    },
    {
        value: 'MS',
        label: 'Montserrat',
    },
    {
        value: 'MZ',
        label: 'Mozambique',
    },
    {
        value: 'MM',
        label: 'Myanmar (Birma)',
    },
    {
        value: 'NA',
        label: 'Namibië',
    },
    {
        value: 'NR',
        label: 'Nauru',
    },
    {
        value: 'NL',
        label: 'Nederland',
    },
    {
        value: 'NP',
        label: 'Nepal',
    },
    {
        value: 'NI',
        label: 'Nicaragua',
    },
    {
        value: 'NC',
        label: 'Nieuw-Caledonië',
    },
    {
        value: 'NZ',
        label: 'Nieuw-Zeeland',
    },
    {
        value: 'NE',
        label: 'Niger',
    },
    {
        value: 'NG',
        label: 'Nigeria',
    },
    {
        value: 'NU',
        label: 'Niue',
    },
    {
        value: 'KP',
        label: 'Noord-Korea',
    },
    {
        value: 'MK',
        label: 'Noord-Macedonië',
    },
    {
        value: 'MP',
        label: 'Noordelijke Marianen',
    },
    {
        value: 'NO',
        label: 'Noorwegen',
    },
    {
        value: 'NF',
        label: 'Norfolk',
    },
    {
        value: 'UG',
        label: 'Oeganda',
    },
    {
        value: 'UA',
        label: 'Oekraïne',
    },
    {
        value: 'UZ',
        label: 'Oezbekistan',
    },
    {
        value: 'OM',
        label: 'Oman',
    },
    {
        value: 'TL',
        label: 'Oost-Timor',
    },
    {
        value: 'AT',
        label: 'Oostenrijk',
    },
    {
        value: 'PK',
        label: 'Pakistan',
    },
    {
        value: 'PW',
        label: 'Palau',
    },
    {
        value: 'PS',
        label: 'Palestijnse gebieden',
    },
    {
        value: 'PA',
        label: 'Panama',
    },
    {
        value: 'PG',
        label: 'Papoea-Nieuw-Guinea',
    },
    {
        value: 'PY',
        label: 'Paraguay',
    },
    {
        value: 'PE',
        label: 'Peru',
    },
    {
        value: 'PN',
        label: 'Pitcairneilanden',
    },
    {
        value: 'PL',
        label: 'Polen',
    },
    {
        value: 'PT',
        label: 'Portugal',
    },
    {
        value: 'PR',
        label: 'Puerto Rico',
    },
    {
        value: 'QA',
        label: 'Qatar',
    },
    {
        value: 'RE',
        label: 'Réunion',
    },
    {
        value: 'RO',
        label: 'Roemenië',
    },
    {
        value: 'RU',
        label: 'Rusland',
    },
    {
        value: 'RW',
        label: 'Rwanda',
    },
    {
        value: 'KN',
        label: 'Saint Kitts en Nevis',
    },
    {
        value: 'LC',
        label: 'Saint Lucia',
    },
    {
        value: 'VC',
        label: 'Saint Vincent en de Grenadines',
    },
    {
        value: 'BL',
        label: 'Saint-Barthélemy',
    },
    {
        value: 'MF',
        label: 'Saint-Martin',
    },
    {
        value: 'PM',
        label: 'Saint-Pierre en Miquelon',
    },
    {
        value: 'SB',
        label: 'Salomonseilanden',
    },
    {
        value: 'WS',
        label: 'Samoa',
    },
    {
        value: 'SM',
        label: 'San Marino',
    },
    {
        value: 'ST',
        label: 'Sao Tomé en Principe',
    },
    {
        value: 'SA',
        label: 'Saoedi-Arabië',
    },
    {
        value: 'SN',
        label: 'Senegal',
    },
    {
        value: 'RS',
        label: 'Servië',
    },
    {
        value: 'SC',
        label: 'Seychellen',
    },
    {
        value: 'SL',
        label: 'Sierra Leone',
    },
    {
        value: 'SG',
        label: 'Singapore',
    },
    {
        value: 'SH',
        label: 'Sint-Helena',
    },
    {
        value: 'SX',
        label: 'Sint-Maarten',
    },
    {
        value: 'SI',
        label: 'Slovenië',
    },
    {
        value: 'SK',
        label: 'Slowakije',
    },
    {
        value: 'SD',
        label: 'Soedan',
    },
    {
        value: 'SO',
        label: 'Somalië',
    },
    {
        value: 'ES',
        label: 'Spanje',
    },
    {
        value: 'SJ',
        label: 'Spitsbergen en Jan Mayen',
    },
    {
        value: 'LK',
        label: 'Sri Lanka',
    },
    {
        value: 'SR',
        label: 'Suriname',
    },
    {
        value: 'SY',
        label: 'Syrië',
    },
    {
        value: 'TJ',
        label: 'Tadzjikistan',
    },
    {
        value: 'TW',
        label: 'Taiwan',
    },
    {
        value: 'TZ',
        label: 'Tanzania',
    },
    {
        value: 'TH',
        label: 'Thailand',
    },
    {
        value: 'TG',
        label: 'Togo',
    },
    {
        value: 'TK',
        label: 'Tokelau',
    },
    {
        value: 'TO',
        label: 'Tonga',
    },
    {
        value: 'TT',
        label: 'Trinidad en Tobago',
    },
    {
        value: 'TD',
        label: 'Tsjaad',
    },
    {
        value: 'CZ',
        label: 'Tsjechië',
    },
    {
        value: 'TN',
        label: 'Tunesië',
    },
    {
        value: 'TR',
        label: 'Turkije',
    },
    {
        value: 'TM',
        label: 'Turkmenistan',
    },
    {
        value: 'TC',
        label: 'Turks- en Caicoseilanden',
    },
    {
        value: 'TV',
        label: 'Tuvalu',
    },
    {
        value: 'UY',
        label: 'Uruguay',
    },
    {
        value: 'VU',
        label: 'Vanuatu',
    },
    {
        value: 'VA',
        label: 'Vaticaanstad',
    },
    {
        value: 'VE',
        label: 'Venezuela',
    },
    {
        value: 'GB',
        label: 'Verenigd Koninkrijk',
    },
    {
        value: 'AE',
        label: 'Verenigde Arabische Emiraten',
    },
    {
        value: 'US',
        label: 'Verenigde Staten',
    },
    {
        value: 'VN',
        label: 'Vietnam',
    },
    {
        value: 'WF',
        label: 'Wallis en Futuna',
    },
    {
        value: 'EH',
        label: 'Westelijke Sahara',
    },
    {
        value: 'ZM',
        label: 'Zambia',
    },
    {
        value: 'ZW',
        label: 'Zimbabwe',
    },
    {
        value: 'ZA',
        label: 'Zuid-Afrika',
    },
    {
        value: 'GS',
        label: 'Zuid-Georgia en Zuidelijke Sandwicheilanden',
    },
    {
        value: 'KR',
        label: 'Zuid-Korea',
    },
    {
        value: 'SS',
        label: 'Zuid-Soedan',
    },
    {
        value: 'SE',
        label: 'Zweden',
    },
    {
        value: 'CH',
        label: 'Zwitserland',
    },
];

export default nationalities;
