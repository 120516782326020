import React, { FC, useEffect, useState } from 'react';

import { Col, Row } from 'reactstrap';

import { Absence, LeaveOfAbsence, WeekWithHours } from '../../../../models';
import getGroupsFromWeeksWithHours from '../../helpers/getGroupsFromWeeksWithHours';
import { onInputChangeType, WeekGroup } from '../../models/WeekGroup';
import WeekInput from '../WeekInput/WeekInput';

interface Props {
    weeks: WeekWithHours[];
    type: Absence['type'] | LeaveOfAbsence['type'];
    disabled: boolean;
    hasError?: boolean;
    onInputChange?: onInputChangeType,
}


const WeekRows: FC<Props> = ({
    weeks = [],
    type,
    disabled,
    hasError = false,
    onInputChange,
}) => {
    const [groups, setGroups] = useState<WeekGroup[]>([]);

    useEffect(() => {
        setGroups(getGroupsFromWeeksWithHours(weeks));
    }, [weeks]);

    return (
        <>
            {
                groups.map((group) => (
                    <Row key={group.id}>
                        {
                            group.weeks.map(weekday => (
                                <WeekInput
                                    key={weekday.id}
                                    weekday={weekday}
                                    type={type}
                                    hasError={hasError}
                                    disabled={disabled}
                                    onChange={onInputChange}
                                />
                            ))
                        }
                        {
                            group.weeks.length === 1 ? <Col /> : null
                        }
                    </Row>
                ))
            }
        </>
    );
};

export default WeekRows;
