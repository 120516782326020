import {
    addDays,
    compareTimeStrings,
    convertDateToApiParamDateTime,
    formatDate,
} from '../../helpers/date';
import { EditShiftPlanningFormData, ShiftPlanningViewModel } from '../../models';
import { AddBaseScheduleShiftPlanningRequest, EditBaseScheduleShiftPlanningColorRequest, EditBaseScheduleShiftPlanningRequest } from './BaseScheduleShiftPlanning';

export const transformEditBaseScheduleShiftPlanningFormData = (
    formData: EditShiftPlanningFormData,
    shiftPlanningId: string,
    date: Date,
): EditBaseScheduleShiftPlanningRequest => {
    const endTimeIsLater = compareTimeStrings(formData.startTime, formData.endTime);
    const startDate = formatDate(date, 'yyyy-MM-dd');
    const endDate = formatDate(addDays(date, endTimeIsLater ? 1 : 0), 'yyyy-MM-dd');
    const start = `${startDate} ${formData.startTime}:00`;
    const end = `${endDate} ${formData.endTime}:00`;

    return {
        type: 'baseScheduleShiftPlannings',
        id: shiftPlanningId,
        attributes: {
            start,
            end,
        },
    };
};

export const transformAddBaseScheduleShiftPlanningRequest = (baseScheduleShiftId: string, userId: string): AddBaseScheduleShiftPlanningRequest => ({
    type: 'baseScheduleShiftPlannings',
    attributes: {
        color: null,
    },
    relationships: {
        baseScheduleShift: {
            data: {
                type: 'baseScheduleShifts',
                id: baseScheduleShiftId,
            },
        },
        user: {
            data: {
                type: 'users',
                id: userId,
            },
        },
    },
});

export const transformBaseScheduleShiftPlanningToRequest = (baseScheduleShiftPlanning: ShiftPlanningViewModel, baseScheduleShiftId: string): AddBaseScheduleShiftPlanningRequest => ({
    type: 'baseScheduleShiftPlannings',
    attributes: {
        start: convertDateToApiParamDateTime(baseScheduleShiftPlanning.start),
        end: convertDateToApiParamDateTime(baseScheduleShiftPlanning.end.transformToAPIDatetime()),
        color: baseScheduleShiftPlanning.color || '',
    },
    relationships: {
        baseScheduleShift: {
            data: {
                type: 'baseScheduleShifts',
                id: baseScheduleShiftId,
            },
        },
        user: {
            data: {
                type: 'users',
                id: baseScheduleShiftPlanning.user?.id || '',
            },
        },
    },
});

export const transformEditBaseScheduleShiftPlanningColorRequest = (
    shiftPlanningId: string,
    color?: string,
): EditBaseScheduleShiftPlanningColorRequest => ({
    type: 'baseScheduleShiftPlannings',
    id: shiftPlanningId,
    attributes: {
        color: color || null,
    },
});
