import { translate } from '../../../../helpers/translations/translator';
import { TrackType } from '../../../../models';

export default function getAddTrackError(
    start: Date,
    end: Date | null,
    trackType: TrackType,
    hasPayrollPeriodError: boolean,
): string | null {
    if (trackType === TrackType.finished && end && start >= end) {
        return translate('pages.tracks.endDateAndEndTimeNeedsToBeLaterThanStartDate');
    }

    if (trackType === TrackType.open && start > new Date()) {
        return translate('pages.tracks.trackCanNotStartInFuture');
    }

    if (hasPayrollPeriodError) {
        return translate('common.datesOverlapWithLockedPayrollPeriod');
    }

    return null;
}
