import { TimeModeType } from '../../@paco/types';
import { getUsers } from '../../helpers/api-ts/userWithAvailability';
import { addWeeks } from '../../helpers/date';
import { BasicUser } from '../../models';

export interface UserWithPlannedHours {
    userId: string;
    plannedHours: number;
}

function transformBasicUserToUserWithWorkedhours(
    userIds: string[],
    workweeksWithUsers: BasicUser[][],
): UserWithPlannedHours[] {
    return userIds.map((userId) => {
        const users = workweeksWithUsers.map((workweek) => workweek
            .find((user) => user.id === userId));
        const plannedHours = users
            .reduce((total, user) => total + (user ? user.plannedHoursInWeek : 0), 0);

        return {
            userId,
            plannedHours,
        };
    });
}

export default async function getUsersPlannedHours(
    startDate: Date,
    userIds: string[],
    timeMode: TimeModeType,
): Promise<UserWithPlannedHours[]> {
    // TODO: Change when backend provided endpoint for multiple workweeks
    const dates = timeMode === 'week' ? [startDate] : [
        startDate,
        addWeeks(startDate, 1),
        addWeeks(startDate, 2),
        addWeeks(startDate, 3),
    ];

    const response = await Promise.all(dates.map(date => getUsers({
        workweek: date,
        filter: { userIds },
    })));

    const workweekWithUsers = response.map(user => user.data);
    return transformBasicUserToUserWithWorkedhours(userIds, workweekWithUsers);
}
