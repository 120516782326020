import { FC } from 'react';

import { Button } from '../../../components';
import { IconButton } from '../../../compositions';
import { ConnectedCalendarNavigation, ConnectedUserSearch } from '../../../connectors';
import trans from '../../../helpers/trans';
import { TimeModeType } from '../../../types';

import './ShiftsCalendarActionBar.scss';

interface ShiftsCalendarActionBarProps {
    handleNotifyEmployeesButtonClick: () => void;
    onNavigationChange: () => void;
    toggleExportModalIsOpen: () => void;
    className?: string;
}

const calendarNavigationTimeModes = [
    TimeModeType.week,
    TimeModeType.month,
    TimeModeType.period,
    TimeModeType.custom,
];

const ShiftsCalendarActionBar: FC<ShiftsCalendarActionBarProps> = ({
    handleNotifyEmployeesButtonClick,
    onNavigationChange,
    toggleExportModalIsOpen,
    className = '',
}) => (
    <div className={`shifts-calendar-action-bar ${className}`}>
        <ConnectedCalendarNavigation
            showConceptModeButtons
            showMobileControls
            timeModes={calendarNavigationTimeModes}
            onCalendarTypeChange={onNavigationChange}
            className="shifts-calendar-action-bar__navigation"
        />
        <IconButton
            icon="arrow-down"
            text={trans('common.export')}
            onClick={toggleExportModalIsOpen}
            className="shifts-calendar-action-bar__export-button"
            iconClassName="shifts-calendar-action-bar__export-button-icon"
        />
        <ConnectedUserSearch className="shifts-calendar-action-bar__user-search" />
        <Button
            text={trans('common.notifyEmployees')}
            onClick={handleNotifyEmployeesButtonClick}
            className="shifts-calendar-action-bar__notify-employees-button"
        />
        <div className="shifts-calendar-action-bar__mobile-buttons">
            <IconButton
                hideLabel
                icon="bell"
                text={trans('common.notifyEmployees')}
                onClick={handleNotifyEmployeesButtonClick}
                className="shifts-calendar-action-bar__notify-employees-button-mobile"
                iconClassName="shifts-calendar-action-bar__notify-employees-button-mobile-icon"
            />
            <IconButton
                hideLabel
                icon="arrow-down"
                text={trans('common.export')}
                onClick={toggleExportModalIsOpen}
                className="shifts-calendar-action-bar__export-button-mobile"
                iconClassName="shifts-calendar-action-bar__export-button-mobile-icon"
            />
            <ConnectedUserSearch className="shifts-calendar-action-bar__user-search-mobile" />
        </div>
    </div>
);
export default ShiftsCalendarActionBar;
