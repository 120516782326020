import { Department } from '../../../entities/Department/Department';
import { reduceDepartmentGroupsFromDepartments } from '../../../entities/Department/DepartmentHelpers';
import { DepartmentGroupIndex } from '../../../entities/DepartmentGroup/DepartmentGroup';
import { transformDepartmentGroupToDepartmentGroupIndex } from '../../../entities/DepartmentGroup/DepartmentGroupTransformers';

const sortByMainDepartmentPresence = (a: DepartmentGroupIndex, b: DepartmentGroupIndex, mainDepartment?: Department): number => {
    if (!mainDepartment) {
        return 0;
    }

    const departmentIndexA = a.departments.findIndex(department => department.id === mainDepartment.id);
    const departmentIndexB = b.departments.findIndex(department => department.id === mainDepartment.id);

    return departmentIndexB - departmentIndexA;
};

const reduceDepartmentsToDepartmentGroupIndices = (departments: Department[], mainDepartment?: Department): DepartmentGroupIndex[] => {
    const groups = reduceDepartmentGroupsFromDepartments(departments);
    const groupIndices = groups
        .map(group => {
            const departmentsInGroup = departments.filter(department => department.departmentGroup?.id === group.id);
            return transformDepartmentGroupToDepartmentGroupIndex(group, departmentsInGroup);
        })
        .sort((a, b) => a?.name?.localeCompare(b?.name))
        .sort((a, b) => sortByMainDepartmentPresence(a, b, mainDepartment));

    return groupIndices;
};

export default reduceDepartmentsToDepartmentGroupIndices;
