import { FC, ReactElement } from 'react';

import { SidebarPageWrapper } from '../../components';
import { Sidebar } from '../../compositions';
import { ConnectedKnowledgeBase, ConnectedKnowledgeBaseSearch, ConnectedKnowledgeBaseSidebar } from '../../connectors';

import './KnowledgeBasePage.scss';

const KnowledgeBasePage: FC = (): ReactElement => (
    <div className="knowledge-base-page">
        <ConnectedKnowledgeBaseSearch />
        <SidebarPageWrapper>
            <Sidebar
                showSidebarToggle={false}
                className="knowledge-base-page__sidebar"
                contentClassName="knowledge-base-page__sidebar-content"
            >
                <ConnectedKnowledgeBaseSidebar />
            </Sidebar>
            <main className="knowledge-base-page__main">
                <ConnectedKnowledgeBase />
            </main>
        </SidebarPageWrapper>
    </div>
);

export default KnowledgeBasePage;
