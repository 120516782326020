import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCheckboxCheck: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 14 14">
        <path d="M0,7.45l5.35,4.8L14,3.83l-2.31-2.08L5.35,7.9L2.27,5.15L0,7.45z" />
    </svg>
);

export default IconCheckboxCheck;
