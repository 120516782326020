import {
    APP_SAGA_ADD_TOAST,
    APP_SAGA_GET_GENERAL_DATA,
    APP_SAGA_GET_HIGH_PRIO_NOTIFICATIONS_INTERVAL,
    APP_SAGA_GET_LOW_PRIO_NOTIFICATIONS_INTERVAL,
    APP_SAGA_GET_MED_PRIO_NOTIFICATIONS_INTERVAL,
    APP_SAGA_GET_PAYROLL_PERIODS,
    APP_SAGA_INIT,
    APP_SAGA_LOGOUT,
    APP_SAGA_NOTIFY_EMPLOYEES,
    APP_SAGA_PUSH_HISTORY,
    APP_SAGA_ROUTE_CHANGE,
    APP_SAGA_SET_NOTIFICATIONS_INTERVAL,
    APP_SAGA_SIDEBAR_RESIZE,
    APP_SAGA_SWITCH_MODE,
} from '../actionTypes';

export const loading = toggle => ({ type: 'APP_SAGA_SET_LOADING', loading: toggle });
export const setAppError = error => ({ type: 'APP_SAGA_SET_ERROR', error });
export const setAppInit = () => ({ type: APP_SAGA_INIT });
export const getAppGeneralData = () => ({ type: APP_SAGA_GET_GENERAL_DATA });
export const setAppToast = (toast, toastType) => ({ type: APP_SAGA_ADD_TOAST, toast, toastType });
export const getHighPrioNotifications = () => ({ type: APP_SAGA_GET_HIGH_PRIO_NOTIFICATIONS_INTERVAL });
export const getMedPrioNotifications = () => ({ type: APP_SAGA_GET_MED_PRIO_NOTIFICATIONS_INTERVAL });
export const getLowPrioNotifications = () => ({ type: APP_SAGA_GET_LOW_PRIO_NOTIFICATIONS_INTERVAL });
export const setAppNotificationsInterval = dispatch => ({
    type: APP_SAGA_SET_NOTIFICATIONS_INTERVAL, dispatch,
});
export const getPayrollPeriods = () => ({ type: APP_SAGA_GET_PAYROLL_PERIODS });
export const setAppHistory = location => ({ type: APP_SAGA_PUSH_HISTORY, location });
export const setAppRouteChange = () => ({ type: APP_SAGA_ROUTE_CHANGE });
export const setAppSidebarResize = () => ({ type: APP_SAGA_SIDEBAR_RESIZE });
export const switchMode = () => ({ type: APP_SAGA_SWITCH_MODE });
export const notifyUsers = data => ({ type: APP_SAGA_NOTIFY_EMPLOYEES, data });
export const logout = () => ({ type: APP_SAGA_LOGOUT });
