/* eslint-disable react/no-array-index-key */

import { FC } from 'react';

import { DistrictDivisionDistrict } from '../../../../entities/DistrictDivisionDistrict/DistrictDivisionDistrict';
import { DistrictDivisionDistrictPlanning } from '../../../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { Setting } from '../../../../entities/Setting/Setting';
import { DistrictDivisionDistrictPlanningItem } from '../index';

import './DistrictDivisionDistrictItem.scss';

interface DistrictDivisionDistrictItemProps {
    isDraggingDistrictDivisionDistrictPlanning: boolean;
    isDraggingShiftPlanning: boolean;
    districtDivisionDistrict: DistrictDivisionDistrict;
    settings: Setting[];
    showDistrictDivisionDistrictPlanningAddButton: boolean;
    showDistrictDivisionDistrictPlanningDeleteButton: boolean;
    onDistrictDivisionDistrictPlanningAddCommentButtonClick: (districtDivisionDistrictPlanning: DistrictDivisionDistrictPlanning) => void;
    onDistrictDivisionDistrictPlanningDeleteButtonClick: (districtDivisionDistrictPlanningId: string, type: string) => void;
    onDistrictDivisionDistrictPlanningDragEnd: () => void;
    onDistrictDivisionDistrictPlanningDragStart: (districtDivisionDistrictPlanning: DistrictDivisionDistrictPlanning) => void;
    onDistrictDivisionDistrictPlanningDrop: (districtDivisionDistrictId: string, index: number, droppedDistrictDivisionDistrict?: DistrictDivisionDistrictPlanning) => void;
    className?: string;
}

const DistrictDivisionDistrictItem: FC<DistrictDivisionDistrictItemProps> = ({
    isDraggingDistrictDivisionDistrictPlanning,
    isDraggingShiftPlanning,
    districtDivisionDistrict,
    settings,
    showDistrictDivisionDistrictPlanningAddButton,
    showDistrictDivisionDistrictPlanningDeleteButton,
    onDistrictDivisionDistrictPlanningAddCommentButtonClick,
    onDistrictDivisionDistrictPlanningDeleteButtonClick,
    onDistrictDivisionDistrictPlanningDragEnd,
    onDistrictDivisionDistrictPlanningDragStart,
    onDistrictDivisionDistrictPlanningDrop,
    className = '',
}) => {
    const handleDistrictDivisionDistrictPlanningDrop = (index: number, targetDistrictDivisionDistrict?: DistrictDivisionDistrictPlanning) => {
        onDistrictDivisionDistrictPlanningDrop(districtDivisionDistrict.id, index, targetDistrictDivisionDistrict);
    };

    return (
        <div className={`district-division-district-item ${className}`}>
            <div className="district-division-district-item__header">
                {districtDivisionDistrict.name}
            </div>

            <div className="district-division-district-item__plannings">
                {new Array(districtDivisionDistrict.maxAmountOfEmployees)
                    .fill(null)
                    .map((value, index) => (
                        <DistrictDivisionDistrictPlanningItem
                            key={index}
                            isDraggingDistrictDivisionDistrictPlanning={isDraggingDistrictDivisionDistrictPlanning}
                            isDraggingShiftPlanning={isDraggingShiftPlanning}
                            showAddButton={showDistrictDivisionDistrictPlanningAddButton}
                            showDeleteButton={showDistrictDivisionDistrictPlanningDeleteButton}
                            index={index}
                            planning={districtDivisionDistrict.districtDivisionDistrictPlannings.find(planning => planning.planningOrder === index)}
                            settings={settings}
                            onAddCommentButtonClick={onDistrictDivisionDistrictPlanningAddCommentButtonClick}
                            onDeleteButtonClick={onDistrictDivisionDistrictPlanningDeleteButtonClick}
                            onDragStart={onDistrictDivisionDistrictPlanningDragStart}
                            onDragEnd={onDistrictDivisionDistrictPlanningDragEnd}
                            onDrop={handleDistrictDivisionDistrictPlanningDrop}
                            className="district-division-district-item__planning"
                        />
                    ))}
            </div>
        </div>
    );
};

export default DistrictDivisionDistrictItem;

/* eslint-enable react/no-array-index-key */
