import { TemporaryWorker } from '../../@paco/entities/TemporaryWorker/TemporaryWorker';
import { addSeconds, isEqual } from '../../@paco/helpers/date';
import { ShiftDraggable, ShiftViewModel } from '../../models';

export const isTemporaryWorkerTimeDeviated = (
    temporaryWorker: TemporaryWorker,
    shift: ShiftViewModel | ShiftDraggable,
): boolean => {
    const startDatesAreEqual = isEqual(temporaryWorker.period.start, shift.start);
    const endDatesAreEqual = isEqual(addSeconds(temporaryWorker.period.end, -1), shift.end.date);

    return !startDatesAreEqual || !endDatesAreEqual;
};
