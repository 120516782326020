import axios, { AxiosResponse } from 'axios';

import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { PAGE_SIZES, USERS } from '../../constants';
import {
    ApiResponse,
    Filter,
    User,
    UserResource,
    UsersSortType,
    UserViewModel,
    UserWithPersonResource,
    UserWithPersonViewModel,
} from '../../models';
import { UsersSorting } from '../../redux/management-ts/managementModels';
import transformUserResource, { transformUserResourceToUserWithPerson } from '../../services/UserService/transformUserResource';

const getUsersSortType = (type: UsersSortType): string => {
    switch (type) {
        case 'employeeNumber':
            return 'r.employeeNumber';
        case 'lastname':
            return 'person.lastname';
        default:
            return 'person.firstname';
    }
};

export default async function getUsers(
    filter: Filter,
    departments: string[],
    paginationNumber: number,
    sorting: UsersSorting,
    includes: string[],
): Promise<AxiosResponse<User[]>> {
    const {
        onlyShowMainDepartment,
        management,
        userTypes,
    } = filter;
    const orderKey = getUsersSortType(sorting.active);
    const orderValue = sorting[sorting.active].ascending ? 'ASC' : 'DESC';
    const size = PAGE_SIZES.MANAGEMENT[USERS];

    return axios.get(
        `${process.env.REACT_APP_API_URL}/users`,
        {
            params: {
                include: includes.join(','),
                departments: onlyShowMainDepartment ? [] : departments,
                mainDepartments: onlyShowMainDepartment ? departments : [],
                search: filter.employeeSearch,
                ...(management && { show_inactive_users: management.showInactiveUsers ? 1 : 0 }),
                'page[number]': paginationNumber || 1,
                'page[size]': size,
                orderBy: JSON.stringify({ [orderKey]: orderValue }),
                showUncoupledLoketUsers: management ? management.showUncoupledLoketUsers : undefined,
                roles: (userTypes && userTypes.length > 0) ? JSON.stringify(userTypes) : undefined,
            },
        },
    );
}

export const getEmployeesOfDepartment = async (
    department: string,
    includes: string[] = [],
): Promise<UserViewModel[]> => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`,
        {
            params: {
                include: includes.join(','),
                departments: [department],
                notIncludingRoles: JSON.stringify(['viewer']),
                orderBy: JSON.stringify({ 'person.firstname': 'ASC' }),
            },
        },
    ) as unknown as ApiResponse<UserResource[]>;

    return response.data.map(user => transformUserResource(
        user,
        user.departments,
        user.roles,
        user.employmentType,
    ));
};

export const getEmployeesInWorkWeek = async (
    date: Date,
    departments: string[],
    includes: string[] = [],
): Promise<UserWithPersonViewModel[]> => {
    const formattedDate = formatDate(date, DateFormat.apiDateTime);

    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/workweek/${formattedDate}`,
        {
            params: {
                include: includes.join(','),
                notIncludingRoles: JSON.stringify(['viewer']),
                departments: (departments && departments.length > 0) ? departments : undefined,
                orderBy: JSON.stringify({ 'person.firstname': 'ASC' }),
            },
        },
    ) as unknown as ApiResponse<UserWithPersonResource[]>;

    return response.data.map((user) => transformUserResourceToUserWithPerson(
        user,
        user.person,
        user.departments,
        user.roles,
        user.employmentType,
    ));
};
