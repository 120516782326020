import React, { FC } from 'react';

import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

import { translate } from '../../helpers/translations/translator';

import './CommentsModal.scss';

export interface Comment {
    id: string;
    user: string;
    body: string;
}

export interface CommentsModalProps {
    isOpen: boolean;
    onClose: () => void;
    reviewedByUser?: string;
    title: string;
    comments: Comment[];
}

const CommentsModal: FC<CommentsModalProps> = ({
    isOpen,
    onClose,
    reviewedByUser,
    title,
    comments = [],
}) => (
    <Modal
        size="lg"
        isOpen={isOpen}
    >
        <ModalHeader>{title}</ModalHeader>
        { reviewedByUser && (
            <ModalHeader>
                <div className="modal-subtitle comments-modal__reviewed-by">
                    {translate('pages.management.reviewedBy')}
                </div>
                <div className="comments-modal__reviewed-by-user">
                    {reviewedByUser}
                </div>
            </ModalHeader>
        )}
        <ModalBody>
            <div className="modal-subtitle comments-modal__comments-title">
                { comments.length === 0 ? translate('pages.management.noComments') : translate('common.notes')}
            </div>
            { comments.map(comment => (
                <div key={comment.id} className="comments-modal__comment">
                    <span className="comments-modal__comment-user">{comment.user}</span>
                    {` - ${comment.body}`}
                </div>
            )) }
        </ModalBody>
        <ModalFooter>
            <Button
                type="button"
                color="link"
                id="modal-close"
                onClick={onClose}
            >
                {translate('common.close')}
            </Button>
        </ModalFooter>
    </Modal>
);

export default CommentsModal;
