import axios from 'axios';

import {
    ApiResponse,
    DataWithPagination,
    DocumentFormData,
    DocumentResource,
    DocumentViewModel,
    Filter,
    PaginationRequestApiParams,
} from '../../models';
import transformDocumentResource from '../../services/DocumentService/transformDocumentResource';
import { getObjProperty } from '../index';

export interface GetDocumentParams {
    filter: Filter;
    departments: string[];
    pagination?: PaginationRequestApiParams;
}

export async function getAllDocuments(params: GetDocumentParams): Promise<DataWithPagination<DocumentViewModel[]>> {
    const { filter, pagination, departments } = params;
    const {
        onlyShowMainDepartment,
        userTypes,
        documentTypes,
    } = filter;
    const { number, size } = pagination || {};
    const include = ['owner', 'documentType', 'roleAccess', 'departmentAccess', 'departmentAccess.group'];
    const filteredDepartments = (departments && departments.length > 0) ? JSON.stringify(departments) : undefined;

    const response = await axios
        .get(
            `${process.env.REACT_APP_API_URL}/files`,
            {
                params: {
                    include: include.join(','),
                    departments: onlyShowMainDepartment ? [] : filteredDepartments,
                    mainDepartments: onlyShowMainDepartment ? departments : [],
                    search: params.filter.search || [],
                    documentTypes,
                    notLinkedUsers: true,
                    roles: (userTypes && userTypes.length > 0) ? JSON.stringify(userTypes) : undefined,
                    ...(number && { 'page[number]': number }),
                    ...(size && { 'page[size]': size }),
                },
            },
        ) as unknown as ApiResponse<DocumentResource[]>;

    return {
        data: response.data.map((document) => transformDocumentResource(
            document,
            document.documentType,
            document.roleAccess,
            document.departmentAccess,
            document.owner,
        )),
        pagination: response.meta.pagination,
    };
}

const generateDocumentBodyData = (data: DocumentFormData) => {
    const {
        id,
        name,
        ext,
        base64,
        documentType,
        roles,
        departments,
    } = data;
    const fileName = ext ? `${name}.${ext}` : name;
    const mimeType = getObjProperty(data, 'file.type');
    const content = base64;

    return {
        type: 'files',
        id,
        attributes: {
            ...(fileName && { fileName }),
            ...(name && { displayName: name }),
            ...(content && { content }),
            ...(mimeType && { mimeType }),
        },
        relationships: {
            documentType: {
                data: {
                    type: 'documentType',
                    id: documentType,
                },
            },
            roleAccess: {
                data: roles && roles.map(roleId => ({
                    type: 'roles',
                    id: roleId,
                })),
            },
            departmentAccess: {
                data: departments && departments.map(department => ({
                    type: 'departments',
                    id: department.id,
                })),
            },
        },
    };
};

export const postDocument = (data: DocumentFormData) => {
    const body = generateDocumentBodyData(data);

    return axios.post(`${process.env.REACT_APP_API_URL}/files/`, { data: body });
};

export const patchDocument = (data: DocumentFormData) => {
    const body = generateDocumentBodyData(data);

    return axios.patch(`${process.env.REACT_APP_API_URL}/files/${data.id}`, { data: body });
};

export const delDocument = (id: string) => axios.delete(`${process.env.REACT_APP_API_URL}/files/${id}`);
