import React, { FC, useEffect, useState } from 'react';

import { generateEventPath } from '../../helpers';
import { TemporaryWorkerViewModel, UserViewModel } from '../../models';
import UserColor from '../UserColor/UserColor';
import ColorPicker from './subcomponents/ColorPicker';

import './UserColorPicker.scss';


interface UserColorPickerProps {
    id: string;
    className?: string;
    color: string | null;
    onColorSelect: (
        userId: string,
        shiftPlanningId: string,
        userType: UserViewModel['type'] | TemporaryWorkerViewModel['type'],
        color: string | null,
    ) => void;
    userId: string;
    userType: UserViewModel['type'] | TemporaryWorkerViewModel['type'];
    shiftPlanningId: string;
}

const UserColorPicker: FC<UserColorPickerProps> = ({
    id,
    className,
    color,
    onColorSelect,
    userId,
    userType,
    shiftPlanningId,
}) => {
    const [active, setActive] = useState(false);

    const onWindowClick = (e: MouseEvent) => {
        const elements = generateEventPath(e.target);
        const outside = !elements.find((element: HTMLElement) => element.id === id);
        if (outside) {
            setActive(false);
        }
    };

    useEffect(() => {
        window.addEventListener('click', onWindowClick);
        return () => {
            window.removeEventListener('click', onWindowClick);
        };
    });

    const onUserColorFocus = () => {
        setActive(!active);
    };

    const onColorPickerClick = (newColor: string | null) => {
        onColorSelect(userId, shiftPlanningId, userType, newColor);
        setActive(false);
    };

    return (
        <div id={id} className={`user-color-picker ${className || ''}`}>
            <UserColor
                color={color}
                onFocus={onUserColorFocus}
                size="small"
            />
            {active && (
                <ColorPicker onColorClick={onColorPickerClick} />
            )}
        </div>
    );
};

export default UserColorPicker;
