import { GenderType, IdentificationType } from '../../types';
import { transformToLoketEmployment } from '../LoketEmployment/LoketEmploymentTransformers';
import { transformToBasicPerson } from '../Person/PersonTransformers';
import {
    EmployeeSyncResult,
    EmployeeSyncResultResource,
    FullLoketUser,
    FullLoketUserResource,
    LoketAddress,
    LoketAddressResource,
    LoketUser,
    LoketUserResource,
} from './LoketUser';

export const transformToLoketUser = (resource: LoketUserResource): LoketUser => ({
    id: resource.id,
    person: {
        firstname: resource.firstName,
        affix: resource.prefix,
        lastname: resource.lastName,
    },
});

const transformToLoketAddress = (resource: LoketAddressResource): LoketAddress => ({
    country: resource.country,
    city: resource.city,
    houseNumber: resource.houseNumber,
    houseNumberAddition: resource.houseNumberAddition,
    street: resource.street,
    postalCode: resource.postalCode,
});

const transformToIdentificationType = (resource: string): IdentificationType => {
    switch (resource.toLowerCase()) {
        case IdentificationType.identityCard:
            return IdentificationType.identityCard;
        case IdentificationType.passport:
            return IdentificationType.passport;
        case IdentificationType.residencePermit:
            return IdentificationType.residencePermit;
        default:
            return IdentificationType.unknown;
    }
};

const transformToGender = (resource: string): GenderType => {
    switch (resource.toLowerCase()) {
        case 'man':
            return GenderType.male;
        case 'vrouw':
            return GenderType.female;
        default:
            return GenderType.unknown;
    }
};

export const transformToFullLoketUser = (resource: FullLoketUserResource): FullLoketUser => {
    const address = transformToLoketAddress(resource.address);
    const employment = resource.employment ? transformToLoketEmployment(resource.employment) : undefined;
    const person = transformToBasicPerson(resource.firstName, resource.lastName, resource.prefix);

    return {
        id: resource.id,
        address,
        employment,
        birthday: new Date(resource.birthday),
        emailAddress: resource.emailAddress,
        employeeNumber: resource.employeeNumber,
        identificationType: resource.identificationType ? transformToIdentificationType(resource.identificationType) : undefined,
        gender: transformToGender(resource.gender),
        nationality: resource.nationality,
        person,
        phoneNumber: resource.phoneNumber,
        mobilePhoneNumber: resource.mobilePhoneNumber,
    };
};

export const transformToEmployeeSyncResult = (resource: EmployeeSyncResultResource): EmployeeSyncResult => ({
    isSuccessful: resource.message === 'Success',
    latestPayrollId: resource.latestPayrollId || undefined,
    loketEmployeeId: resource.loketEmployeeId || undefined,
    userId: resource.userId,
});
