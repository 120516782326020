import { BasicPerson, Person } from './Person';

export const generateFullName = (
    firstName?: string,
    affix?: string,
    lastName?: string,
): string => {
    if (!lastName) return firstName || '';

    const firstNameTemplate = firstName ? `${firstName} ` : '';
    const affixTemplate = affix ? `${affix} ` : '';
    const lastNameTemplate = lastName || '';

    return `${firstNameTemplate}${affixTemplate}${lastNameTemplate}`;
};

export const getPersonFullName = (person: BasicPerson | Person): string => generateFullName(person.firstname, person.affix, person.lastname);
