import { FC } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Button } from '../../../../components';
import trans from '../../../../helpers/trans';
import { UsersRoutes } from '../../../../routes/helpers';
import { PrivateRouteGroupRoutes } from '../../../../routes/routes';

import './UserMenuButtons.scss';

interface UserMenuProps {
    userId?: string;
    onNavLinkClick?: () => void;
    onLogoutClick?: () => void;
    className?: string;
    navLinkClassName?: string;
    activeNavLinkClassName?: string;
}

const UserMenu: FC<UserMenuProps> = ({
    userId,
    onNavLinkClick,
    onLogoutClick,
    className = '',
    navLinkClassName = '',
    activeNavLinkClassName = '',
}) => {
    const navLinkClassNames = (props: { isActive: boolean }): string => classNames('user-menu-buttons__link', {
        [activeNavLinkClassName]: props.isActive,
    }, navLinkClassName);

    return (
        <div className={`user-menu-buttons ${className}`}>
            {userId && (
                <NavLink
                    to={`${PrivateRouteGroupRoutes.management()}/${UsersRoutes.user(userId)}`}
                    onClick={onNavLinkClick}
                    className={navLinkClassNames}
                >
                    {trans('containers.navigation.myProfile')}
                </NavLink>
            )}

            {userId && (
                <NavLink
                    to={PrivateRouteGroupRoutes.logs()}
                    onClick={onNavLinkClick}
                    className={navLinkClassNames}
                >
                    {trans('routes.logs.label')}
                </NavLink>
            )}

            {onLogoutClick && (
                <Button
                    text={trans('common.logout')}
                    onClick={onLogoutClick}
                    className={`user-menu-buttons__link ${navLinkClassName}`}
                />
            )}
        </div>
    );
};

export default UserMenu;
