import { FC, PropsWithChildren } from 'react';

import { ConnectedNavigation } from '../../connectors';

const Layout:FC<PropsWithChildren<unknown>> = ({ children }) => (
    <>
        <ConnectedNavigation />
        {children}
    </>
);

export default Layout;
