import React, { FC, useMemo, useState } from 'react';

import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { SelectPayrollPeriod } from '../../../@paco/compositions';
import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { transformLegacyPayrollPeriodToPayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriodTransformers';
import { translate } from '../../../helpers/translations/translator';
import { PayrollPeriodViewModel } from '../../../models';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../../services/PayrollPeriodService/transformPayrollPeriodLegacy';

import './SyncTvtForm.scss';

interface Props {
    activePeriod?: PayrollPeriodViewModel;
    payrollPeriods: PayrollPeriodViewModel[];
    title: string;
    onSubmit: (payrollPeriod: PayrollPeriodViewModel) => void;
    onCancel: () => void;
}

const SyncTvtForm: FC<Props> = ({
    activePeriod,
    payrollPeriods,
    title,
    onSubmit,
    onCancel,
}) => {
    const transformedActivePeriod = useMemo(() => (activePeriod ? transformLegacyPayrollPeriodToPayrollPeriod(activePeriod) : undefined), [activePeriod]);
    const transformedPayrollPeriods = useMemo(() => payrollPeriods
        .map(payrollPeriod => transformLegacyPayrollPeriodToPayrollPeriod(payrollPeriod)), [payrollPeriods]);

    const [period, setPeriod] = useState<PayrollPeriod | undefined>(transformedActivePeriod);
    const canSubmit = !!payrollPeriods.length;

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        if (period) {
            onSubmit(transformPayrollPeriodToLegacyPayrollPeriod(period));
        }
    };

    const handlePeriodChange = (selectedPeriod: PayrollPeriod[]): void => {
        setPeriod(selectedPeriod[0]);
    };

    return (
        <form onSubmit={handleSubmit} className="export-range-form">
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                <div className="export-range-form__intro">
                    <div className="export-range-form__info-icon" />
                    <p>
                        {translate('common.exportFilterCaution')}
                    </p>
                </div>
                <Row>
                    <SelectPayrollPeriod
                        periods={transformedPayrollPeriods}
                        activePeriod={transformedActivePeriod}
                        onChange={handlePeriodChange}
                        className="export-range-form__select-payroll-period"
                        colClassName="export-range-form__select-payroll-period-col"
                    />
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="link" id="modal-close" onClick={onCancel}>{translate('common.cancel')}</Button>
                <Button type="submit" color="orange" disabled={!canSubmit}>{translate('common.synchronize')}</Button>
            </ModalFooter>
        </form>
    );
};

export default SyncTvtForm;
