import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TrackViewModel } from '../../../../models';
import { AsyncReduxState, initialAsyncReduxState } from '../../../@config/AsyncReduxState';

export type TracksToReviewState = AsyncReduxState<{
    tracksToReview: TrackViewModel[];
}>;

const initialState: TracksToReviewState = {
    ...initialAsyncReduxState,
    tracksToReview: [],
};

export const tracksToReviewSlice = createSlice({
    name: 'tracksToReviewReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): TracksToReviewState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): TracksToReviewState {
            return ({
                ...state,
                error: action.payload,
            });
        },
        setTracksToReview(state, action: PayloadAction<TrackViewModel[]>): TracksToReviewState {
            return ({
                ...state,
                tracksToReview: action.payload,
            });
        },
    },
});

export const {
    setTracksToReview,
    setError,
    setIsLoading,
} = tracksToReviewSlice.actions;

export default tracksToReviewSlice.reducer;
