import { formatDate } from '../../../../../helpers/date';
import { EditShiftPlanningFormData, ShiftPlanningViewModel } from '../../../../../models';

export default function transformShiftPlanningToEditShiftPlanningFormData(shiftPlanning: ShiftPlanningViewModel): EditShiftPlanningFormData {
    return {
        startTime: formatDate(shiftPlanning.start, 'HH:mm'),
        endTime: formatDate(shiftPlanning.end.transformToUpToButExcludingDate().date, 'HH:mm'),
        comment: '',
    };
}
