import { Absence, PayrollPeriodWithHours, WeekWithHours } from '../../models';
import {
    ABSENCES_SAGA_ADD_ABSENCE,
    ABSENCES_SAGA_ADD_LEAVE_OF_ABSENCE_REQUEST,
    ABSENCES_SAGA_APPROVE_EXCHANGE_REQUEST,
    ABSENCES_SAGA_APPROVE_SHIFT_EXCHANGE,
    ABSENCES_SAGA_DECLINE_EXCHANGE_REQUEST,
    ABSENCES_SAGA_DECLINE_SHIFT_EXCHANGE,
    ABSENCES_SAGA_DELETE_ABSENCE,
    ABSENCES_SAGA_EDIT_ABSENCE,
    ABSENCES_SAGA_EDIT_LEAVE_OF_ABSENCE,
    ABSENCES_SAGA_EXPORT_CSV,
    ABSENCES_SAGA_GET_ABSENCES,
    ABSENCES_SAGA_GET_ABSENCES_IN_DATE_RANGE,
    ABSENCES_SAGA_GET_EXCHANGE_REQUESTS,
    ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE_REQUESTS,
    ABSENCES_SAGA_REPLACE_USER_IN_SHIFT,
    ABSENCES_SAGA_RESOLVE_ABSENCE,
    ABSENCES_SAGA_SYNC_LEAVE_OF_ABSENCES_TO_LOKET,
} from '../actionTypes';
import {
    AddLeaveOfAbsenceAction,
    ApproveExchangeAction,
    ApproveShiftExchangeAction,
    DeclineExchangeAction,
    DeclineShiftExchangeAction,
    EditLeaveOfAbsenceAction,
} from './absencesModels';

export const getLeaveOfAbsenceRequests = (page: string, status: string) => ({
    type: ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE_REQUESTS, status, page,
});

export const getExchangeRequests = () => ({ type: ABSENCES_SAGA_GET_EXCHANGE_REQUESTS });
export const approveExchangeRequest = (requestId: string, userId: string): ApproveExchangeAction => ({
    type: ABSENCES_SAGA_APPROVE_EXCHANGE_REQUEST, requestId, userId,
});
export const declineExchangeRequest = (requestId: string): DeclineExchangeAction => ({
    type: ABSENCES_SAGA_DECLINE_EXCHANGE_REQUEST, requestId,
});

export const approveShiftExchange = (shiftExchangeId: string): ApproveShiftExchangeAction => ({ type: ABSENCES_SAGA_APPROVE_SHIFT_EXCHANGE, shiftExchangeId });
export const declineShiftExchange = (shiftExchangeId: string): DeclineShiftExchangeAction => ({ type: ABSENCES_SAGA_DECLINE_SHIFT_EXCHANGE, shiftExchangeId });

export const getAbsences = () => ({ type: ABSENCES_SAGA_GET_ABSENCES });
export const getAbsencesInDateRange = () => ({ type: ABSENCES_SAGA_GET_ABSENCES_IN_DATE_RANGE });

export const editLeaveOfAbsence = (props: EditLeaveOfAbsenceAction) => ({
    type: ABSENCES_SAGA_EDIT_LEAVE_OF_ABSENCE,
    ...props,
});

export const editAbsence = (
    absence: Absence,
    startDate: string,
    endDate: string | null,
    periods: PayrollPeriodWithHours[],
    weekdays: WeekWithHours[],
    waitingDayHours: number,
    page: string,
) => ({
    type: ABSENCES_SAGA_EDIT_ABSENCE,
    absence,
    startDate,
    endDate,
    periods,
    weekdays,
    waitingDayHours,
    page,
});

export const resolveAbsence = (
    absence: Absence,
    startDate: string,
    endDate: string,
    periods: PayrollPeriodWithHours[],
    weekdays: WeekWithHours[],
    waitingDayHours: number,
    page?: string,
) => ({
    type: ABSENCES_SAGA_RESOLVE_ABSENCE,
    absence,
    startDate,
    endDate,
    periods,
    weekdays,
    waitingDayHours,
    page,
});

export const deleteAbsence = (absenceId: string, page: string) => ({
    type: ABSENCES_SAGA_DELETE_ABSENCE, absenceId, page,
});

export const addAbsence = (
    userId: string,
    startDate: string,
    periods: PayrollPeriodWithHours[],
    weekdays: WeekWithHours[],
    waitingDayHours: number,
) => ({
    type: ABSENCES_SAGA_ADD_ABSENCE,
    userId,
    startDate,
    periods,
    weekdays,
    waitingDayHours,
});

export const replaceUserInShift = (addUserId: string, delUserId: string, shiftId: string) => ({
    type: ABSENCES_SAGA_REPLACE_USER_IN_SHIFT, addUserId, delUserId, shiftId,
});

export const addLeaveOfAbsenceRequest = (props: AddLeaveOfAbsenceAction) => ({
    type: ABSENCES_SAGA_ADD_LEAVE_OF_ABSENCE_REQUEST,
    ...props,
});

export const exportCSV = () => ({ type: ABSENCES_SAGA_EXPORT_CSV });

export const syncAbsencesToLoket = (startDate: string, endDate: string | null) => ({
    type: ABSENCES_SAGA_SYNC_LEAVE_OF_ABSENCES_TO_LOKET, startDate, endDate,
});
