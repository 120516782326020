import { FC, MouseEvent } from 'react';

import classNames from 'classnames';

import { Icon } from '../../../../../components';
import { DistrictDivisionGroupTemplate } from '../../../../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';

import './TemplateGroupButton.scss';

interface TemplateGroupButtonProps {
    isActive: boolean;
    isDisabled: boolean;
    userIsDragging: boolean;
    group: DistrictDivisionGroupTemplate;
    onDragEnd: (groupId: string) => void;
    onDragEnter: (groupId: string) => void;
    onDragStart: (groupId: string) => void;
    className?: string;
}

const TemplateGroupButton: FC<TemplateGroupButtonProps> = ({
    isActive,
    isDisabled,
    userIsDragging,
    group,
    onDragEnd,
    onDragEnter,
    onDragStart,
    className = '',
}) => {
    const draggableClassName = classNames('template-group-button', {
        'template-group-button--is-active': isActive,
        'template-group-button--is-disabled': isDisabled,
        'template-group-button--user-is-dragging': userIsDragging,
    }, className);

    const handleDragEnter = (): void => {
        onDragEnter(group.id);
    };

    const handleDragEnd = (): void => {
        onDragEnd(group.id);
    };

    const handleDragOver = (e: MouseEvent): void => {
        e.preventDefault();
    };

    const handleDragStart = (): void => {
        onDragStart(group.id);
    };

    return (
        <li
            draggable
            onDragEnter={handleDragEnter}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDragStart={handleDragStart}
            className={draggableClassName}
        >
            <div className="template-group-button__group-name">
                {group.name}
            </div>
            <div className="template-group-button__divider" />
            <Icon
                name="draggable"
                className="template-group-button__drag-icon"
            />
        </li>
    );
};

export default TemplateGroupButton;
