import { LeaveOfAbsence } from '../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { transformToLeaveOfAbsencesOverviewBodyRows } from '../../entities/LeaveOfAbsenceOverviewBlock/LeaveOfAbsencesOverviewBlockTransformers';
import { LegacyBasicUser } from '../../entities/User/User';
import { TypedDispatch } from '../store';
import { setLeaveOfAbsencesOverviewBodyRows } from './leaveOfAbsencesOverviewReducer';

export const getLeaveOfAbsencesOverviewBlocks = (days: Date[], leaveOfAbsences: LeaveOfAbsence[], users: LegacyBasicUser[]) => async (dispatch: TypedDispatch): Promise<void> => {
    const rows = transformToLeaveOfAbsencesOverviewBodyRows(days, leaveOfAbsences, users);

    dispatch(setLeaveOfAbsencesOverviewBodyRows(rows));
};
