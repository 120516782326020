import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { Department } from '../../entities/Department/Department';
import { Permission } from '../../entities/Permission/Permission';
import { Role } from '../../entities/Role/Role';
import { Setting } from '../../entities/Setting/Setting';
import { AuthenticatedUser } from '../../entities/User/User';
import { getNavRoutesByPermissions } from '../../helpers/routes';
import appRoutes, { NavRoute } from '../../routes/routes';
import { SettingsSlice } from '../settings/settingsReducer';

export type AuthenticatedUserState = AsyncReduxState<{
    isEditUserSuccessful: boolean;
    departments: Department[];
    permissions: Permission[];
    permittedUserRoutes: NavRoute[];
    role?: Role;
    settings: Setting[];
    user?: AuthenticatedUser;
}>;

const initialState: AuthenticatedUserState = {
    ...initialAsyncReduxState,
    departments: [],
    permissions: [],
    permittedUserRoutes: [],
    settings: [],
    isEditUserSuccessful: false,
};

export const authenticatedUserSlice = createSlice({
    name: 'authenticatedUserReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AuthenticatedUserState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsUpdating(state, action: PayloadAction<boolean>): AuthenticatedUserState {
            return {
                ...state,
                isUpdating: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): AuthenticatedUserState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setAuthenticatedUser(state, action: PayloadAction<AuthenticatedUser | undefined>): AuthenticatedUserState {
            const userPermissionSlugs = (action.payload?.permissions || []).map(permission => permission.slug);
            const { role } = action.payload || {};

            const permittedUserRoutes: NavRoute[] = getNavRoutesByPermissions(userPermissionSlugs, appRoutes, state.settings, role);

            return {
                ...state,
                departments: action.payload?.departments || [],
                permissions: action.payload?.permissions || [],
                permittedUserRoutes,
                role,
                user: action.payload,
            };
        },
        setIsEditUserSuccessful(state, action: PayloadAction<boolean>): AuthenticatedUserState {
            return {
                ...state,
                isEditUserSuccessful: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(SettingsSlice.actions.setSettings, (state, action) => {
            const userPermissionSlugs = (state.user?.permissions || []).map(permission => permission.slug);
            const { user } = state;
            const permittedUserRoutes: NavRoute[] = getNavRoutesByPermissions(userPermissionSlugs, appRoutes, action.payload, user?.role);

            return {
                ...state,
                settings: action.payload,
                permittedUserRoutes,
            };
        });
    },
});

export const {
    setIsLoading,
    setIsUpdating,
    setAuthenticatedUser,
    setIsEditUserSuccessful,
    setError,
} = authenticatedUserSlice.actions;

export default authenticatedUserSlice.reducer;
