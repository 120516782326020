import React, { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import DateNavigator from '../../containers/DateNavigator/DateNavigator';
import { getPayrollPeriods } from '../../redux/app/appActions';
import { Reducers } from '../../redux/reducers';
import { setSelectedDate, setStartAndEndDate } from '../../redux/weekNavigator/weekNavigatorActions';


interface ConnectedDateNavigatorProps {
    afterDateChange?: () => void;
    afterIncrementChange?: () => void;
}

const ConnectedDateNavigator: FC<ConnectedDateNavigatorProps> = ({
    afterDateChange,
    afterIncrementChange,
}) => {
    const dispatch = useDispatch();
    const { payrollPeriods } = useSelector((state: Reducers) => state.appReducer);

    const {
        activePayrollPeriod,
        mode,
        startDate,
        endDate,
        selectedDate,
    } = useSelector((state: Reducers) => state.weekNavigatorReducer);

    useEffect(() => {
        if (!payrollPeriods.length) {
            dispatch(getPayrollPeriods());
        }
    }, []);

    const afterDateChangeHandler = () => {
        if (afterDateChange) {
            afterDateChange();
        }
    };

    const afterIncrementChangeHandler = () => {
        if (afterIncrementChange) {
            afterIncrementChange();
        }
    };

    const setSelectedDateHandler = (selected: Date, from: Date, end: Date) => {
        dispatch(setSelectedDate(selected, from, end));
    };

    const setStartAndEndDateHandler = (selected: Date, from: Date, end: Date) => {
        dispatch(setStartAndEndDate(selected, from, end));
    };

    return (
        <DateNavigator
            activePayrollPeriod={activePayrollPeriod}
            mode={mode}
            startDate={startDate}
            endDate={endDate}
            selectedDate={selectedDate}
            payrollPeriods={payrollPeriods}
            afterDateChange={afterDateChangeHandler}
            afterIncrementChange={afterIncrementChangeHandler}
            setStartAndEndDate={setStartAndEndDateHandler}
            setSelectedDate={setSelectedDateHandler}
        />
    );
};

export default ConnectedDateNavigator;
