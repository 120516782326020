/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconHome: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 20 17">
        <path d="M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z" />
    </svg>
);

export default IconHome;
/* eslint-enable max-len */
