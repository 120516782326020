import { RefObject, useEffect, useState } from 'react';

const useTableCellHoverIndex = (ref: RefObject<HTMLTableElement>): number | undefined => {
    const [hoverIndex, setHoverIndex] = useState<number>();
    const [mouseIsEntered, setMouseIsEntered] = useState(false);

    useEffect((): () => void => {
        if (!ref.current) {
            setHoverIndex(undefined);
        }

        const handleMouseMove = (e: MouseEvent): void => {
            const element = e.target as HTMLElement;

            if (element && element.getAttribute('data-day-index')) {
                const dayIndex = parseInt(element.getAttribute('data-day-index') || '0', 10);
                setHoverIndex(dayIndex);
            }
        };

        const handleMouseEnter = (): void => {
            setMouseIsEntered(true);
        };

        const handleMouseLeave = (): void => {
            setMouseIsEntered(false);
        };

        if (ref.current) {
            ref.current.addEventListener('mousemove', handleMouseMove);
            ref.current.addEventListener('mouseenter', handleMouseEnter);
            ref.current.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            ref.current?.removeEventListener('mousemove', handleMouseMove);
            ref.current?.removeEventListener('mouseenter', handleMouseEnter);
            ref.current?.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [ref]);

    return mouseIsEntered ? hoverIndex : undefined;
};

export default useTableCellHoverIndex;
