import {
    ExchangeResource,
    ExchangeShiftResource,
    ExchangeViewModel,
    Log,
    UserResource,
} from '../../models';
import { transformShiftResource } from '../ShiftService/transformShiftResource';
import transformUserResource from '../UserService/transformUserResource';

export default function transformExchangeResource(
    exchange: ExchangeResource,
    owner?: UserResource,
    replacementUsers: UserResource[] = [],
    shift?: ExchangeShiftResource,
    logs?: Log[],
): ExchangeViewModel {
    const shiftStatus = shift ? shift.statusForCurrentUser : 'unknown';

    return {
        type: 'exchanges',
        id: exchange.id,
        createdAt: new Date(exchange.createdAt),
        logs,
        owner: owner ? transformUserResource(owner) : undefined,
        replacementUsers: replacementUsers.map((user) => transformUserResource(user)),
        shift: shift ? (
            transformShiftResource(
                shift,
                shift.plannedUsers,
                shift.shiftPlannings,
                shift.department,
            )
        ) : undefined,
        shiftStatusForOwner: shiftStatus,
    };
}
