import { Resource } from './Resource';

export enum EmploymentTypeSlug {
    fullTime = 'full-time',
    partTime = 'part-time',
    intern = 'intern',
    student = 'student',
    temporaryWorker = 'temporary-worker',
}

export interface EmploymentTypeResource extends Resource {
    name: string;
    slug: EmploymentTypeSlug;
}

export interface EmploymentTypeViewModel {
    id: string;
    name: string;
    slug: EmploymentTypeSlug;
}
