import React, { FC } from 'react';

import Tooltip from 'react-tooltip-lite';

import { SpecialDayViewModel } from '../../models';
import Icon from '../style/Icon/Icon';

import './SpecialDayTooltip.scss';


interface SpecialDayToolTipProps {
    specialDay: SpecialDayViewModel;
    isMobile: boolean;
}

const SpecialDayTooltip: FC<SpecialDayToolTipProps> = ({ specialDay, isMobile }) => {
    const { type, description } = specialDay;
    const id = `icon-${isMobile ? 'mobile-' : ''}${specialDay.id}`;
    return (
        // @ts-ignore
        <Tooltip key={id} content={description} arrowSize={6} padding={7}>
            <span
                id={id}
                className="special-day-tooltip"
            >
                <Icon kind={type} fontSize={16} />
            </span>
        </Tooltip>
    );
};

export default SpecialDayTooltip;
