import { authorizedFetch } from '../../helpers/authorizedFetch';
import { formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { generateApiV3Url } from '../../helpers/url';
import { DateFormat } from '../../types/dateFormatTypes';
import { FetchResult, FetchResultType } from '../FetchResult';
import { Period } from '../Period/Period';
import { transformBasicUserResourceToBasicV3User } from '../User/UserTransformers';
import { LeaveOfAbsence, LeaveOfAbsenceV3Resource } from './LeaveOfAbsence';
import { transformV3ResourceToLeaveOfAbsence } from './LeaveOfAbsenceTransformers';

let getLeaveOfAbsencesInPeriodApiCallAbortController = new AbortController();

export const getLeaveOfAbsencesInPeriodApiCall = async (period: Period, isCancelable = false): Promise<FetchResult<LeaveOfAbsence[], string>> => {
    if (isCancelable) {
        getLeaveOfAbsencesInPeriodApiCallAbortController.abort();
        getLeaveOfAbsencesInPeriodApiCallAbortController = new AbortController();
    }

    try {
        const url = generateApiV3Url({
            endpoint: '/leave-of-absences/in-period-with-user',
            queryParams: {
                start: formatDate(period.start, DateFormat.apiDateTime),
                end: formatDate(period.end, DateFormat.apiDateTime),
            },
        });

        const response = await authorizedFetch(url, { signal: getLeaveOfAbsencesInPeriodApiCallAbortController.signal });

        const doc: LeaveOfAbsenceV3Resource[] = await response.json();

        if (!doc) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const leaveOfAbsences = doc.map(leaveOfAbsenceResource => {
            const basicUser = transformBasicUserResourceToBasicV3User(leaveOfAbsenceResource.user);

            return transformV3ResourceToLeaveOfAbsence(leaveOfAbsenceResource, basicUser);
        });

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: leaveOfAbsences,
        };
    } catch (error) {
        console.error('[getLeaveOfAbsencesInPeriodApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
