import { ShiftPlanningWithShift } from '../../../@paco/entities/ShiftPlanning/ShiftPlanning';
import { UserWithAvailability } from '../../../models';

const updateShiftPlanningInUsersWithAvailability = (
    users: UserWithAvailability[],
    updatedShiftPlanning: ShiftPlanningWithShift,
): UserWithAvailability[] => users.map(user => {
    const shiftPlanningIndex = user.shiftPlannings.findIndex(shiftPlanning => shiftPlanning.id === updatedShiftPlanning.id);
    const shiftPlannings = shiftPlanningIndex !== -1 ? user.shiftPlannings.splice(shiftPlanningIndex, 1, updatedShiftPlanning) : user.shiftPlannings;

    return {
        ...user,
        shiftPlannings,
    };
});

export default updateShiftPlanningInUsersWithAvailability;
