import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { DistrictDivisionTemplate } from '../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import { districtDivisionTemplateSlice } from '../districtDivisionTemplate/districtDivisionTemplateReducer';

export type DistrictDivisionTemplatesState = AsyncReduxState<{
    districtDivisionTemplates: DistrictDivisionTemplate[];
}>;

const initialState: DistrictDivisionTemplatesState = {
    ...initialAsyncReduxState,
    districtDivisionTemplates: [],
};

export const districtDivisionTemplatesSlice = createSlice({
    name: 'districtDivisionTemplatesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DistrictDivisionTemplatesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DistrictDivisionTemplatesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDistrictDivisionTemplates(state, action: PayloadAction<DistrictDivisionTemplate[]>): DistrictDivisionTemplatesState {
            return {
                ...state,
                districtDivisionTemplates: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(districtDivisionTemplateSlice.actions.setDistrictDivisionTemplateToExistingDivisionTemplates, (state, action) => {
            const districtDivisionTemplates = [
                ...state.districtDivisionTemplates,
                action.payload,
            ];

            return {
                ...state,
                districtDivisionTemplates,
            };
        });
    },
});

export const {
    setIsLoading,
    setError,
    setDistrictDivisionTemplates,
} = districtDivisionTemplatesSlice.actions;

export default districtDivisionTemplatesSlice.reducer;
