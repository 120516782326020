import {
    AUTH_SAGA_AUTHENTICATE_USER, AUTH_SAGA_FORGOT_PASSWORD, AUTH_SAGA_SET_API_TOKEN_AND_GET_USER,
    AUTH_SAGA_SUBMIT_PASSWORD, AUTH_SAGA_UPDATE_API_TOKEN, AUTH_SAGA_UPDATE_REFRESH_TOKEN,
} from '../actionTypes';

const authenticateUser = credentials => ({
    type: AUTH_SAGA_AUTHENTICATE_USER,
    credentials,
});

const setApiToken = apiToken => ({
    type: AUTH_SAGA_UPDATE_API_TOKEN,
    apiToken,
});

const setRefreshToken = refreshToken => ({
    type: AUTH_SAGA_UPDATE_REFRESH_TOKEN,
    refreshToken,
});

const forgotUserPassword = email => ({
    type: AUTH_SAGA_FORGOT_PASSWORD,
    email,
});

const submitUserPasswordPincode = (userId, password, pincode) => ({
    type: AUTH_SAGA_SUBMIT_PASSWORD, password, pincode, userId,
});

const setApiTokenAndGetUser = params => ({
    type: AUTH_SAGA_SET_API_TOKEN_AND_GET_USER,
    params,
});

export {
    authenticateUser,
    setApiToken,
    setRefreshToken,
    forgotUserPassword,
    submitUserPasswordPincode,
    setApiTokenAndGetUser,
};
