import React, { FC } from 'react';

import { translate } from '../../../../helpers/translations/translator';
import { LoketBalancesViewModel } from '../../../../models';

import './EmployeeLeaveAndTvTSaldo.scss';


interface EmployeeLeaveAndTvTSaldoProps {
    hasRoleToSeeAbsenceHours: boolean;
    loketBalances?: LoketBalancesViewModel | null;
    className?: string;
}

const EmployeeLeaveAndTvTSaldo:FC<EmployeeLeaveAndTvTSaldoProps> = ({
    hasRoleToSeeAbsenceHours,
    className = '',
    loketBalances,
}) => {
    const leaveSaldo = loketBalances ? loketBalances.leaveBalanceTotal : null;
    const tvtSaldo = loketBalances ? loketBalances.timeForTimeTotal : null;

    if (!hasRoleToSeeAbsenceHours) {
        return <div className={`employee-leave-and-tvt-saldo ${className}`} />;
    }

    return (
        <div className={`employee-leave-and-tvt-saldo ${className}`}>
            <div className="employee-leave-and-tvt-saldo__saldo">
                <div className="employee-leave-and-tvt-saldo__title">
                    {translate('common.leaveSaldo')}
                </div>
                { leaveSaldo !== null && (
                    <div className="employee-leave-and-tvt-saldo__value">
                        {`${leaveSaldo} ${translate('common.hour')}`}
                    </div>
                )}
            </div>

            <div className="employee-leave-and-tvt-saldo__saldo">
                <div className="employee-leave-and-tvt-saldo__title">
                    {translate('common.tvtSaldo')}
                </div>
                { tvtSaldo !== null && (
                    <div className="employee-leave-and-tvt-saldo__value">
                        {`${tvtSaldo} ${translate('common.hour')}`}
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmployeeLeaveAndTvTSaldo;
