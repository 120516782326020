import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getSpecialDayIndexCall } from '../../entities/SpecialDay/SpecialDayService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setSpecialDays } from './specialDaysReducer';

export const fetchSpecialDays = (
    startDate: Date,
    endDate: Date,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getSpecialDayIndexCall(startDate, endDate);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setSpecialDays(response.data));
    } catch (error) {
        console.error('[fetchSpecialDays]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
