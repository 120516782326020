import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { DistrictDivisionGroupTemplate } from '../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';

export type DistrictDivisionGroupTemplateState = AsyncReduxState<{
    districtDivisionGroupTemplate?: DistrictDivisionGroupTemplate;
}>;

const initialState: DistrictDivisionGroupTemplateState = {
    ...initialAsyncReduxState,
};

export const districtDivisionGroupTemplateSlice = createSlice({
    name: 'districtDivisionGroupTemplateReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DistrictDivisionGroupTemplateState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DistrictDivisionGroupTemplateState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): DistrictDivisionGroupTemplateState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setDistrictDivisionGroupTemplate(state, action: PayloadAction<DistrictDivisionGroupTemplate>): DistrictDivisionGroupTemplateState {
            return {
                ...state,
                districtDivisionGroupTemplate: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setIsSuccessful,
    setDistrictDivisionGroupTemplate,
} = districtDivisionGroupTemplateSlice.actions;

export default districtDivisionGroupTemplateSlice.reducer;
