import { FC, ReactElement, useEffect } from 'react';

import { DistrictDivisionTemplatesContainer } from '../../containers';
import { DistrictDivisionTemplate } from '../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import { setDistrictDivisionTemplate } from '../../redux/districtDivisionTemplate/districtDivisionTemplateReducer';
import { getDistrictDivisionTemplates } from '../../redux/districtDivisionTemplates/districtDivisionTemplatesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

const ConnectedDistrictDivisionTemplates: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isLoading, districtDivisionTemplates } = useTypedSelector(state => state.districtDivisionTemplatesReducer);

    useEffect(() => {
        dispatch(getDistrictDivisionTemplates());
    }, []);

    const handleDistrictDivisionTemplateClick = (districtDivisionTemplate: DistrictDivisionTemplate): void => {
        dispatch(setDistrictDivisionTemplate(districtDivisionTemplate));
    };

    return (
        <DistrictDivisionTemplatesContainer
            isLoading={isLoading}
            districtDivisionTemplates={districtDivisionTemplates}
            onDistrictDivisionTemplateClick={handleDistrictDivisionTemplateClick}
        />
    );
};

export default ConnectedDistrictDivisionTemplates;
