import { getEmptyContractFormData } from '../../../../entities/ContractHours/ContractHoursHelpers';
import { AddUserFormData } from '../../../../entities/User/User';
import { addYears } from '../../../../helpers/date';
import { GenderType, IdentificationType } from '../../../../types';

export const generateAddUserFormDataEmptyValues = (): AddUserFormData => ({
    personalInfo: {
        email: '',
        phoneNumber: '',
        firstname: '',
        affix: '',
        lastname: '',
        birthday: new Date('2000-01-01'),
        gender: GenderType.unknown,
    },
    person: {
        hasSundayAllowance: false,
        companyMedic: 0,
        workdays: [],
        identificationType: IdentificationType.unknown,
        identificationExpirationDate: addYears(new Date(), 5),
        nationality: '',
    },
    employmentDates: {
        startDate: undefined,
    },
    mainDepartment: '',
    experienceId: '',
    employmentTypeId: '',
    roleId: '',
    contractHours: [getEmptyContractFormData()],
    shouldTrackTime: true,
    isPaidBasedOnTrackedTime: false,
    isEligibleForTimeForTime: false,
});
