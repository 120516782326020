import axios from 'axios';

import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import {
    AddShiftFormData,
    ApiResponse,
    Filter,
    LegacyAddShiftFormData,
    LegacyEditShiftFormData,
    ShiftApiType,
    ShiftResource,
    ShiftType,
    ShiftViewModel,
} from '../../models';
import getShiftApiType from '../../services/ShiftService/getShiftApiType';
import { transformShiftResource } from '../../services/ShiftService/transformShiftResource';
import { addDays, compareTimeStrings, convertDateToApiParamDate } from '../date';

interface GetShiftsParams {
    filter: Filter;
    startDate: Date;
    endDate: Date;
    departments: string[];
    includes?: string[];
}

export interface AddShiftApiRequestParams {
    type: ShiftApiType;
    attributes: {
        start: string;
        end: string;
        peopleLimit: number;
    };
    relationships: {
        user: {
            data: {
                type: 'users',
                id: string;
            },
        },
        department: {
            data: {
                type: 'departments',
                id: string;
            },
        },
    }
}

interface EditShiftApiRequestParams {
    type: 'shifts';
    id: string;
    attributes: {
        start: string;
        end: string;
        peopleLimit: number;
    };
}

function transformLegacyFormDataToAddApiParams(
    data: LegacyAddShiftFormData,
): AddShiftApiRequestParams {
    return {
        type: ShiftApiType.shift,
        attributes: {
            start: `${data.startDate} ${data.startTime}:00`,
            end: `${data.endDate} ${data.endTime}:00`,
            peopleLimit: data.peopleLimit,
        },
        relationships: {
            user: {
                data: {
                    type: 'users',
                    id: data.user,
                },
            },
            department: {
                data: {
                    type: 'departments',
                    id: data.department,
                },
            },
        },
    };
}

export function transformFormDataToAddApiParams(
    data: AddShiftFormData,
    type: ShiftType,
    userId: string,
): AddShiftApiRequestParams {
    const date = convertDateToApiParamDate(data.date);
    const { startTime, endTime } = data;
    const start = `${date} ${data.startTime}:00`;
    const justifiedEndDate = compareTimeStrings(startTime, endTime)
        ? addDays(data.date, 1) : date;
    const end = `${justifiedEndDate} ${data.endTime}:00`;

    return {
        type: getShiftApiType(type),
        attributes: {
            start,
            end,
            peopleLimit: data.peopleLimit || 0,
        },
        relationships: {
            user: {
                data: {
                    type: 'users',
                    id: userId,
                },
            },
            department: {
                data: {
                    type: 'departments',
                    id: data.department || '',
                },
            },
        },
    };
}

function transformLegacyFormDataToEditApiParams(data: LegacyEditShiftFormData, shiftId: string): EditShiftApiRequestParams {
    return {
        type: 'shifts',
        id: shiftId,
        attributes: {
            start: `${data.startDate} ${data.startTime}:00`,
            end: `${data.endDate} ${data.endTime}:00`,
            peopleLimit: data.peopleLimit,
        },
    };
}

let geShiftsApiCallAbortController = new AbortController();

export const getShifts = async (params: GetShiftsParams): Promise<ShiftViewModel[]> => {
    const {
        includes = [],
        filter,
        startDate,
        endDate,
        departments,
    } = params;
    const { employeeSearch: search } = filter || {};

    geShiftsApiCallAbortController.abort();
    geShiftsApiCallAbortController = new AbortController();

    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shifts/old`,
        {
            params: {
                include: includes.join(','),
                ...(search && { search }),
                startDate: formatDate(startDate, DateFormat.apiDateTime),
                endDate: formatDate(endDate, DateFormat.apiDateTime),
                departments: (departments && departments.length > 0) ? JSON.stringify(departments) : undefined,
            },
            signal: geShiftsApiCallAbortController.signal,
        },
    ) as unknown as ApiResponse<ShiftResource[]>;

    return response.data.map((shift: ShiftResource) => transformShiftResource(
        shift,
        shift.plannedUsers,
        shift.shiftPlannings,
        shift.department,
        shift.temporaryWorkers,
        shift.previouslyPlannedUsers,
    ));
};

export const postShift = (formData: LegacyAddShiftFormData) => {
    const body = transformLegacyFormDataToAddApiParams(formData);
    return axios.post(`${process.env.REACT_APP_API_URL}/shifts/`, { data: body });
};

export const patchShift = (formData: LegacyEditShiftFormData, shiftId: string) => {
    const body = transformLegacyFormDataToEditApiParams(formData, shiftId);
    return axios.patch(`${process.env.REACT_APP_API_URL}/shifts/${shiftId}/`, { data: body });
};

export const getShift = async (id: string, includes: string[] = []): Promise<ShiftViewModel> => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shifts/${id}`,
        {
            params: {
                include: includes.join(','),
            },
        },
    ) as unknown as ApiResponse<ShiftResource>;

    const shift = response.data;

    return transformShiftResource(
        shift,
        shift.plannedUsers,
        shift.shiftPlannings,
        shift.department,
        shift.temporaryWorkers,
        shift.previouslyPlannedUsers,
    );
};
