import { PayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { PzLeaveType } from '../../../../@paco/types/leaveType';
import { compareAsc } from '../../../../helpers/date';
import { LeaveOfAbsenceHours, LeaveType } from '../../../../models';
import transformAbsenceHourToWeekdayWithHours
    from '../../../../services/WeekWithHours/transformAbsenceHourToWeekdayWithHours';

export const getLeaveTypeFromLeaveOfAbsenceHours = (
    leaveOfAbsenceHours: LeaveOfAbsenceHours[] | undefined,
): LeaveType | PzLeaveType | null => {
    if (!leaveOfAbsenceHours || !leaveOfAbsenceHours.length) {
        return null;
    }

    return leaveOfAbsenceHours[0].leaveType;
};

export const transformLeaveOfAbsenceHoursToWeekDayWithHours = (
    leaveOfAbsenceHours: LeaveOfAbsenceHours[] = [],
    payrollPeriods: PayrollPeriod[],
) => leaveOfAbsenceHours
    .map(hours => transformAbsenceHourToWeekdayWithHours(hours, payrollPeriods))
    .sort((a, b) => compareAsc(a.start, b.start));
