import React, { FC } from 'react';

import UserColor from '../../UserColor/UserColor';

import './ColorPicker.scss';

// To clear the color we need null in the BE
const colors = ['e74c3c', 'ff9f19', 'ffeb3b', '26ae60', '3498db', '9c58b6', 'ff5eac', 'ffb8b8', 'a38377', 'b4b4b4', null];

interface ColorPickerProps {
    className?: string;
    onColorClick: (color: string | null) => void;
}

const ColorPicker: FC<ColorPickerProps> = ({ className, ...props }) => {
    const onColorClick = (color: string | null) => {
        if (props.onColorClick) props.onColorClick(color);
    };

    return (
        <div className={`color-picker ${className || ''}`} data-testid="color-picker">
            { colors.map((colorHex: string | null) => (
                <UserColor
                    className="color-picker__button"
                    color={colorHex}
                    key={colorHex || 'blank'}
                    onFocus={onColorClick}
                    size="large"
                />
            ))}
        </div>
    );
};

export default ColorPicker;
