import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import {
    DragState,
    ShiftDraggable,
    ShiftViewModel,
} from '../../models';
import transformShiftsToTransposedShiftDraggable from './transformShiftsToTransposedShiftDraggable';
import transformShiftToShiftDraggable from './transformShiftToShiftDraggable';

export default function transformShiftsToShiftDraggable(
    shifts: (ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel)[],
    draggingShiftId: string | null,
    // eslint-disable-next-line default-param-last
    selectedShiftIds: string[] = [],
    selectedDays: Date[],
    selectedCopyDate: Date | null,
    today: Date,
): [ShiftDraggable[], number] {
    const selectedShifts = shifts.filter((shift) => selectedShiftIds.includes(shift.id));
    const [previewShiftConcepts, dateOffset] = transformShiftsToTransposedShiftDraggable(
        draggingShiftId,
        selectedShifts,
        selectedCopyDate,
        selectedDays,
        today,
    );

    const shiftConcepts = shifts.map((shift) => {
        const isSelected = selectedShiftIds.includes(shift.id);
        return transformShiftToShiftDraggable(shift, isSelected ? DragState.selected : DragState.neutral);
    });

    return [
        [
            ...shiftConcepts,
            ...previewShiftConcepts,
        ],
        dateOffset,
    ];
}
