import axios from 'axios';

import { ShiftPlanningViewModel } from '../../models';
import transformShiftPlanningResource from '../../services/ShiftPlanningService/transformShiftPlanningResource';
import {
    AddBaseScheduleShiftPlanningRequest,
    BaseScheduleShiftPlanningResource,
    EditBaseScheduleShiftPlanningColorRequest,
    EditBaseScheduleShiftPlanningRequest,
} from './BaseScheduleShiftPlanning';

export const patchBaseScheduleShiftPlanningColorApiCall = async (
    shiftPlanningId: string,
    body: EditBaseScheduleShiftPlanningColorRequest,
): Promise<ShiftPlanningViewModel> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedule-shift-plannings/${shiftPlanningId}`);

    const includes = [
        'user',
        'user.person',
        'user.employmentType',
        'user.experience',
    ];

    url.searchParams.append('include', includes.join(','));

    const response = await axios.patch<BaseScheduleShiftPlanningResource>(String(url), { data: body });

    // TODO: Use transformer in entities folder
    return transformShiftPlanningResource(response.data, response.data.user);
};

export const patchBaseScheduleShiftPlanningApiCall = async (
    body: EditBaseScheduleShiftPlanningRequest,
    shiftPlanningId: string,
): Promise<ShiftPlanningViewModel> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedule-shift-plannings/${shiftPlanningId}`);

    const includes = [
        'user',
        'user.person',
        'user.employmentType',
        'user.experience',
    ];

    url.searchParams.append('include', includes.join(','));

    const response = await axios.patch<BaseScheduleShiftPlanningResource>(String(url), { data: body });

    return transformShiftPlanningResource(response.data, response.data.user);
};

export const postBaseScheduleShiftPlanningApiCall = async (body: AddBaseScheduleShiftPlanningRequest): Promise<ShiftPlanningViewModel> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedule-shift-plannings`);

    const includes = [
        'user',
        'user.person',
        'user.employmentType',
        'user.experience',
    ];

    url.searchParams.append('include', includes.join(','));

    const response = await axios.post<BaseScheduleShiftPlanningResource>(String(url), { data: body });

    return transformShiftPlanningResource(response.data, response.data.user);
};

export const deleteBaseScheduleShiftPlanningApiCall = async (id: string): Promise<void> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedule-shift-plannings/${id}`);

    await axios.delete(String(url));
};
