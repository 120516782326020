import { FC, ReactElement } from 'react';

import classnames from 'classnames';

import { LoadingSpinner } from '../../../components';
import { ValkTable } from '../../../compositions';
import { EventLog } from '../../../entities/EventLog/EventLog';
import trans from '../../../helpers/trans';
import { EntityEventLogRow } from './subcomponents';

import './EntityEventLogsTable.scss';

interface PzLogsTableProps {
    isLoading?: boolean;
    logs: EventLog[];
    onViewLogClick: (log: EventLog) => void;
    className?: string;
}

const tableHeader = [
    trans('containers.tables.pzLogsTable.log'),
    trans('common.date'),
    '',
];

const IncompleteUsersTable: FC<PzLogsTableProps> = ({
    isLoading,
    logs,
    onViewLogClick,
    className = '',
}): ReactElement => {
    const wrapperClassName = classnames('pz-logs-table', {
        'pz-logs-table--is-empty': !logs.length,
    }, className);

    if (isLoading && !logs.length) {
        return (
            <div className={wrapperClassName}>
                <LoadingSpinner isSmall className="pz-logs-table__loader" />
            </div>
        );
    }

    return (
        <div className={wrapperClassName}>
            <ValkTable<EventLog>
                hasRowClick
                rows={logs}
                tableHeaders={tableHeader}
                tableClassName="pz-logs-table__table"
                className="pz-logs-table__valk-table"
                cellClassName="pz-logs-table__table-cell"
            >
                {(pzLogs): JSX.Element[] => pzLogs.map(log => (
                    <EntityEventLogRow
                        key={log.id}
                        log={log}
                        onViewLogClick={onViewLogClick}
                        cellClassName="pz-logs-table__table-cell"
                    />
                ))}
            </ValkTable>
            {isLoading && <LoadingSpinner isSmall className="pz-logs-table__loader" />}
        </div>
    );
};

export default IncompleteUsersTable;
