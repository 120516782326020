import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import { formatDate } from '../../helpers/date';
import { ShiftDraggable, ShiftPlanningViewModel, ShiftViewModel } from '../../models';
import isShiftPlanningTimeDeviated from './isShiftPlanningTimeDeviated';

export default function getShiftPlanningDeviatedTime(
    shiftPlanning: ShiftPlanningViewModel,
    shift?: ShiftViewModel | ShiftDraggable | ShiftConceptViewModelOld | BaseScheduleShiftViewModel,
): string | undefined {
    if (!shift || !isShiftPlanningTimeDeviated(shiftPlanning, shift)) {
        return undefined;
    }

    return `${formatDate(shiftPlanning.start, 'HH:mm')}-${formatDate(shiftPlanning.end.transformToUpToButExcludingDate().date, 'HH:mm')}`;
}
