/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconOpenDirectory: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 100 83.9">
        <path d="M39,10l14,14.2l2.9,3h4.2h29.8v46.7h-80V10H39 M43.2,0H9.9c-5.5,0-10,4.5-10,10v63.9c0,5.5,4.5,10,10,10h80c5.5,0,10-4.5,10-10V27.1c0-5.5-4.5-10-10-10H60.2L43.2,0L43.2,0z" />
    </svg>
);

export default IconOpenDirectory;
/* eslint-enable max-len */
