import { BaseScheduleShiftPlanningResource } from '../../entities/BaseScheduleShiftPlanning/BaseScheduleShiftPlanning';
import { ShiftConceptPlanningResource } from '../../entities/ShiftConceptPlanning/ShiftConceptPlanning';
import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import {
    CommentResource,
    ShiftPlanningResource,
    ShiftPlanningType,
    ShiftPlanningViewModel,
    ShiftResource,
    UserWithPersonResource,
} from '../../models';
import transformCommentResource from '../CommentService/transformCommentResource';
// eslint-disable-next-line import/no-cycle
import { transformShiftResource } from '../ShiftService/transformShiftResource';
import { transformUserResourceToUserWithPerson } from '../UserService/transformUserResource';

const getShiftPlanningType = (shiftPlanningTypeString: string): ShiftPlanningType => {
    if (shiftPlanningTypeString === 'baseScheduleShiftPlannings') return ShiftPlanningType.baseSchedule;

    return ShiftPlanningType.shift;
};

// TODO: Voor elke type resource een eigen transformer maken en daarin een transformer callen voor de default resource attributen
export default function transformShiftPlanningResource(
    shiftPlanning: ShiftPlanningResource | ShiftConceptPlanningResource | BaseScheduleShiftPlanningResource,
    user?: UserWithPersonResource,
    shift?: ShiftResource,
    comments: CommentResource[] = [],
): ShiftPlanningViewModel {
    return {
        type: getShiftPlanningType(shiftPlanning.type),
        id: shiftPlanning.id,
        color: shiftPlanning.color,
        customStartTime: shiftPlanning.customStartTime,
        end: new UpToButExcludingDate(shiftPlanning.end).transformToUpToAndIncludingDate(),
        planned: ('planned' in shiftPlanning) ? shiftPlanning.planned : true,
        start: new Date(shiftPlanning.start),
        user: user ? transformUserResourceToUserWithPerson(
            user,
            user.person,
            user.departments,
            user.roles,
            user.employmentType,
        ) : undefined,
        shift: shift ? transformShiftResource(
            shift,
            undefined,
            undefined,
            shift.department,
        ) : undefined,
        comments: comments.map((comment) => transformCommentResource(comment, comment.owner)),
    };
}
