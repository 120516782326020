import { compareAsc } from './index';
import UpToAndIncludingDate from './UpToAndIncludingDate';

export default function doNewDatesDifferFromOldDates(
    start: Date | null,
    newStart: Date | null,
    end: UpToAndIncludingDate | null,
    newEnd: UpToAndIncludingDate | null,
) {
    if (!start && !newStart && !end && !newEnd) {
        return false;
    }

    if (!start || !newStart || !end || !newEnd) {
        return true;
    }

    return ((compareAsc(start, newStart) !== 0) || (compareAsc(end.date, newEnd.date) !== 0));
}
