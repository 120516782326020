import React, { FC } from 'react';

import { PieChart } from 'react-minimal-pie-chart';

import { translate } from '../../../../../../helpers/translations/translator';
import { LoketBalancesViewModel } from '../../../../../../models';
import LeaveOfAbsenceSaldo from '../LeaveOfAbsenceSaldo/LeaveOfAbsenceSaldo';

import './LeaveOfAbsenceSaldi.scss';


interface LeaveSaldo {
    hours: number;
    color: string;
}

interface LeaveOfAbsenceSaldiProps {
    className?: string;
    loketHours: LoketBalancesViewModel | null;
}

const colors = {
    nonStatutoryLeaveHoursTotal: '#158671',
    statutoryLeaveHoursTotal: '#26AE60',
    reservedLeaveHoursTotal: '#FFEB3B',
    timeForTimeTotal: '#3498DB',
    usedLeaveHoursTotal: '#FF9F19',
    neutral: '#F0F0F0',
};

const LeaveOfAbsenceSaldi: FC<LeaveOfAbsenceSaldiProps> = ({
    className,
    loketHours,
}) => {
    if (!loketHours) {
        return (
            <div className="leave-of-absence-saldi--loket-employee-not-found">
                {translate('pages.management.loketEmployeeNotFound')}
            </div>
        );
    }

    const leaveSaldi: LeaveSaldo[] = [
        {
            hours: loketHours.reservedLeaveHoursTotal,
            color: colors.reservedLeaveHoursTotal,
        },
        {
            hours: loketHours.nonStatutoryLeaveHoursTotal,
            color: colors.nonStatutoryLeaveHoursTotal,
        },
        {
            hours: loketHours.statutoryLeaveHoursTotal,
            color: colors.statutoryLeaveHoursTotal,
        },
        {
            hours: loketHours.timeForTimeTotal,
            color: colors.timeForTimeTotal,
        },
        {
            hours: loketHours.usedLeaveHoursTotal,
            color: colors.usedLeaveHoursTotal,
        },
    ];

    const totalLoketHours = leaveSaldi.reduce((total, saldo) => total + Math.max(saldo.hours, 0), 0);

    const defaultPie = [{ value: 1, color: colors.neutral }];

    return (
        <div className={`leave-of-absence-saldi ${className}`}>
            <div className="leave-of-absence-saldi__title">
                {translate('pages.management.leaveOfAbsenceSaldi.actualSaldos')}
            </div>
            <div className="leave-of-absence-saldi__pie-chart-container">
                <PieChart
                    className="leave-of-absence-saldi__pie-chart"
                    lineWidth={40}
                    data={totalLoketHours > 0 ? leaveSaldi.map(saldo => ({
                        value: Math.max(saldo.hours, 0),
                        color: saldo.color,
                    })) : defaultPie}
                />
            </div>
            <LeaveOfAbsenceSaldo
                isTitle
                title={translate('pages.management.leaveOfAbsenceSaldi.actualLeaveOfAbsenceSaldo')}
                hours={loketHours.leaveBalanceTotal}
            />
            <LeaveOfAbsenceSaldo
                title="Bovenwettelijke verlofuren"
                hours={loketHours.nonStatutoryLeaveHoursTotal}
                color={colors.nonStatutoryLeaveHoursTotal}
            />
            <LeaveOfAbsenceSaldo
                title="Wettelijke verlofuren"
                hours={loketHours.statutoryLeaveHoursTotal}
                color={colors.statutoryLeaveHoursTotal}
            />
            <LeaveOfAbsenceSaldo
                title={translate('pages.management.leaveOfAbsenceSaldi.reservedLeaveHours')}
                hours={loketHours.reservedLeaveHoursTotal}
                color={colors.reservedLeaveHoursTotal}
            >
                { loketHours.reservedLeaveHours.map(leaveHours => (
                    <LeaveOfAbsenceSaldo
                        key={leaveHours.label}
                        title={leaveHours.label}
                        hours={leaveHours.value}
                    />
                )) }
            </LeaveOfAbsenceSaldo>
            <LeaveOfAbsenceSaldo
                isTitle
                color={colors.timeForTimeTotal}
                title={translate('pages.management.leaveOfAbsenceSaldi.timeForTimeSaldo')}
                hours={loketHours.timeForTimeTotal}
            />
            <LeaveOfAbsenceSaldo
                title={translate('pages.management.leaveOfAbsenceSaldi.takenLeave')}
                hours={loketHours.usedLeaveHoursTotal}
                color={colors.usedLeaveHoursTotal}
            >
                { loketHours.usedLeaveHours.map(leaveHours => (
                    <LeaveOfAbsenceSaldo
                        key={leaveHours.label}
                        title={leaveHours.label}
                        hours={leaveHours.value}
                    />
                )) }
            </LeaveOfAbsenceSaldo>
        </div>
    );
};

export default LeaveOfAbsenceSaldi;
