import { FC, ReactElement } from 'react';

import Pagination from '@createnl/pagination';

import { translate } from '../../../helpers/translations/translator';

interface PacoPaginationProps {
    id: string;
    amountOfPages: number;
    currentPage: number;
    onChange: (page: number) => void;
    className?: string;
}

const PacoPagination: FC<PacoPaginationProps> = ({
    id,
    amountOfPages,
    currentPage,
    onChange,
    className = '',
}): ReactElement => (
    <Pagination
        id={id}
        buttonPrevText={translate('common.previous')}
        buttonNextText={translate('common.next')}
        centerNumbers
        onChange={onChange}
        amountOfPages={amountOfPages}
        currentPage={currentPage}
        className={`paco-pagination ${className}`}
    />
);

export default PacoPagination;
