import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { FormActionButtons } from '../../../compositions';
import trans from '../../../helpers/trans';
import { ModalFormProps } from '../../../types/modalFormTypes';

import './DeleteDistrictDivisionGroupTemplateForm.scss';

interface DeleteDistrictDivisionGroupTemplateFormProps extends ModalFormProps<{ id: string }> {
    districtDivisionGroupTemplateId: string;
}

const DeleteDistrictDivisionGroupTemplateForm: FC<DeleteDistrictDivisionGroupTemplateFormProps> = ({
    isLoading,
    districtDivisionGroupTemplateId,
    onSubmit,
    onCancel,
}): ReactElement => {
    const [formData, setFormData] = useState({ id: districtDivisionGroupTemplateId });

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formDataToSubmit = { id: formData.id };
        setFormData(formDataToSubmit);

        onSubmit(formDataToSubmit);
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="delete-district-division-group-template-form">
            <div className="delete-district-division-group-template-form__row">
                <div className="delete-district-division-group-template-form__col">
                    <p>{trans('containers.forms.deleteDistrictDivisionGroupTemplateForm.deleteDistrictDivisionGroupTemplate')}</p>
                </div>
            </div>
            <FormActionButtons
                isLoading={isLoading}
                submitText={trans('common.delete')}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default DeleteDistrictDivisionGroupTemplateForm;
