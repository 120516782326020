import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import ConnectedDistrictDivision from '../../connectors/ConnectedDistrictDivision/ConnectedDistrictDivision';

const DistrictDivisionPage: FC = (): ReactElement => (
    <Page>
        <ConnectedDistrictDivision />
    </Page>
);

export default DistrictDivisionPage;
