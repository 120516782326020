import { Comment } from '../../../../../@paco/entities/Comment/Comment';
import { ShiftEmployeeIconProps } from '../subcomponents/ShiftEmployeeIcon/ShiftEmployeeIcon';

const getTemporaryWorkerIcons = (
    comments: Comment[],
): ShiftEmployeeIconProps[] => {
    const icons: ShiftEmployeeIconProps[] = [];

    if (comments && comments.length > 0 && comments[comments.length - 1].body) {
        icons.push({
            iconName: 'comment',
            tooltipText: comments[comments.length - 1].body,
        });
    }

    return icons;
};

export default getTemporaryWorkerIcons;
