import axios from 'axios';

import { CommentResource, CommentViewModel } from '../../models';
import transformCommentResource from '../../services/CommentService/transformCommentResource';
import { AddCommentRequest } from './Comment';

export const postCommentApiCall = async (body: AddCommentRequest): Promise<CommentViewModel> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/comments`);

    const response = await axios.post<CommentResource>(String(url), { data: body });

    return transformCommentResource(response.data);
};
