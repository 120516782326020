import { BasicUser } from '../../../../entities/User/User';
import { getPersonFullName } from '../../../../helpers';
import { CompanyMedicType } from '../../../../types';
import { UsersSelectOption } from '../../../index';
import { UserSelectOption } from '../UsersSelect';

export const transformToUserSelectOptions = (users: BasicUser[]): UserSelectOption[] => users.map(user => (
    {
        value: getPersonFullName(user.person),
        label: (
            <UsersSelectOption
                key={user.id}
                isCompanyMedic={user.companyMedic === CompanyMedicType.yes}
                contractHours={user.contractHours}
                fullName={getPersonFullName(user.person)}
            />
        ),
        userId: user.id,
    }
));
