import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { DashboardSettingType } from '../../types';
import {
    DashboardSetting,
    DashboardSettingResource,
    DashboardSettingV3Resource,
    EditDashboardFormData,
    EditDashboardRequest,
} from './DashboardSetting';

export const transformLegacyResourceToDashboardSetting = (legacyResource: any): DashboardSetting => {
    const typeEnumKey = getEnumKeyByEnumValue(DashboardSettingType, legacyResource.slug);

    if (!typeEnumKey) {
        console.error(`[transformLegacyResourceToDashboardSetting]: Unknown slug found named "${legacyResource.slug}".`);
    }

    return {
        id: legacyResource.id,
        name: legacyResource.name,
        type: typeEnumKey ? DashboardSettingType[typeEnumKey] : DashboardSettingType.unknown,
    };
};

export const transformResourceToDashboardSetting = (resource: DashboardSettingResource): DashboardSetting => {
    const typeEnumKey = getEnumKeyByEnumValue(DashboardSettingType, resource.attributes.slug);

    if (!typeEnumKey) {
        console.error(`[transformResourceToDashboardSetting]: Unknown slug found named "${resource.attributes.slug}".`);
    }

    return {
        id: resource.id,
        name: resource.attributes.name,
        type: typeEnumKey ? DashboardSettingType[typeEnumKey] : DashboardSettingType.unknown,
    };
};

export const transformV3ResourceToDashboardSetting = (resource: DashboardSettingV3Resource): DashboardSetting => {
    const typeEnumKey = getEnumKeyByEnumValue(DashboardSettingType, resource.slug);

    if (!typeEnumKey) {
        console.error(`[transformV3ResourceToDashboardSetting]: Unknown slug found named "${resource.slug}".`);
    }

    return {
        id: resource.id,
        name: resource.name,
        type: typeEnumKey ? DashboardSettingType[typeEnumKey] : DashboardSettingType.unknown,
    };
};

export const transformEditDashboardFormDataToDashboardSettingIds = (
    formData: EditDashboardFormData,
    dashboardSettings: DashboardSetting[],
): string[] => formData.userWidgetTypes
    .map(userWidget => {
        const match = dashboardSettings.find(dashboardSetting => dashboardSetting.type === userWidget);

        if (!match) {
            console.error(`[transformEditDashboardFormDataToDashboardSettingIds]: DashboardSetting resource not found for "${userWidget}".`);
        }

        return match;
    })
    .map(dashboardSetting => dashboardSetting?.id) as string[];

export const transformDashboardSettingIdsToEditDashboardRequest = (
    dashboardSettingIds: string[],
    userId: string,
): EditDashboardRequest => ({
    type: 'users',
    id: userId,
    relationships: {
        dashboardSettings: {
            data: dashboardSettingIds.map(id => (
                {
                    type: 'dashboardSettings',
                    id,
                }
            )),
        },
    },
});
