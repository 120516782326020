import React, { FC, ReactElement } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { getSettingEnabled } from '../../../@paco/entities/Setting/SettingHelpers';
import { useTypedSelector } from '../../../@paco/redux/store';
import { CompletePeriodTracksToReviewContainer } from '../../../containers';
import { EditTrackFormData } from '../../../entities/Track/Track';
import { TrackViewModel } from '../../../models';
import { acceptTrack, editTrackToReview } from '../../../redux/@toolkit/@tracks/tracksToReview/tracksToReviewActions';
import { Reducers } from '../../../redux/reducers';

interface ConnectedCompletePeriodTracksToReviewProps {
    activePeriod?: PayrollPeriod;
    className?: string;
}

const ConnectedCompletePeriodTracksToReview: FC<ConnectedCompletePeriodTracksToReviewProps> = ({ activePeriod, className }): ReactElement => {
    const dispatch = useDispatch();

    const { permissions, role: currentUserRole } = useTypedSelector(state => state.authenticatedUserReducer);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);
    const { settings } = useTypedSelector(state => state.pacoSettingsReducer);
    const isTimerEnableFeedback = getSettingEnabled('TIMER_ENABLE_FEEDBACK', settings);
    const tracksToReview = useSelector<Reducers, TrackViewModel[]>(state => state.tracksToReviewReducer.tracksToReview);
    const isLoading = useSelector<Reducers, boolean>(state => state.tracksToReviewReducer.isLoading);

    const handleSubmitTrack = (formData: EditTrackFormData): void => {
        if (activePeriod) {
            // @ts-ignore
            dispatch(editTrackToReview(formData, activePeriod));
        }
    };

    const handleAcceptTrack = (trackId: string): void => {
        if (activePeriod) {
            // @ts-ignore
            dispatch(acceptTrack(trackId, activePeriod));
        }
    };

    return (
        <CompletePeriodTracksToReviewContainer
            isLoading={isLoading}
            currentUserRole={currentUserRole}
            isTimerEnableFeedback={isTimerEnableFeedback}
            payrollPeriods={payrollPeriods}
            permissions={permissions}
            settings={settings}
            tracksToReview={tracksToReview}
            onSubmitTrack={handleSubmitTrack}
            onAcceptTrack={handleAcceptTrack}
            className={className}
        />
    );
};

export default ConnectedCompletePeriodTracksToReview;
