import { ShiftConceptPlanningResource } from '../../../../../../../entities/ShiftConceptPlanning/ShiftConceptPlanning';
import { getOverlappingUnavailableSlotOnWeekday } from '../../../../../../../helpers';
import {
    addHours,
    addSeconds,
    areIntervalsOverlapping,
    compareAsc,
    endOfDay,
    formatDate,
    isEqual,
    isSameDay,
    startOfDay,
} from '../../../../../../../helpers/date';
import { translate } from '../../../../../../../helpers/translations/translator';
import {
    Absence,
    LeaveOfAbsenceResource,
    PreferToWorkTimeSlot,
    ShiftPlanning,
    ShiftPlanningType,
    UnavailableToWorkTimeSlot,
} from '../../../../../../../models';

export const getOverlappingResourceOnDate = (
    start: string | Date,
    day: Date,
    end?: string | Date,
) => {
    const justifiedEndDate = end ? addHours(end, -4) : start;

    return (
        areIntervalsOverlapping(
            {
                start: startOfDay(day),
                end: endOfDay(day),
            },
            {
                start,
                end: compareAsc(start, justifiedEndDate) === 1 ? addSeconds(start, 1) : justifiedEndDate,
            },
        )
    );
};

const compareResourcesFirstNames = (userAName?: string, userBName?: string): number => ((userAName && userBName) ? userAName.localeCompare(userBName) : 0);

export const getOverlappingResourcesOnDate = (
    leaveOfAbsences: LeaveOfAbsenceResource[],
    absences: Absence[],
    preferToWorkTimeSlots: PreferToWorkTimeSlot[],
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[],
    day: Date,
    shiftConceptPlannings: ShiftConceptPlanningResource[],
    shiftPlannings?: ShiftPlanning[],
): (
    LeaveOfAbsenceResource | Absence | PreferToWorkTimeSlot | UnavailableToWorkTimeSlot | ShiftConceptPlanningResource | ShiftPlanning
    )[] => {
    const overlappingLeaveOfAbsences = leaveOfAbsences
        .filter(leaveOfAbsence => getOverlappingResourceOnDate(leaveOfAbsence.start, day, leaveOfAbsence.end));
    const overlappingAbsences = absences
        .filter(absence => getOverlappingResourceOnDate(absence.start, day, absence.end));
    const overlappingUnavailableToWorkTimeSlots = unavailableToWorkTimeSlots
        .filter(unavailableSlot => getOverlappingResourceOnDate(unavailableSlot.start, day, unavailableSlot.end))
        .filter(unavailableSlot => getOverlappingUnavailableSlotOnWeekday(unavailableSlot, day));
    const overlappingAvailablePreferences = preferToWorkTimeSlots
        .filter(preferToWorkTimeSlot => getOverlappingResourceOnDate(preferToWorkTimeSlot.start, day, preferToWorkTimeSlot.end));
    const overlappingShiftConceptPlannings = (shiftConceptPlannings || [])
        .filter(shiftConceptPlanning => getOverlappingResourceOnDate(shiftConceptPlanning.start, day, shiftConceptPlanning.end));
    const overlappingShiftPlannings = (shiftPlannings || [])
        .filter(shiftPlanning => getOverlappingResourceOnDate(shiftPlanning.start, day, shiftPlanning.end));

    return [
        ...overlappingLeaveOfAbsences,
        ...overlappingAbsences,
        ...overlappingAvailablePreferences,
        ...overlappingUnavailableToWorkTimeSlots,
        ...overlappingShiftConceptPlannings,
        ...overlappingShiftPlannings,
    ].sort((a, b) => compareResourcesFirstNames(a.user?.firstname, b.user?.firstname));
};

export const getStyleClasses = (
    type: string,
    status?: string,
) => {
    if (type === 'leaveOfAbsences') {
        return `leave-of-absence-${status}`;
    }

    if (type === 'absences') {
        return 'absence';
    }

    if (type === 'PreferToWorkTimeSlots') {
        return 'planning-preference-available';
    }

    if (type === 'UnavailableToWorkTimeSlots') {
        return 'planning-preference-unavailable';
    }

    if (type === 'shiftConceptPlannings') {
        return 'shift-concept-planning';
    }

    if (type === 'shiftPlannings') {
        return 'shift-planning';
    }

    return '';
};

export const getResourceStartAndEndTime = (
    start: Date | string,
    type: string,
    day: Date,
    end?: Date | string,
) => {
    const format = 'HH:mm';

    if (type === 'absences' && end === null) {
        const startTime = formatDate(start, format);
        return `${translate('pages.absences.from')} ${startTime}`;
    }

    if (type === 'leaveOfAbsences' || type === 'absences' || (type === 'PreferToWorkTimeSlots') || type === 'shiftConceptPlannings') {
        const startIsSameDay = isSameDay(start, day);

        const justifiedEnd = end ? addHours(end, -4) : undefined;
        const endIsSameDay = justifiedEnd && isSameDay(justifiedEnd, day);

        if (type === 'PreferToWorkTimeSlots' && (
            (!startIsSameDay && !endIsSameDay)
            || isEqual(addHours(day, 28), end || new Date())
        )) {
            return translate('common.wholeDay');
        }

        const startTime = startIsSameDay ? formatDate(start, format) : '00:00';
        const endTime = endIsSameDay ? formatDate(end || '', format) : '23:59';

        return `${startTime} - ${endTime}`;
    }

    const startTime = formatDate(start, format);
    const endTime = formatDate(end || '', format);

    return `${startTime} - ${endTime}`;
};

const getDepartmentName = (shiftPlanning: ShiftPlanning | ShiftConceptPlanningResource): string => {
    let department = null;

    if (shiftPlanning.type === ShiftPlanningType.shift) {
        department = shiftPlanning.shift.department;
    }

    if (shiftPlanning.type === 'shiftConceptPlannings') {
        department = shiftPlanning.shiftConcept.department;
    }

    if (!department) {
        return '';
    }

    if (!department.group) {
        return department.name;
    }

    return `${department.group.name} - ${department.name}`;
};

export const getResourceDescription = (
    type: string,
    status?: string,
    shiftPlanning?: ShiftPlanning,
    shiftConceptPlanning?: ShiftConceptPlanningResource,
): string => {
    if (type === 'leaveOfAbsences' && status === 'approved') {
        return translate('pages.management.approvedLeaveOfAbsence');
    }

    if (type === 'leaveOfAbsences' && status === 'open') {
        return translate('pages.management.pendingLeaveOfAbsence');
    }

    if (type === 'leaveOfAbsences' && status === 'denied') {
        return translate('pages.management.deniedLeaveOfAbsence');
    }

    if (type === 'absences') {
        return translate('pages.management.sickness');
    }

    if (shiftPlanning) {
        return getDepartmentName(shiftPlanning);
    }

    if (shiftConceptPlanning) {
        return getDepartmentName(shiftConceptPlanning);
    }

    if (type === 'PreferToWorkTimeSlots') {
        return translate('pages.shifts.available');
    }

    if (type === 'UnavailableToWorkTimeSlots') {
        return translate('pages.shifts.notAvailable');
    }

    return '';
};
