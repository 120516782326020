import React, { FC } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';


interface FilterProps {
    filter: AppFilter;
    onChange: (filter: AppFilter) => void;
}

const ShowPublishedShiftsFilter: FC<FilterProps> = ({ filter, onChange }) => {
    const onCheckboxChange = (showPublishedShifts: boolean) => {
        const shifts: AppFilter['shifts'] = {
            ...filter.shifts,
            showPublishedShifts,
        };

        onChange({
            ...filter,
            shifts,
        });
    };

    return (
        <FilterBlock title={translate('common.calendar')}>
            <FormGroup check className="p-0">
                <Label check>
                    <CustomInput
                        id="show-published-shifts-filter-checkbox"
                        type="checkbox"
                        label={translate('pages.shifts.showPublishedShifts')}
                        checked={filter.shifts.showPublishedShifts}
                        onChange={() => onCheckboxChange(!filter.shifts.showPublishedShifts)}
                    />
                </Label>
            </FormGroup>
        </FilterBlock>
    );
};

export default ShowPublishedShiftsFilter;
