import { convertDateToApiParamDateTime } from '../../../helpers/date';
import { CopyTemporaryWorkerData, TemporaryWorker } from '../../../models';

export default function transformShiftToCopyShiftPlanningData(
    temporaryWorker: TemporaryWorker,
    shiftId: string,
): CopyTemporaryWorkerData {
    return {
        shiftId,
        name: temporaryWorker.name,
        start: convertDateToApiParamDateTime(temporaryWorker.start),
        end: convertDateToApiParamDateTime(temporaryWorker.end),
        color: temporaryWorker.color || null,
    };
}
