import trans from '../../helpers/trans';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { ChangeLogEntry, ChangeLogEntryResource } from './ChangeLog';
import { transformToChangeLogEntry } from './ChangeLogTransformers';

export const getChangeLogApiCall = async (): Promise<FetchCollectionResult<ChangeLogEntry[], string>> => {
    try {
        const response = await fetch('change-log.json');

        const data = await response.json() as unknown as ChangeLogEntryResource[];

        if (!Array.isArray(data)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const changeLogEntries = data.map(transformToChangeLogEntry);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: changeLogEntries,
        };
    } catch (error) {
        console.error('[getChangeLogApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
