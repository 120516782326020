import React, { FC } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';


interface FilterProps {
    filter: AppFilter;
    onChange: (filter: AppFilter) => void;
}

const UsersLoketFilter: FC<FilterProps> = ({ filter, onChange }) => {
    const onCheckboxChange = (showUncoupledLoketUsers: boolean) => {
        const management: AppFilter['management'] = {
            ...filter.management,
            showUncoupledLoketUsers,
        };

        onChange({
            ...filter,
            management,
        });
    };

    return (
        <FilterBlock title={translate('common.loket')}>
            <FormGroup check className="p-0">
                <Label check>
                    <CustomInput
                        id="uncoupled-loket-users-filter-checkbox"
                        type="checkbox"
                        label={translate('pages.management.showUncoupledLoketUsers')}
                        checked={filter.management.showUncoupledLoketUsers}
                        onChange={() => onCheckboxChange(!filter.management.showUncoupledLoketUsers)}
                    />
                </Label>
            </FormGroup>
        </FilterBlock>
    );
};

export default UsersLoketFilter;
