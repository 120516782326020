import React, { FC } from 'react';

import { Button } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import Logo from '../../../../images/logo-loket.png';

import './Couplings.scss';

interface CouplingsProps {
    onLinkLoketClick: () => void;
}

const Couplings: FC<CouplingsProps> = ({ onLinkLoketClick }) => (
    <div className="couplings">
        <img alt="loket-logo" className="couplings-logo" src={Logo} />
        <div className="couplings-description">
            {translate('pages.management.loketDescription')}
        </div>
        <Button className="couplings-button" type="submit" color="orange" onClick={onLinkLoketClick}>
            {translate('common.link')}
        </Button>
    </div>
);

export default Couplings;
