import { SettingsRoutes } from '../../../pages/Settings/SettingsRoutes';
import trans from '../../helpers/trans';
import { SubNavRoute } from '../routes';

export type SettingNavigationRouteKey =
    'employee' |
    'departments' |
    'manager' |
    'periods' |
    'timer' |
    'districtDivisionTemplates';

const settingNavigationRoutes: SubNavRoute[] = [
    {
        key: 'manager',
        path: SettingsRoutes.manager(),
        label: trans('routes.settings.manager.label'),
        permissions: ['edit-all-settings'],
    },
    {
        key: 'employee',
        path: SettingsRoutes.employee(),
        label: trans('routes.settings.employee.label'),
        permissions: ['edit-all-settings'],
    },
    {
        key: 'timer',
        path: SettingsRoutes.timer(),
        label: trans('routes.settings.timer.label'),
        permissions: ['edit-all-settings'],
    },
    {
        key: 'periods',
        path: SettingsRoutes.periods(),
        label: trans('routes.settings.periods.label'),
        permissions: ['edit-all-payroll-periods'],
    },
    {
        key: 'departments',
        path: SettingsRoutes.groups(),
        label: trans('routes.settings.departments.label'),
        permissions: ['edit-all-departments', 'edit-all-groups'],
    },
    {
        key: 'districtDivisionTemplates',
        path: SettingsRoutes.districtDivisionTemplates(),
        label: trans('routes.settings.districtDivisionTemplates.label'),
        permissions: ['view-all-district-division-templates'],
    },
];

export default settingNavigationRoutes;
