import { validateTime } from '../../../../../helpers/tools/validation';
import { translate } from '../../../../../helpers/translations/translator';
import { EditShiftPlanningFormData } from '../../../../../models';

export interface ValidationErrors {
    startTime?: string;
    endTime?: string;
}

export default function validateFormData(formData: EditShiftPlanningFormData, isSubmitted = true): ValidationErrors {
    const errors: ValidationErrors = {};

    if (!isSubmitted) {
        return {};
    }

    if (!validateTime(formData.startTime)) {
        errors.startTime = translate('validation.isNotValid', {
            item: translate('pages.tracks.startTime'),
        });
    }

    if (!validateTime(formData.endTime)) {
        errors.endTime = translate('validation.isNotValid', {
            item: translate('pages.tracks.endTime'),
        });
    }

    return errors;
}
