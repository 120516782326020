import { EmploymentDatesFormData } from '../User/User';
import {
    DivergentPreEmploymentHour,
    DivergentPreEmploymentHourFormData,
    DivergentPreEmploymentHourPatchRequest,
    DivergentPreEmploymentHourPostRequest,
    DivergentPreEmploymentHourResource,
    DivergentPreEmploymentHourV3Resource,
} from './DivergentPreEmploymentHour';

export const transformEmploymentDatesFormDataToDivergentPreEmploymentHourFormData = (formData: EmploymentDatesFormData): DivergentPreEmploymentHourFormData => ({
    contractHours: parseFloat(formData.divergentPreEmploymentHours || '0'),
});

export const transformToDivergentPreEmploymentHour = (resource: DivergentPreEmploymentHourResource): DivergentPreEmploymentHour => ({
    id: resource.id,
    checked: resource.attributes.checked,
    contractHours: resource.attributes.contractHours || 0,
});

export const transformV3ResourceToDivergentPreEmploymentHour = (resource: DivergentPreEmploymentHourV3Resource): DivergentPreEmploymentHour => ({
    id: resource.id,
    checked: resource.checked,
    contractHours: resource.contractHours || 0,
});

export const transformDivergentPreEmploymentHourFormDataToPostRequest = (
    employmentId: string,
    formData: DivergentPreEmploymentHourFormData,
): DivergentPreEmploymentHourPostRequest => ({
    type: 'divergentPreEmploymentHours',
    attributes: {
        ...(formData.contractHours !== undefined && { contractHours: formData.contractHours }),
        ...(formData.checked !== undefined && { checked: formData.checked }),
    },
    relationships: {
        employment: {
            data: {
                type: 'employments',
                id: employmentId,
            },
        },
    },
});

export const transformDivergentPreEmploymentHourFormDataToPatchRequest = (
    divergentPreEmploymentHourId: string,
    formData: DivergentPreEmploymentHourFormData,
): DivergentPreEmploymentHourPatchRequest => ({
    type: 'divergentPreEmploymentHours',
    id: divergentPreEmploymentHourId,
    attributes: {
        ...(formData.contractHours !== undefined && { contractHours: formData.contractHours }),
        ...(formData.checked !== undefined && { checked: formData.checked }),
    },
});
