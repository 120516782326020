import { put, select, takeLatest } from 'redux-saga/effects';

import { getPayrollPeriodFromDate } from '../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformLegacyPayrollPeriodToPayrollPeriod } from '../../@paco/entities/PayrollPeriod/PayrollPeriodTransformers';
import { getMiddleOfDateRange, getMiddleOfDays } from '../../@paco/helpers/date';
import {
    calendarSlice,
    setActivePayrollPeriod,
    setSelectedDays,
    setSelectedMobileDay,
    setTimeMode,
} from '../../@paco/redux/@interface/calendar/calendarReducer';
import { TIME_MODES } from '../../constants';
import {
    addDays,
    addWeeks,
    eachDayOfInterval,
    getRangeFromDate,
} from '../../helpers/date';
import {
    WEEK_NAVIGATOR_SAGA_SET_DATES,
    WEEK_NAVIGATOR_SAGA_SET_SELECTED_DATE,
    WEEK_NAVIGATOR_SAGA_SET_TIME_MODE,
    WEEK_NAVIGATOR_SET_ACTIVE_PAYROLL_PERIOD,
    WEEK_NAVIGATOR_SET_DATES,
    WEEK_NAVIGATOR_SET_SELECTED_DATE,
    WEEK_NAVIGATOR_SET_TIME_MODE,
} from '../actionTypes';

function* setDates(action) {
    const { selectedDate, startDate, endDate } = action;
    const state = yield select();
    const { payrollPeriods } = state.appReducer;

    const selectedDays = eachDayOfInterval(startDate, endDate);
    const payrollPeriod = getPayrollPeriodFromDate(
        getMiddleOfDays(selectedDays),
        payrollPeriods.map(transformLegacyPayrollPeriodToPayrollPeriod),
    );

    yield put({ type: WEEK_NAVIGATOR_SET_DATES, startDate, endDate });
    yield put({ type: WEEK_NAVIGATOR_SET_SELECTED_DATE, selectedDate: selectedDate || startDate });
    yield put({ type: WEEK_NAVIGATOR_SET_ACTIVE_PAYROLL_PERIOD, payrollPeriod });

    // Trigger @paco/calendar reducer actions
    yield put(setSelectedDays(selectedDays));
    yield put(setSelectedMobileDay(selectedDate || startDate));
    yield put(setActivePayrollPeriod(payrollPeriod));
}

function* setSelectedDate(action) {
    const { selectedDate, startDate, endDate } = action;
    const state = yield select();
    const { payrollPeriods } = state.appReducer;

    yield put({ type: WEEK_NAVIGATOR_SET_SELECTED_DATE, selectedDate });

    if ((selectedDate < startDate) || (selectedDate > endDate)) {
        const modifier = selectedDate >= endDate ? 1 : -1;
        const newStartDate = addWeeks(startDate, modifier);
        const newEndDate = addWeeks(endDate, modifier);
        const payrollPeriod = getPayrollPeriodFromDate(
            getMiddleOfDateRange(newStartDate, newEndDate),
            payrollPeriods.map(transformLegacyPayrollPeriodToPayrollPeriod),
        );

        yield put({ type: WEEK_NAVIGATOR_SET_DATES, startDate: newStartDate, endDate: newEndDate });
        yield put({ type: WEEK_NAVIGATOR_SET_ACTIVE_PAYROLL_PERIOD, payrollPeriod });

        // Trigger @paco/calendar reducer actions
        const selectedDays = eachDayOfInterval(newStartDate, newEndDate);
        yield put(setSelectedDays(selectedDays));
    }
}

function* setLegacyTimeMode(action) {
    const state = yield select();
    const { payrollPeriods } = state.appReducer;
    const { selectedDateAtTimeSwitch, selectedDate } = state.weekNavigatorReducer;
    const { mode } = state.weekNavigatorReducer;

    // When switching back to week from month we want to go back to the date selected
    // in week view
    let newSelectedDate = selectedDateAtTimeSwitch || selectedDate;

    // Adding a few days to prevent jumping to previous month / year
    if (mode === TIME_MODES.WEEK) {
        newSelectedDate = addDays(selectedDate, 3.5);
    } else if (((mode === TIME_MODES.PERIOD) && (action.mode === TIME_MODES.MONTH))
        || ((action.mode === TIME_MODES.YEAR))) {
        newSelectedDate = addDays(selectedDate, 14);
    }

    const dates = getRangeFromDate(newSelectedDate, action.mode, payrollPeriods);
    const payrollPeriod = getPayrollPeriodFromDate(getMiddleOfDays(dates), payrollPeriods.map(transformLegacyPayrollPeriodToPayrollPeriod));

    yield put({ type: WEEK_NAVIGATOR_SET_DATES, startDate: dates[0], endDate: dates[dates.length - 1] });
    yield put({ type: WEEK_NAVIGATOR_SET_ACTIVE_PAYROLL_PERIOD, payrollPeriod });
    yield put({ type: WEEK_NAVIGATOR_SET_SELECTED_DATE, selectedDate: dates[0] });
    yield put({
        type: WEEK_NAVIGATOR_SET_TIME_MODE,
        mode: action.mode,
        prevSelectedDate: selectedDate,
    });

    // Trigger @paco/calendar reducer actions
    yield put(setSelectedDays(dates));
    yield put(setTimeMode(action.mode));
    yield put(setActivePayrollPeriod(payrollPeriod));
}

function* onSetSelectedDays(action) {
    const { payload: days } = action;

    yield put({
        type: WEEK_NAVIGATOR_SET_DATES,
        startDate: days[0],
        endDate: days[days.length - 1],
    });
}

function* onSetActivePayrollPeriod(action) {
    const { payload: payrollPeriod } = action;

    yield put({
        type: WEEK_NAVIGATOR_SET_ACTIVE_PAYROLL_PERIOD,
        payrollPeriod,
    });
}

function* onSetTimeMode(action) {
    const { payload: timeMode } = action;

    yield put({
        type: WEEK_NAVIGATOR_SET_TIME_MODE,
        mode: timeMode,
    });
}

export default function* weekNavigatorWatcher() {
    yield takeLatest(WEEK_NAVIGATOR_SAGA_SET_DATES, setDates);
    yield takeLatest(WEEK_NAVIGATOR_SAGA_SET_SELECTED_DATE, setSelectedDate);
    yield takeLatest(WEEK_NAVIGATOR_SAGA_SET_TIME_MODE, setLegacyTimeMode);

    // Listen for changes in @paco/calendar store
    yield takeLatest(calendarSlice.actions.setSelectedDays, onSetSelectedDays);
    yield takeLatest(calendarSlice.actions.setActivePayrollPeriod, onSetActivePayrollPeriod);
    yield takeLatest(calendarSlice.actions.setTimeMode, onSetTimeMode);
}
