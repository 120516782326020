import { compareTimeStrings } from '../../../helpers/date';
import { addDays, formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { Department } from '../Department/Department';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { ShiftConceptResource, ShiftPlanningShiftConcept } from '../ShiftConcept/ShiftConcept';
import { ShiftPlanning } from '../ShiftPlanning/ShiftPlanning';
import { TemporaryWorker } from '../TemporaryWorker/TemporaryWorker';
import { User } from '../User/User';
import {
    AddShiftFormData,
    AddShiftRequest,
    EditShiftFormData,
    EditShiftTimeFormData,
    EditShiftTimeRequest,
    Shift,
    ShiftIndex,
    ShiftPlanningShift,
    ShiftResource,
    TrackShift,
} from './Shift';

export const transformToShiftIndex = (
    shiftResource: ShiftResource,
    department: Department,
    shiftPlannings: ShiftPlanning[],
    temporaryWorkers?: TemporaryWorker[],
    previouslyPlannedUsers: User[] = [],
): ShiftIndex => ({
    id: shiftResource.id,
    peopleLimit: shiftResource.attributes.peopleLimit,
    department,
    period: transformToPeriod(
        new Date(shiftResource.attributes.start),
        new Date(shiftResource.attributes.end),
    ),
    shiftPlannings,
    temporaryWorkers: temporaryWorkers || [],
    previouslyPlannedUsers,
});

export const transformToShift = (shiftResource: ShiftResource): Shift => ({
    id: shiftResource.id,
    peopleLimit: shiftResource.attributes.peopleLimit,
    period: transformToPeriod(
        new Date(shiftResource.attributes.start),
        new Date(shiftResource.attributes.end),
    ),
});

export const transformToTrackShift = (shiftResource: ShiftResource, department: Department): TrackShift => ({
    id: shiftResource.id,
    peopleLimit: shiftResource.attributes.peopleLimit,
    department,
    period: transformToPeriod(
        new Date(shiftResource.attributes.start),
        new Date(shiftResource.attributes.end),
    ),
});

export const transformToShiftPlanningShift = (shiftResource: ShiftResource, department: Department): ShiftPlanningShift => ({
    id: shiftResource.id,
    peopleLimit: shiftResource.attributes.peopleLimit,
    department,
    period: transformToPeriod(
        new Date(shiftResource.attributes.start),
        new Date(shiftResource.attributes.end),
    ),
});

export const transformToShiftPlanningShiftConcept = (shiftResource: ShiftConceptResource, department: Department): ShiftPlanningShiftConcept => ({
    id: shiftResource.id,
    peopleLimit: shiftResource.attributes.peopleLimit,
    department,
    period: transformToPeriod(
        new Date(shiftResource.attributes.start),
        new Date(shiftResource.attributes.end),
    ),
});

export const transformShiftIndexToEditShiftFormData = (shiftIndex: ShiftIndex): EditShiftFormData => ({
    period: shiftIndex.period,
    peopleLimit: shiftIndex.peopleLimit,
});

export const transformAddShiftFormDataToAddApiParams = (
    data: AddShiftFormData,
): AddShiftRequest => {
    const endDate = compareTimeStrings(data.startTime, data.endTime)
        ? formatDate(addDays(new Date(data.date), 1), 'y-MM-dd')
        : data.date;

    return {
        type: 'shifts',
        attributes: {
            start: `${data.date} ${data.startTime}:00`,
            end: `${endDate} ${data.endTime}:00`,
            peopleLimit: data.peopleLimit,
        },
        relationships: {
            department: {
                data: {
                    type: 'departments',
                    id: data.departmentId,
                },
            },
        },
    };
};

export const transformEditShiftTimeFormDataToEditShiftRequest = (
    data: EditShiftFormData | EditShiftTimeFormData,
    shiftId: string,
): EditShiftTimeRequest => ({
    type: 'shifts',
    id: shiftId,
    attributes: {
        start: formatDate(data.period.start, DateFormat.apiDateTime),
        end: formatDate(data.period.end, DateFormat.apiDateTime),
        ...('peopleLimit' in data && { peopleLimit: data.peopleLimit }),
    },
});
