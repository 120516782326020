import React, { FC, useMemo } from 'react';

import { ConnectedUserContractHours } from '../../../../@paco/connectors';
import { getUserFullName } from '../../../../helpers';
import { UserViewModel } from '../../../../models';
import UserPageLink from '../../../UserPageLink/UserPageLink';
import ExchangeRequestButton from '../ExchangeRequestButton/ExchangeRequestButton';

import './ExchangeRequestCardReply.scss';


interface ExchangeRequestCardReplyProps {
    onApproveClick: () => void;
    onDeclineClick: () => void;
    user: UserViewModel;
}

const ExchangeRequestCardReply: FC<ExchangeRequestCardReplyProps> = ({
    user,
    onApproveClick,
    onDeclineClick,
}) => {
    const name = useMemo(() => getUserFullName(user), [user]);
    const level = useMemo(() => user?.experience?.name, [user]);

    return (
        <div className="exc-card-reply">
            <div className="exc-card-reply__name">
                <UserPageLink className="exc-card-reply__name-link" name={name} id={user.id} />
                {level && (
                    <div className="badge-level exc-card-reply__level">
                        {level}
                    </div>
                )}
                <ConnectedUserContractHours
                    userId={user.id}
                    plannedHoursInWeek={user.plannedHoursInWeek}
                    className="exc-card-reply__hours"
                />
            </div>
            <div className="exc-card-reply__button-container">
                <ExchangeRequestButton type="decline" onClick={onDeclineClick} />
                <ExchangeRequestButton type="accept" onClick={onApproveClick} />
            </div>
        </div>
    );
};

export default ExchangeRequestCardReply;
