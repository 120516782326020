import { EmployeeSyncResultResource, FullLoketUserResource, LoketUserResource } from './LoketUser';

export const isLoketUser = (resource: LoketUserResource): resource is LoketUserResource => (
    typeof resource === 'object'
    && resource !== null
    && 'id' in resource
    && 'firstName' in resource
    && 'lastName' in resource
);

export const isFullLoketUser = (resource: FullLoketUserResource): resource is FullLoketUserResource => (
    typeof resource === 'object'
    && resource !== null
    && 'id' in resource
    && 'firstName' in resource
    && 'lastName' in resource
    && 'employeeNumber' in resource
    && 'emailAddress' in resource
);

export const isEmployeeSyncResultResource = (resource: any): resource is EmployeeSyncResultResource => (
    typeof resource === 'object'
    && resource !== null
    && 'latestPayrollId' in resource
    && 'loketEmployeeId' in resource
    && 'message' in resource
    && 'userId' in resource
);

export const isLoketUserCollection = (collection: LoketUserResource[]): boolean => Array.isArray(collection) && collection.every(isLoketUser);
