import { AddCommentRequest, commentRelationshipKeys, commentRelationshipTypes } from './Comment';

export const transformAddCommentRequest = (
    body: string,
    relationship: commentRelationshipKeys,
    relationshipType: commentRelationshipTypes,
    relationshipId: string,
): AddCommentRequest => ({
    type: 'comments',
    attributes: {
        body,
    },
    relationships: {
        [relationship]: {
            data: {
                type: relationshipType,
                id: relationshipId,
            },
        },
    },
});
