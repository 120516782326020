import React, { FC } from 'react';

import classnames from 'classnames';

import { formatDate } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import { ShiftExchangeViewModel } from '../../models';
import ButtonHistory from '../ButtonHistory/ButtonHistory';
import ExchangeRequestButton from '../ExchangeRequestCard/subcomponents/ExchangeRequestButton/ExchangeRequestButton';
import ExchangeShiftDivider from './subcomponents/ExchangeShiftDivider/ExchangeShiftDivider';
import ExchangeShiftInfo from './subcomponents/ExchangeShiftInfo/ExchangeShiftInfo';

import './ExchangeShiftCard.scss';


interface ExchangeShiftCardProps {
    shiftExchange: ShiftExchangeViewModel;
    onShowLogsClick?: (exchange: ShiftExchangeViewModel) => void;
    onApproveClick?: (exchangeId: string) => void;
    onDeclineClick?: (exchangeId: string) => void;
    className?: string;
}

const ExchangeShiftCard: FC<ExchangeShiftCardProps> = ({
    shiftExchange,
    onShowLogsClick,
    onApproveClick,
    onDeclineClick,
    className,
}) => {
    const createdAtDate = formatDate(shiftExchange.createdAt, 'dd-MM-yyyy');
    const classNames = classnames(
        'card request-card exc-shift-card',
        { 'exc-request-card--has-button-history': onShowLogsClick },
        [className],
    );

    return (
        <div className={classNames}>
            <div className="exc-shift-card__top">
                <div className="request-card-top__title exc-shift-card__title ">
                    {translate('pages.absences.exchangeShift')}
                </div>
                <div className="request-card__requested-at">
                    {translate('pages.absences.requestedOn')}
                    {' '}
                    {createdAtDate}
                    { onShowLogsClick && <ButtonHistory className="request-card__button-history" onClick={() => onShowLogsClick(shiftExchange)} /> }
                </div>
            </div>

            {(shiftExchange.shiftPlanning1 && shiftExchange.shiftPlanning1.shift && shiftExchange.shiftPlanning1.user) && (
                <ExchangeShiftInfo
                    shift={shiftExchange.shiftPlanning1.shift}
                    shiftPlanning={shiftExchange.shiftPlanning1}
                    user={shiftExchange.shiftPlanning1.user}
                />
            )}
            <ExchangeShiftDivider />
            {(shiftExchange.shiftPlanning2 && shiftExchange.shiftPlanning2.shift && shiftExchange.shiftPlanning2.user) && (
                <ExchangeShiftInfo
                    shift={shiftExchange.shiftPlanning2.shift}
                    shiftPlanning={shiftExchange.shiftPlanning2}
                    user={shiftExchange.shiftPlanning2.user}
                />
            )}

            <div className="exc-shift-card__buttons">
                <ExchangeRequestButton type="decline" onClick={onDeclineClick ? () => onDeclineClick(shiftExchange.id) : undefined} />
                <ExchangeRequestButton type="accept" onClick={onApproveClick ? () => onApproveClick(shiftExchange.id) : undefined} />
            </div>
        </div>
    );
};

export default ExchangeShiftCard;
