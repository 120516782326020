import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';
import { updatePayrollPeriodWithPayrollPeriodWeek } from '../../entities/PayrollPeriod/PayrollPeriodHelpers';
import { togglePayrollPeriodLockApiCall } from '../../entities/PayrollPeriod/PayrollPeriodService';
import { PayrollPeriodWeek } from '../../entities/PayrollPeriodWeek/PayrollPeriodWeek';
import { togglePayrollPeriodWeekLockApiCall } from '../../entities/PayrollPeriodWeek/PayrollPeriodWeekService';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsLockPayrollPeriodSuccessful,
    setIsLockPayrollPeriodWeekSuccessful,
    setIsUnlockPayrollPeriodSuccessful,
    setIsUnlockPayrollPeriodWeekSuccessful,
    setLastUpdatedPayrollPeriod,
} from './completePeriodsReducer';

export const togglePayrollPeriodWeekLock = (payrollPeriod: PayrollPeriod, payrollPeriodWeek: PayrollPeriodWeek) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const response = await togglePayrollPeriodWeekLockApiCall(payrollPeriodWeek.id, !payrollPeriodWeek.isLocked);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const updatedPayrollPeriod = updatePayrollPeriodWithPayrollPeriodWeek(payrollPeriod, response.data);
        dispatch(setLastUpdatedPayrollPeriod(updatedPayrollPeriod));

        if (!response.data.isLocked) {
            dispatch(setIsUnlockPayrollPeriodWeekSuccessful(true));

            return;
        }

        dispatch(setIsLockPayrollPeriodWeekSuccessful(true));
    } catch (error) {
        console.error('[togglePayrollPeriodWeekLock]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const togglePayrollPeriodLock = (payrollPeriod: PayrollPeriod) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const response = await togglePayrollPeriodLockApiCall(payrollPeriod.id, !payrollPeriod.isLocked);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLastUpdatedPayrollPeriod(response.data));

        if (!response.data.isLocked) {
            dispatch(setIsUnlockPayrollPeriodSuccessful(true));

            return;
        }

        dispatch(setIsLockPayrollPeriodSuccessful(true));
    } catch (error) {
        console.error('[togglePayrollPeriodLock]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
