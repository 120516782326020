import { FC, MouseEvent } from 'react';

import classnames from 'classnames';

import { IconButton } from '../../../index';
import { WidgetLabel } from '../index';

import './WidgetDraggable.scss';

interface WidgetDraggableProps {
    isActive?: boolean;
    hasPZLabel?: boolean;
    index: number;
    label: string;
    onDeleteButtonClick: (index: number) => void;
    onDragEnter: (index: number) => void;
    onDragStart: (index: number) => void;
    onDrop: () => void;
    className?: string;
}

const WidgetDraggable: FC<WidgetDraggableProps> = ({
    isActive,
    hasPZLabel,
    index,
    label,
    onDeleteButtonClick,
    onDragEnter,
    onDragStart,
    onDrop,
    className = '',
}) => {
    const draggableClassName = classnames('widget-draggable', {
        'widget-draggable--is-active': isActive,
    }, className);

    const handledDeleteButtonClick = () => {
        onDeleteButtonClick(index);
    };

    const handleDragEnter = () => {
        onDragEnter(index);
    };

    const handleDragOver = (e: MouseEvent) => {
        e.preventDefault();
    };

    const handleDragStart = () => {
        onDragStart(index);
    };

    return (
        <div
            draggable
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragStart={handleDragStart}
            onDrop={onDrop}
            className={draggableClassName}
        >
            <WidgetLabel
                hasPZLabel={hasPZLabel}
                label={label}
                className="widget-draggable__label"
            />
            <div className="widget-draggable__divider" />
            <IconButton
                hideLabel
                icon="min-circle"
                text="delete"
                onClick={handledDeleteButtonClick}
                className="widget-draggable__button"
                iconClassName="widget-draggable__icon"
            />
        </div>
    );
};

export default WidgetDraggable;
