import { ShiftViewModel } from './Shift';

export enum DragState {
    neutral = 'neutral',
    selected = 'selected',
    preview = 'preview',
}

export interface ShiftDraggable extends ShiftViewModel {
    dragState: DragState;
}
