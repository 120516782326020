import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getSettingsApiCall } from '../../entities/Setting/SettingService';
import { transformLegacySettingToSetting } from '../../entities/Setting/SettingTransformers';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setSettings } from './settingsReducer';

export const transformAndSetSettings = (legacySettings: any[]) => async (dispatch: TypedDispatch): Promise<void> => {
    const settings = legacySettings.map(transformLegacySettingToSetting);

    dispatch(setSettings(settings));
};

export const fetchSettings = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getSettingsApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setSettings(response.data));
    } catch (error) {
        console.error('[fetchSettings]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
