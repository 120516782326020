import { FEEDBACK_FAILURE, FEEDBACK_REQUEST, FEEDBACK_SUCCESS } from '../actionTypes';

const initialState = {
    loading: false,
    feedbackList: [],
    errors: [],
    scores: {
        id: null,
        neutral: 0,
        satisfied: 0,
        unsatisfied: 0,
        tracks: {
            checkIn: null,
            checkOut: null,
            owner: {
                firstname: null,
                lastname: null,
            },
        },
    },
};

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEEDBACK_REQUEST:
            return { ...state, loading: true, errors: [] };
        case FEEDBACK_SUCCESS:
            return {
                ...state, loading: false, feedbackList: action.feedbackList, scores: action.scores,
            };
        case FEEDBACK_FAILURE:
            return {
                ...state, loading: false, errors: [action.error, ...state.errors],
            };
        default:
            return state;
    }
};

export default feedbackReducer;
