import React, { FC, ReactElement, useState } from 'react';

import { Modal } from 'reactstrap';

import { LoadingSpinner } from '../../../@paco/components';
import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { Permission } from '../../../@paco/entities/Permission/Permission';
import { Role } from '../../../@paco/entities/Role/Role';
import { Setting } from '../../../@paco/entities/Setting/Setting';
import Card from '../../../components/Card/Card';
import TrackCardsContainer from '../../../components/TrackCardsContainer/TrackCardsContainer';
import { EditTrackFormData } from '../../../entities/Track/Track';
import { translate } from '../../../helpers/translations/translator';
import { TrackViewModel } from '../../../models';
import EditTrack from '../../../pages/Tracks/forms/EditTrack';

import './CompletePeriodTracksToReviewContainer.scss';

interface CompletePeriodTracksToReviewContainerProps {
    isLoading: boolean;
    isTimerEnableFeedback: boolean;
    currentUserRole?: Role;
    payrollPeriods: PayrollPeriod[];
    permissions: Permission[];
    settings: Setting[];
    tracksToReview: TrackViewModel[];
    onSubmitTrack: (formData: EditTrackFormData) => void;
    onAcceptTrack: (trackId: string) => void;
    className?: string;
}

const CompletePeriodTracksToReviewContainer: FC<CompletePeriodTracksToReviewContainerProps> = ({
    isLoading,
    currentUserRole,
    isTimerEnableFeedback,
    payrollPeriods,
    permissions,
    settings,
    tracksToReview,
    onSubmitTrack,
    onAcceptTrack,
    className = '',
}): ReactElement => {
    const [trackToEdit, setTrackToEdit] = useState<TrackViewModel>();

    const handleCancelEditTrack = (): void => {
        setTrackToEdit(undefined);
    };

    const handleSubmit = (formData: EditTrackFormData): void => {
        onSubmitTrack(formData);
        handleCancelEditTrack();
    };

    return (
        <div className={`complete-period-detail-tracks-to-review-container ${className}`}>
            <Card
                title={`${translate('pages.pz.tracksToReview')} (${tracksToReview.length})`}
                className="complete-period-detail-tracks-to-review-container__card"
            />

            {isLoading && (
                <LoadingSpinner isSmall className="complete-period-detail-tracks-to-review-container__loader" />
            )}

            {!isLoading && tracksToReview.length !== 0 && !!currentUserRole && (
                <TrackCardsContainer
                    tracks={tracksToReview}
                    settings={settings}
                    payrollPeriods={payrollPeriods}
                    permissions={permissions}
                    currentUserRole={currentUserRole}
                    isTimerEnableFeedback={isTimerEnableFeedback}
                    onReviewTrackClick={setTrackToEdit}
                    onApproveTrackClick={onAcceptTrack}
                    className="complete-period-detail-tracks-to-review-container__cards"
                />
            )}

            {!!trackToEdit && (
                <Modal size="lg" isOpen className="form-edit-track">
                    <EditTrack
                        canEdit
                        payrollPeriods={payrollPeriods}
                        settings={settings}
                        title={translate('pages.tracks.editTrackAndApprove')}
                        onSubmit={handleSubmit}
                        onCancel={handleCancelEditTrack}
                        track={trackToEdit}
                    />
                </Modal>
            )}
        </div>
    );
};

export default CompletePeriodTracksToReviewContainer;
