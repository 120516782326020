import { LeaveOfAbsenceHoursResource, LeaveOfAbsenceHoursViewModel, LeaveType } from '../../models';

function transformLeaveTypeResource(leaveType: string): LeaveType {
    if (leaveType === LeaveType.special) {
        return LeaveType.special;
    }

    if (leaveType === LeaveType.tvt) {
        return LeaveType.tvt;
    }

    return LeaveType.normal;
}

export default function transformLeaveOfAbsenceHoursResource(
    leaveOfAbsenceHours: LeaveOfAbsenceHoursResource,
): LeaveOfAbsenceHoursViewModel {
    return {
        hours: leaveOfAbsenceHours.hours,
        payrollPeriod: leaveOfAbsenceHours.payrollPeriod,
        weekNumber: leaveOfAbsenceHours.weekNumber,
        leaveType: transformLeaveTypeResource(leaveOfAbsenceHours.leaveType),
    };
}
