import {
    addMonths,
    compareAsc,
    max,
    min,
} from '../../../helpers/date';

export const calculateEndDate = (startDate: Date, endDate: Date): Date => {
    if (compareAsc(startDate, endDate) === 1) {
        return startDate;
    }

    const maxEndDate = addMonths(startDate, 1);
    return new Date(min([maxEndDate, endDate]));
};

export const calculateStartDate = (startDate: Date, endDate: Date): Date => {
    if (compareAsc(startDate, endDate) === 1) {
        return endDate;
    }

    const minStartDate = addMonths(endDate, -1);
    return new Date(max([minStartDate, startDate]));
};
