import { FC, useEffect, useState } from 'react';

import { useToggle } from 'react-use';

import packageJson from '../../../../../../package.json';
import { Button } from '../../../../components';
import trans from '../../../../helpers/trans';
import { NavigationRouteKey } from '../../../../routes/routes';
import { NavRouteWithCounter } from '../../Navigation';
import { MobileNavSection, MobileUserMenu } from '../index';

import './MobileNavigation.scss';

interface MobileNavigationProps {
    activeRoute: NavigationRouteKey;
    routes: NavRouteWithCounter[];
    userId?: string;
    userInitials?: string;
    onLinkClick: () => void;
    onLogoutClick: () => void;
    className?: string;
}

const MobileNavigation: FC<MobileNavigationProps> = ({
    activeRoute,
    routes,
    userId,
    userInitials,
    onLinkClick,
    onLogoutClick,
    className = '',
}) => {
    const [activeRouteState, setActiveRouteState] = useState<NavigationRouteKey | undefined>(activeRoute);
    const [showMobileUserMenu, toggleShowMobileUserMenu] = useToggle(false);

    const handleToggleMobileRouteSection = (route: NavRouteWithCounter): void => {
        setActiveRouteState(route.key === activeRouteState ? undefined : route.key);
        toggleShowMobileUserMenu(false);
    };

    useEffect(() => {
        setActiveRouteState(activeRoute);
    }, [activeRoute]);

    useEffect(() => {
        if (showMobileUserMenu) {
            setActiveRouteState(undefined);
        }
    }, [showMobileUserMenu]);

    return (
        <div className={`mobile-navigation ${className}`}>
            {routes.map(route => (
                <MobileNavSection
                    key={route.key}
                    isActive={activeRouteState === route.key}
                    route={route}
                    subRoutes={route.children}
                    onLinkClick={onLinkClick}
                    onToggle={handleToggleMobileRouteSection}
                    className="mobile-navigation__section"
                    navLinkClassName="mobile-navigation__nav-link"
                    activeNavLinkClassName="mobile-navigation__nav-link--is-active"
                />
            ))}

            {userId && (
                <MobileUserMenu
                    isActive={showMobileUserMenu}
                    userId={userId}
                    userInitials={userInitials}
                    onLinkClick={onLinkClick}
                    onToggle={toggleShowMobileUserMenu}
                    className="mobile-navigation__section"
                    navLinkClassName="mobile-navigation__nav-link"
                    activeNavLinkClassName="mobile-navigation__nav-link--is-active"
                />
            )}

            <Button
                text={trans('common.logout')}
                onClick={onLogoutClick}
                className="mobile-navigation__logout-button"
            />

            <div className="mobile-navigation__section mobile-navigation__app-version">
                {`v${packageJson.version}`}
            </div>
        </div>
    );
};

export default MobileNavigation;
