import React, { FC } from 'react';

import { LoadingSpinner } from '../../@paco/components';
import Icon from '../style/Icon/Icon';

import './Card.scss';

interface CardProps {
    isLocked?: boolean;
    isLoading?: boolean;
    title: string;
    index?: number;
    className?: string;
    children?: React.ReactNode;
}

const Card: FC<CardProps> = ({
    isLocked,
    isLoading,
    title,
    index,
    className = '',
    children,
}) => (
    <div className={`card ${className}`}>
        {(title || index) && (
            <div className="card-header card__header">
                {index && <div className="card__index">{index}</div>}
                <div className="card__title">{title}</div>
                {(isLocked && !isLoading) && (
                    <div className="card__lock">
                        <Icon kind="lock" color="primary" />
                    </div>
                )}
                {isLoading && (
                    <LoadingSpinner className="card__loader" isSmall />
                )}
            </div>
        )}
        {children && (
            <div className="card-body card__body">
                {children}
            </div>
        )}
    </div>
);

export default Card;
