import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconPlusShiftConcept: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 14 14" fill="none" className={`icon-component ${className}`}>
        {/* eslint-disable-next-line max-len */}
        <path d="M12.0556 12.6V4.9H1.94444V12.6H12.0556ZM9.88889 0H11.3333V1.4H12.0556C12.8572 1.4 13.5 2.03 13.5 2.8V12.6C13.5 13.377 12.8572 14 12.0556 14H1.94444C1.14278 14 0.5 13.37 0.5 12.6V2.8C0.5 2.023 1.14278 1.4 1.94444 1.4H2.66667V0H4.11111V1.4H9.88889V0ZM6.27778 5.95H7.72222V8.05H9.88889V9.45H7.72222V11.55H6.27778V9.45H4.11111V8.05H6.27778V5.95Z" />
    </svg>
);

export default IconPlusShiftConcept;


