import { startOfDayInHours } from '../../@paco/helpers/date';
import { addDays } from '../../helpers/date';
import UpToAndIncludingDate from '../../helpers/date/UpToAndIncludingDate';
import getEndOfWeek from './getEndOfWeek';
import getStartOfWeek from './getStartOfWeek';

export function getDatesFromWeekNumber(
    year: number,
    weekNumber: number,
): { start: Date, end: UpToAndIncludingDate } {
    const startOfFirstWeekdayOfYear = addDays(getStartOfWeek(new Date(`${year}-01-01`), startOfDayInHours), 7);
    const startOfWeek = addDays(startOfFirstWeekdayOfYear, (weekNumber - 1) * 7);
    const endOfWeek = getEndOfWeek(startOfWeek, startOfDayInHours);

    return {
        start: startOfWeek,
        end: endOfWeek,
    };
}
