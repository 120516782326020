import { FC, ReactElement, useState } from 'react';

import classNames from 'classnames';

// Import must be directly for the order of styling
import IconButton from '../../../compositions/@buttons/IconButton/IconButton';
import { AllCheckerCheckbox } from '../../../compositions/GroupedCheckboxes';
import { FormOption } from '../../../types';
import Icon from '../../Icon/Icon';
import { GroupedCheckbox } from '../../index';

import './Checkbox.scss';

export type HTMLInputProps = JSX.IntrinsicElements['input'];

interface CheckAllInGroupCheckboxProps extends HTMLInputProps {
    isExpandable?: boolean;
    options: FormOption[];
    selectedOptions: FormOption[];
    disabledOptions: FormOption[];
    groupOption: FormOption;
    className?: string;
    checkboxLabelClassName?: string;
}

const CheckAllInGroupCheckbox: FC<CheckAllInGroupCheckboxProps> = ({
    checked = false,
    disabled = false,
    isExpandable = false,
    tabIndex,
    options,
    disabledOptions,
    selectedOptions,
    groupOption,
    name,
    className = '',
    checkboxLabelClassName = '',
    ...inputProps
}): ReactElement => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ref, ...otherProps } = inputProps;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const checkboxGroupClassNames = classNames('checkbox', {
        'checkbox--is-checked': checked,
    }, className);

    const checkboxGroupBoxClassNames = classNames('checkbox__box', {
        'checkbox__box--is-disabled': disabled,
    });

    const groupedCheckboxClassnames = classNames('checkbox-group-list__item', {
        'checkbox-group-list__item--is-sub-item': true,
        'checkbox-group-list__item--is-closed': isExpandable && !isOpen,
    });

    const chevronDownClassNames = classNames('checkbox__chevron-down', {
        'checkbox__chevron-down--is-opened': isOpen,
    });

    const handleChevronClick = (): void => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <label className={checkboxGroupClassNames}>
                <AllCheckerCheckbox
                    {...otherProps}
                    id={groupOption.label}
                    name={name}
                    value={groupOption.value}
                    checked={checked}
                    disabled={disabled}
                    className="checkbox__input"
                />

                <div className={checkboxGroupBoxClassNames}>
                    <Icon name="checkbox-check" className="checkbox__icon" />
                </div>

                {isExpandable && options.length > 0 ? (
                    <IconButton
                        type="button"
                        text={groupOption.label}
                        icon="chevron-down"
                        onClick={handleChevronClick}
                        className="checkbox__chevron-wrapper"
                        iconClassName={chevronDownClassNames}
                    />
                ) : (
                    <span className="checkbox__label">
                        {groupOption.label}
                    </span>
                )}
            </label>
            {options.map(option => (
                <GroupedCheckbox
                    key={option.value}
                    name={option.value}
                    option={option}
                    checked={selectedOptions.find(checkbox => checkbox.value === option.value)?.isChecked}
                    disabled={disabledOptions.find(checkbox => checkbox.value === option.value)?.isDisabled}
                    tabIndex={tabIndex}
                    className={`${groupedCheckboxClassnames} ${checkboxLabelClassName}`}
                />
            ))}
        </>
    );
};

export default CheckAllInGroupCheckbox;
