import React, { FC } from 'react';

import { translate } from '../../../../../helpers/translations/translator';

import './AddDepartmentGroupButton.scss';


interface AddDepartmentGroupButtonProps {
    onClick: () => void;
    title?: string;
    className?: string;
}

const AddDepartmentGroupButton: FC<AddDepartmentGroupButtonProps> = ({ onClick, title, className = '' }) => (
    <button onClick={onClick} className={`add-department-group-button-legacy ${className}`} type="button">
        <div className="add-department-group-button-legacy__plus">+</div>
        <div className="add-department-group-button-legacy__text">
            {title || translate('common.addEntity', { entity: translate('common.group') })}
        </div>
    </button>
);

export default AddDepartmentGroupButton;
