import React, { FC, useEffect, useMemo } from 'react';

import classnames from 'classnames';
import Select, { ActionMeta } from 'react-select';
import {
    Col,
    FormFeedback,
    FormGroup,
    Label,
} from 'reactstrap';
import { unique } from 'underscore';

import { DepartmentViewModel } from '../../../entities/Departments/Departments';
import { generateGroupedDepartmentsSelectList, GroupedDepartmentOption } from '../../../helpers';
import getDepartmentOptionByGroupId from './helpers/getDepartmentsByGroupId';
import getFullDepartmentGroups from './helpers/getFullDepartmentGroups';
import removeDepartmentsWithGroupIdFromList from './helpers/removeDepartmentsWithGroupIdFromList';
import transformDepartmentsToSelectOptions from './helpers/transformDepartmentsToSelectOptions';
import transformSelectOptionsToDepartments from './helpers/transformSelectOptionsToDepartments';
import { DepartmentOption } from './models/DepartmentOption';
import GroupLabel, { GroupLabelData } from './subcomponents/GroupLabel/GroupLabel';
import MultiValueContainer from './subcomponents/MultiValueContainer/MultiValueContainer';

interface Props {
    currentDepartments: DepartmentViewModel[];
    allDepartments: DepartmentViewModel[];
    mainDepartment?: DepartmentViewModel | null;
    disabled?: boolean;
    title: string;
    addGroupButtonTitle?: string;
    isMulti?: boolean;
    hasGroupButtons?: boolean;
    required?: boolean;
    error?: string;
    invalid?: boolean;
    // TODO: Only return DepartmentViewModel
    onChange: (departments: DepartmentViewModel[], fullGroupIds?: string[]) => void;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
}

const SelectDepartments: FC<Props> = ({
    currentDepartments,
    allDepartments,
    mainDepartment = null,
    title,
    addGroupButtonTitle,
    disabled = false,
    isMulti = false,
    hasGroupButtons = false,
    required,
    error,
    invalid = false,
    onChange,
    onMenuOpen,
    onMenuClose,
}) => {
    const options: GroupedDepartmentOption[] | null = useMemo(
        () => generateGroupedDepartmentsSelectList(allDepartments, mainDepartment),
        [allDepartments, mainDepartment],
    );
    const values: DepartmentOption[] = useMemo(() => transformDepartmentsToSelectOptions(
        currentDepartments,
        mainDepartment,
        allDepartments,
    ), [currentDepartments, mainDepartment, allDepartments]);

    useEffect(() => {
        const groups = getFullDepartmentGroups(currentDepartments, mainDepartment, allDepartments);
        onChange(currentDepartments, groups.map(group => group.id));
    }, [mainDepartment]);

    const onGroupButtonClick = (groupId: string) => {
        const departmentOptions = getDepartmentOptionByGroupId(options || [], groupId);
        const departments = transformSelectOptionsToDepartments(departmentOptions, allDepartments);
        const groups = getFullDepartmentGroups(departments, mainDepartment, allDepartments);

        onChange(unique([...currentDepartments, ...departments], 'id'), groups.map(group => group.id));
    };

    const onSelectChange = (value: any, actionMeta: ActionMeta<DepartmentOption>) => {
        const departments = transformSelectOptionsToDepartments(value, allDepartments);
        const { isGroup, value: groupId } = actionMeta.removedValue ? actionMeta.removedValue : { isGroup: false, value: '' };
        const filteredDepartments = (isGroup && groupId) ? removeDepartmentsWithGroupIdFromList(departments, groupId) : departments;
        const groups = getFullDepartmentGroups(filteredDepartments, mainDepartment, allDepartments);

        onChange(filteredDepartments, groups.map(group => group.id));
    };

    const formatGroupLabel = (data: GroupLabelData) => (
        <GroupLabel
            data={data}
            title={addGroupButtonTitle}
            onGroupButtonClick={hasGroupButtons ? onGroupButtonClick : undefined}
        />
    );

    const formatOptionLabel = (data: DepartmentOption) => {
        const classNames = classnames('basic-multi-select__option', {
            'basic-multi-select__option--is-group': data.isGroup,
            'basic-multi-select__option--has-full-group': data.hasFullGroup,
        });

        return (
            <div className={classNames}>
                <div className="basic-multi-select__option-label">
                    {data.label}
                </div>
            </div>
        );
    };

    return (
        <Col className="form-add-edit-employment-departments">
            <FormGroup>
                <Label className={`${required ? 'required' : ''}`}>{title}</Label>
                <div className="form-add-edit-employment-departments-select">
                    <Select
                        // @ts-ignore
                        components={{ MultiValueContainer }}
                        styles={{
                            multiValue: (base, props) => ({
                                ...base,
                                display: props.data.hasFullGroup ? 'none' : base.display,
                            }),
                        }}
                        key="employment-departments-select"
                        isMulti={isMulti}
                        options={options || []}
                        value={values}
                        formatGroupLabel={formatGroupLabel}
                        formatOptionLabel={formatOptionLabel}
                        className={`basic-multi-select ${error || invalid ? 'basic-multi-select--is-invalid' : ''}`}
                        classNamePrefix="select"
                        autoComplete="off"
                        closeMenuOnSelect={false}
                        onMenuOpen={onMenuOpen && onMenuOpen}
                        onMenuClose={onMenuClose && onMenuClose}
                        disabled={disabled}
                        onChange={onSelectChange}
                    />
                    <FormFeedback>
                        { error }
                    </FormFeedback>
                </div>
            </FormGroup>
        </Col>
    );
};

export default SelectDepartments;
