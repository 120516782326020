import { FC, ReactElement } from 'react';

import classnames from 'classnames';

import { LeaveOfAbsencesOverviewBlock } from '../../../../../entities/LeaveOfAbsenceOverviewBlock/LeaveOfAbsencesOverviewBlock';

import './LeaveOfAbsencesOverviewTableBlock.scss';

interface LeaveOfAbsencesOverviewTableBlockProps {
    leaveOfAbsence: LeaveOfAbsencesOverviewBlock;
    className?: string;
}

const LeaveOfAbsencesOverviewTableBlock: FC<LeaveOfAbsencesOverviewTableBlockProps> = ({ leaveOfAbsence, className = '' }): ReactElement => {
    const leaveOfAbsenceClassName = classnames('leave-of-absences-overview-table-block', {
        'leave-of-absences-overview-table-block--starts-in-calendar': leaveOfAbsence.startsInCalendar,
        'leave-of-absences-overview-table-block--ends-in-calendar': leaveOfAbsence.endsInCalendar,
        [`leave-of-absences-overview-table-block--is-${leaveOfAbsence.status}`]: leaveOfAbsence.status,
        [`leave-of-absences-overview-table-block--is-${leaveOfAbsence.amountOfDaysInCalendar}-length`]: leaveOfAbsence.amountOfDaysInCalendar,
    }, className);

    return (
        <div key={leaveOfAbsence.id} className={leaveOfAbsenceClassName}>
            <div
                className="leave-of-absences-overview-table-block__block-wrapper"
                style={{ width: `calc(${leaveOfAbsence.amountOfDaysInCalendar} * var(--leave-of-absences-overview-table-cell-width)` }}
            >
                <div className="leave-of-absences-overview-table-block__block">
                    {leaveOfAbsence.reason}
                </div>
            </div>
        </div>
    );
};

export default LeaveOfAbsencesOverviewTableBlock;
