import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Track } from '../../../../../../@paco/entities/Track/Track';
import { formatDate } from '../../../../../../@paco/helpers/date';
import { PrivateRouteGroupRoutes } from '../../../../../../@paco/routes/routes';
import { DateFormat } from '../../../../../../@paco/types/dateFormatTypes';
import { TrackRoutes } from '../../../../../Tracks/TrackRoutes';
import UserShiftTrackTime from '../UserShiftTrackTime/UserShiftTrackTime';

import './CalendarUserTrack.scss';

interface CalendarUserTrackProps {
    isOrphan?: boolean;
    track: Track;
    className?: string;
}

const CalendarUserTrack: FC<CalendarUserTrackProps> = ({ isOrphan, track, className = '' }): ReactElement => {
    const href = `${PrivateRouteGroupRoutes.tracks()}/${TrackRoutes.finished(track.id, formatDate(track.checkInOutPeriod.start, DateFormat.apiDate))}`;

    return (
        <Link
            to={href}
            className={`calendar-user-track ${className}`}
        >
            <UserShiftTrackTime
                isOrphan={isOrphan}
                trackPeriod={track.billablePeriod}
                trackShift={track.shift}
                className="calendar-user-track__track-time"
            />
        </Link>
    );
};

export default CalendarUserTrack;
