import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import {
    addSeconds,
    areIntervalsOverlapping,
    getEndOfWorkDay,
    getStartOfWorkDay,
} from '../../helpers/date';
import { Shift, ShiftDraggable, ShiftViewModel } from '../../models';

export default function doesShiftFallOnWorkDay(
    shift: Shift
    | ShiftViewModel
    | ShiftDraggable
    | ShiftConceptViewModelOld
    | BaseScheduleShiftViewModel,
    day: Date,
): boolean {
    return areIntervalsOverlapping(
        {
            start: shift.start,
            end: addSeconds(shift.start, 1),
        },
        {
            start: getStartOfWorkDay(day),
            end: getEndOfWorkDay(day),
        },
    );
}
