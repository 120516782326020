import { AddShiftFormData } from '../../../../entities/Shift/Shift';
import {
    FormErrors,
    FormValidation,
    validateDateString,
    validateForm,
    validateNumberRange,
    validateRequiredString,
} from '../../../../services/validationService';

export type AddShiftFormErrors = FormErrors<AddShiftFormData>;
export const maxPeopleLimit = 99;

export const validateAddShiftFormData = (formData: AddShiftFormData): FormValidation<AddShiftFormErrors> => {
    const errors: AddShiftFormErrors = {
        date: validateDateString('date', formData.date),
        peopleLimit: validateNumberRange('peopleLimit', formData.peopleLimit, maxPeopleLimit),
        startTime: validateRequiredString('startTime', formData.startTime),
        endTime: validateRequiredString('endTime', formData.endTime),
        departmentId: validateRequiredString('departmentId', formData.departmentId),
    };

    return validateForm<AddShiftFormErrors>(errors);
};
