import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { ContractHours } from '../../entities/ContractHours/ContractHours';

export type ContractHoursByUser = Record<string, ContractHours[]>;

export type ContractHoursState = AsyncReduxState<{
    userContractHours: ContractHoursByUser;
}>;

const initialState: ContractHoursState = {
    ...initialAsyncReduxState,
    userContractHours: {},
};

export const ContractHoursSlice = createSlice({
    name: 'contractHoursReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ContractHoursState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ContractHoursState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setUserContractHours(state, action: PayloadAction<ContractHoursByUser>): ContractHoursState {
            return {
                ...state,
                userContractHours: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setUserContractHours,
} = ContractHoursSlice.actions;

export default ContractHoursSlice.reducer;
