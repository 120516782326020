import { compareAsc } from '../../helpers/date';
import { WeekWithHours } from '../../models';

export default function mergeNewWeekWithWeeks(
    newWeek: WeekWithHours,
    oldWeeks: WeekWithHours[],
) {
    return [
        ...oldWeeks.filter(week => week.id !== newWeek.id),
        newWeek,
    ].sort((a, b) => (compareAsc(a.start, b.start)));
}
