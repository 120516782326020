import { addSeconds, areIntervalsOverlapping } from '../../helpers/date';
import { PayrollPeriodViewModel } from '../../models';

export function getOverlappingPayrollPeriodOnDate(
    period: PayrollPeriodViewModel,
    date: Date | string,
) {
    return (
        areIntervalsOverlapping(
            {
                start: date,
                end: addSeconds(date, 1),
            },
            {
                start: period.start,
                end: period.end.date,
            },
        )
    );
}
