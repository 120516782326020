import { CommentResource as LegacyCommentResource } from '../../../models';
import { UserResource } from '../User/User';
import { transformToBasicUser } from '../User/UserTransformers';
import {
    AddCommentFormData,
    AddCommentRequest,
    Comment,
    CommentResource,
} from './Comment';

export const transformToComment = (
    commentResource: CommentResource,
    userResource: UserResource,
): Comment => ({
    id: commentResource.id,
    body: commentResource.attributes.body,
    type: commentResource.attributes.type,
    createdAt: new Date(commentResource.attributes.createdAt),
    owner: transformToBasicUser(userResource),
});

export const transformLegacyCommentToComment = (
    legacyCommentResource: LegacyCommentResource,
    userResource?: UserResource,
): Comment => ({
    id: legacyCommentResource.id,
    type: legacyCommentResource.type,
    body: legacyCommentResource.body,
    createdAt: new Date(legacyCommentResource.createdAt),
    owner: userResource ? transformToBasicUser(userResource) : undefined,
});

export const transformCommentFormDataToRequest = (
    formData: AddCommentFormData,
): AddCommentRequest => ({
    type: 'comments',
    attributes: {
        body: formData.comment,
    },
    relationships: {
        [formData.relationshipKey]: {
            data: {
                type: formData.relationshipType,
                id: formData.relationshipId,
            },
        },
    },
});
