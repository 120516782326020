import { TemporaryWorker } from '../@paco/entities/TemporaryWorker/TemporaryWorker';
import { BasicDepartment, DepartmentResource, DepartmentViewModel } from '../entities/Departments/Departments';
import UpToAndIncludingDate from '../helpers/date/UpToAndIncludingDate';
import { Log } from './Log';
import { Resource } from './Resource';
import { ShiftPlanning, ShiftPlanningResource, ShiftPlanningViewModel } from './ShiftPlanning';
import { TemporaryWorker as TemporaryWorkerLegacy, TemporaryWorkerResource } from './TemporaryWorker';
import { BasicUser, UserResource, UserViewModel } from './User';
import { BasicUserWithAbsenceData } from './UserWithAbsenceData';

export enum ShiftType {
    shift = 'shifts',
    concept = 'shiftConcepts',
    baseSchedule = 'baseScheduleShifts',
}

export enum ShiftApiType {
    shift = 'shifts',
    concept = 'shiftConcepts',
    baseSchedule = 'shiftBaseSchedules',
}

export enum ShiftStatusType {
    full = 'full',
    available = 'available',
    past = 'past',
}

export interface Shift extends Resource {
    type: ShiftType;
    peopleLimit: number;
    start: string;
    end: string;
    createdAt: string;
    updatedAt: string;
    logs?: Log[];
    department: BasicDepartment;
    shiftPlannings: ShiftPlanning[];
    temporaryWorkers: TemporaryWorkerLegacy[];
    previouslyPlannedUsers: BasicUser[] | BasicUserWithAbsenceData[];
    plannedUsers: BasicUser[] | BasicUserWithAbsenceData[];
    registeredUsers: BasicUser[] | BasicUserWithAbsenceData[];
    clone?: boolean;
}

export interface ShiftResource extends Resource {
    type: ShiftType;
    peopleLimit: number;
    start: string;
    end: string;
    department?: DepartmentResource;
    shiftPlannings?: ShiftPlanningResource[];
    plannedUsers?: UserResource[];
    temporaryWorkers?: TemporaryWorkerResource[];
    previouslyPlannedUsers?: UserResource[];
}

export interface ShiftViewModel {
    type: ShiftType;
    id: string;
    peopleLimit: number;
    status: ShiftStatusType;
    start: Date;
    end: UpToAndIncludingDate;
    department?: DepartmentViewModel;
    shiftPlannings: ShiftPlanningViewModel[];
    plannedUsers: UserViewModel[];
    temporaryWorkers: TemporaryWorker[];
    previouslyPlannedUsers: UserViewModel[];
}

export interface AddShiftFormData {
    department: string,
    date: Date,
    startTime: string,
    endTime: string,
    peopleLimit: number,
}

export type EditShiftFormData = AddShiftFormData;

export interface LegacyAddShiftFormData {
    department: string;
    user: string;
    endDate: string;
    endTime: string;
    peopleLimit: number;
    startDate: string;
    startTime: string;
}

export interface LegacyEditShiftFormData extends Partial<LegacyAddShiftFormData> {
    endDate: string;
    endTime: string;
    peopleLimit: number;
    startDate: string;
    startTime: string;
}

export function transformShift(params: Shift): Shift {
    return {
        type: ShiftType.shift,
        id: params.id,
        peopleLimit: params.peopleLimit || 0,
        start: params.start,
        end: params.end,
        createdAt: params.createdAt,
        updatedAt: params.updatedAt,
        logs: params.logs || [],
        department: params.department,
        shiftPlannings: params.shiftPlannings || [],
        temporaryWorkers: params.temporaryWorkers || [],
        previouslyPlannedUsers: params.previouslyPlannedUsers || [],
        plannedUsers: params.plannedUsers || [],
        registeredUsers: params.registeredUsers || [],
    };
}

export function checkIntegrityOfShift(shift: Shift): boolean {
    if (!shift) {
        return false;
    }

    return !!(shift.type
        && shift.id
        && (shift.peopleLimit !== null || shift.peopleLimit !== undefined)
        && shift.start
        && shift.end
        && shift.createdAt
        && shift.updatedAt
        && shift.department
        && shift.shiftPlannings
        && shift.temporaryWorkers
        && shift.previouslyPlannedUsers
    );
}
