import {
    put,
    takeLatest,
} from 'redux-saga/effects';

import {
    APP_ROUTE_CHANGE,
    PAGINATION_SAGA_SET_NUMBER,
    PAGINATION_SAGA_SET_PAGES,
    PAGINATION_SET_NUMBER,
    PAGINATION_SET_PAGES,
    PAGINATION_SET_PAGINATION,
} from '../actionTypes';

export function* setPaginationNumber({ number }) {
    yield put({ type: PAGINATION_SET_NUMBER, number });
}

export function* setPaginationSize({ size }) {
    yield put({ type: PAGINATION_SET_PAGES, size });
}

export function* resetPagination() {
    yield put({
        type: PAGINATION_SET_PAGINATION, number: 1, size: null, pages: 1,
    });
}

export default function* paginationWatcher() {
    yield takeLatest(PAGINATION_SAGA_SET_NUMBER, setPaginationNumber);
    yield takeLatest(PAGINATION_SAGA_SET_PAGES, setPaginationSize);
    yield takeLatest(APP_ROUTE_CHANGE, resetPagination);
}
