import {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { NumberInput } from '../../../../@paco/compositions';
import { Setting } from '../../../../@paco/entities/Setting/Setting';
import { translate } from '../../../../helpers/translations/translator';

import './SettingsShiftTime.scss';

interface SettingsShiftTimeProps {
    hasEnd?: boolean;
    settings: Setting[];
    onChange: (value: Setting[]) => void;
    className?: string;
}

const SettingsShiftTime: FC<SettingsShiftTimeProps> = ({
    hasEnd,
    settings,
    onChange,
    className = '',
}): ReactElement => {
    // Warning: this will make the component effectively uncontrolled. Have to do this because of the way the parent is setup.
    const [settingsState, setSettingsState] = useState<Setting[]>(settings);

    const startText = hasEnd ? translate('pages.settings.afterEndShift') : translate('pages.settings.afterStartShift');
    const endText = hasEnd ? translate('pages.settings.beforeEndShift') : translate('pages.settings.beforeStartShift');

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
    };

    const handleNumberInputChange = (value: number, index: number): void => {
        const newSettings = [...settingsState];
        newSettings[index].value = Math.max(0, value).toString();

        setSettingsState(newSettings);
        onChange(newSettings);
    };

    return (
        <form className={`settings-shift-time ${className}`} onSubmit={handleFormSubmit}>
            {settingsState.map((setting, index) => (
                <NumberInput
                    key={setting.id}
                    label={index === 0 ? endText : startText}
                    min={0}
                    max={1000}
                    value={setting.value}
                    onChange={(newValue) => handleNumberInputChange(newValue, index)}
                    className="settings-shift-time__input"
                />
            ))}
        </form>
    );
};

export default SettingsShiftTime;
