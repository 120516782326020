import { FC, ReactElement, useEffect } from 'react';

import EditDistrictDivisionTemplateForm from '../../../containers/@forms/EditDistrictDivisionTemplateForm/EditDistrictDivisionTemplateForm';
import { DistrictDivisionTemplate, EditDistrictDivisionTemplateFormData } from '../../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import trans from '../../../helpers/trans';
import { editDistrictDivisionTemplate } from '../../../redux/districtDivisionTemplate/districtDivisionTemplateActions';
import { setIsSuccessful } from '../../../redux/districtDivisionTemplate/districtDivisionTemplateReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedEditDistrictDivisionTemplateFormProps {
    districtDivisionTemplate: DistrictDivisionTemplate;
    onClose: () => void;
}
const ConnectedEditDistrictDivisionTemplateForm: FC<ConnectedEditDistrictDivisionTemplateFormProps> = ({
    districtDivisionTemplate,
    onClose,
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { isLoading, isSuccessful } = useTypedSelector(state => state.districtDivisionTemplateReducer);

    const handleEditDistrictDivisionTemplate = (editDistrictDivisionTemplateFormData: EditDistrictDivisionTemplateFormData): void => {
        dispatch(editDistrictDivisionTemplate(editDistrictDivisionTemplateFormData));
    };

    useEffect(() => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.editDistrictDivisionTemplateForm.editDistrictDivisionTemplateSucces'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isSuccessful]);

    return (
        <EditDistrictDivisionTemplateForm
            isLoading={isLoading}
            districtDivisionTemplate={districtDivisionTemplate}
            onSubmit={handleEditDistrictDivisionTemplate}
            onCancel={onClose}
        />
    );
};

export default ConnectedEditDistrictDivisionTemplateForm;
