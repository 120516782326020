import { ShiftPlanningWithShift } from '../../../../../@paco/entities/ShiftPlanning/ShiftPlanning';
import { getOverlappingUnavailableSlotOnWeekday } from '../../../../../helpers';
import {
    addHours,
    addSeconds,
    areIntervalsOverlapping,
    compareAsc,
    endOfDay,
    formatDate,
    isEqual,
    isSameDay,
    startOfDay,
} from '../../../../../helpers/date';
import { translate } from '../../../../../helpers/translations/translator';
import {
    Absence,
    LeaveOfAbsence,
    PreferToWorkTimeSlot,
    UnavailableToWorkTimeSlot,
} from '../../../../../models';


export const getOverlappingResourceOnDate = (
    // eslint-disable-next-line max-len
    resource: LeaveOfAbsence | Absence | PreferToWorkTimeSlot | UnavailableToWorkTimeSlot | ShiftPlanningWithShift,
    day: Date,
) => {
    const start = 'period' in resource ? resource.period.start : resource.start;
    const end = 'period' in resource ? resource.period.start : resource.end;
    const justifiedEndDate = end ? addHours(end, -4) : start;

    return (
        areIntervalsOverlapping(
            {
                start: startOfDay(day),
                end: endOfDay(day),
            },
            {
                start,
                end: compareAsc(start, justifiedEndDate) === 1 ? addSeconds(start, 1) : (justifiedEndDate),
            },
        )
    );
};

const compareResourceFirstNames = (
    a: LeaveOfAbsence | Absence | PreferToWorkTimeSlot | UnavailableToWorkTimeSlot | ShiftPlanningWithShift,
    b: LeaveOfAbsence | Absence | PreferToWorkTimeSlot | UnavailableToWorkTimeSlot | ShiftPlanningWithShift,
) => {
    const userAFirstName = 'fullName' in a.user ? a.user.fullName : a.user.firstname;
    const userBFirstName = 'fullName' in b.user ? b.user.fullName : b.user.firstname;

    return userAFirstName.localeCompare(userBFirstName);
};

export const getOverlappingResourcesOnDate = (
    leaveOfAbsences: LeaveOfAbsence[],
    absences: Absence[],
    preferToWorkTimeSlots: PreferToWorkTimeSlot[],
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[],
    day: Date,
    shiftPlannings?: ShiftPlanningWithShift[],
): (
    LeaveOfAbsence | Absence | PreferToWorkTimeSlot | UnavailableToWorkTimeSlot | ShiftPlanningWithShift
    )[] => {
    const overlappingLeaveOfAbsences = leaveOfAbsences
        .filter(leaveOfAbsence => getOverlappingResourceOnDate(leaveOfAbsence, day));
    const overlappingAbsences = absences
        .filter(absence => getOverlappingResourceOnDate(absence, day));
    const overlappingUnavailableToWorkTimeSlots = unavailableToWorkTimeSlots
        .filter(unavailableSlot => getOverlappingResourceOnDate(unavailableSlot, day))
        .filter(unavailableSlot => getOverlappingUnavailableSlotOnWeekday(unavailableSlot, day));
    const overlappingAvailablePreferences = preferToWorkTimeSlots
        .filter(preferToWorkTimeSlot => getOverlappingResourceOnDate(preferToWorkTimeSlot, day));
    const overlappingShiftPlannings = (shiftPlannings || [])
        .filter(shiftPlanning => getOverlappingResourceOnDate(shiftPlanning, day));

    return [
        ...overlappingLeaveOfAbsences,
        ...overlappingAbsences,
        ...overlappingAvailablePreferences,
        ...overlappingUnavailableToWorkTimeSlots,
        ...overlappingShiftPlannings,
    ].sort((a, b) => compareResourceFirstNames(a, b));
};

export const getResourceStyleClass = (
    resource: LeaveOfAbsence | Absence | PreferToWorkTimeSlot | UnavailableToWorkTimeSlot
    | ShiftPlanningWithShift,
) => {
    if (resource.type === 'leaveOfAbsences' && 'status' in resource) {
        return `leave-of-absence-${resource.status}`;
    }

    if (resource.type === 'absences') {
        return 'absence';
    }

    if (resource.type === 'PreferToWorkTimeSlots') {
        return 'planning-preference-available';
    }

    if (resource.type === 'UnavailableToWorkTimeSlots') {
        return 'planning-preference-unavailable';
    }

    if (resource.type === 'shiftPlannings' && resource.planned) {
        return 'shift-planning';
    }

    if (resource.type === 'shiftPlannings' && !resource.planned) {
        return 'registered-shift-planning';
    }

    return '';
};

export const getResourceStartAndEndTime = (
    resource: LeaveOfAbsence | Absence | PreferToWorkTimeSlot | UnavailableToWorkTimeSlot
    | ShiftPlanningWithShift,
    day: Date,
) => {
    const { type } = resource;
    const format = 'HH:mm';
    const start = 'period' in resource ? resource.period.start : resource.start;
    const end = 'period' in resource ? resource.period.end : resource.end;

    if (type === 'absences' && end === null) {
        const startTime = formatDate(start, format);
        return `${translate('pages.absences.from')} ${startTime}`;
    }

    if (type === 'leaveOfAbsences' || type === 'absences' || (type === 'PreferToWorkTimeSlots') || type === 'shiftPlannings') {
        const startIsSameDay = isSameDay(start, day);
        const justifiedEnd = end ? addHours(end, -4) : undefined;
        const endIsSameDay = justifiedEnd && isSameDay(justifiedEnd, day);

        if (type === 'PreferToWorkTimeSlots' && (
            (!startIsSameDay && !endIsSameDay)
            || isEqual(addHours(day, 28), end || new Date())
        )) {
            return translate('common.wholeDay');
        }

        const startTime = startIsSameDay ? formatDate(start, format) : '00:00';
        const endTime = endIsSameDay ? formatDate(end || '', format) : '23:59';
        return `${startTime} - ${endTime}`;
    }

    const startTime = formatDate(start, format);
    const endTime = formatDate(end || '', format);

    return `${startTime} - ${endTime}`;
};

const getResourceDepartmentName = (shiftPlanning: ShiftPlanningWithShift) => {
    const departmentName = shiftPlanning.shift.department.name;

    if (!shiftPlanning.shift.department.departmentGroup) {
        return departmentName;
    }

    return `${shiftPlanning.shift.department.departmentGroup.name} - ${departmentName}`;
};

export const getResourceDescription = (
    resource: LeaveOfAbsence | Absence | PreferToWorkTimeSlot | UnavailableToWorkTimeSlot
    | ShiftPlanningWithShift,
): string => {
    const leaveOfAbsence = resource as LeaveOfAbsence;
    const shiftPlanning = resource as ShiftPlanningWithShift;

    if (resource.type === 'leaveOfAbsences' && leaveOfAbsence.status === 'approved') {
        return translate('pages.management.approvedLeaveOfAbsence');
    }

    if (resource.type === 'leaveOfAbsences' && leaveOfAbsence.status === 'open') {
        return translate('pages.management.pendingLeaveOfAbsence');
    }

    if (resource.type === 'leaveOfAbsences' && leaveOfAbsence.status === 'denied') {
        return translate('pages.management.deniedLeaveOfAbsence');
    }

    if (resource.type === 'absences') {
        return translate('pages.management.sickness');
    }

    if (resource.type === 'shiftPlannings' && shiftPlanning.planned) {
        return getResourceDepartmentName(shiftPlanning);
    }

    if (resource.type === 'shiftPlannings' && !shiftPlanning.planned) {
        return `${translate('pages.shifts.registeredTo')} ${getResourceDepartmentName(shiftPlanning)}`;
    }

    if (resource.type === 'PreferToWorkTimeSlots') {
        return translate('pages.shifts.available');
    }

    if (resource.type === 'UnavailableToWorkTimeSlots') {
        return translate('pages.shifts.notAvailable');
    }

    return '';
};
