import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getRolesApiCall } from '../../entities/Role/RoleService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setRoles } from './rolesReducer';

export const fetchRoles = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getRolesApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setRoles(response.data));
    } catch (error) {
        console.error('[fetchRoles]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
