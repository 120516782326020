import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { PzLogs } from '../../containers';
import { getCheckedFormOptionsValue } from '../../helpers/types/formOption';
import { fetchPzLogs, fetchPzLogsPagination } from '../../redux/pzLogs/pzLogsActions';
import { setPage } from '../../redux/pzLogs/pzLogsReducer';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

const ConnectedPzLogs: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const { categories, categoryOptions } = useTypedSelector(state => state.pzLogCategoriesReducer);
    const {
        isLoading,
        isPaginationLoading,
        logs,
        page,
        search,
        totalPages,
    } = useTypedSelector(state => state.pzLogsReducer);

    const selectedCategoryIds = useMemo(() => getCheckedFormOptionsValue(categoryOptions), [categoryOptions]);

    const handlePaginationChange = (value: number): void => {
        dispatch(setPage(value));
    };

    useEffect((): void => {
        dispatch(fetchPzLogs(selectedCategoryIds, search, page));
    }, [selectedCategoryIds.join(','), page, search]);

    useEffect((): void => {
        dispatch(fetchPzLogsPagination(selectedCategoryIds, search, page));
    }, [selectedCategoryIds.join(','), search]);


    return (
        <PzLogs
            isLoading={isLoading}
            isPaginationLoading={isPaginationLoading}
            categories={categories}
            logs={logs}
            page={page}
            totalPages={totalPages}
            onPaginationChange={handlePaginationChange}
        />
    );
};

export default ConnectedPzLogs;
