import { PatchAbsenceHourData } from '../../helpers/api-ts/absenceHour';
import { AbsenceHours, WeekWithHoursAndWaitingDayHours } from '../../models';

function transformAbsenceHourToPatchAbsenceHourData(
    id: string,
    absenceId: string,
    payrollPeriodId: string,
    hours: number | null,
    weekNumber: number,
    waitingDayHours: number,
): PatchAbsenceHourData {
    return {
        id,
        absenceId,
        payrollPeriodId,
        hours,
        weekNumber,
        waitingDayHours,
    };
}

export default function transformAbsenceHoursToPatchAbsenceHourData(
    absenceId: string,
    absenceHours: AbsenceHours[],
    weeksWithHours: WeekWithHoursAndWaitingDayHours[],
): PatchAbsenceHourData[] {
    return absenceHours.map((absenceHour) => {
        const week = weeksWithHours
            .find(weekday => absenceHour.weekNumber === weekday.weekNumber
                && absenceHour.payrollPeriod.year === (weekday.payrollPeriod && weekday.payrollPeriod.year)) as WeekWithHoursAndWaitingDayHours;

        return transformAbsenceHourToPatchAbsenceHourData(
            absenceHour.id,
            absenceId,
            week.payrollPeriod ? week.payrollPeriod.id : '',
            week.hours,
            week.weekNumber,
            week.waitingDayHours,
        );
    });
}
