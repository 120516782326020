import * as Sentry from '@sentry/browser';
import axios, { AxiosResponse } from 'axios';

// This download method works on all devices (also safari mobile)

export default async function authorizedGetFile(fileUrl: string, fileName: string) {
    try {
        const response: AxiosResponse<BlobPart> = await axios({
            url: fileUrl,
            method: 'GET',
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const element = document.createElement('a');
        element.href = url;
        element.setAttribute('download', fileName);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    } catch (error) {
        Sentry.captureException(error);
    }
}
