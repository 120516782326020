import React from 'react';

import Tooltip from 'react-tooltip-lite';

import {
    Absence,
    AbsenceViewModel,
    LeaveOfAbsence,
    LeaveOfAbsenceViewModel,
} from '../../models';
import { generateTooltipText, getUserStatusBadge } from './helpers';


interface AbsenceBadgeProps {
    modifier?: string,
    className?: string;
    absence?: Absence | AbsenceViewModel,
    leaveOfAbsenceRequest?: LeaveOfAbsence | LeaveOfAbsenceViewModel,
    leaveOfAbsence?: LeaveOfAbsence | LeaveOfAbsenceViewModel,
}

const AbsenceBadge = ({
    absence,
    leaveOfAbsenceRequest,
    leaveOfAbsence,
    modifier = '',
    className = '',
}: AbsenceBadgeProps) => {
    const label = getUserStatusBadge(absence, leaveOfAbsenceRequest, leaveOfAbsence, modifier);
    const tooltip = generateTooltipText(absence, leaveOfAbsenceRequest, leaveOfAbsence);
    const absenceId = (absence || leaveOfAbsenceRequest || leaveOfAbsence || {}).id;
    const id = `status-badge-${absenceId}`;

    return (
        // @ts-ignore
        <Tooltip content={tooltip || ''} arrowSize={6} padding={7}>
            <span
                id={id}
                className={`d-flex ${className}`}
            >
                {label}
            </span>
        </Tooltip>
    );
};

export default AbsenceBadge;
