import { areOptionalDatesEqual } from '../../helpers/date';
import { getAddressFormDataDivergence } from '../Address/AddressHelpers';
import { ContractHours, ContractHoursFormData } from '../ContractHours/ContractHours';
import { getEmergencyContactFormDataDivergence } from '../EmergencyContact/EmergencyContactHelpers';
import { Employment } from '../Employment/Employment';
import { isSamePeriod } from '../Period/PeriodHelpers';
import {
    EditUserFormData,
    EmploymentDatesFormData,
    FullUser,
    UserResource,
} from './User';

// In Track and Shift calls when including a comment, owner is sometimes undefined even though it should exist.

export const getDummyUserResource = (id: string): UserResource => ({
    type: 'users',
    id,
    attributes: {
        firstname: 'Onbekend',
        lastname: '',
        phone: '123456789',
        email: 'unknown@mail.com',
        status: 'active',
        shouldTrackTime: false,
        isPaidBasedOnTrackedTime: false,
        isEligibleForTimeForTime: false,
        registrationComplete: false,
        isLoketAuthorized: false,
        loketEmployeeId: '',
    },
});

export const getEmploymentDatesFormDataDivergence = (formData: EmploymentDatesFormData, employment: Employment): boolean => (
    !areOptionalDatesEqual(formData.startDate, employment.start)
        || !areOptionalDatesEqual(formData.endDate, employment.end)
        || formData.divergentPreEmploymentHours !== employment.divergentPreEmploymentHour?.contractHours.toString()
        || formData.divergentPostEmploymentHours !== employment.divergentPostEmploymentHour?.contractHours.toString()
);

export const getContractHoursFormDataDivergence = (formData: ContractHoursFormData[], contractHours: ContractHours[]): boolean => {
    if (formData.some((contractHour) => contractHour.isDeleted || !contractHour.resourceId)) {
        return true;
    }

    if (formData.length !== contractHours.length) {
        return true;
    }

    return contractHours.some(contractHour => {
        const matchingContractHour = formData
            .find((formDataContractHour) => formDataContractHour.resourceId === contractHour.id);

        if (!matchingContractHour) {
            return true;
        }

        if (!isSamePeriod(matchingContractHour.period, contractHour.period)) {
            return true;
        }

        return matchingContractHour.hours !== contractHour.hours;
    });
};

export const getJustifiedEditUserFormData = (editUserFormData: EditUserFormData, fullUser: FullUser): EditUserFormData => {
    const formData: EditUserFormData = { ...editUserFormData };

    if (formData.employmentDates && fullUser.employment && !getEmploymentDatesFormDataDivergence(formData.employmentDates, fullUser.employment)) {
        delete formData.employmentDates;
    }

    if (formData.contractHours && fullUser.contractHours && !getContractHoursFormDataDivergence(formData.contractHours, fullUser.contractHours)) {
        delete formData.contractHours;
    }

    if (formData.address && fullUser.address && !getAddressFormDataDivergence(formData.address, fullUser.address)) {
        delete formData.address;
    }

    if (formData.emergencyContact && fullUser.emergencyContact && !getEmergencyContactFormDataDivergence(formData.emergencyContact, fullUser.emergencyContact)) {
        delete formData.emergencyContact;
    }

    return formData;
};
