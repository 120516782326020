import { getPersonFullName } from '../../helpers';
import { authorizedFetch } from '../../helpers/authorizedFetch';
import { compareDesc, formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../../japi/types/Document';
import { DateFormat } from '../../types/dateFormatTypes';
import { CommentResource } from '../Comment/Comment';
import { transformToComment } from '../Comment/CommentTransformers';
import { DepartmentResource } from '../Department/Department';
import { transformToDepartment } from '../Department/DepartmentTransformers';
import { DistrictDivisionDistrictResource } from '../DistrictDivisionDistrict/DistrictDivisionDistrict';
import { transformDistrictDivisionDistrict } from '../DistrictDivisionDistrict/DistrictDivisionDistrictTransformers';
import { DistrictDivisionDistrictPlanningResource } from '../DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { transformToDistrictDivisionDistrictPlanning } from '../DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanningTransformers';
import { DistrictDivisionDistrictTemporaryWorkerResource } from '../DistrictDivisionDistrictTemporaryWorker/DistrictDivisionDistrictTemporaryWorker';
import { transformDistrictDivisionDistrictTemporaryWorkerToDistrictDivisionDistrictPlanning } from '../DistrictDivisionDistrictTemporaryWorker/DistrictDivisionDistrictTemporaryWorkerTransformers';
import { DistrictDivisionGroupResource } from '../DistrictDivisionGroup/DistrictDivisionGroup';
import { transformDistrictDivisionGroup } from '../DistrictDivisionGroup/DistrictDivisionGroupTransformers';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { UserResource } from '../User/User';
import { transformToBasicUser } from '../User/UserTransformers';
import { DistrictDivision, DistrictDivisionResource } from './DistrictDivision';
import { transformToDistrictDivision } from './DistrictDivisionTransformers';

let getDistrictDivisionApiCallAbortController = new AbortController();

export const getDistrictDivisionsApiCall = async (date: Date): Promise<FetchCollectionResult<DistrictDivision[], string>> => {
    getDistrictDivisionApiCallAbortController.abort();
    getDistrictDivisionApiCallAbortController = new AbortController();

    try {
        const includes = [
            'comments',
            'comments.owner',
            'districtDivisionGroups',
            'districtDivisionGroups.districtDivisionDistricts',
            'districtDivisionGroups.districtDivisionDistricts.districtDivisionDistrictTemporaryWorkers',
            'districtDivisionGroups.districtDivisionDistricts.districtDivisionDistrictTemporaryWorkers.comments',
            'districtDivisionGroups.districtDivisionDistricts.districtDivisionDistrictTemporaryWorkers.comments.owner',
            'districtDivisionGroups.districtDivisionDistricts.districtDivisionDistrictTemporaryWorkers.department',
            'districtDivisionGroups.districtDivisionDistricts.districtDivisionDistrictPlannings',
            'districtDivisionGroups.districtDivisionDistricts.districtDivisionDistrictPlannings.department',
            'districtDivisionGroups.districtDivisionDistricts.districtDivisionDistrictPlannings.user',
            'districtDivisionGroups.districtDivisionDistricts.districtDivisionDistrictPlannings.comments',
            'districtDivisionGroups.districtDivisionDistricts.districtDivisionDistrictPlannings.comments.owner',
        ];

        const url = generateApiUrl({
            endpoint: `/district-divisions/${formatDate(date, DateFormat.apiDate)}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, { method: 'GET', signal: getDistrictDivisionApiCallAbortController.signal });

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<DistrictDivisionResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const districtDivisions = doc.data.map(districtDivisionResource => {
            const districtDivisionGroupsResource = getMultipleIncluded(doc, districtDivisionResource, 'districtDivisionGroups') as DistrictDivisionGroupResource[];
            const districtDivisionCommentsResource = getMultipleIncluded(doc, districtDivisionResource, 'comments') as CommentResource[];

            const districtDivisionComments = (districtDivisionCommentsResource || []).map(commentResource => {
                const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

                return transformToComment(commentResource, commentOwnerResource);
            }).sort((a, b) => compareDesc(a.createdAt, b.createdAt));

            const districtDivisionGroups = districtDivisionGroupsResource.map(districtDivisionGroupResource => {
                const districtDivisionDistrictsResource = getMultipleIncluded(doc, districtDivisionGroupResource, 'districtDivisionDistricts') as DistrictDivisionDistrictResource[];
                const districtDivisionDistricts = districtDivisionDistrictsResource.map(districtDivisionDistrictResource => {
                    // eslint-disable-next-line max-len
                    const districtDivisionDistrictPlanningsResource = getMultipleIncluded(doc, districtDivisionDistrictResource, 'districtDivisionDistrictPlannings') as DistrictDivisionDistrictPlanningResource[];
                    const plannings = districtDivisionDistrictPlanningsResource
                        .map(districtDivisionDistrictPlanningResource => {
                            const userResource = getIncluded(doc, districtDivisionDistrictPlanningResource, 'user') as UserResource;
                            const departmentResource = getIncluded(doc, districtDivisionDistrictPlanningResource, 'department') as DepartmentResource;
                            const planningCommentsResource = (getMultipleIncluded(doc, districtDivisionDistrictPlanningResource, 'comments') || []) as CommentResource[];

                            const user = transformToBasicUser(userResource);
                            const department = transformToDepartment(departmentResource);

                            const planningComments = planningCommentsResource.map(commentResource => {
                                const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

                                return transformToComment(
                                    commentResource,
                                    commentOwnerResource,
                                );
                            });

                            return transformToDistrictDivisionDistrictPlanning(
                                districtDivisionDistrictPlanningResource,
                                department,
                                getPersonFullName(user.person),
                                user.id,
                                districtDivisionDistrictResource.id,
                                planningComments,
                            );
                        })
                        .sort((a, b) => (a.planningOrder - b.planningOrder));

                    // eslint-disable-next-line max-len
                    const districtDivisionDistrictTemporaryWorkersResource = getMultipleIncluded(doc, districtDivisionDistrictResource, 'districtDivisionDistrictTemporaryWorkers') as DistrictDivisionDistrictTemporaryWorkerResource[];
                    const temporaryWorkers = districtDivisionDistrictTemporaryWorkersResource
                        .map(districtDivisionDistrictTemporaryWorkerResource => {
                            const departmentResource = getIncluded(doc, districtDivisionDistrictTemporaryWorkerResource, 'department') as DepartmentResource;
                            const temporaryWorkersCommentsResource = (getMultipleIncluded(doc, districtDivisionDistrictTemporaryWorkerResource, 'comments') || []) as CommentResource[];
                            const department = transformToDepartment(departmentResource);

                            const temporaryWorkersComments = temporaryWorkersCommentsResource.map(commentResource => {
                                const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

                                return transformToComment(
                                    commentResource,
                                    commentOwnerResource,
                                );
                            });

                            return transformDistrictDivisionDistrictTemporaryWorkerToDistrictDivisionDistrictPlanning(
                                districtDivisionDistrictTemporaryWorkerResource,
                                department,
                                districtDivisionDistrictTemporaryWorkerResource.attributes.name,
                                districtDivisionDistrictTemporaryWorkerResource.id,
                                districtDivisionDistrictResource.id,
                                temporaryWorkersComments,
                            );
                        })
                        .sort((a, b) => (a.planningOrder - b.planningOrder));

                    const districtDivisionDistrictPlannings = [...plannings, ...temporaryWorkers];

                    return transformDistrictDivisionDistrict(districtDivisionDistrictResource, districtDivisionDistrictPlannings);
                });

                return transformDistrictDivisionGroup(districtDivisionGroupResource, districtDivisionDistricts);
            }).sort((a, b) => a.order - b.order);

            return transformToDistrictDivision(
                districtDivisionResource,
                districtDivisionGroups,
                districtDivisionComments,
            );
        });

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: districtDivisions,
        };
    } catch (error) {
        console.error('[getDistrictDivisionsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteDistrictDivisionApiCall = async (districtDivisionId: string): Promise<FetchResult<undefined, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: `/district-divisions/${districtDivisionId}`,
        });

        const response = await authorizedFetch(url, { method: 'DELETE' });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteDistrictDivisionApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
