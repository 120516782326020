import React, { FC } from 'react';

import { LoadingSpinner } from '../../../../../../@paco/components';
import { TimeModeType } from '../../../../../../@paco/types';
import { areDatesInSameMonth, formatDate } from '../../../../../../helpers/date';
import Day from './Day';
import { DayBasis } from './models';

export interface DaysProps extends DayBasis {
    selectedDays: Date[];
    loading: boolean;
}

const Days: FC<DaysProps> = ({
    absences,
    leaveOfAbsences,
    shifts,
    tracks,
    unavailableToWorkTimeSlots,
    mode,
    selectedDays,
    showHeader,
    loading,
    userId,
    onItemClick,
}) => {
    const onAbsenceMouseEnter = (id: string) => {
        const elements = document.getElementsByClassName(`calendar-absence-${id}`);
        Array.from(elements).forEach(element => {
            element.classList.add('calendar-absence--active');
        });
    };

    const onAbsenceMouseLeave = (id: string) => {
        const elements = document.getElementsByClassName(`calendar-absence-${id}`);
        Array.from(elements).forEach(element => {
            element.classList.remove('calendar-absence--active');
        });
    };

    return (
        <>
            {selectedDays.map((day, index) => {
                const key = formatDate(day, 'yyyy-MM-dd');
                const outOfRange = mode === TimeModeType.month && !areDatesInSameMonth(day, selectedDays[selectedDays.length - 1]);

                return (
                    <Day
                        showDate
                        key={key}
                        userId={userId}
                        absences={absences}
                        leaveOfAbsences={leaveOfAbsences}
                        shifts={shifts}
                        tracks={tracks}
                        unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                        index={index}
                        showHeader={index < 7 && showHeader}
                        day={day}
                        mode={mode}
                        outOfRange={outOfRange}
                        last={selectedDays.length - 1 === index}
                        onItemClick={onItemClick}
                        onAbsenceMouseEnter={onAbsenceMouseEnter}
                        onAbsenceMouseLeave={onAbsenceMouseLeave}
                    />
                );
            })}
            {loading && <LoadingSpinner className="user-calendar-days__loading-spinner" />}
        </>
    );
};

export default Days;
