import { EmploymentDatesFormData } from '../User/User';
import {
    DivergentPostEmploymentHour,
    DivergentPostEmploymentHourFormData,
    DivergentPostEmploymentHourPatchRequest,
    DivergentPostEmploymentHourPostRequest,
    DivergentPostEmploymentHourResource,
    DivergentPostEmploymentHourV3Resource,
} from './DivergentPostEmploymentHour';

export const transformEmploymentDatesFormDataToDivergentPostEmploymentHourFormData = (formData: EmploymentDatesFormData): DivergentPostEmploymentHourFormData => ({
    contractHours: parseFloat(formData.divergentPostEmploymentHours || '0'),
});

export const transformToDivergentPostEmploymentHour = (resource: DivergentPostEmploymentHourResource): DivergentPostEmploymentHour => ({
    id: resource.id,
    checked: resource.attributes.checked,
    contractHours: resource.attributes.contractHours || 0,
});

export const transformV3ResourceToDivergentPostEmploymentHour = (resource: DivergentPostEmploymentHourV3Resource): DivergentPostEmploymentHour => ({
    id: resource.id,
    checked: resource.checked,
    contractHours: resource.contractHours || 0,
});

export const transformDivergentPostEmploymentHourFormDataToPostRequest = (
    employmentId: string,
    formData: DivergentPostEmploymentHourFormData,
): DivergentPostEmploymentHourPostRequest => ({
    type: 'divergentPostEmploymentHours',
    attributes: {
        ...(formData.contractHours !== undefined && { contractHours: formData.contractHours }),
        ...(formData.checked !== undefined && { checked: formData.checked }),
    },
    relationships: {
        employment: {
            data: {
                type: 'employments',
                id: employmentId,
            },
        },
    },
});

export const transformDivergentPostEmploymentHourFormDataToPatchRequest = (
    divergentPostEmploymentHourId: string,
    formData: DivergentPostEmploymentHourFormData,
): DivergentPostEmploymentHourPatchRequest => ({
    id: divergentPostEmploymentHourId,
    type: 'divergentPostEmploymentHours',
    attributes: {
        ...(formData.contractHours !== undefined && { contractHours: formData.contractHours }),
        ...(formData.checked !== undefined && { checked: formData.checked }),
    },
});
