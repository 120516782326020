import {
    DetailedHTMLProps,
    FC,
    InputHTMLAttributes,
    PropsWithChildren,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import debounce from 'lodash.debounce';

import CheckboxGroupContext, { CheckboxEntry } from './CheckboxGroupContext';

export interface CheckboxChange extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    checked: boolean;
    disabled: boolean;
}

interface CheckboxGroupProps {
    defaultChecked?: boolean;
    defaultDisabled?: boolean;
    onChange?: (checkboxes: CheckboxChange[]) => void;
}

const ON_CHANGE_DEBOUNCE_TIMEOUT = 100;

const CheckboxGroup: FC<PropsWithChildren<CheckboxGroupProps>> = ({
    defaultChecked,
    defaultDisabled,
    onChange,
    children,
}): ReactElement => {
    const [checkboxes] = useState(new Map<string, CheckboxEntry>());
    const [allCheckerCheckboxes] = useState(new Map<string, CheckboxEntry>());
    const [noneCheckerCheckboxes] = useState(new Map<string, CheckboxEntry>());

    const dispatchOnChange = (): void => {
        if (onChange === undefined) {
            return;
        }

        const checkboxChangeArray: CheckboxChange[] = [];

        checkboxes.forEach((checkbox): void => {
            if (checkbox.isDisabled) {
                return;
            }

            checkboxChangeArray.push({
                ...checkbox.props,
                checked: checkbox.isChecked || false,
                disabled: checkbox.isDisabled || false,
            });
        });

        onChange(checkboxChangeArray);
    };

    const debouncedOnChange = debounce(dispatchOnChange, ON_CHANGE_DEBOUNCE_TIMEOUT);

    const setAllCheckboxesChecked = (state: boolean): void => {
        allCheckerCheckboxes.forEach((checkbox): void => checkbox.setIsChecked(state));
        noneCheckerCheckboxes.forEach((checkbox): void => checkbox.setIsChecked(!state));
        checkboxes.forEach((checkbox, key): void => {
            if (checkbox.isDisabled) {
                return;
            }

            const clone = checkbox;
            checkbox.setIsChecked(state);
            clone.isChecked = state;
            checkboxes.set(key, clone);
        });
    };

    const amountChecked = (): number => {
        let count = 0;

        checkboxes.forEach((checkbox): void => {
            if (checkbox.isChecked === true) {
                count += 1;
            }
        });

        return count;
    };

    const allCheckboxesAreChecked = (): boolean => {
        const checkedCount = amountChecked();

        return checkedCount > 0 && checkedCount === checkboxes.size;
    };

    const allCheckboxesAreNotChecked = (): boolean => amountChecked() === 0;

    const onCheckboxChange = (): void => {
        const allChecked = allCheckboxesAreChecked();
        allCheckerCheckboxes.forEach((checkbox): void => checkbox.setIsChecked(allChecked));

        const noneChecked = allCheckboxesAreNotChecked();
        noneCheckerCheckboxes.forEach((checkbox): void => checkbox.setIsChecked(noneChecked));

        debouncedOnChange();
    };

    const onAllCheckerCheckboxChange = (key: string, initialized: boolean): void => {
        const allCheckerCheckbox = allCheckerCheckboxes.get(key);

        if (!allCheckerCheckbox) {
            return;
        }

        if (initialized) {
            setAllCheckboxesChecked(allCheckerCheckbox.isChecked === true);
            debouncedOnChange();
        } else {
            setAllCheckboxesChecked(defaultChecked || allCheckboxesAreChecked());
        }
    };

    const onNoneCheckerCheckboxChange = (key: string, initialized: boolean): void => {
        const noneCheckerCheckbox = noneCheckerCheckboxes.get(key);

        if (!noneCheckerCheckbox) {
            return;
        }

        if (initialized && noneCheckerCheckbox.isChecked) {
            setAllCheckboxesChecked(false);
            debouncedOnChange();
        } else if (!noneCheckerCheckbox.isChecked && allCheckboxesAreNotChecked()) {
            noneCheckerCheckbox.setIsChecked(true);
        }
    };

    const hasCheckbox = (id: string) => checkboxes.has(id) || allCheckerCheckboxes.has(id) || noneCheckerCheckboxes.has(id);

    const assertIdDoesNotExist = (subject: string): void => {
        if (hasCheckbox(subject)) {
            throw new Error(`Duplicate id ${subject} in CheckboxGroup`);
        }
    };

    const contextValue = useMemo(() => ({
        allCheckerCheckboxes,
        assertIdDoesNotExist,
        checkboxes,
        defaultChecked,
        defaultDisabled,
        noneCheckerCheckboxes,
        onAllCheckerCheckboxChange,
        onCheckboxChange,
        onNoneCheckerCheckboxChange,
    }), [children, checkboxes]);

    return (
        <CheckboxGroupContext.Provider value={contextValue}>
            {children}
        </CheckboxGroupContext.Provider>
    );
};

export default CheckboxGroup;
