import { Department } from '../../../../entities/Department/Department';
import { getUniqueArrayList } from '../../../../helpers/array';
import { DepartmentOption } from '../../../../types/selectOptionTypes';

export const transformDepartmentOptionsToDepartments = (departments: Department[], departmentOptions: DepartmentOption[]): Department[] => {
    const groups = departmentOptions
        .filter(departmentOption => departmentOption.isGroup)
        .reduce((total: Department[], departmentOption) => [
            ...total,
            ...departments.filter(department => department.departmentGroup?.id === departmentOption.value),
        ], []);

    const options = departmentOptions
        .filter(departmentOption => !departmentOption.isGroup)
        .map(departmentOption => departments.find(department => department.id === departmentOption.value))
        .filter(department => department) as Department[];

    return getUniqueArrayList([
        ...groups,
        ...options,
    ], 'id');
};
