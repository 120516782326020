import React, { FC, useMemo } from 'react';

import Tooltip from 'react-tooltip-lite';

import { IconButton } from '../../@paco/compositions';
import { ConnectedUserContractHours } from '../../@paco/connectors';
import { Comment } from '../../@paco/entities/Comment/Comment';
import { EmploymentType } from '../../@paco/entities/EmploymentType/EmploymentType';
import { getFirstLetterOfEmploymentType, getUserFullName } from '../../helpers';
import { translate } from '../../helpers/translations/translator';
import { CommentViewModel, TemporaryWorkerViewModel, UserViewModel } from '../../models';
import { EmploymentTypeSlug, EmploymentTypeViewModel } from '../../models/EmploymentType';
import Icon from '../style/Icon/Icon';
import UserColorPicker from '../UserColorPicker/UserColorPicker';
import UserPageLink from '../UserPageLink/UserPageLink';

import './PlannedUserCard.scss';


interface PlannedUserCardProps {
    isCompanyMedic?: boolean;
    isTemporaryWorker?: boolean;
    id: string;
    labels?: React.ReactNode;
    color?: string | null;
    userId?: string;
    userName: string;
    employmentType?: EmploymentType | EmploymentTypeViewModel;
    experience?: string;
    plannedHoursInWeek?: number;
    time?: string;
    comment?: CommentViewModel | Comment;
    onAddButtonClick?: () => void;
    onColorSelect?: (
        userId: string,
        shiftPlanningId: string,
        userType: UserViewModel['type'] | TemporaryWorkerViewModel['type'],
        color: string | null,
    ) => void;
    onEditButtonClick?: () => void;
    onDeleteButtonClick?: () => void;
    className?: string;
}

const PlannedUserCard: FC<PlannedUserCardProps> = ({
    isCompanyMedic,
    isTemporaryWorker,
    id,
    color = null,
    userId,
    userName,
    employmentType,
    experience,
    plannedHoursInWeek,
    time,
    comment,
    labels,
    onAddButtonClick,
    onColorSelect,
    onEditButtonClick,
    onDeleteButtonClick,
    className = '',
}) => {
    const employmentLetter = useMemo(
        () => getFirstLetterOfEmploymentType(employmentType?.slug || EmploymentTypeSlug.temporaryWorker),
        [employmentType],
    );
    const commentOwner = useMemo(() => (comment ? getUserFullName(comment.owner) : undefined), [comment]);

    return (
        <div className={`planned-user-card ${className}`}>
            <div className="planned-user-card__info">
                <div className="planned-user-card__info-row">
                    {(onColorSelect && userId) && (
                        <UserColorPicker
                            id={`color-picker-${id}`}
                            userType="users"
                            color={color}
                            className="planned-user-card__color-picker"
                            onColorSelect={onColorSelect}
                            userId={userId}
                            shiftPlanningId={id}
                        />
                    )}

                    {isTemporaryWorker ? (
                        <div className="planned-user-card__user-name">{userName}</div>
                    ) : (
                        <UserPageLink
                            isCompanyMedic={isCompanyMedic}
                            name={userName}
                            id={userId}
                        />
                    )}
                    {/* @ts-ignore */}
                    <Tooltip
                        className="planned-user-card__tooltip"
                        content={translate(`common.${employmentType?.slug || EmploymentTypeSlug.temporaryWorker}`)}
                        arrowSize={6}
                        padding={7}
                        zIndex={2000}
                    >
                        <div className="badge-level planned-user-card__employment-type">
                            {employmentLetter}
                        </div>
                    </Tooltip>
                    {experience && <div className="badge-level planned-user-card__level">{experience}</div>}
                    {(userId && !isTemporaryWorker) && (
                        <ConnectedUserContractHours
                            plannedHoursInWeek={plannedHoursInWeek}
                            userId={userId}
                            className="planned-user-card-hours"
                        />
                    )}
                </div>
                {time && (
                    <div className="planned-user-card__info-row planned-user-card__time">
                        {time}
                    </div>
                )}
                {comment && (
                    <div className="planned-user-card__info-row planned-user-card__comment">
                        {`${(commentOwner && comment.body !== '') ? `${commentOwner}: ` : ''}${comment.body}`}
                    </div>
                )}
            </div>

            <div className="planned-user-card__buttons-and-labels">
                {labels}

                {onAddButtonClick && (
                    <IconButton
                        icon="plus"
                        text={translate('common.add')}
                        onClick={onAddButtonClick}
                        className="planned-user-card__button-add"
                        iconClassName="planned-user-card__button-add-icon"
                    />
                )}

                {onEditButtonClick && (
                    <button
                        type="button"
                        className="planned-user-card__button-edit"
                        onClick={onEditButtonClick}
                    >
                        <Icon color="light-blue" kind="edit" fontSize={20} />
                    </button>
                )}

                {onDeleteButtonClick && (
                    <button
                        type="button"
                        className="planned-user-card__button-delete"
                        onClick={onDeleteButtonClick}
                    >
                        <Icon color="light-blue" kind="trash" fontSize={20} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default PlannedUserCard;
