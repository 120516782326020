import { FC } from 'react';

import classnames from 'classnames';

import CalendarLegend from '../../../components/CalendarLegend/CalendarLegend';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { translate } from '../../../helpers/translations/translator';
import { PayrollPeriodViewModel } from '../../../models';
import CalendarToolbar from '../../../pages/Schedule/components/CalendarToolbar/CalendarToolbar';
import CalendarHeader from '../../components/CalendarHeader/CalendarHeader';
import { TimeModeType } from '../../types';

import './AvailabilityActionBar.scss';

export interface AvailabilityActionBarProps {
    isSticky: boolean;
    employeeSearch: string;
    mobileSelectedDay: Date;
    payrollPeriods: PayrollPeriodViewModel[];
    selectedDays: Date[];
    timeMode: TimeModeType;
    afterDateChange: () => void;
    onSearchBarChange: () => void;
    setStartAndEndDate: () => void;
    className?: string;
}

const AvailabilityActionBar: FC<AvailabilityActionBarProps> = ({
    isSticky,
    employeeSearch,
    mobileSelectedDay,
    payrollPeriods,
    selectedDays,
    timeMode,
    afterDateChange,
    onSearchBarChange,
    setStartAndEndDate,
    className = '',
}) => {
    const actionBarClassNames = classnames('availability-action-bar', {
        'availability-action-bar--is-sticky': isSticky,
    }, className);

    return (
        <div className={actionBarClassNames}>
            <div className="availability-action-bar__navigation">
                <CalendarToolbar
                    payrollPeriods={payrollPeriods}
                    timeMode={timeMode}
                    setStartAndEndDate={setStartAndEndDate}
                    afterDateChange={afterDateChange}
                    className="availability-action-bar__calendar-toolbar"
                />
                <SearchBar
                    placeholder={translate('common.searchEmployee')}
                    startVal={employeeSearch}
                    onChange={onSearchBarChange}
                    className="availability-action-bar__search-bar"
                />
            </div>
            <CalendarLegend
                absence
                leaveOfAbsence
                available
                notAvailable
                className="calendar-legend"
            />
            <CalendarHeader
                days={selectedDays}
                mode={timeMode}
                className="availability-action-bar__calendar-header-desktop"
            />
            <CalendarHeader
                days={[mobileSelectedDay]}
                mode={timeMode}
                className="availability-action-bar__calendar-header-mobile"
            />
        </div>
    );
};

export default AvailabilityActionBar;
