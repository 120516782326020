import React, { FC } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';


interface FilterProps {
    filter: AppFilter;
    onChange: (filter: AppFilter) => void;
}

const ShowEmployeesFilter: FC<FilterProps> = ({ filter, onChange }) => {
    const onCheckboxChange = (showEmployees: boolean) => {
        const shifts = {
            ...filter.shifts,
            showEmployees,
        };

        onChange({
            ...filter,
            shifts,
        });
    };

    return (
        <FilterBlock title={translate('common.employees')}>
            <FormGroup check className="p-0">
                <Label check>
                    <CustomInput
                        id="show-employees-checkbox"
                        type="checkbox"
                        label={translate('pages.calendar.showEmployees')}
                        checked={filter.shifts.showEmployees}
                        onChange={() => onCheckboxChange(!filter.shifts.showEmployees)}
                    />
                </Label>
            </FormGroup>
        </FilterBlock>
    );
};

export default ShowEmployeesFilter;
