import axios from 'axios';

import { ApiResponse, CommentCategoryResource, CommentCategoryViewModel } from '../../models';
import transformCommentCategory from '../../services/CommentCategoryService/transformCommentCategory';

export async function getCommentCategories(): Promise<CommentCategoryViewModel[]> {
    const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/comment-categories`) as unknown as ApiResponse<CommentCategoryResource[]>;

    return response.data.map((commentCategory) => transformCommentCategory(commentCategory));
}
