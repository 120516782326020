import React, { FC } from 'react';

import { GroupBase } from 'react-select';

import { DepartmentOption } from '../../models/DepartmentOption';
import AddDepartmentGroupButton from '../AddDepartmentGroupButton/AddDepartmentGroupButton';

export interface GroupLabelData extends GroupBase<DepartmentOption> {
    id?: string;
}

interface GroupLabelProps {
    data: GroupLabelData;
    title?: string;
    onGroupButtonClick?: (id: string) => void;
}

const GroupLabel: FC<GroupLabelProps> = ({
    data,
    onGroupButtonClick,
    title,
}) => (
    <div className="basic-multi-select__group">
        <span className="basic-multi-select__group-label">
            {`${data.label}`}
        </span>
        {(data.id && onGroupButtonClick) && (
            <AddDepartmentGroupButton title={title} onClick={() => data.id && onGroupButtonClick(data.id)} />
        )}
    </div>
);

export default GroupLabel;
