import { FC, ReactElement } from 'react';

interface BackgroundWooshImageProps {
    className?: string;
}

const BackgroundWooshImage: FC<BackgroundWooshImageProps> = ({ className = '' }): ReactElement => (
    <svg
        fill="none"
        preserveAspectRatio="none"
        viewBox="0 0 323 43"
        xmlns="http://www.w3.org/2000/svg"
        className={`background-woosh-image ${className}`}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 5.07417C116.186 5.07417 132.732 43.4782 224.153 38.0445C315.574 32.6108 323 0 323 0V42.8948H0V5.07417Z"
            fill="url(#paint0_linear_2916_172)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 16.8455C50.4163 13.7744 92.52 27.2912 138.241 35.5976C168.622 41.117 203.137 43.0583 233.537 40.745C309.686 34.9504 323 13.1143 323 13.1143V42.8948H0V16.8455Z"
            fill="url(#paint1_linear_2916_172)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_2916_172"
                x1="-81.2883"
                y1="78.2352"
                x2="447.7"
                y2="78.2352"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2A6A7F" />
                <stop offset="1" stopColor="#86C8DF" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2916_172"
                x1="-267.11"
                y1="15.9903"
                x2="-262.025"
                y2="86.1172"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#86C8DF" />
                <stop offset="1" stopColor="#2A6A7F" />
            </linearGradient>
        </defs>
    </svg>
);

export default BackgroundWooshImage;
