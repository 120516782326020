import { FC, useMemo } from 'react';

import { Skeleton } from '../../../../components';

import './DashboardSmallWidgetSkeleton.scss';

export interface DashboardSmallWidgetProps {
    className?: string;
}

const DashboardSmallWidgetSkeleton: FC<DashboardSmallWidgetProps> = ({ className = '' }) => {
    const subTitleSkeletonVariation = useMemo(() => (1 + Math.floor(Math.random() * 2)), []);
    const subTitleSkeletonClassName = `
        dashboard-small-widget-skeleton__sub-title-skeleton
        dashboard-small-widget-skeleton__sub-title-skeleton--variation-${subTitleSkeletonVariation}
    `;

    return (
        <div className={`dashboard-small-widget dashboard-small-widget-skeleton ${className}`}>
            <div className="dashboard-small-widget-content dashboard-small-widget-skeleton__content">
                <div className="dashboard-small-widget-content__titles-wrapper dashboard-small-widget-skeleton__titles-wrapper">
                    <Skeleton className="dashboard-small-widget-content__title-skeleton dashboard-small-widget-skeleton__title-skeleton" />
                    <Skeleton className={subTitleSkeletonClassName} />
                </div>
                <div className="dashboard-small-widget-content__button dashboard-small-widget-skeleton__button" />
            </div>
        </div>
    );
};

export default DashboardSmallWidgetSkeleton;
