import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedDashboard } from '../../connectors';

const DashboardPage: FC = (): ReactElement => (
    <Page>
        <ConnectedDashboard />
    </Page>
);

export default DashboardPage;
