import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { shiftConceptSlice } from '../../../@paco/redux/shiftConcept/shiftConceptReducer';
import { ShiftConceptViewModelOld } from '../../../entities/ShiftConcept/ShiftConcept';
import { updateLegacyShiftConceptWithShiftConcept } from '../../../entities/ShiftConcept/ShiftConceptHelpers';
import { UnavailableToWorkTimeSlotViewModel } from '../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import {
    AbsenceViewModel,
    LeaveOfAbsenceViewModel,
    Pagination,
    ShiftViewModel,
    SpecialDayViewModel,
    UserWithConceptAvailability,
} from '../../../models';
import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';

export type ShiftConceptsState = AsyncReduxState<{
    isShiftsLoading: boolean;
    isPublishShiftConceptsSuccessful: boolean;
    lastModifiedShiftConceptId?: string;
    shiftConcepts: ShiftConceptViewModelOld[];
    shiftConceptsToPublish: ShiftConceptViewModelOld[];
    shifts: ShiftViewModel[];
    specialDays: SpecialDayViewModel[];
    absences: AbsenceViewModel[];
    leaveOfAbsences: LeaveOfAbsenceViewModel[];
    unavailableWorkTimeSlots: UnavailableToWorkTimeSlotViewModel[];
    usersWithConceptAvailability: UserWithConceptAvailability[];
    availabilityPagination: Pagination;
}>;

const initialState: ShiftConceptsState = {
    ...initialAsyncReduxState,
    isPublishShiftConceptsSuccessful: false,
    isShiftsLoading: false,
    shiftConcepts: [],
    shiftConceptsToPublish: [],
    shifts: [],
    specialDays: [],
    absences: [],
    leaveOfAbsences: [],
    unavailableWorkTimeSlots: [],
    usersWithConceptAvailability: [],
    availabilityPagination: { number: 0, pages: 1 },
};

export const ShiftConceptSlice = createSlice({
    name: 'shiftConceptReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ShiftConceptsState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setIsShiftsLoading(state, action: PayloadAction<boolean>): ShiftConceptsState {
            return ({
                ...state,
                isShiftsLoading: action.payload,
            });
        },
        setShiftConcepts(state, action: PayloadAction<ShiftConceptViewModelOld[]>): ShiftConceptsState {
            return ({
                ...state,
                shiftConcepts: action.payload,
            });
        },
        setShiftConceptsToPublish(state, action: PayloadAction<ShiftConceptViewModelOld[]>): ShiftConceptsState {
            return ({
                ...state,
                shiftConceptsToPublish: action.payload,
            });
        },
        setShifts(state, action: PayloadAction<ShiftViewModel[]>): ShiftConceptsState {
            return ({
                ...state,
                shifts: action.payload,
            });
        },
        setLastModifiedShiftConceptId(state, action: PayloadAction<string | undefined>): ShiftConceptsState {
            return ({
                ...state,
                lastModifiedShiftConceptId: action.payload,
            });
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ShiftConceptsState {
            return ({
                ...state,
                isSuccessful: action.payload,
            });
        },
        setIsPublishShiftConceptsSuccessful(state, action: PayloadAction<boolean>): ShiftConceptsState {
            return ({
                ...state,
                isPublishShiftConceptsSuccessful: action.payload,
            });
        },
        setAvailabilityData(state, action: PayloadAction<{
            leaveOfAbsences: LeaveOfAbsenceViewModel[],
            absences: AbsenceViewModel[],
            unavailableWorkTimeSlots: UnavailableToWorkTimeSlotViewModel[],
        }>): ShiftConceptsState {
            return ({
                ...state,
                absences: action.payload.absences,
                leaveOfAbsences: action.payload.leaveOfAbsences,
                unavailableWorkTimeSlots: action.payload.unavailableWorkTimeSlots,
            });
        },
        setAvailabilityPagination(state, action: PayloadAction<number>): ShiftConceptsState {
            const pagination = { ...state.availabilityPagination, number: action.payload };

            return ({
                ...state,
                availabilityPagination: pagination,
            });
        },
        setUsersListViewData(state, action: PayloadAction<{
            userWithConceptAvailability: UserWithConceptAvailability[],
            availabilityPaginationNumber: Pagination,
        }>): ShiftConceptsState {
            return ({
                ...state,
                usersWithConceptAvailability: action.payload.userWithConceptAvailability,
                availabilityPagination: action.payload.availabilityPaginationNumber,
            });
        },
        setUserWithConceptAvailability(state, action: PayloadAction<UserWithConceptAvailability[]>): ShiftConceptsState {
            return ({
                ...state,
                usersWithConceptAvailability: action.payload,
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(shiftConceptSlice.actions.setShiftConcept, (state, action) => {
            const shiftConcept = state.shiftConcepts.find(shift => shift.id === action.payload.id);

            if (!shiftConcept) {
                return state;
            }

            const updatedShiftConcept = updateLegacyShiftConceptWithShiftConcept(shiftConcept, action.payload);

            const shiftConcepts: ShiftConceptViewModelOld[] = [
                ...state.shiftConcepts.filter(shift => shift.id !== action.payload.id),
                updatedShiftConcept,
            ];

            return {
                ...state,
                shiftConcepts,
            };
        });
    },
});

export const {
    setIsLoading,
    setIsShiftsLoading,
    setIsSuccessful,
    setShiftConcepts,
    setShiftConceptsToPublish,
    setShifts,
    setAvailabilityData,
    setAvailabilityPagination,
    setLastModifiedShiftConceptId,
    setIsPublishShiftConceptsSuccessful,
    setUsersListViewData,
    setUserWithConceptAvailability,
} = ShiftConceptSlice.actions;

export default ShiftConceptSlice.reducer;
