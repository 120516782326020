import { FC, useMemo } from 'react';

import classnames from 'classnames';

import { DistrictDivisionDistrictPlanning } from '../../../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { getTimeOnlyLabel } from '../../../../entities/Period/PeriodHelpers';
import { Setting } from '../../../../entities/Setting/Setting';
import { getSettingEnabled } from '../../../../entities/Setting/SettingHelpers';
import { formatDate } from '../../../../helpers/date';
import { DateFormat } from '../../../../types/dateFormatTypes';

import './PrintableDistrictDivisionDistrictPlanning.scss';

interface PrintableDistrictDivisionGroupProps {
    index: number;
    planning?: DistrictDivisionDistrictPlanning;
    settings: Setting[];
    className?: string;
}

const PrintableDistrictDivisionDistrictPlanning: FC<PrintableDistrictDivisionGroupProps> = ({
    index,
    planning,
    settings,
    className = '',
}) => {
    const listItemClassName = classnames('printable-district-division-district-planning', {
        'printable-district-division-district-planning--has-comment': !!planning?.comments.length,
    }, className);

    const showEndTime = useMemo(() => getSettingEnabled('EMPLOYEE_APP_SHOW_END_TIMES', settings, planning?.department.id), [settings, planning]);
    const order = index + 1;

    if (!planning) {
        return (
            <li className={listItemClassName}>
                <div className="printable-district-division-district-planning__order">
                    {order}
                </div>
            </li>
        );
    }

    return (
        <li className={listItemClassName}>
            <div className="printable-district-division-district-planning__header">
                <div className="printable-district-division-district-planning__order">
                    {order}
                </div>
                <div className="printable-district-division-district-planning__employee-name">
                    {planning.fullName}
                </div>
                <div className="printable-district-division-district-planning__time">
                    {showEndTime ? getTimeOnlyLabel(planning.period.start, planning.period.end) : formatDate(planning.period.start, DateFormat.hoursMinutes)}
                </div>
            </div>
            {!!planning.comments.length && (
                <p className="printable-district-division-district-planning__comment">
                    {planning.comments[planning.comments.length - 1].body}
                </p>
            )}
        </li>
    );
};

export default PrintableDistrictDivisionDistrictPlanning;
