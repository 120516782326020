import React, { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useTypedSelector } from '../../../../@paco/redux/store';
import { getPayrollPeriods } from '../../../../redux/app/appActions';
import { setEmployeeSearch, setFilter } from '../../../../redux/filter/filterActions';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import { Reducers } from '../../../../redux/reducers';
import { getUsersWithLoketBalances } from '../../../../redux/shifts-ts/shiftsActions';
import { setStartAndEndDate } from '../../../../redux/weekNavigator/weekNavigatorActions';
import UsersWithHourBalancesOverview from '../../containers/UsersWithHourBalancesOverview/UsersWithHourBalancesOverview';

const ConnectedUsersWithHourBalancesOverview: FC = () => {
    const dispatch = useDispatch();

    const filter = useSelector((state: Reducers) => state.filterReducer.filter);
    const { departmentOptions } = useTypedSelector(state => state.globalFiltersReducer);
    const {
        roles,
        payrollPeriods,
        employmentTypes,
    } = useSelector((state: Reducers) => state.appReducer);
    const {
        loading,
        usersWithLoketBalances,
    } = useSelector((state: Reducers) => state.shiftsReducer);
    const pagination = useSelector((state: Reducers) => state.paginationReducer);
    const timeMode = useSelector((state: Reducers) => state.weekNavigatorReducer.mode);

    const getUsersData = (): void => {
        dispatch(getUsersWithLoketBalances());
    };

    const onSetFilterChange = (newFilter: AppFilter): void => {
        dispatch(setFilter(newFilter, getUsersData));
    };

    const onSearchBarChange = (search: string): void => {
        dispatch(setEmployeeSearch(search));
        dispatch(getUsersWithLoketBalances());
    };

    const onSetStartAndEndDate = (selectedDate: Date, newStartDate: Date, newEndDate: Date): void => {
        dispatch(setStartAndEndDate(selectedDate, newStartDate, newEndDate));
        dispatch(getUsersWithLoketBalances());
    };

    const onAfterDateChange = (): void => {
        dispatch(getUsersWithLoketBalances());
    };

    const onPaginationChange = (): void => {
        dispatch(getUsersWithLoketBalances());
    };

    useEffect((): void => {
        dispatch(getPayrollPeriods());
    }, []);

    useEffect((): void => {
        dispatch(getUsersWithLoketBalances());
    }, [departmentOptions]);

    return (
        <UsersWithHourBalancesOverview
            loading={loading}
            timeMode={timeMode}
            filter={filter}
            users={usersWithLoketBalances}
            roles={roles}
            payrollPeriods={payrollPeriods}
            employmentTypes={employmentTypes}
            pagination={pagination}
            onSetFilterChange={onSetFilterChange}
            onSearchBarChange={onSearchBarChange}
            onAfterDateChange={onAfterDateChange}
            onSetStartAndEndDate={onSetStartAndEndDate}
            onPaginationChange={onPaginationChange}
        />
    );
};

export default ConnectedUsersWithHourBalancesOverview;
