import { formatDate } from '../../helpers/date';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import {
    CompanyMedicType,
    GenderType,
    IdentificationType,
    WorkdayType,
} from '../../types';
import { DateFormat } from '../../types/dateFormatTypes';
import { PersonalInfoFormData, UserProfileV3Resource } from '../User/User';
import {
    AddPersonRequest,
    BasicPerson,
    EditPersonFormData,
    EditPersonRequest,
    Person,
    PersonFormData,
    PersonResource,
} from './Person';

export const transformToBasicPerson = (
    firstname: string,
    lastname: string,
    affix = '',
): BasicPerson => ({
    firstname,
    affix,
    lastname,
});

export const transformToPerson = (resource: PersonResource): Person => ({
    id: resource.id,
    firstname: resource.attributes.firstname,
    affix: resource.attributes.affix,
    lastname: resource.attributes.lastname,
    gender: resource.attributes.gender as GenderType,
    birthday: resource.attributes.birthday ? new Date(resource.attributes.birthday) : undefined,
    hasSundayAllowance: resource.attributes.hasSundayAllowance,
    identificationExpirationDate: new Date(resource.attributes.identificationExpirationDate),
    identificationType: resource.attributes.identificationType as IdentificationType,
    companyMedic: resource.attributes.isCompanyMedic || CompanyMedicType.unknown,
    nationality: resource.attributes.nationality,
    workdays: resource.attributes.workDays,
});

const transformStringToWorkday = (value: string): WorkdayType | undefined => {
    const workday = getEnumKeyByEnumValue(WorkdayType, value);

    if (!workday) {
        console.error(`[transformStringToWorkday]: Unknown enum found named "${value}".`);

        return undefined;
    }

    return WorkdayType[workday];
};

export const transformCompanyMedicToNumber = (companyMedic: CompanyMedicType): number => {
    if (companyMedic === CompanyMedicType.yes) {
        return 2;
    }

    if (companyMedic === CompanyMedicType.no) {
        return 1;
    }

    return 0;
};

export const transformToCompanyMedicType = (resource: number): CompanyMedicType => {
    if (resource === 2) {
        return CompanyMedicType.yes;
    }

    if (resource === 1) {
        return CompanyMedicType.no;
    }

    return CompanyMedicType.unknown;
};

export const transformUserProfileV3ResourceToPerson = (resource: UserProfileV3Resource): Person => {
    const gender = getEnumKeyByEnumValue(GenderType, resource.gender);
    const identificationType = getEnumKeyByEnumValue(IdentificationType, resource.identificationType);
    const workdays = resource.workdays
        .map(transformStringToWorkday)
        .filter(Boolean) as WorkdayType[];

    if (!gender) {
        console.error(`[transformUserProfileV3ResourceToPerson]: Unknown gender enum found named "${resource.gender}".`);
    }

    if (!identificationType) {
        console.error(`[transformUserProfileV3ResourceToPerson]: Unknown identificationType enum found named "${resource.identificationType}".`);
    }

    return {
        id: resource.personId,
        firstname: resource.firstname,
        affix: resource.affix || undefined,
        lastname: resource.lastname,
        gender: gender ? GenderType[gender] : GenderType.unknown,
        birthday: resource.birthday ? new Date(resource.birthday) : undefined,
        hasSundayAllowance: resource.hasSundayAllowance,
        identificationExpirationDate: new Date(resource.identificationExpirationDate || 0),
        identificationType: identificationType ? IdentificationType[identificationType] : IdentificationType.unknown,
        companyMedic: transformToCompanyMedicType(resource.companyMedic),
        nationality: resource.nationality,
        workdays,
    };
};

export const transformAddPersonFormDataToAddApiParams = (
    personalInfoFormData: PersonalInfoFormData,
    personFormData: PersonFormData,
    userId: string,
    experienceId?: string,
): AddPersonRequest => ({
    type: 'people',
    attributes: {
        firstname: personalInfoFormData.firstname,
        lastname: personalInfoFormData.lastname,
        affix: personalInfoFormData.affix,
        birthday: formatDate(personalInfoFormData.birthday, DateFormat.apiDate),
        nationality: personFormData.nationality,
        isCompanyMedic: personFormData.companyMedic,
        hasSundayAllowance: personFormData.hasSundayAllowance,
        workDays: personFormData.workdays,
        identificationType: personFormData.identificationType,
        identificationExpirationDate: formatDate(personFormData.identificationExpirationDate, DateFormat.apiDate),
        gender: personalInfoFormData.gender,
    },
    relationships: {
        user: {
            data: {
                type: 'users',
                id: userId,
            },
        },
        experience: experienceId ? {
            data: {
                type: 'experiences',
                id: experienceId,
            },
        } : undefined,
    },
});

export const transformEditPersonFormDataToEditApiParams = (
    personalInfoFormData: PersonalInfoFormData,
    personFormData: EditPersonFormData,
    userId: string,
    experienceId?: string,
): EditPersonRequest => ({
    type: 'people',
    id: personFormData.id,
    attributes: {
        firstname: personalInfoFormData.firstname,
        lastname: personalInfoFormData.lastname,
        affix: personalInfoFormData.affix,
        birthday: formatDate(personalInfoFormData.birthday, DateFormat.apiDate),
        nationality: personFormData.nationality,
        isCompanyMedic: personFormData.companyMedic,
        hasSundayAllowance: personFormData.hasSundayAllowance,
        workDays: personFormData.workdays,
        identificationType: personFormData.identificationType,
        identificationExpirationDate: formatDate(personFormData.identificationExpirationDate, DateFormat.apiDate),
        gender: personalInfoFormData.gender,
    },
    relationships: {
        user: {
            data: {
                type: 'users',
                id: userId,
            },
        },
        experience: experienceId ? {
            data: {
                type: 'experiences',
                id: experienceId,
            },
        } : undefined,
    },
});
