import React, { FC, useMemo } from 'react';

import { getUserFullName } from '../../../../../../helpers';
import { UserViewModel } from '../../../../../../models';
import { EmploymentTypeSlug } from '../../../../../../models/EmploymentType';
import ShiftEmployee from '../ShiftEmployee/ShiftEmployee';

interface ShiftPreviouslyPlannedUserProps {
    employmentTypeClassName?: string;
    user: UserViewModel;
    employmentTypeSlug?: EmploymentTypeSlug;
    showEmploymentTypeBadge?: boolean;
    className?: string;
}

const ShiftPreviouslyPlannedUser: FC<ShiftPreviouslyPlannedUserProps> = ({
    employmentTypeClassName,
    user,
    employmentTypeSlug,
    showEmploymentTypeBadge = false,
    className,
}) => {
    const name = useMemo(() => getUserFullName(user), [user]);

    return (
        <ShiftEmployee
            className={className}
            employmentTypeClassName={employmentTypeClassName}
            name={name}
            employmentTypeSlug={employmentTypeSlug}
            showEmploymentTypeBadge={showEmploymentTypeBadge}
        />
    );
};

export default ShiftPreviouslyPlannedUser;
