import { FC, ReactElement } from 'react';

import './UserProfileCardListItem.scss';

interface UserDetailListItemProps {
    title: string;
    value: string | number;
    className?: string;
}

const UserProfileCardListItem: FC<UserDetailListItemProps> = ({ title, value, className = '' }): ReactElement => {
    const formattedValue = value === '' ? '-' : value;

    return (
        <li className={`user-profile-card-list-item ${className}`}>
            <div className="user-profile-card-list-item__title">
                {title}
            </div>
            <div className="user-profile-card-list-item__value">
                {formattedValue}
            </div>
        </li>
    );
};

export default UserProfileCardListItem;
