import { FC, ReactElement } from 'react';

import trans from '../../../helpers/trans';
import { GenderType, SelectOption } from '../../../types';
import { SelectInput } from '../../index';

import './GenderSelectInput.scss';

const generateGenderOptions = (): SelectOption[] => {
    const genderArray = Object.keys(GenderType);

    return genderArray.map(gender => ({ label: trans(`common.${gender}`), value: gender })) as SelectOption<GenderType>[];
};

interface GenderSelectInputProps {
    gender: SelectOption<GenderType>;
    onChange: (option: SelectOption<GenderType>) => void;
    requiredClassName?: string;
}

const GenderSelectInput: FC<GenderSelectInputProps> = ({
    gender,
    onChange,
    requiredClassName = '',
}): ReactElement => {
    const genderOptions = generateGenderOptions();

    const onGenderStateChange = (genderValue: SelectOption): void => {
        onChange(genderValue as SelectOption<GenderType>);
    };

    return (
        <SelectInput
            required
            requiredText={trans('common.valkWork')}
            isSearchable={false}
            label={trans('common.gender')}
            name="gender"
            noOptionsMessage={trans('compositions.genderSelectInput.noGenderFound')}
            value={gender}
            options={genderOptions}
            onChange={onGenderStateChange}
            className="gender-select-input"
            requiredClassName={requiredClassName}
        />
    );
};

export default GenderSelectInput;
