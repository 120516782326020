import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { ShiftConcept } from '../../entities/ShiftConcept/ShiftConcept';

export type ShiftConceptState = AsyncReduxState<{
    deletedShiftConceptId?: string;
    shiftConcept?: ShiftConcept;
    isDeleteShiftConceptSuccessful: boolean;
    isEditShiftConceptSuccessful: boolean;
}>;

const initialState: ShiftConceptState = {
    ...initialAsyncReduxState,
    isDeleteShiftConceptSuccessful: false,
    isEditShiftConceptSuccessful: false,
};

export const shiftConceptSlice = createSlice({
    name: 'pacoShiftConceptReducer',
    initialState,
    reducers: {
        setDeletedShiftConceptId(state, action: PayloadAction<string | undefined>): ShiftConceptState {
            return {
                ...state,
                deletedShiftConceptId: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): ShiftConceptState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ShiftConceptState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setIsDeleteShiftConceptSuccessful(state, action: PayloadAction<boolean>): ShiftConceptState {
            return {
                ...state,
                isDeleteShiftConceptSuccessful: action.payload,
            };
        },
        setIsEditShiftConceptSuccessful(state, action: PayloadAction<boolean>): ShiftConceptState {
            return {
                ...state,
                isEditShiftConceptSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ShiftConceptState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setShiftConcept(state, action: PayloadAction<ShiftConcept>): ShiftConceptState {
            return {
                ...state,
                shiftConcept: action.payload,
            };
        },
    },
});

export const {
    setDeletedShiftConceptId,
    setIsLoading,
    setIsSuccessful,
    setIsDeleteShiftConceptSuccessful,
    setIsEditShiftConceptSuccessful,
    setError,
    setShiftConcept,
} = shiftConceptSlice.actions;

export default shiftConceptSlice.reducer;
