import { Notifications } from '../../../entities/Notifications/Notifications';
import { Permission } from '../../../entities/Permission/Permission';
import { Role } from '../../../entities/Role/Role';
import { Setting } from '../../../entities/Setting/Setting';
import { getNavRoutesByPermissions } from '../../../helpers/routes';
import appRoutes, { NavigationRouteKey, NavRoute } from '../../../routes/routes';
import { PermissionSlug } from '../../../types/permissionTypes';
import { NavRouteWithCounter, SubNavRouteWithCounter } from '../Navigation';

const getNavRouteCounter = (key: NavigationRouteKey, notifications: Notifications, permissions: PermissionSlug[]): number => {
    const canViewAbsences = permissions.some(permission => permission === 'view-all-absences');
    const canViewExchanges = permissions.some(permission => permission === 'view-all-exchanges');
    const canViewLeaveOfAbsences = permissions.some(permission => permission === 'view-all-leave-of-absences');
    const canViewTracks = permissions.some(permission => permission === 'view-all-tracks');

    if (key === 'tracks' && canViewTracks) {
        return notifications.runningTracks + notifications.openTracks;
    }

    if (key === 'review-tracks' && canViewTracks) {
        return notifications.openTracks;
    }

    if (key === 'open-tracks' && canViewTracks) {
        return notifications.runningTracks;
    }

    if (key === 'exchange-requests' && canViewExchanges) {
        return notifications.openExchanges;
    }

    if (key === 'absences') {
        return (
            (canViewLeaveOfAbsences ? notifications.openLeaveOfAbsences : 0)
            + (canViewExchanges ? notifications.openExchanges : 0)
            + (canViewAbsences ? notifications.absencesWithConflicts : 0)
        );
    }

    if (key === 'absences-list' && canViewAbsences) {
        return notifications.absencesWithConflicts;
    }

    if (key === 'leave-of-absence-requests' && canViewLeaveOfAbsences) {
        return notifications.openLeaveOfAbsences;
    }

    return 0;
};

export const getUserRoutes = (
    userPermissions: Permission[],
    userSettings: Setting[],
    notifications?: Notifications,
    role?: Role,
): NavRoute[] => {
    const userPermissionSlugs = userPermissions.map(permission => permission.slug);
    // Filter out all children that don't have all required permissions and settings
    const userRoutes: NavRoute[] = getNavRoutesByPermissions(userPermissionSlugs, appRoutes, userSettings, role);

    // Add all counter indicator values to routes
    return userRoutes.map(route => {
        const children: SubNavRouteWithCounter[] = route.children.map(childRoute => ({
            ...childRoute,
            counter: notifications ? getNavRouteCounter(childRoute.key, notifications, userPermissionSlugs) : 0,
        }));

        return {
            ...route,
            children,
            counter: notifications ? getNavRouteCounter(route.key, notifications, userPermissionSlugs) : 0,
        };
    });
};

export const getActiveRoute = (userRoutes: NavRouteWithCounter[], pathname = ''): NavRouteWithCounter | undefined => userRoutes
    .find(route => pathname.indexOf(route.path) !== -1);
