import { ContractHoursFormData } from '../entities/ContractHours/ContractHours';
import { compareAsc, convertDateStringToDDMMYYYY, isValid } from '../helpers/date';
import { objectContainsValues } from '../helpers/tools/object';
import trans from '../helpers/trans';
import {
    isValidTimeString,
    numberDoesNotExceedComparison,
    stringContainsValue,
    stringHasMinimumLength,
    stringMatchesRegEx,
} from '../helpers/validation/validation';

export type FormError<Value = string> = Value | undefined;
export type FormErrors<FormData, FormSubData = string> = Partial<Record<keyof FormData, FormError<FormSubData>>>;
export type FormValidation<Errors> = [Errors, boolean];

export const validateForm = <Errors>(errors: Errors): FormValidation<Errors> => {
    const hasErrors = objectContainsValues<Errors>(errors);

    return [
        errors,
        hasErrors,
    ];
};

export const validateRequiredString = (attribute: string, string?: string): FormError => {
    if (!stringContainsValue(string)) {
        return trans('validation.errors.required', {
            attribute: trans(`validation.attributes.${attribute}`),
        });
    }

    return undefined;
};

export const validateNumberRange = (attribute: string, number: number, comparisonNumber: number): FormError => {
    if (!numberDoesNotExceedComparison(number, comparisonNumber)) {
        return trans('validation.errors.numberExceedsComparison', {
            attribute: trans(`validation.attributes.${attribute}`),
            comparison: String(comparisonNumber),
        });
    }

    return undefined;
};

export const validateDate = (date: Date): FormError => {
    if (!isValid(date)) {
        return trans('validation.errors.invalidDate', {
            attribute: trans('validation.attributes.date'),
        });
    }

    if (date.getFullYear() < 1000 || date.getFullYear() > 9999) {
        return trans('validation.errors.invalidDate', {
            attribute: trans('validation.attributes.date'),
        });
    }

    return undefined;
};

export const validateDateCompareAsc = (dateLeft: Date, dateRight: Date): FormError => {
    if (compareAsc(dateLeft, dateRight) !== 1) {
        return trans('validation.errors.dateAfterComparison', {
            attribute: trans('validation.attributes.endDate'),
            comparison: trans('validation.attributes.startDate').toLowerCase(),
        });
    }

    return undefined;
};

export const validateDateString = (attribute: string, dateString: string): FormError => {
    const formattedDateString = convertDateStringToDDMMYYYY(dateString);
    const DDMMYYYYRegex = /(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})/;

    if (!stringContainsValue(dateString)) {
        return trans('validation.errors.required', {
            attribute: trans(`validation.attributes.${attribute}`),
        });
    }

    if (!stringMatchesRegEx(formattedDateString, DDMMYYYYRegex)) {
        return trans('validation.errors.invalidExample', {
            attribute: trans(`validation.attributes.${attribute}`),
            example: trans('validation.errors.examples.date'),
        });
    }

    return undefined;
};

export const validateTimeString = (attribute: string, timeString: string): FormError => {
    if (!isValidTimeString(timeString)) {
        return trans('validation.errors.invalidExample', {
            attribute: trans(`validation.attributes.${attribute}`),
        });
    }

    return undefined;
};

export const validateEmailRegex = (attribute: string, string: string): FormError => {
    const emailRegEx = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;

    if (!stringMatchesRegEx(string, emailRegEx)) {
        return trans('validation.errors.emailNotValid', {
            attribute: trans(`validation.attributes.${attribute}`),
        });
    }

    return undefined;
};

export const validateStringMinimalLength = (attribute: string, string: string, length: number): FormError => {
    if (!stringHasMinimumLength(string, length)) {
        return trans('validation.errors.phoneNotValid', {
            attribute: trans(`validation.attributes.${attribute}`),
        });
    }

    return undefined;
};

export const validateNumberWithTwoOptionalDecimals = (attribute: string) : FormError => {
    const numberRegex = /^[0-9]+(\.[0-9]{0,2})?$/;

    if (!stringMatchesRegEx(attribute, numberRegex)) {
        return trans('validation.errors.numberNotValid');
    }

    return undefined;
};

export const validateContractHoursInput = (attribute: ContractHoursFormData[]): FormError => {
    const hourErrors = attribute.map(contractHour => validateNumberWithTwoOptionalDecimals(contractHour.hours.toString()) || validateNumberRange('contractHours', contractHour.hours, 60));

    return hourErrors.find(error => Boolean(error));
};

export const validateRequireContractHours = (attribute: string, contractHoursFormData: ContractHoursFormData[]): FormError => {
    const currentContractHoursFormData = contractHoursFormData[contractHoursFormData.length - 1];

    if (currentContractHoursFormData?.hours > 0) {
        return undefined;
    }

    return trans('validation.errors.inputRequiresContractHours', {
        attribute: trans(`validation.attributes.${attribute}`),
    });
};

export const validateDivergentEmploymentHoursInput = (attribute: string): FormError => validateNumberWithTwoOptionalDecimals(attribute) || validateNumberRange('contractHours', Number(attribute), 240);
