import { AbsenceHoursResource, AbsenceHoursViewModel } from '../../models';

export default function transformAbsenceHoursResource(
    absenceHours: AbsenceHoursResource,
): AbsenceHoursViewModel {
    return {
        hours: absenceHours.hours,
        payrollPeriod: absenceHours.payrollPeriod,
        weekNumber: absenceHours.weekNumber,
        waitingDayHours: absenceHours.waitingDayHours,
    };
}
