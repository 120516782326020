import { addSeconds, formatDate, isEqualOrWithin24Hours } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import { LeaveOfAbsenceViewModel } from '../../models';

const getLeaveOfAbsenceTooltipText = (loa: LeaveOfAbsenceViewModel, isRequest?: boolean): string => {
    const { reason } = loa;
    const format = 'EEEE dd-MM';
    const start = formatDate(loa.start, format);
    // Deduct 1 second to prevent 00:00 dates to show extra day
    const end = formatDate(addSeconds(loa.end.date, -1), format);
    const type = translate(`pages.absences.leaveOfAbsence${isRequest ? 'Request' : ''}`);

    if (isEqualOrWithin24Hours(loa.start, loa.end.date)) {
        return translate('pages.absences.leaveOfAbsenceOnDateFromTo', {
            type,
            start: formatDate(loa.start, 'HH:mm'),
            end: formatDate(loa.end.date, 'HH:mm'),
            reason,
            date: start,
        });
    }

    return translate('pages.absences.leaveOfAbsenceFromTo', {
        type,
        start,
        end,
        reason,
    });
};

export default getLeaveOfAbsenceTooltipText;
