import { FC, useMemo } from 'react';

import { Period } from '../../../entities/Period/Period';
import { formatDate } from '../../../helpers/date';
import { DateFormat } from '../../../types/dateFormatTypes';
// Needs direct import because of className specificity
import IconButton from '../IconButton/IconButton';

import './EditTimeButton.scss';

interface EditTimeButtonProps {
    isDisabled?: boolean;
    period: Period;
    onClick: () => void;
    className?: string;
}

const EditTimeButton: FC<EditTimeButtonProps> = ({
    isDisabled = false,
    period,
    onClick,
    className = '',
}) => {
    const time = useMemo(() => (
        `${formatDate(period.start, DateFormat.hoursMinutes)} - ${formatDate(period.end, DateFormat.hoursMinutes)}`
    ), [period]);

    if (isDisabled) {
        return (
            <div className={`edit-time-button edit-time-button--is-disabled ${className}`}>
                {time}
            </div>
        );
    }

    return (
        <IconButton
            icon="edit"
            text={time}
            onClick={onClick}
            className={`edit-time-button ${className}`}
            iconClassName="edit-time-button__icon"
        />
    );
};

export default EditTimeButton;
