import {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Period } from '../../../entities/Period/Period';
import { arePeriodsDeviated } from '../../../entities/Period/PeriodHelpers';
import { ShiftPlanning } from '../../../entities/ShiftPlanning/ShiftPlanning';
import { ShiftsCalendarAbsence } from '../../../entities/ShiftsCalendarAbsence/ShiftsCalendarAbsence';
import { ShiftsCalendarLeaveOfAbsence } from '../../../entities/ShiftsCalendarLeaveOfAbsence/ShiftsCalendarLeaveOfAbsence';
import { ShiftsCalendarUnavailableToWorkTimeSlot } from '../../../entities/ShiftsCalendarUnavailableToWorkTimeSlot/ShiftsCalendarUnavailableToWorkTimeSlot';
import { formatDate } from '../../../helpers/date';
import { ShiftPlanningColor } from '../../../types';
import { DateFormat } from '../../../types/dateFormatTypes';
import { CalendarShiftEmployee } from '../../index';
import { getShiftEmployeeIcons } from './helpers';

interface CalendarShiftPlanningProps {
    exchangeRequests?: boolean;
    shiftExchanges?: boolean;
    isPreview?: boolean;
    showEmploymentType?: boolean;
    absences: ShiftsCalendarAbsence[];
    color: ShiftPlanningColor | null;
    leaveOfAbsences: ShiftsCalendarLeaveOfAbsence[];
    shiftPeriod?: Period;
    shiftPlanning: ShiftPlanning;
    unavailableToWorkTimeSlots: ShiftsCalendarUnavailableToWorkTimeSlot[];
    className?: string;
    employmentTypeClassName?: string;
    iconClassName?: string;
}

const CalendarShiftPlanning: FC<CalendarShiftPlanningProps> = ({
    exchangeRequests,
    shiftExchanges,
    isPreview,
    showEmploymentType = false,
    absences,
    color,
    leaveOfAbsences,
    shiftPeriod,
    shiftPlanning,
    unavailableToWorkTimeSlots,
    className = '',
    employmentTypeClassName = '',
    iconClassName = '',
}) => {
    const [deviatedTime, setDeviatedTimeTime] = useState<string | undefined>(undefined);

    const icons = useMemo(() => (!isPreview ? getShiftEmployeeIcons(
        shiftPlanning,
        absences,
        leaveOfAbsences,
        unavailableToWorkTimeSlots,
    ) : []), [
        isPreview,
        shiftPlanning,
        absences,
        leaveOfAbsences,
        unavailableToWorkTimeSlots,
    ]);

    useEffect(() => {
        const hasDeviatedTime = (!isPreview && !!shiftPeriod) && arePeriodsDeviated(shiftPlanning.period, shiftPeriod);

        if (hasDeviatedTime) {
            const startTime = formatDate(shiftPlanning.period.start, DateFormat.hoursMinutes);
            const endTime = formatDate(shiftPlanning.period.end, DateFormat.hoursMinutes);
            setDeviatedTimeTime(`${startTime} - ${endTime}`);
        }
    }, [isPreview, shiftPlanning, shiftPeriod]);

    return (
        <CalendarShiftEmployee
            exchangeRequests={exchangeRequests}
            shiftExchanges={shiftExchanges}
            isCompanyMedic={shiftPlanning.user.companyMedic}
            showEmploymentType={showEmploymentType}
            color={color}
            icons={icons}
            name={shiftPlanning.user.fullName}
            deviatedTime={deviatedTime}
            employmentTypeSlug={shiftPlanning.user.employmentType?.slug}
            className={className}
            employmentTypeClassName={employmentTypeClassName}
            iconClassName={iconClassName}
        />
    );
};

export default CalendarShiftPlanning;
