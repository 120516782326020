import React, { FC, useEffect, useState } from 'react';

import { Table } from 'reactstrap';

import FixedTableHeader from '../../../../components/FixedTableHeader/FixedTableHeader';
import { UserWithHourBalances } from '../../../../models/UserWithHourBalances';
import UsersWithLoketBalancesHead from './subcomponents/UserWithHourBalancesHead/UserWithHourBalancesHead';
import UserWithHourBalancesRow from './subcomponents/UserWithHourBalancesRow/UserWithHourBalancesRow';

import './UsersWithHourBalancesTable.scss';

interface UsersWithHourBalancesTableProps {
    loading: boolean;
    users: UserWithHourBalances[];
}

const UsersWithHourBalancesTable: FC<UsersWithHourBalancesTableProps> = ({
    loading,
    users,
}) => {
    const [userList, setUserList] = useState<UserWithHourBalances[]>(users || []);

    useEffect(() => {
        if (!loading && users.length) {
            setUserList(users);
        }
    }, [users, loading]);

    return (
        <div className="users-with-loket-balances-table table-container-responsive">
            <div className="table-container">
                <div>
                    <Table>
                        <UsersWithLoketBalancesHead />
                        <tbody>
                            {userList.map((user: UserWithHourBalances) => (
                                <UserWithHourBalancesRow
                                    key={user.userId}
                                    {...user}
                                />
                            )) }
                        </tbody>
                    </Table>
                    <FixedTableHeader className="users-with-loket-balances-table__fixed-table-header">
                        <Table>
                            <UsersWithLoketBalancesHead />
                        </Table>
                    </FixedTableHeader>
                </div>
            </div>
        </div>
    );
};

export default UsersWithHourBalancesTable;
