import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { Experience, ExperienceResource } from './Experience';
import { transformToExperience } from './ExperienceTransformers';

export const getExperiencesApiCall = async (): Promise<FetchCollectionResult<Experience[], string>> => {
    try {
        const url = generateApiUrl({ endpoint: '/experiences' });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<ExperienceResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const experiences = doc.data.map(transformToExperience);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: experiences,
        };
    } catch (error) {
        console.error('[getExperiencesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
