import { getUniqueArrayList } from '../../../helpers/array';
import { FormOption, FormOptionsGroup } from '../../../types';

export interface GroupedFormOptions {
    options: FormOption[];
    groups: FormOptionsGroup[];
}

export const getFormGroups = (formOptions: FormOption[]): FormOptionsGroup[] => {
    const groups = formOptions
        .filter(option => !!option.group)
        .map(option => option.group);

    return getUniqueArrayList(groups, 'value') as FormOptionsGroup[];
};

export const generateGroupedDepartmentOptions = (formOptions: FormOption[]): GroupedFormOptions => {
    const formGroups = getFormGroups(formOptions);

    const groups = formGroups
        .map(group => ({
            label: group.label,
            value: group.value,
            options: formOptions
                .filter(option => option.group?.value === group.value)
                .sort((optionA, optionB) => (optionA.label).localeCompare(optionB.label))
                .map(option => ({
                    label: option.label,
                    value: option.value,
                    isChecked: option.isChecked,
                })),
        }))
        .sort((optionA, optionB) => (optionA.label).localeCompare(optionB.label));

    const options = formOptions
        .filter(option => !option.group)
        .sort((optionA, optionB) => (optionA.label).localeCompare(optionB.label))
        .map(option => ({
            label: option.label,
            value: option.value,
            isChecked: option.isChecked,
        }));

    return {
        groups,
        options,
    };
};

export const getCheckedDepartmentFormOptions = (departmentIds: string[], departments: FormOption[]): FormOption[] => departments.map(department => ({
    ...department,
    isChecked: departmentIds.includes(department.value),
}));

