import { PAGINATION_SET_NUMBER, PAGINATION_SET_PAGINATION, PAGINATION_SET_SIZE } from '../actionTypes';

const initialState = {
    number: 1,
    size: 2,
    pages: 1,
};

const paginationReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAGINATION_SET_NUMBER:
            return { ...state, number: action.number };
        case PAGINATION_SET_SIZE:
            return { ...state, size: action.size };
        case PAGINATION_SET_PAGINATION:
            return {
                ...state, number: action.number || state.number, size: action.size, pages: action.pages,
            };
        default:
            return state;
    }
};

export default paginationReducer;
