import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { KnowledgeBasePage, PzLogsPage } from '../../@paco/pages';
import Feedback from '../Satisfaction/Feedback';
import CompletePeriodDetail from './CompletePeriodDetail/CompletePeriodDetail';
import CompletePeriods from './CompletePeriods';
import { PZRoutes } from './PZRoutes';
import SharedDocuments from './SharedDocuments';
import Synchronizations from './Synchronizations';

import './PZ.scss';

const LegacyPzRouteGroup = (props) => (
    <Routes>
        <Route path={PZRoutes.knowledgeBase()} element={<KnowledgeBasePage />} />
        <Route
            exact
            path={PZRoutes.satisfaction()}
            element={<Feedback {...props} />}
        />
        <Route
            exact
            path={PZRoutes.completePeriods()}
            element={<CompletePeriods />}
        />
        <Route
            exact
            path={PZRoutes.completePeriodDetail(':id')}
            element={<CompletePeriodDetail />}
        />
        <Route
            exact
            path={PZRoutes.documents()}
            element={<SharedDocuments />}
        />
        <Route
            exact
            path={PZRoutes.synchronizations()}
            element={<Synchronizations />}
        />
        <Route
            exact
            path={PZRoutes.logs()}
            element={<PzLogsPage />}
        />
    </Routes>
);

LegacyPzRouteGroup.propTypes = {
    permissions: PropTypes.array.isRequired,
    userRoutes: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        permissions: state.authenticatedUserReducer.permissions,
        userRoutes: state.authenticatedUserReducer.permittedUserRoutes,
    };
}

export default connect(mapStateToProps)(LegacyPzRouteGroup);
