import axios, { AxiosResponse } from 'axios';

import { formatDate } from '../../helpers/date';
import { UnavailableToWorkTimeSlot } from '../../models';
import { UnavailableToWorkTimeSlotResource, UnavailableToWorkTimeSlotViewModel } from './UnavailableToWorkTimeSlot';
import { transformUnavailableToWorkTimeSlot } from './UnavailableToWorkTimeSlotTransformer';

export const getUnavailableWorkTimeSlots = async (
    startDate: Date,
    endDate: Date,
    userUuids: string[] = [],
    includes: string[] = [],
): Promise<UnavailableToWorkTimeSlotViewModel[]> => {
    const start = formatDate(startDate, 'yyyy-MM-dd');
    const end = formatDate(endDate, 'yyyy-MM-dd');
    const url = new URL(`${process.env.REACT_APP_API_URL}/unavailable-to-work-time-slots`);

    url.searchParams.append('startDate', start);
    url.searchParams.append('endDate', end);

    const response = await axios.get<UnavailableToWorkTimeSlotResource[]>(String(url), {
        params: {
            orderByStart: JSON.stringify({ start: 'DESC' }),
            ...(userUuids.length && { userUuids: JSON.stringify(userUuids) }),
            include: includes.join(','),
        },
    });

    return response.data.map((unavailableToWorkTimeSlot) => transformUnavailableToWorkTimeSlot(unavailableToWorkTimeSlot));
};

export const getUnavailableWorkTimeSlotsLegacy = async (
    startDate: Date,
    endDate: Date,
    userUuids: string[] = [],
    includes: string[] = [],
): Promise<AxiosResponse<UnavailableToWorkTimeSlot[]>> => {
    const start = formatDate(startDate, 'yyyy-MM-dd');
    const end = formatDate(endDate, 'yyyy-MM-dd');
    const url = new URL(`${process.env.REACT_APP_API_URL}/unavailable-to-work-time-slots`);

    url.searchParams.append('startDate', start);
    url.searchParams.append('endDate', end);

    return axios.get<UnavailableToWorkTimeSlot[]>(String(url), {
        params: {
            orderByStart: JSON.stringify({ start: 'DESC' }),
            ...(userUuids.length && { userUuids: JSON.stringify(userUuids) }),
            include: includes.join(','),
        },
    });
};
