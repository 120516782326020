import React, { FC, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingSpinner } from '../../../../@paco/components';
import { startOfYear } from '../../../../@paco/helpers/date';
import { useTypedSelector } from '../../../../@paco/redux/store';
import { ScheduleRoutes } from '../../../../@paco/routes/helpers';
import { PrivateRouteGroupRoutes } from '../../../../@paco/routes/routes';
import addDatesToBaseScheduleShift from '../../../../entities/BaseScheduleShift/helpers/addDatesToBaseScheduleShift';
import { checkPermission } from '../../../../helpers';
import { getDaysOfWeek } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import { EditShiftFormData, EditShiftPlanningFormData, ShiftPlanningViewModel } from '../../../../models';
import { deleteBaseScheduleShift, editBaseScheduleShift, fetchBaseScheduleShift } from '../../../../redux/@toolkit/baseScheduleShift/baseScheduleShiftActions';
import { setIsSuccessful } from '../../../../redux/@toolkit/baseScheduleShift/baseScheduleShiftReducer';
import {
    deleteBaseScheduleShiftPlanning,
    editBaseScheduleShiftPlanning,
    fetchEmployeeOptions,
    planUserToBaseScheduleShift,
    setBaseScheduleShiftPlanningColor,
} from '../../../../redux/@toolkit/baseScheduleShiftPlanning/baseScheduleShiftPlanningActions';
import { Reducers } from '../../../../redux/reducers';
import { TypedDispatch } from '../../../../redux/store';
import ErrorPage from '../../../ErrorPage';
import ShiftDetail from '../../containers/ShiftDetail/ShiftDetail';


const ConnectedBaseScheduleShiftDetail: FC = () => {
    const navigate = useNavigate();
    const { id: baseScheduleShiftId } = useParams<{ id: string }>();
    const dispatch = useDispatch<TypedDispatch>();

    const { isLoading, isSuccessful, baseScheduleShift } = useSelector((state: Reducers) => state.baseScheduleShiftReducer);
    const { isLoading: isLoadingPlanning } = useSelector((state: Reducers) => state.baseScheduleShiftPlanningReducer);
    const { permissions } = useTypedSelector(state => state.authenticatedUserReducer);

    const canDeleteBaseScheduleShifts = checkPermission(permissions, 'delete-all-base-schedule-shifts');
    const canAddBaseScheduleShiftPlannings = checkPermission(permissions, 'add-new-base-schedule-shift-plannings');
    const canEditBaseScheduleShiftPlannings = checkPermission(permissions, 'edit-all-base-schedule-shift-plannings');
    const canDeleteBaseScheduleShiftPlannings = checkPermission(permissions, 'delete-all-base-schedule-shift-plannings');

    const selectedDays = useMemo(() => getDaysOfWeek(startOfYear(new Date())), []);
    const shiftWithDates = useMemo(
        () => (baseScheduleShift ? addDatesToBaseScheduleShift(baseScheduleShift, selectedDays) : undefined),
        [baseScheduleShift, selectedDays],
    );

    const handleEmployeeSelect = (userId: string) => {
        if (baseScheduleShift) dispatch(planUserToBaseScheduleShift(baseScheduleShift, userId));
    };

    const handleShiftPlanningColorClick = (shiftPlanning: ShiftPlanningViewModel, color?: string) => {
        if (baseScheduleShift) dispatch(setBaseScheduleShiftPlanningColor(baseScheduleShift, shiftPlanning, color));
    };

    const handleDeleteShiftPlanningClick = (shiftPlanning: ShiftPlanningViewModel) => {
        if (baseScheduleShift) dispatch(deleteBaseScheduleShiftPlanning(baseScheduleShift, shiftPlanning.id));
    };

    const handleSubmitEditShiftPlanning = (formData: EditShiftPlanningFormData, shiftPlanning: ShiftPlanningViewModel) => {
        if (baseScheduleShift) dispatch(editBaseScheduleShiftPlanning(baseScheduleShift, shiftPlanning.id, formData));
    };

    const handleDeleteButtonClick = () => {
        if (baseScheduleShift) dispatch(deleteBaseScheduleShift(baseScheduleShift.id));
    };

    const handleSave = (formData: EditShiftFormData) => {
        if (baseScheduleShift) dispatch(editBaseScheduleShift(baseScheduleShift, formData));
    };

    const handleCancel = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (baseScheduleShift?.department && baseScheduleShift?.start) {
            dispatch(fetchEmployeeOptions(baseScheduleShift.department.id));
        }
    }, [baseScheduleShift]);

    useEffect(() => {
        if (baseScheduleShiftId) {
            dispatch(fetchBaseScheduleShift(baseScheduleShiftId));
        }
    }, [baseScheduleShiftId]);

    useEffect(() => {
        if (baseScheduleShiftId && isSuccessful) {
            dispatch(setIsSuccessful(false));

            if (baseScheduleShift?.baseScheduleId) {
                navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.baseSchedule(baseScheduleShift.baseScheduleId)}`);
            } else {
                navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.baseSchedules()}`);
            }
        }
    }, [isSuccessful]);

    if (!shiftWithDates && isLoading) {
        return (
            <LoadingSpinner />
        );
    }

    if (!shiftWithDates) {
        return (
            <ErrorPage errorCode={404} />
        );
    }

    return (
        <ShiftDetail
            isLoading={isLoading}
            isBaseScheduleShift
            isEmployeeLoading={isLoadingPlanning}
            canDeleteShift={canDeleteBaseScheduleShifts}
            canAddShiftPlannings={canAddBaseScheduleShiftPlannings}
            canEditShiftPlannings={canEditBaseScheduleShiftPlannings}
            canDeleteShiftPlannings={canDeleteBaseScheduleShiftPlannings}
            title={translate('common.editEntity', { entity: translate('pages.shifts.baseScheduleShift') })}
            saveButtonTitle={translate('common.save')}
            selectedDays={selectedDays}
            shift={shiftWithDates}
            onEmployeeSelect={handleEmployeeSelect}
            onShiftPlanningColorClick={handleShiftPlanningColorClick}
            onDeleteShiftPlanningClick={handleDeleteShiftPlanningClick}
            onSubmitEditShiftPlanning={handleSubmitEditShiftPlanning}
            onDeleteButtonClick={handleDeleteButtonClick}
            onSaveButtonClick={handleSave}
            onCancel={handleCancel}
        />
    );
};

export default ConnectedBaseScheduleShiftDetail;
