import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../../models';

const IconExit: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 15 15" fill="none" className={`icon-svg ${className}`}>
        {/* eslint-disable-next-line max-len */}
        <path d="M10 6.50001L6.66667 3.27421V5.69356H0V7.30647H6.66667V9.72582L10 6.50001ZM15 11.3387V1.6613C15 0.766143 14.25 0.0484009 13.3333 0.0484009H3.33333C2.89131 0.0484009 2.46738 0.218331 2.15482 0.520809C1.84226 0.823287 1.66667 1.23354 1.66667 1.6613V4.08066H3.33333V1.6613H13.3333V11.3387H3.33333V8.91937H1.66667V11.3387C1.66667 11.7665 1.84226 12.1767 2.15482 12.4792C2.46738 12.7817 2.89131 12.9516 3.33333 12.9516H13.3333C13.7754 12.9516 14.1993 12.7817 14.5118 12.4792C14.8244 12.1767 15 11.7665 15 11.3387Z" />
    </svg>
);

export default IconExit;
