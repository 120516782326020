import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedDistrictDivisionTemplateDetail } from '../../connectors';

const DistrictDivisionTemplateDetailPage: FC = (): ReactElement => (
    <Page>
        <ConnectedDistrictDivisionTemplateDetail />
    </Page>
);

export default DistrictDivisionTemplateDetailPage;
