import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Modal } from 'reactstrap';

import { getPacoPayrollPeriods } from '../../@paco/redux/payrollPeriods/payrollPeriodsActions';
import ButtonAuthenticate from '../../components/ButtonAuthenticate/ButtonAuthenticate';
import DeleteResourceForm from '../../components/forms/DeleteResourceForm/DeleteResourceForm';
import LogsModal from '../../components/Logs/LogsModal';
import { TRACK_TYPE_OPEN } from '../../constants';
import { checkPermission } from '../../helpers';
import { translate } from '../../helpers/translations/translator';
import { withRouter } from '../../helpers/WithRouter';
import { clearResourceLogs, getResourceLogs } from '../../redux/logs/logsActions';
import {
    addTrack,
    deleteTrack,
    getTracksByUserAndDate,
    updateTrack,
} from '../../redux/tracks/tracksActions';
import { getEmployees } from '../../redux/users/usersActions';
import FinishedTracks from './FinishedTracks';
import AddTrack from './forms/AddTrack';
import EditTrackLegacy from './forms/EditTrackLegacy';
import { getEditTrackFormTitle } from './helpers';
import OpenTracks from './OpenTracks';
import ReviewTracks from './ReviewTracks';
import { TrackRoutes } from './TrackRoutes';

import './Tracks.scss';


class LegacyTracksRouteGroup extends Component {
    state = {
        addTrackForm: false,
        editTrackForm: false,
        editTrackData: null,
        editTrackType: null,
        deleteTrackId: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getEmployees(
            undefined,
            undefined,
            undefined,
            true,
        ));
        dispatch(clearResourceLogs());
        dispatch(getPacoPayrollPeriods());
    }

    addTrack = () => {
        this.toggleAddTrack(true);
    };

    deleteTrack = (id) => {
        this.toggleDeleteTrack(false, id);
    };

    reviewTrack = (id, data, approved) => {
        const { dispatch } = this.props;
        dispatch(updateTrack(id, data, approved));
        this.setState({ editTrackForm: false });
    };

    toggleAddTrack = (active) => {
        const { addTrackForm } = this.state;
        this.setState({
            addTrackForm: active || !addTrackForm,
        });
    };

    toggleDeleteTrack = (active, id) => {
        const { deleteTrackForm } = this.state;
        this.setState({
            deleteTrackId: id,
            deleteTrackForm: active || !deleteTrackForm,
        });
    };

    dispatchOpenLogsModal = () => {
        const { dispatch } = this.props;
        const { editTrackData } = this.state;
        if (editTrackData) {
            dispatch(getResourceLogs(editTrackData.id, 'tracks'));
        }
    };

    dispatchCloseLogsModal = () => {
        const { dispatch } = this.props;
        dispatch(clearResourceLogs());
    };

    activeReviewForm = (track, type) => {
        this.setState({
            editTrackForm: true,
            editTrackData: track,
            editTrackType: type,
        });
    };

    dispatchAddTrack = (data) => {
        const { dispatch } = this.props;
        this.toggleAddTrack(false);
        dispatch(addTrack(data));
    };

    dispatchDeleteTrack = () => {
        const { dispatch } = this.props;
        const { deleteTrackId } = this.state;
        this.toggleDeleteTrack(false);
        dispatch(deleteTrack(deleteTrackId));
    };

    dispatchGetTracksForUserOnDate = (userId, date) => {
        const { dispatch } = this.props;
        dispatch(getTracksByUserAndDate(userId, date));
    };

    render() {
        const {
            addTrackForm,
            editTrackForm,
            editTrackData,
            editTrackType,
            deleteTrackForm,
        } = this.state;
        const {
            departments,
            currentUser,
            permissions,
            settings,
            userTracksOnDate,
            logsLoading,
            payrollPeriods,
            users = [],
            logs = null,
        } = this.props;

        const propsWithDefaults = {
            users: [],
            logs: null,
            ...this.props,
        };

        const canViewLogs = checkPermission(permissions, 'view-all-logs', 'tracks');
        const canAddTracks = checkPermission(permissions, 'add-new-tracks', 'tracks')
            || checkPermission(permissions, 'add-new-manager-tracks', 'tracks')
            || checkPermission(permissions, 'add-new-junior-manager-tracks', 'tracks')
            || checkPermission(permissions, 'add-new-employee-tracks', 'tracks');
        const trackTranslation = translate('common.track');
        const editTrackTitle = getEditTrackFormTitle(editTrackType);

        return (
            <>
                <Routes>
                    <Route
                        path={TrackRoutes.open()}
                        element={(
                            <OpenTracks
                                {...propsWithDefaults}
                                onAddTrackClick={this.addTrack}
                                onReviewTrackClick={this.activeReviewForm}
                                canAddTracks={canAddTracks}
                            />
                        )}
                    />
                    <Route
                        path={TrackRoutes.review()}
                        element={(
                            <ReviewTracks
                                {...propsWithDefaults}
                                onAddTrackClick={this.addTrack}
                                onReviewTrackClick={this.activeReviewForm}
                                canAddTracks={canAddTracks}
                            />
                        )}
                    />
                    <Route
                        path={TrackRoutes.finished()}
                        element={(
                            <FinishedTracks
                                {...propsWithDefaults}
                                onAddTrackClick={this.addTrack}
                                onReviewTrackClick={this.activeReviewForm}
                                onDeleteTrackClick={this.deleteTrack}
                                canAddTracks={canAddTracks}
                            />
                        )}
                    />
                    <Route path="/" element={<Navigate to={TrackRoutes.open()} />} />
                </Routes>
                <Modal size="lg" isOpen={addTrackForm}>
                    <AddTrack
                        departments={departments}
                        users={users}
                        permissions={permissions}
                        onSubmit={this.dispatchAddTrack}
                        onCancel={() => this.toggleAddTrack(false)}
                        onEmployeeSelect={this.dispatchGetTracksForUserOnDate}
                        userTracksOnDate={userTracksOnDate}
                        payrollPeriods={payrollPeriods}
                        currentUserId={currentUser.id}
                    />
                </Modal>
                <Modal size="lg" isOpen={editTrackForm} className="form-edit-track">
                    <EditTrackLegacy
                        canEdit={editTrackType !== TRACK_TYPE_OPEN}
                        permissions={permissions}
                        settings={settings}
                        title={editTrackTitle}
                        onSubmit={this.reviewTrack}
                        onCancel={() => this.setState({ editTrackForm: false })}
                        payrollPeriods={payrollPeriods}
                        onShowLogsClick={canViewLogs ? this.dispatchOpenLogsModal : null}
                        track={editTrackData}
                    />
                </Modal>
                <Modal size="md" isOpen={deleteTrackForm}>
                    <DeleteResourceForm
                        title={translate('common.deleteResource', { resource: trackTranslation })}
                        intro={translate('common.deleteResourceIntro', { resource: trackTranslation.toLowerCase() })}
                        onSubmit={this.dispatchDeleteTrack}
                        onCancel={() => this.toggleDeleteTrack(false)}
                    />
                </Modal>
                <LogsModal
                    isOpen={logsLoading || !!logs}
                    loading={logsLoading}
                    title={translate('logs.trackLog')}
                    logs={logs}
                    onClose={this.dispatchCloseLogsModal}
                />
                {canAddTracks && (
                    <ButtonAuthenticate
                        onClick={this.addTrack}
                        mobile
                    />
                )}
            </>
        );
    }
}

LegacyTracksRouteGroup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    tracks: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    timeMode: PropTypes.string.isRequired,
    pagination: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    currentUserRole: PropTypes.object.isRequired,
    exportCSVRequest: PropTypes.bool.isRequired,
    users: PropTypes.array,
    departments: PropTypes.array.isRequired,
    departmentOptions: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    settings: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    employmentTypes: PropTypes.array.isRequired,
    payrollPeriodsLoading: PropTypes.bool.isRequired,
    userTracksOnDate: PropTypes.array.isRequired,
    absences: PropTypes.array.isRequired,
    absencesLoading: PropTypes.bool.isRequired,
    logs: PropTypes.array,
    logsLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        tracks: state.tracksReducer.tracks,
        loading: state.tracksReducer.loading,
        exportCSVRequest: state.tracksReducer.exportCSVRequest,
        payrollPeriods: state.pacoPayrollPeriodsReducer.payrollPeriods,
        payrollPeriodsLoading: state.appReducer.payrollPeriodsLoading,
        currentUser: state.authenticatedUserReducer.user,
        currentUserRole: state.authenticatedUserReducer.role,
        users: state.usersReducer.employees,
        filter: state.filterReducer.filter,
        timeMode: state.weekNavigatorReducer.mode,
        pagination: state.paginationReducer,
        departments: state.authenticatedUserReducer.departments,
        departmentOptions: state.globalFiltersReducer.departmentOptions,
        permissions: state.authenticatedUserReducer.permissions,
        settings: state.pacoSettingsReducer.settings,
        employmentTypes: state.appReducer.employmentTypes,
        userTracksOnDate: state.tracksReducer.userTracksOnDate,
        absences: state.absencesReducer.absencesInDateRange,
        absencesLoading: state.absencesReducer.loading,
        logs: state.logsReducer.logs,
        logsLoading: state.logsReducer.loading,
    };
}

export default withRouter(connect(mapStateToProps)(LegacyTracksRouteGroup));
