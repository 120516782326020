import {
    downloadDropboxFileApiCall,
    getDropboxApiCall,
    getDropboxEntitiesApiCall,
    getDropboxFoldersApiCall,
} from '../../entities/DropboxEntity/DropboxEntityService';
import { isFetchCollectionResultSuccessful, isFetchResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import {
    DropboxResultType,
    setActiveDirectoryId,
    setActiveResultType,
    setDropboxEntities,
    setDropboxFolders,
    setDropboxSearchFiles,
    setError,
    setIsDownloadFailed,
    setIsFoldersLoading,
    setIsLoading,
    setIsSearchLoading,
} from './dropboxReducer';

export const fetchDropboxEntities = (id?: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getDropboxEntitiesApiCall(id);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDropboxEntities(response.data));
    } catch (error) {
        console.error('[fetchDropboxEntities]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const downloadDropboxFile = (id: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsDownloadFailed(false));
    dispatch(setError(''));

    try {
        const response = await downloadDropboxFileApiCall(id);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            dispatch(setIsDownloadFailed(true));
        }
    } catch (error) {
        dispatch(setIsDownloadFailed(true));
        console.error('[downloadDropboxFile]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchDropboxFolders = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsFoldersLoading(true));
    dispatch(setActiveResultType(DropboxResultType.folder));
    dispatch(setError(''));

    try {
        const response = await getDropboxFoldersApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDropboxSearchFiles([]));
        dispatch(setDropboxFolders(response.data));
    } catch (error) {
        console.error('[fetchDropboxFolders]', error);
    }

    dispatch(setIsFoldersLoading(false));
};

export const fetchDropboxSearchFiles = (search?: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsSearchLoading(true));
    dispatch(setActiveResultType(DropboxResultType.search));
    dispatch(setError(''));

    try {
        const response = await getDropboxApiCall(search);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDropboxSearchFiles(response.data));
    } catch (error) {
        console.error('[fetchDropboxSearchFiles]', error);
    }

    dispatch(setIsSearchLoading(false));
};

export const changeActiveDirectoryId = (activeDirectoryId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setError(''));

    try {
        dispatch(setActiveDirectoryId(activeDirectoryId));
    } catch (error) {
        console.error('[changeActiveDirectoryId]', error);
    }
};
