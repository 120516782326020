import { FC, ReactElement } from 'react';

import Page from '../../components/Page/Page';
import { ConnectedUserDetail } from '../../connectors';

import './UserDetailPage.scss';

const UserDetailPage: FC = (): ReactElement => (
    <Page className="user-detail-page">
        <ConnectedUserDetail />
    </Page>
);

export default UserDetailPage;
