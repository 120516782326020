import React, { FC } from 'react';

import './UserColor.scss';

interface UserColorProps {
    color: string | null;
    className?: string;
    onFocus?: (color: string | null) => void;
    size: 'tiny' | 'small' | 'large';
}

const UserColor: FC<UserColorProps> = ({
    className,
    color,
    size,
    ...props
}) => {
    const onFocus = () => {
        if (props.onFocus) props.onFocus(color);
    };

    const renderCircle = () => (
        <div
            data-testid="user-color-circle"
            className="user-color__circle"
            style={color ? { background: `#${color}` } : {}}
        />
    );

    if (!props.onFocus) {
        return (
            <div className={`user-color user-color--${size} ${className || ''}`}>
                {renderCircle()}
            </div>
        );
    }

    return (
        <button
            type="button"
            onClick={onFocus}
            className={
                `user-color
                user-color--${size}
                ${!color ? 'user-color--blank' : ''}
                ${className || ''}`
            }
        >
            {renderCircle()}
        </button>
    );
};

export default UserColor;
