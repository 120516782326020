import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { DropboxEntity, DropboxFolder } from './DropboxEntity';
import { isDropboxResource } from './DropboxEntityHelpers';
import { transformToDropboxEntity, transformToDropboxFolder } from './DropboxEntityTransformers';

export const getDropboxEntitiesApiCall = async (id?: string): Promise<FetchCollectionResult<DropboxEntity[], string>> => {
    try {
        const endpoint = id ? '/dropbox/scan' : '/dropbox';
        const url = generateApiUrl({
            endpoint,
            queryParams: {
                ...(id && { path: `id:${id}` }),
            },
        });

        const response = await authorizedFetch(url);

        const data: any = await response.json();

        if (!(Array.isArray(data) && data.map(isDropboxResource))) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const entities = data.map(transformToDropboxEntity);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: entities,
        };
    } catch (error) {
        console.error('[getDropboxEntitiesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const downloadDropboxFileApiCall = async (id: string): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/dropbox/download?path=id:${id}` });

        const response = await authorizedFetch(url);

        const blob = await response.blob();

        if (!response.ok || !blob) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const file = window.URL.createObjectURL(blob);

        window.open(file, '_blank');

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[downloadDropboxFileApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};


let getDropboxFoldersApiCallAbortController = new AbortController();

export const getDropboxFoldersApiCall = async (): Promise<FetchCollectionResult<DropboxFolder[], string>> => {
    getDropboxFoldersApiCallAbortController.abort();
    getDropboxFoldersApiCallAbortController = new AbortController();

    try {
        const url = generateApiUrl({ endpoint: '/dropbox/tree' });

        const response = await authorizedFetch(url, {
            signal: getDropboxFoldersApiCallAbortController.signal,
        });

        const data: any = await response.json();

        if (!(Array.isArray(data) && data.map(isDropboxResource))) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const folders = data.map(transformToDropboxFolder);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: folders[0]?.children || [],
        };
    } catch (error) {
        console.error('[getDropboxFoldersApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

let getDropboxApiCallAbortController = new AbortController();

export const getDropboxApiCall = async (search = ''): Promise<FetchCollectionResult<DropboxEntity[], string>> => {
    getDropboxApiCallAbortController.abort();
    getDropboxApiCallAbortController = new AbortController();

    try {
        const url = generateApiUrl({
            endpoint: '/dropbox',
            queryParams: {
                search,
            },
        });

        const response = await authorizedFetch(url, {
            signal: getDropboxApiCallAbortController.signal,
        });

        const data: any = await response.json();

        if (!(Array.isArray(data) && data.map(isDropboxResource))) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const resources: DropboxEntity[] = data.map(transformToDropboxEntity);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: resources,
        };
    } catch (error) {
        console.error('[getDropboxApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
