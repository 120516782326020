import { FC, ReactElement } from 'react';

import { Button } from '../../components';
import trans from '../../helpers/trans';

import './InactiveUser.scss';

interface InactiveUserProps {
    onLogoutButtonClick: () => void;
    className?: string;
}

const InactiveUser: FC<InactiveUserProps> = ({ onLogoutButtonClick, className = '' }): ReactElement => (
    <div className={`inactive-user ${className}`}>
        <h2 className="inactive-user__title">
            {trans('containers.inactiveUser.title')}
        </h2>

        <p className="inactive-user__intro">
            {trans('containers.inactiveUser.intro')}
        </p>

        <p className="inactive-user__contact">
            {trans('containers.inactiveUser.contact')}
        </p>

        <Button
            text={trans('common.logout')}
            onClick={onLogoutButtonClick}
            className="inactive-user__logout-button"
        />
    </div>
);

export default InactiveUser;
