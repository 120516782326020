import { unique } from 'underscore';

import { DepartmentGroupResource, DepartmentViewModel } from '../../../../entities/Departments/Departments';
import { GroupSize } from '../../SelectDepartments/models/GroupSize';

function getDepartmentGroupSize(allDepartments: DepartmentViewModel[]): { [id: string]: number } {
    return allDepartments.reduce((total: { [name: string]: number }, department) => {
        const { group } = department;

        if (!group) {
            return total;
        }

        const { id } = group;

        return {
            ...total,
            [id]: (total[id] || 0) + 1,
        };
    }, {});
}

export default function getDepartmentGroupSizes(
    departments: DepartmentViewModel[],
    allDepartments: DepartmentViewModel[],
    mainDepartment: DepartmentViewModel | null,
): { [id: string]: GroupSize } {
    const fullGroupSizes = getDepartmentGroupSize(allDepartments.filter(department => department.id !== (mainDepartment && mainDepartment.id)));
    const currentGroupSizes = getDepartmentGroupSize(departments.filter(department => department.id !== (mainDepartment && mainDepartment.id)));
    const uniqueGroups = unique(allDepartments
        .map(department => department.group)
        .filter(group => !!group), 'id') as DepartmentGroupResource[];

    return Object.keys(fullGroupSizes).reduce((
        total: { [name: string]: GroupSize },
        key: string,
    ) => ({
        ...total,
        [key]: {
            total: fullGroupSizes[key],
            size: currentGroupSizes[key],
            isFull: currentGroupSizes[key] >= fullGroupSizes[key],
            group: uniqueGroups.find(group => group.id === key) as DepartmentGroupResource,
        },
    }), {});
}
