import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useSelector } from 'react-redux';

import { hasUncheckedDivergentEmploymentHours } from '../../../../@paco/entities/Employment/EmploymentHelpers';
import { PayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { getPayrollPeriodFromDate } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformLegacyPayrollPeriodToPayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriodTransformers';
import { PayrollPeriodWeek } from '../../../../@paco/entities/PayrollPeriodWeek/PayrollPeriodWeek';
import { addArrayLengths } from '../../../../@paco/helpers/array';
import useCheckPermission from '../../../../@paco/helpers/hooks/useCheckPermission';
import trans from '../../../../@paco/helpers/trans';
import { togglePayrollPeriodLock, togglePayrollPeriodWeekLock } from '../../../../@paco/redux/completePeriods/completePeriodsActions';
import {
    setIsLockPayrollPeriodSuccessful,
    setIsLockPayrollPeriodWeekSuccessful,
    setIsUnlockPayrollPeriodSuccessful,
    setIsUnlockPayrollPeriodWeekSuccessful,
    setLastActivePeriodId,
    setOpenTasksInitialized,
} from '../../../../@paco/redux/completePeriods/completePeriodsReducer';
import { getPacoPayrollPeriods } from '../../../../@paco/redux/payrollPeriods/payrollPeriodsActions';
import { useTypedSelector } from '../../../../@paco/redux/store';
import { setToast } from '../../../../@paco/redux/toasts/toastsReducer';
import { exportTracks } from '../../../../@paco/redux/tracks/tracksActions';
import { fetchUsersWithDivergentEmploymentHours } from '../../../../@paco/redux/usersWithDivergentEmploymentHours/usersWithDivergentEmploymentHoursActions';
import { setUsersWithDivergentEmploymentHours } from '../../../../@paco/redux/usersWithDivergentEmploymentHours/usersWithDivergentEmploymentHoursReducer';
import { ToastType } from '../../../../@paco/types';
import { TrackExportType } from '../../../../@paco/types/trackExportType';
import { fetchOpenTracks } from '../../../../redux/@toolkit/@tracks/openTracks/openTracksActions';
import { fetchTracksToReview } from '../../../../redux/@toolkit/@tracks/tracksToReview/tracksToReviewActions';
import { fetchIncompleteAbsences } from '../../../../redux/@toolkit/absences/absencesActions';
import { fetchIncompleteLeaveOfAbsences } from '../../../../redux/@toolkit/leaveOfAbsences/incompleteLeaveOfAbsences/incompleteLeaveOfAbsencesActions';
import { fetchOpenLeaveOfAbsences } from '../../../../redux/@toolkit/leaveOfAbsences/openLeaveOfAbsences/openLeaveOfAbsencesActions';
import { Reducers } from '../../../../redux/reducers';
import { useTypedDispatch } from '../../../../redux/store';
import { syncTvt } from '../../../../redux/tracks/tracksActions';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../../../services/PayrollPeriodService/transformPayrollPeriodLegacy';
import CompletePeriods from '../../containers/CompletePeriods/CompletePeriods';

const ConnectedCompletePeriods: FC = () => {
    const dispatch = useTypedDispatch();

    const { departments } = useTypedSelector(state => state.authenticatedUserReducer);
    const {
        isLoading: isCompletePeriodsLoading,
        isLockPayrollPeriodSuccessful,
        isLockPayrollPeriodWeekSuccessful,
        isUnlockPayrollPeriodSuccessful,
        isUnlockPayrollPeriodWeekSuccessful,
        openTasksInitialized,
        lastActivePeriodId,
        lastUpdatedPayrollPeriod,
    } = useTypedSelector(state => state.completePeriodsReducer);

    const { isLoading: isPayrollPeriodsLoading, payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);
    const { isLoading: incompleteLeaveOfAbsencesIsLoading, incompleteLeaveOfAbsences } = useSelector((state: Reducers) => state.incompleteLeaveOfAbsencesReducer);
    const { isLoading: openLeaveOfAbsencesIsLoading, openLeaveOfAbsences } = useSelector((state: Reducers) => state.openLeaveOfAbsencesReducer);
    const { isLoading: absencesIsLoading, absences } = useSelector((state: Reducers) => state.absencesReducerToolkit);
    const { isLoading: tracksToReviewIsLoading, tracksToReview } = useSelector((state: Reducers) => state.tracksToReviewReducer);
    const { isLoading: openTracksIsLoading, openTracks } = useSelector((state: Reducers) => state.openTracksReducer);
    const { isLoading: completePeriodsIsLoading, usersWithDivergentEmploymentHours } = useSelector((state: Reducers) => state.usersWithDivergentEmploymentHoursReducer);

    const [selectedPeriod, setSelectedPeriod] = useState<PayrollPeriod | undefined>(
        payrollPeriods.find(payrollPeriod => payrollPeriod.id === lastActivePeriodId),
    );

    const incompleteUsers = useMemo(
        () => usersWithDivergentEmploymentHours.filter(user => (
            (user.employment && selectedPeriod) ? hasUncheckedDivergentEmploymentHours(user.employment, selectedPeriod) : false)),
        [selectedPeriod, usersWithDivergentEmploymentHours],
    );

    const openPeriodTasks = addArrayLengths([
        incompleteLeaveOfAbsences,
        openLeaveOfAbsences,
        absences,
        tracksToReview,
        openTracks,
        incompleteUsers,
    ]);

    const isLoading = [
        incompleteLeaveOfAbsencesIsLoading,
        openLeaveOfAbsencesIsLoading,
        absencesIsLoading,
        tracksToReviewIsLoading,
        openTracksIsLoading,
        completePeriodsIsLoading,
    ].some(Boolean);

    const canManageLoketData = useCheckPermission('manage-loket-data');

    const handlePayrollPeriodSelect = (period: PayrollPeriod): void => {
        setSelectedPeriod(period);
        dispatch(setOpenTasksInitialized(false));
        dispatch(setLastActivePeriodId(period.id));
    };

    const handleExportTracks = (
        startDate: Date,
        endDate: Date,
        payrollPeriodIds: string[],
        userIds: string[],
        exportType: TrackExportType,
    ): void => {
        dispatch(exportTracks({
            startDate,
            endDate,
            payrollPeriodIds,
            userIds,
            exportType,
        }));
    };

    const handleSyncTvt = (period: PayrollPeriod): void => {
        dispatch(syncTvt(period));
    };

    const handleLockPeriodButtonClick = (): void => {
        if (selectedPeriod) {
            dispatch(togglePayrollPeriodLock(selectedPeriod));
            dispatch(setLastActivePeriodId(selectedPeriod.id));
        }
    };

    const onLockPeriodWeekButtonClick = (periodWeek: PayrollPeriodWeek): void => {
        if (selectedPeriod) {
            dispatch(togglePayrollPeriodWeekLock(selectedPeriod, periodWeek));
        }
    };

    useEffect((): void => {
        const payrollPeriodId = lastActivePeriodId || lastUpdatedPayrollPeriod?.id;
        if (payrollPeriodId && payrollPeriods.length) {
            const newActivePayrollPeriod = payrollPeriods.find(payrollPeriod => payrollPeriod.id === payrollPeriodId);
            setSelectedPeriod(newActivePayrollPeriod);
        }
    }, [payrollPeriods, lastUpdatedPayrollPeriod]);

    useEffect((): void => {
        if (!payrollPeriods.length) {
            dispatch(getPacoPayrollPeriods());
        }
    }, []);

    useEffect((): () => void => {
        if (selectedPeriod && !openTasksInitialized) {
            dispatch(setUsersWithDivergentEmploymentHours([]));
            const legacySelectedPeriod = transformPayrollPeriodToLegacyPayrollPeriod(selectedPeriod);

            dispatch(fetchIncompleteLeaveOfAbsences(legacySelectedPeriod));
            dispatch(fetchOpenLeaveOfAbsences(legacySelectedPeriod, departments));
            dispatch(fetchIncompleteAbsences(legacySelectedPeriod));
            dispatch(fetchTracksToReview(legacySelectedPeriod));
            dispatch(fetchOpenTracks(legacySelectedPeriod));
            dispatch(fetchUsersWithDivergentEmploymentHours(transformLegacyPayrollPeriodToPayrollPeriod(legacySelectedPeriod)));
            dispatch(setOpenTasksInitialized(true));
        }

        return () => {
            dispatch(setOpenTasksInitialized(false));
        };
    }, [selectedPeriod]);

    useEffect((): void => {
        if (!selectedPeriod) {
            const payrollPeriod = getPayrollPeriodFromDate(new Date(), payrollPeriods);
            setSelectedPeriod(payrollPeriod);
        }
    }, [payrollPeriods]);

    useEffect((): void => {
        if (isLockPayrollPeriodSuccessful) {
            dispatch(setToast({
                text: trans('containers.completePeriods.lockPeriodSuccess'),
                type: ToastType.pass,
            }));

            dispatch(setIsLockPayrollPeriodSuccessful(false));
        }
    }, [isLockPayrollPeriodSuccessful]);

    useEffect((): void => {
        if (isLockPayrollPeriodWeekSuccessful) {
            dispatch(setToast({
                text: trans('containers.completePeriods.lockPeriodWeekSuccess'),
                type: ToastType.pass,
            }));

            dispatch(setIsLockPayrollPeriodWeekSuccessful(false));
        }
    }, [isLockPayrollPeriodWeekSuccessful]);

    useEffect((): void => {
        if (isUnlockPayrollPeriodSuccessful) {
            dispatch(setToast({
                text: trans('containers.completePeriods.unlockPeriodSuccess'),
                type: ToastType.pass,
            }));

            dispatch(setIsUnlockPayrollPeriodSuccessful(false));
        }
    }, [isUnlockPayrollPeriodSuccessful]);

    useEffect((): void => {
        if (isUnlockPayrollPeriodWeekSuccessful) {
            dispatch(setToast({
                text: trans('containers.completePeriods.unlockPeriodWeekSuccess'),
                type: ToastType.pass,
            }));

            dispatch(setIsUnlockPayrollPeriodWeekSuccessful(false));
        }
    }, [isUnlockPayrollPeriodWeekSuccessful]);

    return (
        <CompletePeriods
            isLoading={isLoading}
            isPayrollPeriodsLoading={isPayrollPeriodsLoading || isCompletePeriodsLoading}
            canManageLoketData={canManageLoketData}
            activePeriod={selectedPeriod}
            payrollPeriods={payrollPeriods}
            openPeriodTasks={openPeriodTasks}
            onPeriodSelect={handlePayrollPeriodSelect}
            onExportTracks={handleExportTracks}
            onSyncTvt={handleSyncTvt}
            onLockPeriodButtonClick={handleLockPeriodButtonClick}
            onLockPeriodWeekButtonClick={onLockPeriodWeekButtonClick}
        />
    );
};

export default ConnectedCompletePeriods;
