import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { CommentResource } from '../Comment/Comment';
import { transformToComment } from '../Comment/CommentTransformers';
import { DepartmentResource } from '../Department/Department';
import { transformToDepartment } from '../Department/DepartmentTransformers';
import { DistrictDivisionDistrictResource } from '../DistrictDivisionDistrict/DistrictDivisionDistrict';
import {
    AddDistrictDivisionDistrictPlanningFormData,
    DistrictDivisionDistrictPlanning,
    EditDistrictDivisionDistrictPlanningFormData,
} from '../DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { FetchResult, FetchResultType } from '../FetchResult';
import { UserResource } from '../User/User';
import { DistrictDivisionDistrictTemporaryWorkerResource } from './DistrictDivisionDistrictTemporaryWorker';
import {
    transformAddDistrictDivisionTemporaryWorkerFormDataToRequest,
    transformDistrictDivisionDistrictTemporaryWorkerToDistrictDivisionDistrictPlanning,
    transformEditDistrictDivisionTemporaryWorkerOrderFormDataToRequest,
} from './DistrictDivisionDistrictTemporaryWorkerTransformers';

export const postDistrictDivisionDistrictTemporaryWorkerApiCall = async (formData: AddDistrictDivisionDistrictPlanningFormData): Promise<FetchResult<DistrictDivisionDistrictPlanning, string>> => {
    const apiData = transformAddDistrictDivisionTemporaryWorkerFormDataToRequest(formData);

    try {
        const includes = [
            'department',
            'districtDivisionDistrict',
        ];

        const url = generateApiUrl({
            endpoint: '/district-division-district-temporary-workers',
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DistrictDivisionDistrictTemporaryWorkerResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const departmentResource = getIncluded(doc, doc.data, 'department') as DepartmentResource;
        const districtDivisionDistrictResource = getIncluded(doc, doc.data, 'districtDivisionDistrict') as DistrictDivisionDistrictResource;

        const department = transformToDepartment(departmentResource);
        const districtDivisionDistrictPlanning = transformDistrictDivisionDistrictTemporaryWorkerToDistrictDivisionDistrictPlanning(
            doc.data,
            department,
            doc.data.attributes.name,
            doc.data.id,
            districtDivisionDistrictResource.id,
        );

        return {
            status: 200,
            type: FetchResultType.Success,
            data: districtDivisionDistrictPlanning,
        };
    } catch (error) {
        console.error('[postDistrictDivisionDistrictTemporaryWorkerApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteDistrictDivisionDistrictTemporaryWorkerApiCall = async (districtDivisionDistrictTemporaryWorkerId: string): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: `/district-division-district-temporary-workers/${districtDivisionDistrictTemporaryWorkerId}`,
        });

        const response = await authorizedFetch(url, { method: 'DELETE' });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[deleteDistrictDivisionDistrictTemporaryWorkerApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const editDistrictDivisionDistrictTemporaryWorkerOrderApiCall = async (
    formData: EditDistrictDivisionDistrictPlanningFormData,
): Promise<FetchResult<DistrictDivisionDistrictPlanning, string>> => {
    const apiData = transformEditDistrictDivisionTemporaryWorkerOrderFormDataToRequest(formData);

    try {
        const includes = [
            'department',
            'districtDivisionDistrict',
            'comments',
            'comments.owner',
        ];

        const url = generateApiUrl({
            endpoint: `/district-division-district-temporary-workers/${formData.planningId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DistrictDivisionDistrictTemporaryWorkerResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const departmentResource = getIncluded(doc, doc.data, 'department') as DepartmentResource;
        const districtDivisionDistrictResource = getIncluded(doc, doc.data, 'districtDivisionDistrict') as DepartmentResource;
        const commentsResource = (getMultipleIncluded(doc, doc.data, 'comments') || []) as CommentResource[];

        const comments = commentsResource.map(commentResource => {
            const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

            return transformToComment(
                commentResource,
                commentOwnerResource,
            );
        });

        const department = transformToDepartment(departmentResource);
        const districtDivisionDistrictTemporaryWorker = transformDistrictDivisionDistrictTemporaryWorkerToDistrictDivisionDistrictPlanning(
            doc.data,
            department,
            doc.data.attributes.name,
            doc.data.id,
            districtDivisionDistrictResource.id,
            comments,
        );

        return {
            status: 200,
            type: FetchResultType.Success,
            data: districtDivisionDistrictTemporaryWorker,
        };
    } catch (error) {
        console.error('[editDistrictDivisionDistrictTemporaryWorkerOrderApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
