import { DepartmentResource, DepartmentViewModel } from '../entities/Departments/Departments';
import UpToAndIncludingDate from '../helpers/date/UpToAndIncludingDate';
import { CommentResource, CommentViewModel } from './Comment';
import { FeedbackResource, FeedbackViewModel } from './Feedback';
import { Resource } from './Resource';
import { ShiftPlanning } from './ShiftPlanning';
import { UserResource, UserViewModel } from './User';

// TODO: Finish resource and view model, lot of missing props.

export enum MealType {
    NONE = 'NONE',
    HOT = 'HOT',
    BREAD = 'BREAD',
}

export enum TrackType {
    open = 'open',
    review = 'review',
    finished = 'finished',
}

export interface TrackResource extends Resource {
    type: 'tracks';
    accepted: boolean;
    isOffline: boolean;
    assessor?: {
        affix: string;
        firstname: string;
        lastname: string;
    };
    billableStart: string;
    billableEnd: string;
    checkIn: string;
    checkOut: string;
    comments?: CommentResource[];
    department?: DepartmentResource;
    deviation: number;
    deviationRemark?: string;
    feedback: FeedbackResource;
    meal: MealType;
    owner: UserResource;
    pause: number;
    shift?: {
        id: string;
        start: string;
        end: string;
        shiftPlannings: ShiftPlanning[];
    };
    totalBillableTime: string;
    unplannedRemark?: string;
}

export interface TrackViewModel {
    id: string;
    accepted: boolean;
    isOffline: boolean;
    assessor?: string;
    billableStart: Date;
    billableEnd: UpToAndIncludingDate;
    checkIn: Date;
    checkOut: UpToAndIncludingDate;
    department?: DepartmentViewModel;
    deviation: number;
    deviationRemark?: string;
    feedback?: FeedbackViewModel;
    managerComment?: CommentViewModel;
    meal?: MealType;
    owner: UserViewModel;
    pause: number;
    shift?: {
        id: string;
        start: string;
        end: string;
        shiftPlannings: ShiftPlanning[];
    };
    totalBillableTime: string;
    unplannedRemark?: string;
}
