import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { downloadYearEndStatementApiCall } from '../../entities/YearEndStatement/YearEndStatementService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setIsSuccessful } from './yearEndStatementReducer';

export const downloadYearEndStatement = (employmentId: string, year: number) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await downloadYearEndStatementApiCall(employmentId, year);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[downloadYearEndStatement]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
