import {
    FC,
    ReactElement,
    useMemo,
    useRef,
} from 'react';

import classnames from 'classnames';
import { useScroll, useWindowScroll } from 'react-use';

import { LoadingSpinner } from '../../../components';
import { LeaveOfAbsencesOverviewBodyRow } from '../../../entities/LeaveOfAbsenceOverviewBlock/LeaveOfAbsencesOverviewBlock';
import { Period } from '../../../entities/Period/Period';
import { eachDayOfInterval } from '../../../helpers/date';
import shouldShowStickyTableHead from './helpers/shouldShowStickyTableHead';
import useTableCellHoverIndex from './hooks/useTableCellHoverIndex';
import { LeaveOfAbsencesOverviewTableBody, LeaveOfAbsencesOverviewTableHead } from './subcomponents';

import './LeaveOfAbsencesOverviewTable.scss';

interface LeaveOfAbsencesOverviewTableProps {
    isLoading: boolean;
    hasStickyTableHead?: boolean;
    period: Period;
    rows: LeaveOfAbsencesOverviewBodyRow[];
    className?: string;
}

const LeaveOfAbsencesOverviewTable: FC<LeaveOfAbsencesOverviewTableProps> = ({
    isLoading,
    hasStickyTableHead = false,
    period,
    rows,
    className = '',
}): ReactElement => {
    const wrapperElementRef = useRef<HTMLDivElement>(null);
    const tableElementRef = useRef<HTMLTableElement>(null);
    const tableHeadElementRef = useRef<HTMLTableSectionElement>(null);
    const { y: scrollY } = useWindowScroll();
    const { x: scrollX } = useScroll(wrapperElementRef);
    const hoverIndex = useTableCellHoverIndex(tableElementRef);

    const days = useMemo(() => eachDayOfInterval(period), [period]);
    const showStickyTableHead = useMemo(() => shouldShowStickyTableHead(tableHeadElementRef, scrollY, hasStickyTableHead), [scrollY]);
    const tableHeadLeft = useMemo(() => (hasStickyTableHead && tableHeadElementRef.current?.getBoundingClientRect().left) || 0, [scrollX, scrollY]);

    const wrapperClassName = classnames('leave-of-absences-overview-table', {
        'leave-of-absences-overview-table--is-loading': isLoading,
    }, className);

    const stickyTableHeadClassName = classnames('leave-of-absences-overview-table__table-head--is-sticky', {
        'leave-of-absences-overview-table__table-head--is-hidden': !showStickyTableHead,
    });

    return (
        <div
            ref={wrapperElementRef}
            className={wrapperClassName}
        >
            <style>
                {hoverIndex !== undefined && `
                .leave-of-absences-overview-table-body-cell:nth-child(${hoverIndex + 1}) .leave-of-absences-overview-table-body-cell__active-layer {
                    display: block;
                }`}
            </style>
            <table
                ref={tableElementRef}
                className={`leave-of-absences-overview-table__table ${className}`}
            >
                <LeaveOfAbsencesOverviewTableHead
                    days={days}
                    ref={tableHeadElementRef}
                />
                {hasStickyTableHead && (
                    <LeaveOfAbsencesOverviewTableHead
                        days={days}
                        style={hasStickyTableHead ? { left: `${tableHeadLeft}px` } : {}}
                        className={stickyTableHeadClassName}
                    />
                )}
                <LeaveOfAbsencesOverviewTableBody
                    rows={rows}
                />
            </table>
            {isLoading && <LoadingSpinner className="leave-of-absences-overview-table__loading-spinner" />}
        </div>
    );
};

export default LeaveOfAbsencesOverviewTable;
