import { FC } from 'react';

import { MobileToggleButton, UserMenuButtons } from '../index';

import './MobileUserMenu.scss';

interface MobileUserMenuProps {
    isActive: boolean;
    userId?: string;
    userInitials?: string;
    onLinkClick: () => void;
    onToggle: () => void;
    className?: string;
    navLinkClassName?: string;
    activeNavLinkClassName?: string;
}

const MobileUserMenu: FC<MobileUserMenuProps> = ({
    isActive,
    userId,
    userInitials,
    onLinkClick,
    onToggle,
    className = '',
    navLinkClassName = '',
    activeNavLinkClassName = '',
}) => (
    <div className={`mobile-user-menu ${className}`}>
        <MobileToggleButton
            isUserInitials
            isActive={isActive}
            text={userInitials || ''}
            onClick={onToggle}
        />
        {isActive && (
            <UserMenuButtons
                userId={userId}
                onNavLinkClick={onLinkClick}
                navLinkClassName={navLinkClassName}
                activeNavLinkClassName={activeNavLinkClassName}
            />
        )}
    </div>
);

export default MobileUserMenu;
