import axios from 'axios';

import { DateFormat } from '../../@paco/types/dateFormatTypes';
import {
    Absence,
    AbsenceResource,
    AbsenceViewModel,
    ApiResponse,
    Filter,
    PaginationRequestApiParams,
} from '../../models';
import transformAbsenceResource from '../../services/AbsenceService/transformAbsenceResource';
import { formatDate } from '../date';

export const getAbsencesInDateRange = async (
    startDate: Date | string,
    endDate: Date | string,
    filter?: Filter,
    pagination?: PaginationRequestApiParams,
    includes?: string[],
): Promise<AbsenceViewModel[]> => {
    const { userIds } = filter || {};

    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/absences/`,
        {
            params: {
                include: (includes || []).join(','),
                ...((filter && filter.search) && { search: filter.search }),
                ...((filter && filter.departments) && { departments: filter.departments }),
                userUuids: (userIds && userIds.length > 0) ? JSON.stringify(userIds) : undefined,
                ...(pagination && { 'page[number]': pagination.number || 1 }),
                ...(pagination && { 'page[size]': pagination.size }),
                startDate: formatDate(startDate, DateFormat.apiDateTime),
                endDate: formatDate(endDate, DateFormat.apiDateTime),
            },
        },
    ) as unknown as ApiResponse<AbsenceResource[]>;

    return response.data.map(resource => transformAbsenceResource(
        resource,
        resource.user,
        resource.comments,
        resource.absenceHours,
    ));
};

// TODO: Replace this with new endpoint above
export const getAbsencesInDateRangeLegacy = (
    startDate: Date | string,
    endDate: Date | string,
    filter?: Filter,
    pagination?: PaginationRequestApiParams,
    includes?: string[],
) => {
    const { userIds } = filter || {};

    return axios.get<Absence[]>(
        `${process.env.REACT_APP_API_URL}/absences/`,
        {
            params: {
                include: (includes || []).join(','),
                ...((filter && filter.search) && { search: filter.search }),
                ...((filter && filter.departments) && { departments: filter.departments }),
                userUuids: (userIds && userIds.length > 0) ? JSON.stringify(userIds) : undefined,
                ...(pagination && { 'page[number]': pagination.number || 1 }),
                ...(pagination && { 'page[size]': pagination.size }),
                startDate: formatDate(startDate, DateFormat.apiDateTime),
                endDate: formatDate(endDate, DateFormat.apiDateTime),
            },
        },
    );
};
