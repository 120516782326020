import authorizedGetFile from '../../helpers/file/authorizedGetFile';
import downloadDataUrl from '../../helpers/file/downloadDataUrl';
import { DocumentViewModel, UserDocumentViewModel } from '../../models';

export default function downloadDocumentTemplate(
    id: string,
    displayName: string,
    downloadUrl: string,
    fileName: string,
    isDataUrl: boolean,
) {
    if (isDataUrl && id) {
        const url = `${process.env.REACT_APP_API_URL}/files/download/${id}`;
        authorizedGetFile(url, fileName);
    } else if (!isDataUrl) {
        authorizedGetFile(downloadUrl, fileName);
    } else {
        downloadDataUrl(downloadUrl, fileName);
    }
}

export function downloadUserDocument(userDocument: UserDocumentViewModel) {
    const {
        id,
        displayName,
        downloadUrl,
        fileName,
        isDataUrl,
    } = userDocument;

    downloadDocumentTemplate(
        id,
        displayName,
        downloadUrl,
        fileName,
        isDataUrl,
    );
}

export function downloadDocument(documentViewModel: DocumentViewModel) {
    const {
        id,
        displayName,
        downloadUrl,
        fileName,
        isDataUrl,
    } = documentViewModel;

    downloadDocumentTemplate(
        id,
        displayName,
        downloadUrl,
        fileName,
        isDataUrl,
    );
}

