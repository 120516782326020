import { FC, ReactElement, useEffect } from 'react';

import { EditShiftTimeForm } from '../../../containers';
import { Period } from '../../../entities/Period/Period';
import { ShiftIndex } from '../../../entities/Shift/Shift';
import trans from '../../../helpers/trans';
import { deleteShift, editShiftTime } from '../../../redux/shift/shiftActions';
import { setIsEditShiftSuccessful } from '../../../redux/shift/shiftReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedEditShiftTimeFormProps {
    shift: ShiftIndex;
    onClose: () => void;
    className?: string;
}

const ConnectedEditShiftTimeForm: FC<ConnectedEditShiftTimeFormProps> = ({
    shift,
    onClose,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isEditShiftSuccessful, isDeleteShiftSuccessful } = useTypedSelector(state => state.shiftReducer);

    const handleDeleteButtonClick = (): void => {
        dispatch(deleteShift(shift.id));
    };

    const handleSubmit = (value: Period): void => {
        dispatch(editShiftTime({ period: value }, shift));
    };

    useEffect((): void => {
        if (isEditShiftSuccessful) {
            onClose();

            dispatch(setIsEditShiftSuccessful(false));
            dispatch(setToast({
                text: trans('containers.forms.editShiftTimeForm.editShiftTimeSuccess'),
                type: ToastType.pass,
            }));
        }
    }, [isEditShiftSuccessful]);

    useEffect((): void => {
        if (isDeleteShiftSuccessful) {
            onClose();

            dispatch(setToast({
                text: trans('containers.forms.editShiftTimeForm.deleteShiftSuccess'),
                type: ToastType.pass,
            }));
        }
    }, [isDeleteShiftSuccessful]);

    return (
        <EditShiftTimeForm
            period={shift.period}
            onSubmit={handleSubmit}
            onDeleteButtonClick={handleDeleteButtonClick}
            onCancel={onClose}
            className={className}
        />
    );
};

export default ConnectedEditShiftTimeForm;
