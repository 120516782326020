import React, { FC } from 'react';

import Icon from '../../../../components/style/Icon/Icon';

import './CalendarButtonAdd.scss';

type HTMLButtonProps = JSX.IntrinsicElements['button'];

interface CalendarButtonAddProps extends HTMLButtonProps {
    className?: string;
    kind?: 'plus' | 'plus-shift-concept',
}

const CalendarButtonAdd:FC<CalendarButtonAddProps> = ({
    className = '',
    kind = 'plus',
    ...props
}) => (
    <button type="button" {...props} className={`calendar-button-add ${className}`}>
        <Icon kind={kind} className="calendar-button-add__icon" />
    </button>
);

export default CalendarButtonAdd;
