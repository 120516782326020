import { USERS_SORT_EMPLOYEE_NUMBER, USERS_SORT_FIRST_NAME, USERS_SORT_LAST_NAME } from '../../constants';
import {
    APP_ROUTE_CHANGE,
    MANAGEMENT_ADD_DEPARTMENT_SUCCESS,
    MANAGEMENT_ADD_USER_NOTE_FAILURE,
    MANAGEMENT_ADD_USER_NOTE_REQUEST,
    MANAGEMENT_DEACTIVATE_USER_SUCCESS,
    MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_FAILURE,
    MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_REQUEST,
    MANAGEMENT_DELETE_DEPARTMENT_SUCCESS,
    MANAGEMENT_DELETE_USER_NOTE_REQUEST,
    MANAGEMENT_DELETE_USER_SUCCESS,
    MANAGEMENT_EDIT_DEPARTMENT_SUCCESS,
    MANAGEMENT_EDIT_GROUPS_SUCCESS,
    MANAGEMENT_EDIT_USER_FAILURE,
    MANAGEMENT_EDIT_USER_NOTE_FAILURE,
    MANAGEMENT_EDIT_USER_NOTE_REQUEST,
    MANAGEMENT_EDIT_USER_REQUEST,
    MANAGEMENT_EDIT_USER_SUCCESS,
    MANAGEMENT_EXPORT_USERS_CSV_FAILURE,
    MANAGEMENT_EXPORT_USERS_CSV_REQUEST,
    MANAGEMENT_EXPORT_USERS_CSV_SUCCESS,
    MANAGEMENT_FAILURE,
    MANAGEMENT_GET_COMMENT_CATEGORIES_SUCCESS,
    MANAGEMENT_GET_DOCUMENT_TYPES_SUCCESS,
    MANAGEMENT_GET_PERMISSION_GROUPS_FAILURE,
    MANAGEMENT_GET_PERMISSION_GROUPS_REQUEST,
    MANAGEMENT_GET_PERMISSION_GROUPS_SUCCESS,
    MANAGEMENT_GET_USER_REQUEST,
    MANAGEMENT_GET_USER_SHIFTS_REQUEST,
    MANAGEMENT_GET_USER_SHIFTS_SUCCESS,
    MANAGEMENT_GET_USERS_REQUEST,
    MANAGEMENT_LINK_USER_TO_LOKET_FAILURE,
    MANAGEMENT_LINK_USER_TO_LOKET_REQUEST,
    MANAGEMENT_LINK_USER_TO_LOKET_SUCCESS,
    MANAGEMENT_LINK_USERS_TO_LOKET_FAILURE,
    MANAGEMENT_LINK_USERS_TO_LOKET_REQUEST,
    MANAGEMENT_LINK_USERS_TO_LOKET_SUCCESS,
    MANAGEMENT_REACTIVATE_USER_SUCCESS,
    MANAGEMENT_REQUEST,
    MANAGEMENT_RESET_PASSWORD_AND_PINCODE_FAILURE,
    MANAGEMENT_RESET_PASSWORD_AND_PINCODE_REQUEST,
    MANAGEMENT_RESET_PASSWORD_AND_PINCODE_SUCCESS,
    MANAGEMENT_SET_USER,
    MANAGEMENT_SET_USER_PLANNING_PREFERENCE_FAILURE,
    MANAGEMENT_SET_USER_PLANNING_PREFERENCE_REQUEST,
    MANAGEMENT_SET_USER_PLANNING_PREFERENCE_SUCCESS,
    MANAGEMENT_SET_USERS,
    MANAGEMENT_SUCCESS,
} from '../actionTypes';

const initialState = {
    loading: false,
    shiftsLoading: false,
    users: [],
    user: null,
    userFiles: [],
    yearEndStatements: [],
    userShifts: [],
    userNotes: [],
    userTracks: [],
    userLoketHours: null,
    documentTypes: [],
    permissionGroups: [],
    commentCategories: [],
    exportCSVRequest: false,
    sorting: {
        active: USERS_SORT_FIRST_NAME,
        [USERS_SORT_EMPLOYEE_NUMBER]: {
            ascending: true,
        },
        [USERS_SORT_FIRST_NAME]: {
            ascending: true,
        },
        [USERS_SORT_LAST_NAME]: {
            ascending: true,
        },
    },
    submittedFormData: null,
    errors: [],
};

const managementReducer = (state = initialState, action) => {
    switch (action.type) {
        case MANAGEMENT_REQUEST:
            return { ...state, loading: true };
        case MANAGEMENT_SUCCESS:
        case MANAGEMENT_ADD_DEPARTMENT_SUCCESS:
        case MANAGEMENT_EDIT_DEPARTMENT_SUCCESS:
        case MANAGEMENT_EDIT_GROUPS_SUCCESS:
        case MANAGEMENT_DELETE_DEPARTMENT_SUCCESS:
        case MANAGEMENT_DEACTIVATE_USER_SUCCESS:
        case MANAGEMENT_REACTIVATE_USER_SUCCESS:
        case MANAGEMENT_DELETE_USER_SUCCESS:
        case MANAGEMENT_RESET_PASSWORD_AND_PINCODE_SUCCESS:
        case MANAGEMENT_LINK_USER_TO_LOKET_SUCCESS:
        case MANAGEMENT_LINK_USERS_TO_LOKET_SUCCESS:
        case MANAGEMENT_SET_USER_PLANNING_PREFERENCE_SUCCESS:
            return { ...state, loading: false };
        case MANAGEMENT_EDIT_USER_REQUEST:
        case MANAGEMENT_RESET_PASSWORD_AND_PINCODE_REQUEST:
        case MANAGEMENT_LINK_USER_TO_LOKET_REQUEST:
        case MANAGEMENT_LINK_USERS_TO_LOKET_REQUEST:
        case MANAGEMENT_SET_USER_PLANNING_PREFERENCE_REQUEST:
        case MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_REQUEST:
        case MANAGEMENT_GET_USER_REQUEST:
        case MANAGEMENT_ADD_USER_NOTE_REQUEST:
        case MANAGEMENT_EDIT_USER_NOTE_REQUEST:
        case MANAGEMENT_DELETE_USER_NOTE_REQUEST:
            return { ...state, loading: true };
        case MANAGEMENT_GET_USERS_REQUEST:
            return { ...state, loading: true, user: null };
        case MANAGEMENT_GET_DOCUMENT_TYPES_SUCCESS:
            return { ...state, documentTypes: action.documentTypes };
        case MANAGEMENT_SET_USER:
            return {
                ...state,
                loading: false,
                user: action.user,
                userFiles: action.files,
                yearEndStatements: action.yearEndStatements,
                userLoketHours: action.loketHours,
                userNotes: action.userNotes,
                userTracks: action.userTracks,
            };
        case MANAGEMENT_SET_USERS:
            return {
                ...state,
                loading: false,
                users: action.users,
            };
        case APP_ROUTE_CHANGE:
            return {
                ...state,
                user: null,
                users: [],
                userShifts: [],
            };
        case MANAGEMENT_EDIT_USER_FAILURE:
        case MANAGEMENT_RESET_PASSWORD_AND_PINCODE_FAILURE:
        case MANAGEMENT_LINK_USER_TO_LOKET_FAILURE:
        case MANAGEMENT_LINK_USERS_TO_LOKET_FAILURE:
        case MANAGEMENT_GET_PERMISSION_GROUPS_FAILURE:
        case MANAGEMENT_ADD_USER_NOTE_FAILURE:
        case MANAGEMENT_EDIT_USER_NOTE_FAILURE:
            return { ...state, loading: false };
        case MANAGEMENT_EXPORT_USERS_CSV_REQUEST:
            return { ...state, exportCSVRequest: true };
        case MANAGEMENT_EXPORT_USERS_CSV_SUCCESS:
        case MANAGEMENT_EXPORT_USERS_CSV_FAILURE:
            return { ...state, exportCSVRequest: false };
        case MANAGEMENT_GET_USER_SHIFTS_REQUEST:
            return { ...state, shiftsLoading: true };
        case MANAGEMENT_GET_USER_SHIFTS_SUCCESS:
            return { ...state, shiftsLoading: false, userShifts: action.shifts };
        case MANAGEMENT_FAILURE:
        case MANAGEMENT_SET_USER_PLANNING_PREFERENCE_FAILURE:
        case MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_FAILURE:
            return { ...state, loading: false, errors: [action.error, ...state.errors] };
        case MANAGEMENT_GET_PERMISSION_GROUPS_REQUEST:
            return { ...state, permissionLoading: true };
        case MANAGEMENT_GET_PERMISSION_GROUPS_SUCCESS:
            return { ...state, permissionGroups: action.permissionGroups };
        case MANAGEMENT_EDIT_USER_SUCCESS:
            return {
                ...state,
                user: action.user,
                users: action.users,
                loading: false,
            };
        case MANAGEMENT_GET_COMMENT_CATEGORIES_SUCCESS:
            return { ...state, commentCategories: action.commentCategories };
        default:
            return state;
    }
};

export default managementReducer;
