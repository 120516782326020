import { BaseScheduleShift } from '../../@paco/entities/BaseScheduleShift/BaseScheduleShift';
import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import { updateLegacyShiftPlanningWithShiftPlanning } from '../ShiftPlanning/ShiftPlanningHelpers';
import { BaseScheduleShiftViewModel } from './BaseScheduleShift';

export const updateLegacyBaseScheduleShiftWithBaseScheduleShift = (legacyBaseScheduleShift: BaseScheduleShiftViewModel, baseScheduleShift: BaseScheduleShift): BaseScheduleShiftViewModel => {
    const updatedShiftPlannings = legacyBaseScheduleShift.shiftPlannings.map((legacyShiftPlanning) => {
        const matchingShiftPlanning = baseScheduleShift.shiftPlannings.find(shiftPlanning => shiftPlanning.id === legacyShiftPlanning.id);

        if (!matchingShiftPlanning) {
            return legacyShiftPlanning;
        }

        return updateLegacyShiftPlanningWithShiftPlanning(legacyShiftPlanning, matchingShiftPlanning);
    });

    return {
        ...legacyBaseScheduleShift,
        start: baseScheduleShift.period.start,
        end: new UpToButExcludingDate(baseScheduleShift.period.end).transformToUpToAndIncludingDate(),
        shiftPlannings: updatedShiftPlannings,
    };
};
