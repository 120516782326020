import trans from '../helpers/trans';
import { PermissionSlug } from './permissionTypes';

export enum DashboardSettingType {
    absencesWithConflicts = 'absences-with-conflicts',
    currentPayrollPeriodNumber = 'current-payroll-period-number',
    openAbsences = 'open-absences',
    openAbsenceHours = 'open-absence-hours',
    openExchanges = 'open-exchanges',
    openLeaveOfAbsences = 'open-leave-of-absences',
    openLeaveOfAbsenceHours = 'open-leave-of-absence-hours',
    openTracks = 'open-tracks',
    registeredShiftPlanningsForFourWeeks = 'registered-shift-plannings-for-four-weeks',
    runningTracks = 'running-tracks',
    unknown = 'unknown',
}

export interface DashboardSmallWidget {
    hasPZLabel?: boolean;
    type: DashboardSettingType;
    label: string;
    permissions: PermissionSlug[];
}

export const dashboardSmallWidgets: Record<DashboardSettingType, DashboardSmallWidget> = {
    [DashboardSettingType.absencesWithConflicts]: {
        type: DashboardSettingType.absencesWithConflicts,
        label: trans('containers.forms.editDashboardForm.absencesWithConflicts'),
        permissions: ['view-all-absences'],
    },
    [DashboardSettingType.currentPayrollPeriodNumber]: {
        hasPZLabel: true,
        type: DashboardSettingType.currentPayrollPeriodNumber,
        label: trans('containers.forms.editDashboardForm.currentPeriod'),
        permissions: [
            'view-all-absences',
            'view-all-leave-of-absences',
            'view-all-tracks',
            'view-all-leave-of-absence-hours',
            'view-all-absence-hours',
        ],
    },
    [DashboardSettingType.openAbsences]: {
        type: DashboardSettingType.openAbsences,
        label: trans('containers.forms.editDashboardForm.openAbsences'),
        permissions: ['view-all-absences'],
    },
    [DashboardSettingType.openExchanges]: {
        type: DashboardSettingType.openExchanges,
        label: trans('containers.forms.editDashboardForm.openExchanges'),
        permissions: ['view-all-exchanges'],
    },
    [DashboardSettingType.openLeaveOfAbsences]: {
        type: DashboardSettingType.openLeaveOfAbsences,
        label: trans('containers.forms.editDashboardForm.openLeaveOfAbsenceRequests'),
        permissions: ['view-all-leave-of-absences'],
    },
    [DashboardSettingType.openAbsenceHours]: {
        hasPZLabel: true,
        type: DashboardSettingType.openAbsenceHours,
        label: trans('containers.forms.editDashboardForm.openAbsenceHours'),
        permissions: ['view-all-absences', 'view-all-absence-hours'],
    },
    [DashboardSettingType.openLeaveOfAbsenceHours]: {
        hasPZLabel: true,
        type: DashboardSettingType.openLeaveOfAbsenceHours,
        label: trans('containers.forms.editDashboardForm.openLeaveOfAbsenceHours'),
        permissions: ['view-all-leave-of-absences', 'view-all-leave-of-absence-hours'],
    },
    [DashboardSettingType.runningTracks]: {
        type: DashboardSettingType.runningTracks,
        label: trans('containers.forms.editDashboardForm.runningTracks'),
        permissions: ['view-all-tracks'],
    },
    [DashboardSettingType.openTracks]: {
        type: DashboardSettingType.openTracks,
        label: trans('containers.forms.editDashboardForm.openTracks'),
        permissions: ['view-all-tracks'],
    },
    [DashboardSettingType.registeredShiftPlanningsForFourWeeks]: {
        type: DashboardSettingType.registeredShiftPlanningsForFourWeeks,
        label: trans('containers.forms.editDashboardForm.registeredShiftPlanningsForFourWeeks'),
        permissions: ['view-all-tracks'],
    },
    [DashboardSettingType.unknown]: {
        type: DashboardSettingType.unknown,
        label: trans('common.unknown'),
        permissions: [],
    },
};
