import { Permission } from '../../entities/Permission/Permission';
import { useTypedSelector } from '../../redux/store';

const usePermissions = (): Permission[] => {
    const { permissions } = useTypedSelector(state => state.authenticatedUserReducer);

    return permissions;
};

export default usePermissions;
