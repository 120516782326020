import { FC, ReactElement } from 'react';

import FilterBlock from '../../../../components/Filters/subcomponents/FilterBlock/FilterBlock';
import { Checkbox } from '../../../components';
import trans from '../../../helpers/trans';

import './ShowEmployeesFilterContainer.scss';

interface ShowEmployeesFilterContainerProps {
    showEmployees: boolean;
    onChange: (showEmployees: boolean) => void;
    className?: string;
}

const ShowEmployeesFilterContainer: FC<ShowEmployeesFilterContainerProps> = ({
    showEmployees,
    onChange,
    className = '',
}): ReactElement => (
    <FilterBlock
        title={trans('common.employees')}
        className={`show-employees-filter-container ${className}`}
    >
        <Checkbox
            label={trans('containers.filters.showEmployeesFilterContainer')}
            checked={showEmployees}
            onChange={onChange}
            className={`show-employees-filter-container__check ${className}`}
        />
    </FilterBlock>
);

export default ShowEmployeesFilterContainer;
