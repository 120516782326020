import { FC, ReactElement } from 'react';

import { ElementWithTooltip } from '../../../compositions';
import { getTimeOnlyLabel } from '../../../entities/Period/PeriodHelpers';
import { UnavailableToWorkTimeSlot } from '../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import trans from '../../../helpers/trans';

import './UnavailableToWorkTimeSlotBadge.scss';

interface UnavailableToWorkTimeSlotBadgeProps {
    unavailableToWorkTimeSlot: UnavailableToWorkTimeSlot;
    className?: string;
}

const UnavailableToWorkTimeSlotBadge: FC<UnavailableToWorkTimeSlotBadgeProps> = ({ unavailableToWorkTimeSlot, className = '' }): ReactElement => {
    const time = getTimeOnlyLabel(unavailableToWorkTimeSlot.period.start, unavailableToWorkTimeSlot.period.end);

    return (
        <ElementWithTooltip
            tooltipIsActive
            tooltipText={`${time} ${unavailableToWorkTimeSlot.comment}`}
        >
            <div className={`unavailable-to-work-time-slot-badge ${className}`}>
                {trans('entities.unavailableToWorkTimeSlots.unavailable')}
            </div>
        </ElementWithTooltip>
    );
};

export default UnavailableToWorkTimeSlotBadge;
