import { FC } from 'react';

import { IconButton } from '../../../..';

import './AddDepartmentGroupButton.scss';

interface AddDepartmentGroupButtonProps {
    onClick: () => void;
    title: string;
}

const AddDepartmentGroupButton: FC<AddDepartmentGroupButtonProps> = ({
    onClick,
    title,
}) => (
    <IconButton
        icon="plus"
        text={title}
        onClick={onClick}
        className="add-department-group-button"
    />
);

export default AddDepartmentGroupButton;
