import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import * as Sentry from '@sentry/browser';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-use';

import packageJson from '../../../package.json';
import trans from '../../@paco/helpers/trans';
import { setToast } from '../../@paco/redux/toasts/toastsReducer';
import { ToastType } from '../../@paco/types';
import { isVersionGreaterThan } from './helpers/isVersionGreaterThan';

const COOLDOWN_TIME = 120000;

interface CacheBusterProps {
    children: ReactElement;
}

const CacheBuster: FC<CacheBusterProps> = ({ children }): ReactElement => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [cooldownIsActive, setCooldownIsActive] = useState(false);

    const resetCooldown = () => {
        setCooldownIsActive(false);
    };

    async function checkForUpdate() {
        if (cooldownIsActive) {
            return;
        }

        setTimeout(resetCooldown, COOLDOWN_TIME);

        fetch(
            `/version-current.json?${new Date().getTime()}`,
            {
                cache: 'no-cache',
            },
        )
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = packageJson.version;

                if (isVersionGreaterThan(latestVersion, currentVersion)) {
                    dispatch(setToast({
                        text: trans('components.cacheBuster.versionUpdate', { version: latestVersion }),
                        type: ToastType.updateApp,
                        shouldFadeOutAutomatically: false,
                    }));
                }
            })
            .catch((e) => {
                Sentry.captureException(e);
            });

        setCooldownIsActive(true);
    }

    useEffect(() => {
        checkForUpdate();
    }, [location]);

    return children;
};

export default CacheBuster;
