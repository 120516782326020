import { FC, ReactElement, useEffect } from 'react';

import { ConfirmForm } from '../../../containers';
import { TemporaryWorker } from '../../../entities/TemporaryWorker/TemporaryWorker';
import trans from '../../../helpers/trans';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { deleteTemporaryWorker } from '../../../redux/temporaryWorker/temporaryWorkerActions';
import { setIsDeleteSuccessful } from '../../../redux/temporaryWorker/temporaryWorkerReducer';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedDeleteTemporaryWorkerFormProps {
    temporaryWorker: TemporaryWorker;
    onClose: () => void;
    className?: string;
}

const ConnectedDeleteTemporaryWorkerForm: FC<ConnectedDeleteTemporaryWorkerFormProps> = ({ temporaryWorker, onClose, className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();
    const { name } = temporaryWorker;

    const { isLoading, isDeleteSuccessful } = useTypedSelector(state => state.temporaryWorkerReducer);

    const handleSubmit = (): void => {
        dispatch(deleteTemporaryWorker(temporaryWorker.id));
    };

    useEffect((): void => {
        if (isDeleteSuccessful) {
            dispatch(setToast({
                text: trans('containers.forms.deleteTemporaryWorkerForm.deleteTemporaryWorkerSuccess', { name }),
                type: ToastType.pass,
            }));
            dispatch(setIsDeleteSuccessful(false));

            onClose();
        }
    }, [isDeleteSuccessful]);

    return (
        <ConfirmForm
            isLoading={isLoading}
            intro={trans('containers.forms.deleteTemporaryWorkerForm.intro', { name })}
            submitText={trans('common.delete')}
            onCancel={onClose}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedDeleteTemporaryWorkerForm;
