import React, { FC } from 'react';

import { FormGroup } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import RadioButton from '../../../forms/RadioButton/RadioButton';
import FilterBlock from '../FilterBlock/FilterBlock';


interface FilterProps {
    filter: AppFilter;
    onChange: (filter: AppFilter) => void;
}

const AvailabilityFilter: FC<FilterProps> = ({ filter, onChange }) => {
    const setFilter = (propName: string, value: boolean) => {
        const availability = {
            ...filter.availability,
            [propName]: value,
        };

        onChange({
            ...filter,
            availability,
        });
    };

    return (
        <FilterBlock title={translate('common.users')}>
            <FormGroup check className="p-0">
                <form className="filters-radio-form">
                    <RadioButton
                        id="name"
                        checked={!filter.availability.sortByExperience}
                        label={translate('pages.calendar.sortByName')}
                        onClick={() => setFilter('sortByExperience', false)}
                    />
                    <RadioButton
                        id="experience"
                        checked={filter.availability.sortByExperience}
                        label={translate('pages.calendar.sortByLevel')}
                        onClick={() => setFilter('sortByExperience', true)}
                    />
                </form>
            </FormGroup>
        </FilterBlock>
    );
};

export default AvailabilityFilter;
