import { FC } from 'react';

import classnames from 'classnames';

import { Skeleton } from '../../../../components';

import './ShiftsWidgetShiftSkeleton.scss';

export interface DashboardShiftsWidgetShiftProps {
    index: number;
    className?: string;
}

const ShiftsWidgetShiftSkeleton: FC<DashboardShiftsWidgetShiftProps> = ({ index, className = '' }) => {
    const classNames = classnames('shifts-widget-shift-skeleton', {
        [`shifts-widget-shift-skeleton--has-index-${index}`]: index,
    }, className);

    return (
        <div className={classNames}>
            <div className="shifts-widget-shift-skeleton__skeleton-wrapper">
                <Skeleton className="shifts-widget-shift-skeleton__skeleton shifts-widget-shift-skeleton__employee-skeleton" />
            </div>
            <div className="shifts-widget-shift-skeleton__skeleton-wrapper">
                <Skeleton className="shifts-widget-shift-skeleton__skeleton shifts-widget-shift-skeleton__department-skeleton" />
            </div>
            <div className="shifts-widget-shift-skeleton__skeleton-wrapper shifts-widget-shift-skeleton__shift-time-skeleton-wrapper">
                <Skeleton className="shifts-widget-shift-skeleton__skeleton shifts-widget-shift-skeleton__shift-time-skeleton" />
            </div>
            <div className="shifts-widget-shift-skeleton__skeleton-wrapper shifts-widget-shift-skeleton__track-time-skeleton-wrapper">
                <Skeleton className="shifts-widget-shift-skeleton__skeleton shifts-widget-shift-skeleton__track-time-skeleton" />
            </div>
        </div>
    );
};

export default ShiftsWidgetShiftSkeleton;
