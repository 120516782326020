import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { setDepartmentOptions as setGlobalFilterDepartmentOptions } from './@interface/globalFilters/globalFiltersReducer';
import { clearNotifications } from './@interface/notifications/notificationsReducer';
import { setDepartmentOptions } from './@interface/options/optionsReducer';
import { setAuthenticatedUser } from './authenticatedUser/authenticatedUserReducer';
import { setPayrollPeriods } from './payrollPeriods/payrollPeriodsReducer';
import { Reducers } from './reducers';
import { clearToasts } from './toasts/toastsReducer';

export type TypedDispatch = ThunkDispatch<Reducers, unknown, AnyAction>;
export type ReducerGetter = () => Reducers;

export const useTypedDispatch = (): TypedDispatch => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<Reducers> = useSelector;

export const clearStore = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setAuthenticatedUser(undefined));
    dispatch(setPayrollPeriods([]));
    dispatch(setGlobalFilterDepartmentOptions([]));
    dispatch(setDepartmentOptions([]));
    dispatch(clearNotifications());
    dispatch(clearToasts());
};
