import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { UsersSelect } from '../../../compositions';
import { LegacyBasicUser } from '../../../entities/User/User';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { getBasicUsers } from '../../../redux/users/usersActions';

interface ConnectedUsersSelectProps {
    controlShouldRenderValue?: boolean;
    isDisabled?: boolean;
    departmentId?: string;
    excludeUsers?: string[];
    onSelect: (user: LegacyBasicUser) => void;
    className?: string;
}

const ConnectedUsersSelect: FC<ConnectedUsersSelectProps> = ({
    controlShouldRenderValue = true,
    isDisabled = false,
    departmentId,
    excludeUsers = [],
    onSelect,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isLoading, basicUsers } = useTypedSelector(state => state.pacoUsersReducer);

    const users = useMemo(() => (
        basicUsers.filter(user => !excludeUsers.includes(user.id))
    ), [basicUsers, excludeUsers]);

    useEffect(() => {
        dispatch(getBasicUsers({
            ...(departmentId && { departments: [departmentId] }),
        }));
    }, []);

    return (
        <UsersSelect
            controlShouldRenderValue={controlShouldRenderValue}
            isDisabled={isDisabled || isLoading}
            users={users}
            onSelect={onSelect}
            className={className}
        />
    );
};

export default ConnectedUsersSelect;
