import { PayrollPeriod } from '../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { PayrollPeriodWeek } from '../../@paco/entities/PayrollPeriodWeek/PayrollPeriodWeek';
import { getOverlappingPayrollPeriodWeeks } from '../../@paco/entities/PayrollPeriodWeek/PayrollPeriodWeekHelpers';
import { transformToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import { startOfDayInHours } from '../../@paco/helpers/date';
import { getOverlappingPayrollPeriodOnWorkday } from '../../helpers';
import {
    addDays,
    compareAsc,
    differenceInDays,
    getISOWeek,
    isSameDay,
} from '../../helpers/date';
import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import { PayrollPeriodViewModel, WeekWithHours } from '../../models';
import getStartOfWeek from './getStartOfWeek';


export function getAmountOfWeeksFromDateRange(
    startDate: Date,
    endDate: Date,
): number {
    if (compareAsc(endDate, startDate) === -1) {
        return 0;
    }

    if (isSameDay(startDate, endDate)) {
        return 1;
    }

    const justifiedStartDate = getStartOfWeek(startDate, startOfDayInHours);

    return Math.ceil((differenceInDays(endDate, justifiedStartDate) + 1) / 7);
}

function findWeekWithHoursByYearAndWeekNumber(week: WeekWithHours, year: number, weekNumber: number): boolean {
    return week.payrollPeriod?.year === year && week.weekNumber === weekNumber;
}

function mergeNewWeekdayWithWeekdays(
    oldWeekdays: WeekWithHours[],
    newWeekdays: WeekWithHours[],
): WeekWithHours[] {
    return newWeekdays.map((newWeekday) => {
        const matches = oldWeekdays
            .filter(oldWeekday => findWeekWithHoursByYearAndWeekNumber(oldWeekday, newWeekday.payrollPeriod?.year || 0, newWeekday.weekNumber));
        const hours = matches
            .reduce((total: null | number, week) => ((total === null && week.hours === null) ? null : (total || 0) + (week.hours || 0)), null);

        return {
            ...newWeekday,
            hours,
        };
    });
}

export function transformDatesToWeekdayWithHours(
    startDate: Date,
    endDate: Date,
    payrollPeriodWeeks: PayrollPeriodWeek[],
    payrollPeriod?: PayrollPeriodViewModel,
): WeekWithHours {
    return {
        id: startDate.toISOString(),
        weekNumber: getISOWeek(startDate),
        start: startDate,
        end: new UpToButExcludingDate(endDate).transformToUpToAndIncludingDate(),
        hours: 0,
        payrollPeriod: payrollPeriod || null,
        payrollPeriodWeeks,
    };
}

function getPayrollPeriodByDate(payrollPeriods: PayrollPeriodViewModel[], date: Date) {
    return payrollPeriods.find(
        (payrollPeriod => getOverlappingPayrollPeriodOnWorkday(payrollPeriod, date)),
    );
}

export default function getWeeksFromStartAndEndDate(
    startDate: Date,
    endDate?: Date,
    originalWeekdaysWithHours: WeekWithHours[] = [],
    payrollPeriods: PayrollPeriodViewModel[] = [],
    pacoPayrollPeriods: PayrollPeriod[] = [],
): WeekWithHours[] {
    const weeks = endDate ? getAmountOfWeeksFromDateRange(startDate, endDate) : 1;
    const startOfFirstWeekday = getStartOfWeek(startDate, startOfDayInHours);
    const endOfFirstWeekday = addDays(startOfFirstWeekday, 7);

    const weeksWithHours = new Array(weeks)
        // @ts-ignore
        .fill(0)
        .map((val, index) => {
            const start = addDays(startOfFirstWeekday, index * 7);
            const end = addDays(endOfFirstWeekday, index * 7);
            const payrollPeriod = getPayrollPeriodByDate(payrollPeriods, start);
            const payrollPeriodWeeks = getOverlappingPayrollPeriodWeeks(transformToPeriod(start, end), pacoPayrollPeriods);

            return transformDatesToWeekdayWithHours(
                start,
                end,
                payrollPeriodWeeks,
                payrollPeriod,
            );
        });

    return mergeNewWeekdayWithWeekdays(originalWeekdaysWithHours, weeksWithHours);
}


