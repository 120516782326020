import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';

// eslint-disable-next-line @typescript-eslint/ban-types
export type LeaveOfAbsencesState = AsyncReduxState<{}>;

const initialState: LeaveOfAbsencesState = {
    ...initialAsyncReduxState,
};

export const leaveOfAbsencesSlice = createSlice({
    name: 'leaveOfAbsencesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): LeaveOfAbsencesState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): LeaveOfAbsencesState {
            return ({
                ...state,
                isSuccessful: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): LeaveOfAbsencesState {
            return ({
                ...state,
                error: action.payload,
            });
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
} = leaveOfAbsencesSlice.actions;

export default leaveOfAbsencesSlice.reducer;
