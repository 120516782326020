import React, {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import { Modal } from 'reactstrap';

import { LoadingSpinner } from '../../../@paco/components';
import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { Permission } from '../../../@paco/entities/Permission/Permission';
import { Role } from '../../../@paco/entities/Role/Role';
import { PzLeaveType } from '../../../@paco/types/leaveType';
import Card from '../../../components/Card/Card';
import LeaveOfAbsencesTable from '../../../components/LeaveOfAbsencesTable/LeaveOfAbsencesTable';
import { translate } from '../../../helpers/translations/translator';
import {
    LeaveOfAbsence,
    LeaveOfAbsenceEditType,
    LeaveOfAbsenceFormData,
    LeaveType,
    PayrollPeriodWithHours,
    WeekWithHours,
} from '../../../models';
import ResolveLeaveOfAbsenceForm from '../../../pages/Absences/forms/ResolveLeaveOfAbsenceForm/ResolveLeaveOfAbsenceForm';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../../services/PayrollPeriodService/transformPayrollPeriodLegacy';

import './CompletePeriodIncompleteLeaveOfAbsencesContainer.scss';

interface CompletePeriodIncompleteLeaveOfAbsencesContainerProps {
    isLoading: boolean;
    currentUserRole?: Role;
    leaveOfAbsences: LeaveOfAbsence[];
    pacoPayrollPeriods: PayrollPeriod[];
    permissions: Permission[];
    onSubmitLeaveOfAbsence: (
        type: LeaveOfAbsenceEditType,
        data: LeaveOfAbsenceFormData,
        payrollPeriodWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        leaveOfAbsence: LeaveOfAbsence,
        leaveType?: LeaveType | PzLeaveType,
        declineComment?: string,
    ) => void;
    className?: string;
}

const CompletePeriodIncompleteLeaveOfAbsencesContainer: FC<CompletePeriodIncompleteLeaveOfAbsencesContainerProps> = ({
    isLoading,
    currentUserRole,
    leaveOfAbsences,
    pacoPayrollPeriods,
    permissions,
    onSubmitLeaveOfAbsence,
    className = '',
}): ReactElement => {
    const [leaveOfAbsenceToEdit, setLeaveOfAbsenceToEdit] = useState<LeaveOfAbsence>();

    const payrollPeriods = useMemo(() => pacoPayrollPeriods.map(transformPayrollPeriodToLegacyPayrollPeriod), [pacoPayrollPeriods]);

    const handleCloseEditLeaveOfAbsenceForm = (): void => {
        setLeaveOfAbsenceToEdit(undefined);
    };

    const handleSubmitEditLeaveOfAbsence = (
        type: LeaveOfAbsenceEditType,
        data: LeaveOfAbsenceFormData,
        payrollPeriodWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        leaveType?: LeaveType | PzLeaveType | null,
    ): void => {
        if (leaveOfAbsenceToEdit) {
            onSubmitLeaveOfAbsence(
                type,
                data,
                payrollPeriodWithHours,
                weeksWithHours,
                leaveOfAbsenceToEdit,
                leaveType || undefined,
            );
        }

        handleCloseEditLeaveOfAbsenceForm();
    };

    return (
        <div className={`complete-period-detail-incomplete-leave-of-absences-container ${className}`}>
            <Card
                title={`${translate('pages.pz.emptyLeaveOfAbsenceHours')} (${leaveOfAbsences.length})`}
                className="complete-period-detail-incomplete-leave-of-absences-container__card"
            />

            {isLoading && (
                <LoadingSpinner isSmall className="complete-period-detail-incomplete-leave-of-absences-container__loader" />
            )}

            {!isLoading && leaveOfAbsences.length !== 0 && (
                <LeaveOfAbsencesTable
                    leaveOfAbsences={leaveOfAbsences}
                    currentUserRole={currentUserRole}
                    permissions={permissions}
                    onLeaveOfAbsenceClick={setLeaveOfAbsenceToEdit}
                    className="complete-period-detail-incomplete-leave-of-absences-container__table"
                />
            )}

            <Modal size="lg" isOpen={!!leaveOfAbsenceToEdit} className="form-edit-leave-of-absence">
                <ResolveLeaveOfAbsenceForm
                    absence={leaveOfAbsenceToEdit}
                    permissions={permissions}
                    currentUserRole={currentUserRole}
                    pacoPayrollPeriods={pacoPayrollPeriods}
                    payrollPeriods={payrollPeriods}
                    onSubmit={handleSubmitEditLeaveOfAbsence}
                    onCancel={handleCloseEditLeaveOfAbsenceForm}
                />
            </Modal>
        </div>
    );
};

export default CompletePeriodIncompleteLeaveOfAbsencesContainer;
