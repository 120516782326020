import React, { Component } from 'react';

import PropTypes from 'prop-types';

import CheckboxesWithTextForm from '../../../../components/forms/CheckboxesWithTextForm/CheckboxesWithTextForm';
import { translate } from '../../../../helpers/translations/translator';

import './Settings.scss';

class SettingsMeals extends Component {
    state = {
        checkboxes: [],
        options: {},
    };

    componentDidMount() {
        const {
            mealNoneEnabled, mealNonePrice, mealBreadEnabled, mealHotEnabled, mealBreadPrice,
            mealHotPrice,
        } = this.props;
        mealNoneEnabled.name = 'noMeal';
        mealNoneEnabled.price = mealNonePrice;
        mealBreadEnabled.name = 'coldMeal';
        mealBreadEnabled.price = mealBreadPrice;
        mealHotEnabled.name = 'hotMeal';
        mealHotEnabled.price = mealHotPrice;

        const options = [mealNoneEnabled, mealBreadEnabled, mealHotEnabled];

        const data = options.reduce((total, option) => ({
            ...total,
            [option.id]: {
                ...option,
            },
        }), {});

        const checkboxes = this.getCheckboxesData(data);

        this.setState({
            checkboxes,
            options: data,
        });
    }

    onCheckboxWithTextChange = (id, checked, value) => {
        this.setStateProp(checked, id, value);
    };

    getCheckboxesData = (options) => Object.values(options).map((option) => ({
        id: option.id,
        checked: option.active,
        label: translate(`pages.settings.${option.name}`),
        noValue: !option.price,
        value: option.price ? parseFloat(option.price.value) : 0,
    }));

    setStateProp = (active, id, value) => {
        const { options } = this.state;

        const activeAmount = Object.keys(options)
            .map(key => options[key])
            .filter(option => option.active && !option.delete)
            .length;

        // There should be at least one active option
        if (!active && activeAmount === 1) {
            return null;
        }

        options[id].active = active;
        options[id].value = value;

        return this.setStateAndPropagate(options);
    };

    setStateAndPropagate(options) {
        const checkboxes = this.getCheckboxesData(options);

        this.setState({
            options,
            checkboxes,
        });

        const settings = Object.keys(options).map((id) => ({
            ...options[id],
            key: options[id].key,
            id,
        }));

        const { mealNonePrice, mealBreadPrice, mealHotPrice } = this.props;
        const mealNoneEnabled = settings.find(setting => setting.key === 'MEAL_NONE_ENABLED');
        const mealBreadEnabled = settings.find(setting => setting.key === 'MEAL_BREAD_ENABLED');
        const mealHotEnabled = settings.find(setting => setting.key === 'MEAL_HOT_ENABLED');

        const mealNonePriceSetting = {
            ...mealNonePrice,
            value: mealNoneEnabled.value,
        };

        const mealBreadPriceSetting = {
            ...mealBreadPrice,
            value: mealBreadEnabled.value,
        };
        const mealHotPriceSetting = {
            ...mealHotPrice,
            value: mealHotEnabled.value,
        };

        this.props.onChange([
            ...settings,
            ...[mealNonePrice ? mealNonePriceSetting : []],
            ...[mealBreadPrice ? mealBreadPriceSetting : []],
            ...[mealHotPrice ? mealHotPriceSetting : []],
        ]);
    }

    render() {
        const { checkboxes } = this.state;

        return (
            <form className="settings-meals" id="settings-meals" onSubmit={this.onSubmit} onChange={this.onChange}>
                <div className="settings-card-section-title">
                    { translate('pages.settings.whichMealsDoYouWantToShow') }
                </div>
                <div className="settings-meals-checkboxes">
                    <CheckboxesWithTextForm
                        checkBoxTitle={translate('common.meal')}
                        textTitle={translate('pages.settings.priceInEuros')}
                        checkboxes={checkboxes}
                        onCheckboxChange={this.onCheckboxWithTextChange}
                    />
                </div>
            </form>

        );
    }
}

SettingsMeals.propTypes = {
    onChange: PropTypes.func.isRequired,
    mealNoneEnabled: PropTypes.object.isRequired,
    mealNonePrice: PropTypes.object.isRequired,
    mealBreadEnabled: PropTypes.object.isRequired,
    mealHotEnabled: PropTypes.object.isRequired,
    mealBreadPrice: PropTypes.object.isRequired,
    mealHotPrice: PropTypes.object.isRequired,
};

export default SettingsMeals;
