import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { LeaveOfAbsenceResource } from '../LeaveOfAbsence/LeaveOfAbsence';
import { transformToLeaveOfAbsence } from '../LeaveOfAbsence/LeaveOfAbsenceTransformers';
import { UserResource } from '../User/User';
import { transformToBasicUser } from '../User/UserTransformers';
import { LeaveOfAbsenceHourResource, LoketLeaveOfAbsenceHour } from './LeaveOfAbsenceHour';
import { transformToLoketLeaveOfAbsenceHour } from './LeaveOfAbsenceHourTransformers';

export const getLoketLeaveOfAbsencesHoursApiCall = async (
    leaveOfAbsencesHourId: string,
): Promise<FetchCollectionResult<LoketLeaveOfAbsenceHour, string>> => {
    try {
        const includes = [
            'leaveOfAbsence',
            'leaveOfAbsence.user',
        ];

        const url = generateApiUrl({
            endpoint: `/leave-of-absence-hours/${leaveOfAbsencesHourId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, { method: 'GET' });
        const doc = await response.json();

        if (!isResourceDocument<LeaveOfAbsenceHourResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const leaveOfAbsenceResource = getIncluded(doc, doc.data, 'leaveOfAbsence') as LeaveOfAbsenceResource;
        const leaveOfAbsenceUserResource = getIncluded(doc, leaveOfAbsenceResource, 'user') as UserResource;

        const user = transformToBasicUser(leaveOfAbsenceUserResource);
        const leaveOfAbsence = transformToLeaveOfAbsence(leaveOfAbsenceResource, user);
        const data = transformToLoketLeaveOfAbsenceHour(doc.data, leaveOfAbsence);

        return {
            amountOfPages: 1,
            status: 200,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[getLoketLeaveOfAbsencesHoursApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
