import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { FullLoketUser } from '../../entities/LoketUser/LoketUser';
import { Track } from '../../entities/Track/Track';
import { FullUser, User, UserProfile } from '../../entities/User/User';

export type UserState = AsyncReduxState<{
    isFullUserLoading: boolean;
    isFullUserSuccessful: boolean;
    isLoketUserLoading: boolean;
    isPermissionsLoading: boolean;
    isResendRegistrationSuccessful: boolean;
    isResetUserPasswordSuccessful: boolean;
    isSetUserStatusSuccessful: boolean;
    isSyncUserFailed: boolean;
    isSyncUserLoading: boolean;
    isSyncUserSuccessful: boolean;
    isUserProfileLoading: boolean;
    isUserTracksLoading: boolean;
    fullUser?: FullUser;
    loketError?: string;
    loketUser?: FullLoketUser;
    user?: User;
    userProfile?: UserProfile;
    userTracks: Track[];
}>;

const initialState: UserState = {
    ...initialAsyncReduxState,
    isFullUserLoading: false,
    isFullUserSuccessful: false,
    isLoketUserLoading: false,
    isPermissionsLoading: false,
    isResendRegistrationSuccessful: false,
    isResetUserPasswordSuccessful: false,
    isSetUserStatusSuccessful: false,
    isSyncUserFailed: false,
    isSyncUserLoading: false,
    isSyncUserSuccessful: false,
    isUserProfileLoading: false,
    isUserTracksLoading: false,
    userTracks: [],
};

export const userSlice = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        setIsFullUserLoading(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isFullUserLoading: action.payload,
            };
        },
        setIsFullUserSuccessful(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isFullUserSuccessful: action.payload,
            };
        },
        setIsLoketUserLoading(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isLoketUserLoading: action.payload,
            };
        },
        setIsResendRegistrationSuccessful(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isResendRegistrationSuccessful: action.payload,
            };
        },
        setIsResetUserPasswordSuccessful(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isResetUserPasswordSuccessful: action.payload,
            };
        },
        setIsSetUserStatusSuccessful(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isSetUserStatusSuccessful: action.payload,
            };
        },
        setIsSyncUserFailed(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isSyncUserFailed: action.payload,
            };
        },
        setIsSyncUserLoading(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isSyncUserLoading: action.payload,
            };
        },
        setIsSyncUserSuccessful(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isSyncUserSuccessful: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setIsPermissionsLoading(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isPermissionsLoading: action.payload,
            };
        },
        setIsUserProfileLoading(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isUserProfileLoading: action.payload,
            };
        },
        setIsUserTracksLoading(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isUserTracksLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): UserState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setFullUser(state, action: PayloadAction<FullUser | undefined>): UserState {
            return {
                ...state,
                fullUser: action.payload,
            };
        },
        setLoketError(state, action: PayloadAction<string | undefined>): UserState {
            return {
                ...state,
                loketError: action.payload,
            };
        },
        setLoketUser(state, action: PayloadAction<FullLoketUser | undefined>): UserState {
            return {
                ...state,
                loketUser: action.payload,
            };
        },
        setUser(state, action: PayloadAction<User | undefined>): UserState {
            return {
                ...state,
                user: action.payload,
            };
        },
        setUserProfile(state, action: PayloadAction<UserProfile | undefined>): UserState {
            return {
                ...state,
                userProfile: action.payload,
            };
        },
        setUserTracks(state, action: PayloadAction<Track[]>): UserState {
            return {
                ...state,
                userTracks: action.payload,
            };
        },
    },
});

export const {
    setIsFullUserLoading,
    setIsFullUserSuccessful,
    setIsLoketUserLoading,
    setIsPermissionsLoading,
    setIsResendRegistrationSuccessful,
    setIsResetUserPasswordSuccessful,
    setIsSetUserStatusSuccessful,
    setIsSyncUserFailed,
    setIsSyncUserLoading,
    setIsSyncUserSuccessful,
    setIsLoading,
    setIsSuccessful,
    setIsUserProfileLoading,
    setIsUserTracksLoading,
    setError,
    setFullUser,
    setLoketError,
    setLoketUser,
    setUser,
    setUserProfile,
    setUserTracks,
} = userSlice.actions;

export default userSlice.reducer;
