import { FC, FormEvent, ReactElement } from 'react';

import { FormActionButtons } from '../../../compositions';

import './ConfirmForm.scss';

interface ConfirmFormProps {
    isLoading: boolean;
    intro: string;
    submitText?: string;
    onCancel: () => void;
    onSubmit: () => void;
    className?: string;
}

const ConfirmForm: FC<ConfirmFormProps> = ({
    isLoading,
    intro,
    submitText,
    onCancel,
    onSubmit,
    className = '',
}): ReactElement => {
    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        onSubmit();
    };

    return (
        <form onSubmit={handleSubmit} className={`confirm-form ${className}`}>
            <div className="confirm-form__row">
                <p className="confirm-form__intro">
                    {intro}
                </p>
            </div>

            <FormActionButtons
                isLoading={isLoading}
                submitText={submitText}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default ConfirmForm;
