export enum DateFormat {
    apiDate = 'yyyy-MM-dd',
    apiDateTime = 'yyyy-MM-dd HH:mm:ss',
    date = 'dd-MM-yyyy',
    dateLabel = 'EEEE dd-MM-yyyy',
    dateLabelShort = 'EEEEEE dd-MM-yyyy',
    dateMonthShort = 'dd MMM',
    datePickerLabel = 'd LLL yyyy',
    datePickerDateLabel = 'd LLL',
    dateTime = 'dd-MM-yyyy HH:mm:ss',
    dateWithHoursMinutes = 'dd-MM-yyyy HH:mm',
    day = 'd',
    dayOfWeek = 'EEEEEE',
    dayOfWeekWithDate = 'EEEEEE d LLL',
    dayOfWeekWithDay = 'EEEEEE - d',
    hoursMinutes = 'HH:mm',
    hoursMinutesSeconds = 'HH:mm:ss',
    inputDate = 'yyyy-MM-dd',
    inputDateTime = "yyyy-MM-dd'T'HH:mm:ss",
    inputMonth = 'yyyy-MM',
    inputTime = 'HH:mm',
    inputWeek = "yyyy-'W'ww",
    jsonApiResource = "yyyy-MM-dd'T'HH:mm:ssXXX",
    print = 'EEEEEE dd MMMM yyyy',
    timeOnlyLabel = 'HH:mm',
    timeLabel = 'EEEE dd-MM-yyyy HH:mm',
    timeLabelShort = 'EEEEEE dd-MM-yyyy HH:mm',
    weekNumber = 'w',
    year = 'yyyy',
}
