import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { RootPortal } from '../../components';
import { PrintableLeaveOfAbsenceOverview } from '../../compositions';
import { LeaveOfAbsencesOverviewTopBar } from '../../containers';
import { setIncrementedSelectedDays, setTimePeriodAndSelectedDays } from '../../redux/@interface/calendar/calendarActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { TimeModeType } from '../../types';
import { getLeaveOfAbsencesOverviewCalendarDays } from './helpers/getLeaveOfAbsencesOverviewCalendarDays';

const ConnectedLeaveOfAbsencesOverviewTopBar: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);
    const { selectedDays } = useTypedSelector(state => state.calendarReducer);
    const { leaveOfAbsencesOverviewBodyRows } = useTypedSelector(state => state.leaveOfAbsencesOverviewReducer);
    const { isLoading: isLeaveOfAbsencesLoading } = useTypedSelector(state => state.pacoLeaveOfAbsencesReducer);
    const { basicUsers: users, isLoading: isUsersLoading } = useTypedSelector(state => state.pacoUsersReducer);

    const calendarDays = useMemo(() => (
        getLeaveOfAbsencesOverviewCalendarDays(selectedDays[0])
    ), [selectedDays]);

    useEffect((): () => void => {
        document.body.classList.add('has-printable-page');

        return () => document.body.classList.remove('has-printable-page');
    }, []);

    const handleExportClick = (): void => {
        window.print();
    };

    useEffect((): void => {
        dispatch(setTimePeriodAndSelectedDays(TimeModeType.week, selectedDays[0], payrollPeriods));
    }, []);

    const handleDateChange = (date: Date): void => {
        dispatch(setTimePeriodAndSelectedDays(TimeModeType.week, date, payrollPeriods));
    };

    const handleIncrementClick = (goForward: boolean): void => {
        dispatch(setIncrementedSelectedDays(TimeModeType.week, selectedDays, goForward, payrollPeriods));
    };

    return (
        <>
            <RootPortal>
                <PrintableLeaveOfAbsenceOverview
                    calendarDays={calendarDays}
                    leaveOfAbsencesOverviewBodyRows={leaveOfAbsencesOverviewBodyRows}
                    users={users}
                />
            </RootPortal>
            <LeaveOfAbsencesOverviewTopBar
                isLoading={isLeaveOfAbsencesLoading || isUsersLoading}
                selectedDays={calendarDays}
                onDateChange={handleDateChange}
                onIncrementClick={handleIncrementClick}
                onExportClick={handleExportClick}
            />
        </>
    );
};

export default ConnectedLeaveOfAbsencesOverviewTopBar;
