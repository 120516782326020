import React, { FC, useState } from 'react';

import classnames from 'classnames';

import Icon from '../../../../../../components/style/Icon/Icon';

import './LeaveOfAbsenceSaldo.scss';

interface LeaveOfAbsenceSaldoProps {
    title: string;
    hours: number;
    color?: string;
    isTitle?: boolean;
    className?: string;
    children?: React.ReactNode;
}

const LeaveOfAbsenceSaldo: FC<LeaveOfAbsenceSaldoProps> = ({
    title,
    hours,
    color,
    isTitle = false,
    children,
    className = '',
}) => {
    const [showChildren, setShowChildren] = useState(false);

    const toggleChildren = () => {
        setShowChildren(!showChildren);
    };

    const hasChildren = Array.isArray(children) ? !!children.length : !!children;
    const classNames = classnames('leave-of-absence-saldo', {
        'leave-of-absence-saldo--is-title': isTitle,
        'leave-of-absence-saldo--has-children': hasChildren,
        'leave-of-absence-saldo--has-no-children': !hasChildren,
        'leave-of-absence-saldo--show-children': hasChildren && showChildren,
    }, className);

    return (
        <div className={classNames}>
            <div className="leave-of-absence-saldo__content">
                { color && <div className="leave-of-absence-saldo__color" style={{ background: color }} /> }
                <div className="leave-of-absence-saldo__title">
                    {title}
                </div>
                <div className="leave-of-absence-saldo__hours-and-button-toggle-container">
                    <div className="leave-of-absence-saldo__hours">
                        {`${hours} uur`}
                    </div>
                    <div className="leave-of-absence-saldo__button-toggle-container">
                        <button type="button" className="leave-of-absence-saldo__button-toggle" onClick={toggleChildren}>
                            <Icon className="leave-of-absence-saldo__button-toggle-arrow" color="primary" kind="keyboard_arrow_down" />
                        </button>
                    </div>
                </div>
            </div>
            { children && (
                <div className="leave-of-absence-saldo__children">
                    { children }
                </div>
            )}
        </div>
    );
};

export default LeaveOfAbsenceSaldo;
