import React, { Component } from 'react';

import PropTypes from 'prop-types';
import {
    Badge,
    Card,
    CardBody,
    Col,
    Row,
} from 'reactstrap';

import { doesPeriodOverlapWithLockedPayrollPeriod } from '../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import { transformLegacyRoleToRole } from '../../@paco/entities/Role/RoleTransformers';
import { TRACK_TYPE_REVIEW } from '../../constants';
import {
    checkPermission,
    getIconById,
    getObjProperty,
    getUserFullName,
} from '../../helpers';
import { convertDateToTime } from '../../helpers/date';
import { getPermissionToEdit } from '../../helpers/permissions/getPermissionToAction';
import { translate } from '../../helpers/translations/translator';
import getPermissionToApproveTrack from '../../pages/Tracks/helpers/getPermissionToApproveTrack';
import { transformBillableTimeToHourMinutes } from '../../services/TrackService/getTotalBillableTimeFromTracks';
import ButtonWithTooltip from '../ButtonWithTooltip/ButtonWithTooltip';
import InfoCircle from '../InfoCircle/InfoCircle';
import TrackDurationTooLongWarning from '../TrackDurationTooLongWarning/TrackDurationTooLongWarning';
import UserPageLink from '../UserPageLink/UserPageLink';
import { getTrackCardApproveButtonTooltipLabel, getTrackCardEditButtonLabelTooltipLabel } from './helpers';
import InfoCircles from './subcomponents/InfoCircles/InfoCircles';

import './TrackCard.scss';

class TrackCard extends Component {
    constructor(props) {
        super(props);
        this.element = React.createRef();
    }

    onApproveClick = (id) => {
        this.props.onApproveClick(id);
    };

    render() {
        const {
            track,
            permissions,
            currentUserRole,
            enableFeedback,
            currentUserId,
            payrollPeriods = [],
            showAbnormalTrackDurationWarning = false,
        } = this.props;
        const {
            deviationRemark,
            unplannedRemark,
            owner,
            shift,
        } = track;
        const userId = getObjProperty(track, 'owner.id');
        const department = getObjProperty(track, 'department.name');
        const group = getObjProperty(track, 'department.group.name');
        const shiftPlannings = getObjProperty(track, 'shift.shiftPlannings') || [];
        const shiftPlanning = shiftPlannings
            .find(planning => planning.user && planning.user.id === userId);
        const shiftTime = shift && `${convertDateToTime(shift.start)} - ${convertDateToTime(shift.end)}`;
        const shiftPlanningsTime = shiftPlanning && `${convertDateToTime(shiftPlanning.start)} - ${convertDateToTime(shiftPlanning.end)}`;
        const shiftsLabel = shiftPlanningsTime || shiftTime;
        const canViewSatisfaction = enableFeedback && checkPermission(permissions, 'view-all-feedback-ratings', 'track-card');
        const canViewSatisfactionFeedback = enableFeedback && checkPermission(permissions, 'view-all-feedback', 'track-card');
        const name = getUserFullName(owner);
        const legacyTrackOwnerRoles = getObjProperty(track, 'owner.roles') || [];
        const trackOwnerRoles = legacyTrackOwnerRoles.map(transformLegacyRoleToRole);
        // When roles of track owner are missing then default to true. https://endeavour-nl.atlassian.net/browse/VDV-1038
        const canEditTrack = trackOwnerRoles.length ? getPermissionToEdit(currentUserRole, trackOwnerRoles, permissions, 'tracks') : true;
        const payrollPeriodError = doesPeriodOverlapWithLockedPayrollPeriod(
            transformToPeriod(new Date(track.checkIn), new Date(track.checkOut.date)),
            payrollPeriods,
        );
        const canApproveTrack = getPermissionToApproveTrack(currentUserRole, trackOwnerRoles, permissions, userId, currentUserId);
        const acceptButtonTooltipText = getTrackCardApproveButtonTooltipLabel(payrollPeriodError, !department);
        const editButtonTooltipLabel = getTrackCardEditButtonLabelTooltipLabel(payrollPeriodError);
        const billableEnd = track.billableEnd?.transformToUpToButExcludingDate().date;

        return (
            <div ref={this.element} className="track-card">
                <Card
                    style={{ borderRadius: 10 }}
                >
                    <CardBody className="py-4">
                        <div className="d-flex justify-content-between">
                            <UserPageLink className="track-card__name" name={name} id={track.owner.id} />
                            <InfoCircles
                                track={track}
                                canViewSatisfaction={canViewSatisfaction}
                                canViewSatisfactionFeedback={canViewSatisfactionFeedback}
                            />
                        </div>
                        <div>
                            { department && (
                                <Badge color="secondary">
                                    { `${group ? `${group} - ` : ''}${department}` }
                                </Badge>
                            ) }
                        </div>
                        <div className="mt-3 mb-3">
                            <Row>
                                {shiftsLabel && (
                                    <Col className="mb-2" md={3} sm={6} xs={6}>
                                        <div className="font-weight-semi-bold">{translate('common.shift')}</div>
                                        <div>
                                            {shiftsLabel}
                                        </div>
                                    </Col>
                                )}
                                <Col className="mb-2" md={3} sm={6} xs={6}>
                                    <div className="font-weight-semi-bold">{translate('common.track')}</div>
                                    <div>{`${convertDateToTime(track.billableStart)} - ${billableEnd ? convertDateToTime(billableEnd) : translate('common.unknown')}`}</div>
                                </Col>
                                <Col className="mb-2" md={3} sm={6} xs={6}>
                                    <div className="font-weight-semi-bold">{translate('common.deviation')}</div>
                                    <div>{`${track.deviation} ${translate('common.minutes')}`}</div>
                                </Col>
                                <Col className="mb-2" md={3} sm={6} xs={6}>
                                    <div className="font-weight-semi-bold">{translate('pages.tracks.hoursWorked')}</div>
                                    <div>{transformBillableTimeToHourMinutes(track.totalBillableTime)}</div>
                                </Col>
                            </Row>
                            <Row>
                                {(deviationRemark || unplannedRemark) && (
                                    <Col className="mb-2 py-3 d-flex">
                                        <InfoCircle
                                            tooltip="Feedback"
                                            icon={getIconById('balloon')}
                                        />
                                        <p className="pl-2 font-italic">{deviationRemark || unplannedRemark}</p>
                                    </Col>
                                )}
                            </Row>
                            {showAbnormalTrackDurationWarning && (
                                <Row>
                                    <Col>
                                        <TrackDurationTooLongWarning />
                                    </Col>
                                </Row>
                            )}
                        </div>
                        <div className="track-card__buttons">
                            <ButtonWithTooltip
                                disabled={!canEditTrack || payrollPeriodError}
                                tooltip={payrollPeriodError}
                                tooltipText={editButtonTooltipLabel}
                                color="link"
                                onClick={() => this.props.onReviewTrackClick(track, TRACK_TYPE_REVIEW)}
                                className="track-card__edit-button"
                            >
                                {translate('common.edit')}
                            </ButtonWithTooltip>
                            <ButtonWithTooltip
                                onClick={() => this.onApproveClick(track.id)}
                                tooltip={!department || payrollPeriodError}
                                tooltipText={acceptButtonTooltipText}
                                disabled={!canApproveTrack || !department || payrollPeriodError}
                                className="track-card__button"
                            >
                                {translate('common.accept')}
                            </ButtonWithTooltip>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

TrackCard.propTypes = {
    track: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    currentUserRole: PropTypes.object.isRequired,
    payrollPeriods: PropTypes.array,
    enableFeedback: PropTypes.bool.isRequired,
    onReviewTrackClick: PropTypes.func.isRequired,
    onApproveClick: PropTypes.func.isRequired,
    currentUserId: PropTypes.string,
    showAbnormalTrackDurationWarning: PropTypes.bool,
};

export default TrackCard;
