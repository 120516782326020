import { AbsenceResource, LeaveOfAbsenceResource, LeaveType } from '../../../../../models';

const filterAbsenceByType = (
    resource: AbsenceResource | LeaveOfAbsenceResource,
    type: LeaveType | AbsenceResource['type'],
): boolean => {
    if (!type) {
        return true;
    }

    if (type === resource.type) {
        return true;
    }

    if ('leaveOfAbsenceHours' in resource && resource.leaveOfAbsenceHours?.length) {
        return resource.leaveOfAbsenceHours[0].leaveType === type;
    }

    return false;
};

export {
    filterAbsenceByType,
};
