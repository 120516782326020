import trans from '../../@paco/helpers/trans';

export const TrackRoutes = {
    open: () => trans('routes.tracks.open.link'),
    review: () => trans('routes.tracks.review.link'),
    finished: (trackId?: string, date?: string) => ((trackId && date) ? (
        `${trans('routes.tracks.finished.link')}/?id=${trackId}&date=${date}`
    ) : (
        trans('routes.tracks.finished.link')
    )),
};
