import { Dispatch } from 'redux';

import { TOAST_TYPE_PASS } from '../../../constants';
import { patchAbsence } from '../../../helpers/api/absencesApi';
import { getAbsencesInDateRangeLegacy } from '../../../helpers/api-ts/absences';
import { translate } from '../../../helpers/translations/translator';
import {
    Absence,
    PayrollPeriodViewModel,
    PayrollPeriodWithHours,
    WeekWithHours,
} from '../../../models';
import { EmploymentTypeSlug } from '../../../models/EmploymentType';
import getIncompleteAbsences from '../../../services/AbsenceLegacyService/getIncompleteAbsences';
import { updateAbsenceHours } from '../../absences-ts/absencesHelpers';
import { setAppToast } from '../../app/appActions';
import {
    setAbsences,
    setError,
    setIsLoading,
    setIsSuccessful,
} from './absencesReducer';

export const fetchIncompleteAbsences = (payrollPeriod: PayrollPeriodViewModel) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const { start, end } = payrollPeriod;

    try {
        const response = await getAbsencesInDateRangeLegacy(
            start,
            end.date,
            undefined,
            undefined,
            ['user', 'user.roles', 'user.employmentType', 'absenceHours', 'absenceHours.payrollPeriod'],
        );

        const filteredAbsences = getIncompleteAbsences(response.data, payrollPeriod);
        const filteredAbsencesWithFilteredUsers = filteredAbsences.filter(absence => absence.user.employmentType?.slug !== (EmploymentTypeSlug.partTime || EmploymentTypeSlug.temporaryWorker));

        dispatch(setAbsences(filteredAbsencesWithFilteredUsers));
    } catch (error) {
        console.error('[fetchIncompleteAbsences]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editAbsence = (
    absence: Absence,
    startDate: string,
    endDate: string | null,
    periods: PayrollPeriodWithHours[],
    weekWithHours: WeekWithHours[],
    waitingDayHours: number,
    payrollPeriod: PayrollPeriodViewModel,
) => async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(setIsSuccessful(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        await Promise.all([
            // @ts-ignore
            patchAbsence(absence.id, startDate, endDate),
            updateAbsenceHours(
                absence.id,
                absence.absenceHours || [],
                weekWithHours,
                waitingDayHours,
            ),
        ]);

        await dispatch(fetchIncompleteAbsences(payrollPeriod));

        dispatch(setAppToast(translate('pages.absences.editAbsenceSuccess'), TOAST_TYPE_PASS));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editAbsence]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
