import {
    Shift,
    ShiftPlanningViewModel,
    ShiftViewModel,
    TemporaryWorkerViewModel,
} from '../../../models';

export default function getShiftPlannedUserAndWorkersCount(
    shift: Shift | ShiftViewModel,
): number {
    const shiftPlannings = (shift?.shiftPlannings || []) as unknown as ShiftPlanningViewModel[];
    const temporaryWorkers = (shift?.temporaryWorkers || []) as unknown as TemporaryWorkerViewModel[];

    return shiftPlannings.filter(shiftPlanning => shiftPlanning.planned).length + temporaryWorkers.length;
}
