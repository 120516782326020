import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { getObjProperty } from '../../../../helpers';

import './SidebarNav.scss';

// eslint-disable-next-line react/prefer-stateless-function
class SidebarNav extends Component {
    render() {
        const {
            title,
            departments,
            onLinkClick = null,
            activeLink = null,
        } = this.props;
        return (
            <div className="sidebar-nav">
                <div className="sidebar-nav-title">{title}</div>
                { [...departments]
                    .sort((a, b) => (getObjProperty(b, 'departmentGroup.name') || '').localeCompare((getObjProperty(a, 'departmentGroup.name') || '')))
                    .map((department) => {
                        const name = getObjProperty(department, 'name');
                        const group = getObjProperty(department, 'departmentGroup.name');
                        const active = activeLink === department.id;
                        const className = `sidebar-nav-button 
                            ${active ? 'sidebar-nav-button--active' : ''}
                            ${!onLinkClick ? 'sidebar-nav-button--disabled' : ''}`;

                        return (
                            <button
                                key={department.id || 'main'}
                                className={className}
                                type="button"
                                onClick={() => onLinkClick && onLinkClick(department.id)}
                            >
                                {`${group ? `${group} -` : ''} ${name}`}
                            </button>
                        );
                    }) }
            </div>
        );
    }
}

SidebarNav.propTypes = {
    title: PropTypes.string.isRequired,
    departments: PropTypes.array.isRequired,
    onLinkClick: PropTypes.func,
    activeLink: PropTypes.string,
};

export default SidebarNav;
