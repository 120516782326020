export const last = <Type>(filenameParts: Type[]): Type | null => (
    filenameParts[filenameParts.length - 1]
);

export const lastOrNull = <Type>(parts: Type[]): Type | null => (
    parts.length > 0 ? last(parts) : null
);

export const arrayOfStringsAreEqual = (array1: string[], array2: string[]): boolean => (
    JSON.stringify(array1.sort()) === JSON.stringify(array2.sort())
);

export default lastOrNull;
