import { FC, PropsWithChildren, ReactElement } from 'react';

import Tooltip from 'react-tooltip-lite';

interface ElementWithTooltipProps {
    tooltipIsActive?: boolean;
    tooltipText?: string;
    className?: string;
}

const ElementWithTooltip: FC<PropsWithChildren<ElementWithTooltipProps>> = ({
    tooltipIsActive = false,
    tooltipText,
    children,
    className = '',
}): ReactElement => (
    // TODO: Don't return fragment and fix return type
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
        {tooltipIsActive ? (
            // @ts-ignore
            <Tooltip
                className={`element-with-tooltip ${className}`}
                content={tooltipText}
                arrowSize={6}
                padding={7}
            >
                {children}
            </Tooltip>
            ) : (
                children
            )}
    </>
);

export default ElementWithTooltip;
