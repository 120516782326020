import { FC, PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

// Import of ElementWithTooltip must be directly for the order of styling
import ElementWithTooltip from '../../../compositions/ElementWithTooltip/ElementWithTooltip';
import { Button } from '../..';

import './InputLabel.scss';

interface InputLabelProps {
    text: string;
    requiredTextIsAlwaysVisible?: boolean;
    required?: boolean;
    requiredText?: string;
    tooltip?: string;
    className?: string;
    requiredClassName?: string;
    tooltipButtonClassName?: string;
    tooltipClassName?: string;
}

const InputLabel: FC<PropsWithChildren<InputLabelProps>> = ({
    text,
    requiredTextIsAlwaysVisible = false,
    required = false,
    requiredText = '',
    tooltip,
    className = '',
    requiredClassName = '',
    tooltipButtonClassName,
    tooltipClassName,
    children,
}): ReactElement => {
    const requiredTextClassNames = classNames('input-label__required-text', {
        'input-label__required-text--is-open': requiredTextIsAlwaysVisible,
    });

    return (
        <div className={`input-label ${className}`}>
            {text}
            {(required || requiredText) && (
                <span className={`input-label__required-star ${requiredClassName}`}>
                    <span className={requiredTextClassNames}>{requiredText}</span>
                </span>
            )}
            {children}
            {tooltip && (
                <ElementWithTooltip
                    tooltipIsActive
                    tooltipText={tooltip}
                    className={`input-label__element-with-tooltip ${tooltipClassName}`}
                >
                    <Button text="?" className={`input-label__tooltip-button ${tooltipButtonClassName}`} />
                </ElementWithTooltip>
            )}
        </div>
    );
};

export default InputLabel;
