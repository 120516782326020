import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { Absence } from '../../entities/Absence/Absence';
import { Department } from '../../entities/Department/Department';
import { DistrictDivision } from '../../entities/DistrictDivision/DistrictDivision';
import { DistrictDivisionDistrict } from '../../entities/DistrictDivisionDistrict/DistrictDivisionDistrict';
import { DistrictDivisionDistrictPlanning } from '../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { ShiftIndex } from '../../entities/Shift/Shift';
import { districtDivisionDistrictPlanningSlice } from '../districtDivisionDistrictPlanning/districtDivisionDistrictPlanningReducer';
import {
    getDistrictDivisionDistrictPlanningRelations,
    getDistrictDivisionGroupWithoutPlannings,
    getDistrictDivisionWithNewDistrictDivisionDistrict,
} from './districtDivisionHelpers';

enum LocalStorageItemKey {
    selectedDepartments = 'selectedDistrictDivisionDepartments',
}

export type DistrictDivisionState = AsyncReduxState<{
    isAddDistrictDivisionCommentLoading: boolean;
    isAddDistrictDivisionCommentSuccessful: boolean;
    isAddDistrictDivisionLoading: boolean;
    isAddDistrictDivisionSuccessful: boolean;
    isDeleteDistrictDivisionSuccessful: boolean;
    isAbsencesLoading: boolean;
    isShiftsLoading: boolean;
    absences: Absence[],
    districtDivision?: DistrictDivision;
    districtDivisions: DistrictDivision[];
    shifts: ShiftIndex[];
    selectedDepartments: Department[];
}>;

const localStorageSelectedDepartments = localStorage.getItem(LocalStorageItemKey.selectedDepartments);
const selectedDepartments = localStorageSelectedDepartments ? JSON.parse(localStorageSelectedDepartments) : [];

const initialState: DistrictDivisionState = {
    ...initialAsyncReduxState,
    isAddDistrictDivisionCommentLoading: false,
    isAddDistrictDivisionCommentSuccessful: false,
    isAddDistrictDivisionLoading: false,
    isAddDistrictDivisionSuccessful: false,
    isDeleteDistrictDivisionSuccessful: false,
    isAbsencesLoading: false,
    isShiftsLoading: false,
    absences: [],
    districtDivisions: [],
    shifts: [],
    selectedDepartments,
};

export const districtDivisionSlice = createSlice({
    name: 'districtDivisionReducer',
    initialState,
    reducers: {
        setDistrictDivision(state, action: PayloadAction<DistrictDivision | undefined>): DistrictDivisionState {
            return {
                ...state,
                districtDivision: action.payload,
                isAddDistrictDivisionCommentSuccessful: false,
            };
        },
        setDistrictDivisions(state, action: PayloadAction<DistrictDivision[]>): DistrictDivisionState {
            return {
                ...state,
                districtDivisions: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DistrictDivisionState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsAddDistrictDivisionSuccessful(state, action: PayloadAction<boolean>): DistrictDivisionState {
            return {
                ...state,
                isAddDistrictDivisionSuccessful: action.payload,
            };
        },
        setIsDeleteDistrictDivisionSuccessful(state, action: PayloadAction<boolean>): DistrictDivisionState {
            return {
                ...state,
                isDeleteDistrictDivisionSuccessful: action.payload,
            };
        },
        setIsAbsencesLoading(state, action: PayloadAction<boolean>): DistrictDivisionState {
            return {
                ...state,
                isAbsencesLoading: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): DistrictDivisionState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsAddDistrictDivisionLoading(state, action: PayloadAction<boolean>): DistrictDivisionState {
            return {
                ...state,
                isAddDistrictDivisionLoading: action.payload,
            };
        },
        setIsAddDistrictDivisionCommentLoading(state, action: PayloadAction<boolean>): DistrictDivisionState {
            return {
                ...state,
                isAddDistrictDivisionCommentLoading: action.payload,
            };
        },
        setIsAddDistrictDivisionCommentSuccessful(state, action: PayloadAction<boolean>): DistrictDivisionState {
            return {
                ...state,
                isAddDistrictDivisionCommentSuccessful: action.payload,
            };
        },
        setIsShiftsLoading(state, action: PayloadAction<boolean>): DistrictDivisionState {
            return {
                ...state,
                isShiftsLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): DistrictDivisionState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setAbsences(state, action: PayloadAction<Absence[]>): DistrictDivisionState {
            return {
                ...state,
                absences: action.payload,
            };
        },
        setShifts(state, action: PayloadAction<ShiftIndex[]>): DistrictDivisionState {
            return {
                ...state,
                shifts: action.payload,
            };
        },
        setSelectedDepartments(state, action: PayloadAction<Department[]>): DistrictDivisionState {
            localStorage.setItem(LocalStorageItemKey.selectedDepartments, JSON.stringify(action.payload));

            return {
                ...state,
                selectedDepartments: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(districtDivisionDistrictPlanningSlice.actions.setDistrictDivisionDistrictPlanning, (state, action) => {
            const { districtDivision } = state;

            if (!districtDivision) {
                return state;
            }

            const districtDivisionGroup = districtDivision.districtDivisionGroups
                .find(group => group.districtDivisionDistricts.some(district => district.id === action.payload.districtDivisionDistrictId));

            if (!districtDivisionGroup) {
                return state;
            }

            // Remove any plannings with the same id from the group to prevent duplicates.
            const updatedDistrictDivisionGroup = getDistrictDivisionGroupWithoutPlannings(districtDivisionGroup, [action.payload.id]);

            const districtDivisionGroupDistrict = updatedDistrictDivisionGroup.districtDivisionDistricts.find((district) => district.id === action.payload.districtDivisionDistrictId);

            if (!districtDivisionGroupDistrict) {
                return state;
            }

            // Add new planning to districtDivisionDistrict
            const updatedDistrictDivisionDistrict: DistrictDivisionDistrict = {
                ...districtDivisionGroupDistrict,
                districtDivisionDistrictPlannings: [
                    ...districtDivisionGroupDistrict.districtDivisionDistrictPlannings,
                    action.payload,
                ].sort((a, b) => (a.planningOrder - b.planningOrder)),
            };

            const updatedDistrictDivision = getDistrictDivisionWithNewDistrictDivisionDistrict(
                districtDivision,
                updatedDistrictDivisionGroup,
                updatedDistrictDivisionDistrict,
            );

            return {
                ...state,
                districtDivision: updatedDistrictDivision,
            };
        }).addCase(districtDivisionDistrictPlanningSlice.actions.setLastRemovedDistrictDivisionDistrictPlanningId, (state, action) => {
            const {
                districtDivision,
                districtDivisionGroup,
                districtDivisionDistrict,
                districtDivisionDistrictPlanning,
            } = getDistrictDivisionDistrictPlanningRelations(action.payload, state.districtDivision);

            if (!districtDivision || !districtDivisionGroup || !districtDivisionDistrict || !districtDivisionDistrictPlanning) {
                return state;
            }

            const updatedDistrictDivisionDistrict: DistrictDivisionDistrict = {
                ...districtDivisionDistrict,
                districtDivisionDistrictPlannings: districtDivisionDistrict.districtDivisionDistrictPlannings
                    .filter(planning => planning.id !== action.payload),
            };

            const updatedDistrictDivision = getDistrictDivisionWithNewDistrictDivisionDistrict(
                districtDivision,
                districtDivisionGroup,
                updatedDistrictDivisionDistrict,
            );

            return {
                ...state,
                districtDivision: updatedDistrictDivision,
            };
        }).addCase(districtDivisionDistrictPlanningSlice.actions.setComment, (state, action) => {
            const {
                districtDivision,
                districtDivisionGroup,
                districtDivisionDistrict,
                districtDivisionDistrictPlanning,
            } = getDistrictDivisionDistrictPlanningRelations(action.payload.districtDivisionDistrictPlanningId, state.districtDivision);

            if (!districtDivision || !districtDivisionGroup || !districtDivisionDistrict || !districtDivisionDistrictPlanning) {
                return state;
            }

            const updatedDistrictDivisionDistrictPlanning: DistrictDivisionDistrictPlanning = {
                ...districtDivisionDistrictPlanning,
                comments: [
                    ...districtDivisionDistrictPlanning.comments,
                    action.payload.comment,
                ],
            };

            const updatedDistrictDivisionDistrict: DistrictDivisionDistrict = {
                ...districtDivisionDistrict,
                districtDivisionDistrictPlannings: [
                    ...districtDivisionDistrict.districtDivisionDistrictPlannings
                        .filter(planning => planning.id !== action.payload.districtDivisionDistrictPlanningId),
                    updatedDistrictDivisionDistrictPlanning,
                ].sort((a, b) => (a.planningOrder - b.planningOrder)),
            };

            const updatedDistrictDivision = getDistrictDivisionWithNewDistrictDivisionDistrict(
                districtDivision,
                districtDivisionGroup,
                updatedDistrictDivisionDistrict,
            );

            return {
                ...state,
                districtDivision: updatedDistrictDivision,
            };
        });
    },
});

export const {
    setAbsences,
    setDistrictDivision,
    setDistrictDivisions,
    setError,
    setIsAbsencesLoading,
    setIsAddDistrictDivisionLoading,
    setIsAddDistrictDivisionSuccessful,
    setIsDeleteDistrictDivisionSuccessful,
    setIsLoading,
    setIsAddDistrictDivisionCommentLoading,
    setIsAddDistrictDivisionCommentSuccessful,
    setIsShiftsLoading,
    setIsSuccessful,
    setShifts,
    setSelectedDepartments,
} = districtDivisionSlice.actions;

export default districtDivisionSlice.reducer;
