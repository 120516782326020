import axios from 'axios';

import {
    AddBaseScheduleShiftRequest,
    BaseScheduleShiftResource,
    BaseScheduleShiftViewModel,
    EditBaseScheduleShiftRequest,
} from './BaseScheduleShift';
import { transformToBaseScheduleShift } from './BaseScheduleShiftTransformers';

export const getBaseScheduleShiftApiCall = async (id: string): Promise<BaseScheduleShiftViewModel> => {
    const includes = [
        'baseScheduleShiftPlannings',
        'baseScheduleShiftPlannings.user',
        'baseScheduleShiftPlannings.user.person',
        'baseScheduleShiftPlannings.user.employmentType',
        'baseScheduleShiftPlannings.user.experience',
        'department',
        'department.group',
    ];

    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedule-shifts/${id}`);

    url.searchParams.append('include', includes.join(','));

    const response = await axios.get<BaseScheduleShiftResource>(String(url));

    return transformToBaseScheduleShift(response.data);
};

export const editBaseScheduleShiftApiCall = async (body: EditBaseScheduleShiftRequest, baseScheduleShiftId: string): Promise<void> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedule-shifts/${baseScheduleShiftId}`);

    const includes = [
        'department',
    ];

    url.searchParams.append('include', includes.join(','));

    await axios.patch<BaseScheduleShiftResource>(String(url), { data: body });
};

export const postBaseScheduleShiftApiCall = async (body: AddBaseScheduleShiftRequest): Promise<BaseScheduleShiftViewModel> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedule-shifts`);

    const includes = [
        'baseScheduleShiftPlannings',
        'baseScheduleShiftPlannings.user',
        'baseScheduleShiftPlannings.user.person',
        'baseScheduleShiftPlannings.user.employmentType',
        'baseScheduleShiftPlannings.user.experience',
        'department',
        'department.group',
    ];

    url.searchParams.append('include', includes.join(','));

    const response = await axios.post<BaseScheduleShiftResource>(String(url), { data: body });

    return transformToBaseScheduleShift(response.data);
};

export const deleteBaseScheduleShiftApiCall = async (id: string): Promise<void> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/base-schedule-shifts/${id}`);

    await axios.delete(String(url));
};
