import { FC } from 'react';

import classnames from 'classnames';

import { Setting } from '../../../../entities/Setting/Setting';
import { PrintableDistrictDivisionPageInterface } from '../../models';
import { PrintableDistrictDivisionColumn } from '../index';

import './PrintableDistrictDivisionPage.scss';

interface PrintableDistrictDivisionPageProps {
    isSizer?: boolean;
    page: PrintableDistrictDivisionPageInterface;
    settings: Setting[];
    className?: string;
}

const PrintableDistrictDivisionPage: FC<PrintableDistrictDivisionPageProps> = ({
    isSizer,
    page,
    settings,
    className = '',
}) => {
    const pageClassName = classnames('printable-district-division-page', {
        'printable-district-division-page--is-sizer': isSizer,
    }, className);

    return (
        <div className={pageClassName}>
            <div className="printable-district-division-page__columns-container">
                {page.columns.map(column => (
                    <PrintableDistrictDivisionColumn
                        key={column.id}
                        isSizer={isSizer}
                        blocks={column.blocks}
                        settings={settings}
                        className="printable-district-division-page__column"
                    />
                ))}
            </div>
        </div>
    );
};

export default PrintableDistrictDivisionPage;
