import React, { FC, ReactElement } from 'react';

import ButtonAuthenticate from '../../../../../components/ButtonAuthenticate/ButtonAuthenticate';
import FilterBlock from '../../../../../components/Filters/subcomponents/FilterBlock/FilterBlock';
import { ConnectedShowEmployeesFilter } from '../../../../../connectors';
import { translate } from '../../../../../helpers/translations/translator';

interface BaseScheduleSidebarProps {
    addShiftDate: Date;
    canAddBaseScheduleShifts: boolean;
    onAddShift: (date: Date) => void;
    className?: string;
}

const BaseScheduleSidebar: FC<BaseScheduleSidebarProps> = ({
    addShiftDate,
    canAddBaseScheduleShifts,
    onAddShift,
    className = '',
}): ReactElement => {
    const handleAddShift = () => {
        onAddShift(addShiftDate);
    };

    return (
        <div className={`base-schedules-sidebar ${className}`}>
            {canAddBaseScheduleShifts && (
                <FilterBlock hideMobile>
                    <ButtonAuthenticate
                        text={translate('common.addEntity', { entity: translate('common.shift') })}
                        onClick={handleAddShift}
                    />
                </FilterBlock>
            )}

            <ConnectedShowEmployeesFilter />
        </div>
    );
};

export default BaseScheduleSidebar;
