import { Setting } from '../../../@paco/entities/Setting/Setting';
import { getSetting } from '../../../@paco/entities/Setting/SettingHelpers';
import { getHours, getUserAge } from '../../../helpers/date';
import UpToAndIncludingDate from '../../../helpers/date/UpToAndIncludingDate';

export const isUserAgeBelow18AndShiftIsAfter23h = (
    settings: Setting[],
    userBirthDay: Date,
    shiftPlanningEnd: UpToAndIncludingDate,
    now: Date,
): boolean => {
    const underAgeSetting = getSetting('ENABLE_CAO_MESSAGE_UNDERAGE_EMPLOYEE', settings);

    if (!underAgeSetting || !underAgeSetting.isEnabled) {
        return false;
    }

    const userAge = getUserAge(now, userBirthDay);
    const hours = getHours(shiftPlanningEnd.date);
    const shiftEndsAfter23 = hours >= 23 || hours <= 3;

    return userAge < 18 && shiftEndsAfter23;
};
