import {
    createContext,
    FC,
    PropsWithChildren,
    useMemo,
    useState,
} from 'react';

export interface PrintableBlockOffset {
    id: string;
    offsetTop: number;
    clientHeight: number;
}

interface PrintableBlockOffsetsContextProps {
    offsets: PrintableBlockOffset[];
    addOffset?: (offset: PrintableBlockOffset) => void;
    reset?: () => void;
}

export const PrintableBlockOffsetsContext = createContext<PrintableBlockOffsetsContextProps>({
    offsets: [],
});

const offsetsArray: PrintableBlockOffset[] = [];

const PrintableBlockOffsetsProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [offsets, setOffsets] = useState<PrintableBlockOffset[]>([]);

    const addOffset = (offset: PrintableBlockOffset): void => {
        const index = offsetsArray.findIndex(entry => entry.id === offset.id);
        if (index !== -1) {
            offsetsArray.splice(index, 1, offset);
        } else {
            offsetsArray.push(offset);
        }

        setOffsets([...offsetsArray]);
    };

    const reset = (): void => {
        offsetsArray.splice(0, offsetsArray.length);
        setOffsets([]);
    };

    const value = useMemo(() => ({
        offsets,
        addOffset,
        reset,
    }), [offsets]);

    return (
        <PrintableBlockOffsetsContext.Provider
            value={value}
        >
            {children}
        </PrintableBlockOffsetsContext.Provider>
    );
};

export default PrintableBlockOffsetsProvider;
