import { FC, ReactElement } from 'react';

import { TableCell, TableCellSkeleton, TableRow } from '../../../../components';
import { SkeletonTable } from '../../../../compositions';
import trans from '../../../../helpers/trans';

interface DistrictDivisionGroupTemplateTableSkeletonProps {
    className?: string;
}

const DistrictDivisionGroupTemplateTableSkeleton: FC<DistrictDivisionGroupTemplateTableSkeletonProps> = ({
    className = '',
}): ReactElement => {
    const tableHeader = [
        trans('common.districtDivisions'),
        trans('common.amountOfEmployees'),
    ];

    return (
        <SkeletonTable
            tableHeaders={tableHeader}
            className={`district-division-group-template-table-skeleton ${className}`}
        >
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="medium" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="small" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="large" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <TableCellSkeleton size="small" />
                </TableCell>
                <TableCell>
                    <TableCellSkeleton size="medium" />
                </TableCell>
            </TableRow>
        </SkeletonTable>
    );
};

export default DistrictDivisionGroupTemplateTableSkeleton;
