import { FC, useMemo } from 'react';

import { Button } from '../../components';
import { addDays, formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { TimeModeType } from '../../types';
import { DateRangeInput } from '../CalendarControls/subcomponents';
import { TimeModeSelect } from '../index';

import './CustomDateControls.scss';

interface CustomDateControlsProps {
    selectedDays: Date[];
    timeMode?: TimeModeType;
    timeModeOptions?: TimeModeType[];
    onDatesChange: (date1: Date, date2: Date) => void;
    onTimeModeChange?: (timeMode: TimeModeType) => void;
    onTodayButtonClick: () => void;
    className?: string;
}

const CustomDateControls: FC<CustomDateControlsProps> = ({
    selectedDays,
    timeMode,
    timeModeOptions = [],
    onDatesChange,
    onTimeModeChange,
    onTodayButtonClick,
    className = '',
}) => {
    const [startDateMax, endDateMin] = useMemo(() => {
        const startMax = selectedDays[selectedDays.length - 1]
            ? formatDate(addDays(selectedDays[selectedDays.length - 1], -1), 'yyyy-MM-dd')
            : undefined;

        const endMin = selectedDays[0]
            ? formatDate(addDays(selectedDays[0], 1), 'yyyy-MM-dd')
            : undefined;

        return [startMax, endMin];
    }, [selectedDays]);

    const handleStartDateChange = (date: Date): void => {
        onDatesChange(date, selectedDays[selectedDays.length - 1]);
    };

    const handleEndDateChange = (date: Date): void => {
        onDatesChange(selectedDays[0], date);
    };

    return (
        <div className={`custom-date-controls ${className}`}>
            <div className="custom-date-controls__date-range-inputs">
                <Button
                    text={trans('common.today')}
                    onClick={onTodayButtonClick}
                    className="custom-date-controls__date-today-button"
                />
                <div className="custom-date-controls__date-range-label">
                    {trans('common.startDate')}
                </div>
                <DateRangeInput
                    hasCaret
                    max={startDateMax}
                    selectedDays={[selectedDays[0]]}
                    type="date"
                    onDateChange={handleStartDateChange}
                    className="custom-date-controls__date-range-input"
                    labelClassName="custom-date-controls__date-range-input-label"
                />
                <div className="custom-date-controls__date-range-label">
                    {trans('common.endDate')}
                </div>
                <DateRangeInput
                    hasCaret
                    min={endDateMin}
                    selectedDays={[selectedDays[selectedDays.length - 1]]}
                    type="date"
                    onDateChange={handleEndDateChange}
                    className="custom-date-controls__date-range-input"
                    labelClassName="custom-date-controls__date-range-input-label"
                />
            </div>
            {timeMode && (
                <TimeModeSelect
                    options={timeModeOptions}
                    value={timeMode}
                    onChange={onTimeModeChange}
                    className="custom-date-controls__time-mode-select"
                />
            )}
        </div>
    );
};

export default CustomDateControls;
