import { FC } from 'react';

import { NavLink } from 'react-router-dom';

import { IconSet } from '../../../../components/Icon/Icon';
import trans from '../../../../helpers/trans';
import { DashboardSmallWidgetContent } from '../index';

import './DashboardSmallWidget.scss';

export interface DashboardSmallWidgetProps {
    isLoading?: boolean;
    icon?: keyof IconSet;
    path?: string;
    subTitle: string;
    subTitleAmount?: string;
    title: string;
    handleWidgetClick?: () => void;
    className?: string;
}

const DashboardSmallWidget: FC<DashboardSmallWidgetProps> = ({
    isLoading,
    icon,
    path,
    subTitle,
    subTitleAmount,
    title,
    handleWidgetClick,
    className = '',
}) => {
    const onWidgetClick = () => {
        if (path === `/${trans('routes.shifts.link')}/${trans('routes.shifts.calendar.link')}`) {
            handleWidgetClick?.();
        }
    };

    if (path) {
        return (
            <NavLink
                to={path}
                onClick={onWidgetClick}
                className={`dashboard-small-widget dashboard-small-widget--is-link ${className}`}
            >
                <DashboardSmallWidgetContent
                    showButton
                    isLoading={isLoading}
                    icon={icon}
                    subTitle={subTitle}
                    subTitleAmount={subTitleAmount}
                    title={title}
                    className="dashboard-small-widget__content"
                />
            </NavLink>
        );
    }

    return (
        <div className={`dashboard-small-widget ${className}`}>
            <DashboardSmallWidgetContent
                isLoading={isLoading}
                icon={icon}
                subTitle={subTitle}
                subTitleAmount={subTitleAmount}
                title={title}
                className="dashboard-small-widget__content"
            />
        </div>
    );
};

export default DashboardSmallWidget;
