import React, { PropsWithChildren, ReactNode } from 'react';

import classnames from 'classnames';

export interface TableAttribute<I> {
    key?: string;
    active?: boolean;
    align?: 'right' | 'left' | 'center';
    ascending?: boolean;
    isButton?: boolean;
    callback?: () => void,
    label?: ReactNode;
    type?: 'sort';
    customAction?: (item: I) => any;
    className?: string;
}

interface TableHeadProps<I> {
    attribute: TableAttribute<I>,
    index: number;
}

const TableHead = <I, >({
    attribute,
    index,
}: PropsWithChildren<TableHeadProps<I>>) => {
    const thClassNames = classnames({
        [`text-${attribute.align}`]: attribute.align,
        'has-sort-button': attribute.type === 'sort',
    }, attribute.className);

    return (
        <th
            key={`table_header.${index + 1}`}
            className={thClassNames}
        >
            {attribute.type === 'sort'
                ? (
                    <button className={attribute.active ? 'sort-button-active' : ''} type="button" onClick={attribute.callback}>
                        {attribute.label}
                        <span>{attribute.ascending ? <>&#9660;</> : <>&#9650;</>}</span>
                    </button>
                )
                : (
                    attribute.label
                )}
        </th>
    );
};

export default TableHead;
