import { areIntervalsOverlapping } from '../../helpers/date';
import {
    AbsenceHoursViewModel,
    AbsenceViewModel,
    LeaveOfAbsenceHoursViewModel,
} from '../../models';
import { getDatesFromWeekNumber } from '../WeekWithHours/getDatesFromWeekNumber';

export function doesAbsenceHourOverlapWithDates(
    absenceHour: AbsenceHoursViewModel | LeaveOfAbsenceHoursViewModel,
    start: Date,
    end: Date,
): boolean {
    const { payrollPeriod } = absenceHour;
    const year = payrollPeriod ? payrollPeriod.year : new Date().getFullYear();
    const dates = getDatesFromWeekNumber(year, absenceHour.weekNumber);

    return areIntervalsOverlapping(
        {
            start: dates.start,
            end: dates.end.date,
        },
        {
            start,
            end,
        },
    );
}

export default function getHoursFromAbsences(
    absences: AbsenceViewModel[],
    start: Date,
    end: Date,
): number {
    const absenceHours = absences.reduce((total: AbsenceHoursViewModel[], absence) => [
        ...total,
        ...(absence.absenceHours || []),
    ], []);

    return absenceHours.reduce((total: number, hours) => {
        if (doesAbsenceHourOverlapWithDates(hours, start, end)) {
            return total + (hours.hours || 0) + (hours.waitingDayHours || 0);
        }

        return total;
    }, 0);
}
