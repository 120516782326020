import {
    addDays as dateFnsAddDays,
    addHours as dateFnsAddHours,
    addMonths as dateFnsAddMonths,
    addSeconds as dateFnsAddSeconds,
    addWeeks as dateFnsAddWeeks,
    addYears as dateFnsAddYears,
    areIntervalsOverlapping as dateFnsAreIntervalsOverlapping,
    compareAsc as dateFnsCompareAsc,
    compareDesc as dateFnsCompareDesc,
    differenceInDays as dateFnsDifferenceInDays,
    differenceInHours as dateFnsDifferenceInHours,
    differenceInSeconds as dateFnsDifferenceInSeconds,
    differenceInYears as dateFnsDifferenceInYears,
    eachDayOfInterval as dateFnsEachDayOfInterval,
    endOfDay as dateFnsEndOfDay,
    endOfMonth,
    endOfWeek as dateFnsEndOfWeek,
    endOfYear as dateFnsEndOfYear,
    getDay as dateFnsGetDay,
    getHours as dateFnsGetHours,
    getISOWeek as dateFnsGetIsoWeek,
    getMinutes as dateFnsGetMinutes,
    getMonth,
    getOverlappingDaysInIntervals as dateFnsGetOverlappingDaysInIntervals,
    getYear as dateFnsGetYear,
    isBefore as dateFnsIsBefore,
    isEqual as dateFnsIsEqual,
    isSameDay as dateFnsIsSameDay,
    isValid as dateFnsIsValid,
    max as dateFnsMax,
    min as dateFnsMin,
    setHours as dateFnsSetHours,
    setMinutes as dateFnsSetMinutes,
    setSeconds as dateFnsSetSeconds,
    setYear as dateFnsSetYear,
    startOfDay as dateFnsStartOfDay,
    startOfHour as dateFnsStartOfHour,
    startOfMonth as dateFnsStartOfMonth,
    startOfWeek as dateFnsStartOfWeek,
    startOfYear as dateFnsStartOfYear,
    subDays as dateFnsSubDays,
    subMonths as dateFnsSubMonths,
    subWeeks as dateFnsSubWeeks,
    subYears as dateFnsSubYears,
} from 'date-fns';
import { nl } from 'date-fns/locale';

import { endOfDayInString, parse, startOfDayInHoursString } from '../../@paco/helpers/date';
import { TimeModeType } from '../../@paco/types';
import { TIME_MODES } from '../../constants';
import { PayrollPeriodViewModel } from '../../models';
// eslint-disable-next-line import/no-cycle
import getNextPayrollPeriodByDate from '../../services/PayrollPeriodService/getNextPayrollPeriodByDate';
import getRangeFromPayrollPeriodsByDate from '../../services/PayrollPeriodService/getRangeFromPayrollPeriodsByDate';
import legacyParse from '../legacyDateParser';
import { formatDate } from './parser';

export * from './parser';

/// ////////////
// Date FNS function transformers
/// ////////////

export const addDays = (date: string | Date, days: number): Date => dateFnsAddDays(legacyParse(date), days);
export const addSeconds = (date: string | Date, seconds: number): Date => dateFnsAddSeconds(legacyParse(date), seconds);
export const addHours = (date: string | Date, hours: number): Date => dateFnsAddHours(legacyParse(date), hours);
export const addWeeks = (date: string | Date, weeks: number): Date => dateFnsAddWeeks(legacyParse(date), weeks);
export const addMonths = (date: string | Date, months: number): Date => dateFnsAddMonths(legacyParse(date), months);
export const addYears = (date: string | Date, years: number): Date => dateFnsAddYears(legacyParse(date), years);

export const subDays = (date: string | Date, days: number): Date => dateFnsSubDays(legacyParse(date), days);
export const subWeeks = (date: string | Date, weeks: number): Date => dateFnsSubWeeks(legacyParse(date), weeks);
export const subMonths = (date: string | Date, months: number): Date => dateFnsSubMonths(legacyParse(date), months);
export const subYears = (date: string | Date, years: number): Date => dateFnsSubYears(legacyParse(date), years);

export const getMinutes = (date: string | Date): number => dateFnsGetMinutes(legacyParse(date));
export const getHours = (date: string | Date): number => dateFnsGetHours(legacyParse(date));
export const getDay = (date: string | Date): number => dateFnsGetDay(legacyParse(date));
export const getYear = (date: string | Date): number => dateFnsGetYear(legacyParse(date));
export const getISOWeek = (date: string | Date): number => dateFnsGetIsoWeek(legacyParse(date));

export const setSeconds = (date: string | Date, minutes: number): Date => dateFnsSetSeconds(legacyParse(date), minutes);
export const setMinutes = (date: string | Date, minutes: number): Date => dateFnsSetMinutes(legacyParse(date), minutes);
export const setHours = (date: string | Date, hours: number): Date => dateFnsSetHours(legacyParse(date), hours);
export const setYear = (date: string | Date, year: number): Date => dateFnsSetYear(legacyParse(date), year);

export const startOfHour = (date: string | Date): Date => dateFnsStartOfHour(legacyParse(date));
export const startOfDay = (date: string | Date): Date => dateFnsStartOfDay(legacyParse(date));
export const startOfWeek = (date: string | Date, options?: { weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 }): Date => dateFnsStartOfWeek(legacyParse(date), { ...options, locale: nl });
export const startOfMonth = (date: string | Date): Date => dateFnsStartOfMonth(legacyParse(date));
export const startOfYear = (date: string | Date): Date => dateFnsStartOfYear(legacyParse(date));

export const endOfDay = (date: string | Date): Date => dateFnsEndOfDay(legacyParse(date));
export const endOfWeek = (date: string | Date, options?: { weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 }): Date => dateFnsEndOfWeek(legacyParse(date), { ...options, locale: nl });
export const endOfYear = (date: string | Date): Date => dateFnsEndOfYear(legacyParse(date));

export const min = (dates: (string | Date)[]): Date => dateFnsMin(dates.map(date => legacyParse(date)));
export const max = (dates: (string | Date)[]): Date => dateFnsMax(dates.map(date => legacyParse(date)));

export const differenceInSeconds = (leftDate: string | Date, rightDate: string | Date): number => dateFnsDifferenceInSeconds(legacyParse(leftDate), legacyParse(rightDate));
export const differenceInDays = (leftDate: string | Date, rightDate: string | Date): number => dateFnsDifferenceInDays(legacyParse(leftDate), legacyParse(rightDate));
export const differenceInHours = (leftDate: string | Date, rightDate: string | Date): number => dateFnsDifferenceInHours(legacyParse(leftDate), legacyParse(rightDate));
export const differenceInYears = (leftDate: string | Date, rightDate: string | Date): number => dateFnsDifferenceInYears(legacyParse(leftDate), legacyParse(rightDate));

export const isValid = (date: string | Date): boolean => dateFnsIsValid(legacyParse(date));
export const isBefore = (date: string | Date, dateToCompare: string | Date): boolean => dateFnsIsBefore(legacyParse(date), legacyParse(dateToCompare));
export const isEqual = (date: string | Date, dateToCompare: string | Date): boolean => dateFnsIsEqual(legacyParse(date), legacyParse(dateToCompare));
export const isSameDay = (date: string | Date, dateToCompare: string | Date): boolean => dateFnsIsSameDay(legacyParse(date), legacyParse(dateToCompare));

export const eachDayOfInterval = (startDate: string | Date, endDate: string | Date): Date[] => dateFnsEachDayOfInterval({
    start: legacyParse(startDate),
    end: legacyParse(endDate),
});

export const areIntervalsOverlapping = (
    left: { start: string | Date, end: string | Date },
    right: { start: string | Date, end: string | Date },
): boolean => dateFnsAreIntervalsOverlapping(
    { start: legacyParse(left.start), end: legacyParse(left.end) },
    { start: legacyParse(right.start), end: legacyParse(right.end) },
);

export const compareAsc = (dateLeft: string | Date, dateRight: string | Date): number => dateFnsCompareAsc(legacyParse(dateLeft), legacyParse(dateRight));
export const compareDesc = (dateLeft: string | Date, dateRight: string | Date): number => dateFnsCompareDesc(legacyParse(dateLeft), legacyParse(dateRight));

export const getOverlappingDaysInIntervals = (intervalLeft: Interval, intervalRight: Interval): number => dateFnsGetOverlappingDaysInIntervals(intervalLeft, intervalRight);

/// ////////////
// Helper functions
/// ////////////

// Returns the start of workday of date (04:00)
export const getStartOfWorkDay = (date: string | Date): Date => parse(startOfDayInHoursString, 'HH', startOfDay(date));

// Returns the end of workday of date (03:59)
export const getEndOfWorkDay = (date: string | Date): Date => parse(endOfDayInString, 'HH-mm-ss', addDays(date, 1));

export const isEqualOrWithin24Hours = (date1: string | Date, date2: string | Date): boolean => Math.abs(differenceInHours(date1, date2)) <= 24;

export const isWithin24Hours = (date1: string | Date, date2: string | Date): boolean => Math.abs(differenceInHours(date1, date2)) < 24;

export const getDaysOfWeek = (date: Date): Date[] => eachDayOfInterval(
    startOfWeek(date, { weekStartsOn: 1 }),
    endOfWeek(date, { weekStartsOn: 1 }),
);

const prettifySegment = (unit: number): string => (`${unit}`.length > 1 ? `${unit}` : `0${unit}`);

export const getTimeSegments = (startTime: string | Date, endTime: string | Date = new Date()): { h: string, m: string, s: string } => {
    const totalSeconds = differenceInSeconds(endTime, startTime);

    const h = Math.floor(totalSeconds / 3600);
    const m = Math.floor((totalSeconds % 3600) / 60);
    const s = Math.floor((totalSeconds % 3600) % 60);

    return {
        h: prettifySegment(h),
        m: prettifySegment(m),
        s: prettifySegment(s),
    };
};

export const getDifferenceInTime = (startTime: string | Date, endTime: string | Date = new Date(), withMinutes = false): string => {
    const segments = getTimeSegments(startTime, endTime);

    return `${segments.h}:${segments.m}${withMinutes ? `:${segments.s}` : ''}`;
};

export const roundMinutes = (date: Date): Date => {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(60);

    return date;
};

export const getUserAge = (now: string | Date, date: string | Date) => differenceInYears(now, date);

// compare 2 time strings, like 08:20 and 20:00
// TODO: test if this works with the isEqual from dateFns and replace it
export const compareTimeStrings = (time1: string, time2: string): boolean => (
    (!!time1 && !!time2)
    && parseInt(time1.replace(':', ''), 10) >= parseInt(time2.replace(':', ''), 10)
);

// TODO: Replace this with eachDayOfInterval
export const getDaysRange = (start: Date, range: number): Date[] => {
    const days = [];

    for (let i = 0; i < range; i += 1) {
        days.push(addDays(new Date(start), i));
    }

    return days;
};

export const getWeekRange = (date: string | Date): { from: Date, to: Date } => ({
    from: startOfWeek(legacyParse(date), { weekStartsOn: 1 }),
    to: endOfWeek(legacyParse(date), { weekStartsOn: 1 }),
});

export const getMonthRange = (date: string | Date): Date[] => {
    const from = startOfWeek(startOfMonth(legacyParse(date)), { weekStartsOn: 1 });
    const to = endOfMonth(legacyParse(date));

    return dateFnsEachDayOfInterval({
        start: from,
        end: to,
    });
};

export const getYearRange = (date: string | Date): Date[] => {
    const from = startOfYear(legacyParse(date));
    const to = endOfYear(legacyParse(date));

    return dateFnsEachDayOfInterval({
        start: from,
        end: to,
    });
};

const getDaysFromDate = (date: string | Date, mode: TimeModeType, payrollPeriods: PayrollPeriodViewModel[]): Date[] => {
    switch (mode) {
        case TIME_MODES.YEAR:
            return getYearRange(date);
        case TIME_MODES.PERIOD:
            return getRangeFromPayrollPeriodsByDate(date, payrollPeriods);
        case TIME_MODES.MONTH:
            return getMonthRange(date);
        default:
            return getDaysRange(getWeekRange(date).from, 7);
    }
};

// TODO: Remove this function and use different functions for each TimeModeType
export const getRangeFromDate = (date: string | Date, mode: TimeModeType, payrollPeriods: PayrollPeriodViewModel[] = []): Date[] => {
    const days = getDaysFromDate(date, mode, payrollPeriods);
    days[days.length - 1] = endOfDay((days[days.length - 1]));
    return days;
};

// TODO: This function does too much and is not clear
export const incrementFromDate = (
    date: string | Date,
    mode: string,
    sub: boolean,
    payrollPeriods: PayrollPeriodViewModel[] = [],
): Date => {
    const parsedDate = legacyParse(date);

    if (sub) {
        switch (mode) {
            case TIME_MODES.YEAR:
                return subYears(parsedDate, 1);
            case TIME_MODES.PERIOD:
                return getNextPayrollPeriodByDate(parsedDate, payrollPeriods, true).start;
            case TIME_MODES.MONTH:
                // Add one week because months start on first monday (ie: 28-04 instead of 01-05)
                return subMonths(addWeeks(parsedDate, 1), 1);
            default:
                return subWeeks(parsedDate, 1);
        }
    }

    switch (mode) {
        case TIME_MODES.YEAR:
            return addYears(parsedDate, 1);
        case TIME_MODES.PERIOD:
            return getNextPayrollPeriodByDate(parsedDate, payrollPeriods, false).start;
        case TIME_MODES.MONTH:
            // Add one week because months start on first monday (ie: 28-04 instead of 01-05)
            return addMonths(addWeeks(parsedDate, 1), 1);
        default:
            return addWeeks(parsedDate, 1);
    }
};

export const areDatesInSameMonth = (date1: string | Date, date2: string | Date): boolean => getMonth(legacyParse(date1)) === getMonth(legacyParse(date2));

// TODO: Is this last day of the month?
export const getLastDayOfWeekInMonth = (date: string | Date, dayIndex: number): Date => {
    const endWeek = endOfWeek(legacyParse(date), { weekStartsOn: 1 });
    const endMonth = endOfMonth(legacyParse(date));

    if ((dayIndex / 7 > 3) && (endWeek > endMonth)) {
        return endMonth;
    }

    return endWeek;
};

// Add days to datetime string
export const addDaysToDateTimeString = (dateTime: string, days: number): string => formatDate(addDays(dateTime, days), "yyyy-MM-dd'T'HH:mm:ssxxx");
