import { ShiftConceptResource as LegacyShiftConceptResource } from '../../../entities/ShiftConcept/ShiftConcept';
import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { Department } from '../Department/Department';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { ShiftPlanning } from '../ShiftPlanning/ShiftPlanning';
import { TemporaryWorker } from '../TemporaryWorker/TemporaryWorker';
import {
    EditShiftConceptTimeFormData,
    EditShiftConceptTimeRequest,
    ShiftConcept,
    ShiftConceptResource,
} from './ShiftConcept';

export const transformToShiftConcept = (
    shiftResource: ShiftConceptResource,
    department: Department,
    shiftPlannings: ShiftPlanning[] = [],
    temporaryWorkers: TemporaryWorker[] = [],
): ShiftConcept => ({
    id: shiftResource.id,
    peopleLimit: shiftResource.attributes.peopleLimit,
    department,
    period: transformToPeriod(
        new Date(shiftResource.attributes.start),
        new Date(shiftResource.attributes.end),
    ),
    shiftPlannings,
    temporaryWorkers,
});

export const transformLegacyShiftConcept = (legacyShiftConcept: LegacyShiftConceptResource): ShiftConceptResource => ({
    id: legacyShiftConcept.id,
    type: legacyShiftConcept.type,
    attributes: {
        start: legacyShiftConcept.start,
        end: legacyShiftConcept.end,
        peopleLimit: legacyShiftConcept.peopleLimit,
    },
});

export const transformEditShiftConceptTimeFormDataToEditShiftConceptRequest = (
    data: EditShiftConceptTimeFormData,
    shiftId: string,
): EditShiftConceptTimeRequest => ({
    type: 'shiftConcepts',
    id: shiftId,
    attributes: {
        start: formatDate(data.period.start, DateFormat.apiDateTime),
        end: formatDate(data.period.end, DateFormat.apiDateTime),
    },
});
