import { FC } from 'react';

import { AddDistrictDivisionGroupTemplateFormErrors } from '../../containers/@forms/AddDistrictDivisionGroupTemplateForm/validations/addDistrictDivisionGroupTemplateFormValidation';
import { DistrictDivisionDistrictTemplateForm } from '../../entities/DistrictDivisionDistrictTemplate/DistrictDivisionDistrictTemplate';
import trans from '../../helpers/trans';
import IconButton from '../@buttons/IconButton/IconButton';
import NumberInput from '../@inputs/NumberInput/NumberInput';
import TextInput from '../@inputs/TextInput/TextInput';

import './DistrictDivisionDistrictTemplate.scss';

interface DistrictDivisionDistrictTemplateProps {
    districtDivisionDistrictTemplate: DistrictDivisionDistrictTemplateForm;
    formErrors: AddDistrictDivisionGroupTemplateFormErrors;
    onDistrictNameChange: (id: string, name: string) => void;
    onDistrictMaxAmountOfEmployeesChange: (id: string, maxAmountOfEmployees: number) => void;
    onDeleteDistrict: (id: string) => void;
    className?: string;
}

const DistrictDivisionDistrictTemplate: FC<DistrictDivisionDistrictTemplateProps> = ({
    districtDivisionDistrictTemplate,
    formErrors,
    onDistrictNameChange,
    onDistrictMaxAmountOfEmployeesChange,
    onDeleteDistrict,
    className = '',
}) => (
    <div className={`${className} district-division-district-template`}>
        <div className="district-division-district-template__col">
            <TextInput
                hideLabel
                required
                label={trans('containers.forms.addDistrictDivisionGroupTemplateForm.districtName')}
                type="text"
                value={districtDivisionDistrictTemplate.name}
                error={formErrors.name}
                placeholder={trans('containers.forms.addDistrictDivisionGroupTemplateForm.districtNamePlaceholder')}
                onChange={value => onDistrictNameChange(districtDivisionDistrictTemplate.id, value)}
                className="district-division-district-template__text-input"
            />
        </div>
        <div className="district-division-district-template__col">
            <NumberInput
                hideLabel
                label={trans('containers.forms.addDistrictDivisionGroupTemplateForm.maxAmountOfEmployees')}
                type="number"
                min={1}
                value={districtDivisionDistrictTemplate.maxAmountOfEmployees}
                error={formErrors.districtDivisionDistrictTemplates}
                onChange={value => onDistrictMaxAmountOfEmployeesChange(districtDivisionDistrictTemplate.id, value)}
                className="district-division-district-template__number-input"
            />
        </div>
        <div className="district-division-district-template__col">
            <IconButton
                hideLabel
                text="delete"
                icon="trash"
                iconClassName="district-division-district-template__delete-icon"
                onClick={() => onDeleteDistrict(districtDivisionDistrictTemplate.id)}
                className="district-division-district-template__delete-button"
            />
        </div>
    </div>
);

export default DistrictDivisionDistrictTemplate;
