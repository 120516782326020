import { FC, ReactElement, useMemo } from 'react';

import { Experience } from '../../../entities/Experience/Experience';
import trans from '../../../helpers/trans';
import { SelectOption } from '../../../types';
import { SelectInput } from '../../index';

const generateExperienceOptions = (experiences: Experience[]): SelectOption[] => experiences
    .map(experience => ({ label: experience.name, value: experience.id })) as SelectOption[];

interface ExperienceSelectInputProps {
    experiences: Experience[];
    experience: SelectOption;
    onChange: (option: SelectOption) => void;
}

const ExperienceSelectInput: FC<ExperienceSelectInputProps> = ({
    experiences,
    experience,
    onChange,
}): ReactElement => {
    const experienceOptions = useMemo(() => generateExperienceOptions(experiences), [experiences]);

    const onExperienceStateChange = (experienceValue: SelectOption): void => {
        onChange(experienceValue as SelectOption);
    };

    return (
        <SelectInput
            isSearchable={false}
            label={trans('common.experience')}
            name="experience"
            tooltip={trans('compositions.experienceSelectInput.tooltip')}
            value={experience}
            options={experienceOptions}
            onChange={onExperienceStateChange}
        />
    );
};

export default ExperienceSelectInput;
