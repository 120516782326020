import { NavLinkProps } from 'react-router-dom';

import trans from '../helpers/trans';
import { SettingKey } from '../types';
import { PermissionSlug } from '../types/permissionTypes';
import { RoleType } from '../types/roleTypes';
import { Route } from './route';
import {
    AbsenceNavigationRouteKey,
    absenceNavigationRoutes,
    PzNavigationRouteKey,
    pzNavigationRoutes,
    SettingNavigationRouteKey,
    settingNavigationRoutes,
    ShiftNavigationRouteKey,
    shiftNavigationRoutes,
    TrackNavigationRouteKey,
    trackNavigationRoutes,
    UserDetailNavigationRouteKey,
    userDetailNavigationRoutes,
    UserNavigationRouteKey,
    userNavigationRoutes,
} from './subRoutes';

export type NavigationRouteKey =
    'dashboard' |
    'shifts' |
    'tracks' |
    'absences' |
    'users' |
    'settings' |
    'pz' |
    AbsenceNavigationRouteKey |
    PzNavigationRouteKey |
    SettingNavigationRouteKey |
    ShiftNavigationRouteKey |
    TrackNavigationRouteKey |
    UserNavigationRouteKey |
    UserDetailNavigationRouteKey;

export interface NavRoute extends Route {
    key: NavigationRouteKey;
    children: SubNavRoute[];
}

export interface SubNavRoute extends Omit<NavRoute, 'children'> {
    // Route is accessible when user has any permission. Default is every permission.
    hasSomePermission?: boolean;
    permissions: PermissionSlug[];
    roles?: RoleType[];
    settings?: SettingKey[];
}

export interface NavigationItemProps extends Omit<NavLinkProps, 'children'> {
    key: string;
    label: string;
}

export const PrivateRouteGroupRoutes = {
    shifts: () => `/${trans('routes.shifts.link')}`,
    tracks: () => `/${trans('routes.tracks.link')}`,
    management: () => `/${trans('routes.users.link')}`,
    settings: () => `/${trans('routes.settings.link')}`,
    absences: () => `/${trans('routes.absences.link')}`,
    pz: () => `/${trans('routes.pz.link')}`,
    loketSSO: () => '/loket-authenticate',
    dashboard: () => '/',
    logs: () => trans('routes.logs.link'),
};

const routes: NavRoute[] = [
    {
        key: 'shifts',
        path: PrivateRouteGroupRoutes.shifts(),
        label: trans('routes.shifts.label'),
        children: shiftNavigationRoutes,
    },
    {
        key: 'tracks',
        path: PrivateRouteGroupRoutes.tracks(),
        label: trans('routes.tracks.label'),
        children: trackNavigationRoutes,
    },
    {
        key: 'absences',
        path: PrivateRouteGroupRoutes.absences(),
        label: trans('routes.absences.label'),
        children: absenceNavigationRoutes,
    },
    {
        key: 'users',
        path: PrivateRouteGroupRoutes.management(),
        label: trans('routes.users.label'),
        children: userNavigationRoutes,
    },
    {
        key: 'settings',
        path: PrivateRouteGroupRoutes.settings(),
        label: trans('routes.settings.label'),
        children: settingNavigationRoutes,
    },
    {
        key: 'pz',
        path: PrivateRouteGroupRoutes.pz(),
        label: trans('routes.pz.label'),
        children: pzNavigationRoutes,
    },
];

export const subRoutes: SubNavRoute[] = [
    ...absenceNavigationRoutes,
    ...settingNavigationRoutes,
    ...shiftNavigationRoutes,
    ...pzNavigationRoutes,
    ...trackNavigationRoutes,
    ...userDetailNavigationRoutes,
    ...userNavigationRoutes,
];

export default routes;
