import {
    FILTER_RESET_FILTER,
    FILTER_SAGA_SET_DOCUMENT_SEARCH,
    FILTER_SAGA_SET_EMPLOYEE_SEARCH,
    FILTER_SAGA_SET_FILTER,
} from '../actionTypes';

const setFilter = (filter, callback) => ({ type: FILTER_SAGA_SET_FILTER, filter, callback });

const resetFilters = () => ({ type: FILTER_RESET_FILTER });

const setEmployeeSearch = search => ({ type: FILTER_SAGA_SET_EMPLOYEE_SEARCH, search });

const setDocumentSearch = search => ({ type: FILTER_SAGA_SET_DOCUMENT_SEARCH, search });

export {
    setFilter,
    resetFilters,
    setEmployeeSearch,
    setDocumentSearch,
};
