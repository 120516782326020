import React, { FC, useEffect, useState } from 'react';

import { PayrollPeriod } from '../../@paco/entities/PayrollPeriod/PayrollPeriod';
import doNewDatesDifferFromOldDates from '../../helpers/date/doNewDatesDifferFromOldDates';
import UpToAndIncludingDate from '../../helpers/date/UpToAndIncludingDate';
import { translate } from '../../helpers/translations/translator';
import {
    Absence,
    LeaveOfAbsence,
    PayrollPeriodViewModel,
    WeekWithHours,
} from '../../models';
import getWeeksFromStartAndEndDate from '../../services/WeekWithHours/getWeeksFromStartAndEndDate';
import mergeNewWeekWithWeeks from '../../services/WeekWithHours/mergeNewWeekWithWeeks';
import transformWeeksWithHoursToPayrollPeriods from '../../services/WeekWithHours/transformWeeksWithHoursToPayrollPeriods';
import { getErrorMessage } from './helpers/getErrorMessage';
import WeekRows from './subcomponents/WeekRows/WeekRows';

import './WeekHoursInputs.scss';


interface WeekHoursInputsProps {
    weekWithHours: WeekWithHours[];
    pacoPayrollPeriods: PayrollPeriod[];
    payrollPeriods: PayrollPeriodViewModel[];
    type: Absence['type'] | LeaveOfAbsence['type'];
    startDate: Date | null;
    endDate: UpToAndIncludingDate | null;
    disabled?: boolean;
    hasError?: boolean;
    onChange?: (weekWithHours: WeekWithHours[]) => void;
}


const WeekHoursInputs: FC<WeekHoursInputsProps> = ({
    type,
    pacoPayrollPeriods,
    payrollPeriods,
    disabled = false,
    hasError = false,
    onChange,
    ...props
}) => {
    const compClassName = 'week-hours-inputs';
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<UpToAndIncludingDate | null>(null);
    const [payrollPeriodsFoundInDateRange, setPayrollPeriodsFoundInDateRange] = useState(false);
    const [weeksWithHours, setWeekdaysWithHours] = useState<WeekWithHours[]>([]);

    useEffect(() => {
        setStartDate(props.startDate);
        setEndDate(props.endDate);

        if (!props.startDate || !props.endDate) {
            return;
        }

        if (!doNewDatesDifferFromOldDates(startDate, props.startDate, endDate, props.endDate)) {
            return;
        }

        setWeekdaysWithHours(getWeeksFromStartAndEndDate(
            props.startDate,
            props.endDate.date,
            props.weekWithHours,
            payrollPeriods,
            pacoPayrollPeriods,
        ));
    }, [props.startDate, props.endDate, props.weekWithHours]);

    useEffect(() => {
        setPayrollPeriodsFoundInDateRange(
            !!transformWeeksWithHoursToPayrollPeriods(weeksWithHours, payrollPeriods),
        );

        if (onChange) {
            const weeksWithPayrollPeriod = weeksWithHours.filter(week => !!week.payrollPeriod);
            onChange(weeksWithPayrollPeriod);
        }
    }, [weeksWithHours]);

    if (!weeksWithHours.length || !startDate) {
        const errorMessage = getErrorMessage(
            !weeksWithHours.length,
            !startDate,
        );

        return (
            <div className={`${compClassName} ${compClassName}--error`}>
                {errorMessage}
            </div>
        );
    }

    const onInputChange = (weekday: WeekWithHours) => {
        const periods = mergeNewWeekWithWeeks(weekday, weeksWithHours);
        setWeekdaysWithHours(periods);
    };

    return (
        <div className={compClassName}>
            <WeekRows
                weeks={weeksWithHours}
                type={type}
                disabled={disabled}
                hasError={hasError}
                onInputChange={onInputChange}
            />

            {(weeksWithHours.length && !payrollPeriodsFoundInDateRange) && (
                <div className={`${compClassName}-error`}>
                    {translate('common.noPayrollPeriodsFoundInDateRange')}
                </div>
            ) }
        </div>
    );
};

export default WeekHoursInputs;
