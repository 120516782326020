import React, { FC } from 'react';

import classnames from 'classnames';

import { TimeModeType } from '../../@paco/types';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { areDatesInSameMonth, formatDate } from '../../helpers/date';
import FixedTableHeader from '../FixedTableHeader/FixedTableHeader';


interface Props {
    days: Date[],
    mode: TimeModeType;
    className?: string;
    dayClassName?: string;
}

const FixedCalendarHeader: FC<Props> = ({
    days,
    mode,
    className = '',
    dayClassName = '',
}) => (
    <FixedTableHeader className={className}>
        { days.slice(0, 7).map(day => {
            const weekday = formatDate(new Date(day), DateFormat.dayOfWeek);
            const outOfRange = (mode === TimeModeType.month) && !areDatesInSameMonth(day, days[days.length - 1]);
            const dayClassNames = classnames('day', {
                'day--out-of-range': outOfRange,
            }, dayClassName);

            return (
                <div
                    key={weekday}
                    className={dayClassNames}
                >
                    <div className="day-header">
                        <div className="m-auto">
                            {weekday}
                        </div>
                    </div>
                </div>
            );
        })}
    </FixedTableHeader>
);

export default FixedCalendarHeader;
