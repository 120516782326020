import axios from 'axios';

import { Period } from '../../@paco/entities/Period/Period';
import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { transformDepartmentResource } from '../../entities/Departments/DepartmentsTransformers';
import {
    ApiResponse,
    BasicUser,
    DataWithPagination,
    Filter,
    PaginationRequestApiParams,
    UserResponseApiParams,
} from '../../models';
import { convertDateToApiParamDate } from '../date';

interface GetUsersParams {
    withPriorityOnRole?: boolean;
    filter: Filter;
    showInactiveUsers?: 0 | 1;
    sortByExperience?: boolean;
    workweek?: Date;
    employmentPeriod?: Period;
    includes?: string[];
    pagination?: PaginationRequestApiParams;
}

const transformApiParamsToBasicUser = (params: UserResponseApiParams): BasicUser => ({
    type: 'users',
    id: params.id,
    firstname: params.firstname,
    lastname: params.lastname,
    affix: params.affix,
    plannedHoursInWeek: params.plannedHoursInWeek || 0,
    plannedConceptHoursInWeek: params.plannedConceptHoursInWeek || 0,
    isEligibleForTimeForTime: params.isEligibleForTimeForTime,
    loketEmployeeId: params.loketEmployeeId,
    contractHours: params.contractHours,
    experience: params.experience,
    employmentType: params.employmentType,
    departments: params.departments ? params.departments
        .map(department => transformDepartmentResource(department)) : [],
    mainDepartment: params.mainDepartment ? transformDepartmentResource(params.mainDepartment)
        : null,
    person: params.person,
});

export const getUsers = async (
    params: GetUsersParams,
): Promise<DataWithPagination<BasicUser[]>> => {
    const {
        filter,
        showInactiveUsers,
        sortByExperience,
        workweek,
        withPriorityOnRole,
        employmentPeriod,
    } = params;
    const {
        departments,
        onlyShowMainDepartment,
        userTypes = [],
        employeeContractTypes = [],
    } = filter;
    const sortQuery = sortByExperience ? { 'experience.name': 'ASC' } : { 'person.firstname': 'ASC' };
    const endpoint = workweek ? `users/workweek/${convertDateToApiParamDate(workweek)}` : 'users';

    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${endpoint}`,
        {
            params: {
                orderBy: JSON.stringify(sortQuery),
                include: (params.includes || []).join(','),
                search: filter.search || [],
                show_inactive_users: showInactiveUsers || 0,
                ...(employmentPeriod?.start && { employmentStart: formatDate(employmentPeriod.start, DateFormat.apiDate) }),
                ...(employmentPeriod?.end && { employmentEnd: formatDate(employmentPeriod.end, DateFormat.apiDate) }),
                ...(onlyShowMainDepartment && departments ? { mainDepartments: departments } : { departments }),
                ...(params.pagination && { 'page[number]': params.pagination.number || 1 }),
                ...(params.pagination && { 'page[size]': params.pagination.size }),
                roles: (userTypes && userTypes.length > 0) ? JSON.stringify(userTypes) : undefined,
                employmentTypes: (employeeContractTypes && employeeContractTypes.length > 0) ? JSON.stringify(employeeContractTypes) : undefined,
                withPriorityOnRole,
            },
        },
    ) as unknown as ApiResponse<UserResponseApiParams[]>;

    return {
        data: response.data.map((user) => transformApiParamsToBasicUser(user)),
        pagination: response.meta.pagination,
    };
};
