import axios from 'axios';

function authenticateUserRequest(credentials) {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/token`, credentials);
}

function refreshAccessToken(refreshToken) {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/token/refresh`, {
        refresh_token: refreshToken,
    });
}

function postForgotPassword(email, userId) {
    if (userId) {
        return axios.post(`${process.env.REACT_APP_API_URL}/users/${userId}/forgot-password`);
    }
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password/${email}`);
}

function patchUserPassword(userId, password, pincode) {
    const body = {
        type: 'users',
        id: userId,
        attributes: {
            ...(password && { password }),
            ...(pincode && { pincode }),
        },
    };

    return axios.patch(
        `${process.env.REACT_APP_API_URL}/users/${userId}/`,
        { data: body },
    );
}

export {
    authenticateUserRequest,
    refreshAccessToken,
    postForgotPassword,
    patchUserPassword,
};
