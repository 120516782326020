import axios from 'axios';

import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import {
    ApiResponse,
    DataWithPagination,
    ExchangeResource,
    ExchangeViewModel,
    Filter,
    PaginationRequestApiParams,
} from '../../models';
import transformExchangeResource from '../../services/ExchangeService/transformExchangeResource';
import UpToAndIncludingDate from '../date/UpToAndIncludingDate';

export const getExchangesInDateRange = async (
    startDate: Date,
    endDate?: UpToAndIncludingDate,
    filter: Filter = {},
    departmentIds: string[] = [],
    pagination?: PaginationRequestApiParams,
    includes: string[] = [],
): Promise<DataWithPagination<ExchangeViewModel[]>> => {
    const { employeeSearch, absences } = filter;
    const { onlyWithReplies } = absences || {};

    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/exchanges/`,
        {
            params: {
                include: includes.join(','),
                ...(employeeSearch && { search: employeeSearch }),
                ...(departmentIds && { departments: departmentIds }),
                hasReplacements: onlyWithReplies ? 1 : 0,
                startDate: formatDate(startDate, DateFormat.apiDateTime),
                ...(pagination && { 'page[number]': pagination.number || 1 }),
                ...(pagination && { 'page[size]': pagination.size }),
            },
        },
    ) as unknown as ApiResponse<ExchangeResource[]>;

    const data = response.data.map((exchange: ExchangeResource) => transformExchangeResource(
        exchange,
        exchange.owner,
        exchange.replacementUsers,
        exchange.shift,
        exchange.logs,
    ));

    return {
        data,
        pagination: response.meta.pagination,
    };
};
