import { getEmploymentTypesApiCall } from '../../entities/EmploymentType/EmploymentTypeService';
import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import { setEmploymentTypes, setError, setIsLoading } from './employmentTypesReducer';

export const fetchEmploymentTypes = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getEmploymentTypesApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setEmploymentTypes(response.data));
    } catch (error) {
        console.error('[fetchEmploymentTypes]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
