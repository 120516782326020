import React, { FC } from 'react';

import { getUserFullName } from '../../../../../../helpers';
import { formatDate } from '../../../../../../helpers/date';
import { getTranslationForRole } from '../../../../../../helpers/translations/translateRoles';
import { translate } from '../../../../../../helpers/translations/translator';
import { DocumentViewModel, Role } from '../../../../../../models';

type SharedDocumentsProps = DocumentViewModel;

const SharedDocumentItem: FC<SharedDocumentsProps> = ({
    displayName,
    documentType,
    userTypes,
    departments,
    owner,
    createdAt,
}) => (
    <>
        <td className="list-view-document__name">
            {displayName}
        </td>
        <td className="list-view-document_category">
            {translate(`pages.management.${documentType.name.toLowerCase()}`)}
        </td>
        <td className="list-view-document__userType">
            {userTypes.map((userType: Role) => getTranslationForRole(userType.slug)).join(', ')}
        </td>
        <td className="list-view-document__department">
            {departments.map(department => department.name).join(', ')}
        </td>
        <td className="list-view-document__date">
            {formatDate(createdAt, 'dd-MM-yyyy')}
        </td>
        <td className="list-view-document__addedBy">
            {getUserFullName(owner)}
        </td>
    </>
);

export default SharedDocumentItem;
