import { eachDayOfInterval, startOfDayInHours } from '../../@paco/helpers/date';
import { addHours, compareAsc } from '../../helpers/date';
import { PayrollPeriodViewModel } from '../../models';
import { getOverlappingPayrollPeriodOnDate } from './getOverlappingPeriodOnDate';

const getPayrollPeriodByDate = (
    date: string | Date,
    payrollPeriods: PayrollPeriodViewModel[] = [],
): PayrollPeriodViewModel => {
    const match = payrollPeriods
        .find(period => getOverlappingPayrollPeriodOnDate(period, new Date(date)));

    if (match) {
        return match;
    }

    if (compareAsc(date, payrollPeriods[0].start) === -1) {
        return payrollPeriods[0];
    }

    return payrollPeriods[payrollPeriods.length - 1];
};

export default function getRangeFromPayrollPeriodsByDate(
    date: string | Date,
    payrollPeriods: PayrollPeriodViewModel[] = [],
): Date[] {
    if (payrollPeriods.length === 0) {
        return [new Date(date), new Date(date)];
    }

    const payrollPeriod = getPayrollPeriodByDate(date, payrollPeriods);
    const start = addHours(payrollPeriod.start, -startOfDayInHours);
    const end = addHours(payrollPeriod.end.date, -startOfDayInHours);

    return eachDayOfInterval({ start, end });
}
