import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import { PersonalInfoFormData } from '../User/User';
import {
    EditPersonFormData,
    Person,
    PersonFormData,
    PersonResource,
} from './Person';
import { transformAddPersonFormDataToAddApiParams, transformEditPersonFormDataToEditApiParams, transformToPerson } from './PersonTransformers';

export const postPersonApiCall = async (
    personFormData: PersonFormData,
    personalInfoFormData: PersonalInfoFormData,
    userId: string,
    experienceId?: string,
): Promise<FetchResult<Person, string>> => {
    const apiData = transformAddPersonFormDataToAddApiParams(
        personalInfoFormData,
        personFormData,
        userId,
        experienceId,
    );

    try {
        const url = generateApiUrl({ endpoint: '/people' });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<PersonResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const person = transformToPerson(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: person,
        };
    } catch (error) {
        console.error('[postPersonApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchPersonApiCall = async (
    editPersonFormData: EditPersonFormData,
    personalInfoFormData: PersonalInfoFormData,
    userId: string,
    experienceId?: string,
): Promise<FetchResult<Person, string>> => {
    const apiData = transformEditPersonFormDataToEditApiParams(personalInfoFormData, editPersonFormData, userId, experienceId);

    try {
        const url = generateApiUrl({ endpoint: `/people/${apiData.id}` });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<PersonResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const person = transformToPerson(doc.data);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: person,
        };
    } catch (error) {
        console.error('[patchPersonApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
