import React, { FC } from 'react';

import Select from 'react-select';

import './BasicSelect.scss';

export interface BasicSelectOption {
    value: string | number;
    label: string | number;
}

export interface BasicSelectProps {
    options: BasicSelectOption[],
    value?: string | number;
    className?: string;
    onChange: (value: string) => void,
}

const selectStyles = {
    control: (props: any) => ({
        ...props,
        border: '1px solid #114D61',
        borderRadius: '2px',
        height: '100%',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: () => ({
        marginRight: '8px',
        fill: '#114D61',
    }),
};

const BasicSelect: FC<BasicSelectProps> = ({ options, className, ...props }) => {
    const onChange = (e: any) => {
        props.onChange(e.value);
    };

    const value: BasicSelectOption = options
        .find(option => option.value === props.value) || options[0];

    return (
        <div className={`basic-select ${className}`} onChange={onChange}>
            <Select
                styles={selectStyles}
                value={value || undefined}
                options={options}
                onChange={onChange}
                className="basic-select__select"
            />
        </div>
    );
};

export default BasicSelect;
