const isObject = (value: any): value is Record<string, any> => (
    value !== null
        && typeof value === 'object'
        && Array.isArray(value) === false
);

export const objectContainsValues = <T>(object: T): boolean => {
    if (!isObject(object)) {
        return false;
    }

    const valueArray = Object.values(object);

    for (let i = 0; i < valueArray.length; i += 1) {
        if (![undefined, null].includes(valueArray[i])) {
            return true;
        }
    }

    return false;
};

export const objectIsEmpty = <T>(object: T): boolean => {
    if (!isObject(object)) {
        return false;
    }

    return (
        Object.keys(object).length === 0
        && Object.getPrototypeOf(object) === Object.prototype
    );
};
