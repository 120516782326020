import { FC } from 'react';

import Select, { OnChangeValue } from 'react-select';

import { SelectOption } from '../../types';

import './BasicSelect.scss';

export interface BasicSelectProps {
    options: SelectOption[],
    value?: string | number;
    onChange: (value: SelectOption) => void,
    className?: string;
    selectClassName?: string;
}

const BasicSelect: FC<BasicSelectProps> = ({
    options,
    onChange,
    value,
    className = '',
    selectClassName = '',
}) => {
    const handleChange = (e: OnChangeValue<SelectOption, false>) => {
        const newOption = options.find(option => option.value === (e && e.value));
        if (newOption) {
            onChange(newOption);
        }
    };

    const selectedValue: SelectOption = options.find(option => option.value === value) || options[0];

    return (
        <div className={`basic-select ${className}`}>
            <Select
                options={options}
                value={selectedValue}
                onChange={handleChange}
                className={`basic-select__select ${selectClassName}`}
                classNamePrefix="basic-select__select"
            />
        </div>
    );
};

export default BasicSelect;
