import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiV3Url } from '../../helpers/url';
import { JapiDocument } from '../../japi/types/Document';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { EventLogCategory } from './EventLogCategory';
import { transformToEventLogCategory } from './EventLogCategoryTransformers';

export const getEventLogCategoriesApiCall = async (): Promise<FetchCollectionResult<EventLogCategory[], string>> => {
    try {
        const url = generateApiV3Url({ endpoint: '/event-logs/categories' });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!doc || !Array.isArray(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const categories = doc.map(transformToEventLogCategory);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: categories,
        };
    } catch (error) {
        console.error('[getEventLogCategoriesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
