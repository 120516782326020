import React, { FC, ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Modal } from 'reactstrap';

import { LoadingSpinner } from '../../../../../@paco/components';
import CalendarHeader from '../../../../../@paco/components/CalendarHeader/CalendarHeader';
import { IconNavLink } from '../../../../../@paco/compositions';
import { hasDateFourHoursOrLess } from '../../../../../@paco/entities/Period/PeriodHelpers';
import useDeviceWidth from '../../../../../@paco/helpers/hooks/useDeviceWidth';
import trans from '../../../../../@paco/helpers/trans';
import { ScheduleRoutes } from '../../../../../@paco/routes/helpers';
import { PrivateRouteGroupRoutes } from '../../../../../@paco/routes/routes';
import { TimeModeType } from '../../../../../@paco/types';
import { BaseScheduleViewModel } from '../../../../../entities/BaseSchedule/BaseSchedule';
import { BaseScheduleShiftViewModel } from '../../../../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftViewModel } from '../../../../../models';
import CopyShiftsForm from '../../../forms/CopyShiftsForm/CopyShiftsForm';
import ConceptsCalendar from '../../ConceptsCalendar/ConceptsCalendar';

import './BaseScheduleCalendar.scss';

interface BaseScheduleCalendarProps {
    isLoading: boolean;
    showEmployees: boolean;
    baseSchedule?: BaseScheduleViewModel;
    highlightShiftId?: string;
    selectedDays: Date[];
    shifts: BaseScheduleShiftViewModel[];
    canAddBaseScheduleShifts: boolean;
    canEditBaseScheduleShifts: boolean;
    onAddShift: (shiftDate: Date) => void;
    onCopyShiftsSubmit: (shift: BaseScheduleShiftViewModel) => void;
    className?: string;
}

const BaseScheduleCalendar: FC<BaseScheduleCalendarProps> = ({
    isLoading,
    showEmployees,
    baseSchedule,
    selectedDays,
    shifts,
    canAddBaseScheduleShifts,
    canEditBaseScheduleShifts,
    onAddShift,
    onCopyShiftsSubmit,
    highlightShiftId,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const { isMobile, isTabletPortrait } = useDeviceWidth();

    const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([]);
    const [dateOffset, setDateOffset] = useState(0);
    const [copyShiftsModalIsActive, setCopyShiftsModalIsActive] = useState(false);

    const handleCloseCopyShiftsModal = () => setCopyShiftsModalIsActive(false);

    const handleDeleteShifts = (shiftIds: string[]) => {
        setSelectedShiftIds(shiftIds);
    };

    const handleCopyShifts = (shiftIds: string[], offset: number) => {
        setSelectedShiftIds(shiftIds);
        setDateOffset(offset);
        setCopyShiftsModalIsActive(true);
    };

    const handleSelectedShiftsChange = (shiftIds: string[]) => {
        setSelectedShiftIds(shiftIds);
    };

    const handleCopyShiftsSubmit = (copyEmployees: boolean) => {
        const shiftToCopy = shifts.find(shift => shift.id === selectedShiftIds[0]);

        if (!shiftToCopy) return;

        if (hasDateFourHoursOrLess(shiftToCopy.start)) {
            shiftToCopy.weekday -= 1;
        }

        shiftToCopy.weekday += dateOffset;

        if (!copyEmployees) shiftToCopy.shiftPlannings = [];

        setCopyShiftsModalIsActive(false);
        onCopyShiftsSubmit(shiftToCopy);
    };

    const handleShiftClick = (shift: ShiftViewModel) => navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.baseScheduleShift(shift.id)}`);

    return (
        <div className={`base-schedule-calendar ${className}`}>
            <div className="base-schedule-calendar__top-bar">
                <h1 className="base-schedule-calendar__title">
                    {baseSchedule?.name}
                </h1>
                <IconNavLink
                    icon="reset"
                    text={trans('common.back')}
                    to={`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.baseSchedules()}`}
                    className="base-schedule-calendar__back-button"
                    iconClassName="base-schedule-calendar__back-button-icon"
                />
            </div>
            <CalendarHeader
                days={selectedDays}
                mode={TimeModeType.week}
                className="base-schedule-calendar__calendar-header"
            />
            <ConceptsCalendar
                isMobile={isMobile || isTabletPortrait}
                showAddButton={canAddBaseScheduleShifts}
                canEditBaseScheduleShifts={canEditBaseScheduleShifts}
                dayHeaderFormat="EEEE"
                deleteButtonId="delete-button"
                highlightShiftId={highlightShiftId}
                showEmployees={showEmployees}
                shifts={shifts}
                selectedDays={selectedDays}
                onAddButtonClick={onAddShift}
                onShiftClick={handleShiftClick}
                onSelectedShiftsChange={handleSelectedShiftsChange}
                onDeleteShifts={handleDeleteShifts}
                onCopyShifts={handleCopyShifts}
                className="base-schedule-calendar__calendar"
            />
            {isLoading && (
                <LoadingSpinner />
            )}
            <Modal
                size="md"
                isOpen={copyShiftsModalIsActive}
                className="concepts-calendar-overview__copy-shifts-form"
            >
                <CopyShiftsForm
                    shiftsLength={selectedShiftIds.length}
                    onSubmit={handleCopyShiftsSubmit}
                    onCancel={handleCloseCopyShiftsModal}
                />
            </Modal>
        </div>
    );
};

export default BaseScheduleCalendar;
