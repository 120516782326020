import { FC, ReactElement, ReactNode } from 'react';

import classNames from 'classnames';
import ReactSelect, {
    ActionMeta,
    FormatOptionLabelMeta,
    GroupBase,
    OnChangeValue,
    StylesConfig,
} from 'react-select';

import trans from '../../../helpers/trans';
import { GroupedSelectOption, SelectOption } from '../Select/Select';

import './MultiSelect.scss';

interface MultiSelectProps {
    closeMenuOnSelect?: boolean;
    isLoading: boolean;
    hideSelectedOptions?: boolean;
    isOptionDisabled?: (option: SelectOption) => boolean;
    isSearchable?: boolean;
    menuIsOpen?: boolean;
    formatGroupLabel?: (groupLabel: GroupBase<SelectOption>) => ReactNode;
    formatOptionLabel?: (option: SelectOption, format: FormatOptionLabelMeta<SelectOption>) => ReactNode;
    styles?: StylesConfig<SelectOption>;
    loadingMessage?: string;
    noOptionsMessage?: string;
    disabled?: boolean;
    options: SelectOption[] | GroupedSelectOption[];
    placeholder?: string;
    value?: OnChangeValue<SelectOption[], false>;
    error?: string;
    onChange: (selected: SelectOption[], actionMeta: ActionMeta<SelectOption>) => void;
    onMenuClose?: () => void;
    onMenuOpen?: () => void;
    className?: string;
}

const MultiSelect: FC<MultiSelectProps> = ({
    closeMenuOnSelect,
    hideSelectedOptions,
    isLoading,
    isOptionDisabled,
    isSearchable,
    menuIsOpen,
    formatGroupLabel,
    formatOptionLabel,
    styles,
    loadingMessage = trans('components.select.loadingMessage'),
    noOptionsMessage = trans('components.select.nullState'),
    options,
    value,
    placeholder = trans('components.select.placeholder'),
    disabled,
    error = '',
    onChange,
    onMenuClose,
    onMenuOpen,
    className = '',
}): ReactElement => {
    const selectClassName = classNames('select-overwrite', {
        'select-overwrite--is-disabled': disabled,
        'select-overwrite--has-error': !!error,
    }, className);

    const handleChange = (selectValue: OnChangeValue<SelectOption, boolean>, actionMeta: ActionMeta<SelectOption>): void => {
        onChange(selectValue as SelectOption[], actionMeta);
    };

    const handleMenuClose = (): void => {
        if (onMenuClose) {
            onMenuClose();
        }
    };

    const handleMenuOpen = (): void => {
        if (onMenuOpen) {
            onMenuOpen();
        }
    };

    return (
        <div className="multi-select">
            <ReactSelect
                isMulti
                closeMenuOnSelect={closeMenuOnSelect}
                hideSelectedOptions={hideSelectedOptions}
                isSearchable={isSearchable}
                menuIsOpen={menuIsOpen}
                styles={styles}
                isLoading={isLoading}
                formatGroupLabel={formatGroupLabel}
                formatOptionLabel={formatOptionLabel}
                isOptionDisabled={isOptionDisabled}
                loadingMessage={() => loadingMessage}
                noOptionsMessage={() => noOptionsMessage}
                options={options}
                value={value}
                placeholder={placeholder}
                isDisabled={disabled}
                onChange={handleChange}
                onMenuClose={handleMenuClose}
                onMenuOpen={handleMenuOpen}
                classNamePrefix="select-overwrite"
                className={selectClassName}
            />
        </div>
    );
};

export default MultiSelect;
