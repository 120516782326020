import { FC, PropsWithChildren, ReactElement } from 'react';

import './PacoTable.scss';

interface PacoTableProps {
    className?: string;
}

const PacoTable: FC<PropsWithChildren<PacoTableProps>> = ({ className = '', children }): ReactElement => (
    <table className={`paco-table ${className}`}>
        {children}
    </table>
);

export default PacoTable;
