import axios from 'axios';

import {
    ApiResponse,
    CommentFormData,
    CommentResource,
    PaginationRequestApiParams,
} from '../../models';
import transformCommentResource from '../../services/CommentService/transformCommentResource';

export interface CommentRequestApiParams {
    type: 'comments';
    id?: string;
    attributes: {
        body: string;
    },
    relationships: {
        commentCategory: {
            data: {
                type: 'comment_categories';
                id: string;
            }
        },
        user: {
            data: {
                type: 'users',
                id: string;
            }
        }
    }
}

function transformCommentFormDataToApiParams(data: CommentFormData): CommentRequestApiParams {
    return {
        type: 'comments',
        id: data.id,
        attributes: {
            body: data.body,
        },
        relationships: {
            commentCategory: {
                data: {
                    type: 'comment_categories',
                    id: data.categoryId,
                },
            },
            user: {
                data: {
                    type: 'users',
                    id: data.userId,
                },
            },
        },
    };
}

export async function getComments(
    userIds: string[] = [],
    includes: string[] = [],
    pagination?: PaginationRequestApiParams,
) {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/comments/`,
        {
            params: {
                userUuids: userIds.length > 0 ? JSON.stringify(userIds) : undefined,
                include: (includes || []).join(','),
                ...(pagination && { 'page[number]': pagination.number || 1 }),
                ...(pagination && { 'page[size]': pagination.size }),
            },
        },
    ) as unknown as ApiResponse<CommentResource[]>;

    return response.data.map((comment) => transformCommentResource(
        comment,
        comment.owner,
        comment.user,
        comment.commentCategory || undefined,
    ));
}

export function postComment(data: CommentFormData) {
    const body = transformCommentFormDataToApiParams(data);
    return axios.post(`${process.env.REACT_APP_API_URL}/comments/`, { data: body });
}

export function patchComment(data: CommentFormData) {
    const body = transformCommentFormDataToApiParams(data);
    return axios.patch(`${process.env.REACT_APP_API_URL}/comments/${data.id}`, { data: body });
}

export function deleteComment(id: string) {
    return axios.delete(`${process.env.REACT_APP_API_URL}/comments/${id}`);
}
