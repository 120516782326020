import { formatDate } from '../../../helpers/date';
import {
    addHours,
    addSeconds,
    areIntervalsOverlapping,
    compareAsc,
    startOfDayInHours,
} from '../../helpers/date';
import trans from '../../helpers/trans';
import { DateFormat } from '../../types/dateFormatTypes';
import { PayrollPeriodWeek } from '../PayrollPeriodWeek/PayrollPeriodWeek';
import { Period } from '../Period/Period';
import { doesDateOverlapWithPeriod, doPeriodsOverlap } from '../Period/PeriodHelpers';
import { PayrollPeriod, PayrollPeriodResource } from './PayrollPeriod';

export const doesDateOverlapWithPayrollPeriod = (date: Date, payrollPeriod: PayrollPeriod): boolean => areIntervalsOverlapping({
    start: date,
    end: addSeconds(date, 1),
}, {
    start: payrollPeriod.period.start,
    end: payrollPeriod.period.end,
});

export const doesDateOverlapWithLockedPayrollPeriod = (date: Date, payrollPeriods: PayrollPeriod[]): boolean => (
    payrollPeriods.some(payrollPeriod => (
        payrollPeriod.weeks.some(week => week.isLocked && doesDateOverlapWithPeriod(date, week.period))
    ))
);

export const doesPeriodOverlapWithLockedPayrollPeriod = (period: Period, payrollPeriods: PayrollPeriod[]): boolean => (
    payrollPeriods.some(payrollPeriod => (
        payrollPeriod.weeks.some(week => week.isLocked && doPeriodsOverlap(period, week.period))
    ))
);

export const findPayrollPeriodIndexFromDate = (date: Date, payrollPeriods: PayrollPeriod[]): number => payrollPeriods
    .findIndex(payrollPeriod => doesDateOverlapWithPayrollPeriod(date, payrollPeriod));

export const findPeriodByYearAndNumber = (
    periods: PayrollPeriod[],
    year: number,
    number: number,
): PayrollPeriod | undefined => periods.find(period => period.year === year && period.periodNumber === number);

export const filterPeriodsByYear = (periods: PayrollPeriod[], year: number): PayrollPeriod[] => periods.filter(period => period.year === year);

export const getPayrollPeriodFromDate = (date: Date, payrollPeriods: PayrollPeriod[]): PayrollPeriod | undefined => {
    if (!payrollPeriods.length) {
        return undefined;
    }

    const match = payrollPeriods.find(payrollPeriod => doesDateOverlapWithPayrollPeriod(date, payrollPeriod));

    if (match) {
        return match;
    }

    if (compareAsc(payrollPeriods[0].period.start, date) === -1) {
        return payrollPeriods[payrollPeriods.length - 1];
    }

    return payrollPeriods[0];
};

export const getYearsFromPeriods = (periods: PayrollPeriod[]): number[] => periods
    .reduce((total: number[], period) => [
        ...total,
        ...(total.includes(period.year) ? [] : [period.year]),
    ], [])
    .sort((a, b) => a - b);

export const getPayrollPeriodEndDateLabel = (payrollPeriod: PayrollPeriod, dateFormat = 'EEEEEE d MMM yyyy'): string => (
    formatDate(addSeconds(addHours(payrollPeriod.period.end, -startOfDayInHours), -1), dateFormat)
);

export const getPayrollPeriodDateAndNumberLabel = (payrollPeriod: PayrollPeriod): string => {
    const startDate = formatDate(addHours(payrollPeriod.period.start, -startOfDayInHours), DateFormat.date);
    const endDate = getPayrollPeriodEndDateLabel(payrollPeriod, DateFormat.date);
    const number = `${trans('common.period')} ${payrollPeriod.periodNumber}`.toLowerCase();

    return `${startDate} ${trans('common.until')} ${endDate} - ${number}`;
};

export const updatePayrollPeriodWithPayrollPeriodWeek = (payrollPeriod: PayrollPeriod, payrollPeriodWeek: PayrollPeriodWeek): PayrollPeriod => {
    const payrollPeriodWeekIndex = payrollPeriod.weeks.findIndex(week => week.id === payrollPeriodWeek.id);

    if (payrollPeriodWeekIndex === -1) {
        return payrollPeriod;
    }

    const updatedWeeks = [...payrollPeriod.weeks];
    updatedWeeks.splice(payrollPeriodWeekIndex, 1, payrollPeriodWeek);

    const isLocked = updatedWeeks.every(week => week.isLocked);

    return {
        ...payrollPeriod,
        isLocked,
        weeks: updatedWeeks,
    };
};

export const isPayrollPeriodResource = (resource: any): resource is PayrollPeriodResource => (
    typeof resource === 'object'
    && resource !== null
    && 'id' in resource
    && 'isLocked' in resource
    && 'start' in resource
    && 'end' in resource
    && 'year' in resource
    && 'payrollPeriodWeeks' in resource
);

export const comparePayrollPeriodsByDate = (a: PayrollPeriod, b: PayrollPeriod): number => (
    compareAsc(a.period.start, b.period.start)
);
