import { all } from 'redux-saga/effects';

import absencesWatcher from './absences/absencesSaga';
import absencesTsWatcher from './absences-ts/absencesSaga';
import appWatcher from './app/appSaga';
import authenticationWatcher from './authentication/authenticationSaga';
import feedbackWatcher from './feedback/feedbackSaga';
import filterWatcher from './filter/filterSaga';
import logsWatcher from './logs/logsSaga';
import logsTsWatcher from './logs-ts/logsSaga';
import managementWatcher from './management/managementSaga';
import managementUserWatcher from './management-ts/managementSaga';
import paginationWatcher from './pagination/paginationSaga';
import pzWatcher from './pz/pzSaga';
import settingsWatcher from './settings/settingsSaga';
import shiftsWatcher from './shifts/shiftsSaga';
import shiftsTsWatcher from './shifts-ts/shiftsSaga';
import tracksWatcher from './tracks/tracksSaga';
import usersWatcher from './users/usersSaga';
import weekNavigatorWatcher from './weekNavigator/weekNavigatorSaga';

export default function* rootSaga() {
    yield all([
        appWatcher(),
        authenticationWatcher(),
        weekNavigatorWatcher(),
        shiftsWatcher(),
        shiftsTsWatcher(),
        tracksWatcher(),
        usersWatcher(),
        filterWatcher(),
        paginationWatcher(),
        feedbackWatcher(),
        managementWatcher(),
        managementUserWatcher(),
        absencesWatcher(),
        absencesTsWatcher(),
        logsWatcher(),
        logsTsWatcher(),
        settingsWatcher(),
        pzWatcher(),
    ]);
}
