import lastOrNull from '../tools/array';

export default function getFileNameExtension(filename: string): string | null {
    if (!filename || !filename.length) {
        return null;
    }

    const splits = filename.toLowerCase().split('.').filter((split) => !!split);

    if (splits.length <= 1) {
        return null;
    }

    return lastOrNull<string>(splits);
}
