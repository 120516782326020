import React, { FC, useState } from 'react';

import classnames from 'classnames';

import { IconButton } from '../../../../@paco/compositions';

import './FilterBlock.scss';

interface FilterBlockProps {
    title?: string;
    hideMobile?: boolean;
    isCollapsedByDefault?: boolean | 'auto';
    className?: string;
    children: React.ReactNode;
}

const FilterBlock: FC<FilterBlockProps> = ({
    title,
    hideMobile = false,
    isCollapsedByDefault = 'auto',
    className = '',
    children,
}) => {
    const collapsedStateKey = `filterBlockCollapsed__${btoa(title || '')}`;
    const initialCollapsedState = isCollapsedByDefault === 'auto'
        ? localStorage.getItem(`filterBlockCollapsed__${collapsedStateKey}`) === 'true'
        : isCollapsedByDefault;
    const [isCollapsed, setIsCollapsed] = useState<boolean>(initialCollapsedState);

    const blockClassNames = classnames('filter-block', {
        'filter-block--hide-mobile': hideMobile,
        'filter-block--collapsed': isCollapsed,
        'filter-block--expanded': !isCollapsed,
    }, className);

    const handleCollapseButtonClick = () => {
        setIsCollapsed(!isCollapsed);
        localStorage.setItem(`filterBlockCollapsed__${collapsedStateKey}`, String(!isCollapsed));
    };

    return (
        <div className={blockClassNames}>
            {title && (
                <div className="filter-block__title">
                    <IconButton
                        text={title}
                        onClick={handleCollapseButtonClick}
                        icon="chevron-down"
                        iconPos="right"
                        iconClassName="filter-block__collapse-button-icon"
                        className="filter-block__collapse-button"
                    />
                </div>
            )}
            <div className="filter-block__content">
                <div className="filter-block__content-inner">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default FilterBlock;
