import React, { FC } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { getTranslationForRole } from '../../../../helpers/translations/translateRoles';
import { translate } from '../../../../helpers/translations/translator';
import { Role } from '../../../../models';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';

import './UserTypesFilter.scss';

interface FilterProps {
    filter: AppFilter;
    onChange: (filter: AppFilter) => void;
    roles: Role[];
}

const UserTypesFilter: FC<FilterProps> = ({
    filter,
    roles,
    onChange,
}) => {
    const setFilter = (slug: string, checked: boolean) => {
        const userTypes = !checked ? (
            filter.userTypes.filter((role) => role !== slug)
        ) : [
            ...filter.userTypes,
            slug,
        ];

        onChange({
            ...filter,
            userTypes,
        });
    };

    const getChecked = (id: string) => {
        const arrayLength = filter.userTypes
            .filter(a => a === id).length;
        return arrayLength !== 0;
    };

    const userType = translate('common.role');

    return (
        <FilterBlock title={userType} className="filter-block-user-types">
            {roles
                .filter(type => type.public)
                .map(type => (
                    <FormGroup check className={`p-0 user-type-${type.slug}`} key={type.slug}>
                        <Label check>
                            <CustomInput
                                id={`${userType}-checkbox-${type.slug}`}
                                type="checkbox"
                                label={getTranslationForRole(type.slug)}
                                checked={getChecked(type.slug)}
                                onChange={(e: React.MouseEvent<HTMLInputElement>) => setFilter(type.slug, e.currentTarget.checked)}
                            />
                        </Label>
                    </FormGroup>
                ))}
        </FilterBlock>
    );
};

export default UserTypesFilter;
