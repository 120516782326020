import { FC, ReactElement, useMemo } from 'react';

import PlannedUserCard from '../../../../../../components/PlannedUserCard/PlannedUserCard';
import { Button } from '../../../../../components';
import { ModalContent, ShiftPlanningCard, WorkweekUsersSelect } from '../../../../../compositions';
import TemporaryWorkerCard from '../../../../../compositions/TemporaryWorkerCard/TemporaryWorkerCard';
import { ConnectedHelpTooltip } from '../../../../../connectors';
import { Absence } from '../../../../../entities/Absence/Absence';
import { LeaveOfAbsence } from '../../../../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { getPersonFullName } from '../../../../../entities/Person/PersonHelpers';
import { PreferToWorkTimeSlot } from '../../../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { Setting } from '../../../../../entities/Setting/Setting';
import { ShiftIndex } from '../../../../../entities/Shift/Shift';
import { ShiftPlanning } from '../../../../../entities/ShiftPlanning/ShiftPlanning';
import { TemporaryWorker } from '../../../../../entities/TemporaryWorker/TemporaryWorker';
import { UnavailableToWorkTimeSlot } from '../../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { WorkweekUser } from '../../../../../entities/User/User';
import trans from '../../../../../helpers/trans';

import './ShiftDetailEmployeesForm.scss';

interface ShiftDetailEmployeesFormProps {
    canAddShiftPlannings: boolean;
    canDeleteShiftPlannings: boolean;
    canEditShiftPlannings: boolean;
    isLoading: boolean;
    absences: Absence[];
    leaveOfAbsences: LeaveOfAbsence[];
    preferToWorkTimeSlots: PreferToWorkTimeSlot[];
    recentShiftPlannings: ShiftPlanning[];
    settings: Setting[];
    shift: ShiftIndex;
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[];
    users: WorkweekUser[];
    onAddShiftPlanningClick: (shiftPlanning: ShiftPlanning) => void;
    onAddTemporaryWorkerClick: () => void;
    onDeleteShiftPlanningClick: (shiftPlanning: ShiftPlanning) => void;
    onDeleteTemporaryWorkerClick: (temporaryWorker: TemporaryWorker) => void;
    onEditShiftPlanningClick: (shiftPlanning: ShiftPlanning) => void;
    onEditTemporaryWorkerClick: (temporaryWorker: TemporaryWorker) => void;
    onSelectWorkweekUser: (user: WorkweekUser) => void;
    onShiftPlanningColorSelect: (shiftPlanningId: string, color?: string) => void;
    onTemporaryWorkerColorSelect: (temporaryWorkerId: string, color?: string) => void;
    className?: string;
}

const ShiftDetailEmployeesForm: FC<ShiftDetailEmployeesFormProps> = ({
    canAddShiftPlannings,
    canDeleteShiftPlannings,
    canEditShiftPlannings,
    isLoading,
    absences,
    leaveOfAbsences,
    preferToWorkTimeSlots,
    recentShiftPlannings,
    settings,
    shift,
    unavailableToWorkTimeSlots,
    users,
    onAddShiftPlanningClick,
    onAddTemporaryWorkerClick,
    onDeleteShiftPlanningClick,
    onDeleteTemporaryWorkerClick,
    onEditShiftPlanningClick,
    onEditTemporaryWorkerClick,
    onSelectWorkweekUser,
    onShiftPlanningColorSelect,
    onTemporaryWorkerColorSelect,
    className = '',
}): ReactElement => {
    const shiftPlannings = useMemo(() => (
        [...shift.shiftPlannings].sort((a, b) => a.user.fullName.localeCompare(b.user.fullName))
    ), [shift]);

    const plannedShiftPlannings = shiftPlannings.filter(planning => planning.planned);
    const employeesLength = plannedShiftPlannings.length + shift.temporaryWorkers.length;
    const unplannedShiftPlannings = shiftPlannings.filter(planning => !planning.planned);
    const selectUsers = users.filter(user => !shiftPlannings.some(planning => planning.user.id === user.id));

    const handleShiftPlanningColorSelect = (shiftPlanning: ShiftPlanning, color?: string) => {
        onShiftPlanningColorSelect(shiftPlanning.id, color);
    };

    return (
        <div className={`shift-detail-employees-form ${className}`}>
            <ModalContent
                title={trans('containers.forms.shiftDetailForm.employees')}
                subText={`${employeesLength}/${shift.peopleLimit}`}
            >
                <div className="shift-detail-main-form__body">
                    <WorkweekUsersSelect
                        controlShouldRenderValue={false}
                        isDisabled={!canAddShiftPlannings || isLoading}
                        absences={absences}
                        leaveOfAbsences={leaveOfAbsences}
                        period={shift.period}
                        preferToWorkTimeSlots={preferToWorkTimeSlots}
                        unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                        users={selectUsers}
                        onSelect={onSelectWorkweekUser}
                        className="shift-detail-employees-form__workweek-users-select"
                    />

                    <div className="shift-detail-employees-form__planned-shift-plannings">
                        {plannedShiftPlannings.map(shiftPlanning => {
                            const workweekUser = users.find(user => user.id === shiftPlanning.user.id);

                            return (
                                <ShiftPlanningCard
                                    key={shiftPlanning.id}
                                    showComments
                                    absences={absences}
                                    leaveOfAbsences={leaveOfAbsences}
                                    preferToWorkTimeSlots={preferToWorkTimeSlots}
                                    recentShiftPlannings={recentShiftPlannings}
                                    settings={settings}
                                    shiftPlanning={shiftPlanning}
                                    shift={shift}
                                    unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                                    user={workweekUser}
                                    onColorSelect={canEditShiftPlannings ? handleShiftPlanningColorSelect : undefined}
                                    onDeleteButtonClick={canDeleteShiftPlannings ? onDeleteShiftPlanningClick : undefined}
                                    onEditButtonClick={canEditShiftPlannings ? onEditShiftPlanningClick : undefined}
                                />
                            );
                        })}

                        {shift.temporaryWorkers.map(temporaryWorker => (
                            <TemporaryWorkerCard
                                key={temporaryWorker.id}
                                temporaryWorker={temporaryWorker}
                                shift={shift}
                                onColorSelect={onTemporaryWorkerColorSelect}
                                onDeleteClick={canDeleteShiftPlannings ? onDeleteTemporaryWorkerClick : undefined}
                                onEditClick={canEditShiftPlannings ? onEditTemporaryWorkerClick : undefined}
                            />
                        ))}

                        {(canEditShiftPlannings && !!(shift.temporaryWorkers.length || plannedShiftPlannings.length)) && (
                            <ConnectedHelpTooltip
                                index={1}
                                route="calendar"
                                subTitle={trans('help.shiftsDetail.colors.title')}
                                text={trans('help.shiftsDetail.colors.text')}
                                title={trans('help.shiftsDetail.title')}
                                className="shift-detail-employees-form__colors-tooltip"
                            />
                        )}
                    </div>

                    {canAddShiftPlannings && (
                        <Button
                            text={`+ ${trans('containers.forms.shiftDetailForm.addTemporaryWorker')}`}
                            onClick={onAddTemporaryWorkerClick}
                            className="shift-detail-employees-form__add-temporary-worker-button"
                        />
                    )}

                    {shift.previouslyPlannedUsers.map(user => (
                        <PlannedUserCard
                            key={user.id}
                            id={user.id}
                            userName={getPersonFullName(user.person)}
                            className="shift-detail-employees-form__previously-planned-user-card"
                        />
                    ))}

                    {unplannedShiftPlannings.length > 0 && (
                        <div className="shift-detail-employees-form__unplanned-shift-plannings">
                            <h3 className="shift-detail-employees-form__unplanned-shift-plannings-title">
                                {`${trans('containers.forms.shiftDetailForm.available')} (${unplannedShiftPlannings.length})`}
                            </h3>

                            {unplannedShiftPlannings.map(shiftPlanning => {
                                const workweekUser = users.find(user => user.id === shiftPlanning.user.id);

                                return (
                                    <ShiftPlanningCard
                                        key={shiftPlanning.id}
                                        showComments
                                        absences={absences}
                                        leaveOfAbsences={leaveOfAbsences}
                                        settings={settings}
                                        shiftPlanning={shiftPlanning}
                                        shift={shift}
                                        preferToWorkTimeSlots={preferToWorkTimeSlots}
                                        recentShiftPlannings={recentShiftPlannings}
                                        unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                                        user={workweekUser}
                                        onAddButtonClick={canAddShiftPlannings ? onAddShiftPlanningClick : undefined}
                                        onDeleteButtonClick={canDeleteShiftPlannings ? onDeleteShiftPlanningClick : undefined}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </ModalContent>
        </div>
    );
};

export default ShiftDetailEmployeesForm;
