import { PersonFormData } from '../../../../entities/Person/Person';
import { EditUserFormData, PersonalInfoFormData } from '../../../../entities/User/User';
import {
    FormErrors,
    FormValidation,
    validateContractHoursInput,
    validateDate,
    validateEmailRegex,
    validateForm,
    validateRequireContractHours,
    validateRequiredString,
    validateStringMinimalLength,
} from '../../../../services/validationService';

export type EditPersonalInfoFormErrors = FormErrors<PersonalInfoFormData>;
export type EditUserFormErrors = FormErrors<EditUserFormData> & FormErrors<PersonFormData>;

export const validatePersonalInfoFormData = (formData: PersonalInfoFormData): FormValidation<EditPersonalInfoFormErrors> => {
    const errors: EditPersonalInfoFormErrors = {
        firstname: validateRequiredString('firstname', formData.firstname),
        lastname: validateRequiredString('lastname', formData.lastname),
        email: validateEmailRegex('email', formData.email),
        phoneNumber: validateStringMinimalLength('phoneNumber', formData.phoneNumber, 10),
        birthday: validateDate(formData.birthday),
    };

    return validateForm<EditPersonalInfoFormErrors>(errors);
};

export const validateEditUserFormData = (formData: EditUserFormData, canEditMainDepartment: boolean): FormValidation<EditUserFormErrors> => {
    const errors: EditUserFormErrors = {
        mainDepartment: canEditMainDepartment ? validateRequiredString('mainDepartment', formData.mainDepartment) : undefined,
        contractHours: formData.contractHours ? validateContractHoursInput(formData.contractHours) : undefined,
        identificationExpirationDate: formData.person.identificationExpirationDate ? validateDate(formData.person.identificationExpirationDate) : undefined,
        isEligibleForTimeForTime: formData.isEligibleForTimeForTime ? validateRequireContractHours('isEligibleForTimeForTime', formData.contractHours ?? []) : undefined,
    };

    return validateForm<EditUserFormErrors>(errors);
};
