import { Absence } from '../../../../entities/Absence/Absence';
import { doesAbsenceOverlapWithPeriod } from '../../../../entities/Absence/AbsenceHelpers';
import { LeaveOfAbsence } from '../../../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { Period } from '../../../../entities/Period/Period';
import { doPeriodsOverlap } from '../../../../entities/Period/PeriodHelpers';
import { PreferToWorkTimeSlot } from '../../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { doesPreferToWorkTimeSlotOverlapWithPeriod } from '../../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlotHelpers';
import { UnavailableToWorkTimeSlot } from '../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { doesUnavailableToWorkTimeSlotOverlapWithPeriod } from '../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotHelpers';
import { WorkweekUser } from '../../../../entities/User/User';
import { getPersonFullName } from '../../../../helpers';
import { CompanyMedicType } from '../../../../types';
import { UsersSelectOption } from '../../../index';
import { WorkweekUserSelectOption } from '../WorkweekUsersSelect';

export const transformToWorkweekUserSelectOptions = (
    absences: Absence[],
    leaveOfAbsences: LeaveOfAbsence[],
    period: Period,
    preferToWorkTimeSlots: PreferToWorkTimeSlot[],
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[],
    users: WorkweekUser[],
): WorkweekUserSelectOption[] => {
    const overlappingAbsences = absences.filter(absence => doesAbsenceOverlapWithPeriod(absence, period));
    const overlappingLeaveOfAbsences = leaveOfAbsences.filter(leaveOfAbsence => doPeriodsOverlap(leaveOfAbsence.period, period));
    const overlappingUnavailableToWorkTimeSlots = unavailableToWorkTimeSlots
        .filter(unavailableToWorkTimeSlot => doesUnavailableToWorkTimeSlotOverlapWithPeriod(unavailableToWorkTimeSlot, period));
    const overlappingPreferToWorkTimeSlots = preferToWorkTimeSlots
        .filter(preferToWorkTimeSlot => doesPreferToWorkTimeSlotOverlapWithPeriod(preferToWorkTimeSlot, period));

    return users.map(user => (
        {
            value: getPersonFullName(user.person),
            label: (
                <UsersSelectOption
                    key={user.id}
                    isCompanyMedic={user.companyMedic === CompanyMedicType.yes}
                    absence={overlappingAbsences.find(absence => absence.user.id === user.id)}
                    contractHours={user.contractHours}
                    fullName={getPersonFullName(user.person)}
                    leaveOfAbsence={overlappingLeaveOfAbsences.find(leaveOfAbsence => leaveOfAbsence.user.id === user.id)}
                    period={period}
                    plannedHoursInWeek={user.plannedHoursInWeek}
                    preferToWorkTimeSlot={overlappingPreferToWorkTimeSlots.find(preferToWorkTimeSlot => preferToWorkTimeSlot.user.id === user.id)}
                    unavailableToWorkTimeSlot={overlappingUnavailableToWorkTimeSlots.find(unavailableToWorkTimeSlot => unavailableToWorkTimeSlot.user.id === user.id)}
                />
            ),
            userId: user.id,
        }
    ));
};
