import { FC, useMemo } from 'react';

import { getUserFullName } from '../../../../../helpers';
import { getLeaveOfAbsenceDateLabel } from '../../../../entities/LeaveOfAbsence/LeaveOfAbsenceHelper';
import { ExportLeaveOfAbsenceSyncError } from '../helpers';

import './ExportLeaveOfAbsencesFormError.scss';

interface ExportLeaveOfAbsencesFormErrorProps {
    exportLeaveOfAbsenceSyncError: ExportLeaveOfAbsenceSyncError;
    className?: string;
}

const ExportLeaveOfAbsencesFormError: FC<ExportLeaveOfAbsencesFormErrorProps> = ({
    exportLeaveOfAbsenceSyncError,
    className = '',
}) => {
    const { leaveOfAbsence } = exportLeaveOfAbsenceSyncError;
    const userFullName = getUserFullName(leaveOfAbsence.user.person);
    const dateString = useMemo(() => getLeaveOfAbsenceDateLabel(leaveOfAbsence.period), [leaveOfAbsence]);

    return (
        <div className={`export-leave-of-absences-form-error ${className}`}>
            <p className="export-leave-of-absences-form-error__name-and-date">
                <strong>{`${userFullName} `}</strong>
                {dateString}
            </p>
            <p className="export-leave-of-absences-form-error__message">
                {exportLeaveOfAbsenceSyncError.message}
            </p>
        </div>
    );
};

export default ExportLeaveOfAbsencesFormError;
