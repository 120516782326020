import { DistrictDivisionGroupTemplate } from '../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import { patchDistrictDivisionGroupTemplateApiCall } from '../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplateService';
import { transformDistrictDivisionGroupTemplateToEditDistrictDivisionGroupTemplateFormData } from '../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplateTransformers';
import { AddDistrictDivisionTemplateFormData, DistrictDivisionTemplate, EditDistrictDivisionTemplateFormData } from '../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import {
    deleteDistrictDivisionTemplateApiCall,
    getDistrictDivisionTemplateApiCall,
    patchDistrictDivisionTemplateApiCall,
    postDistrictDivisionTemplateApiCall,
} from '../../entities/DistrictDivisionTemplate/DistrictDivisionTemplateService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { setDistrictDivisionTemplates } from '../districtDivisionTemplates/districtDivisionTemplatesReducer';
import { Reducers } from '../reducers';
import { TypedDispatch } from '../store';
import {
    setDistrictDivisionTemplate,
    setDistrictDivisionTemplateToExistingDivisionTemplates,
    setError,
    setIsLoading,
    setIsSuccessful,
    setIsUpdating,
} from './districtDivisionTemplateReducer';

export const getDistrictDivisionTemplate = (districtDivisionTemplateId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getDistrictDivisionTemplateApiCall(districtDivisionTemplateId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDistrictDivisionTemplate(response.data));
    } catch (error) {
        console.error('[getDistrictDivisionTemplate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addDistrictDivisionTemplate = (addDistrictDivisionTemplateFormData: AddDistrictDivisionTemplateFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await postDistrictDivisionTemplateApiCall(addDistrictDivisionTemplateFormData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDistrictDivisionTemplateToExistingDivisionTemplates(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addDistrictDivisionTemplate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editDistrictDivisionTemplate = (
    editDistrictDivisionTemplateFormData: EditDistrictDivisionTemplateFormData,
) => async (dispatch: TypedDispatch, getState: () => Reducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const { districtDivisionTemplatesReducer } = getState();
        const response = await patchDistrictDivisionTemplateApiCall(editDistrictDivisionTemplateFormData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const { districtDivisionTemplates } = districtDivisionTemplatesReducer;
        const filteredDistrictDivisionTemplates = districtDivisionTemplates.filter(districtDivisionTemplate => districtDivisionTemplate.id !== response.data.id);
        const indexOfDistrictDivisionTemplate = districtDivisionTemplates.map(districtDivisionTemplate => districtDivisionTemplate.id).indexOf(response.data.id);

        const newDistrictDivisionTemplates = [
            ...filteredDistrictDivisionTemplates.slice(0, indexOfDistrictDivisionTemplate),
            response.data,
            ...filteredDistrictDivisionTemplates.slice(indexOfDistrictDivisionTemplate),
        ];

        dispatch(setDistrictDivisionTemplates(newDistrictDivisionTemplates));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editDistrictDivisionTemplate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteDistrictDivisionTemplate = (districtDivisionTemplateId: string) => async (dispatch: TypedDispatch, getState: () => Reducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const { districtDivisionTemplatesReducer } = getState();
        const response = await deleteDistrictDivisionTemplateApiCall(districtDivisionTemplateId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const { districtDivisionTemplates } = districtDivisionTemplatesReducer;
        const filteredDistrictDivisionTemplates = districtDivisionTemplates.filter(districtDivisionTemplate => districtDivisionTemplate.id !== districtDivisionTemplateId);

        dispatch(setDistrictDivisionTemplates(filteredDistrictDivisionTemplates));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteDistrictDivisionTemplate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

const editDistrictDivisionGroupTemplateOrder = async (
    districtDivisionGroupTemplate: DistrictDivisionGroupTemplate,
    districtDivisionTemplateId: string,
): Promise<DistrictDivisionGroupTemplate> => {
    const formData = transformDistrictDivisionGroupTemplateToEditDistrictDivisionGroupTemplateFormData(districtDivisionGroupTemplate, districtDivisionTemplateId);
    const response = await patchDistrictDivisionGroupTemplateApiCall(formData);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('editDistrictDivisionGroupTemplateOrder');
    }

    return response.data;
};

export const editDistrictDivisionTemplateGroupsOrder = (
    districtDivisionGroupTemplates: DistrictDivisionGroupTemplate[],
    districtDivisionTemplate: DistrictDivisionTemplate,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const responses = await Promise.all(districtDivisionGroupTemplates.map(group => editDistrictDivisionGroupTemplateOrder(group, districtDivisionTemplate.id)));

        const updatedDistrictDivisionTemplate = {
            ...districtDivisionTemplate,
            districtDivisionGroupTemplates: responses,
        };

        dispatch(setDistrictDivisionTemplate(updatedDistrictDivisionTemplate));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editDistrictDivisionTemplateGroupsOrder]', error);
    } finally {
        dispatch(setIsUpdating(false));
    }
};
