import { FC, ReactElement, useEffect } from 'react';

import { EditShiftTimeForm } from '../../../containers';
import { Period } from '../../../entities/Period/Period';
import trans from '../../../helpers/trans';
import { deleteBaseScheduleShift, editBaseScheduleShiftTime } from '../../../redux/baseScheduleShift/baseScheduleShiftActions';
import { setIsEditBaseScheduleShiftSuccessful } from '../../../redux/baseScheduleShift/baseScheduleShiftReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedEditBaseScheduleShiftTimeFormProps {
    period: Period;
    baseScheduleShiftId: string;
    onClose: () => void;
    className?: string;
}

const ConnectedEditBaseScheduleShiftTimeForm: FC<ConnectedEditBaseScheduleShiftTimeFormProps> = ({
    period,
    baseScheduleShiftId,
    onClose,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isEditBaseScheduleShiftSuccessful, isDeleteBaseScheduleShiftSuccessful } = useTypedSelector(state => state.pacoBaseScheduleShiftReducer);

    const handleDeleteButtonClick = (): void => {
        dispatch(deleteBaseScheduleShift(baseScheduleShiftId));
    };

    const handleSubmit = (value: Period): void => {
        dispatch(editBaseScheduleShiftTime({ period: value }, baseScheduleShiftId));
    };

    useEffect((): void => {
        if (isEditBaseScheduleShiftSuccessful) {
            onClose();

            dispatch(setIsEditBaseScheduleShiftSuccessful(false));
            dispatch(setToast({
                text: trans('containers.forms.editShiftTimeForm.editShiftTimeSuccess'),
                type: ToastType.pass,
            }));
        }
    }, [isEditBaseScheduleShiftSuccessful]);

    useEffect((): void => {
        if (isDeleteBaseScheduleShiftSuccessful) {
            onClose();

            dispatch(setToast({
                text: trans('containers.forms.editShiftTimeForm.deleteShiftSuccess'),
                type: ToastType.pass,
            }));
        }
    }, [isDeleteBaseScheduleShiftSuccessful]);

    return (
        <EditShiftTimeForm
            period={period}
            onSubmit={handleSubmit}
            onDeleteButtonClick={handleDeleteButtonClick}
            onCancel={onClose}
            className={className}
        />
    );
};

export default ConnectedEditBaseScheduleShiftTimeForm;
