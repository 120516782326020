import { eachDayOfInterval, endOfWeek, startOfWeek } from '../../../helpers/date';

export const setCustomDateRegisteredShift = (date: Date) => {
    if (date) {
        const weekStart = startOfWeek(date, { weekStartsOn: 1 });
        const weekEnd = endOfWeek(date, { weekStartsOn: 1 });

        const newSelectedDays = eachDayOfInterval({
            start: weekStart,
            end: weekEnd,
        });

        return newSelectedDays;
    }

    return [];
};
