import { addHours, formatDate } from '../../../../../helpers/date';
import { AddShiftFormData } from '../../../../../models';

export default function generateAddShiftFormDataEmptyValues(date?: Date): AddShiftFormData {
    return {
        department: '',
        date: date || new Date(),
        startTime: formatDate(new Date(), 'HH:00'),
        endTime: formatDate(addHours(new Date(), 6), 'HH:00'),
        peopleLimit: 1,
    };
}
