import { DistrictDivisionGroupTemplate } from '../DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import {
    AddDistrictDivisionTemplateFormData,
    AddDistrictDivisionTemplateRequest,
    DistrictDivisionTemplate,
    DistrictDivisionTemplateResource,
    EditDistrictDivisionTemplateFormData,
    EditDistrictDivisionTemplateRequest,
} from './DistrictDivisionTemplate';

export const transformToDistrictDivisionTemplate = (
    districtDivisionTemplateResource: DistrictDivisionTemplateResource,
    districtDivisionGroupTemplates: DistrictDivisionGroupTemplate[],
): DistrictDivisionTemplate => ({
    id: districtDivisionTemplateResource.id,
    name: districtDivisionTemplateResource.attributes.name,
    districtDivisionGroupTemplates,
});

export const transformAddDistrictDivisionTemplateFormDataToAddApiParams = (
    data: AddDistrictDivisionTemplateFormData,
): AddDistrictDivisionTemplateRequest => ({
    type: 'districtDivisionTemplates',
    attributes: {
        name: data.name,
    },
});

export const transformEditDistrictDivisionTemplateFormDataToEditApiParams = (
    data: EditDistrictDivisionTemplateFormData,
): EditDistrictDivisionTemplateRequest => ({
    id: data.id,
    type: 'districtDivisionTemplates',
    attributes: {
        name: data.name,
    },
});
