import React from 'react';

import { SvgIconProps } from '../models';
import {
    IconCalendarCheck,
    IconCalendarComment,
    IconCalendarQuestion,
    IconCalendarWarning,
    IconClose,
    IconExit,
    IconEye,
    IconInfo,
    IconMedicalBag,
    IconPlus,
    IconPlusShiftConcept,
    IconTrash,
    IconWarning,
} from '../set';

const getSvgSource = (kind: string): React.FC<SvgIconProps> | undefined => {
    switch (kind) {
        case 'calendar-check':
            return IconCalendarCheck;
        case 'calendar-comment':
            return IconCalendarComment;
        case 'calendar-question':
            return IconCalendarQuestion;
        case 'calendar-warning':
            return IconCalendarWarning;
        case 'close':
            return IconClose;
        case 'exit':
            return IconExit;
        case 'eye':
            return IconEye;
        case 'info':
            return IconInfo;
        case 'medical-bag':
            return IconMedicalBag;
        case 'plus':
            return IconPlus;
        case 'plus-shift-concept':
            return IconPlusShiftConcept;
        case 'trash':
            return IconTrash;
        case 'warning':
            return IconWarning;
        default:
            return undefined;
    }
};

export default getSvgSource;
