import { isFetchCollectionResultSuccessful } from '../../../entities/FetchResult';
import { getLoketLeaveOfAbsencesHoursApiCall } from '../../../entities/LeaveOfAbsenceHour/LeaveOfAbsenceHourService';
import { LoketLeaveSyncFormData } from '../../../entities/LoketLeaveSync/LoketLeaveSync';
import { putLoketLeaveSyncApiCall } from '../../../entities/LoketLeaveSync/LoketLeaveSyncService';
import trans from '../../../helpers/trans';
import { TypedDispatch } from '../../store';
import {
    setError,
    setIsLoading,
    setIsLoketLeaveSyncSuccessful,
    setIsSuccessful,
    setLeaveOfAbsenceHours,
    setLoketLeaveSyncErrors,
} from './exportLeaveOfAbsencesFormReducer';

export const syncLeaveOfAbsencesToLoket = (exportLeaveOfAbsencesFormData: LoketLeaveSyncFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setLoketLeaveSyncErrors([]));
    dispatch(setLeaveOfAbsenceHours([]));
    dispatch(setIsSuccessful(false));
    dispatch(setIsLoketLeaveSyncSuccessful(false));

    try {
        const response = await putLoketLeaveSyncApiCall(exportLeaveOfAbsencesFormData);

        if (!isFetchCollectionResultSuccessful(response)) {
            console.error('[syncLeaveOfAbsencesToLoket]', response.error);

            dispatch(setError(response.error));
            dispatch(setIsLoading(false));

            return;
        }

        dispatch(setLoketLeaveSyncErrors(response.data));
        dispatch(setIsLoketLeaveSyncSuccessful(true));
    } catch (error) {
        console.error('[syncLeaveOfAbsencesToLoket]', error);

        dispatch(setError(trans('errors.unknownError')));
        dispatch(setIsLoading(false));
    }
};

export const getLoketLeaveOfAbsenceHours = (leaveOfAbsenceHourIds: string[]) => async (dispatch: TypedDispatch): Promise<void> => {
    try {
        const loketLeaveOfAbsenceHours = await Promise.all(leaveOfAbsenceHourIds.map(async (leaveOfAbsenceHourId) => {
            const response = await getLoketLeaveOfAbsencesHoursApiCall(leaveOfAbsenceHourId);

            if (!isFetchCollectionResultSuccessful(response)) {
                console.error('[getLeaveOfAbsencesHoursApiCall]', response.error);

                throw response.error;
            }

            return response.data;
        }));

        dispatch(setLeaveOfAbsenceHours(loketLeaveOfAbsenceHours));
        dispatch(setIsSuccessful(true));
    } catch {
        dispatch(setError(trans('errors.unknownError')));
    }

    dispatch(setIsLoading(false));
};
