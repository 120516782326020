import { FC, MouseEvent, useState } from 'react';

import classnames from 'classnames';

import { Icon } from '../../../../components';
import { DistrictDivisionDistrictPlanning } from '../../../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { Setting } from '../../../../entities/Setting/Setting';
import trans from '../../../../helpers/trans';
import { DraggableDistrictDivisionDistrictPlanning } from '../index';

import './DistrictDivisionDistrictPlanningItem.scss';

interface DistrictDivisionDistrictPlanningItemProps {
    isDraggingDistrictDivisionDistrictPlanning: boolean;
    isDraggingShiftPlanning: boolean;
    showAddButton: boolean;
    showDeleteButton: boolean;
    index: number;
    planning?: DistrictDivisionDistrictPlanning;
    settings: Setting[];
    onAddCommentButtonClick: (DistrictDivisionDistrictPlanning: DistrictDivisionDistrictPlanning) => void;
    onDeleteButtonClick: (districtDivisionDistrictPlanningId: string, type: string) => void;
    onDragEnd: () => void;
    onDragStart: (districtDivisionDistrictPlanning: DistrictDivisionDistrictPlanning) => void;
    onDrop: (index: number, districtDivisionDistrictPlanning?: DistrictDivisionDistrictPlanning) => void;
    className?: string;
}

const DistrictDivisionDistrictPlanningItem: FC<DistrictDivisionDistrictPlanningItemProps> = ({
    isDraggingDistrictDivisionDistrictPlanning,
    isDraggingShiftPlanning,
    showAddButton,
    showDeleteButton,
    index,
    planning,
    settings,
    onAddCommentButtonClick,
    onDeleteButtonClick,
    onDragEnd,
    onDragStart,
    onDrop,
    className = '',
}) => {
    const [isDragEnter, setIsDragEnter] = useState<boolean>(false);
    const isDraggingPlanning = isDraggingDistrictDivisionDistrictPlanning || isDraggingShiftPlanning;

    const classNames = classnames('district-division-district-planning', {
        'district-division-district-planning--is-active': isDragEnter,
        'district-division-district-planning--disable-add-button': !showAddButton,
        'district-division-district-planning--is-dragging-planning': isDraggingPlanning,
    }, className);

    const handleElementDragOver = (e: MouseEvent): void => {
        e.preventDefault();
    };

    const handleElementDragEnter = (): void => {
        setIsDragEnter(true);
    };

    const handleElementDragLeave = (): void => {
        setIsDragEnter(false);
    };

    const handleElementDrop = (): void => {
        setIsDragEnter(false);
        onDrop(index, planning);
    };

    return (
        <div className={classNames}>
            <div className="district-division-district-planning__index">
                {index + 1}
            </div>
            {planning ? (
                <DraggableDistrictDivisionDistrictPlanning
                    showDeleteButton={showDeleteButton}
                    isDisabled={isDraggingShiftPlanning}
                    planning={planning}
                    settings={settings}
                    onAddCommentButtonClick={onAddCommentButtonClick}
                    onDeleteButtonClick={onDeleteButtonClick}
                    onDragEnd={onDragEnd}
                    onDragEnter={handleElementDragEnter}
                    onDragLeave={handleElementDragLeave}
                    onDragStart={onDragStart}
                    onDrop={handleElementDrop}
                    className="district-division-district-planning__planning"
                />
            ) : (
                <div
                    onDragOver={handleElementDragOver}
                    onDragEnter={handleElementDragEnter}
                    onDragLeave={handleElementDragLeave}
                    onDrop={handleElementDrop}
                    className="district-division-district-planning__add-employee"
                >
                    {showAddButton && (
                        <>
                            <Icon name="plus" className="district-division-district-planning__plus-icon" />
                            {!isDraggingPlanning && trans('compositions.districtDivisionDistrict.addEmployee')}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default DistrictDivisionDistrictPlanningItem;
