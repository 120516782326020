import { FC, useEffect } from 'react';

import { CopyShiftForm } from '../../../containers';
import { CopyShiftFormData, ShiftIndex } from '../../../entities/Shift/Shift';
import trans from '../../../helpers/trans';
import { copyShift } from '../../../redux/shift/shiftActions';
import { setError, setIsCopyShiftSuccessful } from '../../../redux/shift/shiftReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedCopyShiftFormProps {
    dateOffset: number;
    shift: ShiftIndex;
    onClose: () => void;
}

const ConnectedCopyShiftForm: FC<ConnectedCopyShiftFormProps> = ({ dateOffset, shift, onClose }) => {
    const dispatch = useTypedDispatch();
    const { isLoading, isCopyShiftSuccessful, error } = useTypedSelector(state => state.shiftReducer);

    const handleCopyShift = (shiftFormData: CopyShiftFormData) => {
        dispatch(copyShift(shiftFormData, shift.id, dateOffset));
    };

    useEffect(() => {
        if (isCopyShiftSuccessful) {
            dispatch(setIsCopyShiftSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.copyShiftForm.copyShiftSuccess'),
                type: ToastType.pass,
                shouldFadeOutAutomatically: true,
            }));

            onClose();
        }
    }, [isCopyShiftSuccessful]);

    useEffect(() => {
        if (error) {
            dispatch(setToast({
                text: trans(error),
                type: ToastType.fail,
            }));
            dispatch(setError(''));
            onClose();
        }
    }, [error]);

    return (
        <CopyShiftForm
            isLoading={isLoading}
            onSubmit={handleCopyShift}
            onCancel={onClose}
        />
    );
};

export default ConnectedCopyShiftForm;
