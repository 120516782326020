import { Period } from '../@paco/entities/Period/Period';
import UpToAndIncludingDate from '../helpers/date/UpToAndIncludingDate';
import { CommentResource, CommentViewModel } from './Comment';
import { Resource } from './Resource';
// eslint-disable-next-line import/no-cycle
import { ShiftResource, ShiftViewModel } from './Shift';
import { BasicUser, UserWithPersonResource, UserWithPersonViewModel } from './User';
// eslint-disable-next-line import/no-cycle
import { BasicUserWithAbsenceData } from './UserWithAbsenceData';

export enum ShiftPlanningType {
    shift = 'shiftPlannings',
    baseSchedule = 'baseScheduleShiftPlannings',
}

export interface ShiftPlanning extends Resource {
    type: ShiftPlanningType,
    start: string;
    end: string;
    customStartTime: boolean;
    planned: boolean;
    color: string | null;
    createdAt: string;
    updatedAt: string;
    shift: {
        type: 'shifts';
        id: string;
        start: string;
        end: string;
        department: {
            type: 'departments';
            id: string;
            name: string;
            group: {
                type: 'departments';
                id: string;
                name: string;
            } | null;
        }
    };
    comments: CommentResource[];
    user: BasicUser | BasicUserWithAbsenceData;
}

export interface ShiftPlanningResource extends Resource {
    type: 'shiftPlannings';
    color: string | null;
    customStartTime: boolean;
    end: string;
    planned: boolean;
    start: string;
    user?: UserWithPersonResource;
    shift?: ShiftResource;
    comments?: CommentResource[];
}

export interface ShiftPlanningViewModel {
    type: ShiftPlanningType;
    id: string;
    color: string | null;
    customStartTime: boolean;
    end: UpToAndIncludingDate;
    planned: boolean;
    start: Date;
    user?: UserWithPersonViewModel;
    shift?: ShiftViewModel;
    comments: CommentViewModel[];
}

export interface AddShiftPlanningFormData {
    shiftId: string;
    userId: string;
}

export interface EditShiftPlanningFormData {
    comment: string;
    startTime: string;
    endTime: string;
}

export interface EditShiftPlanningTimeFormData {
    period: Period;
}

export interface CopyShiftPlanningData {
    shiftId: string;
    userId: string;
    comment?: string;
    start: string;
    end: string;
    planned: boolean;
    color: string | null;
}
