import { BaseScheduleFormData } from '../../../../../entities/BaseSchedule/BaseSchedule';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../../services/validationService';

export type BaseScheduleFormErrors = FormErrors<BaseScheduleFormData>;

export const validateFormData = (formData: BaseScheduleFormData): FormValidation<BaseScheduleFormErrors> => {
    const errors: BaseScheduleFormErrors = {
        name: validateRequiredString('name', formData.name),
    };

    return validateForm<BaseScheduleFormErrors>(errors);
};
