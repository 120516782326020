import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SpecialDayViewModel } from '../../../models';
import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';

export type SpecialDaysState = AsyncReduxState<{
    specialDays: SpecialDayViewModel[];
}>;

const initialState: SpecialDaysState = {
    ...initialAsyncReduxState,
    specialDays: [],
};

export const SpecialDaysSlice = createSlice({
    name: 'specialDaysReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): SpecialDaysState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setSpecialDays(state, action: PayloadAction<SpecialDayViewModel[]>): SpecialDaysState {
            return ({
                ...state,
                specialDays: action.payload,
            });
        },
    },
});

export const { setIsLoading, setSpecialDays } = SpecialDaysSlice.actions;

export default SpecialDaysSlice.reducer;


