import React, { FC } from 'react';

import { CheckboxWithTextCallback, CheckboxWithTextParams } from './models';
import CheckboxWithText from './subcomponents/CheckboxWithText/CheckboxWithText';

import './CheckboxesWithTextForm.scss';

interface Props {
    className?: string;
    checkBoxTitle: string;
    textTitle: string;
    checkboxes: CheckboxWithTextParams[];
    onCheckboxChange: CheckboxWithTextCallback;
}

const CheckboxesWithTextForm: FC<Props> = ({
    className,
    checkBoxTitle,
    textTitle,
    checkboxes,
    onCheckboxChange,
}) => (
    <div className={`checkboxes-with-text ${className || ''}`}>
        <div className="checkboxes-with-text__labels">
            <div className="checkboxes-with-text__label-checkbox">
                {checkBoxTitle}
            </div>
            <div className="checkboxes-with-text__label-text">
                {textTitle}
            </div>
        </div>
        {
            checkboxes.map((checkbox) => (
                <CheckboxWithText
                    key={checkbox.id}
                    id={checkbox.id}
                    checked={checkbox.checked}
                    label={checkbox.label}
                    onChange={onCheckboxChange}
                    noValue={checkbox.noValue}
                    value={checkbox.value}
                />
            ))
        }
    </div>
);

export default CheckboxesWithTextForm;
