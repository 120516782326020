import { FC } from 'react';

import classnames from 'classnames';

import { Button } from '../../components';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';
import trans from '../../helpers/trans';
import { TimeModeType } from '../../types';
import { DateFormat } from '../../types/dateFormatTypes';
import { ElementWithTooltip, IconButton, TimeModeSelect } from '..';
import { DateRangeInput } from './subcomponents';

import './CalendarControls.scss';

interface CalendarControlsProps {
    isMobile?: boolean;
    showTimeModeSelect?: boolean;
    activePayrollPeriod?: PayrollPeriod;
    dateFormat?: DateFormat;
    inputType?: TimeModeType.date | TimeModeType.week | TimeModeType.month;
    selectedDays: Date[];
    timeMode?: TimeModeType;
    timeModeOptions?: TimeModeType[];
    onDateChange: (date: Date) => void;
    onIncrementClick: (goForward: boolean) => void;
    onTimeModeChange?: (timeMode: TimeModeType) => void;
    onTodayButtonClick: () => void;
    className?: string;
    dateNavigationClassName?: string;
    todayButtonClassName?: string;
}

const CalendarControls: FC<CalendarControlsProps> = ({
    isMobile,
    showTimeModeSelect,
    activePayrollPeriod,
    dateFormat,
    inputType = 'date',
    selectedDays,
    timeMode,
    timeModeOptions = [],
    onDateChange,
    onIncrementClick,
    onTimeModeChange,
    onTodayButtonClick,
    className = '',
    dateNavigationClassName = '',
    todayButtonClassName = '',
}) => {
    const calenderControlsClassNames = classnames('calendar-controls', {
        'calendar-controls--is-mobile': isMobile,
    }, [className]);

    const handlePrevButtonClick = () => {
        onIncrementClick(false);
    };

    const handleNextButtonClick = () => {
        onIncrementClick(true);
    };

    return (
        <div className={calenderControlsClassNames}>
            <div className={`calendar-controls__date-navigation ${dateNavigationClassName}`}>
                {!isMobile && (
                    <Button
                        text={trans('compositions.calendarNavigation.toToday')}
                        onClick={onTodayButtonClick}
                        className={`calendar-controls__today-button ${todayButtonClassName}`}
                    />
                )}
                <IconButton
                    hideLabel
                    text={trans('common.previous')}
                    aria-label={trans('common.previous')}
                    icon="chevron-left"
                    onClick={handlePrevButtonClick}
                    className="calendar-controls__caret-button"
                />
                <ElementWithTooltip
                    tooltipIsActive={timeMode === TimeModeType.period && !!activePayrollPeriod}
                    tooltipText={`${trans('common.period')}: ${activePayrollPeriod?.periodNumber}`}
                >
                    <DateRangeInput
                        dateFormat={dateFormat}
                        selectedDays={selectedDays}
                        timeMode={timeMode}
                        type={inputType}
                        onDateChange={onDateChange}
                        className="calendar-controls__date-range-input"
                        labelClassName="calendar-controls__date-range-input-label"
                    />
                </ElementWithTooltip>
                <IconButton
                    text={trans('common.next')}
                    hideLabel
                    aria-label={trans('common.next')}
                    icon="chevron-right"
                    onClick={handleNextButtonClick}
                    className="calendar-controls__caret-button"
                />
            </div>

            {(!isMobile && timeMode && showTimeModeSelect) && (
                <TimeModeSelect
                    options={timeModeOptions}
                    value={timeMode}
                    onChange={onTimeModeChange}
                    className="calendar-controls__time-mode-select"
                />
            )}
        </div>
    );
};

export default CalendarControls;
