import { FC, useEffect, useMemo } from 'react';

import { HelpTooltip } from '../../compositions';
import {
    registerTooltip,
    setHelpActiveState,
    setTooltips,
    unregisterTooltip,
} from '../../redux/@interface/help/helpReducer';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { NavigationRouteKey } from '../../routes/routes';
import { getIncrementedTooltips, getTooltipsWithNewActiveIndex } from './helpers';

interface ConnectedHelpTooltipProps {
    index: number;
    route: NavigationRouteKey;
    subTitle: string;
    text: string;
    title: string;
    videoSrc?: string;
    showMobileInfoWarning?: boolean;
    className?: string;
}

const ConnectedHelpTooltip: FC<ConnectedHelpTooltipProps> = ({
    index,
    route,
    subTitle,
    title,
    text,
    videoSrc,
    showMobileInfoWarning,
    className,
}) => {
    const dispatch = useTypedDispatch();

    const { isActive: helpIsActive, tooltips } = useTypedSelector(state => state.helpReducer);
    const activeTooltipIndex = tooltips.findIndex(tooltip => tooltip.isActive);
    const hasNextSibling = useMemo(() => tooltips.some(tooltip => tooltip.index > index), [tooltips, index]);
    const hasPrevSibling = useMemo(() => tooltips.some(tooltip => tooltip.index < index), [tooltips, index]);

    const tooltipsLength = tooltips.length;

    const handleHelpButtonClick = () => {
        const newTooltips = getTooltipsWithNewActiveIndex(activeTooltipIndex !== index ? index : -1, tooltips);
        dispatch(setTooltips(newTooltips));
    };

    const handleNextButtonClick = () => {
        const newTooltips = getIncrementedTooltips(1, tooltips);
        dispatch(setTooltips(newTooltips));
    };

    const handlePrevButtonClick = () => {
        const newTooltips = getIncrementedTooltips(-1, tooltips);
        dispatch(setTooltips(newTooltips));
    };

    const handleStopButtonClick = () => {
        const newTooltips = getTooltipsWithNewActiveIndex(-1, tooltips);
        dispatch(setHelpActiveState(false));
        dispatch(setTooltips(newTooltips));
    };

    useEffect((): () => void => {
        dispatch(registerTooltip({ index, route }));

        return () => {
            dispatch(unregisterTooltip(index));
        };
    }, [route]);

    return (
        <HelpTooltip
            showButton={helpIsActive}
            showNextSiblingButton={hasNextSibling}
            showPrevSiblingButton={hasPrevSibling}
            showTooltip={activeTooltipIndex === index}
            videoSrc={videoSrc}
            index={index}
            subTitle={subTitle}
            text={text}
            title={title}
            tooltipsLength={tooltipsLength}
            showMobileInfoWarning={showMobileInfoWarning}
            onHelpButtonClick={handleHelpButtonClick}
            onNextButtonClick={handleNextButtonClick}
            onPrevButtonClick={handlePrevButtonClick}
            onStopButtonClick={handleStopButtonClick}
            className={className}
        />
    );
};

export default ConnectedHelpTooltip;
