import { FC, ReactElement } from 'react';

import { InputLabel } from '../../../components';
import { CheckboxList } from '../../../compositions';
import trans from '../../../helpers/trans';
import { FormOption } from '../../../types';

interface PzLogCategoriesFilterContainerProps {
    categoryOptions: FormOption[];
    onChange: (selectedCategoryOptions: FormOption[]) => void;
    className?: string;
}

const PzLogCategoriesFilterContainer: FC<PzLogCategoriesFilterContainerProps> = ({ categoryOptions, onChange, className = '' }): ReactElement => {
    const selectedCategoryOptions = categoryOptions.filter(categoryOption => categoryOption.isChecked);

    const handleOptionsChange = (checkedOptionValues: string[]): void => {
        const changedOptions = categoryOptions.map(categoryOption => ({
            ...categoryOption,
            isChecked: checkedOptionValues.some(checkedOptionValue => checkedOptionValue === categoryOption.value),
        }));

        onChange(changedOptions);
    };

    return (
        <div className={`pz-logs-categories-filter-container ${className}`}>
            <InputLabel text={trans('common.category')} />
            <CheckboxList
                hideLabel
                label={trans('common.category')}
                name={trans('common.category')}
                options={categoryOptions}
                value={selectedCategoryOptions.map(option => option.value)}
                onChange={handleOptionsChange}
            />
        </div>
    );
};

export default PzLogCategoriesFilterContainer;
