import { DistrictDivision } from '../../../entities/DistrictDivision/DistrictDivision';
import { DistrictDivisionDistrictPlanning, SwapDistrictDivisionDistrictPlanningsFormData } from '../../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import trans from '../../../helpers/trans';

export const getSelectDistrictDivisionTemplateIntro = (
    showTemplateSelector: boolean,
    districtDivisionTemplatesLength: number,
) => {
    if (!showTemplateSelector) {
        return trans('containers.districtDivisionContainer.userHasMissingDistrictDivisionsPermissions');
    }

    if (districtDivisionTemplatesLength) {
        return trans('containers.districtDivisionContainer.emptyDistrictDivisionIntro');
    }

    return trans('containers.districtDivisionContainer.noDistrictDivisionTemplatesCallToAction');
};

export const reduceDistrictDivisionToPlannings = (districtDivision: DistrictDivision): DistrictDivisionDistrictPlanning[] => districtDivision.districtDivisionGroups
    .map(group => group.districtDivisionDistricts
        .map(district => district.districtDivisionDistrictPlannings).flat())
    .flat();

export const transformDistrictDivisionPlanningsToFormData = (
    activePlanning: DistrictDivisionDistrictPlanning,
    targetPlanning: DistrictDivisionDistrictPlanning,
): SwapDistrictDivisionDistrictPlanningsFormData => ({
    activePlanning: {
        type: activePlanning.type,
        districtDivisionDistrictId: targetPlanning.districtDivisionDistrictId,
        planningId: activePlanning.id,
        index: targetPlanning.planningOrder,
    },
    targetPlanning: {
        type: targetPlanning.type,
        districtDivisionDistrictId: activePlanning.districtDivisionDistrictId,
        planningId: targetPlanning.id,
        index: activePlanning.planningOrder,
    },
});
