import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { LeaveOfAbsencesOverviewBodyRow } from '../../entities/LeaveOfAbsenceOverviewBlock/LeaveOfAbsencesOverviewBlock';

export type LeaveOfAbsencesOverviewState = AsyncReduxState<{
    leaveOfAbsencesOverviewBodyRows: LeaveOfAbsencesOverviewBodyRow[];
}>;

const initialState: LeaveOfAbsencesOverviewState = {
    ...initialAsyncReduxState,
    leaveOfAbsencesOverviewBodyRows: [],
};

export const LeaveOfAbsencesOverviewSlice = createSlice({
    name: 'leaveOfAbsencesOverviewReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): LeaveOfAbsencesOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): LeaveOfAbsencesOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setLeaveOfAbsencesOverviewBodyRows(state, action: PayloadAction<LeaveOfAbsencesOverviewBodyRow[]>): LeaveOfAbsencesOverviewState {
            return {
                ...state,
                leaveOfAbsencesOverviewBodyRows: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setLeaveOfAbsencesOverviewBodyRows,
} = LeaveOfAbsencesOverviewSlice.actions;

export default LeaveOfAbsencesOverviewSlice.reducer;
