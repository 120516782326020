import { transformToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import { TemporaryWorker } from '../../@paco/entities/TemporaryWorker/TemporaryWorker';
import { ShiftPlanningColor } from '../../@paco/types';
import { TemporaryWorkerResource } from '../../models';

export const transformTemporaryWorkerResource = (
    temporaryWorkerResource: TemporaryWorkerResource,
): TemporaryWorker => ({
    id: temporaryWorkerResource.id,
    type: temporaryWorkerResource.type,
    name: temporaryWorkerResource.name,
    period: transformToPeriod(new Date(temporaryWorkerResource.start), new Date(temporaryWorkerResource.end)),
    color: temporaryWorkerResource.color as ShiftPlanningColor,
    comments: [],
});
