import React, { FC } from 'react';

import { translate } from '../../../../helpers/translations/translator';
import { LogAction } from '../../../../models/Log';

import './LogBlockItem.scss';


export interface LogBlockItemProps {
    action: LogAction;
    propName: string;
    oldValue: string;
    newValue: string;
}

const LogBlockItem: FC<LogBlockItemProps> = ({
    action,
    propName,
    oldValue,
    newValue,
}) => (
    <tr className="log-block-item">
        {(action === 'update') && (
            <>
                <td className="log-block-item__prop-name">
                    {`${propName}: `}
                </td>
                <td className="log-block-item__value">
                    <span className="log-block-item__bold">
                        {`${translate('logs.oldValue')}:`}
                    </span>
                    {` ${oldValue}`}
                </td>
                <td className="log-block-item__value">
                    <span className="log-block-item__bold">
                        {`${translate('logs.newValue')}:`}
                    </span>
                    {` ${newValue} `}
                </td>
            </>
        )}
        {(action === 'create' || action === 'loket') && (
            <>
                {propName && (
                    <td className="log-block-item__prop-name">
                        {`${propName}: `}
                    </td>
                )}
                <td className="log-block-item__value">
                    {` ${newValue} `}
                </td>
            </>
        )}
        {(action === 'remove') && (
            <>
                <td className="log-block-item__prop-name">
                    {`${propName}: `}
                </td>
                <td className="log-block-item__value">
                    {` ${oldValue} `}
                </td>
            </>
        )}
    </tr>
);

export default LogBlockItem;
