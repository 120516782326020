import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import trans from '../../../../helpers/trans';

import './ShiftsWidgetHeaderSkeleton.scss';

interface ShiftsWidgetHeaderSkeletonProps {
    className?: string;
}

const ShiftsWidgetHeaderSkeleton: FC<ShiftsWidgetHeaderSkeletonProps> = ({ className = '' }): ReactElement => (
    <div className={`shifts-widget-header ${className}`}>
        <h2 className="shifts-widget-header__title">
            {trans('common.today')}
        </h2>

        <Skeleton className="shifts-widget-header__departments-skeleton" />
    </div>
);

export default ShiftsWidgetHeaderSkeleton;
