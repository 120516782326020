import { FC, ReactElement, useMemo } from 'react';

import { GroupedSelectOption, SelectOption } from '../../../components/@inputs/Select/Select';
import { Department } from '../../../entities/Department/Department';
import trans from '../../../helpers/trans';
import { SelectInput } from '../../index';
import { generateGroupedDepartmentOptions } from './helpers/generateGroupedDepartmentOptions';
import getSelectOption from './helpers/getSelectOption';

interface DepartmentInputProps {
    isLoading?: boolean;
    disabled?: boolean;
    departments: Department[];
    selectedDepartment?: SelectOption;
    label?: string;
    error?: string;
    onDepartmentChange: (department: SelectOption) => void;
    className?: string;
    selectClassName?: string;
}

const DepartmentInput: FC<DepartmentInputProps> = ({
    isLoading,
    disabled,
    departments,
    selectedDepartment,
    label = trans('common.department'),
    error,
    onDepartmentChange,
    className = '',
    selectClassName = '',
}): ReactElement => {
    const groupedDepartments = useMemo((): GroupedSelectOption[] => generateGroupedDepartmentOptions(departments), [departments]);

    const value = useMemo(
        () => (selectedDepartment ? getSelectOption(selectedDepartment, departments) : undefined),
        [selectedDepartment, departments],
    );

    return (
        <SelectInput
            isSearchable
            disabled={disabled}
            required
            requiredText={trans('common.required')}
            isLoading={isLoading}
            label={label}
            error={error}
            name="department"
            placeholder={trans('common.searchDepartment')}
            noOptionsMessage={trans('common.noDepartmentFound')}
            value={value}
            options={groupedDepartments}
            onChange={onDepartmentChange}
            className={className}
            selectClassName={selectClassName}
        />
    );
};

export default DepartmentInput;
