import { addSeconds, areIntervalsOverlapping } from '../../../../helpers/date';
import { CalendarItem } from '../../../containers/CalendarDays/CalenderDays';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../../helpers/date';

export const doesCalendarItemFallOnWorkDay = (item: CalendarItem, day: Date): boolean => areIntervalsOverlapping(
    {
        start: item.start,
        end: addSeconds(item.start, 1),
    },
    {
        start: getStartOfWorkDay(day),
        end: getEndOfWorkDay(day),
    },
);
