import axios from 'axios';

import { SettingFormData } from '../../models';

export const getSettings = () => {
    const includes = ['department'];
    return axios.get(
        `${process.env.REACT_APP_API_URL}/settings/`,
        {
            params: {
                include: includes.join(','),
            },
        },
    );
};

export const generateSettingsBody = (settings: SettingFormData[], patch: boolean) => settings.map(setting => ({
    type: 'setting',
    ...(patch && { id: setting.id }),
    attributes: {
        key: setting.key,
        value: setting.value,
        active: setting.active ? 1 : 0,
    },
    ...(setting.departmentId && {
        relationships: {
            department: {
                data: {
                    type: 'departments',
                    id: setting.departmentId,
                },
            },
        },
    }),
}));


export const putSettings = (settings: SettingFormData[]) => {
    const data = generateSettingsBody(settings, true);
    return axios.put(`${process.env.REACT_APP_API_URL}/settings`, { data, links: {} });
};

export const delSettings = async (settings: SettingFormData[]) => Promise.all(
    settings.map(setting => axios.delete(`${process.env.REACT_APP_API_URL}/settings/${setting.id}`)),
);
