export const getBase64 = (file: File) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(file);
    reader.onerror = () => {
        // @ts-ignore
        resolve();
    };
});
