import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconExport: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 48 48" className={`icon-component ${className}`}>
        {/* eslint-disable-next-line max-len */}
        <path d="M11 46q-1.2 0-2.1-.9Q8 44.2 8 43V17.55q0-1.2.9-2.1.9-.9 2.1-.9h8.45v3H11V43h26V17.55h-8.55v-3H37q1.2 0 2.1.9.9.9.9 2.1V43q0 1.2-.9 2.1-.9.9-2.1.9Zm11.45-15.35V7.8l-4.4 4.4-2.15-2.15L23.95 2 32 10.05l-2.15 2.15-4.4-4.4v22.85Z" />
    </svg>
);

export default IconExport;
