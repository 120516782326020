import React from 'react';

import PropTypes from 'prop-types';

import { translate } from '../../../../helpers/translations/translator';

import './Settings.scss';

const SettingsRadios = ({
    setting, title, onChange,
}) => {
    const name = `name-${Math.random()}`;
    const defaultValue = setting.value;
    const radios = [{ value: '1' }, { value: '0' }];

    return (
        <div className="settings-radios">
            <div className="settings-card-section-title">{title}</div>
            <form className="settings-radios-form">
                {radios.map((radio, index) => (
                    <label key={`${setting.key}-${radio.value}`} className="form-check">
                        <input
                            defaultChecked={radio.value === defaultValue}
                            onChange={() => {
                                onChange({
                                    ...setting,
                                    value: radio.value,
                                });
                            }}
                            className="form-check-input"
                            type="radio"
                            name={name}
                            value={radio.value}
                        />
                        <span className="form-check-label">
                            {index === 0 ? translate('common.yes') : translate('common.no')}
                        </span>
                    </label>
                ))}
            </form>
        </div>
    );
};

SettingsRadios.propTypes = {
    setting: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default SettingsRadios;
