import { Period } from '../../../entities/Period/Period';
import { ShiftIndex } from '../../../entities/Shift/Shift';
import { doesShiftHaveAbsence, doesShiftHaveOpenLeaveOfAbsence } from '../../../entities/Shift/ShiftHelpers';
import { ShiftsCalendarAbsence } from '../../../entities/ShiftsCalendarAbsence/ShiftsCalendarAbsence';
import { ShiftsCalendarLeaveOfAbsence } from '../../../entities/ShiftsCalendarLeaveOfAbsence/ShiftsCalendarLeaveOfAbsence';
import { addDays, differenceInHours, getStartOfWorkDay } from '../../../helpers/date';

export const filterShiftsCalendarShifts = (
    shifts: ShiftIndex[],
    absences: ShiftsCalendarAbsence[],
    leaveOfAbsences: ShiftsCalendarLeaveOfAbsence[],
    onlyShowShiftsWithAbsence: boolean,
    onlyShowShiftsWithOpenLeaveOfAbsence: boolean,
) => shifts
    .filter(shift => !onlyShowShiftsWithAbsence || doesShiftHaveAbsence(shift, absences))
    .filter(shift => !onlyShowShiftsWithOpenLeaveOfAbsence || doesShiftHaveOpenLeaveOfAbsence(shift, leaveOfAbsences));

export const getDifferenceInDaysBetweenShiftAndDate = (shift?: ShiftIndex, date?: Date): number => {
    if (!shift || !date) {
        return 0;
    }

    return Math.ceil(differenceInHours(getStartOfWorkDay(date), shift.period.start) / 24);
};

export const getPreviewCalendarItem = (shifts: ShiftIndex[], previewShiftId: string, previewShiftDay: Date): ShiftIndex | undefined => {
    const previewShift = shifts.find(shift => shift.id === previewShiftId);

    if (!previewShift) {
        return undefined;
    }

    const differenceInDays = getDifferenceInDaysBetweenShiftAndDate(previewShift, previewShiftDay);

    // We don't want to copy a shift to the same day.
    if (!differenceInDays) {
        return undefined;
    }

    const period: Period = {
        start: addDays(previewShift.period.start, differenceInDays),
        end: addDays(previewShift.period.end, differenceInDays),
    };

    return {
        ...previewShift,
        isPreview: true,
        id: `${previewShift.id}-preview`,
        period,
    };
};
