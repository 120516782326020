import { getLoketApiUrl } from '../../../helpers';
import { authorizedFetch } from '../../helpers/authorizedFetch';
import { generateDownloadURI } from '../../helpers/browser';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { YearEndStatement, YearEndStatementResource } from './YearEndStatement';
import { transformToYearEndStatement } from './YearEndStatementTransformers';

export const getYearEndStatementsApiCall = async (
    employeeId: string,
): Promise<FetchCollectionResult<YearEndStatement[], string>> => {
    try {
        const url = generateApiUrl({
            apiUrl: getLoketApiUrl(),
            endpoint: `/payslips/${employeeId}/year-end-statements`,
        });

        const response = await authorizedFetch(url);
        const doc: YearEndStatementResource[] = await response.json();

        if (!Array.isArray(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const yearEndStatements = doc.map(transformToYearEndStatement)
            .sort((a, b) => b.year - a.year);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: yearEndStatements,
        };
    } catch (error) {
        console.error('[getYearEndStatementsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const downloadYearEndStatementApiCall = async (
    employmentId: string,
    year: number,
): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({
            apiUrl: getLoketApiUrl(),
            endpoint: `/payslips/${employmentId}/year-end-statements/${year}`,
        });

        const response = await authorizedFetch(url);
        const blob = await response.blob();

        if (!response.ok || !blob) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const file = window.URL.createObjectURL(blob);
        generateDownloadURI(file, trans('common.yearEndStatement', { year }));
        window.open(file, '_blank');

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[downloadYearEndStatementApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
