import React, { FC } from 'react';

import { PayrollPeriod } from '../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { Permission } from '../../@paco/entities/Permission/Permission';
import { AuthenticatedUser } from '../../@paco/entities/User/User';
import { LeaveOfAbsence } from '../../models';
import LeaveOfAbsenceRequestCard from '../LeaveOfAbsenceRequestCard/LeaveOfAbsenceRequestCard';

import './LeaveOfAbsenceRequestCardsContainer.scss';

interface LeaveOfAbsenceRequestCardContainerProps {
    absences: LeaveOfAbsence[];
    currentUser: AuthenticatedUser;
    payrollPeriods: PayrollPeriod[];
    permissions: Permission[];
    onApprove: (absence: LeaveOfAbsence) => void;
    onDecline: (absence: LeaveOfAbsence) => void;
    onOpenCalendar?: () => void;
    onEditClick: (absence: LeaveOfAbsence) => void;
    className?: string;
}

const LeaveOfAbsenceRequestCardContainer:FC<LeaveOfAbsenceRequestCardContainerProps> = ({
    absences = [],
    currentUser,
    payrollPeriods,
    permissions,
    onApprove,
    onDecline,
    onOpenCalendar,
    onEditClick,
    className = '',
}) => (
    <div className={`leave-of-absence-request-cards-container ${className}`}>
        {absences.map(absence => (
            <LeaveOfAbsenceRequestCard
                key={absence.id}
                absence={absence}
                currentUserRole={currentUser.role}
                payrollPeriods={payrollPeriods}
                permissions={permissions}
                onApprove={() => onApprove(absence)}
                onDecline={() => onDecline(absence)}
                onEditClick={() => onEditClick(absence)}
                onOpenCalendar={onOpenCalendar}
            />
        ))}
    </div>
);

export default LeaveOfAbsenceRequestCardContainer;
