import { FC, useMemo } from 'react';

import { SettingsRoutes } from '../../../../../pages/Settings/SettingsRoutes';
import { LoadingSpinner, Select } from '../../../../components';
import { EmptyState } from '../../../../compositions';
import { transformDistrictDivisionTemplatesToGroupedSelectOptions } from '../../../../entities/DistrictDivisionDistrictTemplate/DistrictDivisionDistrictTemplateTransformers';
import { DistrictDivisionTemplate } from '../../../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import trans from '../../../../helpers/trans';
import { PrivateRouteGroupRoutes } from '../../../../routes/routes';
import { SelectOption } from '../../../../types';
import { getSelectDistrictDivisionTemplateIntro } from '../../helpers';

import './DistrictDivisionTemplateSelect.scss';

interface SelectDistrictDivisionTemplateProps {
    hasSelectSecondaryStyling?: boolean;
    isLoading: boolean;
    showDistrictDivisionTemplatesLink: boolean;
    showSelector: boolean;
    districtDivisionTemplates: DistrictDivisionTemplate[];
    onSelectDistrictDivisionTemplate: (districtDivisionTemplate: DistrictDivisionTemplate) => void;
    className?: string;
}

const DistrictDivisionTemplateSelect: FC<SelectDistrictDivisionTemplateProps> = ({
    hasSelectSecondaryStyling = false,
    isLoading,
    showDistrictDivisionTemplatesLink,
    showSelector,
    districtDivisionTemplates,
    onSelectDistrictDivisionTemplate,
    className = '',
}) => {
    const options = useMemo(() => transformDistrictDivisionTemplatesToGroupedSelectOptions(districtDivisionTemplates), [districtDivisionTemplates]);

    const intro = useMemo(
        () => getSelectDistrictDivisionTemplateIntro(showSelector, districtDivisionTemplates.length),
        [showSelector, districtDivisionTemplates],
    );

    const handleSelectTemplateChange = (selectOption: SelectOption): void => {
        const selectedDistrictDivisionTemplate = districtDivisionTemplates.find(districtDivisionTemplate => districtDivisionTemplate.id === selectOption.value);

        if (selectedDistrictDivisionTemplate) {
            onSelectDistrictDivisionTemplate(selectedDistrictDivisionTemplate);
        }
    };

    return (
        <EmptyState
            showLink={showDistrictDivisionTemplatesLink && !districtDivisionTemplates.length}
            buttonText={trans('containers.districtDivisionContainer.addTemplate')}
            to={`${PrivateRouteGroupRoutes.settings()}/${SettingsRoutes.districtDivisionTemplates()}`}
            title={trans('containers.districtDivisionContainer.emptyDistrictDivisionTitle')}
            intro={intro}
            className={`district-division-template-select ${className}`}
        >
            {(districtDivisionTemplates.length || isLoading) && (
                <>
                    {showSelector && (
                        <Select
                            hasPrimaryStyling={hasSelectSecondaryStyling}
                            hasSecondaryStyling={!hasSelectSecondaryStyling}
                            disabled={isLoading}
                            isLoading={isLoading}
                            isSearchable={false}
                            name={trans('containers.districtDivisionContainer.chooseTemplate')}
                            options={options}
                            onChange={handleSelectTemplateChange}
                            className="district-division-template-select__select"
                        />
                    )}
                    {isLoading && <LoadingSpinner isSmall className="district-division-template-select__loader" />}
                </>
            )}
        </EmptyState>
    );
};

export default DistrictDivisionTemplateSelect;
