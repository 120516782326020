import { getEventLogsApiCall, getEventLogsPaginationApiCall } from '../../entities/EventLog/EventLogService';
import { isFetchCollectionResultSuccessful, isFetchResultSuccessful } from '../../entities/FetchResult';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsPaginationLoading,
    setPzLogs,
    setTotalPages,
} from './pzLogsReducer';

export const fetchPzLogs = (pzLogCategories: string[], search: string, page: number) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { pageSize } = getState().pzLogsReducer;

        const response = await getEventLogsApiCall({
            pagination: { items: pageSize, start: (page - 1) * pageSize },
            categories: pzLogCategories,
            search,
        });

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setPzLogs(response.data));
    } catch (error) {
        console.error('[fetchPzLogs]', error);
    }

    dispatch(setIsLoading(false));
};

export const fetchPzLogsPagination = (pzLogCategories: string[], search: string, page: number) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsPaginationLoading(true));
    dispatch(setError(''));

    try {
        const { pageSize } = getState().pzLogsReducer;

        const response = await getEventLogsPaginationApiCall({
            pagination: { items: pageSize, start: (page - 1) * pageSize },
            categories: pzLogCategories,
            search,
        });

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setTotalPages(response.data.pages));
    } catch (error) {
        console.error('[fetchPzLogsPagination]', error);
    }

    dispatch(setIsPaginationLoading(false));
};
