import { Role as LegacyRole } from '../../../models/Role';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import trans from '../../helpers/trans';
import { FormOption } from '../../types';
import { RoleType } from '../../types/roleTypes';
import { Role, RoleResource, RoleV3Resource } from './Role';

export const transformToRole = (resource: RoleResource): Role => {
    const roleTypeKey = getEnumKeyByEnumValue(RoleType, resource.attributes.slug);

    if (!roleTypeKey) {
        console.error(`[transformToRole]: Unknown slug found named "${resource.attributes.slug}".`);
    }

    return {
        id: resource.id,
        name: resource.attributes.name,
        slug: roleTypeKey ? RoleType[roleTypeKey] : RoleType.employee,
        public: resource.attributes.public,
        assignable: resource.attributes.assignable,
        priority: resource.attributes.priority,
    };
};

export const transformV3ResourceToRole = (resource: RoleV3Resource): Role => {
    const roleTypeKey = getEnumKeyByEnumValue(RoleType, resource.slug);

    if (!roleTypeKey) {
        console.error(`[transformV3ResourceToRole]: Unknown slug found named "${resource.slug}".`);
    }

    return {
        id: resource.id,
        name: resource.name,
        slug: roleTypeKey ? RoleType[roleTypeKey] : RoleType.employee,
        public: resource.public,
        assignable: resource.assignable,
        priority: resource.priority,
    };
};

export const transformRoleToFormOption = (role: Role): FormOption => ({
    label: trans(`entities.roles.name.${role.slug}`),
    value: role.slug,
});

export const transformLegacyRoleToRole = (legacyRoleResource: LegacyRole): Role => {
    const roleTypeKey = getEnumKeyByEnumValue(RoleType, legacyRoleResource.slug);

    if (!roleTypeKey) {
        console.error(`[transformLegacyRoleResourceToRole]: Unknown slug found named "${legacyRoleResource.slug}".`);
    }

    return {
        id: legacyRoleResource.id,
        name: legacyRoleResource.name,
        slug: roleTypeKey ? RoleType[roleTypeKey] : RoleType.employee,
        public: legacyRoleResource.public,
        assignable: legacyRoleResource.assignable,
        priority: legacyRoleResource.priority,
    };
};
