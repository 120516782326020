import { doesShiftPlanningHaveAbsence, doesShiftPlanningHaveOpenLeaveOfAbsence } from '../ShiftPlanning/ShiftPlanningHelpers';
import { ShiftsCalendarAbsence } from '../ShiftsCalendarAbsence/ShiftsCalendarAbsence';
import { ShiftsCalendarLeaveOfAbsence } from '../ShiftsCalendarLeaveOfAbsence/ShiftsCalendarLeaveOfAbsence';
import { ShiftIndex } from './Shift';

export const doesShiftHaveAbsence = (shift: ShiftIndex, absences: ShiftsCalendarAbsence[]): boolean => shift.shiftPlannings
    .some(shiftPlanning => doesShiftPlanningHaveAbsence(shiftPlanning, absences));

export const doesShiftHaveOpenLeaveOfAbsence = (shift: ShiftIndex, leaveOfAbsences: ShiftsCalendarLeaveOfAbsence[]): boolean => shift.shiftPlannings
    .some(shiftPlanning => doesShiftPlanningHaveOpenLeaveOfAbsence(shiftPlanning, leaveOfAbsences));
