import { FC, ReactElement, useMemo } from 'react';

import Select, { MultiValue } from 'react-select';

import { BasicUser } from '../../../entities/User/User';
import trans from '../../../helpers/trans';
import { SelectOption } from '../../../types';
import { transformToMultiUserSelectOptions } from './helpers';

export interface MultiUserSelectOption extends SelectOption<string, ReactElement> {
    userId: string;
}

interface MultiUsersSelectProps {
    closeMenuOnSelect?: boolean;
    controlShouldRenderValue?: boolean;
    isDisabled?: boolean;
    users: BasicUser[];
    onSelect: (users: BasicUser[]) => void;
    className?: string;
}

const MultiUsersSelect: FC<MultiUsersSelectProps> = ({
    closeMenuOnSelect = true,
    controlShouldRenderValue = true,
    isDisabled,
    users,
    onSelect,
    className = '',
}): ReactElement => {
    const options: MultiUserSelectOption[] = useMemo(() => (
        transformToMultiUserSelectOptions(users)
    ), [users]);

    const handleSelectChange = (values: MultiValue<MultiUserSelectOption>): void => {
        const userIds = values.map(value => value.userId);
        const selectedUsers = users.filter(user => userIds.includes(user.id));

        onSelect(selectedUsers);
    };

    return (
        <Select
            closeMenuOnSelect={closeMenuOnSelect}
            isMulti
            controlShouldRenderValue={controlShouldRenderValue}
            isDisabled={isDisabled || !users.length}
            maxMenuHeight={200}
            noOptionsMessage={() => trans('compositions.usersSelect.noEmployeesFound')}
            options={options}
            placeholder={trans('compositions.usersSelect.searchEmployee')}
            onChange={handleSelectChange}
            className={`multi-users-select ${className}`}
            classNamePrefix="select"
        />
    );
};

export default MultiUsersSelect;
