import { EditDistrictDivisionTemplateFormData } from '../../../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../services/validationService';

export type EditDistrictDivisionTemplateFormErrors = FormErrors<EditDistrictDivisionTemplateFormData>;

export const validateEditDistrictDivisionTemplateFormData = (formData: EditDistrictDivisionTemplateFormData): FormValidation<EditDistrictDivisionTemplateFormErrors> => {
    const errors: EditDistrictDivisionTemplateFormErrors = {
        id: validateRequiredString('id', formData.id),
        name: validateRequiredString('name', formData.name),
    };

    return validateForm<EditDistrictDivisionTemplateFormErrors>(errors);
};
