import { RefObject } from 'react';

import { didElementScrollPastElement } from '../../../../helpers/browser';

const shouldShowStickyTableHead = (tableElementRef: RefObject<HTMLTableSectionElement>, scrollY: number, hasStickyTableHead: boolean): boolean => {
    if (!hasStickyTableHead) {
        return false;
    }

    const parentElement = tableElementRef.current?.parentElement || null;
    return didElementScrollPastElement(scrollY, parentElement, true);
};

export default shouldShowStickyTableHead;
