import { getRandomUuid } from '../../../@paco/helpers/crypto';
import {
    addDaysToDateTimeString,
    compareAsc,
    differenceInDays,
    formatDate,
} from '../../../helpers/date';
import { BasicUserWithAbsenceData, Shift, ShiftPlanning } from '../../../models';
import { TemporaryWorker } from '../../../models/TemporaryWorker';

function transformShiftPlanningToShiftPlanningClone(
    shiftPlanning: ShiftPlanning,
    days: number,
): ShiftPlanning {
    return {
        ...shiftPlanning,
        start: addDaysToDateTimeString(shiftPlanning.start, days),
        end: addDaysToDateTimeString(shiftPlanning.end, days),
    };
}

function transformTempWorkersToTempWorkersClone(
    temporaryWorker: TemporaryWorker,
    days: number,
): TemporaryWorker {
    return {
        ...temporaryWorker,
        start: addDaysToDateTimeString(temporaryWorker.start, days),
        end: addDaysToDateTimeString(temporaryWorker.end, days),
    };
}

export default function transformShiftToShiftClone(
    shift: Shift,
    newDateTime: string,
): Shift | null {
    const startDate = formatDate(shift.start, 'yyyy-MM-dd');
    const newDate = formatDate(newDateTime, 'yyyy-MM-dd');
    const difference = differenceInDays(newDate, startDate);

    if (compareAsc(startDate, newDate) === 0) {
        return null;
    }

    return {
        ...shift,
        shiftPlannings: shift.shiftPlannings
            .map((shiftPlanning: ShiftPlanning) => transformShiftPlanningToShiftPlanningClone(shiftPlanning, difference)),
        plannedUsers: (shift.plannedUsers as BasicUserWithAbsenceData[])
            .map((user: BasicUserWithAbsenceData) => ({
                ...user,
                ...(user.shiftPlanning && {
                    shiftPlanning: transformShiftPlanningToShiftPlanningClone(user.shiftPlanning, difference),
                }),
            })),
        registeredUsers: [],
        temporaryWorkers: shift.temporaryWorkers
            .map((temporaryWorker: TemporaryWorker) => transformTempWorkersToTempWorkersClone(temporaryWorker, difference)),
        clone: true,
        start: addDaysToDateTimeString(shift.start, difference),
        end: addDaysToDateTimeString(shift.end, difference),
        id: getRandomUuid(),
    };
}
