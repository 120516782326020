import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';
import Tooltip from 'react-tooltip-lite';

import { ConnectedUserContractHours } from '../../../../../../@paco/connectors';
import { CompanyMedicType } from '../../../../../../@paco/types';
import UserPageLink from '../../../../../../components/UserPageLink/UserPageLink';
import { getFirstLetterOfEmploymentType, getUserFullName } from '../../../../../../helpers';
import { formatDate } from '../../../../../../helpers/date';
import { translate } from '../../../../../../helpers/translations/translator';
import { UserWithConceptAvailability } from '../../../../../../models';
import CalendarButtonAdd from '../../../CalendarButtonAdd/CalendarButtonAdd';
import { ResourceTypes } from '../../ConceptsListViewTable';
import UserConceptAvailabilityOnDate from '../UserConceptAvailabilityOnDate/UserConceptAvailabilityOnDate';

import './ConceptsListViewUser.scss';

interface ConceptsListViewUserProps {
    loading: boolean;
    user: UserWithConceptAvailability;
    range: Date[];
    canAddPlannings: boolean;
    isFilterEmploymentTypeBadgeActive: boolean;
    onAvailabilityClick: (resources: ResourceTypes) => void;
    onAddButtonClick: (date: Date, user: UserWithConceptAvailability) => void;
}

const ConceptsListViewUser: FC<ConceptsListViewUserProps> = ({
    loading,
    user,
    range,
    canAddPlannings,
    isFilterEmploymentTypeBadgeActive,
    onAvailabilityClick,
    onAddButtonClick,
}): ReactElement => {
    const rowClassName = classNames('concepts-list-view-user', {
        'list-view-user--is-company-medic': user.person.isCompanyMedic === CompanyMedicType.yes,
    });

    return (
        <tr key={user.id} className={rowClassName}>
            <td className="concepts-list-view-user__name-td">
                {(isFilterEmploymentTypeBadgeActive && user.employmentType)
                    && (
                        // @ts-ignore
                        <Tooltip className="concepts-list-view-user__tooltip" content={user.employmentType ? translate(`common.${user.employmentType.slug}`) : ''} arrowSize={6} padding={7}>
                            <span className="concepts-list-view-user__employment-type badge-level">
                                {getFirstLetterOfEmploymentType(user?.employmentType.slug)}
                            </span>
                        </Tooltip>
                    )}
                <span>
                    <UserPageLink name={getUserFullName(user)} id={user.id} className="list-view-user__name" />

                    <ConnectedUserContractHours
                        userId={user.id}
                        plannedHoursInWeek={user.plannedHoursInWeek + user.plannedConceptHoursInWeek}
                        className="concepts-list-view-user__hours"
                    />
                </span>
            </td>
            { range.map(day => (
                <td className="concepts-list-view-user__td" key={formatDate(day)}>
                    <div className="concepts-list-view-user__buttons">
                        <UserConceptAvailabilityOnDate
                            loading={loading}
                            day={day}
                            user={user}
                            onAvailabilityClick={onAvailabilityClick}
                        />
                        { canAddPlannings && (
                            <CalendarButtonAdd onClick={() => { onAddButtonClick(day, user); }} />
                        )}
                    </div>
                </td>
            )) }
        </tr>
    );
};

export default ConceptsListViewUser;
