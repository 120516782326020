import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { Setting } from '../../entities/Setting/Setting';

export type SettingsState = AsyncReduxState<{
    settings: Setting[];
}>;

const initialState: SettingsState = {
    ...initialAsyncReduxState,
    settings: [],
};

export const SettingsSlice = createSlice({
    name: 'SettingsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): SettingsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SettingsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSettings(state, action: PayloadAction<Setting[]>): SettingsState {
            return {
                ...state,
                settings: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setSettings,
    setError,
} = SettingsSlice.actions;

export default SettingsSlice.reducer;
