import React, { FC } from 'react';

import trans from '../../../../../../@paco/helpers/trans';
import ItemsList from '../../../../../../components/ItemsList/ItemsList';
import Icon from '../../../../../../components/style/Icon/Icon';
import TableContainer from '../../../../../../components/Table/TableContainer';
import { getUserFullName } from '../../../../../../helpers';
import { formatDate } from '../../../../../../helpers/date';
import { translate } from '../../../../../../helpers/translations/translator';
import { CommentViewModel } from '../../../../../../models';

import './NotesTable.scss';

export interface NotesTableProps {
    notes: CommentViewModel[];
    onEditNoteClick: (data: CommentViewModel) => void;
    onDeleteNoteClick: (id: string) => void;
}

const NotesTable: FC<NotesTableProps> = ({ notes = [], onEditNoteClick, onDeleteNoteClick }) => {
    const renderButtons = (note: CommentViewModel) => (
        <div className="notes-table__action-buttons">
            <button type="button" onClick={() => onEditNoteClick(note)} className="clear-button">
                <Icon color="light-blue" kind="edit" />
            </button>
            <button type="button" onClick={() => onDeleteNoteClick(note.id)} className="clear-button">
                <Icon color="light-blue" kind="trash" />
            </button>
        </div>
    );

    const tableAttributes = [
        {
            label: translate('common.date'),
            customAction: (item: CommentViewModel) => formatDate(item.createdAt, 'dd-MM-yyyy'),
            className: 'notes-table__td-date',
        },
        {
            label: translate('common.createdBy'),
            customAction: (item: CommentViewModel) => getUserFullName(item.owner) || trans('common.automaticNotification'),
            className: 'notes-table__td-owner',
        },
        {
            label: translate('common.note'),
            customAction: (item: CommentViewModel) => item.body,
            className: 'notes-table__td-owner',
        },
        {
            label: translate('common.category'),
            customAction: (item: CommentViewModel) => (item.category ? translate(`pages.management.${item.category.slug.toLowerCase()}`) : ''),
            className: 'notes-table__td-category',
        },
        {
            customAction: (item: CommentViewModel) => renderButtons(item),
            className: 'notes-table__td-edit',
        },
    ];

    return (
        <ItemsList
            itemsLength={notes.length}
            emptyMessage={translate('pages.management.emptyNotes')}
        >
            <TableContainer
                className="notes-table"
                attributes={tableAttributes}
                data={notes}
            />
        </ItemsList>
    );
};

export default NotesTable;
