import { LeaveOfAbsenceHoursViewModel, LeaveOfAbsenceViewModel, LeaveType } from '../../models';
import { doesAbsenceHourOverlapWithDates } from '../AbsenceService/getHoursFromAbsences';

export default function getHoursFromLeaveOfAbsences(
    absences: LeaveOfAbsenceViewModel[],
    start: Date,
    end: Date,
    leaveType: LeaveType[] = [LeaveType.normal, LeaveType.special, LeaveType.tvt],
): number {
    const absenceHours = absences.reduce((total: LeaveOfAbsenceHoursViewModel[], absence) => {
        const filteredHours = absence.leaveOfAbsenceHours
            .filter(hours => leaveType.find(type => type === hours.leaveType));

        return [
            ...total,
            ...filteredHours,
        ];
    }, []);

    return absenceHours.reduce((total: number, hours) => {
        if (doesAbsenceHourOverlapWithDates(hours, start, end)) {
            return total + hours.hours;
        }

        return total;
    }, 0);
}
