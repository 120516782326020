import React from 'react';

import { translate } from '../../helpers/translations/translator';
import { CommentCategoryViewModel } from '../../models';

export default function transformCommentCategoriesToSelectOptions(commentCategories: CommentCategoryViewModel[]): JSX.Element[] {
    return commentCategories.map((commentCategory) => (
        <option
            key={commentCategory.id}
            value={commentCategory.id}
        >
            {(translate(`pages.management.${commentCategory.slug.toLowerCase()}`))}
        </option>
    ));
}
