import { formatDate } from '../../../helpers/date';
import trans from '../../helpers/trans';
import { DateFormat } from '../../types/dateFormatTypes';
import { Period } from '../Period/Period';
import { doPeriodsOverlap, getTimeOnlyLabel } from '../Period/PeriodHelpers';
import { PreferToWorkTimeSlot } from './PreferToWorkTimeSlot';

export const doesPreferToWorkTimeSlotOverlapWithPeriod = (preferToWorkTimeSlot: PreferToWorkTimeSlot, period: Period): boolean => (
    doPeriodsOverlap(preferToWorkTimeSlot.period, period)
);

export const getPreferToWorkTimeLabel = (preferToWorkTimeSlot: PreferToWorkTimeSlot): string => {
    const time = getTimeOnlyLabel(preferToWorkTimeSlot.period.start, preferToWorkTimeSlot.period.end);
    const createDate = `${trans('common.createdAt')} ${formatDate(preferToWorkTimeSlot.createdAt, DateFormat.date)}`;

    return `${time} ${preferToWorkTimeSlot.comment} (${createDate})`;
};
