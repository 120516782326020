import { FC, ReactElement, useState } from 'react';

import classnames from 'classnames';
import { useEffectOnce } from 'react-use';

import { Icon } from '../../components';
import { IconSet } from '../../components/Icon/Icon';
import trans from '../../helpers/trans';
import { Toast as ToastInterface, ToastType } from '../../types/toastTypes';
import { IconButton } from '..';

import './Toast.scss';

interface ToastProps {
    toast: ToastInterface;
    className?: string;
}

let timeoutInstance: NodeJS.Timeout;

const Toast: FC<ToastProps> = ({
    toast,
    className = '',
}): ReactElement => {
    const [isVisible, setIsVisible] = useState(true);

    let iconName: keyof IconSet = 'info-circle';

    if ([ToastType.fail, ToastType.warning].includes(toast.type)) {
        iconName = 'warning-triangle';
    }

    if (toast.type === ToastType.pass) {
        iconName = 'check-circle';
    }

    const handleOnClose = () => {
        setIsVisible(false);
    };

    useEffectOnce(() => {
        if (toast.shouldFadeOutAutomatically) {
            timeoutInstance = setTimeout(
                handleOnClose,
                toast.type === ToastType.updateApp ? 20000 : 4000,
            );
        }

        return () => {
            if (timeoutInstance) clearTimeout(timeoutInstance);
        };
    });

    const toastClassNames = classnames('toast-paco', {
        'toast-paco--is-visible': isVisible,
    }, className);

    return (
        <div className={toastClassNames}>
            <div className={`toast-paco__icon-wrapper toast-paco__icon-wrapper--${toast.type}`}>
                <Icon name={iconName} className="toast-paco__icon" />
            </div>
            <div className="toast-paco__body">
                {toast.text}
            </div>
            <IconButton
                hideLabel
                icon="cross-close"
                text={trans('common.close')}
                onClick={handleOnClose}
                className="toast-paco__close-button"
                iconClassName="toast-paco__close-button-icon"
            />
        </div>
    );
};

export default Toast;
