import { translate } from '../../../helpers/translations/translator';

export const getTrackCardApproveButtonTooltipLabel = (
    payrollPeriodError: boolean,
    trackHasNoDepartment: boolean,
): string => {
    if (payrollPeriodError) {
        return translate('pages.tracks.trackCantBeApprovedInLockedPayrollPeriod');
    }

    if (trackHasNoDepartment) {
        return translate('pages.tracks.trackCardHasNoDepartment');
    }

    return '';
};

export const getTrackCardEditButtonLabelTooltipLabel = (payrollPeriodError: boolean): string => {
    if (payrollPeriodError) {
        return translate('pages.tracks.trackCantBeEditedInLockedPayrollPeriod');
    }

    return '';
};
