import { DocumentTypeViewModel } from '../../models';
import { translate } from './translator';


export const translateDocumentTypes = (documentTypes: DocumentTypeViewModel[]) => documentTypes.map(
    type => ({
        label: (translate(`pages.management.${type.slug.toLowerCase()}`)),
        value: type.id,
    }),
);
