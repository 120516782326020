import { DivergentPreEmploymentHour, DivergentPreEmploymentHourCheckedFormData } from '../../entities/DivergentPreEmploymentHour/DivergentPreEmploymentHour';
import { patchDivergentPreEmploymentHourApiCall, postDivergentPreEmploymentHourApiCall } from '../../entities/DivergentPreEmploymentHour/DivergentPreEmploymentHourService';
import { transformEmploymentDatesFormDataToDivergentPreEmploymentHourFormData } from '../../entities/DivergentPreEmploymentHour/DivergentPreEmploymentHourTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { EmploymentDatesFormData } from '../../entities/User/User';
import { TypedDispatch } from '../store';
import { setError } from '../user/userReducer';
import { setDivergentPreEmploymentHour, setIsLoading } from './divergentPreEmploymentHourReducer';

export const addDivergentPreEmploymentHourContractHours = async (
    employmentId: string,
    employmentDatesFormData: EmploymentDatesFormData,
): Promise<void> => {
    const formData = transformEmploymentDatesFormDataToDivergentPreEmploymentHourFormData(employmentDatesFormData);

    formData.checked = false;

    const response = await postDivergentPreEmploymentHourApiCall(employmentId, formData);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('addDivergentPreEmploymentHourContractHours');
    }
};

export const editDivergentPreEmploymentHourContractHours = async (
    divergentPreEmploymentHourId: string,
    employmentDatesFormData: EmploymentDatesFormData,
): Promise<void> => {
    const formData = transformEmploymentDatesFormDataToDivergentPreEmploymentHourFormData(employmentDatesFormData);

    const response = await patchDivergentPreEmploymentHourApiCall(divergentPreEmploymentHourId, formData);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('editDivergentPreEmploymentHourContractHours');
    }
};

export const addDivergentPreEmploymentHourChecked = async (employmentId: string, checked: boolean): Promise<DivergentPreEmploymentHour> => {
    const response = await postDivergentPreEmploymentHourApiCall(employmentId, { checked });

    if (!isFetchResultSuccessful(response)) {
        throw new Error('addDivergentPreEmploymentHourChecked');
    }

    return response.data;
};

export const setDivergentPreEmploymentHoursCheck = (formData: DivergentPreEmploymentHourCheckedFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await patchDivergentPreEmploymentHourApiCall(formData.divergentPreEmploymentHoursId, { checked: formData.isChecked });

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDivergentPreEmploymentHour(response.data));
    } catch (error) {
        console.error('[setDivergentPreEmploymentHoursCheck]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

