import { FC, ReactElement, useEffect } from 'react';

import { EditShiftPlanningForm } from '../../../containers';
import { EditShiftPlanningFormData, ShiftPlanning } from '../../../entities/ShiftPlanning/ShiftPlanning';
import trans from '../../../helpers/trans';
import { editShiftPlanning } from '../../../redux/shiftPlanning/shiftPlanningActions';
import { setError, setIsEditSuccessful } from '../../../redux/shiftPlanning/shiftPlanningReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedEditShiftTimeFormProps {
    shiftPlanning: ShiftPlanning;
    onClose: () => void;
    className?: string;
}

const ConnectedEditShiftPlanningTimeForm: FC<ConnectedEditShiftTimeFormProps> = ({
    shiftPlanning,
    onClose,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isLoading, isEditSuccessful, error } = useTypedSelector(state => state.shiftPlanningReducer);

    const handleSubmit = (formData: EditShiftPlanningFormData): void => {
        dispatch(editShiftPlanning(formData, shiftPlanning.id));
    };

    useEffect(() => {
        if (error) {
            onClose();

            dispatch(setToast({
                text: error,
                type: ToastType.fail,
            }));
            dispatch(setError(''));
        }
    }, [error]);

    useEffect((): void => {
        if (isEditSuccessful) {
            onClose();

            dispatch(setIsEditSuccessful(false));
            dispatch(setToast({
                text: trans('containers.forms.editShiftPlanningForm.editShiftPlanningSuccess'),
                type: ToastType.pass,
            }));
        }
    }, [isEditSuccessful]);

    return (
        <EditShiftPlanningForm
            isLoading={isLoading}
            shiftPlanning={shiftPlanning}
            onSubmit={handleSubmit}
            onCancel={onClose}
            className={className}
        />
    );
};

export default ConnectedEditShiftPlanningTimeForm;
