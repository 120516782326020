import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { RoleType } from '../../../../@paco/types/roleTypes';
import { checkPermission } from '../../../../helpers';
import { translate } from '../../../../helpers/translations/translator';
import arrow from '../../../../images/arrow-right.svg';

import './UserNavigation.scss';

// const CONTRACT_INFO = 'contractInfo';
const PERSONAL_DATA = 'personalData';
const ABSENCES = 'absences';
const DOCUMENTS = 'documents';
const COUPLINGS = 'couplings';
const CALENDAR = 'calendar';
const PERMISSIONS = 'permissions';
const UNAVAILABILITY = 'unavailability';
const NOTES = 'notes';
const SATISFACTION = 'satisfaction';

class UserNavigation extends Component {
    ref = React.createRef();

    state = {
        indicator: true,
        scroll: false,
    };

    componentDidMount() {
        window.addEventListener('resize', this.calculateScrollPos);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateScrollPos);
    }

    calculateScrollPos = () => {
        const target = this.ref.current;
        let indicator = true;
        if ((target.scrollLeft + target.clientWidth) >= target.scrollWidth) {
            indicator = false;
        }
        this.setState({
            scroll: target.scrollLeft > 0,
            indicator,
        });
    };

    getActiveLink = (links, activeLink) => {
        const active = links.find((link) => translate(`nav.userManagement.users.${link}.link`) === activeLink);
        if (active) {
            return active;
        }

        return links[0];
    };

    renderLinks() {
        const { scroll } = this.state;
        const {
            onLinkClick,
            permissions,
            isLoketAuthorized,
            userId,
            userRole,
            currentUser,
        } = this.props;
        const isEmployee = userRole.slug === RoleType.employee;
        const isEstablishmentManagerOrAdmin = [RoleType.establishmentManager, RoleType.admin].some(role => currentUser?.role.slug === role);
        const isOwnProfile = currentUser.id === userId;
        const canViewPermissions = !isEmployee && (!isOwnProfile || isEstablishmentManagerOrAdmin);
        const canViewUnavailableToWorkTimeSlots = checkPermission(permissions, 'view-unavailable-to-work-time-slots', 'user-navigation');
        const canViewAllPreferToWorkTimeSlots = checkPermission(permissions, 'view-all-prefer-to-work-time-slots', 'user-navigation');
        const canManageLoketData = checkPermission(permissions, 'manage-loket-data', 'user-navigation');
        const hasRoleToSeeAbsenceHours = userRole && userRole.priority <= currentUser.role.priority;

        const LINKS = [
            checkPermission(permissions, 'view-all-user-profiles', 'user-navigation') && PERSONAL_DATA,
            checkPermission(permissions, 'view-all-absences', 'user-navigation') && hasRoleToSeeAbsenceHours && ABSENCES,
            checkPermission(permissions, 'view-all-files', 'user-navigation') && DOCUMENTS,
            (isLoketAuthorized && canManageLoketData) && COUPLINGS,
            (canViewUnavailableToWorkTimeSlots && canViewAllPreferToWorkTimeSlots) && CALENDAR,
            (canViewPermissions && checkPermission(permissions, 'assign-all-permissions', 'user-navigation')) && PERMISSIONS,
            canViewUnavailableToWorkTimeSlots && UNAVAILABILITY,
            checkPermission(permissions, 'view-all-comment-categories', 'user-navigation') && NOTES,
            checkPermission(permissions, 'view-all-feedback-ratings', 'user-navigation') && SATISFACTION,
        ].filter(link => link);
        const activeLink = this.getActiveLink(LINKS, this.props.activeLink);

        return LINKS.map((string) => {
            const link = `nav.userManagement.users.${string}`;
            const active = activeLink === string;
            return (
                <button
                    key={link}
                    type="button"
                    className={`user-navigation-link ${active ? 'user-navigation-link--active' : ''} ${scroll ? 'user-navigation-link--overflow' : ''}`}
                    onClick={() => onLinkClick(translate(`${link}.link`))}
                >
                    {translate(`${link}.label`)}
                </button>
            );
        });
    }

    render() {
        const { indicator } = this.state;

        return (
            <div className="user-navigation-container">
                <div ref={this.ref} className="user-navigation-inner" onScroll={this.calculateScrollPos}>
                    <div className="user-navigation">
                        { this.renderLinks() }
                    </div>
                </div>
                <div className={`user-navigation-arrow ${!indicator ? 'user-navigation-arrow--hidden' : ''}`}>
                    <img alt="arrow" src={arrow} />
                </div>
            </div>
        );
    }
}

UserNavigation.propTypes = {
    activeLink: PropTypes.string,
    onLinkClick: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
    userRole: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    currentUser: PropTypes.object,
    isLoketAuthorized: PropTypes.bool.isRequired,
};

export default UserNavigation;
