import { LeaveOfAbsence, PayrollPeriodViewModel } from '../../models';
import { doesAbsenceHoursHaveNullHours } from '../AbsenceLegacyService/getIncompleteAbsences';

export default function getLeaveOfAbsencesWithNullHoursInPayrollPeriod(
    leaveOfAbsences: LeaveOfAbsence[],
    payrollPeriod: PayrollPeriodViewModel,
): LeaveOfAbsence[] {
    return leaveOfAbsences.filter(leaveOfAbsence => (
        doesAbsenceHoursHaveNullHours(
            leaveOfAbsence.leaveOfAbsenceHours,
            payrollPeriod,
        )
    ));
}
