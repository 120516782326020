import { FC, ReactElement } from 'react';

import {
    IconArrowDown,
    IconArrowLeftRight,
    IconArrowUp,
    IconBell,
    IconCalendarCheck,
    IconCalendarComment,
    IconCalendarQuestion,
    IconCalendarWarning,
    IconCheck,
    IconCheckboxCheck,
    IconCheckCircle,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconChevronUp,
    IconClockOutline,
    IconCloseCircle,
    IconClosedDirectory,
    IconCommentPlusOutline,
    IconCommentTextOutline,
    IconCross,
    IconCrossClose,
    IconDoubleChevronDown,
    IconDownload,
    IconDraggable,
    IconEdit,
    IconExport,
    IconEye,
    IconFile,
    IconFilter,
    IconFolder,
    IconHamburger,
    IconHelpCircleOutline,
    IconHistory,
    IconHome,
    IconInfoCircle,
    IconLock,
    IconLockClosed,
    IconLockOpen,
    IconMagnifyingGlass,
    IconMedicalBag,
    IconMinCircle,
    IconOffline,
    IconOpenDirectory,
    IconPlus,
    IconPlusCircle,
    IconPlusShiftConcept,
    IconReset,
    IconTrash,
    IconWarningTriangle,
} from './set';

import './Icon.scss';

export interface SvgIconProps {
    className?: string;
}

export interface IconSet {
    'arrow-down': FC<SvgIconProps>;
    'arrow-left-right': FC<SvgIconProps>;
    'arrow-up': FC<SvgIconProps>;
    'bell': FC<SvgIconProps>;
    'calendar-check': FC<SvgIconProps>;
    'calendar-comment': FC<SvgIconProps>;
    'calendar-question': FC<SvgIconProps>;
    'calendar-warning': FC<SvgIconProps>;
    'check': FC<SvgIconProps>;
    'check-circle': FC<SvgIconProps>;
    'checkbox-check': FC<SvgIconProps>;
    'chevron-down': FC<SvgIconProps>;
    'chevron-left': FC<SvgIconProps>;
    'chevron-right': FC<SvgIconProps>;
    'chevron-up': FC<SvgIconProps>;
    'clock-outline': FC<SvgIconProps>;
    'close-circle': FC<SvgIconProps>;
    'closed-directory': FC<SvgIconProps>;
    'comment-plus-outline': FC<SvgIconProps>;
    'comment-text-outline': FC<SvgIconProps>;
    'cross': FC<SvgIconProps>;
    'cross-close': FC<SvgIconProps>;
    'double-chevron-down': FC<SvgIconProps>;
    'download': FC<SvgIconProps>;
    'draggable': FC<SvgIconProps>;
    'edit': FC<SvgIconProps>;
    'export': FC<SvgIconProps>;
    'eye': FC<SvgIconProps>;
    'file': FC<SvgIconProps>;
    'filter': FC<SvgIconProps>;
    'folder': FC<SvgIconProps>;
    'hamburger': FC<SvgIconProps>;
    'help-circle-outline': FC<SvgIconProps>;
    'history': FC<SvgIconProps>;
    'home': FC<SvgIconProps>;
    'info-circle': FC<SvgIconProps>;
    'lock': FC<SvgIconProps>;
    'lock-closed': FC<SvgIconProps>;
    'lock-open': FC<SvgIconProps>;
    'magnifying-glass': FC<SvgIconProps>;
    'medical-bag': FC<SvgIconProps>;
    'min-circle': FC<SvgIconProps>;
    'offline': FC<SvgIconProps>;
    'open-directory': FC<SvgIconProps>;
    'plus': FC<SvgIconProps>;
    'plus-circle': FC<SvgIconProps>;
    'plus-shift-concept': FC<SvgIconProps>;
    'reset': FC<SvgIconProps>;
    'trash': FC<SvgIconProps>;
    'warning-triangle': FC<SvgIconProps>;
}

const icons: IconSet = {
    'arrow-down': IconArrowDown,
    'arrow-left-right': IconArrowLeftRight,
    'arrow-up': IconArrowUp,
    bell: IconBell,
    'calendar-check': IconCalendarCheck,
    'calendar-comment': IconCalendarComment,
    'calendar-question': IconCalendarQuestion,
    'calendar-warning': IconCalendarWarning,
    check: IconCheck,
    'check-circle': IconCheckCircle,
    'checkbox-check': IconCheckboxCheck,
    'chevron-down': IconChevronDown,
    'chevron-left': IconChevronLeft,
    'chevron-right': IconChevronRight,
    'chevron-up': IconChevronUp,
    'close-circle': IconCloseCircle,
    'closed-directory': IconClosedDirectory,
    'comment-plus-outline': IconCommentPlusOutline,
    'comment-text-outline': IconCommentTextOutline,
    'clock-outline': IconClockOutline,
    cross: IconCross,
    'cross-close': IconCrossClose,
    'double-chevron-down': IconDoubleChevronDown,
    download: IconDownload,
    draggable: IconDraggable,
    edit: IconEdit,
    export: IconExport,
    eye: IconEye,
    file: IconFile,
    filter: IconFilter,
    folder: IconFolder,
    hamburger: IconHamburger,
    'help-circle-outline': IconHelpCircleOutline,
    history: IconHistory,
    home: IconHome,
    lock: IconLock,
    'lock-closed': IconLockClosed,
    'lock-open': IconLockOpen,
    'info-circle': IconInfoCircle,
    'magnifying-glass': IconMagnifyingGlass,
    'medical-bag': IconMedicalBag,
    'min-circle': IconMinCircle,
    offline: IconOffline,
    'open-directory': IconOpenDirectory,
    plus: IconPlus,
    'plus-circle': IconPlusCircle,
    'plus-shift-concept': IconPlusShiftConcept,
    reset: IconReset,
    trash: IconTrash,
    'warning-triangle': IconWarningTriangle,
};

export interface IconProps extends SvgIconProps {
    name: keyof IconSet;
}

const Icon: FC<IconProps> = ({ name, className = '' }): ReactElement | null => {
    const IconComponent = icons[name] ? icons[name] : null;

    return IconComponent
        ? <IconComponent className={className} />
        : null;
};

export default Icon;
