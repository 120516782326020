import { getEventLogsByEntityApiCall } from '../../entities/EventLog/EventLogService';
import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { EventLogEntityType } from '../../types/eventLogTypes';
import { TypedDispatch } from '../store';
import { setError, setEventLogs, setIsLoading } from './eventLogsReducer';

export const getEventLogsForEntity = (entityId: string, entityType?: EventLogEntityType) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getEventLogsByEntityApiCall(entityId, entityType);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setEventLogs(response.data));
    } catch (error) {
        console.error('[getEventLogsForEntity]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
