import { getDistrictDivisionTemplatesApiCall } from '../../entities/DistrictDivisionTemplate/DistrictDivisionTemplateService';
import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import { setDistrictDivisionTemplates, setError, setIsLoading } from './districtDivisionTemplatesReducer';

export const getDistrictDivisionTemplates = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getDistrictDivisionTemplatesApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDistrictDivisionTemplates(response.data));
    } catch (error) {
        console.error('[getDistrictDivisionTemplates]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
