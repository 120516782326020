import { useEffect } from 'react';

import { localStorageTokenKey } from '../../helpers/authorizedFetch';
import { useTypedDispatch, useTypedSelector } from '../store';
import { getUsersContractHours } from './contractHoursActions';

const useContractHours = () => {
    const isAuthenticated = !!localStorage.getItem(localStorageTokenKey);
    const dispatch = useTypedDispatch();

    const { isSuccessful } = useTypedSelector(state => state.pacoUserReducer);

    useEffect((): void => {
        if (isAuthenticated) {
            dispatch(getUsersContractHours());
        }
    }, [isAuthenticated]);

    useEffect((): void => {
        // Every time a user updates, we want to fetch the updated data
        if (isSuccessful) {
            dispatch(getUsersContractHours());
        }
    }, [isSuccessful]);
};

export default useContractHours;
