/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconPlusCircle: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 20 21">
        <path d="M15 11.5648H11V15.5648H9V11.5648H5V9.56482H9V5.56482H11V9.56482H15V11.5648ZM10 0.564819C8.68678 0.564819 7.38642 0.823477 6.17317 1.32602C4.95991 1.82857 3.85752 2.56517 2.92893 3.49375C1.05357 5.36912 0 7.91265 0 10.5648C0 13.217 1.05357 15.7605 2.92893 17.6359C3.85752 18.5645 4.95991 19.3011 6.17317 19.8036C7.38642 20.3062 8.68678 20.5648 10 20.5648C12.6522 20.5648 15.1957 19.5113 17.0711 17.6359C18.9464 15.7605 20 13.217 20 10.5648C20 9.2516 19.7413 7.95124 19.2388 6.73798C18.7362 5.52473 17.9997 4.42234 17.0711 3.49375C16.1425 2.56517 15.0401 1.82857 13.8268 1.32602C12.6136 0.823477 11.3132 0.564819 10 0.564819Z" />
    </svg>
);

export default IconPlusCircle;
/* eslint-enable max-len */
