import { FC } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { CounterIndicator } from '../index';

import './NavigationLink.scss';

interface NavigationLinkProps {
    counter?: number;
    to: string;
    label: string;
    onClick?: () => void;
    className?: string;
    labelClassName?: string;
    activeClassName?: string;
}

const NavigationLink: FC<NavigationLinkProps> = ({
    counter,
    to,
    label,
    onClick,
    className = '',
    labelClassName = '',
    activeClassName = '',
}) => {
    const navLinkClassName = (props: { isActive: boolean }): string => classNames('navigation-link', {
        'navigation-link--has-counter': counter,
        [activeClassName]: props.isActive,
    }, className);

    return (
        <NavLink
            to={to}
            onClick={onClick}
            className={navLinkClassName}
        >
            <div className={`navigation-link__label ${labelClassName}`}>
                {label}
            </div>
            {!!counter && (
                <CounterIndicator number={counter} className="navigation-link__counter" />
            )}
        </NavLink>
    );
};

export default NavigationLink;
