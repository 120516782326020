import { FC, useMemo } from 'react';

import { EmploymentDatesFormErrors } from '../../../containers/@forms/AddUserForm/validation/addUserFormValidation';
import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import { EmploymentDatesFormData } from '../../../entities/User/User';
import { formatDate, isDateStringValid } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { DateFormat } from '../../../types/dateFormatTypes';
import { TextInput } from '../../index';
import { getEmploymentDateTooltipLabel } from './helpers/getEmploymentDateTooltipLabel';
import { EmploymentDateHoursInput } from './subcomponents';

import './EmploymentDatesInput.scss';

interface EmploymentDatesInputProps {
    isDisabled?: boolean;
    errors: EmploymentDatesFormErrors;
    formData?: EmploymentDatesFormData;
    payrollPeriods: PayrollPeriod[];
    onChange: (formData: EmploymentDatesFormData) => void;
    className?: string;
}

const EmploymentDatesInput: FC<EmploymentDatesInputProps> = ({
    isDisabled = false,
    formData = {},
    errors,
    payrollPeriods,
    onChange,
    className = '',
}) => {
    const {
        startDate,
        endDate,
        divergentPostEmploymentHours,
        divergentPreEmploymentHours,
    } = formData;

    const startDateLabel = useMemo(
        () => ((startDate && payrollPeriods.length) ? getEmploymentDateTooltipLabel(startDate, payrollPeriods) : undefined),
        [startDate, payrollPeriods],
    );

    const endDateLabel = useMemo(
        () => ((endDate && payrollPeriods.length) ? getEmploymentDateTooltipLabel(endDate, payrollPeriods, true) : undefined),
        [endDate, payrollPeriods],
    );

    const handleEmploymentStartChange = (date: string): void => {
        if (isDateStringValid(date)) {
            onChange({
                ...formData,
                startDate: new Date(date),
            });
        }

        if (!date) {
            onChange({
                ...formData,
                startDate: undefined,
                divergentPreEmploymentHours: undefined,
            });
        }
    };

    const handleEmploymentEndChange = (date: string): void => {
        if (isDateStringValid(date)) {
            onChange({
                ...formData,
                endDate: new Date(date),
            });
        }

        if (!date) {
            onChange({
                ...formData,
                endDate: undefined,
                divergentPostEmploymentHours: undefined,
            });
        }
    };

    const handleDivergentPostEmploymentHoursChange = (value: string): void => {
        onChange({
            ...formData,
            divergentPostEmploymentHours: value,
        });
    };

    const handleDivergentPreEmploymentHoursChange = (value: string): void => {
        onChange({
            ...formData,
            divergentPreEmploymentHours: value,
        });
    };

    return (
        <div className={`employment-dates-input ${className}`}>
            <div className="employment-dates-input__row">
                <div className="employment-dates-input__col">
                    <TextInput
                        disabled={isDisabled}
                        error={errors.startDate}
                        required
                        requiredText={trans('common.required')}
                        label={trans('common.employmentStartDate')}
                        type="date"
                        value={startDate ? formatDate(startDate, DateFormat.inputDate) : ''}
                        onChange={handleEmploymentStartChange}
                    />
                </div>
                <div className="employment-dates-input__col">
                    <TextInput
                        disabled={isDisabled}
                        label={trans('common.employmentEndDate')}
                        type="date"
                        value={endDate ? formatDate(endDate, DateFormat.inputDate) : ''}
                        error={errors.endDate}
                        onChange={handleEmploymentEndChange}
                    />
                </div>
            </div>
            <div className="employment-dates-input__row">
                <div className="employment-dates-input__col">
                    {startDate && (
                        <EmploymentDateHoursInput
                            isDisabled={isDisabled}
                            error={errors.divergentPreEmploymentHours}
                            label={trans('common.divergentPreEmploymentHours')}
                            name="divergentPreEmploymentHours"
                            tooltipLabel={startDateLabel}
                            value={divergentPreEmploymentHours}
                            onChange={handleDivergentPreEmploymentHoursChange}
                        />
                    )}
                </div>
                <div className="employment-dates-input__col">
                    {endDate && (
                        <EmploymentDateHoursInput
                            isDisabled={isDisabled}
                            error={errors.divergentPostEmploymentHours}
                            label={trans('common.divergentPostEmploymentHours')}
                            name="divergentPostEmploymentHours"
                            tooltipLabel={endDateLabel}
                            value={divergentPostEmploymentHours}
                            onChange={handleDivergentPostEmploymentHoursChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmploymentDatesInput;
