import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../../models';

const IconWarning: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 18 16" fill="none" className={`icon-svg ${className}`}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-521.000000, -390.000000)" fill="#FFFFFF" fillRule="nonzero">
                {/* eslint-disable-next-line max-len */}
                <path d="M522.889795,406 L537.110205,406 C538.56435,406 539.47083,404.428928 538.743758,403.177716 L531.633553,390.93841 C530.90648,389.687197 529.09352,389.687197 528.366447,390.93841 L521.256242,403.177716 C520.52917,404.428928 521.43565,406 522.889795,406 Z M530,399.41467 C529.480662,399.41467 529.05575,398.991327 529.05575,398.473909 L529.05575,396.592386 C529.05575,396.074967 529.480662,395.651624 530,395.651624 C530.519338,395.651624 530.94425,396.074967 530.94425,396.592386 L530.94425,398.473909 C530.94425,398.991327 530.519338,399.41467 530,399.41467 Z M530.94425,403.177716 L529.05575,403.177716 L529.05575,401.296193 L530.94425,401.296193 L530.94425,403.177716 Z" id="warning" />
            </g>
        </g>
    </svg>
);

export default IconWarning;
