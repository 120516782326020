import React, { FC } from 'react';

import { LoadingSpinner } from '../../../../@paco/components';
import { Log, LoketData } from '../../../../models';
import LoketDataCard from '../../components/LoketDataCard/LoketDataCard';
import LoketLogsCard from '../../components/LoketLogsCard/LoketLogsCard';

import './Synchronizations.scss';


interface SynchronizationsProps {
    loketData: LoketData | null;
    loading: boolean;
    logs: Log[];
    onNonCoupledEmployeesButtonClick: () => void;
}

const Synchronizations: FC<SynchronizationsProps> = ({
    loketData,
    logs,
    loading,
    onNonCoupledEmployeesButtonClick,
}) => (
    <div className="synchronizations">
        <LoketDataCard
            className="synchronizations__card"
            loketData={loketData}
            onNonCoupledEmployeesButtonClick={onNonCoupledEmployeesButtonClick}
        />
        <LoketLogsCard
            className="synchronizations__card-logs"
            logs={logs}
        />
        {loading && <LoadingSpinner />}
    </div>
);

export default Synchronizations;
