import { SettingKey } from '../../types';
import { Setting, SettingV3Resource } from './Setting';

export const transformLegacySettingToSetting = (resource: any): Setting => ({
    id: resource.id,
    isActive: !!resource.active,
    isEnabled: resource.value === '1',
    departmentId: resource?.department?.id,
    key: resource.key as SettingKey,
    value: resource.value,
});

export const transformToSetting = (resource: SettingV3Resource): Setting => ({
    id: resource.id,
    isActive: !!resource.active,
    isEnabled: resource.value !== '0',
    key: resource.key as SettingKey,
    departmentId: resource.department?.id,
    value: resource.value,
});
