import { Shift, ShiftIndex } from '../../../../entities/Shift/Shift';
import { formatDate } from '../../../../helpers/date';
import trans from '../../../../helpers/trans';
import { DateFormat } from '../../../../types/dateFormatTypes';

export const getSaveSequenceSuccessfulToastText = (shifts: (Shift | ShiftIndex)[]): string => {
    const dates = shifts
        .sort((a, b) => a.period.start.getTime() - b.period.start.getTime())
        .map(shift => formatDate(shift.period.start), DateFormat.date)
        .join(', ');

    if (shifts.length < 4) {
        return trans('containers.forms.saveSequenceForm.saveSequenceSuccess', { dates });
    }

    return trans('containers.forms.saveSequenceForm.saveSequenceMultipleSuccess', { dates, remainderCount: shifts.length - 3 });
};
