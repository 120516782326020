import axios from 'axios';

import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { checkIntegrityOfShift } from '../../models';
import { getEndOfWorkDay, getStartOfWorkDay } from '../date';
import { getLoketApiUrl, getObjProperty } from '../index';

// Used for activation, reactivation and deletion of user
export const INACTIVE = 'inactive';
export const ACTIVE = 'active';
export const DELETED = 'deleted';

const getUserShifts = async (userId, startDate, endDate) => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shifts/`,
        {
            params: {
                include: ['department', 'department.group'],
                search: userId,
                startDate: formatDate(getStartOfWorkDay(startDate), DateFormat.apiDateTime),
                endDate: formatDate(getEndOfWorkDay(endDate), DateFormat.apiDateTime),
            },
        },
    );

    response.data.forEach((shift) => {
        if (!checkIntegrityOfShift(shift)) {
            throw new TypeError(`Shift ${shift.id} type mismatch`);
        }
    });

    return response;
};

const getGroups = () => axios.get(
    `${process.env.REACT_APP_API_URL}/groups/`,
);

const getDepartments = () => axios.get(
    `${process.env.REACT_APP_API_URL}/departments/`,
    {
        params: {
            include: 'group',
        },
    },
);

function generateUserStatusBodyData(user, activityStatus) {
    return {
        type: 'users',
        id: user.id,
        attributes: {
            activityStatus,
        },
    };
}

function generateGroup(groupId) {
    if (!groupId) {
        return {
            group: {
                data: null,
            },
        };
    }

    return {
        group: {
            data: {
                type: 'groups',
                id: groupId,
            },
        },
    };
}

function generateGroupBodyData(department) {
    return {
        type: 'departments',
        attributes: {
            name: department.name,
        },
        relationships: generateGroup(department.groupId),
    };
}

const patchDepartment = (department) => {
    const body = generateGroupBodyData(department);
    body.id = department.id;
    return axios.patch(`${process.env.REACT_APP_API_URL}/departments/${department.id}/`, { data: body });
};

const patchDeactivateUser = (userId) => {
    const body = generateUserStatusBodyData(userId, INACTIVE);
    body.id = userId;
    return axios.patch(`${process.env.REACT_APP_API_URL}/users/${userId}/`, { data: body });
};

const patchReactivateUser = (userId) => {
    const body = generateUserStatusBodyData(userId, ACTIVE);
    body.id = userId;
    return axios.patch(`${process.env.REACT_APP_API_URL}/users/${userId}/`, { data: body });
};

const patchDeleteUser = (userId) => {
    const body = generateUserStatusBodyData(userId, DELETED);
    body.id = userId;
    return axios.patch(`${process.env.REACT_APP_API_URL}/users/${userId}/`, { data: body });
};

const postDepartment = (department) => {
    const body = generateGroupBodyData(department);
    return axios.post(`${process.env.REACT_APP_API_URL}/departments/`, { data: body });
};

const generateDocumentBodyData = (data) => {
    const {
        name,
        ext,
        visibleToEmployee,
        userId,
        hr,
        base64,
        id,
        documentType,
    } = data;
    const fileName = ext ? `${name}.${ext}` : name;
    const viewers = [
        ...hr,
        ...[visibleToEmployee && userId],
    ].filter(viewer => viewer);
    const mimeType = getObjProperty(data, 'file.type');
    const content = base64;

    return {
        type: 'files',
        id,
        attributes: {
            ...(fileName && { fileName }),
            ...(name && { displayName: name }),
            ...(content && { content }),
            ...(mimeType && { mimeType }),
        },
        relationships: {
            documentType: {
                data: {
                    type: 'documentType',
                    id: documentType,
                },
            },
            linkedUser: {
                data: {
                    type: 'users',
                    id: userId,
                },
            },
            viewers: {
                data: viewers.map(viewer => ({
                    type: 'users',
                    id: viewer,
                })),
            },
        },
    };
};

const postDocument = (data) => {
    const body = generateDocumentBodyData(data);
    return axios.post(`${process.env.REACT_APP_API_URL}/files/`, { data: body });
};

const patchDocument = (data) => {
    const body = generateDocumentBodyData(data);
    return axios.patch(`${process.env.REACT_APP_API_URL}/files/${data.id}`, { data: body });
};

const delDocument = id => axios.delete(`${process.env.REACT_APP_API_URL}/files/${id}`);

const postRegistrationMail = userId => axios.post(`${process.env.REACT_APP_API_URL}/users/${userId}/resend-registration-mail`);

// ?redirect_uri=${redirectUri}
const getLoginLoket = () => axios.get(
    `${process.env.REACT_APP_API_URL}/loket/login`,
);

const getAuthenticateLoket = (state, code) => axios.get(
    `${process.env.REACT_APP_API_URL}/loket/authorize?code=${code}&state=${state}`,
);

const getUsersCSV = () => axios.get(
    `${process.env.REACT_APP_API_URL}/users/export/sv4`,
);

const postUserToLoketLink = (id) => axios.put(
    `${getLoketApiUrl()}/employee/sync/${id}`,
);

const postUsersToLoketLink = () => axios.put(
    `${getLoketApiUrl()}/employee/sync`,
);

const getPermissionGroups = () => axios.get(`${process.env.REACT_APP_API_URL}/permission-groups`, { params: { include: 'permissions' } });

const generatePlanningPreferenceBody = (weekday, start, end, comment, userId, planningPreferenceId) => (
    {
        type: 'unavailableToWorkTimeSlot',
        ...(planningPreferenceId && { id: planningPreferenceId }),
        attributes: {
            weekday,
            start,
            end,
            comment,
        },
        relationships: {
            user: {
                data: {
                    type: 'users',
                    id: userId,
                },
            },
        },
    }
);

const patchUserPlanningPreference = (weekday, start, end, comment, userId, planningPreferenceId) => {
    const data = generatePlanningPreferenceBody(weekday, start, end, comment, userId, planningPreferenceId);
    return axios.patch(`${process.env.REACT_APP_API_URL}/unavailable-to-work-time-slots/${planningPreferenceId}`, {
        data,
    });
};

const postUserPlanningPreference = (weekday, start, end, comment, userId) => {
    const data = generatePlanningPreferenceBody(weekday, start, end, comment, userId);
    return axios.post(`${process.env.REACT_APP_API_URL}/unavailable-to-work-time-slots`, {
        data,
    });
};

const delUserPlanningPreference = (planningPreferenceId) => axios.delete(
    `${process.env.REACT_APP_API_URL}/unavailable-to-work-time-slots/${planningPreferenceId}`,
);


export {
    getUserShifts,
    getGroups,
    getDepartments,
    patchDepartment,
    postDepartment,
    patchDeactivateUser,
    patchReactivateUser,
    patchDeleteUser,
    postDocument,
    patchDocument,
    delDocument,
    postRegistrationMail,
    getLoginLoket,
    getAuthenticateLoket,
    getUsersCSV,
    postUserToLoketLink,
    postUsersToLoketLink,
    getPermissionGroups,
    patchUserPlanningPreference,
    postUserPlanningPreference,
    delUserPlanningPreference,
};
