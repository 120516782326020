import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { FormActionButtons, Textarea, TimeInput } from '../../../compositions';
import { transformDateAndTimesToPeriod } from '../../../entities/Period/PeriodTransformers';
import { EditShiftPlanningFormData, ShiftPlanning } from '../../../entities/ShiftPlanning/ShiftPlanning';
import { formatDate } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { isValidTimeString } from '../../../helpers/validation/validation';
import { DateFormat } from '../../../types/dateFormatTypes';

import './EditShiftPlanningForm.scss';

interface EditShiftPlanningFormProps {
    isLoading: boolean;
    shiftPlanning: ShiftPlanning;
    onCancel: () => void;
    onSubmit: (formData: EditShiftPlanningFormData) => void;
    className?: string;
}

const EditShiftPlanningForm: FC<EditShiftPlanningFormProps> = ({
    isLoading,
    shiftPlanning,
    onCancel,
    onSubmit,
    className = '',
}): ReactElement => {
    const formRef = useRef<HTMLFormElement>(null);

    const [start, setStart] = useState<string>(formatDate(shiftPlanning.period.start, DateFormat.hoursMinutes));
    const [end, setEnd] = useState<string>(formatDate(shiftPlanning.period.end, DateFormat.hoursMinutes));
    const [comment, setComment] = useState<string>(shiftPlanning.comments[shiftPlanning.comments.length - 1]?.body || '');

    const isValid = isValidTimeString(start) && isValidTimeString(end);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const period = transformDateAndTimesToPeriod(shiftPlanning.period.start, start, end);

        onSubmit({
            period,
            comment: comment || undefined,
        });
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`edit-shift-planning-form ${className}`}>
            <div className="edit-shift-planning-form__row">
                <TimeInput
                    label={trans('common.startTime')}
                    value={start}
                    onChange={setStart}
                    className="edit-shift-planning-form__col"
                />

                <TimeInput
                    label={trans('common.endTime')}
                    value={end}
                    onChange={setEnd}
                    className="edit-shift-planning-form__col"
                />
            </div>

            <div className="edit-shift-planning-form__row">
                <Textarea
                    label={trans('common.comment')}
                    rows={4}
                    value={comment}
                    onChange={setComment}
                    className="edit-shift-planning-form__col"
                />
            </div>

            <FormActionButtons
                isLoading={isLoading}
                disabled={!isValid}
                submitText={trans('common.modify')}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default EditShiftPlanningForm;
