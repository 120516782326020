import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { WorkweekUsersSelect } from '../../../compositions';
import { Period } from '../../../entities/Period/Period';
import { WorkweekUser } from '../../../entities/User/User';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import {
    clearUnavailableUsers,
    getUnavailableUsersByPeriod,
    getWorkweekUsers,
    getWorkweekUsersAbsences,
    getWorkweekUsersLeaveOfAbsences,
    getWorkweekUsersPreferToWorkTimeSlots,
    getWorkweekUsersUnavailableToWorkTimeSlots,
} from '../../../redux/workweekUsers/workweekUsersActions';

interface ConnectedWorkweekUsersSelectProps {
    controlShouldRenderValue?: boolean;
    excludeUsersWithShiftOnDate?: boolean;
    isDisabled?: boolean;
    departmentId?: string;
    excludeUsers?: string[];
    period: Period;
    placeholder?: string;
    onSelect: (user: WorkweekUser) => void;
    className?: string;
}

const ConnectedWorkweekUsersSelect: FC<ConnectedWorkweekUsersSelectProps> = ({
    controlShouldRenderValue = true,
    excludeUsersWithShiftOnDate = false,
    isDisabled = false,
    departmentId,
    excludeUsers = [],
    period,
    placeholder,
    onSelect,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const {
        isLoading,
        isLoadingUnavailableUsers,
        absences,
        leaveOfAbsences,
        preferToWorkTimeSlots,
        unavailableToWorkTimeSlots,
        workweekUsers,
        unavailableUsers,
    } = useTypedSelector(state => state.workweekUsersReducer);

    const users = useMemo(() => (
        workweekUsers
            .filter(user => !excludeUsers.includes(user.id))
            .filter(user => !unavailableUsers.includes(user.id))
    ), [workweekUsers, unavailableUsers, excludeUsers]);

    useEffect(() => {
        dispatch(getWorkweekUsers({
            ...(departmentId && { departments: [departmentId] }),
            workweek: period.start,
        }));

        dispatch(getWorkweekUsersAbsences({
            ...(departmentId && { departments: [departmentId] }),
            period,
        }));

        dispatch(getWorkweekUsersLeaveOfAbsences(period));
        dispatch(getWorkweekUsersUnavailableToWorkTimeSlots(period));
        dispatch(getWorkweekUsersPreferToWorkTimeSlots(period));
    }, []);

    useEffect((): () => void => {
        if (excludeUsersWithShiftOnDate) {
            dispatch(getUnavailableUsersByPeriod(period));
        }

        return (): void => {
            dispatch(clearUnavailableUsers());
        };
    }, [excludeUsersWithShiftOnDate]);

    return (
        <WorkweekUsersSelect
            controlShouldRenderValue={controlShouldRenderValue}
            isDisabled={isLoading || isLoadingUnavailableUsers || isDisabled}
            absences={absences}
            leaveOfAbsences={leaveOfAbsences}
            period={period}
            placeholder={placeholder}
            preferToWorkTimeSlots={preferToWorkTimeSlots}
            unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
            users={users}
            onSelect={onSelect}
            className={className}
        />
    );
};

export default ConnectedWorkweekUsersSelect;
