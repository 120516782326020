import { PayrollPeriod } from '../../../../entities/PayrollPeriod/PayrollPeriod';
import { doesDateOverlapWithPayrollPeriod, getPayrollPeriodDateAndNumberLabel } from '../../../../entities/PayrollPeriod/PayrollPeriodHelpers';
import trans from '../../../../helpers/trans';

export const getEmploymentDateTooltipLabel = (date: Date, payrollPeriods: PayrollPeriod[], isEndDate = false): string | undefined => {
    const overlappingPayrollPeriod = date ? payrollPeriods
        .find(payrollPeriod => doesDateOverlapWithPayrollPeriod(date, payrollPeriod)) : undefined;

    if (!overlappingPayrollPeriod) {
        const dateTranslation = isEndDate ? trans('common.endDate') : trans('common.startDate').toLowerCase();
        return trans('compositions.employmentDatesInput.noPayrollPeriodsFoundForDate', { date: dateTranslation });
    }

    return getPayrollPeriodDateAndNumberLabel(overlappingPayrollPeriod);
};
