import { FC, useMemo } from 'react';

import { Breadcrumbs, EmptyState } from '../../compositions';
import { DropboxEntity, DropboxFolder } from '../../entities/DropboxEntity/DropboxEntity';
import { transformDropboxFoldersToBreadcrumbs } from '../../entities/DropboxEntity/DropboxEntityTransformers';
import trans from '../../helpers/trans';
import { DropboxResultType } from '../../redux/dropbox/dropboxReducer';
import { KnowledgeBaseTable } from '../index';

import './KnowledgeBaseContainer.scss';

interface KnowledgeBaseContainerProps {
    isLoading: boolean;
    activeDirectoryPath?: string;
    dropboxEntities: DropboxEntity[];
    dropboxFolders: DropboxFolder[];
    resultType: DropboxResultType;
    onBreadcrumbClick: (id?: string) => void;
    onFolderClick: (dropboxEntityId: string) => void;
    onViewFileClick: (dropboxEntityId: string) => void;
    className?: string;
}

const KnowledgeBaseContainer: FC<KnowledgeBaseContainerProps> = ({
    isLoading,
    activeDirectoryPath,
    dropboxEntities,
    dropboxFolders,
    resultType,
    onBreadcrumbClick,
    onFolderClick,
    onViewFileClick,
    className = '',
}) => {
    const breadcrumbs = useMemo(() => (
        transformDropboxFoldersToBreadcrumbs(dropboxFolders, activeDirectoryPath)
    ), [dropboxFolders, activeDirectoryPath]);

    const handleBreadcrumbClick = (value?: string): void => {
        onBreadcrumbClick(value);
    };

    if (!isLoading && resultType === DropboxResultType.search && !dropboxEntities.length) {
        return (
            <div className={`knowledge-base-container ${className}`}>
                <EmptyState
                    intro={trans('common.noResultsFound')}
                    title={trans('common.search')}
                    className="knowledge-base-container__empty-state"
                />
            </div>
        );
    }

    return (
        <div className={`knowledge-base-container ${className}`}>
            {resultType === DropboxResultType.folder && (
                <Breadcrumbs
                    breadcrumbs={breadcrumbs}
                    onBreadcrumbClick={handleBreadcrumbClick}
                />
            )}
            <KnowledgeBaseTable
                isLoading={isLoading}
                dropboxEntities={dropboxEntities}
                onFolderClick={onFolderClick}
                onViewFileClick={onViewFileClick}
                className="knowledge-base-container__table"
            />
        </div>
    );
};

export default KnowledgeBaseContainer;
