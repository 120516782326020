import { translate } from '../../../helpers/translations/translator';

export default function getMonthAndWeekdayTranslations(): [string[], string[]] {
    const months = [
        translate('common.months.jan'),
        translate('common.months.feb'),
        translate('common.months.mar'),
        translate('common.months.apr'),
        translate('common.months.may'),
        translate('common.months.jun'),
        translate('common.months.jul'),
        translate('common.months.aug'),
        translate('common.months.sep'),
        translate('common.months.oct'),
        translate('common.months.nov'),
        translate('common.months.dec'),
    ];

    const weekDays = [
        translate('common.weekDays.sun'),
        translate('common.weekDays.mon'),
        translate('common.weekDays.tue'),
        translate('common.weekDays.wed'),
        translate('common.weekDays.thu'),
        translate('common.weekDays.fri'),
        translate('common.weekDays.sat'),
    ];

    return [months, weekDays];
}
