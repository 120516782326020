import { RefObject, useContext, useEffect } from 'react';

import { PrintableBlockOffset, PrintableBlockOffsetsContext } from '../contexts/PrintableBlockOffsetsContext';

const useBlockItemOffset = (
    blockItemRef: RefObject<HTMLDivElement>,
    blockId: string,
    isSizer: boolean,
): void => {
    const { addOffset } = useContext(PrintableBlockOffsetsContext);

    useEffect((): void => {
        if (blockItemRef.current && addOffset && isSizer) {
            const { offsetTop, clientHeight } = blockItemRef.current;
            const newOffset: PrintableBlockOffset = {
                offsetTop,
                clientHeight,
                id: blockId,
            };

            addOffset(newOffset);
        }
    }, [blockItemRef]);
};

export default useBlockItemOffset;
