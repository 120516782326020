import React from 'react';

import PropTypes from 'prop-types';

import Icon from '../../../../components/style/Icon/Icon';
import { translate } from '../../../../helpers/translations/translator';

import './Contract.scss';
import '../list.scss';

const Contract = ({
    data, active, onActivateClick, onDeleteChangeClick,
}) => {
    if (!data) {
        return null;
    }

    const mainClass = `contract ${active ? 'contract--active' : ''}`;
    const buttonClass = `contract-button-toggle ${active ? 'contract-button-toggle--active' : ''}`;

    return (
        <div className={mainClass}>
            <div className="contract-top">
                <div className="contract-name">
                    { data.name }
                </div>
                <button type="button" className={buttonClass} onClick={() => onActivateClick(!active && data.id)}>
                    { active ? translate('pages.management.closeShut') : translate('pages.management.foldOpen') }
                </button>
            </div>
            <div className="contract-detail">
                <ul className="contract-ul ul-3-columns">
                    <li>
                        <div className="li-name">
                            {translate('pages.management.departments')}
                        </div>
                        <div>
                            {data.departments}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.function')}
                        </div>
                        <div>
                            {data.function}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('common.employeeType')}
                        </div>
                        <div>
                            {data.employeeType}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.contractType')}
                        </div>
                        <div>
                            {data.contractType}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.sectorPremium')}
                        </div>
                        <div>
                            {data.sectorPremium}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.accrualPercentage')}
                        </div>
                        <div>
                            {data.accrualPercentage}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.clockTime')}
                        </div>
                        <div>
                            {data.clockTime}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.hourlyWage')}
                        </div>
                        <div>
                            {data.hourlyWage}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.wagePerPeriod')}
                        </div>
                        <div>
                            {data.wagePerPeriod}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.travelCostsPerDayWorked')}
                        </div>
                        <div>
                            {data.travelCostsPerDayWorked}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.otherExpenses')}
                        </div>
                        <div>
                            {data.otherExpenses}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.incomeTax')}
                        </div>
                        <div>
                            {data.incomeTax}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.numberOfContractHoursPerWeek')}
                        </div>
                        <div>
                            {data.numberOfContractHoursPerWeek}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {`${translate('pages.management.costFor')} 
                            ${translate('pages.management.breakfast').toLowerCase()}`}
                        </div>
                        <div>
                            {data.breakFast}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {`${translate('pages.management.costFor')} 
                            ${translate('pages.management.lunch').toLowerCase()}`}
                        </div>
                        <div>
                            {data.lunch}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {`${translate('pages.management.costFor')} 
                            ${translate('pages.management.dinner').toLowerCase()}`}
                        </div>
                        <div>
                            {data.dinner}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.deposit')}
                        </div>
                        <div>
                            {data.deposit}
                        </div>
                    </li>
                    <li>
                        <div className="li-name">
                            {translate('pages.management.particularities')}
                        </div>
                        <div>
                            {data.particularities}
                        </div>
                    </li>
                </ul>
                <button
                    type="button"
                    className="contract-button-edit"
                >
                    <Icon color="light-blue" kind="edit" />
                </button>
            </div>
            { data.changes && (
                <div className="contract-changes">
                    <div className="contract-changes-title">
                        {translate('pages.management.comingChanges')}
                    </div>
                    { data.changes && data.changes.map(item => (
                        <div key={item.id} className="contract-change">
                            {item.value}
                            <button
                                className="contract-button-delete"
                                type="button"
                                onClick={() => onDeleteChangeClick(item.id)}
                            >
                                <Icon color="light-blue" kind="trash" fontSize={20} />
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

Contract.propTypes = {
    data: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    onActivateClick: PropTypes.func.isRequired,
    onDeleteChangeClick: PropTypes.func.isRequired,
};

export default Contract;
