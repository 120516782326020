import { getExperiencesApiCall } from '../../entities/Experience/ExperienceService';
import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import { setError, setExperiences, setIsLoading } from './experiencesReducer';

export const fetchExperiences = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getExperiencesApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setExperiences(response.data));
    } catch (error) {
        console.error('[fetchExperiences]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
