import { DepartmentViewModel } from '../../../../entities/Departments/Departments';
import { DepartmentOption } from '../../SelectDepartments/models/DepartmentOption';

export default function transformDepartmentToSelectOption(
    department: DepartmentViewModel,
    hasFullGroup?: boolean,
): DepartmentOption {
    const group = department.group ? department.group.name : '';
    const label = `${group ? `${group} - ` : ''}${department.name}`;

    return {
        label,
        hasFullGroup,
        value: department.id,
    };
}
