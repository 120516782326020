import { FC } from 'react';

import { Skeleton } from '../index';
import { BackgroundWooshImage } from './subcomponents';

import './DashboardWelcomeCard.scss';

interface DashboardWelcomeCardProps {
    className?: string;
}

const DashboardWelcomeCardSkeleton: FC<DashboardWelcomeCardProps> = ({ className = '' }) => (
    <div className={`dashboard-welcome-card ${className}`}>
        <Skeleton className="dashboard-welcome-card__title dashboard-welcome-card__skeleton" />
        <BackgroundWooshImage className="dashboard-welcome-card__image" />
    </div>
);

export default DashboardWelcomeCardSkeleton;
