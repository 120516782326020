export const numberDoesNotExceedComparison = (number: number, comparisonNumber: number): boolean => number <= comparisonNumber;

// eslint-disable-next-line no-self-compare
export const stringIsNumber = (n: string): boolean => (n ? +n === +n : false);

export const stringContainsValue = (value?: string): boolean => {
    if (!value) {
        return false;
    }

    return value.trim().length > 0;
};

export const stringMatchesRegEx = (string: string, regEx: RegExp, flag?: string): boolean => {
    const regExp = new RegExp(regEx, flag);

    return regExp.test(string);
};

export const isValidTimeString = (time: string): boolean => {
    if (!time.includes(':')) {
        return false;
    }

    const splitTime = time.split(':');
    const isValidFormat = splitTime.every(stringIsNumber) && splitTime.every(number => number.length === 2);

    const [hh, mm] = splitTime.map(Number);
    const isValidHours = hh >= 0 && hh <= 23;
    const isValidMinutes = mm >= 0 && mm <= 59;

    return isValidFormat && isValidHours && isValidMinutes;
};

export const arrayHasMinimumLength = <T>(array: T[], minimumLength: number): boolean => array.length >= minimumLength;

export const stringHasMinimumLength = (string: string, minLength: number): boolean => string.length >= minLength;
