import axios from 'axios';

import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { ShiftPlanningResource, ShiftPlanningViewModel } from '../../models';
import { GetShiftPlanningsWithinRangeParams } from './ShiftPlanning';
import { transformToShiftPlanningViewModel } from './ShiftPlanningsTransformers';

export const getShiftPlanningsWithinRange = async (params: GetShiftPlanningsWithinRangeParams): Promise<ShiftPlanningViewModel[]> => {
    const {
        planned,
        startDate,
        endDate,
        filter,
        includes = [],
    } = params;
    const { userIds } = filter;

    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/plannings`);

    const response = await axios.get<ShiftPlanningResource[]>(String(url), {
        params: {
            ...(includes.length && { include: includes.join(',') }),
            startDate: formatDate(startDate, DateFormat.apiDateTime),
            endDate: formatDate(endDate, DateFormat.apiDateTime),
            planned: planned ? 1 : 0,
            userUuids: (userIds && userIds.length > 0) ? JSON.stringify(userIds) : undefined,
        },
    });

    return response.data.map(resource => transformToShiftPlanningViewModel(resource, resource.comments));
};
