import React from 'react';

import PropTypes from 'prop-types';

import { capitalizeFirstLetter, getObjProperty } from '../../helpers';
import { formatDate } from '../../helpers/date';

import './AbsenceOverlappingShift.scss';

const AbsenceOverlappingShift = ({
    onClick, shift, absence,
}) => {
    const { peopleLimit, plannedUsers } = shift;
    const date = capitalizeFirstLetter(formatDate(shift.start, 'EEEE dd-MM-yyyy'));
    const startTime = formatDate(shift.start, 'HH:mm');
    const endTime = formatDate(shift.end, 'HH:mm');
    const department = getObjProperty(shift, 'department.name');

    return (
        <button type="button" className="overlapping-shift" onClick={() => onClick(shift, absence)}>
            <div className="overlapping-shift-date">{date}</div>
            <div className="overlapping-shift-middle">
                <div className="overlapping-shift-time">
                    {`${startTime} - ${endTime}`}
                </div>
                <div className="overlapping-shift-planning">
                    {`(${plannedUsers.length}/${peopleLimit})`}
                </div>
            </div>
            <div className="overlapping-shift-department">
                {department}
            </div>
        </button>
    );
};

AbsenceOverlappingShift.propTypes = {
    onClick: PropTypes.func.isRequired,
    shift: PropTypes.object.isRequired,
    absence: PropTypes.object.isRequired,
};

export default AbsenceOverlappingShift;
