import { FC, useEffect, useRef } from 'react';

import classNames from 'classnames';

import { scrollIntoView } from '../../../../../helpers/tools/scroll';
import { Icon } from '../../../../components';
import trans from '../../../../helpers/trans';

import './HelpButton.scss';

interface HelpButtonProps {
    isActive?: boolean;
    animationDelayInSeconds?: number;
    onClick: () => void;
    className?: string;
}

const HelpButton: FC<HelpButtonProps> = ({
    isActive = false,
    animationDelayInSeconds = 0,
    onClick,
    className = '',
}) => {
    const helpButtonClassNames = classNames('help-button', {
        'help-button--is-active': isActive,
    }, className);

    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (isActive) {
            scrollIntoView(buttonRef);
        }
    }, [buttonRef, isActive]);

    return (
        <button
            ref={buttonRef}
            style={{ animationDelay: `${animationDelayInSeconds}s` }}
            type="button"
            onClick={onClick}
            className={helpButtonClassNames}
        >
            <div className="help-button__inner">
                <Icon
                    name="help-circle-outline"
                    className="help-button__icon"
                />
                <div className="help-button__text">
                    <div className="help-button__text-wrapper">
                        {trans('help.showHelpContent')}
                    </div>
                </div>
            </div>
        </button>
    );
};

export default HelpButton;
