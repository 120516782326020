import React, { FC, useMemo } from 'react';

import classnames from 'classnames';

import { formatDate } from '../../../../../../helpers/date';

import './DayHeader.scss';


interface Props {
    day: Date;
    dayHeaderFormat: 'EEEEEE' | 'EEEE';
    className?: string;
}

const DayHeader: FC<Props> = ({
    day,
    dayHeaderFormat = 'EEEEEE',
    className,
}) => {
    const classNames = useMemo(() => classnames('day-header concept-day-header', {
        [`concept-day-header--has-${dayHeaderFormat}-format`]: dayHeaderFormat,
    }, [className]), [className, dayHeaderFormat]);

    const weekday = formatDate(new Date(day), dayHeaderFormat);

    return (
        <div className={classNames}>
            {weekday}
        </div>
    );
};

export default DayHeader;
