import { getRandomUuid } from '../../helpers/crypto';
import { addDays, getStartOfWorkDay, isBefore } from '../../helpers/date';
import { Period } from '../Period/Period';
import { doesPeriodOverlapWithWorkday } from '../Period/PeriodHelpers';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { EmploymentDatesFormData } from '../User/User';
import { ContractHours, ContractHoursFormData, EditContractHoursFormData } from './ContractHours';

export const getOverlappingContractHourWithDate = (date: Date, contractHours: ContractHours[]): ContractHours | undefined => {
    const match = contractHours.find(contractHour => (
        contractHour.period.hasEnd && doesPeriodOverlapWithWorkday(date, contractHour.period)
    ));

    if (match) {
        return match;
    }

    return contractHours.find(contractHour => !contractHour.period.hasEnd);
};

export const getContractHoursLabel = (contractHours: ContractHours[] = [], plannedHoursInWeek?: number, date = new Date()): string => {
    const matchingContractHours = getOverlappingContractHourWithDate(date, contractHours);

    if (plannedHoursInWeek !== undefined && matchingContractHours !== undefined) {
        return `${plannedHoursInWeek}/${matchingContractHours.hours}`;
    }

    if (plannedHoursInWeek !== undefined) {
        return `${plannedHoursInWeek.toString()}/?`;
    }

    if (matchingContractHours) {
        return matchingContractHours.hours.toString();
    }

    return '?';
};

export const getEmptyContractFormData = (date: Date = getStartOfWorkDay(new Date()), hours = 0): ContractHoursFormData => {
    const end = isBefore(new Date(), date) ? date : new Date();
    const justifiedEnd = getStartOfWorkDay(addDays(end, 1));

    return {
        id: getRandomUuid(),
        hours,
        period: transformToPeriod(date, justifiedEnd, false),
    };
};

export const sortContractHoursByDate = (contractHours: ContractHours[]): ContractHours[] => contractHours
    .sort((a, b) => a.period.start.getTime() - b.period.start.getTime());

// ContractHours should be aligned after submitting the AddUserForm and EditUserForm. But things could go wrong because it could be
// multiple API calls. This function is to correct a potential missing "hasEnd" property in the last contract hour.
export const alignContractHours = (contractHours: ContractHours[]): ContractHours[] => {
    const clonedContractHours = sortContractHoursByDate([...contractHours]);

    return clonedContractHours.map((contractHour, index) => ({
        ...contractHour,
        period: {
            ...contractHour.period,
            hasEnd: index < clonedContractHours.length - 1,
        },
    }));
};

export const alignContractHoursFormData = (contractHours: ContractHoursFormData[]): ContractHoursFormData[] => {
    const activeContractHours = contractHours.filter(contractHour => !contractHour.isDeleted);
    const deletedContractHours = contractHours.filter(contractHour => contractHour.isDeleted);

    const alignedActiveContractHours = activeContractHours
        .sort((a, b) => a.period.start.getTime() - b.period.start.getTime())
        .map((contractHour, index) => {
            const nextContractHour = activeContractHours[index + 1];

            if (!nextContractHour) {
                const end = isBefore(contractHour.period.end, new Date()) ? new Date() : contractHour.period.end;
                const period: Period = { ...contractHour.period, end, hasEnd: false };

                return {
                    ...contractHour,
                    period,
                };
            }

            const end = nextContractHour.period.start;
            const start = isBefore(end, contractHour.period.start) ? end : contractHour.period.start;
            const period = transformToPeriod(start, end, true);

            return {
                ...contractHour,
                period,
            };
        });

    return [...alignedActiveContractHours, ...deletedContractHours];
};

export const updateContractHoursWithEmployment = (contractHours: ContractHoursFormData[] = [], employment: EmploymentDatesFormData | undefined): ContractHoursFormData[] => {
    if (!employment || !contractHours.length) {
        return contractHours;
    }

    if (!employment.startDate) {
        return contractHours;
    }

    const firstContractHour = contractHours[0];

    const updatedFirstContractHour = {
        ...firstContractHour,
        period: {
            ...firstContractHour.period,
            start: employment.startDate,
        },
    };

    const updatedContractHours = [...contractHours];

    updatedContractHours[0] = updatedFirstContractHour;

    return alignContractHoursFormData(updatedContractHours);
};

export const isEditContractHoursFormData = (formData: ContractHoursFormData): formData is EditContractHoursFormData => (
    'id' in formData
    && 'hours' in formData
    && 'resourceId' in formData
    && formData.isDeleted !== true
);

export const isDeleteContractHoursFormData = (formData: ContractHoursFormData): formData is EditContractHoursFormData => (
    'id' in formData
    && 'hours' in formData
    && 'resourceId' in formData
    && formData.isDeleted === true
);
