import { FC, PropsWithChildren } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '../../components';
import { IconButton } from '../index';

import './EmptyState.scss';

interface EmptyStateProps {
    showButton?: boolean;
    showLink?: boolean;
    buttonText?: string;
    intro?: string;
    to?: string;
    title?: string;
    onAddButtonClick?: () => void;
    className?: string;
}

const EmptyState: FC<PropsWithChildren<EmptyStateProps>> = ({
    showButton,
    showLink,
    buttonText,
    intro,
    to,
    title,
    onAddButtonClick,
    className = '',
    children,
}) => (
    <div className={`empty-state ${className}`}>
        {title && (
            <h2 className="empty-state__title">
                {title}
            </h2>
        )}
        {intro && (
            <p className="empty-state__intro">
                {intro}
            </p>
        )}
        {showLink && (
            <Link
                to={to || '/'}
                className="empty-state__add-button"
            >
                <Icon
                    name="plus"
                    className="empty-state__add-button-icon"
                />
                {buttonText}
            </Link>
        )}
        {showButton && (
            <IconButton
                icon="plus"
                text={buttonText || ''}
                onClick={onAddButtonClick}
                className="empty-state__add-button"
            />
        )}
        {children}
    </div>
);

export default EmptyState;
