import {
    all,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import { getYearEndStatementsApiCall } from '../../@paco/entities/YearEndStatement/YearEndStatementService';
import { TOAST_TYPE_PASS } from '../../constants';
import {
    deleteComment,
    getComments,
    patchComment,
    postComment,
} from '../../helpers/api-ts/comment';
import { getCommentCategories } from '../../helpers/api-ts/commentCategory';
import { getDocumentTypes } from '../../helpers/api-ts/documentType';
import { getDocumentsForUser } from '../../helpers/api-ts/file';
import { getLoketLeaveBalance } from '../../helpers/api-ts/leaveBalance';
import { getUserPayslips } from '../../helpers/api-ts/payslip';
import { getTracksWithFeedbackForUser } from '../../helpers/api-ts/track';
import { getUser } from '../../helpers/api-ts/user';
import { translate } from '../../helpers/translations/translator';
import getUserCallIncludes from '../../services/UserService/getUserCallIncludes';
import {
    APP_SAGA_ADD_TOAST,
    MANAGEMENT_ADD_USER_NOTE_FAILURE,
    MANAGEMENT_ADD_USER_NOTE_REQUEST,
    MANAGEMENT_ADD_USER_NOTE_SUCCESS,
    MANAGEMENT_DELETE_USER_NOTE_FAILURE,
    MANAGEMENT_DELETE_USER_NOTE_REQUEST,
    MANAGEMENT_DELETE_USER_NOTE_SUCCESS,
    MANAGEMENT_EDIT_USER_NOTE_FAILURE,
    MANAGEMENT_EDIT_USER_NOTE_REQUEST,
    MANAGEMENT_EDIT_USER_NOTE_SUCCESS,
    MANAGEMENT_FAILURE,
    MANAGEMENT_GET_COMMENT_CATEGORIES_FAILURE,
    MANAGEMENT_GET_COMMENT_CATEGORIES_REQUEST,
    MANAGEMENT_GET_COMMENT_CATEGORIES_SUCCESS,
    MANAGEMENT_GET_USER_REQUEST,
    MANAGEMENT_SAGA_ADD_USER_NOTE,
    MANAGEMENT_SAGA_DELETE_USER_NOTE,
    MANAGEMENT_SAGA_EDIT_USER_NOTE,
    MANAGEMENT_SAGA_GET_COMMENT_CATEGORIES,
    MANAGEMENT_SAGA_GET_DOCUMENT_TYPES,
    MANAGEMENT_SAGA_GET_USER,
    MANAGEMENT_SET_USER,
} from '../actionTypes';
import { getCurrentUserFromStore } from '../app/appHelpers';
import { transformUserLeaveOfAbsencesAndPermissions } from './managementHelpers';
import {
    AddUserNoteAction,
    DeleteUserNoteAction,
    EditUserNoteAction,
    GetUserAction,
} from './managementModels';

export function* fetchUser(action: GetUserAction): any {
    yield put({ type: MANAGEMENT_GET_USER_REQUEST });

    try {
        const currentUser = yield getCurrentUserFromStore();
        const state = yield select();
        const { permissions } = currentUser;
        const includes = getUserCallIncludes('user', permissions);
        const { user } = state.managementReducer;
        const id = (action && action.id) || user.id;
        const [userData, documentTypes, userNotes, userTracks] = yield all([
            getUser(id, includes),
            getDocumentTypes(),
            getComments([id], ['owner', 'commentCategory']),
            getTracksWithFeedbackForUser([id], ['department', 'owner', 'feedback', 'feedback.category']),
        ]);

        const mayViewPayslips = userData.data.id === currentUser.id;
        const userFiles = yield getDocumentsForUser(userData.data);
        const { loketEmployeeId } = userData.data;

        const [payslips, loketHours, yearEndStatements] = yield all([
            loketEmployeeId && mayViewPayslips
                ? getUserPayslips(loketEmployeeId, documentTypes)
                : [],
            loketEmployeeId
                ? getLoketLeaveBalance(loketEmployeeId)
                : null,
            loketEmployeeId && mayViewPayslips
                ? getYearEndStatementsApiCall(loketEmployeeId)
                : [],
        ]);
        const files = [...userFiles, ...payslips];
        const transformedUser = transformUserLeaveOfAbsencesAndPermissions(userData.data);

        yield put({
            type: MANAGEMENT_SET_USER,
            user: transformedUser,
            files,
            yearEndStatements: yearEndStatements.status === 200 ? yearEndStatements.data : [],
            loketHours,
            userNotes,
            userTracks,
        });
        yield put({ type: MANAGEMENT_SAGA_GET_DOCUMENT_TYPES, documentTypes });
    } catch (error) {
        console.error('[fetchUser]', error);
        yield put({ type: MANAGEMENT_FAILURE, errors: [error] });
    }
}

export function* addUserNote(action: AddUserNoteAction): any {
    yield put({ type: MANAGEMENT_ADD_USER_NOTE_REQUEST });

    try {
        yield postComment(action.formData);
        const { managementReducer } = yield select();
        yield* fetchUser(managementReducer.user.id);
        yield put({ type: MANAGEMENT_ADD_USER_NOTE_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.addNoteSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_ADD_USER_NOTE_FAILURE });
    }
}

export function* editUserNote(action: EditUserNoteAction): any {
    yield put({ type: MANAGEMENT_EDIT_USER_NOTE_REQUEST });

    try {
        yield patchComment(action.formData);
        const { managementReducer } = yield select();
        yield* fetchUser(managementReducer.user.id);
        yield put({ type: MANAGEMENT_EDIT_USER_NOTE_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.editNoteSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_EDIT_USER_NOTE_FAILURE });
    }
}

export function* deleteUserNote(action: DeleteUserNoteAction): any {
    yield put({ type: MANAGEMENT_DELETE_USER_NOTE_REQUEST });

    try {
        yield deleteComment(action.id);
        const { managementReducer } = yield select();
        yield* fetchUser(managementReducer.user.id);
        yield put({ type: MANAGEMENT_DELETE_USER_NOTE_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.deleteNoteSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_DELETE_USER_NOTE_FAILURE });
    }
}

export function* fetchCommentCategories(): any {
    yield put({ type: MANAGEMENT_GET_COMMENT_CATEGORIES_REQUEST });

    try {
        const commentCategories = yield getCommentCategories();
        yield put({ type: MANAGEMENT_GET_COMMENT_CATEGORIES_SUCCESS, commentCategories });
    } catch (error) {
        yield put({ type: MANAGEMENT_GET_COMMENT_CATEGORIES_FAILURE });
    }
}

export default function* managementWatcher() {
    yield takeLatest(MANAGEMENT_SAGA_GET_USER, fetchUser);
    yield takeLatest(MANAGEMENT_SAGA_ADD_USER_NOTE, addUserNote);
    yield takeLatest(MANAGEMENT_SAGA_EDIT_USER_NOTE, editUserNote);
    yield takeLatest(MANAGEMENT_SAGA_DELETE_USER_NOTE, deleteUserNote);
    yield takeLatest(MANAGEMENT_SAGA_GET_COMMENT_CATEGORIES, fetchCommentCategories);
}
