import { getLoketApiUrl } from '../../helpers';

interface ApiGeneratorOptions {
    apiUrl?: string;
    endpoint: string;
    queryParams?: Record<string, string | number | boolean>;
    pageSize?: number;
}

export const generateApiUrl = (options: ApiGeneratorOptions): string => {
    const apiUrl = options.apiUrl || process.env.REACT_APP_API_URL;

    const url = new URL(apiUrl + options.endpoint);
    const queryParams = {
        ...options.queryParams || {},
        // Fix for API standard page size 100 limit
        'page[size]': options.pageSize ? options.pageSize.toString() : '9999',
    };

    Object.entries(queryParams).forEach(([key, value]) => {
        url.searchParams.set(key, value);
    });

    return String(url);
};

const generateUrl = (options: ApiGeneratorOptions, apiUrl?: string): string => {
    const url = new URL(apiUrl + options.endpoint);
    const queryParams = {
        ...options.queryParams || {},
    };

    Object.entries(queryParams).forEach(([key, value]) => {
        url.searchParams.set(key, `${value}`);
    });

    return String(url);
};

export const generateApiV3Url = (options: ApiGeneratorOptions): string => {
    const apiUrl = options.apiUrl || process.env.REACT_APP_API_URL_V3;

    return generateUrl(options, apiUrl);
};

export const generateLoketUrl = (options: ApiGeneratorOptions): string => {
    const apiUrl = getLoketApiUrl();

    return generateUrl(options, apiUrl);
};
