import { LeaveOfAbsence } from '../../../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { LoketLeaveOfAbsenceHour } from '../../../../entities/LeaveOfAbsenceHour/LeaveOfAbsenceHour';
import { LoketLeaveSyncError } from '../../../../entities/LoketLeaveSync/LoketLeaveSync';

export interface ExportLeaveOfAbsenceSyncError {
    leaveOfAbsence: LeaveOfAbsence;
    leaveOfAbsenceHourId: string;
    message?: string;
}

export const transformLoketSyncErrorsAndLeaveOfAbsenceHoursToExportLeaveOfAbsenceSyncError = (
    errors: LoketLeaveSyncError[],
    leaveOfAbsenceHours: LoketLeaveOfAbsenceHour[],
): ExportLeaveOfAbsenceSyncError[] => leaveOfAbsenceHours
    .filter(leaveOfAbsenceHour => errors.some(error => error.leaveId === leaveOfAbsenceHour.id))
    .map(leaveOfAbsenceHour => {
        const errorMatch = errors.find(error => error.leaveId === leaveOfAbsenceHour.id);

        return {
            leaveOfAbsence: leaveOfAbsenceHour.leaveOfAbsence,
            leaveOfAbsenceHourId: leaveOfAbsenceHour.id,
            message: errorMatch?.message,
        };
    });
