import React, { FC } from 'react';

import classnames from 'classnames';

import { Permission } from '../../@paco/entities/Permission/Permission';
import { Role } from '../../@paco/entities/Role/Role';
import { transformLegacyRoleToRole } from '../../@paco/entities/Role/RoleTransformers';
import trans from '../../@paco/helpers/trans';
import { getUserFullName } from '../../helpers';
import { getPermissionToEdit } from '../../helpers/permissions/getPermissionToAction';
import { translate } from '../../helpers/translations/translator';
import { LeaveOfAbsence } from '../../models';
import { getAbsenceDateString } from '../../pages/Absences/helpers';
import Icon from '../style/Icon/Icon';
import TableContainer from '../Table/TableContainer';
import UserPageLink from '../UserPageLink/UserPageLink';

interface LeaveOfAbsencesTableProps {
    currentUserRole?: Role;
    leaveOfAbsences: LeaveOfAbsence[];
    permissions: Permission[];
    onLeaveOfAbsenceClick: (leaveOfAbsence: LeaveOfAbsence) => void;
    className?: string;
}

const LeaveOfAbsencesTable:FC<LeaveOfAbsencesTableProps> = ({
    currentUserRole,
    leaveOfAbsences,
    permissions,
    onLeaveOfAbsenceClick,
    className = '',
}) => {
    const classNames = classnames('leave-of-absences-table', className);

    const renderUserPageLink = (leaveOfAbsence: LeaveOfAbsence) => {
        const { user } = leaveOfAbsence;
        const name = getUserFullName(user);

        if (!user) {
            return null;
        }

        return (
            <UserPageLink isPlain name={name} id={user.id} />
        );
    };

    const renderLeaveType = (leaveOfAbsence: LeaveOfAbsence) => {
        const { leaveOfAbsenceHours } = leaveOfAbsence;

        if (!leaveOfAbsenceHours || !leaveOfAbsenceHours.length) {
            return null;
        }

        return trans(`compositions.leaveTypeSelectInput.${leaveOfAbsenceHours[0].leaveType}`);
    };

    const renderEditButton = (leaveOfAbsence: LeaveOfAbsence) => {
        const leaveOfAbsenceUserRoles = leaveOfAbsence.user.roles.map(transformLegacyRoleToRole);
        const canEditLeaveOfAbsences = getPermissionToEdit(currentUserRole, leaveOfAbsenceUserRoles, permissions, 'leave-of-absences');

        if (!canEditLeaveOfAbsences) {
            return null;
        }

        return (
            <button type="button" onClick={() => onLeaveOfAbsenceClick(leaveOfAbsence)} className="clear-button">
                <Icon color="light-blue" kind="edit" />
            </button>
        );
    };

    return (
        <div className={classNames}>
            <TableContainer
                data={leaveOfAbsences}
                attributes={[
                    {
                        customAction: (absence: LeaveOfAbsence) => renderUserPageLink(absence),
                        label: translate('common.name'),
                    },
                    {
                        customAction: (absence: LeaveOfAbsence) => renderLeaveType(absence),
                        label: translate('pages.absences.leaveType'),
                    },
                    {
                        customAction: (absence: LeaveOfAbsence) => getAbsenceDateString(absence),
                        label: translate('common.date'),
                    },
                    {
                        customAction: (absence: LeaveOfAbsence) => absence.reason,
                        label: translate('common.description'),
                    },
                    {
                        customAction: (absence: LeaveOfAbsence) => renderEditButton(absence),
                        className: 'td-edit',
                    },
                ]}
            />
        </div>
    );
};

export default LeaveOfAbsencesTable;
