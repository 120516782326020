import React, { FC, ReactElement, useMemo } from 'react';

import { Track } from '../../../../../../@paco/entities/Track/Track';
import { getOrphanedTracks } from '../../../../../../@paco/entities/Track/TrackHelpers';
import { Shift } from '../../../../../../models';
import CalendarUserTrack from '../CalendarUserTrack/CalendarUserTrack';
import UserShift from '../UserShift/UserShift';

interface UserShiftAndTracksListProps {
    shifts: Shift[];
    tracks: Track[];
    userId: string;
}

const UserShiftAndTracksList: FC<UserShiftAndTracksListProps> = ({ shifts, tracks, userId }): ReactElement => {
    const plannedTracks = tracks.filter(track => track.shift);
    const unplannedTracks = tracks.filter(track => !track.shift);
    const orphanedTracks = useMemo(() => getOrphanedTracks(tracks, shifts), [shifts, tracks]);

    return (
        <div className="shifts-list">
            {shifts
                .filter(shift => {
                    const userPlanning = shift.shiftPlannings.find((shiftPlanning) => shiftPlanning.user.id === userId);

                    return !!userPlanning?.planned;
                })
                .map(shift => {
                    const shiftTrack = plannedTracks.find(track => track.shift?.id === shift.id);

                    return (
                        <UserShift
                            key={shift.id}
                            shift={shift}
                            track={shiftTrack}
                            userId={userId}
                        />
                    );
                })}

            {orphanedTracks.map(track => (
                <CalendarUserTrack
                    isOrphan
                    track={track}
                    key={track.id}
                />
            ))}

            {unplannedTracks.map(track => (
                <CalendarUserTrack
                    track={track}
                    key={track.id}
                />
            ))}
        </div>
    );
};

export default UserShiftAndTracksList;
