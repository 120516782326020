import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { transformToShiftPlanningSnapshot, transformToShiftPlanningSnapshotResource } from '../ShiftPlanningSnapshot/ShiftPlanningSnapshotTransformers';
import { ShiftSnapshot, ShiftSnapshotResource, ShiftsSnapshotRequest } from './ShiftSnapshot';

export const transformToShiftSnapshot = (shiftSnapShot: ShiftSnapshotResource): ShiftSnapshot => ({
    id: shiftSnapShot.id,
    period: transformToPeriod(new Date(shiftSnapShot.attributes.start), new Date(shiftSnapShot.attributes.end)),
    departmentId: shiftSnapShot.attributes.department,
    peopleLimit: shiftSnapShot.attributes.peopleLimit,
    shiftPlanningSnapshots: shiftSnapShot.attributes.shiftPlanningSnapshots.map(transformToShiftPlanningSnapshot),
});

export const transformToPatchShiftsSnapshotRequest = (shiftSnapshot: ShiftSnapshot): ShiftsSnapshotRequest => ({
    type: 'shiftSnapshots',
    id: shiftSnapshot.id,
    attributes: {
        peopleLimit: shiftSnapshot.peopleLimit,
        department: shiftSnapshot.departmentId,
        start: formatDate(shiftSnapshot.period.start, DateFormat.jsonApiResource),
        end: formatDate(shiftSnapshot.period.end, DateFormat.jsonApiResource),
        shiftPlanningSnapshots: shiftSnapshot.shiftPlanningSnapshots.map(transformToShiftPlanningSnapshotResource),
    },
});
