import { FC } from 'react';

import trans from '../../helpers/trans';
import { BackgroundWooshImage } from './subcomponents';

import './DashboardWelcomeCard.scss';

interface DashboardWelcomeCardProps {
    userFullName?: string;
    className?: string;
}

const DashboardWelcomeCard: FC<DashboardWelcomeCardProps> = ({ userFullName, className }) => (
    <div className={`dashboard-welcome-card ${className}`}>
        <h2 className="dashboard-welcome-card__title">
            {`${trans('components.dashboardWelcomeCard.welcome')}, ${userFullName}`}
        </h2>
        <BackgroundWooshImage className="dashboard-welcome-card__image" />
    </div>
);

export default DashboardWelcomeCard;
