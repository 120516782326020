import React, { FC, ReactElement, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { useTypedDispatch, useTypedSelector } from '../../../@paco/redux/store';
import { PzLeaveType } from '../../../@paco/types/leaveType';
import { CompletePeriodIncompleteLeaveOfAbsencesContainer } from '../../../containers';
import {
    LeaveOfAbsence,
    LeaveOfAbsenceEditType,
    LeaveOfAbsenceFormData,
    LeaveType,
    PayrollPeriodWithHours,
    WeekWithHours,
} from '../../../models';
import { fetchIncompleteLeaveOfAbsences } from '../../../redux/@toolkit/leaveOfAbsences/incompleteLeaveOfAbsences/incompleteLeaveOfAbsencesActions';
import { editLeaveOfAbsence } from '../../../redux/@toolkit/leaveOfAbsences/leaveOfAbsenceActions';
import { Reducers } from '../../../redux/reducers';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../../services/PayrollPeriodService/transformPayrollPeriodLegacy';

interface ConnectedCompletePeriodIncompleteLeaveOfAbsencesProps {
    activePeriod?: PayrollPeriod;
    className?: string;
}

const ConnectedCompletePeriodIncompleteLeaveOfAbsences: FC<ConnectedCompletePeriodIncompleteLeaveOfAbsencesProps> = ({ activePeriod, className }): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoadingIncompleteLeaveOfAbsences = useSelector<Reducers, boolean>(state => state.incompleteLeaveOfAbsencesReducer.isLoading);
    const isLoadingLeaveOfAbsence = useSelector<Reducers, boolean>(state => state.leaveOfAbsenceReducer.isLoading);
    const isSuccessful = useSelector<Reducers, boolean>(state => state.leaveOfAbsenceReducer.isSuccessful);
    const leaveOfAbsences = useSelector<Reducers, LeaveOfAbsence[]>(state => state.incompleteLeaveOfAbsencesReducer.incompleteLeaveOfAbsences);
    const { role: currentUserRole, permissions } = useTypedSelector(state => state.authenticatedUserReducer);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);

    const handleSubmitLeaveOfAbsence = (
        type: LeaveOfAbsenceEditType,
        data: LeaveOfAbsenceFormData,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        leaveOfAbsence: LeaveOfAbsence,
        leaveType?: LeaveType | PzLeaveType,
        declineComment?: string,
    ): void => {
        if (activePeriod) {
            dispatch(editLeaveOfAbsence(
                type,
                data,
                payrollPeriodsWithHours,
                weeksWithHours,
                leaveOfAbsence,
                leaveType,
                declineComment,
            ));
        }
    };

    useEffect((): void => {
        if (activePeriod && isSuccessful) {
            const legacyPayrollPeriod = transformPayrollPeriodToLegacyPayrollPeriod(activePeriod);

            dispatch(fetchIncompleteLeaveOfAbsences(legacyPayrollPeriod));
        }
    }, [isSuccessful]);

    return (
        <CompletePeriodIncompleteLeaveOfAbsencesContainer
            isLoading={isLoadingIncompleteLeaveOfAbsences || isLoadingLeaveOfAbsence}
            leaveOfAbsences={leaveOfAbsences}
            permissions={permissions}
            currentUserRole={currentUserRole}
            pacoPayrollPeriods={payrollPeriods}
            onSubmitLeaveOfAbsence={handleSubmitLeaveOfAbsence}
            className={className}
        />
    );
};

export default ConnectedCompletePeriodIncompleteLeaveOfAbsences;
