import { FC } from 'react';

import { Button, InputLabel, PacoButtonLoadingSpinner } from '../../../components';
import { LoketUser } from '../../../entities/LoketUser/LoketUser';
import { getPersonFullName } from '../../../helpers';
import trans from '../../../helpers/trans';
import AddUserButton from './subcomponents/AddUserButton';

import './SelectUserFromLoketForm.scss';

interface SelectUserFromLoketFormProps {
    isLoading: boolean;
    loketUsers: LoketUser[];
    onLoketUserClick: (userId?: string) => void;
    onCancel: () => void;
}

const SelectUserFromLoketForm: FC<SelectUserFromLoketFormProps> = ({
    isLoading,
    loketUsers,
    onLoketUserClick,
    onCancel,
}) => (
    <form className="select-user-from-loket-form">
        <div className="select-user-from-loket-form__row">
            <div className="select-user-from-loket-form__col">
                <InputLabel
                    text={trans('containers.forms.selectUserFromLoketForm.addUserManuallyLabel')}
                />
                <AddUserButton
                    labelText={trans('containers.forms.selectUserFromLoketForm.addUserManuallyButtonText')}
                    onClick={onLoketUserClick}
                />
            </div>
        </div>

        <div className="select-user-from-loket-form__row">
            <div className="select-user-from-loket-form__col">
                <InputLabel
                    text={trans('containers.forms.selectUserFromLoketForm.addUserFromLoketLabel')}
                    className="select-user-from-loket-form__input-label"
                />

                {isLoading && (
                    <div className="select-user-from-loket-form__loading-spinner-wrapper">
                        <PacoButtonLoadingSpinner />
                    </div>
                )}

                {!isLoading && loketUsers.length > 0 && (
                    <ul className="select-user-from-loket-form__list">
                        {loketUsers.map(user => (
                            <li key={user.id} className="select-user-from-loket-form__list-item">
                                <AddUserButton
                                    userId={user.id}
                                    labelText={getPersonFullName(user.person)}
                                    onClick={onLoketUserClick}
                                />
                            </li>
                        ))}
                    </ul>
                )}

                {!isLoading && !loketUsers.length && (
                    <p>{trans('containers.forms.selectUserFromLoketForm.noLoketUsersAvailable')}</p>
                )}
            </div>
        </div>

        <div className="select-user-from-loket-form__form-button-wrapper">
            <Button
                text={trans('compositions.modalContent.cancel')}
                onClick={onCancel}
                className="select-user-from-loket-form__cancel-button"
            />
        </div>
    </form>
);

export default SelectUserFromLoketForm;
