import { BaseScheduleIndexViewModel } from '../../../../../entities/BaseSchedule/BaseSchedule';
import { PublishBaseScheduleFormData } from '../../../../../entities/ShiftConcept/ShiftConcept';

const generateEmptyPublishBaseScheduleFormDataValues = (
    startDate: Date,
    endDate: Date,
    baseSchedules: BaseScheduleIndexViewModel[] = [],
): PublishBaseScheduleFormData => ({
    startDate,
    endDate,
    baseScheduleId: baseSchedules.length ? baseSchedules[0].id : '',
});

export default generateEmptyPublishBaseScheduleFormDataValues;
