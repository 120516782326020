import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import serialize from 'form-serialize';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    Button,
    CustomInput,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Toast,
    ToastBody,
    ToastHeader,
} from 'reactstrap';

import { LoginLayout } from '../../@paco/containers';
import Icon from '../../components/style/Icon/Icon';
import { translate } from '../../helpers/translations/translator';
import { submitUserPasswordPincode } from '../../redux/authentication/authenticationActions';
import { Reducers } from '../../redux/reducers';

import './Login.scss';

const passwordRegEx = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/;

const ForgotPassword: FC = (): ReactElement => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [passwordCheck, setPasswordCheck] = useState(true);
    const [passwordRepeatCheck, setPasswordRepeatCheck] = useState(true);
    const [pincodeCheck, setPincodeCheck] = useState(true);
    const [pincodeRepeatCheck, setPincodeRepeatCheck] = useState(true);
    const [data, setData] = useState<any>();
    const [canSubmit, setCanSubmit] = useState(false);

    const userError = useSelector((state: Reducers) => state.userReducer?.error);
    const newPasswordSuccess = useSelector((state: Reducers) => state.authenticationReducer.newPasswordSuccess);
    const loading = useSelector((state: Reducers) => state.authenticationReducer.loading);
    const invalidToken = userError?.status === 401;

    useEffect(() => {
        if (!data) {
            return;
        }

        const {
            password,
            passwordRepeat,
            pincode,
            pincodeRepeat,
        } = data;

        setCanSubmit((password && passwordRepeat) || (pincode && pincodeRepeat));
    }, [data]);

    const onChange = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = serialize(e.currentTarget, { hash: true });
        const { password, passwordRepeat } = formData;

        setData(formData);
        setCanSubmit(!!password && !!passwordRepeat);
    };

    const onSubmit = (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const {
            password,
            passwordRepeat,
            pincode,
            pincodeRepeat,
        } = data;

        const newPasswordCheck = !password || (password.search(passwordRegEx) === 0);
        const newPasswordRepeatCheck = password === passwordRepeat;
        const newPincodeCheck = !pincode || (pincode.match(/^[0-9]+$/) != null && pincode.length === 4);
        const newPincodeRepeatCheck = pincode === pincodeRepeat;
        const userId = searchParams.get('user_uuid');
        const token = searchParams.get('api_token');

        setPasswordCheck(newPasswordCheck);
        setPasswordRepeatCheck(newPasswordRepeatCheck);
        setPincodeCheck(newPincodeCheck);
        setPincodeRepeatCheck(newPincodeRepeatCheck);

        if (newPasswordCheck && newPasswordRepeatCheck && newPincodeCheck && newPincodeRepeatCheck && token) {
            localStorage.setItem('apiToken', token);

            dispatch(submitUserPasswordPincode(userId, password, pincode));
        }
    };

    return (
        <div className={`login ${invalidToken ? 'login--invalid-token' : ''}`}>
            { invalidToken && (
                <>
                    <div className="login-toast-error">
                        <Toast isOpen>
                            <ToastHeader className="toast-header--fail login-toast-error-header">
                                <Icon kind="warning" />
                            </ToastHeader>
                            <ToastBody>
                                {translate('pages.login.expiredLink')}
                            </ToastBody>
                        </Toast>
                    </div>
                    <div className="login-cover" />
                </>
            ) }
            <LoginLayout>
                <div className="login-container-form">
                    <h4 className="login-title font-weight-semi-bold">
                        {translate('pages.login.setPassword')}
                    </h4>
                    <p>
                        {translate('pages.login.setPasswordIntro')}
                    </p>
                    <Form className="login-form" onChange={onChange} onSubmit={onSubmit}>
                        <FormGroup>
                            <Input
                                invalid={!passwordCheck}
                                type="password"
                                name="password"
                                id="password"
                                placeholder={translate('pages.login.password')}
                            />
                            <FormFeedback className="reset-password-form-requirements">
                                {translate('pages.login.passwordRequirements')}
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Input
                                invalid={!passwordRepeatCheck}
                                type="password"
                                name="passwordRepeat"
                                id="passwordRepeat"
                                placeholder={translate('pages.login.repeatPassword')}
                            />
                            <FormFeedback>
                                {translate('pages.login.passwordNotRepeated')}
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <CustomInput
                                invalid={!pincodeCheck}
                                type="password"
                                pattern="[0-9]*"
                                inputMode="numeric"
                                className="form-control"
                                id="pincode"
                                name="pincode"
                                placeholder={translate('common.pincode')}
                            />
                            <FormFeedback className="reset-password-form-requirements">
                                {translate('pages.login.pincodeRequirements')}
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <CustomInput
                                invalid={!pincodeRepeatCheck}
                                type="password"
                                pattern="[0-9]*"
                                inputMode="numeric"
                                className="form-control"
                                id="pincodeRepeat"
                                name="pincodeRepeat"
                                placeholder={translate('pages.login.confirmPincode')}
                            />
                            <FormFeedback>
                                {translate('pages.login.pincodeNotSame')}
                            </FormFeedback>
                        </FormGroup>
                        <div className="login-submit d-flex justify-content-end">
                            {!loading && !newPasswordSuccess && (
                                <Button type="submit" color="orange" disabled={!canSubmit}>
                                    {translate('common.save')}
                                </Button>
                            )}
                            {loading && (
                                <div className="login-spinner" />
                            )}
                            {newPasswordSuccess && (
                                <>
                                    <p className="login-paragraph-success">{translate('pages.login.passwordHasChanged')}</p>
                                    <Button tag="a" className="login-button-back" color="link" href={`/${translate('nav.login.link')}`}>
                                        {translate('pages.login.backToLogin')}
                                    </Button>
                                </>
                            )}
                        </div>
                    </Form>
                </div>
            </LoginLayout>
        </div>
    );
};

export default ForgotPassword;
