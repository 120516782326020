import { FC, ReactElement } from 'react';

import { useEffectOnce } from 'react-use';

// When you remove this unused import, you'll get an import error in ChangeLogWidget.tsx :)
// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
import { Reducers } from '../../../../redux/reducers';
import { DepartmentsFilterContainer } from '../../../containers';
import { fetchDepartmentFilterOptions } from '../../../redux/@interface/globalFilters/globalFiltersActions';
import { setDepartmentOptions } from '../../../redux/@interface/globalFilters/globalFiltersReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { FormOption } from '../../../types';

const ConnectedDepartmentsFilter: FC = (): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const departmentOptions = useTypedSelector(state => state.globalFiltersReducer.departmentOptions);
    const departmentOptionsIsLoading = useTypedSelector(state => state.globalFiltersReducer.departmentOptionsIsLoading);

    const handleOnChange = (options: FormOption[]) => {
        dispatch(setDepartmentOptions(options));
    };

    useEffectOnce(() => {
        if (departmentOptions.length === 0) dispatch(fetchDepartmentFilterOptions());
    });

    return !departmentOptionsIsLoading && departmentOptions.length > 0
        ? (
            <DepartmentsFilterContainer
                departmentOptions={departmentOptions}
                onChange={handleOnChange}
            />
        )
        : null;
};

export default ConnectedDepartmentsFilter;
