import { authorizedFetch } from '../../helpers/authorizedFetch';
import { formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../../japi/types/Document';
import { DateFormat } from '../../types/dateFormatTypes';
import { leaveOfAbsenceStatusNumber } from '../../types/leaveOfAbsenceTypes';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { UserResource } from '../User/User';
import { ShiftsCalendarLeaveOfAbsence, ShiftsCalendarLeaveOfAbsenceResource } from './ShiftsCalendarLeaveOfAbsence';
import { transformToShiftsCalendarLeaveOfAbsence } from './ShiftsCalendarLeaveOfAbsenceTransformers';

export const getShiftsCalendarLeaveOfAbsencesIndexCall = async (
    startDate: Date,
    endDate: Date,
): Promise<FetchCollectionResult<ShiftsCalendarLeaveOfAbsence[], string>> => {
    try {
        const url = generateApiUrl({
            endpoint: '/leave-of-absences',
            queryParams: {
                include: 'user',
                startDate: formatDate(startDate, DateFormat.apiDateTime),
                endDate: formatDate(endDate, DateFormat.apiDateTime),
                statuses: JSON.stringify([leaveOfAbsenceStatusNumber.open, leaveOfAbsenceStatusNumber.approved]),
            },
        });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<ShiftsCalendarLeaveOfAbsenceResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shiftsCalendarLeaveOfAbsences = doc.data.map(shiftsCalendarLeaveOfAbsenceResource => {
            const userResource = getIncluded(doc, shiftsCalendarLeaveOfAbsenceResource, 'user') as UserResource;

            return transformToShiftsCalendarLeaveOfAbsence(shiftsCalendarLeaveOfAbsenceResource, userResource);
        });

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: shiftsCalendarLeaveOfAbsences,
        };
    } catch (error) {
        console.error('[getShiftsCalendarLeaveOfAbsencesIndexCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
