import { AddDistrictDivisionGroupTemplateFormData, EditDistrictDivisionGroupTemplateFormData } from '../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import {
    deleteDistrictDivisionGroupTemplateApiCall,
    patchDistrictDivisionGroupTemplateApiCall,
    postDistrictDivisionGroupTemplateApiCall,
} from '../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplateService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { setDistrictDivisionTemplate } from '../districtDivisionTemplate/districtDivisionTemplateReducer';
import { Reducers } from '../reducers';
import { TypedDispatch } from '../store';
import {
    setDistrictDivisionGroupTemplate,
    setError,
    setIsLoading,
    setIsSuccessful,
} from './districtDivisionGroupTemplateReducer';

export const addDistrictDivisionGroupTemplate = (
    addDistrictDivisionGroupTemplateFormData: AddDistrictDivisionGroupTemplateFormData,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await postDistrictDivisionGroupTemplateApiCall(addDistrictDivisionGroupTemplateFormData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDistrictDivisionGroupTemplate(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addDistrictDivisionGroupTemplate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editDistrictDivisionGroupTemplate = (
    editDistrictDivisionGroupTemplateFormData: EditDistrictDivisionGroupTemplateFormData,
) => async (dispatch: TypedDispatch, getState: () => Reducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const { districtDivisionTemplateReducer } = getState();
        const response = await patchDistrictDivisionGroupTemplateApiCall(editDistrictDivisionGroupTemplateFormData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const { districtDivisionTemplate } = districtDivisionTemplateReducer;
        const districtDivisionGroupTemplates = districtDivisionTemplate?.districtDivisionGroupTemplates;

        if (districtDivisionGroupTemplates) {
            const filteredDistrictDivisionGroupTemplates = districtDivisionGroupTemplates.filter(districtDivisionGroupTemplate => districtDivisionGroupTemplate.id !== response.data.id);
            const indexOfDistrictDivisionGroupTemplate = districtDivisionGroupTemplates.map(districtDivisionGroupTemplate => districtDivisionGroupTemplate.id).indexOf(response.data.id);

            const newDistrictDivisionTemplate = {
                ...districtDivisionTemplate,
                districtDivisionGroupTemplates: [
                    ...filteredDistrictDivisionGroupTemplates.slice(0, indexOfDistrictDivisionGroupTemplate),
                    response.data,
                    ...filteredDistrictDivisionGroupTemplates.slice(indexOfDistrictDivisionGroupTemplate)],
            };

            dispatch(setDistrictDivisionTemplate(newDistrictDivisionTemplate));
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editDistrictDivisionGroupTemplate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteDistrictDivisionGroupTemplate = (
    districtDivisionGroupTemplateId: string,
) => async (dispatch: TypedDispatch, getState: () => Reducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const { districtDivisionTemplateReducer } = getState();
        const response = await deleteDistrictDivisionGroupTemplateApiCall(districtDivisionGroupTemplateId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const { districtDivisionTemplate } = districtDivisionTemplateReducer;
        const districtDivisionGroupTemplates = districtDivisionTemplate?.districtDivisionGroupTemplates;

        if (districtDivisionGroupTemplates) {
            const filteredDistrictDivisionGroupTemplates = districtDivisionGroupTemplates.filter(districtDivisionGroupTemplate => districtDivisionGroupTemplate.id !== districtDivisionGroupTemplateId);

            const newDistrictDivisionTemplate = {
                ...districtDivisionTemplate,
                districtDivisionGroupTemplates: filteredDistrictDivisionGroupTemplates,
            };

            dispatch(setDistrictDivisionTemplate(newDistrictDivisionTemplate));
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteDistrictDivisionGroupTemplate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
