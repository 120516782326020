import { Dispatch } from 'redux';

import { BaseScheduleFormData } from '../../../entities/BaseSchedule/BaseSchedule';
import {
    getBaseScheduleApiCall,
    postBaseScheduleApiCall,
} from '../../../entities/BaseSchedule/BaseScheduleService';
import { transformToBaseScheduleFormDataToRequest } from '../../../entities/BaseSchedule/BaseScheduleTransformers';
import { setIsLoading as setIsBaseSchedulesLoading } from '../baseSchedules/baseSchedulesReducer';
import {
    setBaseSchedule,
    setError,
    setIsLoading,
    setIsSuccessful,
} from './baseScheduleReducer';

export const fetchBaseSchedule = (id: string) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getBaseScheduleApiCall(id);

        dispatch(setBaseSchedule(response));
    } catch (error) {
        console.error('[fetchBaseSchedule]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const createBaseSchedule = (formData: BaseScheduleFormData) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsBaseSchedulesLoading(true));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const request = transformToBaseScheduleFormDataToRequest(formData);

        const response = await postBaseScheduleApiCall(request);

        dispatch(setBaseSchedule(response));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[createBaseSchedule]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
