import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PreferToWorkTimeSlotViewModel } from '../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { ShiftConceptViewModelOld } from '../../../entities/ShiftConcept/ShiftConcept';
import { UnavailableToWorkTimeSlotViewModel } from '../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import {
    AbsenceViewModel,
    LeaveOfAbsenceViewModel,
    UserWithPersonViewModel,
} from '../../../models';
import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';

export type ShiftConceptState = AsyncReduxState<{
    isEditSuccessful: boolean;
    isDeleteSuccessful: boolean;
    isEditShiftPlanningSuccessful: boolean;
    isTemporaryWorkerLoading: boolean;
    isEditTemporaryWorkerSuccessful: boolean;
    shiftConcept: ShiftConceptViewModelOld | undefined;
    employees: UserWithPersonViewModel[];
    absences: AbsenceViewModel[];
    leaveOfAbsences: LeaveOfAbsenceViewModel[];
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlotViewModel[];
    preferToWorkTimeSlots: PreferToWorkTimeSlotViewModel[];
    isShiftPlanningLoading: boolean;
    isEmployeesLoading: boolean;
}>;

const initialState: ShiftConceptState = {
    ...initialAsyncReduxState,
    isEditSuccessful: false,
    isEditShiftPlanningSuccessful: false,
    isDeleteSuccessful: false,
    isTemporaryWorkerLoading: false,
    isEditTemporaryWorkerSuccessful: false,
    shiftConcept: undefined,
    employees: [],
    absences: [],
    leaveOfAbsences: [],
    unavailableToWorkTimeSlots: [],
    preferToWorkTimeSlots: [],
    isShiftPlanningLoading: false,
    isEmployeesLoading: false,
};

export const ShiftConceptSlice = createSlice({
    name: 'shiftConceptReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ShiftConceptState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setIsDeleteSuccessful(state, action: PayloadAction<boolean>): ShiftConceptState {
            return ({
                ...state,
                isDeleteSuccessful: action.payload,
            });
        },
        setIsEditSuccessful(state, action: PayloadAction<boolean>): ShiftConceptState {
            return ({
                ...state,
                isEditSuccessful: action.payload,
            });
        },
        setIsTemporaryWorkerLoading(state, action: PayloadAction<boolean>): ShiftConceptState {
            return ({
                ...state,
                isTemporaryWorkerLoading: action.payload,
            });
        },
        setIsEditTemporaryWorkerSuccessful(state, action: PayloadAction<boolean>): ShiftConceptState {
            return ({
                ...state,
                isEditTemporaryWorkerSuccessful: action.payload,
            });
        },
        setIsEditShiftPlanningSuccessful(state, action: PayloadAction<boolean>): ShiftConceptState {
            return ({
                ...state,
                isEditShiftPlanningSuccessful: action.payload,
            });
        },
        setIsShiftPlanningLoading(state, action: PayloadAction<boolean>): ShiftConceptState {
            return ({
                ...state,
                isShiftPlanningLoading: action.payload,
            });
        },
        setIsEmployeesLoading(state, action: PayloadAction<boolean>): ShiftConceptState {
            return ({
                ...state,
                isEmployeesLoading: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): ShiftConceptState {
            return ({
                ...state,
                error: action.payload,
            });
        },
        setShiftConcept(state, action: PayloadAction<ShiftConceptViewModelOld | undefined>): ShiftConceptState {
            return ({
                ...state,
                shiftConcept: action.payload,
            });
        },
        setEmployees(state, action: PayloadAction<UserWithPersonViewModel[]>): ShiftConceptState {
            return ({
                ...state,
                employees: action.payload,
            });
        },
        setAvailabilityData(state, action: PayloadAction<{
            absences: AbsenceViewModel[],
            leaveOfAbsences: LeaveOfAbsenceViewModel[],
            unavailableToWorkTimeSlots: UnavailableToWorkTimeSlotViewModel[],
            preferToWorkTimeSlots: PreferToWorkTimeSlotViewModel[],
        }>): ShiftConceptState {
            return ({
                ...state,
                absences: action.payload.absences,
                leaveOfAbsences: action.payload.leaveOfAbsences,
                unavailableToWorkTimeSlots: action.payload.unavailableToWorkTimeSlots,
                preferToWorkTimeSlots: action.payload.preferToWorkTimeSlots,
            });
        },
    },
});

export const {
    setShiftConcept,
    setEmployees,
    setError,
    setIsLoading,
    setIsShiftPlanningLoading,
    setIsTemporaryWorkerLoading,
    setIsEditTemporaryWorkerSuccessful,
    setIsDeleteSuccessful,
    setIsEmployeesLoading,
    setIsEditSuccessful,
    setIsEditShiftPlanningSuccessful,
    setAvailabilityData,
} = ShiftConceptSlice.actions;

export default ShiftConceptSlice.reducer;
