import React, { FC, ReactElement, useState } from 'react';

import { Modal } from 'reactstrap';

import { LoadingSpinner } from '../../../@paco/components';
import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { Permission } from '../../../@paco/entities/Permission/Permission';
import { AuthenticatedUser } from '../../../@paco/entities/User/User';
import { PzLeaveType } from '../../../@paco/types/leaveType';
import Card from '../../../components/Card/Card';
import LeaveOfAbsenceRequestCardContainer from '../../../components/LeaveOfAbsenceRequestCardsContainer/LeaveOfAbsenceRequestCardsContainer';
import { translate } from '../../../helpers/translations/translator';
import {
    LeaveOfAbsence,
    LeaveOfAbsenceEditType,
    LeaveOfAbsenceFormData,
    LeaveType,
    PayrollPeriodViewModel,
    PayrollPeriodWithHours,
    WeekWithHours,
} from '../../../models';
import DeclineLeaveOfAbsenceForm from '../../../pages/Absences/forms/DeclineLeaveOfAbsenceForm';
import ResolveLeaveOfAbsenceForm from '../../../pages/Absences/forms/ResolveLeaveOfAbsenceForm/ResolveLeaveOfAbsenceForm';

import './CompletePeriodOpenLeaveOfAbsencesContainer.scss';

interface CompletePeriodOpenLeaveOfAbsencesContainerProps {
    isLoading: boolean;
    currentUser?: AuthenticatedUser;
    leaveOfAbsences: LeaveOfAbsence[];
    permissions: Permission[];
    pacoPayrollPeriods: PayrollPeriod[];
    payrollPeriods: PayrollPeriodViewModel[];
    onSubmitLeaveOfAbsence: (
        type: LeaveOfAbsenceEditType,
        data: LeaveOfAbsenceFormData,
        payrollPeriodWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        leaveOfAbsence: LeaveOfAbsence,
        leaveType?: LeaveType | PzLeaveType,
        declineComment?: string,
    ) => void;
    className?: string;
}

interface DenyLoaData {
    data: LeaveOfAbsenceFormData;
    payrollPeriodsWithHours: PayrollPeriodWithHours[];
    weeksWithHours: WeekWithHours[];
}

const CompletePeriodOpenLeaveOfAbsencesContainer: FC<CompletePeriodOpenLeaveOfAbsencesContainerProps> = ({
    isLoading,
    permissions,
    currentUser,
    leaveOfAbsences,
    pacoPayrollPeriods,
    payrollPeriods,
    onSubmitLeaveOfAbsence,
    className = '',
}): ReactElement => {
    const [leaveOfAbsenceToEdit, setLeaveOfAbsenceToEdit] = useState<LeaveOfAbsence>();
    const [leaveOfAbsenceModalIsOpen, setLeaveOfAbsenceModalIsOpen] = useState(false);
    const [denyLoaData, setDenyLoaData] = useState<DenyLoaData>();

    const handleCloseEditLeaveOfAbsenceForm = (): void => {
        setLeaveOfAbsenceToEdit(undefined);
        setLeaveOfAbsenceModalIsOpen(false);
    };

    const handleCloseDeclineLeaveOfAbsenceForm = (): void => {
        setDenyLoaData(undefined);
    };

    const setDeclineLeaveOfAbsenceForm = (
        data: LeaveOfAbsenceFormData,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
    ): void => {
        setDenyLoaData({
            data,
            payrollPeriodsWithHours,
            weeksWithHours,
        });

        setLeaveOfAbsenceModalIsOpen(false);
    };

    const handleSubmitEditLeaveOfAbsence = (
        type: LeaveOfAbsenceEditType,
        data: LeaveOfAbsenceFormData,
        payrollPeriodWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        leaveType?: LeaveType | PzLeaveType | null,
    ): void => {
        if (type === LeaveOfAbsenceEditType.deny) {
            setDeclineLeaveOfAbsenceForm(data, payrollPeriodWithHours, weeksWithHours);
        } else if (leaveOfAbsenceToEdit) {
            onSubmitLeaveOfAbsence(
                type,
                data,
                payrollPeriodWithHours,
                weeksWithHours,
                leaveOfAbsenceToEdit,
                leaveType || undefined,
            );

            handleCloseEditLeaveOfAbsenceForm();
        }
    };

    const onSubmitDeclineLeaveOfAbsence = (declineComment: string): void => {
        if (denyLoaData && leaveOfAbsenceToEdit) {
            const { data, payrollPeriodsWithHours, weeksWithHours } = denyLoaData;

            onSubmitLeaveOfAbsence(
                LeaveOfAbsenceEditType.deny,
                data,
                payrollPeriodsWithHours,
                weeksWithHours,
                leaveOfAbsenceToEdit,
                undefined,
                declineComment,
            );
        }

        handleCloseDeclineLeaveOfAbsenceForm();
    };

    const handleSetLeaveOfAbsenceToEdit = (leaveOfAbsence: LeaveOfAbsence): void => {
        setLeaveOfAbsenceToEdit(leaveOfAbsence);
        setLeaveOfAbsenceModalIsOpen(true);
    };

    return (
        <div className={`complete-period-detail-open-leave-of-absences-container ${className}`}>
            <Card
                title={`${translate('pages.pz.openLeaveOfAbsences')} (${leaveOfAbsences.length})`}
                className="complete-period-detail-open-leave-of-absences-container__card"
            />

            {isLoading && (
                <LoadingSpinner isSmall className="complete-period-detail-open-leave-of-absences-container__loader" />
            )}

            {!isLoading && currentUser && leaveOfAbsences.length !== 0 && (
                <LeaveOfAbsenceRequestCardContainer
                    className="complete-period-detail-open-leave-of-absences-container__cards"
                    absences={leaveOfAbsences}
                    currentUser={currentUser}
                    payrollPeriods={pacoPayrollPeriods}
                    permissions={permissions}
                    onApprove={handleSetLeaveOfAbsenceToEdit}
                    onDecline={handleSetLeaveOfAbsenceToEdit}
                    onEditClick={handleSetLeaveOfAbsenceToEdit}
                />
            )}

            <Modal size="lg" isOpen={(leaveOfAbsenceModalIsOpen && !!leaveOfAbsenceToEdit)} className="form-edit-leave-of-absence">
                <ResolveLeaveOfAbsenceForm
                    absence={leaveOfAbsenceToEdit}
                    permissions={permissions}
                    currentUserRole={currentUser?.role}
                    pacoPayrollPeriods={pacoPayrollPeriods}
                    payrollPeriods={payrollPeriods}
                    onSubmit={handleSubmitEditLeaveOfAbsence}
                    onCancel={handleCloseEditLeaveOfAbsenceForm}
                />
            </Modal>

            <Modal size="lg" isOpen={!!denyLoaData} className="form-decline-leave-of-absence">
                <DeclineLeaveOfAbsenceForm
                    onSubmit={onSubmitDeclineLeaveOfAbsence}
                    onCancel={handleCloseDeclineLeaveOfAbsenceForm}
                />
            </Modal>
        </div>
    );
};

export default CompletePeriodOpenLeaveOfAbsencesContainer;
