import { PayrollPeriodFormData, SettingFormData } from '../../models';
import {
    SETTINGS_SAGA_DELETE_PERIOD,
    SETTINGS_SAGA_GET_SETTINGS,
    SETTINGS_SAGA_SET_PERIOD,
    SETTINGS_SAGA_SET_SETTINGS,
} from '../actionTypes';

export const setPeriod = (data: PayrollPeriodFormData, id?: string) => ({
    type: SETTINGS_SAGA_SET_PERIOD, data, id,
});

export const deletePeriod = (id: string) => ({ type: SETTINGS_SAGA_DELETE_PERIOD, id });
export const getSettings = () => ({ type: SETTINGS_SAGA_GET_SETTINGS });
export const setSettings = (settings: SettingFormData[]) => ({
    type: SETTINGS_SAGA_SET_SETTINGS, settings,
});
