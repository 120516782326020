import { FC } from 'react';

import classNames from 'classnames';

import { AbsenceBadge, Icon } from '../../../../components';
import { Absence } from '../../../../entities/Absence/Absence';
import { Department } from '../../../../entities/Department/Department';
import { getTimeOnlyLabel } from '../../../../entities/Period/PeriodHelpers';
import { ShiftPlanning } from '../../../../entities/ShiftPlanning/ShiftPlanning';

import './ShiftPlanningDraggable.scss';

export interface ShiftPlanningDraggableProps {
    isDisabled?: boolean;
    isUsed?: boolean;
    absence?: Absence;
    shiftPlanning: ShiftPlanning;
    department: Department;
    onDragStart?: (shiftPlanning: ShiftPlanning, departmentId: string) => void;
    onDragEnd?: (isDragging: boolean) => void;
    className?: string;
}

const ShiftPlanningDraggable: FC<ShiftPlanningDraggableProps> = ({
    isDisabled = false,
    isUsed = false,
    absence,
    shiftPlanning,
    department,
    onDragStart,
    onDragEnd,
    className = '',
}) => {
    const draggableClassName = classNames('shift-planning-draggable', {
        'shift-planning-draggable--is-disabled': isDisabled,
        'shift-planning-draggable--is-used': isUsed,
    }, className);

    const handleDragStart = (): void => {
        if (onDragStart) {
            onDragStart(shiftPlanning, department.id);
        }
    };

    const handleDragEnd = (): void => {
        if (onDragEnd) {
            onDragEnd(false);
        }
    };

    return (
        <div
            draggable={!isDisabled}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            className={draggableClassName}
        >
            <div className="shift-planning-draggable__employee-name">
                {shiftPlanning.user.fullName}
            </div>
            {absence && (
                <AbsenceBadge
                    isCompact
                    absence={absence}
                />
            )}
            <div className="shift-planning-draggable__department">
                {department.name}
            </div>
            <div className="shift-planning-draggable__shift-planning-time">
                {getTimeOnlyLabel(shiftPlanning.period.start, shiftPlanning.period.end)}
            </div>
            <Icon name="draggable" className="shift-planning-draggable__draggable-icon" />
        </div>
    );
};

export default ShiftPlanningDraggable;
