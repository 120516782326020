import { TimeModeType } from '../../../@paco/types';
import { getRangeFromDate, incrementFromDate } from '../../../helpers/date';
import { PayrollPeriodViewModel } from '../../../models';

export default function getIncrementDates(
    startDate: Date,
    endDate: Date,
    mode: TimeModeType,
    payrollPeriods: PayrollPeriodViewModel[],
    next: boolean,
): [Date, Date] {
    if (mode === TimeModeType.month || mode === TimeModeType.period || mode === TimeModeType.year) {
        const increment = incrementFromDate(startDate, mode, !next, payrollPeriods);
        const days = getRangeFromDate(increment, mode, payrollPeriods);
        return [days[0], days[days.length - 1]];
    }

    const start = incrementFromDate(startDate, mode, !next);
    const end = incrementFromDate(endDate, mode, !next);

    return [start, end];
}
