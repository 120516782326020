import { unique } from 'underscore';

import { LeaveOfAbsence } from '../../models';

export default function getLoketEmployeeIdsFromAbsences(absences: LeaveOfAbsence[]): string[] {
    const loketEmployeeIds = absences.reduce((total: string[], absence) => {
        if (absence.user && absence.user.loketEmployeeId) {
            return [...total, absence.user.loketEmployeeId];
        }

        return total;
    }, []);

    return unique(loketEmployeeIds);
}
