import { all, put, takeLatest } from 'redux-saga/effects';

import { transformAndSetSettings } from '../../@paco/redux/settings/settingsActions';
import { TOAST_TYPE_FAIL, TOAST_TYPE_PASS } from '../../constants';
import { getObjProperty } from '../../helpers';
import { delSettings, getSettings, putSettings } from '../../helpers/api/settingsApi';
import { delPayrollPeriod, patchPayrollPeriod, postPayrollPeriod } from '../../helpers/api-ts/payrollPeriod';
import { translate } from '../../helpers/translations/translator';
import {
    APP_SAGA_ADD_TOAST,
    SETTINGS_DELETE_PERIOD_FAILURE,
    SETTINGS_DELETE_PERIOD_REQUEST,
    SETTINGS_DELETE_PERIOD_SUCCESS,
    SETTINGS_GET_SETTINGS_FAILURE,
    SETTINGS_GET_SETTINGS_REQUEST,
    SETTINGS_GET_SETTINGS_SUCCESS,
    SETTINGS_SAGA_DELETE_PERIOD,
    SETTINGS_SAGA_GET_SETTINGS,
    SETTINGS_SAGA_SET_PERIOD,
    SETTINGS_SAGA_SET_SETTINGS,
    SETTINGS_SET_PERIOD_FAILURE,
    SETTINGS_SET_PERIOD_REQUEST,
    SETTINGS_SET_PERIOD_SUCCESS,
    SETTINGS_SET_SETTINGS_FAILURE,
    SETTINGS_SET_SETTINGS_REQUEST,
    SETTINGS_SET_SETTINGS_SUCCESS,
} from '../actionTypes';
// eslint-disable-next-line import/no-cycle
import { fetchPayrollPeriods } from '../app/appSaga';

function* setPeriod(action) {
    yield put({ type: SETTINGS_SET_PERIOD_REQUEST });

    try {
        const { data, id } = action;

        yield id ? patchPayrollPeriod(data, id) : postPayrollPeriod(data);
        yield fetchPayrollPeriods();
        yield put({ type: SETTINGS_SET_PERIOD_SUCCESS });

        const toast = translate(`pages.settings.${id ? 'editPeriodSuccess' : 'addPeriodSuccess'}`);
        yield put({ type: APP_SAGA_ADD_TOAST, toast, toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: APP_SAGA_ADD_TOAST, toast: getObjProperty(error, 'meta.message'), toastType: TOAST_TYPE_FAIL });
        yield put({ type: SETTINGS_SET_PERIOD_FAILURE });
    }
}

function* removePeriod(action) {
    yield put({ type: SETTINGS_DELETE_PERIOD_REQUEST });

    try {
        yield delPayrollPeriod(action.id);
        yield fetchPayrollPeriods();
        yield put({ type: SETTINGS_DELETE_PERIOD_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.settings.deletePeriodSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: SETTINGS_DELETE_PERIOD_FAILURE });
    }
}

export function* fetchSettings() {
    yield put({ type: SETTINGS_GET_SETTINGS_REQUEST });

    try {
        const response = yield getSettings();

        // Trigger a Redux Thunk action inside a saga
        yield put(transformAndSetSettings(response.data));

        const settings = response.data.map(setting => ({
            ...setting,
            departmentId: getObjProperty(setting, 'department.id') || undefined,
        }));
        yield put({ type: SETTINGS_GET_SETTINGS_SUCCESS, settings });
    } catch (error) {
        yield put({ type: SETTINGS_GET_SETTINGS_FAILURE });
    }
}

function* setSettings(action) {
    yield put({ type: SETTINGS_SET_SETTINGS_REQUEST });

    try {
        const putData = action.settings.filter(setting => !setting.delete);
        const deleteData = action.settings.filter(setting => !setting.new && setting.delete);

        if (putData.length) {
            yield putSettings(putData);
        }
        if (deleteData.length) {
            yield delSettings(deleteData);
        }

        yield all([
            yield fetchSettings(),
        ]);

        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.settings.updateSettingsSuccess'), toastType: TOAST_TYPE_PASS });
        yield put({ type: SETTINGS_SET_SETTINGS_SUCCESS });
    } catch (error) {
        yield put({ type: SETTINGS_SET_SETTINGS_FAILURE });
    }
}

export default function* settingsWatcher() {
    yield takeLatest(SETTINGS_SAGA_SET_PERIOD, setPeriod);
    yield takeLatest(SETTINGS_SAGA_DELETE_PERIOD, removePeriod);
    yield takeLatest(SETTINGS_SAGA_GET_SETTINGS, fetchSettings);
    yield takeLatest(SETTINGS_SAGA_SET_SETTINGS, setSettings);
}
