export const setIOSVersion = () => {
    const match = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
    let version;

    if (match !== undefined && match !== null) {
        version = [
            parseInt(match[1], 10),
            parseInt(match[2], 10),
            parseInt(match[3] || 0, 10),
        ];
        window.iOSVersion = parseFloat(version.join('.'));
    }
};

// This is safari / firefox fallback for e.path for touch / mouse events
export const generateEventPath = (currentElem) => {
    const path = [];
    while (currentElem) {
        path.push(currentElem);

        // Can't copy dom-related objects
        // eslint-disable-next-line no-param-reassign
        currentElem = currentElem.parentElement;
    }

    if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
        path.push(document);
    }

    if (path.indexOf(window) === -1) {
        path.push(window);
    }

    return path;
};

export const isTouch = () => 'ontouchstart' in document.documentElement;

export const hardRefresh = (goHome) => {
    if (caches) {
        caches.keys().then((names) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const name of names) caches.delete(name);
        });
    }
    if (goHome) {
        window.location.href = '/';
    } else {
        window.location.reload(true);
    }
};

export const generateDownloadURI = (uri, name) => {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
