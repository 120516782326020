import React, { FC, useMemo } from 'react';

import { capitalizeFirstLetter, getUserFullName } from '../../../../helpers';
import { formatDate } from '../../../../helpers/date';
import { ShiftPlanningViewModel, ShiftViewModel, UserViewModel } from '../../../../models';
import UserProfileInfo from '../../../UserProfileInfo/UserProfileInfo';

import './ExchangeShiftInfo.scss';


interface ExchangeShiftInfoProps {
    shift: ShiftViewModel;
    shiftPlanning: ShiftPlanningViewModel;
    user: UserViewModel;
}

const ExchangeShiftInfo: FC<ExchangeShiftInfoProps> = ({ shift, shiftPlanning, user }) => {
    const userFullName = useMemo(() => getUserFullName(user), [user]);
    const department = shift?.department?.name;
    const group = shift?.department?.group?.name;
    const experience = user?.experience?.name;
    const { end, start } = shiftPlanning;
    const startDate = useMemo(() => (start ? capitalizeFirstLetter(formatDate(start, 'EEEE d MMMM yyyy')) : ''), [start]);
    const startTime = useMemo(() => (start ? formatDate(start, 'H:mm') : ''), [start]);
    const endTime = useMemo(() => (end ? formatDate(end.transformToUpToButExcludingDate().date, 'H:mm') : ''), [end]);

    return (
        <div className="exc-shift-info">
            <UserProfileInfo
                className="exc-shift-info__user-info"
                group={group}
                date={start}
                experience={experience}
                name={userFullName}
                department={department}
                userId={user.id}
            />
            <div className="exc-shift-info__start-end-time">
                <div className="exc-shift-info__date">
                    {startDate}
                </div>
                <div className="exc-shift-info__time">
                    {`${startTime} - ${endTime}`}
                </div>
            </div>
        </div>
    );
};

export default ExchangeShiftInfo;
