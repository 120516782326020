import React, { FC } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { EmploymentType } from '../../../../models';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';


interface FilterProps {
    filter: AppFilter;
    onChange: (filter: AppFilter) => void;
    employmentTypes: EmploymentType[];
}

const UserTypesFilter: FC<FilterProps> = ({ filter, employmentTypes, onChange }) => {
    const setFilter = (id: string, checked: boolean) => {
        const employeeContractTypes = !checked ? (
            filter.employeeContractTypes.filter((type) => type !== id)
        ) : [
            ...filter.employeeContractTypes,
            id,
        ];

        onChange({
            ...filter,
            employeeContractTypes,
        });
    };

    const getChecked = (newId: string) => {
        const arrayLength = filter.employeeContractTypes
            .filter(id => id === newId).length;
        return arrayLength !== 0;
    };

    return (
        <FilterBlock title={translate('common.employeeType')}>
            {employmentTypes
                .filter(type => type)
                .map(type => (
                    <FormGroup check className={`p-0 user-type-${type.slug}`} key={type.id}>
                        <Label check>
                            <CustomInput
                                id={`${type}-checkbox-${type.id}`}
                                type="checkbox"
                                label={translate(`common.${type.slug}`)}
                                checked={getChecked(type.id)}
                                onChange={(e: React.MouseEvent<HTMLInputElement>) => setFilter(type.id, e.currentTarget.checked)}

                            />
                        </Label>
                    </FormGroup>
                ))}
        </FilterBlock>
    );
};

export default UserTypesFilter;
