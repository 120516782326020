import axios from 'axios';

import {
    ApiResponse,
    DocumentTypeViewModel,
    PayslipResource,
    UserDocumentViewModel,
} from '../../models';
import transformPayslipResourceToUserDocument from '../../services/UserDocument/transformPayslipResourceToUserDocument';
import { getLoketApiUrl } from '../index';

export async function getUserPayslips(
    employeeId: string,
    documentTypes: DocumentTypeViewModel[],
): Promise<UserDocumentViewModel[]> {
    try {
        const response = await axios
            .get(`${getLoketApiUrl()}/payslips/${employeeId}`) as unknown as ApiResponse<PayslipResource[]>;

        return response
            .data.map((payslip) => transformPayslipResourceToUserDocument(payslip, documentTypes));
    } catch (error) {
        return [];
    }
}
