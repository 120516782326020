import { generateEventPath } from '../../../helpers';

export default function didUserClickOutsideDayPicker(
    e: MouseEvent,
    dayPickerIsOpen: boolean,
): boolean {
    const paths = generateEventPath(e.target);
    const dayPicker = paths.filter(path => ((path.id === 'date-controls-toggle') || (path?.className === 'DayPicker')));

    if (!dayPicker.length && dayPickerIsOpen) {
        return true;
    }

    return false;
}
