import React, { FC } from 'react';

import { Button, Modal, ModalHeader } from 'reactstrap';

import { translate } from '../../helpers/translations/translator';
import { Log } from '../../models/Log';
import Logs from './Logs';

import './LogsModal.scss';


export interface LogsModalProps {
    loading?: boolean;
    isOpen: boolean;
    onClose: () => void;
    logs: Log[];
    title: string;
}

const LogsModal: FC<LogsModalProps> = ({
    isOpen,
    onClose,
    logs,
    title,
    loading = false,
}) => (
    <Modal
        className="logs-modal"
        size="xl"
        isOpen={isOpen}
    >
        <ModalHeader>
            {title}
            <Button
                className="logs-modal__button-close"
                id="modal-close"
                type="button"
                color="link"
                onClick={onClose}
            >
                {translate('common.close')}
            </Button>
        </ModalHeader>
        <Logs
            loading={loading}
            logs={logs || []}
        />
    </Modal>
);

export default LogsModal;
