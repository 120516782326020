import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { OnChangeValue } from 'react-select';
import { ModalFooter, ModalHeader } from 'reactstrap';

import { BaseScheduleShiftViewModel } from '../../../../entities/BaseScheduleShift/BaseScheduleShift';
import { DepartmentViewModel } from '../../../../entities/Departments/Departments';
import { ShiftConceptViewModelOld } from '../../../../entities/ShiftConcept/ShiftConcept';
import { generateGroupedDepartmentsSelectList, Option } from '../../../../helpers';
import { translate } from '../../../../helpers/translations/translator';
import { AddShiftFormData, ShiftViewModel } from '../../../../models';
import ShiftFormBody from '../AddShiftForm/components/ShiftFormBody/ShiftFormBody';
import generateAddShiftFormDataEmptyValues from '../AddShiftForm/helpers/generateAddShiftFormDataEmptyValues';
import validateFormData from '../AddShiftForm/helpers/validateFormData';
import { transformShiftToFormData } from './helpers/transformShiftToFormData';

import './EditShiftForm.scss';

interface EditShiftFormProps {
    title: string;
    data?: ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel;
    selectedDays?: Date[];
    departments?: DepartmentViewModel[];
    canDeleteShift: boolean;
    onDeleteButtonClick: () => void;
    onChange?: (data: AddShiftFormData) => void;
    className?: string;
    departmentSelectIsDisabled?: boolean;
}

const EditShiftForm: FC<EditShiftFormProps> = ({
    title,
    data,
    selectedDays,
    departments = [],
    canDeleteShift,
    onDeleteButtonClick,
    onChange,
    className = '',
    departmentSelectIsDisabled = false,
}) => {
    const [formData, setFormData] = useState<AddShiftFormData>(data ? transformShiftToFormData(data) : generateAddShiftFormDataEmptyValues(new Date()));
    const [selectedDepartment, setSelectedDepartment] = useState<Option | null>((data && data.department) ? { value: data.department.id, label: data.department.name } : null);

    const validationErrors = useMemo(() => validateFormData(formData), [formData]);
    const groupedDepartments = useMemo(
        () => generateGroupedDepartmentsSelectList(departments) || [],
        [departments],
    );

    const handleFormDataChange = (newFormData: AddShiftFormData) => {
        setFormData(newFormData);
    };

    const handleChangeDepartment = (value: OnChangeValue<Option, false>) => {
        setSelectedDepartment(value || null);
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    useEffect(() => {
        setFormData({
            ...formData,
            department: selectedDepartment?.value ? `${selectedDepartment.value}` : '',
        });
    }, [selectedDepartment]);

    useEffect(() => {
        if (onChange) {
            onChange(formData);
        }
    }, [formData]);

    return (
        <form className={`edit-shift-form ${className}`} onSubmit={handleFormSubmit}>
            <ModalHeader>
                {title}
            </ModalHeader>
            <ShiftFormBody
                departmentSelectIsDisabled={departmentSelectIsDisabled}
                groupedDepartments={groupedDepartments}
                selectedDepartment={selectedDepartment}
                selectedDays={selectedDays}
                formData={formData}
                onChange={handleFormDataChange}
                onDepartmentChange={handleChangeDepartment}
                validationErrors={validationErrors}
            />
            <ModalFooter className="edit-shift-form__footer">
                {canDeleteShift && (
                    <button
                        type="button"
                        className="edit-shift-form__delete-button"
                        onClick={onDeleteButtonClick}
                    >
                        {translate('pages.shifts.deleteShift')}
                    </button>
                )}
            </ModalFooter>
        </form>
    );
};

export default EditShiftForm;
