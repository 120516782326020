import { FC, useEffect } from 'react';

import AddDistrictDivisionTemplateForm from '../../../containers/@forms/AddDistrictDivisionTemplateForm/AddDistrictDivisionTemplateForm';
import { AddDistrictDivisionTemplateFormData } from '../../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import trans from '../../../helpers/trans';
import { addDistrictDivisionTemplate } from '../../../redux/districtDivisionTemplate/districtDivisionTemplateActions';
import { setIsSuccessful } from '../../../redux/districtDivisionTemplate/districtDivisionTemplateReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedAddDistrictDivisionTemplateFormProps {
    onClose: () => void;
}

const ConnectedAddDistrictDivisionTemplateForm: FC<ConnectedAddDistrictDivisionTemplateFormProps> = ({
    onClose,
}) => {
    const dispatch = useTypedDispatch();
    const { isLoading, isSuccessful } = useTypedSelector(state => state.districtDivisionTemplateReducer);

    const handleAddDistrictDivisionTemplate = (addDistrictDivisionTemplateFormData: AddDistrictDivisionTemplateFormData): void => {
        dispatch(addDistrictDivisionTemplate(addDistrictDivisionTemplateFormData));
    };

    useEffect(() => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.addDistrictDivisionTemplateForm.addDistrictDivisionTemplateSuccess'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isSuccessful]);

    return (
        <AddDistrictDivisionTemplateForm
            isLoading={isLoading}
            onSubmit={handleAddDistrictDivisionTemplate}
            onCancel={onClose}
        />
    );
};

export default ConnectedAddDistrictDivisionTemplateForm;
