import { FC, useEffect, useState } from 'react';

import { SearchInput } from '../../compositions';
import trans from '../../helpers/trans';

import './UserSearch.scss';

interface UserSearchProps {
    label?: string;
    search: string;
    onSearchChange: (search: string) => void;
    className?: string;
    inputClassName?: string;
    iconWrapperClassName?: string;
}

const debounceDelay = 250;

const UserSearch: FC<UserSearchProps> = ({
    label = trans('containers.userSearch.searchEmployee'),
    search,
    onSearchChange,
    className = '',
    inputClassName = '',
    iconWrapperClassName = '',
}) => {
    const [searchValue, setSearchValue] = useState<string>(search);

    useEffect((): () => void => {
        const timer = setTimeout((): void => {
            onSearchChange(searchValue);
        }, debounceDelay);

        return (): void => clearTimeout(timer);
    }, [searchValue]);

    return (
        <SearchInput
            hideLabel
            icon="magnifying-glass"
            label={label}
            placeholder={label}
            value={searchValue}
            onChange={setSearchValue}
            className={`user-search ${className}`}
            iconWrapperClassName={iconWrapperClassName}
            inputClassName={inputClassName}
        />
    );
};

export default UserSearch;
