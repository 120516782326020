import { FC, ReactElement, useMemo } from 'react';

import { DepartmentBadge } from '../../components';
import { Department } from '../../entities/Department/Department';
import { getUniqueArrayList } from '../../helpers/array';
import reduceDepartmentsToDepartmentGroupIndices from './helpers/reduceDepartmentsToDepartmentGroupIndices';
import { DepartmentGroupButtons } from './subcomponents';

import './DepartmentsList.scss';

interface DepartmentsListProps {
    departments: Department[];
    mainDepartment?: Department;
    className?: string;
}

const DepartmentsList: FC<DepartmentsListProps> = ({
    departments,
    mainDepartment,
    className = '',
}): ReactElement => {
    const allDepartments: Department[] = getUniqueArrayList<Department>([...departments, ...(mainDepartment ? [mainDepartment] : [])], 'id');

    const groupIndices = useMemo(() => (
        reduceDepartmentsToDepartmentGroupIndices(allDepartments, mainDepartment)
    ), [allDepartments, mainDepartment]);
    const ungroupedDepartments = allDepartments.filter(department => !department.departmentGroup);

    return (
        <div className={`departments-list ${className}`}>
            {groupIndices.map((group => (
                <DepartmentGroupButtons
                    key={group.id}
                    group={group}
                    mainDepartment={mainDepartment}
                />
            )))}

            {ungroupedDepartments.map(department => (
                <DepartmentBadge
                    isDeleted={!!department.deletedAt}
                    isMainDepartment={mainDepartment && (department.id === mainDepartment.id)}
                    key={department.id}
                    name={department.name}
                    className="departments-list__department-badge"
                />
            ))}
        </div>
    );
};

export default DepartmentsList;
