import React, { FC, useEffect, useState } from 'react';

import { Col, FormGroup, Label } from 'reactstrap';

import { compareAsc } from '../../../helpers/date';
import { translate } from '../../../helpers/translations/translator';
import FormDatePicker from '../../FormDatePicker/FormDatePicker';

interface Props {
    startDate?: Date;
    endDate?: Date;
    onChange: (startDate: Date, endDate: Date) => void;
}

export const SelectDateRange: FC<Props> = ({ onChange, ...props }) => {
    const [startDate, setStartDate] = useState(props.startDate || new Date());
    const [endDate, setEndDate] = useState(props.endDate || new Date());
    const dateError = compareAsc(startDate, endDate) === 1;

    useEffect(() => {
        onChange(startDate, endDate);
    }, [startDate, endDate]);

    const onChangeStartDate = (date: Date) => {
        setStartDate(date);
    };

    const onChangeEndDate = (date: Date) => {
        setEndDate(date);
    };

    return (
        <>
            <Col>
                <FormGroup>
                    <Label>{translate('pages.tracks.startDate')}</Label>
                    <FormDatePicker
                        selected={startDate}
                        onChange={onChangeStartDate}
                    />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label>{translate('pages.tracks.endDate')}</Label>
                    <FormDatePicker
                        selected={endDate}
                        invalid={dateError}
                        onChange={onChangeEndDate}
                        feedback={translate('pages.tracks.endTimeNeedsToBeLaterThanStartTime')}
                    />
                </FormGroup>
            </Col>
        </>
    );
};
