import { isFetchResultSuccessful } from '../../../entities/FetchResult';
import { EditShiftFormData } from '../../../entities/Shift/Shift';
import { editShiftApiCall } from '../../../entities/Shift/ShiftService';
import { ShiftSnapshot } from '../../../entities/ShiftSnapshot/ShiftSnapshot';
import { getShiftSnapshotApiCall, patchShiftsWithShiftSnapshot } from '../../../entities/ShiftSnapshot/ShiftSnapshotService';
import trans from '../../../helpers/trans';
import { TypedDispatch } from '../../store';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setShiftSnapshot,
} from './shiftSnapshotReducer';

export const getShiftSnapshot = (shiftId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setShiftSnapshot(undefined));

    try {
        const response = await getShiftSnapshotApiCall(shiftId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftSnapshot(response.data));
    } catch (error) {
        console.error('[fetchShiftSnapshot]', error);

        dispatch(setError(trans('errors.unknownError')));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const saveShiftSequence = (shiftSnapshot: ShiftSnapshot, formData: EditShiftFormData, shiftId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsSuccessful(false));

    try {
        await editShiftApiCall(formData, shiftId);
        const response = await patchShiftsWithShiftSnapshot(shiftSnapshot, shiftId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftSnapshot(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[saveShiftSequence]', error);

        dispatch(setError(trans('errors.unknownError')));
    } finally {
        dispatch(setIsLoading(false));
    }
};
