import { differenceInDays } from 'date-fns';

import { capitalizeFirstLetter } from '../../../helpers';
import { addDays, formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { DateFormat } from '../../types/dateFormatTypes';
import { Period } from '../Period/Period';
import { getDateLabelShort, hasDateFourHoursOrLess } from '../Period/PeriodHelpers';
import { ShiftsCalendarLeaveOfAbsence } from '../ShiftsCalendarLeaveOfAbsence/ShiftsCalendarLeaveOfAbsence';
import { LeaveOfAbsence } from './LeaveOfAbsence';

export const getLeaveOfAbsenceTooltipText = (loa: ShiftsCalendarLeaveOfAbsence | LeaveOfAbsence, isRequest?: boolean): string => {
    const { reason } = loa;
    const leaveOfAbsenceType = isRequest ? trans('entities.leaveOfAbsences.leaveOfAbsenceRequest') : trans('entities.leaveOfAbsences.leaveOfAbsence');
    const endDate = hasDateFourHoursOrLess(loa.period.end) ? addDays(loa.period.end, -1) : loa.period.end;
    const date = getDateLabelShort(loa.period.start, endDate).toLowerCase();

    return `${leaveOfAbsenceType}: ${date} (${reason})`;
};

export const getLeaveOfAbsenceDateLabel = (period: Period): string => {
    const startDate = formatDate(period.start, DateFormat.dateLabel);
    const endDate = formatDate(period.end, DateFormat.dateLabel);

    if (differenceInDays(period.end, period.start) === 0) {
        const time = `${formatDate(period.start, DateFormat.hoursMinutes)} - ${formatDate(period.end, DateFormat.hoursMinutes)}`;
        return `${capitalizeFirstLetter(startDate)} ${time}`;
    }

    return `${capitalizeFirstLetter(startDate)} ${trans('common.until')} ${endDate}`;
};
