import React, { FC, ReactElement, useState } from 'react';

import { Modal } from 'reactstrap';

import { LoadingSpinner } from '../../../@paco/components';
import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { Permission } from '../../../@paco/entities/Permission/Permission';
import { Role } from '../../../@paco/entities/Role/Role';
import AbsencesTable from '../../../components/AbsencesTable/AbsencesTable';
import Card from '../../../components/Card/Card';
import { translate } from '../../../helpers/translations/translator';
import { Absence, PayrollPeriodWithHours, WeekWithHours } from '../../../models';
import EditAbsenceForm from '../../../pages/Absences/forms/EditAbsenceForm/EditAbsenceForm';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../../services/PayrollPeriodService/transformPayrollPeriodLegacy';

import './CompletePeriodAbsencesContainer.scss';

interface CompletePeriodAbsencesContainerProps {
    isLoading: boolean;
    isPeriodLocked?: boolean;
    absences: Absence[];
    currentUserRole?: Role;
    payrollPeriods: PayrollPeriod[];
    permissions: Permission[];
    onSubmitAbsence: (
        absence: Absence,
        startDate: string,
        endDate: string | null,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        waitingDayHours: number,
    ) => void;
    className?: string;
}

const CompletePeriodAbsencesContainer: FC<CompletePeriodAbsencesContainerProps> = ({
    isLoading,
    isPeriodLocked = false,
    absences,
    currentUserRole,
    payrollPeriods,
    permissions,
    onSubmitAbsence,
    className = '',
}): ReactElement => {
    const [absenceToEdit, setAbsenceToEdit] = useState<Absence>();

    const handleAbsenceClick = (absence: Absence): void => {
        setAbsenceToEdit(absence);
    };

    const handleCloseEditAbsenceForm = (): void => {
        setAbsenceToEdit(undefined);
    };

    const handleSubmit = (
        absence: Absence,
        startDate: string,
        endDate: string | null,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        waitingDayHours: number,
    ): void => {
        onSubmitAbsence(
            absence,
            startDate,
            endDate,
            payrollPeriodsWithHours,
            weeksWithHours,
            waitingDayHours,
        );

        handleCloseEditAbsenceForm();
    };

    return (
        <div className={`complete-period-detail-absences-container ${className}`}>
            <Card
                title={`${translate('pages.pz.emptyAbsenceHours')} (${absences.length})`}
                className="complete-period-detail-absences-container__card"
            />

            {isLoading && (
                <LoadingSpinner isSmall className="complete-period-detail-absences-container__loader" />
            )}

            {!isLoading && currentUserRole && absences.length !== 0 && (
                <AbsencesTable
                    isPeriodLocked={isPeriodLocked}
                    absences={absences}
                    permissions={permissions}
                    currentUserRole={currentUserRole}
                    onAbsenceClick={handleAbsenceClick}
                    className="complete-period-detail-absences-container__table"
                />
            )}

            {!!(absenceToEdit && currentUserRole) && (
                <Modal size="lg" isOpen className="form-edit-absence">
                    <EditAbsenceForm
                        data={absenceToEdit}
                        permissions={permissions}
                        currentUserRole={currentUserRole}
                        pacoPayrollPeriods={payrollPeriods}
                        payrollPeriods={payrollPeriods.map(transformPayrollPeriodToLegacyPayrollPeriod)}
                        onSubmit={handleSubmit}
                        onCancel={handleCloseEditAbsenceForm}
                    />
                </Modal>
            )}
        </div>
    );
};

export default CompletePeriodAbsencesContainer;
