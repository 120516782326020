import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { FormOption } from '../../../types';

import './Radio.scss';

export type HTMLInputProps = JSX.IntrinsicElements['input'];

interface RadioProps extends HTMLInputProps {
    option: FormOption;
    name: string;
    selected: string;
    className?: string;
    boxClassName?: string;
    labelClassName?: string;
}

const Radio: FC<RadioProps> = ({
    option,
    selected,
    disabled,
    className = '',
    boxClassName = '',
    labelClassName = '',
    ...inputProps
}): ReactElement => {
    const radioClassNames = classNames('radio', {
        'radio--is-disabled': !!disabled,
        'radio--is-selected': option.value === selected,
        [`${className}--is-selected`]: className && option.value === selected,
    }, className);

    return (
        <label className={radioClassNames}>
            <input
                {...inputProps}
                type="radio"
                value={option.value}
                checked={option.value === selected}
                disabled={disabled}
                className="radio__input"
            />

            <div className={`radio__box ${boxClassName}`} />
            <span className={`radio__label ${labelClassName}`}>
                {option.label}
            </span>
        </label>
    );
};

export default Radio;
