import { transformToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import { PreferToWorkTimeSlot } from '../../@paco/entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import transformUserResource from '../../services/UserService/transformUserResource';
import { transformLegacyUserToUser, transformUserViewModelToUser } from '../User/UserTransformers';
import { PreferToWorkTimeSlotResource, PreferToWorkTimeSlotViewModel } from './PreferToWorkTimeSlot';

export const transformPreferToWorkTimeSlot = (
    resource: PreferToWorkTimeSlotResource,
): PreferToWorkTimeSlotViewModel => ({
    type: 'PreferToWorkTimeSlots',
    id: resource.id,
    comment: resource.comment,
    createdAt: new Date(resource.createdAt),
    start: new Date(resource.start),
    end: new UpToButExcludingDate(resource.end).transformToUpToAndIncludingDate(),
    user: transformUserResource(resource.user),
});

export const transformLegacyPreferToWorkTimeSlotToPreferToWorkTimeSlot = (slot: PreferToWorkTimeSlotResource): PreferToWorkTimeSlot => ({
    id: slot.id,
    comment: slot.comment,
    createdAt: new Date(slot.createdAt),
    period: transformToPeriod(new Date(slot.start), new Date(slot.end)),
    user: transformLegacyUserToUser(slot.user),
});

export const transformPreferToWorkTimeSlotViewModelToPreferToWorkTimeSlot = (slot: PreferToWorkTimeSlotViewModel): PreferToWorkTimeSlot => ({
    id: slot.id,
    comment: slot.comment,
    createdAt: slot.createdAt,
    period: transformToPeriod(slot.start, slot.end.date),
    user: transformUserViewModelToUser(slot.user),
});
