import { DepartmentGroupResource, DepartmentViewModel } from '../../../../entities/Departments/Departments';
import { Option } from '../../../../helpers';
import getDepartmentGroupSizeStatus from '../../SelectDepartment/helpers/getDepartmentGroupSizes';
import transformDepartmentGroupToSelectOption from '../../SelectDepartment/helpers/transformDepartmentGroupToSelectOption';
import transformDepartmentToSelectOption from '../../SelectDepartment/helpers/transformDepartmentToSelectOption';

export default function transformDepartmentsToSelectOptions(
    departments: DepartmentViewModel[],
    mainDepartment: DepartmentViewModel | null,
    allDepartments: DepartmentViewModel[],
): Option[] {
    if (!departments) {
        return [];
    }

    const groupSizes = getDepartmentGroupSizeStatus(departments, allDepartments, mainDepartment);
    const groups: DepartmentGroupResource[] = Object.values(groupSizes)
        .filter(value => value.isFull)
        .map(value => value.group);
    const groupOptions = groups
        .map((group) => transformDepartmentGroupToSelectOption(group));

    const departmentOptions = departments
        .map(department => {
            const groupSize = department.group ? groupSizes[department.group.id] : undefined;
            const hasFullGroup = groupSize ? groupSize.isFull : false;
            return transformDepartmentToSelectOption(department, hasFullGroup);
        })
        .filter(department => (department
            && (department.value !== (mainDepartment && mainDepartment.name))));

    return [...groupOptions, ...departmentOptions];
}
