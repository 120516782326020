import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { EventLog } from '../../entities/EventLog/EventLog';

export type EventLogsState = AsyncReduxState<{
    eventLogs: EventLog[];
}>;

const initialState: EventLogsState = {
    ...initialAsyncReduxState,
    eventLogs: [],
};

export const EventLogsSlice = createSlice({
    name: 'eventLogsReducer',
    initialState,
    reducers: {
        reset(): EventLogsState {
            return {
                ...initialState,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): EventLogsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): EventLogsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setEventLogs(state, action: PayloadAction<EventLog[]>): EventLogsState {
            return {
                ...state,
                eventLogs: action.payload,
            };
        },
    },
});

export const {
    reset,
    setIsLoading,
    setError,
    setEventLogs,
} = EventLogsSlice.actions;

export default EventLogsSlice.reducer;
