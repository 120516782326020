import {
    all,
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import {
    fetchAbsenceNotifications,
    fetchExchangeNotifications,
    fetchFeedbackNotifications,
    fetchLeaveOfAbsenceNotifications,
    fetchPayrollPeriodNotifications,
    fetchShiftNotifications,
    fetchTrackNotifications,
} from '../../@paco/redux/@interface/notifications/notificationsActions';
import { setIsLoading } from '../../@paco/redux/@interface/notifications/notificationsReducer';
import { fetchSettings as pacoFetchSettings } from '../../@paco/redux/settings/settingsActions';
import { SettingsSlice } from '../../@paco/redux/settings/settingsReducer';
import { clearStore } from '../../@paco/redux/store';
import { TOAST_TYPE_PASS } from '../../constants';
import { checkPermission, getObjProperty } from '../../helpers';
import { getEmploymentTypes, getRoles } from '../../helpers/api/appApi';
import { postNotifyDepartments, postNotifyEmployees } from '../../helpers/api/shiftsApi';
import { getPayrollPeriods } from '../../helpers/api-ts/payrollPeriod';
import { translate } from '../../helpers/translations/translator';
import {
    ABSENCES_APPROVE_EXCHANGE_REQUEST_SUCCESS,
    ABSENCES_EDIT_ABSENCE_SUCCESS,
    ABSENCES_HANDLE_LEAVE_OF_ABSENCE_SUCCESS,
    APP_ADD_TOAST,
    APP_GET_GENERAL_DATA,
    APP_GET_GENERAL_DATA_FAILURE,
    APP_GET_GENERAL_DATA_SUCCESS,
    APP_GET_NOTIFICATIONS_FAILURE,
    APP_GET_NOTIFICATIONS_REQUEST,
    APP_GET_PAYROLL_PERIODS_FAILURE,
    APP_GET_PAYROLL_PERIODS_REQUEST,
    APP_GET_PAYROLL_PERIODS_SUCCESS,
    APP_LOGOUT,
    APP_NOTIFY_EMPLOYEES_FAILURE,
    APP_NOTIFY_EMPLOYEES_REQUEST,
    APP_NOTIFY_EMPLOYEES_SUCCESS,
    APP_PUSH_HISTORY,
    APP_ROUTE_CHANGE,
    APP_SAGA_ADD_TOAST,
    APP_SAGA_GET_GENERAL_DATA,
    APP_SAGA_GET_HIGH_PRIO_NOTIFICATIONS_INTERVAL,
    APP_SAGA_GET_LOW_PRIO_NOTIFICATIONS_INTERVAL,
    APP_SAGA_GET_MED_PRIO_NOTIFICATIONS_INTERVAL,
    APP_SAGA_GET_PAYROLL_PERIODS,
    APP_SAGA_INIT,
    APP_SAGA_LOGOUT,
    APP_SAGA_NOTIFY_EMPLOYEES,
    APP_SAGA_PUSH_HISTORY,
    APP_SAGA_ROUTE_CHANGE,
    APP_SAGA_SET_ERROR,
    APP_SAGA_SET_LOADING,
    APP_SAGA_SET_NOTIFICATIONS_INTERVAL,
    APP_SAGA_SWITCH_MODE,
    APP_SET_ERROR,
    APP_SET_SWITCH_MODE,
    PAGINATION_SET_PAGINATION,
    SET_LOADING,
    TRACK_REMOVE_SUCCESS,
} from '../actionTypes';
// eslint-disable-next-line import/no-cycle
import { getHighPrioNotifications, getLowPrioNotifications, getMedPrioNotifications } from './appActions';
import { getCurrentUserFromStore } from './appHelpers';

let fetchNotificationsHighPrioTimeoutId;
let fetchNotificationsMedPrioTimeoutId;
let fetchNotificationsLowPrioTimeoutId;

function clearFetchNotificationsHighPrioInterval() {
    clearTimeout(fetchNotificationsHighPrioTimeoutId);
    fetchNotificationsHighPrioTimeoutId = undefined;
}

function clearFetchNotificationsMedPrioInterval() {
    clearTimeout(fetchNotificationsMedPrioTimeoutId);
    fetchNotificationsMedPrioTimeoutId = undefined;
}

function clearFetchNotificationsLowPrioInterval() {
    clearTimeout(fetchNotificationsLowPrioTimeoutId);
    fetchNotificationsLowPrioTimeoutId = undefined;
}

function* fetchAbsenceNotificationsLegacy() {
    try {
        yield put(fetchAbsenceNotifications());
    } catch (error) {
        yield put({ type: APP_GET_NOTIFICATIONS_FAILURE });
        clearFetchNotificationsMedPrioInterval();
    }
}

function* fetchExchangeNotificationsLegacy() {
    try {
        yield put(fetchExchangeNotifications());
    } catch (error) {
        yield put({ type: APP_GET_NOTIFICATIONS_FAILURE });
        clearFetchNotificationsMedPrioInterval();
    }
}

function* fetchLeaveOfAbsenceNotificationsLegacy() {
    try {
        yield put(fetchLeaveOfAbsenceNotifications());
    } catch (error) {
        yield put({ type: APP_GET_NOTIFICATIONS_FAILURE });
        clearFetchNotificationsMedPrioInterval();
    }
}

function* fetchTrackNotificationsLegacy() {
    try {
        yield put(fetchTrackNotifications());
    } catch (error) {
        yield put({ type: APP_GET_NOTIFICATIONS_FAILURE });
        clearFetchNotificationsHighPrioInterval();
    }
}

function* fetchHighPrioNotifications() {
    yield put({ type: APP_GET_NOTIFICATIONS_REQUEST });

    try {
        if (fetchNotificationsHighPrioTimeoutId) {
            yield put(fetchTrackNotifications());
            yield put(fetchShiftNotifications());
        }
    } catch (error) {
        yield put({ type: APP_GET_NOTIFICATIONS_FAILURE });
    }
}

function* fetchMedPrioNotifications() {
    yield put({ type: APP_GET_NOTIFICATIONS_REQUEST });

    try {
        if (fetchNotificationsMedPrioTimeoutId) {
            yield put(fetchAbsenceNotifications());
            yield put(fetchExchangeNotifications());
            yield put(fetchLeaveOfAbsenceNotifications());
        }
    } catch (error) {
        yield put({ type: APP_GET_NOTIFICATIONS_FAILURE });
    }
}

function* fetchLowPrioNotifications() {
    yield put({ type: APP_GET_NOTIFICATIONS_REQUEST });

    try {
        if (fetchNotificationsLowPrioTimeoutId) {
            yield put(fetchFeedbackNotifications());
            yield put(fetchPayrollPeriodNotifications());
        }
    } catch (error) {
        yield put({ type: APP_GET_NOTIFICATIONS_FAILURE });
    }
}

function initFetchNotificationsInterval({ dispatch }) {
    if (!fetchNotificationsHighPrioTimeoutId) {
        // 5 minutes
        fetchNotificationsHighPrioTimeoutId = setInterval(() => dispatch(getHighPrioNotifications()), 300000);
    }

    if (!fetchNotificationsMedPrioTimeoutId) {
        // 30 minutes
        fetchNotificationsMedPrioTimeoutId = setInterval(() => dispatch(getMedPrioNotifications()), 1800000);
    }

    if (!fetchNotificationsLowPrioTimeoutId) {
        // 1 day
        fetchNotificationsLowPrioTimeoutId = setInterval(() => dispatch(getLowPrioNotifications()), 86400000);
    }
}

function* onInit() {
    const state = yield select();
    const { apiToken } = state.authenticationReducer;
    if (apiToken) {
        yield put(pacoFetchSettings());
    }
}

function* onFetchSettings() {
    yield put({ type: APP_SAGA_GET_GENERAL_DATA });
}

function* setLoading({ loading }) {
    yield put({ type: SET_LOADING, loading });
}

function* setAppError({ error }) {
    yield put({ type: APP_SET_ERROR, error });
}

function* addToast({ toastType, toast }) {
    yield put({ type: APP_ADD_TOAST, toastType, toast });
}

function* setSwitchMode() {
    yield put({ type: APP_SET_SWITCH_MODE });
}

export function* getGeneralData() {
    yield put({ type: APP_GET_GENERAL_DATA, loading: true });
    const { permissions } = yield getCurrentUserFromStore();

    const canUseManagerApp = checkPermission(permissions, 'use-manager-app', 'app-saga');
    if (!canUseManagerApp) {
        yield put({ type: APP_GET_GENERAL_DATA_FAILURE });
        yield put({ type: APP_SET_ERROR, error: { code: 403 } });
        return;
    }

    try {
        const canViewRoles = checkPermission(permissions, 'view-all-roles', 'app-saga');
        yield put(setIsLoading(true));

        const responses = yield all([
            getEmploymentTypes(),
            fetchHighPrioNotifications(),
            fetchMedPrioNotifications(),
            fetchLowPrioNotifications(),
            canViewRoles && getRoles(),
        ]);

        yield put(setIsLoading(false));

        yield put({
            type: APP_GET_GENERAL_DATA_SUCCESS,
            employmentTypes: responses[0].data,
            roles: responses[4].data,
        });
    } catch (error) {
        yield put({ type: APP_GET_GENERAL_DATA_FAILURE });
        yield put({ type: APP_SET_ERROR, error: { code: 403 } });
    }
}

export function* fetchPayrollPeriods() {
    yield put({ type: APP_GET_PAYROLL_PERIODS_REQUEST });

    try {
        const payrollPeriods = yield call(() => getPayrollPeriods());
        yield put({ type: APP_GET_PAYROLL_PERIODS_SUCCESS, payrollPeriods });
    } catch (error) {
        yield put({ type: APP_GET_PAYROLL_PERIODS_FAILURE });
    }
}

export function* pushHistory(action) {
    yield put({ type: APP_PUSH_HISTORY, location: action.location });
}

export function* setAppRouteChange() {
    yield put({ type: APP_ROUTE_CHANGE });
}

export function* setPagination(meta) {
    if (meta && meta.pagination) {
        yield put({
            type: PAGINATION_SET_PAGINATION,
            pages: meta.pagination.pages,
            size: meta.pagination.size,
        });
    }
}

export function* notifyEmployees(action) {
    yield put({ type: APP_NOTIFY_EMPLOYEES_REQUEST });
    try {
        const { data } = action;
        const length = getObjProperty(data, 'departments.length') || getObjProperty(data, 'employees.length');
        if (data.departments) {
            yield call(postNotifyDepartments, data);
        } else if (data.employees) {
            yield call(postNotifyEmployees, data);
        }
        yield put({ type: APP_NOTIFY_EMPLOYEES_SUCCESS });
        const translation = length > 1 ? 'sendNotificationsSuccess' : 'sendNotificationSuccess';
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate(`pages.shifts.${translation}`), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: APP_NOTIFY_EMPLOYEES_FAILURE });
    }
}

function* logout() {
    clearFetchNotificationsHighPrioInterval();
    clearFetchNotificationsMedPrioInterval();
    clearFetchNotificationsLowPrioInterval();
    // Trigger @paco/notifications reducer actions
    yield put(clearStore());
    yield put({ type: APP_LOGOUT });
}

export default function* appWatcher() {
    yield takeLatest(APP_SAGA_SET_LOADING, setLoading);
    yield takeLatest(APP_SAGA_SET_ERROR, setAppError);
    yield takeLatest(APP_SAGA_ADD_TOAST, addToast);
    yield takeLatest(APP_SAGA_INIT, onInit);
    yield takeLatest(APP_SAGA_GET_GENERAL_DATA, getGeneralData);
    yield takeLatest(TRACK_REMOVE_SUCCESS, fetchTrackNotificationsLegacy);
    yield takeLatest(ABSENCES_HANDLE_LEAVE_OF_ABSENCE_SUCCESS, fetchLeaveOfAbsenceNotificationsLegacy);
    yield takeLatest(ABSENCES_APPROVE_EXCHANGE_REQUEST_SUCCESS, fetchExchangeNotificationsLegacy);
    yield takeLatest(ABSENCES_EDIT_ABSENCE_SUCCESS, fetchAbsenceNotificationsLegacy);
    yield takeLatest(APP_SAGA_GET_HIGH_PRIO_NOTIFICATIONS_INTERVAL, fetchHighPrioNotifications);
    yield takeLatest(APP_SAGA_GET_MED_PRIO_NOTIFICATIONS_INTERVAL, fetchMedPrioNotifications);
    yield takeLatest(APP_SAGA_GET_LOW_PRIO_NOTIFICATIONS_INTERVAL, fetchLowPrioNotifications);
    yield takeLatest(APP_SAGA_SET_NOTIFICATIONS_INTERVAL, initFetchNotificationsInterval);
    yield takeLatest(APP_SAGA_GET_PAYROLL_PERIODS, fetchPayrollPeriods);
    yield takeLatest(APP_SAGA_PUSH_HISTORY, pushHistory);
    yield takeLatest(APP_SAGA_ROUTE_CHANGE, setAppRouteChange);
    yield takeLatest(APP_SAGA_NOTIFY_EMPLOYEES, notifyEmployees);
    yield takeLatest(APP_SAGA_SWITCH_MODE, setSwitchMode);
    yield takeLatest(SettingsSlice.actions.setSettings, onFetchSettings);
    yield takeLatest(APP_SAGA_LOGOUT, logout);
}
