import { EventLogActionType } from '../../types/eventLogTypes';

export interface EventLogChange {
    label: string;
    from: string;
    to: string;
}

export interface EventLogDataItem {
    label: string;
    value: string;
}

export enum EventLogEntityType {
    absence = 'App\\Entity\\Absence',
    absenceHour = 'App\\Entity\\AbsenceHour',
    comment = 'App\\Entity\\Comment',
    mail = 'App\\V3\\Helper\\EventLogger\\DTO\\MailEventLog',
    other = 'other',
    shift = 'App\\Entity\\Shift',
    track = 'App\\Entity\\Track',
    user = 'App\\Entity\\User',
}

export interface EventLog {
    id: string;
    action: EventLogActionType;
    categorySlug: string;
    changes: EventLogChange[];
    createdAt: Date;
    createdBy: string;
    data: EventLogDataItem[];
    entityType: EventLogEntityType;
    entityUuid: string;
    message: string;
    updatedAt: Date;
}

export interface EventLogChangeResource {
    from: string | { [key: string]: string };
    to: string | { [key: string]: string };
}

export type EventLogDataItemResource = string | { [key: string]: string };

export interface EventLogResource {
    uuid: string;
    action: string;
    category: string;
    changes: {
        [key: string]: EventLogChangeResource;
    } | null;
    createdAt: string;
    createdBy: string;
    data: { [key: string]: EventLogDataItemResource | null };
    entityType: string;
    entityUuid: string;
    message: string;
    updatedAt: string;
}
