import trans from '../../@paco/helpers/trans';

export const AbsencesRoutes = {
    exchangeRequests: () => trans('routes.absences.exchangeRequests.link'),
    leaveOfAbsenceRequests: () => trans('routes.absences.leaveOfAbsenceRequests.link'),
    absencesList: () => trans('routes.absences.absencesList.link'),
    leaveOfAbsencesCalendar: () => trans('routes.absences.leaveOfAbsencesCalendar.link'),
    leaveOfAbsencesOverview: () => trans('routes.absences.leaveOfAbsencesOverview.link'),
    absencesOverview: () => trans('routes.absences.absencesOverview.link'),
};
