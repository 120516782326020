import {
    CSSProperties,
    FC,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import classnames from 'classnames';
import { useToggle } from 'react-use';

import { RootPortal } from '../../components';
import {
    DistrictDivisionPlanner,
    ModalContent,
    PacoModal,
    PrintableDistrictDivision,
    ShiftPlanningSelector,
} from '../../compositions';
import { ConnectedHelpTooltip } from '../../connectors';
import ConnectedAddDistrictDivisionDistrictPlanningCommentForm
    from '../../connectors/@forms/ConnectedAddDistrictDivisionDistrictPlanningCommentForm/ConnectedAddDistrictDivisionDistrictPlanningCommentForm';
import { Absence } from '../../entities/Absence/Absence';
import { Department } from '../../entities/Department/Department';
import { DistrictDivision } from '../../entities/DistrictDivision/DistrictDivision';
import {
    AddDistrictDivisionDistrictPlanningFormData,
    DistrictDivisionDistrictPlanning,
    EditDistrictDivisionDistrictPlanningFormData,
    SwapDistrictDivisionDistrictPlanningsFormData,
} from '../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { DistrictDivisionTemplate } from '../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import { Setting } from '../../entities/Setting/Setting';
import { ShiftIndex } from '../../entities/Shift/Shift';
import { ShiftPlanning } from '../../entities/ShiftPlanning/ShiftPlanning';
import useCheckPermission from '../../helpers/hooks/useCheckPermission';
import useGetHeightAndOffset from '../../helpers/hooks/useGetHeightAndOffset';
import trans from '../../helpers/trans';
import { reduceDistrictDivisionToPlannings, transformDistrictDivisionPlanningsToFormData } from './helpers';
import { DistrictDivisionEmptyState, DistrictDivisionHeader } from './subcomponents';

import './DistrictDivisionContainer.scss';

interface DistrictDivisionContainerProps {
    isAddDistrictDivisionLoading: boolean;
    isAddDistrictDivisionCommentLoading: boolean;
    isAddDistrictDivisionCommentSuccessful: boolean;
    isDepartmentsLoading: boolean;
    isDistrictDivisionLoading: boolean;
    isDistrictDivisionTemplateLoading: boolean;
    isShiftsLoading: boolean;
    absences: Absence[];
    activeDistrictDivision?: DistrictDivision;
    departmentOptions: Department[];
    districtDivisions: DistrictDivision[];
    districtDivisionTemplates: DistrictDivisionTemplate[];
    selectedDate: Date;
    selectedDepartments: Department[];
    settings: Setting[];
    shifts: ShiftIndex[];
    onBackButtonClick: () => void;
    onClearDistrictDivisionButtonClick: () => void;
    onDateChange: (date: Date) => void;
    onDateIncrementClick: (goForward: boolean, isMobile?: boolean) => void;
    onDepartmentChange: (departments: Department[]) => void;
    onDistrictDivisionDistrictPlanningDeleteButtonClick: (districtDivisionDistrictPlanningId: string, type: string) => void;
    onSelectDistrictDivision: (districtDivision: DistrictDivision) => void;
    onSelectDistrictDivisionTemplate: (template: DistrictDivisionTemplate) => void;
    onSaveDistrictDivisionButtonClick: (comment: string) => void;
    onSubmitAddDistrictDivisionDistrictPlanning: (formData: AddDistrictDivisionDistrictPlanningFormData) => void;
    onSubmitEditDistrictDivisionDistrictPlanningOrder: (formData: EditDistrictDivisionDistrictPlanningFormData) => void;
    onSubmitSwapDistrictDivisionDistrictPlanningsOrder: (formData: SwapDistrictDivisionDistrictPlanningsFormData) => void;
}

const DistrictDivisionContainer: FC<DistrictDivisionContainerProps> = ({
    isAddDistrictDivisionLoading,
    isAddDistrictDivisionCommentLoading,
    isAddDistrictDivisionCommentSuccessful,
    isDepartmentsLoading,
    isDistrictDivisionLoading,
    isDistrictDivisionTemplateLoading,
    isShiftsLoading,
    absences,
    activeDistrictDivision,
    departmentOptions,
    districtDivisions,
    districtDivisionTemplates,
    selectedDate,
    selectedDepartments,
    settings,
    shifts,
    onBackButtonClick,
    onClearDistrictDivisionButtonClick,
    onDateChange,
    onDateIncrementClick,
    onDepartmentChange,
    onDistrictDivisionDistrictPlanningDeleteButtonClick,
    onSelectDistrictDivision,
    onSelectDistrictDivisionTemplate,
    onSaveDistrictDivisionButtonClick,
    onSubmitAddDistrictDivisionDistrictPlanning,
    onSubmitEditDistrictDivisionDistrictPlanningOrder,
    onSubmitSwapDistrictDivisionDistrictPlanningsOrder,
}) => {
    const [activeShiftPlanning, setActiveShiftPlanning] = useState<ShiftPlanning>();
    const [activeDistrictDivisionDistrictPlanning, setActiveDistrictDivisionDistrictPlanning] = useState<DistrictDivisionDistrictPlanning>();
    const [addDistrictDivisionDistrictPlanningCommentModalIsOpen, toggleAddDistrictDivisionDistrictPlanningCommentModalIsOpen] = useToggle(false);

    const [activeDepartmentId, setActiveDepartmentId] = useState<string>();
    const [districtDivisionComment, setDistrictDivisionComment] = useState<string | undefined>(activeDistrictDivision?.comments[0]?.body);
    const [isDraggingDistrictDivisionDistrictPlanning, setIsDraggingDistrictDivisionDistrictPlanning] = useState<boolean>(false);
    const [isDraggingShiftPlanning, setIsDraggingShiftPlanning] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const shiftPlanningRef = useRef<HTMLDivElement>(null);
    const { shiftPlanningSelectorTop, pxOffset, elementScrolledPastElement } = useGetHeightAndOffset(containerRef, shiftPlanningRef);

    const canViewAllShifts = useCheckPermission('view-all-shifts');
    const canAddDistrictDivisions = useCheckPermission('add-new-district-divisions');
    const canDeleteDistrictDivisions = useCheckPermission('delete-all-district-divisions');
    const canViewAllDistrictDivisions = useCheckPermission('view-all-district-divisions');
    const viewAllDistrictDivisionDistrictTemplates = useCheckPermission('view-all-district-division-district-templates');
    const canAddDistrictDivisionDistrictPlannings = useCheckPermission('add-new-district-division-district-plannings');
    const districtDivisionDistrictPlannings = useMemo(
        () => (
            activeDistrictDivision ? reduceDistrictDivisionToPlannings(activeDistrictDivision) : []),
        [activeDistrictDivision],
    );

    const classNames = classnames('district-division-container', {
        'district-division-container--hide-shift-plannings': !canViewAllShifts || !canAddDistrictDivisionDistrictPlannings,
        'district-division-container--scrolled-past-element': elementScrolledPastElement,
    });

    useEffect((): () => void => {
        document.body.classList.add('has-printable-page');

        return () => document.body.classList.remove('has-printable-page');
    }, []);

    useEffect((): void => {
        setDistrictDivisionComment(activeDistrictDivision?.comments[0]?.body || '');
    }, [activeDistrictDivision]);

    const handleDistrictDivisionCommentBlur = (): void => {
        onSaveDistrictDivisionButtonClick(districtDivisionComment || '');
    };

    const handleDistrictDivisionCommentChange = (comment?: string): void => {
        setDistrictDivisionComment(comment);
    };

    const handleDistrictDivisionDistrictPlanningAddCommentButtonClick = (districtDivisionDistrictPlanning: DistrictDivisionDistrictPlanning): void => {
        setActiveDistrictDivisionDistrictPlanning(districtDivisionDistrictPlanning);
        toggleAddDistrictDivisionDistrictPlanningCommentModalIsOpen();
    };

    const handleDistrictDivisionDistrictPlanningModalClose = (): void => {
        setActiveDistrictDivisionDistrictPlanning(undefined);
        toggleAddDistrictDivisionDistrictPlanningCommentModalIsOpen();
    };

    const handleDistrictDivisionDistrictPlanningDrop = (
        districtDivisionDistrictId: string,
        index: number,
        targetDistrictDivisionDistrictPlanning?: DistrictDivisionDistrictPlanning,
    ): void => {
        // ShiftPlanning is dropped on an empty slot
        if (activeShiftPlanning && activeDepartmentId) {
            const lastShiftPlanningComment = activeShiftPlanning.comments[activeShiftPlanning.comments.length - 1];
            // Copy last comment of shiftPlanning if it's not from own user: this will filter out employee comments.
            const comment = (lastShiftPlanningComment && lastShiftPlanningComment.owner?.id !== activeShiftPlanning.user.id) ? lastShiftPlanningComment : undefined;

            onSubmitAddDistrictDivisionDistrictPlanning({
                comment,
                departmentId: activeDepartmentId,
                districtDivisionDistrictId,
                index,
                period: activeShiftPlanning.period,
                userId: activeShiftPlanning.user.id,
                name: activeShiftPlanning.user.fullName,
                type: activeShiftPlanning.type,
            });

            return;
        }

        // DistrictDivisionDistrictPlanning is dropped on an empty slot
        if (activeDistrictDivisionDistrictPlanning && !targetDistrictDivisionDistrictPlanning) {
            onSubmitEditDistrictDivisionDistrictPlanningOrder({
                type: activeDistrictDivisionDistrictPlanning.type,
                districtDivisionDistrictId,
                planningId: activeDistrictDivisionDistrictPlanning.id,
                index,
            });

            return;
        }

        // DistrictDivisionDistrictPlanning is dropped on another DistrictDivisionDistrictPlanning
        if (
            activeDistrictDivisionDistrictPlanning
            && targetDistrictDivisionDistrictPlanning
            && targetDistrictDivisionDistrictPlanning.id !== activeDistrictDivisionDistrictPlanning.id
        ) {
            const formData = transformDistrictDivisionPlanningsToFormData(activeDistrictDivisionDistrictPlanning, targetDistrictDivisionDistrictPlanning);

            setActiveDistrictDivisionDistrictPlanning(undefined);
            onSubmitSwapDistrictDivisionDistrictPlanningsOrder(formData);
        }
    };

    const handleDistrictDivisionDistrictPlanningDragEnd = (): void => {
        setIsDraggingDistrictDivisionDistrictPlanning(false);
    };

    const handleDistrictDivisionDistrictPlanningDragStart = (districtDivisionDistrictPlanning: DistrictDivisionDistrictPlanning): void => {
        setIsDraggingDistrictDivisionDistrictPlanning(true);
        setActiveShiftPlanning(undefined);
        setActiveDistrictDivisionDistrictPlanning(districtDivisionDistrictPlanning);
    };

    const handleExportButtonClick = () => {
        window.print();
    };

    const handleShiftPlanningDragStart = (newShiftPlanning: ShiftPlanning, departmentId: string): void => {
        setActiveShiftPlanning(newShiftPlanning);
        setActiveDistrictDivisionDistrictPlanning(undefined);
        setActiveDepartmentId(departmentId);
        setIsDraggingShiftPlanning(true);
    };

    const handleShiftPlanningDragEnd = (isDragging: boolean): void => {
        setIsDraggingShiftPlanning(isDragging);
    };

    const cssVariables = {
        '--px-offset': `${pxOffset}`,
        '--shift-planning-selector-top': `${shiftPlanningSelectorTop}`,
    } as CSSProperties;

    return (
        <div className={classNames} ref={containerRef} style={cssVariables}>
            <ConnectedHelpTooltip
                index={0}
                route="district-division"
                subTitle={trans('help.districtDivision.districtDivisionOverview.title')}
                text={trans('help.districtDivision.districtDivisionOverview.text')}
                title={trans('help.districtDivision.title')}
                showMobileInfoWarning
                className="district-division-container__overview-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={1}
                route="district-division"
                subTitle={trans('help.districtDivision.districtDivisionDate.title')}
                text={trans('help.districtDivision.districtDivisionDate.text')}
                title={trans('help.districtDivision.title')}
                showMobileInfoWarning
                className="district-division-container__date-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={2}
                route="district-division"
                videoSrc="/help/district-division-employee-drop.webm"
                subTitle={trans('help.districtDivision.districtDivisionGroups.title')}
                text={trans('help.districtDivision.districtDivisionGroups.text')}
                title={trans('help.districtDivision.title')}
                showMobileInfoWarning
                className="district-division-container__groups-help-tooltip"
            />
            <DistrictDivisionHeader
                backButtonIsDisabled={isAddDistrictDivisionCommentLoading || isDistrictDivisionLoading}
                showClearDistrictDivisionButton={!!activeDistrictDivision && canDeleteDistrictDivisions}
                showBackButton={!!activeDistrictDivision}
                showExportButton={!!activeDistrictDivision}
                selectedDate={selectedDate}
                onBackButtonClick={onBackButtonClick}
                onDateChange={onDateChange}
                onClearDistrictDivisionButtonClick={onClearDistrictDivisionButtonClick}
                onExportButtonClick={handleExportButtonClick}
                onIncrementClick={onDateIncrementClick}
            />
            <div className="district-division-container__content">
                <div className="district-division-container__shift-planning-selector-wrapper" ref={shiftPlanningRef}>
                    <ShiftPlanningSelector
                        isDepartmentsLoading={isDepartmentsLoading}
                        isDisabled={!activeDistrictDivision}
                        isShiftsLoading={isShiftsLoading}
                        absences={absences}
                        departmentOptions={departmentOptions}
                        districtDivisionDistrictPlannings={districtDivisionDistrictPlannings}
                        selectedDepartments={selectedDepartments}
                        shifts={shifts}
                        onDepartmentChange={onDepartmentChange}
                        onShiftPlanningDragStart={handleShiftPlanningDragStart}
                        onShiftPlanningDragEnd={handleShiftPlanningDragEnd}
                        className="district-division-container__shift-planning-selector"
                    />
                </div>
                {activeDistrictDivision ? (
                    <DistrictDivisionPlanner
                        isLoading={isDistrictDivisionLoading}
                        isDraggingShiftPlanning={isDraggingShiftPlanning}
                        isDraggingDistrictDivisionDistrictPlanning={isDraggingDistrictDivisionDistrictPlanning}
                        isDistrictDivisionCommentLoading={isAddDistrictDivisionCommentLoading}
                        isDistrictDivisionCommentSuccessful={isAddDistrictDivisionCommentSuccessful}
                        districtDivision={activeDistrictDivision}
                        districtDivisionComment={districtDivisionComment}
                        settings={settings}
                        onDistrictDivisionCommentBlur={handleDistrictDivisionCommentBlur}
                        onDistrictDivisionCommentChange={handleDistrictDivisionCommentChange}
                        onDistrictDivisionDistrictPlanningAddCommentButtonClick={handleDistrictDivisionDistrictPlanningAddCommentButtonClick}
                        onDistrictDivisionDistrictPlanningDeleteButtonClick={onDistrictDivisionDistrictPlanningDeleteButtonClick}
                        onDistrictDivisionDistrictPlanningDragEnd={handleDistrictDivisionDistrictPlanningDragEnd}
                        onDistrictDivisionDistrictPlanningDragStart={handleDistrictDivisionDistrictPlanningDragStart}
                        onDistrictDivisionDistrictPlanningDrop={handleDistrictDivisionDistrictPlanningDrop}
                        className="district-division-container__planner"
                    />
                ) : (
                    <DistrictDivisionEmptyState
                        isAddDistrictDivisionLoading={isAddDistrictDivisionLoading}
                        isDistrictDivisionLoading={isDistrictDivisionLoading}
                        isDistrictDivisionTemplateLoading={isDistrictDivisionTemplateLoading}
                        showDistrictDivisionTemplatesLink={viewAllDistrictDivisionDistrictTemplates}
                        showDistrictDivisionSelector={canViewAllDistrictDivisions}
                        showTemplateSelector={canAddDistrictDivisions && viewAllDistrictDivisionDistrictTemplates}
                        date={selectedDate}
                        districtDivisions={districtDivisions}
                        districtDivisionTemplates={districtDivisionTemplates}
                        onSelectDistrictDivision={onSelectDistrictDivision}
                        onSelectDistrictDivisionTemplate={onSelectDistrictDivisionTemplate}
                        className="district-division-container__empty-state"
                    />
                )}
            </div>
            {addDistrictDivisionDistrictPlanningCommentModalIsOpen && activeDistrictDivisionDistrictPlanning && (
                <PacoModal>
                    <ModalContent title={trans('common.addComment')}>
                        <ConnectedAddDistrictDivisionDistrictPlanningCommentForm
                            districtDivisionDistrictPlanning={activeDistrictDivisionDistrictPlanning}
                            onClose={handleDistrictDivisionDistrictPlanningModalClose}
                        />
                    </ModalContent>
                </PacoModal>
            )}
            {(activeDistrictDivision && !isDistrictDivisionLoading) && (
                <RootPortal>
                    <PrintableDistrictDivision
                        districtDivision={activeDistrictDivision}
                        settings={settings}
                    />
                </RootPortal>
            )}
        </div>
    );
};

export default DistrictDivisionContainer;
