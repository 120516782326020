import { Period } from '../Period/Period';

export interface PublishBatchResultResource {
    uuid: string;
    time: string;
    object: 'App\\Entity\\Shift' | 'App\\Entity\\ShiftConceptPlanning';
    message: string;
    status: 'success' | 'warning';
    start: string;
    end: string;
}

export enum PublishBatchResultType {
    shift = 'shift',
    planning = 'planning',
}

export enum PublishBatchResultStatus {
    success = 'success',
    warning = 'warning',
}

export interface PublishBatchResult {
    id: string;
    createdAt: Date;
    message: string;
    period: Period;
    type: PublishBatchResultType;
    status: PublishBatchResultStatus;
}

// Example of a response with PublishBatchResultResource entities:
//
// [
//     [
//         {
//             "time": "2024-07-30T17:00:26+02:00",
//             "uuid": "cd742424-e8d9-47a7-b11f-753574e481fd",
//             "object": "App\\Entity\\Shift",
//             "message": "Created shift",
//             "status": "success",
//             "start": "2024-07-31T05:45:00+02:00",
//             "end": "2024-07-31T14:30:00+02:00"
//         },
//         {
//             "time": "2024-07-30T17:00:26+02:00",
//             "uuid": "54124810-642a-4d90-845a-c28a5ab302d0",
//             "object": "App\\Entity\\ShiftConceptPlanning",
//             "message": "Nellie Schmidt is al ingepland op dit tijdstip",
//             "status": "warning",
//             "start": "2024-07-31T06:00:00+02:00",
//             "end": "2024-07-31T14:30:00+02:00"
//         },
//         {
//             "time": "2024-07-30T17:00:26+02:00",
//             "uuid": "6c73dc2b-4ec8-4bb4-891a-0cc5861faca6",
//             "object": "App\\Entity\\ShiftConceptPlanning",
//             "message": "Geplande dienst voor Natasha Wentzel kon niet aangemaakt worden",
//             "status": "warning",
//             "start": "2024-07-31T07:00:00+02:00",
//             "end": "2024-07-31T12:00:00+02:00"
//         },
//     [
//         {
//             "time": "2024-07-30T17:00:26+02:00",
//             "uuid": "3b139ba6-f517-439c-b231-ba1ef1ace4cf",
//             "object": "App\\Entity\\Shift",
//             "message": "Created shift",
//             "status": "success",
//             "start": "2024-07-31T07:00:00+02:00",
//             "end": "2024-07-31T15:00:00+02:00"
//         },
//         {
//             "time": "2024-07-30T17:00:26+02:00",
//             "uuid": "0c5cb58a-9635-4b1d-84ee-9459439522d3",
//             "object": "App\\Entity\\ShiftConceptPlanning",
//             "message": "Alessandro Parodi is al ingepland op dit tijdstip",
//             "status": "warning",
//             "start": "2024-07-31T15:00:00+02:00",
//             "end": "2024-08-01T00:00:00+02:00"
//         },
//         {
//             "time": "2024-07-30T17:00:26+02:00",
//             "uuid": "96873cf5-1ea5-4ea0-88f8-8c0db6dd0393",
//             "object": "App\\Entity\\ShiftConceptPlanning",
//             "message": "Rauf Okuzcu heeft absenties op dit tijdstip",
//             "status": "warning",
//             "start": "2024-07-31T15:00:00+02:00",
//             "end": "2024-08-01T00:00:00+02:00"
//         },
//     ],
// ]
