import { FC } from 'react';

import trans from '../../helpers/trans';
import { FormOption } from '../../types';
import { RadioList } from '../index';

import './CalendarTypeRadioList.scss';

export enum CalendarTypeFormOption {
    shifts = 'shifts',
    concepts = 'concepts',
}

interface CalendarTypeRadioListProps {
    selectedCalendarType: CalendarTypeFormOption;
    onChange: () => void;
    className?: string;
}

const navigationRadioButtons: FormOption[] = [
    {
        label: trans('compositions.calendarNavRadioList.published'),
        value: CalendarTypeFormOption.shifts,
    },
    {
        label: trans('compositions.calendarNavRadioList.concept'),
        value: CalendarTypeFormOption.concepts,
    },
];

const CalendarTypeRadioList: FC<CalendarTypeRadioListProps> = ({ selectedCalendarType, onChange, className = '' }) => (
    <RadioList
        hideLabel
        label={trans('common.navigation')}
        name="calendar-navigation-radio-list"
        value={selectedCalendarType}
        options={navigationRadioButtons}
        onChange={onChange}
        className={`calendar-type-radio-list ${className}`}
        optionWrapperClassName="calendar-type-radio-list__option-wrapper"
        radioClassName="calendar-type-radio-list__radio"
        radioLabelClassName="calendar-type-radio-list__radio-label"
        radioBoxClassName="calendar-type-radio-list__radio-box"
    />
);

export default CalendarTypeRadioList;
