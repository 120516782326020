import { DepartmentViewModel } from '../../../../entities/Departments/Departments';
import { DepartmentOption } from '../models/DepartmentOption';

export default function transformSelectOptionsToDepartments(options: DepartmentOption[], departments: DepartmentViewModel[]): DepartmentViewModel[] {
    if (!options) {
        return [];
    }

    return options
        .map(option => departments.find(department => department.id === option.value))
        .filter(department => department !== undefined) as DepartmentViewModel[];
}
