import { FC } from 'react';

import { NavRouteWithCounter } from '../../Navigation';
import NavigationLink from '../NavigationLink/NavigationLink';

import './SubNavigation.scss';

interface SubRoutesProps {
    navRoute?: NavRouteWithCounter;
    className?: string;
}

const SubNavigation: FC<SubRoutesProps> = ({ navRoute, className = '' }) => (
    <div className={`sub-navigation ${className}`}>
        {(navRoute?.children || []).map(route => {
            const to = `${navRoute?.path}/${route.path}`;

            return (
                <NavigationLink
                    key={route.key}
                    counter={route.counter}
                    to={to}
                    label={route.label}
                    className="sub-navigation__nav-link"
                    activeClassName="sub-navigation__nav-link--is-active"
                />
            );
        })}
    </div>
);

export default SubNavigation;
