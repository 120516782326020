import { FC, ReactElement } from 'react';

import { TableCell, TableRow } from '../../../components';
import Card from '../../../components/Card/Card';
import { ValkTable } from '../../../compositions';
import { DistrictDivisionDistrictTemplate } from '../../../entities/DistrictDivisionDistrictTemplate/DistrictDivisionDistrictTemplate';
import { DistrictDivisionGroupTemplate } from '../../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import trans from '../../../helpers/trans';

const tableHeader = [
    trans('common.districtDivisions'),
    trans('common.amountOfEmployees'),
];

interface DistrictDivisionGroupTemplateTableProps {
    districtDivisionGroupTemplate: DistrictDivisionGroupTemplate;
}

const DistrictDivisionGroupTemplateTable: FC<DistrictDivisionGroupTemplateTableProps> = ({
    districtDivisionGroupTemplate,
}): ReactElement => (
    <Card className="district-division-group-template-table">
        {districtDivisionGroupTemplate && (
            <ValkTable<DistrictDivisionDistrictTemplate>
                rows={districtDivisionGroupTemplate.districtDivisionDistrictTemplates}
                tableHeaders={tableHeader}
            >
                {(districtDivisionDistrictTemplateRows): JSX.Element[] => districtDivisionDistrictTemplateRows.map(districtDivisionDistrictTemplate => (
                    <TableRow key={districtDivisionDistrictTemplate.id}>
                        <TableCell>
                            {districtDivisionDistrictTemplate.name}
                        </TableCell>
                        <TableCell>
                            {districtDivisionDistrictTemplate.maxAmountOfEmployees === 1
                                ? trans('common.employeeCount')
                                : trans('common.employeesCount', { amount: districtDivisionDistrictTemplate.maxAmountOfEmployees.toString() })}
                        </TableCell>
                    </TableRow>
                ))}
            </ValkTable>
        )}
    </Card>
);

export default DistrictDivisionGroupTemplateTable;
