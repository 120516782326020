import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { ExportTracksFormData } from '../../entities/Track/Track';
import { getExportTracksApiCall } from '../../entities/Track/TrackService';
import { generateDownloadURI } from '../../helpers/browser';
import trans from '../../helpers/trans';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setIsSuccessful } from '../user/userReducer';

export const exportTracks = (formData: ExportTracksFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await getExportTracksApiCall(formData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        generateDownloadURI(response.data.uri, response.data.fileName);

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[exportTracks]', error);
        dispatch(setError(trans('errors.unknownError')));
    } finally {
        dispatch(setIsLoading(false));
    }
};
