import { FC, ReactElement } from 'react';

import { ShowEmployeesFilterContainer } from '../../../containers';
import { setShowEmployees } from '../../../redux/@interface/globalFilters/globalFiltersReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

const ConnectedShowEmployeesFilter: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const showEmployees = useTypedSelector(state => state.globalFiltersReducer.showEmployees);

    const handleOnChange = (show: boolean) => {
        dispatch(setShowEmployees(show));
    };

    return (
        <ShowEmployeesFilterContainer
            showEmployees={showEmployees}
            onChange={handleOnChange}
        />
    );
};

export default ConnectedShowEmployeesFilter;
