import { FC } from 'react';

import { Icon, Skeleton } from '../../../../components';
import { DashboardSmallWidgetProps } from '../DashboardSmallWidget/DashboardSmallWidget';

import './DashboardSmallWidgetContent.scss';

export interface DashboardSmallWidgetContentProps extends Omit<DashboardSmallWidgetProps, 'path'> {
    showButton?: boolean;
}

const DashboardSmallWidgetContent: FC<DashboardSmallWidgetContentProps> = ({
    showButton,
    isLoading,
    icon,
    subTitle,
    subTitleAmount,
    title,
    className = '',
}) => (
    <div className={`dashboard-small-widget-content ${className}`}>
        <div className="dashboard-small-widget-content__titles-wrapper">
            {isLoading ? (
                <Skeleton className="dashboard-small-widget-content__title-skeleton" />
                ) : (
                    <div className="dashboard-small-widget-content__title-wrapper">
                        {icon && <Icon name={icon} className={`dashboard-small-widget-content__icon dashboard-small-widget-content__icon--is-${icon}`} />}
                        <h2 className="dashboard-small-widget-content__title">
                            {title}
                        </h2>
                    </div>
                )}
            <div className="dashboard-small-widget-content__sub-title">
                {subTitleAmount && <h3 className="dashboard-small-widget-content__sub-title-amount">{subTitleAmount}</h3>}
                <h3 className="dashboard-small-widget-content__sub-title-label">{subTitle}</h3>
            </div>
        </div>
        {showButton && (
            <div className="dashboard-small-widget-content__button">
                <Icon className="dashboard-small-widget-content__button-icon" name="chevron-right" />
            </div>
        )}
    </div>
);

export default DashboardSmallWidgetContent;
