import { Department } from '../../../../entities/Department/Department';
import { getDepartmentLabel } from '../../../../entities/Department/DepartmentHelpers';
import { EmploymentType } from '../../../../entities/EmploymentType/EmploymentType';
import { Experience } from '../../../../entities/Experience/Experience';
import { Role } from '../../../../entities/Role/Role';
import nationalities from '../../../../helpers/nationalities';
import trans from '../../../../helpers/trans';
import { IdentificationType, SelectOption, WorkdayType } from '../../../../types';
import { DepartmentOption } from '../../../../types/selectOptionTypes';

export const transformIdentificationToSelectOption = (identification: IdentificationType): SelectOption<IdentificationType> => ({
    label: trans(`types.identification.${identification}`),
    value: identification,
}) as SelectOption<IdentificationType>;

export const transformEmploymentTypeToSelectOption = (employmentTypes: EmploymentType[], selectedEmploymentType: EmploymentType): SelectOption => {
    const givenEmploymentType = employmentTypes.find(employmentType => employmentType.id === selectedEmploymentType.id);

    if (givenEmploymentType) {
        return { label: trans(`common.${givenEmploymentType.slug}`), value: givenEmploymentType.id };
    }

    return { label: trans(`common.${employmentTypes[0].slug}`), value: employmentTypes[0].id };
};

export const transformDepartmentToSelectOption = (department: Department): DepartmentOption => ({
    label: getDepartmentLabel(department),
    value: department.id,
    isDeleted: !!department.deletedAt,
});

export const transformRoleToSelectOption = (roles: Role[], selectedRole: Role): SelectOption => {
    const givenRole = roles.find(role => role.id === selectedRole.id);

    if (givenRole) {
        return { label: trans(`entities.roles.name.${givenRole.slug}`), value: givenRole.id };
    }

    const defaultRole = roles.find(roleSelection => roleSelection.slug === 'employee');

    return { label: trans('entities.roles.name.employee'), value: defaultRole ? defaultRole.id : '' };
};

export const transformExperienceToSelectOption = (experiences: Experience[], selectedExperience?: Experience): SelectOption => {
    const givenExperience = selectedExperience && experiences.find(experience => experience.id === selectedExperience.id);

    if (givenExperience) {
        return { label: givenExperience.name, value: givenExperience.id };
    }

    return { label: experiences[0].name, value: experiences[0].id };
};

export const transformWorkdaysToSelectOptions = (workdays: WorkdayType[]): SelectOption<WorkdayType>[] => workdays.map(workday => ({
    label: trans(`types.workdays.${workday}`),
    value: workday,
})) as SelectOption<WorkdayType>[];

const transformDepartmentToDepartmentOption = (department: Department): DepartmentOption => ({
    label: getDepartmentLabel(department),
    value: department.id,
});

export const transformDepartmentsToDepartmentOptions = (departments: Department[]): DepartmentOption[] => (
    departments.map(transformDepartmentToDepartmentOption)
);

export const transformNationalityToSelectOption = (nationality: string): SelectOption => {
    const selectedNationality = nationalities.find(nationalityOption => nationalityOption.value === nationality);

    return selectedNationality || { value: 'NL', label: 'Nederland' };
};
