import { FC, ReactElement } from 'react';

import { SidebarPageWrapper } from '../../components';
import { Sidebar } from '../../compositions';
import {
    ConnectedLeaveOfAbsencesOverviewFilter,
    ConnectedLeaveOfAbsencesOverviewTable,
    ConnectedLeaveOfAbsencesOverviewTopBar,
    ConnectedLeaveOfAbsencesOverviewUsers,
} from '../../connectors';

import './LeaveOfAbsencesOverviewPage.scss';

const LeaveOfAbsencesOverviewPage: FC = (): ReactElement => (
    <SidebarPageWrapper>
        <Sidebar isOpenedClassName="leave-of-absences-overview-page__sidebar--is-opened">
            <ConnectedLeaveOfAbsencesOverviewFilter />
        </Sidebar>
        <ConnectedLeaveOfAbsencesOverviewUsers />
        <main className="leave-of-absences-overview-page__main">
            <ConnectedLeaveOfAbsencesOverviewTopBar />
            <ConnectedLeaveOfAbsencesOverviewTable className="leave-of-absences-overview-page__table" />
        </main>
    </SidebarPageWrapper>
);

export default LeaveOfAbsencesOverviewPage;
