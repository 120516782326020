import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { LoadingSpinner } from '../../@paco/components';
import { ConnectedHelpTooltip } from '../../@paco/connectors';
import { DepartmentsTable } from '../../@paco/containers';
import { transformLegacyDepartmentToDepartment } from '../../@paco/entities/Department/DepartmentTransformers';
import trans from '../../@paco/helpers/trans';
import { editDepartmentGroups, fetchDepartmentGroups } from '../../@paco/redux/departmentGroups/departmentGroupsActions';
import { setIsEditGroupsSuccessful } from '../../@paco/redux/departmentGroups/departmentGroupsReducer';
import { setToast } from '../../@paco/redux/toasts/toastsReducer';
import { ToastType } from '../../@paco/types';
import ButtonAuthenticate from '../../components/ButtonAuthenticate/ButtonAuthenticate';
import NewFilters from '../../components/Filters/Filters';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import { checkPermission } from '../../helpers';
import { translate } from '../../helpers/translations/translator';
import { fetchDepartments } from '../../redux/@toolkit/departments/departmentsActions';
import { addDepartment, deleteDepartment, editDepartment } from '../../redux/management/managementActions';
import GroupsDepartmentsTableSkeleton from './components/Groups/GroupsDepartmentsTableSkeleton/GroupsDepartmentsTableSkeleton';
import AddOrEditDepartment from './forms/AddOrEditDepartment';
import EditGroups from './forms/EditGroups';

import './Groups.scss';

class Groups extends Component {
    state = {
        departmentForm: false,
        editDepartmentData: null,
        groupsForm: false,
    };

    componentDidMount() {
        this.props.dispatch(fetchDepartmentGroups());
    }

    componentDidUpdate() {
        const { isEditGroupsSuccessful, dispatch } = this.props;

        if (isEditGroupsSuccessful) {
            dispatch(setIsEditGroupsSuccessful(false));
            dispatch(fetchDepartmentGroups());
            dispatch(fetchDepartments());
            dispatch(setToast({
                text: translate('pages.management.manageGroupsSuccess'),
                type: ToastType.pass,
            }));
        }
    }

    onAddDepartmentClick = () => {
        this.setState({
            departmentForm: true,
        });
    };

    onEditDepartmentClick = (department) => {
        this.setState({
            departmentForm: true,
            editDepartmentData: department,
        });
    };

    onManageGroupsClick = () => {
        this.setState({
            groupsForm: true,
        });
    };

    onDeleteDepartmentClick = (department) => {
        this.props.dispatch(deleteDepartment(department));
    };

    getDepartments = () => {
        this.props.dispatch(fetchDepartments());
    };

    onSubmitEditGroups = (newGroups, removeGroups, modifyGroups) => {
        this.setState({
            groupsForm: false,
        });
        this.props.dispatch(editDepartmentGroups({
            groupsToDelete: removeGroups,
            groupsToPatch: modifyGroups,
            groupsToPost: newGroups,
        }));
    };

    onSubmitDepartment = (data) => {
        this.setState({
            departmentForm: false,
            editDepartmentData: null,
        });

        this.props.dispatch(data.id ? editDepartment(data) : addDepartment(data));
    };

    renderDepartments() {
        const { deletableDepartments, managementLoading } = this.props;
        const canEditDepartment = checkPermission(this.props.permissions, 'edit-all-departments', 'groups');
        const canDeleteDepartment = checkPermission(this.props.permissions, 'delete-all-departments', 'groups');

        const tableHeader = [
            translate('common.department'),
            translate('common.group'),
        ];

        return (
            <DepartmentsTable
                isLoading={managementLoading}
                canEditDepartment={canEditDepartment}
                canDeleteDepartment={canDeleteDepartment}
                departments={deletableDepartments.map(transformLegacyDepartmentToDepartment)}
                tableHeaders={tableHeader}
                onEditDepartmentClick={this.onEditDepartmentClick}
                onDeleteDepartmentClick={this.onDeleteDepartmentClick}
                tableClassName="groups-departments-table"
            />
        );
    }

    renderManageGroupsButton() {
        return (
            <div className="filter-block">
                <button type="button" className="button-plain button-plain-orange groups-button-manage-groups" onClick={this.onManageGroupsClick}>
                    {translate('pages.management.manageGroups')}
                </button>
            </div>
        );
    }

    render() {
        const {
            departmentForm,
            editDepartmentData,
            groupsForm,
        } = this.state;
        const {
            isLoadingDepartmentGroups,
            loadingDeletableDepartments,
            groups,
            filter,
            permissions,
        } = this.props;
        const canEditGroups = checkPermission(permissions, 'edit-all-groups', 'groups');
        const canAddGroups = checkPermission(permissions, 'add-new-groups', 'groups');
        const canDeleteGroups = checkPermission(permissions, 'delete-all-groups', 'groups');
        const canAddDepartments = checkPermission(permissions, 'add-new-departments', 'groups');

        if (isLoadingDepartmentGroups) {
            return <LoadingSpinner />;
        }

        return (
            <>
                <SidebarPage
                    sidebarContent={(
                        <NewFilters
                            showResetButton={false}
                            showDepartments={false}
                            filter={filter}
                            button={
                                canAddDepartments ? (
                                    <ButtonAuthenticate
                                        onClick={this.onAddDepartmentClick}
                                        text={translate('pages.management.addDepartment')}
                                    />
                                ) : null
                            }
                            onChange={this.getDepartments}
                        >
                            {canEditGroups ? this.renderManageGroupsButton() : null}
                        </NewFilters>
                    )}
                >
                    <ConnectedHelpTooltip
                        index={0}
                        route="departments"
                        subTitle={trans('help.settings.departments.title')}
                        text={trans('help.settings.departments.text')}
                        title={trans('help.settings.title')}
                        className="groups__page-help-tooltip"
                    />
                    <ConnectedHelpTooltip
                        index={1}
                        route="departments"
                        subTitle={trans('help.settings.addDepartment.title')}
                        text={trans('help.settings.addDepartment.text')}
                        title={trans('help.settings.title')}
                        showMobileInfoWarning
                        className="groups__add-department-help-tooltip"
                    />
                    <ConnectedHelpTooltip
                        index={2}
                        route="departments"
                        subTitle={trans('help.settings.manageGroups.title')}
                        text={trans('help.settings.manageGroups.text')}
                        title={trans('help.settings.title')}
                        showMobileInfoWarning
                        className="groups__manage-groups-help-tooltip"
                    />
                    {loadingDeletableDepartments ? (
                        <div className="groups-departments-wrapper">
                            <LoadingSpinner />
                            <GroupsDepartmentsTableSkeleton />
                        </div>
                    ) : (
                        <div className="groups-departments-wrapper">
                            {this.renderDepartments()}
                        </div>
                    )}
                </SidebarPage>
                {canAddDepartments && (
                    <ButtonAuthenticate
                        mobile
                        onClick={this.onAddDepartmentClick}
                    />
                )}
                <Modal size="lg" isOpen={departmentForm} className="groups-form-department">
                    <AddOrEditDepartment
                        title={editDepartmentData ? translate('pages.management.editDepartment') : translate('pages.management.addDepartment')}
                        onSubmit={this.onSubmitDepartment}
                        onCancel={() => this.setState({
                            departmentForm: null,
                            editDepartmentData: null,
                        })}
                        groups={groups}
                        department={editDepartmentData}
                    />
                </Modal>
                <Modal size="lg" isOpen={groupsForm} className="groups-form-edit-groups">
                    <EditGroups
                        onSubmit={this.onSubmitEditGroups}
                        onCancel={() => this.setState({ groupsForm: null })}
                        groups={groups}
                        canDeleteGroups={canDeleteGroups}
                        canAddGroups={canAddGroups}
                    />
                </Modal>
            </>
        );
    }
}

Groups.propTypes = {
    dispatch: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    loadingDeletableDepartments: PropTypes.bool.isRequired,
    managementLoading: PropTypes.bool.isRequired,
    isEditGroupsSuccessful: PropTypes.bool.isRequired,
    isLoadingDepartmentGroups: PropTypes.bool.isRequired,
    groups: PropTypes.array.isRequired,
    deletableDepartments: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
};

export default connect(null)(Groups);
