import { getRandomUuid } from '../../@paco/helpers/crypto';
import { getLoketApiUrl } from '../../helpers';
import { DocumentTypeViewModel, PayslipResource, UserDocumentViewModel } from '../../models';

export default function transformPayslipResourceToUserDocument(payslip: PayslipResource, documentTypes: DocumentTypeViewModel[]): UserDocumentViewModel {
    const pdfType = documentTypes.find(documentType => documentType.slug === 'salary');

    return {
        id: getRandomUuid(),
        isLoketFile: true,
        isDataUrl: false,
        displayName: payslip.fileName,
        userCanView: true,
        createdAt: new Date(payslip.payrollPeriodStart),
        documentType: pdfType,
        downloadUrl: `${getLoketApiUrl()}/${payslip.downloadUrl}`,
        fileName: payslip.fileName,
        viewers: [],
    };
}
