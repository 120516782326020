import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import ErrorPage from '../../../pages/ErrorPage';
import { LoadingSpinner } from '../../components';
import { DistrictDivisionTemplateDetailContainer } from '../../containers';
import DistrictDivisionTemplateDetailContainerSkeleton from '../../containers/DistrictDivisionTemplateDetailContainer/subcomponents/DistrictDivisionTemplateDetailContainerSkeleton';
import { getDistrictDivisionTemplate } from '../../redux/districtDivisionTemplate/districtDivisionTemplateActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

const ConnectedDistrictDivisionTemplateDetail: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const { id: districtDivisionTemplateId } = useParams<{ id: string }>();

    const { isLoading } = useTypedSelector(state => state.districtDivisionTemplateReducer);
    const { districtDivisionTemplate } = useTypedSelector(state => state.districtDivisionTemplateReducer);

    useEffect(() => {
        if (!districtDivisionTemplate && districtDivisionTemplateId) {
            dispatch(getDistrictDivisionTemplate(districtDivisionTemplateId));
        }
    }, [districtDivisionTemplate]);

    if (isLoading) {
        return (
            <>
                <LoadingSpinner />
                <DistrictDivisionTemplateDetailContainerSkeleton />
            </>
        );
    }

    if (!districtDivisionTemplate) {
        return (
            <ErrorPage errorCode={404} />
        );
    }

    return (
        <DistrictDivisionTemplateDetailContainer
            isLoading={isLoading}
            districtDivisionTemplate={districtDivisionTemplate}
        />
    );
};

export default ConnectedDistrictDivisionTemplateDetail;
