import { FC, ReactElement } from 'react';

import classnames from 'classnames';

import { ConnectedUserSearch } from '../../connectors';
import { LegacyBasicUser } from '../../entities/User/User';
import trans from '../../helpers/trans';
import { LeaveOfAbsencesOverviewUsersList } from './subcomponents';

import './LeaveOfAbsencesOverviewUsers.scss';

interface LeaveOfAbsencesOverviewUsersProps {
    isLoading: boolean;
    users: LegacyBasicUser[];
    className?: string;
}

const LeaveOfAbsencesOverviewUsers: FC<LeaveOfAbsencesOverviewUsersProps> = ({ isLoading, users, className = '' }): ReactElement => {
    const wrapperClassName = classnames('leave-of-absences-overview-users', {
        'leave-of-absences-overview-users--is-loading': isLoading,
    }, className);

    return (
        <div className={wrapperClassName}>
            <section className="leave-of-absences-overview-users__section leave-of-absences-overview-users__users-amount-section">
                <h1 className="leave-of-absences-overview-users__title">
                    {trans('common.employees')}
                </h1>
                <p className="leave-of-absences-overview-users__users-amount">
                    {`${users.length} ${trans('common.employees')}`}
                </p>
            </section>
            <section className="leave-of-absences-overview-users__section leave-of-absences-overview-users__user-search-section">
                <ConnectedUserSearch
                    className="leave-of-absences-overview-users__user-search"
                    iconWrapperClassName="leave-of-absences-overview-users__user-search-icon-wrapper"
                    inputClassName="leave-of-absences-overview-users__user-search-input"
                />
            </section>
            <section className="leave-of-absences-overview-users__section leave-of-absences-overview-users__users-list-section">
                <LeaveOfAbsencesOverviewUsersList
                    users={users}
                />
            </section>
        </div>
    );
};

export default LeaveOfAbsencesOverviewUsers;
