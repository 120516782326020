import { Permission } from '../../../@paco/entities/Permission/Permission';
import { Role } from '../../../@paco/entities/Role/Role';
import { checkPermission } from '../../../helpers';
import { getPermissionToEdit } from '../../../helpers/permissions/getPermissionToAction';

export default function getPermissionToApproveTrack(
    currentUserRole: Role,
    trackOwnerRoles: Role[],
    permissions: Permission[],
    trackOwnerId: string,
    currentUserId?: string,
): boolean {
    const canEditTrack = getPermissionToEdit(currentUserRole, trackOwnerRoles, permissions, 'tracks');
    const canApproveOwnTrack = checkPermission(permissions, 'approve-own-tracks');

    return ((trackOwnerId !== currentUserId) && canEditTrack)
        || ((trackOwnerId === currentUserId) && canApproveOwnTrack);
}
