import { FC, ReactElement } from 'react';

import { SidebarPageWrapper } from '../../components';
import { Sidebar } from '../../compositions';
import { ConnectedPzLogs } from '../../connectors';
import { PzLogsFilterContainer } from '../../containers';

const PzLogsPage: FC = (): ReactElement => (
    <SidebarPageWrapper>
        <Sidebar showSidebarToggle>
            <PzLogsFilterContainer />
        </Sidebar>
        <ConnectedPzLogs />
    </SidebarPageWrapper>
);

export default PzLogsPage;
