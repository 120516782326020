import {
    AddAddressRequest,
    Address,
    AddressFormData,
    AddressResource,
    AddressV3Resource,
    EditAddressFormData,
    EditAddressRequest,
} from './Address';

export const transformToAddress = (resource: AddressResource): Address => ({
    id: resource.id,
    country: resource.attributes.country || undefined,
    city: resource.attributes.city || undefined,
    houseNumber: resource.attributes.houseNumber || undefined,
    houseNumberAddition: resource.attributes.houseNumberAddition || undefined,
    street: resource.attributes.street || undefined,
    postalCode: resource.attributes.postalCode || undefined,
});

export const transformV3ResourceToAddress = (resource: AddressV3Resource): Address => ({
    id: resource.id,
    country: resource.country || undefined,
    city: resource.city || undefined,
    houseNumber: resource.houseNumber || undefined,
    houseNumberAddition: resource.houseNumberAddition || undefined,
    street: resource.street || undefined,
    postalCode: resource.postalCode || undefined,
});

export const transformAddAddressFormDataToAddApiParams = (addressFormData: AddressFormData, personId: string): AddAddressRequest => ({
    type: 'addresses',
    attributes: {
        country: 'NL',
        city: addressFormData.city,
        houseNumber: addressFormData.houseNumber,
        houseNumberAddition: addressFormData.houseNumberAddition,
        street: addressFormData.street,
        postalCode: addressFormData.postalCode,
    },
    relationships: {
        people: {
            data: [{
                type: 'people',
                id: personId,
            }],
        },
    },
});

export const transformEditAddressFormDataToEditApiParams = (addressFormData: EditAddressFormData, personId: string): EditAddressRequest => ({
    type: 'addresses',
    id: addressFormData.id,
    attributes: {
        country: 'NL',
        city: addressFormData.city,
        houseNumber: addressFormData.houseNumber,
        houseNumberAddition: addressFormData.houseNumberAddition,
        street: addressFormData.street,
        postalCode: addressFormData.postalCode,
    },
    relationships: {
        people: {
            data: [{
                type: 'people',
                id: personId,
            }],
        },
    },
});

