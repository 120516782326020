import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { EventLogCategory } from '../../entities/EventLogCategory/EventLogCategory';
import { FormOption } from '../../types';
import { globalFiltersSlice } from '../@interface/globalFilters/globalFiltersReducer';

export type PzLogCategoriesState = AsyncReduxState<{
    categories: EventLogCategory[];
    categoryOptions: FormOption[];
}>;

const initialState: PzLogCategoriesState = {
    ...initialAsyncReduxState,
    categories: [],
    categoryOptions: [],
};

export const PzLogCategoriesSlice = createSlice({
    name: 'pzLogCategoriesReducer',
    initialState,
    reducers: {
        setCategories(state, action: PayloadAction<EventLogCategory[]>): PzLogCategoriesState {
            return {
                ...state,
                categories: action.payload,
            };
        },
        setCategoryOptions(state, action: PayloadAction<FormOption[]>): PzLogCategoriesState {
            return {
                ...state,
                categoryOptions: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): PzLogCategoriesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): PzLogCategoriesState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(globalFiltersSlice.actions.resetFilters, state => {
            const categoryOptions = state.categoryOptions.map(option => ({
                ...option,
                isChecked: false,
            }));

            return {
                ...state,
                categoryOptions,
            };
        });
    },
});

export const {
    setCategories,
    setCategoryOptions,
    setIsLoading,
    setError,
} = PzLogCategoriesSlice.actions;

export default PzLogCategoriesSlice.reducer;
