import { FC, ReactElement } from 'react';

import { AddressFormData } from '../../../entities/Address/Address';
import trans from '../../../helpers/trans';
import { TextInput } from '../../index';

import './AddressInput.scss';

interface AddressInputProps {
    disabled?: boolean;
    addressFormData?: AddressFormData;
    onChange: (addressFormData: AddressFormData) => void;
}

const AddressInput: FC<AddressInputProps> = ({
    disabled,
    addressFormData,
    onChange,
}): ReactElement => {
    const handleStreetChange = (value: string): void => {
        onChange({ street: value || undefined });
    };

    const handleHouseNumberChange = (value: string): void => {
        onChange({ houseNumber: value || undefined });
    };

    const handleHouseNumberAdditionChange = (value: string): void => {
        onChange({ houseNumberAddition: value || undefined });
    };

    const handlePostalCodeChange = (value: string): void => {
        onChange({ postalCode: value || undefined });
    };

    const handleCityChange = (value: string): void => {
        onChange({ city: value || undefined });
    };

    return (
        <>
            <div className="address-input__row">
                <div className="address-input__col">
                    <TextInput
                        disabled={disabled}
                        requiredText={trans('common.valkWork')}
                        label={trans('compositions.addressInput.street')}
                        type="text"
                        value={addressFormData?.street || ''}
                        onChange={handleStreetChange}
                        requiredClassName="address-input__required-label"
                    />
                </div>
                <div className="address-input__col-small">
                    <TextInput
                        disabled={disabled}
                        requiredText={trans('common.valkWork')}
                        label={trans('compositions.addressInput.houseNumber')}
                        type="text"
                        value={addressFormData?.houseNumber || ''}
                        onChange={handleHouseNumberChange}
                        requiredClassName="address-input__required-label"
                    />
                </div>
                <div className="address-input__col-small">
                    <TextInput
                        disabled={disabled}
                        label={trans('compositions.addressInput.houseNumberAddition')}
                        type="text"
                        value={addressFormData?.houseNumberAddition || ''}
                        onChange={handleHouseNumberAdditionChange}
                    />
                </div>
            </div>
            <div className="address-input__row">
                <div className="address-input__col">
                    <TextInput
                        disabled={disabled}
                        requiredText={trans('common.valkWork')}
                        label={trans('common.postalCode')}
                        type="text"
                        value={addressFormData?.postalCode || ''}
                        onChange={handlePostalCodeChange}
                        requiredClassName="address-input__required-label"
                    />
                </div>
                <div className="address-input__col">
                    <TextInput
                        disabled={disabled}
                        requiredText={trans('common.valkWork')}
                        label={trans('common.city')}
                        type="text"
                        value={addressFormData?.city || ''}
                        onChange={handleCityChange}
                        requiredClassName="address-input__required-label"
                    />
                </div>
            </div>
        </>
    );
};

export default AddressInput;
