import {
    FC,
    MouseEvent,
    useMemo,
    useState,
} from 'react';

import classnames from 'classnames';

import { Icon } from '../../../../components';
import { DistrictDivisionDistrictPlanning } from '../../../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { getTimeOnlyLabel } from '../../../../entities/Period/PeriodHelpers';
import { Setting } from '../../../../entities/Setting/Setting';
import { getSettingEnabled } from '../../../../entities/Setting/SettingHelpers';
import { formatDate } from '../../../../helpers/date';
import trans from '../../../../helpers/trans';
import { DateFormat } from '../../../../types/dateFormatTypes';
import { ElementWithTooltip, IconButton } from '../../../index';

import './DraggableDistrictDivisionDistrictPlanning.scss';

interface DraggableDistrictDivisionDistrictPlanningProps {
    isDisabled: boolean;
    showDeleteButton: boolean;
    planning: DistrictDivisionDistrictPlanning;
    settings: Setting[];
    onAddCommentButtonClick: (districtDivisionDistrictPlanning: DistrictDivisionDistrictPlanning) => void;
    onDeleteButtonClick: (districtDivisionDistrictPlanningId: string, type: string) => void;
    onDragEnd: () => void;
    onDragEnter: () => void;
    onDrop: () => void;
    onDragLeave: () => void;
    onDragStart: (districtDivisionDistrictPlanning: DistrictDivisionDistrictPlanning) => void;
    className?: string;
}

const DraggableDistrictDivisionDistrictPlanning: FC<DraggableDistrictDivisionDistrictPlanningProps> = ({
    isDisabled,
    showDeleteButton,
    onDragEnd,
    onDragEnter,
    onDrop,
    onDragLeave,
    onDragStart,
    planning,
    settings,
    onAddCommentButtonClick,
    onDeleteButtonClick,
    className = '',
}) => {
    const [isDragEnter, setIsDragEnter] = useState<boolean>(false);
    const [isDragStart, setIsDragStart] = useState<boolean>(false);

    const showEndTime = useMemo(() => getSettingEnabled('EMPLOYEE_APP_SHOW_END_TIMES', settings, planning.department.id), [settings, planning]);

    const classNames = classnames('draggable-district-division-district-planning', {
        'draggable-district-division-district-planning--is-active': isDragEnter && !isDragStart,
        'draggable-district-division-district-planning--is-disabled': isDisabled,
    }, className);

    const handleDragStart = (): void => {
        setIsDragStart(true);
        onDragStart(planning);
    };

    const handleDragEnd = (): void => {
        setIsDragStart(false);
        onDragEnd();
    };

    const handleDragEnter = (): void => {
        setIsDragEnter(true);
        onDragEnter();
    };

    const handleDragLeave = (): void => {
        setIsDragEnter(false);
        onDragLeave();
    };

    const handleDragOver = (e: MouseEvent): void => {
        e.preventDefault();
    };

    const handleDrop = (): void => {
        onDrop();
        setIsDragStart(false);
        setIsDragEnter(false);
    };

    return (
        <div
            draggable
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
            className={classNames}
        >
            <div className="draggable-district-division-district-planning__employee-name">
                {planning.fullName}
            </div>
            <div className="draggable-district-division-district-planning__department">
                {planning.department.name}
            </div>
            <div className="draggable-district-division-district-planning__shift-planning-time">
                {showEndTime ? getTimeOnlyLabel(planning.period.start, planning.period.end) : formatDate(planning.period.start, DateFormat.hoursMinutes)}
            </div>
            <ElementWithTooltip
                tooltipIsActive={!!planning.comments.length}
                tooltipText={planning.comments[planning.comments.length - 1]?.body}
                className="draggable-district-division-district-planning__comment-tooltip"
            >
                <IconButton
                    hideLabel
                    icon={!planning.comments.length ? 'comment-plus-outline' : 'comment-text-outline'}
                    text={trans('common.addComment')}
                    className="draggable-district-division-district-planning__comment-button"
                    iconClassName="draggable-district-division-district-planning__comment-button-icon"
                    onClick={() => onAddCommentButtonClick(planning)}
                />
            </ElementWithTooltip>
            {showDeleteButton && (
                <IconButton
                    hideLabel
                    icon="trash"
                    text={trans('common.delete')}
                    className="draggable-district-division-district-planning__delete-button"
                    iconClassName="draggable-district-division-district-planning__delete-button-icon"
                    onClick={() => onDeleteButtonClick(planning.id, planning.type)}
                />
            )}
            <Icon name="draggable" className="draggable-district-division-district-planning__draggable-icon" />
        </div>
    );
};

export default DraggableDistrictDivisionDistrictPlanning;
