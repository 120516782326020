import { ABSENCES_CALENDAR_VIEW_PROPS } from '../../constants';
import {
    FILTER_RESET_FILTER,
    FILTER_SET_CALENDAR_VIEW_PROPS,
    FILTER_SET_EMPLOYEE_CONTRACT_TYPE,
    FILTER_SET_FILTER,
    FILTER_SET_FILTER_FROM_PACO_STORE,
    FILTER_SET_USER_TYPE,
} from '../actionTypes';

export const initialState = {
    filter: {
        onlyShowMainDepartment: false,
        employmentTypeBadge: false,
        employeeSearch: '',
        documentSearch: '',
        userTypes: [],
        employeeContractTypes: [],
        absences: {
            onlyWithReplies: false,
            showDenied: false,
            calendarViewProps: [...ABSENCES_CALENDAR_VIEW_PROPS],
        },
        availability: {
            available: true,
            unavailable: true,
            approvedLeaveOfAbsence: true,
            openLeaveOfAbsence: true,
            deniedLeaveOfAbsence: true,
            absence: true,
            sortByExperience: false,
        },
        shifts: {
            showEmployees: true,
            onlyShowShiftsWithOpenLeaveOfAbsence: false,
            onlyShowShiftsWithAbsence: false,
        },
        management: {
            showInactiveUsers: false,
        },
        pz: {
            documentTypes: [],
        },
    },
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FILTER_SET_FILTER:
        case FILTER_SET_FILTER_FROM_PACO_STORE:
        case FILTER_SET_EMPLOYEE_CONTRACT_TYPE:
        case FILTER_SET_USER_TYPE:
        case FILTER_SET_CALENDAR_VIEW_PROPS:
            return { ...state, filter: { ...action.filter } };
        case FILTER_RESET_FILTER:
            return { ...state, filter: initialState.filter };
        default:
            return state;
    }
};

export default filterReducer;
