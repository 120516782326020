import { FC, ReactElement, useMemo } from 'react';

import { LeaveOfAbsencesOverviewBodyRow } from '../../../../../entities/LeaveOfAbsenceOverviewBlock/LeaveOfAbsencesOverviewBlock';
import { LeaveOfAbsencesOverviewTableBodyCell } from '..';

import './LeaveOfAbsencesOverviewTableBody.scss';

interface LeaveOfAbsenceOverviewTableBodyProps {
    rows: LeaveOfAbsencesOverviewBodyRow[];
    className?: string;
}

const LeaveOfAbsencesOverviewTableBody: FC<LeaveOfAbsenceOverviewTableBodyProps> = ({
    rows,
    className = '',
}): ReactElement => {
    const rowsList = useMemo(() => rows.map((row) => (
        <tr key={row.user.id} className="leave-of-absences-overview-table-body__row">
            {row.cells.map((cell, index) => (
                <LeaveOfAbsencesOverviewTableBodyCell
                    key={cell.date.getTime()}
                    index={index}
                    leaveOfAbsences={cell.blocks}
                />
            ))}
        </tr>
    )), [rows]);

    return (
        <tbody className={`leave-of-absences-overview-table-body ${className}`}>
            {rowsList}
        </tbody>
    );
};

export default LeaveOfAbsencesOverviewTableBody;
