import React, { FC } from 'react';

import { translate } from '../../../../helpers/translations/translator';
// @ts-ignore
import ResetIcon from '../../../../images/reset.svg';

import './ButtonReset.scss';

interface ButtonResetProps {
    onButtonResetClick: () => void;
}

const ButtonReset: FC<ButtonResetProps> = ({ onButtonResetClick }) => (
    <button type="button" className="filters__button-reset" onClick={onButtonResetClick}>
        <img alt="refresh-icon" src={ResetIcon} className="filters__button-reset-icon" />
        { translate('common.resetFilters') }
    </button>
);

export default ButtonReset;
