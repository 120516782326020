import axios, { AxiosResponse } from 'axios';

import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { Filter, ShiftPlanningViewModel } from '../../models';
import transformShiftPlanningResource from '../../services/ShiftPlanningService/transformShiftPlanningResource';
import {
    AddShiftConceptPlanningRequest,
    EditShiftConceptPlanningColorRequest,
    EditShiftConceptPlanningRequest,
    ShiftConceptPlanningResource,
} from './ShiftConceptPlanning';

export const getShiftConceptPlanningsApiCall = async (
    startDate: Date,
    endDate: Date,
    filter: Filter,
    includes: string[] = [],
): Promise<AxiosResponse<ShiftConceptPlanningResource[]>> => {
    const { departments, userIds, employeeSearch: search } = filter || {};

    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concept/plannings`);

    if (search) url.searchParams.append('search', search);

    return axios.get<ShiftConceptPlanningResource[]>(String(url), {
        params: {
            ...(includes.length && { include: includes.join(',') }),
            startDate: formatDate(startDate, DateFormat.apiDateTime),
            endDate: formatDate(endDate, DateFormat.apiDateTime),
            departments: (departments && departments.length > 0) ? JSON.stringify(departments) : undefined,
            userUuids: (userIds && userIds.length > 0) ? JSON.stringify(userIds) : undefined,
        },
    });
};

export const patchShiftConceptPlanningColorApiCall = async (
    shiftPlanningId: string,
    body: EditShiftConceptPlanningColorRequest,
): Promise<ShiftPlanningViewModel> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concept/plannings/${shiftPlanningId}`);

    const includes = [
        'user',
        'user.employmentType',
        'user.experience',
        'user.person',
        'comments',
        'comments.owner',
        'comments.owner.person',
    ];

    url.searchParams.append('include', includes.join(','));

    const response = await axios.patch<ShiftConceptPlanningResource>(String(url), { data: body });

    return transformShiftPlanningResource(
        response.data,
        response.data.user,
        undefined,
        response.data.comments,
    );
};

export const patchShiftConceptPlanningApiCall = async (
    body: EditShiftConceptPlanningRequest,
    shiftPlanningId: string,
): Promise<ShiftPlanningViewModel> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concept/plannings/${shiftPlanningId}`);

    const includes = [
        'user',
        'user.employmentType',
        'user.experience',
        'user.person',
        'comments',
        'comments.owner',
        'comments.owner.person',
    ];

    url.searchParams.append('include', includes.join(','));

    const response = await axios.patch<ShiftConceptPlanningResource>(String(url), { data: body });
    return transformShiftPlanningResource(
        response.data,
        response.data.user,
        undefined,
        response.data.comments,
    );
};

export const postShiftConceptPlanningApiCall = async (body: AddShiftConceptPlanningRequest): Promise<ShiftPlanningViewModel> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concept/plannings`);

    const includes = [
        'user',
        'user.employmentType',
        'user.experience',
        'user.person',
    ];

    url.searchParams.append('include', includes.join(','));

    const response = await axios.post<ShiftConceptPlanningResource>(String(url), { data: body });

    return transformShiftPlanningResource(response.data, response.data.user);
};

export const deleteShiftConceptPlanningApiCall = async (id: string): Promise<void> => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/shift/concept/plannings/${id}`);

    await axios.delete(String(url));
};
