import { ChangeEvent, FC, ReactElement } from 'react';

import classNames from 'classnames';

import Icon from '../../Icon/Icon';

import './Checkbox.scss';

export interface CheckboxProps extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
    hideLabel?: boolean;
    label: string;
    onChange: (isChecked: boolean) => void;
    checkClassName?: string;
    labelClassName?: string;
    className?: string;
}

const Checkbox: FC<CheckboxProps> = ({
    checked,
    hideLabel,
    label,
    disabled,
    onChange,
    checkClassName = '',
    labelClassName = '',
    className = '',
    ...checkboxProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.checked);
    };

    const checkboxClassNames = classNames('checkbox', {
        'checkbox--is-disabled': disabled,
    }, className);

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={checkboxClassNames}
        >
            <input
                {...checkboxProps}
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
                className="checkbox__input"
            />

            <div className={`checkbox__box ${checkClassName}`}>
                <Icon name="checkbox-check" className="checkbox__icon" />
            </div>

            {!hideLabel && <span className={`checkbox__label ${labelClassName}`}>{label}</span>}
        </label>
    );
};

export default Checkbox;
