import { compareAsc } from '../../../../../helpers/date';
import UpToAndIncludingDate from '../../../../../helpers/date/UpToAndIncludingDate';
import legacyParse from '../../../../../helpers/legacyDateParser';

export default function getAbsencePayrollPeriodEndDate(
    startDate: string | null,
    endDate: string | null,
    endTime: string,
): UpToAndIncludingDate | null {
    if (!startDate) {
        return null;
    }

    const date = (endDate && endTime) ? new UpToAndIncludingDate(`${endDate} ${endTime}`) : new UpToAndIncludingDate();
    return compareAsc(date.date, legacyParse(startDate)) === -1 ? new UpToAndIncludingDate(startDate) : date;
}
