import React from 'react';

import { Checkbox as GroupedCheckbox } from '../../@paco/compositions/GroupedCheckboxes';

const Checkbox = (props) => {
    const {
        id, value, label, onChange, checked, disabled,
    } = props;

    const transformChangeEvent = (event) => {
        onChange(event.target.value, event.target.checked);
    };

    return (
        <div key={id} className={`form-check form-check-inline custom-checkbox ${disabled ? 'disabled' : ''}`}>
            <GroupedCheckbox
                id={id || `checkbox_${value}`}
                value={value}
                className="form-check-input custom-control-input"
                onChange={onChange ? transformChangeEvent : undefined}
                checked={checked}
            />
            <label
                className="form-check-label custom-control-label"
                htmlFor={id || `checkbox_${value}`}
            >
                <div>{label}</div>
            </label>
        </div>
    );
};

export default Checkbox;
