import {
    WEEK_NAVIGATOR_SAGA_SET_DATES,
    WEEK_NAVIGATOR_SAGA_SET_SELECTED_DATE,
    WEEK_NAVIGATOR_SAGA_SET_TIME_MODE,
} from '../actionTypes';

const setStartAndEndDate = (selectedDate, startDate, endDate) => ({
    type: WEEK_NAVIGATOR_SAGA_SET_DATES,
    selectedDate,
    startDate,
    endDate,
});

const setSelectedDate = (selectedDate, startDate, endDate) => ({
    type: WEEK_NAVIGATOR_SAGA_SET_SELECTED_DATE,
    selectedDate,
    startDate,
    endDate,
});

const setTimeMode = mode => ({
    mode,
    type: WEEK_NAVIGATOR_SAGA_SET_TIME_MODE,
});

export {
    setStartAndEndDate,
    setSelectedDate,
    setTimeMode,
};
