import { FC, ReactElement } from 'react';

import trans from '../../../helpers/trans';
import { SelectOption } from '../../../types';
import { LeaveType, PzLeaveType } from '../../../types/leaveType';
import { SelectInput } from '../../index';

const generatePzLeaveTypeOptions = (): SelectOption<PzLeaveType>[] => {
    const pzLeaveTypeArray = Object.values(PzLeaveType);

    return pzLeaveTypeArray.map(leaveType => ({
        label: trans(`compositions.leaveTypeSelectInput.${leaveType}`),
        value: leaveType,
    })) as SelectOption<PzLeaveType>[];
};

const generateLeaveTypeOptions = (): SelectOption<LeaveType>[] => {
    const leaveTypeArray = Object.values(LeaveType);

    return leaveTypeArray.map(leaveType => ({
        label: trans(`compositions.leaveTypeSelectInput.${leaveType}`),
        value: leaveType,
    })) as SelectOption<LeaveType>[];
};

export const transformLeaveTypeToSelectOption = (pzLeaveType: PzLeaveType): SelectOption<PzLeaveType> => ({
    label: trans(`compositions.leaveTypeSelectInput.${pzLeaveType}`),
    value: pzLeaveType,
}) as SelectOption<PzLeaveType>;

interface LeaveTypeSelectInputProps {
    canViewAllLeaveTypes?: boolean;
    leaveType?: PzLeaveType;
    onChange: (option: PzLeaveType) => void;
}

const LeaveTypeSelectInput: FC<LeaveTypeSelectInputProps> = ({
    canViewAllLeaveTypes,
    leaveType,
    onChange,
}): ReactElement => {
    const leaveTypeOptions = canViewAllLeaveTypes ? generatePzLeaveTypeOptions() : generateLeaveTypeOptions();

    const onLeaveTypeChange = (leaveTypeValue: SelectOption): void => {
        onChange(leaveTypeValue.value as PzLeaveType);
    };

    return (
        <SelectInput
            isSearchable
            label={trans('compositions.leaveTypeSelectInput.leaveType')}
            name="leaveType"
            value={leaveType ? transformLeaveTypeToSelectOption(leaveType) : undefined}
            options={leaveTypeOptions}
            onChange={onLeaveTypeChange}
        />
    );
};

export default LeaveTypeSelectInput;
