import { FC, FormEvent, ReactElement } from 'react';

import { DepartmentBadge } from '../../../components';
import { FormActionButtons } from '../../../compositions';
import { getTimeOnlyLabel } from '../../../entities/Period/PeriodHelpers';
import { Shift, ShiftIndex } from '../../../entities/Shift/Shift';
import { formatDate } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { DateFormat } from '../../../types/dateFormatTypes';

import './SaveSequenceForm.scss';

interface SaveSequenceFormProps {
    isLoading: boolean;
    shift: ShiftIndex;
    similarShifts: Shift[];
    onCancel: () => void;
    onSubmit: () => void;
    className?: string;
}

const SaveSequenceForm: FC<SaveSequenceFormProps> = ({
    isLoading,
    shift,
    similarShifts,
    onCancel,
    onSubmit,
    className = '',
}): ReactElement => {
    const { department, period } = shift;

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        onSubmit();
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={`save-sequence-form ${className}`}
        >
            <div className="save-sequence-form__row">
                <div className="save-sequence-form__col">
                    <h3 className="save-sequence-form__title">
                        {trans('containers.forms.saveSequenceForm.shiftInfo')}
                    </h3>

                    <div className="save-sequence-form__shift-info">
                        <DepartmentBadge
                            name={department.name}
                            groupName={department.departmentGroup?.name}
                            className="save-sequence-form__department-badge"
                        />

                        <div className="save-sequence-form__shift-date-time">
                            <div className="save-sequence-form__shift-date">
                                {formatDate(period.start, DateFormat.dateLabel)}
                            </div>

                            <div className="save-sequence-form__shift-time">
                                {getTimeOnlyLabel(period.start, period.end)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="save-sequence-form__row">
                <div className="save-sequence-form__col">
                    <h3 className="save-sequence-form__title">
                        {trans('containers.forms.saveSequenceForm.shiftsWithinSequence')}
                    </h3>

                    <ul className="save-sequence-form__list">
                        {similarShifts.map(shiftPlanningSnapshot => (
                            <li
                                key={shiftPlanningSnapshot.id}
                                className="save-sequence-form__list-item"
                            >
                                {formatDate(shiftPlanningSnapshot.period?.start || shiftPlanningSnapshot.period.start, DateFormat.dateLabel)}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default SaveSequenceForm;
