import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import {
    LoketLeaveSyncError,
    LoketLeaveSyncErrorResource,
    LoketLeaveSyncFormData,
    LoketLeaveSyncRequest,
} from './LoketLeaveSync';

export const transformExportLeaveOfAbsencesFormDataToApiParams = (exportLeaveOfAbsencesFormData: LoketLeaveSyncFormData): LoketLeaveSyncRequest => ({
    startDate: formatDate(exportLeaveOfAbsencesFormData.start, DateFormat.apiDateTime),
    endDate: exportLeaveOfAbsencesFormData.end ? formatDate(exportLeaveOfAbsencesFormData.end, DateFormat.apiDateTime) : undefined,
});

export const transformToLoketLeaveSyncError = (resource: LoketLeaveSyncErrorResource): LoketLeaveSyncError => ({
    leaveId: resource.leaveId,
    loketLeaveId: resource.loketLeaveId || undefined,
    message: resource.message,
});
