import React, { FC } from 'react';

import { formatDate } from '../../../../../../helpers/date';
import { UnavailableToWorkTimeSlot } from '../../../../../../models';

import './UnavailableSlot.scss';

interface Props {
    slot: UnavailableToWorkTimeSlot;
    onClick: (data: UnavailableToWorkTimeSlot) => void;
}

const UnavailableSlot: FC<Props> = ({ slot, onClick }) => {
    const startTime = formatDate(slot.start, 'HH:mm');
    const endTime = formatDate(slot.end, 'HH:mm');

    const onButtonClick = () => {
        onClick(slot);
    };

    return (
        <button
            type="button"
            onClick={onButtonClick}
            className="calendar-unavailable"
        >
            <div className="calendar-unavailable-time">
                { `${startTime} - ${endTime}` }
            </div>
            <div className="calendar-unavailable-comment">
                { slot.comment }
            </div>
        </button>
    );
};

export default UnavailableSlot;
