import React, { FC } from 'react';

import Tooltip from 'react-tooltip-lite';

import './QuestionToolTip.scss';


interface Props {
    id: string;
    className?: string;
    children: React.ReactNode;
}

const QuestionToolTip: FC<Props> = ({ children, id, className = '' }) => (
    // @ts-ignore
    <Tooltip content={children} arrowSize={6} padding={7} zIndex={2000}>
        <button
            id={id}
            type="button"
            className={`button-question-tooltip ${className}`}
        >
            ?
        </button>
    </Tooltip>
);

export default QuestionToolTip;
