import axios from 'axios';

import { formatDate, startOfDayInHours } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import {
    ApiResponse,
    PayrollPeriodFormData,
    PayrollPeriodResource,
    PayrollPeriodViewModel,
} from '../../models';
import UpToButExcludingDate from '../date/UpToButExcludingDate';

export interface PayrollPeriodRequestApiParams {
    type: 'payrollPeriods';
    id?: string;
    attributes: {
        year: number;
        periodNumber: number;
        start: string;
        end: string;
    }
}

const transformApiParamsToPayrollPeriod = (
    params: PayrollPeriodResource,
): PayrollPeriodViewModel => ({
    type: 'payrollPeriods',
    id: params.id,
    year: params.year,
    periodNumber: params.periodNumber,
    start: new Date(params.start),
    end: new UpToButExcludingDate(params.end).transformToUpToAndIncludingDate(),
    locked: params.locked,
});

const transformFormDataToApiparams = (
    formData: PayrollPeriodFormData,
    id?: string,
): PayrollPeriodRequestApiParams => ({
    type: 'payrollPeriods',
    id,
    attributes: {
        year: formData.year,
        periodNumber: formData.periodNumber,
        start: `${formatDate(formData.start, DateFormat.apiDate)} 0${startOfDayInHours}:00:00`,
        end: `${formatDate(formData.end, DateFormat.apiDate)} 0${startOfDayInHours}:00:00`,
    },
});

export const getPayrollPeriods = async (): Promise<PayrollPeriodViewModel[]> => {
    const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/payroll-periods`, {
            params: {
                orderByStart: JSON.stringify({ start: 'ASC' }),
            },
        }) as unknown as ApiResponse<PayrollPeriodResource[]>;

    return response.data.map((payrollPeriod) => transformApiParamsToPayrollPeriod(payrollPeriod));
};

export const postPayrollPeriod = (data: PayrollPeriodFormData) => axios.post(
    `${process.env.REACT_APP_API_URL}/payroll-periods`,
    { data: transformFormDataToApiparams(data) },
);

export const patchPayrollPeriod = (data: PayrollPeriodFormData, id: string) => axios.patch(
    `${process.env.REACT_APP_API_URL}/payroll-periods/${id}`,
    { data: transformFormDataToApiparams(data, id) },
);

export const delPayrollPeriod = (id: string) => axios.delete(
    `${process.env.REACT_APP_API_URL}/payroll-periods/${id}`,
);

export const patchPayrollPeriodLocked = (id: string, isLocked = true) => axios.patch(
    `${process.env.REACT_APP_API_URL}/payroll-periods/${id}`,
    {
        data: {
            type: 'payrollPeriods',
            id,
            attributes: {
                locked: isLocked,
            },
        },
    },
);
