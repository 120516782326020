import axios from 'axios';

import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import {
    ApiResponse,
    Filter,
    PaginationRequestApiParams,
    ShiftExchangeResource,
    ShiftExchangeViewModel,
} from '../../models';
import transformShiftExchangeResource from '../../services/ShiftExchangeService/transformShiftExchangeResource';
import UpToAndIncludingDate from '../date/UpToAndIncludingDate';

export const getShiftExchangesInDateRange = async (
    startDate?: Date,
    endDate?: UpToAndIncludingDate,
    filter: Filter = {},
    departments: string[] = [],
    pagination?: PaginationRequestApiParams,
    includes: string[] = [],
): Promise<ShiftExchangeViewModel[]> => {
    const { employeeSearch } = filter;
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shift-exchanges/`,
        {
            params: {
                include: includes.join(','),
                ...(employeeSearch && { search: employeeSearch }),
                ...(departments && { departments }),
                startDate: startDate ? formatDate(startDate, DateFormat.apiDateTime) : undefined,
                statuses: JSON.stringify([1]),
                ...(pagination && { 'page[number]': pagination.number || 1 }),
                ...(pagination && { 'page[size]': pagination.size }),
            },
        },
    ) as unknown as ApiResponse<ShiftExchangeResource[]>;

    return response.data.map((exchange: ShiftExchangeResource) => transformShiftExchangeResource(
        exchange,
        exchange.shiftPlanning1,
        exchange.shiftPlanning2,
    ));
};

export const patchApproveShiftExchange = (shiftExchangeId: string) => axios.patch(`${process.env.REACT_APP_API_URL}/shift-exchanges/approve/${shiftExchangeId}`);
export const patchDeclineShiftExchange = (shiftExchangeId: string) => axios.patch(`${process.env.REACT_APP_API_URL}/shift-exchanges/decline/${shiftExchangeId}`);
