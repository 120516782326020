import { FC, ReactElement, useEffect } from 'react';

import { EditShiftTimeForm } from '../../../containers';
import { Period } from '../../../entities/Period/Period';
import trans from '../../../helpers/trans';
import { deleteShiftConceptPlanning, editShiftConceptPlanningTime } from '../../../redux/shiftConceptPlanning/shiftConceptPlanningActions';
import { setIsDeleteSuccessful, setIsEditSuccessful } from '../../../redux/shiftConceptPlanning/shiftConceptPlanningReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedEditShiftConceptPlanningTimeFormProps {
    shiftId: string;
    period: Period;
    onClose: () => void;
    className?: string;
}

const ConnectedEditShiftConceptPlanningTimeForm: FC<ConnectedEditShiftConceptPlanningTimeFormProps> = ({
    shiftId,
    period,
    onClose,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isDeleteSuccessful, isEditSuccessful } = useTypedSelector(state => state.shiftConceptPlanningReducer);

    const handleSubmit = (value: Period): void => {
        dispatch(editShiftConceptPlanningTime({ period: value }, shiftId));
    };

    const handleDeleteButtonClick = (): void => {
        dispatch(deleteShiftConceptPlanning(shiftId));
    };

    useEffect((): void => {
        if (isEditSuccessful) {
            onClose();

            dispatch(setIsEditSuccessful(false));
            dispatch(setToast({
                text: trans('containers.forms.editShiftTimeForm.editShiftTimeSuccess'),
                type: ToastType.pass,
            }));
        }
    }, [isEditSuccessful]);

    useEffect((): void => {
        if (isDeleteSuccessful) {
            onClose();

            dispatch(setIsDeleteSuccessful(false));
            dispatch(setToast({
                text: trans('containers.forms.editShiftTimeForm.deleteShiftSuccess'),
                type: ToastType.pass,
            }));
        }
    }, [isDeleteSuccessful]);

    return (
        <EditShiftTimeForm
            period={period}
            onCancel={onClose}
            onDeleteButtonClick={handleDeleteButtonClick}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedEditShiftConceptPlanningTimeForm;
