import { objectContainsValues } from '../@paco/helpers/tools/object';
import { stringContainsValue } from '../helpers/tools/validation';
import { translate } from '../helpers/translations/translator';

export type FormError<Value = string> = Value | undefined;
export type FormErrors<FormData, FormSubData = string> = Partial<Record<keyof FormData, FormError<FormSubData>>>;
export type FormValidation<Errors> = [Errors, boolean];

export const validateForm = <Errors>(errors: Errors): FormValidation<Errors> => {
    const hasErrors = objectContainsValues<Errors>(errors);

    return [
        errors,
        hasErrors,
    ];
};

export const validateRequiredString = (attribute: string, string?: string): FormError => {
    if (!stringContainsValue(string)) {
        return translate('validation.errors.isRequired', {
            attribute: translate(`validation.attributes.${attribute}`),
        });
    }

    return undefined;
};

