import { FC, ReactElement, useEffect } from 'react';

import { PzLogCategoriesFilterContainer } from '../../../containers';
import { fetchPzLogCategories } from '../../../redux/pzLogCategories/pzLogCategoriesActions';
import { setCategoryOptions } from '../../../redux/pzLogCategories/pzLogCategoriesReducer';
import { setPage } from '../../../redux/pzLogs/pzLogsReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { FormOption } from '../../../types';

const ConnectedPzLogCategoriesFilter: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const { categoryOptions } = useTypedSelector(state => state.pzLogCategoriesReducer);

    const onCategoriesFilterChange = (selectedCategoryOptions: FormOption[]) => {
        dispatch(setPage(1));
        dispatch(setCategoryOptions(selectedCategoryOptions));
    };

    useEffect((): void => {
        dispatch(fetchPzLogCategories());
    }, []);

    return (
        <PzLogCategoriesFilterContainer
            categoryOptions={categoryOptions}
            onChange={onCategoriesFilterChange}
        />
    );
};

export default ConnectedPzLogCategoriesFilter;
