import { transformLegacyCommentToComment } from '../../@paco/entities/Comment/CommentTransformers';
import { transformToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import { transformLegacyShiftConcept } from '../../@paco/entities/ShiftConcept/ShiftConceptTransformers';
import { transformLegacyTemporaryConceptWorker, transformToTemporaryWorker } from '../../@paco/entities/TemporaryWorker/TemporaryWorkerTransformers';
import { transformLegacyUserToUser } from '../../@paco/entities/User/UserTransformers';
import { addDays, compareTimeStrings, formatDate } from '../../helpers/date';
import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import { AddShiftFormData, EditShiftFormData, ShiftType } from '../../models';
import compareShiftPlanningUsersAlphabetically from '../../services/ShiftPlanningService/compareShiftPlanningUsersAlphabetically';
import transformShiftPlanningResource from '../../services/ShiftPlanningService/transformShiftPlanningResource';
import getShiftStatus from '../../services/ShiftService/getShiftStatus';
import { transformDepartmentResource } from '../Departments/DepartmentsTransformers';
import { PublishStatusResource, PublishStatusViewModel } from '../PublishStatus/PublishStatus';
import {
    AddShiftConceptRequest,
    EditShiftConceptRequest,
    ShiftConceptResource,
    ShiftConceptViewModelOld,
} from './ShiftConcept';

export const transformToShiftConcept = (legacyShiftConceptResource: ShiftConceptResource): ShiftConceptViewModelOld => {
    const start = new Date(legacyShiftConceptResource.start);
    const status = getShiftStatus([], legacyShiftConceptResource.peopleLimit, start);
    const shiftPlannings = (legacyShiftConceptResource.shiftConceptPlannings || [])
        .map((shiftConceptPlanning) => transformShiftPlanningResource(
            shiftConceptPlanning,
            shiftConceptPlanning.user,
            undefined,
            shiftConceptPlanning.comments,
        ))
        .sort(compareShiftPlanningUsersAlphabetically);

    const shiftConceptResource = transformLegacyShiftConcept(legacyShiftConceptResource);

    const temporaryWorkers = (legacyShiftConceptResource.temporaryConceptWorkers || [])
        .map(legacyTemporaryConceptWorker => {
            const temporaryConceptWorkerResource = transformLegacyTemporaryConceptWorker(legacyTemporaryConceptWorker, legacyTemporaryConceptWorker.comments);
            const comments = legacyTemporaryConceptWorker.comments.map(commentResource => {
                const userResource = commentResource.owner ? transformLegacyUserToUser(commentResource.owner) : undefined;

                return transformLegacyCommentToComment(commentResource, userResource);
            });

            return transformToTemporaryWorker(
                temporaryConceptWorkerResource,
                transformToPeriod(new Date(shiftConceptResource.attributes.start), new Date(shiftConceptResource.attributes.end)),
                comments,
            );
        })
        .sort((
            temporaryWorker,
            temporaryWorkerToCompare,
        ) => temporaryWorker.name.localeCompare(temporaryWorkerToCompare.name));

    return ({
        type: ShiftType.concept,
        id: legacyShiftConceptResource.id,
        status,
        start,
        shiftPlannings,
        temporaryWorkers,
        department: transformDepartmentResource(legacyShiftConceptResource.department),
        end: new UpToButExcludingDate(legacyShiftConceptResource.end).transformToUpToAndIncludingDate(),
        peopleLimit: legacyShiftConceptResource.peopleLimit,
    });
};

const getShiftStartAndEndDate = (startTime: string, endTime: string, date: Date): [string, string] => {
    const endTimeIsLater = compareTimeStrings(startTime, endTime);
    const startDate = formatDate(date, 'yyyy-MM-dd');
    const endDate = formatDate(addDays(date, endTimeIsLater ? 1 : 0), 'yyyy-MM-dd');
    const start = `${startDate} ${startTime}:00`;
    const end = `${endDate} ${endTime}:00`;

    return [start, end];
};

export const transformEditShiftFormData = (formData: EditShiftFormData, shiftConceptId: string): EditShiftConceptRequest => {
    const [start, end] = getShiftStartAndEndDate(formData.startTime, formData.endTime, formData.date);

    return {
        type: 'shiftConcepts',
        id: shiftConceptId,
        attributes: {
            start,
            end,
            peopleLimit: formData.peopleLimit || 0,
        },
    };
};

export const transformAddShiftFormData = (formData: AddShiftFormData, userId: string): AddShiftConceptRequest => {
    const [start, end] = getShiftStartAndEndDate(formData.startTime, formData.endTime, formData.date);

    return {
        type: 'shiftConcepts',
        attributes: {
            start,
            end,
            peopleLimit: formData.peopleLimit || 0,
        },
        relationships: {
            department: {
                data: {
                    type: 'departments',
                    id: formData.department || '',
                },
            },
            user: {
                data: {
                    type: 'users',
                    id: userId,
                },
            },
        },
    };
};

export const transformPublishStatus = (
    resource: PublishStatusResource,
    id: string,
): PublishStatusViewModel => ({
    id,
    name: resource.name,
    status: resource.status,
    message: resource.message,
    department: resource.department,
    ...(resource.customStart && { customStart: new Date(resource.customStart) }),
    ...(resource.customEnd && { customEnd: new Date(resource.customEnd) }),
    ...(resource.shiftStart && { shiftStart: new Date(resource.shiftStart) }),
    ...(resource.shiftEnd && { shiftEnd: new Date(resource.shiftEnd) }),
});
