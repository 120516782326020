import { FC, useEffect } from 'react';

import ErrorPage from '../../../../pages/ErrorPage';
import AddDistrictDivisionGroupTemplateForm from '../../../containers/@forms/AddDistrictDivisionGroupTemplateForm/AddDistrictDivisionGroupTemplateForm';
import { AddDistrictDivisionGroupTemplateFormData } from '../../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import trans from '../../../helpers/trans';
import { addDistrictDivisionGroupTemplate } from '../../../redux/districtDivisionGroupTemplate/districtDivisionGroupTemplateActions';
import { setIsSuccessful } from '../../../redux/districtDivisionGroupTemplate/districtDivisionGroupTemplateReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedAddDistrictDivisionGroupTemplateFormProps {
    onClose: () => void;
}

const ConnectedAddDistrictDivisionGroupTemplateForm: FC<ConnectedAddDistrictDivisionGroupTemplateFormProps> = ({
    onClose,
}) => {
    const dispatch = useTypedDispatch();
    const { isLoading, isSuccessful } = useTypedSelector(state => state.districtDivisionGroupTemplateReducer);
    const { districtDivisionTemplate } = useTypedSelector(state => state.districtDivisionTemplateReducer);

    const handleAddDistrictDivisionGroupTemplate = (addDistrictDivisionGroupTemplateFormData: AddDistrictDivisionGroupTemplateFormData): void => {
        dispatch(addDistrictDivisionGroupTemplate(addDistrictDivisionGroupTemplateFormData));
    };

    useEffect(() => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.addDistrictDivisionGroupTemplateForm.addDistrictDivisionGroupTemplateSuccess'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isSuccessful]);

    if (!districtDivisionTemplate) {
        return (
            <ErrorPage errorCode={404} />
        );
    }

    return (
        <AddDistrictDivisionGroupTemplateForm
            isLoading={isLoading}
            districtDivisionTemplate={districtDivisionTemplate}
            onSubmit={handleAddDistrictDivisionGroupTemplate}
            onCancel={onClose}
        />
    );
};

export default ConnectedAddDistrictDivisionGroupTemplateForm;
