import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { EditShiftConceptTimeFormData } from '../../entities/ShiftConcept/ShiftConcept';
import { deleteShiftConceptApiCall, editShiftConceptTimeApiCall } from '../../entities/ShiftConcept/ShiftConceptService';
import { TypedDispatch } from '../store';
import {
    setDeletedShiftConceptId,
    setError,
    setIsDeleteShiftConceptSuccessful,
    setIsEditShiftConceptSuccessful,
    setIsLoading,
    setShiftConcept,
} from './shiftConceptReducer';

export const editShiftConceptTime = (editShiftConceptTimeFormData: EditShiftConceptTimeFormData, shiftConceptId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsEditShiftConceptSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await editShiftConceptTimeApiCall(editShiftConceptTimeFormData, shiftConceptId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftConcept(response.data));
        dispatch(setIsEditShiftConceptSuccessful(true));
    } catch (error) {
        console.error('[editShiftConceptTime]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteShiftConcept = (shiftId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsDeleteShiftConceptSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await deleteShiftConceptApiCall(shiftId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDeletedShiftConceptId(shiftId));
        dispatch(setIsDeleteShiftConceptSuccessful(true));
    } catch (error) {
        console.error('[deleteShiftConcept]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
