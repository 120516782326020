import { Comment as CommentsModalComment } from '../../../../../components/CommentsModal/CommentsModal';
import { getUserFullName } from '../../../../../helpers';
import { Absence, CommentResource, LeaveOfAbsence } from '../../../../../models';

function transformCommentToCommentsModalComment(comment: CommentResource): CommentsModalComment {
    return {
        id: comment.id,
        user: getUserFullName(comment.owner),
        body: comment.body,
    };
}

function transformLeaveOfAbsenceToComment(absence: LeaveOfAbsence): CommentsModalComment {
    return {
        id: absence.id,
        user: getUserFullName(absence.user),
        body: absence.reason,
    };
}

export default function getCommentsFromAbsence(
    absence: Absence | LeaveOfAbsence,
): CommentsModalComment[] {
    const reason = absence.type === 'leaveOfAbsences' ? [transformLeaveOfAbsenceToComment(absence)] : [];

    const comments = (absence.comments || [])
        .map((comment) => transformCommentToCommentsModalComment(comment));

    return [...reason, ...comments];
}
