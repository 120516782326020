import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { Shift, ShiftIndex } from '../../entities/Shift/Shift';
import { ShiftPlanning } from '../../entities/ShiftPlanning/ShiftPlanning';
import { setLastAddedShiftPlanning, setLastDeletedShiftPlanningId, setShiftPlanning } from '../shiftPlanning/shiftPlanningReducer';
import { setLastAddedTemporaryWorker, setLastDeletedTemporaryWorkerId, setLastEditedTemporaryWorker } from '../temporaryWorker/temporaryWorkerReducer';

export type ShiftDetailState = AsyncReduxState<{
    isLoadingRecentShiftPlannings: boolean;
    isLoadingShift: boolean;
    isLoadingSimilarShifts: boolean;
    shift?: ShiftIndex;
    recentShiftPlannings: ShiftPlanning[];
    similarShifts: Shift[];
}>;

const initialState: ShiftDetailState = {
    ...initialAsyncReduxState,
    isLoadingRecentShiftPlannings: false,
    isLoadingShift: false,
    isLoadingSimilarShifts: false,
    recentShiftPlannings: [],
    similarShifts: [],
};

export const shiftDetailSlice = createSlice({
    name: 'shiftDetailReducer',
    initialState,
    reducers: {
        reset: (): ShiftDetailState => initialState,
        setIsLoading(state, action: PayloadAction<boolean>): ShiftDetailState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsLoadingShift(state, action: PayloadAction<boolean>): ShiftDetailState {
            return {
                ...state,
                isLoadingShift: action.payload,
            };
        },
        setIsLoadingRecentShiftPlannings(state, action: PayloadAction<boolean>): ShiftDetailState {
            return {
                ...state,
                isLoadingRecentShiftPlannings: action.payload,
            };
        },
        setIsLoadingSimilarShifts(state, action: PayloadAction<boolean>): ShiftDetailState {
            return {
                ...state,
                isLoadingSimilarShifts: action.payload,
            };
        },
        setRecentShiftPlannings(state, action: PayloadAction<ShiftPlanning[]>): ShiftDetailState {
            return {
                ...state,
                recentShiftPlannings: action.payload,
            };
        },
        setShift(state, action: PayloadAction<ShiftIndex>): ShiftDetailState {
            return {
                ...state,
                shift: action.payload,
            };
        },
        setSimilarShifts(state, action: PayloadAction<Shift[]>): ShiftDetailState {
            return {
                ...state,
                similarShifts: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ShiftDetailState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setLastDeletedShiftPlanningId, (state, action): ShiftDetailState => {
            if (!state.shift || !action.payload) {
                return state;
            }

            const shiftPlanningIndex = state.shift.shiftPlannings
                .findIndex(shiftPlanning => shiftPlanning.id === action.payload);

            if (shiftPlanningIndex === -1) {
                return state;
            }

            const updatedShiftPlanning = [...state.shift.shiftPlannings];
            updatedShiftPlanning.splice(shiftPlanningIndex, 1);

            const updatedShift: ShiftIndex = {
                ...state.shift,
                shiftPlannings: updatedShiftPlanning,
            };

            return {
                ...state,
                shift: updatedShift,
            };
        });

        builder.addCase(setLastAddedShiftPlanning, (state, action): ShiftDetailState => {
            if (!state.shift) {
                return state;
            }

            const updatedShiftPlanning = [
                ...state.shift.shiftPlannings,
                action.payload,
            ];

            const updatedShift: ShiftIndex = {
                ...state.shift,
                shiftPlannings: updatedShiftPlanning,
            };


            return {
                ...state,
                shift: updatedShift,
            };
        });

        builder.addCase(setShiftPlanning, (state, action): ShiftDetailState => {
            if (!state.shift || !action.payload) {
                return state;
            }

            const shiftPlanningIndex = state.shift.shiftPlannings
                .findIndex(shiftPlanning => shiftPlanning.id === action.payload.id);

            if (shiftPlanningIndex === -1) {
                return state;
            }

            const updatedShiftPlannings = [...state.shift.shiftPlannings];
            updatedShiftPlannings.splice(shiftPlanningIndex, 1, action.payload);

            const updatedShift: ShiftIndex = {
                ...state.shift,
                shiftPlannings: updatedShiftPlannings,
            };

            return {
                ...state,
                shift: updatedShift,
            };
        });

        builder.addCase(setLastDeletedTemporaryWorkerId, (state, action): ShiftDetailState => {
            if (!state.shift || !action.payload) {
                return state;
            }

            const temporaryWorkerIndex = state.shift.temporaryWorkers
                .findIndex(temporaryWorker => temporaryWorker.id === action.payload);

            if (temporaryWorkerIndex === -1) {
                return state;
            }

            const updatedTemporaryWorkers = [...state.shift.temporaryWorkers];
            updatedTemporaryWorkers.splice(temporaryWorkerIndex, 1);

            const updatedShift: ShiftIndex = {
                ...state.shift,
                temporaryWorkers: updatedTemporaryWorkers,
            };

            return {
                ...state,
                shift: updatedShift,
            };
        });

        builder.addCase(setLastAddedTemporaryWorker, (state, action): ShiftDetailState => {
            if (!state.shift) {
                return state;
            }

            const updatedShift: ShiftIndex = {
                ...state.shift,
                temporaryWorkers: [
                    ...state.shift.temporaryWorkers,
                    action.payload,
                ],
            };

            return {
                ...state,
                shift: updatedShift,
            };
        });

        builder.addCase(setLastEditedTemporaryWorker, (state, action): ShiftDetailState => {
            if (!state.shift) {
                return state;
            }

            const temporaryWorkerIndex = state.shift.temporaryWorkers
                .findIndex(temporaryWorker => temporaryWorker.id === action.payload.id);

            if (temporaryWorkerIndex === -1) {
                return state;
            }

            const updatedTemporaryWorkers = [...state.shift.temporaryWorkers];
            updatedTemporaryWorkers.splice(temporaryWorkerIndex, 1, action.payload);

            const updatedShift: ShiftIndex = {
                ...state.shift,
                temporaryWorkers: updatedTemporaryWorkers,
            };

            return {
                ...state,
                shift: updatedShift,
            };
        });
    },
});

export const {
    reset,
    setIsLoading,
    setIsLoadingRecentShiftPlannings,
    setIsLoadingShift,
    setIsLoadingSimilarShifts,
    setRecentShiftPlannings,
    setShift,
    setSimilarShifts,
    setError,
} = shiftDetailSlice.actions;

export default shiftDetailSlice.reducer;
