import { ConfigurablePermission, CoupledPermissions, Permission } from '../../../entities/Permission/Permission';
import { PermissionGroup } from '../../../entities/PermissionGroup/PermissionGroup';
import trans from '../../../helpers/trans';
import { FormOptionsGroup } from '../../../types';

export const transformPermissionGroupToFormOptions = (
    permissionGroup: PermissionGroup,
    userPermissions: Permission[],
    configurableUserPermissions: ConfigurablePermission[],
): FormOptionsGroup => ({
    label: permissionGroup.name,
    value: permissionGroup.id,
    options: permissionGroup.permissions
        .filter(permission => userPermissions.find(userPermission => userPermission.id === permission.id)
            || configurableUserPermissions.find(configurableUserPermission => configurableUserPermission.id === permission.id))
        .map(permission => {
            const matchingConfigurablePermission = configurableUserPermissions.find(configurablePermission => configurablePermission.id === permission.id);
            const hasUserPermission = userPermissions.map(userPermission => userPermission.id).includes(permission.id);
            const childNames = matchingConfigurablePermission?.children.map(child => child.name).join(', ');
            const permissionLabel = `${permission.name}. ${childNames ? ` ${trans('containers.permissionsList.alsoCoupledWith')}: ${childNames}` : ''}`;

            return {
                label: permissionLabel,
                value: permission.id,
                isChecked: hasUserPermission,
                isDisabled: !matchingConfigurablePermission || matchingConfigurablePermission.hasParent,
                group: {
                    label: permissionGroup.name,
                    value: permissionGroup.id,
                },
            };
        }),
});

const getConfigurablePermissionChildrenIds = (
    configurablePermissions: ConfigurablePermission[],
    id: string,
): string[] => {
    const permission = configurablePermissions
        .find(configurablePermission => configurablePermission.id === id);

    if (!permission?.children) {
        return [];
    }

    return permission.children
        .map(childPermission => {
            const match = configurablePermissions
                .find(configurablePermission => configurablePermission.slug === childPermission.slug);
            return match ? match.id : '';
        })
        .filter(Boolean);
};


export const getConfigurablePermissionsChildrenIds = (
    configurablePermissions: ConfigurablePermission[],
    activeIds: string[],
) => activeIds
    .reduce((total: string[], activeId) => {
        const ids = getConfigurablePermissionChildrenIds(
            configurablePermissions,
            activeId,
        );

        return [...total, ...ids];
    }, []);


const getPermissionChildren = (
    permission: Permission,
    coupledPermissions: CoupledPermissions[],
): string[] => {
    const matchedPermission = coupledPermissions.find((coupledPermission) => coupledPermission.parents
        .find(coupledPermissionParent => coupledPermissionParent === permission.slug));

    return matchedPermission ? matchedPermission.children : [];
};

const doesPermissionHaveParent = (
    permission: Permission,
    coupledPermissions: CoupledPermissions[],
): boolean => coupledPermissions
    .some(coupledPermission => coupledPermission.children.includes(permission.slug));

export const transformPermissionsToConfigurablePermissions = (
    permissions: Permission[] = [],
    coupledPermissions: CoupledPermissions[] = [],
): ConfigurablePermission[] => permissions.map(permission => {
    const childrenSlugs = getPermissionChildren(permission, coupledPermissions);
    const children = permissions.filter(childPermission => childrenSlugs.includes(childPermission.slug));
    const hasParent = doesPermissionHaveParent(permission, coupledPermissions);

    return {
        ...permission,
        children,
        hasParent,
    };
});
