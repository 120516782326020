import { postCommentApiCall } from '../../entities/Comment/CommentService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { AddTemporaryWorkerFormData, EditTemporaryWorkerFormData } from '../../entities/TemporaryWorker/TemporaryWorker';
import {
    deleteTemporaryWorkerColorApiCall,
    patchTemporaryWorkerApiCall,
    patchTemporaryWorkerColorApiCall,
    postTemporaryWorkerApiCall,
} from '../../entities/TemporaryWorker/TemporaryWorkerService';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsDeleteSuccessful,
    setIsLoading,
    setIsSuccessful,
    setLastAddedTemporaryWorker,
    setLastDeletedTemporaryWorkerId,
    setLastEditedTemporaryWorker,
} from './temporaryWorkerReducer';

export const addTemporaryWorker = (formData: AddTemporaryWorkerFormData, shiftId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await postTemporaryWorkerApiCall(shiftId, formData.name);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLastAddedTemporaryWorker(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addTemporaryWorker]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editTemporaryWorker = (formData: EditTemporaryWorkerFormData, temporaryWorkerId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        if (formData.comment) {
            await postCommentApiCall({
                comment: formData.comment,
                relationshipId: temporaryWorkerId,
                relationshipKey: 'temporaryWorker',
                relationshipType: 'temporaryWorkers',
            });
        }

        const response = await patchTemporaryWorkerApiCall(temporaryWorkerId, formData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLastEditedTemporaryWorker(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editTemporaryWorker]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editTemporaryWorkerColor = (temporaryWorkerId: string, color?: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await patchTemporaryWorkerColorApiCall(temporaryWorkerId, color);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLastEditedTemporaryWorker(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editTemporaryWorkerColor]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteTemporaryWorker = (temporaryWorkerId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsDeleteSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await deleteTemporaryWorkerColorApiCall(temporaryWorkerId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLastDeletedTemporaryWorkerId(temporaryWorkerId));
        dispatch(setIsDeleteSuccessful(true));
    } catch (error) {
        console.error('[deleteTemporaryWorker]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

