import { CoupledPermissions } from '../../entities/Permission/Permission';

const coupledPermissions: CoupledPermissions[] = [
    {
        group: 'editAddresses',
        parents: [
            'edit-all-addresses',
        ],
        children: [
            'add-new-addresses',
        ],
    },
    {
        group: 'editEmergencyContacts',
        parents: [
            'edit-all-emergency-contacts',
        ],
        children: [
            'add-new-emergency-contacts',
        ],
    },
    {
        group: 'viewLeaveOfAbsence',
        parents: [
            'view-all-leave-of-absences',
        ],
        children: [
            'view-all-leave-of-absence-hours',
        ],
    },
    {
        group: 'addLeaveOfAbsence',
        parents: [
            'add-new-leave-of-absences',
            'add-new-employee-leave-of-absences',
            'add-new-junior-manager-leave-of-absences',
            'add-new-manager-leave-of-absences',
        ],
        children: [
            'add-new-leave-of-absence-hours',
        ],
    },
    {
        group: 'editJuniorManagerLeaveOfAbsence',
        parents: [
            'edit-junior-manager-leave-of-absences',
        ],
        children: [
            'add-new-leave-of-absence-hours',
            'edit-junior-manager-leave-of-absence-hours',
        ],
    },
    {
        group: 'editEmployeeLeaveOfAbsence',
        parents: [
            'edit-employee-leave-of-absences',
        ],
        children: [
            'add-new-leave-of-absence-hours',
            'edit-employee-leave-of-absence-hours',
        ],
    },
    {
        group: 'addEmployeeAbsenceHours',
        parents: [
            'add-new-employee-absence-hours',
        ],
        children: [
            'edit-employee-absence-hours',
            'delete-all-absence-hours',
        ],
    },
    {
        group: 'addAllAbsenceHours',
        parents: [
            'add-new-absence-hours',
        ],
        children: [
            'edit-junior-manager-absence-hours',
            'delete-all-absence-hours',
        ],
    },
    {
        group: 'viewShifts',
        parents: [
            'view-all-shifts',
        ],
        children: [
            'view-all-shift-plannings',
        ],
    },
    {
        group: 'viewShiftConcept',
        parents: [
            'view-all-shift-concepts',
        ],
        children: [
            'view-all-shift-concept-plannings',
        ],
    },
    {
        group: 'viewBaseScheduleShifts',
        parents: [
            'view-all-base-schedule-shifts',
        ],
        children: [
            'view-all-base-schedule-shift-plannings',
        ],
    },
    {
        group: 'editBaseScheduleShifts',
        parents: [
            'edit-all-base-schedule-shifts',
        ],
        children: [
            'delete-all-base-schedule-shifts',
            'add-new-base-schedule-shift-plannings',
            'edit-all-base-schedule-shift-plannings',
            'delete-all-base-schedule-shift-plannings',
        ],
    },
    {
        group: 'editShiftConcept',
        parents: [
            'edit-all-shift-concepts',
        ],
        children: [
            'delete-all-shift-concepts',
            'add-new-shift-concept-plannings',
            'edit-all-shift-concept-plannings',
            'delete-all-shift-concept-plannings',
        ],
    },
    {
        group: 'editShift',
        parents: [
            'edit-all-shifts',
        ],
        children: [
            'delete-all-shifts',
            'add-new-shift-plannings',
            'edit-all-shift-plannings',
            'delete-all-shift-plannings',
        ],
    },
    {
        group: 'editDepartments',
        parents: [
            'add-new-departments',
            'edit-all-departments',
        ],
        children: [
            'edit-all-groups',
            'add-new-groups',
            'delete-all-groups',
        ],
    },
];

export default coupledPermissions;
