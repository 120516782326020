import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { translate } from '../../helpers/translations/translator';

import './UserPageLink.scss';

const UserPageLink = ({
    isCompanyMedic = false,
    isPlain = false,
    id = '',
    name = '',
    className = '',
}) => {
    const linkClassName = classnames('user-page-link', {
        'user-page-link--is-company-medic': isCompanyMedic,
        'user-page-link--is-plain': isPlain,
    }, className);

    const pathSchedule = `/${translate('nav.userManagement.link')}`;
    const path = `${pathSchedule}/${translate('nav.userManagement.users.link')}/${id}`;

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {
                id ? (
                    <NavLink
                        to={path}
                        className={`${linkClassName} user-page-link--with-id`}
                    >
                        { name }
                    </NavLink>
                ) : (
                    <span
                        className={`${linkClassName} user-page-link--disabled`}
                    >
                        { name }
                    </span>
                )
            }
        </>
    );
};

UserPageLink.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    isPlain: PropTypes.bool,
};

export default UserPageLink;
