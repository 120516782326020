import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { ScheduleRoutes } from '../../@paco/routes/helpers';
import Availability from './Availability';
import BaseSchedule from './BaseSchedule';
import BaseSchedules from './BaseSchedules';
import BaseScheduleShiftDetail from './BaseScheduleShiftDetail';
import ConnectedShiftConceptDetail from './connectors/ConnectedShiftConceptDetail/ConnectedShiftConceptDetail';
import ListViewAndConcepts from './ListViewAndConcepts';
import SpecialDays from './SpecialDays';
import EmployeeHoursOverview from './UsersWithLoketBalancesOverview';

const LegacyScheduleRouteGroup = (props) => {
    const propsWithDefaults = {
        currentUser: {
            departments: {},
        },
        logs: null,
        logsResourceId: null,
        ...props,
    };

    return (
        <Routes>
            <Route path={ScheduleRoutes.baseScheduleShift(':id')} element={<BaseScheduleShiftDetail />} />
            <Route path={ScheduleRoutes.baseSchedule(':id')} element={<BaseSchedule />} />
            <Route path={ScheduleRoutes.baseSchedules()} element={<BaseSchedules />} />
            <Route path={ScheduleRoutes.specialDays()} element={<SpecialDays {...propsWithDefaults} />} />
            <Route path={ScheduleRoutes.availability()} element={<Availability {...propsWithDefaults} />} />
            <Route path={ScheduleRoutes.listView()} element={<ListViewAndConcepts {...propsWithDefaults} />} />
            <Route path={ScheduleRoutes.hours()} element={<EmployeeHoursOverview {...propsWithDefaults} />} />
            <Route path={ScheduleRoutes.shiftConcepts(':id')} element={<ConnectedShiftConceptDetail />} />
        </Routes>
    );
};

LegacyScheduleRouteGroup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    lastAddedShiftId: PropTypes.string.isRequired,
    shifts: PropTypes.array.isRequired,
    shift: PropTypes.object.isRequired,
    shiftSnapshot: PropTypes.object.isRequired,
    similarShifts: PropTypes.array.isRequired,
    specialDays: PropTypes.array.isRequired,
    specialDayTypes: PropTypes.array.isRequired,
    selectedDays: PropTypes.array.isRequired,
    selectedDate: PropTypes.object.isRequired,
    preferToWorkTimeSlots: PropTypes.array.isRequired,
    unavailableToWorkTimeSlots: PropTypes.array.isRequired,
    absences: PropTypes.array.isRequired,
    usersPlannedOnShiftDate: PropTypes.array.isRequired,
    leaveOfAbsences: PropTypes.array.isRequired,
    pacoPayrollPeriods: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    mode: PropTypes.string.isRequired,
    timeMode: PropTypes.string.isRequired,
    currentUser: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    planLoading: PropTypes.bool.isRequired,
    exportCSVRequest: PropTypes.bool.isRequired,
    filter: PropTypes.object.isRequired,
    employees: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    departmentOptions: PropTypes.array.isRequired,
    appHistory: PropTypes.array.isRequired,
    logs: PropTypes.array,
    logsResourceId: PropTypes.string,
    logsLoading: PropTypes.bool.isRequired,
    userRoutes: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        shifts: state.shiftsReducer.shifts,
        shift: state.shiftsReducer.shift,
        similarShifts: state.shiftsReducer.similarShifts,
        shiftSnapshot: state.shiftsReducer.shiftSnapshot,
        lastAddedShiftId: state.shiftsReducer.lastAddedShiftId,
        specialDays: state.shiftsReducer.specialDays,
        specialDayTypes: state.shiftsReducer.specialDayTypes,
        preferToWorkTimeSlots: state.shiftsReducer.preferToWorkTimeSlots,
        unavailableToWorkTimeSlots: state.shiftsReducer.unavailableToWorkTimeSlots,
        absences: state.shiftsReducer.absences,
        usersPlannedOnShiftDate: state.shiftsReducer.usersPlannedOnShiftDate,
        startDate: state.weekNavigatorReducer.startDate,
        endDate: state.weekNavigatorReducer.endDate,
        mode: state.weekNavigatorReducer.mode,
        selectedDays: state.weekNavigatorReducer.selectedDays,
        selectedDate: state.weekNavigatorReducer.selectedDate,
        leaveOfAbsences: state.absencesReducer.openLeaveOfAbsencesRequests,
        leaveOfAbsencesLoading: state.absencesReducer.loading,
        shiftsLeaveOfAbsences: state.shiftsReducer.leaveOfAbsences,
        managementLoading: state.managementReducer.loading,
        timeMode: state.weekNavigatorReducer.mode,
        loading: state.shiftsReducer.loading,
        planLoading: state.shiftsReducer.planLoading,
        exportCSVRequest: state.tracksReducer.exportCSVRequest,
        currentUser: state.authenticatedUserReducer.user,
        filter: state.filterReducer.filter,
        employees: state.usersReducer.employees,
        departments: state.authenticatedUserReducer.departments,
        departmentOptions: state.globalFiltersReducer.departmentOptions,
        roles: state.appReducer.roles,
        pacoPayrollPeriods: state.pacoPayrollPeriodsReducer.payrollPeriods,
        payrollPeriods: state.appReducer.payrollPeriods,
        appHistory: state.appReducer.history,
        permissions: state.authenticatedUserReducer.permissions,
        logs: state.logsReducer.logs,
        logsLoading: state.logsReducer.loading,
        logsResourceId: state.logsReducer.logsResourceId,
        userRoutes: state.authenticatedUserReducer.permittedUserRoutes,
    };
}

export default connect(mapStateToProps)(LegacyScheduleRouteGroup);
