import { getDepartmentsIndexCall } from '../../../entities/Department/DepartmentService';
import { isFetchCollectionResultSuccessful } from '../../../entities/FetchResult';
import { TypedDispatch } from '../../store';
import { setDepartmentOptions, setError, setIsLoading } from './optionsReducer';

export const fetchDepartmentOptions = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getDepartmentsIndexCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDepartmentOptions(response.data));
    } catch (error) {
        console.error('[fetchDepartmentOptions]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
