import React, { FC } from 'react';

import classnames from 'classnames';

import getImageSource from './helpers/getImageSource';
import getSvgSource from './helpers/getSvgSource';

import './Icon.scss';

interface IconProps {
    kind: string;
    color?: string;
    className?: string;
    fontSize?: number;
}

const Icon: FC<IconProps> = ({
    className = '',
    kind,
    color,
    fontSize = 22,
}) => {
    const image = getImageSource(kind);
    const SvgComponent = getSvgSource(kind);
    const materialClassNames = classnames('material-icons icon', {
        [`text-${color}`]: color,
    }, [className]);

    if (SvgComponent) {
        return (
            <SvgComponent className={className} />
        );
    }

    // Fix for jest: svg's are returned as empty object.
    if (typeof image === 'string') {
        return (
            <img
                className={`icon-img ${className}`}
                alt="icon"
                style={{ width: `${fontSize}px` }}
                src={image}
            />
        );
    }

    return (
        <i
            className={materialClassNames}
            style={{ fontSize }}
        >
            { kind }
        </i>
    );
};

export default Icon;
