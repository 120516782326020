import { validateTime } from '../../../../../helpers/tools/validation';
import { translate } from '../../../../../helpers/translations/translator';
import { AddShiftFormData } from '../../../../../models';

export interface ValidationErrors {
    department?: string;
    date?: string;
    startTime?: string;
    endTime?: string;
    peopleLimit?: string;
}

export default function validateFormData(formData: AddShiftFormData, isSubmitted = true): ValidationErrors {
    const errors: ValidationErrors = {};

    if (!isSubmitted) {
        return {};
    }

    if (!formData.department) {
        errors.department = translate('validation.isRequired', {
            item: translate('common.department'),
        });
    }

    if (!formData.date) {
        errors.date = translate('validation.isRequired', {
            item: translate('common.date'),
        });
    }

    if (!validateTime(formData.startTime)) {
        errors.startTime = translate('validation.isNotValid', {
            item: translate('pages.tracks.startTime'),
        });
    }

    if (!validateTime(formData.endTime)) {
        errors.endTime = translate('validation.isNotValid', {
            item: translate('pages.tracks.endTime'),
        });
    }

    if (!formData.peopleLimit || formData.peopleLimit < 1) {
        errors.peopleLimit = translate('validation.peopleLimitNotValid');
    }

    return errors;
}
