import { HelpToolTip } from './helpReducer';

export const getTooltipsWithNewTooltip = (newTooltipIndex: number, tooltips: HelpToolTip[]): HelpToolTip[] => {
    const isDuplicate = tooltips.some(tooltip => tooltip.index === newTooltipIndex);

    if (isDuplicate) {
        return tooltips;
    }

    const newTooltips = [
        ...tooltips,
        { isActive: false, index: newTooltipIndex },
    ];

    return newTooltips.sort((a, b) => (a.index < b.index ? -1 : 1));
};
