import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import { postCommentApiCall } from '../../@paco/entities/Comment/CommentService';
import { getCheckedFormOptionsValue } from '../../@paco/helpers/types/formOption';
import { PAGE_SIZES, TIME_MODES, TOAST_TYPE_PASS } from '../../constants';
import { getFilenameAndGenerateCSV } from '../../helpers';
import {
    addTrackCall,
    createApproveTrackData,
    createEditTrackData,
    deleteTrackCall,
    getIncludes,
    getLoketCSV,
    getTracksCSV,
    getUserTracks,
    getUserTracksCSV,
    postSyncTvt,
    updateTrack,
} from '../../helpers/api/tracksApi';
import { getTracksInDateRangeLegacy } from '../../helpers/api-ts/track';
import { startOfMonth } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import {
    APP_SAGA_ADD_TOAST,
    PAGINATION_SET_PAGINATION,
    TRACK_ADD_TRACK,
    TRACK_REMOVE,
    TRACK_REMOVE_REQUEST,
    TRACK_REMOVE_SUCCESS,
    TRACK_SAGA_ADD_TRACK,
    TRACK_SAGA_APPROVE_TRACK,
    TRACK_SAGA_DELETE_TRACK,
    TRACK_SAGA_EDIT_TRACK,
    TRACK_SAGA_STOP_TRACK,
    TRACKS_EXPORT_CSV_REQUEST,
    TRACKS_EXPORT_CSV_SUCCESS,
    TRACKS_FAILURE,
    TRACKS_GET_USER_TRACKS_FAILURE,
    TRACKS_GET_USER_TRACKS_REQUEST,
    TRACKS_GET_USER_TRACKS_SUCCESS,
    TRACKS_REQUEST,
    TRACKS_SAGA_EXPORT_CSV,
    TRACKS_SAGA_GET_TRACKS,
    TRACKS_SAGA_GET_USER_TRACKS,
    TRACKS_SAGA_SYNC_TVT,
    TRACKS_SUCCESS,
    TRACKS_SYNC_TVT_FAILURE,
    TRACKS_SYNC_TVT_REQUEST,
    TRACKS_SYNC_TVT_SUCCESS,
} from '../actionTypes';

function* fetchTracks(action) {
    yield put({ type: TRACKS_REQUEST, trackType: action.trackType });

    try {
        const {
            filterReducer,
            paginationReducer,
            weekNavigatorReducer,
            globalFiltersReducer,
        } = yield select();
        let { startDate } = weekNavigatorReducer;
        const { trackType } = action;
        const { endDate, mode } = weekNavigatorReducer;
        const { filter } = filterReducer;
        const { departmentOptions } = globalFiltersReducer;
        const departmentIds = getCheckedFormOptionsValue(departmentOptions);

        // Weeknavigator was originally made for calendars, so we apply a small correction
        // to startDate here
        if (mode === TIME_MODES.MONTH) {
            startDate = startOfMonth(endDate);
        }

        const response = yield call(() => getTracksInDateRangeLegacy(
            startDate,
            endDate,
            action.trackType,
            {
                departments: departmentIds,
                employeeSearch: filter.employeeSearch,
                employeeContractTypes: filter.employeeContractTypes,
            },
            {
                number: paginationReducer.number,
                size: PAGE_SIZES.TRACKS[trackType],
            },
            getIncludes(action.trackType),
        ));
        const { pagination, data } = response;

        if (pagination) {
            yield put({
                type: PAGINATION_SET_PAGINATION,
                pages: pagination.pages,
                size: pagination.size,
            });
        }

        yield put({ type: TRACKS_SUCCESS, tracks: data });
    } catch (error) {
        yield put({ type: TRACKS_FAILURE, errors: [error] });
    }
}

function* stopTrack(action) {
    yield put({ type: TRACKS_REQUEST });

    const department = {
        id: action.data.department,
        type: 'departments',
    };

    // eslint-disable-next-line no-param-reassign
    action.data.accepted = 1;

    // eslint-disable-next-line no-param-reassign
    delete action.data.department;

    const track = {
        data: {
            type: 'tracks',
            id: action.id,
            attributes: {
                ...action.data,
            },
            relationships: {
                department,
            },
        },
    };

    const response = yield call(() => updateTrack(action.id, track));

    yield put({ type: TRACK_REMOVE, response });
    yield put({ type: TRACK_REMOVE_SUCCESS });
}

function* approveTrack(action) {
    const track = createApproveTrackData(action);

    yield put({ type: TRACK_REMOVE_REQUEST });
    yield put({ type: TRACK_REMOVE, response: { data: track.data } });

    try {
        yield call(() => updateTrack(action.id, track));
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.tracks.approveTrackSuccess'), toastType: TOAST_TYPE_PASS });

        const state = yield select();
        if (state.tracksReducer.tracks.length === 0) {
            yield fetchTracks({ trackType: state.tracksReducer.trackType });
        }
        yield put({ type: TRACK_REMOVE_SUCCESS });
    } catch (error) {
        yield put({ type: TRACKS_FAILURE, error });
    }
}

function* editTrack(action) {
    const track = createEditTrackData(action);
    try {
        yield call(() => updateTrack(action.id, track));
        const state = yield select();
        yield put({ type: TRACKS_SAGA_GET_TRACKS, trackType: state.tracksReducer.trackType });
        const toast = action.approved ? 'editAndApproveTrackSuccess' : 'editTrackSuccess';
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate(`pages.tracks.${toast}`), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: TRACKS_FAILURE, error });
    }
}

function* addTrack(action) {
    yield put({ type: TRACK_ADD_TRACK });
    try {
        const response = yield call(() => addTrackCall(action.data));

        if (action.data.comment) {
            yield postCommentApiCall({
                comment: action.data.comment,
                relationshipId: response.data.id,
                relationshipType: 'tracks',
                relationshipKey: 'track',
            });
        }

        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.tracks.addTrackSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        console.error(error);
        yield put({ type: TRACKS_FAILURE, error });
    }

    const state = yield select();
    const { trackType } = state.tracksReducer;
    yield put({ type: TRACKS_SAGA_GET_TRACKS, loading: true, trackType });
}

function* deleteTrack(action) {
    yield put({ type: TRACK_REMOVE_REQUEST });
    try {
        yield call(() => deleteTrackCall(action.id));
        yield put({ type: TRACK_REMOVE_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.tracks.deleteTrackSuccess'), toastType: TOAST_TYPE_PASS });
        const state = yield select();
        const { trackType } = state.tracksReducer;
        yield put({ type: TRACKS_SAGA_GET_TRACKS, loading: true, trackType });
    } catch (error) {
        yield put({ type: TRACK_REMOVE_SUCCESS, error });
    }
}

function* exportCSV(action) {
    yield put({ type: TRACKS_EXPORT_CSV_REQUEST });
    try {
        const { data } = action;
        const state = yield select();
        const { filter } = state.filterReducer;
        const { departmentOptions } = state.globalFiltersReducer;
        const departments = getCheckedFormOptionsValue(departmentOptions);

        const response = yield call(() => {
            if (data.exportType === 'hourOverview') {
                return getUserTracksCSV(filter, departments, data);
            }
            if (data.exportType === 'loket') {
                return getLoketCSV(filter, departments, data);
            }
            return getTracksCSV(filter, departments, data);
        });
        getFilenameAndGenerateCSV(response);
        yield put({ type: TRACKS_EXPORT_CSV_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.tracks.getSvgSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: TRACKS_FAILURE, error });
    }
}

function* syncTvt(action) {
    yield put({ type: TRACKS_SYNC_TVT_REQUEST });
    try {
        const { payrollPeriod } = action;
        yield call(() => postSyncTvt(payrollPeriod));

        yield put({ type: TRACKS_SYNC_TVT_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.tracks.syncTvtSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: TRACKS_SYNC_TVT_FAILURE, error });
    }
}

function* fetchUserTracks({ userId, date }) {
    yield put({ type: TRACKS_GET_USER_TRACKS_REQUEST });
    try {
        const response = yield getUserTracks(userId, date);
        const tracks = response ? response.data : [];

        yield put({ type: TRACKS_GET_USER_TRACKS_SUCCESS, tracks });
    } catch (error) {
        console.error(error);
        yield put({ type: TRACKS_GET_USER_TRACKS_FAILURE });
    }
}

export default function* tracksWatcher() {
    yield takeLatest(TRACKS_SAGA_GET_TRACKS, fetchTracks);
    yield takeLatest(TRACK_SAGA_STOP_TRACK, stopTrack);
    yield takeLatest(TRACK_SAGA_APPROVE_TRACK, approveTrack);
    yield takeLatest(TRACK_SAGA_EDIT_TRACK, editTrack);
    yield takeLatest(TRACK_SAGA_ADD_TRACK, addTrack);
    yield takeLatest(TRACK_SAGA_DELETE_TRACK, deleteTrack);
    yield takeLatest(TRACKS_SAGA_EXPORT_CSV, exportCSV);
    yield takeLatest(TRACKS_SAGA_GET_USER_TRACKS, fetchUserTracks);
    yield takeLatest(TRACKS_SAGA_SYNC_TVT, syncTvt);
}
