import React from 'react';

import PropTypes from 'prop-types';

import { translate } from '../../helpers/translations/translator';

import './AddResourceButton.scss';


const AddResourceButton = ({ onClick, text, className = '' }) => (
    <button onClick={onClick} type="button" className={`add-resource-button ${className}`}>
        <div className="add-resource-button-text">
            {`+ ${text} ${translate('common.add').toLowerCase()}`}
        </div>
    </button>
);


AddResourceButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default AddResourceButton;
