import { SelectOption } from '../../../../components/@inputs/Select/Select';
import { Department } from '../../../../entities/Department/Department';
import { getDepartmentLabel } from '../../../../entities/Department/DepartmentHelpers';

const getSelectOption = (selectedDepartment: SelectOption, departments: Department[]): SelectOption => {
    const match = departments.find(department => department.id === selectedDepartment.value);

    return {
        value: selectedDepartment.value,
        label: match ? getDepartmentLabel(match) : selectedDepartment.label,
    };
};

export default getSelectOption;
