import { Comment } from '../../../entities/Comment/Comment';
import { DistrictDivision } from '../../../entities/DistrictDivision/DistrictDivision';
import { DistrictDivisionDistrict } from '../../../entities/DistrictDivisionDistrict/DistrictDivisionDistrict';
import { DistrictDivisionGroup } from '../../../entities/DistrictDivisionGroup/DistrictDivisionGroup';
import { PrintableBlockOffset } from '../contexts/PrintableBlockOffsetsContext';
import {
    DistrictDivisionGroupsPerColumn,
    PrintableDistrictDivisionBlock,
    PrintableDistrictDivisionCommentBlock,
    PrintableDistrictDivisionDistrictBlock,
    PrintableDistrictDivisionPageColumn,
    PrintableDistrictDivisionPageInterface,
    PrintableDistrictDivisionTitleBlock,
} from '../models';

const initialDivisionGroupsPerColumn: DistrictDivisionGroupsPerColumn = [[], [], []];

export const getDistrictDivisionGroupsPerColumn = (districtDivisionGroups: DistrictDivisionGroup[]): DistrictDivisionGroupsPerColumn => districtDivisionGroups
    .filter(group => {
        const emptyDivisionDistricts = group.districtDivisionDistricts
            .filter(districtDivisionDistrict => !districtDivisionDistrict.districtDivisionDistrictPlannings.length);

        return emptyDivisionDistricts.length !== group.districtDivisionDistricts.length;
    })
    .reduce((
        total: DistrictDivisionGroupsPerColumn,
        group,
        groupIndex,
    ) => {
        const justifiedIndex = groupIndex % total.length;
        const districtsWithPlannings = group.districtDivisionDistricts
            .filter(district => !!district.districtDivisionDistrictPlannings.length);
        const groupWithFilteredDistricts: DistrictDivisionGroup = {
            ...group,
            districtDivisionDistricts: districtsWithPlannings,
        };

        return total
            .map((groups, columnIndex) => [
                ...groups,
                ...(columnIndex === justifiedIndex ? [groupWithFilteredDistricts] : []),
            ]) as DistrictDivisionGroupsPerColumn;
    }, initialDivisionGroupsPerColumn);

export const transformDistrictDivisionDistrictToPrintableDistrictDivisionDistrictBlock = (
    district: DistrictDivisionDistrict,
    groupTitle?: string,
): PrintableDistrictDivisionDistrictBlock => ({
    type: 'printable-district-division-district-block',
    id: district.id,
    districtDivisionDistrict: district,
    groupTitle,
});

export const transformDistrictDivisionGroupToPrintableDistrictDivisionDistrictBlocks = (group: DistrictDivisionGroup): PrintableDistrictDivisionBlock[] => group.districtDivisionDistricts
    .reduce((total: PrintableDistrictDivisionBlock[], district, index) => [
        ...total,
        transformDistrictDivisionDistrictToPrintableDistrictDivisionDistrictBlock(district, index === 0 ? group.name : undefined),
    ], []);

export const getDistrictDivisionTitleBlock = (date: Date, isHidden: boolean, id: string): PrintableDistrictDivisionTitleBlock => ({
    type: 'printable-district-division-title-block',
    id,
    isHidden,
    date,
});

export const getDistrictDivisionCommentBlock = (comment: Comment): PrintableDistrictDivisionCommentBlock => ({
    type: 'printable-district-division-comment-block',
    id: comment.id,
    body: comment.body,
});

export const getPrintableDistrictDivisionDistrictBlocksPerColumn = (
    districtDivision: DistrictDivision,
    groups: DistrictDivisionGroup[],
    columnIndex: number,
    isLastColumn?: boolean,
): PrintableDistrictDivisionBlock[] => groups
    .reduce((total: PrintableDistrictDivisionBlock[], group, index) => {
        const hasTitleBlock = index === 0;
        const comment = districtDivision.comments[0]?.body ? districtDivision.comments[0] : undefined;
        const isLastGroup = index === groups.length - 1;

        return [
            ...total,
            ...(hasTitleBlock ? [getDistrictDivisionTitleBlock(districtDivision.date, columnIndex !== 0, columnIndex.toString())] : []),
            ...transformDistrictDivisionGroupToPrintableDistrictDivisionDistrictBlocks(group),
            ...((isLastGroup && isLastColumn && comment) ? [getDistrictDivisionCommentBlock(comment)] : []),
        ];
    }, []);

const transformPrintableDistrictDivisionPageColumn = (id: string, blocks: PrintableDistrictDivisionBlock[]): PrintableDistrictDivisionPageColumn => ({
    id,
    blocks,
});

const transformPrintableDistrictDivisionPage = (id: string, columns: PrintableDistrictDivisionPageColumn[]): PrintableDistrictDivisionPageInterface => ({
    id,
    columns,
});


export const getTotalPrintableDistrictDivisionPages = (offsets: PrintableBlockOffset[], pageHeight: number) => {
    const lowestBlock = [...offsets]
        .sort((a, b) => (b.offsetTop + b.clientHeight) - (a.offsetTop + a.clientHeight))[0];
    const lowestBlockHeight = lowestBlock.clientHeight + lowestBlock.offsetTop;

    return Math.ceil(lowestBlockHeight / pageHeight);
};

export const filterPrintableDistrictDivisionBlockByOffset = (
    block: PrintableDistrictDivisionBlock,
    offsets: PrintableBlockOffset[],
    pageStart: number,
    pageEnd: number,
): boolean => {
    const blockOffset = offsets.find(offset => offset.id === block.id);
    const offsetPlusHeight = (blockOffset?.offsetTop || 0) + (blockOffset?.clientHeight || 0);

    return offsetPlusHeight >= pageStart && offsetPlusHeight < pageEnd;
};

export const getPrintableDistrictDivisionPages = (districtDivision: DistrictDivision): PrintableDistrictDivisionPageInterface => {
    // First divide all the districtDivisionGroups over 3 columns, filter out empty ones.
    const groupsPerColumn = getDistrictDivisionGroupsPerColumn(districtDivision.districtDivisionGroups);
    const justifiedLength = groupsPerColumn.filter(groups => !!groups.length).length - 1;

    // Then get all the PrintableDistrictDivisionBlocks for these 3 columns
    const columns = groupsPerColumn
        .map((groups, index) => {
            const blocks = getPrintableDistrictDivisionDistrictBlocksPerColumn(
                districtDivision,
                groups,
                index,
                index === justifiedLength,
            );

            return transformPrintableDistrictDivisionPageColumn(`column-${index}`, blocks);
        });

    const id = `${districtDivision.id}-${JSON.stringify(districtDivision).length}`;

    return transformPrintableDistrictDivisionPage(`page-${id}`, columns);
};

export const transformPrintableDistrictDivisionColumnsToPrintableDistrictDivisionPages = (
    columns: PrintableDistrictDivisionPageColumn[],
    offsets: PrintableBlockOffset[],
): PrintableDistrictDivisionPageInterface[] => {
    if (offsets.length === 0) {
        return [];
    }

    const pageHeight = 1270;
    // Get the total amount of pages based on the offsets calculated from the sizer page.
    const totalPageAmount = getTotalPrintableDistrictDivisionPages(offsets, pageHeight);

    // Then fill these pages with 3 (empty) columns.
    const pages = new Array(totalPageAmount)
        .fill(null)
        .map((value, index) => {
            const pageColumns: PrintableDistrictDivisionPageColumn[] = initialDivisionGroupsPerColumn
                .map((columnValue, columnIndex) => transformPrintableDistrictDivisionPageColumn(`column-${columnIndex}`, []));

            return transformPrintableDistrictDivisionPage(`page-${index}`, pageColumns);
        });

    // Then fill every column of every page with all the blocks based on the offsets
    return pages.map((page, pageIndex) => {
        const newColumns = page.columns
            .map((column, columnIndex) => {
                const columnBlocks = columns[columnIndex].blocks
                    .filter((block) => filterPrintableDistrictDivisionBlockByOffset(
                        block,
                        offsets,
                        pageIndex * pageHeight,
                        (pageIndex + 1) * pageHeight,
                    ));

                return transformPrintableDistrictDivisionPageColumn(column.id, columnBlocks);
            });

        return transformPrintableDistrictDivisionPage(page.id, newColumns);
    });
};
