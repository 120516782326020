import {
    FC,
    FormEvent,
    ReactElement,
    useMemo,
    useRef,
    useState,
} from 'react';

import { Icon } from '../../../components';
import {
    AddressInput,
    CompanyMedicSelectInput,
    ContractHoursInput,
    DepartmentsInput,
    EmergencyContactInput,
    EmploymentDatesInput,
    EmploymentTypeSelectInput,
    ExperienceSelectInput,
    IdentificationSelectInput,
    NationalitySelectInput,
    PersonalInfoInput,
    RoleSelectInput,
    TextInput,
    WorkdaysSelectInput,
    YesOrNoSelectInput,
} from '../../../compositions';
import DepartmentInput from '../../../compositions/@inputs/DepartmentInput/DepartmentInput';
import { AddressFormData, EditAddressFormData } from '../../../entities/Address/Address';
import { ContractHoursFormData } from '../../../entities/ContractHours/ContractHours';
import { updateContractHoursWithEmployment } from '../../../entities/ContractHours/ContractHoursHelpers';
import { Department } from '../../../entities/Department/Department';
import { EditEmergencyContactFormData, EmergencyContactFormData } from '../../../entities/EmergencyContact/EmergencyContact';
import { EmploymentType } from '../../../entities/EmploymentType/EmploymentType';
import { Experience } from '../../../entities/Experience/Experience';
import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import { Role } from '../../../entities/Role/Role';
import { getRoleById, getRoleDescription } from '../../../entities/Role/RoleHelpers';
import {
    EditUserFormData,
    EmploymentDatesFormData,
    FullUser,
    PersonalInfoFormData,
} from '../../../entities/User/User';
import { getUniqueArrayList } from '../../../helpers/array';
import { formatDate, isDateStringValid } from '../../../helpers/date';
import useCheckPermission from '../../../helpers/hooks/useCheckPermission';
import trans from '../../../helpers/trans';
import { IdentificationType, SelectOption, WorkdayType } from '../../../types';
import { DateFormat } from '../../../types/dateFormatTypes';
import { ModalFormProps } from '../../../types/modalFormTypes';
import { DepartmentOption } from '../../../types/selectOptionTypes';
import { EmploymentDatesFormErrors, validateEmploymentDatesInputFormData } from '../AddUserForm/validation/addUserFormValidation';
import { generateEditUserFormData } from './helpers/generateEditUserFormData';
import {
    transformDepartmentsToDepartmentOptions,
    transformDepartmentToSelectOption,
    transformEmploymentTypeToSelectOption,
    transformExperienceToSelectOption,
    transformIdentificationToSelectOption,
    transformNationalityToSelectOption,
    transformRoleToSelectOption,
    transformWorkdaysToSelectOptions,
} from './helpers/transformToSelectOption';
import useCheckDepartmentsPermissions from './hooks/useCheckDepartmentPermissions';
import { ActionButtons } from './subcomponents';
import {
    EditPersonalInfoFormErrors,
    EditUserFormErrors,
    validateEditUserFormData,
    validatePersonalInfoFormData,
} from './validation/editUserFormValidation';

import './EditUserForm.scss';

interface EditUserFormProps extends ModalFormProps<EditUserFormData> {
    isDepartmentsLoading: boolean;
    departments: Department[];
    experiences: Experience[];
    employmentTypes: EmploymentType[];
    roles: Role[];
    fullUser: FullUser;
    currentUserId?: string;
    onResendRegistration: () => void;
    onSubmit: (userFormData: EditUserFormData) => void;
    payrollPeriods: PayrollPeriod[];
}

const EditUserForm: FC<EditUserFormProps> = ({
    isDepartmentsLoading,
    isLoading,
    departments,
    experiences,
    employmentTypes,
    roles,
    fullUser,
    currentUserId,
    onCancel,
    onResendRegistration,
    onSubmit,
    payrollPeriods,
}): ReactElement => {
    const [formData, setFormData] = useState<EditUserFormData>(generateEditUserFormData(fullUser));
    const [formErrors, setFormErrors] = useState<EditUserFormErrors>({});
    const [personalInfoFormErrors, setPersonalInfoFormErrors] = useState<EditPersonalInfoFormErrors>({});
    const [employmentDatesFormErrors, setEmploymentDatesFormErrors] = useState<EmploymentDatesFormErrors>({});

    const [addressFormData, setAddressFormData] = useState<EditAddressFormData | undefined>(fullUser.address);
    const [emergencyContactFormData, setEmergencyContactFormData] = useState<EditEmergencyContactFormData | undefined>(fullUser.emergencyContact);
    const [personalInfoFormData, setPersonalInfoFormData] = useState<PersonalInfoFormData>(formData.personalInfo);
    const [employmentDatesFormData, setEmploymentDatesFormData] = useState<EmploymentDatesFormData | undefined>(formData.employmentDates);

    const [identificationExpirationDate, setIdentificationExpirationDate] = useState<Date>(formData.person.identificationExpirationDate);
    const [contractHours, setContractHours] = useState<ContractHoursFormData[] | undefined>(formData.contractHours);
    const [shouldTrackTime, setShouldTrackTime] = useState<boolean | undefined>(formData.shouldTrackTime);
    const [isPaidBasedOnTrackedTime, setIsPaidBasedOnTrackedTime] = useState<boolean | undefined>(formData.isPaidBasedOnTrackedTime);
    const [isEligibleForTimeForTime, setIsEligibleForTimeForTime] = useState<boolean | undefined>(formData.isEligibleForTimeForTime);
    const [hasSundayAllowance, setHasSundayAllowance] = useState<boolean>(formData.person.hasSundayAllowance);
    const [companyMedic, setCompanyMedic] = useState<number>(formData.person.companyMedic);

    const [selectedMainDepartment, setSelectedMainDepartment] = useState<DepartmentOption | undefined>(
        fullUser.mainDepartment ? transformDepartmentToSelectOption(fullUser.mainDepartment) : undefined,
    );
    const [selectedDepartments, setSelectedDepartments] = useState<DepartmentOption[]>(transformDepartmentsToDepartmentOptions(fullUser.departments));
    const [selectedWorkdays, setSelectedWorkdays] = useState<SelectOption<WorkdayType>[]>(transformWorkdaysToSelectOptions(fullUser.person.workdays));
    const [experience, setExperience] = useState<SelectOption>(transformExperienceToSelectOption(experiences, fullUser.experience));
    const [employmentType, setEmploymentType] = useState<SelectOption>(transformEmploymentTypeToSelectOption(employmentTypes, fullUser.employmentType));
    const [role, setRole] = useState<SelectOption>(transformRoleToSelectOption(roles, fullUser.role));
    const chosenRole = getRoleById(roles, role.value);
    const [identificationType, setIdentificationType] = useState<SelectOption<IdentificationType>>(transformIdentificationToSelectOption(fullUser.person.identificationType));
    const [nationality, setNationality] = useState<SelectOption>(transformNationalityToSelectOption(fullUser.person.nationality));

    const formRef = useRef<HTMLFormElement>(null);
    const fullUserOtherDepartments = useMemo(() => fullUser.departments.filter(department => department.id !== fullUser.mainDepartment?.id), [fullUser.departments, fullUser.mainDepartment]);
    const fullUserDeletedDepartments = useMemo(() => fullUser.departments.filter(department => department.deletedAt), [fullUser.departments]);
    const departmentOptions: Department[] = getUniqueArrayList<Department>([...departments, ...fullUserDeletedDepartments], 'id');

    const canEditEmail = useCheckPermission('edit-other-user-emails');
    const canEditAddresses = useCheckPermission('edit-all-addresses');
    const canViewAddresses = useCheckPermission('view-all-addresses');
    const canEditEmergencyContacts = useCheckPermission('edit-all-emergency-contacts');
    const canViewEmergencyContacts = useCheckPermission('view-all-emergency-contacts');
    const canEditEmployments = useCheckPermission('edit-all-employments');
    const canViewEmployments = useCheckPermission('view-all-employments');
    const canEditAllUserProfileFields = useCheckPermission('edit-all-user-profile-fields');
    const canEditMainDepartment = useCheckDepartmentsPermissions(departments, fullUser.mainDepartment ? [fullUser.mainDepartment] : []);
    const canEditOtherDepartments = useCheckDepartmentsPermissions(departments, fullUserOtherDepartments);
    const canEditContractHours = useCheckPermission('edit-all-periodical-contract-hours');
    const canAddContractHours = useCheckPermission('add-new-periodical-contract-hours');
    const canDeleteContractHours = useCheckPermission('delete-all-periodical-contract-hours');
    const canViewContractHours = useCheckPermission('view-all-periodical-contract-hours');
    const canModifyContractHours = canEditContractHours && canAddContractHours && canDeleteContractHours;

    const handlePersonalInfoChange = (newPersonalInfoFormData: Partial<PersonalInfoFormData>): void => {
        setPersonalInfoFormData({ ...personalInfoFormData, ...newPersonalInfoFormData });
    };

    const handleAddressChange = (newAddressFormData: AddressFormData): void => {
        setAddressFormData({ id: addressFormData ? addressFormData.id : '', ...addressFormData, ...newAddressFormData });
    };

    const handleEmergencyContactChange = (newEmergencyContactFormData: EmergencyContactFormData): void => {
        setEmergencyContactFormData({ id: emergencyContactFormData ? emergencyContactFormData.id : '', ...emergencyContactFormData, ...newEmergencyContactFormData });
    };

    const handleShouldTrackTime = (shouldTrackTimeState: boolean): void => {
        if (!shouldTrackTimeState) {
            setIsPaidBasedOnTrackedTime(false);
            setIsEligibleForTimeForTime(false);
        }

        setShouldTrackTime(shouldTrackTimeState);
    };

    const handleEmploymentDatesChange = (newEmploymentDatesFormData: EmploymentDatesFormData): void => {
        const updatedContractHours = updateContractHoursWithEmployment(contractHours, employmentDatesFormData);

        setEmploymentDatesFormData(newEmploymentDatesFormData);
        setContractHours(updatedContractHours);
    };

    const handleIdentificationExpirationDate = (date: string): void => {
        if (isDateStringValid(date)) {
            setIdentificationExpirationDate(new Date(date));
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const defaultFormDataToSubmit = {
            id: fullUser.id,
            personalInfo: personalInfoFormData,
            person: {
                id: fullUser.person.id,
                hasSundayAllowance,
                companyMedic,
                identificationType: identificationType.value,
                identificationExpirationDate,
                workdays: selectedWorkdays.map(selectedWorkday => selectedWorkday.value),
                nationality: nationality.value,
            },
            experienceId: experience.value,
            employmentTypeId: employmentType.value,
            roleId: role.value,
        };

        const departmentsFormData = {
            ...((canEditMainDepartment && selectedMainDepartment?.value) && {
                mainDepartment: selectedMainDepartment?.value,
            }),
            ...(canEditOtherDepartments && {
                departments: selectedDepartments.filter(selectedDepartment => !selectedDepartment.isGroup).map(department => department.value),
                departmentGroups: selectedDepartments.filter(selectedDepartment => selectedDepartment.isGroup).map(department => department.value),
            }),
        };

        const editAllAddressesFormData = canEditAddresses ? {
            address: addressFormData,
        } : {};

        const editEmploymentDatesFormData = canEditEmployments ? {
            employmentDates: canEditEmployments ? employmentDatesFormData : {},
        } : {};

        const editAllEmergencyContactFormData = canEditEmergencyContacts ? {
            emergencyContact: emergencyContactFormData,
        } : {};

        const editAllUserProfileFieldFormData = canEditAllUserProfileFields ? {
            shouldTrackTime,
            isPaidBasedOnTrackedTime,
            isEligibleForTimeForTime,
        } : {};

        const editContractHoursFormData = canModifyContractHours ? {
            contractHours,
        } : {};

        const completeFormData = {
            ...defaultFormDataToSubmit,
            ...departmentsFormData,
            ...editAllUserProfileFieldFormData,
            ...editAllAddressesFormData,
            ...editAllEmergencyContactFormData,
            ...editContractHoursFormData,
            ...editEmploymentDatesFormData,
        };

        setFormData(completeFormData);

        const [newFormErrors, hasUserFormErrors] = validateEditUserFormData(completeFormData, canEditMainDepartment);
        const [newPersonalInfoFormErrors, hasPersonInfoFormErrors] = validatePersonalInfoFormData(completeFormData.personalInfo);
        const [newEmploymentDatesFormErrors, hasEmploymentDatesFormErrors] = validateEmploymentDatesInputFormData(completeFormData.employmentDates);

        setFormErrors(newFormErrors);
        setPersonalInfoFormErrors(newPersonalInfoFormErrors);
        setEmploymentDatesFormErrors(newEmploymentDatesFormErrors);

        if (!hasUserFormErrors && !hasPersonInfoFormErrors && !hasEmploymentDatesFormErrors) {
            onSubmit(completeFormData);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="edit-user-form">
            <PersonalInfoInput
                canEditEmail={canEditEmail}
                personalInfoFormData={personalInfoFormData}
                errors={personalInfoFormErrors}
                onChange={handlePersonalInfoChange}
            />
            {canViewEmployments && (
                <EmploymentDatesInput
                    isDisabled={!canEditEmployments}
                    formData={employmentDatesFormData}
                    errors={employmentDatesFormErrors}
                    onChange={handleEmploymentDatesChange}
                    payrollPeriods={payrollPeriods}
                />
            )}
            <div className="edit-user-form__row">
                <div className="edit-user-form__col">
                    <DepartmentInput
                        disabled={!canEditMainDepartment}
                        isLoading={isDepartmentsLoading}
                        departments={departments}
                        selectedDepartment={selectedMainDepartment}
                        error={formErrors.mainDepartment}
                        label={trans('common.mainDepartment')}
                        onDepartmentChange={setSelectedMainDepartment}
                        className="edit-user-form__department-input"
                        selectClassName={selectedMainDepartment?.isDeleted ? 'edit-user-form__department-input-select--is-deleted' : undefined}
                    />
                </div>
            </div>
            <div className="edit-user-form__row">
                <div className="edit-user-form__col">
                    <DepartmentsInput
                        disabled={!canEditOtherDepartments}
                        isLoading={isDepartmentsLoading}
                        departments={selectedMainDepartment ? departmentOptions.filter(department => department.id !== selectedMainDepartment.value) : departmentOptions}
                        label={trans('common.otherDepartments')}
                        selectedDepartments={selectedMainDepartment ? selectedDepartments.filter(department => department.value !== selectedMainDepartment.value) : selectedDepartments}
                        onDepartmentsChange={setSelectedDepartments}
                    />
                </div>
            </div>
            {canViewAddresses && (
                <AddressInput
                    addressFormData={addressFormData}
                    disabled={!canEditAddresses}
                    onChange={handleAddressChange}
                />
            )}
            <div className="edit-user-form__row">
                <div className="edit-user-form__col">
                    <NationalitySelectInput
                        nationality={nationality}
                        onChange={setNationality}
                    />
                </div>
                <div className="edit-user-form__col">
                    <CompanyMedicSelectInput
                        companyMedic={companyMedic}
                        onChange={setCompanyMedic}
                    />
                </div>
            </div>
            {canViewEmergencyContacts && (
                <EmergencyContactInput
                    emergencyContactFormData={emergencyContactFormData}
                    disabled={!canEditEmergencyContacts}
                    onChange={handleEmergencyContactChange}
                />
            )}
            {canViewEmployments && (
                <div className="edit-user-form__row">
                    <div className="edit-user-form__col">
                        <EmploymentTypeSelectInput
                            disabled={!canEditEmployments}
                            employmentTypes={employmentTypes}
                            employmentType={employmentType}
                            onChange={setEmploymentType}
                        />
                    </div>
                    <div className="edit-user-form__col" />
                </div>
            )}
            <div className="edit-user-form__row">
                {fullUser.id !== currentUserId
                && (
                    <div className="edit-user-form__col">
                        <RoleSelectInput
                            roles={roles}
                            role={role}
                            onChange={setRole}
                        />
                        {chosenRole && (
                            <div className="edit-user-form__role-description">
                                <Icon name="info-circle" className="edit-user-form__info-circle-icon" />
                                {getRoleDescription(chosenRole.slug)}
                            </div>
                        )}
                    </div>
                )}
                <div className="edit-user-form__col">
                    <ExperienceSelectInput
                        experiences={experiences}
                        experience={experience}
                        onChange={setExperience}
                    />
                </div>
            </div>

            {canViewContractHours && (
                <ContractHoursInput
                    isDisabled={!canModifyContractHours}
                    contractHours={contractHours}
                    error={formErrors.contractHours}
                    onChange={setContractHours}
                />
            )}

            <div className="edit-user-form__row">
                <div className="edit-user-form__col">
                    <IdentificationSelectInput
                        identificationType={identificationType}
                        onChange={setIdentificationType}
                    />
                </div>
                <div className="edit-user-form__col">
                    <TextInput
                        error={formErrors.identificationExpirationDate}
                        label={trans('common.identificationExpirationDate')}
                        type="date"
                        value={identificationExpirationDate && formatDate(identificationExpirationDate, DateFormat.inputDate)}
                        onChange={handleIdentificationExpirationDate}
                    />
                </div>
            </div>
            <div className="edit-user-form__row">
                <div className="edit-user-form__col">
                    <WorkdaysSelectInput
                        selectedWorkdays={selectedWorkdays}
                        onChange={setSelectedWorkdays}
                    />
                </div>
            </div>
            {canEditAllUserProfileFields && (
                <>
                    <div className="edit-user-form__row">
                        <div className="edit-user-form__col">
                            <YesOrNoSelectInput
                                booleanValue={shouldTrackTime}
                                disabled={!canEditAllUserProfileFields}
                                label={trans('common.shouldTrackTime')}
                                name="shouldTrackTime"
                                onChange={handleShouldTrackTime}
                            />
                        </div>
                        <div className="edit-user-form__col">
                            <YesOrNoSelectInput
                                booleanValue={isPaidBasedOnTrackedTime}
                                disabled={!shouldTrackTime || !canEditAllUserProfileFields}
                                label={trans('common.isPaidBasedOnTrackedTime')}
                                name="isPaidBasedOnTrackedTime"
                                onChange={setIsPaidBasedOnTrackedTime}
                            />
                        </div>
                    </div>
                    <div className="edit-user-form__row">
                        <div className="edit-user-form__col">
                            <YesOrNoSelectInput
                                error={formErrors.isEligibleForTimeForTime}
                                booleanValue={isEligibleForTimeForTime}
                                disabled={!shouldTrackTime || !canEditAllUserProfileFields}
                                label={trans('common.isEligibleForTimeForTime')}
                                name="isEligibleForTimeForTime"
                                onChange={setIsEligibleForTimeForTime}
                            />
                        </div>
                        <div className="edit-user-form__col">
                            <YesOrNoSelectInput
                                booleanValue={hasSundayAllowance}
                                label={trans('common.hasSundayAllowance')}
                                name="hasSundayAllowance"
                                onChange={setHasSundayAllowance}
                            />
                        </div>
                    </div>
                </>
            )}
            <ActionButtons
                isLoading={isLoading}
                showResendRegistrationButton={!fullUser.isRegistrationComplete}
                onCancelButtonClick={onCancel}
                onResendRegistrationButtonClick={onResendRegistration}
            />
        </form>
    );
};

export default EditUserForm;
