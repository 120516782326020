import { FC } from 'react';

import trans from '../../helpers/trans';
import { Legend } from '../index';

interface LeaveOfAbsencesOverviewLegendProps {
    className?: string;
}

enum LeaveOfAbsenceOverviewLegendColorType {
    approved = 'approved',
    open = 'open',
}

const colors = [
    {
        title: trans('compositions.leaveOfAbsencesLegend.approved'),
        type: LeaveOfAbsenceOverviewLegendColorType.approved,
    },
    {
        title: trans('compositions.leaveOfAbsencesLegend.open'),
        type: LeaveOfAbsenceOverviewLegendColorType.open,
    },
];

const LeaveOfAbsencesOverviewLegend: FC<LeaveOfAbsencesOverviewLegendProps> = ({ className = '' }) => (
    <Legend
        colors={colors}
        className={className}
    />
);

export default LeaveOfAbsencesOverviewLegend;
