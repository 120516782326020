import { LeaveOfAbsence, LoketBalancesViewModel } from '../../models';

export default function mapLoketLeaveBalancesToLeaveOfAbsences(
    leaveOfAbsences: LeaveOfAbsence[],
    loketLeaveBalances: (LoketBalancesViewModel | null)[],
): LeaveOfAbsence[] {
    return leaveOfAbsences.map((leaveOfAbsence) => {
        const loketEmployeeId = leaveOfAbsence.user && leaveOfAbsence.user.loketEmployeeId;
        const loketBalances = loketLeaveBalances
            .find(loketLeaveBalance => loketLeaveBalance
                && loketLeaveBalance.loketEmployeeId === loketEmployeeId)
            || null;

        return {
            ...leaveOfAbsence,
            loketBalances,
        };
    });
}
