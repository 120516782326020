import {
    MANAGEMENT_SAGA_ADD_DEPARTMENT,
    MANAGEMENT_SAGA_ADD_DOCUMENT,
    MANAGEMENT_SAGA_AUTHENTICATE_LOKET,
    MANAGEMENT_SAGA_DEL_USER_PLANNING_PREFERENCE,
    MANAGEMENT_SAGA_DELETE_DEPARTMENT,
    MANAGEMENT_SAGA_DELETE_DOCUMENT,
    MANAGEMENT_SAGA_DELETE_USER,
    MANAGEMENT_SAGA_EDIT_DEPARTMENT,
    MANAGEMENT_SAGA_EDIT_DOCUMENT,
    MANAGEMENT_SAGA_EXPORT_USERS_CSV,
    MANAGEMENT_SAGA_GET_PERMISSION_GROUPS,
    MANAGEMENT_SAGA_GET_USER_SHIFTS,
    MANAGEMENT_SAGA_GET_USERS_QUICKLY,
    MANAGEMENT_SAGA_LINK_USER_TO_LOKET,
    MANAGEMENT_SAGA_LINK_USERS_TO_LOKET,
    MANAGEMENT_SAGA_LOGIN_LOKET,
    MANAGEMENT_SAGA_SET_SORTING,
    MANAGEMENT_SAGA_SET_USER_PLANNING_PREFERENCE,
} from '../actionTypes';

const deleteUser = userId => ({ type: MANAGEMENT_SAGA_DELETE_USER, userId });
const getUsersQuickly = (pageNumber) => ({ type: MANAGEMENT_SAGA_GET_USERS_QUICKLY, pageNumber });
const setUsersSorting = (sortType, ascending) => ({
    type: MANAGEMENT_SAGA_SET_SORTING, sortType, ascending,
});
const editDepartment = department => ({ type: MANAGEMENT_SAGA_EDIT_DEPARTMENT, department });
const addDepartment = department => ({ type: MANAGEMENT_SAGA_ADD_DEPARTMENT, department });
const deleteDepartment = department => ({ type: MANAGEMENT_SAGA_DELETE_DEPARTMENT, department });
const addDocument = (data, userId) => ({ type: MANAGEMENT_SAGA_ADD_DOCUMENT, data, id: userId });
const editDocument = (data, userId) => ({ type: MANAGEMENT_SAGA_EDIT_DOCUMENT, data, id: userId });
const deleteDocument = (documentId, userId) => (
    { type: MANAGEMENT_SAGA_DELETE_DOCUMENT, documentId, id: userId });
const authenticateLoket = (state, code) => (
    { type: MANAGEMENT_SAGA_AUTHENTICATE_LOKET, state, code }
);
const loginLoket = userId => ({ type: MANAGEMENT_SAGA_LOGIN_LOKET, userId });
const exportUsersCSV = () => ({ type: MANAGEMENT_SAGA_EXPORT_USERS_CSV });
const linkUserToLoket = userId => ({ type: MANAGEMENT_SAGA_LINK_USER_TO_LOKET, userId });
const linkUsersToLoket = () => ({ type: MANAGEMENT_SAGA_LINK_USERS_TO_LOKET });
const getUserShifts = (userId, startDate, endDate) => ({
    type: MANAGEMENT_SAGA_GET_USER_SHIFTS, userId, startDate, endDate,
});
const getPermissionGroups = () => ({ type: MANAGEMENT_SAGA_GET_PERMISSION_GROUPS });
const setUserPlanningPreference = (
    weekday,
    fromDate,
    toDate,
    comment,
    userId,
    planningPreferenceId,
    page,
) => (
    {
        type: MANAGEMENT_SAGA_SET_USER_PLANNING_PREFERENCE,
        weekday,
        fromDate,
        toDate,
        comment,
        userId,
        planningPreferenceId,
        page,
    }
);
const deleteUserPlanningPreference = (planningPreferenceId) => ({
    type: MANAGEMENT_SAGA_DEL_USER_PLANNING_PREFERENCE, planningPreferenceId,
});

export {
    addDepartment,
    getUsersQuickly,
    editDepartment,
    deleteDepartment,
    addDocument,
    editDocument,
    deleteDocument,
    deleteUser,
    setUsersSorting,
    authenticateLoket,
    loginLoket,
    exportUsersCSV,
    linkUserToLoket,
    linkUsersToLoket,
    getUserShifts,
    getPermissionGroups,
    setUserPlanningPreference,
    deleteUserPlanningPreference,
};
