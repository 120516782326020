import React, { FC, useEffect, useState } from 'react';

import {
    Button,
    Col,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import UserProfileInfo from '../../../../components/UserProfileInfo/UserProfileInfo';
import { DepartmentViewModel } from '../../../../entities/Departments/Departments';
import { ShiftConceptViewModelOld } from '../../../../entities/ShiftConcept/ShiftConcept';
import { getUserFullName } from '../../../../helpers';
import { formatDate } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import { UserWithConceptAvailability } from '../../../../models';
import getShiftConceptsForDepartmentsOnDate from './helpers/getShiftConceptsForDepartmentsOnDate';
import ShiftConceptButtonsList from './subcomponents/ShiftConceptButtonsList/ShiftConceptButtonsList';

import './AssignShiftConceptToUserForm.scss';

interface AssignShiftConceptToUserFormProps {
    user: UserWithConceptAvailability;
    date: Date;
    onSubmit: (userId: string, shiftConcept: ShiftConceptViewModelOld) => void;
    onCancel: () => void;
}

const AssignShiftConceptToUserForm: FC<AssignShiftConceptToUserFormProps> = ({
    user,
    date,
    onSubmit,
    onCancel,
}) => {
    const [loading, setLoading] = useState(true);
    const [userState, setUserState] = useState<UserWithConceptAvailability>(user);
    const [shiftConcepts, setShiftConcepts] = useState<ShiftConceptViewModelOld[]>([]);
    const [activeShiftConcept, setActiveShiftConcept] = useState<ShiftConceptViewModelOld | null>(null);
    const day = date ? formatDate(date, 'EEEEEE. d MMMM') : '';
    const firstname = userState ? userState.firstname : '';
    const canSubmit = !loading && shiftConcepts.length && activeShiftConcept;

    async function getShiftConcepts(departments: DepartmentViewModel[], shiftDate: Date) {
        const newShiftConcepts = await getShiftConceptsForDepartmentsOnDate(departments, shiftDate);
        const filteredShiftConcepts = newShiftConcepts
            .filter((shift) => !shift.shiftPlannings
                .some((shiftPlanning) => shiftPlanning.user?.id === userState.id));

        setShiftConcepts(filteredShiftConcepts);
        setLoading(false);
    }

    useEffect(() => {
        if (user && date) {
            setUserState(user);
            setLoading(true);
            getShiftConcepts(user.departments, date);
        }
    }, [user, date]);

    const onShiftConceptClick = (clickedShiftConcept: ShiftConceptViewModelOld) => {
        setActiveShiftConcept(clickedShiftConcept);
    };

    const onFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (userState && activeShiftConcept) {
            onSubmit(userState.id, activeShiftConcept);
        }
    };

    return (
        <form onSubmit={onFormSubmit}>
            <ModalHeader>{translate('pages.shifts.assignConceptShift')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        { userState && (
                            <UserProfileInfo
                                className="assign-shift-concept-to-user-form__user-profile-info"
                                name={getUserFullName(userState)}
                                date={date}
                                department={userState.mainDepartment ? userState.mainDepartment.name : null}
                                experience={userState.experience.name}
                                userId={user.id}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="assign-shift-concept-to-user-form__intro">
                            {translate('pages.shifts.assignShiftForDate', { day, firstname })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ShiftConceptButtonsList
                            onShiftConceptClick={onShiftConceptClick}
                            loading={loading}
                            shiftConcepts={shiftConcepts}
                            activeShiftConcept={activeShiftConcept}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="link" id="modal-close" onClick={onCancel}>{translate('common.cancel')}</Button>
                <Button type="submit" color="orange" disabled={!canSubmit}>{translate('common.save')}</Button>
            </ModalFooter>
        </form>
    );
};

export default AssignShiftConceptToUserForm;
