import React, { FC } from 'react';

import Tooltip from 'react-tooltip-lite';

import Icon from '../../../../../../components/style/Icon/Icon';

import './ShiftEmployeeIcon.scss';


export interface ShiftEmployeeIconProps {
    iconName: string;
    tooltipText?: string;
    className?: string;
}

const ShiftEmployeeIcon: FC<ShiftEmployeeIconProps> = ({
    iconName,
    tooltipText,
    className = '',
}) => {
    const renderIcon = () => (
        <Icon
            kind={iconName}
            fontSize={15}
            className={`shift-employee-icon ${className}`}
        />
    );

    if (!tooltipText) {
        renderIcon();
    }

    return (
        // @ts-ignore
        <Tooltip content={tooltipText} arrowSize={6} padding={7}>
            {renderIcon()}
        </Tooltip>
    );
};

export default ShiftEmployeeIcon;
