/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconInfoCircle: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 14 14" className={`icon-component ${className}`}>
        <g id="Assets" transform="translate(-589.000000, -390.000000)">
            <path d="M596,390c-3.86,0-7,3.14-7,7s3.14,7,7,7s7-3.14,7-7S599.86,390,596,390z M596,400.5c-0.39,0-0.7-0.32-0.7-0.7 V397c0-0.39,0.32-0.7,0.7-0.7s0.7,0.31,0.7,0.7v2.8C596.7,400.18,596.39,400.5,596,400.5z M596.7,394.9h-1.4v-1.4h1.4V394.9z" />
        </g>
    </svg>
);

export default IconInfoCircle;
/* eslint-enable max-len */
