import axios from 'axios';
import Jsona from 'jsona/lib/Jsona';

import { getObjProperty } from '../helpers';

const jsonApiDataFormatter = new Jsona();

axios.interceptors.response.use(
    (response) => {
        let data = response;
        const raw = getObjProperty(data, 'data.data.content');
        if ((response.headers['content-type'] === 'application/vnd.api+json') && !raw) {
            data = {
                data: jsonApiDataFormatter.deserialize(response.data),
                meta: response.data.meta,
            };
        }

        return data;
    },
    error => Promise.reject(error),
);
