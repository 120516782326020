import { getUserFullName } from '../../../../../helpers';
import { Absence, LeaveOfAbsence } from '../../../../../models';

export default function getReviewedByUserNameFromAbsence(
    absence: Absence | LeaveOfAbsence,
): string | undefined {
    const user = absence.type === 'leaveOfAbsences' ? absence.reviewedByUser : undefined;

    if (!user) {
        return undefined;
    }

    return getUserFullName(user);
}
