import { FC, useMemo } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import LegacyPzRouteGroup from '../../../../../pages/PZ/LegacyPzRouteGroup';
import { PZRoutes } from '../../../../../pages/PZ/PZRoutes';
import { getDefaultSubRoutePathByNavigationRouteKey } from '../../../../helpers/routes';
import { KnowledgeBasePage, PzLogsPage } from '../../../../pages';
import { NavRoute } from '../../../../routes/routes';

interface PzRouteGroupProps {
    permittedUserRoutes: NavRoute[];
}

const PzRouteGroup: FC<PzRouteGroupProps> = ({ permittedUserRoutes }) => {
    const defaultRoute = useMemo(() => getDefaultSubRoutePathByNavigationRouteKey('pz', permittedUserRoutes), [permittedUserRoutes]);

    return (
        <Routes>
            <Route path={PZRoutes.knowledgeBase()} element={<KnowledgeBasePage />} />
            <Route
                path={PZRoutes.logs()}
                element={<PzLogsPage />}
            />

            <Route path="/*" element={<LegacyPzRouteGroup />} />
            <Route path="/" element={<Navigate to={defaultRoute} />} />
        </Routes>
    );
};

export default PzRouteGroup;
