import { FC } from 'react';

import classnames from 'classnames';

import { TableCell, TableRow } from '../../../../../components';
import IconButton from '../../../../../compositions/@buttons/IconButton/IconButton';
import {
    canEmploymentEmploymentHoursBeChecked,
    hasUncheckedDivergentEmploymentHours,
} from '../../../../../entities/Employment/EmploymentHelpers';
import { PayrollPeriod } from '../../../../../entities/PayrollPeriod/PayrollPeriod';
import { doesDateOverlapWithPayrollPeriod } from '../../../../../entities/PayrollPeriod/PayrollPeriodHelpers';
import { getPersonFullName } from '../../../../../entities/Person/PersonHelpers';
import { UserWithEmployment } from '../../../../../entities/User/User';
import { formatDate } from '../../../../../helpers/date';
import { DateFormat } from '../../../../../types/dateFormatTypes';

import './IncompleteUserRow.scss';

interface IncompleteUserRowProps {
    activePeriod: PayrollPeriod;
    user: UserWithEmployment;
    onEditUserClick: (user: UserWithEmployment) => void;
    onCheckUserClick: (user: UserWithEmployment, isChecked: boolean) => void;
    className?: string;
}

const IncompleteUserRow: FC<IncompleteUserRowProps> = ({
    activePeriod,
    user,
    onEditUserClick,
    onCheckUserClick,
    className = '',
}) => {
    const startDateHasPayrollPeriodOverlap = doesDateOverlapWithPayrollPeriod(user.employment.start, activePeriod);
    const endDateHasPayrollPeriodOverlap = user.employment.end ? doesDateOverlapWithPayrollPeriod(user.employment.end, activePeriod) : false;

    const divergentPreEmploymentHours = user.employment.divergentPreEmploymentHour?.contractHours;
    const divergentPostEmploymentHours = user.employment.divergentPostEmploymentHour?.contractHours;

    const hasUncheckedHours = hasUncheckedDivergentEmploymentHours(user.employment, activePeriod);
    const canBeChecked = canEmploymentEmploymentHoursBeChecked(user.employment, activePeriod);

    const startDateCellClassName = classnames('incomplete-users-row__cell', {
        'incomplete-user-row__cell--is-strong': startDateHasPayrollPeriodOverlap,
    });

    const startDateContractHoursCellClassName = classnames('incomplete-user-row__contract-hours-cell', {
        'incomplete-user-row__contract-hours-cell--is-strong': startDateHasPayrollPeriodOverlap,
    });

    const endDateCellClassName = classnames('incomplete-user-row__cell', {
        'incomplete-user-row__cell--is-strong': endDateHasPayrollPeriodOverlap,
    });

    const endDateContractHoursCellClassName = classnames('incomplete-user-row__contract-hours-cell', {
        'incomplete-user-row__contract-hours-cell--is-strong': endDateHasPayrollPeriodOverlap,
    });

    const checkButtonClassName = classnames('incomplete-user-row__check-button', {
        'incomplete-user-row__check-button--is-checked': !hasUncheckedHours,
    });

    const checkIconClassName = classnames('incomplete-user-row__check-icon', {
        'incomplete-user-row__check-icon--is-checked': !hasUncheckedHours,
        'incomplete-user-row__check-icon--is-disabled': !canBeChecked,
    });

    return (
        <TableRow className={`incomplete-user-row ${className}`}>
            <TableCell>
                {getPersonFullName(user.person)}
            </TableCell>
            <TableCell className={startDateCellClassName}>
                {formatDate(user.employment.start, DateFormat.date)}
            </TableCell>
            <TableCell className={startDateContractHoursCellClassName}>
                {divergentPreEmploymentHours !== undefined ? divergentPreEmploymentHours : ''}
            </TableCell>
            <TableCell className={endDateCellClassName}>
                {user.employment?.end ? formatDate(user.employment.end, DateFormat.date) : undefined}
            </TableCell>
            <TableCell className={endDateContractHoursCellClassName}>
                {divergentPostEmploymentHours !== undefined ? divergentPostEmploymentHours : ''}
            </TableCell>
            <TableCell
                className="incomplete-user-row__button-cell"
                contentClassName="incomplete-user-row__button-cell-content"
            >
                <IconButton
                    hideLabel
                    text="edit"
                    icon="edit"
                    onClick={() => onEditUserClick(user)}
                    className="incomplete-user-row__edit-button"
                    iconClassName="incomplete-user-row__edit-icon"
                />
            </TableCell>
            <TableCell
                className="incomplete-user-row__button-cell"
                contentClassName="incomplete-user-row__button-cell-content"
            >
                <IconButton
                    hideLabel
                    disabled={!canBeChecked}
                    icon="check"
                    text="check"
                    onClick={() => onCheckUserClick(user, hasUncheckedHours)}
                    className={checkButtonClassName}
                    iconClassName={checkIconClassName}
                />
            </TableCell>
        </TableRow>
    );
};

export default IncompleteUserRow;
