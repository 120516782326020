import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, Icon, PacoButtonLoadingSpinner } from '../../../components';
import { ButtonWithRefProps } from '../../../components/@buttons/Button/Button';
import { IconSet } from '../../../components/Icon/Icon';

import './SubmitButton.scss';

interface SubmitButtonProps extends ButtonWithRefProps {
    isLoading?: boolean;
    icon?: keyof IconSet;
    iconPos?: 'left' | 'right';
}

const SubmitButton: FC<SubmitButtonProps> = ({
    isLoading,
    type = 'submit',
    text,
    icon,
    iconPos = 'left',
    disabled,
    className = '',
    ...submitButtonProps
}): ReactElement => {
    const submitButtonClassNames = classNames('submit-button', {
        [`submit-button--align-${iconPos}`]: iconPos,
    }, className);

    return (
        <Button
            {...submitButtonProps}
            type={type}
            text={text}
            disabled={isLoading || disabled}
            className={submitButtonClassNames}
        >
            {isLoading ? (
                <PacoButtonLoadingSpinner className="submit-button__icon" />
            ) : (
                icon && <Icon name={icon} className="submit-button__icon" />
            )}
            {text}
        </Button>
    );
};

export default SubmitButton;
