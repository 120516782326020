import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import DayPicker, { Modifiers } from 'react-day-picker';

import { ElementWithTooltip } from '../../@paco/compositions';
import { PayrollPeriod } from '../../@paco/entities/PayrollPeriod/PayrollPeriod';
import trans from '../../@paco/helpers/trans';
import { TimeModeType } from '../../@paco/types';
import Controls from '../../components/WeekNavigator/Controls/Controls';
import {
    addDays,
    eachDayOfInterval,
    getRangeFromDate,
    getWeekRange,
    startOfDay,
} from '../../helpers/date';
import { PayrollPeriodViewModel } from '../../models';
import didUserClickOutsideDayPicker from './helpers/didUserClickOutsideDayPicker';
import getIncrementDates from './helpers/getIncrementDates';
import getMonthAndWeekdayTranslations from './helpers/getMonthAndWeekdayTranslations';

interface DateNavigatorProps {
    activePayrollPeriod?: PayrollPeriod;
    mode: TimeModeType;
    startDate: Date;
    endDate: Date;
    selectedDate: Date;
    payrollPeriods: PayrollPeriodViewModel[];
    afterDateChange: () => void;
    afterIncrementChange: () => void;
    setStartAndEndDate: (selectedDate: Date, from: Date, end: Date) => void;
    setSelectedDate: (selectedDate: Date, from: Date, end: Date) => void;
}

const locale = 'nl';
const firstDayOfWeek = 1;

const DateNavigator:FC<DateNavigatorProps> = ({
    activePayrollPeriod,
    mode,
    startDate,
    endDate,
    selectedDate,
    payrollPeriods = [],
    afterDateChange,
    afterIncrementChange,
    setStartAndEndDate,
    setSelectedDate,
}) => {
    const [dayPickerIsOpen, setDayPickerIsOpen] = useState(false);
    const [hoverRange, setHoverRange] = useState<{ from: Date, to: Date } | null>(null);

    const [months, weekDays] = useMemo(() => getMonthAndWeekdayTranslations(), []);
    const selectedDays = useMemo(() => eachDayOfInterval(startDate, endDate), [startDate, endDate]);
    const controlsIsDisabled = useMemo(() => (mode === 'period') && !payrollPeriods.length, [mode, payrollPeriods]);

    const onMouseUp = (e: MouseEvent) => {
        if (didUserClickOutsideDayPicker(e, dayPickerIsOpen)) {
            setDayPickerIsOpen(false);
        }
    };

    const toggleDayPicker = () => setDayPickerIsOpen(!dayPickerIsOpen);

    const handleDayPickerEnter = (date: Date) => {
        setHoverRange(getWeekRange(date));
    };

    const handleDayPickerLeave = () => {
        setHoverRange(null);
    };

    const handleDayPickerChange = (date: Date) => {
        const days = getRangeFromDate(date, mode, payrollPeriods);

        toggleDayPicker();
        setStartAndEndDate(startOfDay(date), days[0], days[days.length - 1]);
        afterDateChange();
    };

    const handleDayPickerWeekClick = (weekNumber: number, newDays: Date[]) => {
        const days = getRangeFromDate(newDays[0], mode, payrollPeriods);
        setStartAndEndDate(days[0], days[0], days[days.length - 1]);
        afterDateChange();
    };

    const onIncrementClick = (next: boolean, day: boolean) => {
        if (day) {
            const newSelectedDate = addDays(selectedDate, next ? 1 : -1);
            setSelectedDate(newSelectedDate, startDate, endDate);
            afterIncrementChange();

            // We only trigger afterDateChange when selectedDate reaches a new week / month / period
            if ((newSelectedDate > endDate) || (newSelectedDate < startDate)) {
                afterDateChange();
            }
        } else {
            const [start, end] = getIncrementDates(
                startDate,
                endDate,
                mode,
                payrollPeriods,
                next,
            );
            setStartAndEndDate(start, start, end);
            afterDateChange();
        }
    };

    useEffect(() => {
        if (dayPickerIsOpen) {
            window.addEventListener('mouseup', onMouseUp);
        } else {
            window.removeEventListener('mouseup', onMouseUp);
        }
    }, [dayPickerIsOpen]);

    useEffect(() => () => {
        window.removeEventListener('mouseup', onMouseUp);
    }, []);

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: hoverRange && hoverRange.from,
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    return (
        <div className="week-navigator">
            <div className="week-navigator__controls-desktop">
                <ElementWithTooltip
                    tooltipIsActive={mode === TimeModeType.period && !!activePayrollPeriod}
                    tooltipText={`${trans('common.period')}: ${activePayrollPeriod?.periodNumber}`}
                >
                    <Controls
                        day={false}
                        isDisabled={controlsIsDisabled}
                        startDate={startDate}
                        endDate={endDate}
                        selectedDate={selectedDate}
                        mode={mode}
                        onIncrement={onIncrementClick}
                        toggleDayPicker={toggleDayPicker}
                    />
                </ElementWithTooltip>
            </div>
            <div className="week-navigator__controls-mobile">
                <Controls
                    day
                    isDisabled={controlsIsDisabled}
                    startDate={startDate}
                    endDate={endDate}
                    selectedDate={selectedDate}
                    onIncrement={onIncrementClick}
                    toggleDayPicker={toggleDayPicker}
                />
            </div>
            {dayPickerIsOpen && (
                <>
                    <div className="week-navigator__daypicker-desktop">
                        <DayPicker
                            locale={locale}
                            firstDayOfWeek={firstDayOfWeek}
                            weekdaysShort={weekDays.map((d => d.substr(0, 2)))}
                            weekdaysLong={weekDays}
                            months={months}
                            month={selectedDate}
                            selectedDays={selectedDays}
                            showWeekNumbers
                            showOutsideDays
                            modifiers={modifiers as Partial<Modifiers>}
                            onDayClick={handleDayPickerChange}
                            onDayMouseEnter={handleDayPickerEnter}
                            onDayMouseLeave={handleDayPickerLeave}
                            onWeekClick={handleDayPickerWeekClick}
                        />
                    </div>
                    <div className="week-navigator__daypicker-mobile">
                        <DayPicker
                            locale={locale}
                            firstDayOfWeek={firstDayOfWeek}
                            weekdaysShort={weekDays.map((d => d.substr(0, 2)))}
                            weekdaysLong={weekDays}
                            months={months}
                            month={selectedDate}
                            selectedDays={selectedDate}
                            showWeekNumbers
                            showOutsideDays
                            modifiers={modifiers as Partial<Modifiers>}
                            onDayClick={handleDayPickerChange}
                            onDayMouseEnter={handleDayPickerEnter}
                            onDayMouseLeave={handleDayPickerLeave}
                            onWeekClick={handleDayPickerWeekClick}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default DateNavigator;
