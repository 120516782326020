import { authorizedFetch } from '../../helpers/authorizedFetch';
import { formatDate, getEndOfWorkDay, getStartOfWorkDay } from '../../helpers/date';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../../japi/types/Document';
import { DateFormat } from '../../types/dateFormatTypes';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { Period } from '../Period/Period';
import { UserResource } from '../User/User';
import { transformToUser } from '../User/UserTransformers';
import { PreferToWorkTimeSlot, PreferToWorkTimeSlotResource } from './PreferToWorkTimeSlot';
import { transformToPreferToWorkTimeSlot } from './PreferToWorkTimeSlotTransformers';

export const getPreferToWorkTimeSlotsApiCall = async (period: Period): Promise<FetchCollectionResult<PreferToWorkTimeSlot[], string>> => {
    try {
        const includes = ['user'];

        const url = generateApiUrl({
            endpoint: '/prefer-to-work-time-slots',
            queryParams: {
                // https://endeavour-nl.atlassian.net/browse/VDV-822
                // TODO: Partial overlap does not work for now, so we use startOfDay and endOfDay
                startDate: formatDate(getStartOfWorkDay(period.start), DateFormat.apiDateTime),
                endDate: formatDate(getEndOfWorkDay(period.end), DateFormat.apiDateTime),
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<PreferToWorkTimeSlotResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const preferToWorkTimeSlots = doc.data.map(preferToWorkTimeSlot => {
            const userResource = getIncluded(doc, preferToWorkTimeSlot, 'user') as UserResource;
            const user = transformToUser(userResource);

            return transformToPreferToWorkTimeSlot(preferToWorkTimeSlot, user);
        });

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: preferToWorkTimeSlots,
        };
    } catch (error) {
        console.error('[getPreferToWorkTimeSlotsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
