import { DepartmentViewModel } from '../../../../../entities/Departments/Departments';
import { getShifts } from '../../../../../helpers/api-ts/shift';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../../../../helpers/date';
import { ShiftViewModel } from '../../../../../models';

export default async function getShiftsForDepartmentsOnDate(
    departments: DepartmentViewModel[],
    date: string | Date,
): Promise<ShiftViewModel[]> {
    const departmentIds = departments.map((department) => department.id);
    const shifts = await getShifts({
        includes: [
            'temporaryWorkers',
            'department',
            'shiftPlannings',
            'shiftPlannings.user',
            'shiftPlannings.user.person',
        ],
        startDate: getStartOfWorkDay(date),
        endDate: getEndOfWorkDay(date),
        filter: {},
        departments: departmentIds,
    });

    return shifts;
}
