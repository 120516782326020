import {
    EmploymentTypeResource,
    EmploymentTypeViewModel,
} from '../../models/EmploymentType';

export default function transformEmploymentTypeResource(resource: EmploymentTypeResource): EmploymentTypeViewModel {
    return {
        id: resource.id,
        name: resource.name,
        slug: resource.slug,
    };
}
