import {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { EmploymentTypeSlug } from '../../../entities/EmploymentType/EmploymentType';
import { Period } from '../../../entities/Period/Period';
import { arePeriodsDeviated } from '../../../entities/Period/PeriodHelpers';
import { TemporaryWorker } from '../../../entities/TemporaryWorker/TemporaryWorker';
import { formatDate } from '../../../helpers/date';
import { DateFormat } from '../../../types/dateFormatTypes';
import { CalendarShiftEmployee } from '../../index';
import { getCommentIcon } from '../CalendarShiftPlanning/helpers';

interface CalendarShiftPlanningProps {
    isPreview?: boolean;
    showEmploymentType?: boolean;
    shiftPeriod: Period;
    temporaryWorker: TemporaryWorker;
    className?: string;
    employmentTypeClassName?: string;
    iconClassName?: string;
}

const CalendarShiftPlanning: FC<CalendarShiftPlanningProps> = ({
    isPreview,
    showEmploymentType = false,
    shiftPeriod,
    temporaryWorker,
    className = '',
    employmentTypeClassName = '',
    iconClassName = '',
}) => {
    const [deviatedTime, setDeviatedTimeTime] = useState<string | undefined>(undefined);

    const icons = useMemo(() => (!isPreview ? getCommentIcon(temporaryWorker.comments) : []), [
        isPreview, temporaryWorker,
    ]);

    useEffect(() => {
        const hasDeviatedTime = (!isPreview && !!shiftPeriod) && arePeriodsDeviated(temporaryWorker.period, shiftPeriod);

        if (hasDeviatedTime) {
            const startTime = formatDate(temporaryWorker.period.start, DateFormat.hoursMinutes);
            const endTime = formatDate(temporaryWorker.period.end, DateFormat.hoursMinutes);
            setDeviatedTimeTime(`${startTime} - ${endTime}`);
        }
    }, [isPreview, shiftPeriod, temporaryWorker]);

    return (
        <CalendarShiftEmployee
            showEmploymentType={showEmploymentType}
            color={temporaryWorker.color}
            icons={icons}
            name={temporaryWorker.name}
            deviatedTime={deviatedTime}
            employmentTypeSlug={EmploymentTypeSlug.temporaryWorker}
            className={className}
            employmentTypeClassName={employmentTypeClassName}
            iconClassName={iconClassName}
        />
    );
};

export default CalendarShiftPlanning;
