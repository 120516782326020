import { FC, ReactElement } from 'react';

import trans from '../../helpers/trans';
import { FormOption } from '../../types';
import RadioList from '../@inputs/RadioList/RadioList';

import './ExportLeaveOfAbsencesFormRadioList.scss';

export enum SelectedPeriodFormOption {
    period = 'period',
    startDate = 'startDate',
}

interface ExportLeaveOfAbsencesFormRadioListProps {
    selectedPeriodType: SelectedPeriodFormOption;
    onChange: (value: string) => void;
    className?: string;
}

const radioListOptions: FormOption[] = [
    {
        label: trans('types.timeMode.period'),
        value: SelectedPeriodFormOption.period,
    },
    {
        label: trans('common.startDate'),
        value: SelectedPeriodFormOption.startDate,
    },
];

const ExportLeaveOfAbsencesFormRadioList: FC<ExportLeaveOfAbsencesFormRadioListProps> = ({
    selectedPeriodType,
    onChange,
    className = '',
}): ReactElement => (
    <RadioList
        hideLabel
        label={trans('common.navigation')}
        name="export-leave-of-absences-form-radio-list"
        value={selectedPeriodType}
        options={radioListOptions}
        onChange={onChange}
        className={`export-leave-of-absences-form-radio-list ${className}`}
        optionWrapperClassName="export-leave-of-absences-form-radio-list__option-wrapper"
        radioClassName="export-leave-of-absences-form-radio-list__radio"
        radioLabelClassName="export-leave-of-absences-form-radio-list__radio-label"
        radioBoxClassName="export-leave-of-absences-form-radio-list__radio-box"
    />
);

export default ExportLeaveOfAbsencesFormRadioList;
