import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../../redux/@config/AsyncReduxState';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import { transformDepartmentToFormOption } from '../../../entities/Department/DepartmentTransformers';
import { getCheckedEmploymentTypeFormOptions } from '../../../entities/EmploymentType/EmploymentTypeHelpers';
import { getCheckedRoleFormOptions } from '../../../entities/Role/RoleHelpers';
import { AuthenticatedUser } from '../../../entities/User/User';
import { FormOption } from '../../../types';
import { authenticatedUserSlice } from '../../authenticatedUser/authenticatedUserReducer';

export type GlobalFiltersState = AsyncReduxState<{
    onlyShowShiftsWithAbsence: boolean;
    onlyShowShiftsWithOpenLeaveOfAbsence: boolean;
    showEmployees: boolean;
    showEmploymentTypes: boolean;
    departmentOptionsIsLoading: boolean;
    onlyShowMainDepartment: boolean;
    departmentOptions: FormOption[];
    employmentTypeOptions: FormOption[];
    roleOptions: FormOption[];
    userSearch: string;
}>;

const initialState: GlobalFiltersState = {
    ...initialAsyncReduxState,
    showEmployees: true,
    showEmploymentTypes: false,
    onlyShowShiftsWithAbsence: false,
    onlyShowShiftsWithOpenLeaveOfAbsence: false,
    departmentOptionsIsLoading: false,
    onlyShowMainDepartment: false,
    departmentOptions: [],
    employmentTypeOptions: [],
    roleOptions: [],
    userSearch: '',
};

export const globalFiltersSlice = createSlice({
    name: 'globalFiltersReducer',
    initialState,
    reducers: {
        resetFilters(state): GlobalFiltersState {
            return {
                ...initialState,
                departmentOptions: state.departmentOptions.map(option => ({
                    ...option,
                    isChecked: false,
                })),
            };
        },
        setFiltersFromLegacyFilters(state, action: PayloadAction<AppFilter>): GlobalFiltersState {
            const {
                departmentOptions,
                employeeContractTypes,
                userTypes,
                shifts,
            } = action.payload;

            const employmentTypeOptions = getCheckedEmploymentTypeFormOptions(employeeContractTypes || [], state.employmentTypeOptions);
            const roleOptions = getCheckedRoleFormOptions(userTypes || [], state.roleOptions);

            return {
                ...state,
                departmentOptions,
                employmentTypeOptions,
                roleOptions,
                showEmployees: shifts.showEmployees,
            };
        },
        setDepartmentOptions(state, action: PayloadAction<FormOption[]>): GlobalFiltersState {
            return {
                ...state,
                departmentOptions: action.payload,
            };
        },
        setDepartmentOptionsIsLoading(state, action: PayloadAction<boolean>): GlobalFiltersState {
            return {
                ...state,
                departmentOptionsIsLoading: action.payload,
            };
        },
        setEmploymentTypeOptions(state, action: PayloadAction<FormOption[]>): GlobalFiltersState {
            return {
                ...state,
                employmentTypeOptions: action.payload,
            };
        },
        setRoleOptions(state, action: PayloadAction<FormOption[]>): GlobalFiltersState {
            return {
                ...state,
                roleOptions: action.payload,
            };
        },
        setOnlyShowMainDepartments(state, action: PayloadAction<boolean>): GlobalFiltersState {
            return {
                ...state,
                onlyShowMainDepartment: action.payload,
            };
        },
        setShowEmployees(state, action: PayloadAction<boolean>): GlobalFiltersState {
            return {
                ...state,
                showEmployees: action.payload,
            };
        },
        setOnlyShowShiftsWithAbsence(state, action: PayloadAction<boolean>): GlobalFiltersState {
            return {
                ...state,
                onlyShowShiftsWithAbsence: action.payload,
            };
        },
        setOnlyShowShiftsWithOpenLeaveOfAbsence(state, action: PayloadAction<boolean>): GlobalFiltersState {
            return {
                ...state,
                onlyShowShiftsWithOpenLeaveOfAbsence: action.payload,
            };
        },
        setShowEmploymentTypes(state, action: PayloadAction<boolean>): GlobalFiltersState {
            return {
                ...state,
                showEmploymentTypes: action.payload,
            };
        },
        setUserSearch(state, action: PayloadAction<string>): GlobalFiltersState {
            return {
                ...state,
                userSearch: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): GlobalFiltersState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(authenticatedUserSlice.actions.setAuthenticatedUser, (state, action: PayloadAction<AuthenticatedUser | undefined>): GlobalFiltersState => {
            const { departments = [] } = action.payload || {};
            const departmentOptions = departments.map(transformDepartmentToFormOption);

            return {
                ...state,
                departmentOptions,
            };
        });
    },
});

export const {
    resetFilters,
    setDepartmentOptions,
    setEmploymentTypeOptions,
    setRoleOptions,
    setOnlyShowMainDepartments,
    setFiltersFromLegacyFilters,
    setDepartmentOptionsIsLoading,
    setOnlyShowShiftsWithAbsence,
    setOnlyShowShiftsWithOpenLeaveOfAbsence,
    setShowEmployees,
    setShowEmploymentTypes,
    setError,
    setUserSearch,
} = globalFiltersSlice.actions;

export default globalFiltersSlice.reducer;
