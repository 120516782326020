import { FC, ReactElement, useEffect } from 'react';

import DeleteDistrictDivisionTemplateForm from '../../../containers/@forms/DeleteDistrictDivisionTemplateForm/DeleteDistrictDivisionTemplateForm';
import trans from '../../../helpers/trans';
import { deleteDistrictDivisionTemplate } from '../../../redux/districtDivisionTemplate/districtDivisionTemplateActions';
import { setIsSuccessful } from '../../../redux/districtDivisionTemplate/districtDivisionTemplateReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedDeleteDistrictDivisionTemplateFormProps {
    districtDivisionTemplateId: string;
    onClose: () => void;
}
const ConnectedDeleteDistrictDivisionTemplateForm: FC<ConnectedDeleteDistrictDivisionTemplateFormProps> = ({
    districtDivisionTemplateId,
    onClose,
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { isLoading, isSuccessful } = useTypedSelector(state => state.districtDivisionTemplateReducer);

    const handleDeleteDistrictDivisionTemplateClick = (formData: { id: string }): void => {
        dispatch(deleteDistrictDivisionTemplate(formData.id));
    };

    useEffect(() => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.deleteDistrictDivisionTemplateForm.deleteDistrictDivisionTemplateSuccess'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isSuccessful]);

    return (
        <DeleteDistrictDivisionTemplateForm
            isLoading={isLoading}
            districtDivisionTemplateId={districtDivisionTemplateId}
            onSubmit={handleDeleteDistrictDivisionTemplateClick}
            onCancel={onClose}
        />
    );
};

export default ConnectedDeleteDistrictDivisionTemplateForm;
