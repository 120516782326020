import { ChangeEvent, FC } from 'react';

import { ErrorLabel, Input, InputLabel } from '../../../../../components';
import trans from '../../../../../helpers/trans';
import { FormError } from '../../../../../services/validationService';

import './EmploymentDateHoursInput.scss';

interface EmploymentDateHoursInputProps {
    isDisabled: boolean;
    error: FormError<string>;
    label: string;
    name: string;
    tooltipLabel?: string;
    value?: string;
    onChange: (value: string) => void;
    className?: string;
}

const EmploymentDateHoursInput: FC<EmploymentDateHoursInputProps> = ({
    isDisabled,
    error,
    label,
    name,
    tooltipLabel,
    value,
    onChange,
    className = '',
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <div className={`employment-date-hours-input ${className}`}>
            <label
                aria-label={label}
                htmlFor={name}
                className={`select-input__label ${className}`}
            >
                <InputLabel
                    required
                    requiredText={trans('common.required')}
                    text={label}
                    tooltip={tooltipLabel}
                    requiredClassName="employment-date-hours-input__input-label-required-label"
                    tooltipClassName="employment-date-hours-input__input-label-tooltip"
                />
            </label>
            <Input
                disabled={isDisabled}
                id={name}
                required
                type="text"
                value={value || ''}
                error={error}
                onChange={handleChange}
                className="employment-date-hours-input__input"
            />
            {error && <ErrorLabel text={error} className="text-input__error-label" />}
        </div>
    );
};

export default EmploymentDateHoursInput;
