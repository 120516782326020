import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { OnChangeValue } from 'react-select';
import { Button, ModalFooter, ModalHeader } from 'reactstrap';

import { DepartmentViewModel } from '../../../../entities/Departments/Departments';
import { generateGroupedDepartmentsSelectList, Option } from '../../../../helpers';
import { translate } from '../../../../helpers/translations/translator';
import { AddShiftFormData } from '../../../../models';
import ShiftFormBody from './components/ShiftFormBody/ShiftFormBody';
import generateAddShiftFormDataEmptyValues from './helpers/generateAddShiftFormDataEmptyValues';
import validateFormData from './helpers/validateFormData';

interface AddShiftFormProps {
    selectedDays?: Date[];
    title: string;
    date: Date,
    departments: DepartmentViewModel[];
    onCancel?: () => void;
    onSubmit?: (data: AddShiftFormData) => void;
    onChange?: (data: AddShiftFormData) => void;
    className?: string;
    footerClassName?: string;
}

const AddShiftForm: FC<AddShiftFormProps> = ({
    selectedDays,
    title,
    date,
    departments,
    onCancel,
    onSubmit,
    onChange,
    className = '',
    footerClassName,
}) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState<AddShiftFormData>(generateAddShiftFormDataEmptyValues(date));
    const validationErrors = useMemo(() => validateFormData(formData, isSubmitted), [formData, isSubmitted]);
    const canSubmit = formData.department
        && formData.date
        && formData.startTime
        && formData.endTime;
    const [selectedDepartment, setSelectedDepartment] = useState<Option | null>(null);

    const groupedDepartments = useMemo(() => generateGroupedDepartmentsSelectList(departments) || [], [departments]);

    const handleFormDataChange = (newFormData: AddShiftFormData) => {
        setFormData(newFormData);
    };

    const handleChangeDepartment = (value: OnChangeValue<Option, false>) => {
        setSelectedDepartment(value || null);
    };

    const handleSubmitButtonClick = () => {
        setIsSubmitted(true);

        if (!Object.keys(validateFormData(formData)).length && onSubmit) {
            onSubmit(formData);
        }
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    useEffect(() => {
        setFormData({
            ...formData,
            department: selectedDepartment?.value ? `${selectedDepartment.value}` : '',
        });
    }, [selectedDepartment]);

    useEffect(() => {
        if (onChange) {
            onChange(formData);
        }
    }, [formData]);

    return (
        <form className={`add-shift-form ${className}`} onSubmit={handleFormSubmit}>
            <ModalHeader>
                {title}
            </ModalHeader>
            <ShiftFormBody
                selectedDays={selectedDays}
                groupedDepartments={groupedDepartments}
                selectedDepartment={selectedDepartment}
                formData={formData}
                onChange={handleFormDataChange}
                onDepartmentChange={handleChangeDepartment}
                validationErrors={validationErrors}
            />
            <ModalFooter className={footerClassName}>
                <Button
                    type="button"
                    color="link"
                    id="modal-close"
                    onClick={onCancel}
                >
                    {translate('common.cancel')}
                </Button>
                <Button
                    type="submit"
                    color="orange"
                    disabled={!canSubmit}
                    onClick={handleSubmitButtonClick}
                >
                    {translate('common.save')}
                </Button>
            </ModalFooter>
        </form>
    );
};

export default AddShiftForm;
