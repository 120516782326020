import { DocumentTypeResource, DocumentTypeViewModel } from '../../models';

export default function transformDocumentTypeResource(
    documentType: DocumentTypeResource,
): DocumentTypeViewModel {
    return {
        id: documentType.id,
        name: documentType.name,
        slug: documentType.slug,
    };
}
