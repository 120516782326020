import { FC, ReactElement } from 'react';

import UserPageLink from '../../../../../components/UserPageLink/UserPageLink';
import { getPersonFullName } from '../../../../entities/Person/PersonHelpers';
import { LegacyBasicUser } from '../../../../entities/User/User';

import './LeaveOfAbsencesOverviewUsersList.scss';

interface LeaveOfAbsencesOverviewUsersListProps {
    isPrintable?: boolean;
    users: LegacyBasicUser[];
    className?: string;
}

const LeaveOfAbsencesOverviewUsersList: FC<LeaveOfAbsencesOverviewUsersListProps> = ({
    isPrintable = false,
    users,
    className = '',
}): ReactElement => (
    <ul className={`leave-of-absences-overview-users-list ${className}`}>
        {users.map(user => (
            <li
                key={user.id}
                className="leave-of-absences-overview-users-list__item"
            >
                {!isPrintable ? (
                    <UserPageLink
                        id={user.id}
                        name={getPersonFullName(user.person)}
                        className="leave-of-absences-overview-users-list__user-link"
                    />
                ) : (
                    <div className="leave-of-absences-overview-users-list__user-link">
                        {getPersonFullName(user.person)}
                    </div>
                )}
            </li>
        ))}
    </ul>
);

export default LeaveOfAbsencesOverviewUsersList;
