import { addDays, max } from '../../helpers/date';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { UserResource } from '../User/User';
import { ShiftsCalendarAbsence, ShiftsCalendarAbsenceResource } from './ShiftsCalendarAbsence';

export const transformToShiftsCalendarAbsence = (
    shiftCalendarAbsenceResource: ShiftsCalendarAbsenceResource,
    userResource: UserResource,
): ShiftsCalendarAbsence => {
    const { start, end } = shiftCalendarAbsenceResource.attributes;
    const startDate = new Date(start);
    const justifiedEndDate = !end ? addDays(max([startDate, new Date()]), 3) : new Date(end);

    return {
        id: shiftCalendarAbsenceResource.id,
        userId: userResource.id,
        period: transformToPeriod(
            startDate,
            justifiedEndDate,
            !!end,
        ),
    };
};
