import React from 'react';

import PropTypes from 'prop-types';

import './SettingsCard.scss';

const SettingsCard = ({ children, title, disabled = false }) => (
    <div className={`card settings-card ${disabled ? 'settings-card--disabled' : ''}`}>
        <div className="settings-card-title">{title}</div>
        <div className="settings-card-sections">
            { children }
        </div>
    </div>
);

SettingsCard.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
};

export default SettingsCard;
