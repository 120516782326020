import { select, take } from 'redux-saga/effects';

import { setAuthenticatedUser } from '../../@paco/redux/authenticatedUser/authenticatedUserReducer';

function getUser(state) {
    return state.authenticatedUserReducer.user;
}

export function* getCurrentUserFromStore() {
    let state = yield select();
    let user = getUser(state);

    if (!user) {
        yield take(setAuthenticatedUser);
    }

    state = yield select();
    user = getUser(state);
    return user || { permissions: [] };
}
