export enum LeaveOfAbsenceStatus {
    open = 'open',
    approved = 'approved',
    denied = 'denied',
}

export const leaveOfAbsenceStatusNumber: Record<LeaveOfAbsenceStatus, number> = {
    open: 1,
    approved: 2,
    denied: 3,
};
