// In exchange calls person is sometimes undefined even though it should exist.

import { CompanyMedicType } from '../../@paco/types';
import { UserPersonViewModel } from '../../models';

export const getDummyUserPersonViewModel = (id: string): UserPersonViewModel => ({
    id,
    birthday: '2000-06-01T11:00:00+01:00',
    firstname: '',
    gender: 'other',
    identificationExpirationDate: '2000-06-01T11:00:00+01:00',
    identificationType: 'passport',
    isCompanyMedic: CompanyMedicType.unknown,
    lastname: '',
    nationality: 'NL',
    hasSundayAllowance: false,
    workDays: [],
});
