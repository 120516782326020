import { transformToPeriod } from '../Period/PeriodTransformers';
import {
    PublishBatchResult,
    PublishBatchResultResource,
    PublishBatchResultStatus,
    PublishBatchResultType,
} from './PublishBatch';

export const transformToPublishBatchResult = (resource: PublishBatchResultResource): PublishBatchResult => ({
    id: resource.uuid,
    createdAt: new Date(resource.time),
    type: resource.object === 'App\\Entity\\Shift' ? PublishBatchResultType.shift : PublishBatchResultType.planning,
    status: resource.status === 'success' ? PublishBatchResultStatus.success : PublishBatchResultStatus.warning,
    message: resource.message,
    period: transformToPeriod(new Date(resource.start), new Date(resource.end)),
});
