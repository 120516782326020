import { useState } from 'react';

import { getDifferenceInTime } from './date';
import useInterval from './hooks/useInterval';

interface TimeTickerProps {
    from: string | Date;
}

const useTimeTicker = ({ from }: TimeTickerProps) => {
    const [time, setTime] = useState(getDifferenceInTime(from, undefined, true));

    useInterval(() => {
        setTime(getDifferenceInTime(from, undefined, true));
    }, 1000);

    return time;
};

export default useTimeTicker;
