import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import { ShiftSnapshot, ShiftSnapshotResource } from './ShiftSnapshot';
import { transformToPatchShiftsSnapshotRequest, transformToShiftSnapshot } from './ShiftSnapshotTransformers';

export const getShiftSnapshotApiCall = async (shiftId: string): Promise<FetchResult<ShiftSnapshot, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/shifts/${shiftId}/snapshot` });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ShiftSnapshotResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shiftSnapshotResource = doc.data;

        const data = transformToShiftSnapshot(shiftSnapshotResource);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[getShiftSnapshotApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchShiftsWithShiftSnapshot = async (shiftSnapshot: ShiftSnapshot, shiftId: string): Promise<FetchResult<ShiftSnapshot, string>> => {
    try {
        const apiData = transformToPatchShiftsSnapshotRequest(shiftSnapshot);
        const url = generateApiUrl({ endpoint: `/shifts/${shiftId}/snapshot` });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ShiftSnapshotResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shiftSnapshotResource = doc.data;

        const data = transformToShiftSnapshot(shiftSnapshotResource);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[patchShiftsWithShiftSnapshot]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
