import { EditShiftPlanningTimeFormData } from '../../../models';
import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { BaseScheduleShiftPlanningResource } from '../BaseScheduleShiftPlanning/BaseScheduleShiftPlanning';
import { Comment } from '../Comment/Comment';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { ShiftPlanningShift } from '../Shift/Shift';
import { ShiftConceptPlanningResource } from '../ShiftConceptPlanning/ShiftConceptPlanning';
import { ShiftPlanningUser } from '../User/User';
import {
    AddShiftPlanningRequest,
    EditShiftPlanningColorFormData,
    EditShiftPlanningColorRequest,
    EditShiftPlanningPlannedFormData,
    EditShiftPlanningPlannedRequest,
    EditShiftPlanningTimeRequest,
    ShiftPlanning,
    ShiftPlanningResource,
    ShiftPlanningWithShift,
} from './ShiftPlanning';

export const transformToShiftPlanning = (
    resource: ShiftPlanningResource | ShiftConceptPlanningResource | BaseScheduleShiftPlanningResource,
    user: ShiftPlanningUser,
    comments: Comment[] = [],
): ShiftPlanning => ({
    id: resource.id,
    type: resource.type,
    planned: 'planned' in resource.attributes ? resource.attributes.planned : true,
    customStartTime: resource.attributes.customStartTime,
    period: transformToPeriod(
        new Date(resource.attributes.start),
        new Date(resource.attributes.end),
    ),
    color: resource.attributes.color || null,
    user,
    comments,
});

export const transformToShiftPlanningWithShift = (
    resource: ShiftPlanningResource,
    user: ShiftPlanningUser,
    shift: ShiftPlanningShift,
    comments: Comment[] = [],
): ShiftPlanningWithShift => ({
    ...transformToShiftPlanning(resource, user, comments),
    shift,
});

export const transformEditShiftPlanningFormDataToEditShiftPlanningRequest = (
    data: EditShiftPlanningColorFormData | EditShiftPlanningPlannedFormData | EditShiftPlanningTimeFormData,
): EditShiftPlanningColorRequest | EditShiftPlanningPlannedRequest | EditShiftPlanningTimeRequest => {
    if ('color' in data) {
        return {
            type: 'shiftPlannings',
            attributes: {
                color: data.color,
            },
        };
    }

    if ('planned' in data) {
        return {
            type: 'shiftPlannings',
            attributes: {
                planned: data.planned,
            },
        };
    }

    return {
        type: 'shiftPlannings',
        attributes: {
            start: formatDate(data.period.start, DateFormat.apiDateTime),
            end: formatDate(data.period.end, DateFormat.apiDateTime),
        },
    };
};

export const transformToAddShiftPlanningRequest = (shiftId: string, userId: string): AddShiftPlanningRequest => ({
    type: 'shiftPlannings',
    attributes: {
        planned: true,
    },
    relationships: {
        shift: {
            data: {
                type: 'shifts',
                id: shiftId,
            },
        },
        user: {
            data: {
                type: 'users',
                id: userId,
            },
        },
    },
});
