import {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { FormActionButtons, Textarea, TimeInput } from '../../../compositions';
import { transformDateAndTimesToPeriod } from '../../../entities/Period/PeriodTransformers';
import { EditTemporaryWorkerFormData, TemporaryWorker } from '../../../entities/TemporaryWorker/TemporaryWorker';
import { formatDate } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { isValidTimeString } from '../../../helpers/validation/validation';
import { DateFormat } from '../../../types/dateFormatTypes';
import { ModalFormProps } from '../../../types/modalFormTypes';

import './EditTemporaryWorkerForm.scss';

interface EditTemporaryWorkerProps extends ModalFormProps<EditTemporaryWorkerFormData> {
    temporaryWorker: TemporaryWorker;
}

const EditTemporaryWorkerForm: FC<EditTemporaryWorkerProps> = ({
    isLoading,
    temporaryWorker,
    onSubmit,
    onCancel,
}): ReactElement => {
    const [start, setStart] = useState<string>(formatDate(temporaryWorker.period.start, DateFormat.hoursMinutes));
    const [end, setEnd] = useState<string>(formatDate(temporaryWorker.period.end, DateFormat.hoursMinutes));
    const [comment, setComment] = useState<string>(temporaryWorker.comments[temporaryWorker.comments.length - 1]?.body || '');

    const isValid = isValidTimeString(start) && isValidTimeString(end);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const period = transformDateAndTimesToPeriod(temporaryWorker.period.start, start, end);

        onSubmit({
            period,
            comment: comment || undefined,
        });
    };

    return (
        <form onSubmit={handleSubmit} className="edit-temporary-worker-form">
            <div className="edit-temporary-worker-form__row">
                <TimeInput
                    label={trans('common.startTime')}
                    value={start}
                    onChange={setStart}
                    className="edit-temporary-worker-form__col"
                />

                <TimeInput
                    label={trans('common.endTime')}
                    value={end}
                    onChange={setEnd}
                    className="edit-temporary-worker-form__col"
                />
            </div>

            <div className="edit-temporary-worker-form__row">
                <Textarea
                    label={trans('common.comment')}
                    rows={4}
                    value={comment}
                    onChange={setComment}
                    className="edit-temporary-worker-form__col"
                />
            </div>

            <FormActionButtons
                disabled={!isValid}
                isLoading={isLoading}
                submitText={trans('common.modify')}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default EditTemporaryWorkerForm;
