import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import {
    DivergentPostEmploymentHour,
    DivergentPostEmploymentHourFormData,
    DivergentPostEmploymentHourResource,
} from './DivergentPostEmploymentHour';
import {
    transformDivergentPostEmploymentHourFormDataToPatchRequest,
    transformDivergentPostEmploymentHourFormDataToPostRequest,
    transformToDivergentPostEmploymentHour,
} from './DivergentPostEmploymentHourTransformers';

export const patchDivergentPostEmploymentHourApiCall = async (
    divergentPostEmploymentHourId: string,
    formData: DivergentPostEmploymentHourFormData,
): Promise<FetchResult<DivergentPostEmploymentHour, string>> => {
    const apiData = transformDivergentPostEmploymentHourFormDataToPatchRequest(divergentPostEmploymentHourId, formData);

    try {
        const url = generateApiUrl({
            endpoint: `/divergent-post-employment-hours/${divergentPostEmploymentHourId}`,
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DivergentPostEmploymentHourResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const employmentData = transformToDivergentPostEmploymentHour(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: employmentData,
        };
    } catch (error) {
        console.error('[patchDivergentPostEmploymentHourApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postDivergentPostEmploymentHourApiCall = async (
    employmentId: string,
    formData: DivergentPostEmploymentHourFormData,
): Promise<FetchResult<DivergentPostEmploymentHour, string>> => {
    const apiData = transformDivergentPostEmploymentHourFormDataToPostRequest(employmentId, formData);

    try {
        const url = generateApiUrl({
            endpoint: '/divergent-post-employment-hours',
        });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DivergentPostEmploymentHourResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const employmentData = transformToDivergentPostEmploymentHour(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: employmentData,
        };
    } catch (error) {
        console.error('[postDivergentPostEmploymentHourApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
