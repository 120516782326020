import React, { FC } from 'react';

import ConnectedCompletePeriods from './connectors/ConnectedCompletePeriods/ConnectedCompletePeriods';


const CompletePeriods: FC = () => (
    <ConnectedCompletePeriods />
);

export default CompletePeriods;
