import { isValid } from '../../../@paco/helpers/date';
import { setTimePeriodAndSelectedDays } from '../../../@paco/redux/@interface/calendar/calendarActions';
import { TypedDispatch } from '../../../@paco/redux/store';
import { TimeModeType } from '../../../@paco/types';

export interface UrlProperties {
    id: string;
    date: string;
}

export const setActiveTrackFromUrl = () => (dispatch: TypedDispatch): string | undefined => {
    // @ts-ignore
    const params: UrlProperties = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop: string) => searchParams.get(prop),
    });

    const givenDate = params.date ? new Date(params.date) : undefined;

    if (params.id && givenDate && isValid(givenDate)) {
        dispatch(setTimePeriodAndSelectedDays(TimeModeType.week, givenDate, []));

        return params.id;
    }

    return undefined;
};
