import { Role } from '../entities/Role/Role';
import { Setting } from '../entities/Setting/Setting';
import { getSettingEnabled } from '../entities/Setting/SettingHelpers';
import {
    NavigationRouteKey,
    NavRoute,
    SubNavRoute,
    subRoutes,
} from '../routes/routes';
import { PermissionSlug } from '../types/permissionTypes';

export const filterSubNavRoutesByPermissions = (
    subRoute: SubNavRoute,
    permissions: PermissionSlug[],
    userSettings: Setting[] = [],
    userRole?: Role,
) => {
    const hasEveryPermission = subRoute.permissions.every(permission => permissions.includes(permission));
    const hasSomePermission = subRoute.permissions.some(permission => permissions.includes(permission));
    const hasMissingPermissions = subRoute.hasSomePermission ? !hasSomePermission : !hasEveryPermission;

    const { roles: subRouteRoles = [] } = subRoute;
    const roleInSubRoutes = userRole && subRouteRoles.some(role => role === userRole.slug);

    const hasSettingEnabled = subRoute?.settings
        ? subRoute.settings.some(setting => getSettingEnabled(setting, userSettings))
        : true;

    return (!subRoute.roles || roleInSubRoutes) && !hasMissingPermissions && hasSettingEnabled;
};

export const getNavRoutesByPermissions = (
    permissions: PermissionSlug[],
    routes: NavRoute[],
    userSettings: Setting[] = [],
    role?: Role,
): NavRoute[] => routes
    .map(route => {
        const children = route.children.filter((subRoute) => filterSubNavRoutesByPermissions(
            subRoute,
            permissions,
            userSettings,
            role,
        ));

        return {
            ...route,
            children,
        };
    })
    // Filter out all main routes that have no children left
    .filter(route => !!route.children.length);

export const getDefaultSubRoutePathByNavigationRouteKey = (key: NavigationRouteKey, routes: NavRoute[]): string => {
    const mainRoute = routes.find(route => route.key === key);

    if (!mainRoute || !mainRoute.children) {
        return '';
    }

    return mainRoute.children[0].path;
};

export const doesRouteHaveMissingPermissions = (key: NavigationRouteKey, permissions: PermissionSlug[]): boolean => {
    const subRoute = subRoutes.find(route => route.key === key);

    if (!subRoute) {
        return false;
    }

    return subRoute.permissions.some(permission => !permissions.includes(permission));
};

export const doesRouteHaveSomePermission = (key: NavigationRouteKey, permissions: PermissionSlug[]): boolean => {
    const subRoute = subRoutes.find(route => route.key === key);

    if (!subRoute) {
        return true;
    }

    return subRoute.permissions.some(permission => permissions.includes(permission));
};
