import { patchEmploymentApiCall } from '../../entities/Employment/EmploymentService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { EmploymentDatesFormData } from '../../entities/User/User';

export const editEmployment = async (employmentId: string, formData: EmploymentDatesFormData): Promise<void> => {
    const response = await patchEmploymentApiCall(employmentId, formData);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('editEmployment');
    }
};
