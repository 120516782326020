import { translate } from '../../../../../helpers/translations/translator';
import { Role } from '../../../../../models';

export const transformRoleIdToBasicSelectOption = (
    roles: Role[],
    roleIds?: string[],
) => (roleIds || []).map(
    (roleId: string) => {
        const selectedRole = roles.find((role) => roleId === role.id);

        return selectedRole ? ({
            label: translate(`common.${selectedRole.slug.toLowerCase()}`),
            value: roleId,
        }) : ({
            label: '',
            value: '',
        });
    },
);
