import { Resource } from '../../japi/types/Resource';

export interface EmploymentTypeResource extends Resource {
    id: string;
    type: 'employmentTypes';
    attributes: {
        name: string;
        slug: EmploymentTypeSlug;
    };
}

export interface EmploymentTypeV3Resource {
    id: string;
    name: string;
    slug: string;
}

export enum EmploymentTypeSlug {
    fullTime = 'full-time',
    intern = 'intern',
    partTime = 'part-time',
    student = 'student',
    temporaryWorker = 'temporary-worker'
}

export interface EmploymentType {
    id: string;
    name: string;
    slug: EmploymentTypeSlug;
}
