import { FC } from 'react';

import classNames from 'classnames';

import './CounterIndicator.scss';

interface CounterIndicatorProps {
    number: number;
    className?: string;
}

const CounterIndicator: FC<CounterIndicatorProps> = ({ number, className = '' }) => {
    const hasOverflow = number > 99;
    const counterClassName = classNames('counter-indicator', {
        'counter-indicator--has-overflow': hasOverflow,
    }, className);

    return (
        <div className={counterClassName}>
            {hasOverflow ? '99+' : Math.min(number, 99)}
        </div>
    );
};

export default CounterIndicator;
