import { FC, ReactElement, useMemo } from 'react';

import { DepartmentBadge } from '../../../../components';
import { getReadableEmergencyContactInfo } from '../../../../entities/EmergencyContact/EmergencyContactHelpers';
import { UserProfile } from '../../../../entities/User/User';
import { formatDate } from '../../../../helpers/date';
import trans from '../../../../helpers/trans';
import { DateFormat } from '../../../../types/dateFormatTypes';
import DepartmentsList from '../../../DepartmentsList/DepartmentsList';
import { UserDetailListItem } from '..';

import './UserDetailInfoCard.scss';

interface UserDetailInfoCardProps {
    user: UserProfile;
    className?: string;
}

const UserDetailInfoCard: FC<UserDetailInfoCardProps> = ({
    user,
    className = '',
}): ReactElement => {
    const workdays = user.person.workdays.map(workday => trans(`types.workdays.${workday}`).substring(0, 2));
    const readableEmergencyContact = useMemo(() => (user.emergencyContact ? getReadableEmergencyContactInfo(user.emergencyContact) : undefined), [user]);

    return (
        <div className={`user-detail-info-card ${className}`}>
            <ul className="user-detail-info-card__list">
                <UserDetailListItem
                    title={trans('common.phoneNumber')}
                    value={user.phone}
                    className="user-detail-info-card__list-item"
                />
                <UserDetailListItem
                    title={trans('common.companyMedic')}
                    value={trans(`common.${user.person.companyMedic.toLowerCase()}`)}
                    className="user-detail-info-card__list-item"
                />
                <UserDetailListItem
                    title={trans('common.email')}
                    value={user.email}
                    className="user-detail-info-card__list-item"
                />
                <UserDetailListItem
                    title={trans('common.birthday')}
                    value={user.person.birthday ? formatDate(user.person.birthday, DateFormat.date) : ''}
                    className="user-detail-info-card__list-item"
                />
                <UserDetailListItem
                    title={trans('common.gender')}
                    value={trans(`common.${user.person.gender}`)}
                    className="user-detail-info-card__list-item user-detail-info-card__gender-list-item"
                />
                <UserDetailListItem
                    title={trans('common.mainDepartment')}
                    value={user.mainDepartment ? (
                        <DepartmentBadge
                            name={user.mainDepartment.name}
                            isMainDepartment
                        />
                    ) : undefined}
                    className="user-detail-info-card__list-item"
                />
                <UserDetailListItem
                    title={trans('common.otherDepartments')}
                    value={(
                        <DepartmentsList
                            departments={user.departments.filter(department => department.id !== user.mainDepartment?.id)}
                            mainDepartment={user.mainDepartment || undefined}
                        />
                    )}
                    className="user-detail-info-card__list-item"
                />
                <UserDetailListItem
                    title={trans('common.workdays')}
                    value={workdays.length ? workdays.join(', ') : '-'}
                    className="user-detail-info-card__list-item"
                />
                {readableEmergencyContact && (
                    <UserDetailListItem
                        title={trans('common.emergencyContact')}
                        value={readableEmergencyContact}
                        className="user-detail-info-card__list-item"
                    />
                )}
            </ul>
        </div>
    );
};

export default UserDetailInfoCard;
