import { FC, ReactElement } from 'react';

import { UserSearch } from '../../../containers';
import { setUserSearch } from '../../../redux/@interface/globalFilters/globalFiltersReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedUserSearchProps {
    className?: string;
    inputClassName?: string;
    iconWrapperClassName?: string;
}

const ConnectedUserSearch: FC<ConnectedUserSearchProps> = ({
    className = '',
    iconWrapperClassName = '',
    inputClassName = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { userSearch } = useTypedSelector(state => state.globalFiltersReducer);

    const handleSearchChange = (search: string) => {
        dispatch(setUserSearch(search));
    };

    return (
        <UserSearch
            search={userSearch}
            onSearchChange={handleSearchChange}
            className={className}
            iconWrapperClassName={iconWrapperClassName}
            inputClassName={inputClassName}
        />
    );
};

export default ConnectedUserSearch;
