import { UserWithConceptAvailability } from '../../../../models';

const removeShiftConceptPlanningFromUsersWithConceptAvailability = (
    users: UserWithConceptAvailability[],
    shiftConceptPlanningId: string,
): UserWithConceptAvailability[] => users.map(user => {
    const shiftConceptPlannings = user.shiftConceptPlannings.filter(shiftConceptPlanning => shiftConceptPlanning.id !== shiftConceptPlanningId);

    return {
        ...user,
        shiftConceptPlannings,
    };
});

export default removeShiftConceptPlanningFromUsersWithConceptAvailability;
