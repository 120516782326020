import React, { FC, ReactElement } from 'react';

import { ConnectedCalendarNavigation } from '../../../../@paco/connectors';
import { Track } from '../../../../@paco/entities/Track/Track';
import { TimeModeType } from '../../../../@paco/types';
import CalendarLegend from '../../../../components/CalendarLegend/CalendarLegend';
import {
    Absence,
    LeaveOfAbsence,
    Shift,
    UnavailableToWorkTimeSlot,
} from '../../../../models';
import Days from './subcomponents/Days/Days';

import './Calendar.scss';

export interface CalendarProps {
    isLoading: boolean;
    days: Date[];
    mobileDay: Date;
    userId: string;
    shifts: Shift[];
    tracks: Track[];
    absences: Absence[];
    leaveOfAbsences: LeaveOfAbsence[];
    timeMode: TimeModeType;
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[];
    onItemClick: (object: Absence | LeaveOfAbsence | UnavailableToWorkTimeSlot) => void;
    className?: string;
}

const Calendar: FC<CalendarProps> = ({
    isLoading,
    absences,
    days,
    mobileDay,
    userId,
    shifts,
    tracks,
    leaveOfAbsences,
    timeMode,
    unavailableToWorkTimeSlots,
    onItemClick,
    className = '',
}) => {
    const renderDays = (selectedDays: Date[], showHeader = true): ReactElement => (
        <Days
            absences={absences}
            leaveOfAbsences={leaveOfAbsences}
            shifts={shifts}
            tracks={tracks}
            showHeader={showHeader}
            selectedDays={selectedDays}
            unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
            loading={isLoading}
            onItemClick={onItemClick}
            userId={userId}
            mode={timeMode}
        />
    );

    return (
        <div className={`user-calendar ${className}`}>
            <div className="user-calendar__top">
                <ConnectedCalendarNavigation
                    showMobileControls
                    timeModes={[TimeModeType.period, TimeModeType.month]}
                    className="user-calendar__navigation"
                />

                <CalendarLegend
                    plannedShift
                    leaveOfAbsence
                    absence
                    notAvailable
                    unplannedTrack
                />
            </div>
            <div className="calendar-days-desktop user-calendar__days-desktop">
                {renderDays(days)}
            </div>
            <div className="calendar-days-mobile user-calendar__days-mobile">
                {renderDays([mobileDay], false)}
            </div>
        </div>
    );
};

export default Calendar;
