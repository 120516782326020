import axios from 'axios';

import { addDays, formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { PZ_COMPLETE_PERIOD } from '../../constants';
import {
    ApiResponse,
    Filter,
    LeaveOfAbsence,
    LeaveOfAbsenceResource,
    LeaveOfAbsenceViewModel,
    PaginationRequestApiParams,
} from '../../models';
import transformLeaveOfAbsenceResource from '../../services/LeaveOfAbsenceService/transformLeaveOfAbsenceResource';
import { getLeaveOfAbsenceRequestIncludes } from '../api/absencesApi';

export enum LeaveOfAbsenceStatus {
    Open = 1,
    Approved = 2,
    Denied = 3,
}

export type leaveOfAbsenceStatus = 1 | 2 | 3;

interface GetLeaveOfAbsencesParams {
    filter?: Filter;
    startDate: Date | null;
    endDate: Date | null;
    status: LeaveOfAbsenceStatus[];
    includes?: string[];
    pagination?: PaginationRequestApiParams;
}

export const getLeaveOfAbsences = async (
    params: GetLeaveOfAbsencesParams,
): Promise<LeaveOfAbsenceViewModel[]> => {
    const {
        filter,
        startDate,
        endDate,
        status,
        includes = [],
        pagination,
    } = params;
    const { number, size } = pagination || {};
    const { search, departments, userIds } = filter || {};

    const newStartDate = (startDate && endDate) ? formatDate(startDate, DateFormat.apiDateTime) : formatDate(addDays(new Date(), -1), DateFormat.apiDateTime);

    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/leave-of-absences/`,
        {
            params: {
                include: includes.join(','),
                ...(search && { search }),
                ...(departments && { departments }),
                ...(number && { 'page[number]': number }),
                ...(size && { 'page[size]': size }),
                startDate: newStartDate,
                endDate: endDate && formatDate(endDate, DateFormat.apiDateTime),
                statuses: JSON.stringify(status),
                userUuids: (userIds && userIds.length > 0) ? JSON.stringify(userIds) : undefined,
            },
        },
    ) as unknown as ApiResponse<LeaveOfAbsenceResource[]>;

    return response.data.map(resource => transformLeaveOfAbsenceResource(
        resource,
        resource.user,
        resource.comments,
        resource.leaveOfAbsenceHours,
    ));
};

export const getLeaveOfAbsencesLegacy = async (
    params: GetLeaveOfAbsencesParams,
): Promise<ApiResponse<LeaveOfAbsenceResource[]>> => {
    const {
        filter,
        startDate,
        endDate,
        status,
        includes = [],
        pagination,
    } = params;
    const { number, size } = pagination || {};
    const { search, departments, userIds } = filter || {};

    const newStartDate = (startDate && endDate) ? formatDate(startDate, DateFormat.apiDateTime) : formatDate(addDays(new Date(), -1), DateFormat.apiDateTime);

    return axios.get(
        `${process.env.REACT_APP_API_URL}/leave-of-absences/`,
        {
            params: {
                include: includes.join(','),
                ...(search && { search }),
                ...(departments && { departments }),
                ...(number && { 'page[number]': number }),
                ...(size && { 'page[size]': size }),
                startDate: newStartDate,
                endDate: endDate && formatDate(endDate, DateFormat.apiDateTime),
                statuses: JSON.stringify(status),
                userUuids: (userIds && userIds.length > 0) ? JSON.stringify(userIds) : undefined,
            },
        },
    ) as unknown as ApiResponse<LeaveOfAbsenceResource[]>;
};

export const getOpenLeaveOfAbsencesInPeriod = async (
    start: Date,
    end: Date,
): Promise<LeaveOfAbsence[]> => {
    const includes = getLeaveOfAbsenceRequestIncludes(PZ_COMPLETE_PERIOD);

    const response = await axios.get<LeaveOfAbsenceResource[]>(
        `${process.env.REACT_APP_API_URL}/leave-of-absences/`,
        {
            params: {
                include: includes.join(','),
                startDate: formatDate(start, DateFormat.apiDateTime),
                endDate: formatDate(end, DateFormat.apiDateTime),
                statuses: JSON.stringify([LeaveOfAbsenceStatus.Open]),
            },
        },
    );

    return response.data as unknown as LeaveOfAbsence[]; // Fix until LeaveOfAbsence and LeaveOfAbsenceResource is no longer used in legacy code
};

export const getIncompleteLeaveOfAbsencesInPeriod = async (
    start: Date,
    end: Date,
): Promise<LeaveOfAbsence[]> => {
    const includes = getLeaveOfAbsenceRequestIncludes(PZ_COMPLETE_PERIOD);

    const response = await axios.get<LeaveOfAbsenceResource[]>(
        `${process.env.REACT_APP_API_URL}/leave-of-absences/`,
        {
            params: {
                include: includes.join(','),
                startDate: formatDate(start, DateFormat.apiDateTime),
                endDate: formatDate(end, DateFormat.apiDateTime),
                statuses: JSON.stringify([LeaveOfAbsenceStatus.Approved]),
            },
        },
    );

    return response.data as unknown as LeaveOfAbsence[]; // Fix until LeaveOfAbsence and LeaveOfAbsenceResource is no longer used in legacy code
};
