import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { EmploymentType } from '../../entities/EmploymentType/EmploymentType';

export type EmploymentTypesState = AsyncReduxState<{
    employmentTypes: EmploymentType[];
}>;

const initialState: EmploymentTypesState = {
    ...initialAsyncReduxState,
    employmentTypes: [],
};

export const EmploymentTypesSlice = createSlice({
    name: 'employmentTypesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): EmploymentTypesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): EmploymentTypesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setEmploymentTypes(state, action: PayloadAction<EmploymentType[]>): EmploymentTypesState {
            return {
                ...state,
                employmentTypes: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setEmploymentTypes,
} = EmploymentTypesSlice.actions;

export default EmploymentTypesSlice.reducer;
