/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconClosedDirectory: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 100 83.9">
        <path d="M89.9,17.1H60.2L43.2,0H9.9c-5.5,0-10,4.5-10,10v63.9c0,5.5,4.5,10,10,10h80c5.5,0,10-4.5,10-10V27.1C99.9,21.6,95.5,17.1,89.9,17.1z" />
    </svg>
);

export default IconClosedDirectory;
/* eslint-enable max-len */
