import { ShiftConcept } from '../../@paco/entities/ShiftConcept/ShiftConcept';
import { ShiftPlanning } from '../../@paco/entities/ShiftPlanning/ShiftPlanning';
import UpToAndIncludingDate from '../../helpers/date/UpToAndIncludingDate';
import { ShiftPlanningViewModel } from '../../models';
import { ShiftConceptViewModelOld } from './ShiftConcept';

const updateLegacyShiftPlanningWithShiftPlanning = (legacyShiftPlanning: ShiftPlanningViewModel, shiftPlanning: ShiftPlanning): ShiftPlanningViewModel => ({
    ...legacyShiftPlanning,
    start: shiftPlanning.period.start,
    end: new UpToAndIncludingDate(shiftPlanning.period.end),
});

const updateLegacyShiftPlanningsWithShiftPlannings = (
    legacyShiftPlannings: ShiftPlanningViewModel[],
    shiftPlannings: ShiftPlanning[],
): ShiftPlanningViewModel[] => legacyShiftPlannings.map(legacyShiftPlanning => {
    const matchingShiftPlanning = shiftPlannings.find(shiftPlanning => shiftPlanning.id === legacyShiftPlanning.id);

    if (!matchingShiftPlanning) {
        return legacyShiftPlanning;
    }

    return updateLegacyShiftPlanningWithShiftPlanning(legacyShiftPlanning, matchingShiftPlanning);
});

export const updateLegacyShiftConceptWithShiftConcept = (
    legacyShiftConcept: ShiftConceptViewModelOld,
    shiftConcept: ShiftConcept,
): ShiftConceptViewModelOld => {
    const shiftPlannings = updateLegacyShiftPlanningsWithShiftPlannings(legacyShiftConcept.shiftPlannings, shiftConcept.shiftPlannings);

    return {
        ...legacyShiftConcept,
        start: shiftConcept.period.start,
        end: new UpToAndIncludingDate(shiftConcept.period.end),
        shiftPlannings,
        temporaryWorkers: shiftConcept.temporaryWorkers,
    };
};
