import React, { FC } from 'react';

import { translate } from '../../helpers/translations/translator';

import './CalendarLegend.scss';

interface Color {
    title: string;
    color: string;
}

interface Props {
    className?: string;
    plannedShift?: boolean;
    registeredShift?: boolean;
    leaveOfAbsence?: boolean;
    absence?: boolean;
    available?: boolean;
    notAvailable?: boolean;
    unplannedTrack?: boolean;
    conceptShift?: boolean;
}

const CalendarLegend: FC<Props> = ({
    className,
    plannedShift,
    registeredShift,
    leaveOfAbsence,
    absence,
    available,
    notAvailable,
    unplannedTrack,
    conceptShift,
}) => {
    const colors: Color[] = [
        ...(conceptShift ? [
            {
                title: translate('pages.shifts.conceptShift'),
                color: 'concept-blue',
            },
        ] : []
        ),
        ...(leaveOfAbsence ? [
            {
                title: translate('pages.management.approvedLeaveOfAbsence'),
                color: 'primary',
            },
            {
                title: translate('pages.management.pendingLeaveOfAbsence'),
                color: 'light-grey',
            },
            {
                title: translate('pages.management.deniedLeaveOfAbsence'),
                color: 'grey',
            },
        ] : []
        ),
        ...(absence ? [
            {
                title: translate('pages.management.sickness'),
                color: 'scarlet',
            },
        ] : []
        ),
        ...(notAvailable ? [
            {
                title: translate('pages.shifts.notAvailable'),
                color: 'orange-light',
            },
        ] : []
        ),
        ...(available ? [
            {
                title: translate('pages.shifts.available'),
                color: 'green-light',
            },
        ] : []
        ),
        ...(plannedShift ? [
            {
                title: translate('pages.management.plannedShift'),
                color: 'green',
            },
        ] : []
        ),
        ...(registeredShift ? [
            {
                title: translate('pages.management.registeredToShift'),
                color: 'green-open',
            },
        ] : []
        ),
        ...(unplannedTrack ? [
            {
                title: translate('pages.management.unplannedTrack'),
                color: 'green-medium',
            },
        ] : []
        ),
    ];

    return (
        <div className={`legend ${className}`}>
            {colors.map((color) => (
                <div
                    data-testid="legend-color"
                    key={`${color.title}-${color.color}`}
                    className={`legend__color legend__color--${color.color}`}
                >
                    <span className="legend__color-circle" />
                    <span className="legend__color-title">
                        {color.title}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default CalendarLegend;
