import { FC, memo, ReactElement } from 'react';

import { ElementWithTooltip } from '../../../compositions';
import { PreferToWorkTimeSlot } from '../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { getPreferToWorkTimeLabel } from '../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlotHelpers';
import trans from '../../../helpers/trans';

import './PreferToWorkTimeSlotBadge.scss';

interface PreferToWorkTimeSlotBadgeProps {
    preferToWorkTimeSlot: PreferToWorkTimeSlot;
    className?: string;
}

const PreferToWorkTimeSlotBadge: FC<PreferToWorkTimeSlotBadgeProps> = ({ preferToWorkTimeSlot, className = '' }): ReactElement => {
    const label = getPreferToWorkTimeLabel(preferToWorkTimeSlot);

    return (
        <ElementWithTooltip
            tooltipIsActive
            tooltipText={label}
        >
            <div className={`prefer-to-work-time-slot-badge ${className}`}>
                {trans('entities.preferToWorkTimeSlots.preference')}
            </div>
        </ElementWithTooltip>
    );
};

export default memo(PreferToWorkTimeSlotBadge);
