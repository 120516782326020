import { Setting } from '../../@paco/entities/Setting/Setting';
import { Setting as LegacySetting } from '../../models/Setting';

export const transformSettingToLegacySetting = (setting: Setting): LegacySetting => ({
    type: 'settings',
    id: setting.id,
    key: setting.key,
    value: setting.value,
    active: setting.isActive,
    departmentId: setting.departmentId,
});
