import { FC, ReactElement, useMemo } from 'react';

import { NavLink } from 'react-router-dom';

import { Button, Icon } from '../../components';
import { EventLog } from '../../entities/EventLog/EventLog';
import { getEventLogEntityLink, getEventLogEntityTranslation } from '../../entities/EventLog/EventLogHelpers';
import { EventLogCategory } from '../../entities/EventLogCategory/EventLogCategory';
import { formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { DateFormat } from '../../types/dateFormatTypes';
import { PzLogChangesTable, PzLogDataTable } from './subcomponents';
import PzLogListItem from './subcomponents/PzLogListItem/PzLogListItem';

import './PzLogDetail.scss';

interface PzLogDetailProps {
    category?: EventLogCategory;
    log: EventLog;
    onClose: () => void;
    className?: string;
}

const PzLogDetail: FC<PzLogDetailProps> = ({
    category,
    log,
    onClose,
    className = '',
}): ReactElement => {
    const route = useMemo(() => getEventLogEntityLink(log), [log]);
    const entityTranslation = getEventLogEntityTranslation(log.entityType);

    return (
        <div className={`pz-log-detail ${className}`}>
            <ul className="pz-log-detail__property-list">
                <PzLogListItem
                    label={trans('common.category')}
                    value={category ? category.name : ''}
                />
                <PzLogListItem
                    label={trans('compositions.pzLogDetail.dateAndTime')}
                    value={formatDate(log.createdAt, DateFormat.dateTime)}
                />
            </ul>

            <div className="pz-log-detail__content">
                <PzLogDataTable dataItems={log.data} />
                {!!log.changes.length && (
                    <PzLogChangesTable
                        changes={log.changes}
                        className="pz-log-detail__changes-table"
                    />
                )}
                <div className="pz-log-detail__buttons-wrapper">
                    {route && (
                        <NavLink
                            to={route}
                            className="pz-log-detail__view-button"
                        >
                            {trans('compositions.pzLogDetail.viewEntity', { entity: entityTranslation.toLowerCase() })}
                            <Icon
                                name="chevron-right"
                                className="pz-log-detail__view-button-icon"
                            />
                        </NavLink>
                    )}
                    <Button
                        text={trans('common.close')}
                        onClick={onClose}
                        className="pz-log-detail__cancel-button"
                    />
                </div>
            </div>
        </div>
    );
};

export default PzLogDetail;
