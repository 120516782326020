import { FC, useEffect, useState } from 'react';

import { Toast } from '../../compositions';
import { Toast as ToastInterface, ToastType } from '../../types/toastTypes';

import './ToastsContainer.scss';

const maxToasts = 3;

interface ToastProps {
    toasts: ToastInterface[];
    className?: string;
}

export const toastsWithFadingBehavior = (toasts: ToastInterface[]): ToastInterface[] => {
    let failedToasts = 0;

    return [...toasts]
        .reverse()
        .map((toast) => {
            if (toast.type === ToastType.fail) {
                const shouldFadeOutAutomatically = failedToasts >= maxToasts;

                failedToasts += 1;

                return {
                    ...toast,
                    shouldFadeOutAutomatically,
                };
            }

            if (toast.shouldFadeOutAutomatically === undefined) {
                return {
                    ...toast,
                    shouldFadeOutAutomatically: true,
                };
            }

            return { ...toast };
        })
        .reverse();
};

const Toasts: FC<ToastProps> = ({ toasts, className = '' }) => {
    const [toastsState, setToastsState] = useState<ToastInterface[]>([]);

    useEffect(() => {
        setToastsState(toastsWithFadingBehavior(toasts));
    }, [toasts]);

    return (
        <div className={`toasts-container ${className}`}>
            {toastsState.map(toast => (
                <Toast
                    key={`${toast.createdOn}-${toast.text}`}
                    toast={toast}
                    className="toasts-container__toast"
                />
            ))}
        </div>
    );
};

export default Toasts;
