import { addDays, compareTimeStrings, formatDate } from '../../helpers/date';
import { EditShiftPlanningFormData } from '../../models';
import {
    AddShiftConceptPlanningRequest,
    EditShiftConceptPlanningColorRequest,
    EditShiftConceptPlanningRequest,
} from './ShiftConceptPlanning';

export const transformEditShiftConceptPlanningFormData = (
    formData: EditShiftPlanningFormData,
    shiftPlanningId: string,
    date: Date,
    // commentId?: string,
): EditShiftConceptPlanningRequest => {
    const endTimeIsLater = compareTimeStrings(formData.startTime, formData.endTime);
    const startDate = formatDate(date, 'yyyy-MM-dd');
    const endDate = formatDate(addDays(date, endTimeIsLater ? 1 : 0), 'yyyy-MM-dd');
    const start = `${startDate} ${formData.startTime}:00`;
    const end = `${endDate} ${formData.endTime}:00`;

    return {
        type: 'shiftConceptPlannings',
        id: shiftPlanningId,
        attributes: {
            start,
            end,
        },
    };
};

export const transformAddShiftConceptRequest = (shiftConceptId: string, userId: string): AddShiftConceptPlanningRequest => ({
    type: 'shiftConceptPlannings',
    relationships: {
        shiftConcept: {
            data: {
                type: 'shift_concepts',
                id: shiftConceptId,
            },
        },
        user: {
            data: {
                type: 'users',
                id: userId,
            },
        },
    },
});

export const transformEditShiftConceptPlanningColorRequest = (
    shiftPlanningId: string,
    color?: string,
): EditShiftConceptPlanningColorRequest => ({
    type: 'shiftConceptPlannings',
    id: shiftPlanningId,
    attributes: {
        color: color || null,
    },
});
