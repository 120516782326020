import React, { FC } from 'react';

import { ConnectedHelpTooltip } from '../../../../@paco/connectors';
import trans from '../../../../@paco/helpers/trans';
import Card from '../../../../components/Card/Card';
import EmptyList from '../../../../components/EmptyList/EmptyList';
import Logs from '../../../../components/Logs/Logs';
import { translate } from '../../../../helpers/translations/translator';
import { Log } from '../../../../models/Log';

import './LoketLogsCard.scss';


interface LoketLogsCardProps {
    className?: string;
    logs: Log[];
}

const LoketLogsCard: FC<LoketLogsCardProps> = ({ className = '', logs = [] }) => (
    <>
        <ConnectedHelpTooltip
            index={1}
            route="synchronizations"
            subTitle={trans('help.pz.recentSychronizations.title')}
            text={trans('help.pz.recentSychronizations.text')}
            title={trans('help.pz.title')}
            className="loket-logs-card__recent-synchronizations-help-tooltip"
        />
        <Card
            className={`loket-logs-card ${className}`}
            title={translate('pages.pz.recentSynchronizations')}
        >
            {logs.length > 0 ? (
                <Logs
                    isLoket
                    className="loket-logs-card__logs"
                    logs={logs}
                />
            ) : (
                <div className="loket-logs-card__empty">
                    <EmptyList
                        text={translate('pages.pz.noLogsfound')}
                        className="loket-logs-card__null-state"
                    />
                </div>
            )}
        </Card>
    </>
);

export default LoketLogsCard;
