import { FC, ReactElement, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { useToggle } from 'react-use';

import LogsModal from '../../../../components/Logs/LogsModal';
import { translate } from '../../../../helpers/translations/translator';
import { Log } from '../../../../models';
import { Button, LoadingSpinner } from '../../../components';
import { ModalContent, PacoModal } from '../../../compositions';
import {
    ConnectedAddTemporaryWorkerForm,
    ConnectedDeleteShiftForm,
    ConnectedDeleteShiftPlanningForm,
    ConnectedDeleteTemporaryWorkerForm,
    ConnectedEditShiftPlanningForm,
    ConnectedEditTemporaryWorkerForm,
    ConnectedHelpTooltip,
    ConnectedSaveSequenceForm,
} from '../../../connectors';
import { Absence } from '../../../entities/Absence/Absence';
import { LeaveOfAbsence } from '../../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { PreferToWorkTimeSlot } from '../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { Setting } from '../../../entities/Setting/Setting';
import { EditShiftFormData, Shift, ShiftIndex } from '../../../entities/Shift/Shift';
import { transformShiftIndexToEditShiftFormData } from '../../../entities/Shift/ShiftTransformers';
import { ShiftPlanning } from '../../../entities/ShiftPlanning/ShiftPlanning';
import { ShiftSnapshot } from '../../../entities/ShiftSnapshot/ShiftSnapshot';
import { TemporaryWorker } from '../../../entities/TemporaryWorker/TemporaryWorker';
import { UnavailableToWorkTimeSlot } from '../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { WorkweekUser } from '../../../entities/User/User';
import useCheckPermission from '../../../helpers/hooks/useCheckPermission';
import trans from '../../../helpers/trans';
import { ScheduleRoutes } from '../../../routes/helpers';
import { PrivateRouteGroupRoutes } from '../../../routes/routes';
import { ShiftDetailMainForm } from './subcomponents';
import ShiftDetailEmployeesForm from './subcomponents/ShiftDetailEmployeesForm/ShiftDetailEmployeesForm';

import './ShiftDetailForm.scss';

interface ShiftDetailFormProps {
    isLoadingLogs: boolean;
    isLoadingShift: boolean;
    isLoadingUsers: boolean;
    absences: Absence[];
    leaveOfAbsences: LeaveOfAbsence[];
    logs: Log[];
    preferToWorkTimeSlots: PreferToWorkTimeSlot[];
    recentShiftPlannings: ShiftPlanning[];
    settings: Setting[];
    shift: ShiftIndex;
    shiftSnapshot?: ShiftSnapshot;
    similarShifts: Shift[];
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[];
    users: WorkweekUser[];
    onAddShiftPlanningClick: (shiftPlanning: ShiftPlanning) => void;
    onCloseLogsModal: () => void;
    onHistoryButtonClick: () => void;
    onSelectWorkweekUser: (workweekUser: WorkweekUser) => void;
    onShiftPlanningColorSelect: (shiftPlanningId: string, color?: string) => void;
    onSubmitEditShiftForm: (formData: EditShiftFormData) => void;
    onTemporaryWorkerColorSelect: (temporaryWorkerId: string, color?: string) => void;
    className?: string;
}

const ShiftDetailForm: FC<ShiftDetailFormProps> = ({
    isLoadingLogs,
    isLoadingShift,
    isLoadingUsers,
    absences,
    leaveOfAbsences,
    logs,
    preferToWorkTimeSlots,
    recentShiftPlannings,
    settings,
    shift,
    shiftSnapshot,
    similarShifts,
    unavailableToWorkTimeSlots,
    users,
    onAddShiftPlanningClick,
    onCloseLogsModal,
    onHistoryButtonClick,
    onSelectWorkweekUser,
    onShiftPlanningColorSelect,
    onSubmitEditShiftForm,
    onTemporaryWorkerColorSelect,
    className = '',
}): ReactElement => {
    const [editShiftFormData, setEditShiftFormData] = useState<EditShiftFormData>(
        transformShiftIndexToEditShiftFormData(shift),
    );
    const [activeShiftPlanning, setActiveShiftPlanning] = useState<ShiftPlanning>();
    const [activeTemporaryWorker, setActiveTemporaryWorker] = useState<TemporaryWorker>();
    const [shiftPlanningModalIsOpen, toggleShiftPlanningModalIsOpen] = useToggle(false);
    const [deleteShiftModalIsOpen, toggleDeleteShiftModalIsOpen] = useToggle(false);
    const [deleteShiftPlanningModalIsOpen, toggleDeleteShiftPlanningModalIsOpen] = useToggle(false);
    const [deleteTemporaryWorkerModalIsOpen, toggleDeleteTemporaryWorkerModalIsOpen] = useToggle(false);
    const [addTemporaryWorkerModalIsOpen, toggleAddTemporaryWorkerModalIsOpen] = useToggle(false);
    const [editTemporaryWorkerModalIsOpen, toggleEditTemporaryWorkerModalIsOpen] = useToggle(false);
    const [saveSequenceModalIsOpen, toggleSaveSequenceModalIsOpen] = useToggle(false);

    const canAddShiftPlannings = useCheckPermission('add-new-shift-plannings');
    const canDeleteShift = useCheckPermission('delete-all-shifts');
    const canDeleteShiftPlannings = useCheckPermission('delete-all-shift-plannings');
    const canEditShiftPlannings = useCheckPermission('edit-all-shift-plannings');
    const canEditShifts = useCheckPermission('edit-all-shifts');
    const canViewLogs = useCheckPermission('view-all-logs');

    const handleAddTemporaryWorkerClick = () => {
        toggleAddTemporaryWorkerModalIsOpen();
    };

    const handleDeleteShiftClick = () => {
        toggleDeleteShiftModalIsOpen();
    };

    const handleDeleteShiftPlanningClick = (shiftPlanning: ShiftPlanning) => {
        setActiveShiftPlanning(shiftPlanning);
        toggleDeleteShiftPlanningModalIsOpen();
    };

    const handleDeleteTemporaryWorkerClick = (temporaryWorker: TemporaryWorker) => {
        setActiveTemporaryWorker(temporaryWorker);
        toggleDeleteTemporaryWorkerModalIsOpen();
    };

    const handleEditShiftPlanningClick = (shiftPlanning: ShiftPlanning) => {
        setActiveShiftPlanning(shiftPlanning);
        toggleShiftPlanningModalIsOpen();
    };

    const handleEditTemporaryWorkerClick = (temporayWorker: TemporaryWorker) => {
        toggleEditTemporaryWorkerModalIsOpen();
        setActiveTemporaryWorker(temporayWorker);
    };

    const handleSubmit = () => {
        onSubmitEditShiftForm(editShiftFormData);
    };

    return (
        <div className={`shift-detail-form ${className}`}>
            <div className="shift-detail-form__header">
                <h1 className="shift-detail-form__title">
                    {trans('containers.forms.shiftDetailForm.title')}
                </h1>

                <div className="shift-detail-form__buttons-wrapper">
                    <NavLink
                        to={`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.calendar()}`}
                        className="shift-detail-form__cancel-button"
                    >
                        {trans('common.cancel')}
                    </NavLink>

                    {canEditShifts && (
                        <>
                            <Button
                                text={trans('common.submit')}
                                className="shift-detail-form__submit-button"
                                onClick={handleSubmit}
                            />

                            <Button
                                disabled={!similarShifts.length || !shiftSnapshot}
                                text={trans('containers.forms.shiftDetailForm.saveSequence')}
                                onClick={toggleSaveSequenceModalIsOpen}
                                className="shift-detail-form__sequence-button"
                            />

                            <ConnectedHelpTooltip
                                index={0}
                                route="calendar"
                                subTitle={trans('help.shiftsDetail.saveSequence.title')}
                                text={trans('help.shiftsDetail.saveSequence.text')}
                                title={trans('help.shiftsDetail.title')}
                                className="shift-detail-form__save-sequence-help-tooltip"
                            />
                        </>
                    )}
                </div>
            </div>

            <div className="shift-detail-form__forms">
                <ShiftDetailMainForm
                    isDateInputDisabled
                    canDeleteShift={canDeleteShift}
                    shift={shift}
                    showHistoryButton={canViewLogs}
                    onDeleteButtonClick={handleDeleteShiftClick}
                    onFormDataChange={setEditShiftFormData}
                    onHistoryButtonClick={onHistoryButtonClick}
                    className="shift-detail-form__main-form"
                />

                <ShiftDetailEmployeesForm
                    isLoading={isLoadingUsers}
                    canAddShiftPlannings={canAddShiftPlannings}
                    canDeleteShiftPlannings={canDeleteShiftPlannings}
                    canEditShiftPlannings={canEditShiftPlannings}
                    absences={absences}
                    leaveOfAbsences={leaveOfAbsences}
                    preferToWorkTimeSlots={preferToWorkTimeSlots}
                    recentShiftPlannings={recentShiftPlannings}
                    settings={settings}
                    shift={shift}
                    unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                    users={users}
                    onAddShiftPlanningClick={onAddShiftPlanningClick}
                    onAddTemporaryWorkerClick={handleAddTemporaryWorkerClick}
                    onDeleteShiftPlanningClick={handleDeleteShiftPlanningClick}
                    onDeleteTemporaryWorkerClick={handleDeleteTemporaryWorkerClick}
                    onEditShiftPlanningClick={handleEditShiftPlanningClick}
                    onEditTemporaryWorkerClick={handleEditTemporaryWorkerClick}
                    onSelectWorkweekUser={onSelectWorkweekUser}
                    onShiftPlanningColorSelect={onShiftPlanningColorSelect}
                    onTemporaryWorkerColorSelect={onTemporaryWorkerColorSelect}
                    className="shift-detail-form__employees-form"
                />
            </div>

            {isLoadingShift && <LoadingSpinner />}

            {(shiftPlanningModalIsOpen && activeShiftPlanning) && (
                <PacoModal>
                    <ModalContent title={trans('containers.forms.editShiftPlanningForm.title')}>
                        <ConnectedEditShiftPlanningForm
                            shiftPlanning={activeShiftPlanning}
                            onClose={toggleShiftPlanningModalIsOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}

            {(addTemporaryWorkerModalIsOpen && shift) && (
                <PacoModal>
                    <ModalContent title={trans('containers.forms.addTemporaryWorkerForm.title')}>
                        <ConnectedAddTemporaryWorkerForm
                            shiftId={shift.id}
                            onClose={toggleAddTemporaryWorkerModalIsOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}

            {(editTemporaryWorkerModalIsOpen && activeTemporaryWorker) && (
                <PacoModal>
                    <ModalContent title={trans('containers.forms.editTemporaryWorkerForm.title')}>
                        <ConnectedEditTemporaryWorkerForm
                            temporaryWorker={activeTemporaryWorker}
                            onClose={toggleEditTemporaryWorkerModalIsOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}

            {(saveSequenceModalIsOpen && shiftSnapshot) && (
                <PacoModal>
                    <ModalContent title={trans('containers.forms.saveSequenceForm.title')}>
                        <ConnectedSaveSequenceForm
                            editShiftFormData={editShiftFormData}
                            shift={shift}
                            shiftSnapshot={shiftSnapshot}
                            similarShifts={similarShifts}
                            onClose={toggleSaveSequenceModalIsOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}

            {(deleteShiftPlanningModalIsOpen && activeShiftPlanning) && (
                <PacoModal>
                    <ModalContent title={trans('containers.forms.deleteShiftPlanningForm.title')}>
                        <ConnectedDeleteShiftPlanningForm
                            shift={shift}
                            shiftPlanning={activeShiftPlanning}
                            onClose={toggleDeleteShiftPlanningModalIsOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}

            {(deleteTemporaryWorkerModalIsOpen && activeTemporaryWorker) && (
                <PacoModal>
                    <ModalContent title={trans('containers.forms.deleteTemporaryWorkerForm.title')}>
                        <ConnectedDeleteTemporaryWorkerForm
                            temporaryWorker={activeTemporaryWorker}
                            onClose={toggleDeleteTemporaryWorkerModalIsOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}

            {deleteShiftModalIsOpen && (
                <PacoModal>
                    <ModalContent title={trans('containers.forms.deleteShiftForm.title')}>
                        <ConnectedDeleteShiftForm
                            shift={shift}
                            onClose={toggleDeleteShiftModalIsOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}

            {(logs.length || isLoadingLogs) && (
                <LogsModal
                    isOpen={isLoadingLogs || !!logs}
                    loading={isLoadingLogs}
                    title={translate('logs.shiftLog')}
                    logs={logs}
                    onClose={onCloseLogsModal}
                />
            )}
        </div>
    );
};

export default ShiftDetailForm;
