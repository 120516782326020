import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { Role } from '../../entities/Role/Role';

export type RolesState = AsyncReduxState<{
    roles: Role[],
}>;

const initialState: RolesState = {
    ...initialAsyncReduxState,
    roles: [],
};

export const RolesSlice = createSlice({
    name: 'rolesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): RolesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): RolesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setRoles(state, action: PayloadAction<Role[]>): RolesState {
            return {
                ...state,
                roles: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setRoles,
} = RolesSlice.actions;

export default RolesSlice.reducer;
