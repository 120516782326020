import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import {
    CommentResource,
    LeaveOfAbsenceHoursResource,
    LeaveOfAbsenceResource,
    LeaveOfAbsenceStatus,
    LeaveOfAbsenceViewModel,
    UserResource,
} from '../../models';
import transformCommentResource from '../CommentService/transformCommentResource';
import transformLeaveOfAbsenceHoursResource from '../LeaveOfAbsenceHoursService/transformLeaveOfAbsenceHoursResource';
import transformUserResource from '../UserService/transformUserResource';

function transformLeaveOfAbsenceResourceStatus(status: LeaveOfAbsenceResource['status']): LeaveOfAbsenceStatus {
    if (status === 'denied') {
        return LeaveOfAbsenceStatus.denied;
    }

    if (status === 'approved') {
        return LeaveOfAbsenceStatus.approved;
    }

    return LeaveOfAbsenceStatus.open;
}

export default function transformLeaveOfAbsenceResource(
    leaveOfAbsence: LeaveOfAbsenceResource,
    user?: UserResource,
    comments?: CommentResource[],
    leaveOfAbsenceHours?: LeaveOfAbsenceHoursResource[],
): LeaveOfAbsenceViewModel {
    return {
        type: 'leaveOfAbsences',
        id: leaveOfAbsence.id,
        start: new Date(leaveOfAbsence.start),
        end: new UpToButExcludingDate(leaveOfAbsence.end).transformToUpToAndIncludingDate(),
        user: user ? transformUserResource(user) : undefined,
        status: transformLeaveOfAbsenceResourceStatus(leaveOfAbsence.status),
        reason: leaveOfAbsence.reason,
        declineReason: leaveOfAbsence.declineReason,
        comments: (comments || [])
            .map(resource => transformCommentResource(resource)),
        leaveOfAbsenceHours: (leaveOfAbsenceHours || [])
            .map(resource => transformLeaveOfAbsenceHoursResource(resource)),
    };
}
