import { EditEmergencyContactFormData, EmergencyContactFormData } from '../../entities/EmergencyContact/EmergencyContact';
import { patchEmergencyContactApiCall, postEmergencyContactApiCall } from '../../entities/EmergencyContact/EmergencyContactService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';

export const addEmergencyContact = async (emergencyContact: EmergencyContactFormData, personId: string): Promise<void> => {
    const response = await postEmergencyContactApiCall(emergencyContact, personId);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('addEmergencyContact');
    }
};

export const editEmergencyContact = async (emergencyContact: EditEmergencyContactFormData, personId: string): Promise<void> => {
    const response = await patchEmergencyContactApiCall(emergencyContact, personId);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('editEmergencyContact');
    }
};
