import { FC } from 'react';

import './Skeleton.scss';

interface SkeletonProps {
    className?: string;
}

const Skeleton:FC<SkeletonProps> = ({ className }) => (
    <div className={`paco-skeleton ${className}`} />
);

export default Skeleton;
