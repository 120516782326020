import axios, { AxiosResponse } from 'axios';

import { User } from '../../models';

export function getUser(userId: string, includes: string[] = []): Promise<AxiosResponse<User>> {
    return axios.get(
        `${process.env.REACT_APP_API_URL}/users/${userId}`,
        {
            params: {
                include: includes.join(','),
            },
        },
    );
}
