import { TimeModeType, ToastType } from './@paco/types';
import { MealType, TrackType, UsersSortType } from './models';

export const TRACK_TYPE_OPEN = TrackType.open;
export const TRACK_TYPE_REVIEW = TrackType.review;
export const TRACK_TYPE_FINISHED = TrackType.finished;
export const TRACKS_FINISHED = 'TRACKS_FINISHED';
export const LEAVE_OF_ABSENCE_REQUESTS = 'LEAVE_OF_ABSENCE_REQUEST';
export const LEAVE_OF_ABSENCE_REQUESTS_CALENDAR = 'LEAVE_OF_ABSENCE_REQUESTS_CALENDAR';
export const AVAILABILITY_CALENDAR = 'AVAILABILITY_CALENDAR';
export const EXCHANGES = 'EXCHANGES';
export const ABSENCES = 'ABSENCES';
export const ABSENCES_LIST = 'ABSENCES_LIST';
export const ABSENCES_OVERVIEW = 'ABSENCES_OVERVIEW';
export const PZ_COMPLETE_PERIOD = 'PZ_COMPLETE_PERIOD';
export const DOCUMENTS = 'DOCUMENTS';
export const USERS = 'USERS';
export const USER = 'USER';
export const LIST_VIEW = 'LIST_VIEW';
export const CONCEPTS_LIST_VIEW = 'CONCEPTS_LIST_VIEW';
export const LEAVE_OF_ABSENCES_REQUEST_OPEN = 1;
export const LEAVE_OF_ABSENCES_REQUEST_APPROVED = 2;
export const LEAVE_OF_ABSENCES_REQUEST_CLOSED = 3;
export const LEAVE_OF_ABSENCES_TYPE_OPEN = 'open';
export const LEAVE_OF_ABSENCES_TYPE_DENIED = 'denied';
export const LEAVE_OF_ABSENCES_TYPE_APPROVED = 'approved';
export const TYPE_LEAVE_OF_ABSENCE = 'leaveOfAbsences';
export const TYPE_ABSENCE = 'absences';
export const TYPE_LEAVE_OF_ABSENCE_REQUEST = 'leaveOfAbsenceRequests';
export const TYPE_UNAVAILABLE_TO_WORK_TIME_SLOT = 'UnavailableToWorkTimeSlots';
export const TYPE_USERS = 'users';
export const NO = 'NO';
export const HR = 'humanresources';
export const BREAK_TIMES = [0, 15, 30, 45, 60, 75, 90];

export const MEALS = [
    {
        label: 'Geen',
        value: MealType.NONE,
    },
    {
        label: 'Broodmaaltijd',
        value: MealType.BREAD,
    },
    {
        label: 'Warme maaltijd',
        value: MealType.HOT,
    },
];

export const WEEKDAYS = [
    'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun',
];

export const TOAST_TYPE_PASS: ToastType = ToastType.pass;
export const TOAST_TYPE_FAIL: ToastType = ToastType.fail;
export const TOAST_TYPE_WARNING: ToastType = ToastType.warning;
export const TOAST_TYPE_UPDATE_APP: ToastType = ToastType.updateApp;

export const TIME_MODES: Record<string, TimeModeType> = {
    WEEK: TimeModeType.week,
    MONTH: TimeModeType.month,
    PERIOD: TimeModeType.period,
    CUSTOM: TimeModeType.custom,
    YEAR: TimeModeType.year,
};

export const PAGE_SIZES = {
    TRACKS: {
        [TRACK_TYPE_OPEN]: 10,
        [TRACK_TYPE_REVIEW]: 9999,
        [TRACK_TYPE_FINISHED]: 20,
    },
    ABSENCES: {
        [LEAVE_OF_ABSENCE_REQUESTS]: 10,
        [LEAVE_OF_ABSENCE_REQUESTS_CALENDAR]: 9999,
        [EXCHANGES]: 9999,
        [ABSENCES]: 15,
        [ABSENCES_OVERVIEW]: 10,
        [AVAILABILITY_CALENDAR]: 9999,
        [TRACKS_FINISHED]: 9999,
    },
    MANAGEMENT: {
        [USERS]: 15,
    },
    PZ: {
        [DOCUMENTS]: 10,
    },
    SHIFTS: {
        [LIST_VIEW]: 25,
    },
    LOGS: 20,
};

export const USER_ROLE_MANAGER = 'manager';
export const USER_ROLE_JUNIOR_MANAGER = 'junior-manager';
export const USER_ROLE_ESTABLISHMENT_MANAGER = 'establishment-manager';
export const USER_ROLE_EMPLOYEE = 'employee';

export const USERS_SORT_EMPLOYEE_NUMBER: UsersSortType = 'employeeNumber';
export const USERS_SORT_FIRST_NAME: UsersSortType = 'firstname';
export const USERS_SORT_LAST_NAME: UsersSortType = 'lastname';

export const ABSENCES_CALENDAR_VIEW_PROPS = [
    'title',
    'date',
];

export type Gender = 'male' | 'female' | 'other' | null;
export const GENDERS: { label: string, value: Gender }[] = [
    {
        label: 'male',
        value: 'male',
    },
    {
        label: 'female',
        value: 'female',
    },
    {
        label: 'other',
        value: 'other',
    },
    {
        label: 'unknown',
        value: null,
    },
];

export type EmployeeTimerSetting = 'shouldTrackTime' | 'isPaidBasedOnTrackedTime' | 'isEligibleForTimeForTime';
export const ABNORMAL_TRACK_DURATION_THRESHOLD = 'ABNORMAL_TRACK_DURATION_THRESHOLD';
export const ENABLE_CAO_MESSAGE_TIME_BETWEEN_SHIFTS = 'ENABLE_CAO_MESSAGE_TIME_BETWEEN_SHIFTS';
export const ENABLE_CAO_MESSAGE_UNDERAGE_EMPLOYEE = 'ENABLE_CAO_MESSAGE_UNDERAGE_EMPLOYEE';
export const ENABLE_CAO_MESSAGE_REMOVE_EMPLOYEE_FROM_SHIFT = 'ENABLE_CAO_MESSAGE_REMOVE_EMPLOYEE_FROM_SHIFT';
export const BREAK_MOBILE = 750;
export const BREAK_TABLET_LANDSCAPE = 1024;
