import {
    ChangeEvent,
    DetailedHTMLProps,
    forwardRef,
    ForwardRefExoticComponent,
    InputHTMLAttributes,
    ReactElement,
    RefAttributes,
    useContext,
    useEffect,
    useState,
} from 'react';

import { getRandomUuid } from '../../helpers/crypto';
import CheckboxGroupContext from './CheckboxGroupContext';

type CheckboxProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & RefAttributes<HTMLInputElement>;

const Checkbox: ForwardRefExoticComponent<CheckboxProps> = forwardRef((props, ref): ReactElement => {
    const {
        checked,
        disabled,
        onChange,
    } = props;

    const [id] = useState(getRandomUuid());
    const checkboxGroup = useContext(CheckboxGroupContext);

    const [shouldTriggerCheckboxContextChange, setShouldTriggerCheckboxContextChange] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean | undefined>(checked !== undefined ? checked : checkboxGroup.defaultChecked);
    const [isDisabled, setIsDisabled] = useState<boolean | undefined>(disabled !== undefined ? disabled : checkboxGroup.defaultDisabled);

    useEffect((): () => void => {
        checkboxGroup.assertIdDoesNotExist(id);

        return (): void => {
            checkboxGroup.checkboxes.delete(id);
            checkboxGroup.onCheckboxChange();
        };
    }, []);

    // Deze set dus alleen de state in dit component
    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    useEffect((): void => {
        checkboxGroup.checkboxes.set(id, {
            isChecked,
            isDisabled,
            props,
            setIsChecked,
            setIsDisabled,
        });

        if (shouldTriggerCheckboxContextChange) {
            checkboxGroup.onCheckboxChange();
            setShouldTriggerCheckboxContextChange(false);
        }
    }, [
        id, isChecked, isDisabled, checkboxGroup, shouldTriggerCheckboxContextChange,
    ]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.persist();

        if (!isDisabled) {
            setShouldTriggerCheckboxContextChange(true);
            setIsChecked(event.target.checked);
        }

        if (onChange !== undefined) {
            onChange(event);
        }
    };

    return (
        <input
            type="checkbox"
            {...props}
            ref={ref}
            onChange={handleChange}
            checked={isChecked !== undefined ? isChecked : false}
            disabled={isDisabled !== undefined ? isDisabled : false}
        />
    );
});

export default Checkbox;
