import { compareAsc } from '../../helpers/date';
import { SortDirection, SortField } from '../../types/tableTypes';
import { DropboxEntity, DropboxFolder, DropboxResource } from './DropboxEntity';

export const isDropboxResource = (resource: any): resource is DropboxResource => (
    typeof resource === 'object'
        && 'type' in resource
        && (resource.type === 'file' || resource.type === 'folder')
);

export const sortDropboxEntities = (files: DropboxEntity[], field?: string, direction: SortDirection = SortDirection.asc): DropboxEntity[] => {
    if (!field || field === SortField.name) {
        files.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (field === SortField.dateAdded) {
        files.sort((a, b) => compareAsc(
            a.modifiedAt || new Date(0),
            b.modifiedAt || new Date(0),
        ));
    }

    if (field === SortField.size) {
        files.sort((a, b) => (a.size || 0) - (b.size || 0));
    }

    return direction === SortDirection.desc ? files.reverse() : files;
};

const flattenDropboxFolderChildren = (dropboxFolders: DropboxFolder[]): DropboxFolder[] => (
    dropboxFolders.flatMap(folder => [folder, ...flattenDropboxFolderChildren(folder.children)])
);

export const doesDropboxFolderHaveActiveChild = (dropboxFolder: DropboxFolder, activeDirectoryId?: string): boolean => {
    const childrenIds = flattenDropboxFolderChildren(dropboxFolder.children).map(child => child.id);

    return !!activeDirectoryId && childrenIds.includes(activeDirectoryId);
};
