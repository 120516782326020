import { localStorageRefreshTokenKey, localStorageTokenKey } from '../../@paco/helpers/authorizedFetch';
import {
    AUTH_AUTHENTICATE_FAILURE,
    AUTH_AUTHENTICATE_REQUEST,
    AUTH_AUTHENTICATE_SUCCESS,
    AUTH_FORGOT_PASSWORD_FAILURE,
    AUTH_FORGOT_PASSWORD_REQUEST,
    AUTH_FORGOT_PASSWORD_SUCCESS,
    AUTH_REQUEST,
    AUTH_SET_API_TOKEN,
    AUTH_SET_REFRESH_TOKEN,
    AUTH_SUBMIT_PASSWORD_REQUEST,
    AUTH_SUBMIT_PASSWORD_SUCCESS,
    AUTH_UNSET_TOKENS,
} from '../actionTypes';

const initialState = {
    loading: false,
    newPasswordSuccess: false,
    isAuthenticated: !!localStorage.getItem(localStorageTokenKey),
    apiToken: localStorage.getItem(localStorageTokenKey),
    apiRefreshToken: localStorage.getItem(localStorageRefreshTokenKey),
    authenticateFailure: false,
    errors: [],
};

const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_REQUEST:
        case AUTH_AUTHENTICATE_REQUEST:
            return { ...state, loading: true, errors: [] };
        case AUTH_AUTHENTICATE_SUCCESS:
            localStorage.setItem(localStorageTokenKey, action.apiToken);
            localStorage.setItem(localStorageRefreshTokenKey, action.refreshToken);
            localStorage.setItem('authenticated', 'true');

            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                apiToken: action.apiToken,
                authenticateFailure: false,
            };
        case AUTH_AUTHENTICATE_FAILURE:
            return {
                ...state, isAuthenticated: false, loading: false, authenticateFailure: true,
            };
        case AUTH_SET_API_TOKEN:
            localStorage.setItem(localStorageTokenKey, action.apiToken);
            return {
                ...state, apiToken: action.apiToken,
            };
        case AUTH_SET_REFRESH_TOKEN:
            localStorage.setItem(localStorageRefreshTokenKey, action.refreshToken);

            return {
                ...state, refreshToken: action.refreshToken,
            };
        case AUTH_UNSET_TOKENS:
            localStorage.removeItem(localStorageRefreshTokenKey);
            localStorage.removeItem(localStorageTokenKey);

            return {
                ...state, apiToken: null, apiRefreshToken: null, isAuthenticated: false,
            };

        case AUTH_FORGOT_PASSWORD_REQUEST:
        case AUTH_SUBMIT_PASSWORD_REQUEST:
            return { ...state, loading: true };
        case AUTH_FORGOT_PASSWORD_SUCCESS:
        case AUTH_FORGOT_PASSWORD_FAILURE:
            return { ...state, loading: false };
        case AUTH_SUBMIT_PASSWORD_SUCCESS:
            return { ...state, loading: false, newPasswordSuccess: true };
        default:
            return state;
    }
};

export default authenticationReducer;
