import { Resource } from './Resource';

interface ShiftPlanningSnapshot {
    planned: number;
    start: string;
    end: string;
    user: string;
}

export interface ShiftSnapshot extends Resource {
    type: 'shiftSnapshots';
    start: string;
    end: string;
    peopleLimit: number;
    department: string;
    shiftPlanningSnapshots: ShiftPlanningSnapshot[];
}

function checkIntegrityOfShiftPlanningSnapshot(snapshot: ShiftPlanningSnapshot): boolean {
    if (!snapshot) {
        return false;
    }

    return !!(snapshot.user
        && (snapshot.planned !== null || snapshot.planned !== undefined)
        && (snapshot.start !== null || snapshot.start !== undefined)
        && (snapshot.end !== null || snapshot.end !== undefined)
    );
}

export function checkIntegrityOfShiftSnapshot(snapshot: ShiftSnapshot): boolean {
    if (!snapshot) {
        return false;
    }

    return !!(snapshot.id
        && snapshot.start
        && snapshot.end
        && (snapshot.peopleLimit !== null || snapshot.peopleLimit !== undefined)
        && snapshot.department
        && snapshot.shiftPlanningSnapshots
            .every(shiftPlanSnapshot => checkIntegrityOfShiftPlanningSnapshot(shiftPlanSnapshot))
    );
}
