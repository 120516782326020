import { DistrictDivision } from '../../entities/DistrictDivision/DistrictDivision';
import { DistrictDivisionDistrict } from '../../entities/DistrictDivisionDistrict/DistrictDivisionDistrict';
import { DistrictDivisionDistrictPlanning } from '../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { DistrictDivisionGroup } from '../../entities/DistrictDivisionGroup/DistrictDivisionGroup';

export const getDistrictDivisionDistrictPlanningRelations = (districtDivisionDistrictPlanningId: string, districtDivision?: DistrictDivision): {
    districtDivision?: DistrictDivision,
    districtDivisionGroup?: DistrictDivisionGroup,
    districtDivisionDistrict?: DistrictDivisionDistrict,
    districtDivisionDistrictPlanning?: DistrictDivisionDistrictPlanning,
} => {
    if (!districtDivision) {
        return {};
    }

    const districtDivisionGroup = districtDivision.districtDivisionGroups
        .find(group => group.districtDivisionDistricts
            .some(district => district.districtDivisionDistrictPlannings
                .some(planning => planning.id === districtDivisionDistrictPlanningId)));

    if (!districtDivisionGroup) {
        return {};
    }

    const districtDivisionDistrict = districtDivisionGroup.districtDivisionDistricts
        .find(district => district.districtDivisionDistrictPlannings
            .some(planning => planning.id === districtDivisionDistrictPlanningId));

    if (!districtDivisionDistrict) {
        return {};
    }

    const districtDivisionDistrictPlanning = districtDivisionDistrict.districtDivisionDistrictPlannings
        .find(planning => planning.id === districtDivisionDistrictPlanningId);

    return {
        districtDivision,
        districtDivisionGroup,
        districtDivisionDistrict,
        districtDivisionDistrictPlanning,
    };
};

export const getDistrictDivisionWithNewDistrictDivisionDistrict = (
    districtDivision: DistrictDivision,
    districtDivisionGroup: DistrictDivisionGroup,
    newDistrictDivisionDistrict: DistrictDivisionDistrict,
): DistrictDivision => {
    const districtIndex = districtDivisionGroup.districtDivisionDistricts.findIndex(district => district.id === newDistrictDivisionDistrict.id);
    const updatedDistrictDivisionGroupDistricts = [...districtDivisionGroup.districtDivisionDistricts];
    updatedDistrictDivisionGroupDistricts.splice(districtIndex, 1, newDistrictDivisionDistrict);

    const updatedDistrictDivisionGroup: DistrictDivisionGroup = {
        ...districtDivisionGroup,
        districtDivisionDistricts: updatedDistrictDivisionGroupDistricts,
    };

    const groupIndex = districtDivision.districtDivisionGroups.findIndex(group => group.id === updatedDistrictDivisionGroup.id);
    const updatedDistrictDivisionGroups = [...districtDivision.districtDivisionGroups];
    updatedDistrictDivisionGroups.splice(groupIndex, 1, updatedDistrictDivisionGroup);

    return {
        ...districtDivision,
        districtDivisionGroups: updatedDistrictDivisionGroups,
    };
};

export const getDistrictDivisionGroupWithoutPlannings = (districtDivisionGroup: DistrictDivisionGroup, districtDivisionDistrictPlanningIds: string[]) => {
    const updatedDistrictDivisionDistricts = districtDivisionGroup.districtDivisionDistricts
        .map(districtDivisionDistrict => {
            const districtDivisionDistrictPlannings = districtDivisionDistrict.districtDivisionDistrictPlannings
                .filter(planning => !districtDivisionDistrictPlanningIds.includes(planning.id));

            return {
                ...districtDivisionDistrict,
                districtDivisionDistrictPlannings,
            };
        });

    return {
        ...districtDivisionGroup,
        districtDivisionDistricts: updatedDistrictDivisionDistricts,
    };
};
