import { addDays, formatDate, isValid } from '../../../helpers/date';
import { translate } from '../../../helpers/translations/translator';
import { Log } from '../../../models/Log';

interface logsDateObjectProps {
    [key: string]: Log[]
}

export const getLogDateBlockDateString = (loggedAt: string, today: Date) => {
    const format = 'dd-MM-yyyy';
    const date = formatDate(loggedAt, format);
    const todayDate = formatDate(today, format);
    const yesterdayDate = formatDate(addDays(today, -1), format);

    if (date === todayDate) {
        return translate('common.today');
    }

    if (date === yesterdayDate) {
        return translate('common.yesterday');
    }

    return date;
};

export const generateLogsDateObject = (logs: Log[]): logsDateObjectProps => logs
    .reduce((total: logsDateObjectProps, log: Log) => {
        const date = formatDate(log.loggedAt, 'yyyy-MM-dd');

        return {
            ...total,
            [date]: [
                ...total[date] || [],
                log,
            ],
        };
    }, {});

export const getTranslationForPropName = (key: string): string => {
    const pages = ['logs', 'common', 'pages.absences', 'pages.management', 'pages.tracks'];
    const match = pages.find(page => translate(`${page}.${key}`) !== `${page}.${key}`);
    if (match) {
        return translate(`${match}.${key}`);
    }

    return key;
};

export const formatValue = (value?: string, propName?: string, entity?: string): string => {
    if (!value) {
        return translate('common.unknown');
    }

    if (Number(value) || Number(value) === 0) {
        return value;
    }

    if (isValid(new Date(value))) {
        return formatDate(value, 'dd MMMM yyyy HH:mm');
    }

    if ((propName === 'meal') || (propName === 'color')) {
        return getTranslationForPropName(value.toLowerCase());
    }

    if (propName === 'status' && entity === 'leaveofabsence') {
        return getTranslationForPropName(value);
    }

    if (propName === 'employmentType' && entity === 'user') {
        return getTranslationForPropName(value);
    }

    if (value === 'not-found') {
        return translate('logs.not-found');
    }

    return value;
};
