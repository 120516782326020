import { FC, ReactNode, useMemo } from 'react';

import { LoadingSpinner } from '../../components';
import { Absence } from '../../entities/Absence/Absence';
import { Department } from '../../entities/Department/Department';
import { transformDepartmentToFormOption } from '../../entities/Department/DepartmentTransformers';
import { DistrictDivisionDistrictPlanning } from '../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { ShiftIndex } from '../../entities/Shift/Shift';
import { ShiftPlanning } from '../../entities/ShiftPlanning/ShiftPlanning';
import trans from '../../helpers/trans';
import { DepartmentOption } from '../../types/selectOptionTypes';
import { DepartmentsInput } from '../index';
import {
    getSelectedDepartmentsLabel,
    isShiftPlanningUsed,
    transformDepartmentOptionToDepartment,
    transformShiftsToShiftPlanningSelectorProps,
} from './helpers';
import { ShiftPlanningDraggable } from './subcomponents';

import './ShiftPlanningSelector.scss';

interface ShiftPlanningSelectorProps {
    isDepartmentsLoading: boolean;
    isDisabled: boolean;
    isShiftsLoading: boolean;
    absences: Absence[];
    departmentOptions: Department[];
    districtDivisionDistrictPlannings?: DistrictDivisionDistrictPlanning[],
    selectedDepartments: Department[];
    shifts: ShiftIndex[];
    onDepartmentChange: (departments: Department[]) => void;
    onShiftPlanningDragStart: (shiftPlanning: ShiftPlanning, departmentId: string) => void;
    onShiftPlanningDragEnd: (isDragging: boolean) => void;
    className?: string;
}

const ShiftPlanningSelector: FC<ShiftPlanningSelectorProps> = ({
    isDepartmentsLoading,
    isDisabled,
    isShiftsLoading,
    absences,
    departmentOptions,
    districtDivisionDistrictPlannings = [],
    selectedDepartments,
    shifts,
    onDepartmentChange,
    onShiftPlanningDragStart,
    onShiftPlanningDragEnd,
    className = '',
}) => {
    const shiftPlannings = useMemo(() => transformShiftsToShiftPlanningSelectorProps(shifts, absences), [shifts, absences]);
    const inputValueLabel = useMemo(() => getSelectedDepartmentsLabel(selectedDepartments.length), [selectedDepartments.length]);

    const handleDepartmentChange = (chosenDepartments: DepartmentOption[]): void => {
        const departments = chosenDepartments
            .map(chosenDepartment => transformDepartmentOptionToDepartment(departmentOptions, chosenDepartment))
            .filter(dep => dep) as Department[];

        onDepartmentChange(departments);
    };


    return (
        <div className={`shift-planning-selector ${className}`}>
            <div className="shift-planning-selector__header">
                <h2 className="shift-planning-selector__title">{trans('common.employees')}</h2>
                <DepartmentsInput
                    disabled={isDisabled}
                    hideLabel
                    isSearchable={false}
                    isLoading={isDepartmentsLoading}
                    hideSelectedOptions={false}
                    departments={departmentOptions}
                    placeholder={inputValueLabel}
                    selectedDepartments={selectedDepartments.map(transformDepartmentToFormOption)}
                    valueLabel={inputValueLabel}
                    onDepartmentsChange={handleDepartmentChange}
                    className="shift-planning-selector__select-departments"
                />
            </div>
            <div className="shift-planning-selector__shift-plannings-container">
                {!!shiftPlannings.length && (
                    <ul className="shift-planning-selector__shift-plannings">
                        {shiftPlannings.reduce((total: ReactNode[], shiftPlanningDraggableProps, index) => {
                            const prevSiblingHasDifferentDepartment = (index > 0 && shiftPlannings[index].department.id !== shiftPlannings[index - 1].department.id);
                            const isUsed = districtDivisionDistrictPlannings.some(districtPlanning => isShiftPlanningUsed(districtPlanning, shiftPlanningDraggableProps));

                            return [
                                ...total,
                                ...(prevSiblingHasDifferentDepartment ? [(
                                    <li key={`${shiftPlanningDraggableProps.shiftPlanning.id}-divider`}>
                                        <div className="shift-planning-selector__shift-planning-divider" />
                                    </li>
                                )] : []),
                                <li key={shiftPlanningDraggableProps.shiftPlanning.id} className="shift-planning-selector__list-item">
                                    <ShiftPlanningDraggable
                                        {...shiftPlanningDraggableProps}
                                        isDisabled={isDisabled}
                                        isUsed={isUsed}
                                        key={shiftPlanningDraggableProps.shiftPlanning.id}
                                        onDragStart={onShiftPlanningDragStart}
                                        onDragEnd={onShiftPlanningDragEnd}
                                    />
                                </li>,
                            ];
                        }, [])}
                    </ul>
                )}
                {!shiftPlannings.length && <div className="shift-planning-selector__no-shift-plannings">{trans('compositions.shiftPlanningSelector.noShiftPlanningsFound')}</div>}
                {isShiftsLoading && <LoadingSpinner isSmall className="shift-planning-selector__loader" />}
            </div>
        </div>
    );
};

export default ShiftPlanningSelector;
