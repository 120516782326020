import { UserWithAvailability } from '../../../models';

const removeShiftPlanningFromUsersWithAvailability = (
    users: UserWithAvailability[],
    deletedShiftPlanningId: string,
): UserWithAvailability[] => users.map(user => {
    const shiftPlanningIndex = user.shiftPlannings.findIndex(shiftPlanning => shiftPlanning.id === deletedShiftPlanningId);
    const shiftPlannings = shiftPlanningIndex !== -1 ? user.shiftPlannings.splice(shiftPlanningIndex, 1) : user.shiftPlannings;

    return {
        ...user,
        shiftPlannings,
    };
});

export default removeShiftPlanningFromUsersWithAvailability;
