import { isBefore } from '../../helpers/date';
import { ShiftPlanningViewModel, ShiftStatusType } from '../../models';

export default function getShiftStatus(
    // eslint-disable-next-line default-param-last
    shiftPlannings: ShiftPlanningViewModel[] = [],
    peopleLimit: number,
    start: Date,
    temporaryWorkerLength = 0,
    isBaseScheduleShift = false,
): ShiftStatusType {
    const plannedShiftPlannings = (shiftPlannings || [])
        .filter((shiftPlanning) => shiftPlanning.planned);
    const totalEmployeesLength = plannedShiftPlannings.length + temporaryWorkerLength;

    if (!isBaseScheduleShift && isBefore(start, new Date())) {
        return ShiftStatusType.past;
    }

    if (totalEmployeesLength >= peopleLimit) {
        return ShiftStatusType.full;
    }

    return ShiftStatusType.available;
}
