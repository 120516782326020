import React, { FC, useEffect } from 'react';

import { connect, useDispatch } from 'react-redux';

import Logs, { LogsPageProps } from '../../components/Logs/Logs';
import { getDeletedLogs } from '../../redux/logs/logsActions';
import { setPaginationNumber } from '../../redux/pagination/paginationActions';

type LogsConnectorProps = LogsPageProps;

const LogsConnector: FC<LogsConnectorProps> = ({
    loading,
    logs,
    pagination,
}) => {
    const dispatch = useDispatch();

    const onPagination = (page: number) => {
        dispatch(setPaginationNumber(page));
        dispatch(getDeletedLogs());
    };

    useEffect(() => {
        dispatch(getDeletedLogs());
    }, []);

    return (
        <Logs
            pagination={pagination}
            onPagination={onPagination}
            loading={loading}
            logs={logs}
        />
    );
};

function mapStateToProps(state: any) {
    return {
        loading: state.logsReducer.loading,
        logs: state.logsReducer.deletedLogs,
        pagination: state.paginationReducer,
    };
}

export default connect(mapStateToProps)(LogsConnector);
