import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../../redux/@config/AsyncReduxState';
import { LoketLeaveOfAbsenceHour } from '../../../entities/LeaveOfAbsenceHour/LeaveOfAbsenceHour';
import { LoketLeaveSyncError } from '../../../entities/LoketLeaveSync/LoketLeaveSync';

export type ExportLeaveOfAbsencesFormReducerState = AsyncReduxState<{
    isLoketLeaveSyncSuccessful: boolean;
    leaveOfAbsenceHours: LoketLeaveOfAbsenceHour[];
    loketLeaveSyncErrors: LoketLeaveSyncError[];
}>

const initialState: ExportLeaveOfAbsencesFormReducerState = {
    ...initialAsyncReduxState,
    isLoketLeaveSyncSuccessful: false,
    leaveOfAbsenceHours: [],
    loketLeaveSyncErrors: [],
};

const exportLeaveOfAbsencesFormSlice = createSlice({
    name: 'exportLeaveOfAbsencesFormReducer',
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<string>): ExportLeaveOfAbsencesFormReducerState => ({
            ...state,
            error: action.payload,
        }),
        setIsLoading: (state, action: PayloadAction<boolean>): ExportLeaveOfAbsencesFormReducerState => ({
            ...state,
            isLoading: action.payload,
        }),
        setIsLoketLeaveSyncSuccessful: (state, action: PayloadAction<boolean>): ExportLeaveOfAbsencesFormReducerState => ({
            ...state,
            isLoketLeaveSyncSuccessful: action.payload,
        }),
        setIsSuccessful: (state, action: PayloadAction<boolean>): ExportLeaveOfAbsencesFormReducerState => ({
            ...state,
            isSuccessful: action.payload,
        }),
        setLeaveOfAbsenceHours: (state, action: PayloadAction<LoketLeaveOfAbsenceHour[]>): ExportLeaveOfAbsencesFormReducerState => ({
            ...state,
            leaveOfAbsenceHours: action.payload,
        }),
        setLoketLeaveSyncErrors: (state, action: PayloadAction<LoketLeaveSyncError[]>): ExportLeaveOfAbsencesFormReducerState => ({
            ...state,
            loketLeaveSyncErrors: action.payload,
        }),
        reset: () => ({
            ...initialState,
        }),
    },
});

export const {
    reset,
    setError,
    setIsLoading,
    setIsLoketLeaveSyncSuccessful,
    setIsSuccessful,
    setLeaveOfAbsenceHours,
    setLoketLeaveSyncErrors,
} = exportLeaveOfAbsencesFormSlice.actions;

export default exportLeaveOfAbsencesFormSlice.reducer;
