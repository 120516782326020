import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { Absence } from '../../entities/Absence/Absence';
import { LeaveOfAbsence } from '../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { PreferToWorkTimeSlot } from '../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { UnavailableToWorkTimeSlot } from '../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { WorkweekUser } from '../../entities/User/User';

export type WorkweekUsersState = AsyncReduxState<{
    isLoadingUnavailableUsers: boolean;
    absences: Absence[];
    leaveOfAbsences: LeaveOfAbsence[];
    preferToWorkTimeSlots: PreferToWorkTimeSlot[];
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[];
    workweekUsers: WorkweekUser[];
    unavailableUsers: string[];
}>;

const initialState: WorkweekUsersState = {
    ...initialAsyncReduxState,
    isLoadingUnavailableUsers: false,
    absences: [],
    leaveOfAbsences: [],
    preferToWorkTimeSlots: [],
    unavailableToWorkTimeSlots: [],
    workweekUsers: [],
    unavailableUsers: [],
};

export const workweekUsersSlice = createSlice({
    name: 'workweekUsersReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): WorkweekUsersState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsLoadingUnavailableUsers(state, action: PayloadAction<boolean>): WorkweekUsersState {
            return {
                ...state,
                isLoadingUnavailableUsers: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): WorkweekUsersState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): WorkweekUsersState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setAbsences(state, action: PayloadAction<Absence[]>): WorkweekUsersState {
            return {
                ...state,
                absences: action.payload,
            };
        },
        setLeaveOfAbsences(state, action: PayloadAction<LeaveOfAbsence[]>): WorkweekUsersState {
            return {
                ...state,
                leaveOfAbsences: action.payload,
            };
        },
        setPreferToWorkTimeSlots(state, action: PayloadAction<PreferToWorkTimeSlot[]>): WorkweekUsersState {
            return {
                ...state,
                preferToWorkTimeSlots: action.payload,
            };
        },
        setUnavailableToWorkTimeSlots(state, action: PayloadAction<UnavailableToWorkTimeSlot[]>): WorkweekUsersState {
            return {
                ...state,
                unavailableToWorkTimeSlots: action.payload,
            };
        },
        setWorkweekUsers(state, action: PayloadAction<WorkweekUser[]>): WorkweekUsersState {
            return {
                ...state,
                workweekUsers: action.payload,
            };
        },
        setUnavailableUsers(state, action: PayloadAction<string[]>): WorkweekUsersState {
            return {
                ...state,
                unavailableUsers: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsLoadingUnavailableUsers,
    setIsSuccessful,
    setError,
    setAbsences,
    setLeaveOfAbsences,
    setPreferToWorkTimeSlots,
    setUnavailableToWorkTimeSlots,
    setWorkweekUsers,
    setUnavailableUsers,
} = workweekUsersSlice.actions;

export default workweekUsersSlice.reducer;
