import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TrackViewModel } from '../../../../models';
import { AsyncReduxState, initialAsyncReduxState } from '../../../@config/AsyncReduxState';

export type OpenTracksState = AsyncReduxState<{
    openTracks: TrackViewModel[];
}>;

const initialState: OpenTracksState = {
    ...initialAsyncReduxState,
    openTracks: [],
};

export const OpenTracksSlice = createSlice({
    name: 'openTracksReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OpenTracksState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): OpenTracksState {
            return ({
                ...state,
                isSuccessful: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): OpenTracksState {
            return ({
                ...state,
                error: action.payload,
            });
        },
        setOpenTracks(state, action: PayloadAction<TrackViewModel[]>): OpenTracksState {
            return ({
                ...state,
                openTracks: action.payload,
            });
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setOpenTracks,
    setError,
} = OpenTracksSlice.actions;

export default OpenTracksSlice.reducer;
