import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../../models';

import './IconTrash.scss';

const IconTrash: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 15 15" fill="none" className={`icon-trash icon-svg ${className}`}>
        {/* eslint-disable-next-line max-len */}
        <path d="M4.0625 0V0.833333H0V2.5H0.8125V13.3333C0.8125 13.7754 0.983705 14.1993 1.28845 14.5118C1.5932 14.8244 2.00652 15 2.4375 15H10.5625C10.9935 15 11.4068 14.8244 11.7115 14.5118C12.0163 14.1993 12.1875 13.7754 12.1875 13.3333V2.5H13V0.833333H8.9375V0H4.0625ZM2.4375 2.5H10.5625V13.3333H2.4375V2.5ZM4.0625 4.16667V11.6667H5.6875V4.16667H4.0625ZM7.3125 4.16667V11.6667H8.9375V4.16667H7.3125Z" />
    </svg>
);

export default IconTrash;
