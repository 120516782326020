import React from 'react';

import { hotjar } from 'react-hotjar';
import { unique } from 'underscore';

import { transformToPeriod } from '../@paco/entities/Period/PeriodTransformers';
import { Permission } from '../@paco/entities/Permission/Permission';
import { Role } from '../@paco/entities/Role/Role';
import { doesUnavailableToWorkTimeSlotOverlapWithPeriod } from '../@paco/entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotHelpers';
import { UserProfile } from '../@paco/entities/User/User';
import { RoleType } from '../@paco/types/roleTypes';
import {
    GENDERS,
    TYPE_ABSENCE,
    TYPE_LEAVE_OF_ABSENCE,
    TYPE_LEAVE_OF_ABSENCE_REQUEST,
    TYPE_UNAVAILABLE_TO_WORK_TIME_SLOT,
    USER_ROLE_EMPLOYEE,
    USER_ROLE_JUNIOR_MANAGER,
    USER_ROLE_MANAGER,
    WEEKDAYS,
} from '../constants';
import { DepartmentResource, DepartmentViewModel } from '../entities/Departments/Departments';
import { UnavailableToWorkTimeSlotViewModel } from '../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import {
    Absence,
    AbsenceViewModel,
    BasicUser,
    LeaveOfAbsence,
    LeaveOfAbsenceViewModel,
    PayrollPeriodViewModel,
    PreferToWorkTimeSlot,
    UnavailableToWorkTimeSlot,
    User,
} from '../models';
import { transformLegacyUnavailableToWorkTimeSlot } from '../services/UnavailableToWorkTimeSlotService/transformLegacyUnavailableToWorkTimeSlot';
import {
    addSeconds,
    areIntervalsOverlapping,
    endOfDay,
    formatDate,
    getEndOfWorkDay,
    getStartOfWorkDay,
    isEqualOrWithin24Hours,
    startOfDay,
} from './date';
import { generateDownloadURI } from './tools/browser';
import { translate } from './translations/translator';

export * from './tools/browser';
export * from './tools/countries';
export * from './tools/icons';
export * from './tools/modal';
export * from './tools/text';

export interface Option {
    label: string;
    value: string | number | null;
}

export interface GroupedDepartmentOption {
    label: string;
    options: Option[];
    id?: string;
}

export const getObjProperty = (containerObj: any, str: string, defaultValueArg?: any): any => {
    const defaultValue = typeof defaultValueArg !== 'undefined' ? defaultValueArg : null;

    try {
        return str.split('.').reduce((obj, key) => obj[key], containerObj);
    } catch (e) {
        return defaultValue;
    }
};

export const isObjEmpty = (object: any) => Object.keys(object).length === 0;

export const generateWeekdayOptions = (): JSX.Element[] => [
    ...WEEKDAYS.map((weekDay, index) => (
        <option
            key={`index-${index + 1}`}
            value={index + 1}
        >
            {translate(`common.weekDays.${weekDay}`)}
        </option>
    )),
];

export const generateGenderSelectOptions = (): Option[] => [
    ...GENDERS.map((gender) => ({
        value: gender.value,
        label: translate(`common.${gender.label}`),
    })),
];

export function getDepartmentGroups(departments: (DepartmentResource | DepartmentViewModel)[]): any[] {
    const groups = unique(
        departments
            .map(department => department.group)
            .filter(group => group)
            .sort((a, b) => (a?.name || '').localeCompare(b?.name || '')),
        'id',
    );

    groups.map((group: any) => ({
        ...group,
        departments: departments.filter(department => getObjProperty(department, 'group.id') === group.id),
    }));

    return groups;
}

// Generate departments for a react-select component
export const generateGroupedDepartmentsSelectList = (
    allDepartments: DepartmentViewModel[],
    mainDepartment?: DepartmentViewModel | null,
): GroupedDepartmentOption[] | null => {
    if (!allDepartments) {
        return null;
    }

    const departments = allDepartments
        .filter(department => (!mainDepartment || mainDepartment.id !== department.id));

    const groups = getDepartmentGroups(departments);
    const ungrouped: Option[] = departments
        .filter(department => !department.group)
        .sort((a, b) => (a?.name || '').localeCompare(b?.name || ''))
        .map(department => ({
            value: department.id,
            label: department.name,
        }));
    const grouped: GroupedDepartmentOption[] = groups.map(group => ({
        label: group.name,
        id: group.id,
        options: departments
            .filter(department => getObjProperty(department, 'group.id') === group.id)
            .sort((a, b) => (a?.name || '').localeCompare(b?.name || ''))
            .map(department => ({
                value: department.id,
                label: department.name,
            })),
    }));

    return [...grouped, { label: translate('pages.management.looseDepartments'), options: ungrouped }];
};

export const checkPermission = (
    permissions: Permission[],
    slug: string,
): boolean => permissions.some(permission => permission.slug === slug);

export const getFirstName = (
    user: User,
): string => getObjProperty(user, 'person.firstname') || getObjProperty(user, 'user.firstname')
|| getObjProperty(user, 'firstname') || '';

export const getLastName = (
    user: User,
): string => getObjProperty(user, 'person.lastname') || getObjProperty(user, 'user.lastname')
|| getObjProperty(user, 'lastname') || '';

export const getUserAffixAndLastName = (user: any): string => {
    if (!user) {
        return '';
    }

    const lastname = user.lastname || getObjProperty(user, 'person.lastname') || '';
    const affix = user.affix || getObjProperty(user, 'person.affix') || '';
    return `${affix ? ` ${affix}` : ''} ${lastname}`;
};

export const getUserFullName = (user: any): string => {
    if (!user) {
        return '';
    }

    const firstname = user.firstname || getObjProperty(user, 'person.firstname') || '';
    const lastname = user.lastname || getObjProperty(user, 'person.lastname') || '';
    const affix = user.affix || getObjProperty(user, 'person.affix') || '';

    return `${firstname}${affix ? ` ${affix}` : ''} ${lastname}`;
};

export const sortUsersAlphabetically = (users: User[]): User[] => users.sort((a, b) => {
    const firstname = getFirstName(a).localeCompare(getFirstName(b));
    if (firstname !== 0) {
        return firstname;
    }

    return getLastName(a).localeCompare(getLastName(b));
});

export const removeLocationQueries = (): void => {
    // Remove queries like focusShift={id}
    const { pathname } = window.location;
    window.history.replaceState({}, pathname, pathname);
};

export const scrollToElementAndCenter = (element: HTMLElement): void => {
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle = absoluteElementTop - (window.innerHeight / 2);
    window.scrollTo(0, middle);
};

export const getFilenameAndGenerateCSV = (response: any, defaultName?: string): void => {
    const contentDisposition = getObjProperty(response, 'headers.content-disposition');
    const filename = contentDisposition ? contentDisposition.split('filename=')[1] : `${translate('common.exportDefaultName')}.csv`;
    const uri = `data:text/csv;charset=utf-8;header=present,${encodeURIComponent(response.data)}`;

    generateDownloadURI(uri, filename || defaultName);
};

export const initHotjar = () => {
    const hostname = window?.location.hostname || '';

    if (process.env.REACT_APP_APP_ENV === 'production' && hostname.includes('akersloot')) {
        hotjar.initialize(3467291, 6);
    }
};

export const filterDuplicateUsers = (
    arrayA: (User | BasicUser)[],
    arrayB: (User | BasicUser)[],
): (
    User | BasicUser
    )[] => arrayA
    .filter(a => !arrayB.find(b => a.id === b.id));

export const generateAbsenceText = (absence: Absence | AbsenceViewModel) => {
    const format = 'EEEE dd-MM';
    const start = formatDate(absence.start, format);

    if (!absence.end) {
        return translate('pages.absences.calledInSickFrom', { start });
    }

    const end = formatDate(
        (typeof absence.end === 'string')
            ? absence.end
            : absence.end.transformToUpToButExcludingDate().date,
        format,
    );

    return translate('pages.absences.calledInSickFromTo', { start, end });
};

export const generateLeaveOfAbsenceText = (loa: LeaveOfAbsence | LeaveOfAbsenceViewModel, request?: boolean): string => {
    const { reason } = loa;
    const format = 'EEEE dd-MM';
    const start = formatDate(loa.start, format);

    const endDate = (typeof loa.end === 'string')
        ? new Date(loa.end)
        : loa.end;

    const justifiedEnd = (endDate instanceof Date)
        ? endDate
        : endDate.transformToUpToButExcludingDate().date;

    // Deduct 1 second to prevent 00:00 dates to
    // show extra day
    const end = formatDate(addSeconds(justifiedEnd, -1), format);

    const type = translate(`pages.absences.leaveOfAbsence${request ? 'Request' : ''}`);

    if (isEqualOrWithin24Hours(loa.start, justifiedEnd)) {
        return translate('pages.absences.leaveOfAbsenceOnDateFromTo', {
            type,
            start: formatDate(loa.start, 'HH:mm'),
            end: formatDate(justifiedEnd, 'HH:mm'),
            reason,
            date: start,
        });
    }

    return translate('pages.absences.leaveOfAbsenceFromTo', {
        type,
        start,
        end,
        reason,
    });
};

const generateUnavailableText = (unavailableWorkTimeSlot: UnavailableToWorkTimeSlot): string => (
    `${translate('pages.shifts.notAvailable')}: ${unavailableWorkTimeSlot.comment}`
);

export const generateAbsenceTooltipText = (
    type: string,
    user: any,
): string => {
    let text;
    switch (type) {
        case TYPE_LEAVE_OF_ABSENCE:
            text = generateLeaveOfAbsenceText(user.leaveOfAbsence);
            break;
        case TYPE_LEAVE_OF_ABSENCE_REQUEST:
            text = generateLeaveOfAbsenceText(user.leaveOfAbsenceRequest, true);
            break;
        case TYPE_ABSENCE:
            text = generateAbsenceText(user.absence);
            break;
        case TYPE_UNAVAILABLE_TO_WORK_TIME_SLOT:
            text = generateUnavailableText(user.unavailableWorkTimeSlot);
            break;
        default:
            text = '';
    }

    return text;
};

export const hasPermissionToEditUser = (userToEdit: User | UserProfile, permissions: Permission[], currentUserId?: string): boolean => {
    const role = 'roles' in userToEdit ? userToEdit.roles[0] : userToEdit.role;

    if (!role) {
        return false;
    }

    const isUserEmployee = role.slug === RoleType.employee;
    const canEditAllPeople = checkPermission(permissions, 'edit-all-people');
    const canEditAllUserProfiles = checkPermission(permissions, 'edit-all-user-profiles');
    const canEditEmployeeProfiles = checkPermission(permissions, 'edit-all-employee-profiles');
    const canEditOwnUserProfileFields = checkPermission(permissions, 'edit-own-user-profile-fields');
    const sameUserAsCurrentUser = currentUserId === userToEdit.id;

    if (!canEditAllPeople) {
        return false;
    }

    if (isUserEmployee && canEditEmployeeProfiles) {
        return true;
    }

    if (sameUserAsCurrentUser && canEditOwnUserProfileFields) {
        return true;
    }

    return canEditAllUserProfiles;
};

export const getUrlVars = (search: string): any => {
    const vars: any = {};
    const regEx = /[?&]+([^=&]+)=([^&]*)/gi;
    // eslint-disable-next-line no-return-assign
    search.replace(regEx, (m: string, key: any, value: any) => vars[key] = value);
    return vars;
};

export const getUrlParam = (search: string, parameter: string): string => {
    let urlparameter;
    if (search.indexOf(parameter) > -1) {
        urlparameter = getUrlVars(search)[parameter];
    }
    return urlparameter;
};

export const getTranslationForBackendErrors = (msg: string): string => {
    switch (msg) {
        case 'Invalid value':
            return translate('pages.error.invalidValue');
        case 'Invalid relation type':
            return translate('pages.error.invalidRelationType');
        default:
            return msg;
    }
};

export const getPermissionToAdd = (
    currentUserRole: Role | undefined,
    permissions: Permission[],
    type: string,
): boolean => {
    if (!currentUserRole) {
        return false;
    }

    if (checkPermission(permissions, `add-new-${type}`)) {
        return true;
    }

    const canEditEmployeeLOAs = checkPermission(permissions, `add-new-employee-${type}`);
    const canEditJuniorManagerLOAs = checkPermission(permissions, `add-new-junior-manager-${type}`);
    const canEditManagerLOAs = checkPermission(permissions, `add-new-manager-${type}`);
    const userRoleIsEmployee = currentUserRole.slug === USER_ROLE_EMPLOYEE;
    const userRoleIsJunMa = currentUserRole.slug === USER_ROLE_JUNIOR_MANAGER;
    const userRoleIsManager = currentUserRole.slug === USER_ROLE_MANAGER;

    return !!(canEditEmployeeLOAs && userRoleIsEmployee)
        || !!(canEditJuniorManagerLOAs && userRoleIsJunMa)
        || !!(canEditManagerLOAs && userRoleIsManager);
};

export const getPermissionToDelete = (
    userRoles: Role[] = [],
    permissions: Permission[],
    type: string,
): boolean => {
    if (checkPermission(permissions, `delete-all-${type}`)) {
        return true;
    }

    if (checkPermission(permissions, `edit-employee-${type}`)) {
        return true;
    }

    const canEditEmployeeLOAs = checkPermission(permissions, `delete-employee-${type}`);
    const canEditJuniorManagerLOAs = checkPermission(permissions, `delete-junior-manager-${type}`);
    const canEditManagerLOAs = checkPermission(permissions, `delete-manager-${type}`);
    const userRoleIsEmployee = userRoles.find(role => role.slug === USER_ROLE_EMPLOYEE);
    const userRoleIsJunMa = userRoles.find(role => role.slug === USER_ROLE_JUNIOR_MANAGER);
    const userRoleIsManager = userRoles.find(role => role.slug === USER_ROLE_MANAGER);

    return !!(canEditEmployeeLOAs && userRoleIsEmployee)
        || !!(canEditJuniorManagerLOAs && userRoleIsJunMa)
        || !!(canEditManagerLOAs && userRoleIsManager);
};

export const getOverlappingPayrollPeriodOnWorkday = (resource: PayrollPeriodViewModel, day: Date) => (
    areIntervalsOverlapping(
        {
            start: getStartOfWorkDay(day),
            end: getEndOfWorkDay(day),
        },
        {
            start: resource.start,
            end: resource.end.date,
        },
    )
);

export const getOverlappingResourceOnDate = (resource: any, day: Date): boolean => (
    areIntervalsOverlapping(
        {
            start: startOfDay(day),
            end: endOfDay(day),
        },
        {
            start: resource.start,
            end: resource.end,
        },
    )
);

export const getOverlappingAvailableOnDate = (
    resource: PreferToWorkTimeSlot,
    start: Date | string,
): boolean => (
    areIntervalsOverlapping(
        {
            start,
            end: start,
        },
        {
            start: getStartOfWorkDay(resource.start),
            end: getEndOfWorkDay(resource.start),
        },
    ));

export const getOverlappingUnavailableSlotOnWeekday = (
    unavailableSlot: UnavailableToWorkTimeSlot | UnavailableToWorkTimeSlotViewModel,
    date: Date,
): boolean => {
    const { weekday } = unavailableSlot;
    const day = date.getDay();
    return weekday === day || (weekday === 7 && day === 0);
};

export const getNotAvailablePreferences = (
    planningPreferences: UnavailableToWorkTimeSlot[] = [],
    start: Date | string,
    end: Date | string,
): UnavailableToWorkTimeSlot[] => {
    if (!start || !end || !planningPreferences || !planningPreferences.length) {
        return [];
    }

    return planningPreferences.filter(planningPreference => (
        doesUnavailableToWorkTimeSlotOverlapWithPeriod(
            transformLegacyUnavailableToWorkTimeSlot(planningPreference),
            transformToPeriod(new Date(start), new Date(end)),
        )
    ));
};

export const getFirstLetterOfEmploymentType = (
    employmentTypeSlug: string,
): string => (employmentTypeSlug
    ? translate(`common.${employmentTypeSlug}`).charAt(0).toUpperCase()
    : 'U');

export const getAvailablePreferences = (
    planningPreferences: (PreferToWorkTimeSlot)[] = [],
    start: Date | string,
): PreferToWorkTimeSlot[] => {
    if (!start || !planningPreferences || !planningPreferences.length) {
        return [];
    }

    return planningPreferences
        .filter(planningPreference => getOverlappingAvailableOnDate(planningPreference, start));
};

export const generateSelectOptions = (array: (string | number)[]): JSX.Element[] => array
    .map((type: string | number) => {
        const label = typeof type === 'string' ? translate(`common.${type}`) : type;
        return (
            <option key={type} value={type}>
                {label}
            </option>
        );
    });

export const getYearsFromPeriods = (periods: PayrollPeriodViewModel[]): number[] => unique(
    periods.map(period => period.year),
).sort((a, b) => a - b);

export const getLoketApi = (hostname: string, apiUrl?: string): string | undefined => {
    if (hostname.indexOf('valkplanner.nl') !== -1) {
        return `${apiUrl}`.replace('*', hostname.split('.')[0]);
    }

    if ((hostname.indexOf('cdemo.dev') !== -1) && (hostname.indexOf('sprint') === -1)) {
        return `${apiUrl}`.replace('*', hostname.split('-')[0]);
    }

    return apiUrl;
};

export const getLoketApiUrl = (): string | undefined => getLoketApi(
    document.location.hostname,
    process.env.REACT_APP_LOKET_API_URL,
);
