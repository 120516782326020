import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import {
    AbsenceHoursResource,
    AbsenceResource,
    AbsenceViewModel,
    CommentResource,
    UserResource,
} from '../../models';
import transformAbsenceHoursResource from '../AbsenceHoursService/transformAbsenceHoursResource';
import transformCommentResource from '../CommentService/transformCommentResource';
import transformUserResource from '../UserService/transformUserResource';

export default function transformAbsenceResource(
    absence: AbsenceResource,
    user?: UserResource,
    comments?: CommentResource[],
    absenceHours?: AbsenceHoursResource[],
): AbsenceViewModel {
    return {
        type: 'absences',
        id: absence.id,
        start: new Date(absence.start),
        ...absence.end && { end: new UpToButExcludingDate(absence.end).transformToUpToAndIncludingDate() },
        waitingDayHours: absence.waitingDayHours || 0,
        user: user ? transformUserResource(user) : undefined,
        comments: (comments || [])
            .map(resource => transformCommentResource(resource)),
        absenceHours: (absenceHours || [])
            .map((resource) => transformAbsenceHoursResource(resource)),
    };
}
