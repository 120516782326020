import axios from 'axios';

const fetchEmployees = (date, department, includeDepartments) => {
    const includes = [
        'person',
        'roles',
        ...(includeDepartments ? ['departments', 'departments.group'] : []),
    ];
    const endpoint = date ? `/users/workweek/${date}` : '/users/';

    return axios.get(
        `${process.env.REACT_APP_API_URL}${endpoint}`,
        {
            params: {
                include: includes.join(','),
                orderBy: JSON.stringify({ 'person.firstname': 'ASC' }),
                ...(department && { departments: JSON.stringify([department]) }),
                notIncludingRoles: JSON.stringify(['viewer']),
            },
        },
    );
};

const fetchHumanResourceEmployees = () => axios.get(
    `${process.env.REACT_APP_API_URL}/users`,
    {
        params: {
            roles: JSON.stringify('human-resources'),
        },
    },
);

export {
    fetchEmployees,
    fetchHumanResourceEmployees,
};
