import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import './Toggle.scss';

interface ToggleProps {
    isActive: boolean;
    text?: string;
    onChange: (isActive: boolean) => void;
    className?: string;
}

const Toggle: FC<ToggleProps> = ({
    isActive,
    text,
    onChange,
    className = '',
}): ReactElement => {
    const toggleClassNames = classNames('toggle__switch', {
        'toggle__switch--is-active': isActive,
    });

    const handleClick = (): void => {
        onChange(!isActive);
    };

    return (
        <button
            type="button"
            onClick={handleClick}
            className="toggle"
        >
            <div className={`${toggleClassNames} ${className}`}>
                <div className="toggle__handle" />
            </div>
            {text}
        </button>
    );
};

export default Toggle;
