import {
    ChangeEvent,
    FC,
    FocusEvent,
    useEffect,
    useRef,
    useState,
} from 'react';

import useKeyPress from '../../../../helpers/hooks/useKeypress';
import { Input } from '../../../components';
import { IconButton } from '../../../compositions';
import { Period } from '../../../entities/Period/Period';
import { isSamePeriod } from '../../../entities/Period/PeriodHelpers';
import { transformDateAndTimesToPeriod } from '../../../entities/Period/PeriodTransformers';
import { formatDate } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { DateFormat } from '../../../types/dateFormatTypes';

import './EditShiftTimeForm.scss';

interface EditShiftTimeFormProps {
    period: Period;
    onCancel: () => void;
    onDeleteButtonClick: () => void;
    onSubmit: (period: Period) => void;
    className?: string;
}

const EditShiftTimeForm: FC<EditShiftTimeFormProps> = ({
    period,
    onCancel,
    onDeleteButtonClick,
    onSubmit,
    className = '',
}) => {
    const escapeKeyCode = useKeyPress(27);
    const enterKeyCode = useKeyPress(13);
    const formRef = useRef<HTMLInputElement>(null);

    const [startTime, setStartTime] = useState(formatDate(period.start, DateFormat.hoursMinutes));
    const [endTime, setEndTime] = useState(formatDate(period.end, DateFormat.hoursMinutes));

    const handleSubmit = (): void => {
        const newPeriod = transformDateAndTimesToPeriod(period.start, startTime, endTime);

        if (isSamePeriod(newPeriod, period)) {
            onCancel();

            return;
        }

        onSubmit(transformDateAndTimesToPeriod(period.start, startTime, endTime));
    };

    const handleBlur = (event: FocusEvent<HTMLFormElement>): void => {
        if (event.currentTarget.contains(event.relatedTarget)) {
            return;
        }

        handleSubmit();
    };

    const handleStartTimeChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setStartTime(event.target.value);
    };

    const handleEndTimeChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setEndTime(event.target.value);
    };

    useEffect((): void => {
        if (enterKeyCode) {
            handleSubmit();
        }
    }, [enterKeyCode]);

    useEffect((): void => {
        if (escapeKeyCode) {
            onCancel();
        }
    }, [escapeKeyCode]);

    useEffect((): void => {
        if (formRef.current) {
            formRef.current.focus();
        }
    }, [formRef]);

    return (
        <form
            onBlur={handleBlur}
            className={`edit-shift-time-form ${className}`}
        >
            <Input
                ref={formRef}
                type="time"
                value={startTime}
                onChange={handleStartTimeChange}
                className="edit-shift-time-form__input"
            />
            <Input
                type="time"
                value={endTime}
                onChange={handleEndTimeChange}
                className="edit-shift-time-form__input"
            />
            <IconButton
                hideLabel
                icon="trash"
                text={trans('common.delete')}
                onClick={onDeleteButtonClick}
                className="edit-shift-time-form__delete-button"
                iconClassName="edit-shift-time-form__delete-button-icon"
            />
        </form>
    );
};

export default EditShiftTimeForm;
