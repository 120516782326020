import { DepartmentResource } from '../../entities/Departments/Departments';
import { transformDepartmentResource } from '../../entities/Departments/DepartmentsTransformers';
import { getUserFullName } from '../../helpers';
import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import {
    CommentResource,
    MealType,
    TrackResource,
    TrackViewModel,
    UserResource,
} from '../../models';
import { FeedbackResource } from '../../models/Feedback';
import transformCommentResource from '../CommentService/transformCommentResource';
import transformFeedbackResource from '../FeedbackService/transformFeedbackResource';
import transformUserResource from '../UserService/transformUserResource';

export default function transformTrackResource(
    track: TrackResource,
    owner: UserResource,
    department?: DepartmentResource,
    feedback?: FeedbackResource,
    shift?: TrackResource['shift'],
    comments?: CommentResource[],
): TrackViewModel {
    const comment = comments?.length ? comments[0] : undefined;

    return {
        accepted: track.accepted,
        isOffline: track.isOffline,
        ...track.assessor && { assessor: getUserFullName(track.assessor) },
        id: track.id,
        billableStart: new Date(track.billableStart),
        billableEnd: new UpToButExcludingDate(track.billableEnd || new Date()).transformToUpToAndIncludingDate(),
        checkIn: new Date(track.checkIn),
        checkOut: new UpToButExcludingDate(track.checkOut || new Date()).transformToUpToAndIncludingDate(),
        managerComment: comment ? transformCommentResource(comment, comment.owner) : undefined,
        department: department ? transformDepartmentResource(department) : undefined,
        deviation: track.deviation,
        ...track.deviationRemark && { deviationRemark: track.deviationRemark },
        feedback: feedback ? transformFeedbackResource(feedback) : undefined,
        meal: track.meal as MealType,
        owner: transformUserResource(owner, owner.departments, owner.roles),
        pause: track.pause,
        shift,
        totalBillableTime: track.totalBillableTime,
        ...track.unplannedRemark && { unplannedRemark: track.unplannedRemark },
    };
}
