import {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { Button } from '../../components';
import { SearchInput } from '../../compositions';
import trans from '../../helpers/trans';

import './KnowledgeBaseSearch.scss';

interface KnowledgeBaseSearchProps {
    onSubmit: (value: string) => void;
    className?: string;
}

const KnowledgeBaseSearch: FC<KnowledgeBaseSearchProps> = ({ onSubmit, className = '' }): ReactElement => {
    const [searchValue, setSearchValue] = useState('');

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        onSubmit(searchValue);
    };

    return (
        <div className={`knowledge-base-search ${className}`}>
            <h1 className="knowledge-base-search__title">
                {trans('containers.knowledgeBaseSearch.valkKnowledgeBase')}
            </h1>
            <form className="knowledge-base-search__input-button-wrapper" onSubmit={handleSubmit}>
                <SearchInput
                    hideLabel
                    icon="magnifying-glass"
                    label={trans('containers.knowledgeBaseSearch.askYourQuestionHere')}
                    placeholder={trans('containers.knowledgeBaseSearch.askYourQuestionHere')}
                    value={searchValue}
                    onChange={setSearchValue}
                    className={`knowledge-base-search__search-input ${className}`}
                    inputClassName="knowledge-base-search__search-input-input"
                />
                <Button
                    text={trans('common.search')}
                    type="submit"
                    className="knowledge-base-search__search-button"
                />
            </form>
        </div>
    );
};

export default KnowledgeBaseSearch;
