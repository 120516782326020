import { format } from 'date-fns';
import nl from 'date-fns/locale/nl';
import { utcToZonedTime } from 'date-fns-tz';

import legacyParse from '../legacyDateParser';

// This will ensure any timezone on device will be converted to Europe/Amsterdam timezone
export function convertTimeZone(date: string | Date): Date {
    return utcToZonedTime(legacyParse(date), 'Europe/Amsterdam');
}

// Convert a datetime to a time like 18:00.
export function convertDateToTime(date: string | Date): string {
    return format(convertTimeZone(date), 'HH:mm', { locale: nl });
}

// Converts a date or string to an api param datetime format.
export function convertDateToApiParamDateTime(date: string | Date): string {
    return format(legacyParse(date), 'yyyy-MM-dd HH:mm:ss');
}

// Converts a date or string to an api param date format.
export function convertDateToApiParamDate(date: string | Date): string {
    return format(legacyParse(date), 'yyyy-MM-dd');
}

export function formatDate(date: string | Date, newFormat = 'dd-MM-yyyy'): string {
    return `${format(convertTimeZone(date), newFormat, { locale: nl })}`;
}

export const convertMinsToHrsMins = (mins: number): string => {
    const negative = mins < 0;
    const hours = Math.floor(Math.abs(mins) / 60);
    const minutes = Math.abs(mins) % 60;
    const hoursFormatted = hours < 10 ? `0${hours}` : hours;
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
    return `${negative ? '-' : ''}${hoursFormatted}:${minutesFormatted}`;
};
