import { FC, ReactElement } from 'react';

import { Button } from '../../../components';
import trans from '../../../helpers/trans';
import SubmitButton from '../SubmitButton/SubmitButton';

import './FormActionButtons.scss';

interface FormActionButtonsProps {
    isLoading?: boolean;
    disabled?: boolean;
    hideSubmit?: boolean;
    cancelText?: string;
    submitText?: string;
    onCancelClick: () => void;
}

const FormActionButtons: FC<FormActionButtonsProps> = ({
    isLoading,
    disabled,
    hideSubmit = false,
    cancelText = trans('compositions.modalContent.cancel'),
    submitText = trans('compositions.modalContent.save'),
    onCancelClick,
}): ReactElement => (
    <div className="form-action-buttons">
        <Button
            text={cancelText}
            onClick={onCancelClick}
            className="form-action-buttons__cancel"
        />
        {!hideSubmit && (
            <SubmitButton
                isLoading={isLoading}
                disabled={disabled}
                type="submit"
                text={submitText}
                className="form-action-buttons__confirm"
            />
        )}
    </div>
);

export default FormActionButtons;
