import { FeedbackCategoryResource, FeedbackCategoryViewModel } from './FeedbackCategory';
import { Resource } from './Resource';

export enum RatingType {
    UNSATISFIED = 'UNSATISFIED',
    SATISFIED = 'SATISFIED',
    NEUTRAL = 'NEUTRAL',
    ALL = 'ALL',
}

export interface FeedbackResource extends Resource {
    type: 'feedback';
    rating: RatingType | null;
    remark: string | null;
    category: FeedbackCategoryResource | null;
}

export interface FeedbackViewModel {
    id: string;
    rating: RatingType | null;
    remark: string | null;
    category?: FeedbackCategoryViewModel | null;
}
