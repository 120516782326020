import { TemporaryWorker } from '../../@paco/entities/TemporaryWorker/TemporaryWorker';
import { formatDate } from '../../helpers/date';
import { ShiftDraggable, ShiftViewModel } from '../../models';
import { isTemporaryWorkerTimeDeviated } from './isTemporaryWorkerTimeDeviated';

export const getTemporaryWorkerDeviatedTime = (
    temporaryWorker: TemporaryWorker,
    shift?: ShiftViewModel | ShiftDraggable,
): string | undefined => {
    if (!shift || !isTemporaryWorkerTimeDeviated(temporaryWorker, shift)) {
        return undefined;
    }

    return `${formatDate(temporaryWorker.period.start, 'HH:mm')}-${formatDate(temporaryWorker.period.end, 'HH:mm')}`;
};
