import React, { FC } from 'react';

import Select, { OnChangeValue } from 'react-select';
import {
    Col,
    CustomInput,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    ModalBody,
    Row,
} from 'reactstrap';

import FormDatePicker from '../../../../../../components/FormDatePicker/FormDatePicker';
import { capitalizeFirstLetter, GroupedDepartmentOption, Option } from '../../../../../../helpers';
import { formatDate, isSameDay } from '../../../../../../helpers/date';
import { translate } from '../../../../../../helpers/translations/translator';
import { AddShiftFormData } from '../../../../../../models';
import { ValidationErrors } from '../../helpers/validateFormData';

interface DateOption { label: string, value: Date }

interface ShiftFormBodyProps {
    selectedDays?: Date[];
    groupedDepartments: GroupedDepartmentOption[];
    selectedDepartment: Option | null;
    formData: AddShiftFormData;
    onChange: (data: AddShiftFormData) => void;
    onDepartmentChange: (value: OnChangeValue<Option, false>) => void;
    validationErrors: ValidationErrors;
    departmentSelectIsDisabled?: boolean;
}

const ShiftFormBody: FC<ShiftFormBodyProps> = ({
    selectedDays,
    groupedDepartments,
    selectedDepartment,
    formData,
    validationErrors,
    onChange,
    onDepartmentChange,
    departmentSelectIsDisabled,
}) => {
    const handleChangeStartDate = (value: Date) => {
        onChange({
            ...formData,
            date: value,
        });
    };

    const handleChangeStartTime = (e: React.FormEvent<HTMLInputElement>) => {
        onChange({
            ...formData,
            startTime: e.currentTarget.value,
        });
    };

    const handleChangeEndTime = (e: React.FormEvent<HTMLInputElement>) => {
        onChange({
            ...formData,
            endTime: e.currentTarget.value,
        });
    };

    const handleChangeWeekday = (option: OnChangeValue<DateOption, false>) => {
        const date = new Date(String(option?.value));

        onChange({
            ...formData,
            date,
        });
    };

    const handleChangePeopleLimit = (e: React.FormEvent<HTMLInputElement>) => {
        onChange({
            ...formData,
            peopleLimit: parseInt(e.currentTarget.value, 10),
        });
    };

    const handleChangeDepartment = (value: OnChangeValue<Option, false>) => {
        onDepartmentChange(value);
    };

    const weekdaysOptions: DateOption[] = selectedDays?.map((weekday) => ({
        label: capitalizeFirstLetter(formatDate(weekday, 'EEEE')),
        value: weekday,
    })) || [];

    return (
        <ModalBody>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>{translate('common.department')}</Label>
                        <Select
                            id="departments-search"
                            isDisabled={departmentSelectIsDisabled}
                            placeholder={translate('common.searchDepartment')}
                            value={selectedDepartment}
                            noOptionsMessage={() => translate('common.noDepartmentFound')}
                            options={groupedDepartments}
                            // @ts-ignore
                            invalid={!!validationErrors.department}
                            maxMenuHeight={250}
                            onChange={handleChangeDepartment}
                        />
                        <FormFeedback>
                            { validationErrors.department }
                        </FormFeedback>
                    </FormGroup>
                </Col>
                <Col>
                    {!selectedDays
                        ? (
                            <FormGroup>
                                <Label>{translate('common.startDate')}</Label>
                                <FormDatePicker
                                    selected={formData.date}
                                    invalid={!!validationErrors.date}
                                    onChange={handleChangeStartDate}
                                />
                                <FormFeedback>
                                    { validationErrors.date }
                                </FormFeedback>
                            </FormGroup>
                        )
                        : (
                            <FormGroup>
                                <Label>{translate('common.weekDay')}</Label>
                                <Select<DateOption>
                                    id="weekday"
                                    name="weekday"
                                    options={weekdaysOptions}
                                    value={weekdaysOptions.find((option) => isSameDay(option.value, formData.date))}
                                    onChange={handleChangeWeekday}
                                    // @ts-ignore
                                    invalid={!!validationErrors.date}
                                />
                                <FormFeedback>
                                    { validationErrors.date }
                                </FormFeedback>
                            </FormGroup>
                        )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>{translate('pages.tracks.startTime')}</Label>
                        <Input
                            type="time"
                            className="form-control"
                            id="startTime"
                            name="startTime"
                            autoComplete="off"
                            value={formData.startTime}
                            invalid={!!validationErrors.startTime}
                            onChange={handleChangeStartTime}
                        />
                        <FormFeedback>
                            { validationErrors.startTime }
                        </FormFeedback>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>{translate('pages.tracks.endTime')}</Label>
                        <Input
                            type="time"
                            className="form-control"
                            id="endTime"
                            name="endTime"
                            autoComplete="off"
                            value={formData.endTime}
                            invalid={!!validationErrors.endTime}
                            onChange={handleChangeEndTime}
                        />
                        <FormFeedback>
                            { validationErrors.endTime }
                        </FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>{translate('common.people')}</Label>
                        <CustomInput
                            type="number"
                            className="form-control"
                            id="peopleLimit"
                            name="peopleLimit"
                            value={formData.peopleLimit || ''}
                            onChange={handleChangePeopleLimit}
                            invalid={!!validationErrors.peopleLimit}
                        />
                        <FormFeedback>
                            { validationErrors.peopleLimit }
                        </FormFeedback>
                    </FormGroup>
                </Col>
                <Col />
            </Row>
        </ModalBody>
    );
};

export default ShiftFormBody;
