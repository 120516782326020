import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { Icon } from '../../../../components';
import { Department } from '../../../../entities/Department/Department';
import { transformDepartmentToFormOption } from '../../../../entities/Department/DepartmentTransformers';
import trans from '../../../../helpers/trans';
import { DepartmentOption } from '../../../../types/selectOptionTypes';
import { DepartmentsInput } from '../../../index';
import { getSelectedDepartmentsLabel, transformDepartmentOptionsToDepartment } from '../../helpers';

import './ShiftsWidgetHeader.scss';

interface ShiftsWidgetHeaderProps {
    isDepartmentsLoading: boolean;
    departmentOptions: Department[];
    selectedDepartments: Department[];
    onDepartmentChange: (departments: Department[]) => void;
}

const ShiftsWidgetHeader: FC<ShiftsWidgetHeaderProps> = ({
    isDepartmentsLoading,
    departmentOptions,
    selectedDepartments,
    onDepartmentChange,
}): ReactElement => {
    const titleRef = useRef<HTMLHeadingElement>(null);

    const inputValueLabel = useMemo(() => getSelectedDepartmentsLabel(selectedDepartments.length), [selectedDepartments.length]);

    const [titleWidth, setTitleWidth] = useState(0);

    const handleDepartmentChange = (chosenDepartments: DepartmentOption[]): void => {
        const departments = chosenDepartments
            .map(chosenDepartment => transformDepartmentOptionsToDepartment(departmentOptions, chosenDepartment))
            .filter(dep => dep) as Department[];

        onDepartmentChange(departments);
    };

    useEffect((): void => {
        if (titleRef.current) {
            setTitleWidth(titleRef.current.getBoundingClientRect().width);
        }
    }, [titleRef]);

    return (
        <div className="shifts-widget-header">
            <h2 ref={titleRef} className="shifts-widget-header__title">
                {trans('common.today')}
            </h2>
            <span className="shifts-widget-header__scroll-indicator-and-departments-wrapper" style={(titleWidth ? { width: `calc(100% - ${titleWidth}px)` } : {})}>
                <div className="shifts-widget-header__scroll-indicator">
                    <p className="shifts-widget-header__scroll-indicator-text">
                        {trans('compositions.dashboardShiftsWidget.scrollDownToSeeMore')}
                    </p>
                    <Icon name="double-chevron-down" className="shifts-widget-header__scroll-indicator-icon" />
                </div>
                <div className="shifts-widget-header__select-departments-wrapper">
                    <DepartmentsInput
                        hideLabel
                        isSearchable={false}
                        isLoading={isDepartmentsLoading}
                        hideSelectedOptions={false}
                        departments={departmentOptions}
                        placeholder={inputValueLabel}
                        selectedDepartments={selectedDepartments.map(transformDepartmentToFormOption)}
                        valueLabel={inputValueLabel}
                        onDepartmentsChange={handleDepartmentChange}
                        className="shifts-widget-header__select-departments"
                    />
                </div>
            </span>
        </div>
    );
};

export default ShiftsWidgetHeader;
