import { FC, ReactElement, useState } from 'react';

import { arrayOfStringsAreEqual } from '../../../../helpers/tools/array';
import { CheckAllInGroupCheckbox, InputLabel } from '../../../components';
import { FormOption } from '../../../types';
import CheckboxGroup, { CheckboxChange } from '../../GroupedCheckboxes/CheckboxGroup';

import './CheckboxGroupList.scss';

interface CheckboxGroupListProps {
    isExpandable?: boolean;
    hideLabel?: boolean;
    label: string;
    groupOption: FormOption;
    name: string;
    options: FormOption[];
    selectedOptions?: FormOption[];
    disabledOptions?: FormOption[];
    tabIndex?: number;
    onChange: (selected: CheckboxChange[]) => void;
    className?: string;
    groupLabelClassName?: string;
    checkboxLabelClassName?: string;
}

const CheckboxGroupList: FC<CheckboxGroupListProps> = ({
    isExpandable,
    hideLabel,
    label,
    groupOption,
    name,
    options,
    selectedOptions = [],
    disabledOptions = [],
    tabIndex,
    onChange,
    className = '',
    groupLabelClassName = '',
    checkboxLabelClassName = '',
}): ReactElement => {
    const optionsIdList = options.map(option => option.value);
    const selectedOptionsIdList = selectedOptions.map(option => option.value);
    const disabledOptionsIdList = selectedOptions.filter(option => option.isDisabled).map(option => option.value);

    const areAllChildrenChecked = optionsIdList.every(childId => selectedOptionsIdList.some(selectedId => childId === selectedId));
    const areAllChildrenDisabled = optionsIdList.every(childId => disabledOptionsIdList.some(selectedId => childId === selectedId));

    const [groupedChecked, setGroupChecked] = useState<boolean>(areAllChildrenChecked);

    const handleChange = (checkboxes: CheckboxChange[]): void => {
        const selectedValues = selectedOptions
            .filter(selectedOption => !selectedOption.isDisabled)
            .map(selectedOption => selectedOption.value);

        const newSelectedValues = checkboxes
            .filter(checkbox => checkbox.checked)
            .map(checkbox => checkbox.value as string);

        if (!arrayOfStringsAreEqual(selectedValues, newSelectedValues)) {
            setGroupChecked(checkboxes.every(checkbox => checkbox.checked));
            onChange(checkboxes);
        }
    };

    return (
        <div className={`checkbox-group-list ${className}`}>
            {!hideLabel && (
                <InputLabel
                    text={label}
                    className="checkbox-group-list__label"
                />
            )}
            <CheckboxGroup onChange={handleChange}>
                <CheckAllInGroupCheckbox
                    key={groupOption.value}
                    name={name}
                    groupOption={groupOption}
                    checked={groupedChecked}
                    disabled={areAllChildrenDisabled}
                    options={options}
                    selectedOptions={selectedOptions}
                    disabledOptions={disabledOptions}
                    isExpandable={isExpandable}
                    tabIndex={tabIndex}
                    className={`checkbox-group-list__item ${groupLabelClassName}`}
                    checkboxLabelClassName={checkboxLabelClassName}
                />
            </CheckboxGroup>
        </div>
    );
};

export default CheckboxGroupList;
