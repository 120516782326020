import React, { FC, useEffect, useState } from 'react';

import {
    Button,
    Col,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import UserProfileInfo from '../../../../components/UserProfileInfo/UserProfileInfo';
import { DepartmentViewModel } from '../../../../entities/Departments/Departments';
import { getUserFullName } from '../../../../helpers';
import { formatDate } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import { ShiftViewModel, UserWithAvailability } from '../../../../models';
import getShiftsForDepartmentsOnDate from './helpers/getShiftsForDepartmentsOnDate';
import ShiftButtonsList from './subcomponents/ShiftButtonsList/ShiftButtonsList';

import './AssignShiftToUserForm.scss';

interface Props {
    user: UserWithAvailability | null;
    date: Date | null;
    onSubmit: (user: UserWithAvailability, shiftId: string) => void;
    onCancel: () => void;
}

const AssignShiftToUserForm: FC<Props> = ({ date, ...props }) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<UserWithAvailability | null>(null);
    const [shifts, setShifts] = useState<ShiftViewModel[]>([]);
    const [activeShift, setActiveShift] = useState<string | null>(null);
    const day = date ? formatDate(date, 'EEEEEE. d MMMM') : '';
    const firstname = user ? user.firstname : '';
    const canSubmit = !loading && shifts.length && activeShift;

    async function getShifts(departments: DepartmentViewModel[], shiftDate: Date) {
        const newShifts = await getShiftsForDepartmentsOnDate(departments, shiftDate);
        const filteredShifts = newShifts
            .filter((shift) => !shift.shiftPlannings
                .some((shiftPlanning) => shiftPlanning.user?.id === props.user?.id));

        setShifts(filteredShifts);
        setLoading(false);
    }

    useEffect(() => {
        if (props.user && date) {
            setUser(props.user);
            setLoading(true);
            getShifts(props.user.departments, date);
        }
    }, [props.user, date]);

    const onShiftClick = (shiftId: string) => {
        setActiveShift(shiftId);
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (user && activeShift) {
            props.onSubmit(user, activeShift);
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <ModalHeader>{translate('pages.shifts.assignShift')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        { user && (
                            <UserProfileInfo
                                className="assign-shift-to-user-form__user-profile-info"
                                name={getUserFullName(user)}
                                date={date}
                                department={user.mainDepartment ? user.mainDepartment.name : null}
                                experience={user.experience.name}
                                userId={user.id}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="assign-shift-to-user-form__intro">
                            {translate('pages.shifts.assignShiftForDate', { day, firstname })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ShiftButtonsList
                            onShiftClick={onShiftClick}
                            loading={loading}
                            shifts={shifts}
                            activeShiftId={activeShift}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    id="modal-close"
                    type="button"
                    color="link"
                    onClick={props.onCancel}
                    className="assign-shift-to-user-form__close-button"
                >
                    {translate('common.cancel')}
                </Button>
                <Button type="submit" color="orange" disabled={!canSubmit}>{translate('common.save')}</Button>
            </ModalFooter>
        </form>
    );
};

export default AssignShiftToUserForm;
