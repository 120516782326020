import React, { Component } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';
import {
    Button,
    Col,
    CustomInput,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import Icon from '../../../components/style/Icon/Icon';
import { getObjProperty } from '../../../helpers';
import { translate } from '../../../helpers/translations/translator';

class EditGroups extends Component {
    formRef = React.createRef();

    state = {
        newGroupId: Math.round(Math.random() * 99999999),
        data: {
            groups: null,
        },
    };

    componentDidMount() {
        this.onChange();
    }

    deleteGroup = (id) => {
        const { data } = this.state;
        const { groups: propsGroups = [] } = this.props;
        const groups = data.groups || propsGroups;
        data.groups = groups.filter(group => group.id !== id);
        this.setState({
            data,
        });
    };

    addGroup = () => {
        const { data, newGroupId } = this.state;
        const { groups: propsGroups = [] } = this.props;
        const groups = data.groups || propsGroups;
        groups.push({ name: '', id: `new-group-${newGroupId}` });
        this.setState({
            data,
            newGroupId: newGroupId + 1,
        }, () => this.focusLastInput());
    };

    onChange = (e) => {
        if (e) e.preventDefault();
        const { data } = this.state;
        const formData = serialize(this.formRef.current, { hash: true });
        data.groups = Object.keys(formData).map(key => ({ name: formData[key], id: key }));
        this.setState({ data });
    };

    onKeyDown = (e) => {
        // If Enter on last textfield, then create new group
        if (e.keyCode === 13) {
            const { data } = this.state;
            const { groups: propsGroups = [] } = this.props;
            const groups = data.groups || propsGroups;
            const lastName = groups[groups.length - 1].name;
            if ((e.nativeEvent.target.value === lastName) && (lastName.length > 0)) {
                e.preventDefault();
                this.addGroup();
            }
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const groups = getObjProperty(this.state, 'data.groups') || [];
        const { onSubmit, groups: propsGroups = [] } = this.props;
        const newGroups = groups
            .filter(group => propsGroups.filter(g => g.id === group.id).length === 0)
            .filter(group => group.name.length > 0);
        const removeGroups = propsGroups.filter(
            group => groups.filter(g => g.id === group.id).length === 0,
        );

        const modifyGroups = groups.filter(
            group => propsGroups.filter(
                g => (g.id === group.id) && (g.name !== group.name),
            ).length,
        );

        onSubmit(newGroups, removeGroups, modifyGroups);
    };

    focusLastInput = () => {
        const inputs = document.querySelectorAll('#groups-form-edit-group input');
        inputs[inputs.length - 1].focus();
    };

    render() {
        const { data } = this.state;
        const {
            onCancel,
            canDeleteGroups,
            canAddGroups,
            groups = [],
        } = this.props;

        const selectedGroups = data.groups || groups;

        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <form ref={this.formRef} id="groups-form-edit-group" onSubmit={this.onSubmit} onChange={this.onChange} onKeyDown={this.onKeyDown}>
                <ModalHeader>{translate('pages.management.manageGroups')}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.groups')}</Label>
                                {selectedGroups && selectedGroups.map(group => (
                                    <div key={group.id} className="form-control-group">
                                        <CustomInput
                                            type="text"
                                            className="form-control"
                                            id={group.id}
                                            name={group.id}
                                            defaultValue={group.name}
                                        />
                                        { canDeleteGroups && (
                                            <button type="button" className="groups-button-delete" onClick={() => this.deleteGroup(group.id)}>
                                                <Icon color="light-blue" kind="trash" fontSize={20} />
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            { canAddGroups && (
                                <button type="button" className="button-plain button-plain-orange groups-button-add-group" onClick={this.addGroup}>
                                    {`+ ${translate('pages.management.addExtraGroup')}`}
                                </button>
                            )}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" id="modal-close" onClick={onCancel}>{translate('common.cancel')}</Button>
                    <Button type="submit" color="orange">{translate('common.save')}</Button>
                </ModalFooter>
            </form>
        );
    }
}

EditGroups.propTypes = {
    groups: PropTypes.array,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    canDeleteGroups: PropTypes.bool.isRequired,
    canAddGroups: PropTypes.bool.isRequired,
};

export default EditGroups;
