import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { Comment } from '../../entities/Comment/Comment';
import { DistrictDivisionDistrictPlanning } from '../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';

export type DistrictDivisionDistrictPlanningState = AsyncReduxState<{
    isAddPlanningCommentSuccessful: boolean;
    isAddPlanningSuccessful: boolean;
    isDeletePlanningSuccessful: boolean;
    comment?: Comment;
    districtDivisionDistrictPlanning?: DistrictDivisionDistrictPlanning;
    lastRemovedDistrictDivisionDistrictPlanningId?: string;
}>;

const initialState: DistrictDivisionDistrictPlanningState = {
    ...initialAsyncReduxState,
    isAddPlanningCommentSuccessful: false,
    isAddPlanningSuccessful: false,
    isDeletePlanningSuccessful: false,
    isLoading: false,
};

export const districtDivisionDistrictPlanningSlice = createSlice({
    name: 'districtDivisionDistrictPlanningReducer',
    initialState,
    reducers: {
        setError(state, action: PayloadAction<string>): DistrictDivisionDistrictPlanningState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsAddPlanningCommentSuccessful(state, action: PayloadAction<boolean>): DistrictDivisionDistrictPlanningState {
            return {
                ...state,
                isAddPlanningCommentSuccessful: action.payload,
            };
        },
        setIsAddPlanningSuccessful(state, action: PayloadAction<boolean>): DistrictDivisionDistrictPlanningState {
            return {
                ...state,
                isAddPlanningSuccessful: action.payload,
            };
        },
        setIsDeletePlanningSuccessful(state, action: PayloadAction<boolean>): DistrictDivisionDistrictPlanningState {
            return {
                ...state,
                isDeletePlanningSuccessful: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): DistrictDivisionDistrictPlanningState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): DistrictDivisionDistrictPlanningState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setComment(
            state,
            action: PayloadAction<{
                comment: Comment,
                districtDivisionDistrictPlanningId: string }
            >,
        ): DistrictDivisionDistrictPlanningState {
            return {
                ...state,
                comment: action.payload.comment,
            };
        },
        setDistrictDivisionDistrictPlanning(state, action: PayloadAction<DistrictDivisionDistrictPlanning>): DistrictDivisionDistrictPlanningState {
            return {
                ...state,
                districtDivisionDistrictPlanning: action.payload,
            };
        },
        setLastRemovedDistrictDivisionDistrictPlanningId(state, action: PayloadAction<string>): DistrictDivisionDistrictPlanningState {
            return {
                ...state,
                lastRemovedDistrictDivisionDistrictPlanningId: action.payload,
            };
        },
    },
});

export const {
    setError,
    setComment,
    setDistrictDivisionDistrictPlanning,
    setIsAddPlanningCommentSuccessful,
    setIsAddPlanningSuccessful,
    setIsDeletePlanningSuccessful,
    setIsLoading,
    setIsSuccessful,
    setLastRemovedDistrictDivisionDistrictPlanningId,
} = districtDivisionDistrictPlanningSlice.actions;

export default districtDivisionDistrictPlanningSlice.reducer;
