import { FC } from 'react';

import classNames from 'classnames';

import { Button, Icon } from '../../../../components';
import { CounterIndicator } from '..';

import './MobileToggleButton.scss';

interface MobileToggleButtonProps {
    isUserInitials?: boolean;
    isActive: boolean;
    counter?: number;
    text: string;
    onClick: () => void;
    className?: string;
}

const MobileToggleButton: FC<MobileToggleButtonProps> = ({
    isUserInitials,
    isActive,
    counter,
    text,
    onClick,
    className = '',
}) => {
    const buttonClassName = classNames('mobile-toggle-button', {
        'mobile-toggle-button--is-active': isActive,
        'mobile-toggle-button--is-user-initials': isUserInitials,
    }, className);

    return (
        <Button
            text={text}
            onClick={onClick}
            className={buttonClassName}
        >
            <div className="mobile-toggle-button__text">
                {text}
            </div>
            {!!counter && (
                <CounterIndicator number={counter} className="mobile-toggle-button__counter" />
            )}
            <Icon
                name="chevron-down"
                className="mobile-toggle-button__icon"
            />
        </Button>
    );
};

export default MobileToggleButton;
