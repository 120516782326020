import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';

export type YearEndStatementsState = AsyncReduxState;

const initialState: YearEndStatementsState = {
    ...initialAsyncReduxState,
};

export const YearEndStatementsSlice = createSlice({
    name: 'yearEndStatementsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): YearEndStatementsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): YearEndStatementsState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): YearEndStatementsState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
} = YearEndStatementsSlice.actions;

export default YearEndStatementsSlice.reducer;
