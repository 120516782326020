import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import './DepartmentBadge.scss';

interface DepartmentBadgeProps {
    isDeleted?: boolean;
    isGrouped?: boolean;
    isMainDepartment?: boolean;
    groupName?: string;
    name: string;
    className?: string;
}

const DepartmentBadge: FC<DepartmentBadgeProps> = ({
    isDeleted,
    isGrouped,
    isMainDepartment,
    groupName,
    name,
    className = '',
}): ReactElement => {
    const badgeClassName = classNames('department-badge', {
        'department-badge--is-deleted': isDeleted,
        'department-badge--is-grouped': isGrouped,
        'department-badge--is-main-department': isMainDepartment,
    }, className);

    return (
        <div className={badgeClassName}>
            {groupName && (
                <span className="department-badge__group">
                    {`${groupName} - `}
                </span>
            )}
            {name}
        </div>
    );
};

export default DepartmentBadge;
