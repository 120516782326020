import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../../redux/@config/AsyncReduxState';
import { Department } from '../../../entities/Department/Department';

export type OptionsState = AsyncReduxState<{
    departmentOptions: Department[];
}>;

const initialState: OptionsState = {
    ...initialAsyncReduxState,
    departmentOptions: [],
};

export const optionsSlice = createSlice({
    name: 'optionsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OptionsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): OptionsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDepartmentOptions(state, action: PayloadAction<Department[]>): OptionsState {
            return {
                ...state,
                departmentOptions: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setDepartmentOptions,
} = optionsSlice.actions;

export default optionsSlice.reducer;
