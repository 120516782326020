import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../../redux/@config/AsyncReduxState';
import { ShiftSnapshot } from '../../../entities/ShiftSnapshot/ShiftSnapshot';

export type ShiftSnapshotFormState = AsyncReduxState<{
    shiftSnapshot?: ShiftSnapshot;
}>;

const initialState: ShiftSnapshotFormState = {
    ...initialAsyncReduxState,
};

export const ShiftSnapshotFormSlice = createSlice({
    name: 'shiftSnapshotFormReducer',
    initialState,
    reducers: {
        setShiftSnapshot(state, action: PayloadAction<ShiftSnapshot | undefined>): ShiftSnapshotFormState {
            return {
                ...state,
                shiftSnapshot: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): ShiftSnapshotFormState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ShiftSnapshotFormState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ShiftSnapshotFormState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setShiftSnapshot,
} = ShiftSnapshotFormSlice.actions;

export default ShiftSnapshotFormSlice.reducer;
