import { FC, useMemo } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { AbsencesRoutes } from '../../../../../pages/Absences/AbsencesRoutes';
import LegacyAbsencesRouteGroup from '../../../../../pages/Absences/LegacyAbsencesRouteGroup';
import { getDefaultSubRoutePathByNavigationRouteKey } from '../../../../helpers/routes';
import { LeaveOfAbsencesOverviewPage } from '../../../../pages';
import { NavRoute } from '../../../../routes/routes';

interface AbsencesRouteGroupProps {
    permittedUserRoutes: NavRoute[];
}

const AbsencesRouteGroup: FC<AbsencesRouteGroupProps> = ({ permittedUserRoutes }) => {
    const defaultRoute = useMemo(() => getDefaultSubRoutePathByNavigationRouteKey('absences', permittedUserRoutes), [permittedUserRoutes]);

    return (
        <Routes>
            <Route
                path={AbsencesRoutes.leaveOfAbsencesOverview()}
                element={<LeaveOfAbsencesOverviewPage />}
            />

            <Route path="/*" element={<LegacyAbsencesRouteGroup />} />
            <Route path="/" element={<Navigate to={defaultRoute} />} />
        </Routes>
    );
};

export default AbsencesRouteGroup;
