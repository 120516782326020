import React from 'react';

import PropTypes from 'prop-types';

import happy from '../../images/happy.svg';
import neutral from '../../images/neutral.svg';
import sad from '../../images/sad.svg';

import './FeedbackScores.scss';

function renderEmoticon(index) {
    switch (index) {
        case 0:
            return <img alt="happy-icon" src={happy} />;
        case 1:
            return <img alt="neutralicon" src={neutral} />;
        default:
            return <img alt="sad-icon" src={sad} />;
    }
}

const FeedbackScores = ({
    scores,
}) => (
    <div className="feedback-scores">
        {scores.map((score, index) => (
            <div className="feedback-score" key={`${index + 1}`}>
                <div className="feedback-score-title">{score.title}</div>
                <div className="feedback-score-emoticon">
                    { renderEmoticon(index) }

                </div>
                <div className="feedback-score-percentage">
                    {score.percentage}
                    <span>%</span>
                </div>
            </div>
        ))}
    </div>
);

FeedbackScores.propTypes = {
    scores: PropTypes.array.isRequired,
};

export default FeedbackScores;
