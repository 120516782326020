import { FC, ReactElement } from 'react';

import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';

import { Reducers } from '../../../../redux/reducers';
import { EmploymentTypesFilterContainer } from '../../../containers';
import { fetchEmploymentTypeOptions } from '../../../redux/@interface/globalFilters/globalFiltersActions';
import { setEmploymentTypeOptions } from '../../../redux/@interface/globalFilters/globalFiltersReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { FormOption } from '../../../types';

const ConnectedEmploymentTypesFilter: FC = (): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const legacyEmploymentTypeOptions = useSelector((state: Reducers) => state.filterReducer.filter.employeeContractTypes);
    const employmentTypeOptions = useTypedSelector(state => state.globalFiltersReducer.employmentTypeOptions);

    const handleOptionsChange = (options: FormOption[]): void => {
        dispatch(setEmploymentTypeOptions(options));
    };

    useEffectOnce((): void => {
        if (employmentTypeOptions.length === 0) dispatch(fetchEmploymentTypeOptions(legacyEmploymentTypeOptions));
    });

    return employmentTypeOptions.length > 0 ? (
        <EmploymentTypesFilterContainer
            employmentTypeOptions={employmentTypeOptions}
            onChange={handleOptionsChange}
        />
    ) : null;
};

export default ConnectedEmploymentTypesFilter;
