import { CoupledPermissions } from '../../@paco/entities/Permission/Permission';
import { ConfigurablePermission, Permission } from '../../models';
import doesPermissionHaveParent from './doesPermissionHaveParent';
import { getPermissionChildren } from './getPermissionChildren';

export default function transformPermissionsToConfigurablePermissions(
    permissions: Permission[] = [],
    coupledPermissions: CoupledPermissions[] = [],
): ConfigurablePermission[] {
    return permissions.map(permission => {
        const childrenSlugs = getPermissionChildren(permission, coupledPermissions);
        const children = permissions.filter(childPermission => childrenSlugs.includes(childPermission.slug));
        const hasParent = doesPermissionHaveParent(permission, coupledPermissions);

        return {
            ...permission,
            children,
            hasParent,
        };
    });
}
