import React, { FC } from 'react';

import { DepartmentBadge } from '../../../../../../@paco/components';
import { ShiftConceptViewModelOld } from '../../../../../../entities/ShiftConcept/ShiftConcept';
import { convertDateToTime } from '../../../../../../helpers/date';

import './ShiftConceptButton.scss';

interface ShiftConceptButtonProps {
    shiftConcept: ShiftConceptViewModelOld;
    onClick: (activeShiftConcept: ShiftConceptViewModelOld) => void;
    active: boolean;
}

const ShiftConceptButton: FC<ShiftConceptButtonProps> = ({ shiftConcept, onClick, active }) => (
    <button
        type="button"
        className={`shift-concept-button ${active ? 'shift-concept-button--active' : ''}`}
        onClick={() => onClick(shiftConcept)}
    >
        <div className="shift-concept-button__department-time-container">
            { shiftConcept.department && (
                <DepartmentBadge
                    name={shiftConcept.department.name}
                    className="shift-concept-button__department"
                />
            )}
            <div className="shift-concept-button__time">
                {`${convertDateToTime(shiftConcept.start)} - ${convertDateToTime(shiftConcept.end.transformToUpToButExcludingDate().date)}`}
            </div>
        </div>
        <div className="shift-concept-button__planned-employees">
            {`${shiftConcept.shiftPlannings.length}/${shiftConcept.peopleLimit}`}
        </div>
    </button>
);

export default ShiftConceptButton;
