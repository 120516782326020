import { FC, ReactElement } from 'react';

import { AddPersonalInfoFormErrors } from '../../../containers/@forms/AddUserForm/validation/addUserFormValidation';
import { PersonalInfoFormData } from '../../../entities/User/User';
import { formatDate, isDateStringValid } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { GenderType, SelectOption } from '../../../types';
import { DateFormat } from '../../../types/dateFormatTypes';
import { GenderSelectInput, NumberInput, TextInput } from '../../index';

import './PersonalInfoInput.scss';

interface PersonalInfoInputProps {
    canEditEmail?: boolean;
    personalInfoFormData: PersonalInfoFormData;
    errors: AddPersonalInfoFormErrors;
    onChange: (personalInfoFormData: Partial<PersonalInfoFormData>) => void;
}

const PersonalInfoInput: FC<PersonalInfoInputProps> = ({
    canEditEmail,
    personalInfoFormData,
    errors,
    onChange,
}): ReactElement => {
    const genderOption = { value: personalInfoFormData.gender, label: trans(`common.${personalInfoFormData.gender}`) } as SelectOption<GenderType>;

    const handleEmployeeNumberChange = (value: number): void => {
        onChange({ employeeNumber: value });
    };

    const handleFirstnameChange = (value: string): void => {
        onChange({ firstname: value });
    };

    const handleAffixChange = (value: string): void => {
        onChange({ affix: value });
    };

    const handleLastnameChange = (value: string): void => {
        onChange({ lastname: value });
    };

    const handleEmailChange = (value: string): void => {
        onChange({ email: value });
    };

    const handlePhoneNumberChange = (value: string): void => {
        onChange({ phoneNumber: value });
    };

    const handleBirthdayChange = (date: string): void => {
        if (isDateStringValid(date)) {
            onChange({ birthday: new Date(date) });
        }
    };

    const handleGenderChange = (selectOption: SelectOption<GenderType>): void => {
        onChange({ gender: selectOption.value });
    };

    return (
        <>
            <div className="personal-info-input__row">
                <div className="personal-info-input__col">
                    <NumberInput
                        required
                        requiredTextIsAlwaysVisible
                        requiredText={trans('common.valkWork')}
                        label={trans('common.employeeNumber')}
                        type="number"
                        min="0"
                        value={personalInfoFormData.employeeNumber}
                        onChange={handleEmployeeNumberChange}
                        requiredClassName="personal-info-input__required-label"
                    />
                </div>
                <div className="personal-info-input__col" />
            </div>
            <div className="personal-info-input__row">
                <div className="personal-info-input__col">
                    <TextInput
                        required
                        requiredTextIsAlwaysVisible
                        requiredText={trans('common.required')}
                        label={trans('common.firstname')}
                        value={personalInfoFormData.firstname}
                        error={errors.firstname}
                        onChange={handleFirstnameChange}
                    />
                </div>
                <div className="personal-info-input__col-small">
                    <TextInput
                        label={trans('common.affix')}
                        value={personalInfoFormData.affix}
                        onChange={handleAffixChange}
                    />
                </div>
                <div className="personal-info-input__col">
                    <TextInput
                        required
                        requiredText={trans('common.required')}
                        label={trans('common.lastname')}
                        value={personalInfoFormData.lastname}
                        error={errors.lastname}
                        onChange={handleLastnameChange}
                    />
                </div>
            </div>
            <div className="personal-info-input__row">
                <div className="personal-info-input__col">
                    <TextInput
                        required
                        disabled={!canEditEmail}
                        requiredText={trans('common.required')}
                        label={trans('common.email')}
                        value={personalInfoFormData.email}
                        error={errors.email}
                        onChange={handleEmailChange}
                    />
                </div>
                <div className="personal-info-input__col">
                    <TextInput
                        required
                        requiredText={trans('common.required')}
                        label={trans('common.phoneNumber')}
                        value={personalInfoFormData.phoneNumber}
                        error={errors.phoneNumber}
                        onChange={handlePhoneNumberChange}
                    />
                </div>
            </div>
            <div className="personal-info-input__row">
                <div className="personal-info-input__col">
                    <GenderSelectInput
                        gender={genderOption}
                        onChange={handleGenderChange}
                        requiredClassName="personal-info-input__required-label"
                    />
                </div>
                <div className="personal-info-input__col">
                    <TextInput
                        required
                        requiredText={trans('common.valkWork')}
                        label={trans('common.birthday')}
                        type="date"
                        value={formatDate(personalInfoFormData.birthday, DateFormat.inputDate)}
                        error={errors.birthday}
                        onChange={handleBirthdayChange}
                        requiredClassName="personal-info-input__required-label"
                    />
                </div>
            </div>
        </>
    );
};

export default PersonalInfoInput;
