import { RefObject, useEffect, useState } from 'react';

import { useWindowScroll } from 'react-use';

import { didElementScrollPastElement } from '../browser';

interface useGetHeightAndOffsetReturnValues {
    shiftPlanningSelectorTop: number;
    pxOffset: number;
    elementScrolledPastElement: boolean;
}

const useGetHeightAndOffset = (containerRef: RefObject<HTMLDivElement>, shiftPlanningRef: RefObject<HTMLDivElement>): useGetHeightAndOffsetReturnValues => {
    const [parentElement, setParentElement] = useState<HTMLElement | null>(null);
    const [pxOffset, setPxOffset] = useState<number>(0);
    const [shiftPlanningSelectorTop, setShiftPlanningSelectorTop] = useState<number>(0);
    const [elementScrolledPastElement, setElementScrolledPastElement] = useState<boolean>(false);

    const { y: scrollY } = useWindowScroll();

    useEffect((): void => {
        if (shiftPlanningRef.current) {
            const { top } = shiftPlanningRef.current.getBoundingClientRect();
            setShiftPlanningSelectorTop(top);
        }
    }, [shiftPlanningRef, scrollY]);

    useEffect((): void => {
        setParentElement(containerRef.current?.parentElement || null);
        if (containerRef.current) {
            const { top } = containerRef.current.getBoundingClientRect();
            setPxOffset(top);
        }
    }, [containerRef]);

    useEffect((): void => {
        const scrolledPastParent = didElementScrollPastElement(scrollY, parentElement, true);
        setElementScrolledPastElement(scrolledPastParent);
    }, [scrollY]);

    return { shiftPlanningSelectorTop, pxOffset, elementScrolledPastElement };
};

export default useGetHeightAndOffset;
