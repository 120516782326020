import {
    FC,
    FormEvent,
    useRef,
    useState,
} from 'react';

import { Button, Icon, InputLabel } from '../../../components';
import { FormActionButtons, TextInput } from '../../../compositions';
import DistrictDivisionDistrictTemplate from '../../../compositions/DistrictDivisionDistrictTemplate/DistrictDivisionDistrictTemplate';
import { DistrictDivisionDistrictTemplateForm } from '../../../entities/DistrictDivisionDistrictTemplate/DistrictDivisionDistrictTemplate';
import { AddDistrictDivisionGroupTemplateFormData } from '../../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import { transformDistrictDivisionDistrictTemplateFormToDistrictDivisionDistrictTemplate } from '../../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplateTransformers';
import { DistrictDivisionTemplate } from '../../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import { getUniqueArrayList } from '../../../helpers/array';
import trans from '../../../helpers/trans';
import { ModalFormProps } from '../../../types/modalFormTypes';
import { AddDistrictDivisionGroupTemplateFormErrors, validateAddDistrictDivisionGroupTemplateFormData } from './validations/addDistrictDivisionGroupTemplateFormValidation';

import './AddDistrictDivisionGroupTemplateForm.scss';

interface AddDistrictDivisionGroupTemplateFormProps extends ModalFormProps<AddDistrictDivisionGroupTemplateFormData> {
    districtDivisionTemplate: DistrictDivisionTemplate;
    onSubmit: (addDistrictDivisionGroupTemplateFormData: AddDistrictDivisionGroupTemplateFormData) => void;
}

const AddDistrictDivisionGroupTemplateForm: FC<AddDistrictDivisionGroupTemplateFormProps> = ({
    isLoading,
    districtDivisionTemplate,
    onSubmit,
    onCancel,
}) => {
    const [formData, setFormData] = useState<AddDistrictDivisionGroupTemplateFormData>({
        name: '',
        districtDivisionDistrictTemplates: [],
        districtDivisionTemplateId: districtDivisionTemplate.id,
        order: districtDivisionTemplate.districtDivisionGroupTemplates.length,
    });
    const [formErrors, setFormErrors] = useState<AddDistrictDivisionGroupTemplateFormErrors>({});
    const [groupName, setGroupName] = useState<string>(formData.name);
    const [districtDivisionDistrictTemplates, setDistrictDivisionDistrictTemplates] = useState<DistrictDivisionDistrictTemplateForm[]>([]);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formDataToSubmit = {
            name: groupName,
            districtDivisionDistrictTemplates: transformDistrictDivisionDistrictTemplateFormToDistrictDivisionDistrictTemplate(districtDivisionDistrictTemplates),
            districtDivisionTemplateId: districtDivisionTemplate.id,
            order: districtDivisionTemplate.districtDivisionGroupTemplates.length,
        };

        setFormData(formDataToSubmit);

        const [errors, hasErrors] = validateAddDistrictDivisionGroupTemplateFormData(formDataToSubmit);
        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formDataToSubmit);
        }
    };

    const handleAddDistrictDivisionDistrict = (): void => {
        const newDistrict: DistrictDivisionDistrictTemplateForm = {
            id: new Date().getTime().toString(),
            name: '',
            maxAmountOfEmployees: 1,
            order: districtDivisionDistrictTemplates.length,
        };

        setDistrictDivisionDistrictTemplates([...districtDivisionDistrictTemplates, newDistrict]);
    };

    const handleDistrictNameChange = (id: string, name: string): void => {
        const districtDivisionDistrictTemplate = districtDivisionDistrictTemplates.find(districtDivision => districtDivision.id === id);
        if (districtDivisionDistrictTemplate) {
            districtDivisionDistrictTemplate.name = name;

            const newDistrictDivisionDistrictTemplates = getUniqueArrayList([districtDivisionDistrictTemplate, ...districtDivisionDistrictTemplates], 'id');

            setDistrictDivisionDistrictTemplates(newDistrictDivisionDistrictTemplates);
        }
    };

    const handleDistrictMaxAmountOfEmployeesChange = (id: string, maxAmountOfEmployees: number): void => {
        const districtDivisionDistrictTemplate = districtDivisionDistrictTemplates.find(districtDivision => districtDivision.id === id);
        if (districtDivisionDistrictTemplate) {
            districtDivisionDistrictTemplate.maxAmountOfEmployees = maxAmountOfEmployees;

            const newDistrictDivisionDistrictTemplates = getUniqueArrayList([districtDivisionDistrictTemplate, ...districtDivisionDistrictTemplates], 'id');

            setDistrictDivisionDistrictTemplates(newDistrictDivisionDistrictTemplates);
        }
    };

    const handleDeleteDistrict = (id: string): void => {
        const newDistrictDivisionDistrictTemplates = districtDivisionDistrictTemplates.filter(districtDivision => districtDivision.id !== id);

        setDistrictDivisionDistrictTemplates(newDistrictDivisionDistrictTemplates);
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="add-district-division-group-template-form">
            <div className="add-district-division-group-template-form__row">
                <div className="add-district-division-group-template-form__group-name-input">
                    <TextInput
                        label={trans('containers.forms.addDistrictDivisionGroupTemplateForm.groupName')}
                        type="text"
                        value={groupName}
                        error={formErrors.name}
                        onChange={setGroupName}
                    />
                </div>
            </div>

            {districtDivisionDistrictTemplates.length > 0 && (
                <div className="add-district-division-group-template-form__input-labels">
                    <div className="add-district-division-group-template-form__label">
                        <InputLabel text={trans('containers.forms.addDistrictDivisionGroupTemplateForm.districtName')} />
                    </div>
                    <div className="add-district-division-group-template-form__label">
                        <InputLabel text={trans('containers.forms.addDistrictDivisionGroupTemplateForm.maxAmountOfEmployees')} />
                    </div>
                </div>
            )}

            {districtDivisionDistrictTemplates
                .sort((a, b) => a.order - b.order)
                .map(districtDivisionDistrictTemplate => (
                    <DistrictDivisionDistrictTemplate
                        key={districtDivisionDistrictTemplate.id}
                        districtDivisionDistrictTemplate={districtDivisionDistrictTemplate}
                        formErrors={formErrors}
                        onDistrictNameChange={handleDistrictNameChange}
                        onDistrictMaxAmountOfEmployeesChange={handleDistrictMaxAmountOfEmployeesChange}
                        onDeleteDistrict={handleDeleteDistrict}
                        className="add-district-division-group-template-form__template"
                    />
                ))}

            <div className="add-district-division-group-template-form__row">
                <div className="add-district-division-group-template-form__col">
                    <Button
                        text={trans('containers.forms.addDistrictDivisionGroupTemplateForm.addDistrictDivisionGroupDistrict')}
                        onClick={handleAddDistrictDivisionDistrict}
                        className="add-district-division-group-template-form__add-district-button"
                    >
                        <Icon
                            name="plus-circle"
                            className="add-district-division-group-template-form__add-district-button-icon"
                        />
                        <p className="add-district-division-group-template-form__add-district-button-label">
                            {trans('containers.forms.addDistrictDivisionGroupTemplateForm.addDistrictDivisionGroupDistrict')}
                        </p>
                    </Button>
                </div>
            </div>

            <FormActionButtons
                isLoading={isLoading}
                disabled={!groupName}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default AddDistrictDivisionGroupTemplateForm;
