import { AddressFormData, EditAddressFormData } from '../../entities/Address/Address';
import { patchAddressApiCall, postAddressApiCall } from '../../entities/Address/AddressService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';

export const addAddress = async (addressFormData: AddressFormData, personId: string): Promise<void> => {
    const response = await postAddressApiCall(addressFormData, personId);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('addAddress');
    }
};

export const editAddress = async (addressFormData: EditAddressFormData, personId: string): Promise<void> => {
    const response = await patchAddressApiCall(addressFormData, personId);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('editAddress');
    }
};
