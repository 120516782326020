import { FC, PropsWithChildren, ReactElement } from 'react';

import './Card.scss';

interface CardProps {
    className?: string;
}

const Card: FC<PropsWithChildren<CardProps>> = ({ className = '', children }): ReactElement => (
    <section className={`card ${className}`}>
        {children}
    </section>
);

export default Card;
