import { getUniqueArrayList } from '../../helpers/array';
import { SortDirection, SortField } from '../../types/tableTypes';
import { DepartmentGroup } from '../DepartmentGroup/DepartmentGroup';
import { Department } from './Department';

export const getDepartmentLabel = (department: Department): string => {
    const groupName = department.departmentGroup?.name;

    return `${groupName ? `${groupName} - ` : ''}${department.name}`;
};

export const reduceDepartmentGroupsFromDepartments = (departments: Department[]): DepartmentGroup[] => {
    const departmentGroups = departments
        .map(department => department.departmentGroup)
        .filter(Boolean);

    return getUniqueArrayList(departmentGroups, 'id');
};

export const sortDepartments = (departments: Department[], field?: SortField, direction: SortDirection = SortDirection.asc): Department[] => {
    if (!field || field === SortField.group) {
        departments.sort((a, b) => {
            if (!a.departmentGroup && b.departmentGroup) {
                return -1;
            }
            if (a.departmentGroup && !b.departmentGroup) {
                return 1;
            }

            const groupComparison = (b.departmentGroup?.name || '').localeCompare(a.departmentGroup?.name || '');

            if (groupComparison !== 0) {
                return groupComparison;
            }

            const aName = a.name || '';
            const bName = b.name || '';

            return direction === SortDirection.desc ? bName.localeCompare(aName) : (aName).localeCompare(bName);
        });
    }

    if (field === SortField.name) {
        departments.sort((a, b) => a.name.localeCompare(b.name));
    }

    return direction === SortDirection.desc ? departments.reverse() : departments;
};
