/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconMagnifyingGlass: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 15 15" fill="none" className={`icon-component ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0429 9.43396H10.7204L15 13.7221L13.7221 15L9.43396 10.7204V10.0429L9.2024 9.80274C8.2247 10.6432 6.9554 11.1492 5.57461 11.1492C2.49571 11.1492 0 8.65352 0 5.57461C0 2.49571 2.49571 0 5.57461 0C8.65352 0 11.1492 2.49571 11.1492 5.57461C11.1492 6.9554 10.6432 8.2247 9.80274 9.2024L10.0429 9.43396ZM1.71527 5.57461C1.71527 7.71012 3.43911 9.43396 5.57461 9.43396C7.71012 9.43396 9.43396 7.71012 9.43396 5.57461C9.43396 3.43911 7.71012 1.71527 5.57461 1.71527C3.43911 1.71527 1.71527 3.43911 1.71527 5.57461Z" />
    </svg>
);

export default IconMagnifyingGlass;
/* eslint-enable max-len */
