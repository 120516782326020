import UpToAndIncludingDate from '../../helpers/date/UpToAndIncludingDate';
import {
    CommentResource,
    ShiftPlanningResource,
    ShiftPlanningType,
    ShiftPlanningViewModel,
} from '../../models';
import transformCommentResource from '../../services/CommentService/transformCommentResource';

export const transformToShiftPlanningViewModel = (
    shiftPlanning: ShiftPlanningResource,
    comments: CommentResource[] = [],
): ShiftPlanningViewModel => ({
    type: ShiftPlanningType.shift,
    id: shiftPlanning.id,
    color: shiftPlanning.color,
    comments: comments.map((comment) => transformCommentResource(comment, comment.owner)),
    customStartTime: shiftPlanning.customStartTime,
    planned: shiftPlanning.planned,
    start: new Date(shiftPlanning.start),
    end: new UpToAndIncludingDate(shiftPlanning.end),
});
