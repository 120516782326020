import { DocumentFormData } from '../../models';
import {
    AddDocumentAction,
    DeleteDocumentAction,
    EditDocumentAction,
    FetchDocumentsAction,
    FetchDocumentTypeAction,
    GetLoketInfoAction,
} from './pzModels';

export const fetchDocumentTypes = (): FetchDocumentTypeAction => ({
    type: 'PZ_SAGA_GET_DOCUMENT_TYPES',
});

export const fetchDocuments = (): FetchDocumentsAction => ({
    type: 'PZ_SAGA_GET_DOCUMENTS',
});

export const editDocument = (formData: DocumentFormData): EditDocumentAction => ({
    type: 'PZ_SAGA_EDIT_DOCUMENT', formData,
});

export const addDocument = (formData: DocumentFormData): AddDocumentAction => ({
    type: 'PZ_SAGA_ADD_DOCUMENT', formData,
});

export const deleteDocument = (documentId: string): DeleteDocumentAction => ({
    type: 'PZ_SAGA_DELETE_DOCUMENT', documentId,
});

export const getLoketInfo = (): GetLoketInfoAction => ({ type: 'PZ_SAGA_GET_LOKET_INFO' });
