import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import {
    DragState,
    ShiftDraggable,
    ShiftViewModel,
} from '../../models';
import getShiftStatus from '../ShiftService/getShiftStatus';

export default function transformShiftToShiftDraggable(
    shift: (ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel),
    conceptState: DragState = DragState.neutral,
): ShiftDraggable {
    const previouslyPlannedUsers = 'previouslyPlannedUsers' in shift ? shift.previouslyPlannedUsers : [];
    const temporaryWorkers = 'temporaryWorkers' in shift ? shift.temporaryWorkers : [];
    const status = getShiftStatus(
        shift.shiftPlannings,
        shift.peopleLimit,
        shift.start,
        temporaryWorkers?.length || 0,
        shift.type === 'baseScheduleShifts',
    );

    return {
        ...shift,
        status,
        previouslyPlannedUsers,
        temporaryWorkers,
        dragState: conceptState,
        plannedUsers: [],
    };
}
