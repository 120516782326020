/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconLockOpen: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 24 24" className={`icon-component ${className}`}>
        <path d="M18,20V10H6V20H18M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10A2,2 0 0,1 6,8H15V6A3,3 0 0,0 12,3A3,3 0 0,0 9,6H7A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,17A2,2 0 0,1 10,15A2,2 0 0,1 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17Z" />
    </svg>
);

export default IconLockOpen;
/* eslint-enable max-len */
