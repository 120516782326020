import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { DivergentPostEmploymentHour } from '../../entities/DivergentPostEmploymentHour/DivergentPostEmploymentHour';

export type DivergentPostEmploymentHourState = AsyncReduxState<{
    divergentPostEmploymentHour?: DivergentPostEmploymentHour;
}>;

const initialState: DivergentPostEmploymentHourState = {
    ...initialAsyncReduxState,
};

export const DivergentPostEmploymentHourSlice = createSlice({
    name: 'divergentPostEmploymentHourReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DivergentPostEmploymentHourState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setDivergentPostEmploymentHour(state, action: PayloadAction<DivergentPostEmploymentHour>): DivergentPostEmploymentHourState {
            return {
                ...state,
                divergentPostEmploymentHour: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DivergentPostEmploymentHourState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setDivergentPostEmploymentHour,
    setIsLoading,
    setError,
} = DivergentPostEmploymentHourSlice.actions;

export default DivergentPostEmploymentHourSlice.reducer;
