import React, { FC } from 'react';

import { Button, ModalFooter } from 'reactstrap';

import { ElementWithTooltip } from '../../../@paco/compositions';
import { translate } from '../../../helpers/translations/translator';

import './FormFooter.scss';


interface Props {
    onCancel: () => void;
    onDelete?: () => void;
    canSubmit?: boolean;
    isDisabled?: boolean;
    deleteButtonError?: string;
}

const FormFooter: FC<Props> = ({
    onCancel,
    onDelete,
    canSubmit = true,
    isDisabled = false,
    deleteButtonError,
}) => (
    <ModalFooter className="form-footer">
        { (onDelete && !isDisabled) && (
            <ElementWithTooltip
                className="form-footer__tooltip"
                tooltipIsActive={!!deleteButtonError}
                tooltipText={deleteButtonError}
            >
                <button
                    className="form-footer__button-delete"
                    type="button"
                    onClick={onDelete}
                    disabled={!!deleteButtonError}
                >
                    {translate('common.delete')}
                </button>
            </ElementWithTooltip>
        )}
        { (onDelete && isDisabled) && (
            <button
                disabled
                className="form-footer__button-delete"
                type="button"
                onClick={onDelete}
            >
                {translate('common.delete')}
            </button>
        )}
        <Button
            type="button"
            color="link"
            id="modal-close"
            onClick={onCancel}
        >
            {translate('common.cancel')}
        </Button>
        <Button
            type="submit"
            color="orange"
            disabled={!canSubmit || isDisabled}
        >
            {translate('common.save')}
        </Button>
    </ModalFooter>
);

export default FormFooter;
