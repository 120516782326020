import { getDepartmentsIndexCall } from '../../../entities/Department/DepartmentService';
import { transformDepartmentToFormOption } from '../../../entities/Department/DepartmentTransformers';
import { getCheckedEmploymentTypeFormOptions } from '../../../entities/EmploymentType/EmploymentTypeHelpers';
import { getEmploymentTypesApiCall } from '../../../entities/EmploymentType/EmploymentTypeService';
import { transformEmploymentTypeToFormOption } from '../../../entities/EmploymentType/EmploymentTypeTransformers';
import { isFetchCollectionResultSuccessful } from '../../../entities/FetchResult';
import { getCheckedRoleFormOptions } from '../../../entities/Role/RoleHelpers';
import { getRolesApiCall } from '../../../entities/Role/RoleService';
import { transformRoleToFormOption } from '../../../entities/Role/RoleTransformers';
import { TypedDispatch } from '../../store';
import {
    setDepartmentOptions,
    setDepartmentOptionsIsLoading,
    setEmploymentTypeOptions,
    setError,
    setRoleOptions,
} from './globalFiltersReducer';

export const fetchDepartmentFilterOptions = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setDepartmentOptionsIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getDepartmentsIndexCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const departmentOptions = response.data.map(transformDepartmentToFormOption);
        dispatch(setDepartmentOptions(departmentOptions));
    } catch (error) {
        console.error('[fetchDepartmentFilterOptions]', error);
    } finally {
        dispatch(setDepartmentOptionsIsLoading(false));
    }
};

export const fetchEmploymentTypeOptions = (legacyEmploymentTypes: string[]) => async (dispatch: TypedDispatch): Promise<void> => {
    try {
        const response = await getEmploymentTypesApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const employmentTypeOptions = response.data.map(transformEmploymentTypeToFormOption);

        dispatch(setEmploymentTypeOptions(getCheckedEmploymentTypeFormOptions(legacyEmploymentTypes, employmentTypeOptions)));
    } catch (error) {
        console.error('[fetchEmploymentTypeOptions]', error);
    }
};

export const fetchRoleOptions = (legacyRoles: string[]) => async (dispatch: TypedDispatch): Promise<void> => {
    try {
        const response = await getRolesApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const roleOptions = response.data.filter(role => role.public).map(transformRoleToFormOption);

        dispatch(setRoleOptions(getCheckedRoleFormOptions(legacyRoles, roleOptions)));
    } catch (error) {
        console.error('[fetchRoleOptions]', error);
    }
};
