import { FC, ReactElement, useMemo } from 'react';

import trans from '../../../helpers/trans';
import { SelectOption } from '../../../types';
import { SelectInput } from '../../index';

import './NumberSelectInput.scss';

const generateNumberOptions = (numbers: number[]): SelectOption[] => numbers.map(
    number => ({
        label: number.toString(),
        value: number.toString(),
    }),
) as SelectOption[];

interface NumberSelectInputProps {
    numbers: number[];
    number: SelectOption;
    onChange: (option: SelectOption) => void;
}

const NumberSelectInput: FC<NumberSelectInputProps> = ({
    numbers,
    number,
    onChange,
}): ReactElement => {
    const numberOptions = useMemo(() => generateNumberOptions([...new Set(numbers)]), [numbers]);

    const onNumberStateChange = (roleValue: SelectOption): void => {
        onChange(roleValue as SelectOption);
    };

    return (
        <SelectInput
            hideLabel
            isSearchable={false}
            label={trans('components.select.placeholder')}
            placeholder={trans('compositions.numberSelectInput.chooseYear')}
            name="number"
            value={number}
            options={numberOptions}
            onChange={onNumberStateChange}
            className="number-select-input"
        />
    );
};

export default NumberSelectInput;
