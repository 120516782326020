import { ChangeEvent, FC, ReactElement } from 'react';

import classNames from 'classnames';

import { ErrorLabel, InputLabel, Radio } from '../../../components';
import { FormOption } from '../../../types';

import './RadioList.scss';

interface RadioListProps {
    disabled?: boolean;
    hideLabel?: boolean;
    required?: boolean;
    id?: string;
    label: string;
    name: string;
    options: FormOption[];
    value: string;
    tabIndex?: number;
    error?: string;
    onChange: (value: string) => void;
    className?: string;
    optionWrapperClassName?: string;
    radioBoxClassName?: string;
    radioClassName?: string;
    radioLabelClassName?: string;
}

const RadioList: FC<RadioListProps> = ({
    disabled,
    hideLabel = false,
    required = false,
    id,
    label,
    name,
    options,
    value,
    tabIndex,
    error = '',
    onChange,
    className = '',
    optionWrapperClassName = '',
    radioBoxClassName = '',
    radioClassName = '',
    radioLabelClassName = '',
}): ReactElement => {
    const radioListOptionWrapperClassNames = classNames('radio-list__option-wrapper', {
        'radio-list__option-wrapper--has-error': !!error,
    }, optionWrapperClassName);

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        onChange(e.target.value);
    };

    return (
        <label
            id={id}
            aria-label={hideLabel ? label : undefined}
            className={`radio-list ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="radio-list__label"
                />
            )}

            <div className={radioListOptionWrapperClassNames}>
                {options.map(option => (
                    <Radio
                        key={option.value}
                        name={name}
                        option={option}
                        selected={value}
                        tabIndex={tabIndex}
                        disabled={disabled}
                        onChange={handleChange}
                        className={`radio-list__radio ${radioClassName}`}
                        boxClassName={radioBoxClassName}
                        labelClassName={radioLabelClassName}
                    />
                ))}
            </div>

            {error && (
                <ErrorLabel
                    text={error}
                    className="radio-list__error-label"
                />
            )}
        </label>
    );
};

export default RadioList;
