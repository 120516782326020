import { Absence } from '../../../entities/Absence/Absence';
import { doesAbsenceOverlapWithPeriod } from '../../../entities/Absence/AbsenceHelpers';
import { Department } from '../../../entities/Department/Department';
import { DistrictDivisionDistrictPlanning } from '../../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { ShiftIndex } from '../../../entities/Shift/Shift';
import { ShiftPlanning } from '../../../entities/ShiftPlanning/ShiftPlanning';
import { TemporaryWorker } from '../../../entities/TemporaryWorker/TemporaryWorker';
import trans from '../../../helpers/trans';
import { CompanyMedicType } from '../../../types';
import { DepartmentOption } from '../../../types/selectOptionTypes';
import { ShiftPlanningDraggableProps } from '../subcomponents/ShiftPlanningCard/ShiftPlanningDraggable';

const transformShiftPlanningsToShiftPlanningSelectorProps = (shiftPlannings: ShiftPlanning[], absences: Absence[], department: Department): ShiftPlanningDraggableProps[] => shiftPlannings
    .filter(shiftPlanning => shiftPlanning.planned)
    .map(shiftPlanning => {
        const userAbsence = absences.find(absence => (
            shiftPlanning.user.id === absence.user.id
            && doesAbsenceOverlapWithPeriod(absence, shiftPlanning.period)));

        return {
            shiftPlanning,
            absence: userAbsence,
            department,
        };
    });

const transformTemporaryWorkersToShiftPlanningSelectorProps = (temporaryWorkers: TemporaryWorker[], department: Department): ShiftPlanningDraggableProps[] => temporaryWorkers.map(temporaryWorker => ({
    department,
    shiftPlanning: {
        id: temporaryWorker.id,
        type: temporaryWorker.type,
        period: temporaryWorker.period,
        color: temporaryWorker.color,
        planned: true,
        customStartTime: true,
        comments: temporaryWorker.comments,
        user: {
            id: temporaryWorker.id,
            fullName: temporaryWorker.name,
            shouldTrackTime: false,
            companyMedic: CompanyMedicType.unknown,
        },
    },
}));

export const transformShiftsToShiftPlanningSelectorProps = (shifts: ShiftIndex[], absences: Absence[]): ShiftPlanningDraggableProps[] => shifts
    .reduce((total: ShiftPlanningDraggableProps[], shift) => [
        ...total,
        ...transformShiftPlanningsToShiftPlanningSelectorProps(shift.shiftPlannings, absences, shift.department),
        ...transformTemporaryWorkersToShiftPlanningSelectorProps(shift.temporaryWorkers, shift.department),
    ], []);

export const getSelectedDepartmentsLabel = (selectedDepartmentsLength: number): string => {
    if (selectedDepartmentsLength === 0) {
        return trans('compositions.dashboardShiftsWidget.allDepartmentsSelected');
    }

    if (selectedDepartmentsLength === 1) {
        return trans('common.departmentSelected', { amount: String(selectedDepartmentsLength) });
    }

    return trans('common.departmentsSelected', { amount: String(selectedDepartmentsLength) });
};

export const transformDepartmentOptionToDepartment = (
    departments: Department[],
    departmentOption: DepartmentOption,
): Department | undefined => departments.find(department => department.id === departmentOption.value);

export const isShiftPlanningUsed = (
    districtDivisionDistrictPlanning: DistrictDivisionDistrictPlanning,
    shiftDraggableProps: ShiftPlanningDraggableProps,
): boolean => {
    if (districtDivisionDistrictPlanning.userId === shiftDraggableProps.shiftPlanning.user.id) {
        return true;
    }

    return districtDivisionDistrictPlanning.type === 'districtDivisionDistrictTemporaryWorkers' && districtDivisionDistrictPlanning.fullName === shiftDraggableProps.shiftPlanning.user.fullName;
};
