import { FC, ReactElement, useEffect } from 'react';

import { useEffectOnce } from 'react-use';

import { EditUserForm } from '../../../containers';
import { EmploymentType } from '../../../entities/EmploymentType/EmploymentType';
import { Experience } from '../../../entities/Experience/Experience';
import { Role } from '../../../entities/Role/Role';
import { EditUserFormData, FullUser } from '../../../entities/User/User';
import trans from '../../../helpers/trans';
import { fetchDepartmentOptions } from '../../../redux/@interface/options/optionsActions';
import { getPacoPayrollPeriods } from '../../../redux/payrollPeriods/payrollPeriodsActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { editUser, resendUserRegistration } from '../../../redux/user/userActions';
import { setError, setIsResendRegistrationSuccessful, setIsSuccessful } from '../../../redux/user/userReducer';
import { ToastType } from '../../../types';

interface ConnectedEditUserFormProps {
    employmentTypes: EmploymentType[];
    experiences: Experience[];
    roles: Role[];
    fullUser: FullUser;
    currentUserId?: string;
    afterEditUserSuccess: () => void;
    onClose: () => void;
}

const ConnectedEditUserForm: FC<ConnectedEditUserFormProps> = ({
    employmentTypes,
    experiences,
    roles,
    fullUser,
    currentUserId,
    afterEditUserSuccess,
    onClose,
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const {
        isLoading,
        isResendRegistrationSuccessful,
        isSuccessful,
        error,
    } = useTypedSelector(state => state.pacoUserReducer);
    const { departmentOptions, isLoading: isDepartmentLoading } = useTypedSelector(state => state.optionsReducer);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);

    useEffectOnce((): void => {
        dispatch(fetchDepartmentOptions());

        if (!payrollPeriods.length) {
            dispatch(getPacoPayrollPeriods());
        }
    });

    const handleEditUser = (editUserFormData: EditUserFormData): void => {
        dispatch(editUser(editUserFormData, fullUser));
    };

    const handleResendRegistration = (): void => {
        dispatch(resendUserRegistration(fullUser.id));
    };

    useEffect((): void => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.editUserForm.editUserSuccess'),
                type: ToastType.pass,
            }));

            afterEditUserSuccess();
            onClose();
        }
    }, [isSuccessful]);

    useEffect((): void => {
        if (isResendRegistrationSuccessful) {
            dispatch(setIsResendRegistrationSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.editUserForm.resendRegistrationMailSuccess'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isResendRegistrationSuccessful]);

    useEffect((): void => {
        if (error) {
            dispatch(setToast({
                text: trans(error),
                type: ToastType.fail,
            }));

            dispatch(setError(''));
        }
    }, [error]);

    return (
        <EditUserForm
            isLoading={isLoading}
            isDepartmentsLoading={isDepartmentLoading}
            departments={departmentOptions}
            experiences={experiences}
            employmentTypes={employmentTypes}
            fullUser={fullUser}
            currentUserId={currentUserId}
            roles={roles}
            onCancel={onClose}
            onResendRegistration={handleResendRegistration}
            onSubmit={handleEditUser}
            payrollPeriods={payrollPeriods}
        />
    );
};

export default ConnectedEditUserForm;
