import {
    delay,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import { transformDepartmentToFormOption } from '../../@paco/entities/Department/DepartmentTransformers';
import {
    globalFiltersSlice,
    setFiltersFromLegacyFilters,
    setUserSearch,
} from '../../@paco/redux/@interface/globalFilters/globalFiltersReducer';
import { authenticatedUserSlice } from '../../@paco/redux/authenticatedUser/authenticatedUserReducer';
import {
    FILTER_SAGA_SET_AVAILABILITY_FILTER,
    FILTER_SAGA_SET_CALENDAR_VIEW_PROPS,
    FILTER_SAGA_SET_DOCUMENT_CATEGORIES,
    FILTER_SAGA_SET_DOCUMENT_SEARCH,
    FILTER_SAGA_SET_EMPLOYEE_CONTRACT_TYPE,
    FILTER_SAGA_SET_EMPLOYEE_SEARCH,
    FILTER_SAGA_SET_EMPLOYMENT_TYPE_BADGE,
    FILTER_SAGA_SET_FILTER,
    FILTER_SAGA_SET_SHIFTS,
    FILTER_SAGA_SET_USER_TYPE,
    FILTER_SET_CALENDAR_VIEW_PROPS,
    FILTER_SET_DOCUMENT_CATEGORIES,
    FILTER_SET_EMPLOYEE_CONTRACT_TYPE,
    FILTER_SET_EMPLOYMENT_TYPE_BADGE,
    FILTER_SET_FILTER,
    FILTER_SET_FILTER_FROM_PACO_STORE,
    FILTER_SET_USER_TYPE,
    PAGINATION_SET_NUMBER,
    SHIFTS_SAGA_GET_SHIFTS,
} from '../actionTypes';

export function* setFilter({ filter, callback }) {
    yield put({ type: PAGINATION_SET_NUMBER, number: 1 });
    yield put({ type: FILTER_SET_FILTER, filter });

    // This delay makes sure components connected with connect() are fully propagated.
    yield delay(1);
    if (callback) callback();
}

export function* setFilterShifts({ shifts }) {
    const state = yield select();
    const { filter } = state.filterReducer;
    const newFilter = { ...filter };

    newFilter.shifts = shifts;

    yield put({ type: PAGINATION_SET_NUMBER, number: 1 });
    yield put({ type: FILTER_SET_FILTER, filter: newFilter });
    yield put({ type: SHIFTS_SAGA_GET_SHIFTS });
}

export function* setAvailabilityFilter({ filter }) {
    yield put({ type: FILTER_SET_FILTER, filter });
}

export function* setEmployeeName({ search }) {
    const state = yield select();
    const { filter } = state.filterReducer;
    const newFilter = { ...filter };

    newFilter.employeeSearch = search;

    yield put({ type: PAGINATION_SET_NUMBER, number: 1 });
    yield put({ type: FILTER_SET_FILTER, filter: newFilter });

    // Trigger @paco/globalFilters reducer actions
    yield put(setUserSearch(search));
}

export function* setDocumentName({ search }) {
    const state = yield select();
    const { filter } = state.filterReducer;
    const newFilter = { ...filter };

    newFilter.documentSearch = search;

    yield put({ type: PAGINATION_SET_NUMBER, number: 1 });
    yield put({ type: FILTER_SET_FILTER, filter: newFilter });
}

export function* setEmployeeContractType({ contractType, checked }) {
    const state = yield select();
    const { filter } = state.filterReducer;
    let { employeeContractTypes } = filter;
    const newFilter = { ...filter };

    if (checked) {
        employeeContractTypes.push(contractType);
    } else {
        employeeContractTypes = employeeContractTypes.filter(type => type !== contractType);
    }

    newFilter.employeeContractTypes = employeeContractTypes;

    yield put({ type: PAGINATION_SET_NUMBER, number: 1 });
    yield put({
        type: FILTER_SET_EMPLOYEE_CONTRACT_TYPE,
        filter: newFilter,
    });
}

export function* setUserType({ userType, checked }) {
    const state = yield select();
    const { filter } = state.filterReducer;
    let { userTypes } = filter;
    const newFilter = { ...filter };

    if (checked) {
        userTypes.push(userType);
    } else {
        userTypes = userTypes.filter(type => type !== userType);
    }

    newFilter.userTypes = userTypes;

    yield put({ type: PAGINATION_SET_NUMBER, number: 1 });
    yield put({
        type: FILTER_SET_USER_TYPE,
        filter: newFilter,
    });
}

export function* setCalendarViewType({ viewProp, checked }) {
    const state = yield select();
    const { filter } = state.filterReducer;
    let { calendarViewProps } = filter.absences;
    const newFilter = { ...filter };

    if (checked) {
        calendarViewProps.push(viewProp);
    } else {
        calendarViewProps = calendarViewProps.filter(type => type !== viewProp);
    }

    newFilter.absences.calendarViewProps = calendarViewProps;

    yield put({
        type: FILTER_SET_CALENDAR_VIEW_PROPS,
        filter: newFilter,
    });
}

export function* setDocumentCategories({ documentType, checked }) {
    const state = yield select();
    const { filter } = state.filterReducer;
    let { documentTypes } = filter.pz;
    const newFilter = { ...filter };

    if (checked) {
        documentTypes.push(documentType);
    } else {
        documentTypes = documentTypes.filter(type => type !== documentType);
    }

    newFilter.pz.documentTypes = documentTypes;

    yield put({ type: PAGINATION_SET_NUMBER, number: 1 });
    yield put({
        type: FILTER_SET_DOCUMENT_CATEGORIES,
        filter: newFilter,
    });
}

export function* setEmploymentTypeBadge({ checked }) {
    const state = yield select();
    const { filter } = state.filterReducer;
    const newFilter = { ...filter };

    newFilter.employmentTypeBadge = checked;

    yield put({ type: PAGINATION_SET_NUMBER, number: 1 });
    yield put({
        type: FILTER_SET_EMPLOYMENT_TYPE_BADGE,
        filter: newFilter,
    });
}

function* onSetEmploymentTypeOptions(action) {
    const state = yield select();
    const { filter } = state.filterReducer;
    const { payload: employmentTypeOptions } = action;
    const newFilter = { ...filter };

    newFilter.employeeContractTypes = employmentTypeOptions.reduce((total, employmentType) => [
        ...total,
        ...(employmentType.isChecked ? [employmentType.value] : []),
    ], []);

    yield put({ type: FILTER_SET_FILTER_FROM_PACO_STORE, filter: newFilter });
}

function* onSetRoleOptions(action) {
    const state = yield select();
    const { filter } = state.filterReducer;
    const { payload: roleOptions } = action;
    const newFilter = { ...filter };

    newFilter.userTypes = roleOptions.reduce((total, role) => [
        ...total,
        ...(role.isChecked ? [role.value] : []),
    ], []);

    yield put({ type: FILTER_SET_FILTER_FROM_PACO_STORE, filter: newFilter });
}

function* onSetUserSearch(action) {
    const state = yield select();
    const { filter } = state.filterReducer;
    const { payload: search } = action;
    const newFilter = { ...filter };

    newFilter.employeeSearch = search;

    yield put({ type: FILTER_SET_FILTER_FROM_PACO_STORE, filter: newFilter });
}

function* onSetShowEmployees(action) {
    const state = yield select();
    const { filter } = state.filterReducer;
    const { payload: showEmployees } = action;
    const newFilter = { ...filter };

    newFilter.shifts.showEmployees = showEmployees;

    yield put({ type: FILTER_SET_FILTER_FROM_PACO_STORE, filter: newFilter });
}

function* onSetDepartmentOptions(action) {
    const state = yield select();
    const { filter } = state.filterReducer;
    const { payload: departmentOptions } = action;
    const newFilter = { ...filter };

    newFilter.departmentOptions = departmentOptions;

    yield put({ type: FILTER_SET_FILTER_FROM_PACO_STORE, filter: newFilter });
}

function* onSetOnlyShowMainDepartments(action) {
    const state = yield select();
    const { filter } = state.filterReducer;
    const { payload: onlyShowMainDepartment } = action;
    const newFilter = { ...filter };

    newFilter.onlyShowMainDepartment = onlyShowMainDepartment;

    yield put({ type: FILTER_SET_FILTER_FROM_PACO_STORE, filter: newFilter });
}

function* onPacoSetAuthenticatedUser(action) {
    const state = yield select();
    const { filter } = state.filterReducer;
    const newFilter = { ...filter };

    const { departments = [] } = action.payload || {};
    newFilter.departmentOptions = departments.map(transformDepartmentToFormOption);

    yield put({ type: FILTER_SET_FILTER_FROM_PACO_STORE, filter: newFilter });
}

function* setPacoFilterOptions(action) {
    const { filter } = action;

    // Trigger @paco/globalFilters reducer actions
    yield put(setFiltersFromLegacyFilters(filter));
}

export default function* filterWatcher() {
    yield takeLatest(FILTER_SAGA_SET_FILTER, setFilter);
    yield takeLatest(FILTER_SAGA_SET_AVAILABILITY_FILTER, setAvailabilityFilter);
    yield takeLatest(FILTER_SAGA_SET_SHIFTS, setFilterShifts);
    yield takeLatest(FILTER_SAGA_SET_EMPLOYEE_SEARCH, setEmployeeName);
    yield takeLatest(FILTER_SAGA_SET_DOCUMENT_SEARCH, setDocumentName);
    yield takeLatest(FILTER_SAGA_SET_EMPLOYEE_CONTRACT_TYPE, setEmployeeContractType);
    yield takeLatest(FILTER_SAGA_SET_USER_TYPE, setUserType);
    yield takeLatest(FILTER_SAGA_SET_CALENDAR_VIEW_PROPS, setCalendarViewType);
    yield takeLatest(FILTER_SAGA_SET_DOCUMENT_CATEGORIES, setDocumentCategories);
    yield takeLatest(FILTER_SAGA_SET_EMPLOYMENT_TYPE_BADGE, setEmploymentTypeBadge);

    // All filter changes also needs to go to @paco/globalFilters
    yield takeLatest(FILTER_SET_FILTER, setPacoFilterOptions);

    // Listen for changes in @paco/globalFilters store
    yield takeLatest(globalFiltersSlice.actions.setEmploymentTypeOptions, onSetEmploymentTypeOptions);
    yield takeLatest(globalFiltersSlice.actions.setRoleOptions, onSetRoleOptions);
    yield takeLatest(globalFiltersSlice.actions.setUserSearch, onSetUserSearch);
    yield takeLatest(globalFiltersSlice.actions.setShowEmployees, onSetShowEmployees);
    yield takeLatest(globalFiltersSlice.actions.setOnlyShowMainDepartments, onSetOnlyShowMainDepartments);
    yield takeLatest(globalFiltersSlice.actions.setDepartmentOptions, onSetDepartmentOptions);
    yield takeLatest(authenticatedUserSlice.actions.setAuthenticatedUser, onPacoSetAuthenticatedUser);
}
