import { FC, ReactElement } from 'react';

import { InputLabel } from '../../../components';
import { EmergencyContactFormData } from '../../../entities/EmergencyContact/EmergencyContact';
import trans from '../../../helpers/trans';
import { TextInput } from '../../index';

import './EmergencyContactInput.scss';

interface EmergencyContactInputProps {
    disabled?: boolean;
    emergencyContactFormData?: EmergencyContactFormData;
    onChange: (emergencyContactFormData: EmergencyContactFormData) => void;
}

const EmergencyContactInput: FC<EmergencyContactInputProps> = ({
    disabled,
    emergencyContactFormData,
    onChange,
}): ReactElement => {
    const handleFullNameChange = (value: string): void => {
        onChange({ ...emergencyContactFormData, fullName: value || undefined });
    };

    const handleRelationTypeChange = (value: string): void => {
        onChange({ ...emergencyContactFormData, relationType: value || undefined });
    };

    const handlePhoneChange = (value: string): void => {
        onChange({ ...emergencyContactFormData, phone: value || undefined });
    };

    return (
        <>
            <InputLabel
                text={trans('common.emergencyContact')}
                className="emergency-contact-input__label"
            />
            <div className="emergency-contact-input__row">
                <div className="emergency-contact-input__col">
                    <TextInput
                        disabled={disabled}
                        label={trans('common.name')}
                        type="text"
                        value={emergencyContactFormData?.fullName || ''}
                        onChange={handleFullNameChange}
                    />
                </div>
                <div className="emergency-contact-input__col">
                    <TextInput
                        disabled={disabled}
                        label={trans('compositions.emergencyContactInput.relationType')}
                        type="text"
                        value={emergencyContactFormData?.relationType || ''}
                        onChange={handleRelationTypeChange}
                    />
                </div>
                <div className="emergency-contact-input__col">
                    <TextInput
                        disabled={disabled}
                        label={trans('common.phone')}
                        type="tel"
                        value={emergencyContactFormData?.phone || ''}
                        onChange={handlePhoneChange}
                    />
                </div>
            </div>
        </>
    );
};

export default EmergencyContactInput;
