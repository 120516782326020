import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';

import { LoginLayout } from '../../@paco/containers';
import { PrivateRouteGroupRoutes } from '../../@paco/routes/routes';
import { translate } from '../../helpers/translations/translator';
import { authenticateUser, forgotUserPassword } from '../../redux/authentication/authenticationActions';

import './Login.scss';

const emailRegEx = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;

class Login extends Component {
    state = {
        forgotPassword: false,
        forgotPasswordRequested: false,
        emailCheck: true,
        email: null,
        credentials: {
            username: '',
            password: '',
        },
    };

    onLoginFormChange = (e) => {
        const { credentials } = this.state;

        credentials[e.currentTarget.name] = e.currentTarget.value;

        this.setState({
            credentials,
        });
    };

    onForgotPasswordChange = (e) => {
        this.setState({
            email: e.currentTarget.value,
        });
    };

    onAuthenticateUserClick = (e) => {
        e.preventDefault();
        const { credentials } = this.state;

        this.props.dispatch(authenticateUser(credentials));
    };

    onForgotPasswordClick = (e) => {
        e.preventDefault();
        const { email } = this.state;
        const emailCheck = email && (email.search(emailRegEx) === 0);

        this.setState({
            emailCheck,
            forgotPasswordRequested: emailCheck,
        });

        if (emailCheck) {
            this.props.dispatch(forgotUserPassword(email));
        }
    };

    onButtonForgotClick = () => {
        this.setState({
            forgotPassword: true,
        });
    };

    onBackClick = () => {
        this.setState({
            forgotPassword: false,
            forgotPasswordRequested: false,
            emailCheck: true,
            email: null,
        });
    };

    renderLoginForm = () => {
        const { loading, authenticateFailure } = this.props;

        return (
            <div className="login-container-form">
                <h4 className="login-title font-weight-semi-bold">
                    {translate('common.login')}
                </h4>
                <Form className="login-form">
                    <FormGroup>
                        <Input onChange={this.onLoginFormChange} type="email" name="username" id="username" autoComplete="username" placeholder={translate('common.email')} />
                    </FormGroup>
                    <FormGroup>
                        <Input onChange={this.onLoginFormChange} type="password" name="password" autoComplete="password" placeholder={translate('pages.login.password')} />
                    </FormGroup>
                    <div className="d-flex justify-content-between">
                        <div>
                            <FormGroup check className="login-checkbox-remember">
                                <Label check>
                                    <Input type="checkbox" />
                                    {translate('pages.login.remember')}
                                </Label>
                            </FormGroup>
                        </div>
                        <button type="button" className="login-button-forgot" onClick={this.onButtonForgotClick}>
                            {translate('pages.login.forgotPassword')}
                        </button>
                    </div>
                    <div className="login-submit d-flex justify-content-end">
                        { authenticateFailure && (
                            <p className="login-paragraph-fail">
                                {translate('pages.login.loginFailure')}
                            </p>
                        )}
                        <Button type="submit" onClick={this.onAuthenticateUserClick} disabled={loading} color="orange">
                            {translate('common.login')}
                        </Button>
                    </div>
                </Form>
            </div>
        );
    };

    renderForgotPasswordForm = () => {
        const { emailCheck, forgotPasswordRequested } = this.state;
        const { loading } = this.props;

        return (
            <div className="login-container-form">
                <h4 className="login-title font-weight-semi-bold">
                    {translate('pages.login.forgotPassword')}
                </h4>
                <p>
                    {translate('pages.login.forgotPasswordIntro')}
                </p>
                <Form className="login-form">
                    <FormGroup>
                        <Input
                            disabled={forgotPasswordRequested}
                            invalid={!emailCheck}
                            onChange={this.onForgotPasswordChange}
                            autoComplete="email"
                            type="email"
                            name="email"
                            id="email-forgot"
                            placeholder={translate('common.email')}
                        />
                        <FormFeedback>
                            {translate('pages.management.emailNotValid')}
                        </FormFeedback>
                    </FormGroup>
                    { forgotPasswordRequested && (
                        <p>
                            {translate('pages.login.forgotPasswordRequested')}
                        </p>
                    )}
                    <div className="login-submit d-flex justify-content-end">
                        <Button type="button" color="link" onClick={this.onBackClick}>
                            {translate('common.back')}
                        </Button>
                        { !forgotPasswordRequested && (
                            <Button type="submit" onClick={this.onForgotPasswordClick} disabled={loading} color="orange">
                                {translate('common.send')}
                            </Button>
                        )}
                    </div>
                </Form>
            </div>
        );
    };

    render() {
        const { forgotPassword } = this.state;
        const { isAuthenticated } = this.props;

        if (isAuthenticated) {
            return <Navigate to={PrivateRouteGroupRoutes.dashboard()} />;
        }

        return (
            <LoginLayout>
                { forgotPassword ? (
                    this.renderForgotPasswordForm()
                ) : (
                    this.renderLoginForm()
                ) }
            </LoginLayout>
        );
    }
}

Login.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    authenticateFailure: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        loading: state.authenticationReducer.loading,
        errors: state.authenticationReducer.errors,
        isAuthenticated: state.authenticationReducer.isAuthenticated,
        authenticateFailure: state.authenticationReducer.authenticateFailure,
    };
}

export default connect(mapStateToProps)(Login);
