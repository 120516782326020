import React, { FC, useState } from 'react';

import { formatDate } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import { Log } from '../../../../models/Log';
import UserPageLink from '../../../UserPageLink/UserPageLink';
import { formatValue, getTranslationForPropName } from '../../helpers';
import CollapseButton from '../CollapseButton/CollapseButton';
import LogBlockItem from '../LogBlockItem/LogBlockItem';

import './LogBlock.scss';


interface LogBlockProps {
    log: Log;
}

const LogBlock: FC<LogBlockProps> = ({ log }) => {
    const { actor, loggedAt, changes } = log;
    const [collapsed, setCollaped] = useState(false);

    const actorFullName = actor ? actor.fullName : translate('common.automaticApproval');
    const actorId = actor ? actor.uuid : '';
    const changesLength = Object.keys(changes).length;

    const toggleCollapsed = () => {
        setCollaped(!collapsed);
    };

    const renderBlockItems = () => collapsed && Object.keys(log.changes).map((key) => {
        const change = log.changes[key];
        const oldValue = formatValue(change.oldValue, key, log.entity);
        const newValue = formatValue(change.newValue, key, log.entity);
        const propName = getTranslationForPropName(key);

        return (
            <LogBlockItem
                action={log.action}
                key={`${log.id}-${key}`}
                propName={propName}
                oldValue={oldValue}
                newValue={newValue}
            />
        );
    });

    return (
        <div className="log-block">
            <div className="log-block__circle" />
            <div className="log-block__mutations">
                <div className="log-block__title">
                    {`${translate('common.a')} `}
                    <span className="log-block__bold">
                        {`${translate(`logs.${log.entity}`).toLowerCase()} `}
                    </span>
                    {translate('common.is').toLowerCase()}
                    <span>
                        {` ${translate(`logs.${log.action}`).toLowerCase()}.`}
                    </span>
                </div>
                <table>
                    <tbody>
                        { renderBlockItems() }
                    </tbody>
                </table>
            </div>
            <div className="log-block__actor">
                {`${formatDate(loggedAt, 'HH:mm')} ${translate('common.by').toLowerCase()} `}
                <UserPageLink className="log-block__actor-link" name={actorFullName} id={actorId} />
                { changesLength > 0 && (
                    <CollapseButton
                        collapsed={collapsed}
                        className="log-block__button-collapse"
                        onClick={toggleCollapsed}
                    />
                )}
            </div>
        </div>
    );
};

export default LogBlock;
