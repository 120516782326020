import React, { FC, useMemo } from 'react';

import classnames from 'classnames';

import SpecialDayTooltip from '../../../../../../components/SpecialDayToolTip/SpecialDayTooltip';
import {
    formatDate,
    isBefore,
    isSameDay,
    startOfDay,
} from '../../../../../../helpers/date';
import { translate } from '../../../../../../helpers/translations/translator';
import { SpecialDayViewModel } from '../../../../../../models';

import './DayContent.scss';


interface DayContentProps {
    day: Date;
    className?: string;
    headerClassName?: string;
    headerLabelClassName?: string;
    headerDayClassName?: string;
    showDayInMonth: boolean;
    specialDays: SpecialDayViewModel[];
    children?: React.ReactNode;
}

const DayContent: FC<DayContentProps> = ({
    day,
    className,
    headerClassName,
    headerLabelClassName,
    headerDayClassName,
    showDayInMonth,
    specialDays,
    children,
}) => {
    const dayIsToday = useMemo(() => isSameDay(day, new Date()), [day]);
    const dayIsPast = useMemo(() => isBefore(day, startOfDay(new Date())), [day]);
    const date = useMemo(() => formatDate(new Date(day), 'd'), [day]);
    const filteredSpecialDays = useMemo(() => specialDays
        .filter((specialDay) => isSameDay(specialDay.date, day)), [specialDays]);

    const classNames = useMemo(() => classnames('day-content concept-day-content', {
        [`${className}--is-today`]: className && dayIsToday,
        [`${className}--is-past`]: className && dayIsPast,
        'concept-day-content--show-day-in-month': showDayInMonth,
        'concept-day-content--is-today': dayIsToday,
        'concept-day-content--is-past': dayIsPast,
    }, [className]), [
        className,
        showDayInMonth,
        dayIsToday,
        dayIsPast,
    ]);

    return (
        <div className={classNames}>
            { showDayInMonth && (
                <div className={`concept-day-content__header ${headerClassName}`}>
                    <div className={`day-content-header-label concept-day-content__header-label ${headerLabelClassName}`}>
                        { (dayIsToday) && translate('common.today') }
                    </div>
                    { filteredSpecialDays.map(specialDay => (
                        <SpecialDayTooltip
                            key={specialDay.id}
                            specialDay={specialDay}
                            isMobile={false}
                        />
                    ))}
                    <div className={`day-content-header-day concept-day-content__header-day ${headerDayClassName}`}>
                        {date}
                    </div>
                </div>
            )}
            {children}
        </div>
    );
};

export default DayContent;
