import React, { FC, ReactElement } from 'react';

import { formatDate, isSameDay } from '../../../../../../@paco/helpers/date';
import { DateFormat } from '../../../../../../@paco/types/dateFormatTypes';
import { translate } from '../../../../../../helpers/translations/translator';
import { SpecialDayViewModel } from '../../../../../../models';
import ListViewTableHeadCell from '../ListViewTableHeadCell/ListViewTableHeadCell';

import './ListViewTableHead.scss';

interface ListViewTableHeadProps {
    dates: Date[];
    specialDays?: SpecialDayViewModel[];
    className?: string;
    cellClassName?: string;
}

const ListViewTableHead: FC<ListViewTableHeadProps> = ({
    dates,
    specialDays = [],
    className = '',
    cellClassName = '',
}): ReactElement => (
    <thead className={`list-view-table-head ${className}`}>
        <tr>
            <ListViewTableHeadCell
                name={translate('common.employee')}
                className={`list-view-table-head__employee-cell ${cellClassName}`}
            />
            {dates.map((date) => {
                const name = formatDate(date, DateFormat.dayOfWeekWithDay);
                const specialDaysMatches = specialDays.filter(specialDay => isSameDay(date, specialDay.date));

                return (
                    <ListViewTableHeadCell
                        key={date.getTime()}
                        name={name}
                        specialDays={specialDaysMatches}
                    />
                );
            })}
        </tr>
    </thead>
);

export default ListViewTableHead;
