import { FC, ReactElement } from 'react';

import classnames from 'classnames';

import { areDatesInSameMonth, formatDate } from '../../../helpers/date';
import { TimeModeType } from '../../types';
import { DateFormat } from '../../types/dateFormatTypes';

import './CalendarHeader.scss';

interface CalendarHeaderProps {
    days: Date[];
    mode: TimeModeType;
    className?: string;
    dayClassName?: string;
}

const CalendarHeader: FC<CalendarHeaderProps> = ({
    days,
    mode,
    className = '',
    dayClassName = '',
}): ReactElement => {
    const headerClassName = classnames('calendar-header', {
        'calendar-header--is-mobile': days.length === 1,
    }, className);

    return (
        <div className={headerClassName}>
            {days.slice(0, 7).map(day => {
                const weekday = formatDate(new Date(day), DateFormat.dayOfWeek);
                const isOutOfRange = (mode === TimeModeType.month) && !areDatesInSameMonth(day, days[days.length - 1]);
                const dayClassNames = classnames('calendar-header__day', {
                    'calendar-header__day--is-out-of-range': isOutOfRange,
                }, dayClassName);

                return (
                    <div
                        key={weekday}
                        className={dayClassNames}
                    >
                        <div className="calendar-header__day-header">
                            {weekday}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default CalendarHeader;
