import { FC, ReactElement } from 'react';

import { ShiftsCalendarFilterContainer } from '../../../containers';

interface ConnectedResetFiltersButtonProps {
    className?: string;
}

const ConnectedShiftsCalendarFilter: FC<ConnectedResetFiltersButtonProps> = ({ className = '' }): ReactElement => (
    <ShiftsCalendarFilterContainer className={className} />
);

export default ConnectedShiftsCalendarFilter;
