import { DepartmentGroup } from '../../entities/DepartmentGroup/DepartmentGroup';
import {
    deleteDepartmentGroupApiCall,
    getDepartmentGroupsApiCall,
    patchDepartmentGroupApiCall,
    postDepartmentGroupApiCall,
} from '../../entities/DepartmentGroup/DepartmentGroupService';
import { isFetchCollectionResultSuccessful, isFetchResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import {
    setDepartmentGroups,
    setError,
    setIsEditGroupsSuccessful,
    setIsLoading,
} from './departmentGroupsReducer';

export const fetchDepartmentGroups = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getDepartmentGroupsApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDepartmentGroups(response.data));
    } catch (error) {
        console.error('[fetchDepartmentGroups]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

interface EditDepartmentGroupsParams {
    groupsToDelete: DepartmentGroup[];
    groupsToPatch: DepartmentGroup[];
    groupsToPost: DepartmentGroup[];
}

export const editDepartmentGroups = ({ groupsToDelete, groupsToPatch, groupsToPost }: EditDepartmentGroupsParams) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsEditGroupsSuccessful(false));
    dispatch(setError(''));

    try {
        const responses = await Promise.all([
            ...groupsToDelete.map(deleteDepartmentGroupApiCall),
            ...groupsToPatch.map(patchDepartmentGroupApiCall),
            ...groupsToPost.map(postDepartmentGroupApiCall),
        ]);

        if (responses.some(response => !isFetchResultSuccessful(response))) {
            dispatch(setError('One of the requests failed'));

            return;
        }

        dispatch(setIsEditGroupsSuccessful(true));
    } catch (error) {
        console.error('[editDepartmentGroups]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
