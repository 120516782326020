import { Shift } from '../../../models';
import { Track } from './Track';

export const getOrphanedTracks = (tracks: Track[], shifts: Shift[]): Track[] => tracks
    .filter(track => track.shift)
    .filter(track => {
        const trackShift = shifts.find((shift) => shift.id === track.shift?.id);

        if (!trackShift) {
            return false;
        }

        return !trackShift.shiftPlannings.some(shiftPlanning => shiftPlanning.planned && shiftPlanning.user.id === track.owner.id);
    });
