import { getUsersContractHoursApiCall } from '../../entities/ContractHours/ContractHoursService';
import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import {
    ContractHoursByUser,
    setError,
    setIsLoading,
    setUserContractHours,
} from './contractHoursReducer';

export const getUsersContractHours = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const response = await getUsersContractHoursApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const contractHoursByUser: ContractHoursByUser = response.data.reduce((acc, user) => ({
            ...acc,
            [user.id]: user.contractHours,
        }), {});

        dispatch(setUserContractHours(contractHoursByUser));
    } catch (error) {
        console.error('[getUsersContractHours]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
