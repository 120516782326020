import { postCommentApiCall } from '../../entities/Comment/CommentService';
import { AddDistrictDivisionDistrictPlanningFormData, EditDistrictDivisionDistrictPlanningFormData } from '../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import {
    deleteDistrictDivisionDistrictTemporaryWorkerApiCall,
    editDistrictDivisionDistrictTemporaryWorkerOrderApiCall,
    postDistrictDivisionDistrictTemporaryWorkerApiCall,
} from '../../entities/DistrictDivisionDistrictTemporaryWorker/DistrictDivisionDistrictTemporaryWorkerService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import {
    setComment,
    setDistrictDivisionDistrictPlanning,
    setError,
    setIsAddPlanningCommentSuccessful,
    setIsAddPlanningSuccessful,
    setIsDeletePlanningSuccessful,
    setIsLoading,
    setIsSuccessful,
    setLastRemovedDistrictDivisionDistrictPlanningId,
} from '../districtDivisionDistrictPlanning/districtDivisionDistrictPlanningReducer';
import { TypedDispatch } from '../store';

export const addDistrictDivisionDistrictTemporaryWorker = (formData: AddDistrictDivisionDistrictPlanningFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsAddPlanningSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await postDistrictDivisionDistrictTemporaryWorkerApiCall(formData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const comments = formData.comment ? [formData.comment] : [];
        dispatch(setDistrictDivisionDistrictPlanning({ ...response.data, comments }));

        dispatch(setIsAddPlanningSuccessful(true));
    } catch (error) {
        console.error('[addDistrictDivisionDistrictTemporaryWorker]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const removeDistrictDivisionDistrictTemporaryWorker = (districtDivisionDistrictTemporaryWorkerId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsDeletePlanningSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await deleteDistrictDivisionDistrictTemporaryWorkerApiCall(districtDivisionDistrictTemporaryWorkerId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLastRemovedDistrictDivisionDistrictPlanningId(districtDivisionDistrictTemporaryWorkerId));
        dispatch(setIsDeletePlanningSuccessful(true));
    } catch (error) {
        console.error('[removeDistrictDivisionDistrictTemporaryWorker]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addDistrictDivisionDistrictTemporaryWorkerComment = (
    districtDivisionDistrictTemporaryWorkerId: string,
    comment: string,
    isSilent = false,
) => async (dispatch: TypedDispatch): Promise<void> => {
    if (!isSilent) {
        dispatch(setIsLoading(true));
        dispatch(setIsAddPlanningCommentSuccessful(false));
    }
    dispatch(setError(''));

    try {
        const response = await postCommentApiCall({
            comment,
            relationshipId: districtDivisionDistrictTemporaryWorkerId,
            relationshipType: 'districtDivisionDistrictTemporaryWorkers',
            relationshipKey: 'districtDivisionDistrictTemporaryWorker',
        });

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setComment({ comment: response.data, districtDivisionDistrictPlanningId: districtDivisionDistrictTemporaryWorkerId }));
        if (!isSilent) {
            dispatch(setIsAddPlanningCommentSuccessful(true));
        }
    } catch (error) {
        console.error('[addDistrictDivisionDistrictTemporaryWorkerComment]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editDistrictDivisionDistrictTemporaryWorker = (formData: EditDistrictDivisionDistrictPlanningFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await editDistrictDivisionDistrictTemporaryWorkerOrderApiCall(formData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDistrictDivisionDistrictPlanning(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editDistrictDivisionDistrictTemporaryWorker]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
