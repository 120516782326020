import { transformToPeriod } from '../../../../../@paco/entities/Period/PeriodTransformers';
import { doesUnavailableToWorkTimeSlotOverlapWithPeriod } from '../../../../../@paco/entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotHelpers';
import { UnavailableToWorkTimeSlotViewModel } from '../../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { transformUnavailableToWorkTimeSlotViewModelToUnavailableToWorkTimeSlot } from '../../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotTransformer';
import {
    AbsenceViewModel,
    LeaveOfAbsenceStatus,
    LeaveOfAbsenceViewModel,
    ShiftPlanningViewModel,
} from '../../../../../models';
import doesAbsenceOverlapWithShiftPlanning
    from '../../../../../services/AbsenceService/doesAbsenceOverlapWithShiftPlanning';
import getAbsencesTooltipText from '../../../../../services/AbsenceService/getAbsencesTooltipText';
import doesLeaveOfAbsenceOverlapWithShiftPlanning
    from '../../../../../services/LeaveOfAbsenceService/doesLeaveOfAbsenceOverlapWithShiftPlanning';
import getLeaveOfAbsenceTooltipText from '../../../../../services/LeaveOfAbsenceService/getLeaveOfAbsenceTooltipText';
import getUnavailableToWorkTimeSlotTooltipText
    from '../../../../../services/UnavailableToWorkTimeSlotService/getUnavailableToWorkTimeSlotTooltipText';
import { ShiftEmployeeIconProps } from '../subcomponents/ShiftEmployeeIcon/ShiftEmployeeIcon';

const getShiftEmployeeIcons = (
    planning: ShiftPlanningViewModel,
    leaveOfAbsences: LeaveOfAbsenceViewModel[] = [],
    absences: AbsenceViewModel[] = [],
    unavailableTimeSlots: UnavailableToWorkTimeSlotViewModel[] = [],
    now: Date,
): ShiftEmployeeIconProps[] => {
    const icons: ShiftEmployeeIconProps[] = [];
    const approvedLeaveOfAbsence = leaveOfAbsences.find(leaveOfAbsence => (
        planning.user?.id === leaveOfAbsence.user?.id
            && leaveOfAbsence.status === LeaveOfAbsenceStatus.approved
            && doesLeaveOfAbsenceOverlapWithShiftPlanning(leaveOfAbsence, planning)));

    const openLeaveOfAbsence = leaveOfAbsences.find(leaveOfAbsence => (
        planning.user?.id === leaveOfAbsence.user?.id
        && leaveOfAbsence.status === LeaveOfAbsenceStatus.open
        && doesLeaveOfAbsenceOverlapWithShiftPlanning(leaveOfAbsence, planning)));

    const activeAbsence = absences.find(absence => (
        planning.user?.id === absence.user?.id
        && doesAbsenceOverlapWithShiftPlanning(absence, planning, now)));

    const unavailableToWorkTimeSlot = unavailableTimeSlots.find(slot => (
        planning.user?.id === slot.user?.id
        && doesUnavailableToWorkTimeSlotOverlapWithPeriod(
            transformUnavailableToWorkTimeSlotViewModelToUnavailableToWorkTimeSlot(slot),
            transformToPeriod(planning.start, planning.end.date),
        )));

    if (activeAbsence) {
        icons.push({
            iconName: 'medical-bag',
            tooltipText: getAbsencesTooltipText(activeAbsence),
        });
    }

    if (approvedLeaveOfAbsence) {
        icons.push({
            iconName: 'calendar-check',
            tooltipText: getLeaveOfAbsenceTooltipText(approvedLeaveOfAbsence, false),
        });
    }

    if (!approvedLeaveOfAbsence && openLeaveOfAbsence) {
        icons.push({
            iconName: 'calendar-question',
            tooltipText: getLeaveOfAbsenceTooltipText(openLeaveOfAbsence, true),
        });
    }

    if (unavailableToWorkTimeSlot) {
        icons.push({
            iconName: 'calendar-warning',
            tooltipText: getUnavailableToWorkTimeSlotTooltipText(unavailableToWorkTimeSlot),
        });
    }

    if (planning.comments && planning.comments.length > 0 && planning.comments[planning.comments.length - 1].body) {
        icons.push({
            iconName: 'comment',
            tooltipText: planning.comments[planning.comments.length - 1].body,
        });
    }

    return icons.slice(0, 3);
};

export default getShiftEmployeeIcons;
