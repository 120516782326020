import { FC, ReactElement } from 'react';

import { ConnectedPzLogsSearch } from '../../../../connectors';
import trans from '../../../../helpers/trans';

import './PzLogsTopBar.scss';

interface PzLogsTopBarProps {
    className?: string;
}

const PzLogsTopBar: FC<PzLogsTopBarProps> = ({ className = '' }): ReactElement => (
    <div className={`pz-logs-top-bar ${className}`}>
        <h1 className="pz-logs-top-bar__title">{trans('containers.pzLogs.title')}</h1>
        <ConnectedPzLogsSearch className="pz-logs-top-bar__search" />
    </div>
);

export default PzLogsTopBar;
