import { unique } from 'underscore';

import { BaseScheduleShiftViewModel } from '../../../../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../../../../entities/ShiftConcept/ShiftConcept';
import { addSeconds, areIntervalsOverlapping, compareAsc } from '../../../../../helpers/date';
import { ShiftViewModel } from '../../../../../models';


const getMultiSelectedShiftIds = (
    selectedShiftIds: string[],
    activeShiftId: string,
    shifts: (ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel)[] = [],
): string[] => {
    if (!selectedShiftIds.length) {
        return [activeShiftId];
    }

    if (selectedShiftIds.some((id) => id === activeShiftId)) {
        selectedShiftIds.filter(id => id !== activeShiftId);
    }

    const activeShift = shifts.find(shift => shift.id === activeShiftId);
    const lastSelectedShift = shifts.find(shift => shift.id === selectedShiftIds[selectedShiftIds.length - 1]);

    if (!lastSelectedShift || !activeShift) {
        return selectedShiftIds;
    }

    const startDate = compareAsc(activeShift.start, lastSelectedShift.start) === 1 ? lastSelectedShift.start : activeShift.start;
    const endDate = startDate === activeShift.start ? addSeconds(lastSelectedShift.start, 1) : activeShift.start;

    const overlappingShifts = shifts.filter(shift => areIntervalsOverlapping(
        {
            start: shift.start,
            end: addSeconds(shift.start, 1),
        },
        {
            start: startDate,
            end: endDate,
        },
    ));

    return unique([
        activeShift.id,
        ...overlappingShifts.map(shift => shift.id),
        lastSelectedShift.id,
    ]);
};

export default getMultiSelectedShiftIds;
