import trans from '../../helpers/trans';
import { FormOption } from '../../types';
import { EmploymentType, EmploymentTypeResource, EmploymentTypeSlug } from './EmploymentType';

export const getFirstLetterOfEmploymentType = (slug: EmploymentType['slug']): string => (
    trans(`entities.employmentTypes.${slug}`).charAt(0)
);

// In Track and Shift calls EmploymentType is sometimes undefined even though it should exist.
export const getDummyEmploymentTypeResource = (id: string): EmploymentTypeResource => ({
    id,
    type: 'employmentTypes',
    attributes: {
        name: 'Full-time',
        slug: EmploymentTypeSlug.fullTime,
    },
});

export const getCheckedEmploymentTypeFormOptions = (
    employmentTypeIds: string[],
    employmentTypes: FormOption[],
): FormOption[] => employmentTypes.map(employmentType => ({
    ...employmentType,
    isChecked: employmentTypeIds.includes(employmentType.value),
}));
