import {
    TRACK_SAGA_ADD_TRACK,
    TRACK_SAGA_APPROVE_TRACK,
    TRACK_SAGA_DELETE_TRACK,
    TRACK_SAGA_EDIT_TRACK,
    TRACK_SAGA_STOP_TRACK,
    TRACKS_SAGA_EXPORT_CSV,
    TRACKS_SAGA_GET_TRACKS,
    TRACKS_SAGA_GET_USER_TRACKS,
    TRACKS_SAGA_SYNC_TVT,
} from '../actionTypes';

export const getTracks = trackType => ({ type: TRACKS_SAGA_GET_TRACKS, trackType });
export const stopTrack = (id, data) => ({ type: TRACK_SAGA_STOP_TRACK, id, data });
export const approveTrack = id => ({ type: TRACK_SAGA_APPROVE_TRACK, id });
export const updateTrack = (id, data, approved) => ({
    type: TRACK_SAGA_EDIT_TRACK, id, data, approved,
});
export const addTrack = data => ({ type: TRACK_SAGA_ADD_TRACK, data });
export const deleteTrack = id => ({ type: TRACK_SAGA_DELETE_TRACK, id });
export const exportCSV = data => ({ type: TRACKS_SAGA_EXPORT_CSV, data });
export const syncTvt = payrollPeriod => ({ type: TRACKS_SAGA_SYNC_TVT, payrollPeriod });
export const getTracksByUserAndDate = (
    userId,
    date,
) => ({ type: TRACKS_SAGA_GET_USER_TRACKS, userId, date });
export default getTracks;
