import { EmergencyContact, EmergencyContactFormData } from './EmergencyContact';

export const getReadableEmergencyContactInfo = (emergencyContact: EmergencyContact): string | undefined => {
    const { phone, fullName, relationType } = emergencyContact;

    if (!fullName && !relationType && !phone) {
        return undefined;
    }

    if (!fullName && relationType) {
        return `${relationType} ${phone ? `- ${phone}` : ''}`;
    }

    if (!fullName && !relationType && phone) {
        return phone;
    }

    return `${fullName || ''} ${relationType ? `(${relationType})` : ''} ${phone ? `- ${phone}` : ''}`;
};

export const getEmergencyContactFormDataDivergence = (formData: EmergencyContactFormData, emergencyContact: EmergencyContact): boolean => (
    formData.fullName !== emergencyContact.fullName
        || formData.phone !== emergencyContact.phone
        || formData.relationType !== emergencyContact.relationType
);
