import { HTMLInputTypeAttribute } from 'react';

export const isInputTypeSupported = (type: HTMLInputTypeAttribute): boolean => {
    const input = document.createElement('input');
    const value = 'a';
    input.setAttribute('type', type);
    input.setAttribute('value', value);

    return (input.value !== value);
};

export const didElementScrollPastElement = (
    scrollY: number,
    element: HTMLElement | null,
    withNavBarHeight = false,
): boolean => {
    if (!element) {
        return false;
    }

    return element.getBoundingClientRect().top < (withNavBarHeight ? 56 : 0);
};

export const generateDownloadURI = (uri: string, name: string): void => {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
