import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import { getCheckedFormOptionsValue } from '../../@paco/helpers/types/formOption';
import { getProfileOfCurrentUser } from '../../@paco/redux/authenticatedUser/authenticatedUserActions';
import {
    AVAILABILITY_CALENDAR,
    CONCEPTS_LIST_VIEW,
    LIST_VIEW,
    TOAST_TYPE_PASS,
    TOAST_TYPE_WARNING,
} from '../../constants';
import { patchSoftDeleteDepartmentApiCall } from '../../entities/Departments/DepartmentsService';
import { transformToDepartmentSoftDeleteRequest } from '../../entities/Departments/DepartmentsTransformers';
import { checkPermission, getFilenameAndGenerateCSV } from '../../helpers';
import {
    delDocument,
    delUserPlanningPreference,
    getAuthenticateLoket,
    getLoginLoket,
    getPermissionGroups,
    getUsersCSV,
    getUserShifts,
    patchDeactivateUser,
    patchDeleteUser,
    patchDepartment,
    patchDocument,
    patchReactivateUser,
    patchUserPlanningPreference,
    postDepartment,
    postDocument,
    postRegistrationMail,
    postUserPlanningPreference,
    postUsersToLoketLink,
    postUserToLoketLink,
} from '../../helpers/api/managementApi';
import { fetchHumanResourceEmployees } from '../../helpers/api/usersApi';
import { getDocumentTypes } from '../../helpers/api-ts/documentType';
import getUsers from '../../helpers/api-ts/users';
import { translate } from '../../helpers/translations/translator';
import getUserCallIncludes from '../../services/UserService/getUserCallIncludes';
import { fetchDepartments } from '../@toolkit/departments/departmentsActions';
import { getUsersListViewData } from '../@toolkit/shiftConcepts/shiftConceptsActions';
import {
    APP_SAGA_ADD_TOAST,
    APP_SAGA_GET_GENERAL_DATA,
    MANAGEMENT_ADD_DEPARTMENT_SUCCESS,
    MANAGEMENT_ADD_DOCUMENT_FAILURE,
    MANAGEMENT_ADD_DOCUMENT_REQUEST,
    MANAGEMENT_ADD_DOCUMENT_SUCCESS,
    MANAGEMENT_AUTHENTICATE_LOKET_FAILURE,
    MANAGEMENT_AUTHENTICATE_LOKET_REQUEST,
    MANAGEMENT_AUTHENTICATE_LOKET_SUCCESS,
    MANAGEMENT_DEACTIVATE_USER_SUCCESS,
    MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_FAILURE,
    MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_REQUEST,
    MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_SUCCESS,
    MANAGEMENT_DELETE_DEPARTMENT_SUCCESS,
    MANAGEMENT_DELETE_DOCUMENT_FAILURE,
    MANAGEMENT_DELETE_DOCUMENT_REQUEST,
    MANAGEMENT_DELETE_DOCUMENT_SUCCESS,
    MANAGEMENT_DELETE_USER_SUCCESS,
    MANAGEMENT_EDIT_DEPARTMENT_SUCCESS,
    MANAGEMENT_EDIT_DOCUMENT_FAILURE,
    MANAGEMENT_EDIT_DOCUMENT_REQUEST,
    MANAGEMENT_EDIT_DOCUMENT_SUCCESS,
    MANAGEMENT_EXPORT_USERS_CSV_FAILURE,
    MANAGEMENT_EXPORT_USERS_CSV_REQUEST,
    MANAGEMENT_EXPORT_USERS_CSV_SUCCESS,
    MANAGEMENT_FAILURE,
    MANAGEMENT_GET_DOCUMENT_TYPES_FAILURE,
    MANAGEMENT_GET_DOCUMENT_TYPES_REQUEST,
    MANAGEMENT_GET_DOCUMENT_TYPES_SUCCESS,
    MANAGEMENT_GET_PERMISSION_GROUPS_FAILURE,
    MANAGEMENT_GET_PERMISSION_GROUPS_REQUEST,
    MANAGEMENT_GET_PERMISSION_GROUPS_SUCCESS,
    MANAGEMENT_GET_USER_SHIFTS_FAILURE,
    MANAGEMENT_GET_USER_SHIFTS_REQUEST,
    MANAGEMENT_GET_USER_SHIFTS_SUCCESS,
    MANAGEMENT_GET_USERS_REQUEST,
    MANAGEMENT_LINK_USER_TO_LOKET_FAILURE,
    MANAGEMENT_LINK_USER_TO_LOKET_REQUEST,
    MANAGEMENT_LINK_USER_TO_LOKET_SUCCESS,
    MANAGEMENT_LINK_USERS_TO_LOKET_FAILURE,
    MANAGEMENT_LINK_USERS_TO_LOKET_REQUEST,
    MANAGEMENT_LINK_USERS_TO_LOKET_SUCCESS,
    MANAGEMENT_LOGIN_LOKET_FAILURE,
    MANAGEMENT_LOGIN_LOKET_REQUEST,
    MANAGEMENT_LOGIN_LOKET_SUCCESS,
    MANAGEMENT_REACTIVATE_USER_SUCCESS,
    MANAGEMENT_REQUEST,
    MANAGEMENT_RESEND_REGISTRATION_MAIL_FAILURE,
    MANAGEMENT_RESEND_REGISTRATION_MAIL_REQUEST,
    MANAGEMENT_RESEND_REGISTRATION_MAIL_SUCCESS,
    MANAGEMENT_RESET_PASSWORD_AND_PINCODE_FAILURE,
    MANAGEMENT_RESET_PASSWORD_AND_PINCODE_REQUEST,
    MANAGEMENT_RESET_PASSWORD_AND_PINCODE_SUCCESS,
    MANAGEMENT_SAGA_ADD_DEPARTMENT,
    MANAGEMENT_SAGA_ADD_DOCUMENT,
    MANAGEMENT_SAGA_AUTHENTICATE_LOKET,
    MANAGEMENT_SAGA_DEACTIVATE_USER,
    MANAGEMENT_SAGA_DEL_USER_PLANNING_PREFERENCE,
    MANAGEMENT_SAGA_DELETE_DEPARTMENT,
    MANAGEMENT_SAGA_DELETE_DOCUMENT,
    MANAGEMENT_SAGA_DELETE_USER,
    MANAGEMENT_SAGA_EDIT_DEPARTMENT,
    MANAGEMENT_SAGA_EDIT_DOCUMENT,
    MANAGEMENT_SAGA_EXPORT_USERS_CSV,
    MANAGEMENT_SAGA_GET_DOCUMENT_TYPES,
    MANAGEMENT_SAGA_GET_PERMISSION_GROUPS,
    MANAGEMENT_SAGA_GET_USER,
    MANAGEMENT_SAGA_GET_USER_SHIFTS,
    MANAGEMENT_SAGA_GET_USERS,
    MANAGEMENT_SAGA_GET_USERS_QUICKLY,
    MANAGEMENT_SAGA_LINK_USER_TO_LOKET,
    MANAGEMENT_SAGA_LINK_USERS_TO_LOKET,
    MANAGEMENT_SAGA_LOGIN_LOKET,
    MANAGEMENT_SAGA_REACTIVATE_USER,
    MANAGEMENT_SAGA_RESEND_REGISTRATION_MAIL,
    MANAGEMENT_SAGA_RESET_PASSWORD_AND_PINCODE,
    MANAGEMENT_SAGA_SET_GROUPS,
    MANAGEMENT_SAGA_SET_SORTING,
    MANAGEMENT_SAGA_SET_USER_PLANNING_PREFERENCE,
    MANAGEMENT_SET_GROUPS,
    MANAGEMENT_SET_SORTING,
    MANAGEMENT_SET_USER_PLANNING_PREFERENCE_FAILURE,
    MANAGEMENT_SET_USER_PLANNING_PREFERENCE_REQUEST,
    MANAGEMENT_SET_USER_PLANNING_PREFERENCE_SUCCESS,
    MANAGEMENT_SET_USERS,
} from '../actionTypes';
import { getCurrentUserFromStore } from '../app/appHelpers';
import { setPagination } from '../app/appSaga';
import { forgotPassword } from '../authentication/authenticationSaga';
import { fetchUser } from '../management-ts/managementSaga';
import { resetPagination } from '../pagination/paginationSaga';
import { getUsersWithAvailability } from '../shifts-ts/shiftsActions';
import { getAvailability } from '../shifts-ts/shiftsSaga';

export function* fetchUsers() {
    yield put({ type: MANAGEMENT_GET_USERS_REQUEST });

    try {
        const currentUser = yield getCurrentUserFromStore();
        const state = yield select();
        const { sorting } = state.managementReducer;
        const { filter } = state.filterReducer;
        const { departmentOptions } = state.globalFiltersReducer;
        const departmentIds = getCheckedFormOptionsValue(departmentOptions);

        const { permissions } = currentUser;

        const response = yield call(() => getUsers(
            filter,
            departmentIds,
            state.paginationReducer.number,
            sorting,
            getUserCallIncludes('usersEditModal', permissions),
        ));

        yield put({ type: MANAGEMENT_SET_USERS, users: response.data });
    } catch (error) {
        yield put({ type: MANAGEMENT_FAILURE, errors: [error] });
    }
}

export function* fetchUsersQuickly(action = { pageNumber: 1 }) {
    yield put({ type: MANAGEMENT_GET_USERS_REQUEST });
    try {
        const { pageNumber } = action;
        const currentUser = yield getCurrentUserFromStore();
        const state = yield select();
        const { sorting } = state.managementReducer;
        const { filter } = state.filterReducer;
        const { permissions } = currentUser;
        const { departmentOptions } = state.globalFiltersReducer;
        const departmentIds = getCheckedFormOptionsValue(departmentOptions);

        const quickResponse = yield call(() => getUsers(
            filter,
            departmentIds,
            pageNumber,
            sorting,
            getUserCallIncludes('usersQuick', permissions),
        ));

        yield call(() => setPagination(quickResponse.meta));
        yield put({ type: MANAGEMENT_SET_USERS, users: quickResponse.data });

        const response = yield call(() => getUsers(
            filter,
            departmentIds,
            pageNumber,
            sorting,
            getUserCallIncludes('users', permissions),
        ));

        yield put({ type: MANAGEMENT_SET_USERS, users: response.data });
    } catch (error) {
        console.error(error);
        yield put({ type: MANAGEMENT_FAILURE, errors: [error] });
    }
}

function* setGroups(action) {
    yield put({ type: MANAGEMENT_SET_GROUPS, groups: action.groups });
}

function* editDepartment(action) {
    yield put({ type: MANAGEMENT_REQUEST });

    try {
        yield call(() => patchDepartment(action.department));
        yield put({ type: APP_SAGA_GET_GENERAL_DATA });
        yield put(fetchDepartments());
        yield put({ type: MANAGEMENT_EDIT_DEPARTMENT_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.editDepartmentSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_FAILURE, errors: [error] });
    }
}

function* deactivateUser(action) {
    yield put({ type: MANAGEMENT_REQUEST });

    try {
        yield call(() => patchDeactivateUser(action.userId));
        yield put({ type: MANAGEMENT_SAGA_GET_USER, id: action.userId });
        yield put({ type: MANAGEMENT_DEACTIVATE_USER_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.deactivateUserSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_FAILURE, errors: [error] });
    }
}

function* reactivateUser(action) {
    yield put({ type: MANAGEMENT_REQUEST });

    try {
        yield call(() => patchReactivateUser(action.userId));
        yield put({ type: MANAGEMENT_SAGA_GET_USER, id: action.userId });
        yield put({ type: MANAGEMENT_REACTIVATE_USER_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.reactivateUserSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_FAILURE, errors: [error] });
    }
}

function* deleteUser(action) {
    yield put({ type: MANAGEMENT_REQUEST });

    try {
        yield call(() => patchDeleteUser(action.userId));
        yield put({ type: MANAGEMENT_SAGA_GET_USERS });
        yield put({ type: MANAGEMENT_DELETE_USER_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.deleteUserSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_FAILURE, errors: [error] });
    }
}


function* addDepartment(action) {
    yield put({ type: MANAGEMENT_REQUEST });

    try {
        yield call(() => postDepartment(action.department));
        yield put({ type: APP_SAGA_GET_GENERAL_DATA });
        yield put(fetchDepartments());
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.addDepartmentSuccess'), toastType: TOAST_TYPE_PASS });
        yield put({ type: MANAGEMENT_ADD_DEPARTMENT_SUCCESS });
    } catch (error) {
        yield put({ type: MANAGEMENT_FAILURE, errors: [error] });
    }
}

function* deleteDepartment(action) {
    yield put({ type: MANAGEMENT_REQUEST });

    try {
        const softDeleteDepartmentRequest = transformToDepartmentSoftDeleteRequest(action.department);

        yield call(() => patchSoftDeleteDepartmentApiCall(softDeleteDepartmentRequest));
        yield put({ type: APP_SAGA_GET_GENERAL_DATA });
        yield put(fetchDepartments());
        yield put({ type: MANAGEMENT_DELETE_DEPARTMENT_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.deleteDepartmentWarning'), toastType: TOAST_TYPE_WARNING });
    } catch (error) {
        yield put({ type: MANAGEMENT_FAILURE, errors: [error] });
    }
}

function* addDocument(action) {
    yield put({ type: MANAGEMENT_ADD_DOCUMENT_REQUEST });

    try {
        const hr = yield call(() => fetchHumanResourceEmployees());
        yield call(() => postDocument({ ...action.data, hr: hr.data.map(user => user.id) }));
        yield* fetchUser(action);
        yield put({ type: MANAGEMENT_ADD_DOCUMENT_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.addDocumentSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_ADD_DOCUMENT_FAILURE, errors: [error] });
    }
}

function* editDocument(action) {
    yield put({ type: MANAGEMENT_EDIT_DOCUMENT_REQUEST });

    try {
        const hr = yield call(() => fetchHumanResourceEmployees());
        yield call(() => patchDocument({ ...action.data, hr: hr.data.map(user => user.id) }));
        yield* fetchUser(action);
        yield put({ type: MANAGEMENT_EDIT_DOCUMENT_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.editDocumentSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_EDIT_DOCUMENT_FAILURE, errors: [error] });
    }
}

function* deleteDocument(action) {
    yield put({ type: MANAGEMENT_DELETE_DOCUMENT_REQUEST });

    try {
        yield call(() => delDocument(action.documentId));
        yield* fetchUser(action);
        yield put({ type: MANAGEMENT_DELETE_DOCUMENT_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.deleteDocumentSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_DELETE_DOCUMENT_FAILURE, errors: [error] });
    }
}

function* fetchDocumentTypes() {
    yield put({ type: MANAGEMENT_GET_DOCUMENT_TYPES_REQUEST });

    try {
        const documentTypes = yield call(() => getDocumentTypes());
        const state = yield select();
        const { permissions } = state.authenticatedUserReducer;
        if (checkPermission(permissions, 'view-all-document-types', 'management-saga-js')) {
            // eslint-disable-next-line max-len
            yield put({ type: MANAGEMENT_GET_DOCUMENT_TYPES_SUCCESS, documentTypes });
        }
    } catch (error) {
        yield put({ type: MANAGEMENT_GET_DOCUMENT_TYPES_FAILURE, errors: [error] });
    }
}

function* resetPasswordAndPincode(action) {
    yield put({ type: MANAGEMENT_RESET_PASSWORD_AND_PINCODE_REQUEST });

    try {
        yield call(forgotPassword, action);
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.resetPasswordAndPincodeSent'), toastType: TOAST_TYPE_PASS });
        yield put({ type: MANAGEMENT_RESET_PASSWORD_AND_PINCODE_SUCCESS });
    } catch (error) {
        yield put({ type: MANAGEMENT_RESET_PASSWORD_AND_PINCODE_FAILURE });
    }
}

function* resendRegistrationMail(action) {
    yield put({ type: MANAGEMENT_RESEND_REGISTRATION_MAIL_REQUEST });

    try {
        yield call(postRegistrationMail, action.userId);
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.resendRegistrationMailSuccess'), toastType: TOAST_TYPE_PASS });
        yield put({ type: MANAGEMENT_RESEND_REGISTRATION_MAIL_SUCCESS });
    } catch (error) {
        yield put({ type: MANAGEMENT_RESEND_REGISTRATION_MAIL_FAILURE });
    }
}

export function* setSorting(action) {
    const state = yield select();
    const { sorting } = state.managementReducer;
    sorting.active = action.sortType;
    sorting[action.sortType].ascending = action.ascending;
    yield put({ type: MANAGEMENT_SET_SORTING, sorting });
    yield resetPagination();
    yield fetchUsersQuickly();
}

function* loginLoket(action) {
    yield put({ type: MANAGEMENT_LOGIN_LOKET_REQUEST });

    try {
        const pathBase = `${window.location.origin}/${translate('nav.userManagement.link')}`;
        const pathUser = `${pathBase}/${translate('nav.userManagement.users.link')}/${action.userId}`;
        const response = yield call(() => getLoginLoket(pathUser));
        window.open(response.data.url, '_blank');
        yield put({ type: MANAGEMENT_LOGIN_LOKET_SUCCESS });
    } catch (error) {
        yield put({ type: MANAGEMENT_LOGIN_LOKET_FAILURE });
    }
}

function* authenticateLoket(action) {
    yield put({ type: MANAGEMENT_AUTHENTICATE_LOKET_REQUEST });

    try {
        yield call(() => getAuthenticateLoket(action.state, action.code));
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.authenticateLoketSuccess'), toastType: TOAST_TYPE_PASS });
        yield put({ type: MANAGEMENT_AUTHENTICATE_LOKET_SUCCESS });
        yield call(getProfileOfCurrentUser);
    } catch (error) {
        yield put({ type: MANAGEMENT_AUTHENTICATE_LOKET_FAILURE });
        yield call(getProfileOfCurrentUser);
    }
}

export function* exportUsersCSV() {
    yield put({ type: MANAGEMENT_EXPORT_USERS_CSV_REQUEST });
    try {
        const response = yield call(() => getUsersCSV());
        getFilenameAndGenerateCSV(response);
        yield put({ type: MANAGEMENT_EXPORT_USERS_CSV_SUCCESS });
    } catch (error) {
        yield put({ type: MANAGEMENT_EXPORT_USERS_CSV_FAILURE, error });
    }
}

export function* linkUserToLoket(action) {
    yield put({ type: MANAGEMENT_LINK_USER_TO_LOKET_REQUEST });
    try {
        yield call(() => postUserToLoketLink(action.userId));
        yield put({ type: MANAGEMENT_LINK_USER_TO_LOKET_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.linkUserToLoketSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_LINK_USER_TO_LOKET_FAILURE, error });
    }
}

export function* linkUsersToLoket() {
    yield put({ type: MANAGEMENT_LINK_USERS_TO_LOKET_REQUEST });
    try {
        const state = yield select();
        const { filter } = state.filterReducer;
        yield call(() => postUsersToLoketLink(filter));
        yield put({ type: MANAGEMENT_LINK_USERS_TO_LOKET_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.linkUsersToLoketSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: MANAGEMENT_LINK_USERS_TO_LOKET_FAILURE, error });
    }
}

export function* fetchUserShifts(action) {
    yield put({ type: MANAGEMENT_GET_USER_SHIFTS_REQUEST });
    try {
        const response = yield call(() => getUserShifts(action.userId, action.startDate, action.endDate));
        yield put({ type: MANAGEMENT_GET_USER_SHIFTS_SUCCESS, shifts: response.data });
    } catch (error) {
        console.error(error);
        yield put({ type: MANAGEMENT_GET_USER_SHIFTS_FAILURE, error });
    }
}

export function* fetchPermissionGroups() {
    yield put({ type: MANAGEMENT_GET_PERMISSION_GROUPS_REQUEST });
    try {
        const response = yield call(() => getPermissionGroups());

        yield put(
            { type: MANAGEMENT_GET_PERMISSION_GROUPS_SUCCESS, permissionGroups: response.data },
        );
    } catch (error) {
        yield put({ type: MANAGEMENT_GET_PERMISSION_GROUPS_FAILURE });
    }
}

export function* setUserPlanningPreference(action) {
    yield put({ type: MANAGEMENT_SET_USER_PLANNING_PREFERENCE_REQUEST });
    try {
        const {
            weekday, fromDate, toDate, comment, userId, planningPreferenceId, page,
        } = action;

        if (planningPreferenceId) {
            yield call(() => patchUserPlanningPreference(weekday, fromDate, toDate, comment, userId, planningPreferenceId));
        } else {
            yield call(() => postUserPlanningPreference(weekday, fromDate, toDate, comment, userId));
        }

        if (page === AVAILABILITY_CALENDAR) {
            yield call(getAvailability);
        } else if (page === LIST_VIEW) {
            yield put(getUsersWithAvailability());
        } else if (page === CONCEPTS_LIST_VIEW) {
            yield put(getUsersListViewData());
        } else {
            yield call(fetchUser);
        }

        const toast = planningPreferenceId ? 'editPlanningPreferenceSuccess' : 'addPlanningPreferenceSuccess';
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate(`pages.management.${toast}`), toastType: TOAST_TYPE_PASS });
        yield put({ type: MANAGEMENT_SET_USER_PLANNING_PREFERENCE_SUCCESS });
    } catch (error) {
        yield put({ type: MANAGEMENT_SET_USER_PLANNING_PREFERENCE_FAILURE });
    }
}

export function* deleteUserPlanningPreference(action) {
    yield put({ type: MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_REQUEST });
    try {
        yield call(() => delUserPlanningPreference(action.planningPreferenceId));
        yield call(fetchUser);
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.deletePlanningPreferenceSuccess'), toastType: TOAST_TYPE_PASS });
        yield put({ type: MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_SUCCESS });
    } catch (error) {
        yield put({ type: MANAGEMENT_DEL_USER_PLANNING_PREFERENCE_FAILURE });
    }
}

export default function* managementWatcher() {
    yield takeLatest(MANAGEMENT_SAGA_GET_USERS, fetchUsers);
    yield takeLatest(MANAGEMENT_SAGA_GET_USERS_QUICKLY, fetchUsersQuickly);
    yield takeLatest(MANAGEMENT_SAGA_SET_GROUPS, setGroups);
    yield takeLatest(MANAGEMENT_SAGA_DEACTIVATE_USER, deactivateUser);
    yield takeLatest(MANAGEMENT_SAGA_REACTIVATE_USER, reactivateUser);
    yield takeLatest(MANAGEMENT_SAGA_DELETE_USER, deleteUser);
    yield takeLatest(MANAGEMENT_SAGA_EDIT_DEPARTMENT, editDepartment);
    yield takeLatest(MANAGEMENT_SAGA_ADD_DEPARTMENT, addDepartment);
    yield takeLatest(MANAGEMENT_SAGA_DELETE_DEPARTMENT, deleteDepartment);
    yield takeLatest(MANAGEMENT_SAGA_ADD_DOCUMENT, addDocument);
    yield takeLatest(MANAGEMENT_SAGA_EDIT_DOCUMENT, editDocument);
    yield takeLatest(MANAGEMENT_SAGA_DELETE_DOCUMENT, deleteDocument);
    yield takeLatest(MANAGEMENT_SAGA_GET_DOCUMENT_TYPES, fetchDocumentTypes);
    yield takeLatest(MANAGEMENT_SAGA_RESET_PASSWORD_AND_PINCODE, resetPasswordAndPincode);
    yield takeLatest(MANAGEMENT_SAGA_RESEND_REGISTRATION_MAIL, resendRegistrationMail);
    yield takeLatest(MANAGEMENT_SAGA_SET_SORTING, setSorting);
    yield takeLatest(MANAGEMENT_SAGA_AUTHENTICATE_LOKET, authenticateLoket);
    yield takeLatest(MANAGEMENT_SAGA_LOGIN_LOKET, loginLoket);
    yield takeLatest(MANAGEMENT_SAGA_EXPORT_USERS_CSV, exportUsersCSV);
    yield takeLatest(MANAGEMENT_SAGA_LINK_USER_TO_LOKET, linkUserToLoket);
    yield takeLatest(MANAGEMENT_SAGA_LINK_USERS_TO_LOKET, linkUsersToLoket);
    yield takeLatest(MANAGEMENT_SAGA_GET_USER_SHIFTS, fetchUserShifts);
    yield takeLatest(MANAGEMENT_SAGA_GET_PERMISSION_GROUPS, fetchPermissionGroups);
    yield takeLatest(MANAGEMENT_SAGA_SET_USER_PLANNING_PREFERENCE, setUserPlanningPreference);
    yield takeLatest(MANAGEMENT_SAGA_DEL_USER_PLANNING_PREFERENCE, deleteUserPlanningPreference);
}
