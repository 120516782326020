import { AbsenceHours } from '../../models';

export default function transformAbsenceHoursToWaitingDayHours(
    absenceHours: AbsenceHours[] = [],
): number | null {
    if (!absenceHours.length) {
        return null;
    }

    return absenceHours.reduce((total, absenceHour) => total + absenceHour.waitingDayHours || 0, 0);
}
