import React from 'react';

import { BaseScheduleIndexViewModel } from '../../../../../entities/BaseSchedule/BaseSchedule';

const transformBaseSchedulesToOptions = (baseSchedules: BaseScheduleIndexViewModel[]): JSX.Element[] => baseSchedules.map(baseSchedule => (
    <option key={baseSchedule.id} value={baseSchedule.id}>
        {baseSchedule.name}
    </option>
));

export default transformBaseSchedulesToOptions;
