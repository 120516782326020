import { FC, ReactElement } from 'react';

import trans from '../../../helpers/trans';
import { IconButton } from '../../index';

import './ResetFiltersButton.scss';

interface ResetFiltersButtonProps {
    onClick: () => void;
    className?: string;
}

const ResetFiltersButton: FC<ResetFiltersButtonProps> = ({ onClick, className = '' }): ReactElement => (
    <IconButton
        text={trans('common.resetFilters')}
        icon="reset"
        onClick={onClick}
        className={`reset-filters-button ${className}`}
    />
);

export default ResetFiltersButton;
