import { CoupledPermissions } from '../../@paco/entities/Permission/Permission';
import { Permission } from '../../models';

export default function doesPermissionHaveParent(
    permission: Permission,
    coupledPermissions: CoupledPermissions[],
): boolean {
    return !!coupledPermissions
        .find((coupledPermission => coupledPermission.children.includes(permission.slug)));
}
