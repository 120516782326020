import { compareAsc } from '../../helpers/date';
import { PayrollPeriod } from '../PayrollPeriod/PayrollPeriod';
import { doesDateOverlapWithPayrollPeriod } from '../PayrollPeriod/PayrollPeriodHelpers';
import { Period } from '../Period/Period';
import { doPeriodsOverlap } from '../Period/PeriodHelpers';
import { Employment } from './Employment';

export const hasUncheckedDivergentPreEmploymentHour = (employment: Employment, period: PayrollPeriod): boolean => {
    const startDateHasPayrollPeriodOverlap = doesDateOverlapWithPayrollPeriod(employment.start, period);
    const divergentPreEmploymentHourIsChecked = !!employment.divergentPreEmploymentHour?.checked;

    return startDateHasPayrollPeriodOverlap && !divergentPreEmploymentHourIsChecked;
};

export const hasUncheckedDivergentPostEmploymentHour = (employment: Employment, period: PayrollPeriod): boolean => {
    const endDateHasPayrollPeriodOverlap = employment.end ? doesDateOverlapWithPayrollPeriod(employment.end, period) : false;
    const divergentPostEmploymentHourIsChecked = !!employment.divergentPostEmploymentHour?.checked;

    return endDateHasPayrollPeriodOverlap && !divergentPostEmploymentHourIsChecked;
};

export const hasUncheckedDivergentEmploymentHours = (employment: Employment, period: PayrollPeriod): boolean => (
    hasUncheckedDivergentPreEmploymentHour(employment, period) || hasUncheckedDivergentPostEmploymentHour(employment, period)
);

// When employment startDate or endDate overlaps with payrollPeriod does it have the divergentPreEmploymentHour or
// divergentPostEmploymentHour to make a PATCH call?
export const canEmploymentEmploymentHoursBeChecked = (employment: Employment, period: PayrollPeriod): boolean => {
    const startDateHasPayrollPeriodOverlap = doesDateOverlapWithPayrollPeriod(employment.start, period);
    const endDateHasPayrollPeriodOverlap = employment.end ? doesDateOverlapWithPayrollPeriod(employment.end, period) : false;

    const hasDivergentPreEmploymentHour = !!employment.divergentPreEmploymentHour;
    const hasDivergentPostEmploymentHour = !!employment.divergentPostEmploymentHour;

    if (endDateHasPayrollPeriodOverlap) {
        return hasDivergentPreEmploymentHour && hasDivergentPostEmploymentHour;
    }

    if (startDateHasPayrollPeriodOverlap) {
        return hasDivergentPreEmploymentHour;
    }

    return false;
};

export const doesEmploymentOverlapWithPeriod = (employment: Employment, period: Period): boolean => {
    if (!employment.end) {
        return compareAsc(period.end, employment.start) !== -1;
    }

    return doPeriodsOverlap({ start: employment.start, end: employment.end }, period);
};

