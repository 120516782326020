import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../../redux/@config/AsyncReduxState';
import { NavigationRouteKey } from '../../../routes/routes';
import { getTooltipsWithNewTooltip } from './helpHelpers';

export const helpRoutes: NavigationRouteKey[] = ['calendar', 'list-view', 'leave-of-absence-requests', 'dashboard'];

export interface HelpToolTip {
    isActive: boolean;
    index: number;
}

export type HelpState = AsyncReduxState<{
    isActive: boolean;
    activeRoute?: NavigationRouteKey;
    tooltips: HelpToolTip[];
}>

const initialState: HelpState = {
    ...initialAsyncReduxState,
    isActive: false,
    tooltips: [],
};

export const helpSlice = createSlice({
    name: 'helpReducer',
    initialState,
    reducers: {
        registerTooltip(state, action: PayloadAction<{ index: number, route: NavigationRouteKey }>): HelpState {
            const { route, index } = action.payload;
            const tooltips = getTooltipsWithNewTooltip(index, state.tooltips);

            return {
                ...state,
                activeRoute: route,
                tooltips,
            };
        },
        setHelpActiveState(state, action: PayloadAction<boolean>): HelpState {
            return {
                ...state,
                isActive: action.payload,
            };
        },
        setTooltips(state, action: PayloadAction<HelpToolTip[]>): HelpState {
            return {
                ...state,
                tooltips: action.payload,
            };
        },
        resetHelp(): HelpState {
            return { ...initialState };
        },
        unregisterTooltip(state, action: PayloadAction<number>): HelpState {
            const tooltips = state.tooltips.filter(tooltip => tooltip.index !== action.payload);

            return {
                ...state,
                isActive: tooltips.length ? state.isActive : false,
                tooltips,
            };
        },
    },
});

export const {
    registerTooltip,
    resetHelp,
    setHelpActiveState,
    setTooltips,
    unregisterTooltip,
} = helpSlice.actions;

export default helpSlice.reducer;
