import { transformToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import { UnavailableToWorkTimeSlot } from '../../@paco/entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { getDummyUserResource } from '../../@paco/entities/User/UserHelpers';
import { transformToUser } from '../../@paco/entities/User/UserTransformers';
import { transformLegacyUserToUser } from '../../entities/User/UserTransformers';
import { UnavailableToWorkTimeSlot as LegacyUnavailableToWorkTimeSlot } from '../../models';

export const transformLegacyUnavailableToWorkTimeSlot = (
    slot: LegacyUnavailableToWorkTimeSlot,
): UnavailableToWorkTimeSlot => ({
    id: slot.id,
    comment: slot.comment,
    period: transformToPeriod(new Date(slot.start), new Date(slot.end)),
    user: slot.user ? transformLegacyUserToUser(slot.user) : transformToUser(getDummyUserResource(`${slot.id}-id`)),
    weekday: slot.weekday,
});
