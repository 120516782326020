import React from 'react';

import {
    NavigateFunction,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

export interface RoutedProps<Params = string, State = string> {
    location: State;
    navigate: NavigateFunction;
    params: Params;
}

export function withRouter<P extends RoutedProps>(Child: React.ComponentClass<P>) {
    // eslint-disable-next-line func-names
    return function (props: Omit<P, keyof RoutedProps>) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        const [searchParams] = useSearchParams();

        return (
            <Child
                {...props as P}
                navigate={navigate}
                location={location}
                params={params}
                searchParams={searchParams}
            />
        );
    };
}
