import { GroupedSelectOption } from '../../components/@inputs/Select/Select';
import { SelectOption } from '../../types';
import { DistrictDivisionTemplate } from '../DistrictDivisionTemplate/DistrictDivisionTemplate';
import { DistrictDivisionDistrictTemplate, DistrictDivisionDistrictTemplateResource } from './DistrictDivisionDistrictTemplate';

export const transformToDistrictDivisionDistrictTemplate = (
    districtDivisionDistrictTemplateResource: DistrictDivisionDistrictTemplateResource,
): DistrictDivisionDistrictTemplate => ({
    id: districtDivisionDistrictTemplateResource.id,
    name: districtDivisionDistrictTemplateResource.attributes.name,
    maxAmountOfEmployees: districtDivisionDistrictTemplateResource.attributes.maxAmountOfEmployees,
});

const transformDistrictDivisionTemplateToSelectOption = (districtDivisionTemplate: DistrictDivisionTemplate): SelectOption => ({
    label: districtDivisionTemplate.name,
    value: districtDivisionTemplate.id,
});

export const transformDistrictDivisionTemplatesToGroupedSelectOptions = (districtDivisionTemplates: DistrictDivisionTemplate[]): GroupedSelectOption[] => [
    {
        label: '',
        options: districtDivisionTemplates.map(transformDistrictDivisionTemplateToSelectOption),
    },
];
