import { FC, ReactElement } from 'react';

import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';

import { Reducers } from '../../../../redux/reducers';
import { RolesFilterContainer } from '../../../containers';
import { fetchRoleOptions } from '../../../redux/@interface/globalFilters/globalFiltersActions';
import { setRoleOptions } from '../../../redux/@interface/globalFilters/globalFiltersReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { FormOption } from '../../../types';

const ConnectedRolesFilter: FC = (): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const legacyRoleOptions = useSelector((state: Reducers) => state.filterReducer.filter.userTypes);
    const roleOptions = useTypedSelector(state => state.globalFiltersReducer.roleOptions);

    const handleOptionsChange = (options: FormOption[]): void => {
        dispatch(setRoleOptions(options));
    };

    useEffectOnce((): void => {
        if (roleOptions.length === 0) dispatch(fetchRoleOptions(legacyRoleOptions));
    });

    return roleOptions.length > 0 ? (
        <RolesFilterContainer
            roleOptions={roleOptions}
            onChange={handleOptionsChange}
        />
    ) : null;
};

export default ConnectedRolesFilter;
