import { FC } from 'react';

import './ShiftsWidgetDepartment.scss';

export interface ShiftsWidgetDepartmentProps {
    department: string;
    group?: string;
    className?: string;
}

const ShiftsWidgetDepartment: FC<ShiftsWidgetDepartmentProps> = ({ department, group, className = '' }) => (
    <div className={`shifts-widget-department ${className}`}>
        {group && (
            <span>
                <span className="shifts-widget-department__group">
                    {group}
                </span>
                <span className="shifts-widget-department__divider">
                    -
                </span>
            </span>
        )}
        {department}
    </div>
);

export default ShiftsWidgetDepartment;
