import { transformToPeriod } from '../Period/PeriodTransformers';
import { User } from '../User/User';
import { UnavailableToWorkTimeSlot, UnavailableToWorkTimeSlotResource } from './UnavailableToWorkTimeSlot';

export const transformToUnavailableToWorkTimeSlot = (resource: UnavailableToWorkTimeSlotResource, user: User): UnavailableToWorkTimeSlot => {
    const period = transformToPeriod(new Date(resource.attributes.start), new Date(resource.attributes.end));

    return {
        id: resource.id,
        comment: resource.attributes.comment,
        weekday: resource.attributes.weekday,
        period,
        user,
    };
};
