import { areIntervalsOverlapping } from '../../helpers/date';
import trans from '../../helpers/trans';
import { Period } from '../Period/Period';
import { ShiftsCalendarUnavailableToWorkTimeSlot } from '../ShiftsCalendarUnavailableToWorkTimeSlot/ShiftsCalendarUnavailableToWorkTimeSlot';
import { UnavailableToWorkTimeSlot } from './UnavailableToWorkTimeSlot';

export const getUnavailableToWorkTimeSlotTooltipText = (unavailableToWorkTimeSlot: ShiftsCalendarUnavailableToWorkTimeSlot): string => (
    `${trans('entities.unavailableToWorkTimeSlots.unavailable')}: ${unavailableToWorkTimeSlot.comment}`
);

const getHoursFromWorkdayStart = (date: Date): number => {
    const hours = date.getHours() - 4;
    const minutes = date.getMinutes();

    const justifiedHours = hours < 0 ? hours + 24 : hours;

    return justifiedHours + minutes / 60;
};

export const doesUnavailableToWorkTimeSlotOverlapWithPeriod = (
    unavailableToWorkTimeSlot: UnavailableToWorkTimeSlot | ShiftsCalendarUnavailableToWorkTimeSlot,
    period: Period,
): boolean => {
    const { weekday } = unavailableToWorkTimeSlot;
    const slotWeekday = weekday === 7 ? 0 : weekday;
    const periodDay = period.start.getDay();

    const periodStartTime = getHoursFromWorkdayStart(period.start);
    const periodEndTime = getHoursFromWorkdayStart(period.end);
    const justifiedPeriodEndTime = periodEndTime < periodStartTime ? periodEndTime + 24 : periodEndTime;
    const slotStartTime = getHoursFromWorkdayStart(unavailableToWorkTimeSlot.period.start);
    const slotEndTime = getHoursFromWorkdayStart(unavailableToWorkTimeSlot.period.end);
    const justifiedSlotEndTime = slotEndTime < slotStartTime ? slotEndTime + 24 : slotEndTime;

    if (slotWeekday !== periodDay) {
        return false;
    }

    return (
        areIntervalsOverlapping(
            { start: periodStartTime, end: justifiedPeriodEndTime },
            { start: slotStartTime, end: justifiedSlotEndTime },
        ) || areIntervalsOverlapping(
            { start: slotStartTime, end: justifiedSlotEndTime },
            { start: periodStartTime + 24, end: justifiedPeriodEndTime + 24 },
        )
    );
};
