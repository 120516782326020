import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiV3Url } from '../../helpers/url';
import { JapiDocument } from '../../japi/types/Document';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { Setting } from './Setting';
import { transformToSetting } from './SettingTransformers';

export const getSettingsApiCall = async (): Promise<FetchCollectionResult<Setting[], string>> => {
    try {
        const url = generateApiV3Url({ endpoint: '/settings' });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!doc || !Array.isArray(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const settings = doc.map(transformToSetting);
        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: settings,
        };
    } catch (error) {
        console.error('[getSettingsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
