import {
    CSSProperties,
    FC,
    MouseEvent,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import classnames from 'classnames';

import { Button } from '../../../../components';
import { IconButton } from '../../../../compositions';
import { DropboxFolder } from '../../../../entities/DropboxEntity/DropboxEntity';
import { doesDropboxFolderHaveActiveChild } from '../../../../entities/DropboxEntity/DropboxEntityHelpers';
import trans from '../../../../helpers/trans';

import './KnowledgeBaseSidebarItem.scss';

interface KnowledgeBaseSidebarItemProps {
    dropboxFolder: DropboxFolder;
    onFolderClick: (event: MouseEvent<HTMLButtonElement>, activeDirectoryPath: string) => void;
    activeDirectoryId?: string;
    titleClassName?: string;
    className?: string;
}

const KnowledgeBaseSidebarItem: FC<KnowledgeBaseSidebarItemProps> = ({
    dropboxFolder,
    activeDirectoryId,
    onFolderClick,
    className = '',
    titleClassName = '',
}): ReactElement => {
    const [isOpened, setIsOpened] = useState(false);
    const isActive = dropboxFolder.id === activeDirectoryId;
    const hasActiveChild = useMemo(() => doesDropboxFolderHaveActiveChild(dropboxFolder, activeDirectoryId), [dropboxFolder, activeDirectoryId]);

    const itemWrapperClassnames = classnames('knowledge-base-sidebar-item__wrapper', {
        'knowledge-base-sidebar-item__wrapper--is-active': isActive,
    });

    const iconClassNames = classnames('knowledge-base-sidebar-item__icon', {
        'knowledge-base-sidebar-item__icon--is-opened': isOpened,
        'knowledge-base-sidebar-item__icon--is-active': isActive,
    });

    const titleClassNames = classnames('knowledge-base-sidebar-item__title', {
        'knowledge-base-sidebar-item__title--is-active': isActive,
    }, titleClassName);

    const cssVariables = {
        '--sidebar-item-index': dropboxFolder.levelOfDeepness,
    } as CSSProperties;

    const handleFolderClick = (event: MouseEvent<HTMLButtonElement>): void => {
        const newActiveDirectoryId = event.currentTarget.getAttribute('data-id');

        if (newActiveDirectoryId) {
            onFolderClick(event, newActiveDirectoryId);
        }
    };

    const handleCollapseButtonClick = (activeFolder: DropboxFolder, event: MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();

        if (activeFolder.children.length) {
            setIsOpened(!isOpened);
        }
    };

    useEffect((): void => {
        if (hasActiveChild) {
            setIsOpened(true);
        }
    }, [activeDirectoryId]);

    useEffect((): void => {
        if (dropboxFolder.children.length && isActive) {
            setIsOpened(true);
        }
    }, [isActive]);

    return (
        <div style={cssVariables} className={`knowledge-base-sidebar-item ${className}`}>
            <Button
                data-id={dropboxFolder.id}
                text={dropboxFolder.name}
                onClick={handleFolderClick}
                className={itemWrapperClassnames}
            >
                <IconButton
                    hideLabel
                    text={trans('compositions.dashboardChangeLogWidget.extend')}
                    icon={dropboxFolder.children.length === 0 ? 'closed-directory' : 'chevron-right'}
                    onClick={(e) => handleCollapseButtonClick(dropboxFolder, e)}
                    iconClassName={iconClassNames}
                />
                <p className={titleClassNames}>{dropboxFolder.name}</p>
            </Button>
            {isOpened && dropboxFolder.children.map(dropboxFolderChildren => (
                <KnowledgeBaseSidebarItem
                    key={dropboxFolderChildren.path}
                    dropboxFolder={dropboxFolderChildren}
                    activeDirectoryId={activeDirectoryId}
                    onFolderClick={handleFolderClick}
                    className="knowledge-base-sidebar-item__child-item"
                    titleClassName="knowledge-base-sidebar-item__child-title"
                />
            ))}
        </div>
    );
};

export default KnowledgeBaseSidebarItem;
