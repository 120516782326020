import { FeedbackResource, FeedbackViewModel } from '../../models/Feedback';

export default function transformFeedbackResource(
    feedback: FeedbackResource,
): FeedbackViewModel {
    return {
        id: feedback.id,
        rating: feedback.rating || null,
        remark: feedback.remark || null,
        category: feedback.category || null,
    };
}
