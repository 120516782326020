import { FC, useEffect } from 'react';

import { ChangeLogWidget } from '../../compositions';
import { fetchChangeLog } from '../../redux/dashboard/dashboardActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedChangeLogWidgetProps {
    className?: string;
}

const ConnectedChangeLogWidget: FC<ConnectedChangeLogWidgetProps> = ({ className }) => {
    const dispatch = useTypedDispatch();

    const { hasUpdatedChangeLog, isChangeLogLoading, changeLog } = useTypedSelector(state => state.dashboardSettingsReducer);

    useEffect(() => {
        dispatch(fetchChangeLog());
    }, []);

    return (
        <ChangeLogWidget
            hasUpdatedChangeLog={hasUpdatedChangeLog}
            isLoading={isChangeLogLoading}
            changeLog={changeLog}
            className={className}
        />
    );
};

export default ConnectedChangeLogWidget;
