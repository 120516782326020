import { FC, MouseEvent, useMemo } from 'react';

import classNames from 'classnames';

import {
    compareAsc,
    isBefore,
    isSameDay,
    startOfDay,
} from '../../../helpers/date';
import { SpecialDayViewModel } from '../../../models';
import { CalendarItem } from '../../containers/CalendarDays/CalenderDays';
import { doesCalendarItemFallOnWorkDay } from './helpers';
import { CalendarDayContent, CalendarDayHeader } from './subcomponents';

import './CalendarDay.scss';

export interface CalenderDayProps {
    isDark?: boolean;
    isOutOfRange?: boolean;
    showHeader?: boolean;
    showAddItemButton?: boolean;
    showAddSequenceButton?: boolean;
    showDayInMonth?: boolean;

    addItemButtonTooltipText?: string;
    addSequenceButtonTooltipText?: string;
    day: Date;
    dayHeaderFormat?: 'EEEEEE' | 'EEEE';
    items: CalendarItem[];
    specialDays: SpecialDayViewModel[];

    onAddItemButtonClick?: (day: Date) => void;
    onAddSequenceButtonClick?: (day: Date) => void;
    onDayDragEnter?: (day?: Date) => void;
    onDayDrop?: (day?: Date) => void;

    className?: string;
}

const CalenderDay: FC<CalenderDayProps> = ({
    isDark,
    isOutOfRange,
    showAddItemButton,
    showAddSequenceButton,
    showDayInMonth,
    showHeader,

    addItemButtonTooltipText,
    addSequenceButtonTooltipText,
    day,
    dayHeaderFormat,
    items,
    specialDays,

    onAddItemButtonClick,
    onAddSequenceButtonClick,
    onDayDragEnter,
    onDayDrop,

    className = '',
}) => {
    const isPast = useMemo(() => isBefore(day, startOfDay(new Date())), [day]);
    const filteredSpecialDays = useMemo(() => specialDays
        .filter((specialDay) => isSameDay(specialDay.date, day)), [specialDays]);
    const filteredItems = useMemo(
        () => items
            .filter((item) => doesCalendarItemFallOnWorkDay(item, day))
            .sort((a, b) => compareAsc(a.start, b.start)),
        [items, day],
    );

    const calendarDayClassNames = classNames('calendar-day', {
        'calendar-day--is-out-of-range': isOutOfRange,
    }, className);

    const handleElementDrop = () => {
        if (onDayDrop) {
            onDayDrop(isPast ? undefined : day);
        }
    };

    const handleElementDragEnter = () => {
        if (onDayDragEnter) {
            onDayDragEnter(isPast ? undefined : day);
        }
    };

    const handleElementDragOver = (e: MouseEvent) => {
        e.preventDefault();
    };

    return (
        <div
            onDragOver={handleElementDragOver}
            onDragEnter={handleElementDragEnter}
            onDrop={handleElementDrop}
            className={calendarDayClassNames}
        >
            {showHeader && (
                <CalendarDayHeader
                    day={day}
                    dayHeaderFormat={dayHeaderFormat}
                    className="calendar-day__header"
                />
            )}
            <CalendarDayContent
                isDark={isDark}
                showAddItemButton={showAddItemButton}
                showDayInMonth={showDayInMonth}
                showAddSequenceButton={showAddSequenceButton}
                addItemButtonTooltipText={addItemButtonTooltipText}
                addSequenceButtonTooltipText={addSequenceButtonTooltipText}
                day={day}
                specialDays={filteredSpecialDays}
                onAddItemButtonClick={onAddItemButtonClick}
                onAddSequenceButtonClick={onAddSequenceButtonClick}
                className="calendar-day__content"
            >
                {filteredItems.map(item => item.element)}
            </CalendarDayContent>
        </div>
    );
};

export default CalenderDay;
