import { ShiftConceptSlice } from '../@toolkit/shiftConcepts/shiftConceptsReducer';
import {
    APP_LOGOUT,
    APP_ROUTE_CHANGE,
    APP_SAGA_SIDEBAR_RESIZE,
    AUTH_REQUEST,
    FILTER_SET_FILTER,
    SHIFTS_ADD_SHIFT_REQUEST,
    SHIFTS_ADD_SHIFT_SUCCESS,
    SHIFTS_ADD_SPECIAL_DAY_REQUEST,
    SHIFTS_ADD_TEMP_WORKER_REQUEST,
    SHIFTS_ADD_TEMP_WORKER_SUCCESS,
    SHIFTS_CANCEL_EDIT_SHIFT,
    SHIFTS_DELETE_SHIFT_FAILURE,
    SHIFTS_DELETE_SHIFT_REQUEST,
    SHIFTS_DELETE_SHIFT_SUCCESS,
    SHIFTS_DELETE_SPECIAL_DAY_FAILURE,
    SHIFTS_DELETE_SPECIAL_DAY_REQUEST,
    SHIFTS_DELETE_TEMP_WORKER_REQUEST,
    SHIFTS_DELETE_TEMP_WORKER_SUCCESS,
    SHIFTS_DELETE_USER_FROM_SHIFT_REQUEST,
    SHIFTS_DELETE_USER_FROM_SHIFT_SUCCESS,
    SHIFTS_EDIT_SHIFT_FAILURE,
    SHIFTS_EDIT_SHIFT_PLANNING_REQUEST,
    SHIFTS_EDIT_SHIFT_PLANNING_SUCCESS,
    SHIFTS_EDIT_SHIFT_REQUEST,
    SHIFTS_EDIT_SHIFT_SUCCESS,
    SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_FAILURE,
    SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_REQUEST,
    SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_SUCCESS,
    SHIFTS_EDIT_SPECIAL_DAY_REQUEST,
    SHIFTS_EDIT_TEMP_WORKER_REQUEST,
    SHIFTS_EDIT_TEMP_WORKER_SUCCESS,
    SHIFTS_FAILURE,
    SHIFTS_FILTER_SHIFT_BY_ABSENCE_FILTERS,
    SHIFTS_GET_AVAILABILITY_FAILURE,
    SHIFTS_GET_AVAILABILITY_REQUEST,
    SHIFTS_GET_AVAILABILITY_SUCCESS,
    SHIFTS_GET_PLANNING_PREFERENCES_SUCCESS,
    SHIFTS_GET_SHIFT_REQUEST,
    SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_SUCCESS,
    SHIFTS_GET_SHIFT_SNAPSHOT_SUCCESS,
    SHIFTS_GET_SHIFT_SUCCESS,
    SHIFTS_GET_SHIFTS_FAILURE,
    SHIFTS_GET_SHIFTS_REQUEST,
    SHIFTS_GET_SHIFTS_SUCCESS,
    SHIFTS_GET_SPECIAL_DAY_TYPES_FAILURE,
    SHIFTS_GET_SPECIAL_DAY_TYPES_REQUEST,
    SHIFTS_GET_SPECIAL_DAY_TYPES_SUCCESS,
    SHIFTS_GET_SPECIAL_DAYS_FAILURE,
    SHIFTS_GET_SPECIAL_DAYS_REQUEST,
    SHIFTS_GET_SPECIAL_DAYS_SUCCESS,
    SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_SUCCESS,
    SHIFTS_GET_USERS_PLANNED_ON_DATE_SUCCESS,
    SHIFTS_GET_USERS_WITH_AVAILABILITY_REQUEST,
    SHIFTS_GET_USERS_WITH_AVAILABILITY_SUCCESS,
    SHIFTS_GET_USERS_WITH_LOKET_BALANCES_REQUEST,
    SHIFTS_GET_USERS_WITH_LOKET_BALANCES_SUCCESS,
    SHIFTS_MAPPED_ABSENCE_DATA_TO_SHIFT_PLANNINGS,
    SHIFTS_OPEN_SHIFT,
    SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_FAILURE,
    SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_REQUEST,
    SHIFTS_PLAN_USER_TO_SHIFT_REQUEST,
    SHIFTS_PLAN_USER_TO_SHIFT_SUCCESS,
    SHIFTS_SET_EMPLOYEE_COLOR_REQUEST,
    SHIFTS_SET_EMPLOYEE_COLOR_SUCCESS,
    SHIFTS_SET_LOADING,
    WEEK_NAVIGATOR_SET_DATES,
} from '../actionTypes';

const initialState = {
    loading: false,
    planLoading: false,
    exportCSVRequest: false,
    lastAddedShiftId: '',
    shifts: [],
    shift: {},
    usersPlannedOnShiftDate: [],
    similarShifts: [],
    planningPreferences: [],
    preferToWorkTimeSlots: [],
    unavailableToWorkTimeSlots: [],
    absences: [],
    leaveOfAbsences: [],
    shiftSnapshot: {},
    specialDays: [],
    specialDayTypes: [],
    usersWithAvailability: [],
    availabilityPaginationNumber: 1,
    usersWithLoketBalances: [],
    errors: [],
};

const shiftsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHIFTS_GET_SHIFTS_REQUEST:
        case SHIFTS_GET_SHIFT_REQUEST:
        case SHIFTS_GET_SPECIAL_DAYS_REQUEST:
        case SHIFTS_GET_SPECIAL_DAY_TYPES_REQUEST:
        case SHIFTS_ADD_SPECIAL_DAY_REQUEST:
        case SHIFTS_EDIT_SPECIAL_DAY_REQUEST:
        case SHIFTS_DELETE_SPECIAL_DAY_REQUEST:
        case SHIFTS_GET_USERS_WITH_AVAILABILITY_REQUEST:
        case SHIFTS_GET_USERS_WITH_LOKET_BALANCES_REQUEST:
        case SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_REQUEST:
        case AUTH_REQUEST:
            return { ...state, loading: true };
        case SHIFTS_GET_SHIFTS_SUCCESS:
            return {
                ...state,
                loading: false,
                planLoading: false,
                shiftSnapshot: {},
                similarShifts: [],
                shifts: action.shifts,
            };
        case SHIFTS_GET_SHIFT_SUCCESS:
            return {
                ...state,
                loading: false,
                planLoading: false,
                shift: action.shift,
            };
        case SHIFTS_GET_SHIFT_SNAPSHOT_SUCCESS:
            return {
                ...state, shiftSnapshot: action.snapshot,
            };
        case SHIFTS_GET_SHIFT_SIMILAR_SHIFTS_SUCCESS:
            return {
                ...state, similarShifts: action.similarShifts,
            };
        case SHIFTS_FAILURE:
        case SHIFTS_GET_SHIFTS_FAILURE:
        case SHIFTS_DELETE_SHIFT_FAILURE:
        case SHIFTS_EDIT_SHIFT_FAILURE:
        case SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_FAILURE:
        case SHIFTS_GET_AVAILABILITY_FAILURE:
        case SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_FAILURE:
            return {
                ...state, loading: false, planLoading: false, errors: [action.error, ...state.errors],
            };
        case SHIFTS_ADD_SHIFT_REQUEST:
        case SHIFTS_EDIT_SHIFT_REQUEST:
        case SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_REQUEST:
        case SHIFTS_DELETE_SHIFT_REQUEST:
        case SHIFTS_GET_AVAILABILITY_REQUEST:
            return {
                ...state, loading: true, lastAddedShiftId: '',
            };
        case SHIFTS_DELETE_SHIFT_SUCCESS:
            return { ...state, shift: {}, lastAddedShiftId: '' };
        case SHIFTS_ADD_SHIFT_SUCCESS:
        case SHIFTS_EDIT_SHIFT_SUCCESS:
            return {
                ...state,
                shift: action.shift,
                lastAddedShiftId: action.shift.id,
            };
        case SHIFTS_CANCEL_EDIT_SHIFT:
            return {
                ...state,
                lastAddedShiftId: action.shiftId,
            };
        case SHIFTS_PLAN_USER_TO_SHIFT_REQUEST:
        case SHIFTS_DELETE_USER_FROM_SHIFT_REQUEST:
        case SHIFTS_EDIT_SHIFT_PLANNING_REQUEST:
        case SHIFTS_ADD_TEMP_WORKER_REQUEST:
        case SHIFTS_EDIT_TEMP_WORKER_REQUEST:
        case SHIFTS_DELETE_TEMP_WORKER_REQUEST:
        case SHIFTS_SET_EMPLOYEE_COLOR_REQUEST:
            return {
                ...state, planLoading: true,
            };
        case SHIFTS_SET_LOADING:
            return {
                ...state, loading: action.loading,
            };
        case APP_ROUTE_CHANGE:
        case WEEK_NAVIGATOR_SET_DATES:
        case SHIFTS_OPEN_SHIFT:
            return {
                ...state, lastAddedShiftId: '',
            };
        case FILTER_SET_FILTER:
        case `${ShiftConceptSlice.name}/setShiftConcepts`:
            return { ...state, lastAddedShiftId: '' };
        case SHIFTS_MAPPED_ABSENCE_DATA_TO_SHIFT_PLANNINGS:
        case SHIFTS_FILTER_SHIFT_BY_ABSENCE_FILTERS:
            return {
                ...state,
                ...(action.shifts && { shifts: action.shifts }),
                ...(action.shift && { shift: action.shift }),
                loading: false,
            };
        case SHIFTS_GET_SPECIAL_DAYS_SUCCESS:
            return { ...state, loading: false, specialDays: action.specialDays };
        case SHIFTS_GET_SPECIAL_DAY_TYPES_SUCCESS:
            return { ...state, loading: false, specialDayTypes: action.specialDayTypes };
        case SHIFTS_GET_SPECIAL_DAY_TYPES_FAILURE:
        case SHIFTS_GET_SPECIAL_DAYS_FAILURE:
        case SHIFTS_DELETE_SPECIAL_DAY_FAILURE:
            return { ...state, loading: false };
        case SHIFTS_GET_PLANNING_PREFERENCES_SUCCESS:
            return {
                ...state,
                preferToWorkTimeSlots: action.preferToWorkTimeSlots,
                unavailableToWorkTimeSlots: action.unavailableToWorkTimeSlots,
            };
        case SHIFTS_GET_USERS_PLANNED_ON_DATE_SUCCESS:
            return { ...state, usersPlannedOnShiftDate: action.users };
        case SHIFTS_SET_EMPLOYEE_COLOR_SUCCESS:
        case SHIFTS_PLAN_USER_TO_SHIFT_SUCCESS:
        case SHIFTS_EDIT_SHIFT_PLANNING_SUCCESS:
        case SHIFTS_ADD_TEMP_WORKER_SUCCESS:
        case SHIFTS_DELETE_TEMP_WORKER_SUCCESS:
        case SHIFTS_EDIT_TEMP_WORKER_SUCCESS:
            return {
                ...state,
                shift: action.shift,
                planLoading: false,
            };
        case SHIFTS_DELETE_USER_FROM_SHIFT_SUCCESS:
            return {
                ...state,
                shift: action.shift,
                usersPlannedOnShiftDate: action.usersPlannedOnShiftDate,
                planLoading: false,
            };
        case SHIFTS_GET_AVAILABILITY_SUCCESS:
            return {
                ...state,
                loading: false,
                preferToWorkTimeSlots: action.preferToWorkTimeSlots,
                unavailableToWorkTimeSlots: action.unavailableToWorkTimeSlots,
                absences: action.absences,
                leaveOfAbsences: action.leaveOfAbsences,
            };
        case SHIFTS_GET_UNAVAILABLE_WORK_TIME_SLOTS_SUCCESS:
            return {
                ...state,
                unavailableToWorkTimeSlots: action.unavailableToWorkTimeSlots,
            };
        case SHIFTS_EDIT_SHIFTS_WITH_SNAPSHOT_SUCCESS:
            return {
                ...state,
                loading: false,
                shift: {},
                lastAddedShiftId: '',
                shiftSnapshot: {},
                similarShifts: [],
            };
        case SHIFTS_GET_USERS_WITH_AVAILABILITY_SUCCESS:
            return {
                ...state,
                loading: false,
                usersWithAvailability: action.usersWithAvailability,
                availabilityPaginationNumber: action.page,
            };
        case SHIFTS_GET_USERS_WITH_LOKET_BALANCES_SUCCESS:
            return {
                ...state,
                loading: false,
                usersWithLoketBalances: action.usersWithLoketBalances,
            };
        case APP_SAGA_SIDEBAR_RESIZE:
            return { ...state, lastAddedShiftId: '' };
        case APP_LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};

export default shiftsReducer;
