import { FC } from 'react';

import { ShiftsWidgetHeaderSkeleton, ShiftsWidgetShiftsSkeletons } from '..';

interface ShiftsWidgetProps {
    className?: string;
}

const ShiftsWidgetSkeletons: FC<ShiftsWidgetProps> = ({
    className = '',
}) => (
    <div className={`shifts-widget ${className}`}>
        <ShiftsWidgetHeaderSkeleton />

        <ShiftsWidgetShiftsSkeletons className="shifts-widget__shifts" />
    </div>
);

export default ShiftsWidgetSkeletons;
