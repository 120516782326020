import trans from '../../helpers/trans';
import { FormOption } from '../../types';
import { Role } from './Role';

export const getRoleDescription = (roleSlug: string): string => trans(`entities.roles.description.${roleSlug}`);

export const getRoleById = (roles: Role[], roleId: string): Role | undefined => roles.find(role => role.id === roleId);

export const getCheckedRoleFormOptions = (
    roleIds: string[],
    roles: FormOption[],
): FormOption[] => roles.map(role => ({
    ...role,
    isChecked: roleIds.includes(role.value),
}));
