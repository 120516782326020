import axios from 'axios';

import {
    ApiResponse,
    DocumentTypeResource,
    DocumentTypeViewModel,
} from '../../models';
import transformDocumentTypeResource from '../../services/DocumentType/transformDocumentTypeResource';

export async function getDocumentTypes(): Promise<DocumentTypeViewModel[]> {
    const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/document-types/`) as unknown as ApiResponse<DocumentTypeResource[]>;

    return response
        .data.map((documentType) => transformDocumentTypeResource(documentType));
}
