import { ChangeEvent, FC, ReactElement } from 'react';

import {
    ErrorLabel,
    Icon,
    Input,
    InputLabel,
} from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';
import { IconSet } from '../../../components/Icon/Icon';

import './SearchInput.scss';

export interface IconTextInputProps extends Omit<HTMLInputProps, 'onChange'> {
    icon: keyof IconSet;
    label: string;
    hideLabel?: boolean;
    error?: string;
    onChange: (value: string) => void;
    inputClassName?: string;
    iconWrapperClassName?: string;
}

const SearchInput: FC<IconTextInputProps> = ({
    icon,
    label,
    hideLabel = false,
    type = 'text',
    required = false,
    error = '',
    onChange,
    className = '',
    iconWrapperClassName = '',
    inputClassName = '',
    ...inputProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={`search-input ${className}`}
        >
            {!hideLabel && <InputLabel text={label} required={required} className="search-input__label" />}

            <div className="search-input__field-wrapper">
                <div className={`search-input__icon-wrapper ${iconWrapperClassName}`}>
                    <Icon
                        name={icon}
                        className="search-input__icon"
                    />
                </div>

                <Input
                    {...inputProps}
                    type={type}
                    required={required}
                    error={error}
                    onChange={handleChange}
                    className={`search-input__field ${inputClassName}`}
                />
            </div>

            {error && <ErrorLabel text={error} className="search-input__error-label" />}
        </label>
    );
};

export default SearchInput;
