import {
    CSSProperties,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
    useMemo,
} from 'react';

import classnames from 'classnames';

import { formatDate, isSameDay } from '../../../../../helpers/date';
import { DateFormat } from '../../../../../types/dateFormatTypes';
import { transformToLeaveOfAbsencesOverviewHeadWeek } from '../../entities/LeaveOfAbsencesOverviewHeadWeek/LeaveOfAbsencesOverviewHeadWeekTransformers';

import './LeaveOfAbsencesOverviewTableHead.scss';

interface LeaveOfAbsenceOverviewTableHeadProps extends RefAttributes<HTMLTableSectionElement> {
    activeDay?: Date;
    days: Date[];
    style?: CSSProperties;
    className?: string;
}

const LeaveOfAbsencesOverviewTableHead: ForwardRefExoticComponent<LeaveOfAbsenceOverviewTableHeadProps> = forwardRef(({
    activeDay,
    days,
    style,
    className = '',
}, ref: Ref<HTMLTableSectionElement>): ReactElement => {
    const weeks = useMemo(() => days.map(day => transformToLeaveOfAbsencesOverviewHeadWeek(day)), [days]);

    return (
        <thead style={style} ref={ref} className={`leave-of-absences-overview-table-head ${className}`}>
            <tr className="leave-of-absences-overview-table-head__weeks-row">
                {weeks.map(week => (
                    <td
                        key={week.id}
                        className="leave-of-absences-overview-table-head__weeks-cell"
                    >
                        <span className="leave-of-absences-overview-table-head__weeks-cell-day-week-wrapper">
                            <span className="leave-of-absences-overview-table-head__weeks-cell-day">
                                {week.date ? formatDate(week.date, DateFormat.day) : undefined}
                            </span>
                            <span className="leave-of-absences-overview-table-head__weeks-cell-week">
                                {week.date ? `WK${formatDate(week.date, DateFormat.weekNumber)}` : undefined}
                            </span>
                        </span>
                    </td>
                ))}
            </tr>
            <tr className="leave-of-absences-overview-table-head__days-row">
                {days.map((day, index) => {
                    const daysCellClassName = classnames('leave-of-absences-overview-table-head__days-cell', {
                        'leave-of-absences-overview-table-head__days-cell--is-active': activeDay && isSameDay(activeDay, day),
                    });

                    return (
                        <td
                            data-day-index={index}
                            key={day.getTime()}
                            className={daysCellClassName}
                        >
                            {formatDate(day, DateFormat.day)}
                        </td>
                    );
                })}
            </tr>
        </thead>
    );
});

export default LeaveOfAbsencesOverviewTableHead;
