import { addHours, startOfWeek } from '../../helpers/date';

export default function getStartOfWeek(
    date: Date,
    hourCorrection = 0,
): Date {
    const justifiedDate = addHours(date, -hourCorrection);

    return addHours(startOfWeek(justifiedDate, { weekStartsOn: 1 }), hourCorrection);
}
