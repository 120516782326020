import { addDays, addSeconds } from './index';
// eslint-disable-next-line import/no-cycle
import UpToButExcludingDate from './UpToButExcludingDate';

/*
    Up to and including date = "tot en met", alle formulieren zijn "tot en met!"
    Zie date-types-diagram.png voor meer uitleg
*/

// Safari fix where datestring can only be in "yyyy-MM-ddTHH:mm:ss" format.
function transformDatetimeStringToDate(datetime: string): Date {
    return new Date(datetime.replace(' ', 'T'));
}

export default class UpToAndIncludingDate {
    dateObject: Date;

    constructor(date?: string | Date | null) {
        if (!date) {
            this.dateObject = new Date();
        } else {
            this.dateObject = typeof date === 'string' ? transformDatetimeStringToDate(date) : date;
        }
    }

    get date(): Date {
        return this.dateObject;
    }

    // Conversie van van "tot en met" naar "tot" object
    transformToUpToButExcludingDate(): UpToButExcludingDate {
        const date = addSeconds(this.dateObject, 1);
        return new UpToButExcludingDate(date);
    }

    // Conversie naar "tot" date voor calls naar API
    transformToAPIDate(): Date {
        return addDays(this.dateObject, 1);
    }

    // Conversie naar "tot" datetime voor calls naar API
    transformToAPIDatetime(): Date {
        return addSeconds(this.dateObject, 1);
    }
}
