import { Breadcrumb } from '../../compositions/Breadcrumbs/Breadcrumbs';
import {
    DropboxEntity,
    DropboxEntityType,
    DropboxFileResource,
    DropboxFolder,
    DropboxFolderResource,
} from './DropboxEntity';

const getIdFromDropboxLink = (link: string): string => link.split('?path=id:')[1];

export const transformToDropboxEntity = (resource: DropboxFileResource): DropboxEntity => ({
    type: resource.type === 'file' ? DropboxEntityType.file : DropboxEntityType.folder,
    id: getIdFromDropboxLink(resource.link),
    name: resource.name,
    path: resource.path,
    link: `${process.env.REACT_APP_API_URL}/${resource.link.split('api/v2/')[1]}`,
    ...(resource.modified && { modifiedAt: new Date(resource.modified) }),
    ...('size' in resource && { size: resource.size }),
    ...(resource.readableSize && { readableSize: resource.readableSize }),
});

export const transformToDropboxFolder = (resource: DropboxFolderResource): DropboxFolder => ({
    type: resource.type,
    id: getIdFromDropboxLink(resource.link),
    name: resource.name,
    path: resource.path,
    levelOfDeepness: resource.path.split('/').length - 1,
    children: resource.children.map(transformToDropboxFolder),
});

const flattenFolders = (folders: DropboxFolder[]): DropboxFolder[] => folders
    .flatMap(folder => [folder, ...flattenFolders(folder.children)]);

const transformDropboxFolderToBreadcrumb = (dropboxFolder: DropboxFolder): Breadcrumb => ({
    value: dropboxFolder.id,
    label: dropboxFolder.name,
});

export const transformDropboxFoldersToBreadcrumbs = (dropboxFolders: DropboxFolder[], id?: string): Breadcrumb[] => {
    const flattenedFolders = flattenFolders(dropboxFolders);
    const match = flattenedFolders.find(folder => folder.id === id);

    if (!match) {
        return [];
    }

    return new Array(match.levelOfDeepness - 1)
        .fill(null)
        .reduce((total: DropboxFolder[], value, index) => {
            if (index === 0) {
                return [match];
            }

            const prevFolder = total[total.length - 1];
            const parent = flattenedFolders
                .find(folder => folder.children
                    .some(child => child.id === prevFolder.id));

            if (!parent) {
                console.error('[transformDropboxFoldersToBreadcrumbs]: Could not find parent of child, breadcrumbs will be incomplete');
                return total;
            }

            return [...total, parent];
        }, [])
        .map(transformDropboxFolderToBreadcrumb)
        .reverse();
};

