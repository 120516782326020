import React, { FC } from 'react';

import './RadioButton.scss';

interface Props {
    id: string;
    checked: boolean;
    label: string;
    onChange?: (id: string, value: boolean) => void;
    onClick?: () => void;
}

const RadioButton: FC<Props> = ({
    label,
    checked,
    id,
    onChange,
    onClick,
}) => (
    <span className={`radio-button ${checked ? 'radio-button--checked' : ''}`}>
        <label
            className="radio-button__label"
            htmlFor="true"
        >
            <input
                checked={checked}
                onChange={() => onChange && onChange(id, !checked)}
                onClick={() => onClick && onClick()}
                className="radio-button__input"
                type="radio"
                name="true"
            />
            <span className="radio-button__text">
                {label}
            </span>
        </label>
    </span>
);

export default RadioButton;
