import {
    FC,
    FormEvent,
    useRef,
    useState,
} from 'react';

import { InputLabel } from '../../../components';
import { DateInput, FormActionButtons } from '../../../compositions';
import { ExportShiftFormData } from '../../../entities/Shift/Shift';
import { formatDate } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { DateFormat } from '../../../types/dateFormatTypes';
import { ModalFormProps } from '../../../types/modalFormTypes';
import { ExportShiftsFormErrors, validateExportsShiftFormData } from './validations/exportShiftsFormValidation';

import './ExportShiftsForm.scss';

interface ExportShiftsFormProps extends ModalFormProps<ExportShiftFormData> {
    startDate: Date;
    endDate: Date;
    onSubmit: (exportShiftFormData: ExportShiftFormData) => void;
}

const ExportShiftsForm: FC<ExportShiftsFormProps> = ({
    isLoading,
    startDate,
    endDate,
    onSubmit,
    onCancel,
}) => {
    const formRef = useRef<HTMLFormElement>(null);
    const [startDateState, setStartDateState] = useState<Date>(startDate);
    const [endDateState, setEndDateState] = useState<Date>(endDate);
    const [formErrors, setFormErrors] = useState<ExportShiftsFormErrors>({});

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formDataToSubmit = {
            startDate: formatDate(startDateState, DateFormat.inputDate),
            endDate: formatDate(endDateState, DateFormat.inputDate),
        };

        const [errors, hasErrors] = validateExportsShiftFormData(formDataToSubmit);
        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formDataToSubmit);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="export-shifts-form">
            <div className="export-shifts-form__row">
                <div className="export-shifts-form__col">
                    <InputLabel
                        text={trans('containers.forms.exportShiftsForms.selectDateRangeForExport')}
                        className="export-shifts-form__col-label"
                    />
                </div>
            </div>
            <div className="export-shifts-form__row">
                <div className="export-shifts-form__col">
                    <DateInput
                        label={trans('common.startDate')}
                        type="date"
                        value={startDateState}
                        error={formErrors.startDate}
                        onChange={setStartDateState}
                    />
                </div>
                <div className="export-shifts-form__col">
                    <DateInput
                        label={trans('common.endDate')}
                        type="date"
                        value={endDateState}
                        error={formErrors.endDate}
                        onChange={setEndDateState}
                    />
                </div>
            </div>
            <FormActionButtons
                isLoading={isLoading}
                submitText={trans('common.export')}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default ExportShiftsForm;
