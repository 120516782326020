import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DepartmentViewModel } from '../../../entities/Departments/Departments';
import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';

export type DepartmentsState = AsyncReduxState<{
    departments: DepartmentViewModel[];
}>;

const initialState: DepartmentsState = {
    ...initialAsyncReduxState,
    departments: [],
};

export const DepartmentsSlice = createSlice({
    name: 'departmentsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DepartmentsState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): DepartmentsState {
            return ({
                ...state,
                error: action.payload,
            });
        },
        setDepartments(state, action: PayloadAction<DepartmentViewModel[]>): DepartmentsState {
            return ({
                ...state,
                departments: action.payload,
            });
        },
    },
});

export const {
    setIsLoading,
    setError,
    setDepartments,
} = DepartmentsSlice.actions;

export default DepartmentsSlice.reducer;
