import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import {
    AbsenceBadge,
    LeaveOfAbsenceBadge,
    PreferToWorkTimeSlotBadge,
    UnavailableToWorkTimeSlotBadge,
} from '../../../components';
import { Absence } from '../../../entities/Absence/Absence';
import { ContractHours } from '../../../entities/ContractHours/ContractHours';
import { getContractHoursLabel } from '../../../entities/ContractHours/ContractHoursHelpers';
import { LeaveOfAbsence } from '../../../entities/LeaveOfAbsence/LeaveOfAbsence';
import { Period } from '../../../entities/Period/Period';
import { PreferToWorkTimeSlot } from '../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { UnavailableToWorkTimeSlot } from '../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';

import './UsersSelectOption.scss';

interface UsersSelectOptionProps {
    isCompanyMedic: boolean;
    absence?: Absence;
    contractHours?: ContractHours[];
    fullName?: string;
    leaveOfAbsence?: LeaveOfAbsence;
    period?: Period;
    plannedHoursInWeek?: number;
    preferToWorkTimeSlot?: PreferToWorkTimeSlot;
    unavailableToWorkTimeSlot?: UnavailableToWorkTimeSlot;
    className?: string;
}

const UsersSelectOption: FC<UsersSelectOptionProps> = ({
    isCompanyMedic,
    absence,
    contractHours,
    fullName,
    leaveOfAbsence,
    period,
    plannedHoursInWeek,
    preferToWorkTimeSlot,
    unavailableToWorkTimeSlot,
    className = '',
}): ReactElement => {
    const wrapperClassName = classNames('users-select-option', {
        'users-select-option--is-company-medic': isCompanyMedic,
    }, className);

    const hours = getContractHoursLabel(contractHours, plannedHoursInWeek, period?.start);

    return (
        <div className={wrapperClassName}>
            <div className="users-select-option__name">
                {fullName}
            </div>

            {absence && (
                <AbsenceBadge absence={absence} className="users-select-option__absence-badge" />
            )}

            {(!absence && leaveOfAbsence) && (
                <LeaveOfAbsenceBadge leaveOfAbsence={leaveOfAbsence} />
            )}

            {unavailableToWorkTimeSlot && (
                <UnavailableToWorkTimeSlotBadge unavailableToWorkTimeSlot={unavailableToWorkTimeSlot} />
            )}

            {preferToWorkTimeSlot && (
                <PreferToWorkTimeSlotBadge preferToWorkTimeSlot={preferToWorkTimeSlot} />
            )}

            {hours !== undefined && (
                <div className="users-select-option__hours">
                    {hours}
                </div>
            )}
        </div>
    );
};

export default UsersSelectOption;
