// forms
import { LogsReducer } from '../../redux/logs-ts/logsModels';
import addUserFormReducer, { AddUserFormReducerState } from './@forms/addUserForm/addUserFormReducer';
import exportLeaveOfAbsencesFormReducer, { ExportLeaveOfAbsencesFormReducerState } from './@forms/exportLeaveOfAbsencesForm/exportLeaveOfAbsencesFormReducer';
import shiftSnapshotFormReducer, { ShiftSnapshotFormState } from './@forms/shiftSnapshotForm/shiftSnapshotReducer';
// interfaces
import calendarReducer, { CalendarState } from './@interface/calendar/calendarReducer';
import globalFiltersReducer, { GlobalFiltersState } from './@interface/globalFilters/globalFiltersReducer';
import helpReducer, { HelpState } from './@interface/help/helpReducer';
import notificationsReducer, { NotificationsState } from './@interface/notifications/notificationsReducer';
import optionsReducer, { OptionsState } from './@interface/options/optionsReducer';
// other
import authenticatedUserReducer, { AuthenticatedUserState } from './authenticatedUser/authenticatedUserReducer';
import pacoBaseScheduleShiftReducer, { BaseScheduleShiftState } from './baseScheduleShift/baseScheduleShiftReducer';
import completePeriodsReducer, { CompletePeriodsState } from './completePeriods/completePeriodsReducer';
import contractHoursReducer, { ContractHoursState } from './contractHours/contractHoursReducer';
import dashboardSettingsReducer, { DashboardState } from './dashboard/dashboardReducer';
import departmentGroupsReducer, { DepartmentGroupsState } from './departmentGroups/departmentGroupsReducer';
import districtDivisionReducer, { DistrictDivisionState } from './districtDivision/districtDivisionReducer';
import districtDivisionDistrictPlanningReducer, { DistrictDivisionDistrictPlanningState } from './districtDivisionDistrictPlanning/districtDivisionDistrictPlanningReducer';
import districtDivisionGroupTemplateReducer, { DistrictDivisionGroupTemplateState } from './districtDivisionGroupTemplate/districtDivisionGroupTemplateReducer';
import districtDivisionTemplateReducer, { DistrictDivisionTemplateState } from './districtDivisionTemplate/districtDivisionTemplateReducer';
import districtDivisionTemplatesReducer, { DistrictDivisionTemplatesState } from './districtDivisionTemplates/districtDivisionTemplatesReducer';
import divergentPostEmploymentHourReducer, { DivergentPostEmploymentHourState } from './divergentPostEmploymentHour/divergentPostEmploymentHourReducer';
import divergentPreEmploymentHourReducer, { DivergentPreEmploymentHourState } from './divergentPreEmploymentHour/divergentPreEmploymentHourReducer';
import dropboxReducer, { DropboxState } from './dropbox/dropboxReducer';
import employmentTypesReducer, { EmploymentTypesState } from './employmentTypes/employmentTypesReducer';
import eventLogsReducer, { EventLogsState } from './eventLogs/eventLogsReducer';
import experiencesReducer, { ExperiencesState } from './experiences/experiencesReducer';
import pacoLeaveOfAbsencesReducer, { LeaveOfAbsencesState } from './leaveOfAbsences/leaveOfAbsencesReducer';
import leaveOfAbsencesOverviewReducer, { LeaveOfAbsencesOverviewState } from './leaveOfAbsencesOverview/leaveOfAbsencesOverviewReducer';
import pacoPayrollPeriodsReducer, { PayrollPeriodsState } from './payrollPeriods/payrollPeriodsReducer';
import pzLogCategoriesReducer, { PzLogCategoriesState } from './pzLogCategories/pzLogCategoriesReducer';
import pzLogsReducer, { PzLogsState } from './pzLogs/pzLogsReducer';
import rolesReducer, { RolesState } from './roles/rolesReducer';
import pacoSettingsReducer, { SettingsState } from './settings/settingsReducer';
import shiftReducer, { ShiftState } from './shift/shiftReducer';
import pacoShiftConceptReducer, { ShiftConceptState } from './shiftConcept/shiftConceptReducer';
import shiftConceptPlanningReducer, { ShiftConceptPlanningState } from './shiftConceptPlanning/shiftConceptPlanningReducer';
import shiftDetailReducer, { ShiftDetailState } from './shiftDetail/shiftDetailReducer';
import shiftPlanningReducer, { ShiftPlanningState } from './shiftPlanning/shiftPlanningReducer';
import shiftsCalendarReducer, { ShiftsCalendarState } from './shiftsCalendar/shiftsCalendarReducer';
import pacoSpecialDaysReducer, { SpecialDaysState } from './specialDays/specialDaysReducer';
import temporaryWorkerReducer, { TemporaryWorkerState } from './temporaryWorker/temporaryWorkerReducer';
import toastsReducer, { ToastsState } from './toasts/toastsReducer';
import pacoUserReducer, { UserState } from './user/userReducer';
import pacoUsersReducer, { UsersState } from './users/usersReducer';
import usersOverviewReducer, { UsersOverviewState } from './usersOverview/usersOverviewReducer';
import usersWithDivergentEmploymentHoursReducer, { UsersWithDivergentEmploymentHoursState } from './usersWithDivergentEmploymentHours/usersWithDivergentEmploymentHoursReducer';
import workweekUsersReducer, { WorkweekUsersState } from './workweekUsers/workweekUsersReducer';
import yearEndStatementReducer, { YearEndStatementsState } from './yearEndStatement/yearEndStatementReducer';

export interface Reducers {
    addUserFormReducer: AddUserFormReducerState;
    authenticatedUserReducer: AuthenticatedUserState;
    calendarReducer: CalendarState;
    completePeriodsReducer: CompletePeriodsState;
    contractHoursReducer: ContractHoursState;
    dashboardSettingsReducer: DashboardState;
    departmentGroupsReducer: DepartmentGroupsState;
    districtDivisionDistrictPlanningReducer: DistrictDivisionDistrictPlanningState;
    districtDivisionGroupTemplateReducer: DistrictDivisionGroupTemplateState;
    districtDivisionReducer: DistrictDivisionState;
    districtDivisionTemplateReducer: DistrictDivisionTemplateState;
    districtDivisionTemplatesReducer: DistrictDivisionTemplatesState;
    divergentPostEmploymentHourReducer: DivergentPostEmploymentHourState;
    divergentPreEmploymentHourReducer: DivergentPreEmploymentHourState;
    dropboxReducer: DropboxState;
    employmentTypesReducer: EmploymentTypesState;
    eventLogsReducer: EventLogsState;
    experiencesReducer: ExperiencesState;
    exportLeaveOfAbsencesFormReducer: ExportLeaveOfAbsencesFormReducerState;
    globalFiltersReducer: GlobalFiltersState;
    helpReducer: HelpState;
    leaveOfAbsencesOverviewReducer: LeaveOfAbsencesOverviewState;
    logsReducer: LogsReducer;
    notificationsReducer: NotificationsState;
    optionsReducer: OptionsState;
    pacoBaseScheduleShiftReducer: BaseScheduleShiftState;
    pacoLeaveOfAbsencesReducer: LeaveOfAbsencesState;
    pacoPayrollPeriodsReducer: PayrollPeriodsState;
    pacoSettingsReducer: SettingsState;
    pacoShiftConceptReducer: ShiftConceptState;
    pacoSpecialDaysReducer: SpecialDaysState;
    pacoUserReducer: UserState;
    pacoUsersOverviewReducer: UsersOverviewState;
    pacoUsersReducer: UsersState;
    pzLogCategoriesReducer: PzLogCategoriesState;
    pzLogsReducer: PzLogsState;
    rolesReducer: RolesState;
    shiftConceptPlanningReducer: ShiftConceptPlanningState;
    shiftDetailReducer: ShiftDetailState;
    shiftPlanningReducer: ShiftPlanningState;
    shiftReducer: ShiftState;
    shiftsCalendarReducer: ShiftsCalendarState;
    shiftSnapshotFormReducer: ShiftSnapshotFormState;
    temporaryWorkerReducer: TemporaryWorkerState;
    toastsReducer: ToastsState;
    usersWithDivergentEmploymentHoursReducer: UsersWithDivergentEmploymentHoursState;
    workweekUsersReducer: WorkweekUsersState;
    yearEndStatementReducer: YearEndStatementsState;
}

export default {
    addUserFormReducer,
    authenticatedUserReducer,
    calendarReducer,
    completePeriodsReducer,
    contractHoursReducer,
    dashboardSettingsReducer,
    departmentGroupsReducer,
    districtDivisionDistrictPlanningReducer,
    districtDivisionGroupTemplateReducer,
    districtDivisionReducer,
    districtDivisionTemplateReducer,
    districtDivisionTemplatesReducer,
    divergentPostEmploymentHourReducer,
    divergentPreEmploymentHourReducer,
    dropboxReducer,
    employmentTypesReducer,
    eventLogsReducer,
    experiencesReducer,
    exportLeaveOfAbsencesFormReducer,
    globalFiltersReducer,
    helpReducer,
    leaveOfAbsencesOverviewReducer,
    notificationsReducer,
    optionsReducer,
    pacoBaseScheduleShiftReducer,
    pacoLeaveOfAbsencesReducer,
    pacoSettingsReducer,
    pacoShiftConceptReducer,
    pacoSpecialDaysReducer,
    pacoPayrollPeriodsReducer,
    pacoUserReducer,
    usersOverviewReducer,
    pacoUsersReducer,
    pzLogCategoriesReducer,
    pzLogsReducer,
    rolesReducer,
    shiftConceptPlanningReducer,
    shiftDetailReducer,
    shiftPlanningReducer,
    shiftReducer,
    shiftsCalendarReducer,
    shiftSnapshotFormReducer,
    temporaryWorkerReducer,
    toastsReducer,
    usersWithDivergentEmploymentHoursReducer,
    workweekUsersReducer,
    yearEndStatementReducer,
};
