import { getEmptyContractFormData } from '../../../../entities/ContractHours/ContractHoursHelpers';
import { transformContractHoursToContractFormData } from '../../../../entities/ContractHours/ContractHoursTransformers';
import { EmploymentTypeSlug } from '../../../../entities/EmploymentType/EmploymentType';
import { transformCompanyMedicToNumber } from '../../../../entities/Person/PersonTransformers';
import { EditUserFormData, FullUser } from '../../../../entities/User/User';
import { addYears } from '../../../../helpers/date';
import { GenderType } from '../../../../types';

const setDivergentPostEmploymentHoursFormData = (user: FullUser): string | undefined => {
    if (user.employment?.divergentPostEmploymentHour?.contractHours) {
        return user.employment?.divergentPostEmploymentHour?.contractHours.toString();
    }

    if (user.employmentType.slug === EmploymentTypeSlug.partTime) {
        return '0';
    }

    return undefined;
};

export const generateEditUserFormData = (user: FullUser): EditUserFormData => ({
    id: user.id,
    personalInfo: {
        employeeNumber: user.employeeNumber,
        email: user.email || '',
        phoneNumber: user.phone || '',
        firstname: user.person.firstname || '',
        affix: user.person.affix || '',
        lastname: user.person.lastname || '',
        birthday: user.person.birthday || new Date('2000-01-01'),
        gender: user.person.gender || GenderType.unknown,
    },
    person: {
        id: user.person.id,
        hasSundayAllowance: user.person.hasSundayAllowance || false,
        companyMedic: transformCompanyMedicToNumber(user.person.companyMedic),
        workdays: user.person.workdays.map(workday => workday.toString()) || [],
        identificationType: user.person.identificationType || '',
        identificationExpirationDate: user.person.identificationExpirationDate || addYears(new Date(), 5),
        nationality: user.person.nationality || '',
    },
    mainDepartment: user.mainDepartment?.id || '',
    experienceId: user.experience?.id || '',
    employmentTypeId: user.employmentType.id || '',
    roleId: user.role.id || '',
    contractHours: user.contractHours.length ? user.contractHours.map(transformContractHoursToContractFormData) : [getEmptyContractFormData(user.employment?.start)],
    shouldTrackTime: user.shouldTrackTime || true,
    isPaidBasedOnTrackedTime: user.isPaidBasedOnTrackedTime || false,
    isEligibleForTimeForTime: user.isEligibleForTimeForTime || false,
    employmentDates: {
        endDate: user.employment?.end,
        startDate: user.employment?.start,
        divergentPostEmploymentHours: setDivergentPostEmploymentHoursFormData(user),
        divergentPreEmploymentHours: user.employment?.divergentPreEmploymentHour?.contractHours.toString() || '0',
    },
});
