import { addHours, formatDate } from '../../../../../helpers/date';
import { EditShiftPlanningFormData } from '../../../../../models';

export default function generateEmptyEditShiftPlanningFormDataValues(): EditShiftPlanningFormData {
    return {
        startTime: formatDate(new Date(), 'HH:00'),
        endTime: formatDate(addHours(new Date(), 6), 'HH:00'),
        comment: '',
    };
}
