import {
    DocumentFormData,
    DocumentViewModel,
    Role,
} from '../../../../../models';

export function getRolesStringArray(userTypes: Role[]): string[] {
    return userTypes.map(userType => (userType.id));
}

export default function transformDocumentToFormData(document: DocumentViewModel)
    : DocumentFormData {
    return {
        id: document.id,
        name: document.displayName,
        downloadUrl: document.downloadUrl,
        documentType: document.documentType.id,
        departments: document.departments,
        roles: getRolesStringArray(document.userTypes),
        willNotifyUsersAfterSuccess: false,
    };
}

