import React, { FC } from 'react';

import Tooltip from 'react-tooltip-lite';

import { translate } from '../../../helpers/translations/translator';

import './ExclamationMarkBadge.scss';

interface ExclamationMarkBadgeProps {
    className?: string;
}

const ExclamationMarkBadge: FC<ExclamationMarkBadgeProps> = ({ className = '' }) => {
    const tooltip = (
        <>
            <strong>
                {`${translate('common.lookOut')}! `}
            </strong>
            {translate('pages.tracks.trackHasDivergentTimes')}
        </>
    );

    return (
        // @ts-ignore
        <Tooltip content={tooltip} arrowSize={6} padding={7}>
            <span
                id="exclamation-mark-badge"
                className={`exclamation-mark-badge ${className}`}
            >
                <span className="exclamation-mark-badge__icon">
                    !
                </span>
            </span>
        </Tooltip>
    );
};

export default ExclamationMarkBadge;
