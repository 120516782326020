import { generateEventPath } from './browser';

const closeModal = () => {
    if (!document.body.classList.contains('modal-open')) {
        return null;
    }

    const button = document.getElementById('modal-close');
    return button && button.click();
};

const doesAnyPathMatchModalContent = (paths) => paths.some(path => {
    const className = path?.className;

    if (!className || typeof className !== 'string') {
        return false;
    }

    return [
        'modal-content',
        'select__indicator',
        'select-overwrite__clear-indicator',
    ].some(classNamePart => className.indexOf(classNamePart) !== -1);
});

export const addModelCloseListeners = () => {
    document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            closeModal();
        }
    });

    document.addEventListener('mousedown', (e) => {
        const paths = generateEventPath(e.target);

        if (doesAnyPathMatchModalContent(paths)) {
            return null;
        }

        return closeModal();
    });
};
