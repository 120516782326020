import { useEffect, useState } from 'react';

import { isEqual, isValid } from '../../@paco/helpers/date';
import { Absence } from '../../models';
import getAbsencesForEmployeeInLast4Weeks from '../../pages/Absences/helpers/getAbsencesForEmployeeInLast4Weeks';

function useUserHasWaitingDayHours(data: Absence, dateString: string): [boolean, boolean] {
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [isWaitingDayHoursLoading, setIsWaitingDayHoursLoading] = useState(true);
    const [userHasWaitingDayHours, setUserHasWaitingDayHours] = useState(false);

    async function isWaitingDaysHoursEnabled(userId: string, date: Date) {
        const absences = await getAbsencesForEmployeeInLast4Weeks(userId, date, data.id);
        setIsWaitingDayHoursLoading(false);
        setUserHasWaitingDayHours(!absences.length);
    }

    useEffect(() => {
        if (data && startDate) {
            setIsWaitingDayHoursLoading(true);
            isWaitingDaysHoursEnabled(data.user.id, startDate);
        }
    }, [startDate]);

    useEffect(() => {
        const dateStringIsValid = isValid(new Date(dateString));
        const newStartDate = new Date(dateStringIsValid ? dateString : data.start);
        if (!startDate || !isEqual(startDate, newStartDate)) {
            setStartDate(newStartDate);
        }
    }, [data, dateString]);

    return [userHasWaitingDayHours, isWaitingDayHoursLoading];
}

export default useUserHasWaitingDayHours;
