import React, { FC, ReactElement } from 'react';

import { useSelector } from 'react-redux';

import { Reducers } from '../../redux/reducers';
import ConnectedConceptsListView from './connectors/ConnectedConceptsListView/ConnectedConceptsListView';
import ConnectedListView from './connectors/ConnectedListView/ConnectedListView';

const ListViewAndConcepts: FC = (): ReactElement => {
    const { isConceptMode } = useSelector((state: Reducers) => state.appReducer);

    if (isConceptMode) {
        return <ConnectedConceptsListView />;
    }

    return <ConnectedListView />;
};

export default ListViewAndConcepts;
