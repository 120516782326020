import trans from '../../@paco/helpers/trans';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { formatDate } from '../../helpers/date';
import { PreferToWorkTimeSlot } from '../../models';

export const getPreferToWorkTimeSlotComment = (preferToWorkTimeSlot: PreferToWorkTimeSlot): string => {
    const createDate = `${trans('common.createdAt')} ${formatDate(preferToWorkTimeSlot.createdAt, DateFormat.date)}`;

    return `${preferToWorkTimeSlot.comment} (${createDate})`;
};
