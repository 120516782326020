import { authorizedFetch } from '../../helpers/authorizedFetch';
import { formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../../japi/types/Document';
import { DateFormat } from '../../types/dateFormatTypes';
import { EmploymentTypeSlug } from '../EmploymentType/EmploymentType';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { Period } from '../Period/Period';
import { UserResource } from '../User/User';
import { transformToUser } from '../User/UserTransformers';
import { Absence, AbsenceResource } from './Absence';
import { transformToAbsence } from './AbsenceTransformers';

export interface GetAbsenceCallApiCallProps {
    period: Period;
    departments?: string[];
    employeeSearch?: string;
    employmentTypeSlugs?: EmploymentTypeSlug[];
}

export const getAbsencesApiCall = async (filters: GetAbsenceCallApiCallProps): Promise<FetchCollectionResult<Absence[], string>> => {
    try {
        const { period, departments } = filters;
        const includes = ['user'];

        const url = generateApiUrl({
            endpoint: '/absences',
            queryParams: {
                include: includes.join(','),
                ...((departments && departments.length) && { 'departments[]': departments.join('departments[]=') }),
                startDate: formatDate(period.start, DateFormat.apiDateTime),
                endDate: formatDate(period.end, DateFormat.apiDateTime),
            },
        });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<AbsenceResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const absences = doc.data.map(absenceResource => {
            const userResource = getIncluded(doc, absenceResource, 'user') as UserResource;
            const user = transformToUser(userResource);

            return transformToAbsence(
                absenceResource,
                user,
            );
        });

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: absences,
        };
    } catch (error) {
        console.error('[getAbsencesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
