import React from 'react';

import PropTypes from 'prop-types';
import {
    Button, ModalBody,
    ModalFooter, ModalHeader,
} from 'reactstrap';

import { translate } from '../../../helpers/translations/translator';

import './Forms.scss';

const LinkUsersToLoket = ({ onCancel, onSubmit }) => (
    <div>
        <ModalHeader>
            {translate('common.loket')}
            {' '}
            {translate('common.link').toLowerCase()}
        </ModalHeader>
        <ModalBody>
            <p>
                {translate('pages.management.linkUsersToLoketModalIntro')}
            </p>
        </ModalBody>
        <ModalFooter>
            <Button type="button" color="link" id="modal-close" onClick={onCancel}>{translate('common.cancel')}</Button>
            <Button type="submit" color="orange" onClick={onSubmit}>{translate('common.continue')}</Button>
        </ModalFooter>
    </div>
);

LinkUsersToLoket.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default LinkUsersToLoket;
