import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../../models';

const IconInfo: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 18 18" fill="none" className={`icon-svg ${className}`}>
        <g transform="translate(-589.000000, -390.000000)" fillRule="nonzero">
            {/* eslint-disable-next-line max-len */}
            <path d="M598,390 C593.032,390 589,394.032 589,399 C589,403.968 593.032,408 598,408 C602.968,408 607,403.968 607,399 C607,394.032 602.968,390 598,390 Z M598,403.5 C597.505,403.5 597.1,403.095 597.1,402.6 L597.1,399 C597.1,398.505 597.505,398.1 598,398.1 C598.495,398.1 598.9,398.505 598.9,399 L598.9,402.6 C598.9,403.095 598.495,403.5 598,403.5 Z M598.9,396.3 L597.1,396.3 L597.1,394.5 L598.9,394.5 L598.9,396.3 Z" />
        </g>
    </svg>
);

export default IconInfo;
