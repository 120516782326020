import { FC, ReactElement } from 'react';

import { InputLabel } from '../../../components';
import { CheckboxList } from '../../../compositions';
import trans from '../../../helpers/trans';
import { FormOption } from '../../../types';

interface RoleFilterContainerProps {
    roleOptions: FormOption[];
    onChange: (selectedOptions: FormOption[]) => void;
    className?: string;
}

const RoleFilterContainer: FC<RoleFilterContainerProps> = ({
    roleOptions,
    onChange,
    className = '',
}): ReactElement => {
    const selectedRoleOptions = roleOptions.filter(roleOption => roleOption.isChecked);

    const handleOptionsChange = (checkedOptionValues: string[]): void => {
        const changedOptions = roleOptions.map(option => ({
            ...option,
            isChecked: checkedOptionValues.some(checkedOptionValue => checkedOptionValue === option.value),
        }));
        onChange(changedOptions);
    };

    return (
        <div className={`roles-filter-container ${className}`}>
            <InputLabel text={trans('common.role')} />
            <CheckboxList
                hideLabel
                label={trans('common.role')}
                name={trans('common.role')}
                options={roleOptions}
                value={selectedRoleOptions.map(option => option.value)}
                onChange={handleOptionsChange}
            />
        </div>
    );
};

export default RoleFilterContainer;
