import { doesAbsenceOverlapWithPeriod, getAbsenceTooltipText } from '../../../../entities/Absence/AbsenceHelpers';
import { Comment } from '../../../../entities/Comment/Comment';
import { getLeaveOfAbsenceTooltipText } from '../../../../entities/LeaveOfAbsence/LeaveOfAbsenceHelper';
import { doPeriodsOverlap } from '../../../../entities/Period/PeriodHelpers';
import { ShiftPlanning } from '../../../../entities/ShiftPlanning/ShiftPlanning';
import { ShiftsCalendarAbsence } from '../../../../entities/ShiftsCalendarAbsence/ShiftsCalendarAbsence';
import { ShiftsCalendarLeaveOfAbsence } from '../../../../entities/ShiftsCalendarLeaveOfAbsence/ShiftsCalendarLeaveOfAbsence';
import { ShiftsCalendarUnavailableToWorkTimeSlot } from '../../../../entities/ShiftsCalendarUnavailableToWorkTimeSlot/ShiftsCalendarUnavailableToWorkTimeSlot';
import { doesUnavailableToWorkTimeSlotOverlapWithPeriod, getUnavailableToWorkTimeSlotTooltipText } from '../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotHelpers';
import { LeaveOfAbsenceStatus } from '../../../../types';
import { ShiftEmployeeIconType } from '../../ShiftEmployeeIcon/ShiftEmployeeIcon';

export const getCommentIcon = (comments: Comment[]): ShiftEmployeeIconType[] => {
    const icons: ShiftEmployeeIconType[] = [];

    if (comments.length && comments[comments.length - 1].body) {
        icons.push({
            iconName: 'calendar-comment',
            tooltipText: comments[comments.length - 1].body,
        });
    }

    return icons;
};

export const getShiftEmployeeIcons = (
    shiftPlanning: ShiftPlanning,
    absences: ShiftsCalendarAbsence[],
    leaveOfAbsences: ShiftsCalendarLeaveOfAbsence[],
    unavailableToWorkTimeSlots: ShiftsCalendarUnavailableToWorkTimeSlot[],
): ShiftEmployeeIconType[] => {
    const absenceMatch = absences
        .find(absence => absence.userId === shiftPlanning.user.id && doesAbsenceOverlapWithPeriod(absence, shiftPlanning.period));
    const leaveOfAbsenceMatch = leaveOfAbsences
        .find(leaveOfAbsence => leaveOfAbsence.userId === shiftPlanning.user.id && doPeriodsOverlap(leaveOfAbsence.period, shiftPlanning.period));
    const approvedLeaveOfAbsenceMatch = leaveOfAbsenceMatch?.status === LeaveOfAbsenceStatus.approved ? leaveOfAbsenceMatch : undefined;
    const openLeaveOfAbsenceMatch = leaveOfAbsenceMatch?.status === LeaveOfAbsenceStatus.open ? leaveOfAbsenceMatch : undefined;
    const unavailableToWorkTimeSlotMatch = unavailableToWorkTimeSlots
        .find(unavailableToWorkTimeSlot => unavailableToWorkTimeSlot.userId === shiftPlanning.user.id
            && doesUnavailableToWorkTimeSlotOverlapWithPeriod(unavailableToWorkTimeSlot, shiftPlanning.period));

    const icons: ShiftEmployeeIconType[] = [];

    if (absenceMatch) {
        icons.push({
            iconName: 'medical-bag',
            tooltipText: getAbsenceTooltipText(absenceMatch),
        });
    }

    if (approvedLeaveOfAbsenceMatch) {
        icons.push({
            iconName: 'calendar-check',
            tooltipText: getLeaveOfAbsenceTooltipText(approvedLeaveOfAbsenceMatch),
        });
    }

    if (openLeaveOfAbsenceMatch) {
        icons.push({
            iconName: 'calendar-question',
            tooltipText: getLeaveOfAbsenceTooltipText(openLeaveOfAbsenceMatch, true),
        });
    }

    if (unavailableToWorkTimeSlotMatch) {
        icons.push({
            iconName: 'calendar-warning',
            tooltipText: getUnavailableToWorkTimeSlotTooltipText(unavailableToWorkTimeSlotMatch),
        });
    }

    if (shiftPlanning.comments.length && shiftPlanning.comments[shiftPlanning.comments.length - 1].body) {
        icons.push({
            iconName: 'calendar-comment',
            tooltipText: shiftPlanning.comments[shiftPlanning.comments.length - 1].body,
        });
    }

    return icons.slice(0, 3);
};
