import {
    FC,
    FormEvent,
    useRef,
    useState,
} from 'react';

import { Checkbox } from '../../../components';
import { FormActionButtons } from '../../../compositions';
import { CopyShiftFormData } from '../../../entities/Shift/Shift';
import trans from '../../../helpers/trans';
import { ModalFormProps } from '../../../types/modalFormTypes';

import './CopyShiftForm.scss';

interface CopyShiftFormProps extends ModalFormProps<CopyShiftFormData> {
    onSubmit: (formData: CopyShiftFormData) => void;
}

const CopyShiftForm: FC<CopyShiftFormProps> = ({
    isLoading,
    onSubmit,
    onCancel,
}) => {
    const [copyEmployees, setCopyEmployees] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);

    const handleCopyEmployeesCheckboxChange = (isChecked: boolean): void => {
        setCopyEmployees(isChecked);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        onSubmit({ copyEmployees });
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="copy-shift-form">
            <Checkbox
                label={trans('containers.forms.copyShiftForm.copyEmployees')}
                checked={copyEmployees}
                onChange={handleCopyEmployeesCheckboxChange}
                className="copy-shift-form__check"
            />
            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default CopyShiftForm;
