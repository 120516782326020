import React, { FC } from 'react';

import ConnectedUsersWithHourBalancesOverview
    from './connectors/ConnectedUsersWithHourBalancesOverview/ConnectedUsersWithHourBalancesOverview';

const UsersWithLoketBalancesOverviewPage: FC = () => (
    <ConnectedUsersWithHourBalancesOverview />
);

export default UsersWithLoketBalancesOverviewPage;
