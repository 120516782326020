import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { UserWithConceptAvailability } from '../../../../../../models';
import ConceptAvailability from '../../../ConceptAvailability/ConceptAvailability';
import { ResourceTypes } from '../../ConceptsListViewTable';
import { getOverlappingResourcesOnDate } from './helpers/transformResources';

interface UserConceptAvailabilityOnDateProps {
    loading: boolean;
    user: UserWithConceptAvailability;
    day: Date;
    onAvailabilityClick: (resources: ResourceTypes) => void;
}

const UserConceptAvailabilityOnDate: FC<UserConceptAvailabilityOnDateProps> = ({
    loading,
    user,
    day,
    onAvailabilityClick,
}): ReactElement => {
    const [resourcesState, setResourcesState] = useState<ResourceTypes[]>([]);

    useEffect(() => {
        if (!loading) {
            setResourcesState(getOverlappingResourcesOnDate(
                user.leaveOfAbsences,
                user.absences,
                user.preferToWorkTimeSlots,
                user.unavailableToWorkTimeSlots,
                day,
                user.shiftConceptPlannings,
                user.shiftPlannings,
            ));
        }
    }, [user, day, loading]);

    return (
        <>
            {
                resourcesState.map(resource => (
                    <ConceptAvailability
                        showDescription
                        key={resource.id}
                        resource={resource}
                        onClick={onAvailabilityClick}
                    />
                ))
            }
        </>
    );
};

export default UserConceptAvailabilityOnDate;
