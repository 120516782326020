import { FC, useEffect } from 'react';

import ErrorPage from '../../../../pages/ErrorPage';
import EditDistrictDivisionGroupTemplateForm from '../../../containers/@forms/EditDistrictDivisionGroupTemplateForm/EditDistrictDivisionGroupTemplateForm';
import { DistrictDivisionGroupTemplate, EditDistrictDivisionGroupTemplateFormData } from '../../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import trans from '../../../helpers/trans';
import { editDistrictDivisionGroupTemplate } from '../../../redux/districtDivisionGroupTemplate/districtDivisionGroupTemplateActions';
import { setIsSuccessful } from '../../../redux/districtDivisionGroupTemplate/districtDivisionGroupTemplateReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedEditDistrictDivisionGroupTemplateFormProps {
    districtDivisionGroupTemplate: DistrictDivisionGroupTemplate;
    onClose: () => void;
}

const ConnectedEditDistrictDivisionGroupTemplateForm: FC<ConnectedEditDistrictDivisionGroupTemplateFormProps> = ({
    districtDivisionGroupTemplate,
    onClose,
}) => {
    const dispatch = useTypedDispatch();
    const { isLoading, isSuccessful } = useTypedSelector(state => state.districtDivisionGroupTemplateReducer);
    const { districtDivisionTemplate } = useTypedSelector(state => state.districtDivisionTemplateReducer);

    const handleEditDistrictDivisionGroupTemplate = (editDistrictDivisionGroupTemplateFormData: EditDistrictDivisionGroupTemplateFormData): void => {
        dispatch(editDistrictDivisionGroupTemplate(editDistrictDivisionGroupTemplateFormData));
    };

    useEffect(() => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.editDistrictDivisionGroupTemplateForm.editDistrictDivisionGroupTemplateSuccess'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isSuccessful]);

    if (!districtDivisionTemplate) {
        return (
            <ErrorPage errorCode={404} />
        );
    }

    return (
        <EditDistrictDivisionGroupTemplateForm
            isLoading={isLoading}
            districtDivisionGroupTemplate={districtDivisionGroupTemplate}
            districtDivisionTemplateId={districtDivisionTemplate.id}
            onSubmit={handleEditDistrictDivisionGroupTemplate}
            onCancel={onClose}
        />
    );
};

export default ConnectedEditDistrictDivisionGroupTemplateForm;
