import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';

import { getTableItemAttribute } from '../helpers/getTableItemAttribute';
import { TableAttribute } from './TableHead';
import { TableRowItem } from './TableRow';


interface TableColumnProps<I> {
    item: TableRowItem<I>,
    attribute: TableAttribute<I>,
    index: number;
    onClick?: () => void;
}

const TableColumn = <I, >({
    item,
    attribute,
    index,
    onClick,
}: PropsWithChildren<TableColumnProps<I>>) => {
    const tdClassNames = classnames({
        [`text-${attribute.align}`]: attribute.align,
    }, attribute.className);

    const handleClick = (): void => {
        if (onClick) {
            onClick();
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
        <td
            key={`table_column.${item.id}.${index + 1}`}
            onClick={handleClick}
            className={tdClassNames}
        >
            {attribute.customAction
                ? attribute.customAction(item)
                : getTableItemAttribute(item, attribute.key || '')}
        </td>
    );
};

export default TableColumn;
