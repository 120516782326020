import { DivergentPostEmploymentHour } from '../../entities/DivergentPostEmploymentHour/DivergentPostEmploymentHour';
import { DivergentPreEmploymentHour } from '../../entities/DivergentPreEmploymentHour/DivergentPreEmploymentHour';
import { Employment } from '../../entities/Employment/Employment';
import { UserWithEmployment } from '../../entities/User/User';

export const getUserWithDivergentPostEmploymentHour = (user: UserWithEmployment, divergentPostEmploymentHour: DivergentPostEmploymentHour): UserWithEmployment => {
    const employment: Employment = {
        ...user.employment,
        divergentPostEmploymentHour,
    };

    return {
        ...user,
        employment,
    };
};

export const getUserWithDivergentPreEmploymentHour = (user: UserWithEmployment, divergentPreEmploymentHour: DivergentPreEmploymentHour): UserWithEmployment => {
    const employment: Employment = {
        ...user.employment,
        divergentPreEmploymentHour,
    };

    return {
        ...user,
        employment,
    };
};
