import { FC, useEffect } from 'react';

import { useEffectOnce } from 'react-use';

import AddShiftForm from '../../../containers/@forms/AddShiftForm/AddShiftForm';
import { AddShiftFormData } from '../../../entities/Shift/Shift';
import trans from '../../../helpers/trans';
import { fetchDepartmentOptions } from '../../../redux/@interface/options/optionsActions';
import { addShift } from '../../../redux/shift/shiftActions';
import { setIsSuccessful } from '../../../redux/shift/shiftReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedAddShiftFormProps {
    defaultDate?: Date;
    onClose: () => void;
}

const ConnectedAddShiftForm: FC<ConnectedAddShiftFormProps> = ({
    defaultDate = new Date(),
    onClose,
}) => {
    const dispatch = useTypedDispatch();
    const { isLoading, isSuccessful } = useTypedSelector(state => state.shiftReducer);
    const { departmentOptions } = useTypedSelector(state => state.optionsReducer);

    useEffectOnce(() => {
        dispatch(fetchDepartmentOptions());
    });

    const handleAddShift = (shiftFormData: AddShiftFormData) => {
        dispatch(addShift(shiftFormData));
    };

    useEffect(() => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.addShiftForm.addShiftSuccess'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isSuccessful]);

    return (
        <AddShiftForm
            isLoading={isLoading}
            defaultDate={defaultDate}
            departments={departmentOptions}
            onSubmit={handleAddShift}
            onCancel={onClose}
        />
    );
};

export default ConnectedAddShiftForm;
