import React, {
    FC,
    useMemo,
    useState,
} from 'react';

import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    Row,
} from 'reactstrap';

import FormDatePicker from '../../../../components/FormDatePicker/FormDatePicker';
import ModalHeader from '../../../../components/ModelHeader/ModalHeader';
import { BaseScheduleIndexViewModel } from '../../../../entities/BaseSchedule/BaseSchedule';
import { PublishBaseScheduleFormData } from '../../../../entities/ShiftConcept/ShiftConcept';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import generateEmptyPublishBaseScheduleFormDataValues from './helpers/generateEmptyPublishBaseScheduleFormDataValues';
import transformBaseSchedulesToOptions from './helpers/transformBaseSchedulesToOptions';
import validateFormData from './helpers/validateFormData';


interface PublishShiftConceptsFormProps {
    onCancel: () => void;
    onSubmit: (data: PublishBaseScheduleFormData) => void;
    baseSchedules: BaseScheduleIndexViewModel[];
    selectedStartDate: Date;
    selectedEndDate: Date;
    className?: string;
}

const PublishBaseScheduleForm: FC<PublishShiftConceptsFormProps> = ({
    onCancel,
    onSubmit,
    baseSchedules,
    selectedStartDate,
    selectedEndDate,
    className = '',
}) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState<PublishBaseScheduleFormData>(generateEmptyPublishBaseScheduleFormDataValues(
        selectedStartDate,
        selectedEndDate,
        baseSchedules,
    ));

    const validationErrors = useMemo(
        () => validateFormData(formData, isSubmitted),
        [formData, isSubmitted],
    );

    const options = useMemo(() => transformBaseSchedulesToOptions(baseSchedules), [baseSchedules]);

    const canSubmit = !Object.keys(validationErrors).length;

    const onBaseScheduleChange = (e: React.FormEvent<HTMLSelectElement>) => {
        setFormData({
            ...formData,
            baseScheduleId: e.currentTarget.value,
        });
    };

    const handleChangeStartDate = (startDate: Date) => {
        setFormData({
            ...formData,
            startDate,
        });
    };

    const handleChangeEndDate = (endDate: Date) => {
        setFormData({
            ...formData,
            endDate,
        });
    };

    const handleSubmitButtonClick = () => {
        setIsSubmitted(true);

        if (!Object.keys(validateFormData(formData)).length && onSubmit) {
            onSubmit({
                ...formData,
                startDate: getStartOfWorkDay(formData.startDate),
                endDate: getEndOfWorkDay(formData.endDate),
            });
        }
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    return (
        <form className={`publish-base-schedule-form ${className}`} onSubmit={handleFormSubmit}>
            <ModalHeader
                className="publish-base-schedule-form__modal-header"
                title={translate('pages.shifts.addBaseSchedule')}
            />
            <ModalBody>
                <Row>
                    <Col className="publish-shift-concepts-form__intro">
                        {translate('pages.shifts.whichBaseScheduleDoYouWantToPublish')}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.name')}</Label>
                            <Input
                                className="custom-select"
                                type="select"
                                value={formData.baseScheduleId}
                                onChange={onBaseScheduleChange}
                                invalid={validationErrors.baseSchedule}
                            >
                                {options}
                            </Input>
                            <FormFeedback>
                                {validationErrors.baseSchedule}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="publish-shift-concepts-form__row-dates">
                    <Col>
                        <FormGroup>
                            <FormGroup>
                                <Label>{translate('common.startDate')}</Label>
                                <FormDatePicker
                                    selected={formData.startDate}
                                    invalid={!!validationErrors.date}
                                    onChange={handleChangeStartDate}
                                />
                            </FormGroup>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <FormGroup>
                                <Label>{translate('common.endDate')}</Label>
                                <FormDatePicker
                                    selected={formData.endDate}
                                    invalid={!!validationErrors.date}
                                    onChange={handleChangeEndDate}
                                    feedback={validationErrors.date}
                                />
                            </FormGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="link"
                    id="modal-close"
                    onClick={onCancel}
                >
                    {translate('common.cancel')}
                </Button>
                <Button
                    type="submit"
                    color="orange"
                    disabled={!canSubmit}
                    onClick={handleSubmitButtonClick}
                >
                    {translate('pages.shifts.insertShifts')}
                </Button>
            </ModalFooter>
        </form>
    );
};

export default PublishBaseScheduleForm;
