import { TrackRoutes } from '../../../pages/Tracks/TrackRoutes';
import trans from '../../helpers/trans';
import { RoleType } from '../../types/roleTypes';
import { SubNavRoute } from '../routes';

export type TrackNavigationRouteKey = 'open-tracks' | 'review-tracks' | 'finished-tracks';

const trackNavigationRoutes: SubNavRoute[] = [
    {
        key: 'open-tracks',
        path: TrackRoutes.open(),
        label: trans('routes.tracks.open.label'),
        permissions: ['view-all-tracks'],
        roles: [
            RoleType.admin,
            RoleType.establishmentManager,
            RoleType.humanResources,
            RoleType.headManager,
            RoleType.juniorManager,
            RoleType.manager,
        ],
    },
    {
        key: 'review-tracks',
        path: TrackRoutes.review(),
        label: trans('routes.tracks.review.label'),
        permissions: ['view-all-tracks'],
        roles: [
            RoleType.admin,
            RoleType.establishmentManager,
            RoleType.humanResources,
            RoleType.headManager,
            RoleType.juniorManager,
            RoleType.manager,
        ],
    },
    {
        key: 'finished-tracks',
        path: TrackRoutes.finished(),
        label: trans('routes.tracks.finished.label'),
        permissions: ['view-all-tracks'],
        roles: [
            RoleType.admin,
            RoleType.establishmentManager,
            RoleType.humanResources,
            RoleType.headManager,
            RoleType.juniorManager,
            RoleType.manager,
        ],
    },
];

export default trackNavigationRoutes;
