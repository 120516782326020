import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import { areIntervalsOverlapping } from '../../helpers/date';
import { LeaveOfAbsenceViewModel, ShiftPlanningViewModel, ShiftViewModel } from '../../models';

const doesLeaveOfAbsenceOverlapWithShiftPlanning = (
    leaveOfAbsence: LeaveOfAbsenceViewModel,
    shiftPlanning: ShiftPlanningViewModel
    | ShiftViewModel
    | ShiftConceptViewModelOld
    | BaseScheduleShiftViewModel,
): boolean => areIntervalsOverlapping(
    {
        start: shiftPlanning.start,
        end: shiftPlanning.end.date,
    },
    {
        start: leaveOfAbsence.start,
        end: leaveOfAbsence.end.date,
    },
);

export default doesLeaveOfAbsenceOverlapWithShiftPlanning;
