import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { Comment } from '../Comment/Comment';
import { Department } from '../Department/Department';
import { transformToPeriod } from '../Period/PeriodTransformers';
import {
    AddDistrictDivisionDistrictPlanningFormData,
    AddDistrictDivisionDistrictPlanningRequest,
    DistrictDivisionDistrictPlanning,
    DistrictDivisionDistrictPlanningResource,
    EditDistrictDivisionDistrictPlanningFormData,
    EditDistrictDivisionDistrictPlanningOrderRequest,
} from './DistrictDivisionDistrictPlanning';

export const transformToDistrictDivisionDistrictPlanning = (
    resource: DistrictDivisionDistrictPlanningResource,
    department: Department,
    fullName: string,
    userId: string,
    districtDivisionDistrictId: string,
    comments: Comment[] = [],
): DistrictDivisionDistrictPlanning => {
    const start = new Date(resource.attributes.start);
    const end = new Date(resource.attributes.end);
    const period = transformToPeriod(start, end);

    return {
        id: resource.id,
        type: resource.type,
        comments,
        department,
        districtDivisionDistrictId,
        period,
        planningOrder: resource.attributes.planningOrder,
        fullName,
        userId,
    };
};

export const transformAddDistrictDivisionPlanningFormDataToRequest = (
    formData: AddDistrictDivisionDistrictPlanningFormData,
): AddDistrictDivisionDistrictPlanningRequest => ({
    type: 'districtDivisionDistrictPlannings',
    attributes: {
        planningOrder: formData.index,
        start: formatDate(formData.period.start, DateFormat.apiDateTime),
        end: formatDate(formData.period.end, DateFormat.apiDateTime),
    },
    relationships: {
        districtDivisionDistrict: {
            data: {
                type: 'districtDivisionDistricts',
                id: formData.districtDivisionDistrictId,
            },
        },
        department: {
            data: {
                type: 'departments',
                id: formData.departmentId,
            },
        },
        user: {
            data: {
                type: 'users',
                id: formData.userId,
            },
        },
    },
});

export const transformEditDistrictDivisionPlanningOrderFormDataToRequest = (
    formData: EditDistrictDivisionDistrictPlanningFormData,
): EditDistrictDivisionDistrictPlanningOrderRequest => ({
    id: formData.planningId,
    type: 'districtDivisionDistrictPlannings',
    attributes: {
        planningOrder: formData.index,
    },
    relationships: {
        districtDivisionDistrict: {
            data: {
                type: 'districtDivisionDistricts',
                id: formData.districtDivisionDistrictId,
            },
        },
    },
});
