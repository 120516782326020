import { FC, PropsWithChildren, ReactElement } from 'react';

import './SidebarPageWrapper.scss';

interface SidebarPageWrapperProps {
    className?: string;
}

const SidebarPageWrapper: FC<PropsWithChildren<SidebarPageWrapperProps>> = ({ className = '', children }): ReactElement => (
    <div className={`sidebar-page-wrapper ${className}`}>
        {children}
    </div>
);

export default SidebarPageWrapper;
