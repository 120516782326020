import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { ShiftPlanning } from '../../entities/ShiftPlanning/ShiftPlanning';

export type ShiftConceptPlanningState = AsyncReduxState<{
    isDeleteSuccessful: boolean;
    isEditSuccessful: boolean;
    deletedShiftConceptPlanningId?: string;
    shiftConceptPlanning?: ShiftPlanning;
}>;

const initialState: ShiftConceptPlanningState = {
    isDeleteSuccessful: false,
    isEditSuccessful: false,
    ...initialAsyncReduxState,
};

export const shiftConceptPlanningSlice = createSlice({
    name: 'shiftConceptPlanningReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ShiftConceptPlanningState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsEditSuccessful(state, action: PayloadAction<boolean>): ShiftConceptPlanningState {
            return {
                ...state,
                isEditSuccessful: action.payload,
            };
        },
        setIsDeleteSuccessful(state, action: PayloadAction<boolean>): ShiftConceptPlanningState {
            return {
                ...state,
                isDeleteSuccessful: action.payload,
            };
        },
        setDeletedShiftConceptPlanningId(state, action: PayloadAction<string>): ShiftConceptPlanningState {
            return {
                ...state,
                deletedShiftConceptPlanningId: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ShiftConceptPlanningState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setShiftConceptPlanning(state, action: PayloadAction<ShiftPlanning>): ShiftConceptPlanningState {
            return {
                ...state,
                shiftConceptPlanning: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsDeleteSuccessful,
    setIsEditSuccessful,
    setDeletedShiftConceptPlanningId,
    setError,
    setShiftConceptPlanning,
} = shiftConceptPlanningSlice.actions;

export default shiftConceptPlanningSlice.reducer;
