import transformTimeStringToNumber from '../../helpers/date/transformTimeStringToNumber';
import { TrackViewModel } from '../../models';

export function transformBillableTimeToHourMinutes(totalBillableTime: string): string {
    return `${totalBillableTime.split(':')[0]}:${totalBillableTime.split(':')[1]}`;
}

export default function getTotalBillableTimeFromTracks(tracks: TrackViewModel[] = []) {
    return tracks
        .reduce((total: number, track: TrackViewModel) => (
            total + transformTimeStringToNumber(track.totalBillableTime)
        ), 0);
}
