import axios from 'axios';

import { DepartmentResource, DepartmentSoftDeleteRequest, DepartmentViewModel } from './Departments';
import { transformDepartmentResource } from './DepartmentsTransformers';

export const fetchDepartmentsApiCall = async (): Promise<DepartmentViewModel[]> => {
    const includes = ['group'];

    const url = new URL(`${process.env.REACT_APP_API_URL}/departments`);

    url.searchParams.append('include', includes.join(','));

    const response = await axios.get<DepartmentResource[]>(String(url));

    return response.data.map(transformDepartmentResource);
};


export const patchSoftDeleteDepartmentApiCall = async (request: DepartmentSoftDeleteRequest): Promise<DepartmentViewModel> => {
    const includes = ['group'];

    const url = new URL(`${process.env.REACT_APP_API_URL}/departments/${request.id}`);

    url.searchParams.append('include', includes.join(','));

    const response = await axios.patch<DepartmentResource>(String(url), { data: request });

    return transformDepartmentResource(response.data);
};
