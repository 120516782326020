import React, { Component } from 'react';

import nl from 'date-fns/locale/nl';
import PropTypes from 'prop-types';
import { debounce } from 'underscore';

import Icon from '../../../../components/style/Icon/Icon';
import {
    ABSENCES_CALENDAR_VIEW_PROPS,
    LEAVE_OF_ABSENCES_TYPE_DENIED,
    LEAVE_OF_ABSENCES_TYPE_OPEN,
    TYPE_ABSENCE,
} from '../../../../constants';
import { capitalizeFirstLetter, getUserFullName } from '../../../../helpers';
import {
    formatDate,
    getLastDayOfWeekInMonth,
    getOverlappingDaysInIntervals,
    isBefore,
    startOfWeek,
} from '../../../../helpers/date';
import { transformEndDateOrStringToFormDate, transformStartDateOrStringToFormDate } from '../../../../helpers/date/transformStartDateOrStringToFormDate';
import { translate } from '../../../../helpers/translations/translator';

import './Absence.scss';

function generateId(dayIndex, absence) {
    const row = Math.floor(dayIndex / 7);
    return `absence-card-${absence.id}-${row}`;
}

function generateComment(status, reason) {
    switch (status) {
        case LEAVE_OF_ABSENCES_TYPE_OPEN:
            return translate('pages.absences.leaveOfAbsenceRequest');
        case LEAVE_OF_ABSENCES_TYPE_DENIED:
            return `${reason} (${translate('common.denied').toLowerCase()})`;
        default:
            return reason;
    }
}

function generateType(type) {
    if (type === TYPE_ABSENCE) {
        return translate('pages.management.sickness');
    }

    return translate('pages.absences.leaveOfAbsence');
}

function generateClassName(type, status) {
    if (type === TYPE_ABSENCE) {
        return 'absence';
    }

    return status;
}

class Absence extends Component {
    ref = React.createRef();

    dayDate = '';

    single = false;

    offsetParent = 0;

    componentDidMount() {
        this.setData();
        this.setMarginTop();
        this.throttledResize = debounce(this.setMarginTop.bind(this), 50);
        window.addEventListener('resize', this.throttledResize);
    }

    componentDidUpdate() {
        this.setData();
        this.setMarginTop();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.throttledResize);
    }

    setData = () => {
        const { absence, dayIndex, day } = this.props;
        const justifiedStartDate = transformStartDateOrStringToFormDate(absence.start);
        const justifiedEndDate = absence.end ? transformEndDateOrStringToFormDate(absence.end) : null;
        const startDate = formatDate(justifiedStartDate, 'EEEE dd-MM-yyyy');
        const endDate = justifiedEndDate ? formatDate(justifiedEndDate, 'EEEE dd-MM-yyyy') : null;
        this.dayDate = formatDate(day, 'EEEE dd-MM-yyyy');
        this.single = startDate === endDate || (endDate === this.dayDate && dayIndex === 0);
    };

    setMarginTop = () => {
        if (this.single) {
            return;
        }

        const { absence, dayIndex } = this.props;
        const id = generateId(dayIndex, absence, this.dayDate);
        const offsetParent = document.getElementById(id).offsetTop;
        const offsetParentChanged = offsetParent !== this.offsetParent;
        this.offsetParent = offsetParent;

        if (!offsetParentChanged) {
            return;
        }

        const offset = this.ref.current.offsetTop;
        const marginTop = parseInt(this.ref.current.style.marginTop, 10) || 0;
        this.ref.current.style.marginTop = `${offsetParent - (offset - marginTop)}px`;
    };

    render() {
        const {
            absence, dayIndex, day, onClick, onMouseEnter, onMouseLeave, focus, last,
            filter,
        } = this.props;
        const { reason } = absence;
        const name = getUserFullName(absence.user);
        const comment = generateComment(absence.status, reason);
        const type = generateType(absence.type, absence.status);
        const className = generateClassName(absence.type, absence.status);
        const dayDate = formatDate(day, 'EEEE dd-MM-yyyy');
        const justifiedStartDate = transformStartDateOrStringToFormDate(absence.start);
        const justifiedEndDate = absence.end ? transformEndDateOrStringToFormDate(absence.end) : null;

        const startDate = formatDate(justifiedStartDate, 'EEEE dd-MM-yyyy');
        const endDate = justifiedEndDate ? formatDate(justifiedEndDate, 'EEEE dd-MM-yyyy') : '?';

        const isPast = isBefore(justifiedEndDate, new Date());
        const dates = `${capitalizeFirstLetter(startDate)} ${translate('common.until')} ${endDate}`;
        const mobileEndDate = absence.end ? formatDate(justifiedEndDate, 'EEEEEE dd-MM') : '?';
        const mobileDates = `${capitalizeFirstLetter(formatDate(justifiedStartDate, 'EEEEEE dd-MM', { locale: nl }))} t/m ${mobileEndDate}`;
        const time = `${formatDate(absence.start, 'HH:mm')} - ${formatDate(absence.end, 'HH:mm')}`;
        // Single means this card doesn't have any siblings
        const single = startDate === endDate
            || endDate === '?'
            || ((dayIndex % 7 === 0) && last) // If Series start on monday but there's no siblings
            || ((dayDate === startDate) && last) // If start of series but it's last
            || (endDate === dayDate && dayIndex % 7 === 0) // Series ends on a monday
            || (startDate === dayDate && (dayIndex - 6) % 7 === 0); // Series start on sunday
        // The startSeries card will display the text
        const startSeries = dayDate === startDate || dayIndex % 7 === 0;
        // The endSeries is the last column of a series
        const endSeries = dayDate === endDate || (dayIndex - 6) % 7 === 0 || last;
        const rightFill = startSeries && !single;
        const leftFill = endSeries && !single;
        const fullFill = !startSeries && !single && !leftFill && !rightFill;
        // Id is used for aligning siblings with parent card
        const id = generateId(dayIndex, absence, dayDate);
        // Focus id is used for scrolling down page and centering after route
        const focusId = `absence-${absence.id}`;
        const range = justifiedEndDate ? getOverlappingDaysInIntervals(
            {
                start: startOfWeek(day, { weekStartsOn: 1 }),
                end: getLastDayOfWeekInMonth(day, dayIndex),
            },
            {
                start: justifiedStartDate,
                end: justifiedEndDate,
            },
        ) : 0;

        const showType = filter.find(viewProp => viewProp === 'type');
        const showName = filter.find(viewProp => viewProp === 'name');
        const showDate = filter.find(viewProp => viewProp === ABSENCES_CALENDAR_VIEW_PROPS[1]);
        const showReason = filter.find(viewProp => viewProp === ABSENCES_CALENDAR_VIEW_PROPS[0]);
        const icon = (absence.status === 'denied') ? 'person-black' : 'person';

        return (
            <button
                ref={this.ref}
                id={startSeries ? id : null}
                type="button"
                className={`
                    calendar-absence 
                    calendar-absence-${absence.id}
                    calendar-absence--${className}
                    ${focus ? 'calendar-absence--focus' : ''}
                    ${isPast ? 'calendar-absence--past' : ''}
                `}
                onClick={() => onClick(absence)}
                onMouseEnter={() => onMouseEnter(absence.id)}
                onMouseLeave={() => onMouseLeave(absence.id)}
            >
                <div
                    id={focusId}
                    className={`
                            calendar-absence-inner
                            calendar-absence-inner--range-${range}
                            ${startSeries ? 'calendar-absence-inner--visible' : ''}
                            ${single ? 'calendar-absence-inner--single' : ''}
                            ${rightFill ? 'calendar-absence-inner--right-fill' : ''}
                            ${fullFill ? 'calendar-absence-inner--full-fill' : ''}
                            ${leftFill ? 'calendar-absence-inner--left-fill' : ''}
                        `}
                >
                    <div className={`calendar-absence-name ${!showName ? 'hidden' : ''}`}>
                        <Icon className="calendar-absence-icon" kind={icon} fontSize={10} isImage />
                        {name}
                    </div>
                    <div className={`calendar-absence-comment ${!showReason ? 'hidden' : ''}`}>{comment}</div>
                    <div className={`calendar-absence-date calendar-absence-date-desktop ${!showDate ? 'hidden' : ''}`}>{startDate === endDate ? time : dates}</div>
                    <div className={`calendar-absence-date calendar-absence-date-mobile ${!showDate ? 'hidden' : ''}`}>{startDate === endDate ? time : mobileDates}</div>
                    <div className={`calendar-absence-type ${!showType ? 'hidden' : ''}`}>{type}</div>
                </div>
            </button>
        );
    }
}

Absence.propTypes = {
    focus: PropTypes.bool.isRequired,
    absence: PropTypes.object.isRequired,
    dayIndex: PropTypes.number.isRequired,
    day: PropTypes.any.isRequired,
    last: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    filter: PropTypes.array.isRequired,
};

export default Absence;
