import { FC, useMemo } from 'react';

import { LoadingSpinner, Select } from '../../../../components';
import { EmptyState } from '../../../../compositions';
import { DistrictDivision } from '../../../../entities/DistrictDivision/DistrictDivision';
import { transformDistrictDivisionsToGroupedSelectOptions } from '../../../../entities/DistrictDivision/DistrictDivisionTransformers';
import { formatDate } from '../../../../helpers/date';
import trans from '../../../../helpers/trans';
import { SelectOption } from '../../../../types';
import { DateFormat } from '../../../../types/dateFormatTypes';

import './DistrictDivisionSelect.scss';

interface DistrictDivisionSelectProps {
    isLoading: boolean;
    showSelector: boolean;
    date: Date;
    districtDivisions: DistrictDivision[];
    onSelectDistrictDivision: (districtDivision: DistrictDivision) => void;
    className?: string;
}

const DistrictDivisionSelect: FC<DistrictDivisionSelectProps> = ({
    isLoading,
    showSelector,
    date,
    districtDivisions,
    onSelectDistrictDivision,
    className = '',
}) => {
    const options = useMemo(() => transformDistrictDivisionsToGroupedSelectOptions(districtDivisions), [districtDivisions]);

    const handleSelectChange = (selectOption: SelectOption): void => {
        const selectedDistrictDivision = districtDivisions.find(districtDivision => districtDivision.id === selectOption.value);

        if (selectedDistrictDivision) {
            onSelectDistrictDivision(selectedDistrictDivision);
        }
    };

    return (
        <EmptyState
            title={trans('containers.districtDivisionContainer.chooseDistrictDivision')}
            intro={trans('containers.districtDivisionContainer.chooseDistrictDivisionIntro', { date: formatDate(date, DateFormat.dateLabel) })}
            className={`district-division-empty-state ${className}`}
        >
            {showSelector && (
                <Select
                    hasSecondaryStyling
                    disabled={isLoading}
                    isLoading={isLoading}
                    isSearchable={false}
                    name={trans('containers.districtDivisionContainer.chooseTemplate')}
                    options={options}
                    onChange={handleSelectChange}
                    className="district-division-select__select"
                />
            )}
            {isLoading && <LoadingSpinner isSmall className="district-division-select__loader" />}
        </EmptyState>
    );
};

export default DistrictDivisionSelect;
