import { DistrictDivisionDistrictPlanning } from '../DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { DistrictDivisionDistrict, DistrictDivisionDistrictResource } from './DistrictDivisionDistrict';

export const transformDistrictDivisionDistrict = (
    districtDivisionDistrictResource: DistrictDivisionDistrictResource,
    districtDivisionDistrictPlannings: DistrictDivisionDistrictPlanning[] = [],
): DistrictDivisionDistrict => ({
    id: districtDivisionDistrictResource.id,
    name: districtDivisionDistrictResource.attributes.name,
    maxAmountOfEmployees: districtDivisionDistrictResource.attributes.maxAmountOfEmployees,
    districtDivisionDistrictPlannings,
});
