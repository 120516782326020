import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { patchPersonApiCall, postPersonApiCall } from '../../entities/Person/PersonService';
import { AddUserFormData, EditUserFormData } from '../../entities/User/User';
import { removeUserApiCall } from '../../entities/User/UserService';
import { getPersonFullName } from '../../helpers';
import { addAddress, editAddress } from '../address/addressActions';
import { addEmergencyContact, editEmergencyContact } from '../emergencyContact/emergencyContactActions';

export const addPerson = async (addUserFormData: AddUserFormData, userId: string): Promise<void> => {
    const response = await postPersonApiCall(addUserFormData.person, addUserFormData.personalInfo, userId, addUserFormData.experienceId);

    if (!isFetchResultSuccessful(response)) {
        await removeUserApiCall(userId);
        const fullName = getPersonFullName({
            firstname: addUserFormData.personalInfo.firstname,
            lastname: addUserFormData.personalInfo.lastname,
            affix: addUserFormData.personalInfo.affix,
        });

        throw new Error(`addPerson, user with id ${userId} (${fullName}) automatically removed`);
    }

    const emergencyContactData = addUserFormData.emergencyContact || {};
    const addressData = addUserFormData.address || {};
    const shouldAddAddress = addUserFormData.address && Object.keys(addressData).length !== 0;
    const shouldAddEmergencyContact = addUserFormData.emergencyContact && Object.keys(emergencyContactData).length !== 0;

    await Promise.all([
        shouldAddAddress && addAddress(addressData, response.data.id),
        shouldAddEmergencyContact && addEmergencyContact(emergencyContactData, response.data.id),
    ]);
};

export const editPerson = async (
    editUserFormData: EditUserFormData,
    userId: string,
): Promise<void> => {
    const response = await patchPersonApiCall(editUserFormData.person, editUserFormData.personalInfo, userId, editUserFormData.experienceId);

    if (!isFetchResultSuccessful(response)) {
        throw new Error('editPerson');
    }

    const actions: Promise<void>[] = [];

    if (editUserFormData.address) {
        const shouldEditAddress = editUserFormData.address.id;

        actions.push(
            shouldEditAddress
                ? editAddress(editUserFormData.address, editUserFormData.person.id)
                : addAddress(editUserFormData.address, editUserFormData.person.id),
        );
    }

    if (editUserFormData.emergencyContact) {
        const shouldEditEmergencyContact = editUserFormData.emergencyContact.id;

        actions.push(
            shouldEditEmergencyContact
                ? editEmergencyContact(editUserFormData.emergencyContact, editUserFormData.person.id)
                : addEmergencyContact(editUserFormData.emergencyContact, editUserFormData.person.id),
        );
    }

    await Promise.all(actions);
};
