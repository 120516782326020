import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { ShiftIndex } from '../../entities/Shift/Shift';

export type ShiftState = AsyncReduxState<{
    deletedShiftId?: string;
    shift?: ShiftIndex;
    isCopyShiftSuccessful: boolean;
    isDeleteShiftSuccessful: boolean;
    isEditShiftSuccessful: boolean;
}>;

const initialState: ShiftState = {
    ...initialAsyncReduxState,
    isCopyShiftSuccessful: false,
    isDeleteShiftSuccessful: false,
    isEditShiftSuccessful: false,
};

export const shiftSlice = createSlice({
    name: 'shiftReducer',
    initialState,
    reducers: {
        reset(): ShiftState {
            return { ...initialState };
        },
        setDeletedShiftId(state, action: PayloadAction<string | undefined>): ShiftState {
            return {
                ...state,
                deletedShiftId: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): ShiftState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ShiftState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setIsCopyShiftSuccessful(state, action: PayloadAction<boolean>): ShiftState {
            return {
                ...state,
                isCopyShiftSuccessful: action.payload,
            };
        },
        setIsDeleteShiftSuccessful(state, action: PayloadAction<boolean>): ShiftState {
            return {
                ...state,
                isDeleteShiftSuccessful: action.payload,
            };
        },
        setIsEditShiftSuccessful(state, action: PayloadAction<boolean>): ShiftState {
            return {
                ...state,
                isEditShiftSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ShiftState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setShift(state, action: PayloadAction<ShiftIndex>): ShiftState {
            return {
                ...state,
                shift: action.payload,
            };
        },
    },
});

export const {
    reset,
    setDeletedShiftId,
    setIsLoading,
    setIsSuccessful,
    setIsCopyShiftSuccessful,
    setIsDeleteShiftSuccessful,
    setIsEditShiftSuccessful,
    setError,
    setShift,
} = shiftSlice.actions;

export default shiftSlice.reducer;
