import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { LeaveOfAbsence } from '../../entities/LeaveOfAbsence/LeaveOfAbsence';

export type LeaveOfAbsencesState = AsyncReduxState<{
    leaveOfAbsences: LeaveOfAbsence[];
}>;

const initialState: LeaveOfAbsencesState = {
    ...initialAsyncReduxState,
    leaveOfAbsences: [],
};

export const leaveOfAbsencesSlice = createSlice({
    name: 'leaveOfAbsencesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): LeaveOfAbsencesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): LeaveOfAbsencesState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): LeaveOfAbsencesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setLeaveOfAbsences(state, action: PayloadAction<LeaveOfAbsence[]>): LeaveOfAbsencesState {
            return {
                ...state,
                leaveOfAbsences: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setLeaveOfAbsences,
} = leaveOfAbsencesSlice.actions;

export default leaveOfAbsencesSlice.reducer;
