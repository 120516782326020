import { FC, ReactElement } from 'react';

import { TableRow } from '../../../../components';
import { EventLogDataItem } from '../../../../entities/EventLog/EventLog';

import './EntityEventData.scss';

interface EntityEventDataProps {
    item: EventLogDataItem;
    className?: string;
}

const EntityEventData: FC<EntityEventDataProps> = ({ item, className = '' }): ReactElement => (
    <TableRow className={`entity-event-data ${className}`}>
        <td className="entity-event-data__label">
            {`${item.label}:`}
        </td>

        <td className="entity-event-data__value">
            {item.value}
        </td>
    </TableRow>
);

export default EntityEventData;
