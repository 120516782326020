import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { FormActionButtons, TextInput } from '../../../compositions';
import { DistrictDivisionTemplate, EditDistrictDivisionTemplateFormData } from '../../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import trans from '../../../helpers/trans';
import { ModalFormProps } from '../../../types/modalFormTypes';
import { EditDistrictDivisionTemplateFormErrors, validateEditDistrictDivisionTemplateFormData } from './validations/editDistrictDivisionTemplateFormValidation';

import './EditDistrictDivisionTemplateForm.scss';

interface EditDistrictDivisionTemplateFormProps extends ModalFormProps<EditDistrictDivisionTemplateFormData> {
    districtDivisionTemplate: DistrictDivisionTemplate;
}

const EditDistrictDivisionTemplateForm: FC<EditDistrictDivisionTemplateFormProps> = ({
    isLoading,
    districtDivisionTemplate,
    onSubmit,
    onCancel,
}): ReactElement => {
    const [formData, setFormData] = useState<EditDistrictDivisionTemplateFormData>({ id: districtDivisionTemplate.id, name: districtDivisionTemplate.name });
    const [formErrors, setFormErrors] = useState<EditDistrictDivisionTemplateFormErrors>({});
    const [name, setName] = useState<string>(formData.name);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formDataToSubmit = { id: formData.id, name };
        setFormData(formDataToSubmit);

        const [errors, hasErrors] = validateEditDistrictDivisionTemplateFormData(formDataToSubmit);
        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formDataToSubmit);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="edit-district-division-template-form">
            <div className="edit-district-division-template-form__row">
                <div className="edit-district-division-template-form__col">
                    <TextInput
                        label={trans('containers.forms.addDistrictDivisionTemplateForm.districtDivisionName')}
                        type="text"
                        value={name}
                        error={formErrors.name}
                        onChange={setName}
                    />
                </div>
            </div>
            <FormActionButtons
                isLoading={isLoading}
                disabled={!name}
                submitText={trans('common.edit')}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default EditDistrictDivisionTemplateForm;
