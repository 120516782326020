import { FC } from 'react';

import { ElementWithTooltip } from '../../../compositions';
import { EmploymentType } from '../../../entities/EmploymentType/EmploymentType';
import { getFirstLetterOfEmploymentType } from '../../../entities/EmploymentType/EmploymentTypeHelpers';

import './EmploymentBadge.scss';

interface EmploymentBadgeProps {
    employment: EmploymentType;
    className?: string;
}

const EmploymentBadge: FC<EmploymentBadgeProps> = ({ employment, className = '' }) => (
    <ElementWithTooltip
        tooltipIsActive
        tooltipText={employment.name}
    >
        <div className={`employment-badge ${className}`}>
            {getFirstLetterOfEmploymentType(employment.slug)}
        </div>
    </ElementWithTooltip>
);

export default EmploymentBadge;
