export enum LoketLeaveBalanceResourceKeys {
    VERLOF_BOVENWETTELIJK = 1,
    ADV = 2,
    ONBETAALD_VERLOF = 3,
    ONBETAALD_VERLOF_UIT_LEVENSLOOPREGELING = 4,
    ZORGVERLOF = 5,
    VERLOF_VOOR_KINDEREN = 6,
    CALAMITEITENVERLOF = 7,
    BIJZONDER_VERLOF = 8,
    FEESTDAGEN = 9,
    TIME_FOR_TIME = 10,
    KORT_VERZUIM = 11,
    TIJDSPAARFONDS = 12,
    OVERIG_VERLOF_NIET_JAAR_OVERSCHRIJDEND = 13,
    OVERIG_VERLOF_JAAR_OVERSCHRIJDEND = 14,
    VERLOF_WETTELIJK = 15,
}

export interface LoketLeaveBalanceResource {
    accrual: number;
    balance: number;
    balancePreviousYear: number;
    employment: {
        id: string;
        start: string;
        end: string;
    },
    leaveType: {
        key: number;
        value: string;
        balanceExceedsYear: boolean;
    },
    unitType: {
        key: number,
        value: string
    } | null;
    usageAfterToday: number;
    usageThroughToday:number;
    usageTotal: number;
    year: number;
}

export interface LeaveTypeLabelAndValue {
    label: string;
    value: number;
}

export interface LoketBalancesViewModel {
    loketEmployeeId: string;
    nonStatutoryLeaveHoursTotal: number;
    statutoryLeaveHoursTotal: number;
    leaveBalanceTotal: number;
    reservedLeaveHoursTotal: number;
    reservedLeaveHours: LeaveTypeLabelAndValue[];
    timeForTimeTotal: number;
    usedLeaveHoursTotal: number;
    usedLeaveHours: LeaveTypeLabelAndValue[];
}
