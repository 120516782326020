import React, { FC } from 'react';

import './SettingsCardTitle.scss';

interface Props {
    title: string;
    children?: React.ReactNode;
}

export const SettingsCardTitle:FC<Props> = ({
    title,
    children,
}) => (
    <div className={`settings-card-title ${children ? 'settings-card-title--has-children' : ''}`}>
        {title}
        {children}
    </div>
);
