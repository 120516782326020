import React, { FC, ReactElement } from 'react';

import SidebarPage from '../../components/SidebarPage/SidebarPage';
import ConnectedBaseSchedulesOverview from './connectors/ConnectedBaseSchedulesOverview/ConnectedBaseSchedulesOverview';
import ConnectedBaseSchedulesSidebar from './connectors/ConnectedBaseSchedulesSidebar/ConnectedBaseSchedulesSidebar';

const BaseSchedules: FC = (): ReactElement => (
    <SidebarPage
        sidebarContent={
            <ConnectedBaseSchedulesSidebar />
        }
    >
        <ConnectedBaseSchedulesOverview />
    </SidebarPage>
);

export default BaseSchedules;
