import React, { FC, useEffect, useState } from 'react';

import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

import { LeaveTypeSelectInput } from '../../../../@paco/compositions';
import { PayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { PzLeaveType } from '../../../../@paco/types/leaveType';
import WeekdayHoursInputs from '../../../../components/WeekHoursInputs/WeekHoursInputs';
import { checkPermission } from '../../../../helpers';
import UpToAndIncludingDate from '../../../../helpers/date/UpToAndIncludingDate';
import { translate } from '../../../../helpers/translations/translator';
import {
    LeaveOfAbsence,
    LeaveType,
    PayrollPeriodViewModel,
    PayrollPeriodWithHours,
    Permission,
    WeekWithHours,
} from '../../../../models';
import transformWeeksWithHoursToPayrollPeriods
    from '../../../../services/WeekWithHours/transformWeeksWithHoursToPayrollPeriods';
import { transformLeaveOfAbsenceHoursToWeekDayWithHours } from '../ResolveLeaveOfAbsenceForm';

import '../Forms.scss';

const DEFAULT_LEAVE_TYPE: LeaveType = LeaveType.normal;

interface ApproveLeaveOfAbsenceFormProps {
    leaveOfAbsence: LeaveOfAbsence;
    pacoPayrollPeriods: PayrollPeriod[];
    payrollPeriods: PayrollPeriodViewModel[];
    permissions: Permission[];
    onCancel: () => void;
    onSubmit: (
        payrollPeriodWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        leaveType: LeaveType | PzLeaveType,
    ) => void;
}

const ApproveLeaveOfAbsenceForm: FC<ApproveLeaveOfAbsenceFormProps> = ({
    leaveOfAbsence,
    pacoPayrollPeriods,
    payrollPeriods,
    permissions,
    onCancel,
    onSubmit,
}) => {
    const [leaveType, setLeaveType] = useState<LeaveType | PzLeaveType>(DEFAULT_LEAVE_TYPE);
    const [weeksWithHours, setWeekdaysWithHours] = useState<WeekWithHours[]>([]);
    const [initialized, setInitialized] = useState(false);
    const canViewAllLeaveTypes = checkPermission(permissions, 'view-all-leave-types');

    useEffect(() => {
        if (leaveOfAbsence) {
            setWeekdaysWithHours(
                transformLeaveOfAbsenceHoursToWeekDayWithHours(leaveOfAbsence.leaveOfAbsenceHours || [], pacoPayrollPeriods),
            );
            setInitialized(true);
        }
    }, [leaveOfAbsence]);

    const onFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const payrollPeriodsWithHours = transformWeeksWithHoursToPayrollPeriods(weeksWithHours, payrollPeriods);

        onSubmit(payrollPeriodsWithHours, weeksWithHours, leaveType);
    };

    const onWeekdayHoursInputsChange = (weekdays: WeekWithHours[]) => {
        setWeekdaysWithHours(weekdays);
    };

    const onChangeLeaveType = (value: LeaveType | PzLeaveType) => {
        setLeaveType(value);
    };

    if (!leaveOfAbsence || !initialized) {
        return null;
    }

    return (
        <form onSubmit={onFormSubmit}>
            <ModalHeader>
                {translate('pages.absences.approveLeaveOfAbsence')}
            </ModalHeader>
            <ModalBody>
                <div className="leave-of-absence-form__row">
                    <div className="leave-of-absence-form__col">
                        <LeaveTypeSelectInput
                            canViewAllLeaveTypes={canViewAllLeaveTypes}
                            leaveType={leaveType as PzLeaveType}
                            onChange={onChangeLeaveType}
                        />
                    </div>
                </div>
                <WeekdayHoursInputs
                    pacoPayrollPeriods={pacoPayrollPeriods}
                    payrollPeriods={payrollPeriods}
                    weekWithHours={weeksWithHours}
                    type="leaveOfAbsences"
                    startDate={new Date(leaveOfAbsence.start)}
                    endDate={new UpToAndIncludingDate(leaveOfAbsence.end)}
                    onChange={onWeekdayHoursInputsChange}
                />
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="link" id="modal-close" onClick={onCancel}>
                    {translate('common.cancel')}
                </Button>
                <Button type="submit" color="orange">{translate('common.save')}</Button>
            </ModalFooter>
        </form>
    );
};

export default ApproveLeaveOfAbsenceForm;
