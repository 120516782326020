import { ChangeEvent, FC } from 'react';

import { ErrorLabel, Input, InputLabel } from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';

import './TextInput.scss';

export interface TextInputProps extends Omit<HTMLInputProps, 'onChange'> {
    label: string;
    hideLabel?: boolean;
    requiredTextIsAlwaysVisible?: boolean;
    requiredText?: string;
    requiredClassName?: string;
    inputClassName?: string;
    error?: string;
    onChange: (value: string) => void;
}

const TextInput: FC<TextInputProps> = ({
    label,
    hideLabel,
    requiredTextIsAlwaysVisible = false,
    type = 'text',
    required = false,
    requiredText = '',
    error = '',
    onChange,
    className = '',
    requiredClassName = '',
    inputClassName = '',
    ...inputProps
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <label className={`text-input ${className}`}>
            {!hideLabel && (
                <InputLabel
                    text={label}
                    requiredTextIsAlwaysVisible={requiredTextIsAlwaysVisible}
                    required={required}
                    requiredText={requiredText}
                    className="text-input__label"
                    requiredClassName={requiredClassName}
                />
            )}
            <Input
                {...inputProps}
                type={type}
                required={required}
                error={error}
                onChange={handleChange}
                className={`text-input__input ${inputClassName}`}
            />
            {error && <ErrorLabel text={error} className="text-input__error-label" />}
        </label>
    );
};

export default TextInput;
