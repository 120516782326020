import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import { getRemovedLogs, getResourceLogs } from '../../helpers/api/logsApi';
import {
    LOGS_CLEAR_RESOURCE_LOGS, LOGS_GET_DELETED_LOGS_FAILURE,
    LOGS_GET_DELETED_LOGS_REQUEST,
    LOGS_GET_DELETED_LOGS_SUCCESS,
    LOGS_GET_RESOURCE_LOGS_FAILURE, LOGS_GET_RESOURCE_LOGS_REQUEST,
    LOGS_GET_RESOURCE_LOGS_SUCCESS,
    LOGS_SAGA_CLEAR_RESOURCE_LOGS,
    LOGS_SAGA_GET_DELETED_LOGS,
    LOGS_SAGA_GET_RESOURCE_LOGS,
    PAGINATION_SET_PAGINATION,
} from '../actionTypes';


function* fetchDeletedLogs() {
    yield put({ type: LOGS_GET_DELETED_LOGS_REQUEST });

    try {
        const state = yield select();
        const response = yield call(() => getRemovedLogs(state.paginationReducer));
        const { meta } = response;

        if (meta && meta.pagination) {
            yield put({
                type: PAGINATION_SET_PAGINATION,
                pages: meta.pagination.pages,
                size: meta.pagination.size,
            });
        }

        yield put({ type: LOGS_GET_DELETED_LOGS_SUCCESS, logs: response.data });
    } catch (error) {
        yield put({ type: LOGS_GET_DELETED_LOGS_FAILURE, errors: [error] });
    }
}

export function* fetchResourceLogs(action) {
    const { id, resourceType } = action;
    yield put({ type: LOGS_GET_RESOURCE_LOGS_REQUEST, id });

    try {
        const response = yield call(() => getResourceLogs(id, resourceType));
        const { logs } = response.data;
        yield put({ type: LOGS_GET_RESOURCE_LOGS_SUCCESS, logs });
    } catch (error) {
        yield put({ type: LOGS_GET_RESOURCE_LOGS_FAILURE });
    }
}

export function* clearResourceLogs() {
    yield put({ type: LOGS_CLEAR_RESOURCE_LOGS });
}

export default function* logsWatcher() {
    yield takeLatest(LOGS_SAGA_GET_DELETED_LOGS, fetchDeletedLogs);
    yield takeLatest(LOGS_SAGA_GET_RESOURCE_LOGS, fetchResourceLogs);
    yield takeLatest(LOGS_SAGA_CLEAR_RESOURCE_LOGS, clearResourceLogs);
}
