import { BaseScheduleShiftViewModel } from '../../../../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../../../../entities/ShiftConcept/ShiftConcept';
import { formatDate } from '../../../../../helpers/date';
import { AddShiftFormData, ShiftViewModel } from '../../../../../models';

export function transformShiftToFormData(
    shift: ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel,
): AddShiftFormData {
    return {
        department: shift?.department?.id || '',
        date: shift.start,
        startTime: formatDate(shift.start, 'HH:mm'),
        endTime: formatDate(shift.end.transformToUpToButExcludingDate().date, 'HH:mm'),
        peopleLimit: shift.peopleLimit,
    };
}
