import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { EventLog } from '../../entities/EventLog/EventLog';

export type PzLogsState = AsyncReduxState<{
    isPaginationLoading: boolean;
    logs: EventLog[];
    page: number;
    pageSize: number;
    search: string;
    totalPages: number;
}>;

const initialState: PzLogsState = {
    ...initialAsyncReduxState,
    isPaginationLoading: false,
    logs: [],
    page: 1,
    pageSize: 20,
    search: '',
    totalPages: 0,
};

export const PzLogsSlice = createSlice({
    name: 'PzLogsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): PzLogsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsPaginationLoading(state, action: PayloadAction<boolean>): PzLogsState {
            return {
                ...state,
                isPaginationLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): PzLogsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setPage(state, action: PayloadAction<number>): PzLogsState {
            return {
                ...state,
                page: action.payload,
            };
        },
        setTotalPages(state, action: PayloadAction<number>): PzLogsState {
            return {
                ...state,
                totalPages: action.payload,
            };
        },
        setPzLogs(state, action: PayloadAction<EventLog[]>): PzLogsState {
            return {
                ...state,
                logs: action.payload,
            };
        },
        setSearch(state, action: PayloadAction<string>): PzLogsState {
            return {
                ...state,
                search: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsPaginationLoading,
    setError,
    setPage,
    setPzLogs,
    setSearch,
    setTotalPages,
} = PzLogsSlice.actions;

export default PzLogsSlice.reducer;
