import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { ShiftPlanning } from '../../entities/ShiftPlanning/ShiftPlanning';

export type ShiftPlanningState = AsyncReduxState<{
    isAddSuccessful: boolean;
    isDeleteSuccessful: boolean;
    isEditSuccessful: boolean;
    isPlannedSuccessful: boolean;
    lastAddedShiftPlanning?: ShiftPlanning;
    lastDeletedShiftPlanningId?: string;
    shiftPlanning?: ShiftPlanning;
}>;

const initialState: ShiftPlanningState = {
    isAddSuccessful: false,
    isDeleteSuccessful: false,
    isEditSuccessful: false,
    isPlannedSuccessful: false,
    ...initialAsyncReduxState,
};

export const shiftPlanningSlice = createSlice({
    name: 'shiftPlanningReducer',
    initialState,
    reducers: {
        reset: (): ShiftPlanningState => initialState,
        setIsLoading(state, action: PayloadAction<boolean>): ShiftPlanningState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsAddSuccessful(state, action: PayloadAction<boolean>): ShiftPlanningState {
            return {
                ...state,
                isAddSuccessful: action.payload,
            };
        },
        setIsEditSuccessful(state, action: PayloadAction<boolean>): ShiftPlanningState {
            return {
                ...state,
                isEditSuccessful: action.payload,
            };
        },
        setIsDeleteSuccessful(state, action: PayloadAction<boolean>): ShiftPlanningState {
            return {
                ...state,
                isDeleteSuccessful: action.payload,
            };
        },
        setIsPlannedSuccessful(state, action: PayloadAction<boolean>): ShiftPlanningState {
            return {
                ...state,
                isPlannedSuccessful: action.payload,
            };
        },
        setLastDeletedShiftPlanningId(state, action: PayloadAction<string>): ShiftPlanningState {
            return {
                ...state,
                lastDeletedShiftPlanningId: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ShiftPlanningState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setLastAddedShiftPlanning(state, action: PayloadAction<ShiftPlanning>): ShiftPlanningState {
            return {
                ...state,
                lastAddedShiftPlanning: action.payload,
            };
        },
        setShiftPlanning(state, action: PayloadAction<ShiftPlanning>): ShiftPlanningState {
            return {
                ...state,
                shiftPlanning: action.payload,
            };
        },
    },
});

export const {
    reset,
    setError,
    setIsAddSuccessful,
    setIsDeleteSuccessful,
    setIsEditSuccessful,
    setIsLoading,
    setIsPlannedSuccessful,
    setLastDeletedShiftPlanningId,
    setLastAddedShiftPlanning,
    setShiftPlanning,
} = shiftPlanningSlice.actions;

export default shiftPlanningSlice.reducer;
