import { FC, ReactElement } from 'react';

import { EmploymentStatusFilterContainer } from '../../../containers';
import { setOnlyShowShiftsWithAbsence, setOnlyShowShiftsWithOpenLeaveOfAbsence } from '../../../redux/@interface/globalFilters/globalFiltersReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

const ConnectedEmploymentStatusFilter: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const onlyShowShiftsWithAbsence = useTypedSelector(state => state.globalFiltersReducer.onlyShowShiftsWithAbsence);
    const onlyShowShiftsWithOpenLeaveOfAbsence = useTypedSelector(state => state.globalFiltersReducer.onlyShowShiftsWithOpenLeaveOfAbsence);

    const handleShowAllShiftsWithAbsencesChange = (show: boolean) => {
        dispatch(setOnlyShowShiftsWithAbsence(show));
    };

    const handleShowAllShiftsWithLeaveOfOpenAbsencesChange = (show: boolean) => {
        dispatch(setOnlyShowShiftsWithOpenLeaveOfAbsence(show));
    };

    return (
        <EmploymentStatusFilterContainer
            showAllShiftsWithAbsences={onlyShowShiftsWithAbsence}
            showAllShiftsWithOpenLeaveOfAbsences={onlyShowShiftsWithOpenLeaveOfAbsence}
            onShowAllShiftsWithAbsencesChange={handleShowAllShiftsWithAbsencesChange}
            onShowAllShiftsWithOpenLeaveOfAbsencesChange={handleShowAllShiftsWithLeaveOfOpenAbsencesChange}
        />
    );
};

export default ConnectedEmploymentStatusFilter;
