import { combineReducers } from 'redux';

import pacoReducers, { Reducers as PacoReducers } from '../@paco/redux/reducers';
import openTracksReducer, { OpenTracksState } from './@toolkit/@tracks/openTracks/openTracksReducer';
import tracksToReviewReducer, { TracksToReviewState } from './@toolkit/@tracks/tracksToReview/tracksToReviewReducer';
import absencesReducerToolkit, { AbsencesState } from './@toolkit/absences/absencesReducer';
import baseScheduleReducer, { BaseScheduleState } from './@toolkit/baseSchedule/baseScheduleReducer';
// Toolkit
import baseSchedulesReducer, { BaseSchedulesState } from './@toolkit/baseSchedules/baseSchedulesReducer';
import baseScheduleShiftReducer, { BaseScheduleShiftState } from './@toolkit/baseScheduleShift/baseScheduleShiftReducer';
import baseScheduleShiftPlanningReducer, { BaseScheduleShiftPlanningState } from './@toolkit/baseScheduleShiftPlanning/baseScheduleShiftPlanningReducer';
import departmentsReducer, { DepartmentsState } from './@toolkit/departments/departmentsReducer';
import incompleteLeaveOfAbsencesReducer, { IncompleteLeaveOfAbsencesState } from './@toolkit/leaveOfAbsences/incompleteLeaveOfAbsences/incompleteLeaveOfAbsencesReducer';
import leaveOfAbsenceReducer, { LeaveOfAbsencesState } from './@toolkit/leaveOfAbsences/leaveOfAbsenceReducer';
import openLeaveOfAbsencesReducer, { OpenLeaveOfAbsencesState } from './@toolkit/leaveOfAbsences/openLeaveOfAbsences/openLeaveOfAbsencesReducer';
import shiftConceptReducer, { ShiftConceptState } from './@toolkit/shiftConcept/shiftConceptReducer';
import shiftConceptsReducer, { ShiftConceptsState } from './@toolkit/shiftConcepts/shiftConceptsReducer';
import specialDaysReducer, { SpecialDaysState } from './@toolkit/specialDays/specialDaysReducer';
import absencesReducer from './absences/absencesReducer';
import { AbsencesReducer } from './absences-ts/absencesModels';
import appReducer from './app/appReducer';
import authenticationReducer from './authentication/authenticationReducer';
import { AuthenticationReducer } from './authentication-ts/authenticationModels';
import feedbackReducer from './feedback/feedbackReducer';
import filterReducer from './filter/filterReducer';
import { FilterReducer } from './filter-ts/filterModels';
import logsReducer from './logs/logsReducer';
import { LogsReducer } from './logs-ts/logsModels';
import managementReducer from './management/managementReducer';
import { ManagementReducer } from './management-ts/managementModels';
import { PaginationReducer } from './pagination/paginationModels';
import paginationReducer from './pagination/paginationReducer';
import { PzReducer } from './pz/pzModels';
import pzReducer from './pz/pzReducer';
import settingsReducer from './settings/settingsReducer';
import shiftsReducer from './shifts/shiftsReducer';
import tracksReducer from './tracks/tracksReducer';
import usersReducer from './users/usersReducer';
import { WeekNavigatorReducer } from './weekNavigator/weekNavigatorModels';
import weekNavigatorReducer from './weekNavigator/weekNavigatorReducer';


// TODO: write types
export interface Reducers extends PacoReducers {
    [type: string]: any;
    absencesReducer: AbsencesReducer;
    absencesReducerToolkit: AbsencesState;
    authenticationReducer: AuthenticationReducer;
    baseSchedulesReducer: BaseSchedulesState;
    baseScheduleReducer: BaseScheduleState;
    baseScheduleShiftReducer: BaseScheduleShiftState;
    baseScheduleShiftPlanningReducer: BaseScheduleShiftPlanningState;
    departmentsReducer: DepartmentsState;
    filterReducer: FilterReducer;
    incompleteLeaveOfAbsencesReducer: IncompleteLeaveOfAbsencesState;
    leaveOfAbsenceReducer: LeaveOfAbsencesState;
    logsReducer: LogsReducer;
    managementReducer: ManagementReducer;
    openLeaveOfAbsencesReducer: OpenLeaveOfAbsencesState;
    openTracksReducer: OpenTracksState;
    paginationReducer: PaginationReducer;
    pzReducer: PzReducer;
    shiftConceptReducer: ShiftConceptState;
    shiftConceptsReducer: ShiftConceptsState;
    specialDaysReducer: SpecialDaysState;
    tracksToReviewReducer: TracksToReviewState;
    weekNavigatorReducer: WeekNavigatorReducer;
}

// @ts-ignore
export default combineReducers<Reducers>({
    absencesReducer,
    absencesReducerToolkit,
    appReducer,
    authenticationReducer,
    baseSchedulesReducer,
    baseScheduleReducer,
    baseScheduleShiftReducer,
    baseScheduleShiftPlanningReducer,
    departmentsReducer,
    feedbackReducer,
    filterReducer,
    incompleteLeaveOfAbsencesReducer,
    leaveOfAbsenceReducer,
    logsReducer,
    managementReducer,
    openLeaveOfAbsencesReducer,
    openTracksReducer,
    paginationReducer,
    pzReducer,
    settingsReducer,
    shiftConceptReducer,
    shiftConceptsReducer,
    shiftsReducer,
    specialDaysReducer,
    tracksReducer,
    tracksToReviewReducer,
    usersReducer,
    weekNavigatorReducer,
    ...pacoReducers,
});
