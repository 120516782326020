import {
    all,
    call, put, select, takeLatest,
} from 'redux-saga/effects';

import { getPreferToWorkTimeSlots, getUnavailableWorkTimeSlots } from '../../helpers/api/shiftsApi';
import { fetchEmployees } from '../../helpers/api/usersApi';
import {
    USERS_GET_EMPLOYEES_FAILURE,
    USERS_GET_EMPLOYEES_REQUEST,
    USERS_GET_EMPLOYEES_SUCCESS,
    USERS_MAPPED_ABSENCE_DATA_TO_EMPLOYEES,
    USERS_SAGA_GET_EMPLOYEES,
} from '../actionTypes';
import { fetchAbsencesData } from '../shifts/shiftsHelpers';
import { mapAbsencesDataToEmployees, mapPlanningPrefDataToEmployees } from './usersHelpers';

function* fetchAbsenceDataAndMapToEmployees(employees, start, end) {
    const [preferToWorkTimeSlots, unavailableWorkTimeSlots] = yield all([
        call(getPreferToWorkTimeSlots, start, end),
        call(getUnavailableWorkTimeSlots, start, end),
        fetchAbsencesData(yield select()),
    ]);

    const state = yield select();
    const { leaveOfAbsencesRequests, absencesInDateRange } = state.absencesReducer;
    let mappedEmployees = mapAbsencesDataToEmployees(employees, leaveOfAbsencesRequests, absencesInDateRange, start, end);

    mappedEmployees = mapPlanningPrefDataToEmployees(
        mappedEmployees,
        preferToWorkTimeSlots.data,
        unavailableWorkTimeSlots.data,
    );

    yield put({ type: USERS_MAPPED_ABSENCE_DATA_TO_EMPLOYEES, employees: mappedEmployees });
}

export function* getEmployees(action) {
    yield put({ type: USERS_GET_EMPLOYEES_REQUEST });

    try {
        const { start, department, includeDepartments } = action;
        const employees = yield call(() => fetchEmployees(start, department, includeDepartments));

        yield put({
            type: USERS_GET_EMPLOYEES_SUCCESS,
            start: action.start,
            end: action.end,
            department: action.department,
            employees: employees.data,
        });

        // If end, then we can compare start and end times of absences data
        // (only on planning/calendar)
        if (action.end) {
            yield fetchAbsenceDataAndMapToEmployees(employees.data, action.start, action.end);
        }
    } catch (error) {
        yield put({ type: USERS_GET_EMPLOYEES_FAILURE, error });
    }
}

export default function* usersWatcher() {
    yield takeLatest(USERS_SAGA_GET_EMPLOYEES, getEmployees);
}
