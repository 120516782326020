import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { FormOption } from '../../types';
import { EventLogActionType } from '../../types/eventLogTypes';
import { EventLogCategory } from '../EventLogCategory/EventLogCategory';
import {
    EventLog,
    EventLogChange,
    EventLogChangeResource,
    EventLogDataItem,
    EventLogDataItemResource,
    EventLogEntityType,
    EventLogResource,
} from './EventLog';

export const transformToEventLogChange = (resource: EventLogChangeResource, key: string): EventLogChange => ({
    label: key,
    from: typeof resource.from === 'string' ? resource.from : JSON.stringify(resource.from),
    to: typeof resource.to === 'string' ? resource.to : JSON.stringify(resource.to),
});

export const transformToEventLogDataItem = (value: EventLogDataItemResource | null, key: string): EventLogDataItem => ({
    value: typeof value === 'string' ? value : JSON.stringify(value),
    label: key,
});

export const transformToEventLog = (resource: EventLogResource): EventLog => {
    const resourceChanges = resource.changes || {};
    const changes = Object
        .keys(resourceChanges)
        .reduce((total, key) => ([
            ...total,
            transformToEventLogChange(resourceChanges[key], key),
        ]), [] as EventLogChange[]);

    const resourceData = resource.data || {};
    const logDataItems = Object
        .keys(resourceData)
        .reduce((total, key) => ([
            ...total,
            transformToEventLogDataItem(resourceData[key], key),
        ]), [] as EventLogDataItem[]);

    const entityTypeKey = getEnumKeyByEnumValue(EventLogEntityType, resource.entityType);
    const actionTypeKey = getEnumKeyByEnumValue(EventLogActionType, resource.action);

    if (!entityTypeKey) {
        console.error(`[transformToEventLog]: Unknown enum found named "${resource.entityType}".`);
    }

    if (!actionTypeKey) {
        console.error(`[transformToEventLog]: Unknown enum found named "${resource.action}".`);
    }

    return {
        id: resource.uuid,
        action: actionTypeKey ? EventLogActionType[actionTypeKey] : EventLogActionType.created,
        categorySlug: resource.category,
        changes,
        createdAt: new Date(resource.createdAt),
        createdBy: resource.createdBy,
        data: logDataItems,
        entityType: entityTypeKey ? EventLogEntityType[entityTypeKey] : EventLogEntityType.other,
        entityUuid: resource.entityUuid,
        message: resource.message,
        updatedAt: new Date(resource.updatedAt),
    };
};

export const transformEventLogCategoryToFormOption = (eventLogCategory: EventLogCategory): FormOption => ({
    label: eventLogCategory.name,
    value: eventLogCategory.slug,
});
