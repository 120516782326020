import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import { compareAsc } from '../../helpers/date';
import {
    ShiftDraggable,
    ShiftPlanningViewModel,
    ShiftViewModel,
} from '../../models';


export default function isShiftPlanningTimeDeviated(
    shiftPlanning: ShiftPlanningViewModel,
    shift: ShiftViewModel | ShiftDraggable | ShiftConceptViewModelOld | BaseScheduleShiftViewModel,
) {
    return (compareAsc(shiftPlanning.start, shift.start) !== 0
        || compareAsc(shiftPlanning.end.date, shift.end.date) !== 0);
}
