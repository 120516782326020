import { convertDateToApiParamDateTime } from '../../../helpers/date';
import { CopyShiftPlanningData, ShiftPlanning } from '../../../models';

export default function transformShiftToCopyShiftPlanningData(
    shiftPlanning: ShiftPlanning,
    shiftId: string,
): CopyShiftPlanningData {
    return {
        shiftId,
        userId: shiftPlanning.user.id,
        start: convertDateToApiParamDateTime(shiftPlanning.start),
        end: convertDateToApiParamDateTime(shiftPlanning.end),
        planned: shiftPlanning.planned,
        color: shiftPlanning.color,
    };
}
