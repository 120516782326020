import { FC } from 'react';

import classNames from 'classnames';

import UserPageLink from '../../../../../components/UserPageLink/UserPageLink';
import { AbsenceBadge, EmploymentBadge, Skeleton } from '../../../../components';
import { Absence } from '../../../../entities/Absence/Absence';
import { Comment } from '../../../../entities/Comment/Comment';
import { Department } from '../../../../entities/Department/Department';
import { EmploymentType } from '../../../../entities/EmploymentType/EmploymentType';
import { Experience } from '../../../../entities/Experience/Experience';
import { Period } from '../../../../entities/Period/Period';
import { getTimeOnlyLabel } from '../../../../entities/Period/PeriodHelpers';
import trans from '../../../../helpers/trans';
import { getShiftTrackPeriodText } from '../../helpers';
import { ShiftsWidgetDepartment } from '../index';

import './ShiftsWidgetShift.scss';

export interface DashboardShiftsWidgetShiftProps {
    id: string;
    isCompanyMedic?: boolean;
    isTracksLoading?: boolean;
    showEmploymentType?: boolean;
    showExperience?: boolean;
    absence?: Absence;
    comment?: Comment;
    department?: Department;
    employmentType?: EmploymentType;
    experience?: Experience;
    shiftPeriod?: Period;
    trackPeriod?: Period;
    userFullName: string;
    userId: string;
    className?: string;
}

const ShiftsWidgetShift: FC<DashboardShiftsWidgetShiftProps> = ({
    id,
    isCompanyMedic,
    isTracksLoading,
    showEmploymentType,
    showExperience,
    absence,
    comment,
    department,
    employmentType,
    experience,
    shiftPeriod,
    trackPeriod,
    userFullName,
    userId,
    className = '',
}) => {
    const shiftClassName = classNames('shifts-widget-shift', {
        'shifts-widget-shift--not-yet-clocked-in': !trackPeriod,
        'shifts-widget-shift--clocked-in': trackPeriod?.hasEnd === false,
        'shifts-widget-shift--clocked-out': !!trackPeriod?.hasEnd,
        'shifts-widget-shift--is-company-medic': isCompanyMedic,
    }, className);

    return (
        <div key={id} className={shiftClassName}>
            <div className="shifts-widget-shift__user-info-wrapper">
                <span className="shifts-widget-shift__user-info">
                    <UserPageLink
                        id={userId}
                        name={userFullName}
                        className="shifts-widget-shift__user-link"
                    />
                    {showEmploymentType && employmentType && (
                        <EmploymentBadge
                            employment={employmentType}
                            className="shifts-widget-shift__employment-badge"
                        />
                    )}
                    {showExperience && experience && (
                        <div className="shifts-widget-shift__experience">
                            {experience.name}
                        </div>
                    )}
                    {comment && (
                        <div className="shifts-widget-shift__comment">
                            {comment.body}
                        </div>
                    )}
                </span>
                <ShiftsWidgetDepartment
                    department={department?.name || ''}
                    group={department?.departmentGroup?.name}
                    className="shifts-widget-shift__department-mobile"
                />
            </div>
            <ShiftsWidgetDepartment
                department={department?.name || ''}
                group={department?.departmentGroup?.name}
                className="shifts-widget-shift__department"
            />
            <div className="shifts-widget-shift__shift-time">
                {shiftPeriod ? getTimeOnlyLabel(shiftPeriod.start, shiftPeriod.end) : trans('common.unplanned')}
            </div>

            <div className="shifts-widget-shift__track-time">
                {isTracksLoading ? (
                    <Skeleton className="shifts-widget-shift__skeleton-item" />
                ) : (
                    <>
                        <span>
                            {getShiftTrackPeriodText(trackPeriod, absence)}
                        </span>
                        {(absence && trackPeriod) && (
                            <AbsenceBadge
                                isCompact
                                absence={absence}
                                className="shifts-widget-shift__badge--is-compact"
                            />
                        )}
                        {(absence && !trackPeriod) && (
                            <AbsenceBadge
                                absence={absence}
                                className="shifts-widget-shift__badge"
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ShiftsWidgetShift;
