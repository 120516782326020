import { DistrictDivisionDistrictTemplate, DistrictDivisionDistrictTemplateForm, DistrictDivisionDistrictTemplateResource } from '../DistrictDivisionDistrictTemplate/DistrictDivisionDistrictTemplate';
import { transformToDistrictDivisionDistrictTemplate } from '../DistrictDivisionDistrictTemplate/DistrictDivisionDistrictTemplateTransformers';
import {
    AddDistrictDivisionGroupTemplateFormData,
    AddDistrictDivisionGroupTemplateRequest,
    DistrictDivisionGroupTemplate,
    DistrictDivisionGroupTemplateResource,
    EditDistrictDivisionGroupTemplateFormData,
    EditDistrictDivisionGroupTemplateRequest,
} from './DistrictDivisionGroupTemplate';

export const transformToDistrictDivisionGroupTemplate = (
    districtDivisionGroupTemplateResource: DistrictDivisionGroupTemplateResource,
    districtDivisionDistrictTemplatesResource: DistrictDivisionDistrictTemplateResource[],
): DistrictDivisionGroupTemplate => ({
    id: districtDivisionGroupTemplateResource.id,
    name: districtDivisionGroupTemplateResource.attributes.name,
    districtDivisionDistrictTemplates: districtDivisionDistrictTemplatesResource ? districtDivisionDistrictTemplatesResource.map(transformToDistrictDivisionDistrictTemplate) : [],
    order: districtDivisionGroupTemplateResource.attributes.groupOrder,
});

export const transformDistrictDivisionDistrictTemplateFormToDistrictDivisionDistrictTemplate = (
    districtDivisionDistrictTemplateForm: DistrictDivisionDistrictTemplateForm[],
): DistrictDivisionDistrictTemplate[] => districtDivisionDistrictTemplateForm.map(district => ({
    id: district.id,
    name: district.name,
    maxAmountOfEmployees: district.maxAmountOfEmployees,
}));

export const transformDistrictDivisionDistrictTemplateToDistrictDivisionDistrictTemplateForm = (
    districtDivisionDistrictTemplates: DistrictDivisionDistrictTemplate[],
): DistrictDivisionDistrictTemplateForm[] => districtDivisionDistrictTemplates.map((district, index) => ({
    id: district.id,
    name: district.name,
    maxAmountOfEmployees: district.maxAmountOfEmployees,
    order: index,
}));

export const transformDistrictDivisionGroupTemplateToEditDistrictDivisionGroupTemplateFormData = (
    group: DistrictDivisionGroupTemplate,
    districtDivisionTemplateId: string,
): EditDistrictDivisionGroupTemplateFormData => ({
    id: group.id,
    name: group.name,
    districtDivisionDistrictTemplates: group.districtDivisionDistrictTemplates,
    districtDivisionTemplateId,
    order: group.order,
});

export const transformAddDistrictDivisionGroupTemplateFormDataToRequest = (
    data: AddDistrictDivisionGroupTemplateFormData,
): AddDistrictDivisionGroupTemplateRequest => ({
    type: 'districtDivisionGroupTemplates',
    attributes: {
        name: data.name,
        districtDivisionDistrictTemplates: data.districtDivisionDistrictTemplates,
        groupOrder: data.order,
    },
    relationships: {
        districtDivisionTemplate: {
            data: {
                type: 'districtDivisionTemplates',
                id: data.districtDivisionTemplateId,
            },
        },
    },
});

export const transformEditDistrictDivisionGroupTemplateFormDataToEditApiParams = (
    data: EditDistrictDivisionGroupTemplateFormData,
): EditDistrictDivisionGroupTemplateRequest => ({
    id: data.id,
    type: 'districtDivisionGroupTemplates',
    attributes: {
        name: data.name,
        districtDivisionDistrictTemplates: data.districtDivisionDistrictTemplates,
        groupOrder: data.order,
    },
    relationships: {
        districtDivisionTemplate: {
            data: {
                type: 'districtDivisionTemplates',
                id: data.districtDivisionTemplateId,
            },
        },
    },
});
