import React, { FC } from 'react';

import { LoadingSpinner } from '../../../../../../@paco/components';
import { translate } from '../../../../../../helpers/translations/translator';
import { ShiftViewModel } from '../../../../../../models';
import ShiftButton from '../ShiftButton/ShiftButton';

import './ShiftButtonsList.scss';

interface Props {
    loading: boolean;
    shifts: ShiftViewModel[];
    onShiftClick: (shiftId: string) => void;
    activeShiftId: string | null;
}

const ShiftButtonsList: FC<Props> = ({
    loading,
    shifts,
    onShiftClick,
    activeShiftId,
}) => {
    if (loading) {
        return (
            <div className="shift-buttons-list shift-buttons-list--loading">
                <LoadingSpinner
                    isSmall
                    className="shift-buttons-list__loading-spinner"
                />
            </div>
        );
    }

    if (!shifts.length) {
        return (
            <div className="shift-buttons-list">
                {translate('pages.shifts.noShiftsFound')}
            </div>
        );
    }

    return (
        <div className="shift-buttons-list">
            {shifts.map((shift) => (
                <ShiftButton
                    key={shift.id}
                    shift={shift}
                    onClick={onShiftClick}
                    active={activeShiftId === shift.id}
                />
            ))}
        </div>
    );
};

export default ShiftButtonsList;
