import { FC, ReactElement } from 'react';

import trans from '../../../helpers/trans';
import { IdentificationType, SelectOption } from '../../../types';
import { SelectInput } from '../../index';

const generateIdentificationOptions = (): SelectOption<IdentificationType>[] => {
    const identificationArray = Object.values(IdentificationType);

    return identificationArray.map(identification => ({
        label: trans(`types.identification.${identification}`),
        value: identification,
    })) as SelectOption<IdentificationType>[];
};

const identificationOptions = generateIdentificationOptions();

interface IdentificationSelectInputProps {
    identificationType: SelectOption<IdentificationType>;
    onChange: (option: SelectOption<IdentificationType>) => void;
}

const IdentificationSelectInput: FC<IdentificationSelectInputProps> = ({
    identificationType,
    onChange,
}): ReactElement => {
    const onIdentificationStateChange = (identificationValue: SelectOption): void => {
        onChange(identificationValue as SelectOption<IdentificationType>);
    };

    return (
        <SelectInput
            isSearchable={false}
            label={trans('common.identification')}
            name="identification"
            value={identificationType}
            options={identificationOptions}
            onChange={onIdentificationStateChange}
        />
    );
};

export default IdentificationSelectInput;
