import { FC, ReactElement } from 'react';

import { useToggle } from 'react-use';

import { FilterSection } from '../../../components';
import { IconButton, ModalContent, PacoModal } from '../../../compositions';
import { ConnectedAddDistrictDivisionTemplateForm } from '../../../connectors';
import useCheckPermission from '../../../helpers/hooks/useCheckPermission';
import trans from '../../../helpers/trans';

import './DistrictDivisionTemplatesFilterContainer.scss';

interface DistrictDivisionTemplatesFilterContainerProps {
    className?: string;
}

const DistrictDivisionTemplatesFilterContainer: FC<DistrictDivisionTemplatesFilterContainerProps> = ({ className = '' }): ReactElement => {
    const [addDistrictDivisionTemplateModalIsOpen, toggleAddDistrictDivisionTemplateModalIsOpen] = useToggle(false);
    const canAddDistrictDivisionTemplates = useCheckPermission('add-new-district-division-templates');

    return (
        <>
            <form className={`district-division-templates-filter-container ${className}`}>
                {canAddDistrictDivisionTemplates && (
                    <FilterSection>
                        <IconButton
                            icon="plus"
                            text={trans('common.addDistrictDivision')}
                            onClick={toggleAddDistrictDivisionTemplateModalIsOpen}
                            className="district-division-templates-filter-container__add-district-division-button"
                        />
                    </FilterSection>
                )}
                <FilterSection />
            </form>
            {addDistrictDivisionTemplateModalIsOpen && (
                <PacoModal cardClassName="district-division-templates-filter-container__add-district-division-template-modal">
                    <ModalContent title={trans('common.addDistrictDivision')}>
                        <ConnectedAddDistrictDivisionTemplateForm onClose={toggleAddDistrictDivisionTemplateModalIsOpen} />
                    </ModalContent>
                </PacoModal>
            )}
        </>
    );
};

export default DistrictDivisionTemplatesFilterContainer;
