import { Dispatch } from 'redux';

import { getIncompleteLeaveOfAbsencesInPeriod } from '../../../../helpers/api-ts/leaveOfAbsence';
import { PayrollPeriodViewModel } from '../../../../models';
import { EmploymentTypeSlug } from '../../../../models/EmploymentType';
import getLeaveOfAbsencesWithNullHoursInPayrollPeriod from '../../../../services/LeaveOfAbsenceLegacyService/getLeaveOfAbsencesWithNullHoursInPayrollPeriod';
import { deductOneSecondFromLOARequests } from '../../../absences-ts/absencesHelpers';
import { setError, setIncompleteLeaveOfAbsences, setIsLoading } from './incompleteLeaveOfAbsencesReducer';

export const fetchIncompleteLeaveOfAbsences = (payrollPeriod: PayrollPeriodViewModel) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const { start, end } = payrollPeriod;

    try {
        const endDate = end.transformToUpToButExcludingDate();

        const response = await getIncompleteLeaveOfAbsencesInPeriod(start, endDate.date);

        // TODO: Use proper transformer to LeaveOfAbsenceViewModel in next refactor
        const leaveOfAbsences = deductOneSecondFromLOARequests(response);
        const filteredLeaveOfAbsences = getLeaveOfAbsencesWithNullHoursInPayrollPeriod(leaveOfAbsences, payrollPeriod);
        const filteredLeaveOfAbsencesWithFilteredUsers = filteredLeaveOfAbsences
            .filter(leaveOfAbsence => leaveOfAbsence.user.employmentType?.slug !== (EmploymentTypeSlug.partTime || EmploymentTypeSlug.temporaryWorker));

        dispatch(setIncompleteLeaveOfAbsences(filteredLeaveOfAbsencesWithFilteredUsers));
    } catch (error) {
        console.error('[fetchIncompleteLeaveOfAbsences]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
