import React from 'react';

import './ExchangeShiftDivider.scss';

const ExchangeShiftDivider = () => (
    <div className="exc-shift-divider">
        <div className="exc-shift-divider__line" />
        <svg className="exc-shift-divider__arrow" viewBox="0 0 12 22">
            {/* eslint-disable-next-line max-len */}
            <path d="M0.550195 5.45L6.0002 0L11.4502 5.45L10.0402 6.86L7.0002 3.83L7.0002 18.17L10.0402 15.14L11.4502 16.55L6.0002 22L0.550195 16.55L1.96019 15.14L5.0002 18.17L5.0002 3.83L1.96019 6.86L0.550195 5.45Z" />
        </svg>
    </div>
);

export default ExchangeShiftDivider;
