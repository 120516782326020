import {
    FC,
    FormEvent,
    useMemo,
    useRef,
    useState,
} from 'react';

import { FormActionButtons } from '../../../compositions';
import WidgetsList from '../../../compositions/WidgetsList/WidgetsList';
import { EditDashboardFormData } from '../../../entities/DashboardSetting/DashboardSetting';
import { Permission } from '../../../entities/Permission/Permission';
import trans from '../../../helpers/trans';
import { DashboardSettingType } from '../../../types';
import { DashboardSmallWidget } from '../../../types/dashboardTypes';
import { ModalFormProps } from '../../../types/modalFormTypes';
import { getRemainingUserWidgets, getSelectedWidgets } from './helpers';

import './EditDashboardForm.scss';

interface EditDashboardFormProps extends ModalFormProps<EditDashboardFormData> {
    isLoading: boolean;
    permissions: Permission[];
    userWidgetTypes: DashboardSettingType[];
}

const EditDashboardForm: FC<EditDashboardFormProps> = ({
    isLoading,
    permissions,
    userWidgetTypes,
    onCancel,
    onSubmit,
}) => {
    const formRef = useRef<HTMLFormElement>(null);

    const [selectedWidgets, setSelectedWidgets] = useState(getSelectedWidgets(userWidgetTypes, permissions));

    const availableWidgets = useMemo(() => getRemainingUserWidgets(selectedWidgets, permissions), [selectedWidgets]);

    const handleAddWidget = (widgets: DashboardSmallWidget[]) => {
        const remainingWidgets = getRemainingUserWidgets([...selectedWidgets, ...widgets], permissions);
        setSelectedWidgets([...selectedWidgets, ...remainingWidgets]);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        onSubmit({
            userWidgetTypes: selectedWidgets.map(widget => widget.type),
        });
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="edit-dashboard-form">
            {(!selectedWidgets.length && !availableWidgets.length) && (
                <div className="edit-dashboard-form__no-available-widgets">
                    {trans('containers.forms.editDashboardForm.noAvailableWidgets')}
                </div>
            )}
            {!!selectedWidgets.length && (
                <WidgetsList
                    isDraggable
                    title={trans('containers.forms.editDashboardForm.addedWidgets')}
                    widgets={selectedWidgets}
                    onWidgetsChange={setSelectedWidgets}
                    className="edit-dashboard-form__widgets-list"
                />
            )}
            {!!availableWidgets.length && (
                <WidgetsList
                    title={trans('containers.forms.editDashboardForm.availableWidgets')}
                    widgets={availableWidgets}
                    onWidgetsChange={handleAddWidget}
                    className="edit-dashboard-form__widgets-list"
                />
            )}
            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default EditDashboardForm;
