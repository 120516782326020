import { DistrictDivisionDistrict } from '../DistrictDivisionDistrict/DistrictDivisionDistrict';
import { DistrictDivisionGroup, DistrictDivisionGroupResource } from './DistrictDivisionGroup';

export const transformDistrictDivisionGroup = (
    districtDivisionGroupResource: DistrictDivisionGroupResource,
    districtDivisionDistrict: DistrictDivisionDistrict[],
): DistrictDivisionGroup => ({
    id: districtDivisionGroupResource.id,
    name: districtDivisionGroupResource.attributes.name,
    districtDivisionDistricts: districtDivisionDistrict,
    order: districtDivisionGroupResource.attributes.groupOrder,
});
