import React, { PropsWithChildren } from 'react';

import { Table } from 'reactstrap';

import TableColumn from './subcomponents/TableColumn';
import TableHead, { TableAttribute } from './subcomponents/TableHead';
import TableRow, { TableRowItem } from './subcomponents/TableRow';

interface TableContainerProps<I> {
    data: TableRowItem<I>[];
    attributes: TableAttribute<I>[];
    responsive?: boolean;
    activeId?: string;
    onRowClick?: (id: string) => void;
    className?: string;
    tableRowClassName?: string;
}

const TableContainer = <I, >({
    data,
    attributes,
    responsive = true,
    activeId,
    onRowClick,
    className = '',
    tableRowClassName = '',
}: PropsWithChildren<TableContainerProps<I>>) => {
    const handleRowClick = (id: string): void => {
        if (onRowClick) {
            onRowClick(id);
        }
    };

    const renderTableContainer = () => (
        <Table>
            <thead>
                <tr>
                    {attributes.map((attribute, index) => (
                        <TableHead
                            key={`${attribute.key}-${index + 1}`}
                            attribute={attribute}
                            index={index}
                        />
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item, rowIndex) => (
                    <TableRow
                        hasHoverEffect={!!onRowClick}
                        item={item}
                        key={`${item.id}-${rowIndex + 1}`}
                        activeId={activeId}
                        className={tableRowClassName}
                    >
                        {attributes.map((attribute, columnIndex) => {
                            const handleColumnClick = (): void => {
                                handleRowClick(item.id);
                            };

                            return (
                                <TableColumn
                                    key={`${item.id}-${columnIndex + 1}`}
                                    item={item}
                                    attribute={attribute}
                                    index={columnIndex}
                                    onClick={!attribute.isButton && onRowClick ? handleColumnClick : undefined}
                                />
                            );
                        })}
                    </TableRow>
                ))}
            </tbody>
        </Table>
    );

    if (responsive) {
        return (
            <div className={`table-container-responsive ${className}`}>
                <div className="table-container">
                    { renderTableContainer() }
                </div>
            </div>
        );
    }

    return (
        <div className={className}>
            {renderTableContainer()}
        </div>
    );
};

export default TableContainer;
