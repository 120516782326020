import React, { FC } from 'react';

import { translate } from '../../../../../../helpers/translations/translator';

import './UserWithHourBalancesHead.scss';


const UsersWithLoketBalancesHead: FC = () => (
    <thead className="users-with-loket-balances-head">
        <tr>
            <th className="users-with-loket-balances-head__name">
                {translate('common.name')}
                &emsp;
                {translate('pages.management.plannedHours')}
                {' / '}
                {translate('pages.management.contractHours')}
            </th>
            <th>
                {translate('pages.shifts.workedHours')}
            </th>
            <th>
                {translate('pages.absences.sickHours')}
            </th>
            <th>
                {translate('pages.absences.usedLeaveOfAbsenceHours')}
            </th>
            <th>
                {translate('pages.shifts.usedTvt')}
            </th>
            <th>
                {translate('pages.shifts.mutatedTvt')}
            </th>
            <th>
                {translate('pages.absences.leaveOfAbsenceBalance')}
            </th>
            <th>
                {translate('pages.shifts.tvtBalance')}
            </th>
        </tr>
    </thead>
);

export default UsersWithLoketBalancesHead;
