import { GroupedSelectOption } from '../../../../components/@inputs/Select/Select';
import { Department } from '../../../../entities/Department/Department';
import { DepartmentGroup } from '../../../../entities/DepartmentGroup/DepartmentGroup';
import { getUniqueArrayList } from '../../../../helpers/array';
import trans from '../../../../helpers/trans';
import { DepartmentOption } from '../../../../types/selectOptionTypes';

const getDepartmentGroups = (departments: Department[]): DepartmentGroup[] => {
    const groups = departments
        .map(department => department.departmentGroup)
        .filter(group => group && group.id);

    return getUniqueArrayList(groups, 'id') as DepartmentGroup[];
};

// Generate departments for a react-select component
export const generateGroupedDepartmentOptions = (departments: Department[]): GroupedSelectOption[] => {
    const departmentGroups = getDepartmentGroups(departments);

    const groupedDepartments = departmentGroups.map(group => ({
        label: group.name,
        value: group.id,
        options: departments
            .filter(department => department.departmentGroup?.id === group.id)
            .sort((departmentA, departmentB) => (departmentA.name).localeCompare(departmentB.name))
            .map(department => ({ isDeleted: !!department.deletedAt, value: department.id, label: department.name })),
    }));

    const ungroupedDepartments: DepartmentOption[] = departments
        .filter(department => !department.departmentGroup)
        .sort((departmentA, departmentB) => (departmentA.name).localeCompare(departmentB.name))
        .map(department => ({ isDeleted: !!department.deletedAt, value: department.id, label: department.name }));

    return [...groupedDepartments, { label: trans('common.looseDepartments'), options: ungroupedDepartments }];
};
