import { FC, ReactElement, useEffect } from 'react';

import { useEffectOnce, useToggle } from 'react-use';

import { getUsersQuickly } from '../../../../redux/management/managementActions';
import { AddUserForm, SelectUserFromLoketForm } from '../../../containers';
import { EmploymentType } from '../../../entities/EmploymentType/EmploymentType';
import { Experience } from '../../../entities/Experience/Experience';
import { Role } from '../../../entities/Role/Role';
import { AddUserFormData } from '../../../entities/User/User';
import useCheckPermission from '../../../helpers/hooks/useCheckPermission';
import trans from '../../../helpers/trans';
import { fetchFullLoketUser, fetchUnsyncedEmployees } from '../../../redux/@forms/addUserForm/addUserFormActions';
import {
    reset as resetAddUserForm,
    setError as setAddUserFormError,
    setIsGetFullLoketUserSuccessful,
} from '../../../redux/@forms/addUserForm/addUserFormReducer';
import { fetchDepartmentOptions } from '../../../redux/@interface/options/optionsActions';
import { getPacoPayrollPeriods } from '../../../redux/payrollPeriods/payrollPeriodsActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { addUser, syncUserToLoket } from '../../../redux/user/userActions';
import {
    setError,
    setIsSuccessful,
    setIsSyncUserFailed,
    setIsSyncUserSuccessful,
} from '../../../redux/user/userReducer';
import { ToastType } from '../../../types';

interface ConnectedAddUserFormProps {
    employmentType: EmploymentType[];
    experiences: Experience[];
    roles: Role[];
    onClose: () => void;
}

const ConnectedAddUserForm: FC<ConnectedAddUserFormProps> = ({
    employmentType,
    experiences,
    roles,
    onClose,
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const canManageLoketData = useCheckPermission('manage-loket-data');

    const {
        isLoading: addUserFormIsLoading,
        isGetFullLoketUserSuccessful,
        error: addUserFormError,
        fullLoketUser,
        unsyncedEmployees,
    } = useTypedSelector(state => state.addUserFormReducer);
    const {
        isLoading: isUserLoading,
        isSuccessful,
        isSyncUserFailed,
        isSyncUserLoading,
        isSyncUserSuccessful,
        error,
        user,
    } = useTypedSelector(state => state.pacoUserReducer);
    const { isLoading: isDepartmentLoading, departmentOptions } = useTypedSelector(state => state.optionsReducer);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);

    const [selectUserFromLoketModalIsOpen, toggleSelectUserFromLoketModalIsOpen] = useToggle(canManageLoketData);

    const closeForm = () => {
        onClose();
        dispatch(getUsersQuickly());
    };

    useEffectOnce((): void => {
        dispatch(resetAddUserForm());
        dispatch(fetchDepartmentOptions());

        if (!payrollPeriods.length) {
            dispatch(getPacoPayrollPeriods());
        }

        if (canManageLoketData) {
            dispatch(fetchUnsyncedEmployees());
        }
    });

    useEffect((): void => {
        if (isGetFullLoketUserSuccessful) {
            dispatch(setIsGetFullLoketUserSuccessful(false));

            toggleSelectUserFromLoketModalIsOpen();
        }
    }, [isGetFullLoketUserSuccessful]);

    useEffect((): void => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.addUserForm.addUserSuccess'),
                type: ToastType.pass,
            }));

            if (user) {
                dispatch(syncUserToLoket(user.id));

                return;
            }

            closeForm();
        }
    }, [isSuccessful]);

    useEffect((): void => {
        if (isSyncUserSuccessful) {
            dispatch(setIsSyncUserSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.addUserForm.syncUserToLoketSuccess'),
                type: ToastType.pass,
            }));

            closeForm();
        }
    }, [isSyncUserSuccessful]);

    useEffect((): void => {
        if (isSyncUserFailed) {
            dispatch(setIsSyncUserFailed(false));

            dispatch(setToast({
                text: trans('containers.forms.addUserForm.syncUserToLoketFailed'),
                type: ToastType.fail,
            }));

            closeForm();
        }
    }, [isSyncUserFailed]);

    useEffect((): void => {
        if (error || addUserFormError) {
            dispatch(setToast({
                text: error,
                type: ToastType.fail,
            }));

            dispatch(setError(''));
            dispatch(setAddUserFormError(''));
        }
    }, [error, addUserFormError]);

    const handleLoketUserClick = (userId?: string): void => {
        if (!userId) {
            toggleSelectUserFromLoketModalIsOpen();

            return;
        }

        dispatch(fetchFullLoketUser(userId));
    };

    const handleAddUser = (addUserFormData: AddUserFormData): void => {
        dispatch(addUser(addUserFormData));
        dispatch(resetAddUserForm());
    };

    return (
        <>
            {(canManageLoketData && selectUserFromLoketModalIsOpen) && (
                <SelectUserFromLoketForm
                    isLoading={addUserFormIsLoading}
                    loketUsers={unsyncedEmployees}
                    onLoketUserClick={handleLoketUserClick}
                    onCancel={onClose}
                />
            )}

            {!selectUserFromLoketModalIsOpen && (
                <AddUserForm
                    isLoading={isUserLoading || isSyncUserLoading}
                    isDepartmentsLoading={isDepartmentLoading}
                    departments={departmentOptions}
                    experiences={experiences}
                    employmentTypes={employmentType}
                    fullLoketUser={fullLoketUser}
                    payrollPeriods={payrollPeriods}
                    roles={roles}
                    onSubmit={handleAddUser}
                    onCancel={onClose}
                />
            )}
        </>
    );
};

export default ConnectedAddUserForm;
