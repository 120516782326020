import {
    FC,
    ReactElement,
    useMemo,
    useRef,
} from 'react';

import { ErrorLabel } from '../../../components';
import { ContractHoursFormData } from '../../../entities/ContractHours/ContractHours';
import { alignContractHoursFormData, getEmptyContractFormData, getOverlappingContractHourWithDate } from '../../../entities/ContractHours/ContractHoursHelpers';
import { transformToPeriod } from '../../../entities/Period/PeriodTransformers';
import { compareAsc, getEndOfWorkDay, isSameDay } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { IconButton } from '../../index';
import { ContractHoursAndDateInput } from './subcomponents';

import './ContractHoursInput.scss';

interface ContractHoursInputProps {
    isDisabled?: boolean;
    contractHours?: ContractHoursFormData[];
    error?: string;
    onChange: (formData: ContractHoursFormData[]) => void;
    className?: string;
}

const ContractHoursInput: FC<ContractHoursInputProps> = ({
    isDisabled,
    contractHours = [],
    error,
    onChange,
    className = '',
}): ReactElement => {
    const detailsRef = useRef<HTMLDetailsElement>(null);
    const visibleContractHours = contractHours.filter((contractHour) => !contractHour.isDeleted);

    const currentContractHour = useMemo(() => (
        getOverlappingContractHourWithDate(new Date(), visibleContractHours) || visibleContractHours[visibleContractHours.length - 1]
    ), [visibleContractHours]);

    const handleContractHoursChange = (updatedContractHour: ContractHoursFormData): void => {
        const index = contractHours.findIndex((contractHour) => contractHour.id === updatedContractHour.id);
        const updatedContractHours = [...contractHours];

        updatedContractHours.splice(index, 1, updatedContractHour);

        onChange(updatedContractHours);
    };

    const handleMainContractHoursChange = (updatedContractHour: ContractHoursFormData): void => {
        const updatedContractHoursIsToday = isSameDay(updatedContractHour.period.start, new Date());
        const lastContractHour = visibleContractHours[visibleContractHours.length - 1];
        const lastContractHourIsTomorrowOrLater = compareAsc(getEndOfWorkDay(lastContractHour.period.start), new Date()) === 1;

        if (updatedContractHoursIsToday || lastContractHourIsTomorrowOrLater) {
            handleContractHoursChange(updatedContractHour);

            return;
        }

        const newContractHour = getEmptyContractFormData(undefined, updatedContractHour.hours);
        const updatedContractHours = alignContractHoursFormData([...contractHours, newContractHour]);

        detailsRef.current?.setAttribute('open', 'true');
        onChange(updatedContractHours);
    };

    const handleAddButtonClick = (): void => {
        const newContractHours = getEmptyContractFormData();

        const updatedContractHours = alignContractHoursFormData([...contractHours, newContractHours]);

        onChange(updatedContractHours);
    };

    const handleDeleteButtonClick = (id: string): void => {
        const index = contractHours.findIndex((contractHour) => contractHour.id === id);

        if (!index) {
            return;
        }

        // We can directly remove contractHours with no resource id. We don't need to send delete call to api.
        if (!contractHours[index].resourceId) {
            const updatedContractHours = [...contractHours];

            updatedContractHours.splice(index, 1);

            onChange(alignContractHoursFormData(updatedContractHours));

            return;
        }

        const updatedContractHour: ContractHoursFormData = {
            ...contractHours[index],
            period: transformToPeriod(new Date(0), new Date(0), true),
            isDeleted: true,
        };

        const updatedContractHours = [...contractHours];

        updatedContractHours.splice(index, 1, updatedContractHour);

        onChange(alignContractHoursFormData(updatedContractHours));
    };

    const handleBlur = (): void => {
        onChange(alignContractHoursFormData(contractHours));
    };

    return (
        <div className={`contract-hours-input ${className}`}>
            {currentContractHour && (
                <ContractHoursAndDateInput
                    hideDate
                    isDisabled={isDisabled}
                    contractHours={currentContractHour}
                    onBlur={handleBlur}
                    onChange={handleMainContractHoursChange}
                />
            )}

            <details ref={detailsRef}>
                <summary className="contract-hours-input__summary">
                    {trans('compositions.contractHoursInput.seeMutationsContractHours')}
                </summary>

                <div className="contract-hours-input__all-contract-hours">
                    {visibleContractHours.map(((contractHour, index) => (
                        <ContractHoursAndDateInput
                            key={contractHour.id}
                            hideDate={index === 0}
                            hideDeleteButton={contractHours.length === 1}
                            hideLabel={index !== contractHours.length - 1}
                            isDisabled={isDisabled}
                            contractHours={contractHour}
                            onChange={handleContractHoursChange}
                            onDeleteButtonClick={handleDeleteButtonClick}
                            onBlur={handleBlur}
                        />
                    )))}
                </div>

                {!isDisabled && (
                    <div className="contract-hours-input__add-button-wrapper">
                        <IconButton
                            icon="plus"
                            text={trans('compositions.contractHoursInput.addContractHours')}
                            onClick={handleAddButtonClick}
                            className="contract-hours-input__add-button"
                        />
                    </div>
                )}
            </details>

            {error && <ErrorLabel text={error} />}
        </div>
    );
};

export default ContractHoursInput;
