import { FC, PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

import './TableRow.scss';

interface TableRowProps {
    hasHoverState?: boolean;
    className?: string;
}

const TableRow: FC<PropsWithChildren<TableRowProps>> = ({ hasHoverState, className = '', children }): ReactElement => {
    const tableRowClassName = classNames('table-row', {
        'table-row--has-hover-state': hasHoverState,
    }, className);

    return (
        <tr className={tableRowClassName}>
            {children}
        </tr>
    );
};

export default TableRow;
