import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import ReactSelect, { OnChangeValue } from 'react-select';

import trans from '../../../helpers/trans';

import './Select.scss';

export interface SelectOption {
    label: string;
    value: string;
}

export interface GroupedSelectOption {
    label?: string;
    value?: string;
    options: SelectOption[];
}

interface SelectProps {
    hasPrimaryStyling?: boolean;
    hasSecondaryStyling?: boolean;
    isLoading: boolean;
    isSearchable?: boolean;
    id?: string;
    loadingMessage?: string;
    noOptionsMessage?: string;
    disabled?: boolean;
    name: string;
    options: SelectOption[] | GroupedSelectOption[];
    placeholder?: string;
    value?: OnChangeValue<SelectOption, false>;
    error?: string;
    onChange: (selected: SelectOption) => void;
    className?: string;
}

const Select: FC<SelectProps> = ({
    hasPrimaryStyling,
    hasSecondaryStyling,
    isLoading,
    isSearchable = true,
    id,
    loadingMessage = trans('components.select.loadingMessage'),
    noOptionsMessage = trans('components.select.nullState'),
    name,
    options,
    value,
    placeholder = trans('components.select.placeholder'),
    disabled,
    error = '',
    onChange,
    className = '',
}): ReactElement => {
    const selectClassName = classNames('select-overwrite', {
        'select-overwrite--is-disabled': disabled,
        'select-overwrite--has-error': !!error,
        'select-overwrite--has-primary-styling': hasPrimaryStyling,
        'select-overwrite--has-secondary-styling': hasSecondaryStyling,
    }, className);

    const handleChange = (selectValue: OnChangeValue<SelectOption, false>): void => {
        onChange(selectValue as SelectOption);
    };

    return (
        <div className="select">
            <ReactSelect
                isSearchable={isSearchable}
                isLoading={isLoading}
                inputId={id}
                loadingMessage={() => loadingMessage}
                noOptionsMessage={() => noOptionsMessage}
                name={name}
                options={options}
                value={value}
                placeholder={placeholder}
                isDisabled={disabled}
                onChange={handleChange}
                classNamePrefix="select-overwrite"
                className={selectClassName}
            />
        </div>
    );
};

export default Select;
