import React, { FC } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';


interface FilterProps {
    filter: AppFilter;
    onChange: (filter: AppFilter) => void;
}

const AvailabilityFilter: FC<FilterProps> = ({ filter, onChange }) => {
    const setFilter = (propName: string, value: boolean) => {
        onChange({
            ...filter,
            [propName]: value,
        });
    };

    return (
        <FilterBlock title={translate('pages.management.mainDepartment')}>
            <FormGroup check className="p-0">
                <Label check>
                    <CustomInput
                        id="only-show-main-department-filter-checkbox"
                        type="checkbox"
                        label={translate('pages.management.onlyShowMainDepartment')}
                        checked={filter.onlyShowMainDepartment}
                        onChange={() => setFilter('onlyShowMainDepartment', !filter.onlyShowMainDepartment)}
                    />
                </Label>
            </FormGroup>
        </FilterBlock>
    );
};

export default AvailabilityFilter;
