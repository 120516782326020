import { CSSProperties, FC } from 'react';

import classnames from 'classnames';

import { HelpButton, HelpTooltipContent } from './subcomponents';

import './HelpTooltip.scss';

interface HelpTooltipProps {
    showButton: boolean;
    showNextSiblingButton?: boolean;
    showPrevSiblingButton?: boolean;
    showTooltip: boolean;
    index: number;
    subTitle: string;
    text: string;
    title: string;
    tooltipsLength: number;
    videoSrc?: string;
    showMobileInfoWarning?: boolean;
    onHelpButtonClick: () => void;
    onNextButtonClick: () => void;
    onPrevButtonClick: () => void;
    onStopButtonClick: () => void;
    className?: string;
}

const HelpTooltip: FC<HelpTooltipProps> = ({
    showButton,
    showNextSiblingButton,
    showPrevSiblingButton,
    showTooltip,
    index,
    subTitle,
    text,
    title,
    tooltipsLength,
    videoSrc,
    showMobileInfoWarning,
    onHelpButtonClick,
    onNextButtonClick,
    onPrevButtonClick,
    onStopButtonClick,
    className,
}) => {
    const containerClassNames = classnames('help-tooltip', {
        'help-tooltip--is-active': showTooltip,
    }, className);

    const bodyWidth = document.body.clientWidth;

    const contentWrapperCssVariables = {
        '--body-width': `${bodyWidth}px`,
    } as CSSProperties;

    return (
        <div className={containerClassNames}>
            <div className="help-tooltip__container">
                {showButton && (
                    <HelpButton
                        isActive={showTooltip}
                        animationDelayInSeconds={index * 0.1}
                        onClick={onHelpButtonClick}
                        className="help-tooltip__button"
                    />
                )}
                <div className="help-tooltip__tooltip-content-wrapper" style={contentWrapperCssVariables}>
                    {showButton && showTooltip && (
                        <HelpTooltipContent
                            showMobileInfoWarning={showMobileInfoWarning}
                            showNextButton={showNextSiblingButton}
                            showPrevButton={showPrevSiblingButton}
                            index={index}
                            videoSrc={videoSrc}
                            subTitle={subTitle}
                            text={text}
                            title={title}
                            total={tooltipsLength}
                            onNextButtonClick={onNextButtonClick}
                            onPrevButtonClick={onPrevButtonClick}
                            onStopButtonClick={onStopButtonClick}
                            className="help-tooltip__tooltip-content"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default HelpTooltip;
