import { postCommentApiCall } from '../../entities/Comment/CommentService';
import {
    AddDistrictDivisionDistrictPlanningFormData,
    EditDistrictDivisionDistrictPlanningFormData,
    SwapDistrictDivisionDistrictPlanningsFormData,
} from '../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import {
    deleteDistrictDivisionDistrictPlanningApiCall,
    editDistrictDivisionDistrictPlanningOrderApiCall,
    postDistrictDivisionDistrictPlanningApiCall,
} from '../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanningService';
import { editDistrictDivisionDistrictTemporaryWorkerOrderApiCall } from '../../entities/DistrictDivisionDistrictTemporaryWorker/DistrictDivisionDistrictTemporaryWorkerService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import {
    setComment,
    setDistrictDivisionDistrictPlanning,
    setError,
    setIsAddPlanningCommentSuccessful,
    setIsAddPlanningSuccessful,
    setIsDeletePlanningSuccessful,
    setIsLoading,
    setIsSuccessful,
    setLastRemovedDistrictDivisionDistrictPlanningId,
} from './districtDivisionDistrictPlanningReducer';

export const addDistrictDivisionDistrictPlanning = (formData: AddDistrictDivisionDistrictPlanningFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsAddPlanningSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await postDistrictDivisionDistrictPlanningApiCall(formData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const comments = formData.comment ? [formData.comment] : [];
        dispatch(setDistrictDivisionDistrictPlanning({ ...response.data, comments }));

        dispatch(setIsAddPlanningSuccessful(true));
    } catch (error) {
        console.error('[addDistrictDivisionDistrictPlanning]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const removeDistrictDivisionDistrictPlanning = (districtDivisionDistrictPlanningId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsDeletePlanningSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await deleteDistrictDivisionDistrictPlanningApiCall(districtDivisionDistrictPlanningId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLastRemovedDistrictDivisionDistrictPlanningId(districtDivisionDistrictPlanningId));
        dispatch(setIsDeletePlanningSuccessful(true));
    } catch (error) {
        console.error('[removeDistrictDivisionDistrictPlanning]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addDistrictDivisionDistrictPlanningComment = (
    districtDivisionDistrictPlanningId: string,
    comment: string,
    isSilent = false,
) => async (dispatch: TypedDispatch): Promise<void> => {
    if (!isSilent) {
        dispatch(setIsLoading(true));
        dispatch(setIsAddPlanningCommentSuccessful(false));
    }
    dispatch(setError(''));

    try {
        const response = await postCommentApiCall({
            comment,
            relationshipId: districtDivisionDistrictPlanningId,
            relationshipType: 'districtDivisionDistrictPlannings',
            relationshipKey: 'districtDivisionDistrictPlanning',
        });

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setComment({ comment: response.data, districtDivisionDistrictPlanningId }));
        if (!isSilent) {
            dispatch(setIsAddPlanningCommentSuccessful(true));
        }
    } catch (error) {
        console.error('[addDistrictDivisionDistrictPlanningComment]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editDistrictDivisionDistrictPlanning = (formData: EditDistrictDivisionDistrictPlanningFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await editDistrictDivisionDistrictPlanningOrderApiCall(formData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDistrictDivisionDistrictPlanning(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editDistrictDivisionDistrictPlanning]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const swapDistrictDivisionDistrictPlannings = (formData: SwapDistrictDivisionDistrictPlanningsFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const shouldEditActiveDistrictDivisionDistrictPlanning = formData.activePlanning.type === 'districtDivisionDistrictPlannings';
        const shouldEditTargetDistrictDivisionDistrictPlanning = formData.targetPlanning.type === 'districtDivisionDistrictPlannings';

        const activePlanningResponse = shouldEditActiveDistrictDivisionDistrictPlanning
            ? await editDistrictDivisionDistrictPlanningOrderApiCall(formData.activePlanning)
            : await editDistrictDivisionDistrictTemporaryWorkerOrderApiCall(formData.activePlanning);

        const targetPlanningResponse = shouldEditTargetDistrictDivisionDistrictPlanning
            ? await editDistrictDivisionDistrictPlanningOrderApiCall(formData.targetPlanning)
            : await editDistrictDivisionDistrictTemporaryWorkerOrderApiCall(formData.targetPlanning);

        if (!isFetchResultSuccessful(activePlanningResponse)) {
            dispatch(setError(activePlanningResponse.error));

            return;
        }

        if (!isFetchResultSuccessful(targetPlanningResponse)) {
            dispatch(setError(targetPlanningResponse.error));

            return;
        }

        dispatch(setDistrictDivisionDistrictPlanning(activePlanningResponse.data));
        dispatch(setDistrictDivisionDistrictPlanning(targetPlanningResponse.data));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[swapDistrictDivisionDistrictPlannings]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
