import axios from 'axios';

import { formatDate, getEndOfWorkDay, getStartOfWorkDay } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { ApiResponse, SpecialDayResource, SpecialDayViewModel } from '../../models';
import transformSpecialDayResource from '../../services/SpecialDayService/transformSpecialDayResource';

export const getSpecialDays = async (startDate: Date, endDate: Date): Promise<SpecialDayViewModel[]> => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/special-days`,
        {
            params: {
                startDate: formatDate(getStartOfWorkDay(startDate), DateFormat.apiDateTime),
                endDate: formatDate(getEndOfWorkDay(endDate), DateFormat.apiDateTime),
                include: [].join(','),
            },
        },
    ) as unknown as ApiResponse<SpecialDayResource[]>;

    return response.data.map(transformSpecialDayResource);
};
