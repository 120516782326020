import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getShiftsIndexApiCall } from '../../entities/Shift/ShiftService';
import { getShiftsCalendarAbsencesIndexCall } from '../../entities/ShiftsCalendarAbsence/ShiftsCalendarAbsenceService';
import { getShiftsCalendarLeaveOfAbsencesIndexCall } from '../../entities/ShiftsCalendarLeaveOfAbsence/ShiftsCalendarLeaveOfAbsenceService';
import { getShiftsCalendarUnavailableToWorkTimeSlotsIndexCall } from '../../entities/ShiftsCalendarUnavailableToWorkTimeSlot/ShiftsCalendarUnavailableToWorkTimeSlotService';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setShifts,
    setShiftsCalendarAbsences,
    setShiftsCalendarLeaveOfAbsences,
    setShiftsCalendarUnavailableToWorkTimeSlots,
} from './shiftsCalendarReducer';

export const fetchShifts = (
    startDate: Date,
    endDate: Date,
    departments: string[],
    userSearch = '',
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getShiftsIndexApiCall(
            startDate,
            endDate,
            departments,
            userSearch,
        );

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShifts(response.data));
    } catch (error) {
        console.error('[fetchShifts]', error);
    }

    dispatch(setIsLoading(false));
};

export const fetchShiftsCalendarAbsences = (
    startDate: Date,
    endDate: Date,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setError(''));

    try {
        const response = await getShiftsCalendarAbsencesIndexCall(startDate, endDate);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftsCalendarAbsences(response.data));
    } catch (error) {
        console.error('[fetchShiftsCalendarAbsences]', error);
    }
};

export const fetchShiftsCalendarLeaveOfAbsences = (startDate: Date, endDate: Date) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setError(''));

    try {
        const response = await getShiftsCalendarLeaveOfAbsencesIndexCall(startDate, endDate);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftsCalendarLeaveOfAbsences(response.data));
    } catch (error) {
        console.error('[fetchShiftsCalendarLeaveOfAbsences]', error);
    }
};

export const fetchShiftsCalendarUnavailableToWorkTimeSlots = (
    startDate: Date,
    endDate: Date,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setError(''));

    try {
        const response = await getShiftsCalendarUnavailableToWorkTimeSlotsIndexCall(startDate, endDate);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftsCalendarUnavailableToWorkTimeSlots(response.data));
    } catch (error) {
        console.error('[fetchShiftsCalendarUnavailableToWorkTimeSlots]', error);
    }
};
