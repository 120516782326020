import { addYears, formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { transformToPeriod } from '../Period/PeriodTransformers';
import {
    AddContractHoursRequest,
    ContractHours,
    ContractHoursFormData,
    ContractHoursResource,
    ContractHoursV3Resource,
    EditContractHoursFormData,
    EditContractHoursRequest,
    UserContractHours,
    UserContractHoursV3Resource,
} from './ContractHours';
import { alignContractHours } from './ContractHoursHelpers';

export const transformToContractHours = (resource: ContractHoursResource): ContractHours => {
    const start = new Date(resource.attributes.start);
    const hasEnd = !!resource.attributes.end;
    const end = resource.attributes.end ? new Date(resource.attributes.end) : addYears(new Date(), 100);

    return {
        id: resource.id,
        period: transformToPeriod(start, end, hasEnd),
        hours: resource.attributes.contractHours,
    };
};

export const transformV3ResourceToContractHours = (resource: ContractHoursV3Resource): ContractHours => {
    const start = new Date(resource.start);
    const hasEnd = !!resource.end;
    const end = resource.end ? new Date(resource.end) : new Date();

    return {
        id: resource.id,
        period: transformToPeriod(start, end, hasEnd),
        hours: resource.contractHours,
    };
};

export const transformToUserContractHours = (resource: UserContractHoursV3Resource): UserContractHours => ({
    id: resource.id,
    name: resource.name,
    contractHours: alignContractHours(resource.contractHours.map(transformV3ResourceToContractHours)),
});

export const transformContractHoursToContractFormData = (contractHours: ContractHours): ContractHoursFormData => ({
    ...contractHours,
    resourceId: contractHours.id,
});

export const transformToAddContractHoursRequest = (formData: ContractHoursFormData, userId: string): AddContractHoursRequest => ({
    type: 'periodicalContractHours',
    attributes: {
        start: formatDate(formData.period.start, DateFormat.apiDateTime),
        end: formData.period.hasEnd ? formatDate(formData.period.end, DateFormat.apiDateTime) : null,
        contractHours: formData.hours,
    },
    relationships: {
        user: {
            data: {
                type: 'users',
                id: userId,
            },
        },
    },
});

export const transformToEditContractHoursRequest = (formData: EditContractHoursFormData): EditContractHoursRequest => ({
    type: 'periodicalContractHours',
    id: formData.resourceId,
    attributes: {
        start: formatDate(formData.period.start, DateFormat.apiDateTime),
        end: formData.period.hasEnd ? formatDate(formData.period.end, DateFormat.apiDateTime) : null,
        contractHours: formData.hours,
    },
});
