import { generateDownloadURI } from '../../../helpers';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import {
    AddShiftFormData,
    CopyShiftFormData,
    EditShiftFormData,
    EditShiftTimeFormData,
    ExportShiftFormData,
    ShiftIndex,
} from '../../entities/Shift/Shift';
import {
    copyShiftsApiCall,
    deleteShiftApiCall,
    editShiftApiCall,
    exportShiftsApiCall,
    postShiftApiCall,
} from '../../entities/Shift/ShiftService';
import { convertDateStringToDDMMYYYY } from '../../helpers/date';
import trans from '../../helpers/trans';
import { FormOption } from '../../types';
import { TypedDispatch } from '../store';
import {
    setDeletedShiftId,
    setError,
    setIsCopyShiftSuccessful,
    setIsDeleteShiftSuccessful,
    setIsEditShiftSuccessful,
    setIsLoading,
    setIsSuccessful,
    setShift,
} from './shiftReducer';

export const addShift = (addShiftFormDate: AddShiftFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await postShiftApiCall(addShiftFormDate);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShift(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const copyShift = (copyShiftFormData: CopyShiftFormData, shiftId: string, dateOffset: number) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsCopyShiftSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await copyShiftsApiCall(copyShiftFormData, [shiftId], dateOffset);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            return;
        }

        dispatch(setIsCopyShiftSuccessful(true));
    } catch (error) {
        console.error('[copyShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const exportsShifts = (shiftFormData: ExportShiftFormData, departments: FormOption[], userSearch: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await exportShiftsApiCall(shiftFormData, departments, userSearch);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const uri = `data:text/csv;charset=utf-8;header=present,${encodeURIComponent(response.data)}`;
        const formattedStartDate = convertDateStringToDDMMYYYY(shiftFormData.startDate);
        const formattedEndDate = convertDateStringToDDMMYYYY(shiftFormData.endDate);
        const fileName = trans('containers.forms.exportShiftsForms.fileName', { startDate: formattedStartDate, endDate: formattedEndDate });

        generateDownloadURI(uri, fileName);

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[exportsShifts]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editShiftTime = (editShiftTimeFormData: EditShiftTimeFormData, originalShift: ShiftIndex) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsEditShiftSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await editShiftApiCall(editShiftTimeFormData, originalShift.id);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShift(response.data));
        dispatch(setIsEditShiftSuccessful(true));
    } catch (error) {
        console.error('[editShiftTime]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editShift = (editShiftFormData: EditShiftFormData, originalShift: ShiftIndex) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsEditShiftSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await editShiftApiCall(editShiftFormData, originalShift.id);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShift(response.data));
        dispatch(setIsEditShiftSuccessful(true));
    } catch (error) {
        console.error('[editShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteShift = (shiftId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsDeleteShiftSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await deleteShiftApiCall(shiftId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDeletedShiftId(shiftId));
        dispatch(setIsDeleteShiftSuccessful(true));
    } catch (error) {
        console.error('[deleteShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
