import React, { FC } from 'react';

import Icon from '../../../../components/style/Icon/Icon';
import { translate } from '../../../../helpers/translations/translator';

import './ShowCommentsButton.scss';

type HTMLButtonProps = JSX.IntrinsicElements['button'];

type ShowCommentsButtonProps = HTMLButtonProps;


const ShowCommentsButton: FC<ShowCommentsButtonProps> = (props) => (
    <button type="button" {...props} className="show-comments-button">
        <Icon color="primary" className="show-comments-button__icon" kind="comment" fontSize={20} />
        <span className="show-comments-button__text">
            {translate('common.look')}
        </span>
    </button>
);

export default ShowCommentsButton;
