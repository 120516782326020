import { FC, useEffect } from 'react';

import { EditDashboardForm } from '../../../containers';
import { EditDashboardFormData } from '../../../entities/DashboardSetting/DashboardSetting';
import usePermissions from '../../../helpers/hooks/usePermissions';
import trans from '../../../helpers/trans';
import { patchAuthenticatedUserDashboardSettings } from '../../../redux/authenticatedUser/authenticatedUserActions';
import { setIsEditUserSuccessful } from '../../../redux/authenticatedUser/authenticatedUserReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { DashboardSettingType, ToastType } from '../../../types';

interface ConnectedEditDashboardFormProps {
    onClose: () => void;
}

const ConnectedEditDashboardForm: FC<ConnectedEditDashboardFormProps> = ({ onClose }) => {
    const dispatch = useTypedDispatch();
    const permissions = usePermissions();

    const { user, isUpdating, isEditUserSuccessful } = useTypedSelector(state => state.authenticatedUserReducer);
    const { settings } = useTypedSelector(state => state.dashboardSettingsReducer);

    const userWidgets: DashboardSettingType[] = user ? user.dashboardSettings.map(dashboardSetting => dashboardSetting.type) : [];

    useEffect(() => {
        if (isEditUserSuccessful) {
            dispatch(setToast({
                text: trans('containers.forms.editDashboardForm.editDashboardSuccess'),
                type: ToastType.pass,
            }));

            dispatch(setIsEditUserSuccessful(false));
            onClose();
        }
    }, [isEditUserSuccessful]);

    const handleSubmit = async (formData: EditDashboardFormData) => {
        if (user) {
            dispatch(patchAuthenticatedUserDashboardSettings(formData, settings, user));
        }
    };

    return (
        <EditDashboardForm
            isLoading={isUpdating}
            permissions={permissions}
            userWidgetTypes={userWidgets}
            onCancel={onClose}
            onSubmit={handleSubmit}
        />
    );
};

export default ConnectedEditDashboardForm;
