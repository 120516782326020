import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import User from '../../../pages/Management/User';
import { useTypedSelector } from '../../redux/store';

interface ConnectedLegacyUserDetailProps {
    className?: string;
}

const ConnectedLegacyUserDetail: FC<ConnectedLegacyUserDetailProps> = ({ className = '' }): ReactElement => {
    const { isLoading: isEmploymentTypesLoading } = useTypedSelector(state => state.employmentTypesReducer);
    const { isLoading: isExperiencesLoading } = useTypedSelector(state => state.experiencesReducer);
    const { isLoading: isRolesLoading } = useTypedSelector(state => state.rolesReducer);
    const isUserFormDataLoading = isEmploymentTypesLoading || isExperiencesLoading || isRolesLoading;

    const { userId } = useParams<{ userId: string }>();

    return (
        <User
            // @ts-ignore
            isUserFormDataLoading={isUserFormDataLoading}
            userId={userId}
            className={className}
        />
    );
};

export default ConnectedLegacyUserDetail;
