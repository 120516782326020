import { Dispatch } from 'redux';

import { BaseScheduleFormData, BaseScheduleIndexViewModel } from '../../../entities/BaseSchedule/BaseSchedule';
import {
    deleteBaseScheduleApiCall,
    getBaseSchedulesApiCall,
    getBaseSchedulesLazyLoadApiCall,
    patchBaseScheduleApiCall,
} from '../../../entities/BaseSchedule/BaseScheduleService';
import { transformToBaseScheduleFormDataToRequest } from '../../../entities/BaseSchedule/BaseScheduleTransformers';
import {
    setBaseSchedules,
    setError,
    setIsEditSuccessful,
    setIsLazyLoading,
    setIsLoading,
} from './baseSchedulesReducer';

export const fetchBaseSchedules = (departments: string[]) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsLazyLoading(true));
    dispatch(setError(''));

    try {
        const initialResponse = await getBaseSchedulesApiCall(departments);

        dispatch(setBaseSchedules(initialResponse));
        dispatch(setIsLoading(false));

        const lazyLoadedResponse = await getBaseSchedulesLazyLoadApiCall(departments);

        dispatch(setBaseSchedules(lazyLoadedResponse));
    } catch (error) {
        console.error('[fetchBaseSchedules]', error);
    } finally {
        dispatch(setIsLazyLoading(false));
    }
};

export const deleteBaseSchedule = (
    baseScheduleId: string,
    baseSchedules: BaseScheduleIndexViewModel[],
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        await deleteBaseScheduleApiCall(baseScheduleId);
        dispatch(setBaseSchedules(baseSchedules.filter(baseSchedule => baseSchedule.id !== baseScheduleId)));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.error('[deleteBaseSchedule]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editBaseSchedule = (
    formData: BaseScheduleFormData,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const request = transformToBaseScheduleFormDataToRequest(formData);

        await patchBaseScheduleApiCall(request);

        dispatch(setIsEditSuccessful(true));
    } catch (error) {
        console.error('[editBaseSchedule]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
