import React, { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useCheckPermission from '../../../../@paco/helpers/hooks/useCheckPermission';
import { useTypedDispatch, useTypedSelector } from '../../../../@paco/redux/store';
import { DocumentFormData } from '../../../../models';
import { fetchDepartments } from '../../../../redux/@toolkit/departments/departmentsActions';
import { notifyUsers } from '../../../../redux/app/appActions';
import { setDocumentSearch, setFilter } from '../../../../redux/filter/filterActions';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import {
    addDocument,
    deleteDocument,
    editDocument,
    fetchDocuments,
    fetchDocumentTypes,
} from '../../../../redux/pz/pzActions';
import { Reducers } from '../../../../redux/reducers';
import SharedDocumentsOverview from '../../containers/SharedDocumentsOverview/SharedDocumentsOverview';

const ConnectedSharedDocumentsOverview: FC = () => {
    const dispatch = useDispatch();
    const typedDispatch = useTypedDispatch();

    const filter = useSelector((state: Reducers) => state.filterReducer.filter);
    const pagination = useSelector((state: Reducers) => state.paginationReducer);
    const { roles } = useSelector((state: Reducers) => state.appReducer);
    const { isLoading: isDepartmentsLoading, departments } = useSelector((state: Reducers) => state.departmentsReducer);
    const { departmentOptions } = useTypedSelector(state => state.globalFiltersReducer);

    const { permissions } = useTypedSelector(state => state.authenticatedUserReducer);
    const {
        loading: isPzLoading,
        documentTypes,
        documents,
        lastAddedDocument,
    } = useSelector((state: Reducers) => state.pzReducer);

    const isLoading = isPzLoading || isDepartmentsLoading;
    const canNotifyEmployees = useCheckPermission('notify-all-departments');
    const canAddFiles = useCheckPermission('add-new-files');

    const getDocuments = (): void => {
        dispatch(fetchDocuments());
    };

    useEffect((): void => {
        if (!documentTypes.length) {
            dispatch(fetchDocumentTypes());
        }
    }, [permissions]);

    useEffect((): void => {
        if (!documents.length) {
            dispatch(fetchDocuments());
        }

        if (!departments.length) {
            typedDispatch(fetchDepartments());
        }
    }, []);

    useEffect((): void => {
        getDocuments();
    }, [departmentOptions]);

    const onSetFilterChange = (newFilter: AppFilter) => {
        dispatch(setFilter(newFilter, getDocuments));
    };

    const onSearchBarChange = (search: string) => {
        dispatch(setDocumentSearch(search));
        dispatch(fetchDocuments());
    };

    const onAddDocument = (formData: DocumentFormData) => {
        if (formData.id) {
            dispatch(editDocument(formData));
        } else {
            dispatch(addDocument(formData));
        }
    };

    const onDeleteDocument = (documentId: string) => {
        dispatch(deleteDocument(documentId));
    };

    const onNotifyUsers = (data: any) => {
        dispatch(notifyUsers(data));
    };

    return (
        <SharedDocumentsOverview
            filter={filter}
            departments={departments}
            roles={roles}
            documentTypes={documentTypes}
            pagination={pagination}
            loading={isLoading}
            documents={documents}
            lastAddedDocument={lastAddedDocument}
            onSetFilterChange={onSetFilterChange}
            onSetPagination={getDocuments}
            onSearchBarChange={onSearchBarChange}
            onAddDocument={onAddDocument}
            onDeleteDocument={onDeleteDocument}
            canNotifyEmployees={canNotifyEmployees}
            onNotifyUsers={onNotifyUsers}
            canAddFiles={canAddFiles}
        />
    );
};

export default ConnectedSharedDocumentsOverview;
