import { FC } from 'react';

import NotifyEmployees from '../../../../pages/Schedule/forms/NotifyEmployees';
import { Department } from '../../../entities/Department/Department';
import trans from '../../../helpers/trans';

interface LegacyNotifyEmployeesFormProps {
    departments: Department[];
    onCancel: () => void;
    onSubmit: (formData: any) => void;
}

const LegacyNotifyEmployeesForm: FC<LegacyNotifyEmployeesFormProps> = ({
    departments,
    onCancel,
    onSubmit,
}) => (
    <NotifyEmployees
        title={trans('containers.forms.notifyEmployeesForm.notifyScheduleUpdate')}
        subjectEmployeesDefault={trans('containers.forms.notifyEmployeesForm.youHaveANewNotification')}
        subjectDepartmentsDefault={trans('containers.forms.notifyEmployeesForm.newScheduleChanges')}
        bodyDefault={trans('containers.forms.notifyEmployeesForm.lookAtYourScheduleForNewestChanges')}
        departments={departments}
        onSubmit={onSubmit}
        onCancel={onCancel}
    />
);

export default LegacyNotifyEmployeesForm;
