import {
    addDays,
    getHours,
    getMinutes,
    setHours,
    setMinutes,
} from '../../../helpers/date';
import UpToAndIncludingDate from '../../../helpers/date/UpToAndIncludingDate';
import { BaseScheduleShiftViewModel } from '../BaseScheduleShift';

const getShiftTime = (date: Date, shiftDateTime: Date) => {
    const addedHours = setHours(date, getHours(shiftDateTime));
    return setMinutes(addedHours, getMinutes(shiftDateTime));
};

export default (shift: BaseScheduleShiftViewModel, selectedDays: Date[]): BaseScheduleShiftViewModel => {
    const selectedDay = (shift.weekday === 0 && getHours(shift.start) < 4) ? addDays(selectedDays[6], 1) : selectedDays[shift.weekday];

    const shiftPlannings = shift.shiftPlannings.map((shiftPlanning) => ({
        ...shiftPlanning,
        start: getShiftTime(selectedDay, shiftPlanning.start),
        end: new UpToAndIncludingDate(getShiftTime(selectedDay, shiftPlanning.end.transformToUpToButExcludingDate().date)),
    }));

    return {
        ...shift,
        shiftPlannings,
        start: getShiftTime(selectedDay, shift.start),
        end: new UpToAndIncludingDate(getShiftTime(selectedDay, shift.end.transformToUpToButExcludingDate().date)),
    };
};
