import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { LeaveOfAbsencesOverviewTable } from '../../containers';
import { transformToPeriod } from '../../entities/Period/PeriodTransformers';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../helpers/date';
import { getCheckedFormOptionsValue } from '../../helpers/types/formOption';
import { getLeaveOfAbsencesInPeriod } from '../../redux/leaveOfAbsences/leaveOfAbsencesActions';
import { getLeaveOfAbsencesOverviewBlocks } from '../../redux/leaveOfAbsencesOverview/leaveOfAbsencesOverviewActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { getBasicUsers } from '../../redux/users/usersActions';
import { getLeaveOfAbsencesOverviewCalendarDays } from '../ConnectedLeaveOfAbsencesOverviewTopBar/helpers/getLeaveOfAbsencesOverviewCalendarDays';

interface ConnectedLeaveOfAbsencesOverviewTableProps {
    className?: string;
}

const ConnectedLeaveOfAbsencesOverviewTable: FC<ConnectedLeaveOfAbsencesOverviewTableProps> = ({ className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();

    const { selectedDays } = useTypedSelector(state => state.calendarReducer);
    const {
        userSearch,
        departmentOptions,
        employmentTypeOptions,
        roleOptions,
        onlyShowMainDepartment,
    } = useTypedSelector(state => state.globalFiltersReducer);
    const { leaveOfAbsencesOverviewBodyRows } = useTypedSelector(state => state.leaveOfAbsencesOverviewReducer);
    const { isLoading: isLeaveOfAbsencesLoading, leaveOfAbsences } = useTypedSelector(state => state.pacoLeaveOfAbsencesReducer);
    const { isLoading: isUsersLoading, basicUsers: users } = useTypedSelector(state => state.pacoUsersReducer);
    const departmentUuids = getCheckedFormOptionsValue(departmentOptions);
    const employmentTypeUuids = getCheckedFormOptionsValue(employmentTypeOptions);
    const roleUuids = getCheckedFormOptionsValue(roleOptions);

    const calendarDays = useMemo(() => (
        getLeaveOfAbsencesOverviewCalendarDays(selectedDays[0])
    ), [selectedDays]);

    useEffect((): void => {
        dispatch(getBasicUsers({
            userSearch,
            departments: departmentUuids,
            employmentTypes: employmentTypeUuids,
            roles: roleUuids,
            onlyShowMainDepartment,
        }));
    }, [
        userSearch,
        departmentUuids.length,
        employmentTypeUuids.length,
        roleUuids.length,
        onlyShowMainDepartment,
    ]);

    useEffect((): void => {
        if (!calendarDays.length) {
            return;
        }

        dispatch(getLeaveOfAbsencesInPeriod({
            start: getStartOfWorkDay(calendarDays[0]),
            end: getEndOfWorkDay(calendarDays[calendarDays.length - 1]),
        }));
    }, [calendarDays]);

    useEffect((): void => {
        dispatch(getLeaveOfAbsencesOverviewBlocks(calendarDays, leaveOfAbsences, users));
    }, [leaveOfAbsences, users]);

    return (
        <LeaveOfAbsencesOverviewTable
            hasStickyTableHead
            isLoading={isLeaveOfAbsencesLoading || isUsersLoading}
            rows={leaveOfAbsencesOverviewBodyRows}
            period={transformToPeriod(calendarDays[0], calendarDays[calendarDays.length - 1])}
            className={className}
        />
    );
};

export default ConnectedLeaveOfAbsencesOverviewTable;
