import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BaseScheduleIndexViewModel } from '../../../entities/BaseSchedule/BaseSchedule';
import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';

export type BaseSchedulesState = AsyncReduxState<{
    isEditSuccessful: boolean;
    isLazyLoading: boolean;
    baseSchedules: BaseScheduleIndexViewModel[];
}>;

const initialState: BaseSchedulesState = {
    ...initialAsyncReduxState,
    isEditSuccessful: false,
    isLazyLoading: false,
    baseSchedules: [],
};

export const BaseSchedulesSlice = createSlice({
    name: 'baseSchedulesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): BaseSchedulesState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setIsLazyLoading(state, action: PayloadAction<boolean>): BaseSchedulesState {
            return ({
                ...state,
                isLazyLoading: action.payload,
            });
        },
        setIsEditSuccessful(state, action: PayloadAction<boolean>): BaseSchedulesState {
            return {
                ...state,
                isEditSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): BaseSchedulesState {
            return ({
                ...state,
                error: action.payload,
            });
        },
        setBaseSchedules(state, action: PayloadAction<BaseScheduleIndexViewModel[]>): BaseSchedulesState {
            return ({
                ...state,
                baseSchedules: action.payload,
            });
        },
    },
});

export const {
    setBaseSchedules,
    setIsEditSuccessful,
    setError,
    setIsLazyLoading,
    setIsLoading,
} = BaseSchedulesSlice.actions;

export default BaseSchedulesSlice.reducer;
