import { translate } from '../../../helpers/translations/translator';

export const getErrorMessage = (
    noWeekdaysWithHours: boolean,
    noStartDate: boolean,
): string => {
    if (noWeekdaysWithHours) {
        return translate('common.noWeekdaysWithHoursWarning');
    }

    if (noStartDate) {
        return translate('common.noStartDateSelected');
    }

    return '';
};
