import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import classnames from 'classnames';
import {
    Button,
    Col,
    CustomInput,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    Row,
} from 'reactstrap';

import { DepartmentsInput } from '../../../../@paco/compositions';
import { transformDepartmentsToDepartmentOptions } from '../../../../@paco/containers/@forms/EditUserForm/helpers/transformToSelectOption';
import { Department } from '../../../../@paco/entities/Department/Department';
import trans from '../../../../@paco/helpers/trans';
import { DepartmentOption } from '../../../../@paco/types/selectOptionTypes';
import FormDatePicker from '../../../../components/FormDatePicker/FormDatePicker';
import ModalHeader from '../../../../components/ModelHeader/ModalHeader';
import { PublishShiftConceptsFormData } from '../../../../entities/ShiftConcept/ShiftConcept';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import validateFormData from './helpers/validateFormData';

import './PublishShiftConceptsForm.scss';

export interface PublishShiftConceptsFormProps {
    isLoading: boolean;
    amountOfShiftConcepts: number;
    departments: Department[];
    formData: PublishShiftConceptsFormData;
    selectedFilterDepartments: Department[];
    onCancel: () => void;
    onSubmit: (data: PublishShiftConceptsFormData) => void;
    onFormDataChange: (data: PublishShiftConceptsFormData) => void;
}

const PublishShiftConceptsForm: FC<PublishShiftConceptsFormProps> = ({
    isLoading,
    amountOfShiftConcepts,
    departments = [],
    formData,
    selectedFilterDepartments,
    onCancel,
    onSubmit,
    onFormDataChange,
}) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [selectedDepartments, setSelectedDepartments] = useState<DepartmentOption[]>(transformDepartmentsToDepartmentOptions(selectedFilterDepartments));
    const [selectDepartmentsIsOpen, setSelectDepartmentsIsOpen] = useState(false);
    const [notifyUsers, setNotifyUsers] = useState(false);

    const validationErrors = useMemo(
        () => validateFormData(formData, notifyUsers, isSubmitted),
        [formData, isSubmitted, notifyUsers],
    );
    const classNames = useMemo(() => classnames('publish-shift-concepts-form', {
        'publish-shift-concepts-form--has-notify-users': notifyUsers,
        'publish-shift-concepts-form--select-departments-is-open': selectDepartmentsIsOpen,
    }), [notifyUsers, selectDepartmentsIsOpen]);
    const canSubmit = !Object.keys(validationErrors).length
        && formData.departments.length
        && amountOfShiftConcepts
        && !isLoading;

    useEffect(() => {
        onFormDataChange({
            ...formData,
            departments: selectedDepartments.map((department) => department.value),
        });
    }, [selectedDepartments]);

    const handleChangeStartDate = (startDate: Date) => {
        onFormDataChange({
            ...formData,
            startDate,
        });
    };

    const handleChangeEndDate = (endDate: Date) => {
        onFormDataChange({
            ...formData,
            endDate,
        });
    };

    const handleNotificationTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
        onFormDataChange({
            ...formData,
            notificationTitle: e.currentTarget.value,
        });
    };

    const handleNotificationBodyChange = (e: React.FormEvent<HTMLInputElement>) => {
        onFormDataChange({
            ...formData,
            notificationBody: e.currentTarget.value,
        });
    };

    const handleSelectDepartmentsChange = (values: DepartmentOption[]) => {
        setSelectedDepartments(values);
    };

    const handleSelectDepartmentsOpen = () => {
        setSelectDepartmentsIsOpen(true);
    };

    const handleSelectDepartmentsClose = () => {
        setSelectDepartmentsIsOpen(false);
    };

    const handleNotifyUsersCheckboxClick = () => {
        if (!notifyUsers) {
            setIsSubmitted(false);
        }

        if (notifyUsers) {
            onFormDataChange({
                ...formData,
                notificationTitle: undefined,
                notificationBody: undefined,
            });
        }

        setNotifyUsers(!notifyUsers);
    };

    const handleSubmitButtonClick = () => {
        setIsSubmitted(true);

        if (!Object.keys(validateFormData(formData, notifyUsers)).length && onSubmit) {
            onSubmit({
                ...formData,
                startDate: getStartOfWorkDay(formData.startDate),
                endDate: getEndOfWorkDay(formData.endDate),
            });
        }
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    return (
        <form className={classNames} onSubmit={handleFormSubmit}>
            <ModalHeader
                className="publish-shift-concepts-form__modal-header"
                isLoading={isLoading}
                title={translate('pages.shifts.publishShiftConcepts')}
            />
            <ModalBody>
                <Row>
                    <Col className="publish-shift-concepts-form__intro">
                        {translate('pages.shifts.whichShiftsDoYouWantToPublish')}
                    </Col>
                </Row>
                <Row className="publish-shift-concepts-form__row-departments">
                    <Col>
                        <DepartmentsInput
                            isLoading={false}
                            departments={departments}
                            label={trans('common.departments')}
                            selectedDepartments={selectedDepartments}
                            onDepartmentsChange={handleSelectDepartmentsChange}
                            onMenuClose={handleSelectDepartmentsClose}
                            onMenuOpen={handleSelectDepartmentsOpen}
                        />
                    </Col>
                </Row>
                <Row className="publish-shift-concepts-form__row-dates">
                    <Col>
                        <FormGroup>
                            <FormGroup>
                                <Label>{translate('common.startDate')}</Label>
                                <FormDatePicker
                                    selected={formData.startDate}
                                    invalid={!!validationErrors.date}
                                    onChange={handleChangeStartDate}
                                />
                            </FormGroup>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <FormGroup>
                                <Label>{translate('common.endDate')}</Label>
                                <FormDatePicker
                                    selected={formData.endDate}
                                    invalid={!!validationErrors.date}
                                    onChange={handleChangeEndDate}
                                    feedback={validationErrors.date}
                                />
                            </FormGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label
                            check
                            className="publish-shift-concepts-form__notify-users-checkbox"
                        >
                            <CustomInput
                                type="checkbox"
                                id="checkbox-notify-users"
                                checked={notifyUsers}
                                onChange={handleNotifyUsersCheckboxClick}
                            >
                                {translate('pages.shifts.notifyEmployeesAboutThisPublication')}
                            </CustomInput>
                        </Label>
                    </Col>
                </Row>
                <Row className="publish-shift-concepts-form__row-notification-title">
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.title')}</Label>
                            <CustomInput
                                onChange={handleNotificationTitleChange}
                                type="text"
                                className="form-control"
                                id="notification-name"
                                autoComplete="off"
                                invalid={!!validationErrors.notificationTitle}
                                value={formData.notificationTitle || ''}
                            />
                            <FormFeedback>
                                {validationErrors.notificationTitle}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="publish-shift-concepts-form__row-notification-body">
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.message')}</Label>
                            <Input
                                onChange={handleNotificationBodyChange}
                                type="textarea"
                                rows="3"
                                className="form-control"
                                id="notification-body"
                                autoComplete="off"
                                invalid={!!validationErrors.notificationBody}
                                value={formData.notificationBody || ''}
                            />
                            <FormFeedback>
                                {validationErrors.notificationBody}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="link"
                    id="modal-close"
                    onClick={onCancel}
                >
                    {translate('common.cancel')}
                </Button>
                <Button
                    type="submit"
                    color="orange"
                    disabled={!canSubmit}
                    onClick={handleSubmitButtonClick}
                >
                    {translate('pages.shifts.publishShiftsAmount', { amount: amountOfShiftConcepts })}
                </Button>
            </ModalFooter>
        </form>
    );
};

export default PublishShiftConceptsForm;
