import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

import { TOAST_TYPE_FAIL, TOAST_TYPE_PASS, TOAST_TYPE_UPDATE_APP } from '../../constants';
import Check from '../../images/check.svg';
import Close from '../../images/close.svg';
import Info from '../../images/info.svg';
import Warning from '../../images/warning.svg';


const DEFAULT_DURATION = 4000;
const UPDATE_APP_DURATION = 20000;

function getIcon(type) {
    switch (type) {
        case TOAST_TYPE_PASS:
            return Check;
        case TOAST_TYPE_FAIL:
            return Warning;
        default:
            return Info;
    }
}

class _Toast extends Component {
    intervalId = null;

    state = {
        isOpen: true,
    };

    componentDidMount() {
        this.setIntervalId();
    }

    componentDidUpdate() {
        this.setIntervalId();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    setIntervalId = () => {
        const { toast } = this.props;
        const timeout = toast.type === TOAST_TYPE_UPDATE_APP ? UPDATE_APP_DURATION : DEFAULT_DURATION;
        if (toast.autoFadeOut) {
            this.intervalId = setTimeout(this.close, timeout);
        }
    };

    close = () => {
        this.setState({
            isOpen: false,
        });
    };

    render() {
        const { isOpen } = this.state;
        const { toast } = this.props;

        return (
            <Toast isOpen={isOpen}>
                <ToastHeader className={`toast-header--${toast.type && toast.type.toLowerCase()}`}>
                    <img alt="icon" src={getIcon(toast.type)} />
                </ToastHeader>
                <ToastBody>
                    {toast.text}
                </ToastBody>
                <button type="button" className="toast-button-close" onClick={this.close}>
                    <img alt="close-icon" src={Close} />
                </button>
            </Toast>
        );
    }
}

_Toast.propTypes = {
    toast: PropTypes.object.isRequired,
};

export default _Toast;
