import {
    CSSProperties,
    FC,
    PropsWithChildren,
    ReactElement,
} from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import trans from '../../helpers/trans';
import { IconButton, ShiftsLegend, SidebarToggleButton } from '..';

import './Sidebar.scss';

export interface SidebarProps {
    showSidebarToggle?: boolean;
    hasShiftsLegend?: boolean;
    transitionDuration?: number;
    className?: string;
    contentClassName?: string;
    isOpenedClassName?: string;
}

const Sidebar: FC<PropsWithChildren<SidebarProps>> = ({
    showSidebarToggle = true,
    hasShiftsLegend,
    transitionDuration = 200,
    className = '',
    contentClassName = '',
    isOpenedClassName = '',
    children,
}): ReactElement => {
    const [isOpened, toggleIsOpened] = useToggle(true);
    const [isOpenedForMobile, toggleIsOpenedForMobile] = useToggle(false);

    const toggleOpenHandler = () => toggleIsOpened();

    const cssVariables = {
        '--sidebar-transition-duration': `${transitionDuration}ms`,
    } as CSSProperties;

    const sidebarClassNames = classNames('sidebar-paco', {
        'sidebar-paco--is-opened': isOpened,
        [isOpenedClassName]: isOpened && isOpenedClassName,
    }, className);

    const sidebarContentClassNames = classNames('sidebar-paco__content', {
        'sidebar-paco__content--is-opened': isOpenedForMobile,
        'sidebar-paco__content--has-shifts-legend': hasShiftsLegend,
    }, contentClassName);

    return (
        <aside style={cssVariables} className={sidebarClassNames}>
            {showSidebarToggle && (
                <SidebarToggleButton
                    isOpened={isOpened}
                    onClick={toggleOpenHandler}
                    className="sidebar-paco__toggle-button"
                />
            )}
            <div className={sidebarContentClassNames}>
                {children}
                {(hasShiftsLegend && isOpened) && <ShiftsLegend className="sidebar-paco__shifts-legend" />}
            </div>
            <IconButton
                text={isOpenedForMobile ? trans('common.close') : trans('common.filter')}
                icon={isOpenedForMobile ? 'cross' : 'filter'}
                onClick={toggleIsOpenedForMobile}
                className="sidebar-paco__mobile-toggle-button"
            />
        </aside>
    );
};

export default Sidebar;
