import React, { PropsWithChildren } from 'react';

import classNames from 'classnames';

import './LegacyTableRow.scss';

export type TableRowItem<I> = I & { id: string };

interface TableRowProps<I> {
    hasHoverEffect?: boolean;
    item: TableRowItem<I>;
    activeId?: string;
    className?: string;
}

const TableRow = <Interface, >({
    hasHoverEffect = false,
    item,
    activeId,
    className,
    children,
}: PropsWithChildren<TableRowProps<Interface>>) => {
    const tableRowClassNames = classNames('legacy-table-row', {
        [`${className}--is-active`]: activeId === item.id,
        'legacy-table-row--is-active': activeId === item.id,
        'legacy-table-row--is-clickable': hasHoverEffect,
    }, className);

    return (
        <tr
            data-row-id={item.id}
            className={tableRowClassNames}
        >
            {children}
        </tr>
    );
};

export default TableRow;
