import { FC } from 'react';

import { Button, Icon } from '../../components';
import { IconButton } from '../index';

import './Breadcrumbs.scss';

export interface Breadcrumb {
    value: string;
    label: string;
}

interface BreadcrumbsProps {
    breadcrumbs: Breadcrumb[];
    onBreadcrumbClick: (value?: string) => void;
    className?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs, onBreadcrumbClick, className = '' }) => (
    <div className={`breadcrumbs ${className}`}>
        <IconButton
            hideLabel
            icon="home"
            text="home"
            className="breadcrumbs__house-button"
            iconClassName="breadcrumbs__house-icon"
            onClick={() => onBreadcrumbClick(undefined)}
        />
        <div className="breadcrumbs__breadcrumbs">
            {breadcrumbs.map(breadcrumb => (
                <div
                    key={breadcrumb.value}
                    className="breadcrumbs__breadcrumb-button-group"
                >
                    <Button
                        text={breadcrumb.label}
                        onClick={() => onBreadcrumbClick(breadcrumb.value)}
                        className="breadcrumbs__breadcrumb-button"
                    />
                    <Icon
                        name="chevron-right"
                        className="breadcrumbs__chevron-icon"
                    />
                </div>
            ))}
        </div>
    </div>
);

export default Breadcrumbs;
