import React, { FC, useEffect, useState } from 'react';

import classnames from 'classnames';

import './RadioButtonsList.scss';


export interface RadioListButton {
    label: string;
    hidden?: boolean;
}

interface Props {
    className?: string;
    buttons: RadioListButton[];
    checked?: number;
    onRadioChange: (index: number, value: string) => void;
}

export const RadioButtonsList: FC<Props> = ({
    className,
    buttons,
    onRadioChange,
    ...props
}) => {
    const [checked, setChecked] = useState(0);

    const classNames = classnames('radio-buttons-list', {
        [`radio-buttons-list--length-${buttons.length}`]: buttons.length,
    }, [className]);

    useEffect(() => {
        if (props.checked !== undefined) {
            setChecked(props.checked);
        }
    }, [props.checked]);

    const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const index = parseInt(e.currentTarget.value, 10);
        if (props.checked === undefined) {
            setChecked(index);
        }
        onRadioChange(index, buttons[index].label);
    };

    return (
        <div className={classNames}>
            {
                buttons.map((button: RadioListButton, index: number) => {
                    const id = `${button.label}-${index}`;
                    const active = checked === index;

                    const labelClassNames = classnames('radio-buttons-list__label', {
                        'radio-buttons-list__label--active': active,
                        'radio-buttons-list__label--hidden': button.hidden,
                        [`${className}-label`]: !!className,
                        [`${className}-label--active`]: !!className && active,
                        [`${className}-label--hidden`]: !!className && button.hidden,
                    });

                    return (
                        <label
                            key={id}
                            htmlFor={id}
                            className={labelClassNames}
                        >
                            <input
                                id={id}
                                className="radio-buttons-list__input"
                                type="radio"
                                value={index}
                                checked={active}
                                onChange={onInputChange}
                            />
                            {button.label}
                        </label>
                    );
                })
            }
        </div>
    );
};
