import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import { getPayrollPeriodFromDate } from '../../../entities/PayrollPeriod/PayrollPeriodHelpers';
import { addDays, getMiddleOfDays } from '../../../helpers/date';
import { TimeModeType } from '../../../types';
import { TypedDispatch } from '../../store';
import { getDaysFromTimeMode, getIncrementedDays } from './calendarHelpers';
import {
    setActivePayrollPeriod,
    setSelectedDays,
    setSelectedMobileDay,
    setTimeMode,
} from './calendarReducer';

export const setTimePeriodAndSelectedDays = (timeMode: TimeModeType, date: Date, payrollPeriods: PayrollPeriod[]) => async (dispatch: TypedDispatch): Promise<void> => {
    const selectedDays = getDaysFromTimeMode(timeMode, date, payrollPeriods);
    const payrollPeriod = getPayrollPeriodFromDate(date, payrollPeriods);

    dispatch(setActivePayrollPeriod(payrollPeriod));
    dispatch(setSelectedDays(selectedDays));
    dispatch(setSelectedMobileDay(date));
    dispatch(setTimeMode(timeMode));
};

export const setIncrementedSelectedMobileDay = (
    timeMode: TimeModeType,
    selectedMobileDay: Date,
    goForward: boolean,
    payrollPeriods: PayrollPeriod[],
) => async (dispatch: TypedDispatch): Promise<void> => {
    const newSelectedMobileDay = addDays(selectedMobileDay, goForward ? 1 : -1);
    const newSelectedDays = getIncrementedDays(
        timeMode,
        [selectedMobileDay],
        goForward,
        payrollPeriods,
    );
    const payrollPeriod = getPayrollPeriodFromDate(getMiddleOfDays(newSelectedDays), payrollPeriods);

    dispatch(setActivePayrollPeriod(payrollPeriod));
    dispatch(setSelectedDays(newSelectedDays));
    dispatch(setSelectedMobileDay(newSelectedMobileDay));
};

export const setIncrementedSelectedDays = (
    timeMode: TimeModeType,
    selectedDays: Date[],
    goForward: boolean,
    payrollPeriods: PayrollPeriod[],
) => async (dispatch: TypedDispatch): Promise<void> => {
    const newSelectedDays = getIncrementedDays(
        timeMode,
        selectedDays,
        goForward,
        payrollPeriods,
    );
    const payrollPeriod = getPayrollPeriodFromDate(getMiddleOfDays(newSelectedDays), payrollPeriods);

    dispatch(setActivePayrollPeriod(payrollPeriod));
    dispatch(setSelectedDays(newSelectedDays));
    dispatch(setSelectedMobileDay(newSelectedDays[0]));
};
