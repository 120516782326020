import {
    CommentCategoryResource,
    CommentResource,
    CommentViewModel,
    UserResource,
} from '../../models';
import transformCommentCategory from '../CommentCategoryService/transformCommentCategory';
import transformUserResource from '../UserService/transformUserResource';

export default function transformCommentResource(
    comment: CommentResource,
    owner?: UserResource,
    user?: UserResource,
    commentCategory?: CommentCategoryResource,
): CommentViewModel {
    return {
        id: comment.id,
        body: comment.body,
        createdAt: new Date(comment.createdAt),
        ...(owner && { owner: transformUserResource(owner) }),
        ...(user && { user: transformUserResource(user) }),
        ...(commentCategory && { category: transformCommentCategory(commentCategory) }),
    };
}
