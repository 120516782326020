import { AddDistrictDivisionGroupTemplateFormData } from '../../../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import trans from '../../../../helpers/trans';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../services/validationService';

export type AddDistrictDivisionGroupTemplateFormErrors = FormErrors<AddDistrictDivisionGroupTemplateFormData>;

export const validateAddDistrictDivisionGroupTemplateFormData = (formData: AddDistrictDivisionGroupTemplateFormData): FormValidation<AddDistrictDivisionGroupTemplateFormErrors> => {
    const errors: AddDistrictDivisionGroupTemplateFormErrors = {
        name: validateRequiredString('name', formData.name),
        districtDivisionTemplateId: validateRequiredString('districtDivisionTemplateId', formData.districtDivisionTemplateId),
        districtDivisionDistrictTemplates: formData.districtDivisionDistrictTemplates.some(district => district.maxAmountOfEmployees < 0)
            ? trans('validation.errors.numberBelowComparison', {
                attribute: trans('validation.attributes.employeeLimit'),
                comparison: 0,
            })
            : undefined,
    };

    return validateForm<AddDistrictDivisionGroupTemplateFormErrors>(errors);
};
