import { CommentFormData } from '../../models';
import {
    AddUserNoteAction,
    DeleteUserNoteAction,
    EditUserNoteAction,
    GetUserAction,
} from './managementModels';

export const getUser = (id: string): GetUserAction => ({
    type: 'MANAGEMENT_SAGA_GET_USER', id,
});

export const addUserNote = (formData: CommentFormData): AddUserNoteAction => ({
    type: 'MANAGEMENT_SAGA_ADD_USER_NOTE',
    formData,
});

export const editUserNote = (formData: CommentFormData): EditUserNoteAction => ({
    type: 'MANAGEMENT_SAGA_EDIT_USER_NOTE',
    formData,
});

export const deleteUserNote = (id: string): DeleteUserNoteAction => ({
    type: 'MANAGEMENT_SAGA_DELETE_USER_NOTE',
    id,
});

export const getCommentCategories = () => ({
    type: 'MANAGEMENT_SAGA_GET_COMMENT_CATEGORIES',
});
