import axios from 'axios';

import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { checkIntegrityOfShiftSnapshot, transformShift } from '../../models';
import {
    addDays,
    formatDate,
    getEndOfWorkDay,
    getStartOfWorkDay,
} from '../date';

const getShiftsByDate = async (startDate, endDate, filter) => {
    const includes = ['department', 'department.group'];

    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shifts/`,
        {
            params: {
                include: includes.join(','),
                ...(filter && { search: filter.employeeSearch }),
                startDate: formatDate(getStartOfWorkDay(startDate), DateFormat.apiDateTime),
                endDate: formatDate(getEndOfWorkDay(endDate), DateFormat.apiDateTime),
                departments: filter.departments ? JSON.stringify(filter.departments) : undefined,
            },
        },
    );

    response.data.map(transformShift);

    return response;
};

const getShift = (shiftId) => {
    const includes = ['department', 'department.group', 'plannedUsers', 'registeredUsers', 'registeredUsers.employmentType',
        'plannedUsers.experience', 'shiftPlannings', 'shiftPlannings.user.person', 'shiftPlannings.user.experience', 'shiftPlannings.user.employmentType',
        'shiftPlannings.comments', 'previouslyPlannedUsers', 'previouslyPlannedUsers.employmentType', 'shiftPlannings.comments.owner',
        'shiftPlannings.comments.owner.employmentType', 'shiftPlannings.comments.owner.person', 'temporaryWorkers',
        'temporaryWorkers.comments', 'temporaryWorkers.comments.owner', 'temporaryWorkers.comments.owner.person'];

    return axios.get(
        `${process.env.REACT_APP_API_URL}/shifts/${shiftId}`,
        {
            params: {
                include: includes.join(','),
            },
        },
    );
};

const getShiftSnapshot = async (shiftId) => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shifts/${shiftId}/snapshot`,
    );

    if (!checkIntegrityOfShiftSnapshot(response.data)) {
        throw new TypeError(`Snapshot ${response.data.id} type mismatch`);
    }

    return response;
};

const patchShiftsWithSnapshot = snapshot => {
    const data = {
        type: 'shiftSnapshots',
        id: snapshot.id,
        attributes: {
            start: snapshot.start,
            end: snapshot.end,
            peopleLimit: snapshot.peopleLimit,
            department: snapshot.department,
            shiftPlanningSnapshots: snapshot.shiftPlanningSnapshots,
        },
    };

    return axios.patch(`${process.env.REACT_APP_API_URL}/shifts/${snapshot.id}/snapshot`, { data });
};

const getSimilarShifts = shiftId => axios.get(
    `${process.env.REACT_APP_API_URL}/shifts/similar/${shiftId}`,
);

function generateShiftBody(data) {
    return {
        type: 'shifts',
        id: data.id,
        attributes: {
            start: `${data.startDate} ${data.startTime}:00`,
            end: `${data.endDate} ${data.endTime}:00`,
            peopleLimit: data.peopleLimit,
        },
        relationships: {
            user: {
                data: {
                    type: 'users',
                    id: data.user,
                },
            },
            department: {
                data: {
                    type: 'departments',
                    id: data.department,
                },
            },
        },
    };
}

const patchShift = (shift) => {
    const body = generateShiftBody(shift);

    return axios.patch(`${process.env.REACT_APP_API_URL}/shifts/${shift.id}/`, { data: body });
};

const generateShiftPlanningBody = (data) => {
    const attributes = {
        ...(data.start && { start: data.start }),
        ...(data.end && { end: data.end }),
        ...(data.planned && { planned: data.planned }),
        ...((data.color || data.color === null) && { color: data.color }),
    };

    return {
        type: 'shiftPlannings',
        attributes,
        relationships: {
            ...(data.shiftId && {
                shift: {
                    data: {
                        type: 'shifts',
                        id: data.shiftId,
                    },
                },
            }),
            user: {
                data: {
                    type: 'users',
                    id: data.userId,
                },
            },
        },
    };
};

const patchShiftPlanning = (data) => {
    const body = generateShiftPlanningBody(data);

    return axios.patch(
        `${process.env.REACT_APP_API_URL}/shift/plannings/${data.shiftPlanningId}`,
        { data: body },
        {
            params: {
                include: ['user', 'user.experience', 'user.employmentType', 'comments', 'comments.owner', 'comments.owner.person'].join(','),
            },
        },
    );
};

const delShift = shiftId => axios.delete(
    `${process.env.REACT_APP_API_URL}/shifts/${shiftId}/`,
);

const delShiftPlanning = (shiftPlanningId) => axios.delete(
    `${process.env.REACT_APP_API_URL}/shift/plannings/${shiftPlanningId}`,
);

const delUserFromShift = (shiftId, userId) => axios.delete(
    `${process.env.REACT_APP_API_URL}/shift/plannings/${shiftId}/${userId}`,
);

const generateNotifyBody = (data) => {
    const {
        departments, employees, subject, body,
    } = data;

    const departmentsData = departments && departments.map(id => ({ id, type: 'department' }));
    const employeesData = employees && employees.map(id => ({ id, type: 'user' }));

    return {
        type: 'notifications',
        attributes: {
            subject,
            body,
        },
        relationships: {
            ...(departmentsData && {
                departments: {
                    data: departmentsData,
                },
            }),
            ...(employeesData && {
                users: {
                    data: employeesData,
                },
            }),
        },
    };
};

const postNotifyDepartments = (data) => {
    const dataBody = generateNotifyBody(data);

    return axios.post(`${process.env.REACT_APP_API_URL}/departments/notify`, { data: dataBody });
};

const postNotifyEmployees = (data) => {
    const dataBody = generateNotifyBody(data);

    return axios.post(`${process.env.REACT_APP_API_URL}/users/notify`, { data: dataBody });
};

const generateTempWorkerBody = (name, start, end, color) => ({
    data: {
        type: 'temporaryWorkers',
        attributes: {
            ...(start && { start }),
            ...(end && { end }),
            ...(name && { name }),
            ...((color || color === null) && { color }),
        },
    },
});

const postTempWorkerToShift = (shiftId, name) => {
    const body = generateTempWorkerBody(name);
    body.data.relationships = {
        shift: {
            data: {
                type: 'shifts',
                id: shiftId,
            },
        },
    };

    return axios.post(`${process.env.REACT_APP_API_URL}/temporary-workers`, body);
};

const patchTempWorker = ({
    tempWorkerId, name, start, end, color,
}) => {
    const body = generateTempWorkerBody(name, start, end, color);

    return axios.patch(
        `${process.env.REACT_APP_API_URL}/temporary-workers/${tempWorkerId}`,
        body,
        {
            params: {
                include: ['comments', 'comments.owner', 'comments.owner.person'].join(','),
            },
        },
    );
};

const delTempWorker = (tempWorkerId) => axios.delete(`${process.env.REACT_APP_API_URL}/temporary-workers/${tempWorkerId}`);

const getSpecialDays = (year = 2019, start, end) => {
    let startDate = `${year - 1}-12-31 04:00:00`;
    let endDate = `${year + 1}-01-01 03:59:00`;

    if (start && end) {
        startDate = getStartOfWorkDay(startDate);
        endDate = getEndOfWorkDay(endDate);
    }

    return axios.get(`${process.env.REACT_APP_API_URL}/special-days`, {
        params: {
            orderByDate: JSON.stringify({ date: 'DESC' }),
            startDate: formatDate(startDate, DateFormat.apiDateTime),
            endDate: formatDate(endDate, DateFormat.apiDateTime),
        },
    });
};

const getSpecialDayTypes = () => axios.get(`${process.env.REACT_APP_API_URL}/special-days/types`);

const generateSpecialDayBody = (data) => ({
    type: 'specialDay',
    ...(data.id && { id: data.id }),
    attributes: {
        type: data.type,
        date: data.date,
        description: data.descr,
        ...(data.holidayAllowance !== undefined && { holidayAllowance: data.holidayAllowance }),
    },
});

const postSpecialDay = (data) => {
    const body = generateSpecialDayBody(data);

    return axios.post(`${process.env.REACT_APP_API_URL}/special-days`, { data: body });
};

const patchSpecialDay = (data) => {
    const body = generateSpecialDayBody(data);

    return axios.patch(`${process.env.REACT_APP_API_URL}/special-days/${data.id}`, { data: body });
};

const delSpecialDay = (id) => axios.delete(`${process.env.REACT_APP_API_URL}/special-days/${id}`);

const getPreferToWorkTimeSlots = (
    startDate,
    endDate,
    userUuids = [],
    filter = {},
) => {
    const { search, departments } = filter;

    return axios.get(`${process.env.REACT_APP_API_URL}/prefer-to-work-time-slots`, {
        params: {
            orderByStart: JSON.stringify({ start: 'DESC' }),
            userUuids: userUuids.length > 0 ? JSON.stringify(userUuids) : undefined,
            startDate: formatDate(getStartOfWorkDay(startDate), DateFormat.apiDateTime),
            endDate: formatDate(getEndOfWorkDay(endDate), DateFormat.apiDateTime),
            include: ['user', 'user.person'].join(','),
            ...(search && { search }),
            ...(departments && { departments }),
        },
    });
};

const getUnavailableWorkTimeSlots = (
    startDate,
    endDate,
    userUuids = [],
    filter = {},
) => {
    const { search, departments } = filter;

    const start = formatDate(addDays(startDate, -1), 'yyyy-MM-dd');
    const end = formatDate(addDays(endDate, 1), 'yyyy-MM-dd');
    const urlParams = `startDate=${start}&endDate=${end}`;

    return axios.get(`${process.env.REACT_APP_API_URL}/unavailable-to-work-time-slots?${urlParams}`, {
        params: {
            orderByStart: JSON.stringify({ start: 'DESC' }),
            userUuids: userUuids.length > 0 ? JSON.stringify(userUuids) : undefined,
            include: ['user', 'user.person'].join(','),
            ...(search && { search }),
            ...(departments && { departments }),
        },
    });
};


export {
    getShiftsByDate,
    getShift,
    getShiftSnapshot,
    getSimilarShifts,
    patchShift,
    patchShiftsWithSnapshot,
    patchShiftPlanning,
    delShift,
    delShiftPlanning,
    delUserFromShift,
    postNotifyDepartments,
    postNotifyEmployees,
    postTempWorkerToShift,
    patchTempWorker,
    delTempWorker,
    getSpecialDays,
    getSpecialDayTypes,
    postSpecialDay,
    patchSpecialDay,
    delSpecialDay,
    getPreferToWorkTimeSlots,
    getUnavailableWorkTimeSlots,
};
