import { EventLog } from '../../../entities/EventLog/EventLog';
import { formatDate } from '../../../helpers/date';
import { DateFormat } from '../../../types/dateFormatTypes';

type GroupLogsByDate = Record<string, EventLog[]>;

export const groupLogsByDate = (logs: EventLog[]): { [key: string]: EventLog[] } => logs.reduce((total, log) => {
    const date = formatDate(log.createdAt, DateFormat.date);

    if (!total[date]) {
        return {
            ...total,
            [date]: [log],
        };
    }

    const updatedDate = [
        ...total[date],
        log,
    ];

    return {
        ...total,
        [date]: updatedDate,
    };
}, {} as GroupLogsByDate);
