import React, { FC } from 'react';

import { ConnectedUserContractHours } from '../../../../@paco/connectors';
import { getUserFullName } from '../../../../helpers';
import { BasicUser } from '../../../../models';

import './SelectEmployeeLabel.scss';


interface SelectEmployeeLabelProps {
    user: BasicUser;
}

const SelectEmployeeLabel:FC<SelectEmployeeLabelProps> = ({ user }) => (
    <>
        {getUserFullName(user)}

        <ConnectedUserContractHours userId={user.id} className="select-employee-label__hours" />
    </>
);

export default SelectEmployeeLabel;
