import {
    SETTINGS_DELETE_PERIOD_REQUEST,
    SETTINGS_DELETE_PERIOD_SUCCESS,
    SETTINGS_GET_SETTINGS_FAILURE,
    SETTINGS_GET_SETTINGS_REQUEST,
    SETTINGS_GET_SETTINGS_SUCCESS,
    SETTINGS_SET_PERIOD_FAILURE,
    SETTINGS_SET_PERIOD_REQUEST,
    SETTINGS_SET_PERIOD_SUCCESS,
    SETTINGS_SET_SETTINGS_FAILURE,
    SETTINGS_SET_SETTINGS_REQUEST,
    SETTINGS_SET_SETTINGS_SUCCESS,
} from '../actionTypes';

const initialState = {
    loading: false,
    settings: [],
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_DELETE_PERIOD_REQUEST:
        case SETTINGS_SET_PERIOD_REQUEST:
        case SETTINGS_GET_SETTINGS_REQUEST:
        case SETTINGS_SET_SETTINGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SETTINGS_SET_PERIOD_SUCCESS:
        case SETTINGS_DELETE_PERIOD_SUCCESS:
        case SETTINGS_SET_SETTINGS_SUCCESS:
        case SETTINGS_SET_PERIOD_FAILURE:
        case SETTINGS_SET_SETTINGS_FAILURE:
        case SETTINGS_GET_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case SETTINGS_GET_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.settings,
            };
        default:
            return state;
    }
};

export default settingsReducer;
