import getIncluded from '../../japi/getIncluded';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import { JapiDocument } from '../../japi/types/Document';
import { CommentResource } from '../Comment/Comment';
import { transformToComment } from '../Comment/CommentTransformers';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { ShiftResource } from '../Shift/Shift';
import { ShiftConceptResource } from '../ShiftConcept/ShiftConcept';
import { UserResource } from '../User/User';
import { getDummyUserResource } from '../User/UserHelpers';
import { TemporaryWorker, TemporaryWorkerResource } from './TemporaryWorker';
import { transformToTemporaryWorker } from './TemporaryWorkerTransformers';

export const getTemporaryWorkerResourceIncludesAndTransformToTemporaryWorker = (
    doc: JapiDocument,
    temporaryWorkerResource: TemporaryWorkerResource,
    shiftResource: ShiftResource | ShiftConceptResource,
): TemporaryWorker => {
    const commentsResource = (getMultipleIncluded(doc, temporaryWorkerResource, 'comments') || []) as CommentResource[];

    const comments = commentsResource.map(commentResource => {
        const commentOwnerResource = getIncluded(doc, commentResource, 'owner') as UserResource;

        if (!commentOwnerResource) {
            console.error(`[getTemporaryWorkerResourceIncludesAndTransformToTemporaryWorker] Missing owner resource in comment with id ${commentResource.id} (${commentResource.attributes.body})`);
        }

        return transformToComment(
            commentResource,
            commentOwnerResource || getDummyUserResource(`${commentResource.id}-user`),
        );
    });

    const shiftPeriod = transformToPeriod(new Date(shiftResource.attributes.start), new Date(shiftResource.attributes.end));

    return transformToTemporaryWorker(temporaryWorkerResource, shiftPeriod, comments);
};
