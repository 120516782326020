import { startOfDayInHours } from '../../../@paco/helpers/date';
import { addHours, formatDate } from '../../../helpers/date';
import { translate } from '../../../helpers/translations/translator';
import { WeekWithHours } from '../../../models';

export default function getInputTooltipText(weekday: WeekWithHours) {
    const dateFormat = 'dd-MM-yyyy';
    const { start, end, payrollPeriod } = weekday;
    const startDate = formatDate(start, dateFormat);
    const endDate = formatDate(addHours(end.date, -startOfDayInHours), dateFormat);

    const dates = `${startDate} ${translate('common.until')} ${endDate}`;

    if (!payrollPeriod) {
        return `${dates} - ${translate('common.noPayrollPeriodWarning')}`;
    }

    const { periodNumber } = payrollPeriod;
    const locked = payrollPeriod.locked ? `(${translate('common.locked').toLowerCase()})` : '';
    const period = periodNumber && `${translate('common.period').toLowerCase()} ${periodNumber}`;

    return `${dates}${period ? ` - ${period}` : ''} ${locked}`;
}
