import { ReactElement, ReactNode } from 'react';

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableRow,
} from '../../../components';

import './SkeletonTable.scss';

interface SkeletonTableProps {
    caption?: string;
    tableHeaders?: string[];
    cellClassName?: string;
    className?: string;
    headerRowClassName?: string;
    children?: ReactNode;
}

const SkeletonTable = ({
    caption,
    tableHeaders,
    cellClassName = '',
    className = '',
    headerRowClassName = '',
    children,
}: SkeletonTableProps): ReactElement => (
    <div className="skeleton-table">
        <Table className={`skeleton-table__table ${className}`}>
            {caption && <TableCaption title={caption} />}

            {tableHeaders && (
                <TableHead>
                    <TableRow className={`skeleton-table__header-row ${headerRowClassName}`}>
                        {tableHeaders.map(header => (
                            <TableCell
                                key={header}
                                className={cellClassName}
                            >
                                {header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            )}

            <TableBody>
                {children}
            </TableBody>
        </Table>
    </div>
);

export default SkeletonTable;
