import { FC, useEffect } from 'react';

import DeleteDistrictDivisionGroupTemplateForm from '../../../containers/@forms/DeleteDistrictDivisionGroupTemplateForm/DeleteDistrictDivisionGroupTemplateForm';
import trans from '../../../helpers/trans';
import { deleteDistrictDivisionGroupTemplate } from '../../../redux/districtDivisionGroupTemplate/districtDivisionGroupTemplateActions';
import { setIsSuccessful } from '../../../redux/districtDivisionGroupTemplate/districtDivisionGroupTemplateReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedDeleteDistrictDivisionGroupTemplateFormProps {
    districtDivisionGroupTemplateId: string;
    onClose: () => void;
}

const ConnectedDeleteDistrictDivisionGroupTemplateForm: FC<ConnectedDeleteDistrictDivisionGroupTemplateFormProps> = ({
    districtDivisionGroupTemplateId,
    onClose,
}) => {
    const dispatch = useTypedDispatch();
    const { isLoading, isSuccessful } = useTypedSelector(state => state.districtDivisionGroupTemplateReducer);

    const handleDeleteDistrictDivisionGroupTemplate = (formData: { id: string }): void => {
        dispatch(deleteDistrictDivisionGroupTemplate(formData.id));
    };

    useEffect(() => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.deleteDistrictDivisionGroupTemplateForm.deleteDistrictDivisionGroupTemplateSuccess'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isSuccessful]);

    return (
        <DeleteDistrictDivisionGroupTemplateForm
            isLoading={isLoading}
            districtDivisionGroupTemplateId={districtDivisionGroupTemplateId}
            onSubmit={handleDeleteDistrictDivisionGroupTemplate}
            onCancel={onClose}
        />
    );
};

export default ConnectedDeleteDistrictDivisionGroupTemplateForm;
