import { UserResource as LegacyUserResource } from '../../../models';
import Uuid from '../../compositions/GroupedCheckboxes/uuid';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { UserStatus } from '../../types/userStatus';
import { Address } from '../Address/Address';
import { transformV3ResourceToAddress } from '../Address/AddressTransformers';
import { ContractHours } from '../ContractHours/ContractHours';
import { alignContractHours } from '../ContractHours/ContractHoursHelpers';
import { transformV3ResourceToContractHours } from '../ContractHours/ContractHoursTransformers';
import { transformV3ResourceToDashboardSetting } from '../DashboardSetting/DashboardSettingTransformers';
import { Department } from '../Department/Department';
import { reduceDepartmentGroupsFromDepartments } from '../Department/DepartmentHelpers';
import { transformV3ResourceToDepartment } from '../Department/DepartmentTransformers';
import { DepartmentGroup } from '../DepartmentGroup/DepartmentGroup';
import { EmergencyContact } from '../EmergencyContact/EmergencyContact';
import { transformV3ResourceToEmergencyContact } from '../EmergencyContact/EmergencyContactTransformers';
import { Employment } from '../Employment/Employment';
import { transformV3ResourceToEmployment } from '../Employment/EmploymentTransformers';
import { EmploymentType } from '../EmploymentType/EmploymentType';
import { transformV3ResourceToEmploymentType } from '../EmploymentType/EmploymentTypeTransformers';
import { Experience } from '../Experience/Experience';
import { transformV3ResourceToExperience } from '../Experience/ExperienceTransformers';
import { transformV3ResourceToPermission } from '../Permission/PermissionTransformers';
import { Person } from '../Person/Person';
import { generateFullName } from '../Person/PersonHelpers';
import {
    transformToBasicPerson,
    transformToCompanyMedicType,
    transformUserProfileV3ResourceToPerson,
} from '../Person/PersonTransformers';
import { Role } from '../Role/Role';
import { transformV3ResourceToRole } from '../Role/RoleTransformers';
import {
    AddUserFormData,
    AddUserRequest,
    BasicUser,
    BasicUserResource,
    EditUserFormData,
    EditUserRequest,
    EditUserStatusRequest,
    FullUser,
    LegacyBasicUser,
    ShiftIndexUserResource,
    ShiftPlanningUser,
    TrackOwner,
    User,
    UserProfile,
    UserProfileV3Resource,
    UserResource,
    UserWithEmployment,
    UserWithHourBalances,
    WorkweekUser,
    WorkweekUserResource,
} from './User';

export const transformLegacyUserToUser = (legacyUserResource: LegacyUserResource): UserResource => ({
    id: legacyUserResource.id,
    type: 'users',
    attributes: {
        firstname: legacyUserResource.firstname,
        affix: legacyUserResource.affix,
        lastname: legacyUserResource.lastname,
        employeeNumber: legacyUserResource.employeeNumber,
        phone: legacyUserResource.phone,
        email: legacyUserResource.email,
        status: legacyUserResource.status,
        shouldTrackTime: legacyUserResource.shouldTrackTime,
        isPaidBasedOnTrackedTime: legacyUserResource.isPaidBasedOnTrackedTime,
        isEligibleForTimeForTime: legacyUserResource.isEligibleForTimeForTime,
        registrationComplete: legacyUserResource.registrationComplete,
        isLoketAuthorized: legacyUserResource.isLoketAuthorized,
        loketEmployeeId: legacyUserResource.loketEmployeeId,
    },
});

export const transformToUser = (resource: UserResource, employment?: Employment): User => {
    const status = getEnumKeyByEnumValue(UserStatus, resource.attributes.status);

    if (!status) {
        console.error(`[transformToUser]: Unknown status enum found named "${resource.attributes.status}".`);
    }

    return {
        id: resource.id,
        employeeNumber: resource.attributes.employeeNumber,
        phone: resource.attributes.phone,
        email: resource.attributes.email,
        status: status ? UserStatus[status] : UserStatus.active,
        shouldTrackTime: resource.attributes.shouldTrackTime,
        isPaidBasedOnTrackedTime: resource.attributes.isPaidBasedOnTrackedTime,
        isEligibleForTimeForTime: resource.attributes.isEligibleForTimeForTime,
        isRegistrationComplete: resource.attributes.registrationComplete,
        isLoketAuthorized: resource.attributes.isLoketAuthorized,
        loketEmployeeId: resource.attributes.loketEmployeeId || undefined,
        employment,
        person: transformToBasicPerson(resource.attributes.firstname, resource.attributes.lastname, resource.attributes.affix),
    };
};

export const transformToUserWithEmployment = (
    user: UserResource,
    employment: Employment,
): UserWithEmployment => ({
    ...transformToUser(user),
    employment,
});

export const transformToUserWithHourBalances = (
    user: User,
    contractHours: ContractHours[],
    departments: Department[],
    employment: Employment,
    employmentType: EmploymentType,
    mainDepartment?: Department,
): UserWithHourBalances => ({
    ...user,
    contractHours,
    departments,
    employment,
    employmentType,
    mainDepartment,
});

export const transformToShiftPlanningUser = (
    user: ShiftIndexUserResource,
    employmentType: EmploymentType,
    experience: Experience,
): ShiftPlanningUser => ({
    id: user.id,
    employmentType,
    experience,
    fullName: generateFullName(user.attributes.firstname, user.attributes.affix || undefined, user.attributes.lastname),
    companyMedic: transformToCompanyMedicType(user.attributes.isCompanyMedic),
    shouldTrackTime: user.attributes.shouldTrackTime,
});

export const transformToTrackOwner = (
    resource: UserResource,
    person: Person,
    employmentType: EmploymentType,
    experience: Experience,
): TrackOwner => {
    const status = getEnumKeyByEnumValue(UserStatus, resource.attributes.status);

    if (!status) {
        console.error(`[transformToTrackOwner]: Unknown status enum found named "${resource.attributes.status}".`);
    }

    return {
        id: resource.id,
        employeeNumber: resource.attributes.employeeNumber,
        employmentType,
        experience,
        phone: resource.attributes.phone,
        email: resource.attributes.email,
        status: status ? UserStatus[status] : UserStatus.active,
        shouldTrackTime: resource.attributes.shouldTrackTime,
        isPaidBasedOnTrackedTime: resource.attributes.isPaidBasedOnTrackedTime,
        isEligibleForTimeForTime: resource.attributes.isEligibleForTimeForTime,
        isRegistrationComplete: resource.attributes.registrationComplete,
        isLoketAuthorized: resource.attributes.isLoketAuthorized,
        loketEmployeeId: resource.attributes.loketEmployeeId || undefined,
        person,
    };
};

export const transformToBasicUser = (user: UserResource): LegacyBasicUser => ({
    id: user.id,
    person: transformToBasicPerson(user.attributes.firstname, user.attributes.lastname, user.attributes.affix),
});

export const transformBasicUserResourceToBasicV3User = (user: BasicUserResource): BasicUser => ({
    id: user.uuid,
    companyMedic: transformToCompanyMedicType(user.isCompanyMedic),
    // TODO: Use contractHours from user after BE fixed v3/users/workweek
    contractHours: [],
    person: transformToBasicPerson(user.firstname, user.lastname, user.affix || ''),
});

export const transformToFullUser = (
    user: User,
    contractHours: ContractHours[],
    departments: Department[],
    departmentGroups: DepartmentGroup[],
    employmentType: EmploymentType,
    role: Role,
    person: Person,
    mainDepartment?: Department,
    address?: Address,
    emergencyContact?: EmergencyContact,
    experience?: Experience,
): FullUser => ({
    ...user,
    contractHours,
    departments,
    departmentGroups,
    employmentType,
    role,
    mainDepartment,
    person,
    address,
    emergencyContact,
    experience,
});

export const transformUserProfileV3 = (resource: UserProfileV3Resource): UserProfile => {
    const addressResource = resource.addresses[resource.addresses.length - 1];
    const roleResource = resource.roles[resource.roles.length - 1];
    const employmentResource = resource.employments[resource.employments.length - 1];
    const status = getEnumKeyByEnumValue(UserStatus, resource.status);
    const departments = resource.departments.map(transformV3ResourceToDepartment);
    const departmentGroups = reduceDepartmentGroupsFromDepartments(departments);
    const contractHours = alignContractHours(resource.periodicalContractHours.map(transformV3ResourceToContractHours));

    if (!status) {
        console.error(`[transformUserProfileV3ResourceToAuthenticatedUser]: Unknown status enum found named "${resource.status}".`);
    }

    if (!employmentResource) {
        console.error('[transformUserProfileV3ResourceToAuthenticatedUser]: Missing employment resource.');
    }

    if (!contractHours.length) {
        console.error('[transformUserProfileV3ResourceToAuthenticatedUser]: Missing contract hours resource.');
    }

    return {
        id: resource.id,
        isEligibleForTimeForTime: resource.isEligibleForTimeForTime,
        isPaidBasedOnTrackedTime: resource.isPaidBasedOnTrackedTime,
        isLoketAuthorized: resource.isLoketAuthorized,
        isRegistrationComplete: resource.registrationComplete,
        shouldTrackTime: resource.shouldTrackTime,
        contractHours,
        email: resource.email,
        phone: resource.phone,
        status: status ? UserStatus[status] : UserStatus.active,
        employeeNumber: resource.employeeNumber,
        loketEmployeeId: resource.loketEmployeeId || undefined,
        address: addressResource ? transformV3ResourceToAddress(addressResource) : undefined,
        configurablePermissions: resource.configurablePermissions.map(transformV3ResourceToPermission),
        dashboardSettings: (resource?.dashboardSettings || []).map(transformV3ResourceToDashboardSetting),
        departments,
        departmentGroups,
        emergencyContact: transformV3ResourceToEmergencyContact(resource.emergencyContact),
        employment: transformV3ResourceToEmployment(employmentResource),
        employmentType: transformV3ResourceToEmploymentType(resource.employmentType),
        experience: transformV3ResourceToExperience(resource.experience),
        mainDepartment: resource.mainDepartment ? transformV3ResourceToDepartment(resource.mainDepartment) : undefined,
        permissions: Array.isArray(resource.permissions) ? resource.permissions.map(transformV3ResourceToPermission) : [],
        person: transformUserProfileV3ResourceToPerson(resource),
        role: transformV3ResourceToRole(roleResource),
    };
};

export const transformUserProfileV3ToFullUser = (user: UserProfile): FullUser => ({
    ...user,
});

export const transformToWorkweekUser = (resource: WorkweekUserResource): WorkweekUser => {
    const person = transformToBasicPerson(resource.firstName, resource.lastName, resource.affix || '');

    return {
        id: resource.uuid,
        companyMedic: transformToCompanyMedicType(resource.isCompanyMedic),
        birthday: new Date(resource.birthday),
        contractHours: alignContractHours(resource.periodicalContractHours.map(transformV3ResourceToContractHours)),
        employeeNumber: resource.employeeNumber,
        person,
        plannedHoursInWeek: resource.plannedHoursInWeek,
        plannedConceptHoursInWeek: resource.plannedConceptHoursInWeek,
    };
};

export const transformAddUserFormDataToAddApiParams = (
    data: AddUserFormData,
): AddUserRequest => ({
    type: 'users',
    attributes: {
        employeeNumber: data.personalInfo.employeeNumber,
        email: data.personalInfo.email,
        phone: data.personalInfo.phoneNumber,
        shouldTrackTime: data.shouldTrackTime,
        isPaidBasedOnTrackedTime: data.isPaidBasedOnTrackedTime,
        isEligibleForTimeForTime: data.isEligibleForTimeForTime,
        password: Uuid(),
    },
    relationships: {
        mainDepartment: {
            data: {
                type: 'departments',
                id: data.mainDepartment,
            },
        },
        departments: {
            data: data.departments ? data.departments.map(
                departmentId => ({
                    type: 'departments',
                    id: departmentId,
                }),
            ) : [],
        },
        departmentGroups: {
            data: data.departmentGroups ? data.departmentGroups.map(
                departmentGroupId => ({
                    type: 'departmentGroups',
                    id: departmentGroupId,
                }),
            ) : [],
        },
        employmentType: {
            data: {
                type: 'employmentTypes',
                id: data.employmentTypeId,
            },
        },
        roles: {
            data: [{
                type: 'roles',
                id: data.roleId,
            }],
        },
    },
});

export const transformEditUserFormDataToEditApiParams = (
    data: EditUserFormData,
): EditUserRequest => ({
    type: 'users',
    id: data.id,
    attributes: {
        employeeNumber: data.personalInfo.employeeNumber,
        email: data.personalInfo.email,
        phone: data.personalInfo.phoneNumber,
        shouldTrackTime: data.shouldTrackTime,
        isPaidBasedOnTrackedTime: data.isPaidBasedOnTrackedTime,
        isEligibleForTimeForTime: data.isEligibleForTimeForTime,
    },
    relationships: {
        ...(data.mainDepartment && {
            mainDepartment: {
                data: {
                    type: 'departments',
                    id: data.mainDepartment,
                },
            },
        }),
        ...(data.departments && {
            departments: {
                data: data.departments.map(
                    departmentId => ({
                        type: 'departments',
                        id: departmentId,
                    }),
                ),
            },
        }),
        ...(data.employmentTypeId && {
            employmentType: {
                data: {
                    type: 'employmentTypes',
                    id: data.employmentTypeId,
                },
            },
        }),
        ...(data.roleId && {
            roles: {
                data: [{
                    type: 'roles',
                    id: data.roleId,
                }],
            },
        }),
    },
});

export const transformEditUserStatusRequest = (userId: string, status: UserStatus): EditUserStatusRequest => ({
    type: 'users',
    id: userId,
    attributes: {
        activityStatus: status,
    },
});
