import trans from '../../@paco/helpers/trans';

export const PZRoutes = {
    knowledgeBase: () => `${trans('routes.pz.knowledgeBase.link')}`,
    satisfaction: () => `${trans('routes.pz.satisfaction.link')}`,
    completePeriods: () => `${trans('routes.pz.completePeriods.link')}`,
    synchronizations: () => `${trans('routes.pz.synchronizations.link')}`,
    completePeriodDetail: (id: string) => `${trans('routes.pz.completePeriods.link')}/${id}`,
    documents: () => `${trans('routes.pz.documents.link')}`,
    logs: () => `${trans('routes.pz.logs.link')}`,
};
