export const compareStringsNoCase = (a: string, b: string): boolean => (a || '').toLowerCase() === (b || '').toLowerCase();

export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

export const deCapitalizeFirstLetter = (string: string): string => string.charAt(0).toLowerCase() + string.slice(1);

export const camelCaseToDash = (string: string): string => string
    .replace(/[^a-zA-Z0-9]+/g, '-')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/([0-9])([^0-9])/g, '$1-$2')
    .replace(/([^0-9])([0-9])/g, '$1-$2')
    .replace(/-+/g, '-')
    .toLowerCase();
