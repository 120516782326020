import { FC, ReactElement } from 'react';

import trans from '../../../helpers/trans';
import { CompanyMedicType, SelectOption } from '../../../types';
import { SelectInput } from '../../index';

const transformNumberToSelectOption = (companyMedic: number): SelectOption => {
    if (companyMedic === 2) {
        return ({ label: trans('common.yes'), value: CompanyMedicType.yes });
    }

    if (companyMedic === 1) {
        return ({ label: trans('common.no'), value: CompanyMedicType.no });
    }

    return ({ label: trans('common.unknown'), value: CompanyMedicType.unknown });
};

const transformSelectOptionToNumber = (selectOption: SelectOption): number => {
    if (selectOption.value === CompanyMedicType.yes) {
        return 2;
    }

    if (selectOption.value === CompanyMedicType.no) {
        return 1;
    }

    return 0;
};

const generateCompanyMedicOptions = (): SelectOption[] => {
    const companyMedicArray = Object.values(CompanyMedicType);

    return companyMedicArray.map(companyMedic => ({ label: trans(`common.${companyMedic.toLowerCase()}`), value: companyMedic })) as SelectOption<CompanyMedicType>[];
};

const companyMedicOptions = generateCompanyMedicOptions();

interface CompanyMedicSelectInputProps {
    companyMedic: number;
    onChange: (option: number) => void;
}

const CompanyMedicSelectInput: FC<CompanyMedicSelectInputProps> = ({ companyMedic, onChange }): ReactElement => {
    const onCompanyMedicStateChange = (companyMedicValue: SelectOption): void => {
        onChange(transformSelectOptionToNumber(companyMedicValue));
    };

    return (
        <SelectInput
            label={trans('common.companyMedic')}
            name="companyMedic"
            value={transformNumberToSelectOption(companyMedic)}
            options={companyMedicOptions}
            onChange={onCompanyMedicStateChange}
        />
    );
};

export default CompanyMedicSelectInput;
