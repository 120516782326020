import React, { FC } from 'react';

import classnames from 'classnames';
import { ModalHeader as ReactstrapModalHeader } from 'reactstrap';

import { LoadingSpinner } from '../../@paco/components';
import { IconButton } from '../../@paco/compositions';
import trans from '../../@paco/helpers/trans';

import './ModalHeader.scss';

interface ModalHeaderProps {
    isLoading?: boolean;
    showHistoryButton?: boolean;
    title: string;
    onHistoryButtonClick?: () => void;
    className?: string;
}

const ModalHeader:FC<ModalHeaderProps> = ({
    isLoading,
    showHistoryButton,
    title,
    onHistoryButtonClick,
    className,
}) => {
    const classNames = classnames('modal-header', {
        'modal-header--is-loading': isLoading,
    }, [className]);

    return (
        <ReactstrapModalHeader className={classNames}>
            {title}

            {isLoading && <LoadingSpinner className="modal-header__loader" />}

            {(!isLoading && showHistoryButton) && (
                <IconButton
                    hideLabel
                    icon="history"
                    text={trans('compositions.modalHeader.showHistory')}
                    onClick={onHistoryButtonClick}
                    className="modal-header__history-button"
                    iconClassName="modal-header__history-button-icon"
                />
            )}
        </ReactstrapModalHeader>
    );
};

export default ModalHeader;

