import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import classnames from 'classnames';
import { Col, Input } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { Absence, LeaveOfAbsence, WeekWithHours } from '../../../../models';
import QuestionToolTip from '../../../QuestionToolTip/QuestionToolTip';
import getInputTooltipText from '../../helpers/getInputToolTipText';
import { onInputChangeType } from '../../models/WeekGroup';

import './WeekInput.scss';


interface Props {
    weekday: WeekWithHours;
    type: Absence['type'] | LeaveOfAbsence['type'];
    disabled: boolean;
    hasError: boolean;
    onChange?: onInputChangeType,
}


const WeekInput: FC<Props> = ({
    weekday,
    type,
    disabled,
    hasError,
    onChange,
}) => {
    const [value, setValue] = useState<number | null>(0);
    const id = `input-${weekday.id}`;
    const classNames = classnames('week-input', {
        'week-input--has-error': hasError,
    });

    const title = useMemo(() => {
        const resourceType = translate(type === 'absences' ? 'pages.management.sicknessHours' : 'pages.absences.leaveOfAbsenceHours');

        if (!weekday.weekNumber) {
            return translate('common.amountOfHoursInPeriod', {
                type: resourceType.toLowerCase(),
                periodNumber: weekday.payrollPeriod?.periodNumber || '?',
            });
        }

        return translate('common.amountOfHoursInWeek', {
            type: resourceType.toLowerCase(),
            weekNumber: weekday.weekNumber || '?',
        });
    }, [weekday]);
    const tooltipText = useMemo(() => getInputTooltipText(weekday), [weekday]);
    const noPayrollPeriod = !weekday.payrollPeriod;
    const isLocked = weekday.payrollPeriodWeeks.every(week => week.isLocked);

    useEffect(() => {
        setValue(weekday.hours);
    }, [weekday]);

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const number = parseFloat(e.target.value);
        const hours = number >= 0 ? number : null;
        setValue(hours);

        if (onChange) {
            onChange({
                ...weekday,
                hours,
            });
        }
    };

    return (
        <Col className={classNames}>
            <div className="week-input__number">
                <span>{title}</span>
                <QuestionToolTip
                    id={`${id}-tooltip`}
                    className="week-input__tooltip"
                >
                    {tooltipText}
                </QuestionToolTip>
            </div>
            <Input
                type="number"
                step="any"
                className="week-input__input"
                id={id}
                value={value !== null ? value : ''}
                disabled={disabled || isLocked || noPayrollPeriod}
                onChange={onInputChange}
            />
        </Col>
    );
};

export default WeekInput;
