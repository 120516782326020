import { ExperienceResource as LegacyExperienceResource } from '../../../models/Experience';
import { Experience, ExperienceResource, ExperienceV3Resource } from './Experience';

export const transformToExperience = (resource: ExperienceResource): Experience => ({
    id: resource.id,
    name: resource.attributes.name,
});

export const transformV3ResourceToExperience = (resource: ExperienceV3Resource): Experience => ({
    id: resource.id,
    name: resource.name,
});

export const transformLegacyExperienceResourceToExperience = (resource: LegacyExperienceResource): Experience => ({
    id: resource.id,
    name: resource.name,
});
