import React from 'react';

import PropTypes from 'prop-types';

import { ElementWithTooltip, IconNavLink } from '../../@paco/compositions';
import { doesPeriodOverlapWithLockedPayrollPeriod } from '../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import { checkPermission, getObjProperty } from '../../helpers';
import { getPermissionToEdit } from '../../helpers/permissions/getPermissionToAction';
import { translate } from '../../helpers/translations/translator';
import Close from '../../images/close-white.svg';
import Icon from '../style/Icon/Icon';
import LeaveOfAbsenceInfo from './LeaveOfAbsenceInfo';
import EmployeeLeaveAndTvTSaldo from './subcomponents/EmployeeLeaveAndTvTSaldo/EmployeeLeaveAndTvTSaldo';

import './LeaveOfAbsenceRequestCard.scss';

const LeaveOfAbsenceRequestCard = ({
    onApprove,
    onDecline,
    onOpenCalendar,
    currentUserRole,
    absence,
    permissions,
    payrollPeriods = [],
    onShowLogsClick,
    onEditClick,
}) => {
    const overlappingLeaveOfAbsences = absence.overlappingLeaveOfAbsences || [];
    const pathBase = `/${translate('nav.absences.link')}`;
    const calendarPath = `${pathBase}/${translate('nav.absences.absenceCalendar.label')}`;
    const absenceUserRoles = getObjProperty(absence, 'user.roles');
    const canViewLogs = checkPermission(permissions, 'view-all-logs', 'leave-of-absences-card');
    const canEditLeaveOfAbsences = getPermissionToEdit(currentUserRole, absenceUserRoles, permissions, 'leave-of-absences');
    const canViewLeaveOfAbsenceHours = checkPermission(permissions, 'view-all-leave-of-absence-hours', 'leave-of-absences-card');
    const canEditLeaveOfAbsenceHours = getPermissionToEdit(currentUserRole, absenceUserRoles, permissions, 'leave-of-absence-hours');
    const canEdit = canEditLeaveOfAbsences && canEditLeaveOfAbsenceHours;
    const payrollPeriodError = doesPeriodOverlapWithLockedPayrollPeriod(
        transformToPeriod(new Date(absence.start), new Date(absence.end)),
        payrollPeriods,
    );
    const denyButtonClassName = `loa-request-card-button-decline ${!canEdit ? 'loa-request-card-button-decline--disabled' : ''}`;
    const acceptButtonClassName = `loa-request-card-button-accept ${(!canEdit || payrollPeriodError) ? 'loa-request-card-button-accept--disabled' : ''}`;

    const rolesOfAbsence = absence.user.roles;
    const hasRoleToSeeAbsenceHours = (rolesOfAbsence || []).some(roleOfAbsence => roleOfAbsence.priority <= currentUserRole?.priority);
    const calendarLinkText = `${overlappingLeaveOfAbsences.length} ${translate('pages.absences.otherEmployeesFree')}`;

    const handleOpenCalendar = () => {
        if (onOpenCalendar) onOpenCalendar();
    };

    return (
        <div className="loa-request-card-container">
            <div className="card loa-request-card request-card">
                <LeaveOfAbsenceInfo
                    absence={absence}
                    hasRoleToSeeAbsenceHours={hasRoleToSeeAbsenceHours}
                    onEditClick={canViewLeaveOfAbsenceHours ? onEditClick : null}
                    onShowLogsClick={canViewLogs ? onShowLogsClick : null}
                />
                <EmployeeLeaveAndTvTSaldo
                    loketBalances={absence.loketBalances}
                    hasRoleToSeeAbsenceHours={hasRoleToSeeAbsenceHours}
                    className="loa-request-card__saldo"
                />
                <div className="loa-request-card-bottom">
                    <div className="loa-request-card-bottom-left">
                        {overlappingLeaveOfAbsences.length ? (
                            <IconNavLink
                                icon="chevron-right"
                                iconPos="right"
                                text={calendarLinkText}
                                to={{
                                    pathname: calendarPath,
                                    search: `?focusAbsence=${absence.id}`,
                                }}
                                onClick={handleOpenCalendar}
                                iconClassName="loa-request-card__calendar-link-icon"
                            />
                        ) : (
                            <div className="loa-request-card__overlapping-leave-of-absences">
                                {calendarLinkText}
                            </div>
                        )}
                    </div>
                    { canEdit && (
                        <div className="loa-request-card-bottom-right">
                            <ElementWithTooltip
                                tooltipIsActive={payrollPeriodError}
                                tooltipText={translate('pages.absences.leaveOfAbsenceRequestOverlapsWithLockedPeriod')}
                            >
                                <button
                                    className={denyButtonClassName}
                                    type="button"
                                    onClick={() => onDecline('id')}
                                >
                                    <img alt="close-icon" src={Close} />
                                </button>
                            </ElementWithTooltip>
                            <ElementWithTooltip
                                tooltipIsActive={payrollPeriodError}
                                tooltipText={translate('pages.absences.leaveOfAbsenceRequestOverlapsWithLockedPeriod')}
                            >
                                <button
                                    className={acceptButtonClassName}
                                    type="button"
                                    onClick={() => onApprove('id')}
                                >
                                    <Icon kind="check" fontSize={24} />
                                </button>
                            </ElementWithTooltip>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

LeaveOfAbsenceRequestCard.propTypes = {
    absence: PropTypes.object.isRequired,
    onApprove: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onOpenCalendar: PropTypes.func,
    payrollPeriods: PropTypes.array,
    onShowLogsClick: PropTypes.func,
    onEditClick: PropTypes.func,
};

export default LeaveOfAbsenceRequestCard;
