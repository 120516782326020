import { FC } from 'react';

import { DistrictDivisionDistrict } from '../../../../entities/DistrictDivisionDistrict/DistrictDivisionDistrict';
import { Setting } from '../../../../entities/Setting/Setting';
import { PrintableDistrictDivisionDistrictPlanning } from '../index';

import './PrintableDistrictDivisionDistrict.scss';

interface PrintableDistrictDivisionDistrictProps {
    districtDivisionDistrict: DistrictDivisionDistrict;
    settings: Setting[];
    className?: string;
}

const PrintableDistrictDivisionDistrict: FC<PrintableDistrictDivisionDistrictProps> = ({
    districtDivisionDistrict,
    settings,
    className = '',
}) => (
    <div className={`printable-district-division-district ${className}`}>
        <h2 className="printable-district-division-district__title">
            {districtDivisionDistrict.name}
        </h2>
        <ul className="printable-district-division-district__plannings-list">
            {districtDivisionDistrict.districtDivisionDistrictPlannings.map((planning, index) => (
                <PrintableDistrictDivisionDistrictPlanning
                    key={planning.id}
                    index={index}
                    planning={planning}
                    settings={settings}
                    className="printable-district-division-district__planning"
                />
            ))}
        </ul>
    </div>
);

export default PrintableDistrictDivisionDistrict;
