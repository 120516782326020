export default class Translator {
    /**
     * @type {object}
     */
    translations = {};

    /**
     * @param {object} translations
     */
    constructor(translations = {}) {
        this.setTranslations(translations);
    }

    /**
     * Set translations
     *
     * @param {object} translations
     */
    setTranslations(translations) {
        this.translations = translations;
    }

    /**
     *
     * Return all translations
     *
     * @returns {object}
     */
    getTranslations() {
        return this.translations;
    }

    /**
     * Resolve translation and replace (when passed) options
     *
     * @param {string} translation
     * @param {object} [options={}]
     * @return {string}
     */
    translate(translation, options = {}) {
        const splitLabels = translation.split('.');

        let result = this.translations[splitLabels[0]];

        if (!result) {
            return '';
        }

        splitLabels.forEach(splitLabel => {
            if (result[splitLabel]) {
                result = result[splitLabel];
            }
        });

        return (typeof result === 'string') ? this.replaceOptions(result, options) : translation;
    }

    /**
     * Replace translations flags with options
     *
     * @param {string} translation
     * @param {object} [options]
     * @return {string}
     */
    replaceOptions = (translation, options = {}) => translation.replace(/:(\w+)/gi, (word) => {
        const key = word.replace(/^:/, '');

        if (options[key] === undefined || options[key] === null || options[key] === false) {
            return '';
        }

        return options[key];
    }).trim();
}

export const translatorInstance = new Translator();

/**
 * Export Translator.land
 *
 * @lends Translator.prototype.lang
 */
export function translate(translation, options) {
    return translatorInstance.translate(translation, options);
}

/**
 * Export Translator.setLabels
 *
 * @lends Translator.prototype.setTranslations
 */
export function setTranslations(translations) {
    return translatorInstance.setTranslations(translations);
}
