import { DepartmentResource } from '../../entities/Departments/Departments';
import { transformDepartmentResource } from '../../entities/Departments/DepartmentsTransformers';
import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import {
    ShiftPlanningResource,
    ShiftResource,
    ShiftType,
    ShiftViewModel,
    TemporaryWorkerResource,
    UserResource,
} from '../../models';
import transformShiftPlanningResource from '../ShiftPlanningService/transformShiftPlanningResource';
import { transformTemporaryWorkerResource } from '../TemporaryWorkerService/transformTemporaryWorkerResource';
import transformUserResource from '../UserService/transformUserResource';
import getShiftStatus from './getShiftStatus';

export function transformShiftResource(
    shift: ShiftResource,
    plannedUsers: UserResource[] = [],
    shiftPlannings: ShiftPlanningResource[] = [],
    department?: DepartmentResource,
    temporaryWorkers: TemporaryWorkerResource[] = [],
    previouslyPlannedUsers: UserResource[] = [],
): ShiftViewModel {
    const plannings = shiftPlannings.map((shiftPlanning) => transformShiftPlanningResource(
        shiftPlanning,
        shiftPlanning.user,
    ));
    const start = new Date(shift.start);
    const status = getShiftStatus(
        plannings,
        shift.peopleLimit,
        start,
        shift.temporaryWorkers?.length || 0,
    );

    return {
        status,
        start,
        type: ShiftType.shift,
        id: shift.id,
        department: department ? transformDepartmentResource(department) : undefined,
        shiftPlannings: plannings,
        end: new UpToButExcludingDate(shift.end).transformToUpToAndIncludingDate(),
        peopleLimit: shift.peopleLimit,
        plannedUsers: plannedUsers.map((user) => transformUserResource(user)),
        temporaryWorkers: temporaryWorkers
            .map((worker) => transformTemporaryWorkerResource(worker)),
        previouslyPlannedUsers: previouslyPlannedUsers.map((user) => transformUserResource(
            user,
            user.departments,
            user.roles,
            user.employmentType,
        )),
    };
}
