/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconDraggable: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 24 24">
        <rect height="2" width="16" x="4" y="10" />
        <rect height="2" width="16" x="4" y="14" />
    </svg>
);

export default IconDraggable;
/* eslint-enable max-len */
