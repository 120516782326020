import { FC, ReactElement, useMemo } from 'react';

import classnames from 'classnames';
import {
    ActionMeta,
    FormatOptionLabelMeta,
    MultiValueProps,
    StylesConfig,
} from 'react-select';

import { ErrorLabel, InputLabel } from '../../../components';
import MultiSelect from '../../../components/@inputs/MultiSelect/MultiSelect';
import { SelectOption } from '../../../components/@inputs/Select/Select';
import Icon from '../../../components/Icon/Icon';
import { Department } from '../../../entities/Department/Department';
import trans from '../../../helpers/trans';
import { DepartmentOption } from '../../../types/selectOptionTypes';
import { generateGroupedDepartmentOptions } from '../DepartmentInput/helpers/generateGroupedDepartmentOptions';
import { getDepartmentOptionsByGroupId } from './helpers/getDepartmentOptionsByGroupId';
import { getTrimmedDepartmentOptions } from './helpers/getTrimmedDepartmentOptions';
import { removeDepartmentOptionsByGroupId } from './helpers/removeDepartmentOptionsByGroupId';
import { transformDepartmentOptionsToDepartments } from './helpers/transformDepartmentOptionsToDepartments';
import { GroupLabel } from './subcomponents';
import { GroupLabelData } from './subcomponents/GroupLabel/GroupLabel';

import './DepartmentsInput.scss';

interface DepartmentsInputProps {
    isLoading?: boolean;
    isSearchable?: boolean;
    disabled?: boolean;
    hideLabel?: boolean;
    hideGroupLabel?: boolean;
    menuIsOpen?: boolean;
    required?: boolean;
    hideSelectedOptions?: boolean;
    departments: Department[];
    error?: string;
    label?: string;
    placeholder?: string;
    selectedDepartments: DepartmentOption[];
    valueLabel?: string;
    onDepartmentsChange: (departments: DepartmentOption[]) => void;
    onMenuClose?: () => void;
    onMenuOpen?: () => void;
    className?: string;
}

const DepartmentsInput: FC<DepartmentsInputProps> = ({
    isLoading = false,
    isSearchable,
    disabled,
    hideLabel = false,
    hideGroupLabel = false,
    menuIsOpen,
    required = false,
    hideSelectedOptions,
    departments = [],
    error,
    label = trans('common.departments'),
    placeholder,
    selectedDepartments = [],
    valueLabel,
    onDepartmentsChange,
    onMenuClose,
    onMenuOpen,
    className = '',
}): ReactElement => {
    const labelClassName = classnames('departments-input', {
        'departments-input--has-custom-label-value': valueLabel,
    }, className);

    const groupedDepartments = useMemo(() => generateGroupedDepartmentOptions(departments), [departments]);

    const handleChange = (selectedDepartmentOptions: DepartmentOption[], actionMeta: ActionMeta<DepartmentOption>): void => {
        if (actionMeta.removedValue && actionMeta.removedValue.isGroup) {
            const filteredDepartmentOptions = removeDepartmentOptionsByGroupId(departments, selectedDepartmentOptions, actionMeta.removedValue.value);

            onDepartmentsChange(filteredDepartmentOptions);

            return;
        }

        if (actionMeta.action === 'deselect-option') {
            onDepartmentsChange(selectedDepartmentOptions);

            return;
        }


        const trimmedDepartmentOptions = getTrimmedDepartmentOptions(
            transformDepartmentOptionsToDepartments(departments, selectedDepartmentOptions),
            departments,
        );

        onDepartmentsChange(trimmedDepartmentOptions);
    };

    const onGroupButtonClick = (groupId: string): void => {
        const departmentOptions = getDepartmentOptionsByGroupId(departments, groupId);
        const filteredDepartments = selectedDepartments.filter(department => !departmentOptions.map(departmentOfGroup => departmentOfGroup.value).includes(department.value));

        onDepartmentsChange([...departmentOptions, ...filteredDepartments]);
    };

    const formatGroupLabel = (groupLabelData: GroupLabelData): ReactElement | undefined => (
        <GroupLabel
            groupLabel={groupLabelData}
            title={trans('compositions.departmentsInput.addDepartmentGroup')}
            onGroupButtonClick={onGroupButtonClick}
        />
    );

    const formatOptionLabel = (departmentOption: DepartmentOption, formatOption: FormatOptionLabelMeta<DepartmentOption>): ReactElement => {
        const classNames = classnames('departments-input__option', {
            'departments-input__option--is-deleted': departmentOption.isDeleted,
            'departments-input__option--is-group': departmentOption.isGroup,
            'departments-input__option--has-full-group': departmentOption.hasFullGroup,
        });

        if (formatOption.context === 'value' && valueLabel) {
            return (
                <div className="departments-input__custom-value-label">
                    {valueLabel}
                </div>
            );
        }

        return (
            <div className={classNames}>
                <Icon
                    name="filter"
                    className="departments-input__option-icon"
                />
                <div className="departments-input__option-label">
                    {departmentOption.label}
                </div>
            </div>
        );
    };

    const isOptionDisabled = (option: DepartmentOption): boolean => !!option.isDeleted;

    const styles: StylesConfig<SelectOption> = {
        multiValue: (base, props: MultiValueProps<DepartmentOption>) => ({
            ...base,
            display: props.data.hasFullGroup ? 'none' : base.display,
        }),
    };

    const trimmedSelectedDepartments = useMemo(() => getTrimmedDepartmentOptions(
        transformDepartmentOptionsToDepartments(departments, selectedDepartments),
        departments,
    ), [departments, selectedDepartments]);

    return (
        <label
            aria-label={label}
            className={labelClassName}
        >
            {!hideLabel && <InputLabel text={label} required={required} />}
            <MultiSelect
                closeMenuOnSelect={false}
                disabled={disabled}
                hideSelectedOptions={hideSelectedOptions}
                isLoading={isLoading}
                isOptionDisabled={isOptionDisabled}
                isSearchable={isSearchable}
                menuIsOpen={menuIsOpen}
                formatGroupLabel={!hideGroupLabel ? formatGroupLabel : undefined}
                formatOptionLabel={formatOptionLabel}
                noOptionsMessage={trans('common.noDepartmentFound')}
                placeholder={placeholder}
                styles={styles}
                value={trimmedSelectedDepartments}
                options={groupedDepartments}
                onChange={handleChange}
                onMenuClose={onMenuClose}
                onMenuOpen={onMenuOpen}
            />
            {error && <ErrorLabel text={error} className="departments-input__error-label" />}
        </label>
    );
};

export default DepartmentsInput;
