import { FC } from 'react';

import { Icon } from '../../../../components';

import './CalendarActionButton.scss';

type HTMLButtonProps = JSX.IntrinsicElements['button'];

interface CalendarActionButtonProps extends HTMLButtonProps {
    className?: string;
    iconName?: 'plus' | 'plus-shift-concept',
}

const CalendarActionButton:FC<CalendarActionButtonProps> = ({
    className = '',
    iconName = 'plus',
    ...props
}) => (
    <button
        className={`calendar-action-button ${className}`}
        type="button"
        {...props}
    >
        <div className="calendar-action-button__circle">
            <Icon className="calendar-action-button__icon" name={iconName} />
        </div>
    </button>
);

export default CalendarActionButton;
