export enum LeaveType {
    normal = 'normal',
    special = 'special',
    tvt = 'time-for-time',
    unpaid = 'unpaid',
}

export enum PzLeaveType {
    normal = 'normal',
    leaveAboveStatutory = 'leave-above-statutory',
    special = 'special',
    timeForTime = 'time-for-time',
    unpaid = 'unpaid',
    adv = 'adv',
    unpaidLeaveFromLifeCoursePlan = 'unpaid-leave-from-life-course-plan',
    healthcareLeave = 'healthcare-leave',
    childLeave = 'child-leave',
    emergencyLeave = 'emergency-leave',
    holidays = 'holidays',
    shortAbsence = 'short-absence',
    timeSavingsFund = 'time-savings-fund',
    otherLeaveNonYearsExceeding = 'other-leave-non-years-exceeding',
    otherLeaveYearsExceeding = 'other-leave-years-exceeding',
}
