import React, { Component } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';
import {
    Button,
    Col,
    CustomInput,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { InputLabel } from '../../../@paco/components';
import { doesPeriodOverlapWithLockedPayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformToPeriod } from '../../../@paco/entities/Period/PeriodTransformers';
import { getAbnormalTrackDurationThresholdSettingValue } from '../../../@paco/entities/Setting/SettingHelpers';
import ButtonHistory from '../../../components/ButtonHistory/ButtonHistory';
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker';
import TrackDurationTooLongWarning from '../../../components/TrackDurationTooLongWarning/TrackDurationTooLongWarning';
import UserPageLink from '../../../components/UserPageLink/UserPageLink';
import { BREAK_TIMES, MEALS } from '../../../constants';
import { compareStringsNoCase, getObjProperty, getUserFullName } from '../../../helpers';
import {
    compareAsc,
    formatDate,
    isValid,
    setHours,
    setMinutes,
} from '../../../helpers/date';
import { isValidDate } from '../../../helpers/date/isValidDate';
import transformTimeStringToNumber from '../../../helpers/date/transformTimeStringToNumber';
import { translate } from '../../../helpers/translations/translator';
import { getShiftStartAndEndTimeOfTrack } from '../helpers/getShiftStartAndEndTimeOfTrack';
import getEditTrackError from './helpers/getEditTrackError';

import './EditTrack.scss';

class EditTrackLegacy extends Component {
    formRef = React.createRef();

    state = {
        data: {},
        canSubmit: false,
        canApproveAndSubmit: false,
        dateError: false,
        payrollPeriodError: false,
        checkInDate: new Date(),
        checkOutDate: new Date(),
        checkInTime: '00:00',
        checkOutTime: '00:00',
        totalBillableTime: undefined,
    };

    componentDidMount() {
        const { track = null } = this.props;
        const billableStart = getObjProperty(track, 'billableStart');
        const totalBillableTime = track?.totalBillableTime ? transformTimeStringToNumber(track.totalBillableTime) : undefined;
        const checkIn = isValid(billableStart) ? billableStart : getObjProperty(track, 'checkIn');
        const checkOut = track?.billableEnd?.transformToUpToButExcludingDate().date || new Date();

        this.setState({
            totalBillableTime,
            checkInDate: new Date(formatDate(checkIn, 'yyyy-MM-dd')),
            checkOutDate: formatDate(checkOut, 'yyyy-MM-dd'),
            checkInTime: formatDate(checkIn, 'HH:mm'),
            checkOutTime: formatDate(checkOut, 'HH:mm'),
        });
        this.serializeForm();
    }

    static getDerivedStateFromProps(props, state) {
        const { payrollPeriods = [] } = props;
        const {
            checkInDate, checkInTime, checkOutDate, checkOutTime,
        } = state;
        const {
            pause, meal, department,
        } = state.data;

        const checkIn = setMinutes(setHours(checkInDate, checkInTime.split(':')[0]), checkInTime.split(':')[1]);
        const checkOut = setMinutes(setHours(checkOutDate, checkOutTime.split(':')[0]), checkOutTime.split(':')[1]);
        const period = isValidDate(checkIn) && isValidDate(checkOut) ? transformToPeriod(checkIn, checkOut) : undefined;
        const dateError = compareAsc(checkIn, checkOut) === 1;

        const payrollPeriodError = period ? doesPeriodOverlapWithLockedPayrollPeriod(period, payrollPeriods) : false;

        const canSubmit = !!pause && !!meal && department !== 'null' && !dateError && checkInTime && checkOutTime;
        const canApproveAndSubmit = canSubmit && !payrollPeriodError;

        return {
            ...state,
            dateError,
            payrollPeriodError,
            canSubmit,
            canApproveAndSubmit,
        };
    }

    serializeForm = (e) => {
        if (e) {
            e.preventDefault();
        }

        const data = serialize(this.formRef.current, { hash: true });
        this.setState({ data });
    };

    onEditClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        this.onSubmit(undefined);
    };

    onApproveClick = (e) => {
        e.preventDefault();

        const { canSubmit } = this.state;
        if (canSubmit) this.onSubmit(true);
    };

    onSubmit = (accepted) => {
        const {
            data,
            checkInDate,
            checkInTime,
            checkOutDate,
            checkOutTime,
        } = this.state;
        const { track = null, onSubmit } = this.props;
        const checkIn = setMinutes(setHours(checkInDate, checkInTime.split(':')[0]), checkInTime.split(':')[1]);
        const checkOut = setMinutes(setHours(checkOutDate, checkOutTime.split(':')[0]), checkOutTime.split(':')[1]);

        const trackData = {
            ...accepted && { accepted: true },
            ...(!data.accepted && { department: data.department }),
            checkIn,
            checkOut,
            pause: data.pause,
            meal: data.meal,
        };

        onSubmit(track.id, trackData, accepted);
    };

    onChangeDate = (key, val) => {
        this.setState({
            [key]: val,
        });
    };

    getDepartmentOptions = (currentDepartment) => {
        const id = getObjProperty(currentDepartment, 'id');
        const { track = null } = this.props;
        return [
            !id && <option value="null" key="null">{translate('common.notSelected')}</option>,
            ...(track?.owner?.departments || []).map(department => (
                <option
                    key={department.id}
                    value={department.id}
                >
                    {department.name}
                </option>
            )),
        ];
    };

    getPauseTimeOptions = () => BREAK_TIMES.map(time => (
        <option key={time} value={time}>{time}</option>
    ));

    getMealOptions = () => MEALS.map(meal => (
        <option
            key={meal.value}
            value={meal.value}
        >
            {meal.label}
        </option>
    ));

    render() {
        const {
            canEdit,
            settings,
            title,
            onCancel,
            onShowLogsClick = null,
            track = null,
        } = this.props;
        const {
            canSubmit,
            canApproveAndSubmit,
            dateError,
            payrollPeriodError,
            totalBillableTime,
            checkOutDate,
            checkOutTime,
            checkInDate,
            checkInTime,
        } = this.state;

        if (!track) {
            return null;
        }

        const department = track.department || track.shift?.department;
        const defaultMeal = MEALS.filter(meal => compareStringsNoCase(meal.value, track.meal))[0];

        const abnormalTrackDurationTreshold = getAbnormalTrackDurationThresholdSettingValue(settings, track?.department?.id);
        const showAbnormalTrackDurationWarning = totalBillableTime > abnormalTrackDurationTreshold;

        return (
            <form
                className="form-edit-track"
                onChange={this.serializeForm}
                onSubmit={track.accepted ? this.onEditClick : this.onApproveClick}
                ref={this.formRef}
            >
                <ModalHeader className="form-edit-track__modal-header">
                    <span>{title}</span>
                    { onShowLogsClick && <ButtonHistory className="form-edit-track__button-history" onClick={onShowLogsClick} /> }
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.employee')}</Label>
                                <InputLabel
                                    required
                                    text={translate('common.employee')}
                                />
                                <p className="form-fake-field">
                                    <UserPageLink
                                        isPlain
                                        name={getUserFullName(track.owner)}
                                        id={track.owner.id}
                                    />
                                </p>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <InputLabel
                                    required
                                    text={translate('common.department')}
                                />
                                <CustomInput
                                    disabled={track.accepted && department}
                                    type="select"
                                    id="department"
                                    name="department"
                                    defaultValue={department ? department.id : null}
                                >
                                    {this.getDepartmentOptions(department)}
                                </CustomInput>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.shift')}</Label>
                                {track.shift ? (
                                    <p className="form-fake-field">
                                        {getShiftStartAndEndTimeOfTrack(track)}
                                    </p>
                                ) : (
                                    <p className="form-fake-field">
                                        { translate('pages.tracks.unplanned') }
                                    </p>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <InputLabel
                                    required
                                    text={translate('pages.tracks.startDate')}
                                />
                                <FormDatePicker
                                    selected={checkInDate}
                                    invalid={dateError || payrollPeriodError}
                                    onChange={val => this.onChangeDate('checkInDate', val)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-0">
                                <InputLabel
                                    required
                                    text={translate('pages.tracks.startTime')}
                                />
                                <CustomInput
                                    className="form-control required"
                                    type="time"
                                    name="start-time"
                                    id="start-time"
                                    onChange={e => this.onChangeDate('checkInTime', e.target.value)}
                                    value={checkInTime}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <InputLabel
                                    required
                                    text={translate('pages.tracks.endDate')}
                                />
                                <FormDatePicker
                                    selected={checkOutDate || checkInDate}
                                    onChange={val => this.onChangeDate('checkOutDate', val)}
                                    invalid={dateError || payrollPeriodError}
                                    feedback={getEditTrackError(dateError, payrollPeriodError)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-0">
                                <InputLabel
                                    required
                                    text={translate('pages.tracks.endTime')}
                                />
                                <CustomInput
                                    className="form-control required"
                                    type="time"
                                    name="end-time"
                                    id="start-time"
                                    onChange={e => this.onChangeDate('checkOutTime', e.target.value)}
                                    value={checkOutTime}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="mb-0">
                                <InputLabel
                                    required
                                    text={translate('pages.tracks.pauseTime')}
                                />
                                <CustomInput
                                    type="select"
                                    defaultValue={track.pause}
                                    name="pause"
                                    id="pause"
                                >
                                    <option value="">{translate('common.notSelected')}</option>
                                    {this.getPauseTimeOptions()}
                                </CustomInput>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-0">
                                <Label>{translate('common.meal')}</Label>
                                <CustomInput
                                    type="select"
                                    defaultValue={defaultMeal && defaultMeal.value}
                                    name="meal"
                                    id="meal"
                                >
                                    {this.getMealOptions()}
                                </CustomInput>
                            </FormGroup>
                        </Col>
                    </Row>
                    {showAbnormalTrackDurationWarning && (
                        <Row>
                            <Col>
                                <TrackDurationTooLongWarning className="form-edit-track__warning" />
                            </Col>
                        </Row>
                    )}
                </ModalBody>
                <ModalFooter className="form-edit-track__modal-footer">
                    <Button type="button" color="link" id="modal-close" className="form-edit-track__button-cancel" onClick={onCancel}>
                        {translate('common.cancel')}
                    </Button>
                    {canEdit && (
                        <Button
                            type={track.accepted ? 'submit' : 'button'}
                            disabled={!canSubmit}
                            color="orange"
                            className="form-edit-track__button-submit"
                            onClick={this.onEditClick}
                        >
                            {translate('common.edit')}
                        </Button>
                    )}
                    {!track.accepted && (
                        <Button
                            type="submit"
                            disabled={!canApproveAndSubmit}
                            color="green"
                            className="form-edit-track__button-submit"
                        >
                            {title}
                        </Button>
                    )}
                </ModalFooter>
            </form>
        );
    }
}

EditTrackLegacy.propTypes = {
    canEdit: PropTypes.bool.isRequired,
    payrollPeriods: PropTypes.array,
    settings: PropTypes.array.isRequired,
    track: PropTypes.object,
    title: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onShowLogsClick: PropTypes.func,
};

export default EditTrackLegacy;
