import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { LoadingSpinner } from '../../../../@paco/components';
import FixedCalendarHeader from '../../../../components/FixedCalendarHeader/FixedCalendarHeader';
import { TIME_MODES } from '../../../../constants';
import { areDatesInSameMonth, formatDate } from '../../../../helpers/date';
import Day from './Day';

class Days extends Component {
    onAbsenceMouseEnter = (id) => {
        const elements = document.getElementsByClassName(`calendar-absence-${id}`);
        Array.from(elements).forEach(element => {
            element.classList.add('calendar-absence--active');
        });
    };

    onAbsenceMouseLeave = (id) => {
        const elements = document.getElementsByClassName(`calendar-absence-${id}`);
        Array.from(elements).forEach(element => {
            element.classList.remove('calendar-absence--active');
        });
    };

    render() {
        const {
            selectedDays,
            loading,
            mode,
            absences,
            focusAbsenceId = '',
            showHeader = true,
            mobile = false,
        } = this.props;

        return (
            <>
                {selectedDays.map((day, index) => {
                    const key = `${formatDate(day, 'yyyy-MM-dd')}-${focusAbsenceId || ''}`;
                    const outOfRange = mode === TIME_MODES.MONTH && !areDatesInSameMonth(day, selectedDays[selectedDays.length - 1]);

                    return (
                        <Day
                            {...this.props}
                            showDate
                            key={key}
                            index={index}
                            showHeader={index < 7 && showHeader}
                            day={day}
                            outOfRange={outOfRange}
                            last={selectedDays.length - 1 === index}
                            focusAbsenceId={focusAbsenceId || ''}
                            absences={absences}
                            onAbsenceMouseEnter={this.onAbsenceMouseEnter}
                            onAbsenceMouseLeave={this.onAbsenceMouseLeave}
                        />
                    );
                })}
                {loading && <LoadingSpinner />}
                {!mobile && (
                    <FixedCalendarHeader
                        mode={mode}
                        days={selectedDays}
                        resources={absences}
                    />
                )}
            </>
        );
    }
}

Days.propTypes = {
    absences: PropTypes.array.isRequired,
    mode: PropTypes.string.isRequired,
    focusAbsenceId: PropTypes.string,
    selectedDays: PropTypes.array.isRequired,
    showHeader: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    onAbsenceClick: PropTypes.func.isRequired,
    filter: PropTypes.array.isRequired,
    mobile: PropTypes.bool,
};

export default Days;
