import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiV3Url } from '../../helpers/url';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import { PayrollPeriodWeek } from './PayrollPeriodWeek';
import { isPayrollPeriodWeekResource } from './PayrollPeriodWeekHelpers';
import { transformToPayrollPeriodWeek } from './PayrollPeriodWeekTransformers';

export const togglePayrollPeriodWeekLockApiCall = async (id: string, lock: boolean): Promise<FetchResult<PayrollPeriodWeek, string>> => {
    try {
        const url = generateApiV3Url({ endpoint: `/payroll-period-weeks/${id}/${lock ? 'lock' : 'unlock'}` });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
        });

        const doc: JapiDocument = await response.json();

        if (!isPayrollPeriodWeekResource(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const payrollPeriodWeek = transformToPayrollPeriodWeek(doc);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: payrollPeriodWeek,
        };
    } catch (error) {
        console.error('[togglePayrollPeriodWeekLockApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
