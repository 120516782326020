import { FC } from 'react';

import { Icon } from '../../../../components';

import './AddUserButton.scss';

export interface AddUserButtonProps {
    userId?: string;
    labelText: string;
    onClick: (userId?: string) => void;
    className?: string;
}

const AddUserButton: FC<AddUserButtonProps> = ({
    userId,
    labelText,
    onClick,
    className = '',
}) => {
    const handleButtonClick = (): void => {
        if (!userId) {
            onClick();

            return;
        }

        if (userId) {
            onClick(userId);
        }
    };

    return (
        <button
            type="button"
            onClick={handleButtonClick}
            className={`add-user-button ${className}`}
        >
            <label className="add-user-button__label">{labelText}</label>
            <div className="add-user-button__divider" />
            <div className="add-user-button__icon-wrapper">
                <Icon
                    name="plus-circle"
                    className="add-user-button__icon"
                />
            </div>
        </button>
    );
};

export default AddUserButton;
