import { addSeconds } from 'date-fns';

import { LeaveOfAbsenceWithCalendarPeriod } from '../../containers/@tables/LeaveOfAbsencesOverviewTable/entities/LeaveOfAbsenceWithCalendarPeriod/LeaveOfAbsenceWithCalendarPeriod';
import { transformLeaveOfAbsenceWithCalendarPeriod }
    from '../../containers/@tables/LeaveOfAbsencesOverviewTable/entities/LeaveOfAbsenceWithCalendarPeriod/LeaveOfAbsenceWithCalendarPeriodTransformers';
import { LeaveOfAbsence } from '../LeaveOfAbsence/LeaveOfAbsence';
import { doesPeriodOverlapWithWorkday, getOverlappingWorkdaysWithPeriod, someOverlappingWorkdaysWithPeriod } from '../Period/PeriodHelpers';
import { LegacyBasicUser } from '../User/User';
import { LeaveOfAbsencesOverviewBlock, LeaveOfAbsencesOverviewBodyCell, LeaveOfAbsencesOverviewBodyRow } from './LeaveOfAbsencesOverviewBlock';

export const transformLeaveOfAbsenceToLeaveOfAbsencesOverviewBlock = (
    startsInCalendar: boolean,
    endInCalendar: boolean,
    amountOfDaysInCalendar: number,
    leaveOfAbsence: LeaveOfAbsence,
): LeaveOfAbsencesOverviewBlock => ({
    startsInCalendar,
    endsInCalendar: endInCalendar,
    amountOfDaysInCalendar,
    ...leaveOfAbsence,
});

export const transformToLeaveOfAbsencesOverviewBlock = (leaveOfAbsence: LeaveOfAbsenceWithCalendarPeriod, days: Date[]): LeaveOfAbsencesOverviewBlock => {
    const startsInDaysRange = someOverlappingWorkdaysWithPeriod(days, { start: leaveOfAbsence.period.start, end: addSeconds(leaveOfAbsence.period.start, 1) });
    const endsInDaysRange = someOverlappingWorkdaysWithPeriod(days, { start: leaveOfAbsence.period.end, end: addSeconds(leaveOfAbsence.period.end, 1) });
    const amountOfDaysInCalendar = getOverlappingWorkdaysWithPeriod(days, leaveOfAbsence.period);

    return transformLeaveOfAbsenceToLeaveOfAbsencesOverviewBlock(
        startsInDaysRange,
        endsInDaysRange,
        amountOfDaysInCalendar,
        leaveOfAbsence,
    );
};

export const transformToLeaveOfAbsencesOverviewBodyCell = (day: Date, days: Date[], leaveOfAbsences: LeaveOfAbsence[]): LeaveOfAbsencesOverviewBodyCell => {
    const leaveOfAbsenceWithCalendarPeriod = leaveOfAbsences
        .map(leaveOfAbsence => transformLeaveOfAbsenceWithCalendarPeriod(leaveOfAbsence, days[0]))
        .find(leaveOfAbsence => {
            if (!someOverlappingWorkdaysWithPeriod(days, leaveOfAbsence.period)) {
                return false;
            }

            return doesPeriodOverlapWithWorkday(day, { start: leaveOfAbsence.calendarPeriod.start, end: addSeconds(leaveOfAbsence.calendarPeriod.start, 1) });
        });

    return {
        date: day,
        blocks: leaveOfAbsenceWithCalendarPeriod ? [transformToLeaveOfAbsencesOverviewBlock(leaveOfAbsenceWithCalendarPeriod, days)] : [],
    };
};

export const transformToLeaveOfAbsencesOverviewBodyRows = (days: Date[], leaveOfAbsences: LeaveOfAbsence[], users: LegacyBasicUser[]): LeaveOfAbsencesOverviewBodyRow[] => users.map(user => ({
    user,
    cells: days.reduce((total, day) => {
        const userLeaveOfAbsences = leaveOfAbsences.filter(leaveOfAbsence => leaveOfAbsence.user.id === user.id);
        const usedUserLeaveOfAbsences = total.map(cell => cell.blocks.map(block => block.id)).flat();
        // Exclude all the leaveOfAbsences that are already indexed for small performance boost
        const userLeaveOfAbsencesLeft = userLeaveOfAbsences.filter(leaveOfAbsence => !usedUserLeaveOfAbsences.includes(leaveOfAbsence.id));

        const cell = transformToLeaveOfAbsencesOverviewBodyCell(day, days, userLeaveOfAbsencesLeft);

        return [...total, cell];
    }, [] as LeaveOfAbsencesOverviewBodyCell[]),
}));
