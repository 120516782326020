import { ExperienceResource } from './Experience';

// In Track and Shift calls Experience is sometimes undefined even though it should exist.
export const getDummyExperienceResource = (id: string): ExperienceResource => ({
    id,
    type: 'experiences',
    attributes: {
        name: '?',
    },
});
