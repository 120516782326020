import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { AvailabilityActionBar } from '../../@paco/compositions';
import { ConnectedHelpTooltip } from '../../@paco/connectors';
import trans from '../../@paco/helpers/trans';
import { getPacoPayrollPeriods } from '../../@paco/redux/payrollPeriods/payrollPeriodsActions';
import NewFilters from '../../components/Filters/Filters';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import { AVAILABILITY_CALENDAR } from '../../constants';
import { deleteAbsence, editAbsence, editLeaveOfAbsence } from '../../redux/absences-ts/absencesActions';
import { getPayrollPeriods } from '../../redux/app/appActions';
import { setEmployeeSearch, setFilter } from '../../redux/filter/filterActions';
import { setUserPlanningPreference } from '../../redux/management/managementActions';
import { getAvailability } from '../../redux/shifts/shiftsActions';
import { setStartAndEndDate } from '../../redux/weekNavigator/weekNavigatorActions';
import DeclineLeaveOfAbsenceForm from '../Absences/forms/DeclineLeaveOfAbsenceForm';
import EditAbsenceForm from '../Absences/forms/EditAbsenceForm/EditAbsenceForm';
import ResolveLeaveOfAbsenceForm from '../Absences/forms/ResolveLeaveOfAbsenceForm/ResolveLeaveOfAbsenceForm';
import AddOrEditAvailabilityTime from '../Management/forms/AddOrEditAvailabilityTime/AddOrEditAvailabilityTime';
import Days from './components/AvailabilityDays/Days';

import './Availability.scss';

class Availability extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeAbsence: null,
            activeLeaveOfAbsence: null,
            editAbsenceData: null,
            activePlanningPreference: null,
            resolveAbsenceForm: false,
            resolveLeaveOfAbsenceForm: false,
            declineForm: false,
            editPlanningPreferenceForm: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(getPayrollPeriods());
        dispatch(getPacoPayrollPeriods());

        this.getData();
    }

    componentDidUpdate(prevProps) {
        const activeDepartmentOptions = this.props.departmentOptions.filter(option => option.isChecked);
        const prevActiveDepartmentOptions = prevProps.departmentOptions.filter(option => option.isChecked);

        if (activeDepartmentOptions.length !== prevActiveDepartmentOptions.length) {
            this.getData();
        }
    }

    getSnapshotBeforeUpdate(prevProps) {
        this.prevSelectedDays = prevProps.selectedDays;
    }

    setStartAndEndDate = (selectedDate, startDate, endDate) => {
        const { dispatch } = this.props;

        dispatch(setStartAndEndDate(selectedDate, startDate, endDate));

        this.getData();
    };

    getData = () => {
        const { dispatch } = this.props;

        dispatch(getAvailability());
    };

    dispatchEditAbsence = (
        absence,
        startDate,
        endDate,
        periodsWithHours,
        weeksWithHours,
        waitingDayHours,
    ) => {
        const { dispatch } = this.props;

        dispatch(editAbsence(
            absence,
            startDate,
            endDate,
            periodsWithHours,
            weeksWithHours,
            waitingDayHours,
            AVAILABILITY_CALENDAR,
        ));

        this.setState({ resolveAbsenceForm: false });
    };

    dispatchDeleteAbsence = (absenceId) => {
        const { dispatch } = this.props;

        dispatch(deleteAbsence(absenceId, AVAILABILITY_CALENDAR));

        this.setState({ resolveAbsenceForm: false });
    };

    dispatchDeclineLeaveOfAbsence = (declineComment) => {
        const { dispatch } = this.props;
        const { activeLeaveOfAbsence, editAbsenceData } = this.state;

        this.setState({
            resolveLeaveOfAbsenceForm: false,
            declineForm: false,
            activeLeaveOfAbsence: null,
        });

        dispatch(editLeaveOfAbsence({
            editType: 'deny',
            data: editAbsenceData,
            declineComment,
            payrollPeriodsWithHours: editAbsenceData.payrollPeriodsWithHours,
            weeksWithHours: editAbsenceData.weeksWithHours,
            leaveOfAbsence: activeLeaveOfAbsence,
            page: AVAILABILITY_CALENDAR,
        }));
    };

    dispatchEditLeaveOfAbsence = (
        editType,
        data,
        periodsWithHours,
        weeksWithHours,
        leaveType,
    ) => {
        const { dispatch } = this.props;
        const { activeLeaveOfAbsence } = this.state;

        this.setState({
            resolveLeaveOfAbsenceForm: false,
            activeLeaveOfAbsence: null,
        });

        dispatch(editLeaveOfAbsence({
            editType,
            data,
            payrollPeriodsWithHours: periodsWithHours,
            weeksWithHours,
            leaveType,
            leaveOfAbsence: activeLeaveOfAbsence,
            page: AVAILABILITY_CALENDAR,
        }));
    };

    dispatchAvailabilityTime = (weekday, fromDate, toDate, comment) => {
        const { dispatch } = this.props;
        const { activePlanningPreference } = this.state;
        const { id, user } = activePlanningPreference;

        this.setState({
            editPlanningPreferenceForm: false,
            activePlanningPreference: null,
        });

        dispatch(setUserPlanningPreference(
            weekday,
            fromDate,
            toDate,
            comment,
            user.id,
            id,
            AVAILABILITY_CALENDAR,
        ));
    };

    afterDateChange = () => {
        this.getData();
    };

    onSetFilter = (filter) => {
        const { dispatch } = this.props;

        dispatch(setFilter(filter, this.getData));
    };

    onSearchBarChange = (value) => {
        const { dispatch } = this.props;

        dispatch(setEmployeeSearch(value));

        this.getData();
    };

    onResourceClick = (resource) => {
        if (resource.type === 'absences') {
            this.setState({
                resolveAbsenceForm: true,
                activeAbsence: resource,
            });
        }

        if (resource.type === 'leaveOfAbsences') {
            this.setState({
                resolveLeaveOfAbsenceForm: true,
                activeLeaveOfAbsence: resource,
            });
        }

        if (resource.type === 'UnavailableToWorkTimeSlots') {
            this.setState({
                editPlanningPreferenceForm: true,
                activePlanningPreference: resource,
            });
        }
    };

    addDeclineLeaveOfAbsenceForm = (data, payrollPeriodsWithHours, weeksWithHours) => {
        this.setState({
            resolveLeaveOfAbsenceForm: false,
            editAbsenceData: {
                ...data,
                payrollPeriodsWithHours,
                weeksWithHours,
            },
            declineForm: true,
        });
    };

    onSubmitLeaveOfAbsenceForm = (
        editType,
        data,
        payrollPeriodsWithHours,
        weeksWithHours,
        leaveType,
    ) => {
        if (editType === 'deny') {
            this.addDeclineLeaveOfAbsenceForm(data, payrollPeriodsWithHours, weeksWithHours);
        } else {
            this.dispatchEditLeaveOfAbsence(
                editType,
                data,
                payrollPeriodsWithHours,
                weeksWithHours,
                leaveType,
            );
        }
    };

    cancelPlanningPreferenceForm = () => {
        this.setState({
            editPlanningPreferenceForm: false,
            activePlanningPreference: null,
        });
    };

    renderDays(selectedDays, mobile) {
        const {
            loading,
            leaveOfAbsencesLoading,
            managementLoading,
            shiftsLeaveOfAbsences,
            preferToWorkTimeSlots,
            unavailableToWorkTimeSlots,
            mode,
            absences,
        } = this.props;

        return (
            <Days
                loading={loading || leaveOfAbsencesLoading || managementLoading}
                mobile={mobile}
                selectedDays={selectedDays}
                mode={mode}
                leaveOfAbsences={shiftsLeaveOfAbsences}
                absences={absences}
                preferToWorkTimeSlots={preferToWorkTimeSlots}
                unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                onResourceClick={this.onResourceClick}
            />
        );
    }

    render() {
        const {
            selectedDate,
            filter,
            loading,
            managementLoading,
            timeMode,
            permissions,
            currentUser,
            pacoPayrollPeriods,
            payrollPeriods,
            departments,
            selectedDays,
        } = this.props;
        const {
            activeAbsence,
            activeLeaveOfAbsence,
            resolveLeaveOfAbsenceForm,
            declineForm,
            editPlanningPreferenceForm,
            activePlanningPreference,
            resolveAbsenceForm,
        } = this.state;

        // This will make sure shifts are still shown while loading
        const selectedDaysToRender = ((loading || managementLoading)
            && this.prevSelectedDays) ? this.prevSelectedDays : selectedDays;

        const { employeeSearch } = filter;

        return (
            <div className="availability-page">
                <SidebarPage
                    sidebarContent={(
                        <NewFilters
                            departments={departments}
                            filter={filter}
                            filterBlocks={[
                                'availability',
                            ]}
                            permissions={permissions}
                            onChange={this.onSetFilter}
                        />
                    )}
                >
                    <div className="calendar">
                        <AvailabilityActionBar
                            isSticky={selectedDays.length > 7}
                            employeeSearch={employeeSearch}
                            mobileSelectedDay={selectedDate}
                            payrollPeriods={payrollPeriods}
                            selectedDays={selectedDays}
                            timeMode={timeMode}
                            afterDateChange={this.afterDateChange}
                            onSearchBarChange={this.onSearchBarChange}
                            setStartAndEndDate={this.setStartAndEndDate}
                        />
                        <ConnectedHelpTooltip
                            index={1}
                            route="availability"
                            subTitle={trans('help.availability.legend.title')}
                            text={trans('help.availability.legend.text')}
                            title={trans('help.availability.title')}
                            className="calendar__legend-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            showMobileInfoWarning
                            index={2}
                            route="availability"
                            subTitle={trans('help.availability.viewOptions.title')}
                            text={trans('help.availability.viewOptions.text')}
                            title={trans('help.availability.title')}
                            className="calendar__view-options-help-tooltip"
                        />
                        <div className="calendar-days-desktop availability-page__days-desktop">
                            {this.renderDays(selectedDaysToRender)}
                        </div>
                        <div className="calendar-days-mobile">
                            {this.renderDays([selectedDate], true)}
                        </div>
                        <ConnectedHelpTooltip
                            index={0}
                            route="availability"
                            subTitle={trans('help.availability.filters.title')}
                            text={trans('help.availability.filters.text')}
                            title={trans('help.availability.title')}
                            showMobileInfoWarning
                            className="calendar__filters-help-tooltip"
                        />
                    </div>
                </SidebarPage>
                <Modal size="lg" isOpen={resolveAbsenceForm} className="form-resolve-absence">
                    <EditAbsenceForm
                        data={activeAbsence}
                        permissions={permissions}
                        currentUserRole={currentUser?.role}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        onSubmit={this.dispatchEditAbsence}
                        onDelete={this.dispatchDeleteAbsence}
                        onCancel={() => this.setState({ resolveAbsenceForm: false })}
                    />
                </Modal>
                <Modal size="lg" isOpen={resolveLeaveOfAbsenceForm} className="form-resolve-leave-of-absence">
                    <ResolveLeaveOfAbsenceForm
                        absence={activeLeaveOfAbsence}
                        currentUserRole={currentUser.role}
                        permissions={permissions}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        onSubmit={this.onSubmitLeaveOfAbsenceForm}
                        onCancel={() => this.setState({ resolveLeaveOfAbsenceForm: false })}
                        onShowLogsClick={this.toggleLogModal}
                    />
                </Modal>
                <Modal size="lg" isOpen={declineForm} className="form-decline-leave-of-absence">
                    <DeclineLeaveOfAbsenceForm
                        onSubmit={this.dispatchDeclineLeaveOfAbsence}
                        onCancel={() => this.setState({
                            declineForm: false, activeLeaveOfAbsence: null,
                        })}
                    />
                </Modal>
                <Modal size="lg" isOpen={editPlanningPreferenceForm} className="form-add-time">
                    <AddOrEditAvailabilityTime
                        data={activePlanningPreference}
                        permissions={permissions}
                        onCancel={this.cancelPlanningPreferenceForm}
                        onSubmit={this.dispatchAvailabilityTime}
                    />
                </Modal>
            </div>
        );
    }
}

Availability.propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectedDays: PropTypes.array.isRequired,
    selectedDate: PropTypes.object.isRequired,
    shiftsLeaveOfAbsences: PropTypes.array.isRequired,
    absences: PropTypes.array.isRequired,
    preferToWorkTimeSlots: PropTypes.array.isRequired,
    unavailableToWorkTimeSlots: PropTypes.array.isRequired,
    timeMode: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    managementLoading: PropTypes.bool.isRequired,
    filter: PropTypes.object.isRequired,
    departments: PropTypes.array.isRequired,
    departmentOptions: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    pacoPayrollPeriods: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
};

export default connect()(Availability);
