import React, { FC } from 'react';

import Icon from '../../../../components/style/Icon/Icon';

import './ButtonSchedule.scss';


type HTMLButtonProps = JSX.IntrinsicElements['button'];

interface ButtonScheduleProps extends HTMLButtonProps {
    className?: string;
    text?: string;
}

const ButtonSchedule: FC<ButtonScheduleProps> = ({
    className = '',
    text,
    ...props
}) => (
    <button
        type="button"
        className={`button-schedule ${className}`}
        {...props}
    >
        <Icon className="button-schedule__icon" kind="exit" />
        {text}
    </button>
);

export default ButtonSchedule;
