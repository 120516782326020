import { AbsencesRoutes } from '../../../pages/Absences/AbsencesRoutes';
import trans from '../../helpers/trans';
import { SubNavRoute } from '../routes';

export type AbsenceNavigationRouteKey =
    'leave-of-absence-requests' |
    'leave-of-absences-calendar' |
    'leave-of-absences-overview' |
    'exchange-requests' |
    'absences-list' |
    'absences-overview';

const absenceNavigationRoutes: SubNavRoute[] = [
    {
        key: 'leave-of-absence-requests',
        path: AbsencesRoutes.leaveOfAbsenceRequests(),
        label: trans('routes.absences.leaveOfAbsenceRequests.label'),
        permissions: ['view-all-leave-of-absences'],
    },
    {
        key: 'leave-of-absences-calendar',
        path: AbsencesRoutes.leaveOfAbsencesCalendar(),
        label: trans('routes.absences.leaveOfAbsencesCalendar.label'),
        permissions: ['view-all-leave-of-absences'],
    },
    {
        key: 'leave-of-absences-overview',
        path: AbsencesRoutes.leaveOfAbsencesOverview(),
        label: trans('routes.absences.leaveOfAbsencesOverview.label'),
        permissions: ['action-view-leave-of-absences'],
    },
    {
        key: 'exchange-requests',
        hasSomePermission: true,
        path: AbsencesRoutes.exchangeRequests(),
        label: trans('routes.absences.exchangeRequests.label'),
        permissions: ['view-all-exchanges', 'view-all-shift-exchanges'],
    },
    {
        key: 'absences-list',
        path: AbsencesRoutes.absencesList(),
        label: trans('routes.absences.absencesList.label'),
        permissions: ['view-all-absences'],
    },
    {
        key: 'absences-overview',
        path: AbsencesRoutes.absencesOverview(),
        label: trans('routes.absences.absencesOverview.label'),
        permissions: ['view-all-absences'],
    },
];

export default absenceNavigationRoutes;
