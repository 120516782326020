import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import Select, { OnChangeValue, SelectInstance } from 'react-select';

import { BasicUser } from '../../../entities/User/User';
import trans from '../../../helpers/trans';
import { SelectOption } from '../../../types';
import { transformToUserSelectOptions } from './helpers';

export interface UserSelectOption extends SelectOption<string, ReactElement> {
    userId: string;
}

interface UsersSelectProps {
    controlShouldRenderValue?: boolean;
    isDisabled?: boolean;
    users: BasicUser[];
    onSelect: (user: BasicUser) => void;
    className?: string;
}

const UsersSelect: FC<UsersSelectProps> = ({
    controlShouldRenderValue = true,
    isDisabled,
    users,
    onSelect,
    className = '',
}): ReactElement => {
    const selectRef = useRef<SelectInstance<UserSelectOption>|null>(null);

    const [userSelectedOption, setUserSelectedOption] = useState(false);

    const options: UserSelectOption[] = useMemo(() => (
        transformToUserSelectOptions(users)
    ), [users]);

    const handleSelectChange = (option: OnChangeValue<UserSelectOption, false>): void => {
        const selectedUser = users.find(user => user.id === option?.userId);

        if (selectedUser) {
            onSelect(selectedUser);
            setUserSelectedOption(true);
        }
    };

    useEffect((): void => {
        if (!isDisabled && userSelectedOption) {
            selectRef.current?.focus();
        }
    }, [isDisabled, userSelectedOption]);

    return (
        <Select
            ref={selectRef}
            controlShouldRenderValue={controlShouldRenderValue}
            isDisabled={isDisabled || !users.length}
            maxMenuHeight={200}
            noOptionsMessage={() => trans('compositions.usersSelect.noEmployeesFound')}
            options={options}
            placeholder={trans('compositions.usersSelect.searchEmployee')}
            onChange={handleSelectChange}
            className={`users-select ${className}`}
        />
    );
};

export default UsersSelect;
