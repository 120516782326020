import {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import { LoadingSpinner, TableCell, TableRow } from '../../../components';
import { SortValkTable } from '../../../compositions';
import IconButton from '../../../compositions/@buttons/IconButton/IconButton';
import { SortLabelInfo } from '../../../compositions/@tables/SortLabel/SortLabel';
import { Department } from '../../../entities/Department/Department';
import { sortDepartments } from '../../../entities/Department/DepartmentHelpers';
import trans from '../../../helpers/trans';
import { SortConfig, SortDirection, SortField } from '../../../types/tableTypes';

import './DepartmentsTable.scss';

interface DepartmentsTableProps {
    canDeleteDepartment?: boolean;
    canEditDepartment?: boolean;
    isLoading?: boolean;
    departments: Department[];
    onDeleteDepartmentClick?: (department: Department) => void;
    onEditDepartmentClick?: (department: Department) => void;
    className?: string;
}

const DepartmentsTable: FC<DepartmentsTableProps> = ({
    canDeleteDepartment,
    canEditDepartment,
    isLoading,
    departments,
    onDeleteDepartmentClick,
    onEditDepartmentClick,
    className = '',
}): ReactElement => {
    const [sortConfig, setSortConfig] = useState<SortConfig>({ field: SortField.group, direction: SortDirection.desc });

    const sortedDepartments = useMemo(() => sortDepartments([...departments], sortConfig.field, sortConfig.direction), [departments, sortConfig.field, sortConfig.direction]);

    const sortCells: SortLabelInfo[] = [
        { label: trans('common.group'), field: SortField.group },
        { label: trans('common.department'), field: SortField.department },
        { label: '' },
    ];

    const handleTableSort = (field: SortField = SortField.name, direction = SortDirection.desc): void => {
        setSortConfig({ field, direction });
    };

    return (
        <div className={`departments-table ${className}`}>
            <SortValkTable<Department>
                rows={sortedDepartments}
                sortCells={sortCells}
                sortConfig={sortConfig}
                onSort={handleTableSort}
                className="departments-table__valk-table"
                cellClassName="departments-table__cell"
                cellContentClassName="departments-table__cell-content"
                tableClassName="departments-table__table"
            >
                {(departmentRows: Department[]): JSX.Element[] => departmentRows.map(department => (
                    <TableRow key={department.id}>
                        <TableCell className="departments-table__cell">
                            {department.departmentGroup?.name}
                        </TableCell>
                        <TableCell className="departments-table__cell">
                            {department.name}
                        </TableCell>
                        <TableCell className="departments-table__buttons-cell">
                            {(canEditDepartment && onEditDepartmentClick) && (
                                <IconButton
                                    hideLabel
                                    onClick={() => onEditDepartmentClick(department)}
                                    icon="edit"
                                    text="edit"
                                    className="departments-table__button"
                                />
                            )}
                            {(canDeleteDepartment && onDeleteDepartmentClick) && (
                                <IconButton
                                    hideLabel
                                    onClick={() => onDeleteDepartmentClick(department)}
                                    icon="trash"
                                    text="delete"
                                    className="departments-table__button"
                                />
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </SortValkTable>
            {isLoading && <LoadingSpinner className="departments-table__loading-spinner" />}
        </div>
    );
};

export default DepartmentsTable;
