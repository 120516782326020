import {
    AddEmergencyContactRequest,
    EditEmergencyContactFormData,
    EditEmergencyContactRequest,
    EmergencyContact,
    EmergencyContactFormData,
    EmergencyContactResource,
    EmergencyContactV3Resource,
} from './EmergencyContact';

export const transformToEmergencyContact = (resource: EmergencyContactResource): EmergencyContact => ({
    id: resource.id,
    fullName: resource.attributes.fullName || undefined,
    relationType: resource.attributes.relationType || undefined,
    phone: resource.attributes.phone || undefined,
});

export const transformV3ResourceToEmergencyContact = (resource: EmergencyContactV3Resource): EmergencyContact => ({
    id: resource.id,
    fullName: resource.fullName || undefined,
    relationType: resource.relationType || undefined,
    phone: resource.phone || undefined,
});

export const transformAddEmergencyContactFormDataToAddApiParams = (emergencyContactFormData: EmergencyContactFormData, personId: string): AddEmergencyContactRequest => ({
    type: 'emergencyContacts',
    attributes: {
        fullName: emergencyContactFormData.fullName,
        relationType: emergencyContactFormData.relationType,
        phone: emergencyContactFormData.phone,
    },
    relationships: {
        person: {
            data: {
                type: 'people',
                id: personId,
            },
        },
    },
});

export const transformEditEmergencyContactFormDataToEditApiParams = (editEmergencyContactFormData: EditEmergencyContactFormData, personId: string): EditEmergencyContactRequest => ({
    type: 'emergencyContacts',
    id: editEmergencyContactFormData.id,
    attributes: {
        fullName: editEmergencyContactFormData.fullName,
        relationType: editEmergencyContactFormData.relationType,
        phone: editEmergencyContactFormData.phone,
    },
    relationships: {
        person: {
            data: {
                type: 'people',
                id: personId,
            },
        },
    },
});
