import axios from 'axios';

import {
    ApiResponse,
    LoketBalancesViewModel,
    LoketLeaveBalanceResource,
} from '../../models';
import transformLoketBalancesResource from '../../services/LoketBalances/transformLoketBalancesResource';
import { getLoketApiUrl } from '../index';

export async function getLoketLeaveBalance(
    employeeId: string,
): Promise<LoketBalancesViewModel | null> {
    try {
        const response = await axios
            .get(`${getLoketApiUrl()}/leave/balance/${employeeId}`) as unknown as ApiResponse<LoketLeaveBalanceResource[]>;

        return transformLoketBalancesResource(response.data, employeeId);
    } catch (error) {
        return null;
    }
}
