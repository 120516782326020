import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { BasicUserFilters } from '../../entities/User/User';
import { getBasicUsersApiCall } from '../../entities/User/UserService';
import { TypedDispatch } from '../store';
import { setBasicUsers, setError, setIsLoading } from './usersReducer';

export const getBasicUsers = (userFilters: BasicUserFilters) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getBasicUsersApiCall(userFilters);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setBasicUsers(response.data));
    } catch (error) {
        console.error('[getBasicUsers]', error);
    }

    dispatch(setIsLoading(false));
};
