import { Employment } from '../Employment/Employment';
import { Period } from '../Period/Period';
import { doesDateOverlapWithPeriod } from '../Period/PeriodHelpers';

export const getDivergentPreEmploymentHoursBasedOnPeriod = (
    employment: Employment,
    period: Period,
): number | undefined => {
    if (!employment.divergentPreEmploymentHour || !employment.start) {
        return undefined;
    }

    if (!doesDateOverlapWithPeriod(employment.start, period)) {
        return undefined;
    }

    return employment.divergentPreEmploymentHour.contractHours;
};
