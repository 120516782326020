/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconReset: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 14 14">
        <path d="M8.2,3.6c2.87,0,5.2,2.33,5.2,5.2s-2.33,5.2-5.2,5.2H5.4v-1.6h2.8c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6 H3.67l2.46,2.47L5,8.79l-4.4-4.4L5,0l1.14,1.13L3.67,3.6H8.2L8.2,3.6z M2.2,12.39h1.6v1.6H2.2V12.39z" />
    </svg>
);

export default IconReset;
/* eslint-enable max-len */
