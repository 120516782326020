import { formatDate } from '../../../helpers/date';
import { LegacyAddShiftFormData, Shift } from '../../../models';

export default function transformShiftCloneToShiftFormData(
    shift: Shift,
    userId: string,
): LegacyAddShiftFormData {
    return {
        department: shift.department.id,
        user: userId,
        endDate: formatDate(shift.end, 'yyyy-MM-dd'),
        endTime: formatDate(shift.end, 'HH:mm'),
        peopleLimit: shift.peopleLimit,
        startDate: formatDate(shift.start, 'yyyy-MM-dd'),
        startTime: formatDate(shift.start, 'HH:mm'),
    };
}
