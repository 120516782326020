import { addHours, addSeconds, startOfDayInHours } from '../../@paco/helpers/date';
import { formatDate } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import { PayrollPeriodViewModel } from '../../models';

const getPayrollPeriodEndDateLabel = (payrollPeriod: PayrollPeriodViewModel, dateFormat = 'EEEEEE d MMM yyyy'): string => (
    `${translate('common.until')} ${formatDate(addSeconds(addHours(payrollPeriod.end.date, -startOfDayInHours), -1), dateFormat)}`
);

export default getPayrollPeriodEndDateLabel;
