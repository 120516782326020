import React from 'react';

import PropTypes from 'prop-types';

const DayHeader = ({ day }) => (
    <div className="day-header">
        <div className="m-auto">{day}</div>
    </div>
);

DayHeader.propTypes = {
    day: PropTypes.string.isRequired,
};

export default DayHeader;
