import { getAbsencesApiCall } from '../../entities/Absence/AbsenceService';
import { postCommentApiCall } from '../../entities/Comment/CommentService';
import { DistrictDivision } from '../../entities/DistrictDivision/DistrictDivision';
import { deleteDistrictDivisionApiCall, getDistrictDivisionsApiCall } from '../../entities/DistrictDivision/DistrictDivisionService';
import { publishDistrictDivisionTemplateApiCall } from '../../entities/DistrictDivisionTemplate/DistrictDivisionTemplateService';
import { isFetchCollectionResultSuccessful, isFetchResultSuccessful } from '../../entities/FetchResult';
import { Period } from '../../entities/Period/Period';
import { getShiftsIndexApiCall } from '../../entities/Shift/ShiftService';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setAbsences,
    setDistrictDivision,
    setDistrictDivisions,
    setError,
    setIsAbsencesLoading,
    setIsAddDistrictDivisionCommentLoading,
    setIsAddDistrictDivisionCommentSuccessful,
    setIsAddDistrictDivisionLoading,
    setIsAddDistrictDivisionSuccessful,
    setIsDeleteDistrictDivisionSuccessful,
    setIsLoading,
    setIsShiftsLoading,
    setShifts,
} from './districtDivisionReducer';

export const fetchDistrictDivisions = (date: Date) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getDistrictDivisionsApiCall(date);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDistrictDivisions(response.data));
    } catch (error) {
        console.error('[fetchDistrictDivision]', error);
    }

    dispatch(setIsLoading(false));
};

export const addDistrictDivision = (districtDivisionTemplateId: string, date: Date) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsAddDistrictDivisionLoading(true));
    dispatch(setIsAddDistrictDivisionSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await publishDistrictDivisionTemplateApiCall(districtDivisionTemplateId, date);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setIsAddDistrictDivisionSuccessful(true));
    } catch (error) {
        console.error('[addDistrictDivision]', error);
    } finally {
        dispatch(setIsAddDistrictDivisionLoading(false));
    }
};

export const addDistrictDivisionComment = (
    districtDivision: DistrictDivision,
    comment: string,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsAddDistrictDivisionCommentSuccessful(false));
    dispatch(setIsAddDistrictDivisionCommentLoading(true));
    dispatch(setError(''));

    try {
        const response = await postCommentApiCall({
            comment,
            relationshipId: districtDivision.id,
            relationshipKey: 'districtDivision',
            relationshipType: 'districtDivisions',
        }, true);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const updatedDistrictDivision: DistrictDivision = {
            ...districtDivision,
            comments: [
                response.data,
                ...districtDivision.comments,
            ],
        };

        dispatch(setDistrictDivision(updatedDistrictDivision));
        dispatch(setIsAddDistrictDivisionCommentSuccessful(true));
    } catch (error) {
        console.error('[addDistrictDivisionComment]', error);
    }

    dispatch(setIsAddDistrictDivisionCommentLoading(false));
};

export const deleteDistrictDivision = (districtDivisionId: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsDeleteDistrictDivisionSuccessful(false));
    dispatch(setError(''));

    try {
        const { districtDivisionReducer } = getState();
        const { districtDivisions } = districtDivisionReducer;
        const response = await deleteDistrictDivisionApiCall(districtDivisionId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const updatedDistrictDivisions = districtDivisions
            .filter(districtDivision => districtDivision.id !== districtDivisionId);

        dispatch(setIsDeleteDistrictDivisionSuccessful(true));
        dispatch(setDistrictDivisions(updatedDistrictDivisions));
        dispatch(setDistrictDivision(undefined));
    } catch (error) {
        console.error('[deleteDistrictDivision]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchShifts = (
    startDate: Date,
    endDate: Date,
    departments: string[],
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsShiftsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getShiftsIndexApiCall(
            startDate,
            endDate,
            departments,
        );

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShifts(response.data));
    } catch (error) {
        console.error('[fetchShifts]', error);
    }

    dispatch(setIsShiftsLoading(false));
};

export const fetchDistrictDivisionAbsences = (period: Period, departments: string[] = []) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsAbsencesLoading(true));
    dispatch(setError(''));

    try {
        const response = await getAbsencesApiCall({ departments, period });

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setAbsences(response.data));
    } catch (error) {
        console.error('[fetchDistrictDivisionAbsences]', error);
    } finally {
        dispatch(setIsAbsencesLoading(false));
    }
};
