import React, { FC } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { ABSENCES_CALENDAR_VIEW_PROPS } from '../../../../constants';
import { translate } from '../../../../helpers/translations/translator';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';


interface FilterProps {
    filter: AppFilter;
    onChange: (filter: AppFilter) => void;
}

const CalendarViewPropsFilter: FC<FilterProps> = ({ filter, onChange }) => {
    const onCheckboxChange = (viewProp: string, checked: boolean) => {
        const calendarViewProps = !checked ? (
            filter.absences.calendarViewProps.filter((type) => type !== viewProp)
        ) : [
            ...filter.absences.calendarViewProps,
            viewProp,
        ];

        onChange({
            ...filter,
            absences: {
                ...filter.absences,
                calendarViewProps,
            },
        });
    };

    const getChecked = (newId: string): boolean => {
        const arrayLength = filter.absences.calendarViewProps
            .filter(id => id === newId).length;
        return arrayLength !== 0;
    };

    const getTranslationForRole = (viewProp: string) => {
        switch (viewProp) {
            case ABSENCES_CALENDAR_VIEW_PROPS[0]:
                return translate('pages.absences.showReason');
            default:
                return translate('pages.absences.showDate');
        }
    };

    return (
        <FilterBlock title={translate('pages.absences.leaveOfAbsence')}>
            {ABSENCES_CALENDAR_VIEW_PROPS.map(viewProp => (
                <FormGroup check className="p-0" key={viewProp}>
                    <Label check>
                        <CustomInput
                            key={`calendar-view-props-checkbox-${viewProp}`}
                            id={`calendar-view-props-checkbox-${viewProp}`}
                            type="checkbox"
                            label={getTranslationForRole(viewProp)}
                            checked={getChecked(viewProp)}
                            onChange={() => onCheckboxChange(viewProp, !getChecked(viewProp))}
                        />
                    </Label>
                </FormGroup>
            ))}
        </FilterBlock>
    );
};

export default CalendarViewPropsFilter;
