import { Shift, ShiftType } from './Shift';
import { BasicUserWithAbsenceData } from './UserWithAbsenceData';

export interface ShiftWithAbsenceData extends Shift {
    plannedUsers: BasicUserWithAbsenceData[];
    registeredUsers: BasicUserWithAbsenceData[];
    hasLeaveOfAbsence: boolean;
    hasLeaveOfAbsenceRequest: boolean;
    hasAbsence: boolean;
}

export function transformShiftToShiftWithAbsenceData(
    shift: Shift,
    plannedUsers: BasicUserWithAbsenceData[],
    registeredUsers: BasicUserWithAbsenceData[],
    hasLeaveOfAbsence: boolean,
    hasLeaveOfAbsenceRequest: boolean,
    hasAbsence: boolean,
): ShiftWithAbsenceData {
    return {
        type: ShiftType.shift,
        id: shift.id,
        peopleLimit: shift.peopleLimit,
        start: shift.start,
        end: shift.end,
        createdAt: shift.createdAt,
        updatedAt: shift.updatedAt,
        department: shift.department,
        shiftPlannings: shift.shiftPlannings,
        temporaryWorkers: shift.temporaryWorkers,
        previouslyPlannedUsers: shift.previouslyPlannedUsers,
        logs: shift.logs,
        plannedUsers,
        registeredUsers,
        hasLeaveOfAbsence,
        hasLeaveOfAbsenceRequest,
        hasAbsence,
    };
}
