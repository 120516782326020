import { FC, ReactElement, useEffect } from 'react';

import EditTemporaryWorkerForm from '../../../containers/@forms/EditTemporaryWorkerForm/EditTemporaryWorkerForm';
import { EditTemporaryWorkerFormData, TemporaryWorker } from '../../../entities/TemporaryWorker/TemporaryWorker';
import trans from '../../../helpers/trans';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { editTemporaryWorker } from '../../../redux/temporaryWorker/temporaryWorkerActions';
import { setIsSuccessful } from '../../../redux/temporaryWorker/temporaryWorkerReducer';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedEditTemporaryWorkerFormProps {
    temporaryWorker: TemporaryWorker;
    onClose: () => void;
}

const ConnectedEditTemporaryWorkerForm: FC<ConnectedEditTemporaryWorkerFormProps> = ({ temporaryWorker, onClose }): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isLoading, isSuccessful } = useTypedSelector(state => state.temporaryWorkerReducer);

    const handleEditTemporaryWorker = async (formData: EditTemporaryWorkerFormData): Promise<void> => {
        dispatch(editTemporaryWorker(formData, temporaryWorker.id));
    };

    useEffect(() => {
        if (isSuccessful) {
            dispatch(setIsSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.editTemporaryWorkerForm.editTemporaryWorkerSuccess'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isSuccessful]);

    return (
        <EditTemporaryWorkerForm
            isLoading={isLoading}
            temporaryWorker={temporaryWorker}
            onSubmit={handleEditTemporaryWorker}
            onCancel={onClose}
        />
    );
};

export default ConnectedEditTemporaryWorkerForm;
