export const stringContainsValue = (value?: string): boolean => {
    if (!value) {
        return false;
    }

    return value.trim().length > 0;
};

// Validate time string like 12:30
export function validateTime(time: string): boolean {
    if (!time || !time.length) {
        return false;
    }

    return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}
