import { isValid } from '../../../../helpers/date';
import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import { findPayrollPeriodIndexFromDate, getPayrollPeriodFromDate } from '../../../entities/PayrollPeriod/PayrollPeriodHelpers';
import {
    addDays,
    addHours,
    addSeconds,
    getDaysOfMonth,
    getDaysOfPeriod,
    getDaysOfWeek,
    getDaysOfYears,
    getPeriodWithoutStartOfDayCorrection,
    parseIso,
    startOfDayInHours,
} from '../../../helpers/date';
import { isJsonStringValid } from '../../../helpers/json';
import { TimeModeType } from '../../../types';

const getIncrementedPayrollPeriod = (selectedDays: Date[], payrollPeriods: PayrollPeriod[], goForward: boolean): Date[] => {
    const currentIndex = findPayrollPeriodIndexFromDate(addHours(selectedDays[0], startOfDayInHours), payrollPeriods);
    const newIndex = goForward
        ? Math.min(currentIndex + 1, payrollPeriods.length - 1)
        : Math.max(currentIndex - 1, 0);

    const period = getPeriodWithoutStartOfDayCorrection(payrollPeriods[newIndex].period);

    return getDaysOfPeriod({ ...period, end: addSeconds(period.end, -1) });
};

const getDaysFromPayrollPeriod = (date: Date, payrollPeriods: PayrollPeriod[]): Date[] => {
    const payrollPeriod = getPayrollPeriodFromDate(date, payrollPeriods);
    if (!payrollPeriod) {
        return getDaysOfWeek(date);
    }

    const period = getPeriodWithoutStartOfDayCorrection(payrollPeriod.period);

    return getDaysOfPeriod({ ...period, end: addSeconds(period.end, -1) });
};

export const getDaysFromTimeMode = (timeMode: TimeModeType, date: Date, payrollPeriods: PayrollPeriod[]): Date[] => {
    switch (timeMode) {
        case TimeModeType.month:
            return getDaysOfMonth(date);
        case TimeModeType.year:
            return getDaysOfYears(date);
        case TimeModeType.period:
            return getDaysFromPayrollPeriod(date, payrollPeriods);
        default:
            return getDaysOfWeek(date);
    }
};

export const getIncrementedDays = (
    timeMode: TimeModeType,
    selectedDays: Date[],
    goForward: boolean,
    payrollPeriods: PayrollPeriod[],
): Date[] => {
    if (timeMode === TimeModeType.period) {
        return getIncrementedPayrollPeriod(selectedDays, payrollPeriods, goForward);
    }

    const date = goForward
        ? addDays(selectedDays[selectedDays.length - 1], 1)
        : addDays(selectedDays[0], -1);

    return getDaysFromTimeMode(timeMode, date, payrollPeriods);
};

export const transformDomStringToDates = (domString: string): Date[] | undefined => {
    if (!domString || !isJsonStringValid(domString)) {
        return undefined;
    }

    const parsedJson = JSON.parse(domString);

    if (!parsedJson || !Array.isArray(parsedJson)) {
        return undefined;
    }

    const dates = parsedJson.map((date: string) => parseIso(date));

    return dates.some((date) => !isValid(date)) ? undefined : dates;
};

export const transformDomStringToTimeMode = (domString: string): TimeModeType | undefined => {
    if (!domString || !isJsonStringValid(domString)) {
        return undefined;
    }

    const timeMode = JSON.parse(domString);

    if (timeMode === TimeModeType.week) {
        return TimeModeType.week;
    }

    if (timeMode === TimeModeType.month) {
        return TimeModeType.month;
    }

    if (timeMode === TimeModeType.custom) {
        return TimeModeType.custom;
    }

    if (timeMode === TimeModeType.period) {
        return TimeModeType.period;
    }

    return undefined;
};
