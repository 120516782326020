import { PayrollPeriodViewModel } from '../../../models';
import { transformToPayrollPeriodWeek } from '../PayrollPeriodWeek/PayrollPeriodWeekTransformers';
import { transformToPeriod } from '../Period/PeriodTransformers';
import {
    LockPayrollPeriodWeekRequest,
    PayrollPeriod,
    PayrollPeriodResource,
} from './PayrollPeriod';

export const transformToPayrollPeriod = (resource: PayrollPeriodResource): PayrollPeriod => {
    const weeks = resource.payrollPeriodWeeks.map(transformToPayrollPeriodWeek);
    const isLocked = weeks.every(week => week.isLocked);

    return {
        id: resource.id,
        isLocked,
        year: resource.year,
        periodNumber: resource.periodNumber,
        period: transformToPeriod(new Date(resource.start), new Date(resource.end)),
        weeks,
    };
};

export const transformLegacyPayrollPeriodToPayrollPeriod = (legacy: PayrollPeriodViewModel): PayrollPeriod => ({
    id: legacy.id,
    year: legacy.year,
    periodNumber: legacy.periodNumber,
    isLocked: legacy.locked,
    period: transformToPeriod(legacy.start, legacy.end.date),
    weeks: [],
});

export const transformToLockPayrollPeriodWeekRequest = (id: string, isLocked: boolean): LockPayrollPeriodWeekRequest => ({
    type: 'payrollPeriodWeeks',
    id,
    attributes: {
        isLocked,
    },
});
