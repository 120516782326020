import { getRandomUuid } from '../../helpers/crypto';
import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { ShiftPlanningSnapshot, ShiftPlanningSnapshotResource } from './ShiftPlanningSnapshot';

export const transformToShiftPlanningSnapshot = (shiftPlanningSnapshotResource: ShiftPlanningSnapshotResource): ShiftPlanningSnapshot => {
    const start = shiftPlanningSnapshotResource.start ? new Date(shiftPlanningSnapshotResource.start) : undefined;
    const end = shiftPlanningSnapshotResource.end ? new Date(shiftPlanningSnapshotResource.end) : undefined;

    return {
        id: getRandomUuid(),
        planned: !!shiftPlanningSnapshotResource.planned,
        period: start && end ? transformToPeriod(start, end) : undefined,
        shiftPeriod: transformToPeriod(new Date(shiftPlanningSnapshotResource.shiftStart), new Date(shiftPlanningSnapshotResource.shiftEnd)),
        userId: shiftPlanningSnapshotResource.user,
    };
};

export const transformToShiftPlanningSnapshotResource = (shiftPlanningSnapshot: ShiftPlanningSnapshot): ShiftPlanningSnapshotResource => {
    const { period, shiftPeriod } = shiftPlanningSnapshot;

    return {
        planned: shiftPlanningSnapshot.planned ? 1 : 0,
        start: formatDate(period ? period.start : shiftPeriod.start, DateFormat.jsonApiResource),
        end: formatDate(period ? period.end : shiftPeriod.end, DateFormat.jsonApiResource),
        shiftStart: formatDate(shiftPeriod.start, DateFormat.jsonApiResource),
        shiftEnd: formatDate(shiftPeriod.end, DateFormat.jsonApiResource),
        user: shiftPlanningSnapshot.userId,
    };
};
