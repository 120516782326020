import { Permission } from '../../../../entities/Permission/Permission';
import { DashboardSettingType, DashboardSmallWidget, dashboardSmallWidgets } from '../../../../types/dashboardTypes';

const filterWidgetWithMissingPermissions = (widget: DashboardSmallWidget, permissions: Permission[]): boolean => {
    const permissionSlugs = permissions.map(permission => permission.slug);
    const hasMissingPermission = widget.permissions.some(permission => !permissionSlugs.includes(permission));

    return !hasMissingPermission;
};

export const getSelectedWidgets = (types: DashboardSettingType[], permissions: Permission[]): DashboardSmallWidget[] => types
    .map(type => dashboardSmallWidgets[type])
    .filter(widget => widget.type !== DashboardSettingType.unknown)
    .filter((widget) => filterWidgetWithMissingPermissions(widget, permissions));

export const getRemainingUserWidgets = (widgets: DashboardSmallWidget[], permissions: Permission[]): DashboardSmallWidget[] => {
    const allWidgets = Object.values(dashboardSmallWidgets);

    return allWidgets
        .filter(widget => widget.type !== DashboardSettingType.unknown)
        .filter(widget => !widgets.some(otherWidget => otherWidget.type === widget.type))
        .filter((widget) => filterWidgetWithMissingPermissions(widget, permissions));
};
