import { Period } from '../../../entities/Period/Period';
import { arePeriodsDeviated } from '../../../entities/Period/PeriodHelpers';
import { TemporaryWorker } from '../../../entities/TemporaryWorker/TemporaryWorker';
import { formatDate } from '../../../helpers/date';
import { DateFormat } from '../../../types/dateFormatTypes';

export const getTemporaryWorkerDeviatedTime = (temporaryWorker: TemporaryWorker, shiftPeriod: Period): string | undefined => {
    if (!arePeriodsDeviated(temporaryWorker.period, shiftPeriod)) {
        return undefined;
    }

    return `${formatDate(temporaryWorker.period.start, DateFormat.hoursMinutes)}-${formatDate(temporaryWorker.period.end, DateFormat.hoursMinutes)}`;
};
