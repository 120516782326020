import React, { FC, useMemo, useState } from 'react';

import { LoadingSpinner } from '../../../../@paco/components';
import { ConnectedHelpTooltip } from '../../../../@paco/connectors';
import { Permission } from '../../../../@paco/entities/Permission/Permission';
import trans from '../../../../@paco/helpers/trans';
import ExchangeRequestCard from '../../../../components/ExchangeRequestCard/ExchangeRequestCard';
import ExchangeShiftCard from '../../../../components/ExchangeShiftCard/ExchangeShiftCard';
import NewFilters from '../../../../components/Filters/Filters';
import LogsModal from '../../../../components/Logs/LogsModal';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import SidebarPage from '../../../../components/SidebarPage/SidebarPage';
import { checkPermission } from '../../../../helpers';
import { translate } from '../../../../helpers/translations/translator';
import { ExchangeViewModel, ShiftExchangeViewModel } from '../../../../models';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';

import './ExchangeRequestsContainer.scss';

interface ExchangeRequestsContainerProps {
    exchanges: (ExchangeViewModel | ShiftExchangeViewModel)[];
    permissions: Permission[];
    filter: AppFilter,
    loading: boolean;
    onApproveExchange: (exchangeId: string, userId: string) => void;
    onDeclineExchange: (exchangeId: string) => void;
    onApproveShiftExchange: (exchangeId: string) => void;
    onDeclineShiftExchange: (exchangeId: string) => void;
    onSearchBarChange: (search: string) => void;
    onExchangeNavButtonClick: (startDate: Date) => void;
    onFilterChange: (filter: AppFilter) => void;
}

const ExchangeRequestsContainer: FC<ExchangeRequestsContainerProps> = ({
    exchanges,
    permissions,
    filter,
    loading,
    onApproveExchange,
    onDeclineExchange,
    onApproveShiftExchange,
    onDeclineShiftExchange,
    onSearchBarChange,
    onExchangeNavButtonClick,
    onFilterChange,
}) => {
    const [showLogsModal, setShowLogsModal] = useState(false);
    const [activeExchange, setActiveExchange] = useState<ExchangeViewModel | ShiftExchangeViewModel | null>(null);

    const canEditExchanges = useMemo(() => checkPermission(permissions, 'edit-all-exchanges'), [permissions]);
    const canViewLogs = useMemo(() => checkPermission(permissions, 'view-all-logs'), [permissions]);

    const onShowLogsClick = (exchange: ExchangeViewModel | ShiftExchangeViewModel) => {
        setShowLogsModal(true);
        setActiveExchange(exchange);
    };

    const toggleLogModal = () => {
        setShowLogsModal(!showLogsModal);
    };

    return (
        <SidebarPage
            sidebarContent={(
                <NewFilters
                    filter={filter}
                    filterBlocks={[
                        'exchanges',
                    ]}
                    onChange={onFilterChange}
                />
            )}
        >
            <div className="exchange-requests">
                <div className="absences-search">
                    <SearchBar placeholder={translate('common.searchEmployee')} startVal={filter.employeeSearch} onChange={onSearchBarChange} />
                </div>
                <div className="exchange-requests__cards">
                    {exchanges.map(exchange => {
                        if (exchange.type === 'shift-exchanges') {
                            return (
                                <ExchangeShiftCard
                                    key={exchange.id}
                                    className="exchange-requests__card"
                                    onApproveClick={canEditExchanges ? onApproveShiftExchange : undefined}
                                    onDeclineClick={canEditExchanges ? onDeclineShiftExchange : undefined}
                                    shiftExchange={exchange}
                                    onShowLogsClick={canViewLogs ? () => onShowLogsClick(exchange) : undefined}
                                />
                            );
                        }

                        return (
                            <ExchangeRequestCard
                                className="exchange-requests__card"
                                key={exchange.id}
                                request={exchange}
                                onApproveClick={canEditExchanges ? onApproveExchange : undefined}
                                onDeclineClick={canEditExchanges ? onDeclineExchange : undefined}
                                onCalendarClick={() => onExchangeNavButtonClick(exchange.shift?.start || new Date())}
                                onShowLogsClick={canViewLogs ? () => onShowLogsClick(exchange) : undefined}
                            />
                        );
                    })}
                </div>
                <LogsModal
                    isOpen={!!activeExchange && showLogsModal}
                    title={translate('logs.exchangeRequestLog')}
                    logs={(activeExchange?.logs) ? activeExchange.logs : []}
                    onClose={toggleLogModal}
                />
            </div>
            {loading && <LoadingSpinner />}
            <ConnectedHelpTooltip
                index={0}
                route="exchange-requests"
                subTitle={trans('help.absences.exchangeItem.title')}
                text={trans('help.absences.exchangeItem.text')}
                title={trans('help.absences.title')}
                className="exchange-requests__exchange-item-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={1}
                route="exchange-requests"
                subTitle={trans('help.absences.leaveOfAbsencesFilters.title')}
                text={trans('help.absences.leaveOfAbsencesFilters.text')}
                title={trans('help.absences.title')}
                showMobileInfoWarning
                className="exchange-requests__filters-help-tooltip"
            />
        </SidebarPage>
    );
};

export default ExchangeRequestsContainer;
