import { CopyTemporaryWorkerData, Shift, TemporaryWorker } from '../../../models';
import transformShiftCloneToCopyTemporaryWorkerData from './transformShiftCloneToCopyTemporaryWorkerData';

export default function transformShiftCloneToCopyTemporaryWorkerDataArray(
    shift: Shift,
    shiftId: string,
): CopyTemporaryWorkerData[] {
    return (shift.temporaryWorkers || [])
        .map((temporaryWorker: TemporaryWorker) => transformShiftCloneToCopyTemporaryWorkerData(
            temporaryWorker,
            shiftId,
        ));
}
