import {
    FC,
    FormEvent,
    useRef,
    useState,
} from 'react';

import { SelectOption } from '../../../components/@inputs/Select/Select';
import {
    DateInput,
    DepartmentInput,
    FormActionButtons,
    NumberInput,
    TextInput,
} from '../../../compositions';
import { Department } from '../../../entities/Department/Department';
import { AddShiftFormData } from '../../../entities/Shift/Shift';
import { formatDate } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { DateFormat } from '../../../types/dateFormatTypes';
import { ModalFormProps } from '../../../types/modalFormTypes';
import { generateAddShiftFormDataEmptyValues } from './helpers/generateAddShiftFormDataEmptyValues';
import { AddShiftFormErrors, maxPeopleLimit, validateAddShiftFormData } from './validations/addShiftFormValidation';

import './AddShiftForm.scss';

interface AddShiftFormProps extends ModalFormProps<AddShiftFormData> {
    defaultDate?: Date;
    departments: Department[];
    onSubmit: (shiftFormData: AddShiftFormData) => void;
}

const AddShiftForm: FC<AddShiftFormProps> = ({
    isLoading,
    defaultDate = new Date(),
    departments,
    onSubmit,
    onCancel,
}) => {
    const [formData, setFormData] = useState<AddShiftFormData>(generateAddShiftFormDataEmptyValues(defaultDate));
    const [formErrors, setFormErrors] = useState<AddShiftFormErrors>({});
    const [date, setDate] = useState<Date>(new Date(formData.date));
    const [selectedDepartment, setSelectedDepartment] = useState<SelectOption | undefined>(undefined);
    const [startTime, setStartTime] = useState<string>(formData.startTime);
    const [endTime, setEndTime] = useState<string>(formData.endTime);
    const [peopleLimit, setPeopleLimit] = useState<number>(formData.peopleLimit);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formDataToSubmit = {
            departmentId: selectedDepartment ? selectedDepartment.value : '',
            date: formatDate(date, DateFormat.inputDate),
            startTime,
            endTime,
            peopleLimit,
        };
        setFormData(formDataToSubmit);

        const [errors, hasErrors] = validateAddShiftFormData(formDataToSubmit);
        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formDataToSubmit);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="add-shift-form">
            <div className="add-shift-form__row">
                <div className="add-shift-form__col">
                    <DepartmentInput
                        departments={departments}
                        selectedDepartment={selectedDepartment}
                        error={formErrors.departmentId}
                        onDepartmentChange={setSelectedDepartment}
                    />
                </div>
                <div className="add-shift-form__col">
                    <DateInput
                        label={trans('common.date')}
                        type="date"
                        value={date}
                        error={formErrors.date}
                        onChange={setDate}
                    />
                </div>
            </div>
            <div className="add-shift-form__row">
                <div className="add-shift-form__col">
                    <TextInput
                        label={trans('common.startTime')}
                        type="time"
                        value={startTime}
                        error={formErrors.startTime}
                        onChange={setStartTime}
                    />
                </div>
                <div className="add-shift-form__col">
                    <TextInput
                        label={trans('common.endTime')}
                        type="time"
                        value={endTime}
                        error={formErrors.endTime}
                        onChange={setEndTime}
                    />
                </div>
            </div>
            <div className="add-shift-form__row">
                <div className="add-shift-form__col">
                    <NumberInput
                        label={trans('containers.forms.addShiftForm.persons')}
                        value={peopleLimit}
                        min={1}
                        max={maxPeopleLimit}
                        error={formErrors.peopleLimit}
                        onChange={setPeopleLimit}
                    />
                </div>
                <div className="add-shift-form__col" />
            </div>
            <FormActionButtons
                isLoading={isLoading}
                disabled={!selectedDepartment}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default AddShiftForm;
