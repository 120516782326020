import { CopyShiftPlanningData, Shift, ShiftPlanning } from '../../../models';
import transformShiftToCopyShiftPlanningData from './transformShiftToCopyShiftPlanningData';

export default function transformShiftToCopyShiftPlanningDataArray(
    shift: Shift,
    shiftId: string,
): CopyShiftPlanningData[] {
    return (shift.shiftPlannings || [])
        .filter((shiftPlanning: ShiftPlanning) => shiftPlanning.planned)
        .map((shiftPlanning: ShiftPlanning) => transformShiftToCopyShiftPlanningData(
            shiftPlanning,
            shiftId,
        ));
}
