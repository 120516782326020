import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { DeleteShiftPlanningForm } from '../../../containers';
import { useSettingEnabled } from '../../../entities/Setting/SettingHooks';
import { ShiftIndex } from '../../../entities/Shift/Shift';
import { ShiftPlanning } from '../../../entities/ShiftPlanning/ShiftPlanning';
import { differenceInHours } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { deleteShiftPlanning } from '../../../redux/shiftPlanning/shiftPlanningActions';
import { setIsDeleteSuccessful } from '../../../redux/shiftPlanning/shiftPlanningReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedDeleteShiftPlanningFormProps {
    shift: ShiftIndex;
    shiftPlanning: ShiftPlanning;
    onClose: () => void;
    className?: string;
}

const ConnectedDeleteShiftPlanningForm: FC<ConnectedDeleteShiftPlanningFormProps> = ({
    shift,
    shiftPlanning,
    onClose,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { fullName } = shiftPlanning.user;

    const { isLoading, isDeleteSuccessful } = useTypedSelector(state => state.shiftPlanningReducer);

    const isRemoveEmployeeFromShiftEnabled = useSettingEnabled('ENABLE_CAO_MESSAGE_REMOVE_EMPLOYEE_FROM_SHIFT', shift.department.id);
    const isWithin24h = useMemo(() => differenceInHours(shiftPlanning.period.start, new Date()) < 24, [shiftPlanning]);

    const handleSubmit = (): void => {
        dispatch(deleteShiftPlanning(shiftPlanning.id));
    };

    useEffect((): void => {
        if (isDeleteSuccessful) {
            dispatch(setToast({
                text: trans('containers.forms.deleteShiftPlanningForm.deleteShiftPlanningSuccess', { fullName }),
                type: ToastType.pass,
            }));
            dispatch(setIsDeleteSuccessful(false));

            onClose();
        }
    }, [isDeleteSuccessful]);

    return (
        <DeleteShiftPlanningForm
            showDeleteWithin24hWarning={isRemoveEmployeeFromShiftEnabled && isWithin24h}
            isLoading={isLoading}
            fullName={fullName}
            onCancel={onClose}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedDeleteShiftPlanningForm;
