import { AddShiftFormData } from '../../../../entities/Shift/Shift';
import { addHours, formatDate } from '../../../../helpers/date';

export const generateAddShiftFormDataEmptyValues = (date: Date): AddShiftFormData => ({
    departmentId: '',
    date: formatDate(date, 'y-MM-dd'),
    startTime: formatDate(date, 'HH:00'),
    endTime: formatDate(addHours(date, 6), 'HH:00'),
    peopleLimit: 1,
});
