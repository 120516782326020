import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserViewModel } from '../../../models';
import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';

export type BaseScheduleShiftPlanningState = AsyncReduxState<{
    isLoading: boolean,
    employeeOptions: UserViewModel[];
}>;

const initialState: BaseScheduleShiftPlanningState = {
    ...initialAsyncReduxState,
    isLoading: false,
    employeeOptions: [],
};

export const BaseScheduleShiftPlanningSlice = createSlice({
    name: 'baseScheduleShiftPlanningReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): BaseScheduleShiftPlanningState {
            return ({
                ...state,
                isLoading: action.payload,
            });
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): BaseScheduleShiftPlanningState {
            return ({
                ...state,
                isSuccessful: action.payload,
            });
        },
        setError(state, action: PayloadAction<string>): BaseScheduleShiftPlanningState {
            return ({
                ...state,
                error: action.payload,
            });
        },
        setEmployeeOptions(state, action: PayloadAction<UserViewModel[]>): BaseScheduleShiftPlanningState {
            return ({
                ...state,
                employeeOptions: action.payload,
            });
        },
    },
});

export const {
    setEmployeeOptions,
    setError,
    setIsLoading,
    setIsSuccessful,
} = BaseScheduleShiftPlanningSlice.actions;

export default BaseScheduleShiftPlanningSlice.reducer;
