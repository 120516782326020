import { EditShiftPlanningTimeFormData } from '../../../models';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { deleteShiftConceptPlanningApiCall, editShiftConceptPlanningTimeApiCall } from '../../entities/ShiftConceptPlanning/ShiftConceptPlanningService';
import { TypedDispatch } from '../store';
import {
    setDeletedShiftConceptPlanningId,
    setError,
    setIsDeleteSuccessful,
    setIsEditSuccessful,
    setIsLoading,
    setShiftConceptPlanning,
} from './shiftConceptPlanningReducer';

export const editShiftConceptPlanningTime = (editShiftPlanningFormData: EditShiftPlanningTimeFormData, shiftConceptPlanningId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsEditSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await editShiftConceptPlanningTimeApiCall(editShiftPlanningFormData, shiftConceptPlanningId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftConceptPlanning(response.data));
    } catch (error) {
        console.error('[editShiftConceptPlanningTime]', error);
    }

    dispatch(setIsLoading(false));
    dispatch(setIsEditSuccessful(true));
};

export const deleteShiftConceptPlanning = (shiftConceptPlanningId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsDeleteSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await deleteShiftConceptPlanningApiCall(shiftConceptPlanningId);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setDeletedShiftConceptPlanningId(shiftConceptPlanningId));
    } catch (error) {
        console.error('[deleteShiftConceptPlanning]', error);
    }

    dispatch(setIsLoading(false));
    dispatch(setIsDeleteSuccessful(true));
};
