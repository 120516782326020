import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../../redux/@config/AsyncReduxState';
import { DivergentPreEmploymentHour } from '../../entities/DivergentPreEmploymentHour/DivergentPreEmploymentHour';

export type DivergentPreEmploymentHourState = AsyncReduxState<{
    divergentPreEmploymentHour?: DivergentPreEmploymentHour;
}>;

const initialState: DivergentPreEmploymentHourState = {
    ...initialAsyncReduxState,
};

export const DivergentPreEmploymentHourSlice = createSlice({
    name: 'divergentPreEmploymentHourReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DivergentPreEmploymentHourState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setDivergentPreEmploymentHour(state, action: PayloadAction<DivergentPreEmploymentHour>): DivergentPreEmploymentHourState {
            return {
                ...state,
                divergentPreEmploymentHour: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DivergentPreEmploymentHourState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setDivergentPreEmploymentHour,
    setIsLoading,
    setError,
} = DivergentPreEmploymentHourSlice.actions;

export default DivergentPreEmploymentHourSlice.reducer;
