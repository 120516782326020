import { FC, ReactElement } from 'react';

import { TableCell, TableRow } from '../../../../components';
import { EventLogChange } from '../../../../entities/EventLog/EventLog';
import trans from '../../../../helpers/trans';
import { ValkTable } from '../../../index';

interface PzLogChangesTableProps {
    changes: EventLogChange[];
    className?: string;
    rowClassName?: string;
    rowContentClassName?: string;
}

const PzLogChangesTable: FC<PzLogChangesTableProps> = ({
    changes,
    className = '',
    rowClassName = '',
    rowContentClassName = '',
}): ReactElement => (
    <ValkTable<EventLogChange>
        hasRowClick
        rows={changes}
        tableHeaders={[trans('compositions.pzLogDetail.label'), trans('compositions.pzLogDetail.from'), trans('compositions.pzLogDetail.to')]}
        tableClassName="pz-log-changes-table__table"
        headerRowClassName={rowClassName}
        cellClassName="pz-log-changes-table__table-cell"
        cellContentClassName={rowContentClassName}
        className={className}
    >
        {(pzLogChanges): JSX.Element[] => pzLogChanges.map(change => (
            <TableRow
                key={change.label}
                className={`pz-log-changes-table__row ${rowClassName}`}
            >
                <TableCell contentClassName={rowContentClassName}>
                    {change.label}
                </TableCell>
                <TableCell contentClassName={rowContentClassName}>
                    {change.from}
                </TableCell>
                <TableCell contentClassName={rowContentClassName}>
                    {change.to}
                </TableCell>
            </TableRow>
        ))}
    </ValkTable>
);

export default PzLogChangesTable;
