import React, { FC } from 'react';

import { CustomInput, FormGroup, Label } from 'reactstrap';

import { translate } from '../../../../helpers/translations/translator';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import FilterBlock from '../FilterBlock/FilterBlock';

interface AvailabilityFilterProps {
    filter: AppFilter;
    canViewAbsences: boolean;
    canViewAllPreferToWorkTimeSlots: boolean;
    canViewLeaveOfAbsences: boolean;
    canViewUnavailableToWorkTimeSlots: boolean;
    onChange: (filter: AppFilter) => void;
}

const AvailabilityFilter: FC<AvailabilityFilterProps> = ({
    filter,
    canViewAbsences,
    canViewAllPreferToWorkTimeSlots,
    canViewLeaveOfAbsences,
    canViewUnavailableToWorkTimeSlots,
    onChange,
}) => {
    const setFilter = (propName: string, value: boolean) => {
        const availability = {
            ...filter.availability,
            [propName]: value,
        };

        onChange({
            ...filter,
            availability,
        });
    };

    return (
        <FilterBlock title={translate('pages.management.absencesAndLeaveOfAbsences')}>
            {canViewAllPreferToWorkTimeSlots && (
                <FormGroup check className="p-0">
                    <Label check>
                        <CustomInput
                            id="availability-checkbox-1"
                            type="checkbox"
                            label={translate('pages.shifts.availables')}
                            checked={filter.availability.available}
                            onChange={() => setFilter('available', !filter.availability.available)}
                        />
                    </Label>
                </FormGroup>
            )}
            {canViewUnavailableToWorkTimeSlots && (
                <FormGroup check className="p-0">
                    <Label check>
                        <CustomInput
                            id="availability-checkbox-2"
                            type="checkbox"
                            label={translate('pages.shifts.unavailability')}
                            checked={filter.availability.unavailable}
                            onChange={() => setFilter('unavailable', !filter.availability.unavailable)}
                        />
                    </Label>
                </FormGroup>
            )}
            {canViewLeaveOfAbsences
                && (
                    <>
                        <FormGroup check className="p-0">
                            <Label check>
                                <CustomInput
                                    id="availability-checkbox-3"
                                    type="checkbox"
                                    label={`${translate('common.approved')} ${translate('pages.absences.leaveOfAbsence').toLowerCase()}`}
                                    checked={filter.availability.approvedLeaveOfAbsence}
                                    onChange={() => setFilter('approvedLeaveOfAbsence', !filter.availability.approvedLeaveOfAbsence)}
                                />
                            </Label>
                        </FormGroup>
                        <FormGroup check className="p-0">
                            <Label check>
                                <CustomInput
                                    id="availability-checkbox-4"
                                    type="checkbox"
                                    label={translate('pages.management.pendingLeaveOfAbsence')}
                                    checked={filter.availability.openLeaveOfAbsence}
                                    onChange={() => setFilter('openLeaveOfAbsence', !filter.availability.openLeaveOfAbsence)}
                                />
                            </Label>
                        </FormGroup>
                        <FormGroup check className="p-0">
                            <Label check>
                                <CustomInput
                                    id="availability-checkbox-5"
                                    type="checkbox"
                                    label={translate('pages.management.deniedLeaveOfAbsence')}
                                    checked={filter.availability.deniedLeaveOfAbsence}
                                    onChange={() => setFilter('deniedLeaveOfAbsence', !filter.availability.deniedLeaveOfAbsence)}
                                />
                            </Label>
                        </FormGroup>
                    </>
                )}
            {canViewAbsences && (
                <FormGroup check className="p-0">
                    <Label check>
                        <CustomInput
                            id="availability-checkbox-6"
                            type="checkbox"
                            label={translate('pages.absences.absence')}
                            checked={filter.availability.absence}
                            onChange={() => setFilter('absence', !filter.availability.absence)}
                        />
                    </Label>
                </FormGroup>
            )}
        </FilterBlock>
    );
};

export default AvailabilityFilter;
