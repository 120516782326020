import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { DepartmentGroup, DepartmentGroupResource } from './DepartmentGroup';
import {
    transformDepartmentGroupToPatchDepartmentGroupRequest,
    transformDepartmentGroupToPostDepartmentGroupRequest,
    transformToDepartmentGroup,
} from './DepartmentGroupTransformers';

export const getDepartmentGroupsApiCall = async (): Promise<FetchCollectionResult<DepartmentGroup[], string>> => {
    try {
        const url = generateApiUrl({ endpoint: '/groups' });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<DepartmentGroupResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const departmentGroups = doc.data
            .map(departmentGroupResource => transformToDepartmentGroup(departmentGroupResource));

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: departmentGroups,
        };
    } catch (error) {
        console.error('[getDepartmentGroupsIndexCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchDepartmentGroupApiCall = async (updatedDepartmentGroup: DepartmentGroup): Promise<FetchResult<DepartmentGroup, string>> => {
    const editDepartmentGroupRequest = transformDepartmentGroupToPatchDepartmentGroupRequest(updatedDepartmentGroup);

    try {
        const url = generateApiUrl({ endpoint: `/groups/${updatedDepartmentGroup.id}` });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: editDepartmentGroupRequest }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DepartmentGroupResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const departmentGroup = transformToDepartmentGroup(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: departmentGroup,
        };
    } catch (error) {
        console.error('[patchDepartmentGroupApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postDepartmentGroupApiCall = async (newDepartmentGroup: DepartmentGroup): Promise<FetchResult<DepartmentGroup, string>> => {
    const editDepartmentGroupRequest = transformDepartmentGroupToPostDepartmentGroupRequest(newDepartmentGroup);

    try {
        const url = generateApiUrl({ endpoint: '/groups' });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: editDepartmentGroupRequest }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<DepartmentGroupResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const departmentGroup = transformToDepartmentGroup(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: departmentGroup,
        };
    } catch (error) {
        console.error('[postDepartmentGroupApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteDepartmentGroupApiCall = async (departmentGroup: DepartmentGroup): Promise<FetchResult<DepartmentGroup, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/groups/${departmentGroup.id}` });

        const response = await authorizedFetch(url, {
            method: 'DELETE',
        });

        if (!response) {
            return {
                status: 500,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: departmentGroup,
        };
    } catch (error) {
        console.error('[deleteDepartmentGroupApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
