import { isArray } from 'underscore';

import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiV3Url } from '../../helpers/url';
import { JapiDocument } from '../../japi/types/Document';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { PayrollPeriod } from './PayrollPeriod';
import { comparePayrollPeriodsByDate, isPayrollPeriodResource } from './PayrollPeriodHelpers';
import { transformToPayrollPeriod } from './PayrollPeriodTransformers';

export const getPayrollPeriodsApiCall = async (): Promise<FetchCollectionResult<PayrollPeriod[], string>> => {
    try {
        const url = generateApiV3Url({
            endpoint: '/payroll-periods',
        });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!doc || !isArray(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const payrollPeriods = doc.map(transformToPayrollPeriod);

        payrollPeriods.sort(comparePayrollPeriodsByDate);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: payrollPeriods,
        };
    } catch (error) {
        console.error('[getPayrollPeriodsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const togglePayrollPeriodLockApiCall = async (id: string, lock: boolean): Promise<FetchResult<PayrollPeriod, string>> => {
    try {
        const url = generateApiV3Url({ endpoint: `/payroll-periods/${id}/${lock ? 'lock' : 'unlock'}` });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
        });

        const doc: JapiDocument = await response.json();

        if (!isPayrollPeriodResource(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const payrollPeriodWeek = transformToPayrollPeriod(doc);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: payrollPeriodWeek,
        };
    } catch (error) {
        console.error('[togglePayrollPeriodLockApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
