import React, { FC, useEffect, useState } from 'react';

import { LoadingSpinner } from '../../@paco/components';
import { Pagination } from '../../models';
import { Log } from '../../models/Log';
import ConnectedPaginationBlock from '../Pagination/ConnectedPaginationBlock';
import { generateLogsDateObject } from './helpers';
import LogDateBlock from './subcomponents/LogDateBlock/LogDateBlock';

import './Logs.scss';


export interface LogsPageProps {
    isLoket?: boolean;
    className?: string;
    loading?: boolean;
    logs: Log[] | null;
    pagination?: Pagination;
    onPagination?: (page: number) => void;
}

interface logsDateObjectProps {
    [key: string]: Log[];
}

const Logs: FC<LogsPageProps> = ({
    isLoket,
    className = '',
    loading = false,
    logs = [],
    onPagination,
    pagination,
}) => {
    const [logsDateObject, setLogsDateObject] = useState<logsDateObjectProps>({});
    const classNames = `logs-page ${className}`;

    useEffect(() => {
        if (logs) {
            setLogsDateObject(generateLogsDateObject(logs));
        }
    }, [logs]);

    if (loading && (!logs || logs.length === 0)) {
        return (
            <div className={classNames}>
                <LoadingSpinner className="logs-page__loading-spinner" />
            </div>
        );
    }

    const renderLogDateBlock = () => Object.values(logsDateObject).map(logsInDate => (
        <LogDateBlock
            isLoket={isLoket}
            key={logsInDate[0].id}
            logs={logsInDate}
        />
    ));

    if (!pagination || pagination.pages <= 1) {
        return (
            <div className={classNames}>
                { renderLogDateBlock() }
            </div>
        );
    }

    return (
        <div className={classNames}>
            <ConnectedPaginationBlock
                {...pagination}
                onPagination={onPagination}
                className={className}
            >
                {renderLogDateBlock()}
            </ConnectedPaginationBlock>
            { loading && <LoadingSpinner /> }
        </div>
    );
};

export default Logs;
