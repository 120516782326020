import React, { FC, ReactElement, ReactNode } from 'react';

import { LoadingSpinner } from '../../@paco/components';
import { translate } from '../../helpers/translations/translator';
import { Pagination } from '../../models';
import EmptyList from '../EmptyList/EmptyList';
import ConnectedPaginationBlock from '../Pagination/ConnectedPaginationBlock';

import './ItemsList.scss';

interface ItemsListProps {
    loading?: boolean;
    itemsLength: number;
    pagination?: Pagination;
    emptyMessage?: string;
    onPagination?: (page: number) => void;
    className?: string;
    children: ReactNode,
}

const ItemsList: FC<ItemsListProps> = ({
    loading,
    itemsLength,
    pagination = { number: 0, pages: 1 },
    emptyMessage = translate('common.noItemsFound'),
    onPagination,
    className = '',
    children,
}): ReactElement => (
    <ConnectedPaginationBlock {...pagination} className={className} onPagination={onPagination}>
        {loading && <LoadingSpinner /> }
        {itemsLength === 0 ? <EmptyList text={emptyMessage} /> : children}
    </ConnectedPaginationBlock>
);


export default ItemsList;
