// eslint-disable-next-line
import * as Sentry from '@sentry/browser';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { initHotjar, setIOSVersion } from './helpers';
import { setTranslations } from './helpers/translations/translator';
import AppInterceptor from './interceptors/AppInterceptor';
import AuthInterceptor from './interceptors/AuthInterceptor';
import './interceptors/JsonApiInterceptor';
import './interceptors/PageSizeInterceptor';
import { configureStore } from './redux/store';
import * as serviceWorker from './serviceWorker';

import './style/main.scss';
import './@paco/styles/global.scss';

// Make sure APP is underneath main.scss
import App from './App';
import dutch from './translations/dutch.json';

import packageJson from '../package.json';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://922b1a1c5ae5492db83d99a351f8930a@o62714.ingest.sentry.io/5226628',
        release: `${packageJson.name}@${packageJson.version}`,
        environment: process.env.REACT_APP_APP_ENV,
    });
}

setTranslations(dutch);
setIOSVersion();
initHotjar();

export const appRootId = 'app-root';
const container = document.getElementById(appRootId) as Element;
const root = createRoot(container);

configureStore({}).then(store => {
    const authInterceptor = new AuthInterceptor(store);
    const appInterceptor = new AppInterceptor(store);
    authInterceptor.setInterceptors();
    appInterceptor.setInterceptors();

    root.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>,
    );
});

serviceWorker.unregisterAllServiceWorkers();
