import React, { FC, ReactElement } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { useTypedSelector } from '../../../@paco/redux/store';
import CompletePeriodOpenTracksContainer from '../../../containers/@pz/CompletePeriodOpenTracksContainer/CompletePeriodOpenTracksContainer';
import { EditTrackFormData } from '../../../entities/Track/Track';
import { TrackViewModel } from '../../../models';
import { editOpenTrack } from '../../../redux/@toolkit/@tracks/openTracks/openTracksActions';
import { Reducers } from '../../../redux/reducers';

interface ConnectedCompletePeriodOpenTracksProps {
    activePeriod?: PayrollPeriod;
    className?: string;
}

const ConnectedCompletePeriodOpenTracks: FC<ConnectedCompletePeriodOpenTracksProps> = ({
    activePeriod,
    className,
}): ReactElement => {
    const dispatch = useDispatch();
    const { permissions, user } = useTypedSelector(state => state.authenticatedUserReducer);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);
    const { settings } = useTypedSelector(state => state.pacoSettingsReducer);
    const openTracks = useSelector<Reducers, TrackViewModel[]>(state => state.openTracksReducer.openTracks);
    const isLoading = useSelector<Reducers, boolean>(state => state.openTracksReducer.isLoading);

    const handleSubmitTrack = (formData: EditTrackFormData): void => {
        if (activePeriod) {
            // @ts-ignore
            dispatch(editOpenTrack(formData, activePeriod));
        }
    };

    return (
        <CompletePeriodOpenTracksContainer
            isLoading={isLoading}
            payrollPeriods={payrollPeriods}
            permissions={permissions}
            currentUser={user}
            settings={settings}
            openTracks={openTracks}
            onSubmitTrack={handleSubmitTrack}
            className={className}
        />
    );
};

export default ConnectedCompletePeriodOpenTracks;
