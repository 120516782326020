import { PublishShiftConceptsFormData } from '../../../../../entities/ShiftConcept/ShiftConcept';

const generateEmptyPublishShiftConceptsFormDataValues = (
    departments: string[],
    startDate: Date,
    endDate: Date,
): PublishShiftConceptsFormData => ({
    departments,
    startDate,
    endDate,
});

export default generateEmptyPublishShiftConceptsFormDataValues;
