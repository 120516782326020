import React, { FC, useEffect, useState } from 'react';

import classnames from 'classnames';
import { Table } from 'reactstrap';

import { ConnectedHelpTooltip } from '../../../../@paco/connectors';
import trans from '../../../../@paco/helpers/trans';
import { SpecialDayViewModel, UserWithAvailability } from '../../../../models';
import { AvailabilityResourceTypes } from '../AvailabilityDays/subcomponents/Availability/Availability';
import ListViewTableHead from './subcomponents/ListViewTableHead/ListViewTableHead';
import ListViewUser from './subcomponents/ListViewUser/ListViewUser';

import './ListViewTable.scss';

interface Props {
    loading: boolean;
    days: Date[];
    specialDays?: SpecialDayViewModel[];
    startDate: Date;
    endDate: Date;
    users: UserWithAvailability[];
    isFilterEmploymentTypeBadgeActive: boolean;
    canAddPlannings: boolean;
    firstRowSticky: boolean;
    firstColumnSticky: boolean;
    onAvailabilityClick: (resource: AvailabilityResourceTypes) => void;
    onAddButtonClick: (date: Date, user: UserWithAvailability) => void;
}

const ListViewTable: FC<Props> = ({
    loading,
    days,
    specialDays,
    isFilterEmploymentTypeBadgeActive,
    canAddPlannings,
    firstRowSticky,
    firstColumnSticky,
    onAvailabilityClick,
    onAddButtonClick,
    ...props
}) => {
    const classNames = classnames('list-view-table table-container-responsive', {
        'list-view-table--first-row-sticky': firstRowSticky,
        'list-view-table--first-column-sticky': firstColumnSticky,
    });

    const [users, setUsers] = useState<UserWithAvailability[]>(props.users || []);

    useEffect(() => {
        if (!loading && props.users.length) {
            setUsers(props.users);
        }
    }, [props.users, loading]);

    return (
        <div className={classNames}>
            <ConnectedHelpTooltip
                index={2}
                videoSrc="/help/assign-shift.webm"
                route="list-view"
                subTitle={trans('help.listView.assignShift.title')}
                text={trans('help.listView.assignShift.text')}
                title={trans('help.listView.title')}
                showMobileInfoWarning
                className="list-view-table__assign-shift-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={3}
                route="list-view"
                subTitle={trans('help.listView.assignConceptShifts.title')}
                text={trans('help.listView.assignConceptShifts.text')}
                title={trans('help.listView.title')}
                className="list-view-table__assign-concept-shifts-help-tooltip"
            />
            <div className="table-container list-view-table__table-container">
                <Table className="list-view-table__table">
                    <ListViewTableHead
                        dates={days}
                        specialDays={specialDays}
                    />
                    <tbody>
                        {users.map((user: UserWithAvailability) => (
                            <ListViewUser
                                key={user.id}
                                loading={loading}
                                user={user}
                                range={days}
                                isFilterEmploymentTypeBadgeActive={isFilterEmploymentTypeBadgeActive}
                                canAddPlannings={canAddPlannings}
                                onAvailabilityClick={onAvailabilityClick}
                                onAddButtonClick={onAddButtonClick}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default ListViewTable;
