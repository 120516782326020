import { transformToPeriod } from '../Period/PeriodTransformers';
import { User } from '../User/User';
import { PreferToWorkTimeSlot, PreferToWorkTimeSlotResource } from './PreferToWorkTimeSlot';

export const transformToPreferToWorkTimeSlot = (resource: PreferToWorkTimeSlotResource, user: User): PreferToWorkTimeSlot => {
    const period = transformToPeriod(new Date(resource.attributes.start), new Date(resource.attributes.end));

    return {
        id: resource.id,
        comment: resource.attributes.comment,
        createdAt: new Date(resource.attributes.createdAt),
        period,
        user,
    };
};
