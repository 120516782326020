import React, { FC, useMemo } from 'react';

import { getNotAvailablePreferences } from '../../../../../../helpers';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../../../../../helpers/date';
import { UnavailableToWorkTimeSlot } from '../../../../../../models';
import UnavailableSlot from '../UnavailableSlot/UnavailableSlot';

interface Props {
    day: Date;
    slots: UnavailableToWorkTimeSlot[],
    onItemClick: (item: UnavailableToWorkTimeSlot) => void,
}

const UnavailableSlotList: FC<Props> = ({ day, slots, onItemClick }) => {
    const filteredSlots = useMemo(() => getNotAvailablePreferences(slots, getStartOfWorkDay(day), getEndOfWorkDay(day)), [slots]);

    return (
        <div className="shifts-list">
            {
                filteredSlots.map(slot => (
                    <UnavailableSlot
                        onClick={onItemClick}
                        key={slot.id}
                        slot={slot}
                    />
                ))
            }
        </div>
    );
};

export default UnavailableSlotList;
