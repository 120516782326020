import React, { FC } from 'react';

import { translate } from '../../helpers/translations/translator';
import Icon from '../style/Icon/Icon';

import './TrackDurationTooLongWarning.scss';

interface TrackDurationTooLongWarningProps {
    className?: string;
}

const TrackDurationTooLongWarning: FC<TrackDurationTooLongWarningProps> = ({ className = '' }) => (
    <div className={`track-duration-too-long-warning ${className}`}>
        <Icon className="track-duration-too-long-warning__icon" kind="info" />
        <div className="track-duration-too-long-warning__text">
            <span className="track-duration-too-long-warning__look-out">
                {translate('common.lookOut')}
                :
                {' '}
            </span>
            <span className="track-duration-too-long-warning__warning">{translate('pages.tracks.trackDurationTooLongWarning')}</span>
        </div>
    </div>
);

export default TrackDurationTooLongWarning;
