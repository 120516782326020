import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateLoketUrl } from '../../helpers/url';
import { JapiDocument } from '../../japi/types/Document';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import {
    EmployeeSyncResult,
    FullLoketUser,
    LoketUser,
    LoketUserResource,
} from './LoketUser';
import { isEmployeeSyncResultResource, isFullLoketUser, isLoketUserCollection } from './LoketUserHelpers';
import { transformToEmployeeSyncResult, transformToFullLoketUser, transformToLoketUser } from './LoketUserTransformers';

export const fetchUnsyncedEmployeesApiCall = async (): Promise<FetchCollectionResult<LoketUser[], string>> => {
    try {
        const url = generateLoketUrl({ endpoint: '/employee/unsynced' });

        const response = await authorizedFetch(url);
        const doc: LoketUserResource[] = await response.json();

        if (!isLoketUserCollection(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = doc
            .map(transformToLoketUser)
            .sort((a, b) => (a.person.lastname).localeCompare(b.person.lastname));

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[fetchUnsyncedEmployeesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const fetchLoketEmployeeApiCall = async (userId: string): Promise<FetchResult<FullLoketUser, string>> => {
    try {
        const url = generateLoketUrl({ endpoint: `/employee/${userId}` });

        const response = await authorizedFetch(url);
        const doc = await response.json();

        if (!isFullLoketUser(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = transformToFullLoketUser(doc);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[fetchLoketEmployeeCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const syncUserToLoketApiCall = async (userId: string): Promise<FetchResult<EmployeeSyncResult, string>> => {
    try {
        const url = generateLoketUrl({ endpoint: `/employee/sync/${userId}` });

        const response = await authorizedFetch(url, { method: 'PUT' });

        const doc: JapiDocument = await response.json();

        if (!doc || !isEmployeeSyncResultResource(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const result = transformToEmployeeSyncResult(doc);

        return {
            data: result,
            status: response.status,
            type: FetchResultType.Success,
        };
    } catch (error) {
        console.error('[syncUserToLoketApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
