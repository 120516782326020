import { fetchIncompleteAbsences } from '../../../../redux/@toolkit/absences/absencesActions';
import { fetchIncompleteLeaveOfAbsences } from '../../../../redux/@toolkit/leaveOfAbsences/incompleteLeaveOfAbsences/incompleteLeaveOfAbsencesActions';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../../../services/PayrollPeriodService/transformPayrollPeriodLegacy';
import { isFetchResultSuccessful } from '../../../entities/FetchResult';
import {
    getAbsenceNotificationsIndexCall,
    getExchangeNotificationsIndexCall,
    getFeedbackNotificationsIndexCall,
    getLeaveOfAbsenceNotificationsIndexCall,
    getPayrollPeriodNotificationsIndexCall,
    getShiftNotificationsIndexCall,
    getTrackNotificationsIndexCall,
} from '../../../entities/Notifications/NotificationsService';
import { getPayrollPeriodFromDate } from '../../../entities/PayrollPeriod/PayrollPeriodHelpers';
import { Reducers } from '../../reducers';
import { TypedDispatch } from '../../store';
import { fetchUsersWithIncompleteDivergentEmploymentHours } from '../../usersWithDivergentEmploymentHours/usersWithDivergentEmploymentHoursActions';
import {
    setAbsenceNotifications,
    setError,
    setExchangeNotifications,
    setFeedbackNotifications,
    setIncompleteUsers,
    setIsLoading,
    setLeaveOfAbsenceNotifications,
    setOpenAbsenceHours,
    setOpenLeaveOfAbsenceHours,
    setPayrollPeriodNotifications,
    setShiftNotifications,
    setTrackNotifications,
} from './notificationsReducer';

export const fetchAbsenceNotifications = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getAbsenceNotificationsIndexCall();

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setAbsenceNotifications(response.data));
    } catch (error) {
        console.error('[fetchAbsenceNotifications]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchOpenAbsenceHours = () => async (dispatch: TypedDispatch, getState: () => Reducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { pacoPayrollPeriodsReducer } = getState();
        const { payrollPeriods } = pacoPayrollPeriodsReducer;

        const currentPayrollPeriod = getPayrollPeriodFromDate(new Date(), payrollPeriods);

        if (!currentPayrollPeriod) {
            throw new Error('No current payroll period found');
        }

        const legacyPayrollPeriod = transformPayrollPeriodToLegacyPayrollPeriod(currentPayrollPeriod);
        dispatch(fetchIncompleteAbsences(legacyPayrollPeriod));
    } catch (error) {
        console.error('[fetchOpenAbsenceHours]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchExchangeNotifications = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getExchangeNotificationsIndexCall();

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setExchangeNotifications(response.data));
    } catch (error) {
        console.error('[fetchExchangeNotifications]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchLeaveOfAbsenceNotifications = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getLeaveOfAbsenceNotificationsIndexCall();

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLeaveOfAbsenceNotifications(response.data));
    } catch (error) {
        console.error('[fetchLeaveOfAbsenceNotifications]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchOpenLeaveOfAbsenceHours = () => async (dispatch: TypedDispatch, getState: () => Reducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { pacoPayrollPeriodsReducer } = getState();
        const { payrollPeriods } = pacoPayrollPeriodsReducer;

        const currentPayrollPeriod = getPayrollPeriodFromDate(new Date(), payrollPeriods);

        if (!currentPayrollPeriod) {
            throw new Error('No current payroll period found');
        }

        dispatch(fetchIncompleteLeaveOfAbsences(transformPayrollPeriodToLegacyPayrollPeriod(currentPayrollPeriod)));
    } catch (error) {
        console.error('[fetchOpenLeaveOfAbsenceHours]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const initializeOpenLeaveOfAbsenceHours = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setOpenLeaveOfAbsenceHours());
};

export const initializeOpenAbsenceHours = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setOpenAbsenceHours());
};

export const fetchPayrollPeriodNotifications = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getPayrollPeriodNotificationsIndexCall();

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setPayrollPeriodNotifications(response.data));
    } catch (error) {
        console.error('[fetchPayrollPeriodNotifications]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchFeedbackNotifications = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getFeedbackNotificationsIndexCall();

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setFeedbackNotifications(response.data));
    } catch (error) {
        console.error('[fetchFeedbackNotifications]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchTrackNotifications = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getTrackNotificationsIndexCall();

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setTrackNotifications(response.data));
    } catch (error) {
        console.error('[fetchTrackNotifications]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchShiftNotifications = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getShiftNotificationsIndexCall();

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setShiftNotifications(response.data));
    } catch (error) {
        console.error('[fetchShiftNotifications]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchIncompleteUsers = () => async (dispatch: TypedDispatch, getState: () => Reducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { pacoPayrollPeriodsReducer } = getState();
        const { payrollPeriods } = pacoPayrollPeriodsReducer;

        const currentPayrollPeriod = getPayrollPeriodFromDate(new Date(), payrollPeriods);

        if (!currentPayrollPeriod) {
            throw new Error('No current payroll period found');
        }

        dispatch(fetchUsersWithIncompleteDivergentEmploymentHours(currentPayrollPeriod));
    } catch (error) {
        console.error('[fetchIncompleteUsers]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const initializeIncompleteUsers = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIncompleteUsers());
};

export const fetchNotifications = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        await Promise.all([
            dispatch(fetchAbsenceNotifications()),
            dispatch(fetchExchangeNotifications()),
            dispatch(fetchLeaveOfAbsenceNotifications()),
            dispatch(fetchPayrollPeriodNotifications()),
            dispatch(fetchFeedbackNotifications()),
            dispatch(fetchTrackNotifications()),
            dispatch(fetchShiftNotifications()),
        ]);
    } catch (error) {
        console.error('[fetchNotifications]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
