import { Dispatch } from 'redux';

import { TOAST_TYPE_PASS } from '../../../constants';
import { BaseScheduleShiftFormData, BaseScheduleShiftViewModel } from '../../../entities/BaseScheduleShift/BaseScheduleShift';
import {
    deleteBaseScheduleShiftApiCall,
    editBaseScheduleShiftApiCall,
    getBaseScheduleShiftApiCall,
    postBaseScheduleShiftApiCall,
} from '../../../entities/BaseScheduleShift/BaseScheduleShiftService';
import { transformAddBaseScheduleShiftFormData, transformBaseScheduleShiftToRequest, transformEditBaseScheduleShiftFormData } from '../../../entities/BaseScheduleShift/BaseScheduleShiftTransformers';
import { translate } from '../../../helpers/translations/translator';
import { EditShiftFormData } from '../../../models';
import { setAppToast } from '../../app/appActions';
import { Reducers } from '../../reducers';
import { TypedDispatch } from '../../store';
import { setLastModifiedBaseScheduleShiftId } from '../baseSchedule/baseScheduleReducer';
import { copyBaseScheduleShiftPlanning } from '../baseScheduleShiftPlanning/baseScheduleShiftPlanningActions';
import {
    setBaseScheduleShift,
    setError,
    setIsLoading,
    setIsSuccessful,
} from './baseScheduleShiftReducer';

export const fetchBaseScheduleShift = (id: string) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setBaseScheduleShift(undefined));
    dispatch(setError(''));

    try {
        const response = await getBaseScheduleShiftApiCall(id);

        dispatch(setBaseScheduleShift(response));
        dispatch(setIsLoading(false));
    } catch (error) {
        dispatch(setError('fetchBaseScheduleShift'));
        console.error('[fetchBaseScheduleShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const createBaseScheduleShift = (formData: BaseScheduleShiftFormData) => async (dispatch: Dispatch, getState: () => Reducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { baseScheduleReducer } = getState();

        if (!baseScheduleReducer.baseSchedule) {
            throw new Error('There is no BaseSchedule present to add as relationship');
        }

        const request = transformAddBaseScheduleShiftFormData(formData, baseScheduleReducer.baseSchedule?.id);

        const response = await postBaseScheduleShiftApiCall(request);

        dispatch(setAppToast(translate('pages.shifts.addBaseScheduleShiftSuccess'), TOAST_TYPE_PASS));
        dispatch(setLastModifiedBaseScheduleShiftId(response.id));
        dispatch(setBaseScheduleShift(response));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[createBaseScheduleShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editBaseScheduleShift = (
    baseScheduleShift: BaseScheduleShiftViewModel,
    formData: EditShiftFormData,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const request = transformEditBaseScheduleShiftFormData(formData, baseScheduleShift.id);

        await editBaseScheduleShiftApiCall(request, baseScheduleShift.id);

        dispatch(setLastModifiedBaseScheduleShiftId(baseScheduleShift.id));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editBaseScheduleShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteBaseScheduleShift = (baseScheduleShiftId: string) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        await deleteBaseScheduleShiftApiCall(baseScheduleShiftId);

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteBaseScheduleShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const copyBaseScheduleShift = (baseScheduleShift: BaseScheduleShiftViewModel) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const shiftRequest = transformBaseScheduleShiftToRequest(baseScheduleShift);
        const shiftResponse = await postBaseScheduleShiftApiCall(shiftRequest);

        if (baseScheduleShift.shiftPlannings.length > 0) {
            try {
                await Promise.all(baseScheduleShift.shiftPlannings.map(shiftPlanning => dispatch(copyBaseScheduleShiftPlanning(shiftPlanning, shiftResponse.id))));
            } catch (error) {
                console.error('[copyBaseScheduleShift]', error);
                dispatch(deleteBaseScheduleShift(shiftResponse.id));
            }
        }

        dispatch(setAppToast(translate('pages.shifts.copyShiftSuccess'), TOAST_TYPE_PASS));
        dispatch(setLastModifiedBaseScheduleShiftId(shiftResponse.id));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[copyBaseScheduleShift]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
