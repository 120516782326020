import { Setting } from '../../../@paco/entities/Setting/Setting';
import { getSetting } from '../../../@paco/entities/Setting/SettingHelpers';
import { getShiftConceptPlanningsApiCall } from '../../../entities/ShiftConceptPlanning/ShiftConceptPlanningService';
import { addHours } from '../../../helpers/date';

export const doesHaveShiftConceptPlanningsInLast11h = async (
    settings: Setting[],
    userId: string,
    date: Date,
): Promise<boolean> => {
    const timeBetweenShiftsSetting = getSetting('ENABLE_CAO_MESSAGE_TIME_BETWEEN_SHIFTS', settings);

    if (!timeBetweenShiftsSetting || !timeBetweenShiftsSetting.isEnabled) {
        return false;
    }

    const startDate = addHours(date, -11);

    const shiftConceptPlannings = await getShiftConceptPlanningsApiCall(
        startDate,
        date,
        { userIds: [userId] },
    );

    return !!shiftConceptPlannings.data.length;
};
