import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { EntityEvent, IconButton } from '../../../../compositions';
import { EventLog } from '../../../../entities/EventLog/EventLog';

import './GroupedEntityEvents.scss';

interface GroupedEntityEventsProps {
    title: string;
    logs: EventLog[];
    className?: string;
}

const GroupedEntityEvents: FC<GroupedEntityEventsProps> = ({ title, logs, className = '' }): ReactElement => {
    const [isExpanded, toggleIsExpanded] = useToggle(true);

    const wrapperClassName = classNames('grouped-entity-events', {
        'grouped-entity-events--is-expanded': isExpanded,
    }, className);

    return (
        <div className={wrapperClassName}>
            <IconButton
                icon="chevron-down"
                iconPos="right"
                text={title}
                onClick={toggleIsExpanded}
                className="grouped-entity-events__toggle-button"
                iconClassName="grouped-entity-events__toggle-button-icon"
            >
                {title}
            </IconButton>

            <ul className="grouped-entity-events__list">
                {logs.map(log => (
                    <li key={log.id} className="grouped-entity-events__list-item">
                        <EntityEvent log={log} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default GroupedEntityEvents;
