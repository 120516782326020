import { FC } from 'react';

import trans from '../../../../helpers/trans';

import './ShiftsWidgetEmptyMessage.scss';

export interface ShiftsWidgetEmptyMessageProps {
    className?: string;
}

const ShiftsWidgetEmptyMessage: FC<ShiftsWidgetEmptyMessageProps> = ({ className = '' }) => (
    <div className={`shifts-widget-empty-message ${className}`}>
        <span className="shifts-widget-empty-message__title">
            {trans('compositions.dashboardShiftsWidget.noShiftsFoundForToday')}
        </span>
        <span className="shifts-widget-empty-message__text">
            {trans('compositions.dashboardShiftsWidget.noShiftsFoundForTodayCallToAction')}
        </span>
    </div>
);

export default ShiftsWidgetEmptyMessage;
