import { FC, ReactElement, useEffect } from 'react';

import AddCommentForm from '../../../containers/@forms/AddCommentForm/AddCommentForm';
import { AddCommentFormData, commentRelationshipTypes } from '../../../entities/Comment/Comment';
import { DistrictDivisionDistrictPlanning } from '../../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import trans from '../../../helpers/trans';
import { addDistrictDivisionDistrictPlanningComment } from '../../../redux/districtDivisionDistrictPlanning/districtDivisionDistrictPlanningActions';
import { setIsAddPlanningCommentSuccessful } from '../../../redux/districtDivisionDistrictPlanning/districtDivisionDistrictPlanningReducer';
import { addDistrictDivisionDistrictTemporaryWorkerComment } from '../../../redux/districtDivisionDistrictTemporaryWorker/districtDivisionDistrictTemporaryWorkerActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedAddDistrictDivisionDistrictPlanningCommentFormProps {
    districtDivisionDistrictPlanning: DistrictDivisionDistrictPlanning;
    onClose: () => void;
}

const ConnectedAddDistrictDivisionDistrictPlanningCommentForm: FC<ConnectedAddDistrictDivisionDistrictPlanningCommentFormProps> = ({
    districtDivisionDistrictPlanning,
    onClose,
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { isLoading, isAddPlanningCommentSuccessful } = useTypedSelector(state => state.districtDivisionDistrictPlanningReducer);

    const handleSubmit = async (formData: AddCommentFormData): Promise<void> => {
        if (formData.relationshipType === 'districtDivisionDistrictPlannings') {
            dispatch(addDistrictDivisionDistrictPlanningComment(formData.relationshipId, formData.comment));
        }

        if (formData.relationshipType === 'districtDivisionDistrictTemporaryWorkers') {
            dispatch(addDistrictDivisionDistrictTemporaryWorkerComment(formData.relationshipId, formData.comment));
        }
    };

    useEffect((): void => {
        if (isAddPlanningCommentSuccessful) {
            dispatch(setIsAddPlanningCommentSuccessful(false));

            dispatch(setToast({
                text: trans('common.addCommentSuccess'),
                type: ToastType.pass,
            }));

            onClose();
        }
    }, [isAddPlanningCommentSuccessful]);

    return (
        <AddCommentForm
            isLoading={isLoading}
            relationshipId={districtDivisionDistrictPlanning.id}
            relationshipKey={districtDivisionDistrictPlanning.type === 'districtDivisionDistrictPlannings' ? 'districtDivisionDistrictPlanning' : 'districtDivisionDistrictTemporaryWorker'}
            relationshipType={districtDivisionDistrictPlanning.type as commentRelationshipTypes}
            onSubmit={handleSubmit}
            onCancel={onClose}
        />
    );
};

export default ConnectedAddDistrictDivisionDistrictPlanningCommentForm;
