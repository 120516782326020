import { FC, useMemo } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import LegacyScheduleRouteGroup from '../../../../../pages/Schedule/LegacyScheduleRouteGroup';
import { getDefaultSubRoutePathByNavigationRouteKey } from '../../../../helpers/routes';
import { DistrictDivisionPage, ShiftDetailPage, ShiftsCalendarPage } from '../../../../pages';
import { ScheduleRoutes } from '../../../../routes/helpers';
import { NavRoute } from '../../../../routes/routes';

interface ScheduleRouteGroupProps {
    permittedUserRoutes: NavRoute[];
}

const ScheduleRouteGroup: FC<ScheduleRouteGroupProps> = ({ permittedUserRoutes }) => {
    const defaultRoute = useMemo(() => getDefaultSubRoutePathByNavigationRouteKey('shifts', permittedUserRoutes), [permittedUserRoutes]);

    return (
        <Routes>
            <Route path={ScheduleRoutes.calendar()} element={<ShiftsCalendarPage />} />
            <Route path={ScheduleRoutes.districtDivision()} element={<DistrictDivisionPage />} />
            <Route path={ScheduleRoutes.shift(':id')} element={<ShiftDetailPage />} />

            <Route path="/*" element={<LegacyScheduleRouteGroup />} />
            <Route path="/" element={<Navigate to={defaultRoute} />} />
        </Routes>
    );
};

export default ScheduleRouteGroup;
