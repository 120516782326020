import { WeekWithHours } from '../../../models';
import { WeekGroup } from '../models/WeekGroup';

export default function getGroupsFromWeeksWithHours(weeks: WeekWithHours[]): WeekGroup[] {
    return Array
        .from(
            Array(Math.ceil(weeks.length / 2)).keys(),
        )
        .map((index: number) => ({
            id: `group-${weeks[index * 2].start.toISOString()}`,
            weeks: weeks.slice(index * 2, index * 2 + 2),
        }));
}
