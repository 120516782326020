import { FC, ReactElement } from 'react';

import { FilterSection } from '../../../components';
import { LeaveOfAbsencesOverviewLegend } from '../../../compositions';
import {
    ConnectedDepartmentsFilter,
    ConnectedEmploymentTypesFilter,
    ConnectedRolesFilter,
    ConnectedShowMainDepartmentFilter,
} from '../../../connectors';

import './LeaveOfAbsencesOverviewFilterContainer.scss';

interface LeaveOfAbsencesOverviewFilterContainerProps {
    className?: string;
}

const LeaveOfAbsencesOverviewFilterContainer: FC<LeaveOfAbsencesOverviewFilterContainerProps> = ({ className = '' }): ReactElement => (
    <form className={`leave-of-absences-overview-filter-container ${className}`}>
        <FilterSection>
            <ConnectedRolesFilter />
        </FilterSection>
        <FilterSection>
            <ConnectedEmploymentTypesFilter />
        </FilterSection>
        <FilterSection>
            <ConnectedShowMainDepartmentFilter />
        </FilterSection>
        <FilterSection>
            <ConnectedDepartmentsFilter />
        </FilterSection>
        <LeaveOfAbsencesOverviewLegend className="leave-of-absences-overview-filter-container__legend" />
    </form>
);

export default LeaveOfAbsencesOverviewFilterContainer;
