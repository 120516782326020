// Dates ranging over multiple days start at 04:00 and end at 03:59, however in forms and calendars we need to
// show the 00:00 date visually for the user

import { addDays, endOfDay, startOfDay } from './index';

// Legacy do not use!
export const transformStartDateOrStringToFormDate = (value: Date | string): Date => {
    const date = typeof value === 'string' ? new Date(value) : value;

    if ((date.getHours() === 3 && date.getMinutes() === 59) || (date.getHours() === 4 && date.getMinutes() === 0)) {
        return startOfDay(date);
    }

    return date;
};

// Legacy do not use!
export const transformEndDateOrStringToFormDate = (value: Date | string): Date => {
    const date = typeof value === 'string' ? new Date(value) : value;

    if ((date.getHours() === 3 && date.getMinutes() === 59) || (date.getHours() === 4 && date.getMinutes() === 0)) {
        return addDays(endOfDay(date), -1);
    }

    return date;
};
